import { useMemo }           from "react";
import { useCallback }       from "react";
import { Condition }         from "../..";
import { TopLevelCondition } from "../..";
import { useSource }         from "../Layout/JsonPage";

export interface UseLazyCondition {
  conditions: TopLevelCondition;
  source?: any;
  root?: string
}

export function useLazyCondition(props: Partial<UseLazyCondition> = {}) {
  const source = { ...useSource(), ...props?.source };
  const condition = useMemo(() => new Condition(source), [source]);
  return useCallback((options: Partial<UseLazyCondition> = {}) => {
    const conditions = { ...props.conditions, ...options.conditions };
    const rootKey = options.root || props.root;
    return condition.evaluate(conditions, options.source, rootKey);
  }, [props, condition]);
}
