import React                 from "react";
import { classNames }        from "../../..";
import { Box }               from "../../..";
import { ListItemClasses }   from "..";
import { Tooltip }           from "../../..";
import { BoxComponentProps } from "../../..";
import { Alignment }    from "../../../constants/Alignemnt";
import { formatNumber } from "../../../utils/helpers";

export interface ListItemPercentProps extends BoxComponentProps {
  percent: number,
  label?: string,
  precision?: number
  variant?: "default" | "bold"
}

export function ListItemPercent(props: ListItemPercentProps) {
  let { className, percent, label, precision, variant = "default", ...p } = props;
  const classes = classNames(ListItemClasses.ListItemText, {
    [ ListItemClasses.ListItemBoldText ]: variant == "bold"
  }, className);
  percent = typeof percent == "number" ? percent : 0;
  precision = typeof precision == "number" ? precision : 0;
  return <Box container className={classes} gap={"XXXS"} alignItems={"center"} {...p}>
      <p>{formatNumber(percent, precision)}%</p>
  </Box>;
}
