import React                from "react";
import { JsonSectionProps } from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { ButtonVariants }   from "@relcu/ui";
import { JsonSection }      from "@relcu/ui";

export const PasswordSection = React.memo<JsonSectionProps>(function PasswordSection({ component, ...props }) {
  if (props.view === "read" || props.view === "disabled") {
    return (
      <Button type="button"
              alignSelf={"end"}
              disabled={props.view === "disabled"}
              variant={ButtonVariants.Ghost}
              onClick={props.onEdit}
      >
        Change Password
      </Button>
    );
  }
  return <JsonSection {...props} />;
});
