import React                     from "react";
import { FC }                    from "react";
import { useContext }            from "react";
import List                      from "rc-virtual-list";
import { SearchableMenuContext } from "./MenuProvider";
import { SearchableMenuItem }    from "./SearchableMenuItem";

interface VirtualMenuListProps {
  afterLoader?: () => any;
}

export const VirtualList: FC<VirtualMenuListProps> = React.memo(function VirtualMenuList(props) {
  const { getListKey, options } = useContext(SearchableMenuContext);
  return <List data={options}
               height={options.length > 5 ? 200 : options.length * 40}
               itemHeight={40}
               itemKey={getListKey}>
    {
      (item, _, props: any) => {
        return (
          <SearchableMenuItem
            item={item}
            index={_}
            {...props}
          />
        );
      }
    }
  </List>;
});
