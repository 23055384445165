import { useMemo }           from "react";
import React                 from "react";
import { FC }                from "react";
import { useState }          from "react";
import { CommonClasses }     from "../..";
import { FontIcon }          from "../..";
import { Menu }              from "../..";
import { Popper }            from "../..";
import { BoxComponentProps } from "../..";
import { Alignment }         from "../..";
import { NavBarClasses }     from "./NavBarClasses";

export interface NavBarItemMenuProps extends BoxComponentProps {
  active: boolean
  id: string
  droppableId: string
  onToggle(id: string)
}

export const NavBarItemMenu: FC<NavBarItemMenuProps> = React.memo(function NavBarItemMenu(props) {
  const { active, id, onToggle, children } = props;
  const [anchorBounding, setAnchorBounding] = useState(null);

  const togglePopper = event => {
    onToggle(active ? null : id);
    setAnchorBounding(!anchorBounding ? event.currentTarget.getBoundingClientRect() : null);
  };
  return <>
    <FontIcon
      className={NavBarClasses.NavBarItemSelect}
      type={"more_vert"}
      onClick={(e) => {
        e.preventDefault();
        togglePopper(e);
      }}/>
    <Popper
      open={active}
      anchorBounding={anchorBounding}
      onClickAway={togglePopper}
      alignment={Alignment.BottomLeft}
      threshold={6}>
      <Menu
        type={"select"}
        height={44}
        role="listbox"
        className={CommonClasses.SH}>
        {children}
      </Menu>
    </Popper>
  </>;
});
