import { useRef }           from "react";
import { useEffect }        from "react";
import React                from "react";
import { FormSpy }          from "@relcu/form";
import { AlertColors }      from "@relcu/ui";
import { JsonSection }      from "@relcu/ui";
import { JsonSectionProps } from "@relcu/ui";
import { Alert }            from "@relcu/ui";
import { useSource }        from "@relcu/ui";

export const UserGeneralSection = React.memo<JsonSectionProps>(function UserGeneralSection({ component, ...props }) {
  const { $object } = useSource();
  return (
    <JsonSection {...props}>
      {(props.view === "write" || props.view === "create") &&
      <FormSpy subscription={{ values: true, initialValues: true }}>
        {({ values, initialValues }) => <>
          {!$object.deactivated && !initialValues?.deactivated && values.deactivated && (
            <UserDeactivationAlert/>
          )}
        </>}
      </FormSpy>
      }
    </JsonSection>
  );
});

function UserDeactivationAlert() {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);
  return (
    <Alert variant={AlertColors.Warning} width="100%"
           style={{ backgroundColor: "#fbf0c5", boxShadow: "unset" }}>
      <div ref={ref}/>
      You are deactivating this user’s account.
    </Alert>
  );
}
