import React                 from "react";
import { useMemo }           from "react";
import { Box }               from "../..";
import { BoxComponentProps } from "../..";
import { FontIcon }          from "../..";
import { IconType }          from "../..";
import { classNames }        from "../..";

export enum AlertColors {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Primary = "primary",
  PrimaryLight = "primary_light",
  Default = "default"
}

export interface AlertProps extends BoxComponentProps {
  width?: number | string,
  icon?: IconType,
  variant: AlertColors
  stretch?: boolean
  onClose?()//todo need to discuss
}

const defaultAlertProps: AlertProps = {
  variant: AlertColors.Primary
};

Alert.defaultProps = defaultAlertProps;
export function Alert(props: AlertProps) {
  const { className, variant, children, onClose, stretch, icon,...p } = props;
  const classes = classNames(AlertClasses.Alert, {
    [ AlertClasses.Error ]: variant == AlertColors.Error,
    [ AlertClasses.Success ]: variant == AlertColors.Success,
    [ AlertClasses.Warning ]: variant == AlertColors.Warning,
    [ AlertClasses.Primary ]: variant == AlertColors.Primary,
    [ AlertClasses.Default ]: variant == AlertColors.Default,
    [ AlertClasses.Stretch ]: stretch,
    [ AlertClasses.PrimaryLight ]: variant == AlertColors.PrimaryLight
  }, className);

  const type = useMemo(() => {
    if(icon){
      return icon
    }
    switch (variant) {
      case AlertColors.Error:
      case AlertColors.Warning:
        return "warning";
      case AlertColors.Success:
        return "check_circle";
      case AlertColors.Default:
        return null;
      default:
        return "info";
    }
  }, [variant,icon]);

  return <Box container className={classes} alignItems={"center"} gap={"XXS"} {...p}
              style={{ width: props.width, ...p.style }}>
    <Box container gap={"XXS"} flexGrow={1} style={{ overflow: "hidden" }}>
      {type && <FontIcon type={type}/>}
      <Box container flex={1} alignItems={"center"} style={{ overflow: "hidden" }}>
        {children}
      </Box>
    </Box>
    {
      !!onClose && <FontIcon className={AlertClasses.AlertClose} type={"clear"} onClick={onClose}/>
    }
  </Box>;
}

export enum AlertClasses {
  Alert = "alert",
  Error = "alert--error",
  Success = "alert--success",
  Warning = "alert--warning",
  Primary = "alert--primary",
  Stretch = "alert--stretch",
  Default = "alert--default",
  PrimaryLight = "alert--primary-light",
  AlertClose = "alert--close"
}
