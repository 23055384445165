import { TypePolicy } from "@apollo/client";

export const EmailTemplate: TypePolicy = {
  fields: {
    type: {
      read(existing) {
        return existing || "plainText";
      }
    }
  }
};
