import { ConfirmDialog } from "@relcu/ui";

import { DateTime }           from "luxon";
import React                  from "react";
import ReactDOM               from "react-dom";
import { isAuthenticatedVar } from "./reactiveVars";
import { IS_DEV_MODE }        from "./utils/helpers";
import { loadConfig }         from "./utils/loadConfig";
import { loadGoogleMaps }     from "./utils/loadGoogleMaps";
import { loadScript }         from "./utils/loadScript";
import Web                    from "./Web";
import "./styles/index.less";

const version = import.meta.env.VITE_VERSION;


console.info(version,IS_DEV_MODE);

async function main() {

  window.global ||= window;
  if (!IS_DEV_MODE) {
    registerSW(new URL("/sw.js", import.meta.url));
  }
  window.onunhandledrejection = (event) => {
    console.error(`"Unhandled Rejection reason ${event.reason}`);
  };
  window.addEventListener("error", e => console.error(e));
  const settings = await loadConfig();
  isAuthenticatedVar(!!settings.isAuthenticated);
  if (Notification.permission != "denied" && Notification.permission != "granted") {
    await Notification.requestPermission();
  }
  if (!!window[ "chrome" ]) {
    // await loadScript("https://tableau.relcu.dev/javascripts/api/viz_v1.js")
    //await loadScript("https://unpkg.com/@twilio/voice-sdk@2.1.1/dist/twilio.min.js");
    //await loadScript("https://unpkg.com/@twilio/voice-sdk@2.7.3/dist/twilio.min.js");
    await loadScript("https://unpkg.com/@twilio/voice-sdk@2.10.2/dist/twilio.min.js");
    if (settings.datadog) {
      await datadogSetup({
        ...settings.datadog,
        version: version || settings.version
      });
    }
    if (settings.f9) {
      await loadScript("https://app.five9.com/dev/sdk/crm/latest/five9.crm.sdk.js");
    }
    ReactDOM.render(<Web/>, document.getElementById("app"));
    loadGoogleMaps(settings.placesApiKey).catch(console.error);
  } else {
    ReactDOM.render(<ConfirmDialog
      open={true}
      showActions={false}
      onConfirm={() => undefined}
      title="OOPS!"
      subTitle={"Sorry we are not supporting this browser. Please try with Google Chrome."}
      closeIcon={false}
      content={""}
    />, document.getElementById("app"));

  }
}
window.DateTime = DateTime;
main().catch(console.error);

function registerSW(url) {
  window.addEventListener("load", async () => {
    try {
      const registration = await navigator.serviceWorker.register(url.toString());
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log(
                "New Service Worker is available and will be used when all " +
                "tabs for this page are closed."
              );
            } else {
              console.log("Service worker is registered.");
            }
          }
        };
      };
    } catch (e) {
      console.error("Error on reg SW", e);
    }
  });
}
async function datadogSetup(settings) {
  // @ts-ignore
  await import("@datadog/browser-rum" as any).then(({ datadogRum }) => {
    datadogRum.init(settings);
  });
  // @ts-ignore
  await import("@datadog/browser-logs" as any).then(({ datadogLogs }) => {
    datadogLogs.init({
      clientToken: settings.clientToken,
      site: settings.site,
      forwardErrorsToLogs: true,
      env: settings.env,
      version: version || settings.version
    });
  });

  // datadogRum.startSessionReplayRecording();
}
