import React                from "react";
import { FC }               from "react";
import { useReactiveVar }   from "@apollo/client";
import { Stack }            from "@relcu/rc";
import { IconButton }       from "@relcu/rc";
import { Icon }             from "@relcu/rc";
import { Typography }       from "@relcu/rc";
import { Page }             from "@relcu/rc";
import { Toolbar }          from "@relcu/rc";
import { Header }           from "@relcu/rc";
import { fullScreenVar }     from "../../../../../reactiveVars";
import { ChipsSizes }       from "@relcu/ui";
import { ChipsColors }      from "@relcu/ui";
import { Chips }            from "@relcu/ui";
import { ScopeConnectionRC } from "../../../../ScopeConnectionRC";
import "./header.css";

export interface HeaderProps {
  expand?: boolean
  scope?: {
    objectName?: string
    objectId?: string
    id?: string
  }
  subject?: string
  onToggle?()
  mailId?: string,
  onRemove?(id),
  handleClick?(item)
  toggleAll?(boolean)
  allToggled?: boolean
  isUnsubscribed?: boolean
}
export const ThreadHeader: FC<HeaderProps> = React.memo(function ThreadHeader(props) {
  const { expand, isUnsubscribed, toggleAll, allToggled, onToggle, handleClick, onRemove, subject, scope, mailId } = props;
  const isFullScreen = useReactiveVar(fullScreenVar);

  return <Header>
    <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
             style={{ padding: `0  16px 0 ${expand ? "16px" : "8px"}`, height: "56px", verticalAlign: "center" }}>
      {!expand && <Page.Toggle size={"md"} expand={expand} onToggle={onToggle}/>}
      <Stack style={{ width: "100%" }} justifyContent={"space-between"}>
        <Stack.Item>
          <Stack spacing={16}>
            <Stack.Item>
              <Typography style={{
              // wordBreak: "break-word",
              // // whiteSpace: "nowrap",
              // overflow:"hidden",
              // textOverflow: "ellipsis"
            }} weights={"medium"} variant={"base16"}>{subject}</Typography>
            </Stack.Item>
            {isUnsubscribed && <Stack.Item>
              <Chips
                color={ChipsColors.Grey}
                label={"Contact opted out from this email"}
                length={33}
                size={ChipsSizes.Medium}
              />
            </Stack.Item>}
          </Stack>
        </Stack.Item>
        <Stack.Item shrink={1}>
          <Stack spacing={16}>
            <ScopeConnectionRC
              typename={"MailConversation"}
              scope={scope}
              onClick={handleClick}
              onRemove={() => onRemove(mailId)}/>
            <IconButton onClick={() => toggleAll(!allToggled)} circle appearance={"subtle"}
                        icon={<Icon className={"header-icon"}
                                    type={!allToggled ? "keyboard_arrow_down1" : "keyboard_arrow_up1"}/>}/>
            <IconButton
              circle
              appearance={"subtle"}
              icon={<Icon className={"header-icon"} type={isFullScreen ? "close_fullscreen" : "open_in_full"}/>}
              onClick={() => fullScreenVar(!isFullScreen)}/>
          </Stack>
        </Stack.Item>
      </Stack>
    </Toolbar>
  </Header>;
});
