import { classNames as clsNames } from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import React                      from "react";
import { FC }                     from "react";
import { OfferClasses }           from "./OfferClasses";
import "./offer.css";

export const Offer: FC<any> = React.memo(React.forwardRef(function Offer(props, ref: React.Ref<HTMLDivElement>) {
  return <Box container direction={"column"} className={OfferClasses.Offer} ref={ref} {...props}/>;
}));

export const OfferTitle: FC<BoxComponentProps> = React.memo(function OfferTitle(props) {
  return <Box container className={OfferClasses.OfferTitle} {...props}/>;
});
export interface OfferHeaderProps extends BoxComponentProps {
  revers?: boolean
}
export const OfferHeader: FC<OfferHeaderProps> = React.memo(function OfferHeader(props) {
  const { revers, ...p } = props;
  return <Box container direction={"column"} alignItems={revers ? "end" : "start"}
              className={OfferClasses.OfferHeader} {...p}/>;
});

export const OfferBody: FC<OfferHeaderProps> = React.memo(function OfferBody(props) {
  const { revers, ...p } = props;
  return <Box container flex={1} gap={"XXS"} direction={"column"} alignItems={revers ? "end" : "start"}
              className={OfferClasses.OfferBody} {...p}/>;
});

export const OfferHeaderRow: FC<{ bold?: boolean } & BoxComponentProps> = React.memo(function OfferHeaderRow(props) {
  const { bold, className, ...p } = props;
  const classNames = clsNames(OfferClasses.OfferHeaderRow, className);
  return <Box container alignItems={"center"} className={classNames} {...p}/>;
});
