import { FC }                   from "react";
import React                    from "react";
import { Box }                  from "../../..";
import { classNames }           from "../../..";
import { ListItemClasses }      from "..";
import { FontIcon }             from "../../..";
import { BoxComponentProps }    from "../../..";
import { formatNumber }         from "../../../utils/helpers";
import { ListItemTitleClasses } from "../ListItemTitle/ListItemTitle";

export interface ListItemCurrencyProps extends BoxComponentProps {
  currency: string | number,
  label?: string
  precision?: number
  defaultValue?:  string | number,
  variant?: "default" | "bold"
}

export function ListItemCurrency(props: ListItemCurrencyProps) {
  const { className, currency, label, defaultValue = "-", precision, variant = "default", ...p } = props;
  const classes = classNames(ListItemClasses.ListItemText, className);

  if (variant == "bold") {
    return <ListItemBoldCurrency title={label} currency={currency} precision={precision} {...p}/>;
  }
  return <Box container className={classes} gap={"XXXS"} alignItems={"center"} {...p}>
    <FontIcon type="attach_money"/>
    <p>
      {
        (typeof currency != "undefined" && currency != null)
          ? formatNumber(currency, precision ?? 2)
          : defaultValue
      }
    </p>
  </Box>;
}

export interface ListItemBoldCurrencyProps extends BoxComponentProps {
  currency: string | number
  label?: string
  precision?: number
}
export const ListItemBoldCurrency: FC<ListItemBoldCurrencyProps> = React.memo(function ListItemBoldCurrency(props) {
  const { className, currency, label, prefix = "$", ...p } = props;
  const classes = classNames(ListItemTitleClasses.ListItemTitle, className);

  return <Box container className={classes} gap={"XXXS"} alignItems={"center"} {...p}>
    <p>{`$${formatNumber(currency, 2)}`}</p>
  </Box>;
});
