import React                               from "react";
import { useMemo }                         from "react";
import { useState }                        from "react";
import { Stack }                           from "@relcu/rc";
import { Icon }                            from "@relcu/rc";
import { Advice }                          from "@relcu/rc";
import { Typography }                      from "@relcu/rc";
import { TabItem }                         from "@relcu/rc";
import { TabsSwitcher }                    from "@relcu/rc";
import { TabPanel }                        from "@relcu/rc";
import { TabPanelContainer }               from "@relcu/rc";
import { TabContainer }                    from "@relcu/rc";
import { Box }                             from "@relcu/ui";
import { gql, useQuery }                   from "@apollo/client";
import { JsonSectionProps, useSource }     from "@relcu/ui";
import { Section }                         from "@relcu/ui";
import { GetEngagementAnalytics }          from "./__types__/GetEngagementAnalytics";
import { GetEngagementAnalyticsVariables } from "./__types__/GetEngagementAnalytics";
import "./engagement-analytics-view.css";

const ENGAGEMENT_ANALYTICS_QUERY = gql`
  query GetEngagementAnalytics($lead: ID!) {
    engagementAnalytics(where: {
      lead:{
        have:{
          id:{
            equalTo:$lead
          }
        }
      }
    }) {
      edges {
        node {
          id
          callScore
          emailScore
          responseScore
          timeInStatusScore
          statusScore
          smsScore
          totalScore
          smsSummary
          callSummary
          emailSummary
          totalSummary
        }
      }
    }
  }
`;
export const EngagementAnalyticsSection = React.memo<JsonSectionProps>(function EngagementAnalyticsSection(props) {
  const { $object, $viewer } = useSource();
  const lead = $object.objectId;
  const { title, view, editable, ...rest } = props;
  const { data } = useQuery<GetEngagementAnalytics, GetEngagementAnalyticsVariables>(ENGAGEMENT_ANALYTICS_QUERY, {
    variables: { lead }
  });

  const shouldNotRender = useMemo(() => {
    const hasData = data?.engagementAnalytics.edges[ 0 ]?.node;
    const hasTotalSummary = hasData && data.engagementAnalytics.edges[ 0 ].node.totalSummary !== null;
    const hasCallSummary = hasData && data.engagementAnalytics.edges[ 0 ].node.callSummary !== null;
    const hasSmsSummary = hasData && data.engagementAnalytics.edges[ 0 ].node.smsSummary !== null;
    const hasEmailSummary = hasData && data.engagementAnalytics.edges[ 0 ].node.emailSummary !== null;
    return !hasData || !(hasTotalSummary || hasCallSummary || hasSmsSummary || hasEmailSummary);
  }, [data]);

  if (shouldNotRender) {
    return null;
  }

  const getNodeData = (key: string) => data?.engagementAnalytics.edges[ 0 ]?.node?.[ key ];
  const summaries = {
    Total_summary: getNodeData("totalSummary"),
    Call_summary: getNodeData("callSummary"),
    SMS_summary: getNodeData("smsSummary"),
    Email_summary: getNodeData("emailSummary")
  };
  const formattedSummaries = Object.entries(summaries).reduce((acc, [key, value]) => {
    if (value !== null && value !== "") {
      const formattedKey = key.replace(/_/g, " ");
      acc[ formattedKey ] = value;
    }
    return acc;
  }, {});

  const summariesCount = Object.keys(formattedSummaries).length;

  return (
    $viewer.role && (
      <Section
        style={{ minHeight: 220 }}
        view={view}
        title={
          <Stack spacing={4} alignItems={"center"} childrenRenderMode={"clone"}>
            <h2 className="title">
              Communication Summary
            </h2>
            <Advice text={"This content was generated by artificial intelligence. \n" +
              "It may contain errors or inaccuracies based on the \n" +
              "quality of the audio recording."} placement={"autoVertical"}>
              <Icon type={"info"} size={16}/>
            </Advice>
          </Stack>
        }
        editable={editable}>
        {
          summariesCount > 1 ?
            <EngagementAnalyticsTabs formattedSummaries={formattedSummaries}/>
            :
            <EngagementAnalyticsSingleTab formattedSummary={formattedSummaries}/>
        }
      </Section>
    )
  );
});

interface EngagementAnalyticsSingleTab {
  formattedSummary: { [ key: string ]: string };
}

export const EngagementAnalyticsSingleTab = React.memo<EngagementAnalyticsSingleTab>(function EngagementAnalyticsSingleTab(props) {
  const { formattedSummary } = props;
  const summaryKey = Object.keys(formattedSummary)[ 0 ];
  return <div className={"engagement-single-container"}>
    <Typography color={"secondary"} variant={"base14"}>
      {summaryKey}
    </Typography>
    <div className={"engagement-text-container"}>
      {formattedSummary[ summaryKey ]}
    </div>
  </div>;
});

interface EngagementAnalyticsTabs {
  formattedSummaries: { [ key: string ]: string };
}
export const EngagementAnalyticsTabs = React.memo<EngagementAnalyticsTabs>(function EngagementAnalyticsTabs(props) {
  const { formattedSummaries } = props;
  const summaryIndex = Object.keys(formattedSummaries).findIndex(k => k == "Total summary");

  return <TabContainer gap={24} outerState={useState(summaryIndex > -1 ? summaryIndex : 0)} style={{ flex: 1 }}>
    <TabPanelContainer>
      {
        Object.entries(formattedSummaries).map(([key, value],i) => (
          <TabPanel key={i}>
            <Box flex={1} className={"engagement-text-container"}>{formattedSummaries[ key ]}</Box>
          </TabPanel>
        ))
      }
    </TabPanelContainer>
    <TabsSwitcher size={"sm"}>
      {
        Object.entries(formattedSummaries).map(([key, value], index) => (
          <TabItem key={index} active={true}>
            {key}
          </TabItem>
        ))
      }
    </TabsSwitcher>
  </TabContainer>;
});


