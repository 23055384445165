import React, { useContext } from "react";
import { classNames }        from "../..";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";
import { useConstant }       from "./useConstant";
import { TabClasses }        from "./TabClasses";
import { Elements }          from "./TabContainer";
import { TabsState }         from "./TabContainer";

export const usePanelState = () => {
  const [activeIndex] = useContext(TabsState);
  const elements = useContext(Elements);

  const panelIndex = useConstant(() => {
    const currentIndex = elements.panels;
    elements.panels += 1;

    return currentIndex;
  });

  return panelIndex === activeIndex;
};

export interface TabPanelProps extends BoxComponentProps {
  active?: boolean;
}

export function TabPanel(props: TabPanelProps) {
  const { children, className, active, ...p } = props;
  const classes = classNames(TabClasses.TabPanel, className);
  const isActive = usePanelState();

  if (typeof children === "function") {
    return React.cloneElement(children(isActive || active));
  }

  return isActive || active ?
    <Box container className={classes} flex={1} {...p}>{children}</Box> :
    null;
}
