import React                from "react";
import { FC }               from "react";
import { ReactNode }        from "react";
import { CommonClasses }    from "../../theme/classes";
import { Box }              from "../Box";
import { TypographyWeight } from "../Typography";
import { TypographyLine }   from "../Typography";
import { TypographyColor }  from "../Typography";
import { TypographySize }   from "../Typography";
import { Typography }       from "../Typography";

export interface NotificationContentProps {
  content?: string | ReactNode;
  attachments?: any[];
  inApp?: boolean
}
export const NotificationContent: FC<NotificationContentProps> = React.memo(function ({ content, inApp, attachments = [] }) {
  return <Box container direction={"column"} gap={inApp ? "XXXS" : "XXS"}>
    {typeof content == "string" ?
      <Typography weight={inApp && TypographyWeight.Regular}
                  lineHeight={inApp && TypographyLine.LineBody1}
                  color={inApp && TypographyColor.White}
                  size={inApp ? TypographySize.Text : TypographySize.TextSmall} noWrap
                  className={CommonClasses.FlexEllipsis}> {content}</Typography>
      : !!content ? content : null
    }
    {
      !!attachments.length &&
      <Typography size={TypographySize.TextSmall}
                  color={inApp && TypographyColor.White}
                  lineHeight={inApp && TypographyLine.LineBody1}
                  weight={inApp ? TypographyWeight.Medium : TypographyWeight.Bold}
                  noWrap
                  className={CommonClasses.FlexEllipsis}>
        {attachments.join(" ")}
      </Typography>
    }
  </Box>;
});