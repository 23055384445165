import React           from "react";
import { useCallback } from "react";
import { useForm }     from "@relcu/form";
import { OnChange }    from "@relcu/form";

export interface TitleFeeListenerProps {
  names: string[];
  onChange: (name: string, value?, previous?) => void;
}
export const TitleFeeListener = React.memo<TitleFeeListenerProps>(function TitleFeeListener(props) {
  const { names, onChange } = props;
  const form = useForm();

  const handleFeeChange = useCallback((name, value, previous) => {
    if (form.getState().active == name) {
      onChange(name, value, previous);
    }
  }, [form, onChange]);

  return (
    <>
      {names.map(name => {
        return <OnChange key={name} name={name}
                         children={(value, previous) => handleFeeChange(name, value, previous)}/>;
      })}
    </>
  );
});
