import React                 from "react";
import { Box }               from "../../..";
import { ListItemClasses }   from "..";
import { FontIcon }          from "../../..";
import { BoxComponentProps } from "../../..";
import { classNames }        from "../../..";

export interface ListItemPhoneNumberProps extends BoxComponentProps {
  number: string
}

export function ListItemPhoneNumber(props: ListItemPhoneNumberProps) {
  const { className, number, ...p } = props;
  const classes = classNames(ListItemClasses.ListItemText, className);

  return <Box container className={classes} gap={"XXXS"} alignItems={"center"} {...p}>
    <FontIcon type="call"/>
    <Box>
      {number}
    </Box>
  </Box>;
}
