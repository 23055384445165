import React, { ReactNode }      from "react";
import { MutableRefObject }      from "react";
import { SyntheticEvent }        from "react";
import { useCallback }           from "react";
import { useBoxProps }           from "../../..";
import { classNames }            from "../../..";
import { useImperativeState }    from "../../..";
import { Box }                   from "../../..";
import { BoxItemComponentProps } from "../../..";
import { FontIcon }              from "../../..";
import { Label }                 from "../../..";
import { CommonClasses }         from "../../../theme/classes";
import { BaseInputClasses }      from "../BaseInput";
import { BaseInputProps }        from "../BaseInput";
import { MaskInput }             from "../MaskInput";

export enum InputState {
  Success = "success",
  Error = "error",
  Warning = "warning",
}

export enum InputType {
  Text = "text",
  Password = "password",
}

export interface InputProps extends BoxItemComponentProps, BaseInputProps {
  maskAttr?: {
    alwaysShowMask?: boolean,
    mask: string,
    maskPlaceholder?: string,
  },
  value?: number | string;
  autoFocus?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  message?: string;
  icon?: ReactNode;
  type?: "text" | "password" | "time" | string;
  placeholder?: string;
  inputProps?: { [ key: string ]: any; };
  InputProps?: { [ key: string ]: any; };
  leaveStateSpace?: boolean
  onChange?(value, event?: SyntheticEvent);
  onFocus?(value);
  onBlur?(value);
  onKeyDown?(value);
  onKeyPress?(value);
  onKeyUp?(value);
}

export const Input = React.forwardRef((props: InputProps, ref: MutableRefObject<any>) => {
  const {
    className,
    children,
    label,
    value,
    clearable,
    disabled,
    readOnly,
    state,
    required,
    message,
    icon,
    type,
    name,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onFocus,
    maskAttr,
    fullSize,
    halfSize,
    autoFocus,
    InputProps,
    inputProps,
    leaveStateSpace,// todo replace this shit with container query when it will available (make StateContainer display none)
    ...p
  } = props;
  const [stateValue, setStateValue] = useImperativeState(value, onChange);
  const [inputType, setInputType] = React.useState(type);

  const classes = classNames(BaseInputClasses.Input, {
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error,
    [ BaseInputClasses.Warning ]: state == InputState.Warning
  }, className);

  const handleChange = useCallback((e) => {
    setStateValue(e.target.value || null, e);
  }, [onChange]);
  return <Box container className={classes} gap={"XXXS"} direction={"column"} {...useBoxProps(p)} {...InputProps}>
    <Box container
         direction={"row"}
         alignItems={"center"}
         gap={"XXS"}
         className={BaseInputClasses.MainContainer}>
      {icon}
      <Box container direction={"column"} gap={"XXXS"} flex={1} className={BaseInputClasses.InputContainer}>
        {label && <Label required={required}>{label}</Label>}
        {
          maskAttr ?
            <MaskInput
              {...props.maskAttr}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              value={stateValue || ""}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              inputProps={{ ...inputProps, autoFocus }}
              name={name}
              data-test={name}
              onKeyUp={(e) => onKeyUp && onKeyUp(e)}
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
              onKeyPress={(e) => onKeyPress && onKeyPress(e)}
            /> :
            <input
              ref={ref}
              autoFocus={autoFocus}
              type={inputType}
              name={name}
              data-test={name}
              value={stateValue ?? ""}
              disabled={disabled || readOnly}
              readOnly={readOnly}
              placeholder={placeholder || "Insert value"}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyUp={(e) => onKeyUp && onKeyUp(e)}
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
              onKeyPress={(e) => onKeyPress && onKeyPress(e)}
              {...inputProps}
            />
        }
      </Box>
      {
        leaveStateSpace &&
        <Box className={BaseInputClasses.StateContainer} alignSelf={"center"}>
          {(clearable && state == undefined && stateValue) &&
          <FontIcon type={"clear"} onClick={disabled || readOnly ? null : () => setStateValue("")}/>}
          {
            (type == InputType.Password && stateValue) &&
            <FontIcon
              type={inputType == InputType.Text ? "visibility_off" : "remove_red_eye"}
              onClick={() => setInputType(inputType == InputType.Text ? InputType.Password : InputType.Text)}
              className={CommonClasses.GrayIcon} style={{ marginRight: "8px" }}
            />
          }
          {(state == InputState.Success || state == InputState.Error || state == InputState.Warning) &&
          <FontIcon type={state == InputState.Success ? "done" : "warning"}/>}
        </Box>
      }
    </Box>
    <Box container className={BaseInputClasses.Message}>
      {message}
    </Box>
  </Box>;
});
const defaultInputProps = {
  type: InputType.Text,
  disabled: false,
  autoFocus: false,
  readOnly: false,
  state: null,
  leaveStateSpace: true
};
Input.defaultProps = defaultInputProps;
