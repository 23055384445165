import React             from "react";
import { FC }            from "react";
import { useSource }     from "@relcu/ui";
import { JsonViewProps } from "@relcu/ui";
import { JqlForm }       from "../../Jql/JqlForm";

export const PreferenceView: FC<JsonViewProps> = React.memo(function PreferenceView(props) {
  const { $viewer, $object } = useSource();
  const { sections, className } = props;
  return <JqlForm
    {...props}
    group={true}
    className={className}
    jql={props.jql}
    sections={sections}
    initialValues={$object}
    user={$viewer}
  />
  // return (
  //   <>
  //     {sections.map((section, index) => {
  //       let Component = Sections[ section.component ];
  //       if (!Component) {
  //         return null;
  //       }
  //       return (
  //         <Where key={index} conditions={section.conditions}>
  //           <SectionContext.Provider value={section}>
  //             <Section
  //               title={section.title}
  //               key={index}>
  //               <Component/>
  //             </Section>
  //           </SectionContext.Provider>
  //         </Where>
  //       );
  //     })}
  //   </>
  // );
});
export default PreferenceView;
