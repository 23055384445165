import React                   from "react";
import { FC }                  from "react";
import { useMemo }             from "react";
import { SyntheticEvent }      from "react";
import { ReactNode }           from "react";
import { CommonClasses }       from "../../../../theme/classes";
import { classNames }          from "../../../../utils/classNames";
import { BoxComponentProps }   from "../../../Box";
import { Box }                 from "../../../Box";
import { ChipsVariants }       from "../../../Chips";
import { Chips }               from "../../../Chips";
import { ChipsColors }         from "../../../Chips";
import { ItemsEllipsis }       from "../../../Ellipsis";
import { FontIcon }            from "../../../Icon";
import { Label }               from "../../../Label";
import { BaseInputClasses }    from "../../BaseInput";
import { InputState }          from "../../Input";
import { getItemField }        from "../../Select/helpers";
import { getItemLabel }        from "../../Select/helpers";
import { MultiSelectVariants } from "../MultiSelect";
import { MultiSelectClasses }  from "../MultiSelect";

export interface MultiSelectToggleProps extends BoxComponentProps {
  renderSelect?: (option) => ReactNode | string;
  renderHeader?: (option) => ReactNode | string;
  variant?: MultiSelectVariants,
  options: any[];
  items: any[];
  message?: string;
  showSelectedCount?: boolean;
  required?: boolean;
  readOnly?: boolean;
  optionLabel?: string;
  optionKey?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  opened?: boolean;
  keepErrorSpace?: boolean;
  showBorder?: boolean;
  icon?: (item) => any;
  handleDeleteItem?(item: any);
  togglePopper(event: SyntheticEvent);
}

export const MultiSelectToggle = React.forwardRef(function MultiSelectToggle(props: MultiSelectToggleProps, ref: React.Ref<HTMLDivElement>) {
  const {
    renderSelect,
    renderHeader,
    items,
    message,
    showSelectedCount,
    placeholder,
    optionLabel,
    optionKey,
    handleDeleteItem,
    options,
    disabled,
    togglePopper,
    icon,
    label,
    required,
    readOnly,
    opened,
    style,
    showBorder,
    variant,
    keepErrorSpace
  } = props;

  const haveSubMenu = useMemo(() => options.find(option => option.options), [options]);

  function showValueAsCount() {
    let optionsLength = options.length;
    if (haveSubMenu) {
      optionsLength = 0;
      options.forEach(option => optionsLength += option.options.length);
    }
    return <p
      className={BaseInputClasses.MultiSelectPlaceHolder}>{items.length == optionsLength ? "All selected" : `${items.length} selected`}</p>;
  }

  function showValueAsEllipsis() {
    return <ItemsEllipsis
      showLength={3}
      items={items}
      renderItem={(item, index) => {
        const color = typeof item == "object"
          ? item.state == InputState.Error
            ? ChipsColors.Error : item.state == InputState.Success
              ? ChipsColors.Success : ChipsColors.Grey
          : ChipsColors.Grey;
        const opt = options.find(o => getItemField(o, optionKey) == getItemField(item, optionKey));
        const label = opt ?? item;
        return <Chips
          key={index}
          color={color}
          label={(label && getItemLabel(label, optionLabel)) || placeholder}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDelete={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteItem(item);
          }}
          length={25}
        />;
      }}
      ellipsis={(count, extended) => <Chips
        thumbnail={extended && <FontIcon type={"keyboard_arrow_left"}/>}
        color={ChipsColors.Grey}
        variant={ChipsVariants.Outline}
        label={!extended && `+${count}`}
        disabled={false}/>}
    />;
  }

  function showValue() {
    let renderValue;
    if (renderSelect) {
      renderValue = renderSelect(items);
    } else if (!showSelectedCount) {
      renderValue = showValueAsEllipsis();
    } else {
      renderValue = showValueAsCount();
    }
    return items.length ? renderValue :
      <p className={CommonClasses.TextSecondary}>{(placeholder || "Select value")}</p>;
  }

  return <>
    <Box container
         role={"button"}
         direction={"row"}
         gap={"XXS"}
         alignItems={"center"}
         alignSelf={"stretch"}
         ref={ref}
         onClick={disabled ? null : togglePopper}
         className={classNames(BaseInputClasses.MainContainer, {
           [ MultiSelectClasses.HideBorder ]: !showBorder,
           [ MultiSelectClasses.MultiSelectGhost ]: variant == MultiSelectVariants.Ghost
         })}
         style={style}>
      {
        renderHeader ?
          renderHeader(items) :
          <>
            {
              icon
            }
            <Box container direction={"column"} gap={"XXXS"} flex={1}
                 className={BaseInputClasses.InputContainer}>
              {
                label &&
                <Label required={required}>
                  {
                    label
                  }
                </Label>
              }
              <Box container wrap={"wrap"} gap={"XXXS"} className={MultiSelectClasses.MultiSelectContainer}
                   alignItems={"center"}>
                {showValue()}
              </Box>
            </Box>
            <Box container className={BaseInputClasses.StateContainer} gap={"XXS"}>
              {
                !readOnly &&
                <FontIcon className={CommonClasses.ClickableIcon}
                          type={opened ? "keyboard_arrow_down" : "keyboard_arrow_up"}/>
              }
            </Box>
          </>
      }

    </Box>
    {
      (keepErrorSpace || message) &&
      <Box container className={BaseInputClasses.Message}>
        {message}
      </Box>
    }
  </>;
});

MultiSelectToggle.defaultProps = {
  direction: "column",
  showBorder: true,
  keepErrorSpace: true
};
