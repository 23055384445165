import { getIn }                from "@relcu/form";
import { FormApi }              from "@relcu/form";
import { setIn }                from "@relcu/form";
import { FORM_ERROR }           from "@relcu/form";
import { useLocation }          from "@relcu/react-router";
import { useNavigate }          from "@relcu/react-router";
import { omit }                 from "@relcu/ui";
import { useRef }               from "react";
import { useContext }           from "react";
import { useState }             from "react";
import { useCallback }          from "react";
import { schemaVar }            from "../../../../../../reactiveVars";
import { transformFields }      from "../../../../../../utils/graphQlUtils";
import { useJqlMutation }       from "../../../../Jql";
import { ContactDialogContext } from "../../ContactDialog";

export function useResolveDuplicates() {
  const { setResolveDuplicationData, setResolveDuplicationErrors, afterSubmit, jql } = useContext(ContactDialogContext);
  const [create] = useJqlMutation(jql.mutation.create, { operationName: "CreateContactDuplicateResolution" });
  const [update] = useJqlMutation(jql.mutation.update, { operationName: "UpdateContactDuplicateResolution" });
  const modifiedExistingRef = useRef(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();

  const handleMerge = useCallback((form: FormApi<any>) => {
    form.reset();
    const formState = form.getState();
    setResolveDuplicationErrors(formState.errors);
    setResolveDuplicationData(formState.values);
    navigate(`${pathname}${search.toString()}`, { state: { ...state, form: "MergeForm" } });
  }, [state]);

  async function handleResolveSubmit(values, form: FormApi<any>) {
    const { exists, temporary } = values;
    const { initialValues: { initialExists, initialTemporary } } = form.getState();
    const schemas = schemaVar();
    const formErrors = validateContactInfoExists(values);
    if (formErrors) {
      return { [ FORM_ERROR ]: formErrors };
    }
    const exclude = ["id", "objectId", "objectIcon", "objectName", "ACL"];
    try {
      if (modifiedExistingRef.current) {
        await update({
          variables: {
            input: {
              id: exists.id,
              fields: omit(transformFields(exists, initialExists, "Contact", schemas), exclude)
            }
          }
        });
      }

      if (temporary.id) {
        const { data: { updateContact: { contact: currentContact } } } = await update({
          variables: {
            input: {
              id: temporary.id,
              fields: omit(transformFields(temporary, initialTemporary, "Contact", schemas), exclude)
            }
          }
        });
        afterSubmit?.(currentContact, form);
      } else {
        const { data: { createContact: { contact: currentContact } } } = await create({
          variables: {
            input: {
              fields: omit(transformFields(temporary, initialTemporary, "Contact", schemas), exclude)
            }
          }
        });
        afterSubmit?.(currentContact, form);
      }

      modifiedExistingRef.current = false;
    } catch (e) {
      console.error(e.message);
      return e.message || "Ops something went wrong.";
    }
  }

  return {
    handleResolveSubmit,
    modifiedExistingRef,
    setShowMore,
    handleMerge,
    validate,
    showMore
  };
}

export function validate(values) {
  let errors: Object = {};

  values.temporary?.phones?.forEach(p => {
    const duplicateIndexes = checkExistsObject(values?.exists?.phones, p, "number");
    if (duplicateIndexes.length) {
      duplicateIndexes.forEach((i) => errors = setIn(errors, `exists.phones[${i}]`, { duplicate: true }));
    }
  });

  values?.exists?.phones?.forEach(p => {
    const duplicateIndexes = checkExistsObject(values?.temporary?.phones, p, "number");
    if (duplicateIndexes.length) {
      duplicateIndexes.forEach(i => errors = setIn(errors, `temporary.phones[${i}]`, { duplicate: true }));
    }
  });

  values.temporary?.emails?.forEach(p => {
    const duplicateIndexes = checkExistsObject(values?.exists?.emails, p, "address");
    if (duplicateIndexes.length) {
      duplicateIndexes.forEach(i => errors = setIn(errors, `exists.emails[${i}]`, { duplicate: true }));
    }
  });

  values?.exists?.emails?.forEach(p => {
    const duplicateIndexes = checkExistsObject(values?.temporary?.emails, p, "address");
    if (duplicateIndexes.length) {
      duplicateIndexes.forEach(i => errors = setIn(errors, `temporary.emails[${i}]`, { duplicate: true }));
    }
  });
  return errors;
}
function validateContactInfoExists(value) {
  const temporaryEmails = getIn(value, "temporary.emails") || [];
  const existsEmails = getIn(value, "exists.emails") || [];
  const existsPhones = getIn(value, "exists.phones") || [];
  const temporaryPhones = getIn(value, "temporary.phones") || [];
  if ((temporaryEmails.find(e => !e.address) || temporaryEmails.length == 0) && (temporaryPhones.find(e => !e.number) || temporaryPhones.length == 0)) {
    return "Please provide at least one contact information for each contact.";
  }

  if ((existsEmails.find(e => !e.address) || existsEmails.length == 0) && (existsPhones.find(e => !e.number) || existsPhones.length == 0)) {
    return "Please provide at least one contact information for each contact.";
  }
  return undefined;
}
function checkExistsObject(arr = [], obj = {}, key) {
  const duplicateIndexes = [];
  arr.forEach((item, index) => {
    if (item[ key ] && (item[ key ] == obj[ key ])) {
      duplicateIndexes.push(index);
    }
  });

  return duplicateIndexes;
}
