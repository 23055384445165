import React                 from "react";
import { FC }                from "react";
import { classNames }        from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import { Box }               from "@relcu/ui";
import "../contact-dialog.css";

export interface ReadFieldProps extends BoxComponentProps {
  selected?: boolean
}

export const ReadField: FC<ReadFieldProps> = React.memo(function ReadField(props) {
  const { className, selected, ...p } = props;
  const classes = classNames("merge-field", {
    "merge-field_selected": selected
  }, className);
  return <Box container flex={1} gap={"XXS"} align-items={"center"} className={classes} {...p}/>;
});
