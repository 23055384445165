import React                     from "react";
import { FC }                    from "react";
import { useMemo }               from "react";
import { useState }              from "react";
import { MultiSelect }           from "@relcu/ui";
import { NumberField }           from "./NumberField";
import { SwitchDatePickerField } from "./SwitchDatePickerField";

export interface StatusFieldProps {
  field: string;
  operator: string;
  value: any;
  schema: any;
  onChange(data: any);
}

export const StatusField: FC<StatusFieldProps> = React.memo(function StatusField(props) {
  const { field, operator, value, schema, onChange } = props;
  const [selectedFilter, setSelectedFilter] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  if (field == "leadStatus.updatedAt") {
    return <SwitchDatePickerField flex={"0 1 32%"}
                                  value={value}
                                  type={[">","<","greaterThan","lessThan"].includes(operator) ? "datetime" : (["during", "notDuring"].includes(operator) ? "calendar" : "date" )}
                                  onChange={(date) => onChange(date)}/>;
  }
  if (["leadStatus.actionCount","leadStatus.currentStatusActionCount","leadStatus.currentStageActionCount", ].includes(field)) {
    return <NumberField flex={"0 1 32%"} value={isNaN(value) ? null : value}
                        onChange={onChange}/>;
  }
  let availableActions = [];

  if (field == "leadStatus.action") {
    availableActions = Object.keys(schema.actions)?.map((state) => ({ value: state, label: schema.actions[ state ].name }));
  } else {
    availableActions = Object.keys(schema.states)?.map((state) => ({ value: state, label: schema.states[ state ].status }));
  }

  function options() {
    if (selectedIds.length) {
      return availableActions.filter((action) => selectedIds.includes(action.value));
    }

    return availableActions;
  }

  return <MultiSelect
    selectedFilter={selectedFilter}
    onSelectedFilter={(selectedFilter) => {
      setSelectedFilter(selectedFilter);
      setSelectedIds(selectedFilter ? value : []);
    }}
    showSelectedCount
    showSelectionActions
    getOptionValueByKey
    value={value || []}
    options={options()}
    direction={"column"}
    style={{ padding: "0 8px" }}
    flex={"0 1 32%"}
    placeholder={"Select value"}
    onChange={onChange}
  />;
});
