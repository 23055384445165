import { useState }                          from "react";
import { useEffect }                         from "react";
import { gql }                               from "@apollo/client";
import { useQuery }                          from "@apollo/client";
import { useSource }                         from "@relcu/ui";
import { MAILBOX_FRAGMENT }                  from "../../../../graph/operations.graphql";
import { SubscriptionEvent }                 from "../../../../types/graphql-global-types";
import { GetMailDomains }                    from "../../../__types__/GetMailDomains";
import { GET_MAIL_DOMAINS }                  from "../../../operations.graphql";
import { GetViewerMailBoxesVariables }       from "./__types__/GetViewerMailBoxes";
import { GetViewerMailBoxes }                from "./__types__/GetViewerMailBoxes";
import { SubscribeViewerMailBoxesVariables } from "./__types__/SubscribeViewerMailBoxes";
import { SubscribeViewerMailBoxes }          from "./__types__/SubscribeViewerMailBoxes";

export function useMailAccountsSection() {
  const [error, setError] = useState<string>(null);
  const { $viewer } = useSource();
  const { data: { mailDomains: { edges: domains = [] } = {} } = {} } = useQuery<GetMailDomains>(GET_MAIL_DOMAINS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const { data: { mailboxes: { edges: boxes = [] } = {} } = {}, subscribeToMore } = useQuery<GetViewerMailBoxes, GetViewerMailBoxesVariables>(GET_VIEWER_MAILBOXES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      where: {
        assignedTo: {
          have: {
            link: $viewer.id
          }
        }
      }
    }
  });
  const api = {
    boxes,
    error,
    setError,
    get isEmpty() {
      const domainsWithProvider = domains.filter(({ node }) => {
        return node.provider.type != "none";
      });
      return !domainsWithProvider.length && !boxes.length;
    },
    get domains() {
      return domains.filter(({ node: domain }) => {
        const accounts = boxes.filter(({ node }) => node.domain.id == domain.id);
        return domain.provider.type != "none" && (!accounts || (accounts && !accounts.length) || !accounts.find(({ node }) => node.auth));
      });
    },
    subscribeToMore() {
      return subscribeToMore<SubscribeViewerMailBoxes, SubscribeViewerMailBoxesVariables>({
        document: SUBSCRIBE_VIEWER_MAILBOXES,
        variables: {
          where: {
            assignedTo: {
              have: {
                link: $viewer.id
              }
            }
          }
        },
        updateQuery(previousQueryResult, { subscriptionData: { data: { mailboxes: { event, node } } } }) {
          switch (event) {
            case SubscriptionEvent.ENTER:
            case SubscriptionEvent.CREATE:
              return {
                ...previousQueryResult,
                mailboxes: {
                  ...previousQueryResult.mailboxes,
                  edges: [
                    {
                      node,
                      __typename: "MailboxEdge"
                    },
                    ...previousQueryResult.mailboxes.edges
                  ]
                }
              };
            case SubscriptionEvent.UPDATE:
              return {
                ...previousQueryResult,
                mailboxes: {
                  ...previousQueryResult.mailboxes,
                  edges: previousQueryResult.mailboxes?.edges.map(edge => {
                    if (edge.node.id !== node.id) {
                      return edge;
                    } else {
                      return { __typename: "MailboxEdge", node };
                    }
                  })
                }
              };
            case SubscriptionEvent.LEAVE:
              return {
                ...previousQueryResult,
                mailboxes: {
                  ...previousQueryResult.mailboxes,
                  edges: previousQueryResult.mailboxes?.edges.filter(edge => edge.node.id !== node.id)
                }
              };
          }
        }
      });
    }
  };
  useEffect(api.subscribeToMore, [$viewer.id]);
  return api;
}

export const GET_VIEWER_MAILBOXES = gql`
  query GetViewerMailBoxes($where: MailboxWhereInput) {
    mailboxes(where: $where) {
      edges {
        node {
          ...Mailbox
        }
      }
    }
  }
  ${MAILBOX_FRAGMENT}
`;

export const SUBSCRIBE_VIEWER_MAILBOXES = gql`
  subscription SubscribeViewerMailBoxes($where: MailboxSubscriptionWhereInput) {
    mailboxes(where: $where, events: [CREATE, ENTER,LEAVE, UPDATE]) {
      event
      node{
        ...Mailbox
      }
    }
  }
  ${MAILBOX_FRAGMENT}
`;
