import React                   from "react";
import { classNames }          from "../../..";
import { Alignment }           from "../../../constants/Alignemnt";
import { GlobalClasses }       from "../../../constants/GlobalClasses";
import { Box }                 from "../../Box";
import { BoxComponentProps }   from "../../Box";
import { EllipsisWithTooltip } from "../../EllipsisTooltip/EllipsisWithTooltip";

export interface ListItemMultiTextProps extends BoxComponentProps {
  label?: string
}

export function ListItemMultiText(props: ListItemMultiTextProps) {
  const { className, children, label, ...p } = props;
  const classes = classNames(ListItemMultiTextClasses.ListItemMultiText, className);

  return <Box className={classes} {...p}>
    <EllipsisWithTooltip value={children} alignment={Alignment.Top}>
      <p className={GlobalClasses.EllipsisFlex}>{children}</p>
    </EllipsisWithTooltip>
  </Box>;
}
export enum ListItemMultiTextClasses {
  ListItemMultiText = "list-item-multi-text"
}
