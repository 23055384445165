import { BackDropLoader }  from "@relcu/ui";
import { TypographyColor } from "@relcu/ui";
import { Typography }      from "@relcu/ui";
import { permissionUtils } from "@relcu/ui";
import React               from "react";
import { FC }              from "react";
import { useMemo }         from "react";
import { useQuery }        from "@apollo/client";
import { useField }        from "@relcu/form";
import { useSource }       from "@relcu/ui";
import { VerticalDivider } from "@relcu/ui";
import { StepInterface }   from "@relcu/ui";
import { StepBody }        from "@relcu/ui";
import { StepContainer }   from "@relcu/ui";
import { StepHeader }      from "@relcu/ui";
import { applySmoothDrag } from "../../../../../../utils/helpers";
import { AllUsers }        from "../../../../../__types__/AllUsers";
import { ALL_USERS }       from "../../../../../operations.graphql";
import { ColumnsClasses }  from "../Columns";
import { ShareColumn }     from "./ShareColumn";

export const Share: FC<StepInterface> = React.memo(function Share(props) {
  const { title, permission } = props;
  const { $viewer } = useSource();
  const { data: { users: { edges = [] } = {} } = {}, loading } = useQuery<AllUsers>(ALL_USERS);
  const { input: { value: rPerm = [], onChange }, meta } = useField("rPerm");
  const data = useMemo(() => {
    return edges.map(({ node }) => node);
  }, [edges]);
  const available = useMemo(() =>
      data?.filter(i =>
        permissionUtils.hasAccess(i, permission, "read")
        && !i.deactivated
        && i.objectId !== $viewer.objectId
        && !rPerm.some(s => s == i.objectId))
    , [data, rPerm]);

  function selectItem(arr, drop) {
    if (drop.payload) {
      const result = applySmoothDrag(arr, drop);
      onChange(result.map(r => r.objectId));
    } else {
      onChange(rPerm.filter(i => i != drop.objectId));
    }
  }
  function deSelect(arr, drop) {
    if (drop.payload) {
      onChange(rPerm.filter(i => i != drop.payload.objectId));
    } else if (drop.objectId) {
      onChange([drop.objectId, ...rPerm]);
    }

  }
  function selectAll() {
    const ids = available.map(item => item.objectId);

    onChange([...ids, ...rPerm]);
  }
  function deSelectAll() {
    onChange([]);
  }
  const selected = useMemo(() => {
    return data.length && rPerm.map(id => {
      return data.find(item => item.objectId === id);
    }) || [];
  }, [rPerm, data]);

  return (
    <StepContainer style={{ overflow: "hidden" }}>
      {
        title &&
        <StepHeader title={title}/>
      }
      {
        !!meta.submitError && !meta.dirtySinceLastSubmit &&
        <Typography color={TypographyColor.Error} style={{ paddingLeft: "var(--layout-box-gap-l)" }}>
          {meta.submitError}
        </Typography>
      }
      <StepBody className={ColumnsClasses.Column} style={{ position: "relative" }} direction={"row"} flex={1} gap={"XS"}
                alignSelf={"stretch"}>
        {
          loading &&
          <BackDropLoader/>
        }
        <ShareColumn
          emptyTitle={"No available users."}
          emptyDescription={`Unselect from "Selected users"`}
          items={available}
          title={"Available users"}
          columnName={"available"}
          onChange={(drop) => deSelect(available, drop)}
          button={"ADD ALL"}
          buttonClick={selectAll}
        />
        <VerticalDivider style={{ marginTop: 40, height: "calc(100% - 40px)" }}/>
        <ShareColumn
          emptyTitle={"No selected users."}
          emptyDescription={`Select
                             from “Available users” or this view will be available only for you.`}
          items={selected}
          title={"Selected users"}
          columnName={"selected"}
          onChange={(drop) => selectItem(selected, drop)}
          button={"REMOVE ALL"}
          buttonClick={deSelectAll}
        />
      </StepBody>
    </StepContainer>
  );
});


