import { useConstant }                 from "@relcu/ui";
import { useMemo }                     from "react";
import React                           from "react";
import { FC }                          from "react";
import { useContext }                  from "react";
import { EmptyState }                  from "@relcu/rc";
import { PushDistributionItem }        from "@relcu/rc";
import { CircularLoader }              from "@relcu/ui";
import { BackDropLoader }              from "@relcu/ui";
import { dividedDataByFixedDate }      from "../../../../utils/helpers";
import { toFirstUpper }                from "../../../../utils/helpers";
import { GetAllNotifications }         from "../../../__types__/GetAllNotifications";
import { SUBSCRIBE_ALL_NOTIFICATIONS } from "../../../operations.graphql";
import { GET_ALL_NOTIFICATIONS }       from "../../../operations.graphql";
import { RelayQuery }                  from "../../../Relay";
import { useNotification }             from "../../InAppNotification/useNotification";
import { TimeStepLabels }              from "../../Notifications/NotificationBar";
import { NotificationMention }         from "../../Notifications/NotificationMention";
import { NotificationTimeSection }     from "../../Notifications/NotificationTimeSection";
import { UnreadNotificationContext }   from "../../UnreadNotificationProvider";

export const AllPushNotifications: FC<any> = React.memo(function AllPushNotifications(props) {
  const { lastNotificationSeenAt } = useContext(UnreadNotificationContext);
  const lastSeenAt = useConstant(() => lastNotificationSeenAt);
  const { getRecord, onNavigate } = useNotification();

  const variables = { type: { in: ["distributed_lead"] } };
  return <RelayQuery<GetAllNotifications>
    query={{
      document: GET_ALL_NOTIFICATIONS,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables
    }}
    subscription={{
      document: SUBSCRIBE_ALL_NOTIFICATIONS,
      variables
    }}
    className={"Notification"}
    render={({ scrollContainerRef, afterLoaderRef, loading, data: { notifications: { edges = [], pageInfo } } }) => {
      const dividedData = useMemo(() => dividedDataByFixedDate(edges, null, lastSeenAt), [lastSeenAt, edges]);
      const dateSteps = Object.entries(dividedData).filter(dateStep => dateStep[ 1 ].length);
      return (
        <>
          <NotificationMention scrollContainerRef={scrollContainerRef}/>
          <div
            className={"notification-bar-all-notes"}
            ref={scrollContainerRef}>
            {
              loading && <BackDropLoader style={{ zIndex: 1 }}/>
            }
            {
              !!dateSteps.length ?
                <>
                  {
                    dateSteps.map((dateStep, index) => {
                      return <NotificationTimeSection key={index} title={TimeStepLabels[ dateStep[ 0 ] ]}>
                        {
                          dateStep[ 1 ].map(({ node }, index) => {
                              const record = getRecord(node);
                              return <PushDistributionItem
                                key={index}
                                date={node.createdAt}
                                type={toFirstUpper(node?.record?.objectName.toLowerCase())}
                                name={node.scope.objectName}
                                unread={node.unread}
                                onClick={() => onNavigate(record)}
                              />;
                            }
                          )
                        }
                      </NotificationTimeSection>;
                    })
                  }
                  {
                    pageInfo?.hasNextPage && <CircularLoader alignSelf={"center"} ref={afterLoaderRef}/>
                  }
                </>
                :
                <EmptyState
                  compact
                  icon={"rc_lead"}
                  title={"No leads assigned to you"}
                  subtitle={"You will be notified about new assignments"}
                />
            }
          </div>
        </>
      );
    }}
  />;
});
