import React                       from "react";
import { FC }                      from "react";
import { classNames }              from "../../../../utils/classNames";
import { AvatarSizes }             from "../../../Avatar";
import { Avatar }                  from "../../../Avatar";
import { Box }                     from "../../../Box";
import { getItemLabel }            from "../../../Input/Select/helpers";
import { MenuItem }                from "../../../Menu";
import { EmailMultiSelectClasses } from "./EmailMultiSelectField";

interface EmailOptionProps {
  label: string,
  optionLabel: string,
  value: string,
  type: string,
  objectName: string
  objectIcon: string
  optOut: boolean
  active: boolean
}

export const EmailOption: FC<EmailOptionProps> = React.memo(React.forwardRef(function  EmailOption(props, ref) {
  const { label, value, objectName, objectIcon, active, optOut, optionLabel, ...p } = props;
  const additionalLabel = getItemLabel(props, optionLabel);

  return <MenuItem container gap={"XXS"} {...p} ref={ref}>
    <Box container gap={"XXS"}>
      <Avatar icon={objectIcon} text={objectName ?? value} size={AvatarSizes.Small}/>
      <Box container direction={"column"}>
        <p
          className={classNames(EmailMultiSelectClasses.Title, { [ EmailMultiSelectClasses.Active ]: !!active })}>
          {label}
        </p>
        {
          additionalLabel &&
          <p className={EmailMultiSelectClasses.Addition}>{additionalLabel}</p>
        }
      </Box>
    </Box>
  </MenuItem>;
}));

EmailOption.defaultProps = {
  optionLabel: "label"
};