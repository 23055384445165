import React                                              from "react";
import { Box }                                            from "@relcu/ui";
import { TypographyLine }                                 from "@relcu/ui";
import { TimelineItemBody }                               from "@relcu/ui";
import { TimelineItem }                                   from "@relcu/ui";
import { TypographyColor }                                from "@relcu/ui";
import { Typography }                                     from "@relcu/ui";
import { TimelineItemSubject }                            from "@relcu/ui";
import { TimelineItemIconColors }                         from "@relcu/ui";
import { TimelineItemIcon }                               from "@relcu/ui";
import { WorkflowActivity as WorkflowActivityType  } from "../../../../__types__/WorkflowActivity";


export interface WorkflowActivityProps extends WorkflowActivityType {
  createdAt: string;
}

export const WorkflowActivity = React.memo<WorkflowActivityProps>(function WorkflowActivity(props) {
  const { title, message, createdAt, activityCreatedBy: createdBy } = props;

  const getExpandedContent = (text) => text ? (
    <Box container direction={"column"} gap={"XS"}>
      <Box container gap={"XXS"} flexGrow={1}>
        <TimelineItemBody>
          <Typography lineHeight={TypographyLine.Line17}>
            {text}
          </Typography>
        </TimelineItemBody>
      </Box>
    </Box>
  ) : null;
  return (
  <TimelineItem
    primary={<TimelineItemSubject>
      <Typography>{createdBy?.objectName ?? "Unknown"}</Typography>
      <Typography color={TypographyColor.Secondary}>{"initiated"}</Typography>
      <Typography>{title}</Typography>
    </TimelineItemSubject>}
    creationDate={createdAt}
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Warning} icon={"rc_workflow" }/>}
  >
    { getExpandedContent(message) }
    
  </TimelineItem>);
});
