import React                  from "react";
import { Button }             from "@relcu/ui";
import { ButtonColors }       from "@relcu/ui";
import { ButtonVariants }     from "@relcu/ui";
import { MicrosoftLogo }      from "@relcu/ui";
import { MailDomain }         from "../../__types__/MailDomain";
import { useMsConnectButton } from "./useMsConnectButton";

export interface MsLoginButtonProps {
  deviceId: string;
  scope?: string[];
  disabled?: boolean;
  onSuccess({ token });
  onError(error);
}

export const MsLoginButton = React.memo<MsLoginButtonProps>((props) => {
  const config = window.__CONFIG__.outlook;
  const { handleLogin } = useMsConnectButton({
    ...props,
    domain: {
      objectId: config.domainId,
      provider: {
        type: "outlook",
        clientId: config.clientId,
        tenantId: config.tenantId,
        returnUrl: config.returnUrl
      }
    } as MailDomain
  });
  return (
    <Button
      disabled={props.disabled}
      style={{ justifyContent: "center" }}
      variant={ButtonVariants.Outline}
      onClick={handleLogin}
      color={ButtonColors.Primary}
      flexGrow={1}
      icon={<MicrosoftLogo/>}
    >
      LOGIN WITH MICROSOFT
    </Button>
  );
});
