import React                                 from "react";
import { useContext }                        from "react";
import { useCallback }                       from "react";
import { gql }                               from "@apollo/client";
import { useApolloClient }                   from "@apollo/client";
import { OnBlur }                            from "@relcu/form";
import { FormState }                         from "@relcu/form";
import { FormSpy, OnChange }                 from "@relcu/form";
import { useForm }                           from "@relcu/form";
import { useSource }                         from "@relcu/ui";
import { LoanEstimateOffer }                 from "../../../../../../graph/__types__/LoanEstimateOffer";
import { useSchemaField }                    from "../../../../useSchemaField";
import { Proposal }                          from "../../Proposal";
import { LoanEstimateOfferClientDirtyState } from "./__types__/LoanEstimateOfferClientDirtyState";
import { OfferListener }                     from "./OfferListener";
import { useCalculations }                   from "./useCalculations";

export const OfferCalculate = React.memo(() => {
  const { $settings: { pricing: settings } } = useSource();
  const client = useApolloClient();
  const calculateChanges = useCalculations();
  const pmiDownPaymentBoundary = settings.conventional.pmiDownPaymentBoundary;
  const loanEstimate = useContext(Proposal.Context);
  const form = useForm<LoanEstimateOffer>();
  const { defaultValue: sourceDefaultValue } = useSchemaField("LoanEstimateOffer", "mortech.source");
  const { defaultValue: viewDefaultValue } = useSchemaField("LoanEstimateOffer", "mortech.view");
  const calculate = useCallback((name?: string) => {
    let allValues = form.getState().values;
    if (!allValues.propertyValue && (allValues.loanAmount && allValues.downPayment)) {
      form.change("propertyValue", allValues.loanAmount + allValues.downPayment);
      allValues = form.getState().values;
    }
    if (!allValues.propertyValue) {
      return;
    }
    const changes = calculateChanges(allValues, name);
    queueMicrotask(() => {
      Object.keys(changes).forEach((field) => {
        form.change(field as keyof LoanEstimateOffer, changes[ field ]);
      });
    });
  }, [loanEstimate, sourceDefaultValue, viewDefaultValue, form, pmiDownPaymentBoundary]);

  const change = (name: keyof LoanEstimateOffer, value) => {
    if (value !== null) {
      form.change(name, value);
    }
  };

  const handleDirtyStateChange = ({ dirty, values }: FormState<any>) => {
    queueMicrotask(() => {
      client.writeFragment<LoanEstimateOfferClientDirtyState>({
        fragment: LOAN_ESTIMATE_OFFER_CLIENT_DIRTY_STATE,
        data: {
          __typename: "LoanEstimateOffer",
          isDirty: dirty
        },
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: values.id })
      });
    });
  };

  return (
    <>
      <OfferListener
        names={[
          "exempt",
          "loanAmount",
          "downPayment",
          "ltv",
          "propertyValue",
          "financeFf",
          "financeMip",
          "firstUseOfVaProgram",
          "productType",
          "currentMortgageBalance",
          "cashAmount",
          "secondaryFinancing",
          "isStreamLine",
          "veteranStatus",
          "pricingEngine",
          "price",
          "loanTerm",
          "amortizationType",
          "mortech.loanProductId",
          "loanProduct",
          "conforming",
          "initialArmTerm"
        ]}
        onChange={calculate}
      />
      <FormSpy
        subscription={{ values: true, dirty: true, dirtySinceLastSubmit: true, valid: true, dirtyFields: true, modified: true, dirtyFieldsSinceLastSubmit: true, modifiedSinceLastSubmit: true }}
        onChange={state => handleDirtyStateChange(state)}/>
      <OnChange name={"loanPurpose"} children={calculate}/>
    </>
  );
});

export const LOAN_ESTIMATE_OFFER_CLIENT_DIRTY_STATE = gql`
  fragment LoanEstimateOfferClientDirtyState on LoanEstimateOffer {
    isDirty @client
  }
`;
