import { useContext }            from "react";
import { ReactElement }          from "react";
import { ReactNode }             from "react";
import React                     from "react";
import { FC }                    from "react";
import { getItemLabel }          from "../Input/Select/helpers";
import { MenuItem }              from "../Menu";
import { SearchableMenuContext } from "./MenuProvider";

interface SearchableMenuItemProps {
  item: any;
  checkSelected?(item);
  optionLabel: string;
  index: any;
  onSelectItem(item);
  renderOption?: (option, selected?: boolean) => ReactNode;//todo use only menuItem
}

export const SearchableMenuItem: FC<SearchableMenuItemProps> = React.memo(React.forwardRef(function SearchableMenuItem(props, ref) {
  const { item, index } = props;
  const { checkSelected, renderOption, optionLabel, onSelect } = useContext(SearchableMenuContext);
  const selected = checkSelected(item);
  return renderOption
    ?
    React.cloneElement(renderOption(item, selected) as ReactElement, {//todo need to check with real data
      selected,
      key: index,
      ref,
      onClick: (e) => {
        onSelect(item);
      }
    }) :
    <MenuItem
      ref={ref}
      key={index}
      disabled={item.disabled}
      container
      gap={"XXS"}
      selected={checkSelected(item)}
      onClick={() => onSelect(item)}>{getItemLabel(item, optionLabel)}</MenuItem>;
}));
