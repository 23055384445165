import { getItemField } from "../Input/Select/helpers";

export function useMenuProvider(props) {
  const { optionKey, optionLabel, options, selected, onCreateNew, onSelect, renderOption } = props;
  function getListKey(item) {
    if (typeof item == "object") {
      return item[ optionKey ];
    }
    return options.indexOf(item);
  }//works only in virtual list case

  const checkSelected = (option) => {
    const opt = getItemField(option, optionKey);
    if (Array.isArray(selected)) {
      return !!selected.find((s) => {
        const sItem = getItemField(s, optionKey);
        return sItem == opt;
      });
    } else {
      const selectedItem = getItemField(selected, optionKey);
      return selectedItem === opt;
    }
  };

  return {
    optionKey,
    optionLabel,
    options,
    selected,
    onCreateNew,
    onSelect,
    renderOption,
    getListKey,
    checkSelected
  };
}