import React                     from "react";
import { useState }              from "react";
import { useEffect }             from "react";
import { useLazyQuery }          from "@apollo/client";
import { gql }                   from "@apollo/client";
import { useNavigate }           from "@relcu/react-router";
import { Alignment }             from "@relcu/ui";
import { Box, Tooltip }          from "@relcu/ui";
import { Typography }            from "@relcu/ui";
import { useThrottle }           from "@relcu/ui";
import { IChoiceField }          from "../../../types/ISchemas";
import { toFirstUpper }          from "../../../utils/helpers";
import { useSchemaField }        from "../../Layout/useSchemaField";
import { UsersByQueueVariables } from "./__types__/UsersByQueue";
import { UsersByQueue }          from "./__types__/UsersByQueue";

export function useDistributionStatsUsers(queueName) {
  const [hasNoLeads, setHasNoLeads] = useState(false);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  let [query, setQuery] = useThrottle(value, 1000);
  const navigate = useNavigate();
  const limit = 15;
  const { options } = useSchemaField<IChoiceField>("User", "role");
  const [load, { data, loading, refetch }] = useLazyQuery<UsersByQueue, UsersByQueueVariables>(USERS_BY_QUEUE, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  useEffect(() => {
    load({
      variables: {
        queue: queueName,
        hasNoLeads: hasNoLeads,
        match: value,
        first: limit,
        skip: 0
      }
    });
  }, [queueName]);

  const onPageChange = (page) => {
    setPage(page);
    refetch({
      queue: queueName,
      hasNoLeads: hasNoLeads,
      first: limit,
      match: query,
      skip: (page - 1) * limit
    });
  };
  const onFetch = () => {
    refetch({
      queue: queueName,
      hasNoLeads: hasNoLeads,
      first: limit,
      match: query,
      skip: (page - 1) * limit
    });
  };
  useEffect(() => setQuery(value), [value]);
  useEffect(() => {
    setPage(1);
    refetch({
      queue: queueName,
      hasNoLeads: hasNoLeads,
      first: limit,
      match: query,
      skip: 0
    });
  }, [query, hasNoLeads]);
  return {
    get data() {
      return (data?.usersByQueue?.usersByQueue || []).map(stat => {
        return {
          objectName: stat.user.objectName,
          objectIcon: stat.user.objectIcon,
          team: toFirstUpper(stat.user.team) || "-",
          role: options.find(o => o.value == stat.user.role).label,
          coolDownPeriod: `${stat.stats.coolDownPeriod.toFixed(2)} ${stat.stats.isCoolingDown ? "(in cool down)" : "min"}`,
          leadCaps: <Box container gap={"XS"} flexBasis={"200px"} flexGrow={1} flexShrink={1}>
            <Tooltip
              alignment={Alignment.Top}
              style={{ textAlign: "center" }}
              title={`Daily taken: ${stat.stats.leadTakenDaily} / ${stat.stats.dailyCap} \n Weekend: ${(stat.stats.weekendCap ?? stat.stats.dailyCap) || "-"}`}>
              <Typography>
                {`${stat.stats.leadTakenDaily} / ${stat.stats.dailyCap}  / ${(stat.stats.weekendCap ?? stat.stats.dailyCap) || "-"}`}
              </Typography>
            </Tooltip>
            <Tooltip
              alignment={Alignment.Top}
              style={{ textAlign: "center" }}
              title={`Weekly taken: ${stat.stats.leadTakenWeekly} / ${stat.stats.weeklyCap}`}>
              <Typography>
                {`${stat.stats.leadTakenWeekly} / ${stat.stats.weeklyCap}`}
              </Typography>
            </Tooltip>
            <Tooltip
              alignment={Alignment.Top}
              style={{ textAlign: "center" }}
              title={`Monthly taken: ${stat.stats.leadTakenMonthly} / ${stat.stats.monthlyCap}`}>
              <Typography>
                {`${stat.stats.leadTakenMonthly} / ${stat.stats.monthlyCap} `}
              </Typography>
            </Tooltip>
          </Box>,
          leadsAvailable: stat.stats.leadsAvailable,
          online: stat.user.online,
          status: stat.user.status,
          objectId: stat.user.objectId
        };
      });
    },
    setHasNoLeads,
    hasNoLeads,
    setValue,
    value,
    onPageChange,
    page,
    onFetch,
    count: data?.usersByQueue?.count,
    loading: loading,
    onRowClick(item) {
      setTimeout(() => {
        navigate(`/user/${item.objectId}`);
      });
    }
  };

}
export const USERS_BY_QUEUE = gql`
  query UsersByQueue($queue:String!, $first:Int! = 15, $skip:Int = 0, $hasNoLeads:Boolean = false,$match:String) {
    usersByQueue(queue:$queue,first: $first,skip: $skip,hasNoLeads: $hasNoLeads,match: $match) {
      count
      usersByQueue{
        stats{
          active
          leadsAvailable
          coolDownPeriod
          isCoolingDown
          leadTakenDaily
          leadTakenWeekly
          leadTakenMonthly
          monthlyCap
          dailyCap
          weeklyCap
          weekendCap
        }
        user{
          online
          status
          firstName
          objectIcon
          objectName
          objectId
          team
          role
        }
      }
    }
  }
`;
