import React          from "react";
import { Box }        from "../..";
import { classNames } from "../..";

interface BackDropProps {
  onClick?: Function,
  open: boolean,
  invisible?: boolean,
}
export function Backdrop(props: BackDropProps) {
  const { open, invisible, onClick } = props;

  return (open &&
    <Box container justify={"center"} alignItems={"center"} className={classNames(BackdropClasses.BackDrop, {
      [ BackdropClasses.Invisible ]: invisible
    })} onClick={() => props.onClick && onClick()}/>
  );
}

export enum BackdropClasses {
  BackDrop = "back-drop",
  Invisible = "back-drop--invisible",
}
