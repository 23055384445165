import { Field }         from "@relcu/form";
import { Box }           from "@relcu/ui";
import { StepRow }       from "@relcu/ui";
import { SwitchField }   from "@relcu/ui";
import { NumberField }   from "@relcu/ui";
import { StepInterface } from "@relcu/ui";
import { StepSection }   from "@relcu/ui";
import { StepBody }      from "@relcu/ui";
import { StepContainer } from "@relcu/ui";
import { StepHeader }    from "@relcu/ui";
import React             from "react";
import { FC }            from "react";

export interface LeadCountProps extends StepInterface {
}

export const LeadCount: FC<LeadCountProps> = React.memo(function LeadCount(props) {
  const { title } = props;
  return (
    <StepContainer>
      <StepHeader
        title={title}/>
      <StepBody>
        <StepSection title="Lead count" tooltip={"Define lead count for the collapsed section"}>
          <Box container alignItems={"center"} gap={"XL"}>
            <StepRow flexBasis={"30%"}>
              <NumberField name={"leadCount"} flex={1} placeholder={"Type lead count"}/>
            </StepRow>
            <SwitchField name={"expandable"} flex={1} placeholder={"Allow expanding the section"}/>
          </Box>
        </StepSection>
      </StepBody>
    </StepContainer>
  );
});
export const LeadFilterContext = React.createContext(null);
