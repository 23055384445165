import { Typography }     from "@relcu/rc";
import { Cell }           from "@relcu/rc";
import { CircularLoader } from "@relcu/ui";
import React              from "react";
import { FC }             from "react";

const reasons = {
  change: {
    title: "You have changed the loan criteria.",
    content: "To update the offer, click on the “Update rates“ button."
  },
  loading: {
    title: "Getting latest results.",
    content :""
  },
  noFetch: {
    title: "Rates were not fetched.",
    content: "Adjust the properties and select a new rate."
  }
};

export const RateLockCell: FC<{ reason: "empty" | "change" | "noFetch" | "loading" }> = React.memo((props) => {
  const content = reasons[ props.reason ];
  return <Cell span={3}
               style={{
                 display: "flex",
                 opacity: 1,
                 background: "var(--rc-background-tint2)",
                 flexDirection: "column",
                 alignItems: "center",
                 padding: 16,
                 textAlign: "center",
                 textWrap: "balance",
                 justifyContent: "center",
                 borderRight: "1px solid var(--rc-background-tint3)",
                 whiteSpace: "break-spaces"
               } as any}>
    <Typography variant={"base14"} weights={"medium"} style={{ color: "var(--rc-text-primary)" }}>
      {content.title}
    </Typography>
    {props.reason == "loading" ? <CircularLoader/> :
      <Typography variant={"base14"} style={{ color: "var(--rc-text-secondary)" }}>
        {content.content}
      </Typography>}

  </Cell>;
});
