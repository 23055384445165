import React                from "react";
import { FC }               from "react";
import { MultiSelectProps } from "../../Input/MultiSelect";
import { BaseFieldProps }   from "../BaseField";
import { BaseField }        from "../BaseField";
import MultiInputEditField  from "./MultiInputEditField";
import MultiInputReadField  from "./MultiInputReadField";

export type MultiInputFieldProps = BaseFieldProps & MultiSelectProps;

export const MultiInputField: FC<MultiInputFieldProps> = React.memo(function MultiInputField(props) {
  return (
    <BaseField
      {...props}
      format={(value) => value || []}
      readView={<MultiInputReadField/>}
      editView={<MultiInputEditField/>}
    />
  );
});
