import React                 from "react";
import { DateTime }          from "luxon";
import { Ellipsis }          from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { Widget }            from "@relcu/ui";
import { useSource }         from "@relcu/ui";
import { formatNumber }      from "@relcu/ui";
import { IChoiceField }      from "../../../../types/ISchemas";
import { useSchemaField }    from "../../useSchemaField";
import { LeadWidgetClasses } from "./LeadWidgetClasses";
import "./lead-widget.css";

export const LeadWidget = React.memo(() => {
  const { $object: node } = useSource();
  const loanProgramSchema = useSchemaField<IChoiceField>("Lead", "loanProgram");
  const loanPurposeSchema = useSchemaField<IChoiceField>("Lead", "loanPurpose");
  const loanProductSchema = useSchemaField<IChoiceField>("Lead", "loanProduct");
  let loanProduct = loanProductSchema.options.find((option) => option.value == node.loanProduct);
  let loanProgram = loanProgramSchema.options.find((option) => option.value == node.loanProgram);
  let loanPurpose = loanPurposeSchema.options.find((option) => option.value == node.loanPurpose);

  return (
    <Widget gap={"XS"} className={LeadWidgetClasses.LeadWidget}>
      {
        !!node?.leadSource?.leadPartner?.logo &&
        <Box container justify={"start"}>
          <img src={`${node.leadSource.leadPartner.logo}`} alt="Lead logo" height={40}/>
        </Box>
      }
      <Box container direction={"column"}>
        {
          loanPurpose &&
          <Box container justify={"start"} className={LeadWidgetClasses.LeadWidgetMainRow}>
            <Box container direction={"column"} gap={"XXXS"} flexBasis={"50%"} flexShrink={0}>
              {loanPurpose.label}
              <span>
            Loan purpose
          </span>
            </Box>
            <Box container direction={"column"} gap={"XXXS"} flexBasis={"50%"} flexShrink={0}>
              <Box container alignItems={"center"} flex={1}>
                {`$${formatNumber(node.loanAmount, 2)}`}
              </Box>
              <span>
            Loan amount
          </span>
            </Box>
          </Box>
        }
        {
          loanProgram &&
          <Box container justify={"start"} alignItems={"center"} className={LeadWidgetClasses.LeadWidgetRow}>
            <Box flexBasis={"50%"} flexShrink={0}>Loan program</Box>
            {loanProgram.label}
          </Box>
        }
        {
          node.ltv &&
          <Box container justify={"start"} alignItems={"center"} className={LeadWidgetClasses.LeadWidgetRow}>
            <Box flexBasis={"50%"} flexShrink={0}>LTV</Box>
            {Number(node.ltv).toLocaleString("en-US", { minimumFractionDigits: 3, maximumFractionDigits: 3 })}%
          </Box>
        }
        {
          loanProduct &&
          <Box container justify={"start"} alignItems={"center"} className={LeadWidgetClasses.LeadWidgetRow}>
            <Box flexBasis={"50%"} flexShrink={0}>Loan product</Box>
            {loanProduct.label}
          </Box>
        }
        {
          node.leadSource &&
          <Box container justify={"start"} alignItems={"center"} className={LeadWidgetClasses.LeadWidgetRow}>
            <Box flexBasis={"50%"} flexShrink={0}>Source</Box>
            <Ellipsis from={21}>{node.leadSource.objectName}</Ellipsis>
          </Box>
        }
        <Box container justify={"start"} alignItems={"center"} className={LeadWidgetClasses.LeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Date added</Box>
          {DateTime.fromISO(node.createdAt).toFormat("f")}
        </Box>
      </Box>
    </Widget>
  );
});
