import React                                              from "react";
import { useEffect }                                      from "react";
import { useApolloClient }                                from "@apollo/client";
import { gql }                                            from "@apollo/client";
import { useQuery }                                       from "@apollo/client";
import { useMutation }                                    from "@apollo/client";
import { DOCUMENT }                                       from "../../../../graph/operations.graphql";
import { NODE_FRAGMENT }     from "../../../../graph/operations.graphql";
import { DeviceCall }        from "../../../../Tw";
import { SubscriptionEvent } from "../../../../types/graphql-global-types";
import { SubscribeRecodingsVariables }                    from "../../../Layout/Section/__types__/SubscribeRecodings";
import { SubscribeRecodings }                             from "../../../Layout/Section/__types__/SubscribeRecodings";
import { VoiceMailRecordingsVariables }                   from "../../../Layout/Section/__types__/VoiceMailRecordings";
import { VoiceMailRecordings }                            from "../../../Layout/Section/__types__/VoiceMailRecordings";
import { VOICEMAIL_RECORDINGS }                           from "../../../Layout/Section/operations.graphql";
import { SUBSCRIBE_RECORDINGS }                           from "../../../Layout/Section/operations.graphql";
import { CallReplayPlayTemplateVariables }                from "./__types__/CallReplayPlayTemplate";
import { CallReplayPlayTemplate }                         from "./__types__/CallReplayPlayTemplate";
import { CallReplayUser }                                 from "./__types__/CallReplayUser";
import { CallReplayVoiceTemplates_recordings_edges_node } from "./__types__/CallReplayVoiceTemplates";
import { CallReplayVoiceTemplatesVariables }              from "./__types__/CallReplayVoiceTemplates";
import { CallReplayVoiceTemplates }                       from "./__types__/CallReplayVoiceTemplates";

const CALL_REPLAY_USER = gql`
  query CallReplayUser {
    viewer {
      user {
        objectName
        id
      }
    }
  }
`;

export const CALL_REPLAY_VOICE_TEMPLATES = gql`
  query CallReplayVoiceTemplates($id: ID!) {
    recordings(where: {
      deleted: {notEqualTo: true}
      type: {equalTo: "voice_template"}
      owner: {have: {id: {equalTo: $id}}}}
    ){
      count
      edges{
        node{
          ...Node
          ...Document
          name
          url
          type
        }
      }
    }
  }
  ${NODE_FRAGMENT}
  ${DOCUMENT}
`;

export const CALL_REPLAY_PLAY_TEMPLATE = gql`
  mutation CallReplayPlayTemplate($cId: ID!,$rId:ID!) {
    playTemplate(cId: $cId,rId: $rId)
  }
`;

export function useCallReply(callSid: string, onClick) {
  const [menus, setMenus] = React.useState<{ [ key: string ]: DOMRect }>({});
  const client = useApolloClient();
  const { data: { viewer: { user: { id, objectName } } } } = useQuery<CallReplayUser>(CALL_REPLAY_USER, {
    fetchPolicy: "cache-only"
  });

  const { data, subscribeToMore } = useQuery<CallReplayVoiceTemplates, CallReplayVoiceTemplatesVariables>(CALL_REPLAY_VOICE_TEMPLATES, { variables: { id } });
  const [playTemplate] = useMutation<CallReplayPlayTemplate, CallReplayPlayTemplateVariables>(CALL_REPLAY_PLAY_TEMPLATE);
  useEffect(() => {
    return subscribeToMore<SubscribeRecodings, SubscribeRecodingsVariables>({
      document: SUBSCRIBE_RECORDINGS,
      variables: { id, type: "voice_template" },
      updateQuery(previousQueryResult, { subscriptionData: { data: { recordings: { event, node } } } }) {
        switch (event) {
          case SubscriptionEvent.ENTER:
          case SubscriptionEvent.CREATE:
            return {
              ...previousQueryResult,
              recordings: {
                ...previousQueryResult.recordings,
                count: previousQueryResult.recordings.count + 1,
                edges: [
                  {
                    node,
                    __typename: "RecordingEdge"
                  },
                  ...previousQueryResult.recordings.edges
                ]
              }
            };
          case SubscriptionEvent.UPDATE:
            return {
              ...previousQueryResult,
              recordings: {
                ...previousQueryResult.recordings,
                edges: previousQueryResult.recordings?.edges.map(edge => {
                  if (edge.node.id !== node.id) {
                    return edge;
                  } else {
                    return { __typename: "RecordingEdge", node };
                  }
                })
              }
            };
          case SubscriptionEvent.LEAVE:
            const data = client.readQuery<VoiceMailRecordings, VoiceMailRecordingsVariables>({ query: VOICEMAIL_RECORDINGS, variables: { id, type: "voice_template" } });
            client.writeQuery<VoiceMailRecordings, VoiceMailRecordingsVariables>({
              query: VOICEMAIL_RECORDINGS,
              variables: { id, type: "voice_template" },
              data: {
                ...data,
                recordings: {
                  ...data.recordings,
                  edges: [...data.recordings.edges.filter(edge => edge.node.id !== node.id)]
                }
              }
            });
        }
      }
    });
  }, [id]);

  return {
    menus,
    get templates() {
      return (data?.recordings?.edges || []).map((({ node }) => node));
    },
    async send(template: CallReplayVoiceTemplates_recordings_edges_node) {
      onClick?.();
      await playTemplate({ variables: { cId: callSid, rId: template.objectId } });
    },
    onOpen(event, name) {
      setMenus({
        ...menus,
        [ name ]: menus[ name ] ? null : event.currentTarget.getBoundingClientRect()
      });
    },
    onClose(name) {
      setMenus({
        ...menus,
        [ name ]: null
      });
    }
  };
}
