import { Checkbox }         from "@relcu/rc";
import React                from "react";
import { FC }               from "react";
import { useCallback }      from "react";
import { Icon }             from "@relcu/rc";
import { ToggleDropdown }   from "@relcu/rc";
import { HeadingCellProps } from "./HeadingCell";
import { HeadingCell }      from "./HeadingCell";

export interface DropdownGroupHeadingCellProps extends HeadingCellProps {
  title: string;
  selected: string[];
  items: { label: string, value: string }[];
  onSelect(value);
}

export const DropdownGroupHeadingCell: FC<DropdownGroupHeadingCellProps> = React.memo((props) => {
  const { title, items, onSelect, selected } = props;
  const handleSelection = useCallback((event, value) => {
    event.stopPropagation();
    onSelect(value);
  }, [selected]);

  return (
    <HeadingCell expanded={props.expanded}>
      {title}
      <div style={{ flex: 1 }}/>
      {
        !!items.length &&
        <ToggleDropdown
          onClick={(event) => event.stopPropagation()}
          toggleRenderer={<Icon type={"add"} style={{ color: "var(--rc-accent-03-primary)" }}/>}
          placement={"autoVerticalEnd"}>
          {
            items.map((item) => {
              return <ToggleDropdown.Item
                icon={<Checkbox
                  size={"md"}
                  checked={selected.includes(item?.value)}>
                  {item.label}
                </Checkbox>}
                eventKey={3}
                key={item.value}
                onClick={(event) => handleSelection(event, item.value)}/>;

            })
          }
        </ToggleDropdown>
      }
    </HeadingCell>
  );
});
