import { layoutVar } from "../reactiveVars";

export function getObjectPath(node) {
  if (node?.__typename) {
    const layout = layoutVar();
    const { route } = Object(layout[ node.__typename ]);
    if (route) {
      return replaceRoutePattern(route, node);
    }
  }
}
export function replaceRoutePattern(route, params) {
  let sanitizedRoute = route.replace(/\*/g, "");

  sanitizedRoute = sanitizedRoute.replace(/:([a-zA-Z]+)/g, (_, key) => {
    return params[ key ] !== undefined ? params[ key ] : `:${key}`;
  });

  if (sanitizedRoute.endsWith("/")) {
    sanitizedRoute = sanitizedRoute.slice(0, -1);
  }

  return sanitizedRoute;
}
