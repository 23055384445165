import { useNavigate }  from "@relcu/react-router";
import { getObjectPath } from "../../../../utils/layoutUtils";
import { usePhone }      from "../../../usePhone";
import { useHybridCall } from "./useHybridCall";

export function useRelcuCallActions() {
  const { active, remove, unHold, hybridCalls } = usePhone();
  const {
    onHoldCall,
    secondActiveCall,
    contactCall,
    activeCalls
  } = useHybridCall();
  const navigate = useNavigate();

  const handleAccept = () => {
    active.accept();
    if (hybridCalls?.initialDisplayCall?.scopeId) {
      navigate(`${getObjectPath({__typename: hybridCalls?.initialDisplayCall?.scopeClassName, objectId: hybridCalls?.initialDisplayCall.scopeId})}/details`);
    } else if (contactCall) {
      navigate(`/contact/${contactCall.objectId}/calls`);
    }
  };

  const handleToggleHold = () => {
    if (activeCalls?.[ 0 ]) {
      unHold(activeCalls[ 0 ]?.call, !activeCalls[ 0 ]?.hold);
    }
  };

  const handleDropOrRemove = () => {
    if (onHoldCall) {
      remove(secondActiveCall.call, onHoldCall.call);
    } else {
      active.drop();
    }
  };

  return {
    remove,
    handleAccept,
    handleToggleHold,
    handleDropOrRemove,
  };
}
