export enum ContactDialogClasses {
  MergeInfo = "contact-dialog-merge-info",
  Body = "contact-dialog-contact-body",
  ContactAlert = "contact-dialog-contact-alert",
  Row = "contact-dialog-row",
  MultiRow = "contact-dialog-multi-row",
  MultiRowDuplicated = "contact-dialog-multi-row__duplicated",
  RowTitle = "contact-dialog-row-title",
  MergeFieldDisabled = "merge-field__disabled",
  HeaderRow = "contact-dialog-header-row"
}
