import { ReactElement }      from "react";
import { FC }                from "react";
import React                 from "react";
import { InputState }        from "../../..";
import { BoxComponentProps } from "../../..";
import { applyBoxStyles }    from "../../..";
import { classNames }        from "../../..";
import { BaseInputClasses }  from "../BaseInput";

export interface RadioProps extends BoxComponentProps {
  label?: string | ReactElement;
  name?: string;
  checked?: boolean;
  value?: string | number;
  disabled?: boolean;
  state?: InputState;
  halfSize?: boolean;
  fullSize?: boolean;
  alignment?: "left" | "right";
  inputProps?: { [ key: string ]: any; };
  InputProps?: { [ key: string ]: any; };
  onChange?(e);
  onFocus?();
  onBlur?()
}

export const Radio: FC<RadioProps> = React.memo(function Radio(props) {
  let properties = applyBoxStyles<RadioProps>({ ...props, container: true });
  const {
    className,
    value,
    label,
    alignment,
    name,
    onChange,
    checked,
    disabled,
    state,
    onFocus,
    onBlur,
    halfSize,
    fullSize,
    inputProps,
    InputProps,
    ...p
  } = properties;
  const classes = classNames(RadioClasses.Radio, {
    [ RadioClasses.Reverse ]: (alignment == "left"),
    [ RadioClasses.Disabled ]: disabled,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error
  }, className);

  let opts = {
    onChange,
    onFocus,
    onBlur
  };

  if (onChange == null) {
    opts[ "defaultChecked" ] = checked;
  } else {
    opts[ "checked" ] = checked;
  }

  return <label className={classes} {...p} {...InputProps}>
    <input value={value} type="radio" name={name} disabled={disabled} {...opts} {...inputProps}/>
    <span className={RadioClasses.RButton}/>
    {label}
  </label>;
});
Radio.defaultProps = {
  alignment: "right",
  disabled: false
};
export enum RadioClasses {
  Radio = "radio",
  Disabled = "disabled",
  Reverse = "reverse",
  RButton = "r-button",
}
