import React                         from "react";
import { FC }                        from "react";
import { IActionCellProps, Tooltip } from "@relcu/ui";
import { Button }                    from "@relcu/ui";
import { ButtonSizes }               from "@relcu/ui";
import { Alignment }                 from "@relcu/ui";
import { getField }                  from "../../../../../utils/schemaUtils";
import { usePermissions }            from "../../../../AccessControl";

export const LeadStatusAction: FC<IActionCellProps> = React.memo(function LeadStatusAction(props) {
  const { rowData, tooltip, disabled, icon, refetch, onClick, bulkPermissions, ...p } = props;
  const { transitions } = getField("Lead", "leadStatus");
  const { canUpdate } = usePermissions(rowData);
  return <Tooltip title={tooltip} alignment={Alignment.Bottom}>
    <Button
      onlyIcon
      icon={icon}
      size={ButtonSizes.Small}
      onClick={() => onClick(rowData)}
      disabled={!canUpdate || (rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length) || !Object.keys(transitions[ rowData.leadStatus.status ] || {}).length}
      {...p}/>
  </Tooltip>;
});
