import { SubscriptionHookOptions } from "@apollo/client/react/types/types";
import { IQueryBuilderOptions }    from "@relcu/gql-query-builder";
import React                       from "react";
import { useJqlSubscription }      from "./useJqlSubscription";

export const JqlSubscription = React.memo<JqlSubscriptionProps>(
  function JqlQuery(props) {
    const { subscription, children, ...options } = props;
    const result = useJqlSubscription(subscription, options);
    if (typeof children === "function") {
      return result ? children(result) : null;
    }
    return null;
  }
);

export interface JqlSubscriptionProps extends Omit<SubscriptionHookOptions, "subscription"> {
  subscription: IQueryBuilderOptions | IQueryBuilderOptions[],
  children?: (result: ReturnType<typeof useJqlSubscription>) => JSX.Element | null;
  operationName: string;
}
