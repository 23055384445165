import { useState }             from "react";
import { gql }                  from "@apollo/client";
import { useQuery }             from "@apollo/client";
import { getIn }                from "@relcu/form";
import { useNavigate }          from "@relcu/react-router";
import { useSearchParams }      from "@relcu/react-router";
import { confirmModal }         from "@relcu/ui";
import { useLazyCondition }     from "@relcu/ui";
import { useModal }             from "@relcu/ui";
import { getHumanized }         from "../../../../utils/schemaUtils";
import { MAIL_DOMAIN_FRAGMENT } from "../../../operations.graphql";
import { SchemaDialog }         from "../../Dialog/SchemaDialog";
import { useJqlMutation }       from "../../Jql";
import { useJqlQuery }          from "../../Jql";
import { GetAdminMailDomains }  from "./__types__/GetAdminMailDomains";
import { MailDomainDialog }     from "./MailDomainDialog";

export function useMailIntegration(props) {
  const mailboxCount = 10;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mailboxCurrentPage = +searchParams.get("page") || 1;
  const mailboxOffset = mailboxCount;
  const operation = props.jql.query.find.operation;
  const deleteOp = props.jql.mutation.delete;
  const variables = {
    first: mailboxOffset,
    skip: mailboxOffset * (mailboxCurrentPage - 1),
    order: props.order || ["createdAt_DESC", "objectId_ASC"]
  };

  const [modal, modalContext] = useModal(MailDomainDialog);
  const [accountModal, accountModalContext] = useModal(SchemaDialog);
  const evaluate = useLazyCondition();
  const [open, setOpen] = useState(false);
  const { data: { mailDomains: { edges } = {} } = {}, loading, refetch: refetchDomains } = useQuery<GetAdminMailDomains>(GET_ADMIN_MAIL_DOMAINS);
  // const fields = useMemo(() => props.jql.query.find.fields.find(f => Array.isArray(f.edges)).edges.find(n => Array.isArray(n.node)).node, [props.jql.query]);
  // const query = useMemo(() => ({
  //   operation: operation,
  //   variables,
  //   fields: [
  //     "count",
  //     { "pageInfo": props.jql.query.find.pageInfo },
  //     {
  //       edges: [
  //         {
  //           node: [...fields, { lead: ["objectId"] }]
  //         }
  //       ]
  //     }
  //   ]
  // }), [fields, operation, variables]);

  const { data, refetch } = useJqlQuery(props.jql.query.find, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    operationName: `${props.classId}Integration`,
    variables
  });
  const [remove] = useJqlMutation({
    operation: deleteOp.operation,
    fields: deleteOp.fields,
    variables: {
      input: {
        type: deleteOp.variables.input.type
      }
    }
  },{
    operationName: `Delete${props.classId}Account`
  });
  const api = {
    loading,
    modalContext,
    onPage(page) {
      searchParams.set("page", page.toString());
      navigate(`/${location.pathname}?${searchParams.toString()}`);
    },
    accountModalContext,
    onEdit: async (data) => {
      modal({
        record: data,
        open: open,
        title: "Create Mail Domain",
        action: "save",
        onClose: () => {
          setOpen(false);
        }
      });
    },
    onCreateDomain: async () => {
      modal({
        open: open,
        title: "Create Mail Domain",
        action: "create",
        onClose: () => {
          refetchDomains();
          setOpen(false);
        }
      });
    },
    onCreateAccount: async () => {
      accountModal({
        action: "create",
        className: "Mailbox"
      });
    },
    onEditAccount: async (data) => {
      accountModal({
        record: data,
        action: "save",
        className: "Mailbox"
      });
    },
    onAccountDelete: async (obj) => {
      try {
        await confirmModal({
          title: "Delete confirmation",
          subTitle: `Are you sure you want to delete Account?`,
          content: `All information related with this Account will be lost permanently`,
          label: "DELETE"
        });
        await remove({
          variables: {
            input: {
              id: obj.id
            }
          },
          async update() {
            refetch();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    get mailboxHeaders() {
      return props.headers?.filter((field) => {
        if (field.conditions) {
          const { apply } = evaluate({ conditions: field.conditions });
          return apply;
        }

        return true;
      });
    },
    mailboxOffset,
    mailboxCount,
    mailboxCurrentPage,
    get mailboxFields() {
      return props.fields.filter((field) => {
        if (field.conditions) {
          const { apply } = evaluate({ conditions: field.conditions });
          return apply;
        }
        return true;
      });
    },
    get mailboxes() {
      const nodes = data?.[ operation ]?.[ "edges" ] ?? [];
      return nodes.map(({ node }) => {
        return node;
      });
    },
    getColumnValue(row, column) {
      return getHumanized(row.__typename, column.name, getIn(row, column.name));
    },
    get totalMailboxes() {
      return (
        data &&
        data[ operation ] &&
        data[ operation ][ "count" ]
      ) || 0;
    },
    get domains() {
      return edges?.map(({ node }) => ({
        ...node,
        verified: node.valid ? "Verified" : "Not verified",
        relcuEmail: !!node.dns
      })) || [];
    }
  };
  return api;
}

export const GET_ADMIN_MAIL_DOMAINS = gql`
  query GetAdminMailDomains {
    mailDomains {
      edges {
        node {
          ...MailDomain
          provider {
            type
            clientId
            clientSecret
            sync
          }
        }
      }
    }
  }
  ${MAIL_DOMAIN_FRAGMENT}
`;
