import { useMemo }                  from "react";
import { useMutation }              from "@apollo/client";
import { FORM_ERROR }               from "@relcu/form";
import { AlertColors }              from "@relcu/ui";
import { UpdateAppraisalVariables } from "./__types__/UpdateAppraisal";
import { UpdateAppraisal }          from "./__types__/UpdateAppraisal";
import { UPDATE_APPRAISAL }         from "./AppraisalImportModal";
import { loadingVar }               from "../../../reactiveVars";

const APPRAISAL_FEE_TYPES = ["conventional", "fha", "va", "jumbo", "nonConf"];

export function useAppraisalImport(props) {
  const { onClose, refetch, data, action } = props;
  const [updateAppraisal] = useMutation<UpdateAppraisal, UpdateAppraisalVariables>(UPDATE_APPRAISAL);
  const appraisalFeeTypes = useMemo(() => {
    let typesData = [...APPRAISAL_FEE_TYPES];
    if (data) {
      const files = data.node.uploadedFiles;
      for (let key in files) {
        typesData = typesData.filter(t => t !== files[ key ].type);
      }
    }
    return typesData;
  }, [data]);

  async function fetcher(values, method) {
    loadingVar(true);
    let errors = {};
    const data = new FormData();
    for (let k in values) {
      data.append(k, values[ k ]);
    }
    const response = await fetch(`/api/v1/appraisal`, {
      body: data,
      method: method,
      cache: "no-cache",
      credentials:"include",
      headers: {
        "Accept": "application/json"
      }
    });
    loadingVar(false);
    const result = await response.json();
    if (Object.keys(result.errors).length === 0) {
      refetch();
      onClose();
    }

    errors = { ...errors, ...result.errors };
    if (result.errors.all) {
      errors[ FORM_ERROR ] = {
        color: AlertColors.Error,
        message: `We are sorry, something went wrong. ${result.errors.all.message}`
      };
    }
    return errors;
  }

  async function onSubmit(values) {
    try {
      if (action == "edit") {
        let containeFile = false;
        for (let key in values) {
          if (values[ key ] instanceof File) {
            containeFile = true;
          }
        }
        if (!containeFile) {
          await updateAppraisal({
            variables: {
              id: values.objectId,
              fields: {
                enabled: values.enabled,
                objectName: values.name,
                defaultType: values.defaultType
              }
            }
          });
          refetch();
          return onClose();
        } else {
          return await fetcher(values, "PUT");
        }
      } else {
        return await fetcher(values, "POST");
      }
    } catch (e) {
      loadingVar(false);
      console.error(e);
      return {
        FORM_ERROR: {
          message: "We are sorry, something went wrong. Request might be timed out. Please try again",
          color: AlertColors.Error
        }
      };
    }
  }

  const initial = useMemo(() => {
    return {
      defaultType: "conventional",
      ...props.data?.node
    };
  }, [props.data?.node]);

  const api = {
    initial,
    onSubmit,
    appraisalFeeTypes
  };
  return {
    ...api
  };
}
