import React                                from "react";
import { AddressField as BaseAddressField } from "@relcu/ui";
import { AddressFieldProps }                from "@relcu/ui";
import { getField }                         from "../../../../utils/schemaUtils";

export const AddressField = React.memo<AddressFieldProps>(function AddressField(props) {
  const field = getField("Address", "state");
  return (
    <BaseAddressField options={field.options} {...props} apiKey={window.__CONFIG__.hereApiKey}/>
  );
});
