import React                   from "react";
import { PointerField }        from "@relcu/ui";
import { BaseField }           from "@relcu/ui";
import { PointerFieldProps }   from "@relcu/ui";
import ContactPointerEditField from "./ContactPointerEditField";
import "./contact-details.css";

export interface ContactFieldProps extends Omit<PointerFieldProps, "searchText" | "options" | "onType" | "onSelect"> {
  placeholder?: string
}
export const ContactField = React.memo<ContactFieldProps>(function ContactField(props) {
  const readView = <PointerField {...props as PointerFieldProps} view={"read"} subscription={{ value: true }}/>;
  return (
    <BaseField
      {...props}
      readView={readView}
      editView={<ContactPointerEditField/>}
    />
  );
});

