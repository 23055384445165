import { fieldSubscriptionItems, ARRAY_ERROR, ffVersion as version$1 } from './final-form.js';
import { useForm, useField, version as version$2 }                     from './react-final-form.js';
import React__default, { createElement }                               from 'react';

// shared logic between components that use either render prop,
// children render function, or component prop
function renderComponent(props, name) {
  const {
    render,
    children,
    component,
    ...rest
  } = props;

  if (component) {
    return createElement(component, { ...rest,
      children,
      render
    }); // inject children back in
  }

  if (render) {
    return render(children === undefined ? rest : { ...rest,
      children
    }); // inject children back in
  }

  if (typeof children !== 'function') {
    throw new Error(`Must specify either a render prop, a render function as children, or a component prop to ${name}`);
  }

  return children(rest);
}

const defaultIsEqual = (aArray, bArray) => aArray === bArray || Array.isArray(aArray) && Array.isArray(bArray) && aArray.length === bArray.length && !aArray.some((a, index) => a !== bArray[index]);

/**
 * A simple hook to create a constant value that lives for
 * the lifetime of the component.
 *
 * Plagiarized from https://github.com/Andarist/use-constant
 *
 * Do NOT reuse this code unless you know what you're doing.
 * Use Andarist's hook; it's more fault tolerant to things like
 * falsy values.
 *
 * @param {Function} init - A function to generate the value
 */

function useConstant(init) {
  const ref = React__default.useRef();

  if (!ref.current) {
    ref.current = init();
  }

  return ref.current;
}

const all = fieldSubscriptionItems.reduce((result, key) => {
  result[key] = true;
  return result;
}, {});

const useFieldArray = (name, {
  subscription = all,
  defaultValue,
  initialValue,
  isEqual = defaultIsEqual,
  validate: validateProp
} = {}) => {
  const form = useForm('useFieldArray');
  const formMutators = form.mutators;
  const hasMutators = !!(formMutators && formMutators.push && formMutators.pop);

  if (!hasMutators) {
    throw new Error('Array mutators not found. You need to provide the mutators from final-form-arrays to your form');
  }

  const mutators = useConstant(() => // curry the field name onto all mutator calls
  Object.keys(formMutators).reduce((result, key) => {
    result[key] = (...args) => formMutators[key](name, ...args);

    return result;
  }, {}));
  const validate = useConstant(() => (value, allValues, meta) => {
    if (!validateProp) return undefined;
    const error = validateProp(value, allValues, meta);

    if (!error || Array.isArray(error)) {
      return error;
    } else {
      const arrayError = [] // gross, but we have to set a string key on the array
      ;
      arrayError[ARRAY_ERROR] = error;
      return arrayError;
    }
  });
  const {
    meta: {
      length,
      ...meta
    },
    input,
    ...fieldState
  } = useField(name, {
    subscription: { ...subscription,
      length: true
    },
    defaultValue,
    initialValue,
    isEqual,
    validate,
    format: v => v
  });

  const forEach = iterator => {
    // required || for Flow, but results in uncovered line in Jest/Istanbul
    // istanbul ignore next
    const len = length || 0;

    for (let i = 0; i < len; i++) {
      iterator(`${name}[${i}]`, i);
    }
  };

  const map = iterator => {
    // required || for Flow, but results in uncovered line in Jest/Istanbul
    // istanbul ignore next
    const len = length || 0;
    const results = [];

    for (let i = 0; i < len; i++) {
      results.push(iterator(`${name}[${i}]`, i));
    }

    return results;
  };

  return {
    fields: {
      name,
      forEach,
      length: length || 0,
      map,
      ...mutators,
      ...fieldState,
      value: input.value
    },
    meta
  };
};

const arrayVersion = "3.1.2";

const versions = {
  'final-form': version$1,
  'react-final-form': version$2,
  'react-final-form-arrays': arrayVersion
};

const FieldArray = ({
  name,
  subscription,
  defaultValue,
  initialValue,
  isEqual,
  validate,
  ...rest
}) => {
  const {
    fields,
    meta
  } = useFieldArray(name, {
    subscription,
    defaultValue,
    initialValue,
    isEqual,
    validate
  });
  return renderComponent({
    fields,
    meta: { ...meta,
      __versions: versions
    },
    ...rest
  }, `FieldArray(${name})`);
};

export { FieldArray, useFieldArray, arrayVersion };
