import { TypePolicy } from "@apollo/client";
import { Root }       from "../../graph/__types__/Root";
import { ROOT }       from "../../graph/operations.graphql";

export const Contact: TypePolicy = {
  fields: {
    notifications: {
      read(exising, { readField, cache }) {
        const id = readField("id");
        const { notifications } = cache.readQuery<Root>({ query: ROOT });
        const contactNotifications = notifications.edges.filter(({ node }) => node.scope?.id == id).map((n) => n.node);
        return contactNotifications.length;
      }
    },
    tags: {
      merge(existing, incoming, { mergeObjects }) {
        return incoming ?? existing;
      }
    },
    phones: {
      merge(existing, incoming, { mergeObjects }) {
        return incoming ?? existing;
      }
    },
    emails: {
      merge(existing, incoming, { mergeObjects }) {
        return incoming ?? existing;
      }
    },
    types: {
      read(existing) {
        return existing ?? [];
      }
    }
  }
};
