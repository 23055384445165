import { toDate } from "./date-fns";

export function timeFormat(num, from) {
  const t = [];
  for (let i = from; i <= num; i++) {
    t.push(formatTime(i));
  }
  return t;
}

export function formatTime(number: number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}

export function getHalf(date): string {
  let hours = date.getHours();
  return hours >= 12 ? "PM" : "AM";
}

export function toAmPm(num) {
  if (num > 12) {
    return num - 12;
  }
  return num;
}

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;

  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return {
    hours,
    minutes,
    seconds,
    ampm
  };
}

export function fromAmPm(date: Date, type) {
  const day = date.getDate();
  let formattedTime = formatAMPM(date);
  if (type === formattedTime.ampm) {
    return date;
  } else {
    if (type === "PM") {
      date.setHours(date.getHours() + 12);
      date.setDate(day);
    } else {
      date.setHours(date.getHours() != 0 && date.getHours() - 12);
      date.setDate(day);
    }
    return date;
  }
}
export function setTime(dirtyDate: Date, type, value?) {
  let date = toDate(dirtyDate);
  if (type == "minute") {
    date.setMinutes(Number(value));
  }
  if (type == "hour") {
    const h = getHalf(date);
    value = Number(value);
    let hours = (h == "PM" ? value : (value == 12 ? 0 : value + 12));
    date.setHours(hours);
    date = fromAmPm(date, h);

  }
  if (type == "AM" || type == "PM") {
    date = fromAmPm(date, type);
  }
  const half = getHalf(date);
  return fromAmPm(date, half);
}
