import React                from "react";
import { FC }               from "react";
import { formatNumber }     from "../../../../utils/helpers";
import { Box }              from "../../../Box";
import { TypographyWeight } from "../../../Typography";
import { Typography }       from "../../../Typography";
import { ICellProps }       from "../ICellProps";

export interface PercentCellProps extends ICellProps {
  precision?: number
  variant?: "default" | "bold"
}
export const PercentCell: FC<PercentCellProps> = React.memo(function TextCell({ getCellValue, className, variant = "default", defaultValue = "", precision, rowData, dataKey, ...props }) {
  let value = getCellValue(rowData, dataKey);
  value = typeof value == "number" ? value : defaultValue;
  precision = typeof precision == "number" ? precision : 3;
  return <Box className={className} container gap={"XXXS"} alignItems={"center"}>
      <Typography weight={variant === "bold" && TypographyWeight.Bold}>{typeof value == "number" ? `${formatNumber(value, precision)}  %` : defaultValue}</Typography>
    </Box>
});


