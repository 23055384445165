import React                        from "react";
import { FC }                       from "react";
import { useContext }               from "react";
import { useMemo }                  from "react";
import { Field }                    from "@relcu/form";
import { useReactiveVar }           from "@apollo/client";
import { DistributionQueueContext } from "../../../../DistributionQueueDialog";
import { RuleSectionInterface }     from "../Rule";
import { FilterValueEditor }        from "./FilterValueEditor/FilterValueEditor";
import { FilterBuilder }            from "@relcu/ui";
import { StepSection }              from "@relcu/ui";
import { Alert }                    from "@relcu/ui";
import { AlertColors }              from "@relcu/ui";

export const LeadFiltering: FC<RuleSectionInterface> = React.memo(function LeadFiltering(props) {
  const { name, index } = props;
  const { notCompleteRulesVar, leadFilters, clearRulesError, getFieldSchema } = useContext(DistributionQueueContext);
  const notCompleteRules = useReactiveVar(notCompleteRulesVar);
  const errorName = useMemo(() => name.split(".")[ 1 ], [name]);
  const error = useMemo(() => notCompleteRules?.[ index ]?.[ errorName ], [notCompleteRules, index, errorName]);

  return (
    <StepSection error={!!error} title={"Lead filtering"}
                 tooltip={"Filter the leads you want to include in this queue."}>
      <LeadFilter
        leadFilters={leadFilters}
        valueEditor={FilterValueEditor}
        name={name}
        error={error}
        onChange={() => clearRulesError(index, errorName)}
      />
    </StepSection>
  );
});

export type LeadFilterProps = { name, onChange, leadFilters, error, valueEditor };
const LeadFilter = React.memo<LeadFilterProps>(function LeadFilter(props) {
  const { name, onChange, leadFilters, valueEditor, error } = props;

  return <>
    <Field name={name}>
      {
        ({ input, meta }) => {
          return <FilterBuilder
            query={input.value}
            onQueryChange={(data) => {
              onChange();
              input.onChange(data);
            }}
            valueEditor={valueEditor}
            fields={leadFilters}
          />;
        }
      }
    </Field>
    {
      error &&
      <Alert stretch variant={AlertColors.Error}>
        {error}
      </Alert>
    }
  </>;
});
