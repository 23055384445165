import { useMemo }                    from "react";
import { useState }                   from "react";
import React                          from "react";
import { FC }                         from "react";
import { Field }                      from "@relcu/form";
import { useQuery }                   from "@apollo/client";
import { useSource }                  from "@relcu/ui";
import { SearchableSelect }           from "@relcu/ui";
import { GetSignatureUsersVariables } from "./__types__/GetSignatureUsers";
import { GetSignatureUsers }          from "./__types__/GetSignatureUsers";
import { GET_SIGNATURE_USERS }        from "./useEmailSignature";

interface SignatureUserSelectProps {
  name: string
  label?: string
}

export const SignatureUserSelect: FC<SignatureUserSelectProps> = React.memo(function SignatureUserSelect(props) {
  const { name } = props;
  const { $viewer } = useSource();
  const [searchText, setSearchText] = useState("");
  const { data: { users: { edges = [] } = {} } = {} } = useQuery<GetSignatureUsers, GetSignatureUsersVariables>(GET_SIGNATURE_USERS, {
    variables: {
      where: {
        OR: [
          {
            objectName: {
              matchesRegex: `^${(searchText || "").trim()}`,
              options: "i"
            }
          },
          {
            id: {
              equalTo: $viewer.id
            }
          }
        ]
      }
    }
  });

  const users = useMemo(() => {
    return edges.map(({ node }) => node);
  }, [edges]);
  return (
    <Field name={name}>
      {({ input }) => {
        return <SearchableSelect
          flex={1}
          onChange={input.onChange}
          value={input.value}
          optionKey={"objectId"}
          optionLabel={"objectName"}
          options={users}
          label={props.label}
          searchText={searchText}
          onType={(e) => setSearchText(e)}/>;
      }}
    </Field>
  );
});
