import React                from "react";
import { FC }               from "react";
import { ImageProps }       from "../../Input/ImageInput";
import { BaseFieldProps }   from "../BaseField";
import { BaseField }        from "../BaseField";
import ImageInputEditField from "./ImageInputEditField";
import ImageInputReadField from "./ImageInputReadField";

export const ImageInputField: FC<ImageInputFieldProps> = React.memo(function ImageInputField(props) {
  return (
    <BaseField
      {...props}
      readView={<ImageInputReadField/>}
      editView={<ImageInputEditField/>}
    />
  );
});

export type ImageInputFieldProps = BaseFieldProps & ImageProps