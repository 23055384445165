import React, { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { classNames }                                                  from "../..";
import { simpleDebounce }                                              from "../../utils/helpers";

interface ScrollBarProps {
  children?: any,
  allowOuterScroll?: boolean,
  heightRelativeToParent?: string,
  onScroll?: Function,
  freezePosition?: boolean,
  minScrollHandleHeight?: number,
  flex?: string,
  scrollTo?: number,
  keepAtBottom?: boolean
  showHandleOutSide?: boolean
}

Scrollbar.defaultProps = {
  minScrollHandleHeight: 38,
  scrollTo: 0,
  showHandleOutSide: false
};
export function Scrollbar(props: ScrollBarProps) {
  const [startDragHandlePos, setStartDragHandlePos] = useState(0);
  const [scrollHandleHeight, setScrollHandleHeight] = useState(0);
  const [startDragMousePos, setStartDragMousePos] = useState(0);
  const [scrollPos, setScrollPos] = useState(0);
  const [scrollRatio, setScrollRatio] = useState(1);
  const [visibleHeight, setVisibleHeight] = useState(1);
  const [contentHeight, setContentHeight] = useState(1);
  const [onDrag, setOnDrag] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const [scrollbarYWidth, setScrollbarYWidth] = useState(0);
  const mountedRef = React.useRef(false);
  const hideScrollThumb = simpleDebounce(() => {
    if (!mountedRef.current) {
      return;
    }
    setOnDrag(onDrag);
  }, 500);
  const refs = useMemo(() => {
    let innerContainerRef: any = createRef();
    let customScrollbarRef: any = createRef();
    let scrollHandleRef: any = createRef();
    let contentWrapperRef: any = createRef();
    let rootRef: any = createRef();
    return {
      innerContainerRef,
      customScrollbarRef,
      scrollHandleRef,
      contentWrapperRef,
      rootRef
    };
  }, []);
  useEffect(() => {
    if (refs.innerContainerRef.current) {
      refs.innerContainerRef.current.addEventListener("wheel", blockOuterScroll, { passive: false });
    }
    return () => {
      if (refs.innerContainerRef.current) {
        refs.innerContainerRef.current.removeEventListener("wheel", blockOuterScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }
    const sRatio = contentHeight ? visibleHeight / contentHeight : 1;
    const innerContainer = getScrolledElement();
    setContentHeight(innerContainer.scrollHeight);
    setVisibleHeight(innerContainer.clientHeight);
    setScrollbarYWidth(innerContainer.offsetWidth - innerContainer.clientWidth);
    toggleScrollIfNeeded();
    setScrollRatio(sRatio);
    setScrollHandleHeight(getScrollHandleStyle().height);
  });
  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }
    const prevContentHeight = contentHeight;
    const prevVisibleHeight = visibleHeight;
    const innerContainer = getScrolledElement();
    const reachedBottomOnPrevRender = scrollPos >= prevContentHeight - prevVisibleHeight;
    if (typeof props.scrollTo !== "undefined") {
      updateScrollPosition(props.scrollTo);
    } else if (props.keepAtBottom && reachedBottomOnPrevRender) {
      updateScrollPosition(innerContainer.clientHeight - innerContainer.visibleHeight);
    }
  }, [props.scrollTo]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const toggleScrollIfNeeded = () => {
    const shouldHaveScroll = contentHeight - visibleHeight > 1;
    if (hasScroll !== shouldHaveScroll) {
      setHasScroll(shouldHaveScroll);
    }
  };
  const updateScrollPosition = scrollValue => {
    const innerContainer = getScrolledElement();
    const updatedScrollTop = ensureWithinLimits(scrollValue, 0, contentHeight - visibleHeight);
    innerContainer.scrollTop = updatedScrollTop;
    setScrollPos(updatedScrollTop);
  };
  const getScrolledElement = () => refs.innerContainerRef.current;

  const onClick = event => {
    if (!hasScroll || !isMouseEventOnCustomScrollbar(event) || isMouseEventOnScrollHandle(event) || event.target !== event.currentTarget) { //not sure it's a right change
      return;
    }
    const newScrollHandleTop = calculateNewScrollHandleTop(event);
    const newScrollValue = getScrollValueFromHandlePosition(newScrollHandleTop);

    updateScrollPosition(newScrollValue);
    event.preventDefault();
    event.stopPropagation();
  };
  const isMouseEventOnCustomScrollbar = event => {
    if (!refs.customScrollbarRef.current) {
      return false;
    }
    const customScrollElm = refs.rootRef.current;
    const boundingRect = customScrollElm.getBoundingClientRect();
    const customScrollbarBoundingRect = refs.customScrollbarRef.current.getBoundingClientRect();
    const customScrollbarLayout = Object.assign(
      {},
      {
        left: boundingRect.left,
        right: boundingRect.right,
        top: boundingRect.top,
        height: boundingRect.height
      }
    );
    return isEventPosOnLayout(event, customScrollbarLayout);
  };

  const isMouseEventOnScrollHandle = event => {
    if (!refs.scrollHandleRef.current) {
      return false;
    }
    const scrollHandle = refs.scrollHandleRef.current;
    return isEventPosOnDomNode(event, scrollHandle);
  };
  const calculateNewScrollHandleTop = clickEvent => {
    const domNode: any = refs.rootRef.current;
    const boundingRect = domNode.getBoundingClientRect();
    const currentTop = boundingRect.top + window.pageYOffset;
    const clickYRelativeToScrollbar = clickEvent.pageY - currentTop;
    const scrollHandleTop = getScrollHandleStyle().top;
    setScrollHandleHeight(getScrollHandleStyle().height);
    let newScrollHandleTop;
    const isBelowHandle = clickYRelativeToScrollbar > scrollHandleTop + scrollHandleHeight;
    if (isBelowHandle) {
      newScrollHandleTop =
        scrollHandleTop + Math.min(scrollHandleHeight, visibleHeight - scrollHandleHeight);
    } else {
      newScrollHandleTop = scrollHandleTop - Math.max(scrollHandleHeight, 0);
    }
    return newScrollHandleTop;
  };

  const getScrollValueFromHandlePosition = handlePosition => handlePosition / scrollRatio;

  const getScrollHandleStyle = useCallback(() => {
    const handlePosition = scrollPos * scrollRatio;
    let scrollHandleHeight = visibleHeight * scrollRatio;
    //setScrollHandleHeight(scrollHandleHeight);
    return {
      height: scrollHandleHeight,
      top: handlePosition
    };
  }, [scrollRatio, scrollPos]);

  const adjustCustomScrollPosToContentPos = scrollPosition => {
    setScrollPos(scrollPosition);
  };

  const onMouseDown = event => {
    if (!hasScroll || !isMouseEventOnScrollHandle(event)) {
      return;
    }
    setScrollHandleHeight(getScrollHandleStyle().height);
    setStartDragHandlePos(getScrollHandleStyle().top);
    setStartDragMousePos(event.pageY);
    setOnDrag(true);
    document.addEventListener("mousemove", onHandleDrag, { passive: false });
    document.addEventListener("mouseup", onHandleDragEnd, { passive: false });
  };

  const onTouchStart = () => {
    setOnDrag(true);
  };

  const onHandleDrag = event => {
    event.preventDefault();
    const mouseDeltaY = event.pageY - startDragMousePos;
    const handleTopPosition = ensureWithinLimits(
      startDragHandlePos + mouseDeltaY,
      0,
      visibleHeight - scrollHandleHeight
    );
    const newScrollValue = getScrollValueFromHandlePosition(handleTopPosition);
    updateScrollPosition(newScrollValue);
  };

  const onHandleDragEnd = e => {
    setOnDrag(false);
    e.preventDefault();
    document.removeEventListener("mousemove", onHandleDrag);
    document.removeEventListener("mouseup", onHandleDragEnd);
  };

  const blockOuterScroll = e => {
    if (props.allowOuterScroll) {
      return;
    }
    const contentNode = e.currentTarget;
    const totalHeight = e.currentTarget.scrollHeight;
    const maxScroll = totalHeight - e.currentTarget.offsetHeight;
    const delta = e.deltaY % 3 ? e.deltaY : e.deltaY * 2;
    if (contentNode.scrollTop + delta <= 0) {
      contentNode.scrollTop = 0;
      e.preventDefault();
    } else if (contentNode.scrollTop + delta >= maxScroll) {
      contentNode.scrollTop = maxScroll;
      e.preventDefault();
    }
    e.stopPropagation();
  };

  const getScrollStyles = () => {

    const scrollSize = scrollbarYWidth || 20;
    const marginKey = "marginRight";
    const innerContainerStyle = {
      height: props.heightRelativeToParent || props.flex ? "100%" : ""
    };
    //innerContainerStyle[ marginKey ] = -1 * scrollSize;
    const contentWrapperStyle = {
      height: props.heightRelativeToParent || props.flex ? "100%" : "",
      overflowY: props.freezePosition ? "hidden" : "visible"
    };
    //contentWrapperStyle[ marginKey ] = scrollbarYWidth ? 0 : scrollSize;

    return {
      innerContainer: innerContainerStyle,
      contentWrapper: contentWrapperStyle
    };
  };

  const getOuterContainerStyle = () => ({
    height: props.heightRelativeToParent || props.flex ? "100%" : ""
  });

  const getRootStyles = () => {
    const result: any = {};

    if (props.heightRelativeToParent) {
      result.height = props.heightRelativeToParent;
    } else if (props.flex) {
      result.flex = props.flex;
    }
    return result;
  };

  const enforceMinHandleHeight = calculatedStyle => {
    const minHeight = props.minScrollHandleHeight;
    if (calculatedStyle.height >= minHeight) {
      return calculatedStyle;
    }
    const diffHeightBetweenMinAndCalculated = minHeight - calculatedStyle.height;
    const scrollPositionToAvailableScrollRatio = (scrollPos / (contentHeight - visibleHeight)) || 0;
    const scrollHandlePosAdjustmentForMinHeight =
      diffHeightBetweenMinAndCalculated * scrollPositionToAvailableScrollRatio;
    const handlePosition = calculatedStyle.top - scrollHandlePosAdjustmentForMinHeight;

    return {
      height: minHeight,
      top: handlePosition
    };
  };

  const onScroll = event => {
    if (props.freezePosition) {
      return;
    }
    hideScrollThumb();
    adjustCustomScrollPosToContentPos(event.currentTarget.scrollTop);
    if (props.onScroll) {
      props.onScroll(event);
    }
  };
  const scrollStyles = getScrollStyles();
  const rootStyle = getRootStyles();
  const scrollHandleStyle = enforceMinHandleHeight(getScrollHandleStyle());

  return (
    <div
      className={ScrollbarClasses.ScrollBarRoot}
      style={rootStyle}
      ref={refs.rootRef}
    >
      <div
        className={ScrollbarClasses.OuterContainer}
        style={getOuterContainerStyle()}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onClick={onClick}
      >
        {hasScroll ? (
          <div className={classNames(ScrollbarClasses.Positioning, {
            [ ScrollbarClasses.PositioningOutSide ]: props.showHandleOutSide
          })}>
            <div
              ref={refs.customScrollbarRef}
              className={ScrollbarClasses.ScrollBar}
              key="scrollbar"
            >
              <div
                ref={refs.scrollHandleRef}
                className={ScrollbarClasses.ScrollHandle}
                style={scrollHandleStyle}
              />
            </div>
          </div>
        ) : null}
        <div
          ref={refs.innerContainerRef}
          className={ScrollbarClasses.InnerContainer}
          style={scrollStyles.innerContainer}
          onScroll={(e) => onScroll(e)}
        >
          <div
            ref={refs.contentWrapperRef}
            style={scrollStyles.contentWrapper as any}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}
export enum ScrollbarClasses {
  OuterContainer = "outer_container",
  ScrollBar = "scroll-bar",
  InnerContainer = "inner_container",
  Positioning = "positioning",
  ScrollHandle = "scroll-handle",
  ScrollBarRoot = "scroll_bar_root",
  PositioningOutSide = "positioning_outside",
}

const ensureWithinLimits = (value, min, max) => {
  min = !min && min !== 0 ? value : min;
  max = !max && max !== 0 ? value : max;
  if (min > max) {
    console.error("min limit is greater than max limit");
    return value;
  }
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};
function isEventPosOnDomNode(event, domNode) {
  const nodeClientRect = domNode.getBoundingClientRect();
  return isEventPosOnLayout(event, nodeClientRect);
}
function isEventPosOnLayout(event, layout) {
  return (
    event.clientX > layout.left &&
    event.clientX < layout.right &&
    event.clientY > layout.top &&
    event.clientY < layout.top + layout.height
  );
}
