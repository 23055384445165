import React                 from "react";
import { useMemo }           from "react";
import { Alignment }         from "../../../constants/Alignemnt";
import { BoxComponentProps } from "../../Box";
import { Box }               from "../../Box";
import { ChipsColors }       from "../../Chips";
import { Chips }             from "../../Chips";
import { Tooltip }           from "../../Tooltip";
import { useChoiceLabel }    from "../ListItemChoice/useChoiceLabel";

export interface ListItemMultiSelectProps extends BoxComponentProps {
  items: any[]
  visibleCount?: number
  options: any[]
  optionKey: string
  optionLabel: string
}
ListItemMultiChoice.defaultProps = {
  optionKey: "value",
  optionLabel: "label"
};
export function ListItemMultiChoice(props: ListItemMultiSelectProps) {
  const { className, items, visibleCount, options, optionKey, optionLabel, ...p } = props;
  const remainingItems = useMemo(() => items.length > visibleCount ? items.length - visibleCount : 0, [visibleCount, items]);
  const remainingTooltip = useMemo(() => items.slice(visibleCount).map(item => item.label).join(`\n`), [visibleCount, items]);
  let visibleItems = useMemo(() => {
    return visibleCount && items.length > visibleCount ? items.slice(0, visibleCount) : items;
  }, [items, visibleCount]);

  return <Box container className={className} alignItems={"center"} gap={"XXXS"} {...p}>
    {
      visibleItems.map((item, index) => {
        return <Choice
          key={index}
          item={item}
          optionKey={optionKey}
          optionLabel={optionLabel}
          options={options}
        />;
      })
    }
    {
      !!visibleCount && !!remainingItems &&
      <Tooltip title={remainingTooltip} alignment={Alignment.Top}>
        <Chips color={ChipsColors.Grey} label={`+${remainingItems}`}/>
      </Tooltip>
    }
  </Box>;
}

function Choice({
  item,
  options,
  optionKey,
  optionLabel
}) {
  return (
    <Tooltip title={item.label} alignment={Alignment.Top}>
      <Chips color={ChipsColors.Grey} label={useChoiceLabel(item, options, optionKey, optionLabel)}/>
    </Tooltip>
  );
}
