import React                              from "react";
import { JsonSectionProps, StatsSection } from "@relcu/ui";
import { useLeadStats }                   from "./useLeadStats";

export const LeadStatsSection = React.memo<JsonSectionProps>((props) => {
  const api = useLeadStats();

  let counters = [
    {
      header: "SMS",
      items: [
        {
          name: "Rec.",
          count: api.data.messageStats.received
        },
        {
          name: "Sent",
          count: api.data.messageStats.sent
        }
      ]
    },
    {
      header: "CALL",
      items: [
        {
          name: "In.",
          count: api.data.callStats.received
        },
        {
          name: "Out.",
          count: api.data.callStats.sent
        },
        {
          name: "Miss.",
          count: api.data.callStats.miss
        }
      ]
    },
    {
      header: "LOAN PROPOSAL",
      items: [
        {
          name: "Sent.",
          count: api.data.loanProposals
        }
      ]
    }
  ];

  return <StatsSection {...props} counters={counters}/>;

});

export default LeadStatsSection;
