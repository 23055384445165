import { gql }              from "@apollo/client";
import { ModalProps }       from "@relcu/ui";
import { Modal }            from "@relcu/ui";
import { ListClasses }      from "@relcu/ui";
import { Box }              from "@relcu/ui";
import { ListViewClasses }  from "@relcu/ui";
import { CircularLoader }   from "@relcu/ui";
import { SearchInput }      from "@relcu/ui";
import { EmptyList }        from "@relcu/ui";
import React                from "react";
import { FC }               from "react";
import { List }             from "@relcu/ui";
import { useAppraisalFees } from "./useAppraisalFees";

export interface AppraisalFeesProps extends Partial<ModalProps> {
  type: string,
  id: string,
  name: string,
  headers: any,
  fields: any
}

export const AppraisalFeesModal: FC<AppraisalFeesProps> = React.memo(function AppraisalFeesModal(props) {
  const {
    onChange,
    getColumnValue,
    close,
    loading,
    searchValue,
    edges,
    dataCount,
    count,
    currentPage,
    setCurrentPage
  } = useAppraisalFees(props);
  const { type, name, headers, fields } = props;
  return <Modal
    onClose={close}
    open={true}
    closeIcon={true}
    variant="large"
    disableBackdropClick={false}
    title={`${name} ${type} appraisal fees`}
  >
    <Box container direction={"column"} gap={"XXS"} flexGrow={1} className={ListViewClasses.ListView}>
      <Box container justify={!props.title ? "end" : "space-between"} flexShrink={0} alignItems={"center"}>
        {props.title &&
        <h2 className={ListClasses.ListHeader}>
          {props.title}
        </h2>
        }
      </Box>
      <Box container direction={"column"} flexGrow={1} gap={"XS"} style={{ overflow: "hidden" }}>
        <SearchInput
          flexGrow={1}
          clearable
          placeholder={"Search"}
          value={searchValue}
          style={{ padding: 0 }}
          onChange={onChange}/>
        {loading ?
          <CircularLoader alignSelf={"center"} style={{ flexBasis: "567px" }}/>
          :
          <Box container
               flex={1}
               justify={"start"}
               direction={"column"}
               gap={"XXS"}
               style={{ overflow: "hidden" }}>
            {
              !edges.length ?
                <EmptyList
                  justify={"center"}
                  flexBasis={"300px"}
                  title={"No search results"}
                  content={"Please type another keyword and hit enter"}
                  icon={"search"}/>
                :
                <List
                  headers={headers}
                  fields={fields}
                  objects={edges}
                  flex={1}
                  getColumnValue={getColumnValue}
                  total={dataCount}
                  count={count}
                  onPage={page => setCurrentPage(page)}
                  currentPage={currentPage}
                />
            }
          </Box>
        }
      </Box>
    </Box>
  </Modal>;
});

export default AppraisalFeesModal;

export const GET_APPRAISAL_FEES = gql`
  query GetAppraisalFees($where:AppraisalFeeWhereInput,$first:Int, $skip:Int ) {
    appraisalFees(where:$where, first:$first, skip:$skip, order: [state_ASC,county_ASC,objectId_ASC]){
      count
      edges{
        node{
          coOp
          condo
          county
          loanType
          id
          manufactured
          multiFamily
          singleFamily
          state
          mobile
          townhome
          pud
        }
      }
    }
  }
`;
