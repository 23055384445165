import { useEffect }                                 from "react";
import { useMemo }                                   from "react";
import { useCallback }                               from "react";
import { useReactiveVar }                            from "@apollo/client";
import { useQuery }                                  from "@apollo/client";
import { useMutation }                               from "@apollo/client";
import { useApolloClient }                           from "@apollo/client";
import { useAlert }                                  from "@relcu/ui";
import { confirmModal }                              from "@relcu/ui";
import { audioDevicesVar }                           from "../../../../reactiveVars";
import { microphoneAccessVar }                       from "../../../../reactiveVars";
import { usePhone }                                  from "../../../usePhone";
import { SubscriptionEvent }                         from "../../../../types/graphql-global-types";
import { SubscribeRecodingsVariables }               from "../__types__/SubscribeRecodings";
import { SubscribeRecodings }                        from "../__types__/SubscribeRecodings";
import { DeleteRecordingVariables }                  from "../__types__/DeleteRecording";
import { DeleteRecording }                           from "../__types__/DeleteRecording";
import { UpdateRecordingNameVariables }              from "../__types__/UpdateRecordingName";
import { UpdateRecordingName }                       from "../__types__/UpdateRecordingName";
import { VoiceMailRecordingsVariables }              from "../__types__/VoiceMailRecordings";
import { VoiceMailRecordings }                       from "../__types__/VoiceMailRecordings";
import { VoiceMailRecordings_recordings_edges_node } from "../__types__/VoiceMailRecordings";
import { VoiceTemplateUser }                         from "../__types__/VoiceTemplateUser";
import { SUBSCRIBE_RECORDINGS }                      from "../operations.graphql";
import { VOICEMAIL_RECORDINGS }                      from "../operations.graphql";
import { DELETE_RECORDING }                          from "../operations.graphql";
import { UPDATE_RECORDING_NAME }                     from "../operations.graphql";
import { VOICE_TEMPLATE_USER }                       from "../operations.graphql";

type Recording = VoiceMailRecordings_recordings_edges_node
export function useVoiceTemplateSection() {
  const phone = usePhone();
  const client = useApolloClient();
  const { data: { viewer: { user: { id } } } } = useQuery<VoiceTemplateUser>(VOICE_TEMPLATE_USER, {
    fetchPolicy: "cache-only"
  });
  const [updateName] = useMutation<UpdateRecordingName, UpdateRecordingNameVariables>(UPDATE_RECORDING_NAME);
  const [deleteRecording] = useMutation<DeleteRecording, DeleteRecordingVariables>(DELETE_RECORDING);
  const { data, subscribeToMore, refetch } = useQuery<VoiceMailRecordings, VoiceMailRecordingsVariables>(VOICEMAIL_RECORDINGS, { variables: { id, type: "voice_template" } });
  const { success, error } = useAlert();
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const audioDevices = useReactiveVar(audioDevicesVar);
  const hasMicrophoneIssue = !microphoneAccess || !audioDevices?.audiooutput?.length || !audioDevices?.audiooutput?.length;
  const api = {
    get busy() {
      return (phone?.active && phone?.active?.to !== "system:voice:template") || hasMicrophoneIssue;
    },
    get active() {
      return phone?.active?.to == "system:voice:template";
    },
    get voiceTemplates() {
      return (data?.recordings?.edges || []).map((({ node }) => node));
    },
    subscribeToMore() {
      return subscribeToMore<SubscribeRecodings, SubscribeRecodingsVariables>({
        document: SUBSCRIBE_RECORDINGS,
        variables: { id, type: "voice_template" },
        updateQuery(previousQueryResult, { subscriptionData: { data: { recordings: { event, node } } } }) {
          switch (event) {
            case SubscriptionEvent.UPDATE:
              refetch().catch(console.info);
              return previousQueryResult;
            case SubscriptionEvent.ENTER:
            case SubscriptionEvent.CREATE:
              return {
                ...previousQueryResult,
                recordings: {
                  ...previousQueryResult.recordings,
                  count: previousQueryResult.recordings.count + 1,
                  edges: [
                    {
                      node,
                      __typename: "RecordingEdge"
                    },
                    ...previousQueryResult.recordings.edges
                  ]
                }
              };
            case SubscriptionEvent.LEAVE:
              const data = client.readQuery<VoiceMailRecordings, VoiceMailRecordingsVariables>({ query: VOICEMAIL_RECORDINGS, variables: { id, type: "voice_template" } });
              client.writeQuery<VoiceMailRecordings, VoiceMailRecordingsVariables>({
                query: VOICEMAIL_RECORDINGS,
                variables: { id, type: "voice_template" },
                data: {
                  ...data,
                  recordings: {
                    ...data.recordings,
                    edges: [...data.recordings.edges.filter(edge => edge.node.id !== node.id)]
                  }
                }
              });
          }
        }
      });
    },
    async updateRecording({ id, name }: Recording) {
      try {
        await updateName({ variables: { id, name } });
        success("Voicemail template successfully updated.");
      } catch (e) {
        error(e);
      }
    },
    async deleteRecording({ id: recordingId, name }: Recording) {
      try {
        await confirmModal({
          title: "Delete outgoing voicemail record",
          subTitle: `Are you sure you want to delete the ${name} outgoing voicemail record`,
          content: `The record will deleted permanently.`,
          label: "DELETE"
        });
        await deleteRecording({ variables: { id: recordingId } });
      } catch (e) {
        console.error(e);
      }

    },
    toggleRecording() {
      !!api.active ? phone?.active.drop() : phone?.call({ to: "system:voice:template" });
    }
  };
  useEffect(api.subscribeToMore, [id]);
  return {
    ...api,
    voiceTemplates: useMemo(() => api.voiceTemplates, [data]),
    toggleRecording: useCallback(api.toggleRecording, [phone, api.active]),
    updateRecording: useCallback(api.updateRecording, [updateName]),
    deleteRecording: useCallback(api.deleteRecording, [deleteRecording])
  };
}
