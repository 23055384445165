import React                from "react";
import { SyntheticEvent }   from "react";
import { KeyboardEvent }    from "react";
import { GlobalClasses }    from "../../../../constants/GlobalClasses";
import { CommonClasses }    from "../../../../theme/classes";
import { classNames }       from "../../../../utils/classNames";
import { Box }              from "../../../Box";
import { IconType }         from "../../../Icon";
import { FontIcon }         from "../../../Icon";
import { Label }            from "../../../Label";
import { BaseInputClasses } from "../../BaseInput";
import { InputState }       from "../../Input";
import { SelectProps }      from "../Select";
import { SelectSizes }      from "../Select";
import { SelectColors }     from "../Select";
import { SelectVariants }   from "../Select";
import { SelectClasses }    from "../Select";

export interface SelectToggle extends Partial<SelectProps> {
  anchorBounding: DOMRect;
  disabledMod: boolean;
  keepErrorSpace?: boolean;
  showUnderLine?: boolean;
  count: number;
  message?: string;
  handleKeyPress(e: KeyboardEvent);
  checkValue();
  valueExist: boolean;
  handleClear?(e: SyntheticEvent);
  togglePopper(event);
  showSelectedField();
  icon?: IconType;
  className?: string;
}

export const SelectToggle = React.forwardRef(function SelectToggle(props: SelectToggle, ref: React.Ref<HTMLDivElement>) {
  const {
    mode,
    direction,
    label,
    showLabel,
    required,
    variant,
    color,
    size,
    anchorBounding,
    disabled,
    readOnly,
    state,
    disabledMod,
    keepErrorSpace,
    checkValue,
    valueExist,
    handleClear,
    handleKeyPress,
    count,
    togglePopper,
    message,
    showSelectedField,
    showUnderLine,
    icon,
    className
  } = props;

  return (mode === "button" ?
      <>
        <Box
          flex={1}
          container
          gap={direction == "column" ? "XXXS" : "XXS"}
          direction={direction}
          alignItems={!direction ? "center" : null}
          style={{ overflow: "hidden" }}
        >
          {
            label && showLabel &&
            <Label required={required} mLeft={direction == "column"}>
              {
                label
              }
            </Label>
          }
          <Box container direction={"column"} gap={"XXXS"}
               style={{ overflow: "inherit" }}
               flex={1}>
            <Box
              container
              alignItems={"center"}
              flex={1}
              gap={"XXS"}
              justify={"space-between"}
              className={classNames(SelectClasses.SelectButton, {
                [ SelectClasses.SelectButtonOutline ]: variant == SelectVariants.Outline,
                [ SelectClasses.SelectButtonGhost ]: variant == SelectVariants.Ghost,

                [ BaseInputClasses.Grey ]: color == SelectColors.Grey,
                [ BaseInputClasses.Primary ]: color == SelectColors.Primary,

                [ SelectClasses.Big ]: size == SelectSizes.Big,
                [ SelectClasses.Medium ]: size == SelectSizes.Medium,

                [ BaseInputClasses.Active ]: !!anchorBounding,
                [ BaseInputClasses.Disabled ]: disabled,
                [ BaseInputClasses.ReadOnly ]: readOnly,

                [ BaseInputClasses.Success ]: state == InputState.Success,
                [ BaseInputClasses.Error ]: state == InputState.Error,

                [ SelectClasses.SelectPlaceHolder ]: !checkValue()
              })}
              onKeyPress={disabledMod || count == 0 ? null : handleKeyPress}
              onClick={disabledMod || (count == 0 && !anchorBounding) ? (e) => e.stopPropagation() : togglePopper}
            >
              <span className={GlobalClasses.EllipsisFlex}>
                {
                  showSelectedField()
                }
              </span>
              <FontIcon className={CommonClasses.ClickableIcon}
                        type={!!!anchorBounding ? "keyboard_arrow_down" : "keyboard_arrow_up"}/>
              {
                !disabledMod &&
                <>
                  {
                    (state == InputState.Success || state == InputState.Error) &&
                    <FontIcon type={state == InputState.Success ? "done" : "warning"}/>
                  }
                </>
              }

            </Box>
            {
              state && message &&
              <Box className={BaseInputClasses.Message}>
                {message}
              </Box>//todo limit massage width
            }
          </Box>
        </Box>
      </>
      :
      <Box
        container
        flex={1}
        className={classNames(BaseInputClasses.Input, {
          [ SelectClasses.SelectButtonGhost ]: variant == SelectVariants.Ghost,
          [ BaseInputClasses.Disabled ]: disabled,
          [ BaseInputClasses.ReadOnly ]: readOnly,
          [ BaseInputClasses.Success ]: state == InputState.Success,
          [ BaseInputClasses.Error ]: state == InputState.Error,
          [ BaseInputClasses.Active ]: !!anchorBounding
        }, SelectClasses.Default, className)} gap={"XXXS"} direction={"column"}
        style={{ overflow: "hidden" }}>
        <Box container
             tabIndex={disabled || readOnly ? -1 : 0}
             aria-haspopup="listbox"
             aria-controls="menu2"
             direction={"row"}
             alignItems={"center"}
             gap={"XXS"}
             onKeyPress={handleKeyPress}
             onClick={disabledMod || (count == 0 && !anchorBounding) ? (e) => e.stopPropagation() : togglePopper}
             ref={ref}
             className={classNames(BaseInputClasses.MainContainer, {
               [ BaseInputClasses.UnderlineHidden ]: !showUnderLine
             })}
             style={{ overflow: "inherit" }}
        >
          {
            icon && <FontIcon className={CommonClasses.GrayIcon} type={icon}/>
          }
          <Box container direction={"column"} gap={"XXXS"} flex={1}
               className={BaseInputClasses.InputContainer}>
            {
              label &&
              <Label required={required}>
                {
                  label
                }
              </Label>
            }
            <Box container
                 alignItems={"center"}
                 className={!checkValue() ? SelectClasses.SelectPlaceHolder : SelectClasses.SelectText}
                 flexBasis={"24px"}>
              {
                showSelectedField()
              }
            </Box>
          </Box>
          <Box container className={BaseInputClasses.StateContainer} gap={"XXS"}
               alignSelf={!showUnderLine ? "end" : "center"}>
            {
              !disabledMod &&
              <>
                {
                  handleClear && valueExist &&
                  <FontIcon type="clear" onClick={handleClear}/>
                }
                <FontIcon type={!!!anchorBounding ? "keyboard_arrow_down" : "keyboard_arrow_up"}/>
                {
                  (state == InputState.Success || state == InputState.Error) &&
                  <FontIcon type={state == InputState.Success ? "done" : "warning"}/>
                }
              </>
            }
          </Box>
        </Box>
        {
          (keepErrorSpace || message) && typeof message == "string" &&
          <Box className={BaseInputClasses.Message}>
            {message}
          </Box>
        }
      </Box>
  );
});

SelectToggle.defaultProps = {
  keepErrorSpace: true,
  showUnderLine: true
};
