import React              from "react";
import { FC }             from "react";
import { useCallback }    from "react";
import { useState }       from "react";
import { Menu }           from "@relcu/ui";
import { Alignment }      from "@relcu/ui";
import { Popper }         from "@relcu/ui";
import { ButtonVariants } from "@relcu/ui";
import { Button }         from "@relcu/ui";

interface MoreFeesProps {
  disabled?: boolean
}

export const MoreFees: FC<MoreFeesProps> = React.memo(function MoreFees(props) {
  const { children, disabled } = props;
  const [bounding, setBounding] = useState(null);
  const togglePopper = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    setBounding(!bounding ? event.currentTarget.getBoundingClientRect() : null);
  }, [bounding]);
  return <>
    <Button variant={ButtonVariants.Ghost} icon={"add"} onClick={togglePopper} disabled={disabled}>
      More Fees
    </Button>
    <Popper
      open={!!bounding}
      anchorBounding={bounding}
      onClickAway={() => setBounding(null)}
      alignment={Alignment.BottomLeft}
      threshold={6}>
      <Menu
        type={"select"}
        height={44}
        role="listbox">
        {children}
      </Menu>
    </Popper>
  </>;
});
