import { loadScript } from "./loadScript";

export function loadGoogleMaps(key) {
  return new Promise<void>((accept,reject) => {
    let loaded = false;
    Object.assign(window, {
      googleMapsCallback() {
        loaded = true;
      }
    });
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=googleMapsCallback`).then(() => {
      if (loaded) {
        accept()
      }else{
        reject()
      }
    }).catch(reject);
  });
}
