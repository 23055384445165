import { gql }                       from "@apollo/client";
import { useQuery }                  from "@apollo/client";
import { toFirstLower }              from "@relcu/ui";
import { useSource }                 from "@relcu/ui";
import { useMemo }                   from "react";
import { useState }                  from "react";
import { useCallback }               from "react";
import { getSelectionSet }           from "../../../../utils/graphQlUtils";
import { usePermissions }            from "../../../AccessControl";
import { useJqlMutation }            from "../../Jql";
import { SearchWidgetTagsVariables } from "./__types__/SearchWidgetTags";
import { SearchWidgetTags }          from "./__types__/SearchWidgetTags";

export function useTagWidget() {
  const { $object: node } = useSource();
  const { canUpdate } = usePermissions(node);
  const [searchBounding, setSearchBounding] = useState(null);
  const [update] = useJqlMutation({
    operation: `update${node.__typename}`,
    fields: [
      {
        [ toFirstLower(node.__typename) ]: getSelectionSet(["tags"])
      }
    ],
    variables: {
      input: {
        type: `Update${node.__typename}Input!`
      }
    }
  }, {
    operationName: `Update${node.__typename}Tags`
  });

  const [q, setQ] = useState(String());
  const {
    data: { tags: searchTags = [] } = {}
  } = useQuery<SearchWidgetTags, SearchWidgetTagsVariables>(SEARCH_WIDGET_TAGS, {
    variables: {
      className: node.__typename,
      search: q
    }
  });

  const change = useCallback((tags: string[]) => update({
    variables: {
      input: {
        id: node.id,
        fields: {
          tags
        }
      }
    }
  }), [node.id]);

  const togglePopper = useCallback((e) => {
    setSearchBounding(searchBounding ? null : e.currentTarget.getBoundingClientRect());
  }, [setSearchBounding, searchBounding]);

  const handleClose = useCallback(() => {
    setSearchBounding(null);
    setQ("");
  }, [setSearchBounding, searchBounding]);

  const handleSelect = useCallback((item) => {
    handleClose();
    let contain = node.tags?.find(tag => tag == item);
    if (!contain) {
      return change([...node.tags, item]);
    }
  }, [handleClose, change, node.tags]);

  const handleDelete = useCallback((item) => {
    return change(node.tags.filter(tag => tag !== item));
  }, [change, node.tags]);

  const handleCreateNew = useCallback(() => {
    handleClose();
    const tag = q.trim();
    return tag ? handleSelect(tag) : null;
  }, [handleSelect, q]);

  const onType = useCallback(value => {
    if (value == null || value.length <= 25) {
      setQ(value || "");
    }
  }, []);
  const options = useMemo(() => searchTags.filter(t => !node.tags?.includes(t) && t?.includes(q)), [q, searchTags, node.tags]);
  return {
    togglePopper,
    handleClose,
    handleSelect,
    handleDelete,
    handleCreateNew,
    searchBounding,
    setSearchBounding,
    q,
    onType,
    options,
    get data() {
      return node.tags;
    },
    get disabled() {
      return !canUpdate;
    }
  };
}

const SEARCH_WIDGET_TAGS = gql`
  query SearchWidgetTags($className: String!, $search: String!){
    tags(className: $className, search: $search)
  }
`;
