import { TypographyLine }        from "@relcu/ui";
import { TypographyWeight }      from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { BadgeColors }           from "@relcu/ui";
import { BadgeVariants }         from "@relcu/ui";
import { timeTo }                from "@relcu/ui";
import { Badge }                 from "@relcu/ui";
import { NotificationIconColor } from "@relcu/ui";
import { Notification }          from "@relcu/ui";
import { NotificationHeader }    from "@relcu/ui";
import { NotificationContent }   from "@relcu/ui";
import { TypographyColor }       from "@relcu/ui";
import { TypographySize }        from "@relcu/ui";
import { Typography }            from "@relcu/ui";
import { CommonClasses }         from "@relcu/ui";
import { DateTime }              from "luxon";
import React                     from "react";
import { FC }                    from "react";
import { NotificationProps }     from "../NotificationBar";
import { NotificationIcons }     from "../NotificationBar";
import { NotificationTitles }    from "../NotificationBar";
import "../notification-bar.css";

export const BulkMailNotification: FC<NotificationProps> = React.memo(function BulkMailNotification(props) {
  const { node, indicateStatus, onNavigate, className, inApp } = props;
  const fontWeight = node.unread ? 500 : 400;

  return (
    <Notification onNavigate={onNavigate} className={className} style={{ fontWeight }}>
      <NotificationHeader inApp={inApp} title={NotificationTitles[ node.type ]} icon={NotificationIcons[ node.type ]}
                          color={inApp ? NotificationIconColor.White : NotificationIconColor.Secondary}>
        {
          indicateStatus &&
          <Box container gap={"XXXS"} alignItems={"center"}>
            <Typography size={TypographySize.TextSmall} color={TypographyColor.Secondary} noWrap>
              {timeTo(DateTime.fromJSDate(new Date(node.createdAt)))}
            </Typography>
            {node.unread && <Badge variant={BadgeVariants.Dot} color={BadgeColors.Error}/>}
          </Box>
        }
      </NotificationHeader>
      <NotificationContent inApp content={<Box container gap={"XXXS"}>
        {/*<Typography weight={inApp && TypographyWeight.Medium}*/}
        {/*            lineHeight={inApp && TypographyLine.LineBody1}*/}
        {/*            color={inApp && TypographyColor.White}*/}
        {/*            size={inApp ? TypographySize.Text : TypographySize.TextSmall}*/}
        {/*            className={CommonClasses.FlexEllipsis}>{node.record.subject}</Typography>*/}
        <Typography lineHeight={inApp && TypographyLine.LineBody1}
                    color={inApp && TypographyColor.White}
                    size={inApp ? TypographySize.Text : TypographySize.TextSmall}
                    weight={TypographyWeight.Regular}
                    className={CommonClasses.FlexEllipsis}>Operation complete</Typography>
      </Box>}/>
    </Notification>
  );
});
