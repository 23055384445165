import React                    from "react";
import { useCallback }          from "react";
import { FieldState }           from "@relcu/form";
import { Button }               from "../../..";
import { ButtonSizes }          from "../../..";
import { ButtonVariants }       from "../../..";
import { SwitchField }          from "../../..";
import { useBoxProps }          from "../../..";
import { FontIcon }             from "../../..";
import { Label }                from "../../..";
import { Box }                  from "../../..";
import { classNames }           from "../../..";
import { isEmail }              from "../../../utils/helpers";
import { BaseFieldClasses }     from "../BaseField";
import { useConsumeArrayField } from "../BaseField";
import { ChoiceField }          from "../ChoiceField";
import { EmailField }           from "../EmailField";
import { EmailsFieldProps }     from "./EmailsField";

export const EmailsEditField = React.memo(function EmailsEditField(props) {
  const { fields, input: { className, label, required, fullSize = true, schema, placeholder, targetClass, ...inputProps } } = useConsumeArrayField<EmailsFieldProps>();
  const classes = classNames(BaseFieldClasses.MultiField, {
    [ BaseFieldClasses.FullSize ]: fullSize
  }, className);
  // const changeOptOut = useCallback((changedIndex, changedName) => {
  //   fields.forEach((name: string, index: number) => {
  //     let value = fields.value[ index ];
  //     if (changedName === name) {
  //       fields.update(index, {
  //         ...value,
  //         optOut: true
  //       });
  //     } else if ((changedName !== name) && value.optOut) {
  //       fields.update(index, {
  //         ...value,
  //         optOut: false
  //       });
  //     }
  //   });
  // }, [fields]);
  const validateEmail = useCallback((value) => {
    if (required && !value) {
      return `Email is required`;
    }
    if (value && !isEmail(value)) {
      return `Email is invalid`;
    }
  }, [required]);
  const validateType = useCallback((value, all, meta: FieldState<string>) => {
    if (!value) {
      return `Email Type is invalid`;
    }
  }, []);
  const typeField = schema.fields.type;
  const optOutField = schema.fields.optOut;
  return (
    <Box className={classes} container {...useBoxProps(inputProps)}>
      <Box container direction={"column"} flex={1} gap={"XXXS"}>
        {
          label &&
          <Label required={required} mLeft>
            {label}
          </Label>
        }
        {
          fields.map((name, index) => {
            return <Box container justify={"space-between"} gap={"XS"} alignItems={"center"} key={name}>
              <Box container justify={"space-between"} gap={"XS"} alignItems={"center"} flexBasis={"50%"}>
                <ChoiceField flexGrow={1}
                             name={`${name}.type`}
                             validate={validateType}
                             flexShrink={0}
                             defaultValue={typeField.defaultValue || typeField.options[ 0 ]?.value}
                             initialValue={typeField.defaultValue || typeField.options[ 0 ]?.value}
                             options={typeField.options} width={200}/>
                <EmailField flexGrow={1} flexShrink={0} name={`${name}.address`}
                            validate={validateEmail} placeholder={placeholder}/>
              </Box>
              <Box container justify={"space-between"} gap={"XS"} alignItems={"center"} flexBasis={"50%"}>
                <SwitchField
                  type={"Checkbox"}
                  placeholder={"Opt-out"}
                  defaultValue={optOutField.defaultValue}
                  initialValue={optOutField.defaultValue}
                  name={`${name}.optOut`}
                  flexShrink={0}/>
                <FontIcon
                  type={"delete"}
                  alignSelf={"center"}
                  onClick={() => fields.remove(index)}
                  className={BaseFieldClasses.RemoveAction}/>
              </Box>
            </Box>;
          })
        }
      </Box>
      <Box flexShrink={0} container gap={"XXS"} justify={"end"} className={BaseFieldClasses.FieldActions}>
        <Button
          icon={"add"}
          onClick={() => fields.push({})}
          variant={ButtonVariants.Ghost}
          size={ButtonSizes.Small}>
          ADD EMAIL
        </Button>
      </Box>
    </Box>
  );
});
export default EmailsEditField;
