import React              from "react";
import { SyntheticEvent } from "react";
import { SetStateAction } from "react";
import { Dispatch }       from "react";

export function useImperativeState<S>(data: S, dispatch?: Dispatch<SetStateAction<S>> | ((state: S, event?: SyntheticEvent) => void)): [S, Dispatch<SetStateAction<S>> | ((state: S, event?: SyntheticEvent) => void)] {
  const [state, setState] = React.useState(data);
  const result = dispatch ? data : state;
  dispatch = dispatch || setState;
  return [result, dispatch];
}
