import { useMutation }        from "@apollo/client";
import { gql }                from "@apollo/client";
import { useAlert }           from "@relcu/ui";
import { useImperativeState } from "@relcu/ui";
import { NotesViewClasses }   from "@relcu/ui";
import { classNames }         from "@relcu/ui";
import { FontIcon }           from "@relcu/ui";
import { Keys }               from "@relcu/ui";
import { TextArea }           from "@relcu/ui";
import { EmptyList }          from "@relcu/ui";
import { NoteItem }           from "@relcu/ui";
import { CircularLoader }     from "@relcu/ui";
import { Box }                from "@relcu/ui";
import { useSource }          from "@relcu/ui";
import { useState }           from "react";
import React                  from "react";
import { FC }                 from "react";
import { JsonViewProps }      from "@relcu/ui";
import { usePermissions }     from "../../../AccessControl";
import { RelayQuery }         from "../../../Relay";
import { GetNotes }           from "./__types__/GetNotes";
import { SendNoteVariables }  from "./__types__/SendNote";
import { SendNote }           from "./__types__/SendNote";

export const NotesView: FC<JsonViewProps> = React.memo(function NotesView(props) {
  const { $object, $viewer } = useSource();
  const { canUpdate } = usePermissions($object);
  const [text, setText] = useState("");
  const { error } = useAlert();
  const [sendNote] = useMutation<SendNote, SendNoteVariables>(SEND_NOTE, {
    update: () => setText("")
  });
  const send = async () => {
    try {
      if (!canUpdate) {
        return;
      }
      await sendNote({
        variables: {
          input: {
            subject: {
              link: $object.id
            },
            owner: {
              link: $viewer.id
            },
            text
          }
        }
      });
    } catch (e) {
      console.error(e);
      error("Failed to make the note.");
    }
    setText("");
  };
  return <RelayQuery<GetNotes>
    className={"Note"}
    rowHeight={90}
    reverse
    query={{
      document: GET_NOTES,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        where: {
          subject: {
            have: {
              link: $object.id
            }
          }
        }
      }
    }}
    subscription={{
      document: SUBSCRIBE_NOTES,
      variables: {
        link: $object.id
      }
    }}
    render={(renderProps) => {
      const {
        scrollContainerRef,
        beforeLoaderRef,
        register,
        data: { notes: { edges = [], pageInfo } }
      } = renderProps;
      return (
        <Box container flex={1} direction={"column"} className={NotesViewClasses.NotesView}>
          <Box container direction={"column"} flexGrow={1} className={NotesViewClasses.NotesViewItems}
               ref={scrollContainerRef}>
            <Box flex={"1 1 auto"} justify={"center"}>{
              pageInfo?.hasPreviousPage &&
              <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>
            }
            </Box>
            {
              !!edges.length ?
                edges?.map(({ node, cursor }, index) => (
                  <NoteItem
                    key={index}
                    text={node.text}
                    date={node.createdAt}
                    owner={node.owner}
                    ref={e => register(e, node.objectId)}
                  />
                )) :
                <Box flex={"1 1 auto"} justify={"center"}>
                  <EmptyList icon={"rc_note"} content={"Click to \"Add note...\" below to get started"}
                             title={"No notes"}
                             alignSelf={"center"}/>
                </Box>
            }
          </Box>
          <TextArea
            maxLength={3000}
            showCharLimit
            name={"content"}
            value={text}
            placeholder={"Add note..."}
            onChange={setText}
            disabled={!canUpdate}
            onKeyPress={async (e) => {
              if (e.key == Keys.Enter && !e.shiftKey && text?.trim()) {
                await send();
              }
            }}
          />
          <FontIcon type="send" className={classNames(NotesViewClasses.NoteViewSubmit, {
            [ NotesViewClasses.Disabled ]: !text?.trim() || !canUpdate
          })} onClick={text?.trim() ? send : null}/>
        </Box>
      );
    }}
  />;
});

const GET_NOTES = gql`
  query GetNotes($where:NoteWhereInput! ,$last:Int,$before:String,$after:String) {
    notes(
      last: $last,
      before: $before,
      after: $after,
      order: [createdAt_ASC],
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          objectId
          objectIcon
          objectName
          text
          createdAt
          owner {
            id
            objectId
            objectIcon
            objectName
          }
        }
      }
    }
  }
`;
const SEND_NOTE = gql`
  mutation SendNote($input:CreateNoteFieldsInput!){
    createNote(input: {
      fields: $input
    }){
      clientMutationId
      note {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;
const SUBSCRIBE_NOTES = gql`
  subscription SubscribeNotes($link: ID) {
    notes(where: {subject: {have: {link: $link}}}) {
      event
      node {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;
