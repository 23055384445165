import React                  from "react";
import { useMemo }            from "react";
import { Box }                from "../../Box";
import { Label }              from "../../Label";
import { useConsumeField }    from "../BaseField";
import { BaseFieldClasses }   from "../BaseField";
import { LinkExternal }       from "../../LinkExternal";
import { LinkExternalColors } from "../../LinkExternal";
import { useSource }          from "../../Layout/JsonPage";
import { handlebars }         from "../../../utils/helpers";

export const UrlReadField = React.memo(function UrlReadField(props) {
  const { $object, $viewer } = useSource();
  const {
    input: {
      template,
      label,
      required,
      readPlaceholder,
      value,
      defaultLabel,
      ...textProps
    }
  } = useConsumeField();
  const templated = useMemo(() => {
    if (template?.url) {
      const source = { ...$object, $viewer };
      return {
        url: handlebars(template.url, source),
        label: handlebars(template.label || template.url, source)
      };
    }
    return null;
  }, [$object, template]);
  return (
    <Box
      container
      direction={"column"}
      gap={"XXXS"}
      className={BaseFieldClasses.ReadField}
      alignSelf={"start"}
      {...textProps}
    >
      <Label required={required}>{label}</Label>
      {templated?.url &&
      <Box>
        <LinkExternal to={templated.url} color={LinkExternalColors.Primary} allowCopy>
          {templated.label}
        </LinkExternal>
      </Box>
      }
      {(!templated && value) &&
      <Box>
        <LinkExternal to={value} color={LinkExternalColors.Primary} allowCopy>
          {defaultLabel ? defaultLabel : value}
        </LinkExternal>
      </Box>
      }
      {(!templated?.url && !value) &&
      <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
      }
    </Box>
  );
});

export default UrlReadField;
