import { UseFieldConfig }  from "@relcu/form";
import React               from "react";
import { FC }              from "react";
import { SelectProps }     from "../../Input/Select";
import { BaseFieldProps }  from "../BaseField";
import { BaseField }       from "../BaseField";
import { ChoiceReadField } from "../ChoiceField/ChoiceReadField";
import TreeChoiceEditField from "./TreeChoiceEditField";

export type TreeChoiceFieldProps = BaseFieldProps & SelectProps & UseFieldConfig<any>;

export const TreeChoiceField: FC<TreeChoiceFieldProps> = React.memo(function TreeChoiceField(props) {
  return (
    <BaseField {...props} readView={<ChoiceReadField/>} editView={<TreeChoiceEditField/>}/>
  );
});
