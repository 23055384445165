import { getIn }                from "@relcu/form";
import { transformNameToLabel } from "@relcu/ui";
import { FieldProps }           from "@relcu/ui";
import { List }                 from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { useEffect }            from "react";
import React                    from "react";
import { FC }                   from "react";
import { getHumanized }         from "../../../utils/schemaUtils";
import { SearchModalClasses }   from "./SearchModalClasses";

export interface SearchResultProps {
  onRowClick: (item) => void,
  classNames: string[],
  fields?: {
    [ __typename: string ]: FieldProps[]
  }
  getObjects(__typename: string): any[]
  getCount(__typename: string): number,
  onPage(__typename: string, page: number),
}
export const SearchResult: FC<SearchResultProps> = React.memo(props => {
  function updateModalContainerSize() {
    const resultSection: any = document.querySelector(`.${SearchModalClasses.Result}`);
    resultSection?.attributeStyleMap.set("min-height", 0);
  }

  useEffect(() => {
    window.addEventListener("resize", updateModalContainerSize);
    const resultSection: any = document.querySelector(`.${SearchModalClasses.Result}`);
    resultSection?.attributeStyleMap.set("min-height", (CSS as any).px(resultSection.clientHeight));
    return () => window.removeEventListener("resize", updateModalContainerSize);
  }, [props.classNames]);//todo check

  return (
    <Box container direction={"column"} gap={"XXL"} className={SearchModalClasses.Result}>
      {props.classNames.map(type => {
        const count = props.getCount(type);
        if (!count) {
          return null;
        }
        return (
          <Box container direction={"column"} key={type} gap={"XS"} className={SearchModalClasses.ResultSection}>
            <span>{transformNameToLabel(type)}</span>
            <List onRowClick={props.onRowClick}
                  getColumnValue={(row, field) => {
                    return getHumanized(type, field.name, getIn(row, field.name));
                  }
                  }
                  fields={props.fields[ type ]}
                  objects={props.getObjects(type)}
                  total={count}
                  onPage={page => props.onPage(type, page)}
                  flex={1}/>
          </Box>
        );
      })}
    </Box>
  );
});

SearchResult.defaultProps = {
  fields: {
    Contact: [
      {
        name: "objectIcon",
        component: "ListItemImage",
        label: "objectIcon",
        alt: "objectName"
      },
      {
        name: "objectName",
        component: "ListItemTitle",
        label: "Contact name",
        flexBasis: "200px"
      },
      {
        name: "tags",
        component: "ListItemTags",
        label: "Tag",
        flexGrow: 1,
        properties: {
          showLength: 2
        }
      },
      {
        name: "createdAt",
        component: "ListItemDate",
        label: "Created on",
        properties: {
          format: "dateWithTime"
        }
      }
    ],
    User: [
      {
        name: "objectIcon",
        component: "ListItemImage",
        label: "objectIcon",
        alt: "objectName"
      },
      {
        name: "objectName",
        component: "ListItemTitle",
        label: "User name",
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: "200px"
      },
      {
        name: "team",
        component: "ListItemText",
        label: "Team",
        flexGrow: 0,
        flexBasis: "200px"
      },
      {
        name: "role",
        component: "ListItemText",
        label: "Role",
        flexGrow: 1,
        flexBasis: "200px"
      },
      {
        name: "createdAt",
        component: "ListItemDate",
        label: "Created on",
        properties: {
          format: "dateWithTime"
        }
      }
    ],
    Lead: [
      {
        name: "objectIcon",
        component: "ListItemImage",
        label: "objectIcon",
        alt: "objectName",
        flexBasis: "32px",
        flexShrink: 0
      },
      {
        name: "objectName",
        component: "ListItemTitle",
        label: "Lead name",
        flexBasis: "80px",
        flexGrow: 1,
        flexShrink: 0
      },
      // {
      //   name: "loanProgram",
      //   component: "ListItemText",
      //   properties: {
      //     tiny: true
      //   },
      //   flexBasis: "130px",
      //   flexGrow: 1
      // },
      {
        name: "loanAmount",
        component: "ListItemCurrency",
        label: "Loan amount",
        flexBasis: "60px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "leadSource.title",
        component: "ListItemText",
        label: "Lead source",
        properties: {
          tiny: true
        },
        flexBasis: "60px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "leadStatus.status",
        component: "ListItemChips",
        label: "Lead status",
        flexBasis: "100px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "assignedTo.objectIcon",
        component: "ListItemImage",
        label: "Assigned To",
        alt: "assignedTo.objectName",
        flexBasis: "24px",
        flexShrink: 0
      },
      {
        name: "createdAt",
        component: "ListItemDate",
        label: "Created on",
        properties: {
          format: "dateWithTime"
        },
        flexBasis: "110px",
        flexShrink: 0
      }
    ],
    Task: [
      {
        name: "title",
        component: "ListItemTitle",
        label: "Task name",
        flexBasis: "100px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "subject.objectIcon",
        component: "ListItemImage",
        label: "objectIcon",
        alt: "subject.objectName",
        flexBasis: "32px",
        flexShrink: 0
      },
      {
        name: "subject.objectName",
        component: "ListItemText",
        flexBasis: "100px",
        flexGrow: 2,
        flexShrink: 0
      },
      {
        name: "assignedTo.objectIcon",
        component: "ListItemImage",
        label: "Assigned To",
        alt: "assignedTo.objectName",
        flexBasis: "24px",
        flexShrink: 0
      },
      {
        name: "createdAt",
        component: "ListItemDate",
        label: "Created on",
        properties: {
          format: "dateWithTime"
        },
        flexBasis: "110px",
        flexShrink: 0
      }
    ],
    ReferralPartner: [
      {
        name: "objectIcon",
        component: "ListItemImage",
        label: "objectIcon",
        alt: "objectName",
        flexBasis: "32px",
        flexShrink: 0
      },
      {
        name: "objectName",
        component: "ListItemTitle",
        label: "Lead name",
        flexBasis: "80px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "assignedTo.objectIcon",
        component: "ListItemImage",
        label: "Assigned To",
        alt: "assignedTo.objectName",
        flexBasis: "24px",
        flexShrink: 0
      },
      {
        name: "createdAt",
        component: "ListItemDate",
        label: "Created on",
        properties: {
          format: "dateWithTime"
        },
        flexBasis: "110px",
        flexShrink: 0
      }
    ],
    RelcuLoan: [
      {
        name: "borrowerFullName",
        component: "ListItemTitle",
        label: "Borrower name",
        flexBasis: "80px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "loanNumber",
        component: "ListItemText",
        label: "Loan Number",
        flexBasis: "60px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "currentMilestone",
        component: "ListItemText",
        label: "Current milestone",
        flexBasis: "60px",
        flexGrow: 1,
        flexShrink: 0
      },
      {
        name: "createdAt",
        component: "ListItemDate",
        label: "Created on",
        properties: {
          format: "dateWithTime"
        },
        flexBasis: "110px",
        flexShrink: 0
      }
    ],
  }
};
