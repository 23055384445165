import React                 from "react";
import { FC }                from "react";
import { classNames }        from "../../../utils/classNames";
import { BoxComponentProps } from "../../Box";
import { StepInterface }     from "../../Step";
import { Step }              from "./Step";
import { StepperClasses }    from "./StepperClasses";

export interface StepperProps extends BoxComponentProps {
  steps?: Omit<StepInterface, "component">[];
  onStepChange?(index: number);
  step?: number;
  edit?: boolean;
}
//todo add story for Stepper
export const Stepper: FC<StepperProps> = React.memo(function Stepper(props) {
  const { steps, step, onStepChange, edit, className, children } = props;
  const classes = classNames(StepperClasses.Stepper, className);
  return <ul className={classes}>
    {
      children ??
      steps?.map((s, i) => {
        return <Step
          label={s.title}
          step={s.step}
          active={i == step}
          past={i < step}
          edit={edit}
          onClick={() => onStepChange(i)}
          key={i}/>;
      })
    }
  </ul>;
});

Stepper.defaultProps = {
  edit: false
};