import { Link }                                     from "@relcu/react-router";
import { useSource }                                from "@relcu/ui";
import { CommonClasses }                            from "@relcu/ui";
import React                                        from "react";
import { TimelineItem }                             from "@relcu/ui";
import { TypographyColor }                          from "@relcu/ui";
import { Typography }                               from "@relcu/ui";
import { TimelineItemSubject }                      from "@relcu/ui";
import { TimelineItemIconColors }                   from "@relcu/ui";
import { TimelineItemIcon }                         from "@relcu/ui";
import { ProposalActivity as ProposalActivityType } from "../../../../__types__/ProposalActivity";

export interface ProposalActivityProps extends ProposalActivityType {
  createdAt: string;
}

export const ProposalActivity = React.memo<ProposalActivityProps>(function ProposalActivity(props) {
  const { loanEstimate, proposalGeneratedBy, proposalSubject, createdAt } = props;
  const initiatorName = proposalGeneratedBy ? proposalGeneratedBy.objectName : "Unknown";
  const leadObjectName = proposalSubject ? proposalSubject.objectName : "Unknown";
  const { $object } = useSource();

  if ($object.__typename === "User") {
    return <TimelineItem
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"rc_price"}/>}
      primary={<TimelineItemSubject>
        <Link to={`/user/${proposalGeneratedBy.objectId}/details`}>
          <Typography className={CommonClasses.ClickableIcon}>{initiatorName}</Typography>
        </Link>
        <Typography
          color={TypographyColor.Secondary}>generated a loan proposal for</Typography>
        <Link to={`/lead/${proposalSubject.objectId}/loan-proposal-beta?from=${loanEstimate.objectId}`}>
          <Typography className={CommonClasses.ClickableIcon}>{leadObjectName}</Typography>
        </Link>
      </TimelineItemSubject>}
      creationDate={createdAt}
    />;
  } else {
    return <TimelineItem
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"rc_price"}/>}
      primary={<TimelineItemSubject>
        <Link to={`/lead/${proposalSubject.objectId}/loan-proposal-beta?from=${loanEstimate.objectId}`}>
          <Typography className={CommonClasses.ClickableIcon}>{leadObjectName}</Typography>
        </Link>
        <Typography
          color={TypographyColor.Secondary}> has a loan proposal generated by</Typography>
        <Link to={`/user/${proposalGeneratedBy.objectId}/details`}>
          <Typography className={CommonClasses.ClickableIcon}>{initiatorName}</Typography>
        </Link>
      </TimelineItemSubject>}
      creationDate={createdAt}
    />;
  }

});