import { Button }                  from "@relcu/ui";
import { TypographyWeight }        from "@relcu/ui";
import { TypographySize }          from "@relcu/ui";
import React                       from "react";
import { FC }                      from "react";
import { ChipsColors }             from "@relcu/ui";
import { CopyText }                from "@relcu/ui";
import { ListItemText }            from "@relcu/ui";
import { TypographyVariant }       from "@relcu/ui";
import { Typography }              from "@relcu/ui";
import { Box, ListItemChips }      from "@relcu/ui";
import { ListRowColors }           from "@relcu/ui";
import { ListRow }                 from "@relcu/ui";
import { classNames }              from "@relcu/ui";
import { ListClasses }             from "@relcu/ui";
import { MailDomain_dns }          from "../../../__types__/MailDomain";
import { MailDomainDialogClasses } from "../../../MailDomainDialog";

export interface DnsSectionProps {
  dns: MailDomain_dns[];
  onDnsValidate();
}

export const DnsSection: FC<DnsSectionProps> = React.memo(function DnsSection(props) {
  const { dns } = props;
  return (
    <>
      <Box>
        <Typography variant={TypographyVariant.h3} size={TypographySize.SubtitleSmall}
                    weight={TypographyWeight.Medium}>Domain verification</Typography>
      </Box>
      <Box container direction={"column"} gap={"XXS"}>
        <Typography>1. Add these records to your DNS cloud.</Typography>
        <Typography>2. Once added, press Verify.</Typography>
      </Box>
      <Box gap={"XXS"} direction={"column"} container flexGrow={1}>
        <ListRow
          className={classNames(ListClasses.ListRowHead, {})}
          gap={"XXS"}
          color={ListRowColors.White} container
          justify={"space-between"}>
          <ListItemText
            tiny={true}
            text={"Type"}
            flexGrow={1}
            flexShrink={0}
            flexBasis={"30px"}
          />
          <ListItemText
            tiny={true}
            text={"Host"}
            flexGrow={1}
            flexShrink={0}
            flexBasis={"100px"}
          />
          <ListItemText
            tiny={true}
            text={"Value"}
            flexGrow={1}
            flexShrink={1}
            flexBasis={"100px"}
          />
          <ListItemText
            tiny={true}
            text={"Status"}
            flex={"0 0 140px"}
          />
        </ListRow>
        {dns.map((dns, index) => (
          <ListRow
            key={index}
            className={MailDomainDialogClasses.DnsRow}
            gap={"XXS"}
            justify={"space-between"}>
            <ListItemText
              tiny={true}
              text={
                <Typography variant={TypographyVariant.span}>
                  {dns.type.toUpperCase()}
                </Typography>
              }
              flexGrow={1}
              flexShrink={0}
              flexBasis={"30px"}
            />
            <ListItemText
              tiny={true}
              text={
                <CopyText value={dns.host}>
                  <Typography variant={TypographyVariant.span}>
                    {dns.host}
                  </Typography>
                </CopyText>
              }
              flexGrow={1}
              flexShrink={0}
              flexBasis={"100px"}
            />
            <ListItemText
              tiny={true}
              text={
                <CopyText value={dns.data}>
                  <Typography variant={TypographyVariant.span}>
                    {dns.data}
                  </Typography>
                </CopyText>
              }
              flexGrow={1}
              flexShrink={1}
              flexBasis={"100px"}
            />
            <ListItemChips
              color={dns.valid ? ChipsColors.Success : ChipsColors.Error}
              value={dns.valid ? "Verified" : "Not verified"}
              flex={"0 0 140px"}
            />
          </ListRow>
        ))}
      </Box>
      {dns.some((dns) => !dns.valid) &&
        <Box container justify={"end"}>
          <Button onClick={props.onDnsValidate}> Validate</Button>
        </Box>
      }
    </>
  );
});
