import { useMatch }            from "@relcu/react-router";
import { useMemo }             from "react";
import { useCallback }         from "react";
import { useConstant }         from "../../../hooks/useConstant";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { Condition }           from "../../../utils/Condition";
import { TopLevelCondition }   from "../../../utils/Condition";
import { useLazyCondition }    from "../../Where";
import { JsonPageProps }       from "./JsonPage";
import { useSource }           from "./useSource";

export function useJsonPage(props: JsonPageProps) {
  const { page } = props;
  // const where = useConstant(() => new Condition());
  const evaluate = useLazyCondition();
  const match = useMatch("/:typename/:objectId/:tabId/*");
  const windowDimensions = useWindowDimensions();
  const api = {
    getSelectedTab() {
      return (match && match.params) ? page.tabs.find((t) => {
        return t.path == match.params.tabId;
      }) : undefined;
    },
    getTabs() {
      return page.tabs.filter(tab => {
        if (tab.conditions) {
          return evaluate({ conditions: tab.conditions }).apply;
        }
        return true;
      });
    },
    get isSmall() {
      return windowDimensions.width < 1920;
    },
    get widgets() {
      return page.widgets;
    },
    get header() {
      return page.header;
    },
    get width() {
      return windowDimensions.width;
    },
    get showWidgets() {
      const widgets = selectedTab?.properties?.widgets ?? true;
      return widgets && api.width >= 1680 && api?.widgets.length > 0;
    },
    get layout() {
      return {
        className:page.className,
        objectIcon: page.objectIcon,
        objectName: page.objectName
      };
    }
  };

  const selectedTab = useMemo(api.getSelectedTab, [match, page.tabs]);
  const tabs = useMemo(api.getTabs, [page.tabs, evaluate]);

  return {
    ...api,
    tabs,
    selectedTab
  };
}
