import React                        from "react";
import { RsRefForwardingComponent } from "../../@types/common";
import { Typography }               from "../../index";
import { Cell }                     from "../../TableHorizontal";
import { CellProps }                from "../../TableHorizontal/Cell";
import { useClassNames }            from "../../utils";

export interface EmptyCellProps extends CellProps {
  children?: React.ReactNode;
}

export const EmptyCell: RsRefForwardingComponent<"div", EmptyCellProps> = React.forwardRef((props: EmptyCellProps, ref) => {
  const {
    as: Component = Typography,
    className,
    classPrefix = "cell-empty",
    helperText,
    status,
    span,
    space,
    revers,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Cell status={status} helperText={helperText} space={true} span={span} className={classes}>
    <Component
      {...rest}>
    </Component>
  </Cell>;
});
