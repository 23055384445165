import { useState }                 from "react";
import { useContext }               from "react";
import React                        from "react";
import { ComponentType }            from "react";
import { ValueEditorProps }         from "react-querybuilder";
import { MultiInput }               from "@relcu/ui";
import { MultiSelect }              from "@relcu/ui";
import { Select }                   from "@relcu/ui";
import { Input }                    from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { StatusField }              from "../../../../../../../../../QueryBuilder";
import { NumberField }              from "../../../../../../../../../QueryBuilder";
import { PointerValueField }        from "../../../../../../../../../QueryBuilder";
import { SwitchDatePickerField }    from "../../../../../../../../../QueryBuilder";
import { TagsField }                from "../../../../../../../../../QueryBuilder/Fields/TagsField";
import { DistributionQueueContext } from "../../../../../DistributionQueueDialog";

export const FilterValueEditor: ComponentType<ValueEditorProps & {
  getFieldSchema: any
}> = React.memo(function FilterValueEditor(props) {
  const { value, handleOnChange, operator, field } = props;
  const { getFieldSchema } = useContext(DistributionQueueContext);
  function renderValue() {
    if (operator == "notNull" || operator == "is null") {
      return <Box flex={"0 1 30%"}/>;/*todo remove width*/
    }
    const schema = getFieldSchema(field);
    const type = field !== "tags" ? schema[ "type" ] : "Tag";

    switch (type) {
      case "Pointer":
        return <PointerValueField
          flex={"0 1 32%"}
          value={value || []}
          targetClass={schema.targetClass}
          onChange={handleOnChange} thumbnail={true}/>;
      case "Number":
        const parsedValue = Number(props.value);//to avoid strings already exists in db
        return <NumberField flex={"0 1 32%"} value={isNaN(parsedValue) ? 0 : parsedValue}
                            onChange={handleOnChange}/>;
      case "Boolean": {
        return <Select
          value={value}
          options={[
            {
              value: true,
              label: "True"
            },
            {
              value: false,
              label: "False"
            }
          ]}
          direction={"column"}
          style={{ padding: "0 8px" }}
          flex={"0 1 32%"}
          placeholder={"Select value"}
          onChange={(selected) => handleOnChange(selected.value)}
        />;
      }
      case "Choice": {
        return <MultiSelect
          showSelectedCount
          showSelectionActions
          getOptionValueByKey
          value={value || []}
          options={schema.options}
          direction={"column"}
          style={{ padding: "0 8px" }}
          flex={"0 1 32%"}
          placeholder={"Select value"}
          onChange={handleOnChange}
        />;
      }
      case "Status": {
        return <StatusField field={field} key={field} operator={operator} value={value} schema={schema} onChange={handleOnChange}/>;
      }
      case "Date":
        return <SwitchDatePickerField flex={"0 1 32%"}
                                      value={props.value}
                                      type={[">","<"].includes(operator) ? "datetime" : (["during", "notDuring"].includes(operator) ? "calendar" : "date" )}
                                      onChange={(date) => props.handleOnChange(date)}/>;
      case "Tag": {
        return <TagsField targetClass={"Lead"} value={props.value || []} onChange={props.handleOnChange}/>;
      }
      default:
        if (operator == "in" || operator == "notIn") {
          const value = typeof props.value == "string" ? props.value ? [props.value] : [] : props.value;
          return <MultiInput flex={"0 1 32%"} value={value} placeholder={"Type and press Enter"}
                             onChange={props.handleOnChange}/>;
        }
        return <Input flex={"0 1 32%"} value={props.value} placeholder={"Type value"} onChange={props.handleOnChange}/>;
    }
  }
  return renderValue();
});
