import React                  from "react";
import { FC }                 from "react";
import { useState }           from "react";
import { useMounted }         from "../../..";
import { BoxComponentProps }  from "../../..";
import { useImperativeState } from "../../..";
import { Input }              from "../../..";
import { Select }             from "../../..";
import { BaseInputProps }     from "../../..";
import { Box }                from "../../Box";

export interface RelativeDateProps extends Omit<BoxComponentProps, "onChange">, BaseInputProps {
  value?: string;
}

export const RelativeDate: FC<RelativeDateProps> = React.memo(function RelativeDate(props) {
  const { value, onChange, ...p } = props;
  const [date, setDate] = useImperativeState(value, onChange);
  const [t, setT] = useState(() => {
    const parsed = value?.match(/^([-]|[+])(\d+)*([a-z]+)/);
    if (parsed) {
      return `${parsed[ 1 ]}${parsed[ 3 ]}`;
    }
    return "-hrs";
  });
  const [n, setN] = useImperativeState(format(date), setDate);

  useMounted(() => {
    setDate(parse(n, t));
  }, [n, t]);

  return (
    <Box container gap="XS" {...p}>
      <Input
        placeholder={"Enter value"}
        leaveStateSpace={false}
        flexBasis="25%"
        maskAttr={{
          alwaysShowMask: false,
          maskPlaceholder: "",
          mask: "999"
        }}
        value={n}
        onChange={setN}
      />
      <Select placeholder={"Select value"} flexBasis="75%" options={options} value={t}
              onChange={({ value }) => setT(value)}/>
    </Box>
  );
});

const format = (value) => {
  return String(value ?? "").replace(/[^.\-\d]/g, "").replace(/^-?(\d*\.?)|(\d*)\.?/g, "$1$2");
};
const parse = (value, t) => {
  const i = t.substring(0, 1);
  const time = t.substring(1);
  return value ? `${i}${value}${time}` : null;
};

const options = [
  {
    label: "Seconds ago",
    value: "-seconds"
  },
  {
    label: "Minutes ago",
    value: "-minutes"
  },
  {
    label: "Hours ago",
    value: "-hrs"
  },
  {
    label: "Days ago",
    value: "-days"
  },
  {
    label: "Weeks ago",
    value: "-weeks"
  },
  {
    label: "Years ago",
    value: "-years"
  },

  {
    label: "Seconds from now",
    value: "+seconds"
  },
  {
    label: "Minutes from now",
    value: "+minutes"
  },
  {
    label: "Hours from now",
    value: "+hrs"
  },
  {
    label: "Days from now",
    value: "+days"
  },
  {
    label: "Weeks from now",
    value: "+weeks"
  },
  {
    label: "Years from now",
    value: "+years"
  }
];
