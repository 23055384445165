import React           from "react";
import { FC }          from "react";
import { useEffect }   from "react";
import { useCallback } from "react";
import { useRef }      from "react";
import { makeVar }     from "@apollo/client";
import { ReactiveVar } from "@apollo/client/cache/inmemory/reactiveVars";
import { useSource }   from "@relcu/ui";
import { useConstant } from "@relcu/ui";

type Lead = any;
export interface PersistData {
  insurancePrepaymentMonths: number;
  taxPrepaymentMonths: number;
  interestPrepaymentDays: number;
  titleInsurance?: number,
  ownersTitle?: number,
  recordingCharges?: number,
  transferTax?: number,
  customTitleEnabled?: boolean
  discountPoints?: number
  lenderCredits?: number
  sellerConcession?: number
  earnestMoneyPaid?: number
  waive?: boolean
}
export interface Persist {
  data: PersistData,
  offers: Offers,
  leadDataVar: ReactiveVar<any>,
  fees: {
    closingFeesVar: ReactiveVar<string[]>
    prepaymentFeesVar: ReactiveVar<string[]>
    toggleClosingFees(selected: string)
    togglePrepaymentFees(selected: string)
  }
  changeData(data)
}

export interface Offers {
  count: number,
  loading: boolean
}

export interface PersistProps {
  offers?: Offers;
  criteriaData?: Lead;
}

export const PersistContext = React.createContext<Persist>(null);
export const PersistProvider: FC<PersistProps> = React.memo(function PersistProvider(props) {
  const { children, offers, criteriaData } = props;
  const { $settings: { pricing } } = useSource();
  const closingFeesVar = useConstant(() => makeVar<string[]>([]));
  const lead = criteriaData();
  const prepaymentFeesVar = useConstant(() => makeVar<string[]>([]));
  const {
    closingFees: {
      insurancePrepaymentMonths,
      taxPrepaymentMonths,
      interestPrepaymentDays
    } = {} as any
  } = pricing;

  const persistRef = useRef<PersistData>({
    insurancePrepaymentMonths: insurancePrepaymentMonths,
    taxPrepaymentMonths: taxPrepaymentMonths,
    interestPrepaymentDays: interestPrepaymentDays,
    earnestMoneyPaid: 0,
    sellerConcession: 0,
    waive: false
  });

  useEffect(() => {
    const prev = closingFeesVar();
    if (lead?.property?.type?.includes("condo")) {
      if (!prev.includes("condoCertificateFee")) {
        closingFeesVar([...prev, "condoCertificateFee"]);
      }
    } else {
      if (prev.includes("condoCertificateFee")) {
        closingFeesVar(prev.filter(el => el != "condoCertificateFee"));
      }
    }
  }, [lead?.property]);

  useEffect(() => {
    const prev = closingFeesVar();
    if (!(lead?.loanPurpose == "purchase")) {
      if (prev.includes("sellerConcession") || prev.includes("earnestMoneyPaid")) {
        closingFeesVar(prev.filter(el => el != "sellerConcession" && el != "earnestMoneyPaid"));
      }
    } else {
      const addableFields = [];

      //check if this field had a value than show it
      if (persistRef?.current?.sellerConcession) {
        addableFields.push("sellerConcession");
      }

      //check if this field had a value than show it
      if (persistRef?.current?.earnestMoneyPaid) {
        addableFields.push("earnestMoneyPaid");
      }

      closingFeesVar([...prev, ...addableFields]);
    }
  }, [lead?.loanPurpose]);

  const changeData = useCallback((object: Partial<PersistData>) => {
    for (let key in object) {
      if (persistRef.current.hasOwnProperty(key)) {
        persistRef.current[ key ] = object[ key ];
      }
    }
  }, []);

  const toggleClosingFees = useCallback((selected) => {
    const prev = closingFeesVar();
    if (prev.includes(selected)) {
      closingFeesVar(prev.filter(e => e !== selected));
    } else {
      closingFeesVar([...prev, selected]);
    }

  }, []);
  const togglePrepaymentFees = useCallback((selected) => {
    const prev = prepaymentFeesVar();
    if (prev.includes(selected)) {
      prepaymentFeesVar(prev.filter(e => e !== selected));
    } else {
      prepaymentFeesVar([...prev, selected]);
    }
  }, []);
  const persist: Persist = {
    fees: {
      closingFeesVar,
      prepaymentFeesVar,
      toggleClosingFees,
      togglePrepaymentFees
    },
    offers,
    data: persistRef.current,
    changeData,
    leadDataVar: criteriaData
  };
  return (
    <PersistContext.Provider value={persist}>
      {children}
    </PersistContext.Provider>
  );
});
