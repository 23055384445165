import { Operation }              from "@apollo/client";
import { useApolloNetworkStatus } from "@relcu/network-status";
import { useReactiveVar }         from "@apollo/client";
import { BoxComponentProps }      from "@relcu/ui";
import { LinearLoader }           from "@relcu/ui";
import { useConstant }            from "@relcu/ui";
import React                      from "react";
import { FC }         from "react";
import { loadingVar } from "../../reactiveVars";

export const LoadingIndicator: FC<BoxComponentProps> = React.memo((props) => {
  const connecting = useReactiveVar(loadingVar);
  const ignoreOperations = useConstant(() => [
    "GetPmiRates",
    "GetFive9Call",
    "UpdateLeads"
  ]);
  const { numPendingMutations, numPendingQueries } = useApolloNetworkStatus({
    shouldHandleOperation: (operation: Operation) => !ignoreOperations.includes(operation.operationName)
  });
  const loading = numPendingMutations > 0 || numPendingQueries > 0 || connecting;
  return (
    <>
      {loading && <LinearLoader {...props} freeze={false} style={{ position: "fixed", height: 3, zIndex: 1 }}/>}
    </>
  );
});

