import { useMemo }              from "react";
import React                    from "react";
import { FC }                   from "react";
import { useContext }           from "react";
import { getIn }                from "@relcu/form";
import { FormSpy }              from "@relcu/form";
import { ModalBody }            from "@relcu/ui";
import { ModalFooter }          from "@relcu/ui";
import { ButtonVariants }       from "@relcu/ui";
import { AlertColors }          from "@relcu/ui";
import { Alert }                from "@relcu/ui";
import { FieldLevelValidator }  from "@relcu/ui";
import { BackDropLoader }       from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { JsonForm }             from "@relcu/ui";
import { useNavigate }          from "@relcu/react-router";
import { JqlForm }              from "../../../../Jql/JqlForm";
import { ContactDialogContext } from "../../ContactDialog";
import { ContactDialogClasses } from "../../ContactDialogClasses";
import { useContactForm }       from "./useContactForm";

export const validators: FieldLevelValidator[] = [
  {
    field: "phones",
    validate(value = [], allValues, meta) {
      const emails = getIn(allValues, "emails") || [];
      for (let email of emails) {
        if (email.address) {
          return undefined;
        }
      }
      for (let phone of value) {
        if (phone.number) {
          return undefined;
        }
      }
      return "Please provide at least one contact information.";
    }
  }
];

export const ContactForm: FC<any> = React.memo(function ContactCreate(props) {
    const { initialValues, sections, loading, handleResolve, needResolve, setNeedResolve, jql, afterSubmit } = useContext(ContactDialogContext);
    const { onError, beforeSubmit } = useContactForm();
    const navigate = useNavigate();
    const label = props?.action === "save" ? "SAVE" : props?.action === "create" ? "CREATE AND ADD" : "CREATE";
    const contactInitialValues = useMemo(() => {
      const typeItem = sections[ 0 ].fields.find((item) => item.name === "types");
      return {
        phones: [{ type: "home" }],
        emails: [{ type: "personal" }],
        tags: [],
        types: props.type ? [props.type] : typeItem.defaultValue,
        ...initialValues
      };
    }, [sections, initialValues, props.type]);
    return (
      <JqlForm
        group={false}
        className="Contact"
        jql={jql}
        sections={sections}
        validators={validators}
        onError={onError}
        beforeSubmit={beforeSubmit}
        afterSubmit={afterSubmit}
        initialValues={contactInitialValues}>
        {({ elements }) =>
          <>
            <ModalBody style={{ position: "relative" }} container direction={"column"} flexGrow={1}>
              {
                needResolve &&
                <Alert variant={AlertColors.Warning} flex={1} alignItems={"center"} stretch={true}
                       className={ContactDialogClasses.ContactAlert}>
                  <p>The contact you want to create has a duplicate information with an existing contact.</p>
                  <Box flex={1}/>
                  <Button
                    variant={ButtonVariants.Ghost}

                    onClick={handleResolve}>RESOLVE</Button>
                </Alert>
              }
              <Box container direction={"column"} flex={"1 1 auto"} gap={"XS"} className="base-dialog-body">
                {elements}
                {props.children}
                {loading &&
                <BackDropLoader/>
                }
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button type="button" variant={ButtonVariants.Outline}
                      onClick={() => navigate(-1)}>CANCEL</Button>
              <FormSpy subscription={{ submitting: true }}>
                {({ submitting }) => <Button type="submit"
                                             disabled={submitting}>{label}</Button>}
              </FormSpy>
            </ModalFooter>
            <FormSpy
              subscription={{ pristine: true }}
              onChange={({ pristine }) => !pristine && setNeedResolve(false)}/>
          </>
        }
      </JqlForm>
    );
  }
);
