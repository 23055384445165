import { useQuery }     from "@apollo/client";
import { useSource }    from "@relcu/ui";
import { useState }     from "react";
import { Role }         from "../../../../../constants/Role";
import { TEAM_MEMBERS } from "../../../../operations.graphql";

export function usePriorityFilter() {
  const { $object } = useSource();
  const { data: teamData } = useQuery(TEAM_MEMBERS, {
    variables: {
      id: $object.id,
      team: $object.team
    },
    skip: $object.role != Role.TEAM_MANAGER || !$object.team
  });

  const api = {
    onFilter(data) {
      onFilter(data);
      localStorage.setItem(`${$object.role}-${$object.objectId}-users`, JSON.stringify(data));
    },
    get value() {
      return filter;
    },
    get memberObjectIds() {
      return api.members.map(m => m.objectId);
    },
    get members() {
      return teamData ? [$object, ...teamData.users.edges.map(e => e.node)] : [$object];
    }
  };
  const savedFilters = localStorage.getItem(`${$object.role}-${$object.objectId}-users`);
  const [filter, onFilter] = useState<string[]>(savedFilters ? JSON.parse(savedFilters) : api.memberObjectIds);
  //todo implement like in timeline
  return api;
}
