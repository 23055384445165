import React                   from "react";
import { Role }                from "../../../../../constants/Role";
import { PriorityViewClasses } from "../PriorityViewClasses";
import { useSource }           from "@relcu/ui";
import { AvatarSizes }         from "@relcu/ui";
import { Avatar }              from "@relcu/ui";
import { MenuItem }            from "@relcu/ui";
import { MultiSelect }         from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { usePriorityFilter }   from "./usePriorityFilter";
import { Typography }          from "@relcu/ui";
import { TypographyColor }     from "@relcu/ui";
import { TypographyWeight }    from "@relcu/ui";

export const PriorityFilter = React.memo<ReturnType<typeof usePriorityFilter>>(function PriorityFilter(props) {
  const { $object } = useSource();

  return (
    <>
      {
        (!!props.members.length && $object.role != Role.LOAN_OFFICER && $object.role != Role.SALES_MANAGER) &&
        <Box container className={PriorityViewClasses.PriorityFilter} alignItems={"center"}>
          <Box container alignItems={"center"}>
            <Typography weight={TypographyWeight.Regular} color={TypographyColor.Secondary}>Filter users:</Typography>
            <MultiSelect
              width={250}
              keepErrorSpace={false}
              showSelectionActions
              getOptionValueByKey
              headerProps={{
                showBorder: false,
                style: {
                  background: "transparent"
                }
              }}
              showSelectedCount
              placeholder="Select Member"
              optionKey={"objectId"}
              optionLabel={"objectName"}
              value={props.value}
              options={props.members}
              onChange={props.onFilter}
              renderOption={(option) => {
                return <MenuItem
                  thumbnail={<Avatar icon={option.objectIcon} size={AvatarSizes.Small} text={option.objectName}/>}>
                  {option.objectName}
                </MenuItem>;
              }}/>
          </Box>
        </Box>
      }
    </>
  );
});
