import React               from "react";
import { FC }              from "react";
import { Alignment }       from "../../../../constants/Alignemnt";
import { AvatarSizes }     from "../../../Avatar";
import { Avatar }          from "../../../Avatar";
import { Box }             from "../../../Box";
import { Link }            from "../../../Link";
import { Tooltip }         from "../../../Tooltip";
import { TypographyColor } from "../../../Typography";
import { Typography }      from "../../../Typography";
import { CellClasses }     from "../CellClasses";
import { ICellProps }      from "../ICellProps";

export interface ImageCellProps extends ICellProps {
  alt?: string;
}

export const AvatarCell: FC<ImageCellProps> = React.memo(function AvatarCell({ rowData, dataKey, getCellValue, alt, defaultValue, className, ...props }) {
  const value = getCellValue(rowData, dataKey);
  const name = value?.objectName;
  return <Box container gap={"XXS"} alignItems={"center"} className={className}>
    {
      name ? <>
          <Tooltip title={name} alignment={Alignment.Top}>
            <Avatar icon={value?.objectIcon} text={value?.objectName ?? "No Image"} size={AvatarSizes.Small}/>
          </Tooltip>
          {value?.__typename === "RelcuLoan" && !value.lead
            ? <Typography color={TypographyColor.Tertiary}>{name}</Typography>
            : <Link
              to={value?.navLink ?? `/${value?.__typename.toLowerCase()}/${value?.objectId}`}/*todo fix this*/
              className={CellClasses.AvatarCellLink}
              onClick={(e) => e.stopPropagation()}>
              {name}
            </Link>
          }
        </>
        :
        <Typography color={TypographyColor.Tertiary}>{defaultValue || ""}</Typography>
    }
  </Box>;
});


