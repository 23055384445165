import { useLayoutEffect }   from "react";
import { useRef }            from "react";
import { useLazyAsync }      from "./useAsync";
import { useConstant }       from "./useConstant";
import { useInfiniteScroll } from "./useInfiniteScroll";
import { useMounted }        from "./useMounted";
import { usePrevious }       from "./usePrevious";

export interface RelayPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface UseRelayPagination {
  pageInfo: RelayPageInfo
  onLoadMore: () => Promise<any>
  fromTop?: boolean
}

export function useRelayPagination({ pageInfo, onLoadMore, fromTop = true }: UseRelayPagination) {
  const hasMore = fromTop ? pageInfo?.hasPreviousPage : pageInfo?.hasNextPage;
  const [loadMore, { loading }] = useLazyAsync(onLoadMore, []);
  const [page, loaderRef, scrollContainerRef] = useInfiniteScroll({ hasMore, loading });
  const pageInfoRef = useRef<RelayPageInfo>();
  const elements = useConstant(() => new Map<string, HTMLDivElement>());
  const cursorRef = useRef<string>();
  const prevPage = usePrevious(page);
  useMounted(loadMore, [page]);

  useLayoutEffect(() => {
    if (elements.has(cursorRef.current)) {
      const el = elements.get(cursorRef.current);
      el.scrollIntoView(true);
    }
  }, [cursorRef.current]);

  useLayoutEffect(() => {
    if (prevPage !== page) {
      cursorRef.current = null;
    }
    if (!cursorRef.current) {
      if (!pageInfoRef.current) {
        pageInfoRef.current = pageInfo;
      }
      let el;
      if (page < 1) {
        el = elements.get(pageInfoRef.current?.endCursor);
      } else {
        el = elements.get(fromTop ? pageInfoRef.current?.startCursor : pageInfoRef.current?.endCursor);
      }
      if (el) {
        el.scrollIntoView(true);
        pageInfoRef.current = pageInfo;
      }
    }
  }, [pageInfo, page]);

  return {
    page,
    loaderRef,
    scrollContainerRef,
    cursorRef,
    elements
  };
}
