import React                 from "react";
import { classNames }        from "../../..";
import { Alignment }         from "../../../constants/Alignemnt";
import { Tooltip }           from "../../Tooltip";
import { AvatarSizes }       from "../../Avatar";
import { Avatar }            from "../../Avatar";
import { Box }               from "../../Box";
import { BoxComponentProps } from "../../Box";
import { IconType }          from "../../Icon";

export interface ListItemImageProps extends BoxComponentProps {
  text?: string;
  icon?: IconType | string
  label?: string
}

export function ListItemImage(props: ListItemImageProps) {
  const { className, text, icon, label, ...p } = props;

  const classes = classNames(ListItemImageClasses.ListItemImage, className);
  return <Box className={classes} {...p}>
    <Tooltip title={text} alignment={Alignment.Top}>
      <Avatar icon={icon} text={text} size={AvatarSizes.Small}/>
    </Tooltip>
  </Box>;
}
export enum ListItemImageClasses {
  ListItemImage = "list-item-image"
}
