import React                 from "react";
import { FC }                from "react";
import { InputProps }        from "../../Input/Input";
import { BaseFieldProps }    from "../BaseField";
import { BaseFieldProvider } from "../BaseField";
import NumberEditField       from "./NumberEditField";
import NumberReadField       from "./NumberReadField";
import { useNumberField }    from "./useNumberField";

export type NumberFieldProps = BaseFieldProps & InputProps & { precision?: number, warning?: string }

export const NumberField: FC<NumberFieldProps> = React.memo(function NumberField({ view = "write",...props }) {
  const field = useNumberField(props);
  return (
    <BaseFieldProvider field={field}>
      {view === "write" && <NumberEditField/>}
      {view === "read" && <NumberReadField/>}
    </BaseFieldProvider>
  );
});
