import { GlobalClasses }      from "../../../../constants/GlobalClasses";
import { classNames }         from "../../../..";
import React                  from "react";
import { FC }                 from "react";
import { Box }            from "../../../Box";
import { ButtonColors }   from "../../../Button";
import { Button }         from "../../../Button";
import { ButtonProps }    from "../../../Button";
import { RecordingTimer } from "../../../RecordingTimer";
import { VoiceActionClasses } from "./VoiceActionClasses";

export interface VoiceActionProps extends ButtonProps {
  busy?: boolean
  active?: boolean
}

export const VoiceAction: FC<VoiceActionProps> = React.memo(function VoiceAction(props) {
  const { busy, active, className, ...p } = props;
  const classes = classNames(VoiceActionClasses.VoiceAction, {
    [ GlobalClasses.Active ]: active
  }, className);

  return <Box container justify="start" gap={"XS"} className={classes}>
    <Button
      {...p}
      disabled={busy}
      icon={!!active ? "stop" : "record_voice_over"}
      color={!!active ? ButtonColors.Error : ButtonColors.Primary}
    >
      {!!active ? "STOP RECORDING" : "START RECORDING"}
    </Button>
    {
      active &&
      <RecordingTimer/>
    }
  </Box>;
});
