import { FC }                from "react";
import React                 from "react";
import { classNames }        from "../..";
import { BoxComponentProps } from "../..";
import { Box }               from "../..";
import { NavBarClasses }     from "./NavBarClasses";

export interface NavBarEmptyWatchingProps extends BoxComponentProps {
  title: string
  text: string
  img: string
  small?: boolean
}

export const NavBarEmptyWatching: FC<NavBarEmptyWatchingProps> = React.memo(function NavBarEmptyWatching(props) {
  const { className, img, title, text, small, ...p } = props;
  const classes = classNames(NavBarClasses.NavBarEmptyWatching, {
    [ NavBarClasses.Small ]: small
  }, className);
  return <Box
    container
    className={classes}
    flex={1}
    direction={"column"}
    alignItems={"center"}
    justify={"center"}
    gap={"XXXS"}
    {...p}>
    {
      !small &&
      <>
        <Box>
          {title}
        </Box>
        <p>
          {text}
        </p>
      </>
    }
    {
      <Box>
        <img src={img} alt={"Empty pinned pages list"}/>
      </Box>
    }
  </Box>;
});
