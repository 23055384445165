import { CircularLoader }               from "@relcu/ui";
import React                            from "react";
import { FC }                           from "react";
import { useState }                     from "react";
import { Container, Draggable }         from "react-smooth-dnd";
import { ToggleCombinator }             from "@relcu/ui";
import { classNames, EmptyList }        from "@relcu/ui";
import { ButtonColors }                 from "@relcu/ui";
import { ButtonSizes }                  from "@relcu/ui";
import { Accordion }                    from "@relcu/ui";
import { Button }                       from "@relcu/ui";
import { ListViewClasses }              from "@relcu/ui";
import { Box }                          from "@relcu/ui";
import { JsonViewProps }                from "@relcu/ui";
import { EmptyFocusView }               from "./EmptyFocusView";
import { FocusSection }                 from "./FocusSection";
import { useFocusView }                 from "./useFocusView";
import { ViewBody }                     from "./ViewBody";
import { ViewHeader as BaseViewHeader } from "../BaseView";
import { ViewHeader }                   from "./ViewHeader";
import "./focus-view.css";

export const FocusView: FC<JsonViewProps> = React.memo(function FocusView(props) {
  const {
    priorityQueueDialogContext,
    priorityQueueShareDialogContext,
    handleCreateView,
    handleUpdateView,
    handleDeleteView,
    handleShareView,
    handleCopyView,
    handleCreateQueue,
    handleUpdateQueue,
    handleDeleteQueue,
    handleToggleQueue,
    handleCopyQueue,
    priorityView,
    onSectionDrop,
    onViewDrop,
    sortEdges,
    count,
    loading
  } = useFocusView(props);
  const [overViewId, setOverViewId] = useState<string>(null);

  return (
    <Box container direction={"column"} gap={"XXS"} flexGrow={1} className={ListViewClasses.ListView}>
      {priorityQueueDialogContext}
      {priorityQueueShareDialogContext}
      <BaseViewHeader title={props.title} onClick={handleCreateView}/>
      {
        loading ? <Box container flex={1} alignItems={"center"} justify={"center"}>
            <CircularLoader/>
          </Box> :
          count ?
            <div className={"focus__container"}>
              <Container
                dragHandleSelector={".column-drag-handle"}
                getChildPayload={index => priorityView.find((view, i) => i == index)}
                onDrop={onViewDrop}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "var(--layout-box-gap-x-x-s)"
                }}>
                {
                  priorityView.map(({ node }, index) => {
                    const priorityQueues: any = sortEdges(node.priorityQueues.edges);
                    return <Draggable key={node.id}>
                      <Accordion
                        key={node.objectId}
                        header={
                          (open) =>
                            <ViewHeader
                              open={open}
                              node={node}
                              handleShareView={handleShareView}
                              handleCopyView={handleCopyView}
                              handleDeleteView={handleDeleteView}
                              handleUpdateView={handleUpdateView}
                              handleCreateQueue={(e) => handleCreateQueue(e, node.id)}
                            />
                        }
                        className={"focus-view"}
                        openClass={"focus-view--open"}>
                        <ViewBody
                          className={classNames({ [ "focus-view__body--empty" ]: !node.priorityQueues.edges.length })}>
                          {
                            <Container
                              groupName="col"
                              dragHandleSelector={".column-drag-handle"}
                              onDragEnter={() => setOverViewId(node.id)}
                              onDragLeave={() => setOverViewId((prev) => prev == node.id && null)}
                              onDrop={e => onSectionDrop(node.id, e)}
                              getChildPayload={index => priorityQueues.find((queue, i) => i == index)}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                gap: "var(--layout-box-gap-x-x-s)"
                              }}
                            >
                              {priorityQueues.length
                                ?
                                priorityQueues.map(({ node: queue }) => {
                                  return <Draggable key={queue.objectId}>
                                    <FocusSection title={queue.title} enabled={queue.enabled} key={queue.objectId}>
                                      <ToggleCombinator options={[
                                        {
                                          "label": "OFF"
                                        },
                                        {
                                          "label": "ON"
                                        }
                                      ]} checked={queue.enabled}
                                                        onChange={(e) => handleToggleQueue(queue.id, !queue.enabled)}/>
                                      <Button onlyIcon className={"focus-view__button"} icon={"content_copy"}
                                              size={ButtonSizes.Small}
                                              color={ButtonColors.White} onClick={(e) => handleCopyQueue(e, queue, node)}/>
                                      <Button onlyIcon className={"focus-view__button"} icon={"delete"}
                                              size={ButtonSizes.Small}
                                              color={ButtonColors.White}
                                              onClick={(e) => handleDeleteQueue(e, queue.id)}/>
                                      <Button onlyIcon className={"focus-view__button"} icon={"create"}
                                              size={ButtonSizes.Small}
                                              color={ButtonColors.White} onClick={(e) => handleUpdateQueue(e, queue)}/>
                                    </FocusSection>
                                  </Draggable>;
                                })
                                :
                                (!overViewId || (overViewId != node.id)) &&
                                <EmptyFocusView onCreate={(e) => handleCreateQueue(e, node.id)}/>
                              }
                            </Container>
                          }
                        </ViewBody>
                      </Accordion>
                    </Draggable>;
                  })
                }
              </Container>
            </div>
            :
            <EmptyList
              flex={1}
              icon={props.blank?.icon || "search_off"}
              content={`Please contact your admin to create “Focus view"`}
              title={"No Focus views found"}
              alignSelf={"center"}
              justify={"center"}
              onClick={handleCreateView}
            />
      }
    </Box>
  );
});
