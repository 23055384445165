import React, { useContext } from "react";
import { SelectPickerProps } from "../index";
import SelectPicker          from "../SelectPicker";
import { FormField }         from "./FormField";
import { FormFieldProps }    from "./FormField";

export interface FormSelectProps<ValueType = any> extends Omit<FormFieldProps<SelectPickerProps, ValueType>, "component"> {
  data: SelectPickerProps["data"];
  size?: SelectPickerProps["size"];
}

export interface FormSelectComponent extends React.FC<FormSelectProps> {
  <P = any>(
    props: FormSelectProps<P>
  ): React.ReactElement | null;
}

export const FormSelect: FormSelectComponent = React.memo((props: FormSelectProps, ref) => {
  const { data, size, properties, ...rest } = props;
  return (
    <FormField<SelectPickerProps> {...rest} component={SelectPicker} properties={{ data, size, ...properties }}/>
  );
});

FormSelect.displayName = "FormSelect";
