import { useRef }                     from "react";
import React                          from "react";
import { RefAttributes }              from "react";
import { PropsWithoutRef }            from "react";
import { ForwardRefExoticComponent }  from "react";
import { ElementType }                from "react";
import { useCallback }                from "react";
import { DndProvider }                from "react-dnd";
import { useDrag }                    from "react-dnd";
import { useDrop }                    from "react-dnd";
import { HTML5Backend }               from "react-dnd-html5-backend";
import { Cell }                       from "rsuite-table";
import { Column }                     from "rsuite-table";
import { HeaderCell }                 from "rsuite-table";
import { TableProps as RsTableProps } from "rsuite-table";
import { Table as RsTable }           from "rsuite-table";
import { InnerCellProps }             from "rsuite-table/lib/Cell";
import { Alignment }                  from "../../constants/Alignemnt";
import { classNames }                 from "../../utils/classNames";
import { Box }                        from "../Box";
import { ButtonProps }                from "../Button";
import { ButtonColors }               from "../Button";
import { ButtonVariants }             from "../Button";
import { ButtonSizes }                from "../Button";
import { Button }                     from "../Button";
import { ButtonGroup }                from "../ButtonGroup";
import { EmptyList }                  from "../EmptyList";
import { IconType }                   from "../Icon";
import { FontIcon }                   from "../Icon";
import Checkbox                       from "../Input/Checkbox";
import { PaginationProps }            from "../Pagination";
import { Pagination }                 from "../Pagination";
import { StatusCell }                 from "./cell";
import { BooleanCell }                from "./cell";
import { UrlCell }                    from "./cell";
import { LinkCell }                   from "./cell";
import { PhoneNumberCell }            from "./cell";
import { PhoneFormattedCell }         from "./cell";
import { UserStatusCell }             from "./cell";
import { IconCell }                   from "./cell";
import { ChipsCell }                  from "./cell";
import { DateCell }                   from "./cell";
import { TimezoneCell }               from "./cell";
import { PercentCell }                from "./cell";
import { LeadStatusCell }             from "./cell";
import { CurrencyCell }               from "./cell";
import { TextCell }                   from "./cell";
import { ActionsCell }                from "./cell";
import { AvatarCell }                 from "./cell";
import { ICellProps }                 from "./cell/ICellProps";
import { useTable }                   from "./useTable";

const components = {
  TextCell: TextCell,
  StatusCell: StatusCell,
  ChipsCell: ChipsCell,
  DateCell: DateCell,
  CurrencyCell: CurrencyCell,
  AvatarCell: AvatarCell,
  LeadStatusCell: LeadStatusCell,
  PercentCell: PercentCell,
  IconCell: IconCell,
  UserStatusCell: UserStatusCell,
  PhoneNumberCell: PhoneNumberCell,
  PhoneFormattedCell: PhoneFormattedCell,
  UrlCell: UrlCell,
  LinkCell: LinkCell,
  BooleanCell: BooleanCell,
  TimezoneCell: TimezoneCell
};
export interface IColumn {
  title: string,
  key: string,
  width: number,
  visible?: boolean,
  fixed?: "left" | "right",
  align?: "left" | "center" | "right";
  verticalAlign?: "top" | "middle" | "bottom";
  resizable?: boolean
  sortable?: boolean
  component?: string | ElementType<ICellProps>,
  flexGrow?: number
  minWidth?: number
  properties?: Record<string, any>
}
export interface Action extends Omit<ButtonProps, "onClick" | "type"> {
  component?: ElementType<ActionComponentProps>;
  tooltip?: string;
  type: any,
  width?: number
  onClick(event: Event, data: any);
}
export interface ActionComponentProps extends Omit<Action, "component"> {
  rowData: any;
}
export type Actions = Action[] | ((data: any) => Action[])
export interface TableProps extends RsTableProps<any,any> {
  columns?: IColumn[],
  actions?: Actions,
  onColumnsChange?: (columns: TableProps["columns"]) => void
  selectedRows?: string[]
  onRowSelection?: (selection: string[]) => void
  getCellValue?: (rowData, dataKey: string) => any
  pagination?: PaginationProps,
  standardColumn?: boolean,
  type?: string
  empty?: {
    title?: string;
    icon?: IconType;
    content?: string
    action?: string
  }
}
export const Table: ForwardRefExoticComponent<PropsWithoutRef<TableProps> & RefAttributes<any>> = React.forwardRef((props, ref) => {
  const api = useTable(props);
  let { className, standardColumn, ...tableProps } = api.tableProps;
  const growItem = api.columns.find((item) => item.flexGrow);
  const [th, setHeight] = React.useState(0);
  const [tw, setWidth] = React.useState(0);
  const el = React.useRef(null);
  const bounce = React.useRef(bounced(50));
  const unmountRef = useRef(false);
  React.useEffect(() => {
    if (el.current) {
      const resizeObserver = new ResizeObserver((event) => {
        let width = event[ 0 ].contentBoxSize[ 0 ].inlineSize;
        let height = event[ 0 ].contentBoxSize[ 0 ].blockSize;
        bounce.current(() => {
          if (!unmountRef.current) {
            setWidth(Math.ceil(width));
            setHeight(Math.ceil(height));
          }
        });
      });

      resizeObserver.observe(el.current);
      return () => {
        unmountRef.current = true;
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }

  }, [el]);

  return <DndProvider backend={HTML5Backend}>
    <div ref={el} className={className}>
      <RsTable
        className={'ui-table'}/*todo-duplicate remove ui-table class after remove duplicate style in UI folder*/
        ref={ref}
        headerHeight={46}
        rowClassName={(rowData) => {
          let isSelected = rowData && api.selectedRows.some(item => item === rowData.id || item === "*");
          return classNames("table__row", {
            "table__row--selected": isSelected,
            "table__row--highlighted": rowData?.notifications
          });
        }}
        autoHeight={false}
        data={api.data}
        renderEmpty={api.empty ? (() =>
          <Box container flexGrow={0} justify={"center"}>
            <EmptyList alignSelf="center"
                       content={api.empty.content}
                       icon={api.empty.icon} title={api.empty.title}/>
          </Box>) : undefined
        }
        {...tableProps}
        height={th}
        width={tw}
        onSortColumn={api.onSortColumn}
      >
        {
          api.onRowSelection &&
          <Column verticalAlign={"middle"} width={46} align="center" fixed>
            <CellWrapper style={{ padding: 0 }}>
              <Checkbox label="" onChange={api.handleCheckAll}
                        indeterminate={!(api.selectedRows.length === api.data.length || api.selectedRows.some(item => item === "*"))}
                        checked={!!api.selectedRows.length}
              />
            </CellWrapper>
            <CellWrapper dataKey={"id"} style={{ padding: 0, height: "100%" }} onClick={(e) => e.stopPropagation()}>
              {(rowData, rowIndex) => (
                <Checkbox
                  justify={"center"}
                  className={classNames("checkBox-cell", {
                    "highlighted-first-cell": rowData[ "notifications" ]
                  })} label=""
                  onChange={(event) => {
                    event.stopPropagation();
                    api.handleCheck(event, rowData.id);
                  }}
                  checked={api.selectedRows.some(item => item === rowData.id || item === "*")}
                />
              )}
            </CellWrapper>
          </Column>
        }
        {
          api.columns.filter(c => c.visible && c.fixed == "left")
          .map(({ component, sortable, title, key, properties, visible, field, flexGrow, ...columnProps }, index, array) => {

            return <Column
              key={key}
              verticalAlign={"middle"}
              {...columnProps}
              minWidth={20}
              resizable={true}
              sortable={!!sortable}
            >
              {standardColumn
                ? <DefaultHeaderCell dataKey={key}>{title}</DefaultHeaderCell>
                : !columnProps.fixed ?
                  <DraggableHeaderCell onDrag={api.handleDragColumn} id={key} handleFreeze={api.handleFreeze}>
                    {title}
                  </DraggableHeaderCell>
                  :
                  <FixedHeaderCell handleUnFreeze={api.handleUnFreeze}>{title}</FixedHeaderCell>
              }
              <CellRenderer dataKey={key} selectedRows={api.selectedRows} component={component}
                            getCellValue={api.getCellValue} properties={properties}/>
            </Column>;
          })
        }
        {
          api.columns.filter(c => c.visible && !c.fixed)
          .map(({ component, sortable, title, key, properties, visible, field, flexGrow, ...columnProps }, index, array) => {
            return <Column
              key={key}
              verticalAlign={"middle"}
              {...columnProps}
              minWidth={20}
              resizable={columnProps.resizable !== false}
              sortable={!!sortable}
            >
              {standardColumn
                ? <DefaultHeaderCell dataKey={key}>{title}</DefaultHeaderCell>
                : !columnProps.fixed ?
                  <DraggableHeaderCell onDrag={api.handleDragColumn} id={key} handleFreeze={api.handleFreeze}>
                    {title}
                  </DraggableHeaderCell>
                  :
                  <FixedHeaderCell handleUnFreeze={api.handleUnFreeze}>{title}</FixedHeaderCell>
              }
              <CellRenderer dataKey={key} selectedRows={api.selectedRows} component={component}
                            getCellValue={api.getCellValue} properties={properties}/>
            </Column>;
          })
        }
        <Column
          verticalAlign={"middle"}
          minWidth={20}
          flexGrow={1}
        >
          <DefaultHeaderCell>{" "}</DefaultHeaderCell>
          <Cell>{" "}</Cell>
        </Column>
        {
          api.columns.filter(c => c.visible && c.fixed == "right")
          .map(({ component, sortable, title, key, properties, visible, field, flexGrow, ...columnProps }, index, array) => {

            return <Column
              key={key}
              verticalAlign={"middle"}
              {...columnProps}
              minWidth={20}
              resizable={true}
              sortable={!!sortable}
            >
              {standardColumn
                ? <DefaultHeaderCell dataKey={key}>{title}</DefaultHeaderCell>
                : !columnProps.fixed ?
                  <DraggableHeaderCell onDrag={api.handleDragColumn} id={key} handleFreeze={api.handleFreeze}>
                    {title}
                  </DraggableHeaderCell>
                  :
                  <FixedHeaderCell handleUnFreeze={api.handleUnFreeze}>{title}</FixedHeaderCell>
              }
              <CellRenderer dataKey={key} selectedRows={api.selectedRows} component={component}
                            getCellValue={api.getCellValue} properties={properties}/>
            </Column>;
          })
        }
        {
          api.actions &&
          <Column
            verticalAlign={"middle"}
            minWidth={32 + api.actions[ "reduce" ]((sum, action) => sum + (action.width ?? 32), 0)}
            width={32 + api.actions[ "reduce" ]((sum, action) => sum + (action.width ?? 32), 0)}
            fixed={"right"}
          >
            <HeaderCell><Box className={"action-cell-header"}>Action</Box></HeaderCell>
            <ActionsCell dataKey="id" actions={api.actions} className={"actions-cell"}/>
          </Column>
        }
      </RsTable>
      {
        api.pagination &&
        <Box container flexGrow={0} justify="end">
          <Pagination {...api.pagination}/>
        </Box>
      }
    </div>
  </DndProvider>;
});

Table.defaultProps = {
  getCellValue: (rowData, dataKay) => rowData[ dataKay ]
};

const CellWrapper: React.ForwardRefExoticComponent<InnerCellProps & React.RefAttributes<HTMLDivElement>> = React.forwardRef((props, ref) => {
  const { sortType, onSortColumn, sortColumn, ...rest } = props as any;
  return <Cell {...rest} ref={ref}/>;
});
function CellRenderer({ component, properties, getCellValue, selectedRows, ...other }) {
  const className = classNames("cell", {
    "highlighted-first-cell": other.rowData[ "notifications" ] && other.firstColumn
  });
  const Component = typeof component == "string" ? (components[ component ] ?? TextCell) : (component ?? TextCell);
  return <Cell {...other}>
    <Component {...other} {...properties} className={className} getCellValue={getCellValue}
               highlighted={other.rowData[ "notifications" ]}/>
  </Cell>;
}
function FixedHeaderCell({ children, handleUnFreeze, ...rest }) {
  const styles = {
    padding: "0px 16px"
  };
  const unFreeze = useCallback((e) => {
    e.stopPropagation();
    handleUnFreeze(rest.dataKey);
  }, [handleUnFreeze, rest.dataKey]);

  return <HeaderCell
    {...rest}
    style={{ padding: 0 }}
    renderSortIcon={renderSortIcon}
    renderCell={([children, sortElement]) => {
      return <Box alignItems={"center"} container gap={"XXXS"} style={styles}>
        {children}
        {sortElement}
        <ButtonGroup onOpen={(e) => e.stopPropagation()} style={{ flex: 1, justifyContent: "end" }}
                     color={ButtonColors.Gray} variant={ButtonVariants.Ghost} height={50} collapse buttonMenu
                     alignment={Alignment.BottomRight}>
          <Button size={ButtonSizes.Big} onlyIcon icon={"more_vert"}/>
          <Button size={ButtonSizes.Small} onClick={unFreeze}>Unfreeze</Button>
        </ButtonGroup>
      </Box>;
    }
    }>
    {children}

  </HeaderCell>;
}
function DefaultHeaderCell({ children, ...rest }) {
  const styles = {
    padding: "0px 16px"
  };
  return <HeaderCell
    {...rest}
    style={{ padding: 0 }}
    renderSortIcon={renderSortIcon}
    renderCell={([children, sortElement]) => {
      return <Box alignItems={"center"} container gap={"XXXS"} style={styles}>
        {children}
        {sortElement}
      </Box>;
    }
    }>
    {children}
  </HeaderCell>;
}
function DraggableHeaderCell({ children, onDrag, id, handleFreeze, ...rest }) {
  const ref = React.useRef(null);
  const [{ canDrop, isOver, handlerId }, drop] = useDrop(() => ({
    accept: "column",
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId()
    }),
    drop(item: any, monitor) {
      onDrag?.(item.id, id);
    }
  }), [id]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "column",
    item: () => {
      return { id };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }), [id]);
  const isActive = canDrop && isOver;

  drag(drop(ref));
  const freeze = useCallback((e, direction: "left" | "right") => {
    e.stopPropagation();
    handleFreeze(rest.dataKey, direction);
  }, [handleFreeze, rest.dataKey]);
  const styles = {
    padding: "0px 16px",
    cursor: "grab",
    opacity: isDragging ? 0 : 1,
    borderLeft: isActive ? "2px solid #2589f5" : null
  };
  return (
    <HeaderCell
      {...rest}
      style={{ padding: 0 }}
      renderSortIcon={renderSortIcon}
      renderCell={([children, sortElement]) => {
        return <Box className={"prevent-reset"} gap={"XXXS"} alignItems={"center"} container ref={ref}
                    data-handler-id={handlerId}
                    style={styles}>
          <FontIcon style={{ fontSize: 12 }} type={"drag_indicator"}/>
          {children}
          {sortElement}
          <ButtonGroup onOpen={(e) => e.stopPropagation()} style={{ flex: 1, justifyContent: "end" }}
                       color={ButtonColors.Gray} variant={ButtonVariants.Ghost} height={50} collapse buttonMenu
                       alignment={Alignment.BottomRight}>
            <Button size={ButtonSizes.Big} onlyIcon icon={"more_vert"}/>
            <Button size={ButtonSizes.Small} onClick={(e) => freeze(e, "left")}>Freeze Left</Button>
            <Button size={ButtonSizes.Small} onClick={(e) => freeze(e, "right")}>Freeze Right</Button>
          </ButtonGroup>
        </Box>;
      }
      }>
      {children}

    </HeaderCell>

  );
}
function renderSortIcon(sortType) {
  if (sortType == "asc") {
    return <FontIcon style={{ fontSize: 12 }} type={"arrow_upward"}/>;
  }
  if (sortType == "desc") {
    return <FontIcon style={{ fontSize: 12 }} type={"arrow_downward" as any}/>;
  }
  return <FontIcon style={{ fontSize: 12 }} type={"unfold_more"}/>;
}

function bounced(delay = 100) {
  let bounceTimer: any = 0;
  return (callback) => {
    if (bounceTimer) {
      clearTimeout(bounceTimer);
    }
    bounceTimer = setTimeout(() => {
      bounceTimer = 0;
      callback();
    }, delay);
  };
}
