import React                 from "react";
import { FC }                from "react";
import { useState }          from "react";
import { MouseEventHandler } from "react";
import { KeyboardEvent }     from "react";
import { useCallback }       from "react";
import { CommonClasses }     from "../../../..";
import { ButtonSizes }       from "../../../..";
import { ButtonColors }      from "../../../..";
import { Button }            from "../../../..";
import { Keys }              from "../../../../constants/Keys";
import { Box }               from "../../../Box";
import { BoxComponentProps } from "../../../Box";
import { ListItemIcon }      from "../../../List";
import { ListItemText }      from "../../../List";
import { ListRowColors }     from "../../../List";
import { ListRow }           from "../../../List";
import { AudioPlayer }          from "../../AudioPlayer/AudioPlayer";
import { OutlineTextFiled }     from "../../OutlineTextField";
import { VoiceTemplateClasses } from "./VoiceTemplateClasses";

export interface VoiceTemplateProps extends BoxComponentProps {
  voiceTemplate: { name: string | null, url: string | null },
  onSave: (r: { name: string | null, url: string | null }) => void,
  onDelete: (r: { name: string | null, url: string | null }) => void
  onEdit: MouseEventHandler,
  editing: boolean
  onCancel: () => void
}

export const VoiceTemplate: FC<VoiceTemplateProps> = React.memo(function VoiceTemplate(props) {
  const { voiceTemplate, onEdit, editing, onCancel, onSave, onDelete, ...p } = props;
  const [templateName, setTemplateName] = useState<string>(voiceTemplate.name);

  return <VoiceRow
    editing={editing}
    onChange={
      (e) => {
        setTemplateName(e.target.value);
      }
    }
    templateName={templateName}
    voiceTemplate={voiceTemplate}
    onSave={() => onSave({ ...voiceTemplate, name: templateName })}
    onDelete={(e) => {
      e.stopPropagation();
      onDelete(voiceTemplate);
    }}
    onCancel={(e) => {
      e.stopPropagation();
      setTemplateName(voiceTemplate.name);
      onCancel();
    }}
    onEdit={onEdit}
    {...p}
  />;
});

interface VoiceRowProps {
  editing: boolean;
  templateName: string;
  voiceTemplate: { name: string | null, url: string | null },
  onChange(e);
  onSave(e);
  onDelete(e),
  onCancel(e)
  onEdit(e)
}

export const VoiceRow: FC<VoiceRowProps> = React.memo(function (props) {
  const { onChange, editing, templateName, voiceTemplate, onSave, onDelete, onCancel, onEdit, ...p } = props;
  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === Keys.Enter) {
      e.preventDefault();
      onSave(e);
    }
  }, [voiceTemplate, templateName]);
  return <ListRow
    gap={"XXXL"}
    color={editing ? ListRowColors.White : ListRowColors.Grey}
    outlined={editing}
    className={VoiceTemplateClasses.VoiceRow}
    {...p}>
    {
      editing
        ?
        <OutlineTextFiled
          label="Title"
          flex={"0 1 250px"}
          onChange={onChange}
          value={templateName}
          onKeyPress={handleKeyPress}
        />
        :
        <ListItemText flex={"0 1 250px"} text={templateName} container gap={"XL"} alignItems={"center"}/>
    }
    <Box flex={"1 2 auto"} container gap={"XXS"} alignItems={"center"}>
      <AudioPlayer src={voiceTemplate.url}/>
      <Box container gap={"XXS"} className={VoiceTemplateClasses.VoiceRowActions}>
        {
          editing ? <>
              <ListItemIcon className={CommonClasses.ClickableIcon} type={"save"} onClick={(e) => onSave({ ...voiceTemplate, name: templateName })}/>
              <ListItemIcon className={CommonClasses.ClickableIcon} type={"clear"} onClick={onCancel}/>
            </>
            :
            <>
              <Button
                size={ButtonSizes.Small}
                icon="create"
                onlyIcon
                color={ButtonColors.White}
                onClick={onEdit}/>
              <Button
                size={ButtonSizes.Small}
                icon="delete"
                onlyIcon
                color={ButtonColors.White}
                onClick={onDelete}/>
            </>
        }
      </Box>
    </Box>
  </ListRow>;
});
