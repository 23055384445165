import React             from "react";
import { useComponents } from "@relcu/ui";
import { JqlForm }       from "../Jql/JqlForm";
import { JqlFormProps }  from "../Jql/JqlForm";

export const ClassForm = React.memo<JqlFormProps>(function ClassForm(props) {
  const { forms } = useComponents();
  const type = forms[ `${props.className}Form` ] || JqlForm;
  return React.createElement(type, props);
});
