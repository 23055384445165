import React                             from "react";
import { FC }                            from "react";
import { Container, Draggable }          from "react-smooth-dnd";
import { Typography }                    from "@relcu/rc";
import { CommonClasses }                 from "@relcu/ui";
import { classNames }                    from "@relcu/ui";
import { ChipsSizes, FontIcon }          from "@relcu/ui";
import { ChipsColors }                   from "@relcu/ui";
import { ButtonVariants, ListItemChips } from "@relcu/ui";
import { ButtonColors }                  from "@relcu/ui";
import { ListItemDate }                  from "@relcu/ui";
import { ButtonSizes }                   from "@relcu/ui";
import { ListItemText }                  from "@relcu/ui";
import { ListItemTitle }                 from "@relcu/ui";
import { Tooltip }                       from "@relcu/ui";
import { ListRow }                       from "@relcu/ui";
import { ListActions }                   from "@relcu/ui";
import { Button }                        from "@relcu/ui";
import { Box }                           from "@relcu/ui";
import { ListRowColors }                 from "@relcu/ui";
import { JsonViewProps }                 from "@relcu/ui";
import { ListViewClasses }               from "@relcu/ui";
import { EmptyList }                     from "@relcu/ui";
import { ListClasses }                   from "@relcu/ui";
import { CircularLoader }                from "@relcu/ui";
// import { useDrag }                       from "react-dnd";
// import { useDrop }                       from "react-dnd";
// import type { Identifier, XYCoord }      from "dnd-core";
import { DistributionQueueViewClasses }  from "./DistributionQueueViewClasses";
import { useDistributionQueue }          from "./useDistributionQueue";
import "./distribution-queue-view.css";

export const DistributionQueueView: FC<JsonViewProps> = React.memo(function LeadView(props) {
  return <DistributionQueueViewList {...props}/>;
});

export const DistributionQueueViewList: FC<JsonViewProps> = React.memo(function LeadView(props) {
  const {
    statsModalContext,
    queueModalContext,
    handleQueueCLick,
    handleStatsCLick,
    confirmContext,
    handleDelete,
    handleDrop,
    loading,
    pull,
    push,
    data
  } = useDistributionQueue(props.dialog);

  return <>
    {statsModalContext}
    {queueModalContext}
    {confirmContext}
    <Box container direction={"column"} gap={"XS"} className={ListViewClasses.ListView} flexGrow={1}>
      {
        loading ?
          <Box container flex={1} alignItems={"center"} justify={"center"}>
            <CircularLoader/>
          </Box> :
          data.length == 0 ?
            <Box container direction={"column"} gap={"XS"} flex={"1 1 auto"} justify={"center"}
                 className={DistributionQueueViewClasses.DistributionQueueEmptyView}>
              <EmptyList icon={"call_split"}
                         content={"When you have distribution queues defined, you'll see them here."}
                         title={"No queues found"}
                         alignSelf={"center"}/>
              <Button alignSelf={"center"} icon={"add"} onClick={() => handleQueueCLick(false)}>
                Create
              </Button>
            </Box>
            :
            <Box container direction={"column"} gap={"XS"} className={ListClasses.List} flex={"1 1 0%"}>
              <Box container justify={"space-between"} flexShrink={0} alignItems={"center"}>
                <h2 className={ListClasses.ListHeader}>
                  Queue configuration
                </h2>
                <Button alignSelf={"center"} icon={"add"} variant={ButtonVariants.Ghost}
                        onClick={() => handleQueueCLick(false)}>
                  Create
                </Button>
              </Box>
              <Box container direction={"column"} flex={1} gap={"S"} style={{ overflowY: "auto" }}>
                {
                  !!push.length &&
                  <Box gap={"XS"} direction={"column"} container flexGrow={1}>
                    <Typography variant={"base16"}>
                      Push distribution
                    </Typography>
                    <ListRow
                      className={ListClasses.ListRowHead}
                      gap={"XXS"}
                      color={ListRowColors.White}
                      justify={"space-between"}>
                      <FontIcon type={"drag_indicator"}
                                style={{ fontSize: "var(--typography-icon-size)", visibility: "hidden", flex: "0 0 10px" }}/>
                      <ListItemText text="Name" tiny flexBasis={"130px"} flexGrow={2} flexShrink={1}/>
                      <ListItemText text="Leads available" tiny flexBasis={"130px"} flexGrow={1} flexShrink={0}/>
                      <ListItemText text="Eligible users" tiny flexBasis={"130px"} flexGrow={1} flexShrink={0}/>
                      <ListItemText text="Status" tiny flexBasis={"130px"} flexGrow={1} flexShrink={0}/>
                      <ListItemText text="Created at" tiny flexBasis={"80px"} flexShrink={1}/>
                    </ListRow>
                    <Container
                      orientation={"vertical"}
                      style={{ display: "flex", flexDirection: "column", gap: 8 }}
                      groupName="push-col"
                      dragHandleSelector=".column-drag-handle"
                      onDrop={e => handleDrop(push, e, "round_robin")}
                      getChildPayload={index => push[ index ]}
                      dragClass="card-ghost"
                      dropClass="card-ghost-drop"
                      dropPlaceholder={{
                        showOnTop: true,
                        className: "drop-preview"
                      }}>
                      {
                        push.map((queue, i) => {
                          return (
                            <Draggable key={queue.id}>
                              <DraggableRow
                                name={"push"}
                                key={queue.id}
                                index={i}
                                id={queue.id}
                                objectId={queue.objectId}
                                objectName={queue.objectName}
                                leadsAvailable={queue.leadsAvailable}
                                eligibleUsers={queue.eligibleUsers}
                                draft={queue.draft}
                                disabled={queue.disabled}
                                createdAt={queue.createdAt}
                                handleQueueCLick={handleQueueCLick}
                                handleStatsCLick={(objectId, objectName) => handleStatsCLick(objectId, objectName, queue.strategy, queue.distributeOfflineUsers)}
                                handleDelete={handleDelete}
                              />
                            </Draggable>
                          );
                        })
                      }
                    </Container>
                  </Box>
                }
                {
                  !!pull.length &&
                  <Box gap={"XS"} direction={"column"} container flexGrow={1}>
                    <Typography variant={"base16"}>
                      Pull distribution
                    </Typography>
                    <ListRow
                      className={ListClasses.ListRowHead}
                      gap={"XXS"}
                      color={ListRowColors.White}
                      justify={"space-between"}>
                      <FontIcon type={"drag_indicator"}
                                style={{ fontSize: "var(--typography-icon-size)", visibility: "hidden", flex: "0 0 10px" }}/>
                      <ListItemText text="Name" tiny flexBasis={"130px"} flexGrow={2} flexShrink={1}/>
                      <ListItemText text="Leads available" tiny flexBasis={"130px"} flexGrow={1} flexShrink={0}/>
                      <ListItemText text="Eligible users" tiny flexBasis={"130px"} flexGrow={1} flexShrink={0}/>
                      <ListItemText text="Status" tiny flexBasis={"130px"} flexGrow={1} flexShrink={0}/>
                      <ListItemText text="Created at" tiny flexBasis={"80px"} flexShrink={1}/>
                    </ListRow>
                    <Container
                      orientation={"vertical"}
                      style={{ display: "flex", flexDirection: "column", gap: 8 }}
                      groupName="pull-col"
                      dragHandleSelector=".column-drag-handle"
                      onDrop={e => handleDrop(pull, e, "pull")}
                      getChildPayload={index => pull[ index ]}
                      dragClass="card-ghost"
                      dropClass="card-ghost-drop"
                      dropPlaceholder={{
                        showOnTop: true,
                        className: "drop-preview"
                      }}>
                      {
                        pull.map((queue, i) => {
                          return (
                            <Draggable key={queue.id}>
                              <DraggableRow
                                name={"pull"}
                                key={queue.id}
                                index={i}
                                id={queue.id}
                                objectId={queue.objectId}
                                objectName={queue.objectName}
                                leadsAvailable={queue.leadsAvailable}
                                eligibleUsers={queue.eligibleUsers}
                                draft={queue.draft}
                                disabled={queue.disabled}
                                createdAt={queue.createdAt}
                                handleQueueCLick={handleQueueCLick}
                                handleStatsCLick={(objectId, objectName) => handleStatsCLick(objectId, objectName, queue.strategy, queue.distributeOfflineUsers)}
                                handleDelete={handleDelete}
                              />
                            </Draggable>
                          );
                        })
                      }
                    </Container>
                  </Box>
                }
              </Box>
            </Box>
      }
    </Box>
  </>;
});

interface DraggableRowProps {
  id: string,
  objectId: string,
  objectName: string,
  leadsAvailable: number,
  eligibleUsers: number,
  index: number,
  draft: boolean,
  name?: string,
  disabled: boolean,
  createdAt,
  handleDelete(id: string),
  handleQueueCLick(edit, id?),
  handleStatsCLick(id, name),
}
export const DraggableRow: FC<DraggableRowProps> = (props) => {
  const { id, objectId, name, index, objectName, leadsAvailable, eligibleUsers, draft, disabled, createdAt, handleDelete, handleStatsCLick, handleQueueCLick } = props;
  return (
    <ListRow justify={"space-between"} gap={"XXS"}>
      <FontIcon type={"drag_indicator"}
                style={{ fontSize: "var(--typography-icon-size)", cursor: "move", flex: "0 0 10px" }}
                className={classNames(CommonClasses.GrayIcon, "column-drag-handle", "prevent-reset")}/>{/*ref={dragRef}*/}
      <ListItemTitle title={objectName} flexBasis={"130px"} flexGrow={2} flexShrink={1}/>
      <ListItemText text={leadsAvailable.toString()} flexBasis={"130px"} flexGrow={1}
                    flexShrink={0}/>
      <ListItemText text={eligibleUsers.toString()} flexBasis={"130px"} flexGrow={1}
                    flexShrink={0}/>
      {
        draft ?
          <ListItemChips flexBasis={"130px"} value={"Draft"} size={ChipsSizes.Medium}
                         color={ChipsColors.Info} flexGrow={1}/>
          :
          <ListItemChips flexBasis={"130px"} value={disabled ? "Disabled" : "Enabled"}
                         size={ChipsSizes.Medium} flexGrow={1}/>
      }
      <ListItemDate date={createdAt} format="withTime" style={{ textAlign: "right" }}
                    flexBasis={"80px"} flexShrink={1}/>
      <ListActions>
        <Tooltip title={"Delete queue"}>
          <Button onlyIcon size={ButtonSizes.Small} icon={"delete"} color={ButtonColors.White}
                  onClick={(e) => handleDelete(id)}/>
        </Tooltip>
        <Tooltip title={"Stats per user"}>
          <Button onlyIcon size={ButtonSizes.Small} icon={"leaderboard"} color={ButtonColors.White}
                  onClick={(e) => handleStatsCLick(objectId, objectName)}/>
        </Tooltip>
        <Tooltip title={"Update queue"}>
          <Button onlyIcon size={ButtonSizes.Small} icon={"create"} color={ButtonColors.White}
                  onClick={(e) => handleQueueCLick(true, id)}/>
        </Tooltip>
      </ListActions>
    </ListRow>
  );
};


// export const Row: FC<DistributionQueueRowProps> = (props) => {
//   const { id, objectId, name, index, objectName, leadsAvailable, eligibleUsers, draft, disabled, createdAt, handleDelete, handleStatsCLick, handleQueueCLick, handleMoveRow, handleDrop } = props;
//   const dndRef = useRef<HTMLDivElement>(null);
//   const dragRef = useRef<HTMLDivElement>(null);
//   const [{ handlerId }, drop] = useDrop<DragItem,
//     void,
//     { handlerId: Identifier | null }>({
//     accept: name,
//     collect(monitor) {
//       return {
//         handlerId: monitor.getHandlerId()
//       };
//     },
//     hover(item: DragItem, monitor) {
//       if (!dndRef.current) {
//         return;
//       }
//       const dragIndex = item.index;
//       const hoverIndex = index;
//
//       // Don't replace items with themselves
//       if (dragIndex === hoverIndex) {
//         return;
//       }
//
//       // Determine rectangle on screen
//       const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
//
//       // Get vertical middle
//       const hoverMiddleY =
//         (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
//
//       // Determine mouse position
//       const clientOffset = monitor.getClientOffset();
//
//       // Get pixels to the top
//       const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
//
//       // Only perform the move when the mouse has crossed half of the items height
//       // When dragging downwards, only move when the cursor is below 50%
//       // When dragging upwards, only move when the cursor is above 50%
//
//       // Dragging downwards
//       if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
//         return;
//       }
//
//       // Dragging upwards
//       if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
//         return;
//       }
//
//       // Time to actually perform the action
//       handleMoveRow(dragIndex, hoverIndex);
//
//       // Note: we're mutating the monitor item here!
//       // Generally it's better to avoid mutations,
//       // but it's good here for the sake of performance
//       // to avoid expensive index searches.
//       item.index = hoverIndex;
//     }
//   });
//
//   const [{ isDragging }, drag] = useDrag({
//     type: name,
//     item: () => {
//       return { index };
//     },
//     collect: (monitor: any) => ({
//       isDragging: monitor.isDragging()
//     }),
//     end: handleDrop
//   });
//
//   const opacity = isDragging ? 0 : 1;
//
//   drag(drop(dndRef));
//   return (
//     <ListRow justify={"space-between"} gap={"XXS"}>
//       <FontIcon type={"drag_indicator"}
//                 style={{ fontSize: "var(--typography-icon-size)", cursor: "move", flex: "0 0 10px" }}
//                 className={classNames(CommonClasses.GrayIcon, "column-drag-handle", "prevent-reset")}/>{/*ref={dragRef}*/}
//       <ListItemTitle title={objectName} flexBasis={"130px"} flexGrow={2} flexShrink={1}/>
//       <ListItemText text={leadsAvailable.toString()} flexBasis={"130px"} flexGrow={1}
//                     flexShrink={0}/>
//       <ListItemText text={eligibleUsers.toString()} flexBasis={"130px"} flexGrow={1}
//                     flexShrink={0}/>
//       {
//         draft ?
//           <ListItemChips flexBasis={"130px"} value={"Draft"} size={ChipsSizes.Medium}
//                          color={ChipsColors.Info} flexGrow={1}/>
//           :
//           <ListItemChips flexBasis={"130px"} value={disabled ? "Disabled" : "Enabled"}
//                          size={ChipsSizes.Medium} flexGrow={1}/>
//       }
//       <ListItemDate date={createdAt} format="withTime" style={{ textAlign: "right" }}
//                     flexBasis={"80px"} flexShrink={1}/>
//       <ListActions>
//         <Tooltip title={"Delete queue"}>
//           <Button onlyIcon size={ButtonSizes.Small} icon={"delete"} color={ButtonColors.White}
//                   onClick={(e) => handleDelete(id)}/>
//         </Tooltip>
//         <Tooltip title={"Stats per user"}>
//           <Button onlyIcon size={ButtonSizes.Small} icon={"leaderboard"} color={ButtonColors.White}
//                   onClick={(e) => handleStatsCLick(objectId, objectName)}/>
//         </Tooltip>
//         <Tooltip title={"Update queue"}>
//           <Button onlyIcon size={ButtonSizes.Small} icon={"create"} color={ButtonColors.White}
//                   onClick={(e) => handleQueueCLick(true, id)}/>
//         </Tooltip>
//       </ListActions>
//     </ListRow>
//   );
// };

export default DistributionQueueView;

// interface DragItem {
//   index: number;
//   id: string;
//   type: string;
// }

// interface DistributionQueueRowProps {
//   id: string,
//   objectId: string,
//   objectName: string,
//   leadsAvailable: number,
//   eligibleUsers: number,
//   index: number,
//   draft: boolean,
//   name?: string,
//   disabled: boolean,
//   createdAt,
//   handleDelete(id: string),
//   handleQueueCLick(edit, id?),
//   handleStatsCLick(id, name),
//   handleMoveRow(dragIndex: number, hoverIndex: number)
//   handleDrop()
// }
