import { useState }           from "react";
import { useMemo }            from "react";
import { useMounted }         from "../../..";
import { useImperativeState } from "../../..";
import { simpleDebounce }     from "../../../utils/helpers";
import { filterData }         from "./helpers";
import { SelectProps }        from "./Select";
import { useCommonSelect }    from "./useCommonSelect";

export function useSelect(props: SelectProps) {
  const { onLoadingChange, disabled, readOnly, options, optionLabel } = props;
  const {
    showSelectedField,
    setSelected,
    handleKeyPress,
    togglePopper,
    handleSelect,
    checkValue,
    valueExist,
    selected,
    anchorBounding,
    setAnchorBounding
  } = useCommonSelect(props);

  const [loading] = useImperativeState(props.loading, onLoadingChange);
  const [search, setSearch] = useState("");
  const disabledMod = useMemo(() => disabled || readOnly, [disabled, readOnly]);
  const [filteredData, setFilteredData] = useState([]);
  const items = useMemo(() => {
    return search ? filteredData : options || [];
  }, [search, filteredData, options]);

  const handleFilter = () => {
    let value = search.toLowerCase();
    let filtered = options.filter(select => filterData(select, value, optionLabel));
    setFilteredData(filtered);
  };

  useMounted(() => {
    if (search) {
      handleFilter()
    }
  }, [search]);

  return {
    search,
    setSearch,
    anchorBounding,
    setAnchorBounding,
    loading,
    handleSelect,
    setSelected,
    selected,
    disabledMod,
    togglePopper,
    handleKeyPress,
    checkValue,
    valueExist,
    showSelectedField,
    items,
    filteredData
  };
}
