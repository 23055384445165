import { useReactiveVar }  from "@apollo/client";
import { Outlet }          from "@relcu/react-router";
import { Navigate }        from "@relcu/react-router";
import { NavigateProps }      from "@relcu/react-router";
import { isAuthenticatedVar } from "../../reactiveVars";
import { Logo }               from "@relcu/ui";
import { Box }             from "@relcu/ui";
import React               from "react";
import "./guest.css";

export interface GuestProps {
  redirect?: NavigateProps
}

export const Guest = React.memo<GuestProps>(function Guest({ redirect = { to: "/" } }) {
  const assets = window.__CONFIG__.assets;
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  if (isAuthenticated && redirect?.to) {
    return <Navigate {...redirect} to={redirect.to}/>;
  }
  return (
    <Box className="main">
      <Box container justify={"center"} direction={"column"} gap={"L"} className="guest">
        <Logo url={`${assets}/icon.svg`} width={64}
              className="logo-container"
              alignSelf={"center"}/>
        <Box container className="guest-container" alignSelf={"center"}>
          <Outlet/>
        </Box>
      </Box>
    </Box>
  );
});
