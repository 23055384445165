import { useFormState }     from "@relcu/form";
import { useField }         from "@relcu/form";
import { useMemo }          from "react";
import { useEffect }        from "react";
import { useState }         from "react";
import { JsonSectionProps } from "../../JsonSection";

export function useStreetSection(section: JsonSectionProps) {
  const [panorama, setPanorama] = useState(null);

  let addressField = useMemo(() => section.fields.find(({ type }) => (type == "Address")), [section.fields]);
  const pickFields = (fields: string[], obj) => {
    let result: any = {};
    if (typeof obj !== "object") {
      return result;
    }
    let keys = Object.keys(obj);
    for (let k of keys) {
      if (fields.includes(k)) {
        result[ k ] = obj[ k ];
      } else if (typeof obj[ k ] === "object") {
        result = { ...result, ...pickFields(fields, obj[ k ]) };
      }
    }
    return result;
  };
  const name = section.path ? [section.path, addressField.name].join(".") : addressField.name;
  const { input: { value: address } } = useField(name, { subscription: { value: true, initial: true } });
  const zillowLink = useMemo(() => {
    if (address?.formatted) {
      return `https://www.zillow.com/homes/${address.formatted.replaceAll(",", "").replaceAll(" ", "-")}_rb/`;
    }
    return `https://www.zillow.com/homes/`;
  }, [address?.formatted]);

  let placesService;
  let sessionToken;
  useEffect(() => {
    try {
      placesService = new window[ "google" ].maps.places.AutocompleteService();
      sessionToken = new window[ "google" ].maps.places.AutocompleteSessionToken();
    } catch (e) {
      console.error("Google SDK is not implemented.", e.message);
    }
  }, []);

  useEffect(() => {
    if (address.lat && address.lng) {
      if (panorama) {
        panorama.setPosition(address);
        panorama.setVisible(true);
      } else {
        let pano = new window[ "google" ].maps.StreetViewPanorama(
          document.getElementById("street-view") as HTMLElement,
          {
            position: address,
            pov: { heading: 165, pitch: 0 },
            zoom: 1
          }
        );
        pano.addListener("status_changed", () => {
          if (pano.getStatus() == "ZERO_RESULTS") {
            pano.setVisible(false);
            document.getElementById("street-view").style.backgroundColor = "transparent";
          }
        });
        setPanorama(pano);

      }
    } else if (panorama) {
      panorama.setVisible(false);
      document.getElementById("street-view").style.backgroundColor = "transparent";
    }
  }, [address?.lat, address?.lng]);

  return {
    panorama,
    zillowLink
  };
}
