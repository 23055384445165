import React                from "react";
import { FC }               from "react";
import { useMemo }          from "react";
import { Box }              from "../../Box";
import { Label }            from "../../Label";
import { BaseFieldClasses } from "../BaseField";
import { useConsumeField }  from "../BaseField";
import { PermissionProps }  from "./PermissionField";

export const PermissionReadField: FC = React.memo(function PermissionReadField(props) {
  const { input: { value, label, options } } = useConsumeField<PermissionProps>();

  const readValue = useMemo(() => {
    let perms = [];
    Object.entries(value ?? {}).forEach(([key, enable]) => enable && perms.push(options.find(o => o.name == key)?.readStateLabel));
    return perms;
  }, [value]);
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"}>
      <Label>{label}</Label>
      {readValue?.length ? readValue.join(", ") : <span className={BaseFieldClasses.ReadFieldNotAvailable}>{"-"}</span>}
    </Box>
  );
});
