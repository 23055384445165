import { ReactNode }         from "react";
import { PropsWithChildren } from "react";
import { FC }                from "react";
import React                from "react";
import { UseLazyCondition } from "./useLazyCondition";
import { useCondition }     from "./useCondition";

export interface WhereProps extends  UseLazyCondition {
  children: ReactNode | ((value: number) => ReactNode)
}
export const Where: FC<WhereProps> = React.memo(withConditionCheck(function Condition(props) {
  const { apply } = useCondition(props);

  if (props.children instanceof Function) {
    return <>{props.children(apply)}</>;
  }

  if (apply) {
    return (
      <>
        {props.children}
      </>
    );
  }
  return null;
}));
Where.defaultProps = {
  source: {}
};
function withConditionCheck(Where: React.FC<UseLazyCondition>) {
  return (props: PropsWithChildren<UseLazyCondition>) => {
    if (props.conditions) {
      return <Where {...props} />;
    }
    return (
      <>
        {props.children}
      </>
    );
  };
}
