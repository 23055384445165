import React                      from "react";
import { FC }                     from "react";
import { Operation }              from "@apollo/client";
import { useApolloNetworkStatus } from "@relcu/network-status";
import { ButtonProps }            from "@relcu/rc";
import { Button }                 from "@relcu/rc";

export const SendBtn: FC<ButtonProps> = React.memo(function SendBtn(props) {
  const { disabled, ...p } = props;
  const { numPendingMutations, numPendingQueries } = useApolloNetworkStatus({
    shouldHandleOperation: (operation: Operation) => {
      return operation.operationName === "UploadFile";
    }
  });

  return <Button
    disabled={(numPendingMutations > 0 || numPendingQueries > 0) || disabled}
    size={"xs"}
    {...p}>
    SEND
  </Button>;
});
