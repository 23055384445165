import React                                 from "react";
import { useMemo }                           from "react";
import { useContext }                        from "react";
import { useReactiveVar }                    from "@apollo/client";
import { useForm }                           from "@relcu/form";
import { useField }                          from "@relcu/form";
import { FormSpy }                           from "@relcu/form";
import { FormSummary }                       from "@relcu/ui";
import { Checkbox, FontIcon }                from "@relcu/ui";
import { MenuItem }                          from "@relcu/ui";
import { Box }                               from "@relcu/ui";
import { CurrencyField }                     from "@relcu/ui";
import { SwitchField }                       from "@relcu/ui";
import { useMounted }                        from "@relcu/ui";
import { useCondition }                      from "@relcu/ui";
import { useSource }                         from "@relcu/ui";
import { Where }                             from "@relcu/ui";
import { Accordion }                         from "@relcu/ui";
import { CommonClasses }                     from "@relcu/ui";
import { classNames }                        from "@relcu/ui";
import { format }                            from "../../../../../../utils/helpers";
import { transformNameToLabel }              from "../../../../../../utils/helpers";
import { CreditReport }                      from "../CreditReport";
import { MoreFees }                          from "../MoreFees";
import { PersistContext }                    from "../PersistProvider";
import PrepaidCosts                          from "../PrepaidCosts";
import { RateClasses }                       from "../Rate/RateClasses";
import { OTHER_COSTS_SUM_FIELDS }            from "../RateConditions";
import { WHEN_PESTINSPECTION_FEE }           from "../RateConditions";
import { WHEN_IS_PURCHASE }                  from "../RateConditions";
import { WHEN_STREAMLINE_WO_APPRAISAL }      from "../RateConditions";
import { PmiProviderPreview }                from "../RatePartner";
import { RatePartnerSelect }                 from "../RatePartner";
import { RateContext }                       from "../Rate/RateProvider";
import { RateSelectBody }                    from "../Rate/RateSelect";
import { usePmi }                            from "../RatePartner/usePmi";
import { RatesContext }                      from "../RatesProvider";
import { WHEN_PROPERTY_TYPE_CONDO }          from "../RateConditions";
import { WHEN_SECONDARY_FINANCING_NOT_NONE } from "../RateConditions";
import { WHEN_IN_SURVEY_FEE_STATES }         from "../RateConditions";
import { WHEN_IN_ATTORNEY_FEE_STATES }       from "../RateConditions";
import { WHEN_IN_DOC_REVIEW_FEE_STATES }     from "../RateConditions";
import { WHEN_FF }                           from "../RateConditions";
import { WHEN_MIP }                          from "../RateConditions";
import { TitleFee }                          from "../TitleFee";
import { LenderCredits }                     from "./TotalClosingCosts";
import { DiscountPoints }                    from "./TotalClosingCosts";
import "./closing-costs.css";

export const ClosingCosts = React.memo(function ClosingCost() {
  const { mortechPoints, adminFee, points } = useContext(RateContext);
  const { $settings: { pricing: { closingFees: $closingFees } } } = useSource();
  const { fees: { toggleClosingFees, closingFeesVar }, leadDataVar } = useContext(PersistContext);
  const lead = useReactiveVar(leadDataVar);
  const closingFees = useReactiveVar(closingFeesVar);
  const { option, loading, isPmiEligible } = usePmi();
  const { input: { value: lenderCredits } } = useField("lenderCredits", { subscription: { value: true } });
  const { apply: whenIsPurchase } = useCondition({
    conditions: WHEN_IS_PURCHASE,
    source: { $object: lead }
  });
  const { apply: whenPropertyTypeCondo } = useCondition({
    conditions: WHEN_PROPERTY_TYPE_CONDO,
    source: { $object: lead }
  });
  const moreFeeDisabled = useMemo(() => {
    return !whenIsPurchase && !whenPropertyTypeCondo;
  }, [whenIsPurchase, whenPropertyTypeCondo]);
  const warning = useMemo(() => lenderCredits > Number(mortechPoints), [lenderCredits, mortechPoints]);
  return <Box container className={"closing-cost"} direction={"column"}>
    <PrepaidCosts/>
    {
      option?.includes("single") && !loading && isPmiEligible &&
      <RatePartnerSelect option="single" partnerImg={<PmiProviderPreview/>}/>
    }
    <TitleFee/>
    <CreditReport/>
    <Accordion
      collapse={false}
      className={RateClasses.RateSelect}
      openClass={RateClasses.RateSelectOpen}
      header={(open) => {
        return <Box container alignItems={"center"} gap={"XS"} flex={1}
                    className={classNames(RateClasses.RateSelectHeader, {
                      [ RateClasses.RateSelectExtended ]: open
                    })}>
          <FontIcon
            className={classNames(CommonClasses.GrayIcon, {
              [ CommonClasses.PrimaryIcon ]: open
            })}
            alignSelf={"center"}
            type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
          <p className={RateClasses.RateSelectTitle} style={{ flexGrow: 1 }}>
            Other closing options
          </p>
          {/*<p className={RateClasses.RateSelectSum}>*/}
          {/*  <PrepaidCostsSum/>*/}
          {/*</p>*/}
          <MoreFees disabled={moreFeeDisabled}>
            {whenIsPurchase ? <>
                <MenuItem alignItems={"stretch"}>
                  <Checkbox
                    style={{ alignSelf: "center" }}
                    leftMessageSpace={false}
                    flex={1}
                    alignSelf={"stretch"}
                    onChange={() => toggleClosingFees("earnestMoneyPaid")}
                    checked={closingFees.includes("earnestMoneyPaid")}
                    placeholder={"Earnest money paid"}/>
                </MenuItem>
                <MenuItem alignItems={"stretch"}>
                  <Checkbox
                    style={{ alignSelf: "center" }}
                    leftMessageSpace={false}
                    flex={1}
                    alignSelf={"stretch"}
                    onChange={() => toggleClosingFees("sellerConcession")}
                    checked={closingFees.includes("sellerConcession")}
                    placeholder={"Seller concession"}/>
                </MenuItem>
              </>
              : ""}
            {
              whenPropertyTypeCondo ? (
                <MenuItem alignItems={"stretch"}>
                  <Checkbox
                    style={{ alignSelf: "center" }}
                    leftMessageSpace={false}
                    flex={1}
                    alignSelf={"stretch"}
                    onChange={() => toggleClosingFees("condoCertificateFee")}
                    checked={closingFees.includes("condoCertificateFee")}
                    placeholder={"Condo certificate fee"}/>
                </MenuItem>
              ) : ""
            }
          </MoreFees>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              return <FormSummary
                data={{
                  points,
                  $closingFees,
                  lenderCredits: -values.lenderCredits,
                  sellerConcession: -values.sellerConcession,
                  earnestMoneyPaid: -values.earnestMoneyPaid,
                  closingFees
                }}
                fields={OTHER_COSTS_SUM_FIELDS}>
                {
                  (value) => {
                    return <>{"$"}{format(value, 2)}</>;
                  }
                }
              </FormSummary>;
            }}
          </FormSpy>
        </Box>;
      }}>
      <RateSelectBody className={"other-fees"} divider horizontalDivider={false} direction={"row"} wrap={"wrap"}
                      style={{ padding: 0 }}>
        <Where conditions={WHEN_STREAMLINE_WO_APPRAISAL} source={{ $object: lead }}>
          <Box container flexBasis={"calc(50% - ( 16px / 2 ))"} gap={"XXS"}>
            <CurrencyField
              view={"write"}
              name={"appraisalFee"}
              disabled
              precision={2}
              label={"Appraisal fee"}
              flexGrow={1}
            />
            <SwitchField name={"waive"} label={"Waive"}/>
            <WaiveSpy/>
          </Box>
        </Where>
        <LenderCredits/>
        <DiscountPoints/>
        <CurrencyField view={"write"} precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"} disabled
                       name={"floodCertificationFee"}
                       label={"Flood certificate fee"}/>
        <CurrencyField view={"write"} precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"} disabled={!adminFee.editable}
                       name={"adminFee"} label={"Admin fee"}/>
        <Where conditions={WHEN_MIP} source={{ $object: lead }}>
          <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"fundingFee"}
                         label={"MIP"}/>
        </Where>
        <Where conditions={WHEN_FF} source={{ $object: lead }}>
          <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"fundingFee"}
                         label={"Funding fee"}/>
        </Where>
        <Where source={{ $closingFees, $object: lead }} conditions={WHEN_PESTINSPECTION_FEE}>
          <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"pestInspectionFee"}
                         label={"Pest inspection"}/>
        </Where>
        <Where source={{ $closingFees, $object: lead }} conditions={WHEN_IN_DOC_REVIEW_FEE_STATES}>
          <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"docReviewFee"}
                         label={"Doc review fee"}/>
        </Where>
        <Where source={{ $closingFees, $object: lead }} conditions={WHEN_IN_ATTORNEY_FEE_STATES}>
          <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"attorneyFee"}
                         label={"Attorney fee"}/>
        </Where>
        <Where source={{ $closingFees, $object: lead }} conditions={WHEN_IN_SURVEY_FEE_STATES}>
          <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"} name={"surveyFee"}
                         label={"Survey"}/>
        </Where>
        <Where conditions={WHEN_SECONDARY_FINANCING_NOT_NONE} source={{ $object: lead }}>
          <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"subordinationFee"}
                         label={"Subordination fee"}/>
        </Where>
        {
          closingFees.map((name) => <CurrencyField
            view={"write"}
            key={name}
            precision={2}
            disabled={name == "condoCertificateFee"}
            flexBasis={"calc(50% - ( 16px / 2 ))"}
            name={name}
            label={transformNameToLabel(name)}
          />)
        }
      </RateSelectBody>
    </Accordion>

  </Box>;
});

const WaiveSpy = React.memo(function WaiveSpy() {
  const form = useForm();
  const { getAppraisalFeeValue } = useContext(RatesContext);
  const { input: { value } } = useField("waive", { subscription: { value: true } });
  useMounted(() => {
    if (value) {
      form.change("appraisalFee", 0);
    } else {
      form.change("appraisalFee", getAppraisalFeeValue());
    }
  }, [value]);
  return null;
});

export default ClosingCosts;
