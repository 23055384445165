import React                                from "react";
import { FC }                               from "react";
import { Form }                             from "@relcu/form";
import { Field }                            from "@relcu/form";
import { ModalFooter }                      from "@relcu/ui";
import { ModalBody }                        from "@relcu/ui";
import { Checkbox }                         from "@relcu/ui";
import { InlineEditableInput }              from "@relcu/ui";
import { InlineEditableReadView }           from "@relcu/ui";
import { FontIcon }                         from "@relcu/ui";
import { classNames }                       from "@relcu/ui";
import { Accordion }                        from "@relcu/ui";
import { ButtonVariants }                   from "@relcu/ui";
import { Button }                           from "@relcu/ui";
import { ModalProps }                       from "@relcu/ui";
import { Where }                            from "@relcu/ui";
import { Box }                              from "@relcu/ui";
import { Modal }                            from "@relcu/ui";
import { CurrencyInlineEditableField }      from "@relcu/ui";
import { CurrencyInputField }               from "@relcu/ui";
import { TextInlineEditableField }          from "@relcu/ui";
import { CommonClasses }                    from "@relcu/ui";
import { format }                           from "../../../../../utils/helpers";
import { InlineSummaryField }               from "./InlineSummaryFiled";
import { LoanProposalDialogClasses }        from "./LoanProposalDialogClasses";
import { WHEN_DISCOUNT_POINT_NOT_NULL }     from "./LoanProposalOfferConditions";
import { WHEN_LENDER_CREDIT_NOT_NULL }      from "./LoanProposalOfferConditions";
import { WHEN_SELLER_CONCESSION_NOT_NULL }  from "./LoanProposalOfferConditions";
import { WHEN_EARNEST_MONEY_PAID_NOT_NULL } from "./LoanProposalOfferConditions";
import { OfferBody }                        from "./Offer/Offer";
import { OfferClasses }                     from "./Offer/OfferClasses";
import { OfferTitle }                       from "./Offer/Offer";
import { OfferHeaderRow }                   from "./Offer/Offer";
import { OfferHeader }                      from "./Offer/Offer";
import { Offer }                            from "./Offer/Offer";
import { useLoadProposalDialog }            from "./useLoadProposalDialog";
import "./loan-proposal-dialog.css";

export interface LoanProposalDialogProps extends Partial<ModalProps> {
  id: string;
  templateId: string;
  onPreview: (file: any, objectId) => void;
}

export const LoanProposalDialog: FC<LoanProposalDialogProps> = React.memo(function LoanProposalDialog(props) {
  const { id, templateId, onClose, onPreview } = props;
  const {
    offers,
    count,
    openFiled,
    loanPurposes,
    prepaid,
    closing,
    monthly,
    includedOffers,
    proposalTitle,
    setProposalTitle,
    validateMonth,
    validateDay,
    loading,
    onEdit,
    handleGenerate,
    applyClassName,
    handleSubmit,
    handleMonthlyToggle,
    handleClosingToggle,
    handlePrepaidToggle,
    handleIncludeToggle,
    handleCancel
  } = useLoadProposalDialog({ id, templateId, onClose, onPreview });

  return <Modal
    title={"Generate loan proposal"}
    onClose={props.onClose}
    open={true}
    closeIcon={true}
    disableBackdropClick={false}
    variant="big"
    className={LoanProposalDialogClasses.Modal}>
    <ModalBody className={LoanProposalDialogClasses.Container}>
      <Box container className={LoanProposalDialogClasses.Body}>
        <Offer container direction={"column"} flexBasis={`calc(100% / ${count + 1})`}>
          <Box container className={LoanProposalDialogClasses.Title} alignItems={"center"}>
            <InlineEditableInput
              justify={"start"}
              value={proposalTitle}
              onChange={(value) => setProposalTitle(value)}
            />
          </Box>
          <OfferHeader>
            <OfferHeaderRow>Loan program</OfferHeaderRow>
            <OfferHeaderRow>Loan purpose</OfferHeaderRow>
            <OfferHeaderRow>Interest rate</OfferHeaderRow>
            <OfferHeaderRow>Loan amount</OfferHeaderRow>
            <OfferHeaderRow>Cash at closing</OfferHeaderRow>
          </OfferHeader>
          <OfferBody>
            {/* Monthly Payment*/}
            {/*<OfferHeaderRow>PMI</OfferHeaderRow>*/}
            <OfferHeaderRow className={OfferClasses.OfferSectionRow} style={{ paddingLeft: 32 }}>
              Lender credits
            </OfferHeaderRow>
            <Accordion
              alignSelf={"stretch"}
              collapse={monthly}
              onToggle={handleMonthlyToggle}
              header={(open) => (
                <Box container alignItems={"center"} gap={"XXS"}>
                  <FontIcon
                    className={classNames(CommonClasses.GrayIcon, {
                      [ CommonClasses.PrimaryIcon ]: open
                    })}
                    alignSelf={"center"}
                    type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
                  <OfferHeaderRow className={OfferClasses.OfferSectionRow}>Monthly payment</OfferHeaderRow>
                </Box>
              )}>
              <OfferHeaderRow>PMI</OfferHeaderRow>
              <OfferHeaderRow>Principal & Interest</OfferHeaderRow>
              <OfferHeaderRow>Insurance</OfferHeaderRow>
              <OfferHeaderRow>Taxes</OfferHeaderRow>
              <OfferHeaderRow>Monthly premium</OfferHeaderRow>
            </Accordion>
            <Accordion
              alignSelf={"stretch"}
              collapse={closing}
              onToggle={handleClosingToggle}
              header={(open) => (
                <Box container alignItems={"center"} gap={"XXS"}>
                  <FontIcon
                    className={classNames(CommonClasses.GrayIcon, {
                      [ CommonClasses.PrimaryIcon ]: open
                    })}
                    alignSelf={"center"}
                    type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
                  <OfferHeaderRow className={OfferClasses.OfferSectionRow}>Closing costs</OfferHeaderRow>
                </Box>
              )}>
              <OfferHeaderRow>PMI</OfferHeaderRow>
              <OfferHeaderRow>Credit report and other fees</OfferHeaderRow>
              <OfferHeaderRow>Title fees</OfferHeaderRow>
              <OfferHeaderRow>Appraisal fee</OfferHeaderRow>
              <OfferHeaderRow>Flood certificate fee</OfferHeaderRow>
              <OfferHeaderRow>Condo certificate fee</OfferHeaderRow>
              <OfferHeaderRow>Discount points</OfferHeaderRow>
              <OfferHeaderRow>Admin fee</OfferHeaderRow>
              <OfferHeaderRow>Funding fee</OfferHeaderRow>
              <OfferHeaderRow>Mip</OfferHeaderRow>
              <OfferHeaderRow>Pest inspection</OfferHeaderRow>
              <OfferHeaderRow>Doc review fee</OfferHeaderRow>
              <OfferHeaderRow>Attorney fee</OfferHeaderRow>
              <OfferHeaderRow>Survey</OfferHeaderRow>
              <OfferHeaderRow>Subordination fee</OfferHeaderRow>
              <OfferHeaderRow>Seller concession</OfferHeaderRow>
              <OfferHeaderRow>Earnest money paid</OfferHeaderRow>
            </Accordion>
            {/*<OfferHeaderRow>PMI</OfferHeaderRow>*/}

            {/* Prepaid Cost */}
            <Accordion
              alignSelf={"stretch"}
              collapse={prepaid}
              onToggle={handlePrepaidToggle}
              header={(open) => (
                <Box container alignItems={"center"} gap={"XXS"}>
                  <FontIcon
                    className={classNames(CommonClasses.GrayIcon, {
                      [ CommonClasses.PrimaryIcon ]: open
                    })}
                    alignSelf={"center"}
                    type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
                  <OfferHeaderRow className={OfferClasses.OfferSectionRow}>Prepaid costs</OfferHeaderRow>
                </Box>
              )}>
              <OfferHeaderRow>Property insurance (yearly)</OfferHeaderRow>
              <OfferHeaderRow>Lock in fee</OfferHeaderRow>
              <OfferHeaderRow className={applyClassName("tax")}>
                Property tax(prepaid)
              </OfferHeaderRow>
              <OfferHeaderRow className={applyClassName("insurance")}>Property insurance(prepaid)</OfferHeaderRow>
              <OfferHeaderRow className={applyClassName("interest")}>Prepaid interest</OfferHeaderRow>
            </Accordion>
          </OfferBody>
        </Offer>
        {
          offers.map(({ node }, index) => {
            return <Offer container direction={"column"} flexBasis={`calc(100% / ${count + 1})`} key={node.id}>
              <Form
                subscription={{
                  invalid: true,
                  submitting: true
                }}
                initialValues={node}
                keepDirtyOnReinitialize={true}
                onSubmit={handleSubmit}>
                {({ handleSubmit, form, invalid, submitting }) => (
                  <>
                    <OfferTitle alignItems={"center"} gap={"XXS"}>
                      {
                        openFiled != `objectName:${index}` &&
                        <Checkbox
                          leftMessageSpace={false}
                          className={OfferClasses.OfferCheckbox}
                          checked={includedOffers?.includes(node.objectId)}
                          onChange={() => handleIncludeToggle(node.objectId)}/>
                      }
                      <TextInlineEditableField
                        renderReadActions={({ onEdit }) => {
                          return <FontIcon type="create" key={"create"} className={CommonClasses.ClickableWhiteIcon}
                                           onClick={onEdit}/>;
                        }}
                        onEdit={() => onEdit(`objectName:${index}`)}
                        isEditing={openFiled == `objectName:${index}`}
                        onConfirm={handleSubmit}
                        justify={"start"}
                        name={"objectName"}
                        onCancel={() => handleCancel(form)}
                        classes={{ actions: OfferClasses.OfferInlineEditableAFiledActions }}/>
                    </OfferTitle>
                    <OfferHeader revers>
                      <Field name={"selectedRate.productName"}>{({ input: { value } }) =>
                        <OfferHeaderRow>{value}</OfferHeaderRow>}</Field>
                      <Field name={"loanCriteria.loanPurpose"}>{({ input: { value: loanPurpose } }) =>
                        <OfferHeaderRow>{loanPurposes.options.find(({ value }) => value == loanPurpose)?.label}</OfferHeaderRow>}
                      </Field>
                      <Field name={"selectedRate.quoteDetails.rate"}>{({ input: { value } }) =>
                        <OfferHeaderRow>{`${value}%`}</OfferHeaderRow>}
                      </Field>
                      <Field name={"loanCriteria.totalLoanAmount"}>{({ input: { value } }) =>
                        <OfferHeaderRow>{`$${format(value, 2)}`}</OfferHeaderRow>}
                      </Field>
                      <Field name={"cashAtClosing"}>{({ input: { value } }) => <>
                        <OfferHeaderRow direction={"column"} gap={"XXXS"}>
                          {`$${format(value, 2)}`}
                          <p
                            className={LoanProposalDialogClasses.CalculationItem}>{value > 0 ? "(From you)" : "(To you)"}</p>
                        </OfferHeaderRow>

                      </>}
                      </Field>
                    </OfferHeader>
                    <OfferBody revers style={{ paddingLeft: 32 }}>
                      <Where source={{ ...node }} conditions={WHEN_LENDER_CREDIT_NOT_NULL}>
                        {(apply) => (
                          <CurrencyInlineEditableField precision={2}
                                                       disableActionsButtons={invalid || submitting}
                                                       hideActionButtons={!apply}
                                                       onEdit={() => onEdit(`lenderCredits:${index}`)}
                                                       isEditing={openFiled == `lenderCredits:${index}`}
                                                       onCancel={() => handleCancel(form)}
                                                       onConfirm={handleSubmit}
                                                       name={"lenderCredits"}
                                                       style={{ fontWeight: "bold" }}/>
                        )}
                      </Where>
                      <Accordion
                        alignSelf={"stretch"}
                        collapse={monthly}
                        onToggle={handleMonthlyToggle}
                        header={(open) => (
                          <Box container alignItems={"center"} gap={"XXS"}>
                            <CurrencyInlineEditableField precision={2} style={{ fontWeight: "bold" }}
                                                         hideActionButtons
                                                         name={"monthlyPayment.total"}/>
                          </Box>
                        )}>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"monthlyPayment.pmi"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"monthlyPayment.pi"}/>
                        <CurrencyInlineEditableField
                          precision={2}
                          disableActionsButtons={invalid || submitting}
                          onEdit={() => onEdit(`monthlyPayment.insurance:${index}`)}
                          isEditing={openFiled == `monthlyPayment.insurance:${index}`}
                          name={"monthlyPayment.insurance"}
                          onCancel={() => handleCancel(form)}
                          onConfirm={handleSubmit}
                        />
                        <CurrencyInlineEditableField
                          precision={2}
                          disableActionsButtons={invalid || submitting}
                          onEdit={() => onEdit(`monthlyPayment.tax:${index}`)}
                          isEditing={openFiled == `monthlyPayment.tax:${index}`}
                          name={"monthlyPayment.tax"}
                          onCancel={() => handleCancel(form)}
                          onConfirm={handleSubmit}
                        />
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"monthlyPayment.mi"}/>
                      </Accordion>
                      {/*todo add conditional pmi*/}

                      {/*Closing Cost*/}
                      <Accordion
                        alignSelf={"stretch"}
                        collapse={closing}
                        onToggle={handleClosingToggle}
                        header={(open) => (
                          <Box container alignItems={"center"} gap={"XXS"}>
                            <CurrencyInlineEditableField style={{ fontWeight: "bold" }} hideActionButtons
                                                         name={"closingCost.total"}/>
                          </Box>
                        )}>
                        <CurrencyInlineEditableField precision={2} hideActionButtons
                                                     name={"closingCost.pmi"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons
                                                     name={"closingCost.creditAndOther.total"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.title.total"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.appraisalFee"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons
                                                     name={"closingCost.floodCertification"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons
                                                     name={"closingCost.condoCertificate"}/>
                        <Where source={{ ...node }} conditions={WHEN_DISCOUNT_POINT_NOT_NULL}>
                          {(apply) => (
                            <CurrencyInlineEditableField precision={2}
                                                         disableActionsButtons={invalid || submitting}
                                                         hideActionButtons={!apply}
                                                         onEdit={() => onEdit(`closingCost.discountPoints:${index}`)}
                                                         isEditing={openFiled == `closingCost.discountPoints:${index}`}
                                                         onCancel={() => handleCancel(form)}
                                                         onConfirm={handleSubmit}
                                                         name={"closingCost.discountPoints"}/>
                          )}
                        </Where>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.adminFee"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.fundingFee"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.mip"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons
                                                     name={"closingCost.pestInspection"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.docReview"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.attorney"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"closingCost.survey"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons
                                                     name={"closingCost.subordination"}/>
                        <Where source={{ ...node }} conditions={WHEN_SELLER_CONCESSION_NOT_NULL}>
                          {(apply) => (
                            <CurrencyInlineEditableField
                              precision={2}
                              disableActionsButtons={invalid || submitting}
                              onEdit={() => onEdit(`closingCost.sellerConcession:${index}`)}
                              isEditing={openFiled == `closingCost.sellerConcession:${index}`}
                              onCancel={() => handleCancel(form)}
                              onConfirm={handleSubmit}
                              hideActionButtons={!apply}
                              name={"closingCost.sellerConcession"}/>
                          )}
                        </Where>
                        <Where source={{ ...node }} conditions={WHEN_EARNEST_MONEY_PAID_NOT_NULL}>
                          {(apply) => (
                            <CurrencyInlineEditableField
                              precision={2}
                              disableActionsButtons={invalid || submitting}
                              onEdit={() => onEdit(`closingCost.earnestMoneyPaid:${index}`)}
                              isEditing={openFiled == `closingCost.earnestMoneyPaid:${index}`}
                              onCancel={() => handleCancel(form)}
                              onConfirm={handleSubmit}
                              hideActionButtons={!apply}
                              name={"closingCost.earnestMoneyPaid"}/>
                          )}
                        </Where>
                      </Accordion>

                      {/* Prepaid Cost */}
                      <Accordion
                        alignSelf={"stretch"}
                        collapse={prepaid}
                        onToggle={handlePrepaidToggle}
                        header={(open) => (
                          <Box container alignItems={"center"} gap={"XXS"}>
                            <CurrencyInlineEditableField
                              precision={2}
                              style={{ fontWeight: "bold" }}
                              hideActionButtons
                              name={"prepaid.total"}/>
                          </Box>
                        )}>
                        <CurrencyInlineEditableField
                          precision={2}
                          disableActionsButtons={invalid || submitting}
                          hideActionButtons={!!(node.prepaid.yearlyInsurance || node.prepaid.yearlyInsurance != 0)}
                          name={"prepaid.yearlyInsurance"}/>
                        <CurrencyInlineEditableField precision={2} hideActionButtons name={"prepaid.lockInFee"}/>
                        <InlineSummaryField
                          disableActionsButtons={invalid || submitting}
                          classes={{
                            root: applyClassName("tax")
                          }}
                          onEdit={() => onEdit(`tax:${index}`)}
                          isEditing={openFiled == `tax:${index}`}
                          readView={() => <Box container gap={"XXS"}>
                            <Box container alignItems={"center"} gap={"XXXS"} style={{ color: "lightgray" }}>
                              <Field name={"prepaid.monthsToPayTax"} subscription={{ value: true }}>
                                {({ input: { value } }) => <p
                                  className={LoanProposalDialogClasses.CalculationItem}>{`${value} `}</p>}
                              </Field>
                              <p className={LoanProposalDialogClasses.CalculationItem}>*</p>
                              <Field name={"prepaid.monthlyTax"} subscription={{ value: true }}>
                                {({ input: { value } }) => <p
                                  className={LoanProposalDialogClasses.CalculationItem}>{` $${format(value, 2)}`}</p>}
                              </Field>
                              <p className={LoanProposalDialogClasses.CalculationItem}>=</p>
                            </Box>
                            <Field name={"prepaid.tax"} subscription={{ value: true }}>
                              {({ input: { value } }) => <InlineEditableReadView value={`$${format(value, 2)}`}/>}
                            </Field>
                          </Box>
                          }
                          editView={() => <InlineSummaryField.EditView>
                            <CurrencyInputField label="Month" validate={validateMonth} name={"prepaid.monthsToPayTax"}/>
                            <p>*</p>
                            <CurrencyInputField label="Amount" name={"prepaid.monthlyTax"}/>
                            <p>=</p>
                            <Field name={"prepaid.tax"}>
                              {
                                ({ input: { value } }) =>
                                  <InlineSummaryField.Value>{`$${format(value, 2)}`}</InlineSummaryField.Value>
                              }
                            </Field>
                          </InlineSummaryField.EditView>
                          }
                          onCancel={() => handleCancel(form)}
                          onConfirm={handleSubmit}
                        />
                        <InlineSummaryField
                          disableActionsButtons={invalid || submitting}
                          classes={{
                            root: applyClassName("insurance")
                          }}
                          onEdit={() => onEdit(`insurance:${index}`)}
                          isEditing={openFiled == `insurance:${index}`}
                          readView={() => <Box container gap={"XXS"}>
                            <Box container alignItems={"center"} gap={"XXXS"} style={{ color: "lightgray" }}>
                              <Field name={"prepaid.monthsToPayInsurance"} subscription={{ value: true }}>
                                {({ input: { value } }) => <p
                                  className={LoanProposalDialogClasses.CalculationItem}>{`${value} `}</p>}
                              </Field>
                              <p className={LoanProposalDialogClasses.CalculationItem}>*</p>
                              <Field name={"prepaid.monthlyInsurance"} subscription={{ value: true }}>
                                {({ input: { value } }) => <p
                                  className={LoanProposalDialogClasses.CalculationItem}>{` $${format(value, 2)}`}</p>}
                              </Field>
                              <p className={LoanProposalDialogClasses.CalculationItem}>=</p>
                            </Box>
                            <Field name={"prepaid.insurance"} subscription={{ value: true }}>
                              {({ input: { value } }) => <InlineEditableReadView value={`$${format(value, 2)}`}/>}
                            </Field>
                          </Box>
                          }
                          editView={() => <InlineSummaryField.EditView>
                            <CurrencyInputField label="Month" validate={validateMonth}
                                                name={"prepaid.monthsToPayInsurance"}/>
                            <p>*</p>
                            <CurrencyInputField label="Amount" name={"prepaid.monthlyInsurance"}/>
                            <p>=</p>
                            <Field name={"prepaid.insurance"}>
                              {
                                ({ input: { value } }) =>
                                  <InlineSummaryField.Value>{`$${format(value, 2)}`}</InlineSummaryField.Value>
                              }
                            </Field>
                          </InlineSummaryField.EditView>
                          }
                          onCancel={() => handleCancel(form)}
                          onConfirm={handleSubmit}
                        />
                        <InlineSummaryField
                          disableActionsButtons={invalid || submitting}
                          classes={{
                            root: applyClassName("interest")
                          }}
                          onEdit={() => onEdit(`interest:${index}`)}
                          isEditing={openFiled == `interest:${index}`}
                          readView={() => <Box container gap={"XXS"}>
                            <Box container alignItems={"center"} gap={"XXXS"}>
                              <Field name={"prepaid.daysToPayInterest"} subscription={{ value: true }}>
                                {({ input: { value } }) => <p
                                  className={LoanProposalDialogClasses.CalculationItem}>{`${value} `}</p>}
                              </Field>
                              <p className={LoanProposalDialogClasses.CalculationItem}>*</p>
                              <Field name={"prepaid.dailyInterest"} subscription={{ value: true }}>
                                {({ input: { value } }) => <p
                                  className={LoanProposalDialogClasses.CalculationItem}>{` $${format(value, 2)}`}</p>}
                              </Field>
                              <p className={LoanProposalDialogClasses.CalculationItem}>=</p>
                            </Box>
                            <Field name={"prepaid.interest"} subscription={{ value: true }}>
                              {({ input: { value } }) => <InlineEditableReadView value={`$${format(value, 2)}`}/>}
                            </Field>
                          </Box>
                          }
                          editView={() => <InlineSummaryField.EditView>
                            <CurrencyInputField label="Day" validate={validateDay}
                                                name={"prepaid.daysToPayInterest"}/>
                            <p>*</p>
                            <CurrencyInputField label="Amount" name={"prepaid.dailyInterest"}/>
                            <p>=</p>
                            <Field name={"prepaid.interest"}>
                              {
                                ({ input: { value } }) =>
                                  <InlineSummaryField.Value>{`$${format(value, 2)}`}</InlineSummaryField.Value>
                              }
                            </Field>
                          </InlineSummaryField.EditView>
                          }
                          onCancel={() => handleCancel(form)}
                          onConfirm={handleSubmit}
                        />
                      </Accordion>
                    </OfferBody>
                  </>
                )}
              </Form>
            </Offer>;
          })
        }
      </Box>
    </ModalBody>
    <ModalFooter>
      <Button variant={ButtonVariants.Outline} onClick={() => onClose()}>CANCEL</Button>
      <Button type="submit" disabled={!includedOffers?.length || loading} onClick={handleGenerate}>GENERATE</Button>
    </ModalFooter>
  </Modal>;
});

export default LoanProposalDialog;
