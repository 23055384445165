import React             from "react";
import { FC }            from "react";
import { useState }      from "react";
import { useEffect }     from "react";
import { SelectPicker }  from "@relcu/rc";
import { useClassNames } from "@relcu/rc";
import { Icon }          from "@relcu/rc";

export interface InjectionAddonProps {
  controlId: string;
  onChange?(newValue: string);
  data?: any[];
}

export const InjectionAddon: FC<InjectionAddonProps> = React.memo(function InjectionAddon(props) {
  const { controlId, data } = props;
  const [cursorPositions, setCursorPositions] = useState(null);

  useEffect(() => {
    const controlInput = document.querySelector(controlId);
    controlInput.addEventListener("blur", (ev) => {
      setCursorPositions({ start: ev.target[ "selectionStart" ], end: ev.target[ "selectionEnd" ] });
    });

    return () => {
      controlInput.removeEventListener("blur", (ev) => {
        setCursorPositions({ start: ev.target[ "selectionStart" ], end: ev.target[ "selectionEnd" ] });
      });
    };
  }, []);

  const onInsert = (param) => {
    const input = document.querySelector<HTMLInputElement>(controlId);
    const value = input.value;
    if (cursorPositions == null) {
      props?.onChange(`${value} ${param}`);
    } else {
      props?.onChange(value.substring(0, cursorPositions.start) + param + value.substring(cursorPositions.end, value.length));
    }

    input.focus();
    // input.setSelectionRange(cursorPositions.start, cursorPositions.start + param.length);
  };
  const { withClassPrefix, merge } = useClassNames("input-group-btn");
  const { withClassPrefix: withAddOnClassPrefix } = useClassNames("input-group-addon");
  const classes = merge(withAddOnClassPrefix(), withClassPrefix());

  return <SelectPicker
    className={classes}
    style={{ alignSelf: "end" }}
    menuAutoWidth={false}
    menuStyle={{ minWidth: 300 }}
    data={data}
    placement={"autoVerticalEnd"}
    value={null}
    onChange={(item) => onInsert(item)}
    groupBy={"role"}
    toggleAs={InjectionToggle}
    searchable={true}
  />;
});

export const InjectionToggle: FC<InjectionAddonProps> = React.forwardRef(function InjectionToggle(props, ref: any) {
  return <Icon type={"rc_data_object"}
               ref={ref}
               style={{ transform: "translateY(25%)", fontSize: 20, color: "var(--rc-accent-03-primary)" }}/>;
});
