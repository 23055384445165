import React                                   from "react";
import { useContext }                          from "react";
import { useForm }                             from "@relcu/form";
import { Box }                                 from "@relcu/ui";
import { Where }                               from "@relcu/ui";
import { CurrencyField }                       from "@relcu/ui";
import { RateClasses }                         from "../Rate/RateClasses";
import { WHEN_MONTHLY_PREMIUM_GREATER_THEN_0 } from "../RateConditions";
import { PmiProviderPreview }                  from "../RatePartner";
import { RatePartnerSelect }                   from "../RatePartner";
import { RateContext }                         from "../Rate/RateProvider";
import { usePmi }                              from "../RatePartner/usePmi";
import { MonthlyPaymentClasses }               from "./MonthlyPaymentClasses";
import { MonthlyPaymentSum }                   from "./MonthlyPaymentSum";
import "./monthly-payment.css"
export const MonthlyPayment = React.memo(function MonthlyPayment(props) {
  const { updateTaxInsurance, monthlyPremium } = useContext(RateContext);
  const form = useForm();
  const { option, loading, error, isPmiEligible } = usePmi();

  return <Box container direction={"column"}>
    <Box container justify={"space-between"} className={RateClasses.RateSectionHeader}>
      <h2 className={RateClasses.SectionTitle}>Monthly Payment</h2>
      <p className={RateClasses.SectionSum}>
        <MonthlyPaymentSum/>
      </p>
    </Box>
    <Box container flex={1}  wrap={"wrap"} className={MonthlyPaymentClasses.MonthlyPayment}>
      <CurrencyField view={"write"} flexBasis={"calc(50% - ( 16px / 2 ))"} name="principal" disabled
                     label={"Principal & Interest"} precision={2}/>
      <CurrencyField view={"write"} flexBasis={"calc(50% - ( 16px / 2 ))"} name="monthlyTax" label={"Taxes"}
                     placeholder={"Input monthly taxes here"} precision={2}
                     onBlur={() => updateTaxInsurance("monthlyTax", form.getFieldState("monthlyTax").value, form)}/>
      <CurrencyField view={"write"} flexBasis={"calc(50% - ( 16px / 2 ))"} name="monthlyInsurance" label={"Insurance"}
                     onBlur={() => updateTaxInsurance("monthlyInsurance", form.getFieldState("monthlyInsurance").value, form)}
                     placeholder={"Input monthly insurance here"} precision={2}/>
      <Where source={{ monthlyPremium }} conditions={WHEN_MONTHLY_PREMIUM_GREATER_THEN_0}>
        <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                       name={"monthlyPremium"}
                       label={"Mortgage insurance"}/>
      </Where>
    </Box>
    {
      (option?.includes("monthly") || loading || error ) && isPmiEligible &&
      <div style={{ margin:'16px'}}>
        <RatePartnerSelect option={"monthly"} partnerImg={<PmiProviderPreview/>} flexBasis={"100%"}/>
      </div>
    }
  </Box>;
});
