import React                        from "react";
import { StandardProps }            from "../@types/common";
import { RsRefForwardingComponent } from "../@types/common";
import { IconProps }                from "../Icon";
import { Icon }                     from "../Icon";
import { useClassNames, useCustom } from "../utils";
// import { CloseButtonLocale }        from "../locales";

export interface ActionIconProps extends StandardProps, IconProps {
  /** Custom locale */
  // locale?: CloseButtonLocale;

}

/**
 * Close button for components such as Message and Notification.
 */
const ActionIcon: RsRefForwardingComponent<"i", ActionIconProps> = React.forwardRef(
  (props: ActionIconProps, ref) => {
    const {
      classPrefix = "action-icon",
      className,
      // locale: overrideLocale,
      ...rest
    } = props;

    const { withClassPrefix, merge } = useClassNames(classPrefix, "rc");
    // const { locale } = useCustom<CloseButtonLocale>("ActionIcon", overrideLocale);
    const classes = merge(className, withClassPrefix());

    return (
      <Icon
        tabIndex={0}
        role="button"
        {...rest}
        className={classes}
        // aria-label={locale?.closeLabel}
      />
    );
  }
);

ActionIcon.displayName = "ActionIcon";

export default ActionIcon;
