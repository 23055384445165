import React               from "react";
import { InputState }      from "../../Input/Input";
import { Time }            from "../../Input/Time";
import { useConsumeField } from "../BaseField";
import { TimeFieldProps }  from "./TimeField";

export const TimeEditField = React.memo(function TimeEditField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField<TimeFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  return (
    <Time
      {...input}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}/>
  );
});
export default TimeEditField;
