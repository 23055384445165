import { useState }                       from "react";
import { useEffect }                      from "react";
import { useCallback }                    from "react";
import { useContext }                     from "react";
import { useMemo }                        from "react";
import { useReactiveVar }                 from "@apollo/client";
import { gql, useMutation }               from "@apollo/client";
import { FormApi }                        from "@relcu/form";
import { schemaVar }                      from "../../../../../../reactiveVars";
import { transformFields }                from "../../../../../../utils/graphQlUtils";
import { format }                         from "../../../../../../utils/helpers";
import { daysOfaYear }                    from "../../../../../../utils/helpers";
import { loanAmountDetails }              from "../../../../../../utils/helpers";
import { isNumeric }                      from "../../../../../../utils/utils";
import { OFFER_FRAGMENT }                 from "../../operations.graphql";
import { PersistContext }                 from "../PersistProvider";
import { WHEN_PESTINSPECTION_FEE }        from "../RateConditions";
import { TOTAL_CLOSING_COSTS_SUM_FIELDS } from "../RateConditions";
import { WHEN_STREAMLINE_WO_CREDIT }      from "../RateConditions";
import { WHEN_STREAMLINE_WO_APPRAISAL }   from "../RateConditions";
import { MONTHLY_PAYMENT_SUM_FIELDS }     from "../RateConditions";
import { PREPAID_COSTS_SUM_FIELDS }       from "../RateConditions";
import { CREDIT_REPORT_SUM_FIELDS }       from "../RateConditions";
import { TITLE_FEE_SUM_FIELDS }           from "../RateConditions";
import { CLOSING_COSTS_SUM_FIELDS }          from "../RateConditions";
import { WHEN_IS_PURCHASE }                  from "../RateConditions";
import { WHEN_SECONDARY_FINANCING_NOT_NONE } from "../RateConditions";
import { WHEN_IN_SURVEY_FEE_STATES }         from "../RateConditions";
import { WHEN_IN_ATTORNEY_FEE_STATES }       from "../RateConditions";
import { WHEN_IN_DOC_REVIEW_FEE_STATES }     from "../RateConditions";
import { WHEN_FF }                           from "../RateConditions";
import { WHEN_MIP }                          from "../RateConditions";
import { WHEN_LENDER_CREDITS }               from "../RateConditions";
import { WHEN_DISCOUNT_POINT }               from "../RateConditions";
import { RatesContext }                      from "../RatesProvider";
import { CreateOfferVariables }              from "./__types__/CreateOffer";
import { CreateOffer }                       from "./__types__/CreateOffer";
import { UpdateTaxInsuranceVariables }       from "./__types__/UpdateTaxInsurance";
import { UpdateTaxInsurance }                from "./__types__/UpdateTaxInsurance";
import { IRate }                             from "./RateProvider";
import { useAlert }                          from "@relcu/ui";
import { useSource }                         from "@relcu/ui";
import { useLazySummary }                    from "@relcu/ui";
import { useCondition }                      from "@relcu/ui";

const dbFiledMap = {
  monthlyInsurance: "annualInsurance",
  monthlyTax: "annualTaxes"
};
type Lead = any
export function useRate(props: IRate, active?: boolean, sticky?: boolean) {
  const { success, error, info } = useAlert();
  const schemas = schemaVar();
  const [update] = useMutation<UpdateTaxInsurance, UpdateTaxInsuranceVariables>(UPDATE_TAX_INSURANCE);
  const [create] = useMutation<CreateOffer, CreateOfferVariables>(CREATE_OFFER);
  const { $settings: { pricing: settings, decouplePricingPage }, $object } = useSource();
  const points = useMemo(() => props.points, [props.points]);
  const adjustments = useMemo(() => props.adjustments, [props.adjustments]);
  const productDesc = useMemo(() => props.productDesc, [props.productDesc]);
  const { appraisalFeeValue, condoCertificateFee, pmiResult, titleFeeResult, selected: { pmi: { selectedPmiVar }, title: { selectedTitleFeeIdVar, customTitleFeeVar } } } = useContext(RatesContext);
  const { fees: { closingFeesVar, prepaymentFeesVar }, offers, leadDataVar, data } = useContext(PersistContext);
  const lead = useReactiveVar(leadDataVar);
  const { property, ltv, loanProgram } = lead;
  const closingFees = useReactiveVar(closingFeesVar);
  const prepaymentFees = useReactiveVar(prepaymentFeesVar);
  const { closingFees: $closingFees } = settings;
  const { total: loanAmount, fee: fundingFee } = useMemo(() => loanAmountDetails(lead, settings), [lead, settings]);
  const selectedTitleFeeId = useReactiveVar(selectedTitleFeeIdVar);
  const [yearlyInsurance, setYearlyInsurance] = useState(lead.property?.annualInsurance);
  const selectedPmi = useReactiveVar(selectedPmiVar);
  const titleFees = useMemo(() => {
    return titleFeeResult?.result?.map((title) => title);
  }, [titleFeeResult?.result]);

  const selectedTitleFee = useMemo(() => {
    const customTitleFee = customTitleFeeVar();
    if (selectedTitleFeeId == "custom") {
      return customTitleFee;
    }

    let selected = titleFees?.find((e) => e.providerId == selectedTitleFeeId);

    if (!selected && (selected = titleFees?.find((e) => e.providerId == "custom"))) {
      customTitleFeeVar(selected);
      return selected;
    } else if (!selected) {
      return;
    }

    return selected;
  }, [titleFees, selectedTitleFeeId]);

  useEffect(() => {
    if (!selectedTitleFeeId) {
      selectedTitleFeeIdVar(titleFees.find(fee => fee.default)?.providerId);
    } else {
      const incoming = titleFees?.find(r => r.providerId == selectedTitleFeeId);
      if (!incoming || !incoming.fees || !Object.keys(incoming.fees)) {
        selectedTitleFeeIdVar(titleFees.find(fee => fee.default)?.providerId);
      }
    }
  }, [titleFees, selectedTitleFeeId]);

  useEffect(() => {
    if (!selectedPmi || !selectedPmi.rate1) {
      if (pmiResult.error && !Object.keys(pmiResult?.result).length) {
        selectedPmiVar(null);
      } else if (pmiResult?.result?.monthly) {
        selectedPmiVar({ ...pmiResult.result.monthly.find(r => r.status == "Success"), option: "monthly" });
      } else {
        const keys = Object.keys(pmiResult?.result);
        selectedPmiVar(keys.length ? { ...pmiResult.result[ keys[ 0 ] ].find(r => r.status == "Success"), option: keys[ 0 ] } : null);
      }
    }
  }, [pmiResult?.result, pmiResult?.result.length, selectedPmi]);

  const principal = useMemo(() => props.piti - props.monthlyPremium, [props.piti]);
  const absPoints = useMemo(() => format(Math.abs(Number(points)), 3), [points]);
  const interestPrepayment = useMemo(() => (loanAmount / 100 * props.rate) / daysOfaYear(new Date().getFullYear()), [loanAmount, props.rate, settings]);
  const leadState = useMemo(() => {
    return lead?.property?.propertyAddress?.state;
  }, [lead]);
  const requiresPestInspection = useMemo(() => {
    const isVa = lead.loanProgram === "va";
    const { pestInspectionFeeStates } = settings.closingFees;
    return isVa && leadState && pestInspectionFeeStates?.includes(leadState);
  }, [lead, settings]);
  const adminFee = useMemo(() => {
    if (lead.loanProgram == "va") {
      return { value: 0, editable: false };
    }
    let adminFee = {
      value: settings.closingFees.adminFee.default,
      editable: settings.closingFees.adminFee.editable
    };
    if (lead.leadSource?.leadPartner?.title == "Nerd Wallet" && isNumeric(settings.closingFees.adminFee.nerdWallet)) {
      adminFee = { value: settings.closingFees.adminFee.nerdWallet, editable: false };
    } else if (lead.leadSource?.leadPartner?.title == "Credit Karma" && isNumeric(settings.closingFees.adminFee.creditKarma)) {
      adminFee = { value: settings.closingFees.adminFee.creditKarma, editable: false };
    } else if(lead.leadSource?.leadPartner?.title == "Creative Planning" && isNumeric(settings.closingFees.adminFee.creativePlanning)) {
      adminFee = { value: settings.closingFees.adminFee.creativePlanning, editable: false };
    }
    return adminFee;
  }, [lead.leadSource, settings.closingFees.adminFee]);

  const mortechPoints = useMemo(() => {
    return Math.round((Number(loanAmount) * Math.abs(Number(points)))) / 100;
  }, [loanAmount, points]);

  const calculateClosingCostWithoutLenderCredits = useLazySummary({ fields: [...CLOSING_COSTS_SUM_FIELDS, ...PREPAID_COSTS_SUM_FIELDS] });
  const calculateClosingCost = useLazySummary({ fields: CLOSING_COSTS_SUM_FIELDS, data: { $object: lead } });
  const calculateTotalClosingCost = useLazySummary({ fields: TOTAL_CLOSING_COSTS_SUM_FIELDS });
  const calculateTitleFee = useLazySummary({ fields: TITLE_FEE_SUM_FIELDS });
  const calculateCreditReport = useLazySummary({ fields: CREDIT_REPORT_SUM_FIELDS });
  const calculatePrepaidCost = useLazySummary({ fields: PREPAID_COSTS_SUM_FIELDS });
  const calculateMonthlyPayment = useLazySummary({ fields: MONTHLY_PAYMENT_SUM_FIELDS });

  const { apply: applyDiscountPoints } = useCondition({
    source: { points: props.points },
    conditions: WHEN_DISCOUNT_POINT
  });
  const { apply: applyLenderCredits } = useCondition({
    source: { points: props.points },
    conditions: WHEN_LENDER_CREDITS
  });
  const { apply: applyMIP } = useCondition({
    conditions: WHEN_MIP,
    source: { $object: lead }
  });
  const { apply: applyFF } = useCondition({
    conditions: WHEN_FF,
    source: { $object: lead }
  });
  const { apply: applyPestInspection } = useCondition({
    conditions: WHEN_PESTINSPECTION_FEE,
    source: { $closingFees, $object: lead }
  });
  const { apply: applyDocReview } = useCondition({
    conditions: WHEN_IN_DOC_REVIEW_FEE_STATES,
    source: { $closingFees, $object: lead }
  });
  const { apply: applyAttorneyFee } = useCondition({
    conditions: WHEN_IN_ATTORNEY_FEE_STATES,
    source: { $closingFees, $object: lead }
  });
  const { apply: applySurveyFee } = useCondition({
    conditions: WHEN_IN_SURVEY_FEE_STATES,
    source: { $closingFees, $object: lead }
  });
  const { apply: applySubordination } = useCondition({
    conditions: WHEN_SECONDARY_FINANCING_NOT_NONE,
    source: { $object: lead }
  });
  const { apply: applyYearlyInsurance } = useCondition({
    conditions: WHEN_IS_PURCHASE,
    source: { $object: lead }
  });
  const { apply: applyAppraisalFee } = useCondition({
    conditions: WHEN_STREAMLINE_WO_APPRAISAL,
    source: { $object: lead }
  });
  const { apply: applyCreditReportFee } = useCondition({
    conditions: WHEN_STREAMLINE_WO_CREDIT,
    source: { $object: lead }
  });

  async function updateTaxInsurance(name, value, form) {
    if (name == "monthlyInsurance") {
      setYearlyInsurance((value || 0) * 12);
      form.change("yearlyInsurance", (value || 0) * 12);
    }
    if (!decouplePricingPage) {
      let { data: { updateLead: { lead: resp } }, errors } = await update({
        variables: {
          id: $object.id,
          input: {
            ...transformFields(property, property, "Property", schemas),
            [ dbFiledMap[ name ] ]: (value || 0) * 12
          }
        }
      });
      !errors?.length && leadDataVar({ ...lead, ...resp });
    } else {
      leadDataVar({ ...lead, property: { ...lead.property, [ dbFiledMap[ name ] ]: (value || 0) * 12 } });
    }
  }

  async function updateInsurance(value, form) {
    setYearlyInsurance(value);
    form.change("monthlyInsurance", (value || 0) / 12);
    if (!decouplePricingPage) {
      let { data: { updateLead: { lead: resp } }, errors } = await update({
        variables: {
          id: $object.id,
          input: {
            ...transformFields(property, property, "Property", schemas),
            annualInsurance: value
          }
        }
      });
      !errors?.length && leadDataVar({ ...lead, ...resp });
    } else {
      leadDataVar({ ...lead, property: { ...lead.property, annualInsurance: value } });
    }
  }

  function calculateSumWithoutLenderCredits(values) {
    return calculateClosingCostWithoutLenderCredits({
      ...values,
      pmi: !isMonthlyPmi ? selectedPmi?.premium1 || 0 : 0,
      points,
      $closingFees,
      sellerConcession: -values.sellerConcession,
      earnestMoneyPaid: -values.earnestMoneyPaid,
      propertyInsurancePrepaid: (values.insurancePrepaymentMonths * values.monthlyInsurance),
      propertyTaxPrepaid: (values.taxPrepaymentMonths * values.monthlyTax),
      prepaidInterest: (values.interestPrepaymentDays * values.interestPrepayment),
      closingFees,
      prepaymentFees
    });
  }

  // function handleMoreFeeFieldsChange(values, form: FormApi) {
  //   const sumWithoutLenderCredits = calculateSumWithoutLenderCredits(values);
  //   if (sumWithoutLenderCredits < 0) {
  //     form.change("lenderCredits", 0);
  //   } else if (sumWithoutLenderCredits - Number(mortechPoints) < 0) {
  //     form.change("lenderCredits", sumWithoutLenderCredits);
  //   } else {
  //     form.change("lenderCredits", Number(mortechPoints));
  //   }
  // }

  async function handleSubmit(data, form: FormApi<Lead>) {
    if (offers.count >= 3) {
      info("You have reached the maximum limit for saving offers.");
      return;
    }

    const { total } = loanAmountDetails(lead, settings);
    const offer: any = {
      objectName: lead.mortech.productName,
      loanCriteria: {
        isPmiEligible: pmiResult.isPmiEligible,
        requiresPestInspection,
        cashOut: lead.cashOut,
        cltv: lead.cltv,
        downPayment: lead.downPayment,
        dti: lead.dti,
        loanAmount: lead.loanAmount,
        totalLoanAmount: total,
        fico: lead.members.filter((members) => ["borrower", "co_borrower"].includes(members.type)).map(({ creditScore }) => creditScore),
        financeFf: lead.financeFf,
        financeMip: lead.financeMip,
        firstTimeHomeBuyer: lead.firstTimeHomeBuyer,
        firstUseOfVaProgram: lead.firstUseOfVaProgram,
        loanPurpose: lead.loanPurpose,
        ltv: lead.ltv,
        mortech: {
          investorIds: lead?.mortech?.investorIds,
          lockInDays: lead?.mortech?.lockInDays,
          product: lead?.mortech?.product,
          source: lead?.mortech?.source,
          view: lead?.mortech?.view,
          productName: lead.mortech.productName
        },
        property: {
          occupancy: lead?.property?.occupancy,
          propertyAddress: {
            propertyCounty: lead?.property?.propertyAddress?.county,
            state: leadState,
            zipCode: lead?.property?.propertyAddress?.zipCode
          },
          type: lead.property.type,
          value: lead.property.value,
          currentMortgageBalance: lead.property.currentMortgageBalance
        },
        secondaryFinancing: lead.secondaryFinancing,
        veteranStatus: lead.members.find(b => b.type == "borrower")?.veteranStatus,
        waiveEscrow: lead.waiveEscrow
      },
      selectedRate: {
        lastUpdate: props.lastUpdate,
        pricingStatus: props.pricingStatus,
        productTerm: props.productTerm,
        vendorName: props.investor,//todo check
        productDesc: props.productDesc,
        productName: lead.mortech.productName,
        quoteDetails: {
          loanAmount: props.loanAmount,
          rate: props.rate,
          apr: props.apr,
          price: props.price,
          piti: props.piti,
          srp: props.srp,
          monthlyPremium: props.monthlyPremium
        }
      },
      closingCost: {
        total: calculateClosingCost({
            ...data,
            points,
            $closingFees,
            pmi: !isMonthlyPmi ? selectedPmi?.premium1 || 0 : 0,
            sellerConcession: -data.sellerConcession,
            earnestMoneyPaid: -data.earnestMoneyPaid,
            closingFees
          }
        ),
        title: {
          total: calculateTitleFee(data),
          insurance: data.titleInsurance,
          ownersTitle: data.ownersTitle,
          recordingCharges: data.recordingCharges,
          transferTax: data.transferTax,
          settlementFee: data.settlementFee,
          lendersTitle: data.lendersTitle
        },
        creditAndOther: {
          total: calculateCreditReport(data),
          documentDelivery: data.electronicDocumentDelivery,//todo check
          loanSafe: data.loanSafeFee,
          mers: data.mersFee,
          verification: data.verificationFee,
          transcript: data.transcriptFee
        },
        floodCertification: data.floodCertificationFee,
        adminFee: data.adminFee
        //todo add conditional pmi
      },
      prepaid: {
        total: calculatePrepaidCost({
          ...data,
          propertyInsurancePrepaid: (data.insurancePrepaymentMonths * data.monthlyInsurance),
          propertyTaxPrepaid: (data.taxPrepaymentMonths * data.monthlyTax),
          prepaidInterest: (data.interestPrepaymentDays * data.interestPrepayment),
          prepaymentFees
        }),
        dailyInterest: data.interestPrepayment,
        daysToPayInterest: data.interestPrepaymentDays,
        interest: data.interestPrepayment * data.interestPrepaymentDays,

        monthsToPayInsurance: data.insurancePrepaymentMonths,
        monthlyInsurance: data.monthlyInsurance,
        insurance: data.insurancePrepaymentMonths * data.monthlyInsurance,

        monthsToPayTax: data.taxPrepaymentMonths,
        monthlyTax: data.monthlyTax,
        tax: data.taxPrepaymentMonths * data.monthlyTax
      },
      monthlyPayment: {
        total: calculateMonthlyPayment({
          ...data,
          pmi: isMonthlyPmi ? selectedPmi?.premium1 || 0 : 0
        }),
        pi: data.principal, //todo check
        insurance: data.monthlyInsurance,
        tax: data.monthlyTax,
        mi: props.monthlyPremium//todo check
      },
      totalClosingCost: calculateTotalClosingCost({
        ...data,
        points,
        $closingFees,
        pmi: !isMonthlyPmi ? selectedPmi?.premium1 || 0 : 0,
        lenderCredits: -data.lenderCredits,
        sellerConcession: -data.sellerConcession,
        earnestMoneyPaid: -data.earnestMoneyPaid,

        propertyInsurancePrepaid: (data.insurancePrepaymentMonths * data.monthlyInsurance),
        propertyTaxPrepaid: (data.taxPrepaymentMonths * data.monthlyTax),
        prepaidInterest: (data.interestPrepaymentDays * data.interestPrepayment),
        prepaymentFees,
        closingFees
      }),
      lead: {
        link: $object.id
      }
    };
    if (applyLenderCredits) {
      offer.lenderCredits = data.lenderCredits;
    }
    if (applyDiscountPoints) {
      offer.closingCost[ "discountPoints" ] = data.discountPoints;
    }
    if (applyMIP) {
      offer.closingCost[ "mip" ] = data.fundingFee;
    }
    if (applyFF) {
      offer.closingCost[ "fundingFee" ] = data.fundingFee;
    }
    if (applyPestInspection) {
      offer.closingCost[ "pestInspection" ] = data.pestInspectionFee;
    }
    if (applyDocReview) {
      offer.closingCost[ "docReview" ] = data.docReviewFee;
    }
    if (applyAttorneyFee) {
      offer.closingCost[ "attorney" ] = data.attorneyFee;
    }
    if (applySurveyFee) {
      offer.closingCost[ "survey" ] = data.surveyFee;
    }
    if (applySubordination) {
      offer.closingCost[ "subordination" ] = data.subordinationFee;
    }
    if (applyYearlyInsurance) {
      offer.prepaid[ "yearlyInsurance" ] = data.yearlyInsurance;
    }
    if (closingFees.includes("earnestMoneyPaid")) {
      offer.closingCost[ "earnestMoneyPaid" ] = data.earnestMoneyPaid;
    }
    if (closingFees.includes("sellerConcession")) {
      offer.closingCost[ "sellerConcession" ] = data.sellerConcession;
    }
    if (closingFees.includes("condoCertificateFee")) {
      offer.closingCost[ "condoCertificate" ] = data.condoCertificateFee;
    }
    if (prepaymentFees.includes("lockInFee")) {
      offer.prepaid[ "lockInFee" ] = data.lockInFee;
    }
    if (applyAppraisalFee) {
      offer.closingCost[ "appraisalFee" ] = data.appraisalFee;
    }
    if (applyCreditReportFee) {
      offer.closingCost.creditAndOther[ "creditReport" ] = data.creditReportFee;
    }
    if (selectedPmi) {
      if (!isMonthlyPmi) {
        offer.closingCost[ "pmi" ] = selectedPmi.premium1;
      } else {
        offer.monthlyPayment[ "pmi" ] = selectedPmi.premium1;
      }
    }

    let result = await create({
      variables: {
        input: {
          fields: offer
        }
      }
    });
    if (result?.data?.createOffer) {
      success("Offer successfully saved!");
    } else {
      error("Failed to save offer, please try again.");
    }
  }
  const isMonthlyPmi = useMemo(() => selectedPmi?.option == "monthly", [selectedPmi]);
  const monthlyPmi = useMemo(() => selectedPmi?.option == "monthly" ? selectedPmi : null, [selectedPmi]);
  const initialValues = useMemo(() => {
    const initialValues = {
      ...data,
      titleInsurance: 0,
      ownersTitle: 0,
      lendersTitle: 0,
      recordingCharges: 0,
      transferTax: 0,
      settlementFee: 0,
      ...selectedTitleFee?.fees,
      adminFee: adminFee.value,
      appraisalFee: appraisalFeeValue,
      lenderCredits: Number(mortechPoints),
      discountPoints: Number(mortechPoints),
      fundingFee: fundingFee,
      interestPrepayment,
      docReviewFee: settings.closingFees.docReviewFee,
      subordinationFee: settings.closingFees.subordinationFee,
      attorneyFee: settings.closingFees.attorneyFee,
      surveyFee: settings.closingFees.surveyFee,
      pestInspectionFee: settings.closingFees.pestInspectionFee,
      lockInFee: settings.closingFees.lockInFee,
      floodCertificationFee: settings.closingFees.floodCertificationFee,
      condoCertificateFee: condoCertificateFee,
      creditReportFee: settings.closingFees.creditReportFee * (lead.members.filter(m => ["borrower", "co_borrower"].includes(m.type)).length),
      loanSafeFee: settings.closingFees.loanSafeFee,
      mersFee: settings.closingFees.mersFee,
      verificationFee: settings.closingFees.verificationFee,
      electronicDocumentDelivery: settings.closingFees.electronicDocumentDelivery,
      transcriptFee: settings.closingFees.transcriptFee,
      principal: principal,
      monthlyPremium: props.monthlyPremium,
      yearlyInsurance: !decouplePricingPage ? lead.property?.annualInsurance : yearlyInsurance,
      monthlyTax: lead.property?.annualTaxes / 12,
      monthlyInsurance: !decouplePricingPage ? lead.property?.annualInsurance / 12 : yearlyInsurance / 12
    };

    // const sumWithoutLenderCredits = calculateSumWithoutLenderCredits(initialValues);
    //
    // if (sumWithoutLenderCredits < 0) {
    //   initialValues.lenderCredits = 0;
    // } else if (sumWithoutLenderCredits - Number(mortechPoints) < 0) {
    //   initialValues.lenderCredits = sumWithoutLenderCredits;
    // }

    return initialValues;
  }, [data, appraisalFeeValue, adminFee, condoCertificateFee, selectedTitleFee, interestPrepayment, principal, fundingFee, lead.property?.annualInsurance, calculateSumWithoutLenderCredits]);

  return {
    ...props,
    active,
    sticky,
    lead,
    settings,
    ltv,
    isMonthlyPmi,
    monthlyPmi,
    initialValues,
    selectedTitleFee,
    selectedPmi,
    titleFees,
    principal,
    points,
    loanAmount,
    adjustments,
    loanProgram,
    productDesc,
    updateTaxInsurance,
    updateInsurance,
    interestPrepayment,
    adminFee,
    fundingFee,
    handleSubmit,
    // handleMoreFeeFieldsChange,
    calculateClosingCostWithoutLenderCredits,
    mortechPoints,
    absPoints
  };
}

export const UPDATE_TAX_INSURANCE = gql`
  mutation UpdateTaxInsurance($id:ID!,$input:UpdatePropertyFieldsInput!) {
    updateLead(input: {id:$id,fields:{property: $input}}) {
      lead {
        id
        property{
          type
          occupancy
          value
          use
          propertyAddress {
            city
            country
            county
            formatted
            state
            street
            street2
            zipCode
            lat
            lng
          }
          currentMortgageBalance
          firstLienBalance
          secondLienBalance
          annualInsurance
          annualTaxes
          heloc
          monthlyHoa
          dues
        }
      }
    }
  }
`;
export const CREATE_OFFER = gql`
  mutation CreateOffer($input:CreateOfferInput!) {
    createOffer(input: $input){
      offer {
        ...Offer
      }
    }
  }
  ${OFFER_FRAGMENT}
`;
