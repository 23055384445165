import React              from "react";
import { HTMLAttributes } from "react";
import { Tooltip }        from "../..";
import { Alignment }      from "../..";

export interface EllipsisProps extends HTMLAttributes<any> {
  from: number
  to?: number
  extension?: boolean
  withoutTooltip?: boolean
}

export function Ellipsis(props: EllipsisProps) {
  const { children, from, to, extension, withoutTooltip, ...p } = props;
  let child = children;
  let ellipsed = false;
  if (typeof children == "string") {
    child = children.substr(0, from);
    if (to) {
      if (children.length > from) {
        child += "...";
        ellipsed = true;
      }
      if ((from + 3) < to) {
        child += children.substr(to, children.length);
        ellipsed = true;
      }
    } else if (extension) {
      const extension = children.search(/\.(?=[^.]*$)/g);

      if (children.length > from && ((from + 3) < extension)) {
        child += "... ";
        child += children.substr(children.search(/\.(?=[^.]*$)/g), children.length);
        ellipsed = true;
      } else {
        child = children;
      }
    } else if (children.length > from) {
      child += "...";
      ellipsed = true;
    }
  }

  return (ellipsed && typeof children == "string" && !withoutTooltip) ?
    <Tooltip title={children.toString()} alignment={Alignment.Bottom}>
      <p {...p} style={{ cursor: "pointer", ...p?.style  }}>
        {child}
      </p>
    </Tooltip>
    :
    <p {...p}>
      {child}
    </p>;
}
