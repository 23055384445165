import React                   from "react";
import { FC }                  from "react";
import { gql }                 from "@apollo/client";
import { useMutation }         from "@apollo/client";
import { Form }                from "@relcu/form";
import { Modal }               from "@relcu/ui";
import { defaultMutators }     from "@relcu/ui";
import { ModalFooter }         from "@relcu/ui";
import { ModalProps }          from "@relcu/ui";
import { ButtonVariants }      from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { ModalBody }           from "@relcu/ui";
import { ChoiceField }         from "@relcu/ui";
import { DateField }           from "@relcu/ui";
import { TextField }           from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { MultiTextField }      from "@relcu/ui";
import { getPrimaryBorrower }  from "../../../utils/helpers";
import { CreateTaskVariables } from "./__types__/CreateTask";
import { CreateTask }          from "./__types__/CreateTask";

interface QuickCreateTaskModalProps extends Partial<ModalProps> {
  data: any;
}

export const QuickCreateTaskModal: FC<QuickCreateTaskModalProps> = React.memo(function QuickCreateTaskModal(props) {
  const { onClose, data } = props;
  const [create] = useMutation<CreateTask, CreateTaskVariables>(CREATE_TASK);
  const primaryBorrower = data.__typename == "Lead" ? getPrimaryBorrower(data) : data;
  const onSubmit = async (values) => {
    const task = {
      ...values,
      assignedTo: { link: data.assignedTo.id },
      subject: { link: data.id }
    };
    await create({
      variables: {
        input: {
          fields: task
        }
      }
    });
    onClose();
  };

  return <Modal
    onClose={onClose}
    variant={"middle"}
    open={true}
    disableBackdropClick={true}
    closeIcon={true}
    title={`Create task for ${primaryBorrower.contact.objectName}`}
    className={"send-mail-modal"}
  >
    <Form
      mutators={defaultMutators}
      onSubmit={onSubmit}
      initialValues={{
        remind: "10"
      }}>
      {({ handleSubmit, submitting }) =>
        <form onSubmit={handleSubmit} noValidate>
          <ModalBody container
                     flex={1}
                     gap={"XS"}
                     wrap={"wrap"}
                     style={{ paddingTop: "var(--layout-box-gap-x-s)", paddingBottom: "var(--layout-box-gap-x-s)" }}>
            <Box container flexBasis={"100%"}>
              <TextField
                label={"Title"}
                required
                name={"title"}
                placeholder={"Enter title"}
                halfSize={true}
              />
            </Box>
            <DateField
              label={"Dua date"}
              required
              name={"dueDate"}
              placeholder={"Select due date"}
              halfSize={true}
              isTime
            />
            <ChoiceField
              label={"Reminder"}
              required
              name={"remind"}
              placeholder={"Select due date"}
              halfSize={true}
              options={[
                {
                  value: "0",
                  label: "On time"
                },
                {
                  value: "10",
                  label: "10 minutes before"
                },
                {
                  value: "15",
                  label: "15 minutes before"
                },
                {
                  value: "30",
                  label: "30 minutes before"
                },
                {
                  value: "60",
                  label: "1 hour before"
                },
                {
                  value: "1440",
                  label: "1 day before"
                }
              ]}
            />
            <MultiTextField placeholder={"Enter task description"} label={"Description"} name={"description"}
                            flexBasis={"100%"}/>
          </ModalBody>
          <ModalFooter>
            <Button variant={ButtonVariants.Ghost} onClick={onClose}>CANCEL</Button>
            <Button type="submit" disabled={submitting}>Create</Button>
          </ModalFooter>
        </form>
      }
    </Form>
  </Modal>;
});

export const CREATE_TASK = gql`
  mutation CreateTask($input: CreateTaskInput!){
    createTask(input: $input){
      task{
        objectId
      }
    }
  }
`;
