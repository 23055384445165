import React                   from "react";
import { Form, FormSpy }       from "@relcu/form";
import { ButtonVariants }      from "@relcu/ui";
import { FieldSpy }            from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { ModalFooter }         from "@relcu/ui";
import { BackDropLoader }      from "@relcu/ui";
import { ModalBody }           from "@relcu/ui";
import { Modal }               from "@relcu/ui";
import { defaultMutators }     from "@relcu/ui";
import { ModalProps }          from "@relcu/ui";
import { DomainDetailsForm }   from "./Form/DomainDetailsForm/DomainDetailsForm";
import { DomainNameForm }      from "./Form/DomainNameForm/DomainNameForm";
import { useMailDomainDialog } from "./useMailDomainDialog";
import "./mail-domain-dialog.css";

export interface MailDomainTooltipProps {
  text: string;
}

export interface MailDomainDialogProps extends ModalProps {
  action: "create" | "save";
  record?: any;
}

export const MailDomainDialog = React.memo<MailDomainDialogProps>(function MailDomainDialog(props) {
  const { step, setStep, mailDomain, loading, handleSubmit, onEnableRelcuEmail, onDnsValidate, validateName, domainDuplicate, setDomainDuplicate } = useMailDomainDialog(props);
  const action = props.action;
  return (
    <Modal
      variant={"middle"}
      open={props.open}
      onClose={props.onClose}
      title={`${action === "save" ? "Edit" : "Add a "} domain`}
      closeIcon={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      style={{ position: "relative" }}>
      <Form
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize={true}//todo remove
        initialValues={{
          objectId: mailDomain.objectId,
          domain: mailDomain.domain,
          disabled: mailDomain.disabled,
          useForOut: mailDomain.useForOut,
          aliases: mailDomain.aliases || [],
          provider: {
            type: "none",
            sync: "-15m",
            ...mailDomain?.provider,
            returnUrl: `${window.location.origin}/api/v1/login/oauth`
          }
        }}
        subscription={{ submitting: true }}
        mutators={defaultMutators}>
        {
          ({ handleSubmit, submitting }) => {
            return <form
              onSubmit={(e) => e.preventDefault()}
              noValidate
              style={{ display: "contents" }}>
              <ModalBody container flexGrow={1}>
                {action === "create" && step === 0 &&
                  <DomainNameForm domainDuplicate={domainDuplicate} setDomainDuplicate={setDomainDuplicate}/>
                }
                {(action == "save" || step == 1) &&
                  <DomainDetailsForm
                    onEnableRelcuEmail={onEnableRelcuEmail}
                    onDnsValidate={onDnsValidate}
                    mailDomain={mailDomain}
                    action={action}
                  />}
              </ModalBody>
              <ModalFooter>
                {
                  action === "create" && step == 1 &&
                  <Button disabled={submitting} variant={ButtonVariants.Ghost}
                          onClick={(() => setStep(0))}>Back</Button>
                }
                <Button disabled={submitting} variant={ButtonVariants.Outline}
                        onClick={props.onClose}>CANCEL</Button>
                <FieldSpy name={"domain"} subscription={{ value: true }} onChange={() => {
                }}/>
                {
                  step == 0 ?
                    <FormSpy subscription={{ values: true }}>
                      {
                        ({ values }) => {
                          return <Button disabled={!values.domain}
                                         onClick={() => validateName(values.domain)}>NEXT</Button>;
                        }
                      }
                    </FormSpy> :
                    <Button
                      disabled={submitting}
                      onClick={handleSubmit}>SAVE</Button>
                }
              </ModalFooter>
            </form>;
          }
        }
      </Form>
      {loading && <BackDropLoader/>}
    </Modal>
  );
});

export const enum MailDomainDialogClasses {
  MailDomainDialogBody = "mail-domain-dialog__body",
  DnsRow = "mail-domain-dialog__dns-row"
}
