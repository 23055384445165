import { SyntheticEvent }    from "react";
import React                 from "react";
import { CommonClasses }     from "../../theme/classes";
import { classNames }        from "../../utils/classNames";
import { BoxComponentProps } from "../Box";
import { Box }               from "../Box";
import { FontIcon }          from "../Icon";
import { SliderClasses }     from "./SliderClasses";

export interface SliderContainerProps extends BoxComponentProps {
  onMouseOver?: (e: SyntheticEvent) => void;
  onMouseOut?: (e: SyntheticEvent) => void;
  showLeftBtn: boolean,
  showRightBtn: boolean,
  handleMoveTo: (index: number) => void;
  activeIndex: number;
}

export const SliderContainer = React.memo(React.forwardRef(function SliderContainer(props: SliderContainerProps, ref: React.Ref<HTMLDivElement>) {
  const { onMouseOver, onMouseOut, className, showLeftBtn, showRightBtn, handleMoveTo, activeIndex, children, ...p } = props;
  return <Box
    container
    gap={"XXS"}
    flexShrink={0}
    className={className}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    {...p}>
    {
      showLeftBtn &&
      <FontIcon
        className={classNames(SliderClasses.SliderBtn, SliderClasses.SliderBtnL, CommonClasses.ClickableIcon)}
        type={"keyboard_arrow_left"} onClick={() => handleMoveTo(activeIndex - 1)}/>
    }
    {
      showRightBtn &&
      <FontIcon
        className={classNames(SliderClasses.SliderBtn, SliderClasses.SliderBtnR, CommonClasses.ClickableIcon)}
        type={"keyboard_arrow_right"} onClick={() => handleMoveTo(activeIndex + 1)}/>
    }
    <Box container gap={"XXS"} flexShrink={0} className={SliderClasses.SliderContainer} ref={ref}>
      <Box className={classNames(SliderClasses.SliderIndicator, SliderClasses.SliderIndicatorL)}/>
      {children}
      <Box className={classNames(SliderClasses.SliderIndicator, SliderClasses.SliderIndicatorR)}/>
    </Box>
  </Box>;
}));