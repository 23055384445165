import React                          from "react";
import { classNames }                 from "../..";
import { Box }                        from "../Box";
import { applyBoxItemStyles }         from "../Box";
import { BoxContainerComponentProps } from "../Box";
import { FontIcon }                   from "../Icon";
import { PaginationClasses }          from "./PaginationClasses";

export interface PaginationItemProps extends BoxContainerComponentProps {
  selected: boolean;
  children: any;
}

export function PaginationItem(props: PaginationItemProps) {
  let properties = applyBoxItemStyles<PaginationItemProps>(props);
  const { className, children, selected, ...p } = properties;
  const classes = classNames(PaginationClasses.PaginationItem, {
    [ PaginationClasses.Selected ]: selected,
    [ PaginationClasses.Action ]: children.type == FontIcon,
    [ PaginationClasses.Ellipsis ]: children == "...",
    [ PaginationClasses.Long ]: (typeof children == "number" && children > 99)
  }, className);

  return <Box container className={classes} alignItems={"center"} justify={"center"} {...p}>{children}</Box>;
}
