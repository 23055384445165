import React                 from "react";
import { FC }                from "react";
import { useMemo }           from "react";
import { useCallback }       from "react";
import { classNames }        from "../../../utils/classNames";
import { BoxComponentProps } from "../../Box";
import { FontIcon }          from "../../Icon";
import { TypographyColor }   from "../../Typography";
import { TypographySize }    from "../../Typography";
import { Typography }        from "../../Typography";

import { StepperClasses } from "./StepperClasses";

interface StepProp extends BoxComponentProps {
  label: string;
  step?: string;
  active?: boolean;
  error?: boolean;
  past?: boolean;
  edit?: boolean;
  required?: boolean;
  desc?: string;
  onClick?();
}

export const Step: FC<StepProp> = React.memo(function Step(props) {
  const { label, step, active, past, edit, onClick, className, desc, error, required, ...p } = props;
  const pastStep = useMemo(() => !edit && past, [edit, past]);
  const classes = classNames(StepperClasses.Step, {
    [ StepperClasses.Edit ]: edit,
    [ StepperClasses.Active ]: active,
    [ StepperClasses.Past ]: pastStep
  }, className);

  const handleClick = useCallback(() => {
    if (edit || past) {
      return onClick();
    }
  }, [edit, past]);

  return <li className={classes} onClick={handleClick} {...p}>
    <Typography
      size={TypographySize.SubtitleSmall}
      className={
        classNames(
          StepperClasses.Label,
          {
            [ StepperClasses.ErrorLabel ]: error,
            [ StepperClasses.ActiveLabel ]: active,
            [ StepperClasses.PastLabel ]: pastStep
          })
      }>
      {`${label}${required ? "*" : ""}`}
    </Typography>
    {
      error &&
      <FontIcon type={"warning"}/>
    }
    {
      !edit && step &&
      <Typography color={TypographyColor.Secondary} size={TypographySize.TextSmall}
                  className={StepperClasses.Index}>{step}</Typography>
    }
    {
      desc &&
      <Typography color={TypographyColor.Secondary} size={TypographySize.TextTiny}>
        {desc}
      </Typography>
    }
  </li>;
});

Step.defaultProps = {
  edit: false,
  active: false,
  past: false
};
