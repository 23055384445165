import { useSubscription }         from "@apollo/client";
import { OperationVariables }      from "@apollo/client";
import { gql }                     from "@apollo/client";
import { SubscriptionHookOptions } from "@apollo/client/react/types/types";
import { IQueryBuilderOptions }    from "@relcu/gql-query-builder";
import * as builder                from "@relcu/gql-query-builder";
import { useMemo }                 from "react";

export function useJqlSubscription<TData = any, TVariables = OperationVariables>(
  subscription: IQueryBuilderOptions | IQueryBuilderOptions[],
  options: SubscriptionHookOptions<TData, TVariables> & { operationName: string }
) {
  const { documentNode, variables } = useMemo(() => buildSubscription(subscription, options), [subscription, options?.operationName]);

  return useSubscription<TData, TVariables>(documentNode, {
    ...options,
    variables: {
      ...variables,
      ...options?.variables
    }
  });
}

export function buildSubscription(subscription: IQueryBuilderOptions | IQueryBuilderOptions[], options) {
  const { query: subscriptionString, variables } = builder.subscription(subscription, null, {
    operationName: options?.operationName || ""
  });
  return {
    documentNode: gql(subscriptionString),
    variables
  };
}
