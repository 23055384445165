import React                 from "react";
import { FC }                from "react";
import { BoxComponentProps } from "../../Box";
import { BaseFieldProps }    from "../BaseField";
import { BaseArrayField }    from "../BaseField";

import PhoneNumbersEditField from "./PhoneNumbersEditField";
import PhoneNumbersReadField from "./PhoneNumbersReadField";

export type PhoneNumbersFieldProps = BaseFieldProps & BoxComponentProps & { schema };

export const PhoneNumbersField: FC<PhoneNumbersFieldProps> = React.memo(function PhoneNumbersField(props) {
  return (
    <BaseArrayField
      {...props}
      readView={<PhoneNumbersReadField/>}
      editView={<PhoneNumbersEditField/>}
    />
  );
});
