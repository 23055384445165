import { useField }            from "@relcu/form";
import { useContext }          from "react";
import React                   from "react";
import { CurrencyField }       from "@relcu/ui";
import { isPositiveInteger }   from "../../../../../../utils/helpers";
import { LoanCriteriaContext } from "../LoanCriteriaProvider";
import { useLoanLimit }        from "../useLoanLimit";

export const TotalLoanAmountField = React.memo(function TotalLoanAmountField() {
  const limit = useLoanLimit();
  const { product: { name } ,settings} = useContext(LoanCriteriaContext);
  const { input: { value: totalLoanAmount } } = useField("totalLoanAmount", { subscription: { value: true } });
  let warning: string;
  if (totalLoanAmount > limit) {
    warning = "Loan amount exceeds maximum loan limit.";
  } else if (totalLoanAmount > settings.averageLoanLimit && !name?.toLowerCase().includes("jum")) {
    warning = "Loan amount exceeds average loan limit.";
  }
  return <CurrencyField view={"write"} validate={value => isPositiveInteger(value)} warning={warning} flex={1} readOnly={true} name="totalLoanAmount"
                        halfSize label="Total loan amount" precision={2}/>;

});
