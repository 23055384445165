import { gql }                                 from "@apollo/client";
import { useQuery }                            from "@apollo/client";
import { useReactiveVar }                      from "@apollo/client";
import { MicrophoneAccessDialog }              from "@relcu/ui";
import { modal }                               from "@relcu/ui";
import { useCallback }                         from "react";
import { useMemo }                             from "react";
import { schemaUpdatedVar }                    from "../../reactiveVars";
import { layoutUpdatedVar }                    from "../../reactiveVars";
import { audioDevicesVar }                     from "../../reactiveVars";
import { microphoneAccessVar }                 from "../../reactiveVars";
import { versionVar }                          from "../../reactiveVars";
import { AlertBarUser }                        from "./__types__/AlertBarUser";

const version = import.meta.env.VITE_VERSION;

export function useAlertBar() {
  const { data: { viewer: { user } } } = useQuery<AlertBarUser>(USER, {
    fetchPolicy: "cache-only"
  });
  const currentVersion = version || window.__CONFIG__.versions.web;
  const latestVersion = useReactiveVar(versionVar);
  const currentLayoutVersion =  window.__CONFIG__.versions.layoutUpdatedAt;
  const latestLayoutVersion = useReactiveVar(layoutUpdatedVar);
  const currentSchemaVersion =  window.__CONFIG__.versions.schemaUpdatedAt;
  const latestSchemaVersion = useReactiveVar(schemaUpdatedVar);
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const audioDevices = useReactiveVar(audioDevicesVar);
  const openMicrophoneAccessModal = useCallback(() => {
    const {destroy} = modal(MicrophoneAccessDialog,{
      open:true,
      closeIcon:true,
      onClose(e?) {
        destroy()
      }
    });
  }, []);
  const soundWarning = useMemo(() => {
    if (!microphoneAccess) {
      return "MIC_ACCESS";
    }
    if (audioDevices.audiooutput?.length == 0) {
      return "SPK_MISSING";
    }
    if (audioDevices.audioinput?.length == 0) {
      return "MIC_MISSING";
    }
  }, [microphoneAccess, audioDevices]);
  return {
    openMicrophoneAccessModal,
    viewerId:user.objectId,
    soundWarning,
    latestVersion,
    hasNewLayoutVersion: currentLayoutVersion !== latestLayoutVersion,
    hasNewSchemaVersion: currentSchemaVersion !== latestSchemaVersion,
    hasNewVersion: currentVersion !== latestVersion

  };
}
const USER = gql`
  query AlertBarUser {
    viewer {
      user {
        id
        objectId
      }
    }
  }
`;
