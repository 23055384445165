import React          from "react";
import { FC }         from "react";
import { LeadHeader } from "../LeadHeader";
import { Five9Call }  from "./Five9Call";

export const LeadFive9Header: FC = React.memo(function LeadFive9Header(props) {
  return (
    <LeadHeader>
      <Five9Call/>
    </LeadHeader>
  );
});
