import { FieldArrayRenderProps } from "@relcu/form";
import { FC }                    from "react";
import React                     from "react";
import { BaseProps }             from "./BaseField";
import { BaseFieldProps }        from "./BaseFieldProps";
import { useBaseArrayField }     from "./useBaseArrayField";

export const BaseArrayField = React.memo<BaseProps>(function BaseField(props) {
  const { readView, editView, view = "write", ...p } = props;
  const field = useBaseArrayField<BaseFieldProps>(p);
  return (
    <BaseArrayFieldProvider field={field}>
      {view === "write" && editView}
      {view === "read" && readView}
    </BaseArrayFieldProvider>
  );
});
export const BaseArrayFieldProvider: FC<{ field: ArrayFieldContext }> = React.memo(function BaseArrayFieldProvider(props) {
  return (
    <BaseArrayFieldContext.Provider value={props.field}>
      {props.children}
    </BaseArrayFieldContext.Provider>
  );
});
export const BaseArrayFieldContext = React.createContext<ArrayFieldContext<any>>(null);
export interface ArrayFieldContext<T extends BaseFieldProps = BaseFieldProps> extends FieldArrayRenderProps<any, any> {
  input: T
}
