import { HTMLAttributes }                 from "react";
import React, { createContext, useState } from "react";
import { useClassNames }                  from "../utils";
import { useConstant }                    from "./useConstant";
import { TabClasses }                     from "./TabClasses";

export const TabsState = createContext(null);
export const Elements = createContext(null);

export enum TabsPosition {
  TOP = "top",
  BOTTOM = "bottom"
}

export interface TabContainerProps extends HTMLAttributes<HTMLDivElement> {
  outerState?: any;
  position?: TabsPosition;
  activeIndex?: number;
  gap?: number;
}

TabContainer.defaultProps = {
  position: TabsPosition.TOP
};
export function TabContainer(props: TabContainerProps) {
  const { className, children, outerState, position, gap = 8, style, ...p } = props;
  const { merge, withClassPrefix } = useClassNames(TabClasses.TabContainer, "rc");
  const classes = merge(className, withClassPrefix());
  const elements = useConstant(() => ({ tabs: 0, panels: 0 }));
  const innerState = useState(0);
  const state = outerState || innerState;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: position == TabsPosition.TOP ? "column-reverse" : "column",
        gap,
        ...style
      }}
      className={classes}
      {...p}>
      <Elements.Provider value={elements}>
        <TabsState.Provider value={state}>
          {children}
        </TabsState.Provider>
      </Elements.Provider>
    </div>
  );
}
