import React              from "react";
import { FC }             from "react";
import { HTMLAttributes } from "react";
import { useEffect }      from "react";
import { useState }       from "react";

interface ImagePreview extends HTMLAttributes<HTMLImageElement> {
  img: File
}

export const ImagePreview: FC<ImagePreview> = React.memo(function ImagePreview(props) {
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    if (props.img) {
      let reader = new FileReader();

      reader.onload = function (e) {
        setPreview(e.target.result);
      };

      reader.readAsDataURL(props.img);
    } else {
      setPreview(null);
    }

  }, [props.img]);

  return preview ? <img src={preview} alt="Profile picture" {...props}/> : null;
});