import { TypePolicy } from "@apollo/client";

export const TabProperties: TypePolicy = {
  fields: {
    widgets: {
      read(e) {
        return e ?? true;
      }
    }
  }
};
