import { Suspense }                 from "react";
import { useMemo }                  from "react";
import { useContext }               from "react";
import React                        from "react";
import { FC }                       from "react";
import { useReactiveVar }           from "@apollo/client";
import { Field }                    from "@relcu/form";
import { FilterBuilder }            from "@relcu/ui";
import { ButtonVariants }           from "@relcu/ui";
import { FieldSpy }                 from "@relcu/ui";
import { Button }                   from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { CircularLoader }           from "@relcu/ui";
import { AlertColors }              from "@relcu/ui";
import { Alert }                    from "@relcu/ui";
import { StepEmptySection }         from "@relcu/ui";
import { StepSection }              from "@relcu/ui";
import { RuleSectionInterface }     from "../Rule";
import { DistributionQueueContext } from "../../../../DistributionQueueDialog";
import { LeadToUserValueEditor }    from "./LeadToUserEditor/LeadToUserValueEditor";

export const LeadToUser: FC<RuleSectionInterface> = React.memo(function LeadToUser(props) {
  const { name, index } = props;
  const { leadToUser, notCompleteRulesVar, clearRulesError } = useContext(DistributionQueueContext);
  const notCompleteRules = useReactiveVar(notCompleteRulesVar);
  const errorName = useMemo(() => name.split(".")[ 1 ], [name]);
  const error = useMemo(() => notCompleteRules?.[ index ]?.[ errorName ], [notCompleteRules, index, errorName]);
  return <StepSection error={!!error} title={"Lead-to-user mapping"}
                      tooltip={"Map the filtered leads to users."}>
    <FieldSpy name={name} subscription={{ value: true }} onChange={() => {
      clearRulesError(index, errorName);
    }}/>
    <Field name={name}>
      {
        ({ input, meta }) => {
          return (!input.value ?
              <StepEmptySection
                content={`If you skip this step filtered leads will be distributed to all users.`}
                title={`Click on “Add filter” to map the filtered results.`}
                onClick={() => {
                  input.onChange({
                    "rules": [],
                    "combinator": "and",
                    "not": false
                  });
                }
                }>
              </StepEmptySection> :
              <Box container direction={"column"} gap={"XS"}>
                <Suspense fallback={<CircularLoader justify={"center"}/>}>
                  <FilterBuilder query={input.value} onQueryChange={input.onChange} fields={leadToUser}
                                 valueEditor={LeadToUserValueEditor}/>
                </Suspense>
                <Button
                  alignSelf={"end"}
                  variant={ButtonVariants.Ghost}
                  onClick={() => input.onChange(null)}>
                  Clear filter
                </Button>
              </Box>
          );
        }
      }
    </Field>
    {
      error &&
      <Alert stretch variant={AlertColors.Error}>
        {error}
      </Alert>
    }
  </StepSection>;
});
