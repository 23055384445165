import { useMemo }                from "react";
import React                      from "react";
import { FC }                     from "react";
import { Field }                  from "@relcu/form";
import { Alert, StepHeader }      from "@relcu/ui";
import { AlertColors }            from "@relcu/ui";
import { StepEmptySection }       from "@relcu/ui";
import { StepInterface }          from "@relcu/ui";
import { FilterBuilder, StepRow } from "@relcu/ui";
import { StepBody }               from "@relcu/ui";
import { StepContainer }          from "@relcu/ui";
import { StepSection }            from "@relcu/ui";
import { pluralize }              from "../../../../../../utils/pluralize";
import { getFieldRecursion }      from "../../../../../../utils/schemaUtils";
import { FilterValueEditor }      from "./FilterValueEditor";

interface FilterProps extends StepInterface {
  fields: any[];
  schemaName: string;
}
export const Filter: FC<FilterProps> = React.memo(function Filter(props) {
  const { title, name } = props;
  const fields = useMemo(() => {
    return props.fields.map(field => {
      if (!field.operators) {
        if (field.name == "tags") {
          field.operators = FilterDefaultOperator[ "Tag" ];
        } else if (field.name == "objectId") {
          field.operators = FilterDefaultOperator[ "ID" ];
        } else if (["leadStatus.actionCount","leadStatus.currentStatusActionCount","leadStatus.currentStageActionCount", ].includes(field.name)) {
          field.operators = FilterDefaultOperator[ "Number" ];
        } else if (field.name == "leadStatus.updatedAt") {
          field.operators = FilterDefaultOperator[ "Date" ];
        } else {
          const type = getFieldRecursion(props.schemaName, field.name)?.type;
          field.operators = FilterDefaultOperator[ type ] ?? [];
        }
      }
      return field;
    });
  }, [props.fields, props.schemaName]);
  return (
    <StepContainer>
      <StepHeader
        title={title}/>
      <StepBody flex={"0 0 auto"} gap={"XS"} style={{
        overflowY: "scroll",
        height: "90%"
      }}>
        <Field name={name}>
          {({ input, meta }) => {
            return (
              <>
                {!input.value ?
                  <StepEmptySection
                    content={`If you skip this step the table view will list all ${pluralize(props.schemaName)}.`}
                    title={`Click on “Add filter” to set a filter for ${pluralize(props.schemaName)} that will be shown in the table.`}
                    onClick={() => {
                      input.onChange({});
                    }
                    }>
                  </StepEmptySection> : <StepSection>
                    <StepRow>
                      <FilterContext.Provider value={{ schemaName: props.schemaName }}>
                        <FilterBuilder
                          query={input.value}
                          onQueryChange={
                            (data) => {
                              input.onChange(data);
                            }
                          }
                          valueEditor={FilterValueEditor}
                          fields={fields}
                        />
                      </FilterContext.Provider>
                    </StepRow>
                  </StepSection>
                }
                {
                  !!meta.submitError && !meta.dirtySinceLastSubmit &&
                  <Alert stretch variant={AlertColors.Error}>
                    {meta.submitError}
                  </Alert>
                }
              </>);
          }}
        </Field>
      </StepBody>
    </StepContainer>
  );
});

Filter.defaultProps = {
  name: "filter"
}

export const FilterContext = React.createContext<{ schemaName: string }>(null);

export const FilterDefaultOperator = {
  ID: [
    {
      "name": "=",
      "label": "Equal to"
    },
    {
      "name": "!=",
      "label": "Not equal to"
    },
    { name: "in", label: "In" },
    { name: "notIn", label: "Not in" }
  ],
  String: [
    {
      "name": "=",
      "label": "Equal to"
    },
    {
      "name": "!=",
      "label": "Not equal to"
    },
    {
      "name": "notNull",
      "label": "Has any value"
    },
    {
      "name": "is null",
      "label": "Has no value"
    },
    { name: "beginsWith", label: "Starts With" },//"(?i)^${text}"
    { name: "endsWith", label: "Ends With" },//(?i)${text}$
    { name: "contains", label: "Contains" },//"(?i)${text}"
    { name: "doesNotContain", label: "Does not contain" }
  ],
  Email: [
    {
      "name": "=",
      "label": "Equal to"
    },
    {
      "name": "!=",
      "label": "Not equal to"
    },
    {
      "name": "notNull",
      "label": "Has any value"
    },
    {
      "name": "is null",
      "label": "Has no value"
    },
    { name: "beginsWith", label: "Starts With" },//"(?i)^${text}"
    { name: "endsWith", label: "Ends With" },//(?i)${text}$
    { name: "contains", label: "Contains" },//"(?i)${text}"
    { name: "doesNotContain", label: "Does not contain" }
  ],
  Phone: [
    {
      "name": "=",
      "label": "Equal to"
    },
    {
      "name": "!=",
      "label": "Not equal to"
    },
    {
      "name": "notNull",
      "label": "Has any value"
    },
    {
      "name": "is null",
      "label": "Has no value"
    },
    { name: "beginsWith", label: "Starts With" },//"(?i)^${text}"
    { name: "endsWith", label: "Ends With" },//(?i)${text}$
    { name: "contains", label: "Contains" }//"(?i)${text}"
  ],
  Status: [
    { name: "in", label: "In" },
    { name: "notIn", label: "Not in" },
    { name: "notNull", label: "Has any value" },
    { name: "is null", label: "Has no value" }
  ],
  Pointer: [
    { name: "in", label: "In" },
    { name: "notIn", label: "Not in" },
    { name: "notNull", label: "Has any value" },
    { name: "is null", label: "Has no value" }
  ],
  Choice: [
    {
      name: "in", "label": "In"
    },
    {
      name: "notIn", "label": "Not in"
    },
    {
      name: "notNull", "label": "Has any value"
    },
    {
      name: "is null", "label": "Has no value"
    }
  ],
  Array:[
    {
      name: "in", "label": "In"
    },
    {
      name: "notIn", "label": "Not in"
    },
    {
      name: "notNull", "label": "Has any value"
    },
    {
      name: "is null", "label": "Has no value"
    }
  ],
  MultiChoice: [
    {
      name: "in", "label": "In"
    },
    {
      name: "notIn", "label": "Not in"
    },
    {
      name: "notNull", "label": "Has any value"
    },
    {
      name: "is null", "label": "Has no value"
    }
  ],
  Tag: [
    {
      name: "in", "label": "In"
    },
    {
      name: "notIn", "label": "Not in"
    },
    {
      name: "notNull", "label": "Has any value"
    },
    {
      name: "is null", "label": "Has no value"
    }
  ],
  Date: [
    { name: "notNull", label: "Has any value" },
    { name: "is null", label: "Has no value" },
    { name: "greaterThan", label: "After" },
    { name: "lessThan", label: "Before" },
    { name: "during", label: "In" },
    { name: "notDuring", label: "Not in" }
  ],
  Boolean: [
    { "name": "=", "label": "Is" },
    { "name": "!=", "label": "Is not" },
    { "name": "notNull", "label": "Has any value" },
    { "name": "is null", "label": "Has no value" }
  ],
  Number: [
    {
      "name": "=",
      "label": "Equal to"
    },
    {
      "name": "!=",
      "label": "Not equal to"
    },
    {
      "name": "notNull",
      "label": "Has any value"
    },
    {
      "name": "is null",
      "label": "Has no value"
    },
    {
      "name": ">",
      "label": "Greater than"
    },
    {
      "name": "<",
      "label": "Less then"
    },
    {
      "name": ">=",
      "label": "Greater than or equal to"
    },
    {
      "name": "<=",
      "label": "Less then or equal to"
    }
  ],
  Percent: [
    {
      "name": "=",
      "label": "Equal to"
    },
    {
      "name": "!=",
      "label": "Not equal to"
    },
    {
      "name": "notNull",
      "label": "Has any value"
    },
    {
      "name": "is null",
      "label": "Has no value"
    },
    {
      "name": ">",
      "label": "Greater than"
    },
    {
      "name": "<",
      "label": "Less then"
    },
    {
      "name": ">=",
      "label": "Greater than or equal to"
    },
    {
      "name": "<=",
      "label": "Less then or equal to"
    }
  ]
};
