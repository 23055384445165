import React                         from "react";
import { FC }                        from "react";
import { useMemo }                   from "react";
import { useContext }                from "react";
import { useParams }                 from "@relcu/react-router";
import { OnChange }                  from "@relcu/form";
import { useForm }                   from "@relcu/form";
import { Field }                     from "@relcu/form";
import { FieldArray }                from "@relcu/form";
import { FormSpy }                   from "@relcu/form";
import { HiddenField }               from "@relcu/ui";
import { useSource }                 from "@relcu/ui";
import { RadioButton }               from "@relcu/rc";
import { CheckboxGroup }             from "@relcu/rc";
import { Checkbox }                  from "@relcu/rc";
import { FormField }                 from "@relcu/rc";
import { Avatar, Stack, Typography } from "@relcu/rc";
import { transformNameToLabel }      from "../../../../../../utils/helpers";
import { DetailsContainer }          from "../../../../../Generation";
import { DetailsField }              from "../../../../../Generation";
import { useSchemaField }            from "../../../../useSchemaField";
import { FlyerContext }              from "../Content";

export const FlyerDetails: FC = React.memo(function TemplateDetails() {
  const { replaceableFieldsSources } = useContext(FlyerContext);
  const applyToFields = useMemo(() => replaceableFieldsSources.filter(r => r != "user"), [replaceableFieldsSources]);
  const { flyerId } = useParams();
  const { options } = useSchemaField("User", "role");
  const { $object } = useSource();
  const form = useForm();

  return <DetailsContainer>
    <HiddenField name={"content"}/>
    <DetailsField style={{ width: "50%" }}>
      <DetailsField.Title>General</DetailsField.Title>
      <FormField
        name="title"
        label="Flyer name"
        required
        validate={(value) => {
          if (value?.trim() === "") {
            return `Flyer name is required.`;
          }
        }}
        groupProperties={{ style: { alignSelf: "stretch" } }}
      />
    </DetailsField>
    <DetailsField>
      <DetailsField.Title>Available for</DetailsField.Title>
      <Field
        type={"radio"}
        value={"email"}
        name="availableForType">
        {
          ({ input, meta }) => {
            return (
              <RadioButton {...input}>
                Email
              </RadioButton>
            );
          }
        }
      </Field>
      <Field
        type={"radio"}
        value={"sms"}
        name="availableForType">
        {
          ({ input, meta }) => {
            return (
              <RadioButton{...input}>
                SMS
              </RadioButton>
            );
          }
        }
      </Field>
      {/*todo after sms templating change make it back to checkbox*/}
      {/*<FieldArray*/}
      {/*  name="availableForType"*/}
      {/*  validate={(value, allValues, meta) => {*/}
      {/*    if (meta.modified && value?.length == 0) {*/}
      {/*      return "Please select at least one option.";*/}
      {/*    }*/}
      {/*  }}>*/}
      {/*  {*/}
      {/*    ({ fields, meta }) => {*/}
      {/*      return (*/}
      {/*        <>*/}
      {/*          <CheckboxGroup value={fields.value} onChange={(value) => form.change("availableForType", value)}>*/}
      {/*            <Checkbox value={"email"}>Email</Checkbox>*/}
      {/*            <Checkbox value={"sms"}>SMS</Checkbox>*/}
      {/*          </CheckboxGroup>*/}
      {/*          {*/}
      {/*            meta.error &&*/}
      {/*            <TemplateDetailsError>*/}
      {/*              {meta.error}*/}
      {/*            </TemplateDetailsError>*/}
      {/*          }*/}
      {/*        </>*/}
      {/*      );*/}
      {/*    }*/}
      {/*  }*/}
      {/*</FieldArray>*/}
      {/*<OnChange*/}
      {/*  name={"availableForType"}*/}
      {/*  children={(value, previous) => {*/}
      {/*    if (value?.includes("sms")) {*/}
      {/*      const onlyManual = form.getState().values.availableFor.filter(a => a == "manual");*/}
      {/*      form.change("availableFor", onlyManual);*/}
      {/*    }*/}
      {/*  }}*/}
      {/*/>*/}
    </DetailsField>
    {
      applyToFields.length > 1 &&
      <DetailsField>
        <DetailsField.Title withGap={false}>Apply to</DetailsField.Title>
        <DetailsField.SubTitle>Select when this template should be available for use.</DetailsField.SubTitle>
        {
          applyToFields.map((source, index) => {
            return <Field
              key={index}
              type={"radio"}
              value={source}
              name="applyTo">
              {
                ({ input, meta }) => {
                  return (
                    <RadioButton
                      {...input}>
                      {transformNameToLabel(source)}
                    </RadioButton>
                  );
                }
              }
            </Field>;
          })
        }
        <FormSpy subscription={{ values: true, initialValues: true }}>
          {
            ({ values, initialValues }) => {
              return (initialValues.applyTo == values.applyTo) || flyerId == "create" ?
                null :
                <DetailsField.State variant={"warning"}>
                  This change may result in errors with replacement parameters when you use the flyer.
                </DetailsField.State>;
            }
          }
        </FormSpy>
      </DetailsField>
    }
    <DetailsField>
      <DetailsField.Title>Available as an attachment for</DetailsField.Title>
      <FieldArray
        name="availableFor"
        validate={(value, allValues, meta) => {
          if (meta.modified && value?.length == 0) {
            return "Please select at least one option.";
          }
        }}>
        {
          ({ fields, meta }) => {
            return (
              <>
                <CheckboxGroup value={fields.value} onChange={(value) => form.change("availableFor", value)}>
                  <Checkbox value={"manual"}>Manual</Checkbox>
                  {
                    $object.className == "Settings" &&
                    <Checkbox value={"automated"}>Automated</Checkbox>
                  }
                  <Checkbox value={"bulk"}>Bulk</Checkbox>
                </CheckboxGroup>
                {
                  meta.error &&
                  <DetailsField.State variant={"error"}>
                    {meta.error}
                  </DetailsField.State>
                }
              </>
            );
          }
        }
      </FieldArray>
    </DetailsField>
    <DetailsField>
      <DetailsField.Title>Status</DetailsField.Title>
      <Field
        name="enabled"
        type="checkbox">
        {({ input }) => {
          return <Checkbox
            checked={input.checked}
            onChange={(value, checked) => input.onChange(checked)}>
            Enable template
          </Checkbox>;
        }}
      </Field>
    </DetailsField>
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const owner = values?.owner;
        const role = options.find(option => option.value == owner?.role);
        return owner ?
          <DetailsField>
            <DetailsField.Title>Flyer owner</DetailsField.Title>
            <Stack direction={"row"} alignItems={"center"} spacing={16}>
              <Avatar size={"xl"} src={owner.objectIcon} showIndicator={false}>{owner.objectName}</Avatar>
              <Stack spacing={4} direction={"column"} alignItems={"flex-start"} childrenRenderMode={"clone"}>
                <Typography variant={"base16"} lineHeight={"lh20"}>{owner.objectName}</Typography>
                <Typography variant={"base14"} lineHeight={"lh16"} color={"tertiary"}>{role.label}</Typography>
              </Stack>
            </Stack>
          </DetailsField>
          :
          null;
      }}
    </FormSpy>
  </DetailsContainer>;
});



