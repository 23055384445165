import { SearchableMultiSelectProps } from "../../..";
import React                          from "react";
import { BaseFieldProps }             from "../BaseField";
import { BaseField }                  from "../BaseField";
import { PointerFieldValue }          from "../PointerField";
import PointerListEditField           from "./PointerListEditField";
import PointerListReadField           from "./PointerListReadField";

export type PointerListFieldProps = BaseFieldProps & Omit<SearchableMultiSelectProps,"value"> & {
  thumbnail?: boolean
  collapse?: boolean
  options: PointerFieldValue[]
  filters?: object
};
export const PointerListField = React.memo<PointerListFieldProps>(function PointerListField(props) {
  return (
    <BaseField
      {...props}
      readView={<PointerListReadField/>}
      editView={<PointerListEditField/>}
    />
  );
});
