import React                         from "react";
import { FC }                        from "react";
import { AlertColors }               from "@relcu/ui";
import { Alert, ListItemText }       from "@relcu/ui";
import { ListRowColors }             from "@relcu/ui";
import { ListRow }                   from "@relcu/ui";
import { JsonSectionProps, Section } from "@relcu/ui";
import { Box }                       from "@relcu/ui";
import { GmailConnectButton }        from "../../../Auth/Gmail/GmailConnectButton";
import { MsConnectButton }           from "../../../Auth/Microsoft/MsConnectButton";
import { DisconnectButton }          from "./ContactButtons";
import { EmptyAccounts }             from "./EmptyAccounts";
import { useMailAccountsSection }    from "./useMailAccountsSection";

export const MailAccountsSection: FC<JsonSectionProps> = React.memo(function MailAccountsSection(props) {
  const { title, editable } = props;
  const { isEmpty, boxes, domains, error, setError } = useMailAccountsSection();
  return (
    <Section
      container
      title={title}
      editable={editable}
      direction={"column"}
      gap={"XS"}
      flexGrow={1}>
      {
        isEmpty ?
          <EmptyAccounts/>
          :
          <Box container direction={"column"} gap={"XXS"} flex={1}>
            <ListRow flex={1} gap={"XXXL"} color={ListRowColors.White}>
              <ListItemText tiny={true} flexBasis={200} text={"Name"}/>
              <ListItemText tiny={true} flexBasis={200} flexGrow={1} text={"Domain"}/>
            </ListRow>
            {
              boxes.map(({ node }) => {
                return <ListRow flex={1} gap={"XXXL"} key={node.id}>
                  <ListItemText flexBasis={200} text={node.address}/>
                  <ListItemText flexBasis={200} flexGrow={1} text={node.domain.domain}/>
                  {
                    node.domain.provider.type != "none" && node.auth &&
                    <DisconnectButton objectId={node.objectId} type={node.domain.provider.type as any}/>
                  }
                </ListRow>;
              })
            }
            {
              domains.map(({ node }) => {
                return <ListRow flex={1} gap={"XXXL"} key={node.id}>
                  <ListItemText flexBasis={200} text={`yourname@${node.domain}`}
                                style={{ color: "var(--color-gray-secondary)", opacity: .6 }}/>
                  <ListItemText flexBasis={200} flexGrow={1} text={node.domain}/>
                  {
                    node.provider.type == "outlook" ?
                      <MsConnectButton
                        domain={node}
                        onError={data => setError(data?.error?.message)}
                      />
                      :
                      <GmailConnectButton
                        domain={node}
                        onError={data => setError(data?.error?.message)}
                      />
                  }
                </ListRow>;
              })
            }
            {
              error &&
              <Alert variant={AlertColors.Error} stretch={true}>
                {
                  error
                }
              </Alert>
            }
          </Box>
      }
    </Section>
  );
});
