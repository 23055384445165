import { useCallback }       from "react";
import { useRef }            from "react";
import { useContext }        from "react";
import React                 from "react";
import { Box }             from "../../..";
import { classNames }      from "../../..";
import { CalendarClasses } from "../CalendarClasses";
import { isToday }           from "../date-fns";
import { FocusedInput }      from "../Datepicker";
import DatepickerContext     from "../Datepicker/DatepickerContext";
import { CalendarDayObject } from "../Month/useMonth.utils";
import useDay                from "./useDay";

export interface DayProps extends CalendarDayObject {
  range?: boolean
  startDate: Date | null
  endDate: Date | null
  focusedInput: FocusedInput,
  now: Date
}

export function Day(props: DayProps) {
  const { date, dayLabel, isInMonth, range = false, startDate, endDate, focusedInput, now } = props;
  const dayRef = useRef(null);
  const context = useContext(DatepickerContext);
  const currentDate = { startDate, endDate }[ focusedInput ] || now;
  const onDateSelect = useCallback((date) => {
    date.setHours(currentDate.getHours());
    date.setMinutes(currentDate.getMinutes());
    context.onDateSelect(date);
  }, [context, currentDate]);
  const {
    isEndDate,
    isStartDate,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover
  } = context;

  const {
    isSelected,
    isSelectedStartOrEnd,
    onClick,
    onKeyDown,
    tabIndex,
    disabledDate
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef
  });
  return (
    <Box container justify={"center"} alignItems={"center"}
         className={classNames(CalendarClasses.CalendarDate, {
           [ CalendarClasses.Disabled ]: !isInMonth,
           [ CalendarClasses.DisabledAction ]: disabledDate,
           [ CalendarClasses.RangeStart ]: range && isStartDate(date),
           [ CalendarClasses.RangeEnd ]: range && isEndDate(date),
           [ CalendarClasses.CalendarRange ]: range && isSelected
         })}>
      <Box
        container
        tabIndex={tabIndex}
        onClick={onClick}
        alignItems={"center"}
        justify={"center"}
        ref={dayRef}
        onKeyDown={onKeyDown}
        //onMouseEnter={onMouseEnter}
        className={classNames(CalendarClasses.DateItem, {
          [ CalendarClasses.DateToday ]: isToday(date),
          [ CalendarClasses.Active ]: isSelectedStartOrEnd,
          [ CalendarClasses.Disabled ]: !isInMonth,
          [ CalendarClasses.DisabledAction ]: disabledDate
        })}>
        {dayLabel}
      </Box>
    </Box>
  );
}
