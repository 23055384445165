import { useCallback }  from "react";
import { useEffect }  from "react";
import { useRef }  from "react";

export function useWorkerEvents(listener) {
  const listenerRef = useRef(listener)
  useEffect(() => {
    listenerRef.current = listener
  }, [listener])
  useEffect(() => {
    const handler = e => listenerRef.current(e)
    navigator.serviceWorker.addEventListener("message", handler);
    return () => {
      navigator.serviceWorker.removeEventListener("message", handler);
    };
  },[])
}
