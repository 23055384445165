import React                   from "react";
import { FC }                  from "react";
import { useSource }           from "@relcu/ui";
import { ChipsColors }         from "@relcu/ui";
import { Chips }               from "@relcu/ui";
import { ListItemDate }        from "@relcu/ui";
import { CircularLoader }      from "@relcu/ui";
import { ListItemImage }       from "@relcu/ui";
import { ListItemTitle }       from "@relcu/ui";
import { ListRow }             from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { EmptyList }           from "@relcu/ui";
import { JsonPageProps }       from "@relcu/ui";
import { getContactName }      from "../../../../utils/helpers";
import { ActivityItem }        from "./ActivityItem";
import { ActivityViewClasses } from "./ActivityViewClasses";
import { useActivities }       from "./useActivities";
import "./activity-view.css";

export const ActivityView: FC<JsonPageProps> = React.memo((props) => {
  const { $object } = useSource();
  const api = useActivities();
  const { pageInfo, dividedData } = api.data;

  return <Box className={ActivityViewClasses.ActivityView} ref={api.scrollContainerRef}>
    <Box container direction={"column"} gap={"XXS"} flexGrow={1} className={ActivityViewClasses.ActivityViewContainer}>
      {
        !api.isEmpty ?
          <>
            {dividedData.map((edge, index) => {
              return (
                Date.parse(edge) ?
                  <Chips disabled color={ChipsColors.Grey} key={index}
                         label={edge} alignSelf={"center"}/>
                  :
                  (() => {
                    const { node: activityNode } = edge;
                    const initiator = initiatorDetails(activityNode, $object);
                    return <ListRow key={index} gap={"XXS"} className={ActivityViewClasses.ActivityViewItem}>
                      <ListItemImage
                        flexBasis={"24px"}
                        text={initiator}
                        icon={activityNode.initiator && activityNode.initiator.objectIcon}
                      />
                      <ListItemTitle
                        flexBasis={"150px"}
                        className={ActivityViewClasses.ActivityViewItemName}
                        title={initiator}
                      />
                      <ActivityItem action={activityNode.action} node={activityNode}/>
                      <ListItemDate format="withTime" date={activityNode.createdAt} flexShrink={0} flexBasis={80}/>
                    </ListRow>;
                  })()
              );
            })
            }
            {
              pageInfo?.hasNextPage &&
              <CircularLoader alignSelf={"center"} ref={api.loaderRef}/>
            }
          </>
          :
          <>
            <Box flex={"1 1 auto"} justify={"center"}/>
            <Box flex={"1 1 auto"} justify={"center"}>
              <EmptyList icon={"history"} title={"No activities recorded yet"}
                         content={`All activities related to this ${$object.__typename.toLowerCase()} will appear here`}
                         alignSelf={"center"}/>
            </Box>
          </>
      }
    </Box>
  </Box>;
});
function initiatorDetails(node, parent) {
  let initiatorName: string;
  if (node.initiator) {
    initiatorName = getContactName(node.initiator, parent);
  } else {
    if (node.action.startsWith("te_")) {
      initiatorName = "Total Expert";
    } else if (node.action.startsWith("vl_")) {
      initiatorName = "Velocify";
    } else {
      initiatorName = "Relcu";
    }
  }
  return initiatorName;
}
export default ActivityView;
