import React                      from "react";
import { useState }               from "react";
import { FC }                     from "react";
import { useEffect }              from "react";
import { AvatarSizes }            from "../../Avatar";
import { Avatar }                 from "../../Avatar";
import { Box }                    from "../../Box";
import { BoxComponentProps }      from "../../Box";
import { Ellipsis }               from "../../Ellipsis";
import { RecipientItemContainer } from "./RecipientItemContainer";
import { RecipientListClasses }   from "./RecipientListClasses";
import { Typography }             from "../../Typography";
import { TypographyColor }        from "../../Typography";
import { TypographySize }         from "../../Typography";
import { TypographyVariant }      from "../../Typography";
import { classNames }             from "../../../utils/classNames";
import { Alignment }              from "../../../constants/Alignemnt";
import { FontIcon }               from "../../Icon";
import { CommonClasses }          from "../../../theme/classes";
import { Popper }                 from "../../Popper";

export interface RecipientItemProps extends BoxComponentProps {
  info: {
    label: string
    value: string
  }[];
  selected?: boolean;
  primary: string;
  secondary?: string;
  onUnMount?();
}
export const RecipientItem = React.forwardRef<any, RecipientItemProps>(function RecipientItem(props, ref) {
  const { selected, primary, info, secondary, onUnMount, ...p } = props;
  useEffect(() => {
    return () => onUnMount?.();
  }, []);

  return <RecipientItemContainer ref={ref} selected={selected} gap={"XXXS"} {...p}>
    <Box container gap={"XXS"}>
      <Avatar
        text={primary}
        size={AvatarSizes.Small}
      />
      <Box container direction={"column"} gap={"XXXS"}>
        <RecipientItemName name={primary} info={info}/>
        <RecipientItemInfo info={secondary}/>
      </Box>
    </Box>
  </RecipientItemContainer>;
});

interface RecipientItemNameProps extends BoxComponentProps {
  info: {
    label: string
    value: string
  }[];
  name: string;
}
const RecipientItemName: FC<RecipientItemNameProps> = React.memo(function RecipientItemName(props) {
  const { name, info, ...p } = props;

  return <Box container gap={"XXXS"} alignItems={"center"} style={{ height: "24px" }} {...p}>
    <Ellipsis from={35} style={{ fontSize: "var(--typography-body-font-size)" }}>{name}</Ellipsis>
    {info && <RecipientItemTooltip info={info}>
      <FontIcon
        type={"info"}
        className={classNames(
          RecipientListClasses.ListItemInfoIcon,
          CommonClasses.GrayIcon,
          CommonClasses.ClickableIcon
        )}
      />
    </RecipientItemTooltip>}
  </Box>;
});

interface RecipientItemInfoProps extends BoxComponentProps {
  info: string;
}
const RecipientItemInfo: FC<RecipientItemInfoProps> = React.memo(function RecipientItemInfo(props) {
  const { info, ...p } = props;

  return <Box container gap={"XXXS"} {...p}>
    <Typography
      variant={TypographyVariant.span}
      color={TypographyColor.Secondary}
      size={TypographySize.TextSmall}
    >
      <Ellipsis from={45}>{info}</Ellipsis>
    </Typography>
  </Box>;
});

interface RecipientItemTooltipProps extends BoxComponentProps {
  info: {
    label: string
    value: string
  }[];
}
const RecipientItemTooltip: FC<RecipientItemTooltipProps> = React.memo(function RecipientItemTooltip(props) {
  const { info, children, ...p } = props;
  const [anchorBounding, setAnchorBounding] = useState(null);

  const handleTouchStart = event => {
    setAnchorBounding(event.currentTarget.getBoundingClientRect());
  };
  const handleTouchEnd = () => {
    setAnchorBounding(null);
  };

  const childrenProps = {
    onFocus: handleTouchStart,
    onMouseEnter: handleTouchStart,
    onMouseLeave: handleTouchEnd,
    onMouseDown: handleTouchEnd,
    onBlur: handleTouchEnd
  };

  return <>
    {React.cloneElement((children as any), { ...childrenProps })}
    <Popper open={true} alignment={Alignment.Bottom} anchorBounding={anchorBounding} threshold={4}>
      <Box container direction={"column"} gap={"XXS"} className={RecipientListClasses.ListItemTooltip} {...p}>
        {info.map(({ label, value }, index) =>
          <Box container direction={"row"} key={index} style={{ height: "24px" }}>
            <Typography
              style={{ width: 100 }}
              color={TypographyColor.Secondary}
              size={TypographySize.TextSmall}
            >
              {label}
            </Typography>
            <Ellipsis
              from={40}
              style={{ fontSize: "var(--typography-body-font-size)" }}>
              {value}
            </Ellipsis>
          </Box>
        )}

      </Box>
    </Popper>
  </>;
});
