import { FormSpy }             from "@relcu/form";
import { useRef }              from "react";
import React                   from "react";
import { FC }                  from "react";
import { BoxComponentProps }   from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { defaultMutators }     from "@relcu/ui";
import { Form }                from "@relcu/form";
import { flatten }             from "../../../../../utils/helpers";
import { LoanCriteria }        from "./LoanCriteria";
import { LoanCriteriaClasses } from "./LoanCriteriaClasses";

interface LoanCriteriaFormProps extends BoxComponentProps {
  onCriteriaSubmit: (lead: Partial<any>, FormApi) => void
  initialValues: any
}

export const LoanCriteriaForm: FC<LoanCriteriaFormProps> = React.memo(function LoanCriteriaForm({ onCriteriaSubmit, initialValues, ...boxProps }) {
  const ref = useRef(null);
  const onError = (err) => {
    const flattenErrors = flatten(err);
    const firstError = Object.keys(flattenErrors)[ 0 ];
    const invalidField = ref?.current?.querySelector(`[name="${firstError}"]`);
    if (invalidField) {
      invalidField.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return <Box container direction={"column"} className={LoanCriteriaClasses.LoanCriteria} {...boxProps}>
    <Form initialValues={{ ...initialValues, mortechProductsList: [] }}
          keepDirtyOnReinitialize={true}
          mutators={defaultMutators}
          onSubmit={onCriteriaSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form style={{ display: "contents" }} onSubmit={handleSubmit} ref={ref}>
          <LoanCriteria/>
          <FormSpy subscription={{ errors: true, error: true, submitFailed: true }}
                   onChange={(state) => {
                     if (state.submitFailed && !!Object.keys(state.errors)?.length) {
                       onError(state.errors);
                     }
                   }}/>
        </form>
      )}
    </Form>
  </Box>;
});
