import React                    from "react";
import { FC }                   from "react";
import { useMemo }              from "react";
import { useMutation }          from "@apollo/client";
import { confirmModal }         from "@relcu/ui";
import { useSelect }            from "@relcu/ui";
import { MenuItem }             from "@relcu/ui";
import { ModalProps }           from "@relcu/ui";
import { groupBy }              from "@relcu/ui";
import { Modal }                from "@relcu/ui";
import { ModalBody }            from "@relcu/ui";
import { ButtonVariants }       from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { ModalFooter }          from "@relcu/ui";
import { SearchInput }          from "@relcu/ui";
import { TypographyColor }      from "@relcu/ui";
import { TypographySize }       from "@relcu/ui";
import { Typography }           from "@relcu/ui";
import { LeadWhereInput }       from "../../../types/graphql-global-types";
import { getField }             from "../../../utils/schemaUtils";
import { UpdateLeadsVariables } from "../BulkUserAssignModal/__types__/UpdateLeads";
import { UpdateLeads }          from "../BulkUserAssignModal/__types__/UpdateLeads";
import { UPDATE_LEADS }         from "../BulkUserAssignModal/BulkUserAssignModal";
import { CommonModalClasses }   from "../CommonModal/CommonModalClasses";
import "../CommonModal/common-modal.css";

export interface BulkStatusModalProps extends Partial<ModalProps> {
  ids: string[];
  where?: LeadWhereInput;
  totalCount?: number;
  destroyHandler: () => void;
}

export const BulkStatusModal: FC<BulkStatusModalProps> = React.memo(function BulkStatusModal(props) {
  const { onClose, ids, where: queryWhere, destroyHandler, totalCount } = props;
  const [updateLeads, { loading }] = useMutation<UpdateLeads, UpdateLeadsVariables>(UPDATE_LEADS, {
    context: {
      action: "SetStatus",
      errorPolicy: "ignore"
    }
  });
  const { states } = getField("Lead", "leadStatus");
  const options = useMemo(() => {
    const statuses = Object.entries<{ stage: string, status: string }>(states).map(([value, { stage, status }]) => {
      return { value, label: status, parent: stage };
    });
    const byStages = groupBy(statuses, "parent");
    const options = [];
    for (let [stage, items] of Object.entries(byStages)) {
      options.push({ value: stage, label: stage, disabled: true });
      options.push(...items);
    }
    return options;
  }, [states]);

  const {
    selected,
    setSearch,
    search,
    handleSelect,
    items
  } = useSelect({
    options,
    optionLabel: "label",
    optionKey: "value"
  });

  const handleSubmit = async () => {
    try {
      const where = ids.includes("*") ? queryWhere : {
        id: {
          in: ids
        }
      };

      if (ids.includes("*")) {
        await confirmModal({
          title: `You are about to update the status for ${totalCount} leads.`,
          content: "This may affect the focus views, distribution, automation rules, and other areas of the platform depending on your configurations.",
          label: "CONFIRM"
        });
      }
      updateLeads({
        variables: {
          where: where,
          fields: {
            leadStatus: {
              status: selected.value,
              action: "manual"
            }
          }
        }
      }).catch((e) => {
        console.error(e);
      });
      destroyHandler();
    } catch {

    }
  };
  return <Modal
    onClose={onClose}
    open={true}
    closeIcon={true}
    disableBackdropClick={false}
    title={"Set status"}
    className={CommonModalClasses.BulkModal}
  >
    <ModalBody container direction={"column"} flex={1} className={CommonModalClasses.Container}>
      <SearchInput value={search} onChange={setSearch}/>
      <div className={CommonModalClasses.ScrollContainer}>
        {items.map((item, index) => {
          return <MenuItem
            className={CommonModalClasses.Item}
            disabled={item.disabled}
            selected={item.value == selected?.value}
            key={index}
            onClick={(e) => {
              handleSelect(item);
            }}>
            <Typography
              size={item.disabled ? TypographySize.TextTiny : TypographySize.Text}
              color={item.disabled ? TypographyColor.Secondary : TypographyColor.ExtraDark}
            >
              {item.disabled ? item.label.toUpperCase() : item.label}
            </Typography>
          </MenuItem>;
        })}
      </div>
    </ModalBody>
    <ModalFooter borderOnTop>
      <Button variant={ButtonVariants.Outline} onClick={onClose}>CANCEL</Button>
      <Button onClick={handleSubmit} disabled={!selected || loading}>SET</Button>
    </ModalFooter>
  </Modal>;
});
