import React                 from "react";
import { FC }                from "react";
import { Box }               from "@relcu/ui";
import { RadioField }        from "@relcu/ui";
import { MailDomainTooltip } from "../DomainDetailsForm";

export const ProviderChanger: FC<any> = React.memo(function ProviderChanger(props) {
  return (
    <Box gap={"XS"} container direction={"row"}>
      <RadioField
        name={"provider.type"}
        label={"None"}
        value={"none"}/>
      <Box container alignItems={"center"} gap={"XXXS"}>
        <RadioField
          disabled
          name={"provider.type"}
          label={"SMTP/IMAP"}
          value={"smtp_imap"}/>
        <MailDomainTooltip text={"Coming soon! \n The integrations are not available yet."}/>
      </Box>
      <RadioField
        name={"provider.type"}
        label={"Gmail"}
        value={"gmail"}/>
      <Box container alignItems={"center"} gap={"XXXS"}>
        <RadioField
          name={"provider.type"}
          label={"Outlook"}
          value={"outlook"}/>
      </Box>
    </Box>
  )
});