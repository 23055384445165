import React                    from "react";
import { classNames }           from "../..";
import { Link }                 from "../..";
import { applyBoxItemStyles }   from "../..";
import { BoxComponentProps }    from "../..";
import { Box }                  from "../..";
import { SubNavigationClasses } from "./SubNavigationClasses";

interface SubNavigationItem extends BoxComponentProps {
  path: string;
  end?: boolean;
}
export function SubNavigationItem(props: SubNavigationItem) {
  let properties = applyBoxItemStyles<Omit<SubNavigationItem, "color">>(props);
  const { children, className, path, end = true, ...p } = properties;
  const classes = classNames(SubNavigationClasses.SubNavigationItem, className);

  return <Link to={path} className={SubNavigationClasses.SubNavigationLink} end={end} {...p}>
    <Box container wrap={"nowrap"} gap={"XXS"} className={classes} alignItems={"center"} {...p}>
      {children}
    </Box>
  </Link>;
}
