import { TopLevelCondition } from "@relcu/ui";

export const WHEN_SHOW_MORE_IS_TRUE: TopLevelCondition = { and: [{ path: "showMore", operator: "equals", value: true }] };
export const WHEN_TEM_COMPANY_EXISTS: TopLevelCondition = { and: [{ path: "temporary.company", operator: "notWeakEquals", value: null }] };
export const WHEN_EX_COMPANY_EXISTS: TopLevelCondition = { and: [{ path: "exists.company", operator: "notWeakEquals", value: null }] };
export const WHEN_TEM_FIRSTNAME_EXISTS: TopLevelCondition = { and: [{ path: "temporary.firstName", operator: "notWeakEquals", value: false }] };
export const WHEN_EX_FIRSTNAME_EXISTS: TopLevelCondition = { and: [{ path: "exists.firstName", operator: "notWeakEquals", value: false }] };
export const WHEN_TEM_LASTNAME_EXISTS: TopLevelCondition = { and: [{ path: "temporary.lastName", operator: "notWeakEquals", value: false }] };
export const WHEN_EX_LASTNAME_EXISTS: TopLevelCondition = { and: [{ path: "exists.lastName", operator: "notWeakEquals", value: false }] };
export const WHEN_TEM_MIDDLENAME_EXISTS: TopLevelCondition = { and: [{ path: "temporary.middleName", operator: "notWeakEquals", value: false }] };
export const WHEN_EX_MIDDLENAME_EXISTS: TopLevelCondition = { and: [{ path: "exists.middleName", operator: "notWeakEquals", value: false }] };
export const WHEN_EX_BIRTHDAY_EXISTS: TopLevelCondition = { and: [{ path: "exists.birthday", operator: "notWeakEquals", value: null }] };
export const WHEN_TEM_BIRTHDAY_EXISTS: TopLevelCondition = { and: [{ path: "temporary.birthday", operator: "notWeakEquals", value: null }] };
