import { gql }           from "@apollo/client";
import { NODE_FRAGMENT } from "../../../../graph/operations.graphql";
import { DOCUMENT }      from "../../../../graph/operations.graphql";

export const SELECTED_RATE_FRAGMENT = gql`
  fragment SelectedRate on SelectedRate {
    lastUpdate
    pricingStatus
    productDesc
    productTerm
    productName
    vendorName
    quoteDetails {
      apr
      loanAmount
      monthlyPremium
      piti
      price
      rate
      srp
    }
  }
`;
export const PREPAID_FRAGMENT = gql`
  fragment Prepaid on Prepaid {
    dailyInterest
    daysToPayInterest
    insurance
    interest
    lockInFee
    monthlyInsurance
    monthlyTax
    monthsToPayInsurance
    monthsToPayTax
    tax
    total
    yearlyInsurance
  }
`;
export const OFFER_LOAN_CRITERIA_FRAGMENT = gql`
  fragment LoanCriteria on LoanCriteria {
    cashOut
    cltv
    downPayment
    dti
    fico
    financeFf
    financeMip
    firstTimeHomeBuyer
    firstUseOfVaProgram
    isPmiEligible
    loanAmount
    totalLoanAmount
    loanPurpose
    ltv
    mortech {
      hudReo
      investorIds
      lockInDays
      product
      productName
      source
      streamLine
      streamLineWithAppraisal
      streamLineWithCredit
      view
    }
    property {
      currentMortgageBalance
      occupancy
      propertyAddress {
        propertyCounty
        state
        zipCode
      }
      type
      value
    }
    requiresPestInspection
    secondaryFinancing
    veteranStatus
    waiveEscrow
  }
`;
export const MONTHLY_PAYMENT_FRAGMENT = gql`
  fragment MonthlyPayment on MonthlyPayment {
    total
    insurance
    mi
    pi
    pmi
    tax
  }
`;
export const CLOSING_COST_FRAGMENT = gql`
  fragment ClosingCost on ClosingCost {
    total
    adminFee
    appraisalFee
    attorney
    condoCertificate
    discountPoints
    docReview
    earnestMoneyPaid
    floodCertification
    fundingFee
    mip
    pestInspection
    pmi
    sellerConcession
    subordination
    survey
    title {
      total
      insurance
      ownersTitle
      recordingCharges
      transferTax
      lendersTitle
      settlementFee
    }
    creditAndOther {
      creditReport
      documentDelivery
      loanSafe
      mers
      total
      transcript
      verification
    }
  }
`;
export const OFFER_FRAGMENT = gql`
  ${DOCUMENT}
  ${NODE_FRAGMENT}
  ${SELECTED_RATE_FRAGMENT}
  ${PREPAID_FRAGMENT}
  ${OFFER_LOAN_CRITERIA_FRAGMENT}
  ${MONTHLY_PAYMENT_FRAGMENT}
  ${CLOSING_COST_FRAGMENT}
  fragment Offer on Offer {
    ...Node
    ...Document
    selectedRate {
      ...SelectedRate
    }
    loanCriteria {
      ...LoanCriteria
    }
    closingCost {
      ...ClosingCost
    }
    prepaid {
      ...Prepaid
    }
    monthlyPayment {
      ...MonthlyPayment
    }
    lenderCredits
    totalClosingCost
    lead {
      id
    }
    createdAt
  }
`;
