import React                                 from "react";
import { useState }                          from "react";
import { useLazyQuery }                      from "@apollo/client";
import { gql }                               from "@apollo/client";
import { useMutation }                       from "@apollo/client";
import { deepPick }                          from "@relcu/ui";
import { MAIL_DOMAIN_FRAGMENT }              from "../../../../operations.graphql";
import { CreateMailDomainVariables }         from "./__types__/CreateMailDomain";
import { CreateMailDomain }                  from "./__types__/CreateMailDomain";
import { GetMailDomainCountByNameVariables } from "./__types__/GetMailDomainCountByName";
import { GetMailDomainCountByName }          from "./__types__/GetMailDomainCountByName";
import { GetMailDomainDnsVariables }         from "./__types__/getMailDomainDns";
import { GetMailDomainDns }                  from "./__types__/getMailDomainDns";
import { UpdateMailDomainVariables }         from "./__types__/UpdateMailDomain";
import { UpdateMailDomain }                  from "./__types__/UpdateMailDomain";
import { ValidateMailDomainDnsVariables }    from "./__types__/ValidateMailDomainDns";
import { ValidateMailDomainDns }             from "./__types__/ValidateMailDomainDns";

const pickFields = ["domain", "provider.type", "provider.clientId", "provider.tenantId", "provider.returnUrl", "provider.clientSecret", "provider.sync", "disabled", "useForOut", "aliases"];

export function useMailDomainDialog(props) {
  const [step, setStep] = React.useState(props.action == "save" ? 1 : 0);
  const [domain, setDomain] = React.useState<any>({
    provider: { type: "none" },
    domain: "",
    ...props.record || {}
  });
  const [validateMailDomainDns, { loading: validateLoading }] = useMutation<ValidateMailDomainDns, ValidateMailDomainDnsVariables>(VALIDATE_MAIL_DOMAIN_DNS);
  const [getMailDomainDns, { loading: getMailLoading }] = useMutation<GetMailDomainDns, GetMailDomainDnsVariables>(GET_MAIL_DOMAIN_DNS);
  const [createMailDomain, { loading: createMailLoading }] = useMutation<CreateMailDomain, CreateMailDomainVariables>(CREATE_MAIL_DOMAIN, { refetchQueries: ["GetMailDomains"] });
  const [updateMailDomain] = useMutation<UpdateMailDomain, UpdateMailDomainVariables>(UPDATE_MAIL_DOMAIN);
  const [getDomainCount] = useLazyQuery<GetMailDomainCountByName, GetMailDomainCountByNameVariables>(GET_DOMAIN_COUNT_BY_NAME);
  const [domainDuplicate, setDomainDuplicate] = useState<string>("");
  const api = {
    loading: validateLoading || getMailLoading || createMailLoading,
    step,
    setStep,
    mailDomain: domain,
    domainDuplicate,
    setDomainDuplicate,
    handleSubmit: async (values, f, c) => {
      let { objectId, ...others } = values;
      let fields = deepPick(others, pickFields);
      fields = {
        ...fields,
        domain: fields.domain?.trim()
      };
      if (fields.provider.type == "none") {
        fields.provider = {
          type: "none"
        };
      }
      if (objectId) {
        await updateMailDomain({
          variables: {
            id: objectId,
            fields: fields
          }
        });
      } else {
        await createMailDomain({
          variables: {
            fields: fields
          }
        });
      }
      props.onClose();
    },
    onDnsValidate: async () => {
      let { objectId } = api.mailDomain;
      const result = await validateMailDomainDns({
        variables: {
          id: objectId
        }
      });
      setDomain(result.data.validateMailDomainDns);
    },
    onEnableRelcuEmail: async () => {
      let { objectId, ...others } = api.mailDomain;
      const fields = deepPick(others, pickFields);
      if (!objectId) {
        const result = await createMailDomain({
          variables: {
            fields: fields as any
          }
        });
        setDomain(result.data.createMailDomain.mailDomain);
        objectId = result.data.createMailDomain.mailDomain.objectId;
      }
      const result = await getMailDomainDns({
        variables: {
          id: objectId
        }
      });
      setDomain(result.data.getMailDomainDns);
    },
    validateName: async (domain) => {
      const { data } = await getDomainCount({
        variables: {
          name: domain
        }
      });
      if (data.mailDomains.count > 0) {
        setDomainDuplicate("This domain already exists. Edit the existing domain or add a different one.");
        return;
      }
      if (!domain.match(/^(\S*)+[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}$/i)) {
        setDomainDuplicate("The domain format is incorrect.");
        return;
      }
      setDomain(d => ({ ...d, domain }));
      setStep(1);
    }
  };
  return api;
}

const CREATE_MAIL_DOMAIN = gql`
  mutation CreateMailDomain($fields:CreateMailDomainFieldsInput!) {
    createMailDomain(input: { fields: $fields }) {
      mailDomain {
        ...MailDomain
        provider {
          type
          clientId
          tenantId
          clientSecret
          sync
          returnUrl
        }
      }
    }
  }
  ${MAIL_DOMAIN_FRAGMENT}
`;
const GET_DOMAIN_COUNT_BY_NAME = gql`
  query GetMailDomainCountByName($name: String!) {
    mailDomains(where:{domain:{equalTo: $name}}) {
      count
    }
  }
`;
const UPDATE_MAIL_DOMAIN = gql`
  mutation UpdateMailDomain($id:ID!,$fields:UpdateMailDomainFieldsInput) {
    updateMailDomain(input: {id:$id, fields: $fields }) {
      mailDomain {
        ...MailDomain
        provider {
          type
          clientId
          tenantId
          clientSecret
          sync
          returnUrl
        }
      }
    }
  }
  ${MAIL_DOMAIN_FRAGMENT}
`;
const GET_MAIL_DOMAIN_DNS = gql`
  mutation GetMailDomainDns($id:ID) {
    getMailDomainDns(id:$id) {
      ...MailDomain
      provider {
        type
        clientId
        tenantId
        clientSecret
        sync
        returnUrl
      }
    }
  }
  ${MAIL_DOMAIN_FRAGMENT}
`;
const VALIDATE_MAIL_DOMAIN_DNS = gql`
  mutation ValidateMailDomainDns($id:ID) {
    validateMailDomainDns( id:$id) {
      ...MailDomain
      provider {
        type
        clientId
        tenantId
        clientSecret
        sync
        returnUrl
      }
    }
  }
  ${MAIL_DOMAIN_FRAGMENT}
`;
