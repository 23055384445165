import { useRef }                from "react";
import { MutableRefObject }      from "react";
import { SyntheticEvent }        from "react";
import React                     from "react";
import { useCallback }           from "react";
import { classNames }            from "../../..";
import { useImperativeState }    from "../../..";
import { Box }                   from "../../..";
import { BoxItemComponentProps } from "../../..";
import { FontIcon }              from "../../..";
import { Label }                 from "../../..";
import { CommonClasses }         from "../../../theme/classes";
import { formatAMPM }            from "../../Calendar/time-fns";
import { BaseInputClasses }      from "../BaseInput";
import { BaseInputProps }        from "../BaseInput";

export enum InputState {
  Success = "success",
  Error = "error",
  Warning = "warning",
}

export enum InputType {
  Text = "text",
  Password = "password",
}

export interface TimeProps extends BoxItemComponentProps, BaseInputProps {
  value?: number | string;
  autoFocus?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  message?: string;
  placeholder?: string;
  inputProps?: { [ key: string ]: any; };
  InputProps?: { [ key: string ]: any; };
  leaveStateSpace?: boolean;
  onChange?(value, event?: SyntheticEvent);
  onFocus?(value);
  onBlur?(value);
  onKeyDown?(value);
  onKeyPress?(value);
  onKeyUp?(value);
}

export const Time = React.forwardRef(function Time(props: TimeProps, ref: MutableRefObject<any>) {
  const {
    className,
    children,
    label,
    value,
    clearable,
    disabled,
    readOnly,
    state,
    required,
    message,
    name,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onFocus,
    fullSize,
    halfSize,
    autoFocus,
    InputProps,
    inputProps,
    leaveStateSpace,// todo replace this shit with container query when it will available (make StateContainer display none)
    ...p
  } = props;
  const [stateValue, setStateValue] = useImperativeState(value, onChange);

  const classes = classNames(BaseInputClasses.Input, {
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error,
    [ BaseInputClasses.Warning ]: state == InputState.Warning
  }, TimeClasses.Time, className);

  const handleChange = useCallback((e) => {
    setStateValue(e.target.value || null, e);
  }, [onChange]);

  const timRef = useRef(null);
  const handleOpenNativePicker = () => {
    if ("showPicker" in HTMLInputElement.prototype) {
      timRef.current.showPicker();
    }
  };

  function checkMeridiem(time: string) {
    const timeArray = time.split(":");
    const hour = parseInt(timeArray[ 0 ]);
    const minute = parseInt(timeArray[ 1 ]);
    const date = new Date();
    if (!isNaN(hour) && !isNaN(minute)) {
      date.setHours(hour);
      date.setMinutes(minute);
      if (timeArray[ 2 ]) {
        date.setSeconds(parseInt(timeArray[ 2 ]));
      }

      const { minutes, hours, seconds, ampm } = formatAMPM(date);
      return `${hours} : ${minutes} ${(timeArray[ 2 ] != undefined) ? `: ${seconds}` : ""} ${ampm}`;
    }

    return "";
  }

  function showTime() {
    const time = stateValue || "";
    return !time ? placeholder ?? "n/a" : checkMeridiem(time);
  }

  return <Box container className={classes} gap={"XXXS"} direction={"column"} {...p} {...InputProps}>
    <Box container
         direction={"row"}
         alignItems={"center"}
         gap={"XXS"}
         className={BaseInputClasses.MainContainer}>
      <Box container direction={"column"} gap={"XXXS"} flex={1} className={BaseInputClasses.InputContainer}>
        {label && <Label required={required}>{label}</Label>}
        {
          "showPicker" in HTMLInputElement.prototype ?
            <Box container gap={"XXS"}>
              <time dateTime={stateValue || ""}>{showTime()}</time>
              <input
                type="time"
                style={{ visibility: "hidden" }}
                value={stateValue || ""}
                ref={timRef}
                name={name}
                disabled={disabled || readOnly}
                readOnly={readOnly}
                onChange={handleChange}
                onBlur={onBlur}
                onFocus={onFocus}
                className={TimeClasses.TimeInput}
                {...inputProps}
              />
              <FontIcon type={"access_time"} className={CommonClasses.ClickableIcon} onClick={handleOpenNativePicker}/>
            </Box> :
            <input
              ref={ref}
              autoFocus={autoFocus}
              type="time"
              name={name}
              value={stateValue || ""}
              disabled={disabled || readOnly}
              readOnly={readOnly}
              placeholder={placeholder || "Select value"}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyUp={(e) => onKeyUp && onKeyUp(e)}
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
              onKeyPress={(e) => onKeyPress && onKeyPress(e)}
              {...inputProps}
            />
        }
      </Box>
    </Box>
    <Box container className={BaseInputClasses.Message}>
      {message}
    </Box>
  </Box>;
});
const defaultInputProps = {
  type: InputType.Text,
  disabled: false,
  autoFocus: false,
  readOnly: false,
  state: null,
  leaveStateSpace: true
};

export enum TimeClasses {
  Time = "time",
  TimeInput = "time__input"
}
Time.defaultProps = defaultInputProps;



