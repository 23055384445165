import { transformNameToLabel }       from "@relcu/ui";
import { toFirstLower }               from "@relcu/ui";
import React                          from "react";
import { Box }                        from "@relcu/ui";
import { EmptyList }                  from "@relcu/ui";
import { Widget }                     from "@relcu/ui";
import { useSource }                  from "@relcu/ui";
import { ExportActivityWidget }       from "./ActivityItem";
import { ProposalActivityWidget }     from "./ActivityItem";
import { GenericActivityWidget }      from "./ActivityItem";
import { ContactMergeActivityWidget } from "./ActivityItem";
import { StatusActivityWidget }       from "./ActivityItem";
import { TakeLeadActivityWidget }     from "./ActivityItem";
import { FocusActivityWidget }        from "./ActivityItem";
import { MailActivityWidget }         from "./ActivityItem";
import { CallActivityWidget }         from "./ActivityItem";
import { SMSActivityWidget }          from "./ActivityItem";
import { AssignmentActivityWidget }   from "./ActivityItem";
import { WorkflowActivityWidget }   from "./ActivityItem";
import { ActivityWidgetClasses }      from "./ActivityWidgedClasses";
import { useActivityWidget }          from "./useActivityWidget";
import "./activity-widget.css";

export const ActivityWidget = React.memo(function ActivityWidget() {
  // return  <>""</>
  const { $object: node } = useSource();
  const edges = useActivityWidget();

  const activityItemsMap = {
    "AssignmentActivity": AssignmentActivityWidget,
    "StatusChangeActivity": StatusActivityWidget,

    "PhoneMessageActivity": SMSActivityWidget,
    "PhoneCallActivity": CallActivityWidget,

    "MailMessageActivity": MailActivityWidget,
    "DistributionActivity": TakeLeadActivityWidget,
    "PrioritizationActivity": FocusActivityWidget,

    "GenericActivity": GenericActivityWidget,
    "ObjectMergeActivity": ContactMergeActivityWidget,
    "ProposalActivity": ProposalActivityWidget,
    "WorkflowActivity": WorkflowActivityWidget,
    "ExportActivity": ExportActivityWidget
  };
  return (
    <Widget title="Recent activities">
      {edges.length == 0 ?
        <Box
          container
          direction={"column"}
          alignItems={"center"}
          justify={"center"}
          className={ActivityWidgetClasses.ActivityWidgetEmptyView}
          flex={1}
        >
          <EmptyList icon={"history"} title={"No activities recorded yet"}
                     content={`All activities related to this ${toFirstLower(transformNameToLabel(node.__typename))} will appear here`}
                     alignSelf={"center"} small/>
        </Box> :
        <Box
          container
          direction={"column"}
          gap={"XS"}
          flex={1}>
          {
            edges.map(({ node }, index) => {
                const Component = activityItemsMap[ node.type ];
                return <Box key={index} container direction={"column"} gap={"XXXXS"}
                            className={ActivityWidgetClasses.ActivityViewItem}>
                  <Component {...node.data} createdAt={node.createdAt}/>
                </Box>;
              }
            )
          }
        </Box>
      }
    </Widget>
  );
});

