import { useState }                       from "react";
import React                              from "react";
import { useEffect }                      from "react";
import { useAlert }                       from "@relcu/ui";
import { useQuery }                       from "@apollo/client";
import { gql }                            from "@apollo/client";
import { SubscribeUnreadNotifications }   from "../../graph/__types__/SubscribeUnreadNotifications";
import { SUBSCRIBE_UNREAD_NOTIFICATIONS } from "../../graph/operations.graphql";
import { soundVar }                       from "../../reactiveVars";
import { SoundTypes }                     from "../../Sound";
import { SubscriptionEvent }              from "../../types/graphql-global-types";
import { SoundUser }                      from "./__types__/SoundUser";
import { usePhone }                       from "../usePhone";
import { Subscription }                   from "@apollo/client/react/components";

const SOUND_USER = gql`
  query SoundUser {
    viewer {
      user {
        id
        status
      }
    }
  }
`;

export const Sound = React.memo(function Sound(props) {
  const { data: { viewer: { user: { id, status } } } } = useQuery<SoundUser>(SOUND_USER, {
    fetchPolicy: "cache-only"
  });
  const [isRinging,setIsRinging] = useState(false);
  const phone = usePhone();
  const sound = soundVar();
  const { info } = useAlert();
  const api = {
    play(type: SoundTypes, loop = 0,reset=false) {
      sound.play(type, loop, reset,(e:DOMException) => {
        if(e.code == 0){
          let w = info("Due to low interactions in the system, the browser has muted the sound effects. Please close this to unmute (i.e., new lead available to take, ringing incoming calls, etc.).",
            { autoClose: false, width: "auto" });
          document.body.addEventListener("click", () => {
            w.remove();
          }, { once: true });
        }
      });
    },
    pause(type: SoundTypes) {
      return sound.pause(type);
    },
    async onCallChange() {
      if(status==="available" && phone?.direction==="incoming" && ["initiated","pending","ringing","queued"].includes(phone?.hybridCalls?.myCall?.status)){
        // play reset
        if(!isRinging){
          api.play("direct-call-main",Infinity,true);
          setIsRinging(true);
        }
      }else if(status!=="available" || (phone?.hybridCalls?.myCall && (["in-progress"].includes(phone?.hybridCalls?.myCall?.status)))) {
        // on call or busy
        await sound.silent(false);
        if(isRinging){
          setIsRinging(false);
        }
      }else if (status==="available"){
        sound.unSilent();
        if(isRinging){
          setIsRinging(false);
          await api.pause("direct-call-main");
        }
      }

    },
    onUnreadNotification({ event, node }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
        case SubscriptionEvent.ENTER: {
          switch (node.type) {
            case "sms":
              api.play("sms");
              break;
          }
        }
      }
    }
  };
  useEffect(() => {api.onCallChange().catch(console.error)}, [phone?.hybridCalls?.myCall, phone?.direction, status]);

  // useEffect( () => {
  //   setInterval(async  () => {
  //     const types: SoundTypes[] = ["sms", "lead-increment", 'call-increment', "direct-call"];
  //
  //     let type1:SoundTypes = "direct-call";
  //     let loop = Math.floor(Math.random() * 5);
  //
  //
  //      sound.play(type1, loop, true,(e:DOMException) => {
  //       console.error("SOUND PLAY ERROR SOUND.TSX", e.name, e.cause, e.message, e.stack ,e.code, e.code == DOMException.ABORT_ERR );
  //       if(e.code == 0){
  //         let w = info("Due to low interactions in the system, the browser has muted the sound effects. Please close this to unmute (i.e., new lead available to take, ringing incoming calls, etc.).",
  //           { autoClose: false, width: "auto" });
  //         document.body.addEventListener("click", () => {
  //           w.remove();
  //         }, { once: true });
  //       }
  //
  //     });
  //   }, 10000);
  // } );
  return (
    <>
      <Subscription<SubscribeUnreadNotifications>
        subscription={SUBSCRIBE_UNREAD_NOTIFICATIONS}
        fetchPolicy="cache-only"
        onData={({ data: { data: { notifications } } }) => api.onUnreadNotification(notifications)}
      />
    </>
  );
});
