import React                           from "react";
import { FC, useCallback }             from "react";
import { useState }                    from "react";
import { useMemo }                     from "react";
import { AutoComplete }                from "@relcu/rc";
import { Form }                        from "@relcu/rc";
import { Icon }                        from "@relcu/rc";
import { InputGroup }                  from "@relcu/rc";
import { useMutation }                 from "@apollo/client";
import { useReactiveVar }              from "@apollo/client";
import { Navigate }                    from "@relcu/react-router";
import { useParams }                   from "@relcu/react-router";
import { useNavigate }                 from "@relcu/react-router";
import { Routes }                      from "@relcu/react-router";
import { Route }                       from "@relcu/react-router";
import { EmptyState }                  from "@relcu/rc";
import { Button }                      from "@relcu/rc";
import { Stack }                       from "@relcu/rc";
import { Typography }                  from "@relcu/rc";
import { Toolbar }                     from "@relcu/rc";
import { Sidenav }                     from "@relcu/rc";
import { Page }                        from "@relcu/rc";
import { Content as RCContent }        from "@relcu/rc";
import { toFirstLower }                from "@relcu/ui";
import { omit }                        from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { confirmModal }                from "@relcu/ui";
import { CircularLoader }              from "@relcu/ui";
import { fullScreenVar }               from "../../../../reactiveVars";
import { UpdateFlyerFieldsInput }      from "../../../../types/graphql-global-types";
import { uuid }                        from "../../../../utils/helpers";
import { GenerationListCard }          from "../../../Generation";
import { RelayQuery }                  from "../../../Relay";
import { RemoveEmailTemplate }         from "../MailTemplateView/__types__/RemoveEmailTemplate";
import { DuplicateFlyer }              from "./__types__/DuplicateFlyer";
import { DuplicateFlyerVariables }     from "./__types__/DuplicateFlyer";
import { GetFlyers_flyers_edges_node } from "./__types__/GetFlyers";
import { GetFlyers }                   from "./__types__/GetFlyers";
import { RemoveFlyerVariables }        from "./__types__/RemoveFlyer";
import { UpdateFlyerVariables }        from "./__types__/UpdateFlyer";
import { UpdateFlyer }                 from "./__types__/UpdateFlyer";
import { Content }                     from "./Content/Content";
import { REMOVE_FLYER }                from "./FlyerGenerationViewQueries";
import { GET_FLYERS }                  from "./FlyerGenerationViewQueries";
import { SUBSCRIBE_FLYERS }            from "./FlyerGenerationViewQueries";
import { DUPLICATE_FLYER }             from "./FlyerGenerationViewQueries";
import { UPDATE_FLYER }                from "./FlyerGenerationViewQueries";

export const FlyerGenerationView: FC<any> = React.memo(function FlyerGenerationView(props) {
  const { $viewer, $object } = useSource();
  const navigate = useNavigate();
  const params = useParams();
  const [objectId] = params[ "*" ].split("/");
  const isFullScreen = useReactiveVar(fullScreenVar);
  const canCreate = (($viewer.id == $object.id) && ($object.__typename == "User" ? $object.flyerPermissions.create : $viewer.flyerPermissions.create)) || $object.className == "Settings";
  const [expand, setExpand] = useState(true);
  const [updateFlyer] = useMutation<UpdateFlyer, UpdateFlyerVariables>(UPDATE_FLYER);
  const [duplicateFlyer] = useMutation<DuplicateFlyer, DuplicateFlyerVariables>(DUPLICATE_FLYER, {
    refetchQueries: ["GetFlyers"]// todo not sure this is need
  });
  const [removeFlyer] = useMutation<RemoveEmailTemplate, RemoveFlyerVariables>(REMOVE_FLYER, {
    refetchQueries: ["GetFlyers"]
  });

  const [search, setSearch] = useState(null);
  const where = useMemo(() => {
    const query = {};

    if ($object.__typename == "User") {
      query[ "owner" ] = {
        have: {
          id: {
            equalTo: $object?.id
          }
        }
      };
    } else if ($object.className == "Settings") {
      query[ "owner" ] = {
        exists: false
      };
    } else {
      query[ "OR" ] = [
        {
          owner: {
            have: {
              id: {
                equalTo: $viewer.id
              }
            }
          }
        },
        {
          owner: {
            exists: false
          }
        }
      ];
    }

    if ($object.__typename && $object.__typename != "User") {
      query[ "applyTo" ] = {
        equalTo: toFirstLower($object.__typename)
      };
    }

    if (search) {
      query[ "title" ] = {
        matchesRegex: `^${search}`,
        options: "i"
      };
    }

    return query;
  }, [search, $object]);
  const subscriptionWhere = useMemo(() => {
    const subscriptionWhere = { ...where };

    if ($object.className !== "Settings" && $object.__typename && $object.__typename != "User") {
      subscriptionWhere[ "OR" ] = [
        {
          owner: {
            have: {
              link: $object?.id
            }
          }
        },
        {
          owner: {
            exists: false
          }
        }
      ];
    } else if ($object.__typename == "User") {
      subscriptionWhere[ "owner" ] = {
        have: {
          link: $object?.id
        }
      };
    }
    return subscriptionWhere;
  }, [where, $object]);
  const handleUpdate = useCallback((id: string, title: string) => {
    updateFlyer({
      variables: {
        input: {
          id,
          fields: {
            title
          }
        }
      }
    }).catch(console.error);
  }, []);
  const handleRemove = async (id: string, redirectId: string) => {
    try {
      await confirmModal({
        title: "Delete confirmation",
        subTitle: `Are you sure you want to delete flyer?`,
        content: `All information related with this flyer will be lost permanently`,
        label: "DELETE"
      });
      await removeFlyer({
        variables: {
          id
        }
      });

      if (redirectId) {
        navigate(redirectId);
      } else {
        fullScreenVar(false);
      }
    } catch (e) {
    }
  };
  const handleDuplicate = (data: GetFlyers_flyers_edges_node) => {
    const fields: UpdateFlyerFieldsInput = {
      ...omit(data, ["owner", "createdAt", "id", "updatedAt", "__typename", "ACL", "objectId", "objectIcon"]),
      title: `${data.title}_copy`
    };

    fields.previewSettings = Object.keys(omit(fields.previewSettings, ["__typename"])).reduce((previousValue, currentValue) => {
      if (fields.previewSettings[ currentValue ] != null) {
        previousValue[ currentValue ] = {
          link: fields.previewSettings[ currentValue ].id
        };
      }
      return previousValue;
    }, {});

    if ($object.className != "Settings") {
      fields[ "owner" ] = {
        link: $viewer.id
      };
    }
    duplicateFlyer({
      variables: {
        input: {
          fields: (fields as any)
        }
      }
    });
  };
  const handleCreate = async () => {
    navigate(`create/${uuid()}`);
  };

  return <RelayQuery<GetFlyers>
    className={"Flyers"}
    rowHeight={48}
    query={{
      document: GET_FLYERS,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: { where }
    }}
    subscription={{
      document: SUBSCRIBE_FLYERS,
      variables: { where: subscriptionWhere }
    }}
    render={renderProps => {
      const {
        scrollContainerRef,
        beforeLoaderRef,
        afterLoaderRef,
        loading,
        data: { flyers: { edges = [], pageInfo } } = {}
      } = renderProps;
      const selected = edges.find(e => e.node.objectId === objectId)?.node;
      const activeIndex = edges.findIndex(e => e.node.objectId == objectId);
      const nextOrPrevTemplate = activeIndex + 1 < edges.length ?
        activeIndex + 1 :
        activeIndex - 1 >= 0 ? (activeIndex - 1) : null;
      const navigateNode = edges[ nextOrPrevTemplate ]?.node;
      const navigateId = navigateNode?.objectId;
      return (
        <>
          {
            (!loading && !edges.length && !search && objectId != "create") ?
              <EmptyState
                buttonLabel={"CREATE"}
                background
                icon={"add_photo_alternate"}
                title={"You haven’t created flyers"}
                onClick={canCreate && handleCreate}
                subtitle={canCreate && "To create a new one, click the ‘Create’ button below."}
              />
              :
              <Page>
                <Page.Sidebar width={expand ? 320 : 0}
                              isFullScreen={isFullScreen}
                              style={{ borderWidth: expand ? "1px" : "0", opacity: expand ? 1 : 0, gap: 8 }}>
                  <Sidenav.Header>
                    <Toolbar spacing={6} size={"sm"}>
                      <Page.Toggle
                        size={"sm"}
                        expand={expand}
                        onToggle={() => setExpand(expand => !expand)}
                        disabled={edges?.length == 0}/>
                      <Typography weights={"medium"} variant={"base16"}>
                        {props.title}
                      </Typography>
                      <Stack.Item grow={1}/>
                      {
                        canCreate &&
                        <Button
                          size={"xs"}
                          style={{ alignSelf: "end" }}
                          onClick={handleCreate}>
                          CREATE
                        </Button>
                      }
                    </Toolbar>
                    <Stack direction={"column"} spacing={16} style={{ marginLeft: 16, marginRight: 16 }}
                           childrenRenderMode={"clone"}>
                      <InputGroup inside>
                        <InputGroup.Addon>
                          <Icon type={"search"}/>
                        </InputGroup.Addon>
                        <Form.Control onChange={setSearch} name="name" accepter={AutoComplete} placeholder="Search..."/>
                      </InputGroup>
                    </Stack>
                  </Sidenav.Header>
                  <Sidenav style={{ height: "calc(100% - 126px)" }} expanded={expand} appearance="subtle">
                    <Sidenav.Body style={{ height: "100%" }}>
                      <div
                        className={"mail-list-container"}
                        style={{ height: "100%" }}
                        ref={scrollContainerRef}>
                        {pageInfo?.hasPreviousPage && <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>}
                        {
                          edges.map((item) => {
                            const mail = item.node;
                            return <GenerationListCard
                              canUpdate={canCreate || $viewer.role == "admin"}
                              onUpdate={handleUpdate}
                              objectId={mail.objectId}
                              onRemove={() => handleRemove(mail.id, navigateId)}
                              onDuplicate={($object.className == "Settings" || $object.id === $viewer.id) ? () => handleDuplicate(mail) : null}
                              selected={objectId == mail?.objectId}
                              onClick={() => navigate(`${mail.objectId}`)}
                              enabled={mail.enabled}
                              title={mail.title}
                              date={mail.createdAt}
                              key={mail.id}/>;
                          })
                        }
                        {
                          loading ?
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              style={{ flex: 1 }}>
                              <CircularLoader/>
                            </Stack> : !loading && edges.length == 0 ?
                              <EmptyState
                                title={"No results"}
                                subtitle={search ? "Try again with different term." : null}/> :
                              null
                        }
                        {
                          afterLoaderRef && pageInfo?.hasNextPage &&
                          <CircularLoader alignSelf={"center"} ref={afterLoaderRef}/>
                        }
                      </div>
                    </Sidenav.Body>
                  </Sidenav>
                </Page.Sidebar>
                <Routes>
                  <Route path={"/"} element={
                    <RCContent style={{ justifyContent: "center" }}>
                      {
                        loading ?
                          <CircularLoader/>
                          :
                          (
                            edges.length ?
                              <Navigate to={edges[ 0 ].node.objectId}/> :
                              <EmptyState icon={"add_photo_alternate"} subtitle={`No flyers available`}/>
                          )
                      }
                    </RCContent>
                  }/>
                  <Route
                    path={"/:flyerId/*"}
                    element={
                      (selected || objectId == "create") ?
                        <Content
                          onRemove={(id) => handleRemove(id, navigateId)}
                          replaceableFieldsSources={props.replaceableFieldsSources}
                          hasPermissions={canCreate || $viewer.role == "admin"}
                          onDuplicate={handleDuplicate}
                          setExpand={setExpand}
                          flyer={selected}
                          expand={expand}
                        />
                        :
                        <RCContent>
                          <EmptyState icon={"add_photo_alternate"} subtitle={`No flyer found`}/>
                        </RCContent>
                    }
                  />
                </Routes>
              </Page>
          }
        </>
      );
    }}
  />;
});
