import React                    from "react";
import { HTMLAttributes }       from "react";
import { Box }                  from "../../Box";
import { RecipientListClasses } from "./RecipientListClasses";

export interface RecipientListContainerProps extends HTMLAttributes<HTMLDivElement> {

}

export const RecipientListContainer = React.memo(React.forwardRef(function RecipientListContainer(props: RecipientListContainerProps, ref?: React.Ref<HTMLDivElement>) {
  return <Box container direction={"column"} className={RecipientListClasses.ListContent} ref={ref}
              gap={"XXS"} {...props}/>;
}));