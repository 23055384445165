import React                         from "react";
import { useCallback }               from "react";
import { useContext }                from "react";
import { useMemo }                   from "react";
import { useReactiveVar }            from "@apollo/client";
import { SelectPicker }              from "@relcu/rc";
import { Stack }                     from "@relcu/rc";
import { Icon }                      from "@relcu/rc";
import { IconButton }                from "@relcu/rc";
import { useNavigate }               from "@relcu/react-router";
import { ClientContext }             from "../../../../Client";
import { audioOutputIdVar }          from "../../../../reactiveVars";
import { audioInputIdVar }           from "../../../../reactiveVars";
import { audioDevicesVar }           from "../../../../reactiveVars";
import "./sound-controller.css";
import { usePhone }                  from "../../../usePhone";
import { UnreadNotificationContext } from "../../UnreadNotificationProvider";
import { MaybeError }                from "./MaybeError";

export function SoundController() {
  const client = useContext(ClientContext);
  const { userObjectId } = useContext(UnreadNotificationContext);
  const inputId = useReactiveVar(audioInputIdVar);
  const outputId = useReactiveVar(audioOutputIdVar);
  const devices = useReactiveVar(audioDevicesVar);
  const navigate = useNavigate();
  const selectedInput = useMemo(() => devices.audioinput?.find(d => d.deviceId == inputId), [inputId, devices]);
  const selectedOutput = useMemo(() => devices.audiooutput?.find(d => d.deviceId == outputId), [outputId, devices]);
  const onPreferenceClick = useCallback(() => {
    navigate(`/user/${userObjectId}/preference`);
  }, [navigate, userObjectId]);
  const setOutput = useCallback((deviceId) => {
    client.soundPreferences.changeOutputDevice(devices.audiooutput?.find(d => d.deviceId == deviceId));
  }, [devices, client]);
  const setInput = useCallback((deviceId) => {
    client.soundPreferences.changeInputDevice(devices.audioinput?.find(d => d.deviceId == deviceId));
  }, [client,devices]);
  return <Stack direction={"row"}
                alignItems={"center"}
                style={{ alignSelf: "stretch" }}
                childrenRenderMode={"clone"}
                justifyContent={"space-between"}>
    <Stack>
      <MaybeError message={!devices.audioinput.length && "No microphone detected"}>
        <SelectPicker
          appearance={"icon"}
          menuStyle={{ width: "auto" }}
          icon={"record_voice_over"}
          disabled={!devices.audioinput.length}
          size={"lg"}
          onSelect={setInput}
          placement={"topEnd"}
          value={selectedInput?.deviceId}
          searchable={false}
          valueKey={"deviceId"}
          data={devices.audioinput}
        />
      </MaybeError>
      <MaybeError message={!devices.audiooutput.length && "No speaker detected"}>
        <SelectPicker
          menuStyle={{ width: "auto" }}
          appearance={"icon"}
          disabled={!devices.audiooutput.length}
          icon={"volume_up"}
          size={"lg"}
          onSelect={setOutput}
          placement={"topEnd"}
          value={selectedOutput?.deviceId}
          searchable={false}
          valueKey={"deviceId"}
          data={devices.audiooutput}
        />
      </MaybeError>
    </Stack>
    <Stack>
      <CallQualityIndicator/>
      <IconButton
        onClick={onPreferenceClick}
        appearance={"text"}
        icon={<Icon type={"tune"}/>}
        size={"md"}
        style={{ flexShrink: 0 }}
      />
    </Stack>
  </Stack>;
}
const CallQualityIndicator = () => {
  const phone = usePhone();
  if (!phone?.active) {
    return null;
  }
  if (phone.active && !phone.active.warnings?.length) {
    return <Icon size={24} color={"success"} type={"signal_cellular_alt"}/>;
  }
  console.log("WARNING ----", phone?.active?.warnings?.map(w => w.toUpperCase()));
  if (phone.active && phone.active.warnings.length <= 2) {
    return <Icon size={24} color={"success"} type={"rc_signal_cellular_alt_2_bar"}/>;
  }
  return <Icon size={24} color={"warning"} type={"rc_signal_cellular_alt_1_bar"}/>;

};

