import React          from "react";
import { JsonForm }   from "@relcu/ui";
import { UseJqlForm } from "./useJqlForm";
import { useJqlForm } from "./useJqlForm";

export const JqlForm = React.memo<JqlFormProps>(function JqlForm(props) {
  const { onSubmit, ref,className, ...p } = useJqlForm(props);

  return (
    <JsonForm
      className={className}
      group={true}
      sections={props.sections}
      onSubmit={onSubmit}
      formProps={{ ref }}
      {...p}
    />
  );
});

export interface JqlFormProps extends UseJqlForm{

}
