import React                 from "react";
import { FC }                from "react";
import { ReactElement }      from "react";
import { Stack }             from "@relcu/rc";
import { Typography }        from "@relcu/rc";
import "./dial-list.css";

export interface DialListProps {
  header: string | ReactElement;
}

export const DialList: FC<DialListProps> = React.memo(function DialList(props) {
  const { children, header, ...p } = props;
  return <Stack direction={"column"} spacing={8} justifyContent={"center"} alignItems={"stretch"} style={{ flex: 1, alignSelf: "stretch" }}>
    {
      typeof header == "string" ?
        <Stack.Item className={"dial-list-header"}>
          <Typography variant={"small12"} color={"secondary"}>
            {header}
          </Typography>
        </Stack.Item> :
        header
    }
    {children}
  </Stack>;
});
