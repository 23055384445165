import { ChoiceFieldProps }      from "@relcu/ui";
import { BaseField }             from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import React                     from "react";
import { FC }                    from "react";
import { UsersAndTagsEditField } from "./UsersAndTagsEditField";

export const UsersAndTagsField: FC<ChoiceFieldProps> = React.memo(function UsersAndTagsField(props) {
  return <BaseField {...props} readView={<Box>Implement me!</Box>} editView={<UsersAndTagsEditField/>}/>;
});
