import { useMemo }          from "react";
import React                from "react";
import { useSource }        from "@relcu/ui";
import { ChoiceFieldProps } from "@relcu/ui";
import { ChoiceCalcField }  from "./ChoiceCalcField";

export const VeteranTypeField = React.memo<Partial<ChoiceFieldProps>>(function VeteranTypeField(props) {
  const { $object: { members } } = useSource();
  const index = useMemo(() => members.findIndex(b => b.type == "borrower"), [members]);
  return (
    <ChoiceCalcField required={true} flex={1} name={`members[${index}].veteranStatus`}
                     label={"Veteran Type"} placeholder={"Select type"} {...props}/>
  );
});
