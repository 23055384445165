import { format }                 from "../..";
import { classNames as clsNames } from "../../utils/classNames";
import { FunctionComponent }      from "react";
import React                      from "react";
import { FC }                     from "react";
import { Box }                    from "../Box";
import { BoxComponentProps }      from "../Box";
import { BaseFieldClasses }       from "../Field/BaseField";
import { IconType }               from "../Icon";
import { FontIcon }               from "../Icon";
import { Label }                  from "../Label";
import { SummaryClasses }         from "./SummaryClasses";

export interface SummaryProps extends BoxComponentProps {
  title: string;
  halfSize?: boolean;
  fullSize?: boolean;
}
interface SummaryComponent extends FunctionComponent<SummaryProps> {
  Value?: FC<any>
}
export const Summary: SummaryComponent = React.memo(function Summary(props) {
  const { className, children, title, halfSize, ...p } = props;
  const classNames = clsNames(SummaryClasses.Calculator, {
    [ BaseFieldClasses.HalfSize ]: halfSize
  }, className);

  return <Box container className={classNames} alignItems={"center"} gap={"XXXS"} wrap={"wrap"} {...p}>
    {
      title &&
      <Label required={false} flexBasis={"100%"}>
        {title}
      </Label>
    }
    {
      children
    }
  </Box>;
});
Summary.Value = React.memo<SummaryValueProps>(function SummaryValue(props) {
  const { className, icon, value, ...p } = props;
  const classNames = clsNames(SummaryClasses.CalculatorResult, className);
  return <Box container gap={"XXXS"} alignItems={"center"} alignSelf={"baseline"} className={classNames} {...p}>
    <FontIcon type={icon}/>
    <p>{`${format(value || 0, 2)}`}</p>
  </Box>;
});
export interface SummaryValueProps extends BoxComponentProps {
  icon: IconType,
  value: number
}
