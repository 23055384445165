import { Ellipsis }             from "@relcu/ui";
import React                    from "react";
import { useContext }           from "react";
import { useMemo }              from "react";
import { useState }             from "react";
import { useCallback }          from "react";
import { usePrevious }          from "@relcu/ui";
import { useMounted }           from "@relcu/ui";
import { useField }             from "@relcu/final-form";
import { UseFieldConfig }       from "@relcu/final-form";
import { FormControlBaseProps } from "../../@types/common";
import { createNullableValidator } from "../../FormField/FormField";
import { createRequireValidator }  from "../../FormField/FormField";
import { useInputNumber }          from "../../FormField/FormInputNumber";
import { InputCellProps }          from "../../index";
import { Button }                  from "../../index";
import { SelectPickerProps }       from "../../SelectPicker";
import { CheckPickerCell }         from "../../TableCell";
import { CheckboxCell }            from "../../TableCell";
import { InputCell }               from "../../TableCell";
import { CellProps }               from "../../TableHorizontal/Cell";
import { CellGroupContext }        from "../../TableHorizontal/CellGroup";
import { composeValidators }       from "../../utils/composeValidators";
import ImageCell                   from "../ImageCell";
import ReadCell                    from "../ReadCell";
import SelectCell                  from "../SelectCell";

export interface FieldCellProps<P = any, ValueType = any> extends UseFieldConfig<ValueType>, Omit<CellProps, "defaultValue" | "value"> {
  name: string;
  type?: string;
  helperText?: string;
  plaintext?: boolean;
  prefix?: string;
  required?: boolean;
  notNullable?: boolean;
  highlight?: boolean;
  showError?: boolean;
  component?: React.ElementType<P & FormControlBaseProps<ValueType>>;
  properties?: Partial<P>;
}

export interface CellFieldComponent<P = any> extends React.FC<FieldCellProps<P>> {
  <P = any>(
    props: FieldCellProps<P>
  ): React.ReactElement | null;
  Number: typeof NumberFieldCell;
  Checkbox: typeof CheckboxFieldCell;
  Select: typeof SelectFieldCell;
  CheckPicker: typeof CheckPickerFieldCell;
  Read: typeof ReadFieldCell;
  Text: typeof TextFieldCell;
  PmiCell: typeof PMIFieldCell;
  TitleCell: typeof TitleFieldCell;
}

export const FieldCell: CellFieldComponent = React.memo((props: FieldCellProps) => {
  const {
    name,
    component: Component = InputCell,
    status,
    validate,
    required,
    notNullable,
    properties,
    plaintext,
    highlight = true,
    icon,
    showError = true,
    inert,
    ...config
  } = props;
  const requiredValidator = useMemo(() => required && createRequireValidator(null), [required]);
  const nullableValidator = useMemo(() => notNullable && createNullableValidator(null), [notNullable]);
  const { input, meta } = useField(name, {
    ...config,
    validate: useMemo(() => composeValidators(requiredValidator, nullableValidator, validate), [requiredValidator, nullableValidator, validate])
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const hasError = meta.error && meta.touched;
  const wasActive = usePrevious(meta.active);
  const prevValue = usePrevious(input.value);
  useMounted(() => {
    if (highlight && !wasActive && !meta.active && !meta.submitting && prevValue !== input.value) {
      setIsSuccess(true);
    }
  }, [input.value, prevValue, wasActive, meta.active, meta.submitting, highlight]);

  const onAnimationEnd = useCallback(() => {
    setIsSuccess(false);
  }, []);
  const context = useContext(CellGroupContext);

  if (plaintext) {
    return <ReadCell status={hasError ? "error" : (isSuccess ? "success" : status)}
                     {...input}
                     helperText={!showError ? null : hasError ? meta.error : props.helperText}
                     {...properties} inert={inert ?? context?.disabled} onAnimationEnd={onAnimationEnd}
                     icon={icon}/>;

  }

  return (<Component status={hasError ? "error" : (isSuccess ? "success" : status)}
                     {...input}
                     {...properties}
                     helperText={!showError ? null : hasError ? meta.error : props.helperText}
                     inert={inert ?? context?.disabled} onAnimationEnd={onAnimationEnd}
                     icon={icon}/>);
}) as unknown as CellFieldComponent;

export interface NumberFieldCellProps extends Omit<FieldCellProps, "component"> {
  precision?: number;
}

export const NumberFieldCell = React.memo((props: NumberFieldCellProps) => {
  const { parse, format } = useInputNumber(props);
  return <FieldCell parse={parse} format={format}
                    component={NumberCell}
                    properties={{ space: true, className: "rc-cell-number", helperText: props.helperText, justify: props.justify }} {...props} />;
});
const NumberCell = React.memo<InputCellProps>(props => {
  const onChange = useCallback((event) => {
    let cursor = event.target.selectionEnd;
    props.onChange(event);
    event.persist();
    setTimeout(() => {
      if (String(event.target.value).includes(",")) {
        const oldLength = (String(props.value).match(/,/g) || []).length;
        const newLength = (String(event.target.value).match(/,/g) || []).length;
        if (oldLength !== newLength) {
          cursor += 1;
        }
      }
      event.target.selectionStart = cursor;
      event.target.selectionEnd = cursor;
      event.target.focus();
    });
  }, [props.onChange, props.value]);
  return <InputCell {...props} onChange={onChange}/>;
});
export const CheckboxFieldCell = React.memo((props: Omit<FieldCellProps, "component">) => {
  const { children } = props;
  return <FieldCell type={"checkbox"}  {...props} component={CheckboxCell} properties={{ children }}/>;
});

export const PMIFieldCell = React.memo((props: {
  onOpen: () => void,
  loading: boolean
} & Omit<FieldCellProps, "component">) => {
  const { onOpen, loading, helperText, status, ...other } = props;
  const requiredValidator = useMemo(() => createRequireValidator(null), []);
  const context = useContext(CellGroupContext);
  const { input, meta } = useField(props.name, {
    ...other,
    validate: requiredValidator
  });

  return <ImageCell
    imageHelperText={pmiHelperTexts[input.value]}
    helperText={helperText || meta.error} status={meta.error ? "error" : status}
    src={loading ? null : input.value ? `../assets/pmi_logos/${input.value}_second.png` : null}
    inert={context.disabled}>
    <Button appearance={"text"} onClick={onOpen} disabled={loading} size={"xs"} loading={loading}>SELECT</Button>
  </ImageCell>;
});

export const TitleFieldCell = React.memo((props: {
    onOpen: () => void,
    loading: boolean
    title? : string
  } & Omit<FieldCellProps, "component">) => {
    const { onOpen, loading, helperText, status, title, ...other } = props;
    const requiredValidator = useMemo(() => createRequireValidator(null), []);
    const context = useContext(CellGroupContext);
    const { input, meta } = useField(props.name, {
      ...other,
      validate: requiredValidator
    });

   if(input.value==="lodestar") {
     return <ReadCell
       helperText={helperText || meta.error} status={meta.error ? "error" : status}
       inert={context.disabled} as={"div"} className={"rc-cell-title-fee"}>
       <Ellipsis from={14} title={title||titleFeeHelperTexts[input.value]} >
         {title||titleFeeHelperTexts[input.value]}
       </Ellipsis>
       <Button appearance={"text"} onClick={onOpen} disabled={loading} size={"xs"} loading={loading}>SELECT</Button>
     </ReadCell>
   } else {
       return <ImageCell
         imageHelperText={title||titleFeeHelperTexts[input.value]}
         helperText={helperText || meta.error} status={meta.error ? "error" : status}
         src={loading ? null : input.value ? `../assets/titleFee_logos/${input.value}_second.png` : null}
         inert={context.disabled}>
         <Button appearance={"text"} onClick={onOpen} disabled={loading} size={"xs"} loading={loading}>SELECT</Button>
       </ImageCell>
     }

  }
);

export interface SelectFieldCellProps extends Omit<FieldCellProps<SelectPickerProps>, "component"> {
  data: { label: string, value: string }[];
  searchable?: boolean;
  cleanable?: boolean
}

export const SelectFieldCell = React.memo((props: SelectFieldCellProps) => {
  const { data, searchable = false, inert } = props;
  return <FieldCell {...props} component={SelectCell}
                    showError={false}
                    properties={{ data, searchable, helperText: props.helperText, style: props.style, inert }}/>;
});

export const CheckPickerFieldCell = React.memo((props: SelectFieldCellProps) => {
  const { data } = props;
  return <FieldCell {...props} component={CheckPickerCell} showError={false}
                    properties={{ data, helperText: props.helperText, style: props.style }}/>;
});

export const ReadFieldCell = React.memo((props: Omit<FieldCellProps, "component">) => {
  const { icon, disabled, style, ...rest } = props;
  return <FieldCell {...rest} properties={{ icon, disabled, style }} component={ReadCell}/>;
});
export const TextFieldCell = React.memo((props: Omit<FieldCellProps, "component">) => {
  const { icon, disabled, style, ...rest } = props;
  return <FieldCell {...rest} properties={{ icon, disabled, style }} component={InputCell}/>;
});

FieldCell.Number = NumberFieldCell;
FieldCell.Checkbox = CheckboxFieldCell;
FieldCell.Select = SelectFieldCell;
FieldCell.CheckPicker = CheckPickerFieldCell;
FieldCell.Read = ReadFieldCell;
FieldCell.Text = TextFieldCell;
FieldCell.PmiCell = PMIFieldCell;
FieldCell.TitleCell = TitleFieldCell;
FieldCell.displayName = "FieldCell";

const pmiHelperTexts = {
  "national": "National MI",
  "essent": "Essent",
  "enact": "Enact",
  "radian": "Radian",
  "arch": "Arch"
}

const titleFeeHelperTexts = {
  "custom": "Custom",
  "summit": "Summit Company",
  "meymax": "Meymax Company",
  "wfg": "WFG Company",
  "silk": "The silk companies",
  "solidifi": "Solidifi"
}

export const SlikLogo = () => {
  return <svg width="58" height="24" viewBox="0 0 150 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.8142 16.9102H26.3626C25.776 16.9102 25.3005 17.3857 25.3005 17.9722V58.737C25.3005 59.3235 25.776 59.799 26.3626 59.799H31.8142C32.4008 59.799 32.8763 59.3235 32.8763 58.737V17.9722C32.8763 17.3857 32.4008 16.9102 31.8142 16.9102Z"
      fill="#17202C"/>
    <path
      d="M44.9904 51.4915V18.0628C44.9911 17.9104 44.9616 17.7594 44.9037 17.6185C44.8458 17.4776 44.7605 17.3495 44.6529 17.2416C44.5453 17.1338 44.4174 17.0483 44.2766 16.9901C44.1358 16.9318 43.9849 16.902 43.8325 16.9023H38.5676C38.262 16.903 37.9691 17.0247 37.7531 17.2408C37.537 17.4568 37.4153 17.7497 37.4147 18.0552V58.6434C37.4147 58.9492 37.5361 59.2424 37.7523 59.4586C37.9686 59.6748 38.2618 59.7963 38.5676 59.7963H57.2761C57.4276 59.7966 57.5777 59.767 57.7177 59.7092C57.8577 59.6514 57.9849 59.5665 58.0921 59.4593C58.1992 59.3522 58.2841 59.225 58.3419 59.085C58.3997 58.9449 58.4293 58.7949 58.429 58.6434V53.7998C58.429 53.494 58.3075 53.2007 58.0913 52.9845C57.8751 52.7683 57.5819 52.6469 57.2761 52.6469H46.1433C45.8373 52.6462 45.5441 52.5242 45.3279 52.3076C45.1118 52.091 44.9904 51.7975 44.9904 51.4915Z"
      fill="#17202C"/>
    <path
      d="M64.7206 59.7887H70.4371C70.6058 59.7887 70.7721 59.7495 70.923 59.6742C71.0739 59.5988 71.2052 59.4894 71.3065 59.3546C71.4078 59.2197 71.4764 59.0632 71.5067 58.8973C71.5371 58.7314 71.5285 58.5607 71.4815 58.3987L64.7509 35.2501C64.6815 35.0246 64.6886 34.7825 64.7711 34.5614L71.0779 18.3958C71.1424 18.2314 71.1657 18.0538 71.146 17.8783C71.1262 17.7028 71.0639 17.5348 70.9644 17.3889C70.865 17.243 70.7314 17.1235 70.5754 17.0409C70.4193 16.9583 70.2454 16.9151 70.0688 16.915L64.6702 16.9024C64.4515 16.9022 64.238 16.9685 64.0578 17.0923C63.8776 17.2162 63.7392 17.3919 63.6611 17.5961L57.8966 32.4474C57.878 32.4949 57.8435 32.5344 57.799 32.5591C57.7544 32.5839 57.7026 32.5924 57.6525 32.5832C57.6023 32.5739 57.557 32.5475 57.5242 32.5085C57.4914 32.4694 57.4733 32.4202 57.4728 32.3692V17.9897C57.4728 17.8464 57.4445 17.7046 57.3896 17.5724C57.3346 17.4401 57.2541 17.3201 57.1526 17.219C57.0511 17.118 56.9306 17.038 56.7981 16.9837C56.6656 16.9293 56.5237 16.9017 56.3805 16.9024H50.9793C50.8355 16.9017 50.6929 16.9296 50.5599 16.9844C50.4269 17.0392 50.3061 17.1199 50.2045 17.2217C50.1029 17.3235 50.0226 17.4445 49.968 17.5776C49.9135 17.7108 49.886 17.8534 49.887 17.9972V49.279C49.887 49.5674 50.0015 49.844 50.2054 50.0479C50.4094 50.2518 50.6859 50.3663 50.9743 50.3663H56.3754C56.6638 50.3663 56.9404 50.2518 57.1443 50.0479C57.3482 49.844 57.4627 49.5674 57.4627 49.279V47.4274C57.4628 47.2944 57.4876 47.1627 57.5359 47.0389L58.8124 43.7189C58.8291 43.6767 58.8584 43.6406 58.8964 43.6157C58.9344 43.5909 58.9792 43.5784 59.0246 43.5801C59.07 43.5817 59.1138 43.5974 59.1499 43.625C59.186 43.6526 59.2126 43.6907 59.2261 43.7341L63.6712 59.0268C63.7414 59.2495 63.8813 59.4437 64.0702 59.5809C64.2592 59.718 64.4872 59.7909 64.7206 59.7887Z"
      fill="#17202C"/>
    <path
      d="M16.4937 37.4384C12.7702 33.4575 9.22322 30.564 9.22322 26.8076C9.22322 23.9872 11.0421 23.3263 12.2782 23.3263C13.7641 23.3263 15.4544 24.4438 15.4544 26.4418V28.2077C15.4522 28.3673 15.4879 28.5251 15.5586 28.6683C15.6292 28.8114 15.7329 28.9357 15.8609 29.031C15.9889 29.1262 16.1377 29.1898 16.2951 29.2164C16.4525 29.243 16.6139 29.2319 16.7662 29.184L21.8646 27.6704C22.0843 27.6047 22.2781 27.4722 22.4191 27.2914C22.5601 27.1106 22.6414 26.8904 22.6517 26.6613C22.7652 24.1209 22.2682 16.3711 11.973 16.3711C11.8317 16.3711 11.6904 16.3711 11.5492 16.3711C1.88713 16.7344 -0.998872 29.1134 4.49815 35.395C5.25497 36.2754 6.01179 37.1457 6.4129 37.6326C6.43561 37.6604 7.82815 39.174 7.92654 39.2674C11.6501 43.2482 15.1844 46.1796 15.1844 49.9335C15.1844 52.7564 13.3681 53.4173 12.1294 53.4173C10.6435 53.4173 8.95581 52.2972 8.95581 50.3018V48.5359C8.95811 48.376 8.92238 48.2178 8.85157 48.0745C8.78076 47.9311 8.6769 47.8066 8.54854 47.7113C8.42018 47.6159 8.271 47.5525 8.11329 47.5261C7.95559 47.4997 7.79387 47.5112 7.64147 47.5596L2.54556 49.0858C2.32607 49.1519 2.13248 49.2844 1.9915 49.4652C1.85053 49.6459 1.76911 49.8659 1.75847 50.0949C1.64242 52.6378 2.1394 60.3876 12.4372 60.3876C12.5759 60.3876 12.7172 60.3876 12.861 60.3876C22.5205 60.0244 25.4065 47.6453 19.9095 41.3663C19.13 40.4682 16.5871 37.5317 16.4937 37.4384Z"
      fill="#17202C"/>
    <path
      d="M72.6949 3.06435C48.7996 11.4423 24.9119 -6.04017 1.00909 2.33781C0.728664 2.44378 0.484529 2.62798 0.305664 2.86855C0.126798 3.10913 0.0207132 3.39597 0 3.69504V15.4686C0 16.0186 0.446523 16.2935 1.00909 16.0993C13.0501 11.8788 25.3357 14.2854 37.3818 16.5811C37.7099 16.3996 38.0789 16.3049 38.4539 16.3061H43.7239C44.2396 16.3065 44.7394 16.4845 45.1392 16.8103C45.539 17.1361 45.8143 17.5897 45.9187 18.0948C46.8117 18.2184 47.9873 18.3748 48.6937 18.4656C48.6983 17.8917 48.9294 17.3428 49.3367 16.9384C49.7439 16.534 50.2944 16.3068 50.8683 16.3061H56.2694C56.8462 16.3075 57.3989 16.5374 57.8065 16.9454C58.2141 17.3535 58.4434 17.9065 58.444 18.4833V19.172C59.5338 19.1518 60.9466 19.0635 62.0263 18.9802L62.5308 17.6936C62.6878 17.2856 62.9646 16.9347 63.3248 16.687C63.6851 16.4393 64.1119 16.3066 64.549 16.3061H69.9477C70.2698 16.3076 70.5876 16.3806 70.8782 16.5197C71.1687 16.6589 71.4247 16.8608 71.6278 17.1109C71.9684 17.0176 72.5284 16.8788 72.6899 16.8233C72.9678 16.7148 73.209 16.5293 73.3851 16.2884C73.5612 16.0475 73.6648 15.7613 73.6838 15.4636V3.69504C73.6889 3.1476 73.2449 2.87263 72.6949 3.06435Z"
      fill="#00A0DF"/>
    <path
      d="M104.501 19.1562H100.961C100.756 19.1602 100.56 19.2454 100.417 19.3933C100.274 19.5413 100.196 19.7398 100.199 19.9454V42.5857C100.197 42.6977 100.219 42.8089 100.263 42.9115C100.308 43.0141 100.375 43.1057 100.459 43.1799C100.542 43.2542 100.642 43.3092 100.749 43.3411C100.856 43.3731 100.969 43.3812 101.08 43.365L104.62 42.7886C104.802 42.7563 104.967 42.6601 105.085 42.5173C105.203 42.3745 105.266 42.1945 105.263 42.0093V19.9454C105.267 19.7398 105.188 19.5413 105.045 19.3933C104.903 19.2454 104.707 19.1602 104.501 19.1562Z"
      fill="#17202C"/>
    <path
      d="M96.2437 19.082H84.7182C84.6165 19.084 84.5162 19.1059 84.423 19.1466C84.3298 19.1873 84.2455 19.246 84.175 19.3193C84.1044 19.3926 84.049 19.479 84.0119 19.5737C83.9748 19.6684 83.9567 19.7695 83.9587 19.8712V23.2999C83.9562 23.4056 83.9755 23.5106 84.0154 23.6085C84.0553 23.7064 84.115 23.795 84.1906 23.8688C84.2663 23.9427 84.3564 24.0001 84.4552 24.0375C84.5541 24.075 84.6596 24.0917 84.7652 24.0866L86.9718 23.953C87.0771 23.9486 87.1822 23.9658 87.2806 24.0035C87.379 24.0412 87.4686 24.0987 87.5439 24.1724C87.6192 24.2462 87.6787 24.3345 87.7186 24.432C87.7584 24.5296 87.7779 24.6343 87.7758 24.7396V46.4399C87.7718 46.5649 87.7986 46.689 87.8537 46.8012C87.9088 46.9134 87.9907 47.0104 88.092 47.0837C88.1933 47.1569 88.3111 47.2042 88.435 47.2214C88.5588 47.2385 88.685 47.225 88.8024 47.1821L92.3425 45.8091C92.4904 45.75 92.6168 45.6474 92.7052 45.5149C92.7935 45.3824 92.8396 45.2262 92.8372 45.067V24.3587C92.8342 24.1604 92.907 23.9686 93.0409 23.8223C93.1747 23.676 93.3594 23.5865 93.5571 23.572L96.2783 23.4186C96.4762 23.4035 96.6609 23.3135 96.7947 23.1668C96.9285 23.0201 97.0013 22.828 96.9982 22.6295V19.8712C97.0015 19.6668 96.9242 19.4694 96.783 19.3217C96.6417 19.174 96.448 19.0879 96.2437 19.082Z"
      fill="#17202C"/>
    <path
      d="M121.039 19.082H109.53C109.429 19.084 109.328 19.1059 109.235 19.1466C109.142 19.1873 109.058 19.246 108.987 19.3193C108.917 19.3926 108.861 19.479 108.824 19.5737C108.787 19.6684 108.769 19.7695 108.771 19.8712V22.2361C108.769 22.3382 108.787 22.4397 108.825 22.5347C108.862 22.6297 108.918 22.7164 108.989 22.7898C109.06 22.8631 109.145 22.9217 109.239 22.9621C109.332 23.0025 109.433 23.024 109.535 23.0253L111.965 23.0104C112.067 23.0117 112.168 23.0332 112.261 23.0736C112.355 23.114 112.44 23.1726 112.511 23.246C112.582 23.3193 112.638 23.406 112.675 23.501C112.713 23.596 112.731 23.6975 112.729 23.7996V41.146C112.726 41.3516 112.804 41.5501 112.947 41.698C113.09 41.846 113.285 41.9312 113.491 41.9351H117.034C117.239 41.9306 117.434 41.845 117.576 41.6972C117.718 41.5493 117.796 41.3512 117.793 41.146V23.7501C117.79 23.5454 117.867 23.3476 118.009 23.1998C118.151 23.052 118.345 22.9662 118.55 22.961L121.051 22.9461C121.256 22.9416 121.451 22.856 121.593 22.708C121.735 22.5601 121.812 22.3619 121.808 22.157V19.8712C121.81 19.7688 121.791 19.667 121.753 19.5718C121.715 19.4766 121.659 19.3899 121.588 19.3165C121.516 19.2432 121.431 19.1847 121.337 19.1445C121.242 19.1042 121.141 19.083 121.039 19.082Z"
      fill="#17202C"/>
    <path
      d="M130.172 38.2516V19.8712C130.174 19.7695 130.156 19.6684 130.119 19.5737C130.082 19.479 130.026 19.3926 129.956 19.3193C129.885 19.246 129.801 19.1873 129.708 19.1466C129.615 19.1059 129.514 19.084 129.413 19.082H125.87C125.768 19.084 125.668 19.1059 125.575 19.1466C125.482 19.1873 125.397 19.246 125.327 19.3193C125.256 19.3926 125.201 19.479 125.164 19.5737C125.127 19.6684 125.109 19.7695 125.111 19.8712V41.4478C125.107 41.6417 125.176 41.83 125.304 41.9756C125.432 42.1211 125.61 42.2135 125.803 42.2345C128.835 42.5302 131.84 43.0578 134.791 43.8127C134.905 43.8388 135.023 43.8385 135.137 43.8118C135.25 43.7851 135.357 43.7327 135.447 43.6588C135.538 43.5848 135.61 43.4911 135.659 43.3849C135.707 43.2786 135.731 43.1627 135.728 43.0459V40.483C135.731 40.3033 135.672 40.128 135.561 39.9869C135.449 39.8457 135.293 39.7475 135.117 39.7087C134.053 39.4885 132.441 39.2486 130.837 39.0333C130.651 39.0058 130.48 38.9113 130.358 38.7675C130.235 38.6238 130.169 38.4404 130.172 38.2516Z"
      fill="#17202C"/>
    <path
      d="M144.859 35.4117L148.003 36.0524C148.116 36.073 148.231 36.0683 148.342 36.0387C148.452 36.009 148.554 35.9552 148.641 35.881C148.728 35.8069 148.798 35.7143 148.844 35.61C148.891 35.5057 148.914 35.3923 148.911 35.2781V32.0621C148.914 31.8772 148.851 31.6973 148.734 31.5543C148.616 31.4112 148.452 31.3142 148.27 31.2804L144.594 30.6743C144.413 30.6406 144.249 30.5439 144.132 30.4014C144.015 30.2589 143.952 30.0796 143.954 29.8951V24.5813C143.952 24.4758 143.971 24.3711 144.011 24.2734C144.051 24.1757 144.11 24.0872 144.185 24.0133C144.261 23.9394 144.35 23.8817 144.449 23.8437C144.547 23.8056 144.652 23.7881 144.758 23.7922L149.193 24.0569C149.299 24.0613 149.404 24.044 149.503 24.0062C149.602 23.9683 149.691 23.9106 149.767 23.8367C149.843 23.7627 149.902 23.6741 149.942 23.5763C149.982 23.4784 150.002 23.3734 150 23.2677V19.9454C150.003 19.7398 149.925 19.5413 149.782 19.3933C149.639 19.2454 149.443 19.1602 149.238 19.1562H139.652C139.446 19.1602 139.25 19.2454 139.108 19.3933C138.965 19.5413 138.886 19.7398 138.89 19.9454V43.793C138.888 44.0675 138.975 44.3353 139.136 44.5576C139.297 44.78 139.524 44.9454 139.785 45.0299C141.542 45.5741 145.554 46.9001 148.909 48.5996C149.027 48.6561 149.157 48.6813 149.288 48.6728C149.418 48.6643 149.544 48.6224 149.654 48.5511C149.763 48.4798 149.853 48.3815 149.913 48.2656C149.974 48.1498 150.004 48.0203 150 47.8896V44.498C150.001 44.3447 149.958 44.1943 149.875 44.0651C149.793 43.9359 149.674 43.8336 149.535 43.7707C147.888 43.0635 146.205 42.4434 144.493 41.9129C144.336 41.8621 144.199 41.7629 144.103 41.6296C144.006 41.4962 143.953 41.3356 143.954 41.1707V36.1835C143.951 36.0696 143.974 35.9565 144.02 35.8525C144.067 35.7485 144.136 35.6562 144.223 35.5823C144.31 35.5084 144.412 35.4547 144.522 35.4252C144.632 35.3957 144.747 35.3911 144.859 35.4117Z"
      fill="#17202C"/>
    <path
      d="M125.415 45.5898C121.17 45.5898 117.729 48.1577 117.729 53.036C117.729 57.719 121.17 60.4797 125.415 60.4797C129.66 60.4797 133.101 57.9218 133.101 53.036C133.101 47.9647 129.66 45.5898 125.415 45.5898ZM125.452 56.8061C123.431 56.8061 121.793 55.4084 121.793 53.036C121.793 50.5622 123.431 49.2635 125.452 49.2635C127.473 49.2635 129.108 50.4608 129.108 53.036C129.108 55.5098 127.471 56.8061 125.452 56.8061Z"
      fill="#17202C"/>
    <path
      d="M109.216 57.0332C106.844 57.0332 104.922 55.5489 104.922 53.0356C104.922 50.4158 106.844 49.0354 109.216 49.0354C110.99 49.0354 112.512 49.7479 113.174 51.2445L114.137 51.1579L113.986 49.0354L113.865 47.3037L113.768 45.9134H113.026C113.006 45.9958 112.969 46.0728 112.916 46.1389C112.863 46.205 112.796 46.2585 112.719 46.2956C112.643 46.3328 112.56 46.3526 112.475 46.3537C112.39 46.3547 112.307 46.337 112.229 46.3018C111.062 45.8119 109.805 45.5685 108.539 45.5869C104.293 45.5869 100.852 48.1547 100.852 53.0331C100.852 57.716 104.293 60.4768 108.539 60.4768C109.804 60.4945 111.059 60.2415 112.22 59.7346C112.296 59.698 112.38 59.679 112.465 59.6791C112.55 59.6792 112.633 59.6984 112.71 59.7352C112.786 59.772 112.854 59.8255 112.907 59.8918C112.96 59.9581 112.997 60.0354 113.016 60.1181V60.1577H113.758L113.833 59.7816L113.894 58.6635L113.981 57.0382L114.1 54.881L113.155 54.8093C112.494 56.2713 110.98 57.0332 109.216 57.0332Z"
      fill="#17202C"/>
    <path
      d="M98.4898 53.0266C98.4895 52.6487 98.5118 52.2712 98.5566 51.896C98.5676 51.7929 98.5534 51.6887 98.5152 51.5923C98.477 51.4959 98.416 51.4102 98.3375 51.3426C98.2589 51.275 98.1651 51.2274 98.0641 51.204C97.9631 51.1806 97.8579 51.1821 97.7576 51.2083L92.81 52.4972C92.6741 52.5339 92.5541 52.6144 92.4686 52.7262C92.383 52.838 92.3367 52.9749 92.3367 53.1156C92.3367 53.2564 92.383 53.3932 92.4686 53.505C92.5541 53.6168 92.6741 53.6973 92.81 53.7341L97.7848 54.9586C97.8859 54.9838 97.9917 54.9838 98.0928 54.9587C98.1939 54.9336 98.2874 54.8841 98.365 54.8146C98.4426 54.745 98.502 54.6575 98.538 54.5597C98.574 54.4619 98.5855 54.3568 98.5715 54.2536C98.5192 53.8466 98.492 53.4368 98.4898 53.0266Z"
      fill="#00A0DF"/>
    <path
      d="M141.208 52.4934L136.26 51.2046C136.16 51.1782 136.055 51.1767 135.954 51.2001C135.853 51.2234 135.759 51.271 135.681 51.3387C135.602 51.4064 135.542 51.4922 135.504 51.5887C135.466 51.6851 135.452 51.7893 135.463 51.8923C135.506 52.2676 135.528 52.6451 135.528 53.0228C135.528 53.4364 135.502 53.8496 135.449 54.2597C135.435 54.363 135.446 54.4681 135.482 54.5659C135.518 54.6637 135.578 54.7512 135.655 54.8207C135.733 54.8903 135.826 54.9398 135.927 54.9649C136.028 54.99 136.134 54.9899 136.235 54.9648L141.205 53.7476C141.349 53.7178 141.479 53.6392 141.572 53.5252C141.665 53.4112 141.716 53.2687 141.716 53.1215C141.716 52.9744 141.666 52.8317 141.573 52.7173C141.481 52.6029 141.352 52.5239 141.208 52.4934Z"
      fill="#00A0DF"/>
  </svg>;
};
