import { useMemo }           from "react";
import React                 from "react";
import { ReactElement }      from "react";
import { ReactNode }         from "react";
import { Scrollbar }         from "../../../";
import { Box }               from "../../../";
import { ClickAwayListener } from "../../../";
import { Menu }              from "../../../";
import { MenuItem }          from "../../../";
import { Alignment }         from "../../../";
import { Loader }            from "../../..";
import { Popper }            from "../../Popper";
import { Input }             from "../Input";
import { UseAutocomplete }   from "./useAutocomplete";
import { useAutocomplete }   from "./useAutocomplete";

export interface AutocompleteProps extends UseAutocomplete {
  height?: number
  renderOption?: (option) => ReactNode;
  renderInput?: ReactNode
}

const defaultAutocompleteProps = {
  options: [],
  optionKey: "value",
  optionLabel: "label",
  variant: "default",
  disabled: false,
  readOnly: false,
  loading: false,
  color: null
};

Autocomplete.defaultProps = defaultAutocompleteProps;
export function Autocomplete(props: AutocompleteProps) {
  const { renderInput, renderOption, height, ...rest } = props;
  const {
    getInputProps,
    getOptionProps,
    getDefaultOptionProps,
    anchorBounding,
    setAnchorBounding,
    options,
    loading,
    defaultOption
  } = useAutocomplete(rest);
  const relativeHeight = useMemo(() => options?.length > 5 ? `${(height - 4) * 4}px` : null, [options, height]);
  return (
    <ClickAwayListener onClickAway={() => setAnchorBounding(null)}>
      <Box flex={1}>
        {renderInput ? React.cloneElement(renderInput as ReactElement, getInputProps()) : <Input {...getInputProps()}/>}
        <Popper open={!!anchorBounding} anchorBounding={anchorBounding} alignment={Alignment.BottomLeft}
                threshold={12}>
          {
            options.length > 0 ? <Menu
                type={"select"}
                autoFocus={false}
                height={height || "fit-content"}
                role="listbox">
                {!loading && defaultOption &&
                <MenuItem {...getDefaultOptionProps()} />}
                {!loading ?
                  <Scrollbar heightRelativeToParent={relativeHeight}>
                    {
                      (options || []).map((option, index) => {
                        return renderOption ?
                          React.cloneElement(renderOption(option) as ReactElement, getOptionProps(option, index)) :
                          <MenuItem {...getOptionProps(option, index)}/>;
                      })
                    }
                  </Scrollbar>
                  :
                  <Box container justify={"center"}>
                    <Loader variant={"circular"}/>
                  </Box>
                }
              </Menu>
              : <div/>
          }
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
