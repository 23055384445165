import React                 from "react";
import { FC }                from "react";
import { ReactElement }      from "react";
import { Badge }             from "../../../../Badge";
import { BadgeVariants }     from "../../../../Badge";
import { BoxComponentProps } from "../../../../Box";
import { Box }               from "../../../../Box";
import { TypographySize }    from "../../../../Typography";
import { TypographyColor }   from "../../../../Typography";
import { TypographyVariant } from "../../../../Typography";
import { Typography }        from "../../../../Typography";

export interface EmailInfoSecondaryProps extends BoxComponentProps {
  thumbnail?: ReactElement;
  indicate?: boolean;
}

export const EmailInfoSecondary: FC<EmailInfoSecondaryProps> = React.memo(function EmailInfoSecondary(props) {
  const { children, thumbnail, indicate, ...p } = props;
  return <Box container gap={"XXS"} alignItems={"center"} {...p}>
    {thumbnail}
    <Typography variant={TypographyVariant.time} color={TypographyColor.Secondary} size={TypographySize.TextTiny} noWrap={true}>
      {children}
    </Typography>
    {
      indicate && <Badge variant={BadgeVariants.Dot}/>
    }
  </Box>;
});