import { setIn }             from "@relcu/form";
import { CalendarDateProps } from "@relcu/ui";
import { DateTime }          from "luxon";
import { FilterRule }        from "../components/Filter";
import { IField }            from "../types/ISchemas";
import ms                    from "./ms";
import { isDateField }       from "./schemaUtils";
import { isStatusField }     from "./schemaUtils";
import { isPointerField }    from "./schemaUtils";
import { isObjectField }     from "./schemaUtils";
import { isArrayField }      from "./schemaUtils";
import { isBooleanField }    from "./schemaUtils";

const toArray = (v: any) => (Array.isArray(v) ? v : typeof v === "string" ? v.split(",") : []);
const safeStringify = (v: any) => {
  if (typeof v == "object" && v !== null) {
    const str = JSON.stringify(v);
    return str;
    // return str.substring(1, str.length - 1);
  } else {
    return `"${v}"`;
  }
};
const gqlOperators: { [ op: string ]: string } = {
  "=": "equalTo",
  "!=": "notEqualTo",
  "<": "lessThan",
  "<=": "lessThanOrEqualTo",
  ">": "greaterThan",
  ">=": "greaterThanOrEqualTo",
  "in": "in",
  "notIn": "notIn",
  "is null": "notExists",
  "notNull": "exists"

};
function parseOperator(rule) {
  if (rule.operator == "exists") {
    return true;
  } else if (rule.operator == "notExists") {
    return false;
  }
  return rule.value;
}
function applyStatus(where: any, rule: FilterRule, field: string, subField = null) {
  where[ field ] = {
    [ subField || "status" ]: { [ rule.operator == "notExists" ? "exists" : rule.operator ]: parseOperator(rule) }
  };

  // where[ "AND" ].push({
  //   [ field ]: {
  //     status: { [ rule.operator == "notExists" ? "exists" : rule.operator ]: parseOperator(rule) }
  //   }
  // });
}
function applyStartWith(where: any, rule: FilterRule) {
  const value = rule.value.replace(/^[+]/, "[+]");
  where [ rule.field ] = { "matchesRegex": `^${value}`, options: "i" };
  // where[ "AND" ].push({ [ rule.field ]: { "matchesRegex": `(?i)^${value}` } });
}
function applyEndWith(where: any, rule: FilterRule) {
  where[ rule.field ] = { "matchesRegex": `${rule.value}$`, options: "i" };
  // where[ "AND" ].push({ [ rule.field ]: { "matchesRegex": `(?i)${rule.value}$` } });
}
function applyContains(where: any, rule: FilterRule) {
  const value = rule.value.replace(/^[+]/, "[+]");
  where[ rule.field ] = { "matchesRegex": `${value}`, options: "i" };
  // where[ "AND" ].push({ [ rule.field ]: { "matchesRegex": `(?i)${value}` } });
}
function applyDoesNotContain(where: any, rule: FilterRule) {
  const value = rule.value.replace(/^[+]/, "[+]");
  where[ rule.field ] = { "matchesRegex": `^(?!.*${value}).*$` };
  // where[ "AND" ].push({ [ rule.field ]: { "matchesRegex": `^(?!.*${value}).*$` } });
}
function applyOn(where: any, rule: FilterRule) {
  throw new Error("Apply on operator should not be used");
  const start = DateTime.fromJSDate(new Date(rule.value)).set({ hour: 0, minute: 0, second: 0 });
  const end = start.plus({ days: 1 });
  const onDay = {
    greaterThan: start.toJSDate(),
    lessThan: end.toJSDate()
  };
  where[ "AND" ].push({ [ rule.field ]: { ...onDay } });
}
function applyBoolean(where: any, rule: FilterRule) {
  if (rule.operator == "notExists") {
    where[ rule.field ] = { equalTo: null };
  } else if (rule.operator == "exists") {
    where[ rule.field ] = { notEqualTo: null };
  } else {
    where[ rule.field ] = { [ rule.operator ]: rule.value };
  }
  // where[ "AND" ].push({ [ rule.field ]: { [ rule.operator == "notExists" ? "notEqualTo" : "equalTo" ]: true } });
}
function parse(rule, getField: (field: string, s: string) => any, parentPath?: string) {
  if (rule.value?._now || rule.value?.$now) {
    //relative date handling, calendar dated are handled separately bellow
    if (/^([+\-])/.test(rule.value._now || rule.value?.$now)) {
      rule.value = new Date(Date.now() + ms(rule.value._now || rule.value.$now));
    }
  }
  if (rule.value?._date || rule.value?.$date) {
    rule.value = new Date(rule.value._date || rule.value.$date);
  }
  const schemaField = parentPath ? `${parentPath}.${rule.field}` : rule.field;
  let { field, path, resolvedBy } = getField(schemaField, parentPath);
  if (parentPath) {
    resolvedBy = resolvedBy.replace(`${parentPath}.`, "");
    path = path.replace(`${parentPath}.`, "");
  }

  let where = {};
  if (isStatusField(field) && !(rule.field === "leadStatus.updatedAt" && ["during", "notDuring"].includes(rule.operator))) {
    const field = rule.field.split(".")[ 0 ];
    const subField = rule.field.split(".")[ 1 ];
    if (subField == "status" || subField == "action" || subField == "actionCount" || subField == "currentStatusActionCount" || subField == "currentStageActionCount" || subField == "updatedAt") {
      applyStatus(where, rule, field, subField);
    } else {
      applyStatus(where, rule, field);
    }
  } else if (isArrayField(field) && !["String", "Number", "Boolean"].includes(field.targetClass as string)) {

    where[ resolvedBy ] = {
      [ "have" ]: {
        ...where[ resolvedBy ],
        ...parse({ field: path, operator: rule.operator, value: rule.value }, getField, resolvedBy)
      }
    };
  } else if (isPointerField(field)) {
    if (["in", "notIn"].includes(rule.operator)) {
      const schema = field;
      if (Array.isArray(schema.targetClass)) {
        where[ rule.operator === "in" ? "OR" : "AND" ] = [
          ...rule.value.map?.((value) => {
            return {
              [ resolvedBy ]: {
                [ rule.operator === "in" ? "have" : "haveNot" ]: {
                  link: value
                }
              }
            };
          })
        ];
        // where[ "AND" ].push({
        //   [ rule.operator === "in" ? "OR" : "AND" ]: [
        //     ...rule.value.map?.((value) => {
        //       return {
        //         [ resolvedBy]: {
        //           [ rule.operator === "in" ? "have" : "haveNot" ]: {
        //             link: value
        //           }
        //         }
        //       };
        //     })
        //   ]
        // });
      } else {

        where[ resolvedBy ] = {
          [ "have" ]: {
            ...where[ resolvedBy ],
            ...parse({ field: resolvedBy == path ? "objectId" : path, operator: rule.operator, value: rule.value }, getField)
          }
        };
        // where[ "AND" ].push({
        //   [ resolvedBy ]: {
        //     [ rule.operator === "in" ? "have" : "haveNot" ]: {
        //       ...where[ "AND" ][ resolvedBy ],
        //       ...parse({ field: path, operator: rule.operator, value: rule.value }, getField)
        //     }
        //   }
        // });
      }
    } else if (resolvedBy == path) {

      where = setIn(where, rule.field, { [ rule.operator == "notExists" ? "exists" : rule.operator ]: (rule.operator == "exists" ? true : (rule.operator == "notExists" ? false : rule.value)) });
      // where[ "AND" ].push(setIn({}, rule.field, { [ rule.operator == "notExists" ? "exists" : rule.operator ]: (rule.operator == "exists" ? true : (rule.operator == "notExists" ? false : rule.value)) }));
    } else {
      where[ resolvedBy ] = {
        [ "have" ]: {
          ...where[ resolvedBy ],
          ...parse({ field: path, operator: rule.operator, value: rule.value }, getField)
        }
      };
    }
  } else if (isBooleanField(field)) {
    if (rule.field.includes(".")) {
      const [parentField, childField] = rule.field.split(/[.[\]]+/).filter(Boolean);

      if (isArrayField(field)) {
        where[ parentField ] = {
          [ rule.operator === "notExists" ? "haveNot" : "have" ]: {
            [ childField ]: {
              equalTo: true
            }
          }
        };
        // where.AND.push({
        //   [ parentField ]: {
        //     [ rule.operator === "notExists" ? "haveNot" : "have" ]: {
        //       [ childField ]: {
        //         equalTo: true
        //       }
        //     }
        //   }
        // });
      }
    } else {
      applyBoolean(where, rule);
    }
  } else if (isObjectField(field)) {
    where[ resolvedBy ] = {
      ...where[ resolvedBy ],
      ...parse({ field: path, operator: rule.operator, value: rule.value }, getField, resolvedBy)
    };
    // where[ "AND" ].push({
    //   [ resolvedBy ]: {
    //     ...where[ "AND" ][ resolvedBy ],
    //     ...parse({ field: path, operator: rule.operator, value: rule.value }, getField, resolvedBy)
    //   }
    // });
  } else if ((isDateField(field) || rule.field === "leadStatus.updatedAt") && ["during", "notDuring"].includes(rule.operator)) {
    //calendar operators
    const calendarValue: CalendarDateProps["value"] = rule.value._now || rule.value?.$now;
    let now = DateTime.now();
    let min: Date;
    let max: Date;
    switch (calendarValue) {
      case "today":
        min = now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "yesterday":
        now = now.minus({ day: 1 });
        min = now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "tomorrow":
        now = now.plus({ day: 1 });
        min = now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "week":
        min = now.set({ weekday: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ weekday: 7, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "last_week":
        now = now.minus({ week: 1 });
        min = now.set({ weekday: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ weekday: 7, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "next_week":
        now = now.plus({ week: 1 });
        min = now.set({ weekday: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ weekday: 7, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "month":
        min = now.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ day: now.daysInMonth, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "last_month":
        now = now.minus({ month: 1 });
        const daysInLastMonth = now.daysInMonth;
        min = now.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ day: daysInLastMonth, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "next_month":
        now = now.plus({ month: 1 });
        const daysInNextMonth = now.daysInMonth;
        min = now.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ day: daysInNextMonth, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "year":
        min = now.set({ month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ month: 12, day: 31, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "last_year":
        now = now.minus({ year: 1 });
        min = now.set({ month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ month: 12, day: 31, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
      case "next_year":
        now = now.plus({ year: 1 });
        min = now.set({ month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        max = now.set({ month: 12, day: 31, hour: 24, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        break;
    }

    if (rule.operator == "during") {
      where = setIn(where, rule.field, {
        greaterThan: min,
        lessThan: max
      });
    } else {
      if (rule.field === "leadStatus.updatedAt") {
        const field = rule.field.split(".")[ 0 ];
        const subField = rule.field.split(".")[ 1 ];
        where = setIn(where, "OR", [
          { [ field ]: { [ subField ]: { greaterThan: max } } },
          { [ field ]: { [ subField ]: { lessThan: min } } }
        ]);
      } else {
        where = setIn(where, "OR", [
          { [ rule.field ]: { greaterThan: max } },
          { [ rule.field ]: { lessThan: min } }
        ]);
      }
    }

  } else {
    if (["exists", "notExists"].includes(rule.operator)) {
      if (rule.field === "tags") {
        where[ rule.field ] = { [ rule.operator == "notExists" ? "equalTo" : "notEqualTo" ]: [] };
        // where[ "AND" ].push({ [ rule.field ]: { [ rule.operator == "notExists" ? "equalTo" : "notEqualTo" ]: [] } });
      } else {
        where = setIn(where, rule.field, { [ rule.operator == "notExists" ? "equalTo" : "notEqualTo" ]: null });
        // where[ "AND" ].push(setIn({}, rule.field, { [ rule.operator == "notExists" ? "equalTo" : "notEqualTo" ]: null }));
      }
    } else if (rule.operator === "on") {
      applyOn(where, rule);
    } else if (rule.operator == "beginsWith") {
      applyStartWith(where, rule);
    } else if (rule.operator == "endsWith") {
      applyEndWith(where, rule);
    } else if (rule.operator == "contains") {
      applyContains(where, rule);
    } else if (rule.operator == "doesNotContain") {
      applyDoesNotContain(where, rule);
    } else {
      where = setIn(where, rule.field, { [ rule.operator ]: rule.value });
      // where[ "AND" ].push(setIn({}, rule.field, { [ rule.operator ]: rule.value }));
    }
  }
  return where;
  // if (Object.keys(where).length == 0) {
  //   delete where[ "AND" ];
  // }//todo check how to check if rules is empty
  // return where[ "AND" ][ 0 ];
}
export function toGqlQuery(ruleGroup: RuleGroupTypeAny, className: string, getField: (className, path: string) => IField) {
  const gqlCombinators: { [ op: string ]: string } = {
    "and": "AND",
    "or": "OR"
  };
  const resolver = (field, skip) => {
    const paths = field.split(".");
    let fieldType: IField;
    for (let i = 0; i < paths.length; i++) {
      let path = Array(i + 1).fill(undefined).map((a, i) => paths[ i ]).join(".");
      if (path == skip) {
        continue;
      }
      fieldType = getField(className, path);
      if (isPointerField(fieldType) || isObjectField(fieldType) || isArrayField(fieldType)) {
        return { resolvedBy: path, path: field.replace(`${path}.`, ""), field: fieldType };
      }
    }
    return { resolvedBy: field, path: field, field: fieldType };
  };
  let fallbackExpression = "";
  if (!fallbackExpression) {
    //fallbackExpression = "\"$and\":[{\"$expr\":true}]";
  }
  const processRuleGroup = (rg) => {
    const combinator = `"${gqlCombinators[ rg.combinator ]}"`;
    const expression: string = rg.rules.map(rule => {
      if ("rules" in rule) {
        const processedRuleGroup = processRuleGroup(rule);
        return processedRuleGroup ? `{${processedRuleGroup}}` : "";
      }
      return JSON.stringify(parse({
        field: rule.field,
        operator: gqlOperators[ rule.operator ] ?? rule.operator,
        value: rule.value
      }, resolver));
    }).filter(Boolean).join(",");

    return expression ? `${combinator}:[${expression}]` : fallbackExpression;
  };
  // "mongodb" export type does not currently support independent combinators
  if ("combinator" in ruleGroup) {
    return `{${processRuleGroup(ruleGroup)}}`;
  }
  return `{${fallbackExpression}}`;
}

type RuleGroupType<R extends RuleType = RuleType,
  C extends string = string> = CommonProperties & {
  combinator: C;
  rules: RuleGroupArray<RuleGroupType<R, C>, R>;
  not?: boolean;
};

type RuleGroupArray<RG extends RuleGroupType = RuleGroupType,
  R extends RuleType = RuleType> = (R | RG)[];

type RuleType<F extends string = string,
  O extends string = string,
  V = any> = CommonProperties & {
  field?: F;
  operator?: O;
  value?: V;
  valueSource?: ValueSource;
};
interface CommonProperties {
  path?: number[];
  id?: string;
  disabled?: boolean;
}
export type SchemaResolver = (field: string, skipPath: string) => { resolvedBy: string, path: string, field: IField }
type ValueSource = "value" | "field";
export type RuleGroupTypeAny = RuleGroupType | RuleGroupTypeIC;
type RuleGroupTypeIC<R extends RuleType = RuleType, C extends string = string> = Omit<RuleGroupType<R, C>,
  "combinator" | "rules"> & {
  rules: RuleGroupICArray<RuleGroupTypeIC<R, C>, R, C>;
};
type RuleGroupICArray<RG extends RuleGroupTypeIC = RuleGroupTypeIC,
  R extends RuleType = RuleType,
  C extends string = string> = [R | RG] | [R | RG, ...MappedTuple<[C, R | RG]>] | ((R | RG)[] & { length: 0 });
type MAXIMUM_ALLOWED_BOUNDARY = 80;
type MappedTuple<Tuple extends Array<unknown>,
  Result extends Array<unknown> = [],
  Count extends ReadonlyArray<number> = []> = Count["length"] extends MAXIMUM_ALLOWED_BOUNDARY
  ? Result
  : Tuple extends []
    ? []
    : Result extends []
      ? MappedTuple<Tuple, Tuple, [...Count, 1]>
      : MappedTuple<Tuple, Result | [...Result, ...Tuple], [...Count, 1]>




