import { gql }              from "@apollo/client";
import { useQuery }         from "@apollo/client";
import { useConstant }      from "@relcu/ui";
import { useLazyCondition } from "@relcu/ui";
import { useMemo }          from "react";
import { layoutVar }        from "../../reactiveVars";
import { LayoutViewer }     from "./__types__/LayoutViewer";

const USER = gql`
  query LayoutViewer {
    viewer {
      user {
        id
        objectId
        role
      }
    }
  }
`;
export function useLayout() {
  const { data: { viewer: { user: $viewer } } } = useQuery<LayoutViewer>(USER, {
    fetchPolicy: "cache-only"
  });
  const layouts = useConstant(() => Object.values(layoutVar()));
  const evaluate = useLazyCondition({ source: { $viewer } });
  const api = {
    get routes() {
      return layouts.filter(layout => !!layout.route
        && (!layout.conditions || evaluate({ conditions: layout.conditions }).apply));
    }
  };
  return {
    $viewer,
    ...api,
    routes: useMemo(() => api.routes, [layouts, evaluate])
  };
}
