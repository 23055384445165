import React                 from "react";
import { FC }                from "react";
import { classNames }        from "../../utils/classNames";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";
import { StepClasses }       from "./StepClasses";

export const StepRow: FC<BoxComponentProps> = React.memo(function StepRow(props) {
  const { className, ...p } = props;
  const classes = classNames(StepClasses.StepRow, className);
  return <Box container className={classes} alignItems={"center"} {...p}/>;
});
