import React                                   from "react";
import { FC }                                  from "react";
import { useMemo }                             from "react";
import { useFragment }                         from "@apollo/client";
import { useParams }                           from "@relcu/react-router";
import { toNodeId }                            from "../../../../../utils/helpers";
import { EmptyConversation }                   from "../../../../EmptyConversation/EmptyConversation";
import { PhoneMessageConversation }            from "../__types__/PhoneMessageConversation";
import { PHONE_MESSAGE_CONVERSATION_FRAGMENT } from "../ConversationView";
import { BulkItemContent }                     from "./BulkItemContent/BulkItemContent";
import { PhoneMessages }                       from "./Messages/PhoneMessages";
import "./conversation-content.css";

export const ConversationContent: FC<any> = React.memo(function ConversationContent(props) {
  const params = useParams();
  const objectId = params[ "conversationId" ];
  const nearBy = params[ "nearBy" ];
  const { data: conversation = {} } = useFragment<PhoneMessageConversation>({
    fragment: PHONE_MESSAGE_CONVERSATION_FRAGMENT,
    fragmentName: "PhoneMessageConversation",
    from: {
      __typename: "PhoneMessageConversation",
      id: toNodeId({ className: "PhoneMessageConversation", objectId })
    }
  });
  const contact = useMemo(() => conversation?.participants?.find((p) => p.party.__typename == "Contact")?.party, [conversation?.participants]);

  return (
    conversation?.type == "bulk" ?
      <BulkItemContent key={conversation.objectId} conversation={conversation}/> :
      contact ?
        <PhoneMessages key={conversation.objectId} conversation={conversation} nearBy={nearBy}/>
        :
        <EmptyConversation icon={"forum"} text={"Select conversation to see messages."}/>
  );
});
