import { XYCoord }              from "dnd-core";
import { Identifier }           from "dnd-core";
import { useContext }           from "react";
import { useRef }               from "react";
import React                    from "react";
import { useDrag }              from "react-dnd";
import { useDrop }              from "react-dnd";
import { CommonClasses }        from "../../..";
import { FontIcon }             from "../../..";
import { classNames }           from "../../..";
import { BoxComponentProps }    from "../../..";
import { Box }                  from "../../..";
import { ListDraggableContext } from "./ListDraggableContext";

export enum ListRowColors {
  White = "white",
  Grey = "grey"
}

export interface ListRowProps extends BoxComponentProps {
  active?: boolean
  outlined?: boolean
  color?: ListRowColors
  index?: number
}

export const ListRow = React.forwardRef(function ListRow(props: ListRowProps, ref?: React.Ref<HTMLDivElement>) {
  const { className, children, active, color, outlined, ...p } = props;
  const classes = classNames(ListRowClasses.ListRow, {
    [ ListRowClasses.ListActiveRow ]: active,
    [ ListRowClasses.ListGreyRow ]: color == ListRowColors.Grey,
    [ ListRowClasses.ListOutlinedRow ]: outlined,
    [ ListRowClasses.ListWhiteRow ]: color == ListRowColors.White
  }, className);

  return <Box alignItems={"center"} container className={classes} tabIndex={0} {...p} ref={ref}>
    {children}
  </Box>;
});

export const DraggableListRow = React.memo(function DraggableListRow(props: ListRowProps) {
  const context = useContext(ListDraggableContext);
  const dndRef = useRef<HTMLDivElement>(null);
  // const dragRef = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<ListDragItem,
    void,
    { handlerId: Identifier | null }>({
    accept: "queue",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: ListDragItem, monitor) {
      if (!dndRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dndRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      context.onRowMove?.(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "queue",
    item: () => {
      return { index: props.index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    end: () => context.onRowDrop?.()
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(dndRef));
  return <ListRow {...props} style={{ opacity, "WebkitUserDrag": "element" } as any} ref={dndRef}
                  data-handler-id={handlerId}>
    <FontIcon type={"drag_indicator"}
              style={{ fontSize: "var(--typography-icon-size)", cursor: "move", flex: "0 0 10px" }}
              className={classNames(CommonClasses.GrayIcon)}/>{/*ref={dragRef}*/}
    {props.children}
  </ListRow>;
});
ListRow.defaultProps = {
  active: false,
  color: ListRowColors.Grey
};

export enum ListRowClasses {
  ListRow = "list-row",
  ListActiveRow = "list-row--active",
  ListGreyRow = "list-row--grey",
  ListWhiteRow = "list-row--white",
  ListOutlinedRow = "list-row--outlined",
  Clickable = "list-row--clickable",
  ActionSticky = "list-row-action-sticky"
}

export interface ListDragItem {
  index: number;
  id: string;
  type: string;
}
export function withDrag(ListRow) {
  return (props: ListRowProps) => {

  };
}
