import { TypePolicy }      from "@apollo/client";
import { MemberOwnFields } from "../../graph/__types__/MemberOwnFields";
import { Root }            from "../../graph/__types__/Root";
import { ROOT }            from "../../graph/operations.graphql";
import { schemaVar }       from "../../reactiveVars";
import { leadPricingVar }  from "../../reactiveVars";
import { getNullValues }   from "../../utils/schemaUtils";

export const Lead: TypePolicy = {
  fields: {
    notifications: {
      read(exising, { readField, cache }) {
        const id: string = readField("id");
        const { notifications } = cache.readQuery<Root>({ query: ROOT });
        const leadNotifications = notifications.edges.filter(({ node }) => node.scope?.id == id).map((n) => n.node);
        return leadNotifications.length;
      }
    },
    leadStatus: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      }
    },
    pricing: {
      read(existing, { readField, cache, storage }) {
        const id: string = readField("id");
        const pricing = leadPricingVar();
        return (pricing && pricing[ id ]) ? pricing[ id ] : { lockindays: "45", view: "2", investorId: "*", productList: "4" };
      }

    },
    tags: {
      merge(existing, incoming, { mergeObjects, storage }) {
        return incoming ?? existing;
      }
    },
    mortech: {
      read(existing, { storage, cache }) {
        if (!storage.defaultValue) {
          const schemas = schemaVar();
          storage.defaultValue = getNullValues(schemas, "Mortech");
        }
        return existing || storage.defaultValue;
      }
    },
    members: {
      read(existing, { storage, cache }) {
        if (!storage.defaultValue) {
          const schemas = schemaVar()
          storage.defaultValue = [getNullValues(schemas, "LeadMember")];
        }
        return existing || storage.defaultValue;
      }
    },
    property: {
      read(existing, { storage, cache }) {
        if (!storage.defaultValue) {
          const schemas = schemaVar();
          storage.defaultValue = getNullValues(schemas, "Property");
        }
        return existing || storage.defaultValue;
      }
    },
    readyToPullPricing: {
      read(existing, { readField, cache }) {
        //todo
        const members = readField<MemberOwnFields[]>("members") ?? [];
        const loanPurpose = readField("loanPurpose") ?? null;
        const downPayment = readField("downPayment") ?? null;
        const loanAmount = readField("loanAmount") ?? null;
        const waiveEscrow = readField("waiveEscrow") ?? null;
        const cltv = readField("cltv") ?? null;
        const ltv = readField("ltv") ?? null;
        const secondaryFinancing = readField("secondaryFinancing") ?? null;
        const firstTimeHomeBuyer = readField("firstTimeHomeBuyer") ?? null;
        const property = readField<any>("property");
        const type = property?.type || null;
        const value = property?.value || null;
        const use = property?.use || null;
        const propertyAddress = property?.propertyAddress;
        const state = propertyAddress?.state || null;
        const county = propertyAddress?.county || null;
        // const { type = null, value = null, use = null, propertyAddress: { state = null, county = null } = {} } = readField<Property>("property");
        const creditScores = members.filter((b) => ["borrower", "co_borrower"].includes(b.type))
        .map((borrower) => ({ creditScore: borrower.creditScore }));
        let hasCreditScores = false;
        for (let score of creditScores) {
          hasCreditScores = hasCreditScores && score[ "creditScore" ];
        }
        return (
          loanPurpose != null && loanAmount != null &&
          downPayment != null && ltv != null && cltv != null && secondaryFinancing != null &&
          waiveEscrow != null && firstTimeHomeBuyer != null &&
          hasCreditScores != null && type != null && value != null && use != null && state != null && county != null
        );
      }
    },
    dti: {
      read(existing) {
        return existing ?? 30;
      }
    }
  }
};
export const LeadStat: TypePolicy = {
  fields: {
    unreadMessagesCount: {
      read(existing) {
        return existing ?? (existing === -1 ? 0 : existing);
      }
    },
    missedCallsCount: {
      read(existing) {
        return existing ?? (existing === -1 ? 0 : existing);
      }
    },
    unreadEmailsCount: {
      read(existing) {
        return existing ?? (existing === -1 ? 0 : existing);
      }
    }
  }
};
