import React                  from "react";
import { FC }                 from "react";
import { ButtonColors }       from "@relcu/ui";
import { Box }                from "@relcu/ui";
import { HorizontalDivider }  from "@relcu/ui";
import { ListView }           from "@relcu/ui";
import { useMailIntegration } from "./useMailIntegration";
import "./mail-integration.css";

export const MailIntegrationView: FC = React.memo(function MailIntegrationView(props) {
  const api = useMailIntegration(props);
  return <Box direction={"column"} container className={"mail-integration"}>
    {api.modalContext}
    {api.accountModalContext}
    <ListView
      getColumnValue={api.getColumnValue}
      createAction={{
        onClick(event: Event, data: any) {
          return api.onCreateDomain();
        }
      }}
      actions={[
        {
          icon: "create",
          color: ButtonColors.White,
          onClick(event: Event, data: any) {
            return api.onEdit(data);

          }
        }
      ]}
      title={"Domain(s)"}
      name={"Domain"}
      loading={api.loading}
      currentPage={0}
      onRowClick={undefined}
      onPage={() => {
      }}
      headers={[
        {
          title: "Name",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "200px"
        },
        {
          title: "Status",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "200px"
        },
        {
          title: "Disabled",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "145px"
        },
        {
          title: "Relcu email",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "145px"
        },
        {
          title: "Date created",
          flexBasis: "80px",
          flexShrink: 0
        }
      ]}
      fields={[
        {
          name: "domain",
          component: "ListItemTitle",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "200px"
        },
        {
          name: "verified",
          component: "ListItemChips",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "200px"
        },
        {
          name: "disabled",
          component: "ListItemSwitch",
          flexGrow: 0,
          flexShrink: 1,
          label: "Disabled",
          flexBasis: "145px"
        },
        {
          name: "relcuEmail",
          component: "ListItemSwitch",
          flexGrow: 0,
          flexShrink: 1,
          label: "Relcu email",
          flexBasis: "145px"
        },
        {
          name: "createdAt",
          component: "ListItemDate",
          label: "Date created",
          properties: {
            format: "withTime"
          },
          flexBasis: "80px",
          flexShrink: 0
        }
      ]}
      offset={api.domains.length}
      count={api.domains.length}
      total={api.domains.length}
      data={api.domains}
      blank={{
        title: "No domains added",
        content: "To enable the email integration add the domain your company uses for emails. Click on \"Add domain\" to proceed.",
        action: "ADD DOMAIN",
        icon: "rc_email_integration"
      }}
    />

    {!!api.domains.length && <>
      <HorizontalDivider bold/>
      <ListView
        getColumnValue={api.getColumnValue}
        createAction={{
          onClick(event: Event, data: any) {
            return api.onCreateAccount();
          }
        }}
        actions={(data) => {
          if (data.auth?.expiresAt) {
            return [];
          }
          return [
            {
              icon: "create",
              color: ButtonColors.White,
              onClick(event: Event, data: any) {
                return api.onEditAccount(data);
              }
            }
          ];
        }}
        title={"Accounts"}
        name={"account"}
        loading={api.loading}
        currentPage={api.mailboxCurrentPage}
        onRowClick={undefined}
        onPage={api.onPage}
        headers={api.mailboxHeaders}
        fields={api.mailboxFields}
        offset={api.mailboxOffset}
        count={api.mailboxCount}
        total={api.totalMailboxes}
        data={api.mailboxes}
        blank={{
          title: "No accounts found",
          content: "Click on \"Add account\" button to create an account.",
          action: "ADD ACCOUNT"
        }}
      />
    </>
    }
  </Box>;
});
