import { TypePolicy } from "@apollo/client";

export const Filters: TypePolicy = {
  fields: {
    fields: {
      read(existing) {
        return existing || [];
      }
    },
    rules: {
      read(existing) {
        return existing || [];
      }
    }
  }
};
