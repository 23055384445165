import React               from "react";
import { FC }              from "react";
import { Alignment }       from "../../../../constants/Alignemnt";
import { BadgeColors }     from "../../../Badge";
import { BadgeVariants }   from "../../../Badge";
import { Badge }           from "../../../Badge";
import { Box }             from "../../../Box";
import { ChipsSizes }      from "../../../Chips";
import { ChipsColors }     from "../../../Chips";
import { Chips }           from "../../../Chips";
import { ItemsEllipsis }   from "../../../Ellipsis";
import { FontIcon }        from "../../../Icon";
import { TypographyColor } from "../../../Typography";
import { TypographySize }  from "../../../Typography";
import { Typography }      from "../../../Typography";
import { ICellProps }      from "../ICellProps";

export interface TagsCellProps extends ICellProps {
  showLength: number,
  size?: ChipsSizes,
  isTag?: boolean
}
export const ChipsCell: FC<TagsCellProps> = React.memo(function ChipsCell({ rowData, dataKey, className, defaultValue, getCellValue, showLength, size = ChipsSizes.Medium, isTag = false, ...props }) {
  const value = getCellValue(rowData, dataKey) ?? [];

  return <Box className={className}>
    {value.length ?
        <ItemsEllipsis
            wrap={"nowrap"}
            showLength={showLength}
            items={value}
            holdup={false}
            alignment={props.rowIndex < 2 ? Alignment.Bottom : Alignment.Top}
            renderItem={
              (item, index) => <Chips
                  length={7}
                  key={index}
                  color={ChipsColors.Grey}
                  label={item}
                  size={size}
                  thumbnail={
                    isTag ?
                        <Badge variant={BadgeVariants.Dot} color={BadgeColors.Primary}/> :
                        item.icon ?
                            <FontIcon type={item.icon}/> :
                            null
                  }
              />
            }
            ellipsis={(count) =>
                <Typography
                    size={size == ChipsSizes.Medium ? TypographySize.Text : TypographySize.TextSmall}
                    color={TypographyColor.Secondary}
                    style={{ cursor: "pointer" }}
                >
                  {`+${count}`}
                </Typography>
            }
        />:
        <Typography
            size={TypographySize.Text}
            color={TypographyColor.Secondary}
        >
          {defaultValue ?? ""}
        </Typography>
    }

  </Box>;
});
ChipsCell.defaultProps = {
  showLength: 2
};