import { useMemo }   from "react";
import { checkType } from "../../../utils/helpers";

export function useChoiceLabel(value, options, optionKey, optionLabel) {
  const choice = useChoice(value, options, optionKey, optionLabel);
  return checkType(choice, optionLabel);
}

export function useChoice(choice, options, optionKey, optionLabel) {
  const selected = useMemo(() => typeof choice == "object" ? choice[ optionKey ] : choice, [choice, optionKey]);
  return useMemo(() => (options || []).find(o => typeof o == "object" ? o[ optionKey ] == selected : o == selected), [options, optionKey, selected, optionLabel]);
}

