import React            from "react";
import { FC }           from "react";
import { Stack }        from "@relcu/rc";
import { DialListItem } from "../../../DialList/DialListItem";

export interface CallControllersProps {
  onSelect(state: "conference" | "transfer");
}

export const CallControllers: FC<CallControllersProps> = React.memo(function CallControllers(props) {
  return <Stack direction={"column"} spacing={8} alignItems={"stretch"}>
    <DialListItem onClick={() => props.onSelect("transfer")} icon={"keyboard_arrow_right"}>
      Warm transfer
    </DialListItem>
    {/*<DialListItem onClick={() => props.onSelect("conference")}>Conference call</DialListItem>*/}
  </Stack>;
});
