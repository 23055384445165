import { useQuery }                          from "@apollo/client";
import { useReactiveVar }                    from "@apollo/client";
import { PhoneMessageTemplateWhereInput }    from "../../../../types/graphql-global-types";
import { isEmpty }                           from "../../../../utils/helpers";
import { messageDraftsVar }                  from "../../../../reactiveVars";
import { GetPhoneMessageTemplatesVariables } from "../PhoneMessageTemplateView/__types__/GetPhoneMessageTemplates";
import { GetPhoneMessageTemplates }          from "../PhoneMessageTemplateView/__types__/GetPhoneMessageTemplates";
import { GET_PHONE_MESSAGE_TEMPLATES }       from "../PhoneMessageTemplateView/PhoneMessageTemplateViewQueries";

export function useMessageTemplates(where?: PhoneMessageTemplateWhereInput, id?: string, additionalQueryConfigs: {
  skip?: number | null;
  before?: string | null;
  after?: string | null;
  from?: string | null;
  first?: number | null;
  last?: number | null;
  skipQuery?: boolean | null;
} = {}) {
  const draft = useReactiveVar(messageDraftsVar);
  const { skipQuery, ...rest } = additionalQueryConfigs;
  const wh = {
    ...where
  };

  if (!isEmpty(draft) && !draft[ id ]?.[ "type" ]) {
    delete wh.type;
    wh.AND = [
      {
        OR: [
          {
            "type": {
              "equalTo": "loan_proposal"
            }
          },
          {
            "type": {
              "equalTo": "sms"
            }
          }
        ]
      }
    ];//todo to not have conflict with exist OR in PhoneMessageThere query
  }

  const { data: { phoneMessageTemplates: { edges: templates = [] } = {} } = {}, loading } = useQuery<GetPhoneMessageTemplates, GetPhoneMessageTemplatesVariables>(GET_PHONE_MESSAGE_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: skipQuery,
    variables: {
      where: wh,
      ...rest
    }
  });

  return {
    templates,
    get loaded() {
      return loading;
    }
  };
}
