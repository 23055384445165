import { gql }             from "@apollo/client";
import { useQuery }        from "@apollo/client";
import { CircularLoader }  from "@relcu/ui";
import { EmptyList }       from "@relcu/ui";
import { Box }             from "@relcu/ui";
import { Suspense }        from "react";
import React               from "react";
import { FC }              from "react";
import { GetTableauToken } from "./__types__/GetTableauToken";
import "./analytics.css";

const TableauFrame = React.lazy(() => import("./TableauFrame"));

export const AnalyticsView: FC = React.memo(function ReportingView() {
  const { data: { viewer: { tableauToken } = {} } = {}, loading } = useQuery<GetTableauToken>(GET_TABLEAU_TOKEN, { fetchPolicy: "network-only" });
  if (!tableauToken && loading) {
    return null;
  }
  return <Box container direction={"row"} className={"analytics"}>
    {(!window.__CONFIG__.tableauDashboard || !tableauToken) ?
      <Box gap={"XS"} flex={1} style={{ marginTop: "200px" }}>
        <EmptyList icon={"analytics"} title={"Analytics"} content={"No dashboard configured"}/>
      </Box> :
      <Suspense fallback={<CircularLoader justify={"center"}/>}>
        <TableauFrame ticket={tableauToken}/>
      </Suspense>
    }
  </Box>;
});
const GET_TABLEAU_TOKEN = gql`
  query GetTableauToken{
    viewer {
      tableauToken
    }
  }`;
