import { stringToHslColor }                                      from "@relcu/ui";
import { concatText }                                            from "@relcu/ui";
import { useMemo }                                               from "react";
import { HTMLAttributes }                                        from "react";
import { FC }                                                    from "react";
import React, { useContext }                                     from "react";
import Badge                                                     from "../Badge";
import { useClassNames }                                         from "../utils";
import { TypeAttributes, WithAsProps, RsRefForwardingComponent } from "../@types/common";
import { AvatarGroupContext }                                    from "../AvatarGroup/AvatarGroup";

export interface AvatarProps extends WithAsProps {
  /** A avatar can have different sizes */
  size?: "xl" | "md" | "xs" | "sm";

  /**
   * The `src` attribute for the `img` element.
   */
  src?: string;

  /**
   * The `sizes` attribute for the `img` element.
   */
  sizes?: string;

  /**
   * The `srcSet` attribute for the `img` element.
   * Use this attribute for responsive image display.
   */
  srcSet?: string;

  /**
   * Attributes applied to the `img` element if the component is used to display an image.
   * It can be used to listen for the loading error event.
   */
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;

  /** Set avatar shape to circle  */
  circle?: boolean;

  /** This attribute defines an alternative text description of the image */
  alt?: string;

  showIndicator?: boolean;

  /** A badge can have different colors */
  color?: "red" | "green" | "gray";
}

export const Avatar: RsRefForwardingComponent<"div", AvatarProps> = React.forwardRef(
  (props: AvatarProps, ref: React.MutableRefObject<HTMLDivElement>) => {
    const {
      classPrefix = "avatar",
      as: Component = "div",
      showIndicator = true,
      size: sizeProp,
      className,
      color = "red",
      children,
      src,
      srcSet,
      sizes,
      imgProps,
      circle = true,
      alt,
      ...rest
    } = props;

    const { size } = useContext(AvatarGroupContext);
    const { withClassPrefix, prefix, merge } = useClassNames(classPrefix);
    const classes = merge(className, withClassPrefix(sizeProp || size, { circle }));
    let midName = useMemo(() => {
      if (typeof children == "string") {
        return concatText(children, 2).toUpperCase();
      }
      return children;
    }, [children, size]);
    const styles = useMemo(() => {
      let style = {};
      if (props.style) {
        style = { ...props.style };
      }

      if (typeof children == "string" && !children.startsWith("+1")) {
        style[ "backgroundColor" ] = stringToHslColor(children, 70, 60);
      }

      return style;
    }, [props.style, midName]);

    return (
      <Badge color={color} size={sizeProp} ref={ref} content={showIndicator}>
        <Component {...rest} style={styles} className={classes}>
          {src || srcSet ? (
            <img
              {...imgProps}
              className={prefix`image`}
              src={src}
              sizes={sizes}
              srcSet={srcSet}
              alt={alt}
            />
          ) : (
            midName && midName != "+1" ? midName : <AvatarIconSvg className={prefix`icon`}/>
          )}
        </Component>
      </Badge>
    );
  }
);

const AvatarIconSvg: FC<HTMLAttributes<SVGElement>> = React.memo((props) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none"
              className={props.className}>
    <rect x="1" width="52" height="52" rx="26" fill="white"/>
    <circle cx="27" cy="26" r="26" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M26.9983 0C12.6458 0 0.997437 11.6484 0.997437 26.0008C0.997437 40.3533 12.6458 52.0017 26.9983 52.0017C41.3508 52.0017 52.9991 40.3533 52.9991 26.0008C52.9991 11.6484 41.3508 0 26.9983 0V0ZM26.999 7.8C31.3151 7.8 34.7992 11.2841 34.7992 15.6003C34.7992 19.9164 31.3151 23.4005 26.999 23.4005C22.6828 23.4005 19.1987 19.9164 19.1987 15.6003C19.1987 11.2841 22.6828 7.8 26.999 7.8V7.8ZM26.9993 44.7213C20.4991 44.7213 14.7529 41.3932 11.3988 36.3491C11.4768 31.1749 21.7991 28.3408 26.9993 28.3408C32.1734 28.3408 42.5218 31.1749 42.5998 36.3491C39.2457 41.3932 33.4995 44.7213 26.9993 44.7213V44.7213Z"
          fill="#6D7683"/>
  </svg>;
});

Avatar.displayName = "Avatar";

export default Avatar;
