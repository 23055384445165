import React                   from "react";
import { classNames }          from "../../..";
import { GlobalClasses }       from "../../../constants/GlobalClasses";
import { Box }                 from "../../Box";
import { BoxComponentProps }   from "../../Box";
import { EllipsisWithTooltip } from "../../EllipsisTooltip/";

export interface ListItemTitleProps extends BoxComponentProps {
  title: string
}

export function ListItemTitle(props: ListItemTitleProps) {
  const { className, title, ...p } = props;
  const classes = classNames(ListItemTitleClasses.ListItemTitle, className);

  return <Box container alignItems={"center"} className={classes} {...p}>
    <EllipsisWithTooltip value={title}>
      <p className={GlobalClasses.EllipsisFlex}>{title}</p>
    </EllipsisWithTooltip>
  </Box>;
}
export enum ListItemTitleClasses {
  ListItemTitle = "list-item-title"
}
