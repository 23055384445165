import React                                from "react";
import { GraphQLError }                     from "graphql";
import { useApolloClient }                  from "@apollo/client";
import { useLocation }                      from "@relcu/react-router";
import { useNavigate }                      from "@relcu/react-router";
import { FORM_ERROR }                       from "@relcu/form";
import { getIn }                            from "@relcu/form";
import { useSource }                        from "@relcu/ui";
import { pick }                             from "@relcu/ui";
import { FieldLevelValidator }              from "@relcu/ui";
import { useModal }                         from "@relcu/ui";
import { LeadMembers_lead_members_contact } from "../../../../__types__/LeadMembers";
import { getSchema }                        from "../../../../utils/schemaUtils";
import { ContactDialog }                    from "../../Dialog/ContactDialog";
import { getJqlMutation }                   from "../../Jql";
import { JqlFormProps }                     from "../../Jql/JqlForm";
import { JqlForm }                          from "../../Jql/JqlForm";

function validatePhoneOrEmail(value = [], allValues) {
  const emails = getIn(allValues, "emails") || [];
  const phones = getIn(allValues, "phones") || [];
  for (let email of emails) {
    if (email.address) {
      return undefined;
    }
  }
  for (let phone of phones) {
    if (phone.number) {
      return undefined;
    }
  }
  return "Please provide at least one contact information.";
}
export const validators: FieldLevelValidator[] = [
  {
    field: "emails",
    validate: (value = [], allValues, meta) => {
      return validatePhoneOrEmail(value, allValues);
    }
  },
  {
    field: "phones",
    validate: (value = [], allValues, meta) => {
      return validatePhoneOrEmail(value, allValues);
    }
  }
];

export const ReferralPartnerForm = React.memo<JqlFormProps>(function ReferralForm(props) {
  const [modal, contextHolder] = useModal(ContactDialog);
  const client = useApolloClient();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { $viewer } = useSource();
  function getDuplicateError(graphQLErrors: ReadonlyArray<GraphQLError>) {
    return graphQLErrors?.map((error) => error?.extensions?.validationErrors?.find(vError => vError?.type == "duplicationError")).filter((t) => !!t);
  }

  function transformData(contact) {
    const { emails, phones, ...rest } = contact;
    return {
      ...rest,
      emails: emails?.filter((e) => e.address != undefined).map(upEmail => {
        const { __typename, ...email } = upEmail;
        return email;
      }),
      phones: phones?.filter((e) => e.number != undefined).map(upPhone => {
        const { __typename, ...number } = upPhone;
        return number;
      })
    };
  }

  async function create(variables) {
    const createJql = props.jql.mutation.create;
    const { documentNode: mutation } = getJqlMutation(createJql, { operationName: `Create${props.className}Form` });
    const { data } = await client.mutate({
      //refetchQueries: ["ListViewQuery"],
      mutation,
      variables
    });
    return data?.[ props.jql.mutation.create.operation ];
  }

  async function update(variables) {
    const updateJql = props.jql.mutation.update;
    const { documentNode: mutation } = getJqlMutation(updateJql, { operationName: `Update${props.className}Form` });
    const { data } = await client.mutate({
      //refetchQueries: ["ListViewQuery"],
      mutation,
      variables
    });
    return data?.[ props.jql.mutation.update.operation ];
  }

  const handleSubmit = async (values, form) => {
    let { id, fields } = values;
    fields = transformData(fields);

    if (id) {
      return update({ input: { id, fields } });
    }

    if ($viewer.role == "loan_officer" && !fields.assignedTo) {
      fields.assignedTo = {
        link: $viewer.id
      };
    }

    return create({ input: { fields } });
  };

  const handleError = (e, form, variables) => {
    const { id } = variables;
    if (getDuplicateError(e.graphQLErrors)?.length) {
      navigate(pathname, { state: { ...state, form: "DuplicationForm", title: "Resolve duplication" } });
      const referral = transformData(form.getState().values);
      const { fields } = getSchema("Contact");
      const contact = pick(referral, Object.keys(fields)) as LeadMembers_lead_members_contact;
      return new Promise((accept) => {
        modal({
          member: {
            ...contact,
            id: referral.contact.id,
            objectId: referral.contact.objectId
          },
          contactsData: {
            phones: referral?.phones.map(p => p.number) ?? [],
            emails: referral?.emails.map(e => e.address) ?? [],
            ids: referral.contact?.id ? [referral.contact?.id] : []
          },
          navigate: false,
          className: "Contact",
          title: "Update",
          action: "save",
          async onClose(data?) {
            if (data) {
              const fields = {
                types: data.types,
                firstName: data.firstName,
                lastName: data.lastName,
                middleName: data.middleName,
                emails: data.emails,
                phones: data.phones,
                contact: {
                  id: data.id,
                  objectIcon: data.objectIcon,
                  objectId: data.objectId,
                  objectName: data.objectName,
                  __typename: data.__typename
                }
              };
              for (let k in fields) {
                form.change(k, fields[ k ]);
              }
              await handleSubmit({ id, fields: { ...fields, contact: { link: data.id } } }, form);
              accept(null);
            } else {
              accept({
                [ FORM_ERROR ]: e.message
              });
            }
          }
        });
      });

      // return {
      //   [ FORM_ERROR ]: e.message
      // };
    }
  };

  return (
    <>
      {contextHolder}
      <JqlForm
        {...props}
        validators={validators}
        onError={handleError}
        onSubmit={handleSubmit}
      />
    </>
  );
});
