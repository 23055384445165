import { useState }        from "react";
import { useContext }      from "react";
import React               from "react";
import { Box }             from "../../..";
import { CalendarClasses } from "../CalendarClasses";
import { FocusedInput }    from "../Datepicker";
import DatepickerContext   from "../Datepicker/DatepickerContext";
import { Day }             from "../Day/Day";
import { MonthContent }    from "./MonthContent";
import { MonthHeader }     from "./MonthHeader";
import { MonthYear }       from "./MonthYear";
import { UseMonthProps }   from "./useMonth";
import { useMonth }          from "./useMonth";
import { CalendarDayObject } from "./useMonth.utils";
import { WeeksDays }         from "./WeeksDays";

export interface MonthProps extends UseMonthProps {
  startDate: Date | null
  endDate: Date | null
  focusedInput: FocusedInput,
  range?: boolean,
  now: Date
  yearProps?: any
}

export function Month(props: MonthProps) {
  const { year, month, firstDayOfWeek, startDate, endDate, focusedInput, range, now, yearProps } = props;
  const datepicker = useContext(DatepickerContext);
  const date = { startDate, endDate }[ focusedInput ];
  const [isYearsOpen, setYearsOpen] = useState(false);
  const { days, weekdayLabels, monthLabel } = useMonth({ year, month, firstDayOfWeek });
  return (
    <>
      <MonthHeader
        monthLabel={monthLabel}
        onPrev={datepicker.goToPreviousMonths}
        onNext={datepicker.goToNextMonths}
        onOpenYears={setYearsOpen}
        yearIsOpen={isYearsOpen}
      />
      <MonthContent>
        {isYearsOpen &&
          <MonthYear
            yearProps={yearProps}
            year={year}
            month={month}
            date={date}
          />
        }
        {!isYearsOpen &&
        <>
          <WeeksDays labels={weekdayLabels}/>
          <Box container justify={"center"} wrap={"wrap"} className={CalendarClasses.CalendarBody}>
            {days.map((day: CalendarDayObject, key) => (
              <Day
                {...day}
                range={range}
                key={key}
                now={now}
                startDate={startDate}
                endDate={startDate}
                focusedInput={focusedInput}
              />
            ))}
          </Box>
        </>
        }
      </MonthContent>
    </>
  );
}
