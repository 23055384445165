import React, { forwardRef, useLayoutEffect }          from "react";
import { findDOMNode }                                 from "react-dom";
import { BoxComponentProps }                           from "../../..";
import { applyBoxItemStyles }                          from "../../..";
import { BaseInputProps }                              from "../BaseInput/BaseInputProps";
import { useInputElement, useInputState, usePrevious } from "./hooks";
import { defer }                                       from "./utils/defer";
import { isFunction, toString }                        from "./utils/helpers";
import { isInputFocused }                              from "./utils/input";
import MaskUtils                                       from "./utils/mask";

export const MaskInput = forwardRef(function MaskInput(props: MaskInputProps, forwardedRef: any) {
  let properties = applyBoxItemStyles<MaskInputProps>(props);
  const {
    alwaysShowMask,
    mask,
    maskPlaceholder,
    inputProps: iProps,
    ...restProps
  } = properties;

  const maskUtils = new MaskUtils({ mask, maskPlaceholder });
  const isMasked = !!mask;
  const isEditable = !restProps.disabled && !restProps.readOnly;
  const isControlled = restProps.value !== null && restProps.value !== undefined;
  const previousIsMasked = usePrevious(isMasked);
  const initialValue = toString(
    (isControlled ? restProps.value || "" : restProps.defaultValue) || ""
  );

  const {
    inputRef,
    getInputState,
    setInputState,
    getLastInputState
  } = useInputState(initialValue, isMasked);
  const getInputElement = useInputElement(inputRef);

  function onChange(event) {
    const currentState = getInputState();
    const previousState = getLastInputState();
    let newInputState = maskUtils.processChange(currentState, previousState);

    setInputState(newInputState);

    if (props.onChange) {
      props.onChange(event);
    }
  }

  function onFocus(event) {
    inputRef.current = event.target;

    const currentValue = getInputState().value;

    if (isMasked && !maskUtils.isValueFilled(currentValue)) {
      let newValue = maskUtils.formatValue(currentValue);
      let newSelection = maskUtils.getDefaultSelectionForValue(newValue);
      let newInputState = {
        value: newValue,
        selection: newSelection
      };

      setInputState(newInputState);

      if (newValue !== currentValue && props.onChange) {
        props.onChange(event);
      }

      defer(() => {
        setInputState(getLastInputState());
      });
    }

    if (props.onFocus) {
      props.onFocus(event);
    }
  }

  function onBlur(event) {
    const currentValue = getInputState().value;
    const lastValue = getLastInputState().value;

    if (isMasked && !alwaysShowMask && maskUtils.isValueEmpty(lastValue)) {
      let newValue = "";
      let newInputState = {
        value: newValue,
        selection: { start: null, end: null }
      };

      setInputState(newInputState);

      if (newValue !== currentValue && props.onChange) {
        props.onChange(event);
      }
    }

    if (props.onBlur) {
      props.onBlur(event);
    }
  }
  function onMouseDown(event) {
    const input = getInputElement();
    const { value } = getInputState();

    if (!isInputFocused(input) && !maskUtils.isValueFilled(value)) {
      const mouseDownX = event.clientX;
      const mouseDownY = event.clientY;
      const mouseDownTime = new Date().getTime();

      const mouseUpHandler = mouseUpEvent => {
        document.removeEventListener("mouseup", mouseUpHandler);

        if (!isInputFocused(input)) {
          return;
        }

        const deltaX = Math.abs(mouseUpEvent.clientX - mouseDownX);
        const deltaY = Math.abs(mouseUpEvent.clientY - mouseDownY);
        const axisDelta = Math.max(deltaX, deltaY);
        const timeDelta = new Date().getTime() - mouseDownTime;

        if (
          (axisDelta <= 10 && timeDelta <= 200) ||
          (axisDelta <= 5 && timeDelta <= 300)
        ) {
          const lastState = getLastInputState();
          const newSelection = maskUtils.getDefaultSelectionForValue(
            lastState.value
          );
          const newState = {
            ...lastState,
            selection: newSelection
          };
          setInputState(newState);
        }
      };

      document.addEventListener("mouseup", mouseUpHandler);
    }

    if (props.onMouseDown) {
      props.onMouseDown(event);
    }
  }

  if (isMasked && isControlled) {
    const input = getInputElement();
    const isFocused = input && isInputFocused(input);

    let newValue =
      isFocused || alwaysShowMask || restProps.value
        ? maskUtils.formatValue(restProps.value)
        : restProps.value;

    setInputState({
      ...getLastInputState(),
      value: newValue
    });
  }

  const lastState = getLastInputState();
  const lastSelection = lastState.selection;
  const lastValue = lastState.value;

  useLayoutEffect(() => {
    if (!isMasked) {
      return;
    }

    const input = getInputElement();
    const isFocused = isInputFocused(input);
    const previousSelection = lastSelection;
    const currentState = getInputState();
    let newInputState = { ...currentState };

    if (!isControlled) {
      const currentValue = currentState.value;
      const formattedValue = maskUtils.formatValue(currentValue);
      const isValueEmpty = maskUtils.isValueEmpty(formattedValue);
      const shouldFormatValue = !isValueEmpty || isFocused || alwaysShowMask;
      if (shouldFormatValue) {
        newInputState.value = formattedValue;
      } else if (isValueEmpty && !isFocused) {
        newInputState.value = "";
      }
    }

    if (isFocused && !previousIsMasked) {
      newInputState.selection = maskUtils.getDefaultSelectionForValue(
        newInputState.value
      ) as any;
    } else if (isControlled && isFocused && previousSelection) {
      if (previousSelection.start !== null && previousSelection.end !== null) {
        newInputState.selection = previousSelection as any;
      }
    }

    setInputState(newInputState);
  });

  const inputProps = {
    ...restProps,
    onFocus,
    onBlur,
    onChange: isMasked && isEditable ? onChange : props.onChange,
    onMouseDown: isMasked && isEditable ? onMouseDown : props.onMouseDown,
    ref: ref => {
      (inputRef as any).current = findDOMNode(ref);

      if (isFunction(forwardedRef)) {
        forwardedRef(ref);
      } else if (forwardedRef !== null && typeof forwardedRef === "object") {
        forwardedRef.current = ref;
      }
    },
    value: isMasked && isControlled ? lastValue : restProps.value
  };
  return <input  {...iProps} {...inputProps} />;
});
MaskInput.defaultProps = {
  alwaysShowMask: false,
  maskPlaceholder: "_"
};
interface MaskInputProps extends BoxComponentProps, BaseInputProps {
  alwaysShowMask?: boolean,
  mask: string | string[] | RegExp[],
  maskPlaceholder?: string,
  onFocus?,
  onBlur?,
  onChange?,
  onMouseDown?
  value?: string
  readOnly?: boolean
  defaultValue?: string
  inputProps?: { [ key: string ]: any; };
}
