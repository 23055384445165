import { useReactiveVar }                 from "@apollo/client";
import React                              from "react";
import { ReactNode }                      from "react";
import { useContext }                     from "react";
import { FC }                             from "react";
import { FormSpy }                        from "@relcu/form";
import { useSource }                      from "@relcu/ui";
import { FormSummary }                    from "@relcu/ui";
import { format }                         from "../../../../../../utils/helpers";
import { PersistContext }                 from "../PersistProvider";
import { RateContext }                    from "../Rate/RateProvider";
import { RatesContext }                   from "../RatesProvider";
import { TOTAL_CLOSING_COSTS_SUM_FIELDS } from "../RateConditions";

type FunctionOrReactNode = ReactNode | ((value: string | number) => ReactNode)
export const TotalClosingCostsSum: FC<{ children?: FunctionOrReactNode, prefix?: string }> = React.memo(function TotalClosingCostsSum(props) {
  const { children, prefix = "$" } = props;
  const { points ,isMonthlyPmi,selectedPmi} = useContext(RateContext);
  const { $settings: { pricing: { closingFees: $closingFees } } } = useSource();

  const { fees: { closingFeesVar, prepaymentFeesVar } } = useContext(PersistContext);
  const closingFees = useReactiveVar(closingFeesVar);
  const prepaymentFees = useReactiveVar(prepaymentFeesVar);
  return <FormSpy subscription={{ values: true }}>
    {({ values }) =>
      <FormSummary data={{
        points,
        $closingFees,
        pmi: !isMonthlyPmi ? selectedPmi?.premium1 || 0 : 0,
        lenderCredits: -values.lenderCredits,
        sellerConcession: -values.sellerConcession,
        earnestMoneyPaid: -values.earnestMoneyPaid,
        propertyInsurancePrepaid: (values.insurancePrepaymentMonths * values.monthlyInsurance),
        propertyTaxPrepaid: (values.taxPrepaymentMonths * values.monthlyTax),
        prepaidInterest: (values.interestPrepaymentDays * values.interestPrepayment),
        prepaymentFees,
        closingFees
      }} fields={TOTAL_CLOSING_COSTS_SUM_FIELDS}>
        {
          (value) => {
            return children instanceof Function
              ?
              <>{children(value)}</>
              :
              <>{prefix || ""}{format(value, 2)}</>;
          }
        }
      </FormSummary>
    }
  </FormSpy>;
});
