import { toFirstLower }            from "@relcu/ui";
import { useMemo }                 from "react";
import { useState }                from "react";
import { useApolloClient }         from "@apollo/client";
import { gql }                     from "@apollo/client";
import { useMutation }             from "@apollo/client";
import { useAlert }                from "@relcu/ui";
import { useSource }               from "@relcu/ui";
import { getPrimaryBorrower }      from "../../../utils/helpers";
import { useMailApi }              from "../../useMailApi";
import { SendBulkEmailsVariables } from "./__types__/SendBulkEmails";
import { SendBulkEmails }          from "./__types__/SendBulkEmails";
import { useNavigate }             from "@relcu/react-router";
import { BulkSendMailModalProps }  from "./BulkSendMailModal";
import { BulkSendMailModalAlert }  from "./BulkSendMailModal";

export function useBulkSendMailModal(props: BulkSendMailModalProps) {
  const client = useApolloClient();
  const { onClose, variables, ids, destroyHandler } = props;
  const { info, error } = useAlert();
  const navigate = useNavigate();
  const { $viewer, $object } = useSource();
  const allowBehalfOfSend = $viewer.role !== "loan_officer";
  const [selected, setSelected] = useState(null);
  const isAllSelected = ids.some(item => item === "*");
  const query = {
    where: isAllSelected ? variables.where : { id: { in: ids } },
    order: variables.order
  };
  const { defaultBulkMailbox: defaultMailbox, mailBoxes } = useMailApi();
  const primaryBorrower = selected?.__typename == "Lead" ? getPrimaryBorrower(selected) : selected;
  const [sendBulkEmail] = useMutation<SendBulkEmails, SendBulkEmailsVariables>(SEND_BULK_EMAILS);
  const emails = useMemo(() => primaryBorrower?.contact?.emails?.map((email) => ({ ...email, disabled: email.optOut })), [primaryBorrower]);
  const defaultEmail = useMemo(() => emails?.find(email => !email.disabled), [emails]);
  const to = defaultEmail ? defaultEmail.address : undefined;
  const addresses = useMemo(() => {
    let options = mailBoxes.filter(m => !m.disabled && m.domain.valid && !m.common).map(m => ({ label: m.address, value: m.address }));
    if (allowBehalfOfSend) {
      return [{ label: "On behalf of assignees", value: "" }, ...options];
    }
    return options;
  }, [mailBoxes, allowBehalfOfSend]);
  const from = addresses[ 0 ]?.value || "";
  const templateFilters = useMemo(() => {
    const filters: Record<string, object> = {
      enabled: { equalTo: true },
      availableFor: { equalTo: "bulk" },
      AND: [
        { applyTo: { equalTo: toFirstLower(props.className) } },
        { applyTo: { notEqualTo: "loanProposal" } }
      ],
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: $viewer.id
              }
            }
          }
        }
      ]
    };
    return filters;
  }, [props.className, $viewer.id]);
  const flyerFilters = useMemo(() => {
    return {
      enabled: { equalTo: true },
      availableForType: { equalTo: "email" },
      availableFor: { equalTo: "bulk" },
      applyTo: { equalTo: toFirstLower(props.className) },
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: $viewer.id
              }
            }
          }
        }
      ]
    };
  }, [props.className, $viewer.id]);
  const handleSelect = (data) => {
    setSelected(data);
  };
  const getTemplate = id => {
    return client.readFragment({
      id: client.cache.identify({ id, __typename: "EmailTemplate" }),
      fragment: EMAIL_TEMPLATE,
      fragmentName: "ShortEmailTemplate"
    });
  };

  async function onBulkSubmit(data) {
    try {
      const template = getTemplate(data.template);
      if (!template) {
        return { template: `Template is required.` };
      }

      const result = await sendBulkEmail({
        variables: {
          from: data.from,
          where: query.where,
          flyerId: data.flyer,
          subject: template.subject || "(No subject)",
          templateId: template.objectId,
          className: props.className,
          includeUnsubscribeLink: data.includeUnsubscribeLink
        }
      });
      if (result?.data.sendBulkMail?.objectId) {
        info(BulkSendMailModalAlert({
          handleClick: () => {
            navigate(`/user/${$viewer.objectId}/emails/${result?.data.sendBulkMail?.objectId}`);
          }
        }), { width: "auto" });
        destroyHandler();
      } else {
        return result;
      }
    } catch (e) {
      console.error(e);
      error(e?.message ?? "Something went wrong");
    }
  }

  return {
    from,
    to,
    query,
    onBulkSubmit,
    handleSelect,
    getTemplate,
    selected,
    flyerFilters,
    templateFilters,
    addresses,
    mailBoxes
  };
}
const SEND_BULK_EMAILS = gql`
  mutation SendBulkEmails( $from: String, $where: LeadWhereInput, $subject: String, $className: String, $templateId: String, $flyerId: String,  $includeUnsubscribeLink: Boolean){
    sendBulkMail(
      from: $from,
      where: $where,
      subject: $subject,
      flyerId: $flyerId,
      templateId: $templateId,
      className: $className,
      includeUnsubscribeLink: $includeUnsubscribeLink
    ){
      id
      objectId
    }
  }
`;
const EMAIL_TEMPLATE = gql`
  fragment ShortEmailTemplate on EmailTemplate{
    id
    objectId
    content
    subject
  }
`;
