import React                                                     from "react";
import ActionIcon                                                from "../ActionIcon";
import { Icon }                                                  from "../Icon";
import { useClassNames }                                         from "../utils";
import { WithAsProps, TypeAttributes, RsRefForwardingComponent } from "../@types/common";

export interface TagProps extends WithAsProps {
  /** Different sizes */
  size?: "sm" | "xs";

  /** modes*/

  /** Whether to close */
  input?: boolean;

  choice?: boolean;

  filter?: boolean;
  /** modes*/

  selected?: boolean;

  disabled?: boolean;

  /** A tag can have different colors */
  color?: TypeAttributes.Color;

  /** The content of the component */
  children?: React.ReactNode;

  /** Click the callback function for the Close button */
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;

  /** Click the callback function for the toggle tag */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface TagComponent extends RsRefForwardingComponent<"div", TagProps> {
  /**
   * @deprecated Use <Tag.SecondaryText> instead.
   */
  SecondaryText: typeof TagSecondaryText;
}

export const Tag: TagComponent = React.forwardRef((props: TagProps, ref) => {
  const {
    as: Component = "div",
    classPrefix = "tag",
    size = "xs",
    color = "default",
    children,
    input,
    filter,
    choice,
    selected,
    className,
    disabled,
    onClose,
    onClick,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix);
  const classes = merge(className, withClassPrefix(size, color, { input, disabled, filter, choice, selected }));

  return (
    <Component {...rest} ref={ref} role={"button"} className={classes} onClick={input ? null : onClick}>
      {filter && selected && <Icon type={"check_circle"} className={prefix`done-icon`}/>}
      {<span className={prefix`text`}>{children}</span>}
      {input && (!filter && !selected) && <ActionIcon type={"cancel"} onClick={onClose}/>}
    </Component>
  );
}) as unknown as TagComponent;

export const TagSecondaryText: RsRefForwardingComponent<"div"> = React.forwardRef((props, ref) => {
  return <span style={{ color: "var(--rc-text-tertiary)" }}>
    {props.children}
  </span>;
});

Tag.SecondaryText = TagSecondaryText;

Tag.displayName = "Tag";
