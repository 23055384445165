import { TypographySize }          from "@relcu/ui";
import { TypographyColor }         from "@relcu/ui";
import { TypographyWeight }        from "@relcu/ui";
import { TypographyVariant }       from "@relcu/ui";
import { ListRowColors }           from "@relcu/ui";
import { CopyText }                from "@relcu/ui";
import { ListItemText }            from "@relcu/ui";
import { ListRow }                 from "@relcu/ui";
import { Box }                     from "@relcu/ui";
import { Typography }              from "@relcu/ui";
import React                       from "react";
import { FC }                      from "react";
import { MailDomain_dns }          from "../../../__types__/MailDomain";
import { MailDomainDialogClasses } from "../../../MailDomainDialog";
import { MailDomainTooltip }       from "../DomainDetailsForm";

export interface EmailForwardProps {
  dns: MailDomain_dns[];
}

export const EmailForward: FC<EmailForwardProps> = React.memo(function EmailForward(props) {
  const forwardDns: MailDomain_dns = props?.dns?.find(dns => !dns.host.includes("domainkey"));
  return (
    <Box container direction={"column"} gap={"XS"}>
      <Typography variant={TypographyVariant.h2} size={TypographySize.Subtitle}
                  weight={TypographyWeight.Medium}>Email forwarding</Typography>
      <Typography>
        *Organizing email forwarding is critical to not miss any incoming emails. Follow one of the
        options
        below to set up email forwarding:
      </Typography>
      <Typography>
        {`You can configure and set up from your mail server to forward all emails to `}
        <Typography
          variant={TypographyVariant.span}
          weight={TypographyWeight.Bold}>
          intake@{forwardDns?.host || ""}
        </Typography>.
        <MailDomainTooltip
          text={"Recommended option if you\n have a mail server."}/>
      </Typography>
      <Typography color={TypographyColor.Secondary}>or</Typography>
      <Typography>Use the MX record to manage your mailbox fully through Relcu. <MailDomainTooltip
        text={"If you do not have a mail\n server follow this option."}/></Typography>
      <ListRow
        gap={"XXS"}
        className={MailDomainDialogClasses.DnsRow}
        color={ListRowColors.White}
        justify={"space-between"}>
        <ListItemText
          tiny={true}
          text={
            <Typography variant={TypographyVariant.span}>
              MX
            </Typography>
          }
          flexGrow={1}
          flexShrink={0}
          flexBasis={"30px"}
        />
        <ListItemText
          tiny={true}
          text={
            <Typography variant={TypographyVariant.span}>
              reply
            </Typography>
          }
          flexGrow={1}
          flexShrink={0}
          flexBasis={"100px"}
        />
        <ListItemText
          tiny={true}
          text={
            <CopyText value={"mx.sendgrid.net"}>
              <Typography variant={TypographyVariant.span}>
                mx.sendgrid.net
              </Typography>
            </CopyText>
          }
          flexGrow={1}
          flexShrink={1}
          flexBasis={"100px"}
        />
      </ListRow>
    </Box>
  );
});