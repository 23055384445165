import React                        from "react";
import { FC }                       from "react";
import { useMemo }                  from "react";
import { useBoxProps }              from "@relcu/ui";
import { BoxComponentProps }        from "@relcu/ui";
import { useSource }                from "@relcu/ui";
import { Link }                     from "@relcu/ui";
import { TypographyWeight }         from "@relcu/ui";
import { TypographyColor }          from "@relcu/ui";
import { TypographySize }           from "@relcu/ui";
import { Typography }               from "@relcu/ui";
import { ChipsSizes }               from "@relcu/ui";
import { ChipsColors }              from "@relcu/ui";
import { Chips }                    from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { AvatarSizes }              from "@relcu/ui";
import { Avatar }                   from "@relcu/ui";
import { CallOut }                  from "@relcu/ui";
import { DeepPartial }              from "@apollo/client/utilities";
import { createPhoneNumbers }       from "../../../../../../utils/helpers";
import { ScopeConnection }          from "../../../../../ScopeConnection";
import { PhoneMessageThreadProps }  from "../../../../../PhoneMessageThread/PhoneMessageThread";
import { PhoneMessageThread }       from "../../../../../PhoneMessageThread/PhoneMessageThread";
import { MailSection }              from "../../../MailView/MailSection/MailSection";
import { MailSectionPosition }      from "../../../MailView/MailSection/MailSection";
import { MailSubSection }           from "../../../MailView/MailSubSection";
import { PhoneMessageConversation } from "../../__types__/PhoneMessageConversation";
import { usePhoneMessages }         from "./usePhoneMessages";

export interface PhoneMessagesProps extends Partial<PhoneMessageThreadProps>, BoxComponentProps {
  conversation: DeepPartial<PhoneMessageConversation>;
}

export const PhoneMessages: FC<PhoneMessagesProps> = React.memo(function PhoneMessages(props) {
  const { contact, scope, toContactPath, toLeadPath, id, scopeId, changeScope, selectedMsgs, setSelectedMsgs, onRemove } = usePhoneMessages({ conversation: props.conversation });
  const { $object, $viewer } = useSource();
  const queryVariables = useMemo(() => ({
    where: {
      conversation: {
        have: {
          id: {
            equalTo: id
          }
        }
      }
    }
  }), [id]);
  const subscriptionVariables = useMemo(() => ({
    where: {
      conversation: {
        have: {
          link: id
        }
      }
    }
  }), [id]);
  const to = useMemo(() => createPhoneNumbers(contact, "smsOptOut"), [contact]);

  return (
    <Box container direction={"column"} gap={"XXXS"} flex={1} {...useBoxProps(props)}>
      <MailSection position={MailSectionPosition.Top}>
        <MailSubSection>
          {!selectedMsgs.length ?
            <CallOut
              avatar={
                <Link to={toContactPath}>
                  <Avatar text={contact.objectName} size={AvatarSizes.Big}/>
                </Link>
              }
              content={
                <Box container gap={"XXS"}>
                  <Chips label={"Contact"} color={ChipsColors.Grey} size={ChipsSizes.Small}/>
                  {
                    scope &&
                    <Link to={toLeadPath}>
                      <Typography size={TypographySize.TextSmall} color={TypographyColor.Primary}
                                  weight={TypographyWeight.Medium}>
                        {scope.objectName}
                      </Typography>
                    </Link>
                  }
                </Box>
              }
              header={
                <Typography weight={TypographyWeight.Medium}>{contact.objectName}</Typography>
              }
            />
            :
            <ScopeConnection onClick={changeScope} onRemove={onRemove} typename={"PhoneMessage"} type={"bulk"}/>}
        </MailSubSection>
      </MailSection>
      <PhoneMessageThread
        key={props.conversation.objectId}
        nearBy={props.nearBy}
        allowBulkConnection={true}
        setSelectedMsgs={setSelectedMsgs}
        selectedMsgs={selectedMsgs}
        contact={contact}
        scopeId={scopeId}
        queryVariables={queryVariables}
        subscriptionVariables={subscriptionVariables}
        to={to}
        canWrite={$object.objectId == $viewer.objectId}
      />
    </Box>
  );
});

