import { useMemo }     from "react";
import React           from "react";
import { useField }    from "@relcu/form";
import { FieldConfig } from "@relcu/form";
import { useSource }   from "../../..";
import { compileVars } from "../../..";

export interface HiddenFieldProps extends FieldConfig<any> {
  name: string
}

export const HiddenField = React.memo<HiddenFieldProps>(function HiddenField(props) {
  const source = useSource()
  useField(props.name, {
    defaultValue: useMemo(() => {
      return props.defaultValue && compileVars(props.defaultValue, source);
    }, [props.defaultValue,source])
  });
  return null;
});
