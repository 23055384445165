import { FormSpy }           from "@relcu/form";
import { useContext }        from "react";
import React                 from "react";
import { TopLevelCondition } from "../../..";
import { JsonPermissions }   from "../../../utils/jsonPermissions";
import { Where }             from "../../Where";
import { useComponents }     from "../Component";
import { JsonFormContext }   from "./JsonForm";

export const JsonField = React.memo<JsonFieldProps>(withCondition(function JSONField(props) {
  const { component, properties, type, ...fieldProps } = props;
  const { fields } = useComponents();
  const context = useContext(JsonFormContext);
  const validators = context?.validators || [];
  const componentType = fields[ component ];
  const name = fieldProps.name;
  const validate = React.useMemo(() => {
    const validator = validators.find(({ field }) => {
      if (field === name) {
        return true;
      }
      if (field instanceof RegExp) {
        return field.test(name);
      }
      if (Array.isArray(field)) {
        return ~field.indexOf(name) || field.findIndex(f => f instanceof RegExp && f.test(name)) !== -1;
      }
      return false;
    });
    return validator?.validate;
  }, [name, validators.length]);
  if (!componentType) {
    return null;
  }
  return React.createElement(componentType, {
    ...fieldProps,
    ...properties,
    validate,
    flexBasis: props.view === "write" ? properties?.flexBasis || "calc(50% - 8px)" : "30%",
    type: JSONFieldType[ type ] || type
  });
}));
export interface JsonFieldProps<P = any> {
  name: string;
  label?: string;
  type?: string;
  required?: boolean
  targetClass?: string | string[];
  defaultValue?: any;
  placeholder?: string;
  options?: any[]
  component: string;
  view?: "read" | "write"
  mode?: "create" | "edit"
  permissions?: JsonPermissions
  visible?: boolean
  properties?: P
  conditions?: TopLevelCondition
  [ k: string ]: any
}

export const JSONFieldType = {
  String: "text",
  Date: "date",
  DateTime: "datetime",
  Boolean: "Checkbox",
  PhoneNumber: "text",
  Password: "password"
};

function withCondition(JsonField) {
  return function FieldConditionHok(props: JsonFieldProps) {
    if (props?.conditions) {
      const { conditions, ...properties } = props;
      const context = useContext(JsonFormContext);
      return (
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <Where conditions={conditions} source={{ ...context.initialValues, ...values }}>
              <JsonField {...properties} />
            </Where>
          )}
        </FormSpy>
      );
    }
    return <JsonField {...props}/>;
  };
}
