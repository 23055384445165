import React                  from "react";
import { FC }                 from "react";
import { useMutation }        from "@apollo/client";
import { ModalProps }         from "@relcu/ui";
import { useSelect }          from "@relcu/ui";
import { Modal }              from "@relcu/ui";
import { TypographyColor }    from "@relcu/ui";
import { TypographySize }     from "@relcu/ui";
import { Typography }         from "@relcu/ui";
import { MenuItem }           from "@relcu/ui";
import { SearchInput }        from "@relcu/ui";
import { ModalBody }          from "@relcu/ui";
import { getField }           from "../../../utils/schemaUtils";
import { UPDATE_LEAD_STATUS } from "../../Layout/Header/LeadHeader/useLeadHeader";
import { CommonModalClasses } from "../CommonModal/CommonModalClasses";

export interface StatusModalProps extends Partial<ModalProps> {
  data: any;
  onSubmit();
}

export const StatusModal: FC<StatusModalProps> = React.memo(function StatusModal(props) {
  const { onClose, data, onSubmit } = props;
  const { transitions, actions } = getField("Lead", "leadStatus");
  const [updateStatus] = useMutation(UPDATE_LEAD_STATUS, {});

  const onSelect = async ({ value }) => {
    const newStatus = transitions[ data.leadStatus.status ][ value ];
    await updateStatus({ variables: { id: data.id, status: newStatus, action: value } });
    onSubmit();
  };
  const availableActions = Object.keys(transitions[ data.leadStatus.status ] || {})?.map((actionId) => {
    return { value: actionId, label: actions[ actionId ].name };
  });

  const {
    selected,
    setSearch,
    search,
    handleSelect,
    items
  } = useSelect({
    onChange: onSelect,
    options: availableActions,
    optionLabel: "label",
    optionKey: "value"
  });

  return (
    <Modal
      onClose={onClose}
      open={true}
      closeIcon={true}
      disableBackdropClick={false}
      title={"Set status"}
      className={CommonModalClasses.BulkModal}
    >
      <ModalBody container direction={"column"} flex={1} className={CommonModalClasses.Container}>
        <SearchInput value={search} onChange={setSearch}/>
        <div className={CommonModalClasses.ScrollContainer}>
          {items.map((item, index) => {
            return <MenuItem
              className={CommonModalClasses.Item}
              disabled={item.disabled}
              selected={item.value == selected?.value}
              key={index}
              onClick={(e) => {
                handleSelect(item);
              }}>
              <Typography
                size={item.disabled ? TypographySize.TextTiny : TypographySize.Text}
                color={item.disabled ? TypographyColor.Secondary : TypographyColor.ExtraDark}
              >
                {item.disabled ? item.label.toUpperCase() : item.label}
              </Typography>
            </MenuItem>;
          })}
        </div>
      </ModalBody>
    </Modal>
  );
});
