import { useCallback }                               from "react";
import { useState }                                  from "react";
import { useQuery }                                  from "@apollo/client";
import { gql }                                       from "@apollo/client";
import { useAlert }                                  from "@relcu/ui";
import { useLocation }                               from "@relcu/react-router";
import { useNavigate }                               from "@relcu/react-router";
import { useSchemaDialog }                           from "../SchemaDialog";
import { GetContactByPhoneNumbersOrEmailsVariables } from "./__types__/GetContactByPhoneNumbersOrEmails";
import { GetContactByPhoneNumbersOrEmails }          from "./__types__/GetContactByPhoneNumbersOrEmails";
import { ContactDialogProps }                        from "./ContactDialog";

export function useContactDialog(props: ContactDialogProps) {
  let { action, onClose, navigate: needToNavigate = true, contactsData, member } = props;
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { error, success } = useAlert();
  const [createContactData, setCreateContactData] = useState(null);
  const [needResolve, setNeedResolve] = useState(false);
  const [resolveDuplicationData, setResolveDuplicationData] = useState(null);
  const [merging, setMerging] = useState(false);
  const [resolveDuplicationErrors, setResolveDuplicationErrors] = useState(null);
  const { data: { contacts: { edges = [] } = {} } = Object(), loading: loadingContacts } = useQuery<GetContactByPhoneNumbersOrEmails,
    GetContactByPhoneNumbersOrEmailsVariables>(GET_CONTACT_BY_PHONE_NUMBERS_OR_EMAIL, {
    variables: {
      ...contactsData
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const { loading, sections, jql } = useSchemaDialog(props);
  const handleSubmitErrors = useCallback(submitError => {
    if (submitError) {
      error(submitError);
    }
  }, []);

  const afterSubmit = (data, form) => {
    data = data[ jql.query.get.operation ] || data;
    setTimeout(() => {
      const { submitSucceeded } = form.getState();
      if (submitSucceeded) {
        success("Changes saved successfully.");
        props.onClose(data);
      }
    });
  };

  return {
    setCreateContactData,
    createContactData,
    needToNavigate,
    loadingContacts,
    get resolveContactInitialValues() {
      const resolveContactInitialValues = {
        temporary: member ?? {} as any,
        exists: { ...edges[ 0 ]?.node }
      };
      const existsPhones = edges[ 0 ]?.node?.phones;
      const createdPhones = member.phones;
      const existsEmails = edges[ 0 ]?.node?.emails;
      const createdEmails = member.emails;
      const { exists: existingPhones, current: currentPhones } = reorderDuplicates(existsPhones, createdPhones, "number");
      const { exists: existingEmails, current: currentEmails } = reorderDuplicates(existsEmails, createdEmails, "address");
      resolveContactInitialValues.exists.phones = existingPhones;
      resolveContactInitialValues.temporary.phones = currentPhones;
      resolveContactInitialValues.exists.emails = existingEmails;
      resolveContactInitialValues.temporary.emails = currentEmails;
      return resolveContactInitialValues;
    },
    setResolveDuplicationData,
    resolveDuplicationData,
    setResolveDuplicationErrors,
    resolveDuplicationErrors,
    handleSubmitErrors,
    setNeedResolve,
    needResolve,
    // handleResolve,
    afterSubmit,
    setMerging,
    onClose,
    loading,
    merging,
    action,
    jql,
    sections
  };
}

function reorderDuplicates(exists = [], current = [], key: string) {
  const currentOrdered = [];
  let existingOrdered = [];

  const currentMap = new Map(current.map((i) => [i[ key ], i]));
  const existingMap = new Map(exists.map((i) => [i[ key ], i]));

  currentMap.forEach((v, k) => {
    const item = existingMap.get(k);
    if (item) {
      currentOrdered.unshift(v);
      existingOrdered.unshift(v);
      existingMap.delete(k);
    } else {
      currentOrdered.push(v);
    }
  });
  existingOrdered = existingOrdered.concat(Array.from(existingMap.values()));

  return {
    current: currentOrdered,
    exists: existingOrdered
  };
}
export const CONTACT = gql`
  fragment Contact on Contact {
    id
    objectId
    objectName
    objectIcon
    firstName
    middleName
    lastName
    types
    ssn
    birthday
    company
    tags
    phones {
      number
      objectName
      type
      carrier
      nationalFormat
      sms
      smsCountryCode
      callOptOut
      smsOptOut
      default
    }
    emails {
      address
      type
      optOut
    }
  }
`;
export const GET_CONTACT_BY_PHONE_NUMBERS_OR_EMAIL = gql`
  query GetContactByPhoneNumbersOrEmails($phones: [Phone], $emails: [Email],$ids:[ID]) {
    contacts(where: {
      AND: [
        {id: {notIn: $ids}}
        {OR: [
          {
            phones: {
              have: {
                number: {
                  in: $phones
                }
              }
            }
          },
          {
            emails: {
              have: {
                address: {
                  in: $emails
                }
              }
            }
          }
        ]}
      ]
    }) {
      edges {
        node {
          ...Contact
        }
      }
    }
  }
  ${CONTACT}
`;
