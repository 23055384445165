import { useWindowDimensions } from "@relcu/ui";
import React                   from "react";
import { FC }                  from "react";
import { Box }                 from "@relcu/ui";
import { BoxComponentProps }   from "@relcu/ui";
import { NavBarDivider }       from "@relcu/ui";
import { NavBarSection }       from "./NavBarSection";
import { SectionType }         from "./NavBarSection";
import "./nav-bar.css";
import { BookmarkContext }     from "./useBookmarks";
import { useBookmarks }        from "./useBookmarks";

export const NavBar: FC<BoxComponentProps> = React.memo(function NavBar(props) {
  const { className, ...p } = props;
  const windowDimensions = useWindowDimensions();
  const bookmarks = useBookmarks();
  return <BookmarkContext.Provider value={bookmarks}>
    <Box direction={"column"} container flexShrink={0}
         flexBasis={windowDimensions.width > 1700 && 245} className={className} {...p}>
      <NavBarSection type={SectionType.PINNED}/>
      <NavBarDivider flexShrink={0}/>
      <NavBarSection type={SectionType.HISTORY}/>
    </Box>
  </BookmarkContext.Provider>;
});
