import { useQuery }              from "@apollo/client";
import { CommonClasses }         from "@relcu/ui";
import { SearchableMultiSelect } from "@relcu/ui";
import { useMemo }               from "react";
import { useState }              from "react";
import React                     from "react";
import { FC }                    from "react";
import { FontIcon, MenuItem }    from "@relcu/ui";
import { GET_TAGS }              from "../../operations.graphql";
export interface TagsFieldProps{
  targetClass:string,
  value:string[]
  onChange(date);
}
export const TagsField: FC<TagsFieldProps> = React.memo(function TagsField(props) {
  const {targetClass,onChange,value = []} = props
  const [q, setQ] = useState("");

  const {
    data: { tags: searchTags = [] } = {}
  } = useQuery(GET_TAGS, {
    fetchPolicy: "network-only",
    variables: {
      limit: 2,
      className: targetClass,
      search: q ? `.*${q?.trim()}.*` : ""
    }
  });
  const options = useMemo(() => {
    return [...value, ...searchTags.filter(t => !value.find(tag => tag === t))];
  }, [searchTags, value]);

  return <SearchableMultiSelect
    flex={"0 1 32%"}
    onType={setQ}
    searchText={q}
    options={options}
    onChange={onChange}
    value={value}
    renderOption={(option) => {
      return <MenuItem
        container
        thumbnail={<FontIcon type={"local_offer"} className={CommonClasses.GrayIcon}/>}>
        {option}
      </MenuItem>;
    }}
    state={ undefined}
    message={ undefined}
  />;
});