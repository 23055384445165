import { CalendarDate }    from "@relcu/ui";
import { DateTime }        from "luxon";
import { useState }        from "react";
import React               from "react";
import { FC }              from "react";
import { useMemo }         from "react";
import { Box, DatePicker } from "@relcu/ui";
import { RelativeDate }    from "@relcu/ui";
import { Checkbox }        from "@relcu/ui";
import { isValidDate }     from "@relcu/ui";

export interface SwitchDatePickerFieldProps {
  flex: string;
  onChange(date);
  value?: { _now?, _date };
  type?: "date" | "datetime" | "calendar";
}

export const SwitchDatePickerField: FC<SwitchDatePickerFieldProps> = React.memo(function SwitchDatePicker(props) {
  const { value, onChange, flex, type } = props;
  const [isRelative, setIsRelative] = useState(value?._now ? true : !isValidDate(value?._date));
  const format = useMemo(() => type === "datetime" ? "MM/dd/yyyy hh:mm a" : "MM/dd/yyyy", [type]);
  function parseAbsoluteDate(value) {
    if (isValidDate(value)) {
      return new Date(value);
    }
  }
  function formatAbsoluteDate(value) {
    if (isValidDate(value)) {
      return DateTime.fromJSDate(new Date(value)).toFormat(format);
    }
    return value || null;
  }

  return (
    <Box container direction={"column"} gap={"XXXS"} flex={flex}>
      <Box container className={"wrapper"}>
        {type == "calendar" ? <CalendarDate flex={1} value={value._now} onChange={(date)=>onChange({_now:date})}></CalendarDate> :
          <>
            <Checkbox checked={isRelative} onChange={() => setIsRelative(isRelative => !isRelative)}/>
            {(isRelative ? <RelativeDate value={value?._now} onChange={(date) => onChange({ _now: date })} flex={1}/> :
              <DatePicker value={formatAbsoluteDate(value?._date)}
                          onChange={(date) => onChange({ _date: parseAbsoluteDate(date) })}
                          type={type} flex={1}/>)}
          </>}

        {}
      </Box>
    </Box>
  );
});
