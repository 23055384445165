import React                 from "react";
import { FC }                from "react";
import { useMemo }           from "react";
import { getIn }             from "@relcu/form";
import { useForm }           from "@relcu/form";
import { FieldArray }        from "@relcu/form";
import { NumberField }       from "@relcu/ui";
import { ButtonVariants }    from "@relcu/ui";
import { Button }            from "@relcu/ui";
import { CommonClasses }     from "@relcu/ui";
import { ListItemText }      from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { FontIcon }          from "@relcu/ui";
import { UsersAndTagsField } from "../../../../../Field/UsersAndTagsField";
import { parseLimits }       from "../../utils";
import { StepClasses }       from "@relcu/ui";
import { StepRow }           from "@relcu/ui";
import { StepSectionProps }  from "@relcu/ui";
import { StepSection }       from "@relcu/ui";
import "./caps.css";

interface CapsProps extends StepSectionProps {
  name: string,
  coolDownIncluded?: boolean
}

export const Caps: FC<CapsProps> = React.memo(function Caps(props) {
  const { name, coolDownIncluded, title, tooltip, ...p } = props;
  const form = useForm();
  const applyBasis = useMemo(() => !coolDownIncluded ? "40%" : "30%", [coolDownIncluded]);
  const basis = useMemo(() => {
    return `calc(calc(100% - ${applyBasis}) / ${coolDownIncluded ? 5 : 4})`;
  }, [coolDownIncluded, applyBasis]);

  return <StepSection title={title} tooltip={tooltip} {...p}>
    <FieldArray name={name}>
      {
        ({ fields, meta }) => {
          return <Box container direction={"column"} gap={"XXXS"}>
            <Box container gap={"XS"} alignItems={"center"} className={StepClasses.StepRowHeader}>
              <ListItemText className={StepClasses.StepItem} flex={`0 2 ${applyBasis}`}
                            text={"Apply to"} tiny/>
              <ListItemText className={StepClasses.StepItem} flex={`0 1 ${basis}`} text={"Daily"} tiny/>
              <ListItemText className={StepClasses.StepItem} flex={`0 1 ${basis}`} text={"Weekly"} tiny/>
              <ListItemText className={StepClasses.StepItem} flex={`0 1 ${basis}`} text={"Monthly"} tiny/>
              <ListItemText className={StepClasses.StepItem} flex={`0 1 ${basis}`} text={"Weekend"} tiny/>
              {
                coolDownIncluded &&
                <ListItemText className={StepClasses.StepItem} flex={`0 1 ${basis}`} text={"Cool down period (min)"}
                              tiny/>
              }
              <FontIcon flexShrink={0} type={"" as any}/>
            </Box>
            {
              fields.map((name, index) => {
                return <StepRow gap={"XS"} key={index}>
                  {
                    fields.value[ index ]?.filter.includes("*") ?
                      <Box flex={`0 2 ${applyBasis}`} className={CapsClasses.CapAll}>All
                        users</Box>
                      :
                      <UsersAndTagsField flex={`0 2 ${applyBasis}`} halfSize name={`${name}.filter`}
                                         placeholder={"Select filter"}/>
                  }
                  <NumberField view={"write"} flex={`0 1 ${basis}`} placeholder={"No cap"}
                               name={`${name}.daily`}/>
                  <NumberField view={"write"} flex={`0 1 ${basis}`} placeholder={"No cap"}
                               name={`${name}.weekly`}/>
                  <NumberField view={"write"} flex={`0 1 ${basis}`} placeholder={"No cap"}
                               name={`${name}.monthly`}/>
                  <NumberField view={"write"} flex={`0 1 ${basis}`} placeholder={"No cap"}
                               name={`${name}.weekend`}/>
                  {
                    coolDownIncluded &&
                    <NumberField view={"write"} precision={1} flex={`0 1 ${basis}`} placeholder={"No limit"}
                                 name={`${name}.cdp`}/>
                  }
                  <FontIcon flexShrink={0} type={index == 0 ? "add" : "remove"} className={CommonClasses.ClickableIcon}
                            onClick={() => index == 0 ? fields.push({ filter: [] }) : fields.remove(index)}/>
                </StepRow>;
              })
            }
            <Button
              alignSelf={"end"}
              variant={ButtonVariants.Ghost}
              onClick={() => {
                let value = getIn(form.getState().initialValues, name);
                form.change(name, value ?? parseLimits([{ filter: ["*"] }]));
              }}>
              Reset filter
            </Button>
          </Box>;
        }
      }
    </FieldArray>
  </StepSection>;
});

Caps.defaultProps = {
  coolDownIncluded: false
};

const enum CapsClasses {
  CapAll = "cap__all"
}
