import { gql }                                        from "@apollo/client";
import { useSource }                                  from "@relcu/ui";
import { useRelayPagination }                         from "@relcu/ui";
import { useEffect }                                  from "react";
import { useMemo }                                    from "react";
import { useQuery }                                   from "@apollo/client";
import { useSubscription }                            from "@apollo/client";
import { ApolloClient }                               from "@apollo/client";
import { SubscriptionEvent }                          from "../../../../types/graphql-global-types";
import { divideDataByDate }                           from "../../../../utils/helpers";
import { nextCursor }                                 from "../../../../utils/helpers";
import { GetActivitiesByDynamicWhereVariables }       from "./__types__/GetActivitiesByDynamicWhere";
import { GetActivitiesByDynamicWhere }                from "./__types__/GetActivitiesByDynamicWhere";
import { SubscribeActivitiesByDynamicWhereVariables } from "./__types__/SubscribeActivitiesByDynamicWhere";
import { SubscribeActivitiesByDynamicWhere }          from "./__types__/SubscribeActivitiesByDynamicWhere";

export function useActivities() {
  const { $object } = useSource();
  const { queryVariables, subscriptionVariables } = useMemo(() => createWhereInputVariables($object), [$object]);
  const query = useQuery<GetActivitiesByDynamicWhere, GetActivitiesByDynamicWhereVariables>(GET_ACTIVITY_BY_DYNAMIC_WHERE, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true
  });
  useSubscription<SubscribeActivitiesByDynamicWhere, SubscribeActivitiesByDynamicWhereVariables>(SUBSCRIBE_ACTIVITY_BY_DYNAMIC_WHERE, {
    variables: subscriptionVariables,
    onData({ client, data: { data: { activities: { event, node } } } }) {
      const { count } = api.data;
      switch (event) {
        case SubscriptionEvent.CREATE:
          const cursor = nextCursor(api.data.edges.length);
          pagination.cursorRef.current = cursor;
          writeActivity(client, queryVariables, node, count, cursor);
      }
    }
  });
  const api = {
    get isEmpty() {
      return !api.data.count;
    },
    get data() {
      const { data: { activities: { edges = [], pageInfo, count = 0 } = {} } = {} } = query;
      return { pageInfo, edges, count, dividedData: divideDataByDate(edges) };
    },
    get loaded() {
      return !!query.data;
    },
    reload() {
      if (api.loaded) {
        query.refetch().catch(console.error);
      }
    },
    async fetchMore() {
      if (api.data.pageInfo) {
        return query.fetchMore({
          query: GET_ACTIVITY_BY_DYNAMIC_WHERE,
          variables: {
            ...queryVariables,
            after: api.data.pageInfo.endCursor
          }
        });
      }
    }
  };
  const pagination = useRelayPagination({
    pageInfo: api.data.pageInfo,
    onLoadMore: api.fetchMore,
    fromTop: false
  });

  useEffect(api.reload, []);
  return {
    ...api,
    ...pagination
  };
}

const queryWhere = (node) => {
  const id = node.id;
  const key = node.__typename;
  let whereMap = {
    "Contact": {
      OR: {
        initiator: { have: { link: id } },
        receiver: { have: { link: id } }
      }
    },
    "User": {
      OR: {
        initiator: { have: { link: id } },
        receiver: { have: { link: id } }
      }
    },
    "Lead": {
      subject: {
        have: {
          id: {
            equalTo: id
          }
        }
      }
    }
  };
  return whereMap[ key ];
};
const subscriptionWhere = (node) => {
  const id = node.id;
  const key = node.__typename;
  let whereMap = {
    "Contact": {
      OR: {
        initiator: { have: { link: id } },
        receiver: { have: { link: id } }
      }
    },
    "User": {
      OR: {
        initiator: { have: { link: id } },
        receiver: { have: { link: id } }
      }
    },
    "Lead": {
      subject: {
        have: {
          link: id
        }
      }
    }
  };
  return whereMap[ key ];
};
const readActivity = (client: ApolloClient<object>, variables: GetActivitiesByDynamicWhereVariables, count: number) => {
  const query = { query: GET_ACTIVITY_BY_DYNAMIC_WHERE, variables };
  let data: GetActivitiesByDynamicWhere = {
    activities: {
      count: 0,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: null,
        endCursor: null,
        __typename: "PageInfo"
      },
      __typename: "ActivityConnection",
      edges: []
    }
  };
  if (count) {
    data = client.readQuery<GetActivitiesByDynamicWhere, GetActivitiesByDynamicWhereVariables>(query);
  }
  return data;
};
const createWhereInputVariables = (node) => {
  return {
    queryVariables: { where: queryWhere(node) },
    subscriptionVariables: { where: subscriptionWhere(node) }
  };
};
const writeActivity = (client: ApolloClient<object>, variables: GetActivitiesByDynamicWhereVariables, node, count: number, cursor: string) => {
  const data = readActivity(client, variables, count);
  const pageInfo = data.activities?.pageInfo;
  client.writeQuery<GetActivitiesByDynamicWhere, GetActivitiesByDynamicWhereVariables>({
    query: GET_ACTIVITY_BY_DYNAMIC_WHERE,
    variables: {
      ...variables,
      before: pageInfo.startCursor
    },
    data: {
      ...data,
      activities: {
        ...data.activities,
        count: count + 1,
        pageInfo: {
          ...pageInfo,
          hasNextPage: pageInfo.hasNextPage || false,
          hasPreviousPage: pageInfo.hasPreviousPage || false,
          startCursor: cursor,
          endCursor: pageInfo?.endCursor || cursor
        },
        edges: [
          {
            __typename: "ActivityEdge",
            cursor,
            node
          }
        ]
      }
    }
  });
};

const GET_ACTIVITY_BY_DYNAMIC_WHERE = gql`
  query GetActivitiesByDynamicWhere($before:String,$after:String,$where: ActivityWhereInput!) {
    activities(where: $where, order: createdAt_DESC,
      first: 38,
      before: $before,
      after: $after,
    ) {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          objectId
          createdAt
        }
      }
    }
  }
`;
const SUBSCRIBE_ACTIVITY_BY_DYNAMIC_WHERE = gql`
  subscription SubscribeActivitiesByDynamicWhere($where: ActivitySubscriptionWhereInput) {
    activities(where: $where) {
      event
      node {
        id
        objectId
        createdAt
      }
    }
  }

`;

