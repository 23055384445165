import Dispatcher     from "./Dispatcher";
import ActionTypes    from "./ActionTypes";
import { ApolloLink } from "@apollo/client";
import { Operation }   from "@apollo/client";
import { NextLink }    from "@apollo/client";
import { Observable }  from "@apollo/client";
import { FetchResult } from "@apollo/client";

export default class NetworkStatusLink extends ApolloLink {

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    Dispatcher.dispatch({
      type: ActionTypes.REQUEST,
      payload: { operation }
    });

    const subscriber = forward(operation);

    return new Observable(observer => {
      let isPending = true;

      const subscription = subscriber.subscribe({
        next: result => {
          isPending = false;

          Dispatcher.dispatch({
            type: ActionTypes.SUCCESS,
            payload: { operation, result }
          });

          observer.next(result);
        },

        error: networkError => {
          isPending = false;

          Dispatcher.dispatch({
            type: ActionTypes.ERROR,
            payload: { operation, networkError }
          });

          observer.error(networkError);
        },

        complete: observer.complete.bind(observer)
      });

      return () => {
        if (isPending) {
          Dispatcher.dispatch({
            type: ActionTypes.CANCEL,
            payload: { operation }
          });
        }

        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  }

  reset() {
    Dispatcher.dispatch({
      type: ActionTypes.RESET,
      payload: null
    });
  }
}
