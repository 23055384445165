import React                        from "react";
import { FC }                       from "react";
import { useClassNames }            from "@relcu/rc";
import { Icon }                     from "@relcu/rc";
import { StackProps }               from "@relcu/rc";
import { Stack }                    from "@relcu/rc";
import { Typography }               from "@relcu/rc";
import { RsRefForwardingComponent } from "@relcu/rc/src/@types/common";
import { WithAsProps }              from "@relcu/rc/src/@types/common";
import "./details-field.css";

export interface DetailsFieldComponent extends RsRefForwardingComponent<"div", StackProps> {
  Title: typeof Title;
  SubTitle: typeof SubTitle;
  State: typeof State;
}
export const DetailsField: DetailsFieldComponent = React.forwardRef(function DetailsField(props, ref) {
  const { children, ...rest } = props;
  return <Stack
    direction={"column"}
    alignItems={"flex-start"}
    spacing={8}
    childrenRenderMode={"clone"}
    {...rest}>
    {children}
  </Stack>;
}) as unknown as DetailsFieldComponent;

export const Title: FC<WithAsProps & { withGap?: boolean }> = React.memo(function Title(props) {
  const { withGap = true, className, ...rest } = props;
  const { prefix } = useClassNames("details-field", "rc");
  return <Typography
    weights={"medium"}
    variant={"base16"}
    lineHeight={"lh20"}
    className={prefix({ "with-gap": withGap })} {...rest}/>;
});

export const SubTitle: FC<WithAsProps> = React.memo(function SubTitle(props) {
  const { style, className, ...rest } = props;
  const { prefix } = useClassNames("details-field-with-gap", "rc");
  return <Typography variant={"base16"} lineHeight={"lh20"} className={prefix()} {...rest}/>;
});

export const State: FC<StackProps & { variant: "error" | "warning" }> = (props) => {
  const { variant, ...rest } = props;
  const { prefix } = useClassNames("details-field", "rc");
  return <Stack spacing={4} style={{ paddingLeft: 4 }} {...rest}>
    <Icon type={"error"} color={variant} size={16}/>
    <Typography lineHeight={"lh24"} className={prefix(variant)}>{props.children}</Typography>
  </Stack>;
};

DetailsField.Title = Title;
DetailsField.SubTitle = SubTitle;
DetailsField.State = State;
