import React                 from "react";
import { BaseInputProps }    from "../../Input/BaseInput";
import { BaseFieldProps }    from "../BaseField";
import { BaseFieldProvider } from "../BaseField";
import AddressEditField      from "./AddressEditField";
import AddressReadField      from "./AddressReadField";
import { useAddressField }   from "./useAddressField";

export type AddressFieldProps = BaseFieldProps & BaseInputProps & {
  apiKey: string;
  options: { label: string, value: string }[]
}

export const AddressField = React.memo<AddressFieldProps>(function AddressField({ view = "edit", ...props }) {
  const field = useAddressField(props);
  return (
    <BaseFieldProvider field={field}>
      {view === "write" && <AddressEditField/>}
      {view === "read" && <AddressReadField/>}
    </BaseFieldProvider>
  );
});
