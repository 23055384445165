import React                  from "react";
import { FC }                 from "react";
import { useState }           from "react";
import { useCallback }        from "react";
import { ChipsVariants }      from "../..";
import { FontIcon }           from "../..";
import { ItemsEllipsis }      from "../..";
import { useBoxProps }        from "../..";
import { useImperativeState } from "../..";
import { SearchableMenu }     from "../..";
import { Alignment }          from "../..";
import { Popper }             from "../..";
import { Badge }              from "../..";
import { BadgeVariants }      from "../..";
import { BadgeColors }        from "../..";
import { ChipsColors }        from "../..";
import { Chips }              from "../..";
import { ButtonSizes }        from "../..";
import { ButtonVariants }     from "../..";
import { Box }                from "../..";
import { Label }              from "../..";
import { Button }             from "../..";
import { classNames }         from "../..";
import { BoxComponentProps }  from "../Box";
import { ChipsSizes }         from "../Chips";
import { BaseFieldClasses }   from "../Field/BaseField";
import { TagClasses }         from "./TagClasses";

export enum TagSizes {
  Small = "small",
  Medium = "medium",
  Big = "big"
}

export interface TagProps extends BoxComponentProps {
  size?: TagSizes,
  chipSize: ChipsSizes,
  tags: string[]
  label?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  options: string[]
  onChange(tags)
  onSelect?(item)
  searchText?: string
  onType?(text)
}

export const Tag: FC<TagProps> = React.memo((props) => {
  const { className, label, required, size, disabled, tags, chipSize, placeholder, options, onSelect, onChange, searchText = "", onType, ...p } = props;
  const classes = classNames({
    [ TagClasses.Tag ]: size == TagSizes.Big,
    [ TagClasses.Medium ]: size == TagSizes.Medium,
    [ BaseFieldClasses.ReadField ]: size == TagSizes.Small
  }, className);
  const [searchBounding, setSearchBounding] = useState(null);
  const [q, setQ] = useImperativeState(searchText, onType);

  const togglePopper = useCallback((e) => {
    setSearchBounding(searchBounding ? null : e.currentTarget.getBoundingClientRect());
  }, [setSearchBounding, searchBounding]);

  const handleClose = useCallback(() => {
    setSearchBounding(null);
    setQ("");
  }, [setSearchBounding, searchBounding]);

  const handleSelect = (item) => {
    onSelect?.(item);
    handleClose();
    let contain = tags?.find(tag => tag == item);
    if (!contain) {
      return onChange([...tags, item]);
    }
  };

  const handleDelete = (item) => {
    return onChange(tags.filter(tag => tag !== item));
  };

  const handleCreateNew = useCallback(() => {
    handleClose();
    const tag = q.trim();
    return tag ? handleSelect(tag) : null;
  }, [handleSelect, q]);

  const handleType = useCallback((value) => {
    if (value == null || value.length <= 25) {
      setQ(value || "");
    }
  }, [setQ]);

  return <Box container direction={"column"} gap={size == TagSizes.Big ? "XS" : "XXXS"}
              className={classes} {...useBoxProps(p)}>
    <Box container gap={"XXXS"} alignItems={"start"}>
      {
        size == TagSizes.Big ? label :
          <Label required={required}>{label}</Label>
      }
      {
        <Button type={"button"} variant={ButtonVariants.Ghost}
                disabled={disabled}
                size={size == TagSizes.Big ? ButtonSizes.Medium : ButtonSizes.Small} icon={"add"} onlyIcon
                style={{ "--btn-icon-only-size": (size == TagSizes.Big ? "24px" : "16px") as string } as any}
                onClick={togglePopper}/>
      }
    </Box>
    <Box container flex={1} alignItems={"center"} wrap={"wrap"} gap={"XXXS"}>
      <ItemsEllipsis
        showLength={2}
        items={tags}
        renderItem={(item, index) =>
          <Chips key={index}
                 color={ChipsColors.Grey}
                 label={item}
                 disabled={disabled}
                 size={chipSize}
                 onDelete={() => handleDelete(item)}
                 thumbnail={<Badge variant={BadgeVariants.Dot} color={BadgeColors.Primary}/>}/>
        }
        ellipsis={(count, extended) => <Chips
          thumbnail={
            extended && <FontIcon
              style={{ fontSize: chipSize == ChipsSizes.Small ? "var(--typography-subtitle-font-size)" : "var(--typography-title-font-size)" }}
              type={"keyboard_arrow_left"}/>
          }
          color={ChipsColors.Grey}
          size={chipSize}
          variant={ChipsVariants.Outline}
          label={!extended && `+${count}`}
          disabled={false}/>}
      />

    </Box>
    <Popper alignment={Alignment.BottomLeft} open={!!searchBounding} onClickAway={handleClose}
            anchorBounding={searchBounding}>
      <SearchableMenu
        style={{ width: 200 }}
        options={options}
        onSelect={handleSelect}
        searchText={q}
        onCreateNew={handleCreateNew}
        placeholder={props.placeholder ?? "Search or type here"}
        loading={false}
        onType={handleType}
        searchIcon={"local_offer"}
        showCreateButton={false}
      />
    </Popper>
  </Box>;
});

Tag.defaultProps = {
  size: TagSizes.Small
};
