import { ReactElement }      from "react";
import React, { ReactNode }  from "react";
import { classNames }        from "../..";
import { BoxComponentProps } from "../Box";
import { Box }               from "../Box";
import { Ellipsis }          from "../Ellipsis";
import { FontIcon }          from "../Icon";
import { Avatar }            from "../Avatar";

export enum ChipsColors {
  Primary = "primary",
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
  Grey = "grey"
}
export enum ChipsVariants {
  Fill = "fill",
  Outline = "outline",
}
export enum ChipsSizes {
  Small = "small",
  Medium = "medium",
  Big = "big"
}

export interface ChipsProps extends BoxComponentProps {
  color?: ChipsColors,
  label: string | ReactNode,
  stretch?: boolean,
  size?: ChipsSizes,
  variant?: ChipsVariants,
  disabled?: boolean,
  thumbnail?: ReactElement,
  length?: number,
  onDelete?(event)
  onClick?(event)
}

const defaultChipsProps: ChipsProps = {
  color: ChipsColors.Primary,
  label: "Tag",
  stretch: false,
  size: ChipsSizes.Medium,
  variant: ChipsVariants.Fill,
  thumbnail: null,
  disabled: false,
  length: 30,
  onClick: null,
  onDelete: null
};

Chips.defaultProps = defaultChipsProps;
export function Chips(props: ChipsProps) {
  const { className, variant, color, children, label, onClick, onDelete, stretch, thumbnail, disabled, size, length, ...p } = props;
  const classes = classNames(ChipsClasses.Chips, {
    [ ChipsClasses.Primary ]: (color == ChipsColors.Primary),
    [ ChipsClasses.Success ]: (color == ChipsColors.Success),
    [ ChipsClasses.Error ]: (color == ChipsColors.Error),
    [ ChipsClasses.Warning ]: (color == ChipsColors.Warning),
    [ ChipsClasses.Info ]: (color == ChipsColors.Info),
    [ ChipsClasses.Grey ]: (color == ChipsColors.Grey),

    [ ChipsClasses.Disabled ]: disabled,
    [ ChipsClasses.Clickable ]: !!onClick,
    [ ChipsClasses.Stretch ]: stretch,

    [ ChipsClasses.Fill ]: (variant == ChipsVariants.Fill),
    [ ChipsClasses.Outline ]: (variant == ChipsVariants.Outline),

    [ ChipsClasses.Small ]: (size == ChipsSizes.Small),
    [ ChipsClasses.Medium ]: (size == ChipsSizes.Medium),
    [ ChipsClasses.Big ]: (size == ChipsSizes.Big),

    [ ChipsClasses.WithAvatar ]: thumbnail && thumbnail.type == Avatar
  }, className);

  return <Box
    container
    className={classes}
    onClick={onClick}
    gap={ChipsSizes.Small ? "XXXS" : "XXS"}
    alignItems={"center"}
    {...p}
  >
    {thumbnail}
    {
      label &&
      <Box justify={"center"} className={ChipsClasses.Content} flex={1}>
        <Ellipsis from={length}>
          {label}
        </Ellipsis>
      </Box>
    }
    {
      !!onDelete &&
      <FontIcon type="clear" onClick={(e) => {
        e.stopPropagation();
        onDelete(e);
      }} className={ChipsClasses.Delete}/>
    }
  </Box>;
}
export enum ChipsClasses {
  Chips = "chips",
  Primary = "chips--primary",
  Success = "chips--success",
  Error = "chips--error",
  Warning = "chips--warning",
  Info = "chips--info",
  White = "chips--white",
  Grey = "chips--grey",
  Fill = "chips--fill",
  Outline = "chips--outline",
  Small = "chips--small",
  Medium = "chips--medium",
  Big = "chips--big",
  Delete = "chips__delete",
  Disabled = "chips--disabled",
  Clickable = "chips--clickable",
  Stretch = "chips--stretch",
  Content = "chips__content",
  WithAvatar = "chips--with-avatar"
}
