import { useCallback }          from "react";
import { useMemo }              from "react";
import { useSource }            from "@relcu/ui";
import { PopupWindow }          from "../../../utils/PopupWindow";
import { MsConnectButtonProps } from "./MsConnectButton";

export function useMsConnectButton(props: MsConnectButtonProps) {
  const { domain } = props;
  const { $viewer } = useSource();
  const popupHeight = 650;
  const popupWidth = 500;
  const oauthUrl = useMemo(() => {
    const url = new URL(`${window.location.origin}/api/v1/login/microsoft`);
    const data = btoa(JSON.stringify({
      domainId: domain.objectId,
      deviceId: props.deviceId,
      userId: $viewer?.objectId,
      popupId: "microsoft-oauth-authorize"
    }));
    url.searchParams.append("data", data);
    return url.href;
  }, [domain]);
  const handleLogin = useCallback(() => {
    const left = (window.screen.width / 2) - (popupWidth / 2);
    const top = (window.screen.height / 2) - (popupHeight / 2);
    const popup = PopupWindow.open(
      "microsoft-oauth-authorize",
      oauthUrl,
      {
        popup: false,
        height: popupHeight,
        width: popupWidth,
        top: top,
        left: left
      }
    );
    popup.then(
      (data: any) => {
        return props.onSuccess?.(data);
      },
      error => {
        console.error("ERROR DATA", error);
        props.onError(error);
      }
    );
  }, [oauthUrl]);

  return {
    handleLogin
  };
}
