import React                  from "react";
import { FC }                 from "react";
import { Label }              from "@relcu/ui";
import { LinkExternalColors } from "@relcu/ui";
import { LinkExternal }       from "@relcu/ui";
import { GlobalClasses }      from "@relcu/ui";
import { ChoiceField }        from "@relcu/ui";
import { TextField }          from "@relcu/ui";
import { Box }                from "@relcu/ui";
import { Field }              from "@relcu/form";

const SyncSinceOptions = [
  {
    label: "15 minutes",
    value: "-15m"
  },
  {
    label: "1 hour",
    value: "-1hr"
  },
  {
    label: "3 hours",
    value: "-3hrs"
  },
  {
    label: "1 day",
    value: "-1day"
  },
  {
    label: "1 week",
    value: "-1week"
  },
  {
    label: "2 weeks",
    value: "-2weeks"
  }
];

export interface ProviderProps {
  type: "gmail" | "outlook"
}

export const Provider: FC<ProviderProps> = React.memo(function Provider(props) {
  const {type} = props;
  return <Box container direction={"column"} gap={"XS"}>
    <TextField
      required
      label={"Client ID"}
      name={"provider.clientId"}
      placeholder={"Enter client ID here"}/>
    {
      type == "outlook" &&
      <TextField
        required
        label={"Tenant ID"}
        name={"provider.tenantId"}
        placeholder={"Enter tenant ID here"}/>
    }
    <TextField
      required
      label={"Client secret"}
      name={"provider.clientSecret"}
      placeholder={"Enter client secret"}/>
    <Field name={"provider.returnUrl"}>
      {({ input: { value } }) => {
        return (
          <Box container
               direction={"column"}
               gap={"XXXS"}
               flex={1}
               style={{ background: "white", padding: "var(--layout-box-gap-x-x-x-s) var(--layout-box-gap-x-x-s) var(--layout-box-gap-x-x-x-s) var(--layout-box-gap-x-x-s)" }}
          >
            <Label>Callback URL</Label>
            <LinkExternal
              color={LinkExternalColors.Primary}
              to={`mailto:${value}`}
              className={GlobalClasses.EllipsisFlex}
              allowCopy
              copyText={value}
            >
              {value}
            </LinkExternal>
          </Box>
        );
      }}
    </Field>{/*todo change this to normal component*/}
    <ChoiceField
      label={"Email sync period"}
      name={"provider.sync"}
      options={SyncSinceOptions}
      placeholder={"Select sync period"}/>
  </Box>;
});
