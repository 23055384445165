import React                     from "react";
import { FC }                    from "react";
import { useContext }            from "react";
import { ReactElement }          from "react";
import { getItemLabel }          from "../Input/Select/helpers";
import { Label }                 from "../Label";
import { MenuItem }              from "../Menu";
import { SearchableMenuContext } from "./MenuProvider";

export const MultiList: FC<any> = React.memo(function MultiList(props) {
  const { checkSelected, renderOption, optionLabel, onSelect, options } = useContext(SearchableMenuContext);

  return options.map((option, index) => {
    return <React.Fragment key={index}>
      <Label style={{ padding: 16 }}>{option.label}</Label>
      {
        option.options.map((option, index) => {
          return renderOption
            ?
            React.cloneElement(renderOption(option) as ReactElement, {//todo need to check with real data
              selected: checkSelected(option),
              key: index,
              onClick: (e) => onSelect(option)
            }) :
            <MenuItem
              key={index}
              container
              gap={"XXS"}
              selected={checkSelected(option)}
              onClick={() => onSelect(option)}>{getItemLabel(option, optionLabel)}</MenuItem>;
        })
      }
    </React.Fragment>;
  });
});
