import React           from "react";
import { FC }          from "react";
import { DateTime }    from "luxon";
import { Duration }    from "luxon/src/duration";
import { StackProps }  from "@relcu/rc";
import { Icon }        from "@relcu/rc";
import { Stack }       from "@relcu/rc";
import { Typography }  from "@relcu/rc";
import { Timer }       from "@relcu/rc";

export interface CallStatusProps extends StackProps {
  timer?: Date;
  status?: keyof typeof CallStatuses;
}

export interface StatusProps {
  diffFromNow?: Duration;
}

export const Call: FC<StatusProps> = React.memo(function Call(props) {
  const { children, diffFromNow, ...rest } = props;
  return <Stack childrenRenderMode={"clone"} justifyContent={"center"} spacing={4}>
    <Typography lineHeight={"lh24"} color={"tertiary"} {...rest}>
      {children || "Call to"}
    </Typography>
  </Stack>;
});

export const Pending: FC<StatusProps> = React.memo(function Pending(props) {
  const { children, diffFromNow, ...rest } = props;
  return <Stack childrenRenderMode={"clone"} justifyContent={"center"} spacing={4}>
    <Typography lineHeight={"lh24"} color={"tertiary"} {...rest}>
      Incoming call...
    </Typography>
  </Stack>;
});

export const Calling: FC<StatusProps> = React.memo(function Calling(props) {
  return <Stack
    childrenRenderMode={"clone"}
    justifyContent={"center"}
    spacing={4}
    style={{ color: "var(--rc-text-tertiary)" }}>
    <Icon type={"phone_in_talk"} size={16}/>
    <Typography lineHeight={"lh24"} color={"tertiary"}>
      {props.children || "Calling..."}
    </Typography>
  </Stack>;
});

export const Mute: FC<StatusProps> = React.memo(function Mute(props) {
  const { diffFromNow } = props;
  return <Stack
    childrenRenderMode={"clone"}
    justifyContent={"center"}
    spacing={4}>
    <Icon type={"pause"} color={"warning"} size={16}/>
    <Timer style={{ color: "var(--rc-status-04-warning-dark)" }}
           startFrom={{ h: diffFromNow.hours, m: diffFromNow.minutes, s: Math.round(diffFromNow.seconds) }}/>
  </Stack>;
});

export const Hold: FC<StatusProps> = React.memo(function Hold(props) {
  const { diffFromNow } = props;
  return <Stack
    childrenRenderMode={"clone"}
    justifyContent={"center"}
    spacing={4}>
    <Icon type={"pause"} color={"warning"} size={16}/>
    <Timer style={{ color: "var(--rc-status-04-warning-dark)" }}
           startFrom={{ h: diffFromNow.hours, m: diffFromNow.minutes, s: Math.round(diffFromNow.seconds) }}/>
  </Stack>;
});

export const InProgress: FC<StatusProps> = React.memo(function InProgress(props) {
  const { diffFromNow } = props;
  return <Stack
    childrenRenderMode={"clone"}
    justifyContent={"center"}
    spacing={4}>
    <Icon type={"mic"} color={"success"} size={16}/>
    <Timer style={{ color: "var(--rc-status-04-success-dark)" }}
           startFrom={{ h: diffFromNow.hours, m: diffFromNow.minutes, s: Math.round(diffFromNow.seconds) }}/>
  </Stack>;
});

export const Contacted: FC<StatusProps> = React.memo(function Contacted(props) {
  return <Stack
    childrenRenderMode={"clone"}
    justifyContent={"center"}
    spacing={4}>
    <Icon type={"mic"} color={"success"} size={16}/>
    <Typography lineHeight={"lh24"} style={{ color: "var(--rc-status-04-success-dark)" }}>
      Contacted
    </Typography>
  </Stack>;
});

export const Declined: FC<StatusProps> = React.memo(function Declined(props) {
  return <Stack
    childrenRenderMode={"clone"}
    justifyContent={"center"}
    spacing={4}>
    <Icon type={"phone_missed"} color={"error"} size={16}/>
    <Typography lineHeight={"lh24"} style={{ color: "var(--rc-status-03-error-primary)" }}>
      Declined the call
    </Typography>
  </Stack>;
});

export const CallStatuses = {
  "pending": Pending,
  "connecting": Call,
  "call": Call,
  "ringing": Calling,
  "initiated": Calling,
  "mute": Mute,
  "hold": Hold,
  "in-progress": InProgress,
  "open": InProgress,
  "contacted": Contacted,
  "no-answer": Declined,
  "busy": Declined
};

export const CallStatus: FC<CallStatusProps> = React.memo(function CallStatus(props) {
  const { timer, children, status } = props;
  const CollStatuses = CallStatuses[ status ];
  if (!CollStatuses && !children) {
    return <></>;
  } else if (!CollStatuses && children) {
    return <Call>
      {children}
    </Call>;
  }

  const diffFromNow = timer && DateTime.now().diff(DateTime.fromJSDate(timer), ["hours", "minutes", "seconds"]);
  return (
    <CollStatuses diffFromNow={diffFromNow}>
      {children}
    </CollStatuses>
  );
});
