import React              from "react";
import { FC }             from "react";
import { InputProps }     from "../../Input/Input";
import { BaseFieldProps } from "../BaseField";
import { BaseField }      from "../BaseField";
import TextEditField      from "./TextEditField";
import TextReadField      from "./TextReadField";

export const TextField: FC<TextFieldProps> = React.memo(function TextField(props) {
  const { regexp, ...rest } = props;
  return (
    <BaseField
      {...rest}
      validate={(value) => {
        if (regexp) {
          let reg = new RegExp(regexp, "iu");
          if (!value?.match(reg)) {
            return `${props.label} is invalid`;
          }
        }
      }}
      readView={<TextReadField/>}
      editView={<TextEditField/>}
    />
  );
});

export type TextFieldProps = BaseFieldProps & InputProps & {
  regexp?: string
}


