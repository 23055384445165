import { Form }           from "@relcu/rc";
import React              from "react";
import { useSchemaField } from "../../../useSchemaField";

export const ProposalLoanPurpose = React.memo(function PropertyType() {
  const { options } = useSchemaField("LoanEstimate", "loanPurpose");
  return (
    <Form.Select
      name="loanPurpose"
      label="Loan purpose"
      size="lg"
      required
      properties={{ searchable: false, style: { width: "100%" } }}
      data={options}
    />
  );
});
