import { TypePolicy } from "@apollo/client";

export const LayoutSection: TypePolicy = {
  keyFields: ["objectId"],
  fields: {
    selectedFields: {
      merge(existing, incoming) {
        return incoming || existing || [];
      }
    }
  }
};
