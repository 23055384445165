export const enum PriorityViewClasses {
  PriorityView = "priority-view",
  PriorityQueue = "priority-queue",
  PriorityQueues = "priority-queues",
  PriorityFilter = "priority-filter",
  PriorityTitle = "priority-title",
  PriorityList = "priority-list",
  PriorityListTitle = "priority-list-title",
  PriorityNextPage = "priority-next-page",
  PriorityTable = "priority-table",
  PriorityEmpty = "priority-empty",
  PriorityExpandableDisabled = "priority-expandable-disabled",
  EmptyPage = "empty-page"
}
