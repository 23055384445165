import React                 from "react";
import { FC }                from "react";
import { useRef }            from "react";
import { useEffect }         from "react";
import { classNames }        from "../../../utils/classNames";
import { Box }               from "../../Box";
import { BoxComponentProps } from "../../Box";
import { ButtonSizes }       from "../../Button";
import { ButtonColors }      from "../../Button";
import { Button }            from "../../Button";
import { DialPadButton }     from "./DialPadButton";
import { DialPadClasses }    from "./DialPadClasses";

export interface DialPadProps extends BoxComponentProps {
  disableCall?: boolean
  disableSms?: boolean
  disableDigits?: boolean
  onCall?()
  onSms?()
  onDigit(number: string | number),
  onClose?()
}

const validDigits = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "*", "#"];
export const DialPad: FC<DialPadProps> = React.memo(function DialPad(props) {
  const { onDigit, className, onCall, onSms, disableCall, disableSms, disableDigits } = props;
  const classes = classNames(DialPadClasses.DialPad, className);
  const dailPadRef = useRef(null);

  const onKeyPadDigit = async (e) => {
    if (disableDigits) {
      return;
    }
    if ((document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA" && !document.activeElement.getAttribute("contentEditable")) && validDigits.includes(e.key)) {
      const btn = dailPadRef.current?.querySelector(`[data-number="${e.key}"]`);
      if (btn) {
        btn.classList.add("dPad__button--active");
      }
      if (e.key == "Backspace") {
        onDigit(e.key);
      } else if (btn) {
        btn.click();
      }
    }
  };

  const onKeyPadDigitUp = async (e) => {
    if (disableDigits) {
      return;
    }
    if ((document.activeElement.tagName != "INPUT") && validDigits.includes(e.key)) {
      const btn = dailPadRef.current?.querySelector(`[data-number="${e.key}"]`);
      if (btn) {
        btn.classList.remove("dPad__button--active");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyPadDigit);
    document.addEventListener("keyup", onKeyPadDigitUp);

    return () => {
      document.removeEventListener("keydown", onKeyPadDigit);
      document.addEventListener("keyup", onKeyPadDigitUp);
    };
  }, [disableDigits]);

  return <Box container justify={"space-between"} tabIndex={0} gap={"M"} alignItems={"center"} direction={"column"}
              className={classes} ref={dailPadRef}>
    <Box container wrap={"wrap"} justify={"center"} className={DialPadClasses.DialPadContainer}>
      <DialPadButton digit={1} onClick={() => onDigit(1)} text={<>&nbsp;</>} data-number={1}/>
      <DialPadButton digit={2} onClick={() => onDigit(2)} text={"ABC"} data-number={2}/>
      <DialPadButton digit={3} onClick={() => onDigit(3)} text={"DEF"} data-number={3}/>
      <DialPadButton digit={4} onClick={() => onDigit(4)} text={"GHI"} data-number={4}/>
      <DialPadButton digit={5} onClick={() => onDigit(5)} text={"JKL"} data-number={5}/>
      <DialPadButton digit={6} onClick={() => onDigit(6)} text={"MNO"} data-number={6}/>
      <DialPadButton digit={7} onClick={() => onDigit(7)} text={"PQRS"} data-number={7}/>
      <DialPadButton digit={8} onClick={() => onDigit(8)} text={"TUV"} data-number={8}/>
      <DialPadButton digit={9} onClick={() => onDigit(9)} text={"WXYZ"} data-number={9}/>
      <DialPadButton digit={"*"} onClick={() => onDigit("*")}/>
      <DialPadButton digit={0} onClick={() => onDigit(0)} text={"+"} data-number={0}/>
      <DialPadButton digit={"#"} onClick={() => onDigit("#")}/>
    </Box>
    {
      (onCall || onSms) &&
      <Box container gap={"XS"}>
        {
          onSms &&
          <Button onlyIcon size={ButtonSizes.Big} icon={"forum"} disabled={disableSms} onClick={onSms}/>
        }
        {
          onCall &&
          <Button onlyIcon size={ButtonSizes.Big} icon={"call"} disabled={disableCall} color={ButtonColors.Success}
                  onClick={onCall}/>
        }
      </Box>
    }
  </Box>;
});
