import { useMemo }                  from "react";
import { useContext }               from "react";
import React                        from "react";
import { FC }                       from "react";
import { useReactiveVar }           from "@apollo/client";
import { Field }                    from "@relcu/form";
import { FontIcon }                 from "@relcu/ui";
import { CommonClasses }            from "@relcu/ui";
import { InlineEditableInput }      from "@relcu/ui";
import { classNames }               from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { DistributionQueueContext } from "../../../DistributionQueueDialog";
import { RuleProps }                from "./Rule";
import { RulesClasses }             from "./RulesClasses";

export const RuleHeader: FC<Partial<RuleProps> & { open: boolean }> = React.memo(function RuleHeader(props) {
  const { onClone, onDelete, index, name, open, ...p } = props;
  const { notCompleteRulesVar } = useContext(DistributionQueueContext);
  const notCompleteRules = useReactiveVar(notCompleteRulesVar);
  const incompleteRule = useMemo(() => !!Object.keys(notCompleteRules?.[ index ] ?? {}).length, [notCompleteRules, index]);

  return <Box container gap={"S"} alignItems={"center"} className={classNames(RulesClasses.Header, {
    [ RulesClasses.Error ]: incompleteRule && !open
  })} {...p}>
    <Field name={name}>
      {
        ({ input, meta }) => {
          return <InlineEditableInput
            readProps={{
              style: {
                fontSize: "var(--typography-subtitle-small-font-size)",
                fontWeight: 500,
                color: "var(--color-gray-extra-dark)"
              }
            }}
            flex={"0 1 335px"}
            justify={"start"}
            value={input.value}
            onChange={(value) => input.onChange(value)}
          />;
        }
      }
    </Field>
    {
      incompleteRule && !open
        ?
        <p className={RulesClasses.ErrorText}>Rule not completed</p>
        :
        <Box flex={1}/>
    }
    <FontIcon type="content_copy" className={CommonClasses.ClickableIcon} onClick={(e) => {
      e.stopPropagation();
      onClone();
    }}/>
    <FontIcon type="delete" className={CommonClasses.ClickableIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}/>
    <FontIcon type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              className={open ? CommonClasses.PrimaryIcon : CommonClasses.GrayIcon}/>
  </Box>;
});
