import React                    from "react";
import { FC }                   from "react";
import { BaseField }            from "@relcu/ui";
import { ImageUploadProps }     from "@relcu/ui";
import { BaseFieldProps }       from "@relcu/ui";
import { ImageUploadEditField } from "./ImageUploadEditField";
import ImageUploadReadField     from "./ImageUploadReadField";

export const ImageUploadField: FC<ImageUploadFieldProps> = React.memo(function ImageUploadField(props) {
  return (
    <BaseField
      acceptedTypes={[
        "image/*"
      ]}
      {...props}
      readView={<ImageUploadReadField/>}
      editView={<ImageUploadEditField/>}
    />
  );
});

export type ImageUploadFieldProps = BaseFieldProps & ImageUploadProps


