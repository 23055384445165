import React                    from "react";
import { useBoxProps }          from "../../Box";
import { Box }                  from "../../Box";
import { ChipsColors }          from "../../Chips";
import { Chips }                from "../../Chips";
import { ItemsEllipsis }        from "../../Ellipsis";
import { Label }                from "../../Label";
import { useConsumeField }      from "../BaseField";
import { BaseFieldClasses }     from "../BaseField";
import { MultiInputFieldProps } from "./MultiInputField";

export const MultiInputReadField = React.memo(function MultiInputReadField() {
  const { input: { readPlaceholder, label, value, ...inputProps } } = useConsumeField<MultiInputFieldProps>();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(inputProps)}>
      <Label>{label}</Label>
      {
        value?.length <= 0 ? (readPlaceholder ? readPlaceholder : "-") :
          <ItemsEllipsis
            showLength={2}
            items={value}
            renderItem={(item, index) =>
              <Chips key={index}
                     color={ChipsColors.Grey}
                     label={item}
                     disabled={true}/>}
          />}
    </Box>
  );
});

export default MultiInputReadField;
