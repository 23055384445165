import { useMemo }                  from "react";
import React                        from "react";
import { FC }                       from "react";
import { useQuery }                 from "@apollo/client";
import { MultiChoiceField }         from "@relcu/ui";
import { MortechInvestors }         from "../../View/PricingView/LoanCriteria/__types__/MortechInvestors";
import { MortechProductsVariables } from "../../View/PricingView/LoanCriteria/__types__/MortechProducts";
import { MortechProducts }          from "../../View/PricingView/LoanCriteria/__types__/MortechProducts";
import { GET_MORTECH_PRODUCTS }     from "../../View/PricingView/LoanCriteria/useLoanCriteria";
import { GET_MORTECH_INVESTORS }    from "../../View/PricingView/LoanCriteria/useLoanCriteria";

export const ProductCategoryField: FC<any> = React.memo(function ProductCategoryField({ targetClass, ...props }) {
  const { data: { mortechInvestors } = { mortechInvestors: [] }, loading: investorLoading } = useQuery<MortechInvestors>(GET_MORTECH_INVESTORS);
  const investorIds = useMemo(() => mortechInvestors.map(investor => investor.id), [mortechInvestors]);
  const { data: { mortechProducts: products } = { mortechProducts: [] }, loading } = useQuery<MortechProducts, MortechProductsVariables>(GET_MORTECH_PRODUCTS, {
    skip: !investorIds,
    variables: { investorIds }
  });
  const options = useMemo(() => products?.map(({ id, name }) => ({ value: id, label: name })), [products]);

  return <MultiChoiceField
    width={400}
    {...props}
    flexBasis={props.view == "read" ? "100%" : props.flexBasis}
    disabled={loading || investorLoading}
    options={options}
  />;
});