import { DependencyList } from "react";
import { useEffect }      from "react";
import { useRef }         from "react";

export function useMounted(fn: Function, deps?: DependencyList) {
  const ref = useRef(false);
  useEffect(() => {
    if (ref.current) {
      fn();
    }
    ref.current = true;
  }, deps);
}
