import React                   from "react";
import { useCallback }         from "react";
import { CSSProperties }       from "react";
import { useState }            from "react";
import { useEffect }           from "react";
import { forwardRef }          from "react";
import { useImperativeHandle } from "react";
import { useRef }              from "react";
import { Alert }               from "./Alert";
import { AlertPosition }       from "./AlertPosition";
import { AlertObject }         from "./AlertProvider";

export const AlertTransition = forwardRef((alert: AlertObject, ref) => {
  const [show, setShow] = useState(false);
  const createStyle = useCallback((show: boolean, position: AlertPosition) => {
    let transform;
    switch (position) {
      case AlertPosition.TOP_LEFT:
        transform = `translateX(${(show ? "20px" : "-120px")})`;
        break;
      case AlertPosition.TOP_CENTER:
        transform = `translateY(${(show ? "20px" : "-120px")})`;
        break;
      case AlertPosition.TOP_RIGHT:
        transform = `translateX(${(show ? "-20px" : "120px")})`;
        break;
      case AlertPosition.BOTTOM_LEFT:
        transform = `translateX(${(show ? "20px" : "-120px")})`;
        break;
      case AlertPosition.BOTTOM_CENTER:
        transform = `translateY(${(show ? "-20px" : "120px")})`;
        break;
      case AlertPosition.BOTTOM_RIGHT:
        transform = `translateX(${(show ? "-20px" : "120px")})`;
        break;
    }
    return {
      position: "relative",
      marginBottom: "5px",
      marginTop: "5px",
      opacity: `${(show ? "1" : "0")}`,
      transform: transform,
      transition: "all 300ms ease-in-out"
    } as CSSProperties;
  }, []);
  const timeoutRef = useRef(null);
  const remove = useCallback(() => {
    setShow(false);
    timeoutRef.current = setTimeout(() => {
      alert.remove();
    }, 301);
  }, [alert, setShow, timeoutRef]);

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    let timeout;
    if (alert.autoClose) {
      timeout = setTimeout(() => {
        remove();
      }, alert.autoCloseTimeout);
    }
    return () => {
      if (typeof timeout != "undefined") {
        clearTimeout(timeout);
      }
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    remove
  }));

  return <Alert {...alert.options} style={createStyle(show, alert.position)}
                variant={alert.variant}>{alert.message}</Alert>;
});
