import React  from "react";
import { FC } from "react";

export const DialIcon: FC<React.SVGProps<SVGSVGElement>> = React.memo(function DialIcon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill={props.fill} className={"dail-input__icon"}
              {...props}>
    <path
      d="m11.4 16 2.6-2.6 2.6 2.6 1.4-1.4-2.6-2.6L18 9.4 16.6 8 14 10.6 11.4 8 10 9.4l2.6 2.6-2.6 2.6ZM3 12l4.35-6.15q.275-.4.712-.625Q8.5 5 9 5h10q.825 0 1.413.588Q21 6.175 21 7v10q0 .825-.587 1.413Q19.825 19 19 19H9q-.5 0-.938-.225-.437-.225-.712-.625Z"/>
  </svg>;
});
