import { useCallback }      from "react";
import { formatNumber }     from "../../..";
import { useBaseField }     from "../BaseField";
import { NumberFieldProps } from "./NumberField";
import { useNumberUtils }   from "./useNumberUtils";

export function useNumberField(props: NumberFieldProps) {
  const { clean, parse: parseUtil, toFixedNoRounding } = useNumberUtils(props);
  const parse = (value, name) => {
    const str = String(value);
    if (value && props.precision && str.indexOf(".") < 0) {
      const end = str.substring(str.length - props.precision, str.length);
      const start = str.substring(0, str.length - props.precision);
      if (start && end) {
        value = [start, end].join(".");
      }
    }
    value = clean(value);
    if (!value) {
      return parseUtil(null, name);
    }
    if (props.precision || props.precision === 0) {
      value = toFixedNoRounding(value, props.precision);
    }

    const parsedNumber = parseUtil(Number(value), name);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
  };
  const format = (value, name) => {
    value = clean(value);
    if (!value) {
      return null;
    }
    return formatNumber(value, props.precision);
  };
  const filed = useBaseField(props, {
    defaultValue: props.defaultValue || undefined,
    format,
    parse
  });

  const onChange = useCallback((value, event) => {
    let cursor = event.target.selectionEnd;
    filed.input.onChange(value);
    event.persist();
    setTimeout(() => {
      if (String(event.target.value).includes(",")) {
        const oldLength = (String(filed.input.value).match(/,/g) || []).length;
        const newLength = (String(event.target.value).match(/,/g) || []).length;
        if (oldLength !== newLength) {
          cursor += 1;
        }
      }
      event.target.selectionStart = cursor;
      event.target.selectionEnd = cursor;
      event.target.focus();
    });
  }, [filed.input]);

  return {
    ...filed,
    parse,
    toFixedNoRounding,
    clean,
    format,
    onChange
  };
}
