import { FunctionComponent }         from "react";
import React                         from "react";
import { FC }                        from "react";
import { classNames }                from "@relcu/ui";
import { Box }                       from "@relcu/ui";
import { InlineEdit }                from "@relcu/ui";
import { useImperativeState }        from "@relcu/ui";
import { InlineEditableInputProps }  from "@relcu/ui";
import { InlineSummaryFiledClasses } from "./InlineSummaryFiledClasses";
import './inline-summary-filed.css'
type InlineSummaryProps = Omit<InlineEditableInputProps, "value"> & {
  readView: () => React.ReactNode
  editView: () => React.ReactNode
  classes?: {
    root?: string
    actions?: string
  }
}

interface InlineSummaryComponent extends FunctionComponent<InlineSummaryProps> {
  Value?: FC<any>
  EditView?: FC<any>
}

export const InlineSummaryField: InlineSummaryComponent = React.memo(function InlineSummaryField(props) {
  const { isEditing, onConfirm, onCancel, onEdit, hideActionButtons, readView, editView, classes, disableActionsButtons } = props;
  const [editing, setEditing] = useImperativeState(isEditing, onEdit);

  const handleConfirm = (e) => {
    onConfirm?.(e, null);
    setEditing(false);
  };

  return (
    <InlineEdit
      disableActionsButtons={disableActionsButtons}
      hideActionButtons={hideActionButtons}
      flex={1}
      justify={"end"}
      readView={readView}
      editView={editView}
      isEditing={editing}
      onEdit={(e) => {
        setEditing(true);
      }}
      onConfirm={handleConfirm}
      onCancel={(e) => {
        onCancel?.(e);
        setEditing(false);
      }}
      classes={{ ...classes, actions: classNames(classes.actions, { [ InlineSummaryFiledClasses.InlineSummaryActions ]: isEditing }) }}
    />
  );
});

InlineSummaryField.Value = React.memo(function InlineSummaryValue(props) {
  return <p className={InlineSummaryFiledClasses.InlineSummaryValue} {...props}/>;
});

InlineSummaryField.EditView = React.memo(function InlineSummaryEditView(props) {
  const { className, ...p } = props;
  const classes = classNames(InlineSummaryFiledClasses.InlineSummaryEdit, className);
  return <Box container alignItems={"center"} gap={"XXXS"} className={classes} {...p}/>;
});
