import { FC }       from "react";
import React        from "react";
import { DateTime } from "luxon";
import { MouseEventHandler } from "react";
import Badge        from "../Badge";
import Typography   from "../Typography";

export interface PushDistributionItemProps {
  date: string
  type?: string
  name?: string
  unread?: boolean,
  onClick?: MouseEventHandler,
}
const defaultListCardProps: PushDistributionItemProps = {
  date: new Date().toISOString()
};

export const PushDistributionItem: FC<PushDistributionItemProps> = (props: PushDistributionItemProps) => {
  const { onClick, type, name, date, unread, ...p } = props;
  return <div className={"rc-push-distribution-item"} onClick={onClick}>
    <div className={"rc-push-distribution-item-content"}>
      <Typography
        color={"primary"}
        variant={"base14"}
        weights={unread ? "medium" : "regular"}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}>
        {type}
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
        <Typography
          style={{
            whiteSpace: "nowrap"
          }}
          color={"secondary"}
          variant={"small12"}
          weights={"regular"}>{DateTime.fromISO(date).toLocaleString({ hour: "2-digit", minute: "2-digit" })}</Typography>
        {
          unread && <Badge className={"rc-online-status"} size={"sm"}/>
        }
      </div>
    </div>
    <div>
      <Typography
        className={"rc-push-distribution-item-name"}
        variant={"base14"}
        weights={unread ? "medium" : "regular"}>
        {name}
      </Typography>
    </div>
  </div>;
};
PushDistributionItem.defaultProps = defaultListCardProps;
