import React              from "react";
import { useCallback }    from "react";
import { FC }             from "react";
import { InputProps }     from "../../Input/Input";
import { BaseFieldProps } from "../BaseField";
import { BaseField }      from "../BaseField";
import UrlEditField       from "./UrlEditField";
import UrlReadField       from "./UrlReadField";
import { validURL }       from "../../../utils/helpers";

export type UrlFieldProps = BaseFieldProps & InputProps;

export const UrlField: FC<UrlFieldProps> = React.memo(function UrlField(props) {

  const validate = useCallback((value) => {
    if (value && !validURL(value)) {
      return `${props.label} is invalid`;
    }
  }, []);

  return (
    <BaseField
      {...props}
      validate={validate}
      readView={<UrlReadField/>}
      editView={<UrlEditField/>}
    />
  );
});
