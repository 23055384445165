import { getIn }              from "@relcu/form";
import { FieldProps }         from "@relcu/ui";
import { List }               from "@relcu/ui";
import { Box }                from "@relcu/ui";
import { useEffect }          from "react";
import React                  from "react";
import { FC }                 from "react";
import { getHumanized }       from "../../../utils/schemaUtils";
import { SearchModalClasses } from "../SearchModal/SearchModalClasses";

export interface SearchResultProps {
  onRowClick: (item) => void,
  fields?: FieldProps[]
  data: any[]
  count: number,
  onPage(page: number),
}
export const RecordSearchResult: FC<SearchResultProps> = React.memo(props => {
  function updateModalContainerSize() {
    const resultSection: any = document.querySelector(`.${SearchModalClasses.Result}`);
    resultSection?.attributeStyleMap.set("min-height", 0);
  }

  useEffect(() => {
    window.addEventListener("resize", updateModalContainerSize);
    const resultSection: any = document.querySelector(`.${SearchModalClasses.Result}`);
    resultSection?.attributeStyleMap.set("min-height", (CSS as any).px(resultSection.clientHeight));
    return () => window.removeEventListener("resize", updateModalContainerSize);
  }, []);//todo check

  return (
    <Box container direction={"column"} gap={"XXL"} className={SearchModalClasses.Result}>
      <Box container direction={"column"} gap={"XS"} className={SearchModalClasses.ResultSection}>
        <List onRowClick={props.onRowClick}
              headers={[
                {
                  title: "Name",
                  flexGrow: 1,
                  flexBasis: "100px"
                },
                {
                  title: "ResNum",
                  flexBasis: "150px"
                },
                {
                  title: "Lead source",
                  flexBasis: "150px"
                },
                {
                  title: "Address",
                  flexBasis: "300px"
                },
                {
                  title: "Created Date",
                  flexBasis: "100px"
                }
              ]}
              getColumnValue={(row, field) => {
                return getHumanized("DirectMail", field.name, getIn(row, field.name));
              }
              }
              fields={[
                {
                  name: "objectName",
                  component: "ListItemText",
                  flexGrow: 1,
                  flexBasis: "100px"
                },
                {
                  name: "resNum",
                  component: "ListItemText",
                  flexBasis: "150px"
                },
                {
                  name: "leadProvider",
                  component: "ListItemText",
                  flexBasis: "150px"
                },
                {
                  name: "address",
                  component: "ListItemText",
                  flexBasis: "300px"
                },

                {
                  name: "createdAt",
                  component: "ListItemDate",
                  flexBasis: "100px"
                }
              ]}
              objects={props.data}
              total={props.count}
              onPage={page => props.onPage(page)}
              flex={1}/>
      </Box>
    </Box>
  );
});

