import React                           from "react";
import { useMemo }                     from "react";
import { useMutation }                 from "@apollo/client";
import { gql }                         from "@apollo/client";
import { useReactiveVar }              from "@apollo/client";
import { useQuery }                    from "@apollo/client";
import { useAlert }                    from "@relcu/ui";
import { deviceVar }                   from "../../../../reactiveVars";
import { toNodeId }                    from "../../../../utils/helpers";
import { SendPhoneMessageVariables }   from "../../../__types__/SendPhoneMessage";
import { SendPhoneMessage }            from "../../../__types__/SendPhoneMessage";
import { useMessageTemplates }         from "../../../Layout/View/MessageView/useMessageTemplates";
import { SEND_MESSAGE }                from "../../../operations.graphql";
import { GetContactNodeInfoVariables } from "./__types__/GetContactNodeInfo";
import { GetContactNodeInfo }          from "./__types__/GetContactNodeInfo";
import { SmsReplayUser }               from "./__types__/SmsReplayUser";

const SMS_REPLAY_USER = gql`
  query SmsReplayUser {
    viewer {
      user {
        id
        firstName
        lastName
        nmlsId
        calendar
        email
        team
        applyUrl
        custom1
        custom2
        custom3
        custom4
        custom5
        custom6
        custom7
        custom8
        custom9
        custom10
      }
    }
  }
`;

export function useSmsReply(props) {
  const { error } = useAlert();
  const [menus, setMenus] = React.useState<{ [ key: string ]: DOMRect }>({});
  const { active, hybridCalls } = useReactiveVar(deviceVar);
  const { data: { viewer: { user } } } = useQuery<SmsReplayUser>(SMS_REPLAY_USER, {
    fetchPolicy: "cache-only"
  });
  const { data: { node } = {} } = useQuery<GetContactNodeInfo, GetContactNodeInfoVariables>(GET_CONTACT_NODE_INFO, {
    skip: !hybridCalls?.initialDisplayCall?.objectId,
    variables: {
      id: toNodeId({ className: "Contact", objectId: hybridCalls?.initialDisplayCall?.objectId })
    }
  });
  const templateFilters = useMemo(() => {
    const filters: Record<string, object> = {
      type: {
        equalTo: "call"
      },
      enabled: { equalTo: true },
      availableFor: {
        in: ["manual"]
      },
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: user.id
              }
            }
          }
        }
      ]
    };
    return filters;
  }, [user.id]);

  //todo need to check applyTo here ???
  const [send] = useMutation<SendPhoneMessage, SendPhoneMessageVariables>(SEND_MESSAGE);
  const { templates } = useMessageTemplates(templateFilters);

  return {
    menus,
    get templates() {
      return templates["toSorted"]((t) => {
        return t.node.owner?.objectId ? -1 : 1;
      });
    },
    async send(templateNode) {
      try {
        props?.onClick();
        active.reject();
        let variables = {
          from: hybridCalls?.called.number,
          to: hybridCalls?.caller.number,
          templateId: templateNode.objectId,
          message: templateNode.text
        };
        if (hybridCalls?.initialDisplayCall?.objectId) {
          variables[ "contactId" ] = hybridCalls.initialDisplayCall.objectId;
        }

        if (hybridCalls?.initialDisplayCall?.scopeClassName && hybridCalls?.initialDisplayCall?.scopeId) {
          variables[ "scopeId" ] = toNodeId({ className: hybridCalls.initialDisplayCall.scopeClassName, objectId: hybridCalls.initialDisplayCall.scopeId });
        }
        await send({
          variables
        });
      } catch (e) {
        active.drop();
        error(e);
      }
    },
    onOpen(event, name) {
      setMenus({
        ...menus,
        [ name ]: menus[ name ] ? null : event.currentTarget.getBoundingClientRect()
      });
    },
    onClose(name) {
      setMenus({
        ...menus,
        [ name ]: null
      });
    }
  };
}

const GET_CONTACT_NODE_INFO = gql`
  query GetContactNodeInfo($id: ID!) {
    node(id: $id){
      ...on Contact{
        firstName
        lastName
        middleName
        objectName
      }
    }
  }
`;
