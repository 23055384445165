import { useReactiveVar } from "@apollo/client";
import { makeVar }        from "@apollo/client";
import { useMemo }        from "react";

const STORAGE_KEY = "@storage";
const getInitialValue = (initialValue) => {
  try {
    const item = window.localStorage.getItem(STORAGE_KEY);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    console.log(error);
    return initialValue;
  }
};
export const storageVar = makeVar(getInitialValue({}));
export function useReactiveStorage(key: string, initialValue?) {
  const storage = useReactiveVar(storageVar);
  const storedValue = useMemo(() => storage[ key ] ?? initialValue, [storage]);
  const setValue = (value) => {
    try {
      const storage = storageVar();
      let storeValue;
      if (value instanceof Function) {
        storeValue = { ...storage, [ key ]: value(storage[ key ]) };
      } else {
        storeValue = { ...storage, [ key ]: value };
      }
      storageVar(storeValue);
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(storeValue));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
