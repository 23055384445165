import { useField }            from "@relcu/form";
import { useEffect }           from "react";
import { useContext }          from "react";
import { FunctionComponent }   from "react";
import React                   from "react";
import { LoanCriteriaContext } from "../LoanCriteriaProvider";

export function withCalc<P extends { name: string }>(Component: FunctionComponent<P>) {
  return function WithCalcHok(props) {
    const { calculate } = useContext(LoanCriteriaContext);
    const { input: { value } } = useField(props.name, { subscription: { value: true } });
    useEffect(() => {
      calculate(props.name);
    }, [value, props.name]);
    return <Component {...props}/>;
  };

}
