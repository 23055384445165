import { classNames }              from "../../..";
import { InputHTMLAttributes }     from "react";
import { FC }                      from "react";
import React                       from "react";
import { Box }                     from "../../Box";
import { BoxComponentProps }       from "../../Box";
import { Label }                   from "../../Label";
import { OutlineTextFieldClasses } from "./OutlineTextFieldClasses";

// InputHTMLAttributes<HTMLInputElement>
export interface OutlineTextFieldProps extends BoxComponentProps {
  label: string
  value: string
  onChange?: (e) => void
  onKeyPress?: (e) => void
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  InputProps?: { [ key: string ]: any; };
  placeholder?: string
}

export const OutlineTextFiled: FC<OutlineTextFieldProps> = React.memo(function OutlineTextFiled(props) {
  const { label, className, inputProps, InputProps, value, onChange, onKeyPress, placeholder, ...p } = props;
  const classes = classNames(OutlineTextFieldClasses.OutlineTextField, className);
  return <Box container gap={"XS"} className={classes} alignItems={"center"} {...InputProps} {...p}>
    {
      label &&
      <Label>
        {label}
      </Label>
    }
    <input type="text" onChange={onChange} value={value} onKeyPress={onKeyPress}
           placeholder={placeholder} {...inputProps}/>
  </Box>;
});
