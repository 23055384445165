import React                          from "react";
import { ChipsProps }                 from "../../..";
import { Chips }                      from "../../..";
import { ChipsSizes }                 from "../../..";
import { Box }                        from "../../..";
import { ChipsColors }                from "../../..";
import { BoxComponentProps, Tooltip } from "../../..";
import { Alignment }                  from "../../..";

export interface ListItemChipsProps extends BoxComponentProps {
  value: string
  label?: string
  color?: ChipsColors
}
export function ListItemChips(props: ListItemChipsProps &  Partial<ChipsProps>) {
  const { className, value, flexBasis, flexGrow, flexShrink, flex, label, color, ...p } = props;
  return <Box
    container
    justify={"start"}
    className={className}
    flexBasis={flexBasis}
    flexShrink={flexShrink}
    flexGrow={flexGrow}
    flex={flex}>
    <Tooltip title={label} alignment={Alignment.Top}>
      <Chips size={ChipsSizes.Small} color={color || ChipsColors.Grey}
                                                            label={value} {...p}/>
    </Tooltip>
  </Box>;
}
