import React                     from "react";
import { useBoxProps }           from "../../..";
import { ItemsEllipsis }         from "../../..";
import { ChipsVariants }         from "../../..";
import { FontIcon }              from "../../..";
import { checkType }             from "../../..";
import { Box }                   from "../../Box";
import { ChipsColors }           from "../../Chips";
import { Chips }                 from "../../Chips";
import { Label }                 from "../../Label";
import { useConsumeField }       from "../BaseField";
import { BaseFieldClasses }      from "../BaseField";
import { MultiChoiceFieldProps } from "./MultiChoiceField";

export const MultiChoiceReadField = React.memo(function MultiChoiceReadField() {
  const { input: { readPlaceholder, label, required, value, options, read, write, ...multiChoiceProps } } = useConsumeField<MultiChoiceFieldProps>();
  function checkValue(item) {
    const selected = typeof item == "object" ? item[ "value" ] : item;
    return options.find(o => typeof o == "object" ? o[ "value" ] == selected : o == selected);
  }

  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(multiChoiceProps)}>
      <Label>{label}</Label>
      <Box container wrap={"wrap"} gap={"XXXS"}>
        {
          value?.length <= 0 ? (readPlaceholder ? readPlaceholder : "-") :
            <ItemsEllipsis
              showLength={2}
              items={value}
              renderItem={(item, index) =>
                <Chips key={index}
                       color={ChipsColors.Grey}
                       label={item && checkType(checkValue(item), "label") || (readPlaceholder ? readPlaceholder : "-")}
                       disabled={true}/>}
            />
        }
      </Box>
    </Box>
  );
});

export default MultiChoiceReadField;
