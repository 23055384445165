import { useMemo }                    from "react";
import { useEffect }                  from "react";
import { gql }                        from "@apollo/client";
import { useQuery }                   from "@apollo/client";
import { useSource }                  from "@relcu/ui";
import { SubscriptionEvent }          from "../../../../types/graphql-global-types";
import { ACTIVITY_FRAGMENT }          from "../../../operations.graphql";
import { GetLastActivitiesVariables } from "./__types__/GetLastActivities";
import { GetLastActivities }          from "./__types__/GetLastActivities";
import { SubscribeLastActivities }    from "./__types__/SubscribeLastActivities";
import { SubscribeLastActivitiesVariables } from "./__types__/SubscribeLastActivities";

export function useActivityWidget() {
  const { $object: node, $viewer } = useSource();
  const { queryVariables, subscriptionVariables } = useMemo(() => createVariables(node, $viewer), [node, $viewer]);
  const {
    data: { activities: { edges = [] } = {} } = {},
    subscribeToMore
  } = useQuery<GetLastActivities, GetLastActivitiesVariables>(GET_LAST_ACTIVITIES, {
    variables: {
      where: queryVariables.where,
      first: 4
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    return subscribeToMore<SubscribeLastActivities, SubscribeLastActivitiesVariables>({
      document: SUBSCRIBE_LAST_ACTIVITIES,
      variables: subscriptionVariables,
      updateQuery(prev, { subscriptionData: { data: { activities: { event, node } } } }) {
        switch (event) {
          case SubscriptionEvent.CREATE: {
            const pageInfo = prev.activities?.pageInfo;
            if (!prev.activities.edges.find(({ node: n }) => n.objectId == node.objectId)) {
              return {
                ...prev,
                activities: {
                  ...prev.activities,
                  pageInfo: {
                    ...pageInfo,
                    hasNextPage: pageInfo.hasNextPage || false,
                    hasPreviousPage: pageInfo.hasPreviousPage || false,
                    startCursor: pageInfo.startCursor || null,
                    endCursor: pageInfo?.endCursor || null
                  },
                  edges: [
                    {
                      __typename: "ActivityEdge",
                      cursor: null,
                      node
                    },
                    ...prev.activities.edges
                  ]
                }
              };
            }
            return prev;
          }
        }
        return prev;
      }
    });
  }, [node.objectId,subscriptionVariables]);
  return edges.slice(0, 4);
}

const createVariables = (node, viewer) => {
  let contains = [node.objectId]
  if (node.__typename === "Contact" && viewer.role !== "admin") {
    contains.push(viewer.objectId)
  }
  return {
    queryVariables: {
      where: {
        relatedTo: {
          contains: contains
        }
      }
    },
    subscriptionVariables: {
      where: {
        relatedTo: {
          contains: contains
        }
      }
    }
  };
};

const GET_LAST_ACTIVITIES = gql`
  query GetLastActivities($where: ActivityWhereInput!, $first:Int!) {
    activities(where: $where, order: createdAt_DESC,first: $first) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...Activity
        }
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
const SUBSCRIBE_LAST_ACTIVITIES = gql`
  subscription SubscribeLastActivities($where: ActivitySubscriptionWhereInput) {
    activities(where: $where) {
      event
      node {
        ...Activity
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
