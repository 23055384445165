export const replacementParamDropdown = (replacements) => {
  const groupedReplacementParamsList = Object[ "groupBy" ](replacements || [], ({ role }) => role);
  const dropDownHTML = `
    <ul class="se-insert-replacement-list">
      ${
          Object.keys(groupedReplacementParamsList).map(key => {
            return `<details>
                        <summary class="section-title">
                          ${key}
                          <svg width="1em" height="1em" viewBox="0 0 21 32" fill="currentColor" aria-hidden="true"
                               focusable="false" class="rs-picker-menu-group-caret rs-icon" aria-label="angle down"
                               data-category="legacy">
                            <path
                              d="M19.196 13.143a.612.612 0 01-.179.411l-8.321 8.321c-.107.107-.268.179-.411.179s-.304-.071-.411-.179l-8.321-8.321c-.107-.107-.179-.268-.179-.411s.071-.304.179-.411l.893-.893a.582.582 0 01.411-.179c.143 0 .304.071.411.179l7.018 7.018 7.018-7.018c.107-.107.268-.179.411-.179s.304.071.411.179l.893.893a.617.617 0 01.179.411z"/>
                          </svg>
                        </summary>
                        ${
              groupedReplacementParamsList[ key ].map(params => {
                return `<li data-key="${params.value}" class="se-menu-item">${params.label}</li>`;
              }).join(" ")
            }
                    </details>`;
          }).join(" ")
      }
    </ul>
  `;

  return {
    name: "replacementParams",
    display: "submenu",
    title: "Insert replacement parameters",
    buttonClass: "se-insert-replacement",
    innerHTML: "<svg focusable=\"false\" aria-hidden=\"true\" viewBox=\"0 0 24 24\" data-testid=\"AddCircleOutlineOutlinedIcon\"><path d=\"M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z\"></path></svg><span>Replacement</span>",
    add: function (this: any, core, targetElement) {
      const context = core.context;
      context.replacements = {
        targetButton: targetElement,
        currentSpan: null
      };
      let listDiv = this.setSubmenu(core);
      const items = listDiv.querySelectorAll(".se-insert-replacement-list li");
      for (let item of items) {
        item.addEventListener("click", this.onClick.bind(core));
      }
      core.initMenuTarget(this.name, targetElement, listDiv);
    },
    setSubmenu: function (core) {
      const listDiv = core.util.createElement("DIV");
      listDiv.className = "se-menu-container se-submenu se-list-layer";
      listDiv.innerHTML = dropDownHTML;
      return listDiv;
    },

    onClick: function (this: any, event) {
      const value = event.target.getAttribute("data-key");
      if (!value) {
        return;
      }
      this.functions.insertHTML(value, true);
      this.submenuOff();
    }
  };
};
