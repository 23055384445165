import { classNames as clsNames } from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import React                      from "react";
import { FC }                     from "react";
import { PmiDialogClasses }       from "./PmiDialogClasses";

export const PmiPartner: FC<BoxComponentProps> = React.memo(function PmiPartner(props) {
  const { className, ...p } = props;
  const classNames = clsNames(PmiDialogClasses.PmiPartner, className);
  return <Box container alignItems={"center"} className={classNames} {...p}/>;
});

export interface PmiPartnerItemProps extends BoxComponentProps {
  title: string
  value: string
}
export const PmiPartnerItem: FC<PmiPartnerItemProps> = React.memo(function PmiPartnerItem(props) {
  const { title, value, ...p } = props;
  return <Box container alignItems={"center"} gap={"XXXS"} {...p}>
    <p className={PmiDialogClasses.PmiPartnerItemTitle}>{title}</p>
    <p className={PmiDialogClasses.PmiPartnerItemValue}>{value}</p>
  </Box>;
});

