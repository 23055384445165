import React                     from "react";
import { FC }                    from "react";
import { useClassNames }         from "@relcu/rc";
import { TypographyLine }        from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { NotificationIconColor } from "@relcu/ui";
import { Notification }          from "@relcu/ui";
import { NotificationHeader }    from "@relcu/ui";
import { NotificationContent }   from "@relcu/ui";
import { TypographyColor }       from "@relcu/ui";
import { TypographySize }        from "@relcu/ui";
import { Typography }            from "@relcu/ui";
import { NotificationProps }     from "../NotificationBar";
import "../notification-bar.css";

export const DistributionLeadNotification: FC<NotificationProps> = React.memo(function DistributionLeadNotification(props) {
  const { onNavigate, className, inApp, node } = props;
  const { merge } = useClassNames("");
  const classes = merge(className, "distribution-new-lead");
  return (
    <Notification onNavigate={onNavigate} className={classes}>
      <NotificationHeader inApp={inApp} title={"New Lead"} icon={"rc_lead"} color={NotificationIconColor.White}/>
      <NotificationContent inApp content={<Box container gap={"XXXS"}>
        <Typography
          color={inApp && TypographyColor.White}
          lineHeight={inApp && TypographyLine.LineBody1}
          size={inApp ? TypographySize.Text : TypographySize.TextSmall}
        >
          {node.scope.objectName} is assigned to you through {node.record.objectName}
        </Typography>
      </Box>}/>
    </Notification>
  );
});
