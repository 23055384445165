import React                from "react";
import { FC }               from "react";
import { formatNumber }     from "../../../../utils/helpers";
import { Box }              from "../../../Box";
import { TypographyWeight } from "../../../Typography";
import { Typography }       from "../../../Typography";
import { ICellProps }       from "../ICellProps";

export interface CurrencyCellProps extends ICellProps {
  precision: number,
  variant?: "default" | "bold"
}
export const CurrencyCell: FC<CurrencyCellProps> = React.memo(function TextCell({ rowData, precision, getCellValue, dataKey, defaultValue, variant = "default", className,...props }) {
  const currency = getCellValue(rowData, dataKey);
  return <Box className={className}>
      <Typography weight={variant === "bold" && TypographyWeight.Bold}>
        {currency ? `$ ${formatNumber(currency, precision ?? 2)}` : defaultValue ?? ""}
      </Typography>
    </Box>
});