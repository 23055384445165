import React                    from "react";
import { InputState }           from "../../Input/Input";
import { MultiInput }           from "../../Input/MultiInput";
import { useConsumeField }      from "../BaseField";
import { MultiUrlFieldProps }   from "./MultiUrlField";

export const MultiUrlEditField = React.memo(function MultiUrlEditField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField<MultiUrlFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  return (
    <MultiInput
      {...input}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
    />
  );
});
export default MultiUrlEditField;
