import React                 from "react";
import { FC }                from "react";
import { BoxComponentProps } from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { useBoxProps }       from "@relcu/ui";
import { TypographyWeight }  from "@relcu/ui";
import { Typography }        from "@relcu/ui";

interface ColumnContainerProps extends BoxComponentProps {
  title: string;
}

export const ColumnContainer: FC<ColumnContainerProps> = React.memo(function ColumnContainer(props) {
  const { title, children, ...p } = props;
  return <Box container direction={"column"} gap={"S"} {...useBoxProps(p)}>
    <Typography weight={TypographyWeight.Medium}>
      {title}
    </Typography>
    <Box container style={{overflow:"hidden"}} direction={"column"} gap={"XS"} flex={1}>
      {children}
    </Box>
  </Box>;
});