import { MutableRefObject } from "react";
import React                from "react";

export function useInfiniteScroll({
  hasMore,
  loading,
  distance = 250
}): [
  number,
  MutableRefObject<HTMLDivElement>,
  MutableRefObject<HTMLDivElement>
] {
  const scrollContainerRef = React.useRef<HTMLDivElement>();
  const loaderRef = React.useRef<HTMLDivElement>();
  const [page, setPage] = React.useState(0);
  React.useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    const scrollContainerNode = scrollContainerRef.current;
    if (!scrollContainerNode || !loaderNode || !hasMore) {
      return;
    }

    const options = {
      root: scrollContainerNode,
      // threshold:1,
      rootMargin: `0px 0px ${distance}px 0px`
    };

    const listener = entries => {
      entries.forEach(
        ({ isIntersecting }) => {
          if (isIntersecting && !loading) {
            setPage(page => page + 1);
          }
        }
      );
    };

    const observer = new IntersectionObserver(listener, options);
    observer.observe(loaderNode);

    return () => observer.disconnect();
  }, [hasMore, loading]);

  return [page, loaderRef, scrollContainerRef];
}
