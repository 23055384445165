import React                 from "react";
import { FC }                from "react";
import { createContext }     from "react";
import { useEffect }         from "react";
import { useFragment }       from "@apollo/client";
import { DeepPartial }       from "@apollo/client/utilities";
import { useParams }         from "@relcu/react-router";
import { emailDraftsVar }    from "../../../../../reactiveVars";
import { mailInputVar }      from "../../../../../reactiveVars";
import { toNodeId }          from "../../../../../utils/helpers";
import { EmptyConversation } from "../../../../EmptyConversation/EmptyConversation";
import { MailConversation }  from "../__types__/MailConversation";
import { MAIL_CONVERSATION } from "../MailView";
import { Bulk }              from "./Bulk";
import { Thread }            from "./Thread";

export interface ContentProps {
  expand: boolean,
  setExpand: React.Dispatch<any>,
  draftId?: string
  firstConversationId?: string
}

export const ContentContext = createContext<{ conversation: DeepPartial<MailConversation> }>(null);

export const Content: FC<ContentProps> = React.memo(function Content(props) {
  const { expand, setExpand, draftId } = props;
  useEffect(() => {
    return () => {
      mailInputVar(null);
      emailDraftsVar({});
    };
  }, []);
  const params = useParams();
  const [objectId] = params[ "*" ].split("/");

  const { data: conversation = {} } = useFragment<MailConversation>({
    fragment: MAIL_CONVERSATION,
    fragmentName: "MailConversation",
    from: {
      __typename: "MailConversation",
      id: toNodeId({ className: "MailConversation", objectId })
    }
  });

  return !Object.keys(conversation).length ?
    <EmptyConversation icon={"email"} text={"Select email to see conversation."}/>
    :
    <ContentContext.Provider value={{ conversation }}>
      {
        conversation?.type == "bulk" ?
          <Bulk expand={expand} onToggle={() => setExpand(expand => !expand)}
                {...props}/>
          :
          <Thread
            firstConversationId={props.firstConversationId}
            expand={expand}
            onToggle={() => setExpand(expand => !expand)}
            key={conversation?.objectId}
          />
      }
    </ContentContext.Provider>;
});
