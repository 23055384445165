import { FC }               from "react";
import React                from "react";
import { FieldInputProps }  from "@relcu/final-form";
import { FieldRenderProps } from "@relcu/form";
import { BaseFieldProps }   from "./BaseFieldProps";
import { useBaseField }     from "./useBaseField";

export const BaseField = React.memo<BaseProps>(function BaseField(props) {
  const { readView, editView, view = "write", ...p } = props;
  const field = useBaseField<BaseFieldProps>(p);
  return (
    <BaseFieldProvider field={field}>
      {view === "write" && editView}
      {view === "read" && readView}
    </BaseFieldProvider>
  );
});
export const BaseFieldProvider: FC<{ field: FieldContext }> = React.memo(function BaseFieldProvider(props) {
  return (
    <BaseFieldContext.Provider value={props.field}>
      {props.children}
    </BaseFieldContext.Provider>
  );
});
export const BaseFieldContext = React.createContext<FieldContext<any>>(null);
export interface FieldContext<T extends BaseFieldProps = BaseFieldProps, FieldValue = any> extends FieldRenderProps<FieldValue> {
  input: T & FieldInputProps<FieldValue, HTMLElement>;
}
export interface BaseProps extends BaseFieldProps {
  readView: React.ReactNode
  editView: React.ReactNode
}
