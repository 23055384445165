import React                  from "react";
import { FC }                 from "react";
import { ReactElement }       from "react";
import { useCallback }        from "react";
import { getIn }              from "@relcu/form";
import { Alignment }          from "../../constants/Alignemnt";
import { useImperativeState } from "../../hooks/useImperativeState";
import { Button }             from "../Button";
import { ButtonProps }        from "../Button";
import { FontIcon }           from "../Icon";
import { toString }           from "../Input/MaskInput/utils/helpers";
import { MenuItem }           from "../Menu";
import { Menu }               from "../Menu";
import { Popper }             from "../Popper";
import { Tooltip }            from "../Tooltip";

interface DropDownItem {
  [ key: string ]: any;
}

interface DropDownProps extends ButtonProps {
  alignment?: Alignment;
  renderSelect?: (active: boolean) => ReactElement;
  renderOptionValue?(option: any);
  onSelect(DropDownItem);
  options: (DropDownItem | string)[];
  labelKey?: string;
  tooltip?: string;
}

export const DropDown: FC<DropDownProps> = React.memo(function DropDown(props) {
    const { variant, color, icon, renderSelect, onSelect, options, labelKey, alignment, renderOptionValue, tooltip, disabled, ...p } = props;
    const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
    const togglePopper = useCallback((event) => {
      setAnchorBounding(!anchorBounding ? event.currentTarget.getBoundingClientRect() : null);
    }, [anchorBounding]);

    const renderCustomSelect = useCallback(() => {
      const element = renderSelect(!!anchorBounding);
      return React.cloneElement(
        element,
        {
          onClick: (e) => {
            if (disabled) {
              return;
            }
            element.props.onClick?.(e);
            togglePopper(e);
          }, ...p
        });
    }, [anchorBounding, props]);

    return (
      <>
        {
          renderSelect ?
            tooltip ?
              <Tooltip title={tooltip} alignment={Alignment.Right}>
                {renderCustomSelect()}
              </Tooltip>
              : renderCustomSelect()
            :
            <Button
              variant={variant}
              color={color}
              onlyIcon
              icon={icon}
              onClick={togglePopper}
              {...p}
            />
        }
        <Popper
          open={!!anchorBounding}
          anchorBounding={anchorBounding}
          onClickAway={() => setAnchorBounding(null)}
          alignment={alignment}
          threshold={6}>
          <Menu
            type={"select"}
            height={44}
            role="listbox">
            {
              options.map((option, index) => {
                const styles = typeof option != "string" && option.value == "delete" ? { color: "var(--color-error)" } : {};
                return <MenuItem key={index}
                                 thumbnail={typeof option != "string" && option.icon &&
                                 <FontIcon type={option.icon} style={styles}/>}
                                 onClick={(e) => {
                                   togglePopper(e);
                                   onSelect?.(option);
                                 }}>
                  {renderOptionValue ? renderOptionValue(option) : typeof option == "string" ? option : toString(getIn(option, labelKey))}
                </MenuItem>;
              })
            }
          </Menu>
        </Popper>
      </>
    );
  }
);

DropDown.defaultProps =
  {
    labelKey: "label",
    icon: "add",
    alignment: Alignment.BottomRight
  };
