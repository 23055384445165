import React, { createContext, useState } from "react";
import { classNames }                     from "../..";
import { Box }                            from "../Box";
import { BoxComponentProps }              from "../Box";
import { useConstant }                    from "./useConstant";
import { TabClasses }                     from "./TabClasses";

export const TabsState = createContext(null);
export const Elements = createContext(null);

export enum TabsPosition {
  TOP = "top",
  BOTTOM = "bottom"
}

export interface TabContainerProps extends BoxComponentProps {
  outerState?: any
  position?: TabsPosition
  activeIndex?: number
}

TabContainer.defaultProps = {
  position: TabsPosition.TOP
};
export function TabContainer(props: TabContainerProps) {
  const { className, children, outerState, position, ...p } = props;
  const classes = classNames(TabClasses.TabContainer, className);
  const elements = useConstant(() => ({ tabs: 0, panels: 0 }));
  const innerState = useState(0);
  const state = outerState || innerState;
  return (
    <Box container direction={position == TabsPosition.TOP ? "column-reverse" : "column"} gap={"XXS"}
         className={classes} {...p}>
      <Elements.Provider value={elements}>
        <TabsState.Provider value={state}>
          {children}
        </TabsState.Provider>
      </Elements.Provider>
    </Box>
  );
}
