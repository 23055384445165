import { useFieldArray }       from "@relcu/form";
import { UseFieldArrayConfig } from "@relcu/form";
import { useMemo }             from "react";
import { BaseFieldProps }      from "./BaseFieldProps";
import { getFieldProps }       from "./getFieldProps";

export function useBaseArrayField<T extends BaseFieldProps>(options: T, mergeOptions?: UseFieldArrayConfig<any>) {
  const props = {
    ...options,
    ...mergeOptions
  };
  const [config, input] = useMemo(() => getFieldProps<T>(props), [props]);
  const field = useFieldArray(props.name, config);
  return {
    ...field,
    input
  };
}
