import { useQuery }                 from "@apollo/client";
import { GetUnreadNotifications }   from "./__types__/GetUnreadNotifications";
import { GET_UNREAD_NOTIFICATIONS } from "./operations.graphql";

export function useUnreadNotifications(): GetUnreadNotifications["notifications"] {
  const { data: { notifications } = {} } = useQuery<GetUnreadNotifications>(GET_UNREAD_NOTIFICATIONS, {
    fetchPolicy: "cache-only",
  });
  return notifications;
}
