import { useReactiveVar }            from "@apollo/client";
import { useContext }                from "react";
import React                         from "react";
import { FC }                        from "react";
import { FontIcon }                  from "@relcu/ui";
import { classNames }                from "@relcu/ui";
import { Box }                       from "@relcu/ui";
import { Accordion }                 from "@relcu/ui";
import { CommonClasses }             from "@relcu/ui";
import { CurrencyField }             from "@relcu/ui";
import { Where }                     from "@relcu/ui";
import { FormSummary }               from "@relcu/ui";
import { PersistContext }            from "../PersistProvider";
import { RateClasses }               from "../Rate/RateClasses";
import { RateSelectBody }            from "../Rate/RateSelect";
import { WHEN_STREAMLINE_WO_CREDIT } from "../RateConditions";
import { CREDIT_REPORT_SUM_FIELDS }  from "../RateConditions";

export const CreditReport: FC<any> = React.memo(function CreditReport(props) {
  const { leadDataVar } = useContext(PersistContext);
  const lead = useReactiveVar(leadDataVar);
  return <Accordion className={RateClasses.RateSelect} openClass={RateClasses.RateSelectOpen} flex={1} header={(open) =>
    <Box container alignItems={"center"} gap={"XS"} flex={1} className={classNames(RateClasses.RateSelectHeader, {
      [ RateClasses.RateSelectExtended ]: open
    })}>
      <FontIcon
        className={classNames(CommonClasses.GrayIcon, {
          [ CommonClasses.PrimaryIcon ]: open
        })}
        alignSelf={"center"}
        type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
      <p className={RateClasses.RateSelectTitle} style={{ flexGrow: 1 }}>
        Credit report and other fees
      </p>
      <p className={RateClasses.RateSelectSum}>
        <FormSummary fields={CREDIT_REPORT_SUM_FIELDS}/>
      </p>

    </Box>
  }>
    <RateSelectBody divider direction={"row"} wrap={"wrap"}>
      <Where conditions={WHEN_STREAMLINE_WO_CREDIT} source={{ $object: lead }}>
        <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                       name={"creditReportFee"}
                       label={"Credit report fee"}/>
      </Where>
      <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"} name={"loanSafeFee"}
                     label={"LoanSafe fee"}/>
      <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"} name={"mersFee"}
                     label={"MERS fee"}/>
      <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                     name={"verificationFee"}
                     label={"Verification fee"}/>
      <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"}
                     name={"electronicDocumentDelivery"}
                     label={"Electronic document delivery"}/>
      <CurrencyField view={"write"} disabled precision={2} flexBasis={"calc(50% - ( 16px / 2 ))"} name={"transcriptFee"}
                     label={"Transcript fee"}/>
    </RateSelectBody>
  </Accordion>;
});
