import { useRef }              from "react";
import React                   from "react";
import { useMemo }             from "react";
import { useImperativeHandle } from "react";
import { ReactNode }           from "react";
import { useCallback }         from "react";
import { SelectVariants }      from "../../..";
import { IconType }            from "../../..";
import { BoxComponentProps }   from "../../..";
import { SelectClasses }       from "../../..";
import { BaseInputClasses }    from "../../..";
import { Alignment }           from "../../..";
import { useImperativeState }  from "../../..";
import { Box }                 from "../../..";
import { BaseInputProps }      from "../../..";
import { classNames }          from "../../..";
import { applyBoxItemStyles }  from "../../..";
import { Popper }              from "../../..";
import { SearchableMenu }      from "../../SearchableMenu";
import { SelectToggle }        from "../Select/SelectToggle/SelectToggle";
import { useCommonSelect }     from "../Select/useCommonSelect";

export interface SearchableSelectProps extends BaseInputProps, BoxComponentProps {
  label?: string;
  alignment?: Alignment;
  message?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  variant?: SelectVariants,
  keepErrorSpace?: boolean;
  readOnly?: boolean;
  showLabel?: boolean;
  style?: any;
  menuProps?: { [ key: string ]: any };
  searchText: string;
  clearable?: boolean;
  renderSelect?: (option) => ReactNode;
  value?: string | number | object;
  optionKey?: string;
  optionLabel?: string;
  options?: any[];
  loading?: boolean;
  searchable?: boolean;
  renderOption?: (option) => ReactNode;
  onChange?(value);
  onType?(value: string, e),
  onCreateNew?(),
  selectedFilter?: boolean
  onSelectedFilter?(filtered: boolean)
  onLoadMoreHandler?: React.ReactElement
  icon?: IconType;
}

export const SearchableSelect = React.forwardRef(function SearchableSelect(props: SearchableSelectProps, ref) {
  let properties = applyBoxItemStyles<SearchableSelectProps>(props);
  const {
    label,
    className,
    required,
    disabled,
    message,
    readOnly,
    state,
    placeholder,
    searchPlaceholder,
    options,
    value,
    optionKey,
    optionLabel,
    onCreateNew,
    loading,
    onType,
    searchText,
    halfSize,
    fullSize,
    icon,
    renderOption,
    renderSelect,
    onChange,
    alignment,
    clearable,
    style,
    keepErrorSpace,
    variant,
    menuProps = {},
    selectedFilter,
    onLoadMoreHandler,
    onSelectedFilter
  } = properties;
  const {
    showSelectedField,
    handleKeyPress,
    togglePopper,
    handleSelect,
    checkValue,
    valueExist,
    selected,
    anchorBounding,
    setAnchorBounding
  } = useCommonSelect({ value, onChange, renderSelect, optionLabel, optionKey, options, placeholder });
  const [search, setSearch] = useImperativeState(searchText, onType);
  const disabledMod = useMemo(() => disabled || readOnly, [disabled, readOnly]);

  const handleClose = useCallback(() => {
    setAnchorBounding(null);
  }, [setAnchorBounding, anchorBounding]);

  useImperativeHandle(ref, () => ({
    handleClose
  }));

  const handleClear = useCallback((e) => {
    e.stopPropagation();
    handleSelect(null);
  }, [handleSelect]);

  const handleCreateNew = () => {
    handleClose();
    onCreateNew();
  };
  const toggleRef = useRef(null)
  return (
    <Box className={classNames(SelectClasses.Select, {
      [ BaseInputClasses.HalfSize ]: halfSize,
      [ BaseInputClasses.FullSize ]: fullSize
    }, className)} style={style}>
      <SelectToggle
        ref={toggleRef}
        keepErrorSpace={keepErrorSpace}
        mode={"default"}
        direction={"column"}
        label={label}
        showLabel={true}
        required={required}
        variant={variant}
        color={null}
        size={null}
        anchorBounding={anchorBounding}
        disabled={disabled}
        readOnly={readOnly}
        state={state}
        disabledMod={disabledMod}
        valueExist={valueExist}
        checkValue={checkValue}
        handleKeyPress={handleKeyPress}
        count={options.length}
        togglePopper={togglePopper}
        message={message}
        handleClear={(clearable && selected) ? handleClear : null}
        showSelectedField={showSelectedField}
        icon={icon}
        className={className}
      />
      <Popper
        alignment={alignment}
        open={!!anchorBounding}
        anchorBounding={anchorBounding}
        onClickAway={handleClose}>
        <SearchableMenu
          width={(menuProps.width ?? toggleRef?.current?.clientWidth) as number}
          {...menuProps}
          onLoadMoreHandler={onLoadMoreHandler}
          selectedFilter={selectedFilter}
          onSelectedFilter={onSelectedFilter}
          renderOption={renderOption}
          options={options}
          selected={selected}
          onSelect={handleSelect}
          placeholder={searchPlaceholder}
          searchText={search}
          optionKey={optionKey}
          optionLabel={optionLabel}
          onCreateNew={!!onCreateNew && handleCreateNew}
          loading={loading}
          onType={setSearch}
        />
      </Popper>
    </Box>
  );
});

SearchableSelect.defaultProps = {
  alignment: Alignment.BottomLeft,
  variant: SelectVariants.Outline,
  clearable: true,
  showLabel: true,
  optionLabel: "label",
  optionKey: "value"
};
