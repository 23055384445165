import React                 from "react";
import { FC }                from "react";
import { useEffect }         from "react";
import { useMemo }           from "react";
import { useState }          from "react";
import { classNames }        from "../../../../utils/classNames";
import { Badge }             from "../../../Badge";
import { BadgeColors }       from "../../../Badge";
import { BadgeVariants }     from "../../../Badge";
import { Box }               from "../../../Box";
import { BoxComponentProps } from "../../../Box";
import { ListItemDate }      from "../../../List";
import { ListRow }           from "../../../List";
import { TabContainer }      from "../../../Tab";
import { TabItem }           from "../../../Tab";
import { Tabs }              from "../../../Tab";
import { TabPanel }          from "../../../Tab";
import { TabPanelContainer } from "../../../Tab";

export interface RelcuMilestoneTask {
  milestoneLosId?: string
  losId: string;
  completed: boolean;
  title: string;
}

export interface RelcuMilestoneDocument {

  milestoneLosId?: string
  slug?: string
  losId: string;
  title: string;
  status: string;
  receivedDate?: string;
  requestedDate?: string;
  createdDate?: string;
}

export interface RelcuMilestoneInfoProps extends BoxComponentProps {
  comment: string,
  tasks?: RelcuMilestoneTask[],
  documents?: RelcuMilestoneDocument[],
  unread?: boolean;
  onMarkAsRead?();
  // arrowPosition: number;
  losId?:string
}

export const RelcuMilestoneInfo: FC<RelcuMilestoneInfoProps> = React.memo(function RelcuMilestoneInfo(props) {
  const { comment, className, losId, tasks, documents, unread, onMarkAsRead } = props;
  const classes = classNames(RelcuMilestoneInfoClasses.Info, className);
  const passedTasksCount = useMemo(() => tasks?.filter(a => a.completed)?.length, [tasks]);
  const availableTasksCount = useMemo(() => tasks?.length, [tasks]);
  const passedDocumentsCount = useMemo(() => documents?.filter(a => a.status == "received")?.length, [tasks]);
  const availableDocumentsCount = useMemo(() => documents?.length, [documents]);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    setPosition(0);
  }, []);

  useEffect(() => {
    if(unread && position==0) {
      onMarkAsRead?.()
    }
  }, [unread,position,losId]);

  return (
    <Box container direction={"column"} gap={"XXS"} className={classes}
      // style={{ "--arrow-position": `${arrowPosition}px` } as any}
    >
      <TabContainer gap={"S"} outerState={[position, setPosition]}>
        <TabPanelContainer>
          <TabPanel>
            <Box container direction={"column"} flex={1} gap={"XXXS"} className={RelcuMilestoneInfoClasses.Container}>
              {
                comment ?
                  <>
                    {/*<Box container gap={"XXS"}>*/}
                    {/*  <Box flex={1}/>*/}
                    {/*  {*/}
                    {/*    !!date &&*/}
                    {/*    <p className={classNames(CommonClasses.TextTiny, CommonClasses.TextSecondary)}>{date}</p>*/}
                    {/*  }*/}
                    {/*</Box>*/}
                    <Box>
                      <p className={RelcuMilestoneInfoClasses.Text}>{comment}</p>
                    </Box>
                  </>
                  :
                  <Box container direction={"column"} flex={1} justify={"center"} alignItems={"center"} gap={"XXXS"}>
                    <p className={RelcuMilestoneInfoClasses.EmptyTitle}>No note</p>
                    <p className={RelcuMilestoneInfoClasses.EmptyDescription}>Nobody has left note on this milestone yet.</p>
                  </Box>
              }
            </Box>
          </TabPanel>
          <TabPanel>
            <RelcuMilestoneTasks tasks={tasks}/>
          </TabPanel>
          <TabPanel>
            <RelcuMilestoneDocuments documents={documents}/>
          </TabPanel>
        </TabPanelContainer>
        <Tabs>
          <TabItem active={true}>
            NOTE
          </TabItem>
          <TabItem active={false}>
            {`TASKS${availableTasksCount ? ` (${passedTasksCount}/${availableTasksCount})` : ""}`}
          </TabItem>
          <TabItem active={false}>
            {`DOCUMENTS${availableDocumentsCount ? ` (${passedDocumentsCount}/${availableDocumentsCount})` : ""}`}
          </TabItem>
        </Tabs>
      </TabContainer>
    </Box>
  );
});
RelcuMilestoneInfo.defaultProps = {
  tasks: []
};

interface RelcuMilestoneTasksProps extends BoxComponentProps {
  tasks: RelcuMilestoneTask[];
}

export const RelcuMilestoneTasks: FC<RelcuMilestoneTasksProps> = React.memo(function RelcuMilestoneTasks(props) {
  const { tasks } = props;

  return !tasks.length ?
    <Box container direction={"column"} flex={1} gap={"XXS"} className={RelcuMilestoneInfoClasses.Container}>
      <Box container direction={"column"} flex={1} justify={"center"} alignItems={"center"} gap={"XXXS"}>
        <p className={RelcuMilestoneInfoClasses.EmptyTitle}>No tasks</p>
        <p className={RelcuMilestoneInfoClasses.EmptyDescription}>Tasks created in Encompass will appear here.</p>
      </Box>
    </Box> :
    <Box container direction={"column"} gap={"XXS"} flex={1} className={RelcuMilestoneInfoClasses.ListContainer}>
      {
        tasks.map(task => {
          return <ListRow gap={"XXS"} key={task.losId} alignItems={"center"}>
            <Badge
              variant={BadgeVariants.Regular}
              icon={"done"}
              color={task.completed ? BadgeColors.Success : BadgeColors.LightGrey}
            />
            <p className={RelcuMilestoneInfoClasses.TaskText} key={task.losId}>{task.title}</p>
          </ListRow>;
        })
      }
    </Box>;
});
RelcuMilestoneTasks.defaultProps = {
  tasks: []
};

interface RelcuMilestoneDocumentsProps extends BoxComponentProps {
  documents: RelcuMilestoneDocument[];
}

export const RelcuMilestoneDocuments: FC<RelcuMilestoneDocumentsProps> = React.memo(function RelcuMilestoneDocuments(props) {
  const { documents } = props;
  return !documents.length ?
    <Box container direction={"column"} flex={1} gap={"XXS"} className={RelcuMilestoneInfoClasses.Container}>
      <Box container direction={"column"} flex={1} justify={"center"} alignItems={"center"} gap={"XXXS"}>
        <p className={RelcuMilestoneInfoClasses.EmptyTitle}>No documents</p>
        <p className={RelcuMilestoneInfoClasses.EmptyDescription}>Documents added in Encompass will appear here.</p>
      </Box>
    </Box> :
    <Box container direction={"column"} gap={"XXS"} flex={1} className={RelcuMilestoneInfoClasses.ListContainer}>
      {
        documents.map(document => {
          const date = document.status == "received" ? document.receivedDate : document.requestedDate ?? document.createdDate;
          return <ListRow gap={"XXS"} key={document.losId} alignItems={"center"}>
            <Badge
              variant={BadgeVariants.Regular}
              icon={"done"}
              color={document.status == "received" ? BadgeColors.Success : BadgeColors.LightGrey}
            />
            <p className={RelcuMilestoneInfoClasses.TaskText} key={document.losId}>{document.title}</p>
            <Box flex={1}/>
            <ListItemDate format={"onlyDate"} date={date}/>
          </ListRow>;
        })
      }
    </Box>;
});

RelcuMilestoneDocuments.defaultProps = {
  documents: []
};

export const enum RelcuMilestoneInfoClasses {
  Info = "info",
  Title = "info__title",
  Invisible = "info__badge--invisible",
  Container = "info__container",
  Text = "info__text",
  ListContainer = "info__container--list",
  TaskText = "task__text",
  EmptyTitle = "info__title--empty",
  EmptyDescription = "info__description--empty"
}