import { SetStateAction }     from "react";
import { Dispatch }           from "react";
import React                  from "react";
import { useImperativeState } from "../../..";
import { Button }             from "../../..";
import { ButtonVariants }     from "../../..";
import { ButtonColors }       from "../../..";
import { FontIcon }           from "../../..";
import { Box }                from "../../..";
import { IconDirection }      from "../../..";
import { CalendarClasses }    from "../CalendarClasses";

interface MonthHeaderProps {
  onPrev: Dispatch<any>,
  onNext: Dispatch<any>,
  onOpenYears: Dispatch<SetStateAction<boolean>>,
  yearIsOpen: boolean
  monthLabel: string
}

export function MonthHeader(props: MonthHeaderProps) {
  const { onPrev, onNext, yearIsOpen, onOpenYears, monthLabel } = props;
  const [isYearOpen, setOpenYears] = useImperativeState(yearIsOpen, onOpenYears);

  return (
    <Box container alignItems={"center"} justify={"space-between"}
         className={CalendarClasses.CalendarNav}>
      <Box container>
        {!yearIsOpen && <FontIcon className={CalendarClasses.CalendarArrowIcon} onClick={onPrev}
                                  type={"keyboard_arrow_left"}/>}
      </Box>
      <Button
        flexShrink={0}
        color={ButtonColors.Primary}
        variant={ButtonVariants.Ghost}
        onClick={() => setOpenYears(!isYearOpen)}
        icon={!yearIsOpen ? "keyboard_arrow_down" : "keyboard_arrow_up"}
        iconDirection={IconDirection.Right}>
        {monthLabel}
      </Button>
      <Box container>
        {!yearIsOpen && <FontIcon className={CalendarClasses.CalendarArrowIcon} onClick={onNext}
                                  type={"keyboard_arrow_right"}/>}
      </Box>
    </Box>
  );
}
