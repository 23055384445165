import { useReactiveVar }                            from "@apollo/client";
import { useState }                                  from "react";
import { useCallback }                               from "react";
import { useMemo }                                   from "react";
import { useEffect }                                 from "react";
import { useQuery }                                  from "@apollo/client";
import { useMutation }                               from "@apollo/client";
import { useAlert }                                  from "@relcu/ui";
import { useMounted }                                from "@relcu/ui";
import { confirmModal }                              from "@relcu/ui";
import { audioDevicesVar }                           from "../../../../reactiveVars";
import { microphoneAccessVar }                       from "../../../../reactiveVars";
import { SubscriptionEvent }                         from "../../../../types/graphql-global-types";
import { usePhone }                                  from "../../../usePhone";
import { SetDefaultOutOfWorkVoicemailVariables }     from "../__types__/SetDefaultOutOfWorkVoicemail";
import { SetDefaultOutOfWorkVoicemail }              from "../__types__/SetDefaultOutOfWorkVoicemail";
import { SubscribeRecodingsVariables }               from "../__types__/SubscribeRecodings";
import { SubscribeRecodings }                        from "../__types__/SubscribeRecodings";
import { UserVoiceMail }                             from "../__types__/UserVoiceMail";
import { VoiceMailRecordings_recordings_edges_node } from "../__types__/VoiceMailRecordings";
import { VoiceMailRecordingsVariables }              from "../__types__/VoiceMailRecordings";
import { VoiceMailRecordings }                       from "../__types__/VoiceMailRecordings";
import { SUBSCRIBE_RECORDINGS }                      from "../operations.graphql";
import { USER_VOICE_MAIL }                           from "../operations.graphql";
import { DELETE_RECORDING }                          from "../operations.graphql";
import { UPDATE_RECORDING_NAME }                     from "../operations.graphql";
import { SET_DEFAULT_OUT_OF_WORK_VOICEMAIL }         from "../operations.graphql";
import { SET_DEFAULT_VOICEMAIL }                     from "../operations.graphql";
import { VOICEMAIL_RECORDINGS }                      from "../operations.graphql";
import { DeleteRecordingVariables }                  from "../__types__/DeleteRecording";
import { DeleteRecording }                           from "../__types__/DeleteRecording";
import { UpdateRecordingNameVariables }              from "../__types__/UpdateRecordingName";
import { UpdateRecordingName }                       from "../__types__/UpdateRecordingName";
import { SetDefaultVoiceMailVariables }              from "../__types__/SetDefaultVoiceMail";
import { SetDefaultVoiceMail }                       from "../__types__/SetDefaultVoiceMail";
import { useSource }                                 from "@relcu/ui";

type Recording = VoiceMailRecordings_recordings_edges_node
export function useVoiceMailSection(title: string, type: "voice_mail_template" | "voicemail_outOfWork_template" = "voice_mail_template") {
  const phone = usePhone();
  const { data: { viewer: { user: { id, voiceMail, voicemailOutOfWork } } } } = useQuery<UserVoiceMail>(USER_VOICE_MAIL, {
    fetchPolicy: "cache-only"
  });

  const [setVoicemail] = useMutation<SetDefaultVoiceMail, SetDefaultVoiceMailVariables>(SET_DEFAULT_VOICEMAIL);
  const [setOutOfWorkVoicemail] = useMutation<SetDefaultOutOfWorkVoicemail, SetDefaultOutOfWorkVoicemailVariables>(SET_DEFAULT_OUT_OF_WORK_VOICEMAIL);
  const { defaultVoicemail, to, markAsDefault } = useMemo(() => {
    switch (type) {
      case "voice_mail_template":
        return {
          to: "system:voicemail",
          defaultVoicemail: voiceMail,
          markAsDefault: setVoicemail
        };
      case "voicemail_outOfWork_template":
        return {
          to: "system:voicemail:outOfWork",
          defaultVoicemail: voicemailOutOfWork,
          markAsDefault: setOutOfWorkVoicemail
        };
    }
  }, [type, voiceMail, voicemailOutOfWork, setVoicemail, setOutOfWorkVoicemail]);
  const [updateName] = useMutation<UpdateRecordingName, UpdateRecordingNameVariables>(UPDATE_RECORDING_NAME);
  const [deleteRecording] = useMutation<DeleteRecording, DeleteRecordingVariables>(DELETE_RECORDING);
  const { data, subscribeToMore,refetch } = useQuery<VoiceMailRecordings, VoiceMailRecordingsVariables>(VOICEMAIL_RECORDINGS, { variables: { id, type } });
  const [editId, setEditId] = useState<string>(null);
  const { success, error } = useAlert();
  const isSelected = useMemo(() => !!data?.recordings?.edges.find(({ node }) => node.id == defaultVoicemail?.id), [data?.recordings?.edges, defaultVoicemail?.id]);
  const { $settings: { defaultVoiceMail: defaultVoiceMailText } } = useSource();
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const audioDevices = useReactiveVar(audioDevicesVar);
  const hasMicrophoneIssue = !microphoneAccess || !audioDevices?.audiooutput?.length ||!audioDevices?.audiooutput?.length
  const api = {
    setEditId,
    editId,
    defaultVoiceMailText,
    get busy() {
      return phone?.active && phone?.active?.to !== to || hasMicrophoneIssue;
    },
    get active() {
      return phone?.active?.to == to;
    },
    get data() {
      return data?.recordings?.edges || [];
    },
    get voiceMails() {
      return (data?.recordings?.edges || []).map((({ node }) => node));
    },
    async onChangeDefault(id) {
      await api.setDefault(id);
    },
    onCancel() {
      setEditId(null);
    },
    async handleSave(recording) {
      await api.updateRecording(recording);
      setEditId(null);
    },
    subscribeToMore() {
      return subscribeToMore<SubscribeRecodings, SubscribeRecodingsVariables>({
        document: SUBSCRIBE_RECORDINGS,
        variables: { id, type },
        updateQuery(previousQueryResult, { subscriptionData: { data: { recordings: { event, node } } } }) {
          switch (event) {
            case SubscriptionEvent.UPDATE:
            refetch().catch(console.info)
            return previousQueryResult
            case SubscriptionEvent.ENTER:
            case SubscriptionEvent.CREATE:
              return {
                ...previousQueryResult,
                recordings: {
                  ...previousQueryResult.recordings,
                  count: previousQueryResult.recordings.count + 1,
                  edges: [
                    {
                      node,
                      __typename: "RecordingEdge"
                    },
                    ...previousQueryResult.recordings.edges
                  ]
                }
              };
            case SubscriptionEvent.LEAVE:
              return {
                ...previousQueryResult,
                count: Math.abs(previousQueryResult.recordings.count - 1),
                recordings: {
                  ...previousQueryResult.recordings,
                  edges: previousQueryResult.recordings?.edges.filter(edge => edge.node.id !== node.id)
                }
              };
          }
        }
      });
    },
    async updateRecording({ id, name }: Recording) {
      try {
        await updateName({ variables: { id, name } });
        success("Voicemail successfully updated.");
      } catch (e) {
        error(e);
      }

    },
    async setDefault(rId: string | null) {
      await markAsDefault({ variables: { id, rId } });
    },
    async deleteRecording({ id: recordingId, name }: Recording) {
      try {
        await confirmModal({
          title: `Delete ${title.toLowerCase()} record`,
          subTitle: `Are you sure you want to delete the ${name} ${title.toLowerCase()} record?`,
          content: `The record will deleted permanently`,
          label: "DELETE"
        });
        await deleteRecording({ variables: { id: recordingId } });
      } catch (e) {
        console.error(e);
      }

    },
    toggleRecording() {
      !!api.active ? phone?.active.drop() : phone?.call({ to });
    }
  };
  useEffect(api.subscribeToMore, [id, type]);
  useMounted(async () => {
    if (!isSelected) {
      await api.setDefault(null);
    }
  }, [isSelected, markAsDefault]);
  return {
    ...api,
    isSelected,
    defaultVoicemail,
    voiceMails: useMemo(() => api.voiceMails, [data]),
    toggleRecording: useCallback(api.toggleRecording, [phone, api.active, to]),
    updateRecording: useCallback(api.updateRecording, [updateName]),
    setDefault: useCallback(api.setDefault, [markAsDefault]),
    deleteRecording: useCallback(api.deleteRecording, [deleteRecording])
  };
}
