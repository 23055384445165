import React   from "react";
import { FC }  from "react";
import { Box } from "../../../Box";

export const EmailHeader: FC<any> = React.memo(function EmailHeader(props) {
  return <Box container gap={"XXS"} {...props}/>
});

export const enum EmailHeaderClasses {
  HeaderBody = "header__body"
}