import { Link }                                     from "@relcu/ui";
import React                                        from "react";
import { useSource }                                from "@relcu/ui";
import { CommonClasses }                            from "@relcu/ui";
import { TimelineItem }                             from "@relcu/ui";
import { TypographyColor }                          from "@relcu/ui";
import { Typography }                               from "@relcu/ui";
import { TimelineItemSubject }                      from "@relcu/ui";
import { TimelineItemIconColors }                   from "@relcu/ui";
import { TimelineItemIcon }                         from "@relcu/ui";
import { BulkMailActivity as BulkMailActivityType } from "../../../../__types__/BulkMailActivity";

export interface BulkMailActivityProps extends BulkMailActivityType {
  createdAt: string;
}

export const BulkMailActivity = React.memo<BulkMailActivityProps>(function BulkMailActivity(props) {
  const { bulkMailConversation, bulkMailCount, bulkMailCountPerUser, bulkMailUserCount: usersCount, bulkMailConversationFrom, createdAt } = props;
  const bulkMailUserCount = usersCount - 1;
  const { $viewer, $object } = useSource();
  const isOwner = bulkMailConversationFrom.objectId === $object.objectId;
  const behalfOf = bulkMailUserCount && bulkMailCount !== bulkMailUserCount;
  const mailCount = isOwner ? bulkMailCount : (bulkMailCountPerUser?.find(b => b.user == $object.objectId).count ?? bulkMailCount);
  const navigateTo = (obj) => {
    if ($viewer.objectId === obj.objectId) {
      return `/user/${obj.objectId}/emails/${bulkMailConversation.objectId}`;
    } else {
      return `/user/${obj.objectId}`;
    }
  };

  return <TimelineItem
    primary={<TimelineItemSubject>
      <Link
        to={navigateTo(bulkMailConversationFrom)}>
        <Typography
          className={CommonClasses.ClickableIcon}>{bulkMailConversationFrom?.objectName || "Unknown"}</Typography>
      </Link>
      <Typography color={TypographyColor.Secondary}>sent</Typography>
      <Typography>bulk emails</Typography>
      <Typography color={TypographyColor.Secondary}>to</Typography>
      <Typography>{mailCount} people {behalfOf ? `on behalf of` : ``}</Typography>
      {!!behalfOf && (!isOwner ?
          <Link
            to={navigateTo($object)}>
            <Typography className={CommonClasses.ClickableIcon}>{$object.objectName}</Typography>
          </Link> : <Typography>{bulkMailUserCount} users</Typography>
      )}
    </TimelineItemSubject>}
    creationDate={createdAt}
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.TealDark} icon={"rc_bulk_email"}/>}>
  </TimelineItem>;

});
