import { Typography }     from "@relcu/ui";
import { Box }            from "@relcu/ui";
import { CommonClasses }  from "@relcu/ui";
import { FontIcon }       from "@relcu/ui";
import { Ellipsis }       from "@relcu/ui";
import { classNames }     from "@relcu/ui";
import React              from "react";
import { SidebarClasses } from "./SidebarClasses";

export interface TableSidebarItemProps {
  title: string
  onSelect: () => void
  selected: boolean
  onDelete?: () => void
}

export const SidebarItem = React.memo<TableSidebarItemProps>(function TableSidebarItem(props) {
  const { title, selected, onDelete, onSelect } = props;
  return (
    <Box
      container
      alignItems={"center"}
      justify={"space-between"}
      className={classNames(SidebarClasses.HeadingItem)}
      onClick={onSelect}
    >
      <Ellipsis from={20}
                className={classNames(
                  SidebarClasses.ItemName,
                  { [ SidebarClasses.Selected ]: selected })}
      >
        {title}
      </Ellipsis>
      {onDelete &&
      <FontIcon type={"delete"} className={CommonClasses.ClickableIcon}
                onClick={onDelete}/>
      }
    </Box>
  );
});
export type TableSidebarDefaultItemProps  = Omit<TableSidebarItemProps,"onDelete">
export const TableSidebarDefaultItem = React.memo<TableSidebarDefaultItemProps>((props)=>{
  const { title, selected, onSelect } = props;
  return (
    <Box
      container
      alignItems={"center"}
      className={classNames(SidebarClasses.HeadingItem, SidebarClasses.All)}
      onClick={onSelect}
    >
      <Typography className={classNames( { [ SidebarClasses.Selected ]: selected })}>
        {title}
      </Typography>
    </Box>
  )
})