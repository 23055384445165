import React                                                         from "react";
import { FC, useCallback }                                           from "react";
import { useState }                                                  from "react";
import { useMemo }                                                   from "react";
import { useReactiveVar }                                            from "@apollo/client";
import { useMutation }                                               from "@apollo/client";
import { Navigate }                                                  from "@relcu/react-router";
import { useParams }                                                 from "@relcu/react-router";
import { useNavigate }                                               from "@relcu/react-router";
import { Routes }                                                    from "@relcu/react-router";
import { Route }                                                     from "@relcu/react-router";
import { EmptyState }                                                from "@relcu/rc";
import { Badge }                                                     from "@relcu/rc";
import { CheckPicker }                                               from "@relcu/rc";
import { AutoComplete, Form, InputGroup }                            from "@relcu/rc";
import { Icon }                                                      from "@relcu/rc";
import { Button }                                                    from "@relcu/rc";
import { Stack }                                                     from "@relcu/rc";
import { Typography }                                                from "@relcu/rc";
import { Toolbar }                                                   from "@relcu/rc";
import { Sidenav }                                                   from "@relcu/rc";
import { Page }                                                      from "@relcu/rc";
import { Content as RCContent }                                      from "@relcu/rc";
import { toFirstLower }                                              from "@relcu/ui";
import { omit }                                                      from "@relcu/ui";
import { useSource }                                                 from "@relcu/ui";
import { confirmModal }                                              from "@relcu/ui";
import { CircularLoader }                                            from "@relcu/ui";
import { fullScreenVar }                                             from "../../../../reactiveVars";
import { CreatePhoneMessageTemplateFieldsInput }                     from "../../../../types/graphql-global-types";
import { uuid }                                                      from "../../../../utils/helpers";
import { GenerationListCard }                                        from "../../../Generation";
import { RelayQuery }                                                from "../../../Relay";
import { DuplicatePhoneMessageTemplateVariables }                    from "./__types__/DuplicatePhoneMessageTemplate";
import { DuplicatePhoneMessageTemplate }                             from "./__types__/DuplicatePhoneMessageTemplate";
import { GetPhoneMessageTemplates_phoneMessageTemplates_edges_node } from "./__types__/GetPhoneMessageTemplates";
import { GetPhoneMessageTemplates }                                  from "./__types__/GetPhoneMessageTemplates";
import { RemovePhoneMessageTemplateVariables }                       from "./__types__/RemovePhoneMessageTemplate";
import { RemovePhoneMessageTemplate }                                from "./__types__/RemovePhoneMessageTemplate";
import { UpdatePhoneMessageTemplateVariables }                       from "./__types__/UpdatePhoneMessageTemplate";
import { UpdatePhoneMessageTemplate }                                from "./__types__/UpdatePhoneMessageTemplate";
import { Content }                                                   from "./Content/Content";
import { UPDATE_PHONE_MESSAGE_TEMPLATE }                             from "./PhoneMessageTemplateViewQueries";
import { DUPLICATE_PHONE_MESSAGE_TEMPLATE }                          from "./PhoneMessageTemplateViewQueries";
import { REMOVE_PHONE_MESSAGE_TEMPLATE }                             from "./PhoneMessageTemplateViewQueries";
import { SUBSCRIBE_PHONE_MESSAGE_TEMPLATES }                         from "./PhoneMessageTemplateViewQueries";
import { GET_PHONE_MESSAGE_TEMPLATES }                               from "./PhoneMessageTemplateViewQueries";
import "./phone-message-template-view.css";

const states = [
  {
    label: "Enabled",
    value: "enabled",
    action: "Status"
  },
  {
    label: "Disabled",
    value: "disabled",
    action: "Status"
  }
];

export const PhoneMessageTemplateView: FC<any> = React.memo(function PhoneMessageTemplateView(props) {
  const { $viewer, $object } = useSource();
  const navigate = useNavigate();
  const params = useParams();
  const [objectId] = params[ "*" ].split("/");
  const isFullScreen = useReactiveVar(fullScreenVar);
  const canCreate = ($object.className == "Settings" || ($object.id === $viewer.id));
  const [expand, setExpand] = useState(true);
  const [updatePhoneMessageTemplate] = useMutation<UpdatePhoneMessageTemplate, UpdatePhoneMessageTemplateVariables>(UPDATE_PHONE_MESSAGE_TEMPLATE);
  const [duplicatePhoneMessageTemplate] = useMutation<DuplicatePhoneMessageTemplate, DuplicatePhoneMessageTemplateVariables>(DUPLICATE_PHONE_MESSAGE_TEMPLATE);
  const [removePhoneMessageTemplate] = useMutation<RemovePhoneMessageTemplate, RemovePhoneMessageTemplateVariables>(REMOVE_PHONE_MESSAGE_TEMPLATE);
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState([]);
  const where = useMemo(() => {
    const query = {};
    if ($object.className === "Settings") {
      query[ "owner" ] = {
        exists: false
      };
    } else if ($object.__typename == "User") {
      query[ "owner" ] = {
        have: {
          id: {
            equalTo: $object?.id
          }
        }
      };
    }

    const stateFilters = filters.filter(f => f == "disabled" || f == "enabled");

    if (stateFilters.length < 2) {
      if (filters.includes("enabled")) {
        query[ "enabled" ] = {
          equalTo: true
        };
      } else if (filters.includes("disabled")) {
        query[ "enabled" ] = {
          notEqualTo: true
        };
      }
    }

    if ($object.__typename && $object.__typename != "User") {
      query[ "applyTo" ] = {
        equalTo: toFirstLower($object.__typename)
      };
    }

    if (search) {
      query[ "title" ] = {
        matchesRegex: `^${search}`,
        options: "i"
      };
    }

    return query;
  }, [search, $object, filters]);
  const subscriptionWhere = useMemo(() => {
    const subscriptionWhere = { ...where };

    if ($object.className !== "Settings") {
      subscriptionWhere[ "owner" ] = {
        have: {
          link: $object?.id
        }
      };
    }
    return subscriptionWhere;
  }, [where, $object]);

  const handleUpdate = useCallback((id: string, title: string) => {
    updatePhoneMessageTemplate({
      variables: {
        input: {
          id,
          fields: {
            title
          }
        }
      }
    }).catch(console.error);
  }, []);
  const handleRemove = async (id: string, redirectId: string, type: string) => {
    try {
      await confirmModal({
        title: "Delete confirmation",
        subTitle: `Are you sure you want to delete sms template?`,
        content: `All information related with this sms template will be lost permanently`,
        label: "DELETE"
      });
      await removePhoneMessageTemplate({
        variables: {
          id
        }
      });

      if (redirectId) {
        navigate(redirectId);
      } else {
        fullScreenVar(false);
      }
    } catch (e) {
    }
  };
  const handleDuplicate = (data: GetPhoneMessageTemplates_phoneMessageTemplates_edges_node) => {
    const fields = {
      ...omit(data, ["owner", "createdAt", "id", "updatedAt", "__typename", "ACL", "objectId", "objectIcon", "to", "from", "scope", "user", "contact"]),
      title: `${data.title}_copy`
    };
    if ($object.className != "Settings") {
      fields[ "owner" ] = {
        link: $viewer.id
      };
    }
    duplicatePhoneMessageTemplate({
      variables: {
        input: {
          fields: fields as CreatePhoneMessageTemplateFieldsInput
        }
      }
    });
  };
  const handleCreate = async () => navigate(`create/${uuid()}`);
  const ToggleSelect = React.forwardRef<HTMLDivElement>((props, ref) => {
    //todo add orange indicator
    return <Icon type={"filter_alt"} ref={ref} style={{ color: "var(--rc-accent-03-primary)", cursor: "pointer" }}/>;
  });

  return <RelayQuery<GetPhoneMessageTemplates>
    className={"PhoneMessageTemplates"}
    rowHeight={48}
    query={{
      document: GET_PHONE_MESSAGE_TEMPLATES,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: { where }
    }}
    subscription={{
      document: SUBSCRIBE_PHONE_MESSAGE_TEMPLATES,
      variables: { where: subscriptionWhere }
    }}
    render={renderProps => {
      const {
        scrollContainerRef,
        beforeLoaderRef,
        afterLoaderRef,
        loading,
        data: { phoneMessageTemplates: { edges = [], pageInfo } } = {}
      } = renderProps;
      const selected = edges.find(e => e.node.objectId === objectId)?.node;
      const activeIndex = edges.findIndex(e => e.node.objectId == objectId);
      const nextOrPrevTemplate = activeIndex + 1 < edges.length ?
        activeIndex + 1 :
        activeIndex - 1 >= 0 ? (activeIndex - 1) : null;
      const navigateNode = edges[ nextOrPrevTemplate ]?.node;
      const navigateId = navigateNode?.objectId;
      const navigateType = navigateNode?.type;
      return (
        <>
          {
            (!loading && !edges.length && !search && objectId != "create" && !filters?.length) ?
              <EmptyState
                buttonLabel={"CREATE"}
                background
                icon={"quickreply"}
                title={"You haven’t created templates"}
                subtitle={canCreate && "To create a new one, choose the type and click the  ‘Create’ button below."}
                onClick={canCreate && handleCreate}
              />
              :
              <Page>
                <Page.Sidebar width={expand ? 320 : 0}
                              isFullScreen={isFullScreen}
                              style={{ borderWidth: expand ? "1px" : "0", opacity: expand ? 1 : 0, gap: 8 }}>
                  <Sidenav.Header>
                    <Toolbar spacing={6} size={"sm"}>
                      {
                        <Page.Toggle
                          size={"sm"}
                          expand={expand}
                          onToggle={() => setExpand(expand => !expand)}
                          disabled={edges?.length == 0}/>
                      }
                      <Typography weights={"medium"} variant={"base16"}>{props.title}</Typography>
                      <Stack.Item grow={1}/>
                      {
                        (($viewer.id == $object.id) || $object.className == "Settings") &&
                        (
                          canCreate &&
                          <Button
                            size={"xs"}
                            style={{ alignSelf: "end" }}
                            onClick={handleCreate}>
                            CREATE
                          </Button>
                        )
                      }
                    </Toolbar>
                    <Stack direction={"column"} spacing={16} style={{ marginLeft: 16, marginRight: 16 }}
                           childrenRenderMode={"clone"}>
                      <InputGroup inside>
                        <InputGroup.Addon>
                          <Icon type={"search"}/>
                        </InputGroup.Addon>
                        <Form.Control onChange={setSearch} name="name" accepter={AutoComplete} placeholder="Search..."/>
                      </InputGroup>
                      {
                        !!filters.length
                          ? <Badge
                            style={{ alignSelf: "end" }}
                            children={
                              <CheckPicker
                                menuStyle={{ width: 200 }}
                                data={states}
                                placement={"autoVerticalEnd"}
                                value={filters}
                                onChange={setFilters}
                                toggleAs={ToggleSelect}
                                searchable={false}
                              />
                            }
                            size={"sm"}/>
                          : <CheckPicker
                            menuStyle={{ width: 200 }}
                            style={{ alignSelf: "end" }}
                            data={states}
                            placement={"autoVerticalEnd"}
                            value={filters}
                            onChange={setFilters}
                            groupBy={"action"}
                            toggleAs={ToggleSelect}
                            searchable={false}
                          />
                      }{/*todo cant change to select picker cause it doesn't have clear button in custom toggle*/}
                    </Stack>
                  </Sidenav.Header>
                  <Sidenav style={{ height: "calc(100% - 146px)" }} expanded={expand} appearance="subtle">
                    <Sidenav.Body style={{ height: "100%" }}>
                      <div
                        className={"rc-phone-message-list-container"}
                        style={{ height: "100%" }}
                        ref={scrollContainerRef}>
                        {
                          pageInfo?.hasPreviousPage &&
                          <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>
                        }
                        {
                          edges.map(({ node: phoneMessage }) => {
                            return <GenerationListCard
                              canUpdate={canCreate || $viewer.role == "admin"}
                              onUpdate={handleUpdate}
                              objectId={phoneMessage.objectId}
                              onRemove={() => handleRemove(phoneMessage.id, navigateId, navigateType)}
                              onDuplicate={canCreate ? () => handleDuplicate(phoneMessage) : null}
                              selected={objectId == phoneMessage?.objectId}
                              onClick={() => navigate(phoneMessage.objectId)}
                              enabled={phoneMessage.enabled}
                              title={phoneMessage.title}
                              date={phoneMessage.createdAt}
                              key={phoneMessage.id}
                            />;
                          })
                        }
                        {
                          loading ?
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              style={{ flex: 1 }}>
                              <CircularLoader/>
                            </Stack> : !loading && edges.length == 0 ?
                              <EmptyState
                                title={"No results"}
                                subtitle={search ? "Try again with different term." : null}/> :
                              null
                        }
                        {
                          afterLoaderRef && pageInfo?.hasNextPage &&
                          <CircularLoader alignSelf={"center"} ref={afterLoaderRef}/>
                        }
                      </div>
                    </Sidenav.Body>
                  </Sidenav>
                </Page.Sidebar>
                <Routes>
                  <Route path={"/"} element={
                    <RCContent style={{ justifyContent: "center" }}>
                      {
                        loading ?
                          <CircularLoader/>
                          :
                          (
                            edges.length ?
                              <Navigate to={edges[ 0 ].node.objectId}/> :
                              <EmptyState icon={"email"} subtitle={`No templates available`}/>
                          )
                      }
                    </RCContent>
                  }/>
                  <Route
                    path={"/:phoneMessageTemplateId/*"}
                    element={
                      (selected || objectId == "create") ?
                        <Content
                          onRemove={(id) => handleRemove(id, navigateId, navigateType)}
                          replaceableFieldsSources={props.replaceableFieldsSources}
                          hasPermissions={canCreate || $viewer.role == "admin"}
                          onDuplicate={handleDuplicate}
                          setExpand={setExpand}
                          template={selected}
                          expand={expand}
                        />
                        :
                        <RCContent>
                          <EmptyState icon={"email"} subtitle={`No template found`}/>
                        </RCContent>
                    }
                  />
                </Routes>
              </Page>
          }
        </>
      );
    }}
  />;
});
