import React                   from "react";
import { BoxComponentProps }   from "../Box";
import { Box }                 from "../Box";
import { TimelineItemClasses } from "./TimelineItemClasses";


export const TimelineItemBody = React.memo<BoxComponentProps>(({children}) => {
  return <Box container flexGrow={1} direction={"column"} className={TimelineItemClasses.TimelineItemBody} gap={"XS"}>
    {children}
  </Box>;
});