import { BoxComponentProps } from "@relcu/ui";
import { useState }          from "react";
import { useEffect }         from "react";
import { useRef }            from "react";
import React                 from "react";
import { FC }                from "react";
import { Input }             from "@relcu/ui";

export interface NumberFieldProps extends Omit<BoxComponentProps, "onChange"> {
  value: number;
  precision?: number;
  onChange?(value: number)
}
export const NumberField: FC<NumberFieldProps> = React.memo(function NumberField(props) {
  // const { clean, toFixedNoRounding } = useNumberUtils(props);
  // const onChange = useCallback((value, event) => {
  //   let cursor = event.target.selectionEnd;
  //   const parsedValue = parse(value);
  //   props.onChange(parsedValue);
  //   event.persist();
  //   setTimeout(() => {
  //     if (String(event.target.value).includes(",")) {
  //       const oldLength = (String(props.value).match(/,/g) || []).length;
  //       const newLength = (String(event.target.value).match(/,/g) || []).length;
  //       if (oldLength !== newLength) {
  //         cursor += 1;
  //       }
  //     }
  //     event.target.selectionStart = cursor;
  //     event.target.selectionEnd = cursor;
  //     event.target.focus();
  //   });
  // }, [props.value]);
  //
  // const parse = (value) => {
  //   const str = String(value);
  //   if (value && props.precision && str.indexOf(".") < 0) {
  //     const end = str.substring(str.length - props.precision, str.length);
  //     const start = str.substring(0, str.length - props.precision);
  //     if (start && end) {
  //       value = [start, end].join(".");
  //     }
  //   }
  //   value = clean(value);
  //   if (!value) {
  //     return null;
  //   }
  //   if (props.precision || props.precision === 0) {
  //     value = toFixedNoRounding(value, props.precision);
  //   }
  //   return Number(value);
  // };
  const [state, setState] = useState(props.value);
  useEffect(() => {
    if (!/\.$/.test(String(state))) {
      props.onChange((state != null && typeof state != "undefined") ? Number(state) : null);
    }
  }, [state, props.value]);
  return <Input
    flex={"0 1 32%"}
    value={state}
    placeholder={"Type value"}
    onChange={(value) => {
      if(value && (!value.trim() || isNaN(value))){
        return
      }
      setState(value);
    }}/>;
});
