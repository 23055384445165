import React                     from "react";
import { useMemo }               from "react";
import { classNames }            from "../..";
import { Box }                   from "../..";
import { applyBoxItemStyles }    from "../..";
import { BoxItemComponentProps } from "../..";
import { IconType }              from "../..";
import { FontIcon }              from "../..";

export enum BadgeColors {
  Primary = "primary",
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
  Grey = "grey",
  LightGrey = "light-grey",
  Ghost = "ghost",
  White = "white"
}
export enum BadgeVariants {
  Dot = "dot",
  Indicator = "indicator",
  Regular = "regular",
}

export enum BadgeAlignment {
  TopLeft = "topLeft",
  BottomLeft = "bottomLeft",
  TopRight = "topRight",
  BottomRight = "bottomRight",
}

export enum BadgeSize {
  big = "big"
}

export interface BadgeProps extends BoxItemComponentProps {
  color?: BadgeColors,
  alignment?: BadgeAlignment,
  label?: string | number,
  variant?: BadgeVariants
  outline?: boolean
  icon?: IconType
  size?: BadgeSize
}

const defaultBadgeProps: BadgeProps = {
  color: BadgeColors.Primary,
  variant: BadgeVariants.Regular
};
Badge.defaultProps = defaultBadgeProps;
export function Badge(props: BadgeProps) {
  let properties = applyBoxItemStyles<BadgeProps>(props);

  const { className, variant, outline, color, children, icon, label, alignment, size, ...p } = props;
  const classes = classNames(BadgeClasses.Badge, {
    [ BadgeClasses.Primary ]: (color == BadgeColors.Primary),
    [ BadgeClasses.Success ]: (color == BadgeColors.Success),
    [ BadgeClasses.Error ]: (color == BadgeColors.Error),
    [ BadgeClasses.Warning ]: (color == BadgeColors.Warning),
    [ BadgeClasses.Grey ]: (color == BadgeColors.Grey),
    [ BadgeClasses.LightGrey ]: (color == BadgeColors.LightGrey),
    [ BadgeClasses.Info ]: (color == BadgeColors.Info),
    [ BadgeClasses.Ghost ]: (color == BadgeColors.Ghost),
    [ BadgeClasses.White ]: (color == BadgeColors.White),
    [ BadgeClasses.WithIcon ]: (!!icon),
    [ BadgeClasses.Regular ]: (variant == BadgeVariants.Regular),
    [ BadgeClasses.Dot ]: (variant == BadgeVariants.Dot),
    [ BadgeClasses.Indicator ]: (variant == BadgeVariants.Indicator),
    [ BadgeClasses.Outline ]: outline,
    [ BadgeClasses.TopLeft ]: (alignment == BadgeAlignment.TopLeft),
    [ BadgeClasses.TopRight ]: (alignment == BadgeAlignment.TopRight),
    [ BadgeClasses.BottomLeft ]: (alignment == BadgeAlignment.BottomLeft),
    [ BadgeClasses.BottomRight ]: (alignment == BadgeAlignment.BottomRight),
    [ BadgeClasses.BigBadge ]: size == BadgeSize.big
  }, className);

  let badgeProperties = useMemo(() => {
    return children ? { className: classes } : { className: classNames(properties.className, classes), style: properties.style };
  }, [children, classes, properties.className, properties.style, classNames]);

  let badgeRootProperties = useMemo(() => {
    return children ? { className: classNames(properties.className, BadgeClasses.BadgeRoot), style: properties.style } : { className: BadgeClasses.BadgeRoot, style: properties.style };
  }, [children, properties.className, properties.style, classNames]);

  let badge = <Box {...p} {...badgeProperties}>
    {icon ? <FontIcon type={icon}/> : ((variant != BadgeVariants.Dot && variant != BadgeVariants.Indicator) && label)}
  </Box>;

  return children ? <Box {...badgeRootProperties} >
    {badge}
    {children}
  </Box> : badge;
}

export enum BadgeClasses {
  Badge = "badge",
  BadgeRoot = "badge-root",
  Primary = "primary",
  Success = "success",
  White = "white",
  Error = "error",
  Warning = "warning",
  Info = "info",
  Outline = "outline",
  Dot = "dot",
  Indicator = "indicator",
  Regular = "regular",
  WithIcon = "with-icon",
  TopLeft = "top-left",
  BottomLeft = "bottom-left",
  TopRight = "top-right",
  BottomRight = "bottom-right",
  Ghost = "ghost",
  Grey = "grey",
  LightGrey = "light-grey",
  BigBadge = "badge--big"
}
