import React                   from "react";
import { FC }                  from "react";
import { useEffect }           from "react";
import { useState }            from "react";
import { useCallback }         from "react";
import { classNames }          from "@relcu/ui";
import { useImperativeState }  from "@relcu/ui";
import { Badge }               from "@relcu/rc";
import { Stack }               from "@relcu/rc";
import { Icon }                from "@relcu/rc";
import { IconButton }          from "@relcu/rc";
import { Typography }          from "@relcu/rc";
import { useMounted }          from "@relcu/ui";
import { DialPad }             from "@relcu/ui";
import { useReactiveVar }      from "@apollo/client";
import { audioDevicesVar }     from "../../../../reactiveVars";
import { microphoneAccessVar } from "../../../../reactiveVars";
import { CallReply }           from "../../CallActions/CallReply";
import { useCallReply }        from "../../CallActions/CallReply/useCallReply";
import { SmsReply }            from "../../CallActions/SmsReply";
import { useHybridCall }       from "./useHybridCall";
import { useRelcuCallActions } from "./useRelcuCallActions";

export enum HybridCallActionsClasses {
  HybridCallActionsDisabled = "hybrid-call-actions-disabled",
}

interface RelcuCallActionsProps {
  allActivesInProgress?: boolean;
  disableMerge?: boolean;
  dialPadOpen?: boolean;
  onDialPadOpen?(toggle: boolean);
  voiceMessagesOpen?: boolean;
  onVoiceMessagesOpen?(toggle: boolean);
  onMerge?();
}

export const RelcuCallActions: FC<RelcuCallActionsProps> = React.memo(function RelcuCallActions(props) {
  const { onMerge, voiceMessagesOpen, onVoiceMessagesOpen, dialPadOpen, onDialPadOpen, allActivesInProgress } = props;
  const { activeCalls, myCall, isModerator, rejectedCalls, isMerged, onHoldCall, initiatingConference, status, direction, active, isConference } = useHybridCall();
  const [openDialPad, setOpenDialPad] = useImperativeState<any>(dialPadOpen, onDialPadOpen);
  const [openVoiceMessages, setOpenVoiceMessages] = useImperativeState<any>(voiceMessagesOpen, onVoiceMessagesOpen);
  const [digits, setDigits] = useState("");
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const devices = useReactiveVar(audioDevicesVar);
  const hasMicrophoneIssue = !microphoneAccess || !devices.audioinput.length || !devices.audiooutput.length;
  const { handleAccept, handleDropOrRemove, handleToggleHold, remove } = useRelcuCallActions();
  const reply = useCallReply(myCall?.callSid, () => setOpenVoiceMessages(false));

  useEffect(() => {
    if (status === "ringing") {
      setOpenDialPad(false);
    }
  }, [status]);

  const onDigit = useCallback((number) => {
    setDigits(prev => prev += number);
  }, [active]);

  const handleToggleVoiceMessageClick = () => {
    setOpenVoiceMessages(!openVoiceMessages);
    setOpenDialPad(!openVoiceMessages && false);
  };

  const handleToggleDialPadClick = () => {
    setOpenDialPad(!openDialPad);
    setOpenVoiceMessages(!openDialPad && false);
  };

  const handleDropLastActiveCall = () => {
    if (activeCalls?.length == 1 && isConference) {
      remove(activeCalls[ 0 ].call);
    } else {
      active.drop();
    }
  };

  useMounted(() => {
    active?.sendDigits(digits.slice(-1));
  }, [digits]);

  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      childrenRenderMode={"clone"}
      style={{ width: "100%" }}>
      {
        !!activeCalls?.length ?
          <>
            <Stack
              className={classNames({ [ HybridCallActionsClasses.HybridCallActionsDisabled ]: initiatingConference })}
              justifyContent={"center"}
              spacing={16}
              alignItems={"center"}
              childrenRenderMode={"clone"}>
              {
                activeCalls?.length == 1 ? //one to one call
                  (
                    myCall?.status == "ringing"
                      ?
                      direction === "incoming" ?
                        <>
                          <IconButton
                            size={"sm"}
                            icon={<Icon type={"call"}/>}
                            color={"green"}
                            disabled={hasMicrophoneIssue}
                            onClick={handleAccept}/>
                          {
                            !isConference &&
                            <IconButton
                              size={"sm"}
                              icon={<Icon type={"forum"}/>}
                              onClick={handleToggleVoiceMessageClick}/>
                          }
                          <IconButton
                            size={"sm"}
                            icon={<Icon type={"call_end"}/>}
                            color={"red"}
                            onClick={() => {
                              active.reject()
                            }}/>
                        </>
                        :
                        <IconButton
                          size={"sm"}
                          icon={<Icon type={"call_end"}/>}
                          color={"red"}
                          onClick={handleDropLastActiveCall}
                        />
                      :
                      activeCalls[ 0 ].status == "in-progress"
                        ?
                        <>
                          <IconButton
                            size={"sm"}
                            icon={<Icon type={"call_end"}/>}
                            color={"red"}
                            onClick={() => active.drop()}/>
                          {
                            hasMicrophoneIssue ?
                              <Badge
                                className={"dial-button-error-badge"}
                                content={
                                  <Icon color={"error"} type={"info" as any}/>
                                }>
                                <IconButton
                                  size={"sm"}
                                  disabled={hasMicrophoneIssue}
                                  icon={<Icon type={myCall?.muted ? "mic_off" : "mic"}/>}
                                  onClick={() => active.mute(!myCall?.muted)}
                                />
                              </Badge> :
                              <IconButton
                                size={"sm"}
                                icon={<Icon type={myCall?.muted ? "mic_off" : "mic"}/>}
                                onClick={() => active.mute(!myCall?.muted)}
                              />
                          }
                          {
                            !!rejectedCalls?.length && isModerator &&
                            <IconButton
                              size={"sm"}
                              icon={<Icon type={onHoldCall ? "play_arrow" : "pause"}/>}
                              onClick={handleToggleHold}
                            />
                          }
                          {
                            direction === "outgoing" && !rejectedCalls?.length &&
                            <>
                              {
                                !!reply?.templates?.length &&
                                <IconButton
                                  size={"sm"}
                                  icon={<Icon type={"rc_voicemail_call"}/>}
                                  onClick={handleToggleVoiceMessageClick}
                                />
                              }
                              <IconButton size={"sm"} icon={<Icon type={"dialpad"}/>}
                                          onClick={handleToggleDialPadClick}/>
                            </>
                          }
                        </>
                        :
                        //not in-progress call
                        <>
                          {
                            direction === "incoming" &&
                            //todo if caller dropped during transfer and  second LO stuck in ringing state
                            <IconButton
                              size={"sm"}
                              icon={<Icon type={"call_end"}/>}
                              color={"red"}
                              onClick={handleDropLastActiveCall}
                            />
                          }
                          {
                            direction === "outgoing" &&
                            <IconButton
                              size={"sm"}
                              icon={<Icon type={"call_end"}/>}
                              color={"red"}
                              onClick={() => active.drop()}/>
                          }
                        </>
                    //not in-progress call end
                  )
                  :
                  //conf call
                  //conf call not moderator
                  !isModerator
                    ?
                    <>
                      {
                        myCall?.status == "in-progress"
                          ?
                          <>
                            <IconButton
                              size={"sm"}
                              icon={<Icon type={"call_end"}/>}
                              color={"red"}
                              onClick={() => {
                                active.drop();
                              }}
                            />
                            {hasMicrophoneIssue ?
                              <Badge
                                className={"dial-button-error-badge"}
                                content={
                                  <Icon color={"error"} type={"info" as any}/>
                                }>
                                <IconButton
                                  size={"sm"}
                                  disabled={hasMicrophoneIssue}
                                  icon={<Icon type={myCall?.muted ? "mic_off" : "mic"}/>}
                                  onClick={() => active.mute(!myCall?.muted)}
                                />
                              </Badge> :
                              <IconButton
                                size={"sm"}
                                icon={<Icon type={myCall?.muted ? "mic_off" : "mic"}/>}
                                onClick={() => active.mute(!myCall?.muted)}
                              />
                            }
                          </>
                          :
                          <>
                            <IconButton
                              size={"sm"}
                              icon={<Icon type={"call"}/>}
                              disabled={hasMicrophoneIssue}
                              color={"green"}
                              onClick={handleAccept}/>
                            {/*<IconButton*/}
                            {/*  size={"sm"}*/}
                            {/*  icon={<Icon type={"forum"}/>}*/}
                            {/*  onClick={handleToggleVoiceMessageClick}/>*/}
                            <IconButton
                              size={"sm"}
                              icon={<Icon type={"call_end"}/>}
                              color={"red"}
                              onClick={() => {
                                active.reject();
                              }}
                            />
                          </>//not in progress
                      }
                    </>
                    // conf call not moderator end
                    :
                    <>
                      <IconButton
                        size={"sm"}
                        icon={<Icon type={"call_end"}/>}
                        color={"red"}
                        onClick={handleDropOrRemove}/>
                      {hasMicrophoneIssue ?
                        <Badge
                          className={"dial-button-error-badge"}
                          content={
                            <Icon color={"error"} type={"info"}/>
                          }>
                          <IconButton
                            size={"sm"}
                            disabled={hasMicrophoneIssue}
                            icon={<Icon type={myCall?.muted ? "mic_off" : "mic"}/>}
                            onClick={() => active.mute(!myCall?.muted)}
                          />
                        </Badge> :
                        <IconButton
                          size={"sm"}
                          icon={<Icon type={myCall?.muted ? "mic_off" : "mic"}/>}
                          onClick={() => active.mute(!myCall?.muted)}
                        />
                      }
                      {
                        onMerge &&
                        <IconButton
                          disabled={activeCalls?.[ 1 ]?.status != "in-progress"}
                          size={"sm"}
                          icon={<Icon type={"call_merge"}/>}
                          onClick={onMerge}
                        />
                      }
                      {
                        allActivesInProgress && activeCalls?.[ 1 ]?.status == "in-progress" && activeCalls?.[ 1 ]?.__typename == "Contact" && !isMerged &&
                        <IconButton size={"sm"} icon={<Icon type={"dialpad"}/>} onClick={handleToggleDialPadClick}/>
                      }
                    </>
              }
            </Stack>
            {
              openDialPad &&
              <>
                <Stack.Item basis={"24px"}/>
                <Stack justifyContent={"center"} alignItems={"center"} style={{ height: 44 }}>
                  {
                    digits &&
                    <Typography variant={"large"}>{digits}</Typography>
                  }
                </Stack>
                <DialPad onDigit={onDigit} onClose={() => setOpenDialPad(false)}/>
              </>
            }
            {
              direction === "outgoing" && openVoiceMessages && !openDialPad &&
              <>
                <Stack.Item basis={"32px"}/>
                <CallReply templates={reply.templates} send={reply.send}/>
              </>
            }
            {
              activeCalls?.[ 0 ]?.status !== "in-progress" && direction === "incoming" && openVoiceMessages && !openDialPad &&
              <>
                <Stack.Item basis={"32px"}/>
                <SmsReply onClick={() => setOpenVoiceMessages(false)}/>
              </>
            }
          </>
          :
          //todo completed, in-progress, busy case
          myCall?.status == "in-progress" &&
          <IconButton
            size={"sm"}
            icon={<Icon type={"call_end"}/>}
            color={"red"}
            onClick={() => active.drop()}/>
      }
    </Stack>
  );
});
