export enum Alignment {
  Top = "top",
  TopLeft = "topLeft",
  TopRight = "topRight",
  Bottom = "bottom",
  BottomLeft = "bottomLeft",
  BottomRight = "bottomRight",
  Left = "left",
  Right = "right",
  RightTop = "rightTop"
}
