import { useMemo }                                                        from "react";
import { DateTime }                                                       from "luxon";
import { getDays, GetDaysProps, getWeekdayLabels, GetWeekdayLabelsProps } from "./useMonth.utils";

export const dayLabelFormatFn = (date: Date) => DateTime.fromJSDate(date).toFormat("d");
export const weekdayLabelFormatFn = (date: Date) => DateTime.fromJSDate(date).toFormat("ccc");
export const monthLabelFormatFn = (date: Date) => DateTime.fromJSDate(date).toLocaleString({month: "long", year:"numeric"});

export interface UseMonthResult {
  weekdayLabels: string[]
  days: (number | { dayLabel: string; date: Date, isInMonth: boolean })[]
  monthLabel: string
}

export interface UseMonthProps extends GetWeekdayLabelsProps, GetDaysProps {
  monthLabelFormat?(date: Date): string
}

export function useMonth({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = dayLabelFormatFn,
  weekdayLabelFormat = weekdayLabelFormatFn,
  monthLabelFormat = monthLabelFormatFn
}: UseMonthProps): UseMonthResult {
  const days = useMemo(() => getDays({ year, month, firstDayOfWeek, dayLabelFormat }), [
    year,
    month,
    firstDayOfWeek,
    dayLabelFormat
  ]);
  const weekdayLabels = useMemo(() => getWeekdayLabels({ firstDayOfWeek, weekdayLabelFormat }), [
    firstDayOfWeek,
    weekdayLabelFormat
  ]);

  return {
    days,
    weekdayLabels,
    monthLabel: monthLabelFormat(new Date(year, month))
  };
}
