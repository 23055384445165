import { useQuery }         from "@apollo/client";
import { ViewerUserRole }   from "./__types__/ViewerUserRole";
import { VIEWER_USER_ROLE } from "./queries.grapql";
import { ACL }              from "../../graph/__types__/ACL";

export interface UseACL {
  ACL?: ACL
  __typename: string
}

export function useACL() {
  const { data: { viewer } } = useQuery<ViewerUserRole>(VIEWER_USER_ROLE, {
    fetchPolicy: "cache-only"
  });
  const acl = {
    can(type: "read" | "write", object: UseACL) {
      if (!object.ACL) {
        return false;
      }
      const { ACL } = object;
      if (ACL.public?.[ type ]) {
        return true;
      }
      let user = ACL.users?.find?.(u => u.userId === viewer.user.id);
      let role = ACL.roles?.find?.(u => u.roleName === viewer.user.role);
      let team = ACL.teams?.find?.(u => u.teamName === `${viewer.user.role}:${viewer.user.team}`);
      return (!!user?.[ type ] || !!role?.[ type ] || !!team?.[ type ]);
    },
    canRead<T extends UseACL>(object: T) {
      return acl.can("read", object);
    },
    canWrite<T extends UseACL>(object: T) {
      return acl.can("write", object);
    }
  };
  return acl;
}
