import { MutableRefObject }      from "react";
import React                     from "react";
import { FC }                    from "react";
import { useContext }            from "react";
import { ReactElement }          from "react";
import { getItemLabel }          from "../Input/Select/helpers";
import { MenuItem }              from "../Menu";
import { SearchableMenuContext } from "./MenuProvider";

export interface InfiniteListProps {
  onLoadMoreHandler: React.ReactElement
}

export const InfiniteList = React.memo(function InfiniteList(props: InfiniteListProps) {
  const { checkSelected, renderOption, optionLabel, onSelect, options } = useContext(SearchableMenuContext);
  return <div style={{height: options.length > 5 ? 200 : options.length * 40, overflowY: "scroll", position: "relative"}}>
    {
      options.map((option, index) => {
        return renderOption
          ?
          React.cloneElement(renderOption(option) as ReactElement, {//todo need to check with real data
            selected: checkSelected(option),
            key: index,
            onClick: (e) => onSelect(option)
          }) :
          <MenuItem
            key={index}
            container
            gap={"XXS"}
            selected={checkSelected(option)}
            onClick={() => onSelect(option)}>{getItemLabel(option, optionLabel)}</MenuItem>;
      })
    }
    {
      props.onLoadMoreHandler
    }
  </div>;
})
