import { useMemo }            from "react";
import { ReactNode }          from "react";
import { useCallback }        from "react";
import { KeyboardEvent }      from "react";
import { useState }           from "react";
import { Keys }               from "../../../constants/Keys";
import { useImperativeState } from "../../../hooks/useImperativeState";
import { BoxComponentProps }  from "../../Box";
import { getItemField }       from "./helpers";
import { getItemLabel }       from "./helpers";
import { SelectProps }        from "./Select";

export interface CommonSelectProps extends BoxComponentProps {
  value: number | string | object;
  onChange(value);
  renderSelect?: (option) => ReactNode | string;
  optionKey: string;
  optionLabel: string;
  getOptionValue?: (option) => string;
  options: any[];
}
export function useCommonSelect(props: Partial<SelectProps>) {
  const {
    onClick,
    renderSelect,
    optionKey,
    options,
    optionLabel,
    placeholder,
    getOptionValue,
    onChange,
    value
  } = props;

  const [selected, setSelected] = useImperativeState(getOptionValue ? getOptionValue(value) : value, onChange);
  const [anchorBounding, setAnchorBounding] = useState(null);
  const togglePopper = (event) => {
    onClick && onClick(event);
    setAnchorBounding(!anchorBounding ? event.currentTarget.getBoundingClientRect() : null);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === Keys.Enter || event.key === Keys.Space) {
      togglePopper(event);
    }
  };

  const checkValue = () => options.find(o => getItemField(o, optionKey) === getItemField(selected, optionKey));

  const valueExist = useMemo(() => !!selected, [selected]);

  const showSelectedField = () => {
    return (renderSelect && selected && (typeof renderSelect == "function" ? renderSelect(selected) : renderSelect)) || (
        selected !== null && selected !== undefined && getItemLabel(checkValue(), optionLabel)) ||
      placeholder ||
      "Select value";
  };

  const handleSelect = useCallback((item) => {
    setAnchorBounding(null);
    setSelected(item);
  }, [setSelected]);

  return {
    anchorBounding,
    setAnchorBounding,
    showSelectedField,
    setSelected,
    handleKeyPress,
    togglePopper,
    valueExist,
    handleSelect,
    checkValue,
    selected
  };
}
