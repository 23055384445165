import { FC }                    from "react";
import React                     from "react";
import { classNames }            from "../..";
import { Box }                   from "../Box";
import { BoxItemComponentProps } from "../Box";
import { DividerClasses }        from "./DividerClasses";

export const HorizontalDivider: FC<BoxItemComponentProps & { bold?: boolean }> = React.memo((props) => {
  const { className, bold, ...p } = props;
  return <Box {...p}
    className={classNames(DividerClasses.HorizontalDivider, { [ DividerClasses.HorizontalDividerBold ]: bold }, className)}/>;
});
