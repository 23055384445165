import { CheckboxProps }        from "@relcu/ui";
import { classNames }           from "@relcu/ui";
import React                    from "react";
import { FC }                   from "react";
import { Checkbox }             from "@relcu/ui";
import { BoxComponentProps }    from "@relcu/ui";
import { ContactDialogClasses } from "../ContactDialogClasses";
import { ReadField }            from "./ReadField";

export interface MergeFieldProps extends BoxComponentProps {
  selected: boolean
  value: any
  onChange?()
  checkboxProp?: Partial<CheckboxProps>
}

export const MergeField: FC<MergeFieldProps> = React.memo(function MergeField(props) {
  const { selected, value, onChange, checkboxProp, className, ...p } = props;
  const classes = classNames({ [ ContactDialogClasses.MergeFieldDisabled ]: !value }, className);

  return <ReadField selected={selected} {...p}>
    <Checkbox checked={selected} placeholder={value || "No value"}
              onChange={onChange} className={classes} {...checkboxProp}/>
  </ReadField>;
});
