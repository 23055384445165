import { useImperativeState } from "../../../hooks/useImperativeState";
import { BoxComponentProps }  from "../../Box";
import { BaseInputProps }     from "../BaseInput";

export interface SwitchProps extends BoxComponentProps, BaseInputProps {
  checked: boolean,
  onChange?(e?)
  onBlur?(e?)
  onFocus?(e?)
}

export function useSwitch(props) {
  const { label, onChange: onValueChange, onFocus, onBlur, name, disabled, checked, color } = props;
  const [isChecked, setChecked] = useImperativeState(checked, onValueChange);
  function onChange() {
    setChecked(!isChecked);
  }
  const opts = {
    onChange,
    onFocus,
    onBlur
  };

  return {
    opts,
    label,
    onChange: onValueChange,
    onFocus,
    onBlur,
    color,
    name,
    disabled,
    isChecked
  };
}