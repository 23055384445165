import { gql } from "@apollo/client";

/*---------------------------------------------------------------------------------------------------------*/
export const NODE_FRAGMENT = gql`
  fragment Node on Node{
    id
  }
`;
export const ACL_FRAGMENT = gql`
  fragment ACL on ACL{
    public{
      read
      write
    }
    roles {
      read
      write
      roleName
    }
    teams {
      read
      write
      teamName
    }
    users {
      read
      write
      userId
    }
  }
`;
export const DOCUMENT = gql`
  ${ACL_FRAGMENT}
  fragment Document on Document{
    objectId
    objectName
    objectIcon
    updatedAt
    createdAt
    ACL {
      ...ACL
    }
  }
`;

export const MAILBOX_FRAGMENT = gql`
  fragment Mailbox on Mailbox{
    ...Document
    ...Node
    objectId
    address
    common
    disabled
    auth {
      accessToken
    }
    assignedTo {
      __typename
      ... on User {
        ...Document
        ...Node
      }
      ... on LeadSource {
        ...Document
        ...Node
      }
    }
    domain {
      id
      domain
      disabled
      valid
      provider {
        type
        clientId
      }
    }
  }
  ${DOCUMENT}
  ${NODE_FRAGMENT}
`;

export const ELEMENT = gql`
  fragment Element on Element {
    value
  }
`;
export const PHONE_LINE_FRAGMENT = gql`
  fragment PhoneLine on PhoneLine {
    id
    name
    common
    objectId
    objectIcon
    objectName
    number
    sms
    voice
    mms
    fax
    createdAt
  }
`;
export const MESSAGE_PARTICIPANT_FRAGMENT = gql`
  fragment MessageParticipant on PhoneMessageParticipant {
    type
    party {
      __typename
      ... on User {
        id
        objectId
        objectIcon
        objectName
      }
      ... on Contact {
        id
        objectId
        objectIcon
        objectName
      }
    }
  }
`;

export const FILE_FRAGMENT = gql`
  ${DOCUMENT}
  ${NODE_FRAGMENT}
  fragment File on File {
    ...Node
    ...Document
    url
    deleted
    uploaded
    name
    mimeType
    size
  }
`;
export const MAIL_MESSAGE = gql`
  fragment MailMessage on MailMessage {
    ...Node
    objectId
    emailId
    subject
    html
    snippet
    unsubscribe
    body {
      id
      objectId
      url
    }
    status
    date
    conversation {
      id
      objectId
    }
    loanEstimate {
      id
      objectId
    }
   loanProposal {
     id
     objectId
   }
    attachments {
      size
      url
      filename
      contentId
      file {
        ...Node
        ...Document
        name
        url
        mimeType
        size
        __typename
      }
    }
    template {
      ...Node
      objectId
      objectName
      objectIcon
      content
      title
      subject
    }
    parties {
      type
      unread
      address
      notified
      party {
        ...on Contact {
          company
          primaryPhone
          emails {
            address
            optOut
            type
            isPrimary
          }
          ...Node
          objectId
          objectName
          objectIcon
          updatedAt
        }
        ...on User {
          email
          ...Node
          objectId
          objectName
          objectIcon
          updatedAt
        }
      }
      metadata {
        isDraft
        flags
        labels
        thrId
        uid
        provider
      }
    }
    inReplyTo {
      ...Node
      objectId
    }
    attachments {
      file {
        name
        url
        mimeType
        size
      }
    }
  }
  ${DOCUMENT}
  ${NODE_FRAGMENT}
`;
export const CALL_FRAGMENT = gql`
  fragment Call on Participant {
    status
    type
    party {
      __typename
      ... on User {
        id
        objectId
        objectName
        objectIcon
        firstName
        lastName
      }
      ... on Contact {
        id
        objectId
        objectName
        objectIcon
        firstName
        lastName
        middleName
        phones{
          number
          callOptOut
        }
      }
    }
  }
`;
export const USER_NODE_FRAGMENT = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  ${PHONE_LINE_FRAGMENT}
  fragment UserNode on User {
    ...Node
    ...Document
    firstName
    lastName
    username
    role
    status
    online
    onCall
    team
    nmlsId
    reviewUrl
    calendar
    applyUrl
    positionName
    lastNotificationSeenAt
    bulkPermissions {
      email
      sms
    }
    dataTableView {
      create
      share
    }
    emailTemplateEditorPermissions {
      builder
      plainText
      html
    }
    flyerPermissions {
      create
      send
    }
    notificationControls {
      bulkEmail
      bulkSMS
      emails
      loanComments
      missedCalls
      distributedLead
      sms
      tasksReminders
    }
    phoneLines {
      edges {
        node {
          ...PhoneLine
        }
      }
    }
    voiceMail {
      ...Node
      objectId
      objectName
      objectIcon
      name
      uploaded
      uploadStatus
      url
      type
    }
    voicemailOutOfWork {
      ...Node
      objectId
      objectName
      objectIcon
      name
      uploaded
      uploadStatus
      url
      type
    }
    #velocifyId
    voicemailUrl
    #totalExpertId
    licensedStates
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    custom9
    custom10
    email
    identities {
      provider
      userId
      scope
      accessToken
      updatedAt
      userEmail
      metadata {
        syncEmails
      }
    }
    accountLockoutExpiresAt
    optimalBlue {
      businessChannelId
    }
  }
`;

export const MEMBER_OWN_FIELDS_FRAGMENT = gql`
  fragment MemberOwnFields on LeadMember {
    annualIncome
    bankruptcy
    creditRating
    creditScore
    employmentOccupation
    employmentStatus
    foreclosure
    isMilitary
    isPrimary
    jobStartedAt
    maritalStatus
    monthlyIncome
    type
    typeElaborate
    veteranStatus
  }
`;

export const USER_NOTIFICATION_CONTROLS_FRAGMENT = gql`
  fragment UserNotificationControls on User {
    notificationControls {
      tasksReminders
      sms
      missedCalls
      loanComments
      distributedLead
      emails
      bulkEmail
      bulkSMS
    }
  }
`;

export const USER_HISTORY_BOOKMARK_FRAGMENT = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  ${ELEMENT}
  fragment UserHistoryAndBookmark on User {
    id
    history {
      __typename
      ...Document
      ...on User{
        status
        online
        deactivated
      }
      ...Node
      ...Element
    }
    bookmarks {
      __typename
      ...Document
      ...Node
      ...Element
      ...on User{
        status
        online
        deactivated
      }
    }
  }
`;
export const USER_FRAGMENT = gql`
  ${USER_NODE_FRAGMENT}
  ${USER_HISTORY_BOOKMARK_FRAGMENT}
  fragment User on User{
    ...UserNode
    ...UserHistoryAndBookmark
  }
`;
export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on Notification {
    id
    objectId
    updatedAt
    createdAt
    unread
    type
    data
    record {
      __typename
      ... on Node {
        id
      }
      ... on Document {
        objectId
        objectName
      }
      ... on Task {
        dueDate
        title
      }
      ... on Conference {
        status
        unread
      }
      ... on PhoneMessage {
        content
        unread
        phoneMessageConversation: conversation {
          objectId
        }
      }
      ... on EmailMessage {
        subject
        __typename
        conversation {
          __typename
          objectId
        }
      }
      ... on MailMessage {
        subject
        __typename
        conversation {
          __typename
          objectId
        }
      }
      ... on MailConversation {
        subject
      }
      ... on DistributionQueue {
        disabled
        autoDial
        deleted
      }
      ... on PhoneMessageConversation {
        content
        unread
        lastUpdatedAt
      }
    }
    scope {
      __typename
      ...on Node {
        id
      }
      ...on Document {
        objectId
        objectName
      }
    }
  }
`;
export const PRIORITY_QUEUE_FRAGMENT = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  fragment PriorityQueue on PriorityQueue{
    ...Node
    ...Document
    title
    fields {
      properties
      key
      fixed
    }
    priorityView {
      id
    }
    priority
    leadSort
    leadCount
    enabled
    expandable
    leadFilter
  }
`;
export const PRIORITY_VIEW_FRAGMENT = gql`
  ${PRIORITY_QUEUE_FRAGMENT}
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  fragment PriorityView on PriorityView{
    ...Node
    ...Document
    title
    priorityQueues {
      edges {
        node {
          ...PriorityQueue
        }
      }
    }
    priority
    rPerm
  }
`;
export const VIEWER_TW_TOKEN = gql`
  query ViewerTwToken {
    viewer {
      twilioToken
      sessionToken
      user {
        id
      }
    }
  }
`;

export const VIEWER_PHONE_LINES = gql`
  query ViewerPhoneLines {
    viewer {
      user {
        id
        objectId
        phoneLines {
          edges {
            node {
              number
            }
          }
        }
      }
    }
  }
  ${PHONE_LINE_FRAGMENT}
`;

export const VIEWER_USER_NODE = gql`
  query ViewerUserNode {
    viewer {
      sessionToken
      user {
        ...UserNode
      }
    }
  }
  ${USER_NODE_FRAGMENT}
`;

export const ROOT = gql`
  query Root {
    viewer {
      sessionToken
      twilioToken
      user {
        ...User
      }
    }
    settings:settings_v2
    phoneLines(where:{common: {equalTo: true}} ){
      edges{
        node{
          ...PhoneLine
        }
      }
    }
    notifications(where: {unread: {equalTo: true},type: {in: ["sms","email","bulk_email", "bulk_sms","reminder","missed_call","milestone_comment"]},record: {exists: true}},order: [createdAt_DESC]){
      edges {
        node {
          ...Notification
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${PHONE_LINE_FRAGMENT}
  ${NOTIFICATION_FRAGMENT}
`;

export const GET_MAILBOXES = gql`
  query GetMailBoxes($where: MailboxWhereInput) {
    mailboxes(where: $where) {
      edges {
        node {
          ...Mailbox
        }
      }
    }
  }
  ${MAILBOX_FRAGMENT}
`;

export const SUBSCRIBE_MAILBOXES = gql`
  subscription SubscribeMailBoxes($where: MailboxSubscriptionWhereInput) {
    mailboxes(where: $where, events: [CREATE, ENTER,LEAVE, UPDATE]) {
      event
      node {
        ...Mailbox
      }
    }
  }
  ${MAILBOX_FRAGMENT}
`;
export const SUBSCRIBE_MAILBOXES_DELETE = gql`
  subscription SubscribeMailBoxesDelete($where: MailboxSubscriptionWhereInput) {
    mailboxes(where: $where, events: [ DELETE]) {
      event
      node {
        ...Node
      }
    }
  }
  ${NODE_FRAGMENT}
`;

export const TIME_ZONE_CONTACT_NAME_FRAGMENT = gql`
  fragment LeadTimeZone on Lead {
    members{
      type
      isPrimary
      contact{
        objectId
        objectName
        firstName
        lastName
        middleName
      }
    }
    timezone
  }
`;
export const GET_SETTINGS = gql`
  query GetSettings {
    settings:settings_v2
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($id: ID!, $fields: UpdateSettingsFieldsInput!) {
    updateSettings(input: { id: $id, fields: $fields})
  }
`;

export const SUBSCRIBE_UNREAD_NOTIFICATIONS = gql`
  subscription SubscribeUnreadNotifications {
    notifications(where: {unread: {equalTo: true},type: {in: ["sms","email","bulk_email", "bulk_sms","reminder","missed_call","milestone_comment", "distributed_lead"]},record: {exists: true}}) {
      event
      node {
        ...Notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const LOAN_ESTIMATE_OFFER_OB = gql`
  fragment LoanEstimateOfferOB on LoanEstimateOptimalBlue {
    productTypes
    source
    automatedUnderwritingSystem
    debtServiceCoverageRatio
    monthsReserves
    prepaymentPenalty
    view
  }
`;
export const LOAN_ESTIMATE_OFFER = gql`
  fragment LoanEstimateOffer on LoanEstimateOffer {
    pricingEngine
    adminFee
    appraisalFee
    apr
    cashToClose
    cltv
    createdAt
    creditReportFee
    deleted
    dti
    electronicDocumentDelivery
    fico
    fico1
    financeMip
    mip
    financeFf
    ff
    firstTimeHomeBuyer
    firstTimeUse
    firstUseOfVaProgram
    floodCertificationFee
    id
    objectId
    insurance
    mortech {
      investor
      loanProductId
      loanProductName
      source
      view
    }
    optimalBlue {
      ...LoanEstimateOfferOB
    }
    isHUDReo
    lenderCredits
    discountPoints
    points
    loanAmount
    loanProduct
    loanSafeFee
    loanTerm
    lockInDays
    lockInFee
    amiWaiverEligibility
    ltv
    mersFee
    mip
    objectIcon
    objectId
    objectName
    ownersTitle
    pestInspectionFee
    docReviewFee
    attorneyFee
    surveyFee
    subordinationFee
    condoCertificateFee
    earnestMoneyPaid
    sellerConcession
    pmi
    pmiType
    pmiEligible
    pmiCompany
    titleCompany
    titleCompanyName
    prepaidInterestAmount
    prepaidInterestDays
    prepaidInterestTotal
    propertyInsurancePrepaidAmount
    propertyInsurancePrepaidMonth
    propertyInsurancePrepaidTotal
    propertyTaxMonth
    propertyTaxAmount
    propertyTaxTotal
    propertyInsuranceYearly
    price
    productType
    propertyValue
    propertyType
    amortizationType
    initialArmTerm
    downPayment
    currentMortgageBalance
    cashAmount
    purchasePrice
    isStreamLine
    withCredit
    withAppraisal
    loanProgram
    propertyUse
    veteranStatus
    rate
    pi
    monthlyPremium
    rateUpdated
    rateInvestor
    rateId
    recordingCharges
    secondaryFinancing
    settlementFee
    taxes
    titleInsurance
    totalLoanAmount
    transcriptFee
    transferTax
    updatedAt
    verificationFee
    waive
    waiveEscrow
    conforming
    documentType
    creditReportTotal
    closingCostTotal
    monthlyPaymentTotal
    otherCostTotal
    prepaidCostTotal
    titleFeeTotal
    exempt
    monthlyIncome
    lienAmount
    isValid @client
    isDirty @client
    isTitleFeeEditable @client
  }
  ${LOAN_ESTIMATE_OFFER_OB}
`;
export const LOAN_ESTIMATE = gql`
  fragment LoanEstimate on LoanEstimate {
    applyUrl
    createdAt
    draft
    file {
      ...File
    }
    id
    image {
      ...File
    }
    loanPurpose
    objectIcon
    objectId
    objectName
    propertyAnnualInsurance
    propertyAnnualTax
    propertyCity
    propertyCountry
    propertyCounty
    propertyOccupancy
    propertyState
    propertyType
    propertyZipCode
    propertyFipsCode
    sent
    updatedAt
    offers{
      edges {
        node {
          ...LoanEstimateOffer
        }
      }
    }
  }
  ${FILE_FRAGMENT}
  ${LOAN_ESTIMATE_OFFER}
`;
