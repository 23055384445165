import { useRef }    from "react";
import { useEffect } from "react";

export function useVisualizer(dataArray: Uint8Array) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    // const dpr = window.devicePixelRatio || 1;
    // canvas.width = canvas.width * dpr;
    // canvas.height = (canvas.height * 2) * dpr;
    // contextRef.current.scale(dpr, dpr);//todo do not remove this(thoughts for the future)
    canvas.height = (canvas.height * 2);
    contextRef.current = canvas.getContext("2d");
    contextRef.current.lineWidth = 4;
    contextRef.current.strokeStyle = "#92c353";
    contextRef.current.lineCap = "round";
  }, []);

  useEffect(() => {
    const bufferLength = 16;
    const barWidth = 4;
    const gap = (canvasRef.current?.width - (bufferLength * barWidth)) / (bufferLength + 1);
    let barHeight;
    let centerY = canvasRef.current?.height / 2;
    let ctx = contextRef.current;
    ctx.clearRect(0, 0, canvasRef.current?.width, canvasRef.current?.height);
    ctx.fillStyle = "#92c353";
    ctx.lineWidth = barWidth;
    ctx.lineCap = "round";
    let x = gap;
    for (let i = 0; i < bufferLength; i++) {
      barHeight = dataArray[ i ] * 0.03;
      ctx.beginPath();
      ctx.moveTo(x, centerY - barHeight);
      ctx.lineTo(x, centerY);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(x, centerY + barHeight);
      ctx.lineTo(x, centerY);
      ctx.stroke();
      // contextRef.current.fillRect(x, centerY, barWidth, barHeight);
      // contextRef.current.fillRect(x, centerY, barWidth, -barHeight);
      x += barWidth + gap;
    }
  }, [dataArray]);

  return {
    canvasRef
  };
}
