import React, { useContext }                                 from "react";
import Input                                                 from "../Input";
import { useClassNames }                                     from "../utils";
import { TypeAttributes, FormControlBaseProps, WithAsProps } from "../@types/common";
import FormContext                                           from "../Form/FormContext";
import { FormGroupContext }                                  from "../FormGroup/FormGroup";

/**
 * Props that FormControl passes to its accepter
 */
export type FormControlAccepterProps<ValueType = any> = FormControlBaseProps<ValueType>;

export interface FormControlProps<P = any, ValueType = any>
  extends WithAsProps,
    Omit<React.HTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  /** Proxied components */
  accepter?: React.ElementType<P & FormControlBaseProps<ValueType>>;

  /** The name of form-control */
  name: string;

  /** Value */
  value?: ValueType;

  /** Callback fired when data changing */
  onChange?(value: ValueType, event: React.SyntheticEvent): void;

  /** Make the control readonly */
  readOnly?: boolean;

  /** Render the control as plain text */
  plaintext?: boolean;

  prefix?: string;

  /** Disable the form control. */
  disabled?: boolean;

  /** Remove field value and error message when component is unmounted  */
  shouldResetWithUnmount?: boolean;
}

interface FormControlComponent extends React.FC<FormControlProps> {
  <Accepter extends React.ElementType = typeof Input>(
    props: FormControlProps & { accepter?: Accepter } & React.ComponentPropsWithRef<Accepter>
  ): React.ReactElement | null;
}

export const FormControl: FormControlComponent = React.forwardRef((props: FormControlProps, ref) => {
  const {
    readOnly: readOnlyContext,
    plaintext: plaintextContext,
    disabled: disabledContext
  } = useContext(FormContext);

  const {
    as: Component = "div",
    accepter: AccepterComponent = Input,
    classPrefix = "form-control",
    name,
    value,
    readOnly = readOnlyContext,
    plaintext = plaintextContext,
    disabled = disabledContext,
    prefix: inputPrefix,
    onChange,
    onBlur,
    defaultValue,
    shouldResetWithUnmount = false,
    ...rest
  } = props;

  const { controlId } = useContext(FormGroupContext);

  const { withClassPrefix, prefix } = useClassNames(classPrefix);
  const classes = withClassPrefix("wrapper", { prefix: !!inputPrefix });

  const handleFieldChange = (value: any, event: React.SyntheticEvent) => {
    onChange?.(value, event);
  };
  const handleFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
  };

  const ariaDescribedby = controlId ? `${controlId}-help-text` : null;

  return (
    <Component className={classes} ref={ref}>
      {!!inputPrefix && <span className={prefix("input-prefix")}>{inputPrefix}</span>}
      <AccepterComponent
        id={controlId}
        aria-labelledby={controlId ? `${controlId}-control-label` : null}
        aria-describedby={ariaDescribedby}
        {...rest}
        readOnly={readOnly}
        plaintext={plaintext}
        disabled={disabled}
        name={name}
        value={value}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
      />
    </Component>
  );
});

FormControl.displayName = "FormControl";

export default FormControl;
