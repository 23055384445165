import { useCallback }            from "react";
import { useMemo }                from "react";
import { useUnreadNotifications } from "../useUnreadNotifications";

export function useStat(id?: string) {
  const notifications = useUnreadNotifications();
  return useMemo(() => getStats(notifications, id), [notifications, id]);
}

export function useLazyStats() {
  const notifications = useUnreadNotifications();
  return useCallback((id?: string) => getStats(notifications, id), [notifications]);
}

function getStats(notifications: ReturnType<typeof useUnreadNotifications>, id?: string) {
  function filter(e, type) {
    return e.node.type === type && (id ? (e.node?.scope?.id === id || e.node?.user?.id === id) : true);
  }
  return {
    missedCallsCount: notifications?.edges.filter(e => filter(e, "missed_call")).length || 0,
    unreadMessagesCount: notifications?.edges.filter(e => filter(e, "sms")).length || 0,
    unreadEmailsCount: notifications?.edges.filter(e => filter(e, "email")).length || 0,
    unreadPipelinesCount: notifications?.edges.filter(e => filter(e, "milestone_comment") && e.node.data.loanId).length || 0,
    remindersCount: notifications?.edges.filter(e => filter(e, "reminder")).length || 0,
    bulkEmailsCount: notifications?.edges.filter(e => filter(e, "bulk_email")).length || 0,
    bulkSMSCount: notifications?.edges.filter(e => filter(e, "bulk_sms")).length || 0
  };
}
