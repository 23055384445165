export const enum RatePartnerClasses {
  RatePartner = "rate-partner",
  RatePartnerTitle = "rate-partner-title",
  RatePartnerSum = "rate-partner-sum",
  RatePartnerLoading = "rate-partner-loading",
  RatePartnerError = "rate-partner-error",
  RatePartnerButton = "rate-partner-button",
  EmptyPartnerTitle = "empty-partner-title",
  RatePartnerTitleTotal = "empty-partner-sum-title",
  RatePartnerSumTotal = "empty-partner-sum-total",
  EmptyPartner = "empty-partner",
  RatePartnerLogoSmall = "rate-partner-logo-small",
  RatePartnerLogoSmallBackdrop = "rate-partner-logo-small-bg",
  RatePartnerLogoSmallBackdropText = "rate-partner-logo-small-bg-text",
  RatePartnerPreview = "rate-partner__preview",
  RatePartnerFail = "rate-partner__fail",
  RatePartnerPreviewWarning = "rate-partner__preview_warning",
  RatePartnerPreviewError = "rate-partner__preview_error",
}
