import React                     from "react";
import { FC }                    from "react";
import { TextField }             from "../../../Field/TextField";
import { TextFieldProps }        from "../../../Field/TextField";
import { FieldContainer }        from "../FieldContainer";

export interface EmailInputFieldProps extends TextFieldProps {
  label: string;
}

export const EmailInputField: FC<EmailInputFieldProps> = React.memo(function EmailInputField(props) {
  const { label, name, className,disabled, ...p } = props;
  return <FieldContainer label={label} className={className} {...p}>
    <TextField disabled={disabled} name={name} message={null} state={null}/>
  </FieldContainer>;
});