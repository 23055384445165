import React                 from "react";
import { FC }                from "react";
import { classNames }        from "../../../../../utils/classNames";
import { Box }               from "../../../../Box";
import { BoxComponentProps } from "../../../../Box";

export interface EmailHeaderProps extends BoxComponentProps {
}

export const EmailInfo: FC<EmailHeaderProps> = React.memo(function EmailInfo(props) {
  const { className, ...p } = props;
  const classes = classNames(EmailInfoClasses.EmailInfo, className);

  return <Box container wrap={"nowrap"} gap={"XS"} className={classes} justify={"space-between"}
              alignItems={"center"} {...p}/>;
});

export const enum EmailInfoClasses {
  EmailInfo = "email-info"
}