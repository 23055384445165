import { useSubscription }        from "@apollo/client";
import { useQuery }               from "@apollo/client";
import { gql }                    from "@apollo/client";
import { useSearchParams }        from "@relcu/react-router";
import { useState }               from "react";
import { useMemo }                from "react";
import { GetFocusViews }          from "./__types__/GetFocusViews";
import { SubscribePriorityViews } from "./__types__/SubscribePriorityViews";

export const GET_FOCUS_VIEWS = gql`
  query GetFocusViews {
    priorityViews(order: [priority_ASC]){
      count
      edges {
        node {
          id
          objectId
          createdAt
          updatedAt
          title
          priority
          rPerm
        }
      }
    }
  }
`;

export const SUBSCRIBE_PRIORITY_VIEWS = gql`
  subscription SubscribePriorityViews {
    priorityViews {
      event
      node {
        id
        objectId
        createdAt
        updatedAt
        title
        priority
        rPerm
      }
    }
  }
`;

export function useFocusView() {
  const [visible, setVisible] = useState(false);
  const { data: { priorityViews: { edges = [] } = {} } = {}, loading, refetch } = useQuery<GetFocusViews>(GET_FOCUS_VIEWS);
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view") || edges[ 0 ]?.node?.objectId;
  useSubscription<SubscribePriorityViews>(SUBSCRIBE_PRIORITY_VIEWS, {
    onSubscriptionData: ({ subscriptionData: { data: { priorityViews: { event } } } }) => {
      refetch();
    }
  });
  const views = useMemo(() => {
    return edges.map(({ node }) => {
      return {
        onSelect() {
          setSearchParams({ view: node.objectId });
        },
        title: node.title,
        selected: node.objectId === view
      };
    });
  }, [edges, view]);

  return {
    loading,
    visible,
    setVisible,
    views,
    view
  };
}
