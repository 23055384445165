import React                   from "react";
import { FC }                  from "react";
import { useRef }              from "react";
import { Typography }          from "@relcu/ui";
import { TypographyWeight }    from "@relcu/ui";
import { TypographySize }      from "@relcu/ui";
import { useMounted }          from "@relcu/ui";
import { ButtonVariants }      from "@relcu/ui";
import { ButtonColors }        from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { RecordingTimer }      from "@relcu/ui";
import { DisabledVisualizer }  from "@relcu/ui";
import { Visualizer }          from "@relcu/ui";
import { SoundSectionClasses } from "../SoundSectionClasses";
import { useAudio }            from "../useAudio";
import useAudioInput           from "./useAudioInput";

interface AudioControllerProps {
  outputDeviceId: string;
  inputDeviceId: string;
  onRecord: (record: boolean) => void;
  onListen: (play: boolean) => void;
  disabled?: boolean;
}

export const AudioController: FC<AudioControllerProps> = React.memo(function AudioController(props) {
  const { outputDeviceId, inputDeviceId, onListen, onRecord, disabled } = props;
  const {
    error,
    dataArray,
    isRecording,
    isAudioInputTestRunning,
    playbackURI,
    setPlaybackURI,
    recordDuration,
    readAudioInput,
    stopReadAudioInput
  } = useAudioInput();
  const { playAudio, pauseAudio, resumeAudio, status, currentTime } = useAudio({
    url: playbackURI,
    deviceId: outputDeviceId,
    duration: recordDuration,
    countdown: true
  });
  const previousInputDeviceIdRef = useRef("");

  function handleStopRead() {
    stopReadAudioInput();
  }

  function handleStartRead() {
    const newDeviceSelected = previousInputDeviceIdRef.current !== inputDeviceId;
    previousInputDeviceIdRef.current = inputDeviceId;

    // Restarts the test to continuously capture audio input
    if (!error && (newDeviceSelected || (!isRecording && !isAudioInputTestRunning))) {
      readAudioInput({ deviceId: inputDeviceId, enableRecording: true });
      setPlaybackURI(null);
    }
  }

  async function handlePlayerClick() {
    switch (status) {
      case "playing":
        pauseAudio();
        break;
      case "pause":
        resumeAudio();
        break;
      case "ended":
      case "idle":
        playAudio();
        break;
      default:
        playAudio();
    }
  }

  useMounted(() => {
    if (status == "playing") {
      onRecord(false);
      onListen(false);
      return;
    } else {
      onRecord(true);
      onListen(true);
    }
  }, [status]);

  useMounted(() => {
    if (isAudioInputTestRunning) {
      onRecord(false);
      onListen(true);
    } else {
      onRecord(true);
    }
  }, [isAudioInputTestRunning]);
  return <Box container direction={"column"} gap={"S"} alignItems={"center"} justify={"center"}
              className={SoundSectionClasses.Audio}>
    {
      playbackURI ?
        <Box container direction={"column"} alignItems={"center"} gap={"XS"}>
          {
            recordDuration &&
            <Typography size={TypographySize.Subtitle}
                        weight={TypographyWeight.Medium}>{new Date(currentTime || recordDuration).toISOString().substr(11, 8)}</Typography>
          }
          <Box container gap={"XXS"} wrap={"wrap"} justify={"center"} className={SoundSectionClasses.AudioActions}>
            <Button icon={status == "playing" ? "pause" : "play_arrow"}
                    onClick={handlePlayerClick}>{status == "playing" ? "PAUSE" : "LISTEN"}</Button>
            <Button disabled={status == "playing"} icon="record_voice_over" variant={ButtonVariants.Outline}
                    onClick={handleStartRead}>RECORD</Button>
            <Button disabled={status == "playing"} variant={ButtonVariants.Ghost} onClick={() => setPlaybackURI("")}>CLOSE
              RECORDER</Button>
          </Box>
        </Box> :
        <>
          {
            isAudioInputTestRunning && dataArray &&
            <Visualizer dataArray={dataArray}/>
          }
          {
            isAudioInputTestRunning ?
              <>
                <Button icon="stop" onClick={handleStopRead} color={ButtonColors.Error}>STOP RECORDING</Button>
                <RecordingTimer/>
              </>
              :
              <>
                <DisabledVisualizer/>
                <Button icon="record_voice_over" onClick={handleStartRead} disabled={disabled}>RECORD TO TEST</Button>
                <Box style={{ height: 12 }}/>
              </>
          }
        </>
    }
  </Box>;
});
