import { getField } from "./schemaUtils";
const createSub = (id: string, className: string, viewer) => {
  const { role, id: viewerId } = viewer;
  let whereMap: Record<string, any> = {
    [ className ]: {
      AND: [
        {
          scope: {
            have: {
              link: id
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                exists: true
              }
            }
          }
        }
      ]
    },
    "Contact": {
      participants: {
        have: {
          party: {
            have: {
              link: id
            }
          }
        }
      }
    }
  };
  if (role != "admin") {
    if(className !== "Lead"){
      whereMap[`${className}`] = ({
        AND: [
          {
            scope: {
              have: {
                link: id
              }
            }
          },
          {
            participants: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                }
              }
            }
          }
        ]
      } as any);
    }
    whereMap[ "Contact" ] = ({
      AND: [
        {
          participants: {
            have: {
              party: {
                have: {
                  link: id
                }
              }
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                have: {
                  link: viewerId
                }
              }
            }
          }
        }
      ]
    } as any);
  }
  return whereMap[ className ];
};
const createQuery = (id: string, className: string, viewer) => {
  const schemaField = getField("PhoneMessage", "scope");
  const { role, id: viewerId } = viewer;
  let whereMap: Record<string, any> = {
    [ className ]: {
      AND: [
        {
          scope: {
            have: {
              id: {
                equalTo: id
              }
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                exists: true
              }
            }
          }
        }
      ]

    }
  };
  if(whereMap[className]?.AND && Array.isArray(schemaField?.targetClass)){
    whereMap[className].AND[0] = {
      scope: {
        have: {
          link: id
        }
      }
    }
  }
  if (role != "admin") {
    if(className !== "Lead"){
      whereMap[`${className}`] = ({
        AND: [
          {
            scope: {
              have: {
                link: id
              }
            }
          },
          {
            participants: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                }
              }
            }
          }
        ]
      } as any);
    }
    whereMap[ "Contact" ] = {
      AND: [
        {
          participants: {
            have: {
              party: {
                have: {
                  link: id
                }
              }
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                have: {
                  link: viewerId
                }
              }
            }
          }
        }

      ]
    };
  } else {
    whereMap[ "Contact" ] = {
      participants: {
        have: {
          party: {
            have: {
              link: id
            }
          }
        }
      }
    };
  }
  return whereMap[ className ];
};
const createSubscriptionWhereInput = (node, viewer) => {
  return createSub(node.id, node.__typename, viewer);
};

const createQueryWhereInput = (node, viewer) => {
  const { __typename, id } = node;
  return createQuery(id, __typename, viewer);
};
export const createWhereInputVariables = (node, viewer) => {
  return {
    queryVariables: { where: createQueryWhereInput(node, viewer) },
    subscriptionVariables: { where: createSubscriptionWhereInput(node, viewer) }
  };
};
