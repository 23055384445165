import { useCallback } from "react";

export function useNumberUtils (props) {
  const parse = (value, name) => {
    return props.parse ? props.parse(value, name) : value;
  };
  const clean = (value) => {
    return String(value).replace(/[^.\-\d]/g, "").replace(/^-?(\d*\.?)|(\d*)\.?/g, "$1$2");
  };
  const toFixedNoRounding = useCallback((number: number | string, n: number) => {
    const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
    const match = String(number).match(reg);
    if (!match) {
      return;
    }
    const a = match[ 0 ];
    const dot = a.indexOf(".");
    if (dot === -1) { // integer, insert decimal dot and pad up zeros
      return a + "." + "0".repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? (a + "0".repeat(b)) : a;
  }, []);


  return {
    parse,
    clean,
    toFixedNoRounding
  }
}