import React                 from "react";
import { BadgeVariants }     from "../../..";
import { BadgeSize }         from "../../..";
import { ButtonVariants }    from "../../..";
import { BoxComponentProps } from "../../..";
import { classNames }        from "../../..";
import { Button }            from "../../..";
import { Box }               from "../../..";
import { StatusBadge }       from "../StatusBadge";

export interface StatusBarProps extends BoxComponentProps {
  title: string;
  description: string;
  status: "away" | "busy" | "out_of_work";
  onAvailable?(event: React.SyntheticEvent, status: "available");
  collapsed?: boolean
}

export const StatusBar = React.memo<StatusBarProps>(function StatusBar(props) {
  const { className, collapsed, onAvailable, title, description, status, ...p } = props;
  const classes = classNames(StatusBarClasses.StatusBar, className);
  return (<>
      {!collapsed ?
        <Box container justify={"space-between"} direction={"column"} alignItems={"center"} className={classes}
             gap={"XXS"} {...p}>
          <Box container direction={"column"} flex={1} gap={"XXXS"} justify={"center"} alignItems={"center"}>
            <StatusBadge size={BadgeSize.big} status={status} variant={BadgeVariants.Regular}/>
            <Box container direction={"column"} alignItems={"center"}>
              <p className={StatusBarClasses.StatusBarTitle}>{title}</p>
              <p className={StatusBarClasses.StatusBarDescription}>{description}</p>
            </Box>
          </Box>
          <Button variant={ButtonVariants.Ghost} onClick={(e) => onAvailable?.(e, "available")}>CHANGE TO
            AVAILABLE</Button>
        </Box> :
        <Box container direction={"column"} gap={"XXXS"} alignItems={"center"}>
          <StatusBadge size={BadgeSize.big} status={status} variant={BadgeVariants.Regular}/>
        </Box>
      }
    </>
  );
});
export enum StatusBarClasses {
  StatusBar = "status-bar",
  StatusBarTitle = "status-bar__title",
  StatusBarDescription = "status-bar__description",
}
