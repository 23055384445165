import React               from "react";
import { DatePicker }      from "../../Input/DatePicker";
import { InputState }      from "../../Input/Input";
import { useConsumeField } from "../BaseField";
import { DateFieldProps }  from "./DateField";

export const DateEditField = React.memo(function DateEditField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField<DateFieldProps>();
  const type = input.type === "date" ? input.type : "datetime";
  const hasError = (touched && !!error) || !!submitError;

  return (
    <DatePicker
      {...input}
      type={type}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
    />
  );
});
export default DateEditField;
