import { Form } from "@relcu/rc";
import React    from "react";

export const ProposalPropertyInsurance = React.memo(function ProposalPropertyInsurance() {
  return (
    <Form.InputNumber
      name="propertyAnnualInsurance"
      label="Annual property insurance"
      prefix="$"
      size="lg"
      required
      properties={{ style: { width: "100%" } }}
    />
  );
});
