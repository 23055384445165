import React                  from "react";
import { FC }                 from "react";
import { useState }           from "react";
import { getMessageStatus }   from "@relcu/ui";
import { EmailInfoPrimary }   from "@relcu/ui";
import { EmailInfo }          from "@relcu/ui";
import { EmailHeaderContent } from "@relcu/ui";
import { Avatar }             from "@relcu/ui";
import { EmailAvatar }        from "@relcu/ui";
import { EmailHeader }        from "@relcu/ui";
import { CommonClasses }      from "@relcu/ui";
import { classNames }         from "@relcu/ui";
import { FontIcon }           from "@relcu/ui";
import { TypographyColor }    from "@relcu/ui";
import { Typography }         from "@relcu/ui";
import { ButtonVariants }     from "@relcu/ui";
import { ButtonSizes }        from "@relcu/ui";
import { Button }             from "@relcu/ui";
import { Box }                from "@relcu/ui";
import "./bulk.css";

export interface BulkPreviewProps {
  selectedItem: { cursor: string } | null;
  count: number;
  skip: number;
  selectedIndex: number;
  slide: (path: string) => void;
  status: string | null;
  statusMsg?: string | null;
  messageStatus: string | null;
  receiver: string;
}

export const BulkPreview: FC<BulkPreviewProps> = React.memo(function BulkPreview(props) {
  const { count, selectedItem, status, slide, messageStatus, receiver, children, statusMsg, selectedIndex, skip } = props;

  const [currentIndex, setCurrentIndex] = useState(skip + selectedIndex);
  const handleClick = (action) => {
    setCurrentIndex(prev => {
      if (action == "after" && count > prev + 1) {
        slide(action);
        return prev + 1;
      } else if (action == "before" && 0 < prev) {
        slide(action);
        return prev - 1;
      }
      return prev;
    });

  };

  return <>
    <Box container alignItems={"center"} justify={"space-between"} className={"preview-action-row"}>
      <Button
        onClick={() => {
          slide(null);
        }}
        icon={"arrow_back"}
        size={ButtonSizes.Large}
        variant={ButtonVariants.Ghost}
      >
        Back to statistics
      </Button>
      {status && count > 1 &&
        <Box container gap={"XXS"} alignItems={"center"} className={"preview-slide-actions"}>
          {
            status && statusMsg &&
            <Typography color={TypographyColor.Tertiary}>
              {statusMsg}
            </Typography>
          }
          <Typography color={TypographyColor.Tertiary}>
            {currentIndex + 1}/{count}
          </Typography>
          <FontIcon
            onClick={() => handleClick("before")}
            type={"keyboard_arrow_left"}
            className={classNames("left_right-buttons", CommonClasses.ClickableIcon)}
          />
          <FontIcon
            onClick={() => handleClick("after")}
            type={"keyboard_arrow_right"}
            className={classNames("left_right-buttons", CommonClasses.ClickableIcon)}
          />
        </Box>}
      <Typography>
        {getMessageStatus(messageStatus)}
      </Typography>
    </Box>
    {
      receiver && <EmailHeader>
        <EmailAvatar>
          <Avatar text={receiver}/>
        </EmailAvatar>
        <EmailHeaderContent flex={1}>
          <EmailInfo>
            <EmailInfoPrimary>
              {receiver}
            </EmailInfoPrimary>
          </EmailInfo>
        </EmailHeaderContent>
      </EmailHeader>
    }
    {children}
  </>;
});
