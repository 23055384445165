import React                 from "react";
import { FC }                from "react";
import { useCallback }       from "react";
import { useState }          from "react";
import { useBoxProps }       from "../..";
import { Box }               from "../..";
import { classNames }        from "../..";
import { CommonClasses }     from "../..";
import { FontIcon }          from "../..";
import { GlobalClasses }     from "../..";
import { useAlert }          from "../..";
import { BoxComponentProps } from "../Box";

export enum LinkExternalColors {
  Primary = "primary",
}

export type LinkExternalProps = BoxComponentProps & {
  allowCopy?: boolean
  color?: LinkExternalColors
  target?: string
  to?: string
  copyText?: string
  ellipsis?:boolean
};

export const LinkExternal: FC<LinkExternalProps> = React.memo(function LinkExternal(props) {
  const { className, children, to, color, copyText, allowCopy = false, ellipsis=true, target = "_blank", ...p } = props;

  const classes = classNames(LinkExternalClasses.Link, {
    [ LinkExternalClasses.Primary ]: color == LinkExternalColors.Primary
  }, className);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      {allowCopy ?
        <CopyText value={copyText || to} ellipsis={ellipsis} {...p} >
          <a
            href={to}
            className={classes}
            target={target}
            {...p}
          >
            {children || to}
          </a>
        </CopyText> :
        <a
          href={to}
          className={classes}
          target={target}
          {...p}
        >
          {children || to}
        </a>
      }
    </Box>
  );
});

export enum LinkExternalClasses {
  Link = "link-external",
  Primary = "link-external--primary"
}

export type CopyTextProps = BoxComponentProps & {
  value: string
  ellipsis?:boolean
  showCopyByDefault?:boolean
};
export const CopyText: FC<CopyTextProps> = React.memo(function CopyText(props) {
  const { children, value, ellipsis = true, showCopyByDefault = false, ...p } = props;
  const { success } = useAlert();
  const [showCopy, setShowCopy] = useState(showCopyByDefault);
  const handleCopy = useCallback((e) => {
    let link = value;
    if (value.startsWith("mailto:", 0)) {
      link = value.slice(7);
    }
    navigator.clipboard.writeText(link);
    success("Link copied.");
  }, []);

  const handleMouseToggle = useCallback(() => {
    setShowCopy(!showCopy);
  }, [showCopy]);

  const classes = classNames({
    [ GlobalClasses.EllipsisFlex ]: ellipsis
  });

  const mousePointerProps = showCopyByDefault ? {} : {
    onMouseEnter:handleMouseToggle,
    onMouseLeave:handleMouseToggle
  };

  return (
    <Box
      container
      {...mousePointerProps}
      alignItems={"center"}
      gap={"XXS"}
      {...useBoxProps(p)}
    >
      <Box className={classes}>
        {children}
      </Box>
      {showCopy &&
      <Box container>
        <FontIcon
          type={"content_copy"}
          className={CommonClasses.ClickableIcon}
          onClick={handleCopy}
        />
      </Box>
      }
    </Box>
  );
});
