import { useMemo }            from "react";
import React                  from "react";
import { useBoxProps }        from "../../Box";
import { Box }                from "../../Box";
import { Label }              from "../../Label";
import { LinkExternal }       from "../../LinkExternal";
import { LinkExternalColors } from "../../LinkExternal";
import { useConsumeField }    from "../BaseField";
import { BaseFieldClasses }   from "../BaseField";
import { AddressFieldProps }  from "./AddressField";

export const AddressReadField = React.memo(function AddressReadField(props) {
  const { input: { readPlaceholder, className, showZillow, label, value, ...addressFieldProps } } = useConsumeField<AddressFieldProps>();
  const zillowLink = useMemo(() => {
    if (value?.formatted) {
      return `https://www.zillow.com/homes/${value.formatted.replaceAll(",", "").replaceAll(" ", "-")}_rb/`;
    }
    return `https://www.zillow.com/homes/`;
  }, [value?.formatted]);
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(addressFieldProps)}>
      <Label>{label}</Label>
      <Box container direction={"column"} gap={"XXXS"}>
          {
              value && value.formatted ?
                (showZillow ? <LinkExternal to={zillowLink} color={LinkExternalColors.Primary} allowCopy title={"Go to Zillow"} ellipsis={false} >{value.formatted}</LinkExternal> : <>{value.formatted}</>):
                <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
          }
      </Box>
    </Box>
  );
});

export default AddressReadField;//todo need to test
