import React               from "react";
import { CheckboxProps }   from "../../Input/Checkbox";
import { Checkbox }        from "../../Input/Checkbox";
import { InputState }      from "../../Input/Input";
import { useConsumeField } from "../BaseField";

export const SwitchEditField = React.memo(function SwitchEditField(props) {
  const { input, meta: { touched, error } } = useConsumeField();
  const hasError = touched && !!error;
  delete input[ "public" ];//todo find public in backend
  return (
    <Checkbox
      {...input as CheckboxProps}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? error : undefined}
    />
  );
});
export default SwitchEditField;
