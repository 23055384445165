import { useApolloClient }                                     from "@apollo/client";
import { useLazyQuery }                                        from "@apollo/client";
import { gql }                                                 from "@apollo/client";
import { useField }                                            from "@relcu/final-form";
import { useForm }                                             from "@relcu/final-form";
import { FieldCell }                                           from "@relcu/rc";
import { useMemo }                                             from "react";
import { useContext }                                          from "react";
import { useState }                                            from "react";
import { useCallback }                                         from "react";
import React                                                   from "react";
import { FC }                                                  from "react";
import { Proposal }                                            from "../../../Proposal";
import { GetTitleFeesBetaVariables }                           from "./__types__/GetTitleFeesBeta";
import { GetTitleFeesBeta }                                    from "./__types__/GetTitleFeesBeta";
import { LoanEstimateOfferClientTitleFeeEditableState }        from "./TitleFeeDialog/__types__/LoanEstimateOfferClientTitleFeeEditableState";
import { LOAN_ESTIMATE_OFFER_CLIENT_TITLE_FEE_EDITABLE_STATE } from "./TitleFeeDialog/TitleFeeDialog";
import { TitleFeeDialog }                                      from "./TitleFeeDialog/TitleFeeDialog";

export const TitleSelectCell: FC = React.memo(function TitleSelectCell() {
  const { getState } = useForm();
  const loanEstimate = useContext(Proposal.Context);
  const [open, setOpen] = useState(false);
  const client = useApolloClient();
  const form = useForm();
  const [getTitleFees, {
    data: { titleFees } = { titleFees: [] }, fetchMore, loading
  }] = useLazyQuery<GetTitleFeesBeta, GetTitleFeesBetaVariables>(GET_TITLE_FEES, {
    returnPartialData: true,
    notifyOnNetworkStatusChange: true
  });
  const { input: { value: selectedTitleFeeId } } = useField("titleCompany", { subscription: { value: true } });
  const { input: { value: selectedTitleFeeName } } = useField("titleCompanyName", { subscription: { value: true } });
  const [selectedId, setSelectedId] = useState<string>(selectedTitleFeeId);

  const titleFeeInput = useCallback(() => {
    const { values } = getState();
    return {
      loanAmount: values.totalLoanAmount,
      cashOutAmount: values.cashOutAmount,
      loanPurpose: loanEstimate.loanPurpose,
      currentMortgageBalance: values.currentMortgageBalance,
      amortizationType: values.amortizationType,
      loanType: values.productType,
      firstTimeHomeBuyer: values.firstTimeHomeBuyer,
      property: {
        type: loanEstimate.propertyType,
        occupancy: loanEstimate.propertyOccupancy,
        propertyAddress: {
          city: loanEstimate.propertyCity,
          county: loanEstimate.propertyCounty.replace(" County", ""),
          state: loanEstimate.propertyState,
          zipCode: loanEstimate.propertyZipCode,
          fipsCode: loanEstimate.propertyFipsCode
        },
        value: values.propertyValue
      },
      offerId:values.id
    };
  }, [loanEstimate, getState]);

  const reFetch = useCallback(async (providerIds) => {
    await fetchMore({
      variables: {
        input: {
          ...titleFeeInput(),
          providerIds: providerIds
        }
      }
    });
  }, [titleFeeInput]);

  const selected = useMemo(() => {
    return titleFees.find(fee => fee.providerId == selectedId);
  }, [selectedId, titleFees]);

  const setChanges = (selected) => {
    form.change("titleCompany", selected.providerId);
    form.change("titleCompanyName", selected.title);
    form.change("ownersTitle", selected.fees.ownersTitle);
    form.change("recordingCharges", selected.fees.recordingCharges);
    form.change("settlementFee", selected.fees.settlementFee);
    form.change("titleInsurance", selected.fees.titleInsurance);
    form.change("transferTax", selected.fees.transferTax);
    if (selected.fees.lendersTitle != null && selected.fees.lendersTitle != undefined) {
      form.change("lendersTitle", selected.fees.lendersTitle);
    }
    queueMicrotask(() => {
      client.writeFragment<LoanEstimateOfferClientTitleFeeEditableState>({
        fragment: LOAN_ESTIMATE_OFFER_CLIENT_TITLE_FEE_EDITABLE_STATE,
        data: {
          __typename: "LoanEstimateOffer",
          isTitleFeeEditable: selected.editable
        },
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: form.getState().values.id })
      });
    });
  };

  const onApply = useCallback(() => {
    setChanges(selected);
    setOpen(false);
  }, [form, selected, setOpen]);

  const onOpen = useCallback(async () => {
    const { data } = await getTitleFees({
      variables: {
        input: {
          ...titleFeeInput()
        }
      }
    });
    const titleFees = data?.titleFees || [];
    if (!selectedId && titleFees.length) {
      const selectedFeeId = titleFees.find(fee => fee.default)?.providerId;
      const selectedFee = titleFees.find(fee => fee.providerId == selectedFeeId);
      setSelectedId(selectedFeeId);
      setChanges(selectedFee);
    } else {
      setOpen(true);
    }
  }, [titleFeeInput, getTitleFees, selectedId]);

  const warning = useMemo(() => {
    return titleFees.some(t => t.errorMessage);
  }, [titleFees]);

  const error = useMemo(() => {
    return titleFees.length && titleFees.every(t => t.errorMessage);
  }, [titleFees]);

  return <>
    <TitleFeeDialog loading={loading} onClose={() => setOpen(false)} titleFees={titleFees} open={open}
                    reFetch={reFetch} onApply={onApply} selectedId={selectedId} setSelectedId={setSelectedId}/>
    <FieldCell.TitleCell
      title={selectedTitleFeeName}
      helperText={error ? "Failed to get title fees." : (warning ? "There are options that failed to load." : "")}
      status={error ? "error" : (warning ? "warning" : "info")} required loading={loading} onOpen={onOpen}
      name={"titleCompany"}/>
  </>;
});

export const TITLE_FEE_FRAGMENT = gql`
  fragment TitleFeeResponse on TitleFeeResponses {
    title
    providerId
    default
    editable
    errorMessage
    fees{
      ownersTitle
      recordingCharges
      settlementFee
      titleInsurance
      transferTax
      lendersTitle
    }
  }
`;
export const GET_TITLE_FEES = gql`
  query GetTitleFeesBeta($input: TitleFeeArgs!){
    titleFees(input: $input) @connection(key: "titleFeesBeta",filter: ["input"]){
      ...TitleFeeResponse
    }
  }
  ${TITLE_FEE_FRAGMENT}
`;
