import { useMutation }                    from "@apollo/client";
import { gql }                            from "@apollo/client";
import { FormApi }                        from "@relcu/form";
import { FORM_ERROR }                     from "@relcu/form";
import { useNavigate }                    from "@relcu/react-router";
import { deepPick }                       from "@relcu/ui";
import { useSource }                      from "@relcu/ui";
import { useCallback }                    from "react";
import React                              from "react";
import { layoutVar }                      from "../../../../reactiveVars";
import { useJqlQuery }                    from "../../Jql";
import { JqlFormProps }                   from "../../Jql/JqlForm";
import { JqlForm }                        from "../../Jql/JqlForm";
import { CreateRelcuAndLosLoanVariables } from "./__types__/CreateRelcuAndLosLoan";
import { CreateRelcuAndLosLoan }          from "./__types__/CreateRelcuAndLosLoan";

export const RelcuLoanForm = React.memo<JqlFormProps>(function UserForm(props) {
  const { $object: { objectId: leadId } } = useSource();
  const layouts = layoutVar();
  const navigate = useNavigate();
  const { data: { transformLoanFromLead: loan } = {} } = useJqlQuery({
    operation: "transformLoanFromLead",
    fields: layouts[ "RelcuLoan" ].jql.query.get.fields,
    variables: { id: leadId }
  }, {
    operationName: "TransformLoanFromLead",
    fetchPolicy: "cache-and-network"
  });
  const [exportToLOS] = useMutation<CreateRelcuAndLosLoan, CreateRelcuAndLosLoanVariables>(EXPORT_LOAN_TO_LOS);
  const onSubmit = useCallback(async ({ fields },formApi) => {
    const registeredFields = formApi.getRegisteredFields();
    fields = deepPick(fields,registeredFields);
    await exportToLOS({ variables: { fields } });
  }, [navigate, exportToLOS, leadId]);
  const afterSubmit = useCallback(() => {
    setTimeout(() => {
      navigate(`/lead/${leadId}/relcuLoan`);
    });

  }, [navigate, leadId]);
  return (
    <>
      <JqlForm
        {...props}
        afterSubmit={afterSubmit}
        onSubmit={onSubmit}
        getErrorMessage={(error, errors) => errors[ FORM_ERROR ]}
        initialValues={loan}
      />
    </>
  );
});
const EXPORT_LOAN_TO_LOS = gql`
  mutation CreateRelcuAndLosLoan($fields:CreateRelcuLoanFieldsInput){
    createRelcuAndLosLoan(fields:$fields ){
      objectId
      lead {
        id
        objectId
        objectName
        objectIcon
        relcuLoan {
          id
          objectId
          objectName
          objectIcon
        }
      }
    }
  }
`;
