import NetworkStatusAction from './NetworkStatusAction';

type Listener = (action: NetworkStatusAction) => void;

export default class Dispatcher {

  static dispatcher = new Dispatcher();

  static addListener(fn: Listener) {
    return this.dispatcher.addListener(fn);
  }

  static removeListener(fn: Listener) {
    return this.dispatcher.removeListener(fn);
  }

  static dispatch(action: NetworkStatusAction) {
    this.dispatcher.dispatch(action);
  }

  listeners: Listener[] = [];

  addListener(fn: Listener) {
    this.listeners.push(fn);
  }

  removeListener(fn: Listener) {
    this.listeners = this.listeners.filter(cur => cur !== fn);
  }

  dispatch(action: NetworkStatusAction) {
    this.listeners.forEach(currentListener => {
      currentListener(action);
    });
  }
}
