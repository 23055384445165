import React                            from "react";
import { FC }                    from "react";
import { useMemo }               from "react";
import { useState }              from "react";
import { gql }                   from "@apollo/client";
import { useQuery }              from "@apollo/client";
import { SearchableMultiSelect } from "@relcu/ui";
import { CommonClasses }         from "@relcu/ui";
import { FontIcon, MenuItem }    from "@relcu/ui";
import { InputState }            from "@relcu/ui";
import { MultiChoiceFieldProps } from "@relcu/ui";
import { useConsumeField }       from "@relcu/ui";
import { GET_TAGS }              from "../../../operations.graphql";



export const TagsEditField: FC = React.memo(function UsersAndTagsEditField(props) {
    let { input, meta: { touched, error, submitError, initial = [] } } = useConsumeField<MultiChoiceFieldProps>();
    const hasError = (touched && !!error) || !!submitError;
    const [q, setQ] = useState("");

    const {
      data: { tags: searchTags = [] } = {}
    } = useQuery(GET_TAGS, {
      fetchPolicy: "network-only",
      variables: {
        limit: 2,
        className: input.targetClass,
        search: q ? `.*${q?.trim()}.*` : ""
      }
    });
    const options = useMemo(() => {
      return [...initial, ...searchTags.filter(t => !initial.find(tag => tag === t))];
    }, [searchTags, initial]);

    return <SearchableMultiSelect
      {...input}
      onType={setQ}
      searchText={q}
      options={options}
      onChange={input.onChange}
      value={input.value}
      renderOption={(option) => {
        return <MenuItem
          container
          thumbnail={<FontIcon type={"local_offer"} className={CommonClasses.GrayIcon}/>}>
          {option}
        </MenuItem>;
      }}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
    />;
  }
);
