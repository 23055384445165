import { SetStateAction }                               from "react";
import { Dispatch }                                     from "react";
import React                                            from "react";
import { FC }                                           from "react";
import { gql }                                          from "@apollo/client";
import { format }                                       from "@relcu/ui";
import { useCallback }                                  from "react";
import { useState }                                     from "react";
import { CircularLoader }                               from "@relcu/ui";
import { Ellipsis, ModalBody }                          from "@relcu/ui";
import { ListItemText }                                 from "@relcu/ui";
import { ButtonVariants }                               from "@relcu/ui";
import { Button }                                       from "@relcu/ui";
import { ListItemTitle }                                from "@relcu/ui";
import { ListRow }                                      from "@relcu/ui";
import { Radio }                                        from "@relcu/ui";
import { Box }                                          from "@relcu/ui";
import { ModalProps }                                   from "@relcu/ui";
import { Modal }                                        from "@relcu/ui";
import { EmptyList }                                    from "@relcu/ui";
import { useLazySummary }                               from "@relcu/ui";
import { TITLE_FEE_SUM_FIELDS }                         from "../../../offer.conditions";
import { GetTitleFeesBeta }                             from "../__types__/GetTitleFeesBeta";
import { TitleFeeDialogClasses }                        from "./TitleFeeDialogClasses";
import "./title-fee-dialog.css";

export interface TitleFeeDialogProps extends Partial<ModalProps> {
  reFetch: (providerIds: string[]) => void;
  titleFees: GetTitleFeesBeta["titleFees"];
  loading: boolean;
  onApply: () => void;
  selectedId: string;
  setSelectedId: Dispatch<SetStateAction<string>>
}

export const TitleFeeDialog: FC<TitleFeeDialogProps> = React.memo(function TitleFeeDialog(props) {
  const { onClose, reFetch, titleFees, loading, onApply, selectedId, setSelectedId } = props;
  const [reFetchingId, setReFetchingId] = useState<string>();
  const calculateTitleFees = useLazySummary({ fields: TITLE_FEE_SUM_FIELDS });
  const reload = useCallback((providerId) => {
    setReFetchingId(providerId);
    reFetch([providerId]);
  }, [reFetch, setReFetchingId]);
  return <Modal
    title={"Title fees"}
    onClose={props.onClose}
    open={props.open}
    closeIcon={true}
    disableBackdropClick={false}
    className={TitleFeeDialogClasses.Modal}
    gap={"S"}
  >
    <ModalBody container direction={"column"} gap={"S"} className={TitleFeeDialogClasses.Container} flex={1}>
      <h3 className={TitleFeeDialogClasses.ContainerTitle}> Select a title company</h3>
      {
        !!titleFees?.length ?
          <>
            <Box container direction={"column"} gap={"XXS"} flex={1}>
              {titleFees.map((fee, k) => {
                return <Radio
                  disabled={!!fee.errorMessage}
                  checked={fee?.providerId == selectedId}
                  onChange={() => {
                    setSelectedId(fee.providerId);
                  }}
                  name="titleCompany"
                  key={`tab-panel-item-${k}`}
                  label={<ListRow className={TitleFeeDialogClasses.TitleFee} flex={1} gap={"XS"}>
                    <ListItemTitle title={fee.title} flexBasis={"20%"} flexShrink={2} flex={"1 2 20%"}/>
                    {loading && reFetchingId == fee.providerId ?
                      <Box container gap={"XXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                        <CircularLoader/>
                        <ListItemText text={"Loading..."}/>
                      </Box> :
                      fee.errorMessage ?
                        <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                          <ListItemText label={fee.errorMessage}
                                        text={<Ellipsis
                                          style={{ pointerEvents: "auto", color: "var(--color-error)" }}
                                          from={90}>{fee.errorMessage}</Ellipsis>} tiny/>
                          <Button style={{ pointerEvents: loading ? "none" : "auto" }}
                                  disabled={loading}
                                  variant={ButtonVariants.Ghost}
                                  onClick={() => {
                                    reload(fee.providerId);
                                  }}>
                            Reload
                          </Button>
                        </Box>
                        :
                        <Box container gap={"XXXS"} flex={"1 0 15%"} justify={"end"} alignItems={"center"}>
                          <ListItemText text={"Rate:"} tiny/>
                          <ListItemText text={`$${format(calculateTitleFees(fee.fees), 2)}`}/>
                        </Box>
                    }
                  </ListRow>}/>;
              })
              }
            </Box>
            <Box container justify={"end"} gap={"XXS"} className={TitleFeeDialogClasses.Footer}>
              <Button variant={ButtonVariants.Outline} onClick={() => onClose()}>CANCEL</Button>
              <Button variant={ButtonVariants.Fill} onClick={onApply}>APPLY</Button>
            </Box>
          </>
          :
          <Box flex={"1 1 auto"} justify={"center"}>
            <EmptyList icon={"pie_chart"} content={"Please update lead data and try again."}
                       title={"No matching rates"}
                       alignSelf={"center"}/>
          </Box>
      }
    </ModalBody>
  </Modal>;
});

export const LOAN_ESTIMATE_OFFER_CLIENT_TITLE_FEE_EDITABLE_STATE = gql`
  fragment LoanEstimateOfferClientTitleFeeEditableState on LoanEstimateOffer {
    isTitleFeeEditable @client
  }
`;
