import React                 from "react";
import { FC }                from "react";
import { Box }               from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import "./message-body.css";
import { MailMessage }   from "../../../../../../graph/__types__/MailMessage";
import { Email }         from "../../../../../ContentVisualizer";
import { MsgAttachment } from "../../../../../Message/Msg/MsgAttachments/MsgAttachment";

export interface MessageBodyProps extends BoxComponentProps {
  message: MailMessage;
}

export const MessageBody: FC<MessageBodyProps> = React.memo(function MessageBody(props) {
  const { message } = props;

  return <Box container direction={"column"} gap={"S"}
              className={MessageBodyClasses.MessageBody}>
    <Email template={message.html || message.snippet}/>
    <Box container gap={"XXS"} wrap={"wrap"}>
      {
        message.attachments.map((attachment, index) => (
          <MsgAttachment
            key={index}
            incoming
            attachment={attachment.file}/>
        ))
      }
    </Box>
  </Box>;
});

export const enum MessageBodyClasses {
  MessageBody = "message-body"
}
