import { useCallback }                         from "react";
import { useState }                            from "react";
import { useSource }                           from "@relcu/ui";
import { modal }                               from "@relcu/ui";
import { useModal }                            from "@relcu/ui";
import { useAlert }                            from "@relcu/ui";
import { useMutation }                         from "@apollo/client";
import { gql }                                 from "@apollo/client";
import { downloadFile }                        from "../../../../utils/helpers";
import { fileToBase64 }                        from "../../../../utils/helpers";
import { PdfModal }                            from "../../../Modal/ProposalPdfModal";
import { PreviewDummyLoanProposalVariables }   from "./__types__/PreviewDummyLoanProposal";
import { PreviewDummyLoanProposal }            from "./__types__/PreviewDummyLoanProposal";
import { UploadLoanProposalTemplateVariables } from "./__types__/UploadLoanProposalTemplate";
import { UploadLoanProposalTemplate }          from "./__types__/UploadLoanProposalTemplate";

export function useLoanProposalTemplateView() {
  const { $object } = useSource();
  const [openPdfModal, modalContext] = useModal(PdfModal);
  const { pricing: { loanProposal = {} } = {} } = $object;
  const [edit, setEdit] = useState(!loanProposal?.templateId);
  const [file, setFile] = useState(loanProposal?.template ? {
    name: loanProposal.template.name,
    type: loanProposal.template.mimeType,
    url: loanProposal.template.url
  } as File & { url: string } : null);
  const [loading, setLoading] = useState(false);
  const [preview] = useMutation<PreviewDummyLoanProposal, PreviewDummyLoanProposalVariables>(PREVIEW_DUMMY_LOAN_PROPOSAL);
  const [upload] = useMutation<UploadLoanProposalTemplate, UploadLoanProposalTemplateVariables>(UPLOAD_LOAN_PROPOSAL_TEMPLATE);

  const { error } = useAlert();
  const handleTemplateCreate = useCallback((event) => {
    if (event.target.files[ 0 ].type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      error("Invalid format.");
    }
    setEdit(true);
    setLoading(true);
    setFile(event.target.files[ 0 ]);
    setLoading(false);
    event.target.value = "";
  }, []);

  async function onPreview(count: number, isPurchase: boolean) {
    const variables: PreviewDummyLoanProposalVariables = {
      columns: count,
      isPurchase
    };
    if (file instanceof File) {
      const content = await getEncodedFile(file);
      variables.blob = {
        type: file.type,
        name: file.name,
        content
      };
    }
    if (loanProposal?.templateId) {
      variables.templateId = loanProposal.templateId;
    }
    const { data: { previewDummyLoanProposal: { renderId } } } = await preview({
      variables
    });
    render(renderId);
  }

  function render(renderId) {
    const url = `${window.location.origin}/api/v1/pdf/${renderId}`;
    const actions = [];
    if (!loanProposal?.templateId) {
      actions.push({
        children: "SAVE",
        onClick: async () => {
          await handleSave();
          destroy();
        }
      });
    }

    const { destroy } = openPdfModal({
      url,
      actions
    });
  }

  const onSave = useCallback(async () => {
    const reader = new FileReader();
    reader.onload = async function (e) {
      try {
        const encoded = fileToBase64(reader);
        const res = await upload({
          variables: {
            blob: {
              type: file.type,
              name: file.name,
              content: encoded
            }
          }
        });
        file.url = res.data.uploadLoanProposalTemplate.pricing.loanProposal.template.url;
        setFile(file);
      } catch (e) {
        console.error(e);
        error("File not uploaded.");
      }
    };
    reader.readAsDataURL(file);
  }, [file]);

  function handleEdit(e) {
    if ((e.target == e.currentTarget) && !loanProposal?.templateId) {
      setEdit(true);
    }
  }

  const handleClear = useCallback(() => {
    setFile(loanProposal?.template ? {
      name: loanProposal.template.name,
      type: loanProposal.template.mimeType,
      url: loanProposal.template.url
    } as File & { url: string } : null);
    setEdit(false);
  }, [loanProposal?.template]);

  async function handleSave() {
    setEdit(false);
    await onSave();
  }
  async function handleDownload(e) {
    try {
      e.preventDefault();
      e.stopPropagation();
      await downloadFile(file);
    } catch (e) {
      error("Failed to Download Template");
    }
  }

  return {
    get isEmpty() {
      return !file && !loanProposal?.templateId;
    },
    modalContext,
    file,
    edit,
    onPreview,
    handleDownload,
    handleSave,
    loading,
    setEdit,
    loanProposal,
    handleEdit,
    handleClear,
    handleTemplateCreate
  };
}

export function getEncodedFile(file: File) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve => {
    reader.onload = function (e) {
      const encoded = fileToBase64(reader);
      resolve(encoded);
    };
  }));
}

export const PREVIEW_DUMMY_LOAN_PROPOSAL = gql`
  mutation PreviewDummyLoanProposal ($blob:Blob, $templateId: String, $columns: Int, $isPurchase: Boolean) {
    previewDummyLoanProposal(blob: $blob, templateId: $templateId, columns: $columns, isPurchase: $isPurchase) {
      renderId
    }
  }
`;

export const UPLOAD_LOAN_PROPOSAL_TEMPLATE = gql`
  mutation UploadLoanProposalTemplate ($blob: Blob!) {
    uploadLoanProposalTemplate(blob: $blob) {
      pricing {
        loanProposal{
          template{
            id
            objectId
            mimeType
            name
            url
          }
        }
      }
    }
  }
`;
