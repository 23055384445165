import { useEffect }            from "react";
import { useState }             from "react";
import { useCallback }          from "react";
import { useMemo }              from "react";
import { useSubscription }      from "@apollo/client";
import { gql }                  from "@apollo/client";
import { IQueryBuilderOptions } from "@relcu/gql-query-builder";
import { useThrottleCallback }  from "@relcu/ui";
import { useLatest }            from "@relcu/ui";
import { omit }                 from "@relcu/ui";
import { useLazyCondition }     from "@relcu/ui";
import { IColumn }              from "@relcu/ui";
import { JsonFieldProps }       from "@relcu/ui";
import { useSource }            from "@relcu/ui";
import { PriorityQueue }        from "../../../../../graph/__types__/PriorityQueue";
import { visibleQueuesVar }     from "../../../../../reactiveVars";
import { getJql }               from "../../../../../utils/Jql";
import { getValidTimezones }    from "../../../../../utils/timezone";
import { useReactiveStorage }   from "../../../../useReactiveStorage";
import { useJqlQuery }          from "../../../Jql";
import { usePriorityFilter }    from "../PriorityFilter/usePriorityFilter";
import { PriorityQueueProps }   from "./PriorityQueue";

// const PRIORITY_LEADS_SUBSCRIPTION = gql`
//   subscription PriorityLeadSubscription($queue:ID!){
//     leads(where: {priority: {queue: {have: {link:$queue }}}},events: [UPDATE]){
//       event
//       node {
//         assignedTo {
//           id
//           objectId
//         }
//       }
//     }
//   }
// `;
const PRIORITY_PROCESS_SUBSCRIPTION = gql`
  subscription PriorityProcessSubscription($queue:String!,$users:[String]){
    priorityQueueProcessed(queue: $queue,users: $users)
  }
`;

export interface UsePriorityQueue {
  queue: PriorityQueue;
  filter: ReturnType<typeof usePriorityFilter>;
  jql: IQueryBuilderOptions;
  fields: JsonFieldProps[];
  onUpdate?: () => void;
}

export function usePriorityQueue(props: PriorityQueueProps) {
  const count = props.count;
  const evaluate = useLazyCondition();
  const leadCountInQueue = useMemo(() => props.queue.leadCount, [props.queue]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [collapsed, setCollapsed] = useReactiveStorage(`@priority-collapsed`, {});
  const { $settings } = useSource();
  const fields = useMemo(() => {
    return props.fields.map((f) => {
      return {
        name: f.name,
        field: f.field,
        fields: f.fields
      };
    });
  }, [props.fields]);
  const actions = useMemo(() => {
    return props.actions?.map(a => {
      return {
        type: a.type,
        field: a.field
      };
    });
  }, [props.actions]);
  const order = useMemo(() => {
    if (Array.isArray(props.queue.leadSort)) {
      return props.queue.leadSort.map(s => {
        if (s.match(/^[-]/)) {
          return `${s.replace(/^[-]/, "")}_DESC`;
        } else {
          return `${s}_ASC`;
        }
      });
    }
  }, [props.queue]);
  const timezones = useMemo(() => getValidTimezones($settings.callableStartHour, $settings.callableEndHour), [$settings]);
  const variables = useMemo(() => {
    return {
      order: [...order, "objectId_ASC"],
      where: {
        AND: [
          {
            assignedTo: {
              have: {
                objectId: {
                  in: props.filter.value
                }
              }
            }
          },
          {
            priority: {
              queue: {
                have: {
                  objectId: { in: props.queue.objectId }
                }
              }
            }
          },
          {
            OR: [
              {
                timezone: { equalTo: null }
              },
              {
                timezone: { in: timezones }
              }
            ]
          }
        ]
      },
      first: leadCountInQueue
    };
  }, [props.queue, props.filter, timezones, leadCountInQueue]);
  const onUpdateRef = useLatest(props.onUpdate);
  const { operation, variables: queryVariables, fields: queryFields } = useMemo(() => getJql(fields, actions, props.queue.fields, "Lead")
  .build("find"), [fields, actions, props.queue.fields]);
  const { data: { leads: { pageInfo = {}, edges = [] } = {} } = {}, loading, refetch, fetchMore } = useJqlQuery({
    operation: operation,
    fields: queryFields,
    variables: omit(queryVariables, ["__typename"])
  }, {
    operationName: "GetPriorityLeads",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    skip: operation == undefined || fields == undefined || variables == undefined,
    variables: variables
  });
  const refresh = useThrottleCallback(() => {
    onUpdateRef.current?.();
    refetch();
  }, 1000);
  // useSubscription(PRIORITY_LEADS_SUBSCRIPTION, {
  //   variables: {
  //     queue: props.queue.id
  //   }
  // });
  useSubscription(PRIORITY_PROCESS_SUBSCRIPTION, {
    variables: {
      queue: props.queue.objectId,
      users: props.filter.value
    },
    onData() {
      refresh();
    }
  });
  useEffect(() => {
    if (selectedRows.length) {
      setSelectedRows(selectedRows.filter(item => edges.some(({ node }) => node.id === item)));
    }
  }, [edges]);

  useEffect(() => {
    const visibleQueues = visibleQueuesVar();
    const included = visibleQueues.includes(props.queue.id);
    if (count && !included) {
      visibleQueuesVar([...visibleQueues, props.queue.id]);
    } else if (!count && included) {
      visibleQueuesVar(visibleQueues.filter(q => q !== props.queue.id));
    }
  }, [count, props.queue.id]);

  const onLoadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...variables,
        first: leadCountInQueue,
        after: pageInfo.endCursor
      }
    });
  }, [leadCountInQueue, edges]);

  const onLoadLess = useCallback(() => {
    refetch(variables);
    setSelectedRows([]);
  }, [leadCountInQueue]);

  const availableColumns = useMemo(() => {
    return props.fields.filter((field) => {
      if (field.conditions) {
        const { apply } = evaluate({ conditions: field.conditions });
        return apply;
      }
      return true;
    }).map(({ label, name, ...other }, index) => ({
      title: label,
      key: name,
      resizable: !other.flexGrow,
      ...other
    } as IColumn));
  }, [props.fields, evaluate]);

  const columns = useMemo(() => {
    return props.queue.fields.map((sColumn) => {
      const aColumn = availableColumns.find(a => a.key == sColumn.key);
      return {
        ...aColumn, fixed: sColumn.fixed, visible: true
      } as IColumn;
    });
  }, [props.queue.fields, availableColumns]);
  const data = useMemo(() => {
    return edges.map(e => e.node);
  }, [edges]);

  const handleRowSelect = async (ids) => {
    setSelectedRows(ids);
  };

  const toggle = () => {
    setCollapsed({
      ...collapsed,
      [ props.queue.id ]: !collapsed[ props.queue.id ]
    });
    setSelectedRows([]);
  };

  return {
    count,
    leadCount: data.length,
    pageInfo,
    onLoadMore,
    onLoadLess,
    get isOpen() {
      return !collapsed[ props.queue.id ];
    },
    handleRowSelect,
    selectedRows,
    variables,
    leadCountInQueue,
    columns,
    data,
    toggle,
    refetch,
    availableColumns,
    loading
  };
}
