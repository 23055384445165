import React                     from "react";
import { useState }              from "react";
import { useRef }                from "react";
import { AvatarSizes }           from "../../Avatar";
import { Avatar }                from "../../Avatar";
import { Box }                   from "../../Box";
import { applyBoxItemStyles }    from "../../Box";
import { InputState }            from "../../Input/Input";
import { SearchableSelect }      from "../../Input/SearchableSelect";
import { Link }                  from "../../Link";
import { ListItemText }          from "../../List";
import { MenuItem }              from "../../Menu";
import { useConsumeField }       from "../BaseField";
import { PointerDetailsClasses } from "./PointerDetailsClasses";
import { PointerFieldProps }     from "./PointerField";

export const PointerEditField = React.memo(function PointerEditField(props) {
  const selectRef: any = useRef();
  const { input, meta: { touched, error, submitError } } = useConsumeField<PointerFieldProps>();
  const { style } = applyBoxItemStyles(input);
  const [selectedFilter, setSelectedFilter] = useState(false);
  const hasError = (touched && !!error) || !!submitError;

  function handleModeChange(selectedMod) {
    setSelectedFilter(selectedMod);
    input?.onSelectedFilter?.(selectedMod ? input.value : null);
  }

  return (
    <SearchableSelect
      renderSelect={(selected) => (
        <Box container className={PointerDetailsClasses.PointerSelect} gap={"XXS"} alignItems={"center"}>
          {
            input.thumbnail !== false &&
            <Avatar icon={selected.objectIcon} size={AvatarSizes.Small} text={selected.objectName}/>
          }
          {
            !input.readOnly
              ?
              <ListItemText text={selected.objectName}/>
              :
              <Link
                to={`/${selected.__typename.toLowerCase()}/${selected.objectId}`}>
                {selected.objectName}
              </Link>
          }
        </Box>
      )}
      renderOption={(option) => (
        <MenuItem container thumbnail={input.thumbnail ? <Avatar icon={option.objectIcon} size={AvatarSizes.Small}
                                                                 text={option.objectName}/> : null}>
          {option.objectName}
        </MenuItem>
      )}
      // menuProps={{
      //   width: 350
      // }}
      {...input}
      ref={selectRef}
      options={input.options}
      value={input.value}
      selectedFilter={selectedFilter}
      onSelectedFilter={handleModeChange}
      optionKey={"objectId"}
      onCreateNew={input?.onCreateNew}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
      style={style}
    />
  );
});
export default PointerEditField;
