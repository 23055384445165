import React                 from "react";
import { FC }                from "react";
import { BoxComponentProps } from "../../../../Box";
import { Box }               from "../../../../Box";
import { ChipsSizes }        from "../../../../Chips";
import { Chips }             from "../../../../Chips";
import { FontIcon }          from "../../../../Icon";
import { TypographyColor }   from "../../../../Typography";
import { TypographyWeight }  from "../../../../Typography";
import { Typography }        from "../../../../Typography";

export interface EmailInfoPrimaryProps extends BoxComponentProps {
  items?: any[];
}

export const EmailInfoPrimary: FC<EmailInfoPrimaryProps> = React.memo(function EmailInfoPrimary(props) {
  const { children, items } = props;
  return <Box container gap={"XXXS"} style={{overflow: "hidden"}}>
    <Typography weight={TypographyWeight.Medium} color={TypographyColor.ExtraDark} noWrap={true}>
      {children}
    </Typography>
    {
      !!items?.length &&
      <Box flex={1} container gap={"XXXS"} wrap={"wrap"}>
        {
          items.map(({ name, icon, color }, index) => {
            return (
              <Chips
                key={index}
                thumbnail={<FontIcon type={icon}/>}
                color={color}
                label={name}
                length={15}
                size={ChipsSizes.Small}
              />
            );
          })
        }
      </Box>
    }
  </Box>;
});