import { useEffect }         from "react";
import { useRef }            from "react";
import React                 from "react";
import { FC }                from "react";
import { useState }          from "react";
import { useMemo }           from "react";
import { UseFieldConfig }    from "@relcu/final-form";
import { Fields }            from "@relcu/gql-query-builder";
import { CheckPicker }       from "@relcu/rc";
import { SelectPickerProps } from "@relcu/rc";
import { JsonPermissions }   from "@relcu/ui";
import { getSelectionSet }   from "../../../../utils/graphQlUtils";
import { toFirstLower }      from "../../../../utils/helpers";
import { pluralize }         from "../../../../utils/pluralize";
import { useJqlQuery }       from "../../../Layout/Jql";
import { usePointerFilters } from "../PointerField";

export interface BaseFieldProps extends Omit<UseFieldConfig<any>, "value"> {
  targetClass?: string | string[];
  targetField?: string;
  permissions?: JsonPermissions;
  readPlaceholder?: string;
}

export interface PointerPickerValue {
  objectId: string;
  objectName?: string;
  objectIcon?: string;
  __typename: string;
  [ k: string ]: any;
}

export interface PointerPickerProps extends BaseFieldProps, Omit<SelectPickerProps, "data"> {
  fields?: Fields;
  data?: PointerPickerValue[];
  filters?: object;
}

export const PointerListPicker: FC<PointerPickerProps> = React.memo(function PointerPicker({ fields, targetClass, appearance, ...props }) {
  const selectionSet = useMemo(() => getSelectionSet(fields), [fields]);
  const filters = usePointerFilters(props.filters);
  const [q, setQ] = useState("");
  const targetClasses = useMemo(() => Array.isArray(targetClass) ? targetClass : [targetClass], [targetClass]);
  const selectedItemsRef = useRef(props.value.map(v => v[ props.valueKey ]));

  useEffect(() => {
    selectedItemsRef.current = props.value?.map(v => v[ props.valueKey ]);
  }, [props.value]);

  const query = useMemo(() => {
    let query: any;
    let value = {
      OR: [
        {
          objectName: {
            matchesRegex: q ? `^${(q || "").trim()}` : "",
            options: "i"
          }
        }
      ],
      ...filters
    };
    if (selectedItemsRef.current) {
      value.OR.push({
        objectId: {
          in: selectedItemsRef.current
        }
      });
    }

    if (targetClasses.length > 1) {
      query = targetClasses.map(n => {
        const operation = pluralize(toFirstLower(n));
        return {
          operation,
          variables: {
            after: "",
            first: ((selectedItemsRef.current?.length ?? 0) / targetClasses.length) + 5,
            [ `${operation}Where` ]: {
              type: `${n}WhereInput`,
              name: "where",
              value
            }
          },
          fields: [
            {
              pageInfo: [
                "endCursor",
                "startCursor",
                "hasNextPage",
                "hasPreviousPage"
              ]
            },
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        };
      });
    } else {
      query = {
        operation: pluralize(toFirstLower(targetClasses[ 0 ])),
        variables: {
          after: "",
          first: (selectedItemsRef.current?.length ?? 0) + 10,
          where: {
            type: `${targetClasses[ 0 ]}WhereInput`,
            value
          }
        },
        fields: [
          {
            pageInfo: [
              "endCursor",
              "startCursor",
              "hasNextPage",
              "hasPreviousPage"
            ]
          },
          {
            edges: [
              {
                node: selectionSet
              }
            ]
          }
        ]
      };
    }
    return query;
  }, [q, targetClasses]);

  const { data = {}, loading, fetchMore } = useJqlQuery(query, {
    // skip: props.view === "read"
  });

  const options = useMemo(() => {
      if (targetClasses.length > 1) {
        const items = [];
        Object.keys(data).forEach(t => {
          const label = t == "leadSources" ? "CAMPAIGN" : t.toUpperCase();
          const options = data[ t ].edges.map(({ node }) => node);
          if (options.length) {
            items.push({
              label,
              options: data[ t ].edges.map(({ node }) => node)
            });
          }
        });
        return items;
      }
      const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
      if (data[ operation ]) {
        return (
          Object(data[ operation ])[ "edges" ] || []
        ).map(({ node }) => node);
      }
      return [];
    },
    [data, targetClasses]
  );
  const pageInfo = useMemo(() => {
    const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
    return Object(data[ operation ])[ "pageInfo" ];
  }, [data, targetClasses]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        where: query.variables.where.value,
        first: query.variables.first,
        after: pageInfo.endCursor
      },
      updateQuery(prev, { fetchMoreResult }) {
        const className = pluralize(toFirstLower(targetClasses[ 0 ]));
        return {
          [ className ]: {
            ...prev[ className ],
            pageInfo: fetchMoreResult[ className ].pageInfo,
            edges: [...prev[ className ].edges, ...fetchMoreResult[ className ].edges]
          }
        };
      }
    });
  };
  //todo in parallel with gql query selectPicker filtering it's own list but I think loading flag should fix visual bug

  const handleChange = (value, event) => {
    const objects = options.filter(o => value.includes(o[ props.valueKey ]));
    props?.onChange(objects, event);
  };
  return <CheckPicker
    loading={loading}
    onSearch={(searchKeyword) => setQ(searchKeyword)}
    data={options}
    cleanable={false}
    appearance={appearance as "default" | "subtle"/*todo workaround*/}
    {...props}
    value={props.value.map(v => v[ props.valueKey ])}
    onChange={handleChange}
  />;
});
