import { FC }            from "react";
import React             from "react";
import { Spinner }       from "../Spinner";
import { UseAsyncState } from "./useAsync";
import { useAsync }      from "./useAsync";

export const Async: FC<AsyncProps> = React.memo(function Async(props) {
  const { children, asyncFunction, params } = props;
  const state = useAsync(asyncFunction, params);
  return <>{children(state)}</>;
});
export const Await: FC<Omit<AsyncProps, "children">> = React.memo(function Await({ children, ...props }) {
  return (
    <Async {...props}>
      {({ loading,error }) => (loading || !!error) ? <Spinner/> : <>{children}</>}
    </Async>
  );
});
Async.defaultProps = {
  params: []
};
export interface AsyncProps {
  asyncFunction: () => Promise<any>,
  params?: any[]
  children: (state: UseAsyncState<any>) => React.ReactNode
}
