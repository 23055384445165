import React                    from "react";
import { FC }                   from "react";
import { transformNameToLabel } from "@relcu/ui";
import { Sidebar }              from "@relcu/ui";
import { JsonViewProps }        from "@relcu/ui";
import { TableProvider }        from "../../../Table";
import { TableContainer }       from "../../../Table";
import { TableSubheader }       from "../../../Table";
import { TableFooter }          from "../../../Table";
import { Table }                from "../../../Table";
import { TableHeader }          from "../../../Table";
import { useTableView }         from "./useTableView";

export const TableView: FC<JsonViewProps> = React.memo(function TableView(props) {
    const tableView = useTableView(props);
    return (
      <>
        {tableView.pageViewModalContext}
        <TableProvider
          queryFind={props.jql.query.find}
          variables={tableView.queryVariables}
          data={tableView.data}
          total={tableView.total}
          selectedRows={tableView.selectedRows}
          onRowSelect={tableView.setSelectedRows}
          loading={tableView.loading}
          actions={props.actions}
          classId={props.classId}
          editable={props.editable}
        >
          {
            tableView.viewOpen &&
            <Sidebar
              items={tableView.views}
              defaultItem={tableView.defaultView}
              title={transformNameToLabel(props.classId)}
              onClose={() => tableView.setViewOpen(false)}
            />
          }
          <TableContainer>
            <TableHeader
              expanded={tableView.viewOpen}
              onExpand={tableView.setViewOpen}
              title={tableView.selectedView?.name ?? `All ${transformNameToLabel(props.classId)}s`}
              draft={tableView.draft}
              onSelect={tableView.handleViewCLick}
              selectedView={tableView.selectedView}
            />
            <TableSubheader
              title={props.classId}
              name={tableView.selectedView?.name ?? `All ${transformNameToLabel(props.classId)}`}
            />
            <Table
              onSort={tableView.onSort}
              order={tableView.order}
              clickableRow={props.classId !== "RelcuLoan"}
              columns={tableView.columns}
              availableColumns={tableView.availableColumns}
              onColumnsChange={tableView.onColumnsChange}
              ref={tableView.tableRef}
              draggable={true}
            />
            {tableView.pageCount > 1 &&
              <TableFooter
                defaultPage={tableView.currentPage}
                count={tableView.pageCount}
                onChange={tableView.onPage}
              />
            }
          </TableContainer>
        </TableProvider>
      </>
    );
  }
);

