import React                  from "react";
import { FC }                 from "react";
import { cloneElement }       from "react";
import { Children }           from "react";
import { MutableRefObject }   from "react";
import { createRef }          from "react";
import { useEffect }          from "react";
import { FunctionComponent }  from "react";
import { useCallback }        from "react";
import { useState }           from "react";
import { useRef }             from "react";
import { useLayoutEffect }    from "react";
import { useImperativeState } from "../../hooks/useImperativeState";
import { CommonClasses }      from "../../theme/classes";
import { classNames }         from "../../utils/classNames";
import { simpleDebounce }     from "../../utils/helpers";
import { BoxComponentProps }  from "../Box";
import { Box }                from "../Box";
import { FontIcon }           from "../Icon";
import { SliderClasses }      from "./SliderClasses";
import { AnimatedCircle }     from "../AnimatedCircle";
import { Badge }              from "../Badge";
import { BadgeAlignment }     from "../Badge";
import { BadgeVariants }      from "../Badge";

type SliderProps = {
  moveTo?: number //if onChange and current set form parent do not use this use onChange(work only for uncontrolled slider)
  disableMoveOnHover?: boolean//do not work if onChange and current set form parent(work only for uncontrolled slider)
  current?: number
  onChange?(current)
  badgeR?: boolean
  badgeL?: boolean
  animationProgress?: boolean
  setAnimationProgress?: any
}

interface SliderComponent extends FunctionComponent<SliderProps & BoxComponentProps> {
  Item?: FC<BoxComponentProps>;
}

export const Slider: SliderComponent = React.memo(function Slider(props) {
  const { children, moveTo = 0, className, disableMoveOnHover = true, current, onChange, badgeR, badgeL, animationProgress, setAnimationProgress, ...p } = props;
  const classes = classNames(SliderClasses.Slider, className);
  const containerRef = useRef<any>(null);
  const [showLeftBtn, setShowLeftBtn] = useState(true);
  const [showRightBtn, setShowRightBtn] = useState(true);
  const [activeItem, setActiveItem] = useImperativeState(current, onChange);
  const [elRefs, setElRefs] = useState([]);
  const mouseOverRef = useRef(disableMoveOnHover);

  useLayoutEffect(() => {
    const listener = (entries) => {
      entries.forEach(
        ({ isIntersecting, boundingClientRect, target, rootBounds, intersectionRect, intersectionRatio }) => {
          if (!isIntersecting) {
            if ((boundingClientRect.left < (rootBounds.left + 50)) || (boundingClientRect.right > (rootBounds.right - 50))) {
              if (!target.classList.contains(SliderClasses.SliderItemOffScreen)) {
                target.classList.add(SliderClasses.SliderItemOffScreen);
              }

              if (!elRefs.find(el => el?.reference.current == target.previousSibling)) {
                setShowLeftBtn(true);
              }

              if (!elRefs.find(el => el?.reference.current == target.nextSibling)) {
                setShowRightBtn(true);
              }
            }
          } else {
            if (target.classList.contains(SliderClasses.SliderItemOffScreen)) {
              target.classList.remove(SliderClasses.SliderItemOffScreen);
            }

            if (!elRefs.find(el => el?.reference.current == target.previousSibling)) {
              setShowLeftBtn(false);
            }
            if (!elRefs.find(el => el?.reference.current == target.nextSibling)) {
              setShowRightBtn(false);
            }
          }
        }
      );
    };

    const observer = new IntersectionObserver(listener, {
      root: containerRef.current,
      rootMargin: "0px",
      threshold: 0
    });

    for (let i = 0; i < elRefs.length; i++) {
      if (elRefs[ i ]?.reference?.current) {
        observer.observe(elRefs[ i ]?.reference.current);
      }
    }
    return () => {
      for (let i = 0; i < elRefs.length; i++) {
        if (elRefs[ i ]?.reference?.current) {
          observer.unobserve(elRefs[ i ].reference.current);
        }
      }
      observer.disconnect();
    };
  }, [children, elRefs]);

  useEffect(() => {
    setElRefs((elRefs) => {
      const length = React.Children.count(children);
      return Array(length).fill(null).map((_, i) => elRefs[ i ] || { reference: createRef() });
    });
  }, [children]);

  useEffect(() => {
    if (activeItem < 0) {
      setActiveItem(0);
    } else if ((activeItem > (elRefs.length - 1)) && elRefs.length > 0) {
      setActiveItem(elRefs.length - 1);
    }

    if (elRefs[ activeItem ]?.reference?.current) {
      containerRef.current.scrollTo({ left: elRefs[ activeItem ].reference.current.offsetLeft, behavior: "smooth" });
      // elRefs[ activeItem ].reference.current.scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" });
    }
  }, [activeItem, elRefs]);

  useEffect(() => {
    if (!mouseOverRef.current) {
      setActiveItem(moveTo);
    }
  }, [moveTo]);

  const handleMoveTo = useCallback((itemIndex: number) => {
    setActiveItem(itemIndex);
  }, [activeItem, elRefs]);

  const handleMouseOver = () => {
    (simpleDebounce(() => mouseOverRef.current = true, 500))();
  };
  const handleMouseOut = () => {
    (simpleDebounce(() => mouseOverRef.current = false, 500))();
  };

  return <Box
    container
    gap={"XXS"}
    flexShrink={0}
    className={classes}
    onMouseOver={disableMoveOnHover ? handleMouseOver : null}
    onMouseOut={disableMoveOnHover ? handleMouseOut : null}
    {...p}>
    {showLeftBtn &&
      <SliderButton
        type={SliderButtonType.Left}
        badge={badgeL}
        onClick={() => handleMoveTo(activeItem - 1)}
      />}
    {showRightBtn &&
      <SliderButton
        type={SliderButtonType.Right}
        badge={badgeR}
        animationProgress={animationProgress}
        setAnimationProgress={setAnimationProgress}
        onClick={() => handleMoveTo(activeItem + 1)}
      />}
    <Box container gap={"XXS"} flexShrink={0} className={SliderClasses.SliderContainer} ref={containerRef}>
      <Box className={classNames(SliderClasses.SliderIndicator, SliderClasses.SliderIndicatorL)}/>
      {
        Children.map(children, (child: any, index) => cloneElement(child, { ref: elRefs[ index ]?.reference }))
      }
      <Box className={classNames(SliderClasses.SliderIndicator, SliderClasses.SliderIndicatorR)}/>
    </Box>
  </Box>;
});

Slider.Item = React.forwardRef(function SliderItem(props, ref: MutableRefObject<any>) {
  const { children, className, ...p } = props;
  const classes = classNames(SliderClasses.SliderItem, className);

  return <Box className={classes} {...p} ref={ref}>
    {children}
  </Box>;
});


enum SliderButtonType {
  Left = "left",
  Right = "right"
}

type SliderButtonProps = {
  type: SliderButtonType
  animationProgress?: boolean,
  setAnimationProgress?: any
  badge?: boolean
}

interface SliderButtonComponent extends FunctionComponent<SliderButtonProps & BoxComponentProps>{}

const SliderButton: SliderButtonComponent = React.memo(function SliderRightButton(props) {
  const { children, badge = false, onClick, className, type,  animationProgress, setAnimationProgress, ...p } = props;

  const classes = classNames(SliderClasses.SliderBtn, {
    [SliderClasses.SliderBtnL]: (type == SliderButtonType.Left),
    [SliderClasses.SliderBtnR]: (type == SliderButtonType.Right)
  }, className);

  return (
    <Box className={classes}>
      {badge ?
        <>
          {animationProgress ?
            <AnimatedCircle onClose={()=>{setAnimationProgress(false)}} size={26}>
              <FontIcon
                className={classNames(SliderClasses.SliderBtnIcon, CommonClasses.ClickableIcon)}
                type={`keyboard_arrow_${type}`} onClick={onClick} />
            </AnimatedCircle> :
            <Badge variant={BadgeVariants.Dot} alignment={BadgeAlignment.TopRight}>
              <FontIcon
                className={classNames(SliderClasses.SliderBtnIcon, CommonClasses.ClickableIcon)}
                type={`keyboard_arrow_${type}`} onClick={onClick} />
            </Badge>}
        </> :
        <>
          <FontIcon
            className={classNames(SliderClasses.SliderBtnIcon, CommonClasses.ClickableIcon)}
            type={`keyboard_arrow_${type}`} onClick={onClick} />
        </>}
    </Box>
  )
})

export default Slider;


