import { makeVar }    from "@apollo/client";
import { TypePolicy } from "@apollo/client";
import { Reference }  from "@apollo/client/utilities";
import { loadFile }   from "../../utils/helpers";

export const MailMessage: TypePolicy = {
  fields: {
    html: {
      read(html: string, { readField, storage, cache }) {
        try {
          const attachments: readonly any[] = readField("attachments");
          if (typeof html == "string") {
            return replaceAttachmentUrls(html, attachments);
          }
          const bodyRef: Reference = readField("body");

          storage.htmlVar ??= makeVar("");
          if (storage.htmlRef != bodyRef?.__ref) {
            storage.htmlRef = bodyRef?.__ref;
            const url: string = readField("url", bodyRef);
            if (url) {
              let startRef = storage.htmlRef;
              loadFile(url).then(text => {
                if (storage.htmlRef == startRef) {
                  storage.htmlVar(replaceAttachmentUrls(text, attachments));
                } else {

                }
              });
            }
          }
          return storage.htmlVar();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
};

function replaceAttachmentUrls(html: string, attachments: readonly { contentId, filename, url }[]) {
  if (!html) {
    return html;
  }
  if (!attachments.length) {
    return html;
  }
  html = html.replace(/src="cid:([^"]+)"/g, (_, cid) => {
    let attachment = attachments.find(a => (
      a.contentId == cid ||
      a.contentId == `<${cid}>`
    ));
    if (attachment) {
      return `src="${attachment.url}"`;
    }
    return _;
  });
  return html;
}
