import { useCallback }               from "react";
import { useQuery }                  from "@apollo/client";
import { gql }                       from "@apollo/client";
import { useMutation }               from "@apollo/client";
import { useAlert }                  from "@relcu/ui";
import { useSource }                 from "@relcu/ui";
import { UPDATE_SETTINGS }           from "../../../../graph/operations.graphql";
import { toNodeId }                  from "../../../../utils/helpers";
import { GetSignatureUserVariables } from "./__types__/GetSignatureUser";
import { GetSignatureUser }          from "./__types__/GetSignatureUser";

export function useEmailSignature() {
  const { $settings, $viewer } = useSource();
  const [update] = useMutation(UPDATE_SETTINGS);
  const { data: { user } = {} } = useQuery<GetSignatureUser, GetSignatureUserVariables>(GET_SIGNATURE_USER, {
    variables: {
      id: $viewer.id
    }
  });

  const { error, success } = useAlert();
  const addSignature = useCallback((fields: any) => {
    fields.push({ name: "", signature: "", enabledRelcu: true, enabledMicrosoftGmail: false });
  }, []);

  const handleSubmit = useCallback(async (data) => {
    try {
      await update({
        variables: {
          id: toNodeId({ className: "Settings", objectId: $settings.objectId }),
          fields: { emailSignature: data.emailSignature }
        }
      });
      success("Changes saved successfully.");
    } catch (e) {
      console.error(e);
      error(e?.message);
    }
  }, [$settings]);

  const handleDelete = useCallback(async () => {
    try {
      await update({
        variables: {
          id: toNodeId({ className: "Settings", objectId: $settings.objectId }),
          fields: { emailSignature: [] }
        }
      });
    } catch (e) {
      console.error(e);
      error(e?.message);
    }
  }, [$settings]);

  return {
    user,
    addSignature,
    handleSubmit,
    handleDelete
  };
}

export const EMAIL_SIGNATURE_USER_FRAGMENT = gql`
  fragment EmailSignatureUser on User {
    objectId
    objectName
    objectIcon
    nmlsId
    firstName
    lastName
    reviewUrl
    applyUrl
    calendar
    username
    positionName
    phoneLines{
      edges {
        node {
          number
        }
      }
    }
  }
`;

export const GET_SIGNATURE_USER = gql`
  query GetSignatureUser($id:ID!)  {
    user(id: $id) {
      ...EmailSignatureUser
    }
  }
  ${EMAIL_SIGNATURE_USER_FRAGMENT}
`;

export const GET_SIGNATURE_USERS = gql`
  query GetSignatureUsers($where:UserWhereInput)  {
    users(where: $where, first: 20) {
      edges {
        node {
          ...EmailSignatureUser
        }
      }
    }
  }
  ${EMAIL_SIGNATURE_USER_FRAGMENT}
`;
