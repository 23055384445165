import { useLazyQuery }     from "@apollo/client";
import { gql }              from "@apollo/client";
import { useQuery }         from "@apollo/client";
import { OnChange }         from "@relcu/form";
import { useForm }          from "@relcu/form";
import { Field }            from "@relcu/form";
import { ChoiceField }      from "@relcu/ui";
import { Box }              from "@relcu/ui";
import { useEffect }        from "react";
import { useMemo }          from "react";
import React                from "react";
import { JsonSection }      from "@relcu/ui";
import { JsonSectionProps } from "@relcu/ui";
import { useJqlQuery }      from "../../Jql";

export const UserOBSection = React.memo<JsonSectionProps>(function UserOBSection({ component, ...props }) {
  const { title, editable, onEdit, onClear, ...p } = props;
  const { data: { optimalBlueBusinessChannels = [] } = {} } = useJqlQuery({
    operation: "optimalBlueBusinessChannels",
    fields: [
      "index",
      "name",
      {
        originators: [
          "index",
          "firstName",
          "lastName"
        ]
      }
    ]
  }, {
    operationName: "OptimalBlueBusinessChannels",
    fetchPolicy: "cache-first"
  });

  const channels = useMemo(() => {
    return optimalBlueBusinessChannels.map((i) => {
      return {
        value: i.index,
        label: i.name
      };
    }).sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }, [optimalBlueBusinessChannels]);
  const form = useForm();
  return (
    <JsonSection
      view={props.view}
      title={title}
      editable={editable}
      container
      direction={"column"}
      gap={"XS"}
      onEdit={onEdit}
      onClear={onClear}
      {...p}>
      <Box container direction={"column"} gap={"XS"} alignSelf={"stretch"} flex={1}>
        <Box container gap={"XS"}>
          <Field name={`optimalBlue.businessChannelId`} subscription={{ value: true }}>
            {({ input: { value } }) => (
              <ChoiceField
                placeholder={"Select Business channel ID"}
                name={`optimalBlue.businessChannelId`}
                defaultValue={value || null}
                label="Business channel ID"
                view={props.view as "write" | "read"}
                options={channels}
                handleClear={() => form.change(`optimalBlue.businessChannelId`, null)}
                flex={1}/>
            )}
          </Field>
          <OnChange name={`optimalBlue.businessChannelId`} children={() => {
            form.change(`optimalBlue.originatorId`, null)
          }}/>
          <Field name={`optimalBlue.businessChannelId`} subscription={{ value: true }}>
            {({ input: { value: channel } }) => (
              <Field name={`optimalBlue.originatorId`} subscription={{ value: true }}>
                {({ input: { value } }) => {
                  const options = channel ? optimalBlueBusinessChannels.find(i => i.index === channel)?.originators.map(o => ({ value: o.index, label: `${o.firstName} ${o.lastName}` })).sort((a, b) => {
                    if (a.label < b.label) {
                      return -1;
                    }
                    if (a.label > b.label) {
                      return 1;
                    }
                    return 0;
                  }) : [];
                  return <ChoiceField
                    name={`optimalBlue.originatorId`}
                    placeholder={"Select Originator ID"}
                    defaultValue={value || null}
                    label="Originator ID"
                    handleClear={() => form.change(`optimalBlue.originatorId`, null)}
                    view={props.view as "write" | "read"}
                    options={options}
                    flex={1}/>;
                }}
              </Field>
            )}
          </Field>
        </Box>
      </Box>
    </JsonSection>
  );
});
