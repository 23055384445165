import React          from "react";
import { FC }         from "react";
import { Form }       from "@relcu/rc";
import { EmptyState } from "@relcu/rc";
import { Button }     from "@relcu/rc";
import { Stack }      from "@relcu/rc";
import { useSource }  from "@relcu/ui";
import { RadioField } from "@relcu/ui";

export const EmptyMailTemplateState: FC<{
  hasPermission: boolean,
  createPermissions: { label: string, value: string }[]
  handleCreate(type: string)
}> = React.memo(function EmptyMailTemplateState(props) {
  const { hasPermission, createPermissions, handleCreate } = props;
  const { $viewer, $object } = useSource();
  return <Form
    keepDirtyOnReinitialize={true}
    onSubmit={(values) => handleCreate(values.type)}
    initialValues={{ type: createPermissions[ 0 ]?.value }}
    formProps={{ style: { display: "contents" } }}>
    <EmptyState
      // buttonDisabled={!canContact}
      buttonLabel={"CREATE"}
      background
      icon={"email"}
      title={"You haven’t created templates"}
      subtitle={($viewer.id == $object.id) && "To create a new one, choose the type and click the  ‘Create’ button below."}>
      {
        ((($viewer.id == $object.id) || $object.className == "Settings") && hasPermission) &&
        <Stack direction={"column"} spacing={16} childrenRenderMode={"clone"} style={{ marginTop: 16 }}>
          {
            createPermissions.length > 1 &&
            <Stack spacing={32} childrenRenderMode={"clone"}>
              {
                createPermissions.map((perm, index) => {
                  return <RadioField name={"type"} label={perm.label} value={perm.value} key={index}/>;
                })
              }
            </Stack>
          }
          <Button type={"submit"}>CREATE</Button>
        </Stack>
      }
    </EmptyState>
  </Form>;
});
