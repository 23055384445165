import React                                     from "react";
import { useCallback }                           from "react";
import { useMemo }                               from "react";
import { useEffect }                             from "react";
import { gql }                                   from "@apollo/client";
import { useQuery }                              from "@apollo/client";
import { useMutation }                           from "@apollo/client";
import { BadgeColors }                           from "@relcu/ui";
import { useNavigate }                           from "@relcu/react-router";
import { useLocation }                           from "@relcu/react-router";
import { matchPath }                             from "@relcu/react-router";
import { PathMatch }                             from "@relcu/react-router";
import { USER_HISTORY_BOOKMARK_FRAGMENT }        from "../../graph/operations.graphql";
import { layoutVar }                             from "../../reactiveVars";
import { ILayout }                               from "../../types/ILayouts";
import { pointer }                               from "../../utils/helpers";
import { toFirstLower }                          from "../../utils/helpers";
import { toPointer }                             from "../../utils/helpers";
import { getObjectPath }                         from "../../utils/layoutUtils";
import { ViewerBookmarksAndhistory }             from "../__types__/ViewerBookmarksAndhistory";
import { VIEWER_HISTORY_BOOKMARK }               from "../operations.graphql";
import { useLazyStats }                          from "../Stat";
import { useLazyCondition }                      from "@relcu/ui";
import { SubscribeUserHistoryBookmarkVariables } from "./__types__/SubscribeUserHistoryBookmark";
import { SubscribeUserHistoryBookmark }          from "./__types__/SubscribeUserHistoryBookmark";
import { UpdateUser }                            from "./__types__/UpdateUser";
import { UpdateUserVariables }                   from "./__types__/UpdateUser";

export function useBookmarks() {
  const { data: { viewer: { user: { history, bookmarks, id, objectId, notificationControls } } }, subscribeToMore } = useQuery<ViewerBookmarksAndhistory>(VIEWER_HISTORY_BOOKMARK, {
    fetchPolicy: "cache-only",
    returnPartialData: true
  });

  const layout = layoutVar();
  const evaluate = useLazyCondition();
  const layouts = Object.values(layout).filter(l => !!l.route);

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(UPDATE_USER);
  const location = useLocation();
  const navigate = useNavigate();
  const loadStats = useLazyStats();
  function convert(bookmarks) {
    return bookmarks?.map(bookmark => {
      if (bookmark.__typename !== "Element") {
        const isYou = id === bookmark.id;
        let count = 0;
        const { missedCallsCount, unreadMessagesCount, remindersCount, bulkEmailsCount, unreadEmailsCount, unreadPipelinesCount, bulkSMSCount } = loadStats(bookmark.id);
        if (!isYou) {
          for (let control in notificationControls) {
            switch (control) {
              case "emails":
                count += notificationControls[ control ] ? unreadEmailsCount : 0;
                break;
              case "sms":
                count += notificationControls[ control ] ? unreadMessagesCount : 0;
                break;
              case "missedCalls":
                count += notificationControls[ control ] ? missedCallsCount : 0;
                break;
              case "loanComments":
                count += notificationControls[ control ] ? unreadPipelinesCount : 0;
                break;
              case "tasksReminders":
                count += notificationControls[ control ] ? remindersCount : 0;
                break;
              case "bulkEmail":
                count += notificationControls[ control ] ? bulkEmailsCount : 0;
                break;
              case "bulkSMS":
                count += notificationControls[ control ] ? bulkSMSCount : 0;
                break;
              default:
                break;
            }
          }
        }
        return {
          id: bookmark.id,
          objectId: bookmark.objectId,
          name: bookmark.objectName,
          type: bookmark.__typename,
          image: (bookmark.__typename == "Lead" ? null : bookmark.objectIcon),
          status: bookmark.status,
          deactivated: bookmark.deactivated,
          color: BadgeColors.Error,
          count: count,
          to: `${getObjectPath(bookmark)}/`
        };
      }
      return {
        id: bookmark.value.objectId,
        objectId: bookmark.value.objectId,
        name: bookmark.value.objectName,
        type: "Page",
        image: bookmark.value.objectIcon,
        to: bookmark.value.objectPath
      };
    });
  }
  async function update(fields) {
    let history = fields.history;
    if (history) {
      let empCount = 0;
      const updated = [];
      for (let h of history) {
        if (location.pathname.includes(h[ "objectId" ])) {
          updated.push(h);
        } else {
          if (!!hasStat(h)) {
            updated.push(h);
          } else if (empCount < 5) {
            empCount++;
            updated.push(h);
          }
        }
      }
      if (updated.length !== history.length) {
        fields.history = updated;
      }
    }
    Object.keys(fields).forEach(k => {
      fields[ k ] = fields[ k ].map(d => {
        if (d.id) {
          return toPointer(d.id);
        }
        if (d.__typename === "Element") {
          return d.value;
        }
        return d;
      });
    });
    return updateUser({
      variables: {
        input: {
          id,
          fields
        }
      }
    });
  }
  async function onPin(item) {
    return onChange({
      history: current.filter(h => h[ "id" ] !== item.id),
      bookmarks: [item, ...watch]
    });
  }

  async function onUnpin(item) {
    return onChange({
      bookmarks: watch.filter(h => h[ "id" ] !== item.id),
      history: [item, ...current]
    });
  }
  async function onRemove(item) {
    let next;
    const all = [...watch, ...current];
    if (all.length == 1 && all[ 0 ].id == id) {
      return false;
    }

    if (item.objectId == objectId) {
      const currentIndex = current.findIndex(c => c.objectId == item.objectId);
      next = current[ currentIndex + 1 ];
      if (!next) {
        next = current[ current.length - 2 ];
      }//current element is last one
    }
    return onChange({
      history: current.filter(h => h[ "id" ] !== item.id)
    }).finally(() => {
      if (next) {
        navigate(next.to);
      }
    });
  }
  async function onClear() {
    return onChange({
      history: current.filter(e => e.id === id || e.count)
    });
  }
  async function onChange(fields) {
    Object.keys(fields).map(k => {
      fields[ k ] = fields[ k ].map(d => {
        if (d.type !== "Page") {
          return toPointer(d.id);
        }
        return {
          objectId: d.objectId,
          objectIcon: d.image,
          objectName: d.name,
          objectPath: d.to
        };
      });
    });
    return update(fields);
  }
  async function pushState(match: PathMatch, layout: ILayout) {
    const state = [...history, ...bookmarks];
    const objectId = match.params?.objectId || match.pathname;
    const found = state.find(o => o[ "objectId" ] === objectId || (o[ "value" ]?.objectId == objectId));
    if (found) {
      return;
    }
    if (layout.className && match.params?.objectId) {
      return update({
        history: [
          pointer({ className: layout.className, objectId }),
          ...history
        ]
      });
    }
    return update({
      history: [
        {
          objectIcon: layout.objectIcon,
          objectName: layout.objectName,
          objectPath: match.pathname,
          objectId
        },
        ...history
      ]
    });
  }
  function hasStat(h) {
    const { missedCallsCount, unreadMessagesCount, remindersCount, unreadEmailsCount, bulkEmailsCount, bulkSMSCount, unreadPipelinesCount } = loadStats(h.id);
    return missedCallsCount || unreadMessagesCount || remindersCount || unreadEmailsCount || unreadPipelinesCount || bulkEmailsCount || bulkSMSCount;
  }
  useEffect(() => {
    for (let layout of layouts) {
      const match = matchPath(layout.route, location.pathname);
      if (match && (!layout.conditions || evaluate({ conditions: layout.conditions }).apply)) {
        pushState(match, layout).catch(console.error);
        break;
      }
    }
  }, [location.pathname]);
  const current = useMemo(() => convert(history) ?? [], [history, loadStats, notificationControls]);
  const watch = useMemo(() => convert(bookmarks) ?? [], [bookmarks, loadStats, notificationControls]);
  const setCurrent = useCallback(history => onChange({ history }), []);
  const setWatch = useCallback(bookmarks => onChange({ bookmarks }), []);
  useEffect(() => {
    return subscribeToMore<SubscribeUserHistoryBookmark, SubscribeUserHistoryBookmarkVariables>({
      document: SUBSCRIBE_USER_HISTORY_BOOKMARK,
      variables: { id }
    });
  }, [id]);

  return {
    current,
    watch,
    onChange,
    setWatch,
    setCurrent,
    onPin,
    onUnpin,
    onClear,
    onRemove
  };
}
export const BookmarkContext = React.createContext<ReturnType<typeof useBookmarks>>(null);
const SUBSCRIBE_USER_HISTORY_BOOKMARK = gql`
  ${USER_HISTORY_BOOKMARK_FRAGMENT}
  subscription SubscribeUserHistoryBookmark($id:ID!){
    users(where: {id: {equalTo: $id}}){
      event
      node{
        id
        objectId
        ...UserHistoryAndBookmark
      }
    }
  }
`;
const UPDATE_USER = gql`
  ${USER_HISTORY_BOOKMARK_FRAGMENT}
  mutation UpdateUser($input:UpdateUserInput!) {
    updateUser(input: $input){
      user{
        ...UserHistoryAndBookmark
      }
    }
  }
`;
