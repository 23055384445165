import React                     from "react";
import { useRef }                from "react";
import { useCallback }           from "react";
import { useFieldArray }         from "@relcu/form";
import { BoxItemComponentProps } from "@relcu/ui";
import { Mime }                  from "@relcu/mime";

export interface AttachmentUploaderProps extends BoxItemComponentProps {
  name: string;
}

export function useAttachmentUploader(props: AttachmentUploaderProps) {
  const { name } = props;
  const validateAttachments = useCallback((items) => {
    if (items?.length > 10) {
      return "Only 10 files may be uploaded at a time.";
    }
    const supportedFiles = [];
    const unSupportedFiles = [];
    items.forEach(value => {
      const type = value.attachment.mimeType || value.attachment.type || Mime.getType(value.attachment.name);
      if (["image/jpeg", "image/gif", "image/png"].includes(type)) {
        supportedFiles.push(value.attachment);
      } else {
        unSupportedFiles.push(value.attachment);
      }
    });
    const supportedFilesSize = supportedFiles.reduce((sum, file) => sum + file.size, 0);
    const unSupportedFilesSize = unSupportedFiles.reduce((sum, file) => sum + file.size, 0);
    const totalFilesSize = supportedFilesSize + unSupportedFilesSize;

    if (unSupportedFilesSize / 1024 > 1500 && items.length > 1) {
      return "Total size of non image attachments can not be greater than 1.5MB";
    }
    if (Math.ceil(totalFilesSize / 1024 / 1024) > 5 && items.length > 1) {
      return "Total size of attachments can not be greater than 5MB";
    }
  }, []);

  const attachment = useFieldArray(name, {
    validate: validateAttachments
  });
  const keysRef = useRef(attachment.fields.length ? [0] : []);
  const counterRef = useRef(attachment.fields.length);

  function addFile(file) {
    keysRef.current.push(++counterRef.current) &&
    attachment.fields.push({ attachment: file });
  }

  function onPaste(e: ClipboardEvent) {
    e.clipboardData.files[ 0 ] && addFile(e.clipboardData.files[ 0 ]);
  }

  return {
    fields: attachment.fields,
    error: attachment.meta.error,
    keysRef,
    onPaste,
    addFile
  };
}
