import { TypePolicy }    from "@apollo/client";
import { schemaVar }     from "../../reactiveVars";
import { getNullValues } from "../../utils/schemaUtils";

export const Property: TypePolicy = {
  fields: {
    propertyAddress: {
      read(existing, { storage, cache }) {
        if (!storage.defaultValue) {
          const schemas = schemaVar()
          storage.defaultValue = getNullValues(schemas, "Address");
        }
        return existing || storage.defaultValue;
      }
    }
  }
};
