export const enum ConferenceViewClasses {
  ConferenceView = "conference-view",
  Conference = "conference",
  ConferenceSection = "conference-section",
  ConferenceSectionHeader = "conference-section__header",
  ConferenceSectionInfo = "conference-section__info",
  ConferenceHeader = "conference-header",
  ConferenceScope = "conference-scope",
  FilterText = "filter-text",
  ConferenceEmptyList = "conference-empty-list",
  TranscriptionIndicator = "transcription-indicator"
}
