import React                    from "react";
import { Box }                  from "../..";
import { classNames }           from "../..";
import { BoxComponentProps }    from "../Box";
import { SubNavigationClasses } from "./SubNavigationClasses";

interface SubNavigationProps extends BoxComponentProps {

}
export function SubNavigation(props: SubNavigationProps) {
  const { children, className, ...p } = props;
  const classes = classNames(SubNavigationClasses.SubNavigation, className);

  return <Box container className={classes} gap={"XXXXS"} {...p}>
    {children}
  </Box>;
}
