import React                        from "react";
import { TypeAttributes }           from "../@types/common";
import { RsRefForwardingComponent } from "../@types/common";
import { FlexboxGridItemProps }     from "../FlexboxGrid";
import FlexboxGrid                  from "../FlexboxGrid";
import { useClassNames }            from "../utils";

export interface ColumnProps extends FlexboxGridItemProps {
  /** A tag can have different colors */
  color?: TypeAttributes.Color;

  heading?: boolean;

  colSpan?: number;

  /** Whether to close */
  closable?: boolean;

  /** The content of the component */
  children?: React.ReactNode;

  /** Click the callback function for the Close button */
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const Column: RsRefForwardingComponent<"div", ColumnProps> = React.forwardRef((props: ColumnProps, ref) => {
  const {
    className,
    children,
    heading,
    classPrefix = "column",
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix({ heading })
  );

  return <FlexboxGrid.Item className={classes} {...rest}>
    {children}
  </FlexboxGrid.Item>;
});
