export interface DroppableProps {
  dropEffect: "copy" | "move" | "link" | "none",
  acceptFrom?: string[],
  droppableId?: string,
  objectTypes: string[], //file format and our object types, //todo Files
  acceptFiles?: boolean,
  onOverCb?(e),
  onEnterCb?(e),
  onLeaveCb?(e),
  onDropCb?(data, e?),
  onErrorCb?(message)
}

export const useDroppable = (args: DroppableProps) => {
  const {
    acceptFiles = false,
    objectTypes = ["*"],
    acceptFrom = [],
    dropEffect,
    onOverCb,
    onLeaveCb,
    onEnterCb,
    onDropCb,
    onErrorCb
  } = args;

  const checkValidType = (dataTransferTypes: string[], keys: string[]) => {
    return dataTransferTypes.every(type => {
      return keys.find(item => {
        return type.toLocaleLowerCase().includes(item.toLocaleLowerCase());
      });
    });
  };

  const checkValidComponentDrop = (dataTransfer) => {
    let effect = dataTransfer.effectAllowed;
    if (
      (effect == dropEffect || effect.toLocaleLowerCase().includes(dropEffect) || effect == "all") &&
      !acceptFiles &&
      (checkValidType(dataTransfer.types, objectTypes) || objectTypes.includes("*")) &&
      (!acceptFrom.length || checkValidType(dataTransfer.types, acceptFrom) || acceptFrom.includes("*"))
    ) {
      return true;
    }

    return false;
  };

  const checkValidFileType = (dataTransfer) => {
    let valid = true;
    for (let i = 0; i < dataTransfer.items.length; i++) {
      if (!objectTypes.includes(dataTransfer.items[ i ].type)) {
        valid = false;
      }
    }

    return valid;
  };

  const checkValidFileDrop = (dataTransfer) => {
    return acceptFiles && dataTransfer.effectAllowed == "all" && dataTransfer.types.includes("Files") && (checkValidFileType(dataTransfer) || objectTypes.includes("*"));
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (checkValidComponentDrop(e.dataTransfer) || checkValidFileDrop(e.dataTransfer)) {
      // if (e.target == e.currentTarget) {
      onEnterCb && onEnterCb(e); //TODO speak with Vahram about onEnter shooting check target == currentTarget or not ?
      // setAcceptDrag(true);
      // }
    }
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // if (e.target == e.currentTarget && acceptDrag) {
    //   setAcceptDrag(false);
    // }//todo add to next if after checking

    if (checkValidComponentDrop(e.dataTransfer) || checkValidFileDrop(e.dataTransfer)) {
      onLeaveCb && onLeaveCb(e);
    }
  };

  const onDrop = (e) => {
    if (e.dataTransfer.types.includes("Files")) {// to stop file open in tab whatever file valid or not in this case we dont need dragEnd
      const files = [];
      e.preventDefault();
      if (checkValidFileDrop(e.dataTransfer)) {// check if file valid then shoot drop event callback
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          files.push(e.dataTransfer.files[ i ]);
        }
        onDropCb && onDropCb(files, e);
      }
    } else if (checkValidComponentDrop(e.dataTransfer)) {
      e.preventDefault();
      let allowedData = [];
      e.dataTransfer.types.map(type => {
        allowedData.push(JSON.parse(e.dataTransfer.getData(type)).data);
      });
      onDropCb && onDropCb(allowedData, e);
    }

    // if (acceptDrag) {
    //   setAcceptDrag(false);
    // }
  };

  const onDragOver = (e) => {
    if (checkValidComponentDrop(e.dataTransfer) || checkValidFileDrop(e.dataTransfer)) {
      e.dataTransfer.dropEffect = dropEffect;
      onOverCb && onOverCb(e);
    } else {
      e.dataTransfer.dropEffect = "none";
    }
    e.stopPropagation();
    e.preventDefault();

  };

  return { onDragEnter, onDragLeave, onDrop, onDragOver };
};
