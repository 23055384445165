import React                      from "react";
import { FC }                     from "react";
import { getIn }                  from "@relcu/form";
import { ButtonColors }           from "@relcu/ui";
import { List }                   from "@relcu/ui";
import { Button }                 from "@relcu/ui";
import { FontIcon }               from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { classNames as clsNames } from "@relcu/ui";
import { SectionClasses }         from "@relcu/ui";
import { Accordion }              from "@relcu/ui";
import { useModal }               from "@relcu/ui";
import { CommonClasses }          from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { useSource }              from "@relcu/ui";
import { getHumanized }           from "../../../../../utils/schemaUtils";
import LoanProposalDialog         from "../LoanProposalDialog";
import "./pending-offers.css";
import { PendingOffersClasses }   from "./PendingOffersClasses";

export interface PendingOffersProps extends BoxComponentProps {
  offers: any[],
  headers: any[],
  selectedFields: any[],
  loading: boolean
  count: number
  onPreviewPdf: (file, objectId) => void
  onRemove(obj)
}

export const PendingOffers: FC<PendingOffersProps> = React.memo(function PendingOffers(props) {
  const { className, offers, count, onRemove, headers, selectedFields, onPreviewPdf } = props;
  const [open, contextHolder] = useModal(LoanProposalDialog);
  const { $object: node, $settings: { pricing: settings } } = useSource();
  const classNames = clsNames(PendingOffersClasses.PendingOffers, className);
  const actions = [{
    icon: "delete",
    tooltip: "Delete",
    color: ButtonColors.White,
    onClick: (e, obj) => onRemove(obj)
  }];
  function getColumnValue(row, column) {
    if (column.name == "cashAtClosing") {
      const propertyVal = row.loanCriteria.loanPurpose == "purchase" ? row.loanCriteria.property.value : row.loanCriteria.property.currentMortgageBalance;
      const cashAtClosing = row.totalClosingCost + propertyVal - row.loanCriteria.loanAmount;
      column.properties = {
        ...column.properties,
        "data-support-text": cashAtClosing > 0 ? "⊖" : "⊕"
      };
      return getHumanized(row.__typename, column.name, Math.abs(cashAtClosing));
    }
    return getHumanized(row.__typename, column.name, getIn(row, column.name));
  }
  function handleOpen(e) {
    e.stopPropagation();
    open({
      id: node.id,
      templateId: settings?.loanProposal?.templateId,
      onPreview: onPreviewPdf
    });
  }

  return (
    <>
      {contextHolder}
      <Accordion
        container
        className={classNames}
        collapse={true}
        header={(open) => {
          return <Box className={"header"} container alignItems={"center"} gap={"XS"}>
            <FontIcon
              className={clsNames(CommonClasses.ClickableIcon, {
                [ CommonClasses.PrimaryIcon ]: open
              })}
              alignSelf={"center"}
              type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
            <Box flex={"auto"} container alignItems={"center"} gap={"XXXS"}>
              <h3 className={SectionClasses.SectionHeader}>Selected offers</h3>
              <span style={{ color: "#727e92" }}>({count || 0})</span>
            </Box>
            <Button onClick={handleOpen}>MAKE PROPOSAL</Button>

          </Box>;
        }}
      >
        <List className={"body"} headers={headers} fields={selectedFields} objects={offers} actions={actions as any}
              isActionSticky={true}
              getColumnValue={getColumnValue}/>
      </Accordion>
    </>
  );
});
