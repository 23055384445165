import React                 from "react";
import { DateTime }          from "luxon";
import { Ellipsis }          from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { Widget }            from "@relcu/ui";
import { useSource }         from "@relcu/ui";
import { formatNumber }      from "@relcu/ui";
import { IChoiceField }      from "../../../../types/ISchemas";
import { useSchemaField }        from "../../useSchemaField";
import { MoomLeadWidgetClasses } from "./MoomLeadWidgetClasses";
import "./moom-lead-widget.css";

export const MoomLeadWidget = React.memo(() => {
  const { $object: node } = useSource();
  const creditRatingSchema = useSchemaField<IChoiceField>("LeadMember", "creditRating");
  const languagePreferencesSchema = useSchemaField<IChoiceField>("Lead", "languagePreference");

  let borrower = node.members.find((b)=>b.type==="borrower");
  let creditRating = creditRatingSchema.options.find((option) => option.value == borrower.creditRating);
  let language = languagePreferencesSchema.options.find((option) => option.value == node.languagePreference);

  return (
    <Widget gap={"XS"} className={MoomLeadWidgetClasses.MoomLeadWidget}>
      <Box container direction={"column"}>
        <Box container justify={"start"} className={MoomLeadWidgetClasses.MoomLeadWidgetMainRow}>
          {node.borrowerFirstName} {node.borrowerLastName}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Is military</Box>
          {borrower?.isMilitary?"Yes":"No"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Borrower birthday</Box>
          {node?.borrowerBirthday ? DateTime.fromISO(node.borrowerBirthday).toFormat("D") : "-"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Borrower SSN</Box>
          {node.borrowerSSN||"-"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Credit rating</Box>
          {creditRating?.label||"-"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Credit score</Box>
          {borrower?.creditScore||"-"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Marital status</Box>
          {borrower?.maritalStatus||"-"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Prior bankruptcy?</Box>
          {borrower?.bankruptcy?"Yes":"No"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Prior foreclosure?</Box>
          {borrower?.foreclosure||"-"}
        </Box>
        <Box container justify={"start"} alignItems={"center"} className={MoomLeadWidgetClasses.MoomLeadWidgetRow}>
          <Box flexBasis={"50%"} flexShrink={0}>Language preference</Box>
          {language?.label||"-"}
        </Box>
      </Box>
    </Widget>
  );
});
