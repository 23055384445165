import React                 from "react";
import { Box }               from "../..";
import { classNames }        from "../..";
import { BoxComponentProps } from "../Box";
import { TabClasses }        from "./TabClasses";

export interface TabsProps extends BoxComponentProps {
  onChange?(index)
}
export function Tabs(props: TabsProps) {
  const { className, children, onChange, ...p } = props;
  const classes = classNames(TabClasses.Tabs, className);

  return <Box container flex={"0 1"} justify={"start"} className={classes} gap={"XXS"} {...p}>
    {children}
  </Box>;
}
