import React, { useState }       from "react";
import { Alignment }             from "../..";
import { BoxItemComponentProps } from "../..";
import { applyBoxItemStyles }    from "../..";
import { Popper }                from "../Popper";

export interface TooltipProps extends BoxItemComponentProps {
  title: string,
  alignment?: Alignment
}
Tooltip.defaultProps = {
  alignment: Alignment.BottomRight
};

export function Tooltip(props: TooltipProps) {
  let properties = applyBoxItemStyles<TooltipProps>(props);
  const { children, alignment, title, ...p } = properties;
  const [anchorBounding, setAnchorBounding] = useState(null);

  const handleTouchStart = event => {
    setAnchorBounding(event.currentTarget.getBoundingClientRect());
  };
  const handleTouchEnd = () => {
    setAnchorBounding(null);
  };

  const childrenProps = {
    onFocus: handleTouchStart,
    onMouseEnter: handleTouchStart,
    onMouseLeave: handleTouchEnd,
    onMouseDown: handleTouchEnd,
    onBlur: handleTouchEnd
  };

  return <>
    {React.cloneElement((children as any), { ...childrenProps })}
    {
      title &&
      <Popper open={true} alignment={alignment} anchorBounding={anchorBounding} threshold={4}>
        <p {...p} className={TooltipClasses.TooltipText}>{title}</p>
      </Popper>
    }
  </>;
}
export enum TooltipClasses {
  TooltipText = "tooltip-text"
}
