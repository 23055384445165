import React                           from "react";
import { FC }                          from "react";
import { useMemo }                     from "react";
import { useContext }                  from "react";
import { useField }                    from "@relcu/form";
import { OnChange }                    from "@relcu/form";
import { FormSpy }                     from "@relcu/final-form";
import { FormField }                   from "@relcu/rc";
import { useSource }                   from "@relcu/ui";
import { transformNameToLabel }        from "../../../../../../utils/helpers";
import { toFirstLower }                from "../../../../../../utils/helpers";
import { PreviewSettings }             from "../../../../../Generation";
import { PointerPicker }               from "../../../../Field/PointerPicker";
import { PointerPickerProps }          from "../../../../Field/PointerPicker";
import { PhoneMessageTemplateContext } from "../Content";
import { ContactPreviewProps }         from "./ContactPreview";
import { ContactPreview }              from "./ContactPreview";

export const PhoneMessageTemplatePreviewSettings: FC = React.memo(function PhoneMessageTemplatePreviewSettings() {
  const { replaceableFieldsSources } = useContext(PhoneMessageTemplateContext);
  let optionalFields = useMemo(() => replaceableFieldsSources.filter(r => r != "Contact"), [replaceableFieldsSources]);
  const contactField = useField("contact");
  const { $viewer } = useSource();
  const userFilters = useMemo(() => {
    if ($viewer.role == "loan_officer" || ($viewer.role == "team_manager" && !$viewer.team)) {
      return {
        objectId: { equalTo: $viewer.objectId }
      };
    } else if ($viewer.role == "team_manager") {
      return {
        team: { equalTo: $viewer.team }
      };
    }
    return {};
  }, [$viewer.role]);

  return <PreviewSettings divided>
    <PreviewSettings.Section>
      <PreviewSettings.Title>
        Preview settings
      </PreviewSettings.Title>
      <FormField<PointerPickerProps>
        component={PointerPicker}
        name="user"
        label="User"
        errorMessage={false}
        properties={{
          disabled: $viewer.role == "loan_officer",
          cleanable: true,
          style: { width: "100%" },
          labelKey: "objectName",
          valueKey: "objectId",
          filters: userFilters,
          targetClass: "User"
        }}
      />
      {
        <FormSpy subscription={{ values: true, initialValues: true }}>
          {
            ({ values, initialValues }) => {
              return optionalFields.filter(r => values.applyTo.includes(toFirstLower(r))).map((field, index) => {
                return <FormField<PointerPickerProps>
                  key={index}
                  component={PointerPicker}
                  name={"scope"}
                  label={transformNameToLabel(field)}
                  errorMessage={false}
                  properties={{
                    cleanable: true,
                    style: { width: "100%" },
                    labelKey: "objectName",
                    valueKey: "id",
                    targetClass: field
                  }}
                />;
              });
            }
          }
        </FormSpy>
      }
      <OnChange name={"scope"} children={(value) => {
        const [className] = atob(value).split(":");
        if (className != "Lead") {
          contactField.input.onChange(null);
        }
      }}/>
      <FormSpy subscription={{ values: true }}>
        {
          ({ values }) => {
            const isScopeAvailable = values.applyTo.includes("lead");
            return (values.applyTo.includes("contact") && (!values.applyTo.includes("lead") || values.applyTo.includes("lead") && values.scope)) ?
              <FormField<ContactPreviewProps>
                component={ContactPreview}
                name={"contact"}
                label={"Contact"}
                errorMessage={false}
                properties={{
                  scope: isScopeAvailable ? values.scope : null
                }}
              /> : null;
          }
        }
      </FormSpy>
    </PreviewSettings.Section>
  </PreviewSettings>;
});

