import React                      from "react";
import { FC }                     from "react";
import { PhoneNumbersFieldProps } from "@relcu/ui";
import { BaseArrayField }         from "@relcu/ui";
import { ContactTypesEditFiled }  from "./ContactTypesEditFiled";

export type ContactTypesFieldProps = Partial<PhoneNumbersFieldProps>

const ContactTypesField: FC<ContactTypesFieldProps> = React.memo(function ContactTypesField(props) {

  return (
    <BaseArrayField
      {...props}
      view="write"
      readView={null}
      editView={<ContactTypesEditFiled/>}
    />
  )
});
export default ContactTypesField;
