import React                                               from "react";
import { ComponentType }                                   from "react";

import { QueryBuilderProps }                               from "react-querybuilder";
import { DragHandleProps, QueryBuilderWithoutDndProvider } from "react-querybuilder";
import { OperatorSelectorProps }                           from "react-querybuilder";
import { ActionWithRulesProps }                            from "react-querybuilder";
import { CombinatorSelectorProps }                         from "react-querybuilder";
import { ValueEditorProps }                                from "react-querybuilder";
import { CommonClasses }                                   from "../../../../theme/classes";
import { classNames }                                      from "../../../../utils/classNames";
import { Box }                                             from "../../../Box";
import { ButtonVariants }                                  from "../../../Button";
import { Button }                                          from "../../../Button";
import { FontIcon }                                        from "../../../Icon";
import { Select }                                          from "../../../Input/Select";
import { ToggleCombinator }                                from "../../../Input/ToggleSwitch";

type Builder = QueryBuilderProps & { valueEditor: ComponentType<ValueEditorProps>, maxGroupLevel?: number, showCombinator?: boolean }

export function FilterBuilder(props: Builder) {
  const { valueEditor, fields, maxGroupLevel = 3, showCombinator = true } = props;

  return <QueryBuilderWithoutDndProvider
    enableDragAndDrop={true}
    query={Object.keys(props.query).length ? props.query : null}
    onQueryChange={props.onQueryChange}
    fields={fields}
    showCloneButtons={true}
    controlClassnames={
      {
        queryBuilder: FilterBuilderClasses.Filter,
        header: FilterBuilderClasses.Header,
        ruleGroup: classNames(FilterBuilderClasses.Group, FilterBuilderClasses.Custom),
        rule: FilterBuilderClasses.Rule,
        removeRule: FilterBuilderClasses.RuleRemove,
        dragHandle: classNames(CommonClasses.ClickableIcon, FilterBuilderClasses.DragHandler)
      }
    }
    controlElements={
      {
        addGroupAction: props => (props.level < maxGroupLevel ? <GroupButton {...props} /> : null),
        addRuleAction: RuleButton,
        removeGroupAction: RemoveGroupButton,
        removeRuleAction: RemoveRuleButton,
        cloneGroupAction: CloneGroupButton,
        cloneRuleAction: CloneGroupButton,
        combinatorSelector: showCombinator ? CombinatorButton : () => <Box flex={1}/>,
        operatorSelector: OperatorSelect,
        fieldSelector: FieldSelect,
        valueEditor
      }
    }
  />;
};

// export const DragHandle: React.ForwardRefExoticComponent<DragHandleProps & React.RefAttributes<HTMLSpanElement>> = React.forwardRef(function FieldSelect(props, ref) {
//   return <FontIcon type={"drag_indicator"}
//                    style={{ fontSize: "var(--typography-icon-size)", cursor: "move" }}
//                    className={classNames(CommonClasses.GrayIcon, props.className)}/>;
// });

export const FieldSelect: ComponentType<OperatorSelectorProps> = React.memo(function FieldSelect(props) {
  return <Select
    flex={"0 0 32%"}
    options={props.options}
    value={props.value}
    optionKey={"name"}
    searchable={true}
    optionLabel={"label"}
    onChange={(selected) => props.handleOnChange(selected.name)}
    placeholder={"Search here"}
  />;
});

export const OperatorSelect: ComponentType<OperatorSelectorProps> = React.memo(function OperatorSelect(props) {
  return <Select
    flex={"0 0 32%"}
    options={props.options}
    value={props.value}
    optionKey={"name"}
    optionLabel={"label"}
    onChange={(selected) => props.handleOnChange(selected.name)}
    placeholder={"Select value"}
  />;
});

export const RemoveRuleButton: ComponentType<ActionWithRulesProps> = React.memo(function RemoveGroupButton(props) {
  return <FontIcon type={"remove"} className={CommonClasses.ClickableIcon} onClick={props.handleOnClick}/>;
});

export const RemoveGroupButton: ComponentType<ActionWithRulesProps> = React.memo(function RemoveGroupButton(props) {
  return <FontIcon type={"delete"} className={CommonClasses.ClickableIcon} onClick={props.handleOnClick}/>;
});

export const CloneGroupButton: ComponentType<ActionWithRulesProps> = React.memo(function RemoveGroupButton(props) {
  return <FontIcon type={"content_copy"} className={CommonClasses.ClickableIcon} onClick={props.handleOnClick}/>;
});

export const CombinatorButton: ComponentType<CombinatorSelectorProps> = React.memo(function CombinatorButton(props: any) {
  const { options } = props;
  return <Box container justify={"start"} flex={1}>
    <ToggleCombinator
      options={props.options as any}
      checked={Boolean(props.options.findIndex(n => n.name == props.value))}
      onChange={(checked) => props.handleOnChange(options[ Number(checked) ].name)}/>
  </Box>;
});

export const GroupButton: ComponentType<ActionWithRulesProps> = React.memo(function GroupButton(props) {
  return <Button variant={ButtonVariants.Ghost} onClick={props.handleOnClick}>ADD GROUP</Button>;
});

export const RuleButton: ComponentType<ActionWithRulesProps> = React.memo(function RuleButton(props) {
  return <Button variant={ButtonVariants.Ghost} onClick={props.handleOnClick}>ADD FILTER</Button>;
});

const enum FilterBuilderClasses {
  Filter = "filter-builder",
  Group = "group",
  DragHandler = "drag-handler",
  Custom = "group--custom",
  Header = "header",
  Rule = "rule",
  RuleRemove = "rule__remove",
}
