import { FieldInputProps } from "@relcu/form";
import { useMemo }         from "react";
import React               from "react";
import { useField }        from "@relcu/form";
import { useSource }       from "@relcu/ui";
import { TagProps }        from "../../../Tag";
import { Tag }             from "../../../Tag";

export interface TagFieldProps extends TagProps, Omit<FieldInputProps<any>, "value"> {
  name: string;
}

export const TagField = React.memo<TagFieldProps>(function TagField(props) {
  const { $object } = useSource();
  const { input: { value, onChange } } = useField(props.name, {
    subscription: { value: true, initial: true },
    initialValue: useMemo(() => props.defaultValue || [], [props.defaultValue])
  });
  return (
    <Tag
      onChange={onChange}
      {...props}
      tags={value as string[]}
      id={$object.id}
      className={$object.__typename}
    />
  );
});
