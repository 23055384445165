import React                     from "react";
import { VFC }                   from "react";
import { SearchableSelectProps } from "../../..";
import { BaseField }             from "../BaseField";
import { BaseFieldProps }        from "../BaseField";
import PointerEditField          from "./PointerEditField";
import PointerReadField          from "./PointerReadField";

export interface PointerFieldValue {
  objectId: string
  objectName?: string
  objectIcon?: string
  __typename: string
  [ k: string ]: any
}
export type PointerFieldProps = BaseFieldProps & Omit<SearchableSelectProps, "onSelect"> & {
  thumbnail?: boolean
  options?: PointerFieldValue[]
  filters?: object
  onSelect?()
};

export const PointerField: VFC<PointerFieldProps> = React.memo(function PointerField(props) {
  return (
    <BaseField
      {...props}
      readView={<PointerReadField/>}
      editView={<PointerEditField/>}
    />
  );
});
PointerField.defaultProps = {
  options: []
};
