import { Form }              from "@relcu/form";
import { Field }             from "@relcu/form";
import { AlertColors }       from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { Label }             from "@relcu/ui";
import { Section }           from "@relcu/ui";
import { ChoiceField }       from "@relcu/ui";
import { Button }            from "@relcu/ui";
import { Alert }             from "@relcu/ui";
import { useBoxProps }       from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import { BaseInputClasses }  from "@relcu/ui";
import { CommonClasses }     from "@relcu/ui";
import { classNames }        from "@relcu/ui";
import { useState }          from "react";
import React                 from "react";
import { FC }                from "react";
import { loadingVar }        from "../../../../reactiveVars";

export const LeadImportView: FC<{ options: object[] }> = React.memo(function LeadImportView(props) {
  const [alert, setAlert] = useState<{ message: string, color: AlertColors }>();
  function onAlertClose() {
    setAlert(undefined);
  }
  function onFail(message) {
    setAlert({
      message,
      color: AlertColors.Warning
    });
  }
  async function onSubmit(values) {
    try {
      loadingVar(true);
      setAlert({
        message: "This action might take long, please be patient an do not leave the page!",
        color: AlertColors.PrimaryLight
      });
      const response = await fetch(`/api/v1/lead/import?provider=${values.provider}`, {
        body: values.file,
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Accept": "application/json"
        }
      });
      const result = await response.json();
      loadingVar(false);
      if (!response.ok) {
        return setAlert({
          message: result.error,
          color: AlertColors.Error
        });
      }
      const results = result?.results || [];
      const total = result.total;
      const errors = results.filter(r => !!r.error);
      const failed = errors.length;
      const duplicates = result.duplicates;
      if (failed && duplicates) {
        setAlert({
          message: `Out of all ${total} leads ${failed} have failed to import and ${duplicates} were identified as duplicates`,
          color: AlertColors.Warning
        });
      } else if (failed) {
        let firstError = errors[ 0 ].error;
        setAlert({
          message: `${failed} out of ${total} leads failed to import "${firstError}"`,
          color: AlertColors.Warning
        });
      } else if (duplicates) {
        setAlert({
          message: `${total} out of ${total} leads were successfully imported and ${duplicates} were identified as duplicates`,
          color: AlertColors.PrimaryLight
        });
      } else {
        setAlert({
          message: `${total} out of ${total} leads were successfully imported`,
          color: AlertColors.Success
        });
      }
    } catch (e) {
      loadingVar(false);
      console.error(e);
      setAlert({
        message: "We are sorry, something went wrong. Request might be timed out. Please try to upload one more time with smaller chunks",
        color: AlertColors.Error
      });
    }
  }
  return (
    <Section title={"Lead import"} justify={"center"} view={"read"} editable={false}>
      {!!alert &&
      <Alert variant={alert.color} width={"100%"} onClose={onAlertClose}>
        <span>{alert.message}</span>
      </Alert>
      }
      <Form onSubmit={onSubmit} subscription={{ submitting: true }}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} style={{ display: "contents" }}>
            <Box container direction={"column"} gap={"XS"} flex={1}>
              <Box container flex={1} gap={"M"}>
                <ChoiceField required={true} flexBasis="30%" label="Provider" name="provider" options={props.options}
                             defaultValue={"RelcuProvider"}/>
                <FileUploadButton label={"Upload CSV"} required={true} onFail={onFail} name={"file"}/>
              </Box>
              <Button type="submit" disabled={submitting} alignSelf={"end"}>UPLOAD</Button>
            </Box>
          </form>
        )}
      </Form>
    </Section>
  );
});

interface FileUploadButtonProps extends BoxComponentProps {
  label: string,
  error?: string,
  onFail?: (e: string) => void,
  validate?: (value: any) => string,
  name: string,
  required: boolean
}

export const FileUploadButton: FC<FileUploadButtonProps> = React.memo(function FileUploadButton(props) {
  return (
    <Box container direction={"column"} gap={"XXXS"} flex={1} {...useBoxProps(props)}>
      <Label>
        {props.label}
      </Label>
      <Box container direction={"column"} gap={"XXS"}>
        <Field name={props.name} validate={props.validate ?? null}>
          {({ input, meta: { touched, error, ...other } }) => {
            return <>
              <label>
                <input
                  className={CommonClasses.WidthEllipsis}
                  required={props.required}
                  type="file"
                  accept={"text/csv"}
                  onChange={(e) => {
                    if (!props.validate && e.target.files[ 0 ]?.size > 104857600) {
                      props.onFail("File is too big!");
                      input.onChange(null);
                      e.target.value = "";
                    } else {
                      input.onChange(e.target.files[ 0 ]);
                    }
                  }}/>
              </label>
              {
                error &&
                <Box alignSelf={"start"} container
                     className={classNames(BaseInputClasses.Message, BaseInputClasses.Error)}>
                  {error}
                </Box>
              }
            </>;
          }}
        </Field>
      </Box>
    </Box>
  );
});
