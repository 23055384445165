import { useEffect }                               from "react";
import { useMemo }                                 from "react";
import { useCallback }                             from "react";
import { useState }                                from "react";
import { useSubscription }                         from "@apollo/client";
import { useMutation }                             from "@apollo/client";
import { useQuery }                                from "@apollo/client";
import { gql }                                     from "@apollo/client";
import { DeepPartial }                             from "@apollo/client/utilities";
import { useSource }                               from "@relcu/ui";
import { Action }                                  from "@relcu/ui";
import { PhoneMessageWhereInput }                  from "../../../../../../types/graphql-global-types";
import { TableColumn }                             from "../../../../../Table";
import { PhoneMessageConversation }                from "../../__types__/PhoneMessageConversation";
import { CancelBulkPhoneMessageVariables }         from "./__types__/CancelBulkPhoneMessage";
import { CancelBulkPhoneMessage }                  from "./__types__/CancelBulkPhoneMessage";
import { GetBulkPhoneMessagesVariables }           from "./__types__/GetBulkPhoneMessages";
import { GetBulkPhoneMessages }                    from "./__types__/GetBulkPhoneMessages";
import { SubscribeBulkPhoneMessagesVariables }     from "./__types__/SubscribeBulkPhoneMessages";
import { SubscribeBulkPhoneMessages }              from "./__types__/SubscribeBulkPhoneMessages";
import { updatePhoneMessageConversationVariables } from "./__types__/updatePhoneMessageConversation";
import { updatePhoneMessageConversation }          from "./__types__/updatePhoneMessageConversation";

export function useBulkContent(conversation: DeepPartial<PhoneMessageConversation>) {
  const perPage = 10;
  const [page, setPage] = useState(1);
  const skip = perPage * (page - 1);
  const [status, setStatus] = useState(null);
  const { $viewer, $object } = useSource();
  const lastSender = conversation.participants.find(p => p.party.__typename == "User");
  const onBehalf = !conversation.participants.find(p => p.party.objectId == $object.objectId);
  const [markAsRead] = useMutation<updatePhoneMessageConversation, updatePhoneMessageConversationVariables>(UPDATE_CONVERSATION_AS_READ);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    if (!onBehalf && conversation.unread && $viewer.objectId === $object.objectId) {
      markAsRead({ variables: { id: conversation.objectId } });
    }
  }, [conversation.unread, $viewer.objectId, $object.objectId]);
  const columns = useMemo(() => {
    const columns: TableColumn[] = [
      {
        title: "Name",
        key: "scope",
        width: 300,
        minWidth: 200,
        resizable: false,
        visible: true,
        component: "AvatarCell"
      },
      {
        title: "Status",
        key: "status",
        width: 100,
        minWidth: 100,
        resizable: false,
        visible: true,
        component: "StatusCell"
      },
      {
        title: "Details",
        key: "errorCode",
        width: 400,
        minWidth: 400,
        resizable: false,
        visible: true,
        component: "TextCell"
      }
    ];
    if (!onBehalf && $object.role !== "loan_officer") {
      columns.push({
        title: "AssignedTo",
        key: "scope.assignedTo",
        width: 300,
        minWidth: 200,
        resizable: false,
        visible: true,
        component: "AvatarCell"
      });
    }
    return columns;
  }, [onBehalf]);

  useEffect(() => {
    setPage(1);
    setStatus(null);
    setSelectedItem(null);
  }, [conversation.objectId]);

  const actions: Action[] = [{
    type: "SeePhoneMessage",
    width: 200,
    onClick: (event, data) => {
      const cursor = edges.find(({ node }) => node.objectId === data.objectId).cursor;
      setSelectedItem({ data, cursor });
    }
  }];

  const variables = useMemo(() => {
    let where: PhoneMessageWhereInput = { bulkConversation: { have: { id: { equalTo: conversation.id } } } };
    if (onBehalf) {
      where.participants = { have: { party: { have: { link: $object.id } } } };
    }
    if (status) {
      switch (status) {
        case "completed":
          where = {
            ...where,
            status: { in: ["sent", "processed", "delivered"] }
          };
          break;
        case "failed":
          where = {
            ...where,
            status: { in: ["failed"] }
          };
          break;
        default:
          where = {
            ...where,
            status: { equalTo: status }
          };
      }
    }
    return {
      first: perPage,
      skip: skip,
      where: where
    };
  }, [perPage, page, skip, status, conversation.id]);
  const { data: { phoneMessages: { edges = [], count } = {} } = {}, loading, refetch, fetchMore, subscribeToMore } = useQuery<GetBulkPhoneMessages, GetBulkPhoneMessagesVariables>(GET_BULK_PHONE_MESSAGES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables
  });
  useSubscription<SubscribeBulkPhoneMessages, SubscribeBulkPhoneMessagesVariables>(SUBSCRIBE_BULK_PHONE_MESSAGES, {
    variables: { where: { bulkConversation: { have: { link: conversation.id } } } },
    onData: () => {
      refetch();
    }
  });

  const slide = useCallback((path) => {
    let newVariables = null;
    if (!path) {
      setSelectedItem(null);
      fetchMore({ variables, query: GET_BULK_PHONE_MORE_MESSAGES });
    }

    if (path === "after") {
      newVariables = {
        first: 1,
        after: selectedItem.cursor,
        where: variables.where
      };
    } else if (path === "before") {
      newVariables = {
        last: 1,
        before: selectedItem.cursor,
        where: variables.where
      };
    }
    if (newVariables) {
      fetchMore({
        variables: newVariables,
        query: GET_BULK_PHONE_MORE_MESSAGES
      }).then(({ data: { phoneMessages: { edges = [] } = {} } = {} }) => {
        if (edges.length) {
          const phoneMessages = edges[ 0 ];
          setSelectedItem({ data: phoneMessages.node, cursor: phoneMessages.cursor });
        }
      });
    }
  }, [selectedItem, variables.where]);
  const onPage = useCallback((page) => {
    setPage(page);
  }, []);
  const onFilter = useCallback((status) => {
    setPage(1);
    setStatus(status);
  }, []);
  const [cancel, { loading: cancelLoading }] = useMutation<CancelBulkPhoneMessage, CancelBulkPhoneMessageVariables>(CANCEL_BULK_PHONE_MESSAGES);

  const onCancelAll = useCallback(async () => {
    await cancel({ variables: { bulkId: conversation.objectId } });
    await refetch();
  }, []);

  useEffect(() => {
    if (status === "queued" && Math.ceil(count / perPage) < page && edges.length === 0 && page > 1) {
      setPage(Math.floor(count / perPage));
    }
  }, [count, status, page, perPage]);
  const stats = useMemo(() => {
    if (onBehalf) {
      const userStats = conversation.bulkStatsPerUser.find(s => s.user.objectId == $object.objectId);
      return userStats.stats;
    } else {
      return conversation.bulkStats;
    }
  }, [conversation.bulkStatsPerUser, $object, onBehalf, conversation.bulkStats]);

  return {
    skip,
    onBehalf,
    cancelLoading,
    lastSender,
    onCancelAll,
    loading,
    actions,
    columns,
    onFilter,
    status,
    onPage,
    count,
    page,
    slide,
    selectedItem,
    setSelectedItem,
    stats,
    get pageCount() {
      return Math.ceil(count / perPage);
    },
    phoneMessages: edges.map(({ node }) => node),
    bulkConversation: conversation
  };
}
const CANCEL_BULK_PHONE_MESSAGES = gql`
  mutation CancelBulkPhoneMessage($bulkId:String!,$conversationId:String) {
    cancelBulkPhoneMessage(bulkId: $bulkId,conversationId: $conversationId){
      id
      objectId
    }
  }
`;

const GET_BULK_PHONE_MORE_MESSAGES = gql`
  query GetBulkMorePhoneMessages($where:PhoneMessageWhereInput!,$skip:Int, $after:String, $before:String, $last:Int, $first:Int){
    phoneMessages(
      order: [createdAt_DESC, objectId_ASC]
      where: $where,
      first: $first,
      skip: $skip,
      after:$after,
      before:$before,
      last:$last
    ) {
      edges {
        cursor
        node {
          id
          status
          unread
          attachments {
            name
            url
          }
          scope {
            ...on Node {
              id
            }
            ... on Document {
              objectId
              objectName
            }
            ...on Lead {
              assignedTo {
                objectId
                objectIcon
                objectName
              }  
            }
          }
          participants {
            type
            party {
              ...on Contact {
                id
                objectId
                objectName
              }
              ...on User {
                id
                objectId
                objectName
              }
            }
          }
          objectId
          content
          status
          errorCode
        }
      }
    }
  }
`;

const GET_BULK_PHONE_MESSAGES = gql`
  query GetBulkPhoneMessages($where:PhoneMessageWhereInput!,$skip:Int, $after:String, $before:String, $last:Int, $first:Int){
    phoneMessages(
      order: [createdAt_DESC, objectId_ASC]
      where: $where,
      first: $first,
      skip: $skip,
      after:$after,
      before:$before,
      last:$last
    ) {
      count
      edges {
        cursor
        node {
          id
          unread
          scope {
            ...on Node {
              id
            }
            ... on Document {
              objectId
              objectName
            }
            ...on Lead {
              assignedTo {
                objectId
                objectIcon
                objectName
              }
            }
          }
          participants {
            type
            party {
              ...on Contact {
                id
                objectId
                objectName
              }
              ...on User {
                id
                objectId
                objectName
              }
            }
          }
          objectId
          content
          status
          attachments {
            name
            url
          }
          errorCode
        }
      }
    }
  }
`;

const SUBSCRIBE_BULK_PHONE_MESSAGES = gql`
  subscription SubscribeBulkPhoneMessages($where:PhoneMessageSubscriptionWhereInput!){
    phoneMessages(
      where: $where,
    ){
      event
      node {
        id
        status
        scope {
          ...on Node {
            id
          }
          ... on Document {
            objectId
            objectName  
          }
        }
        participants {
          type
          party {
            ...on Contact {
              id
              objectId
              objectName
            }
            ...on User {
              id
              objectId
              objectName
            }
          }
        }
        objectId
        content
        status
        errorCode
      }
    }
  }
`;

const UPDATE_CONVERSATION_AS_READ = gql`
  mutation updatePhoneMessageConversation($id:ID!){
    updatePhoneMessageConversation(input: {id: $id,fields: {unread: false}}){
      phoneMessageConversation {
        id
        objectId
        unread
      }
    }
  }
`;
