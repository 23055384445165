import { classNames }                             from "@relcu/ui";
import { CircularLoader }                         from "@relcu/ui";
import { BoxComponentProps }                      from "@relcu/ui";
import { EmptyList }                              from "@relcu/ui";
import React                                      from "react";
import { FC }                                     from "react";
import { AppraisalFee }                           from "../__types__/AppraisalFee";
import { MortechPricing_mortechPricing_products } from "../__types__/MortechPricing";
import { StateBasedFee }                          from "../__types__/StateBasedFee";
import { TitleFee }                               from "../__types__/TitleFee";
import { PersistProps }                           from "./PersistProvider";
import { PersistProvider }                        from "./PersistProvider";
import { RatesProvider }                          from "./RatesProvider";
import "./results.css";
import { ResultsClasses }                         from "./ResultsClasses";

export interface TitleFees {
  result: TitleFee[];
  loading: boolean;
  refetch: (v?) => void;
  error?: any;
}
export interface ResultsProps extends BoxComponentProps, PersistProps {
  pmi?: {
    isPmiEligible: boolean
    result: Record<string, any>
    createPmiInput: (l: Partial<any>) => any
    loading: boolean
    error: string
    refetch: (v?) => void
  },
  titleFeeResult?: TitleFees & { createTitleFeeInput: (l: Partial<any>) => any, update: (providerId: string, f: { [ k: string ]: number }) => void }
  rates?: {
    data: (MortechPricing_mortechPricing_products | null)[] | null
    loading: boolean
  }
  stateBasedFee: StateBasedFee
  appraisalFees: AppraisalFee[]
  criteriaData?: any
}

export const Results: FC<ResultsProps> = React.memo(
  function Results(props) {
    const { pmi, rates, stateBasedFee, appraisalFees, offers, titleFeeResult, criteriaData, ...p } = props;
    const isLoading = rates?.loading;
    const isEmpty = !rates?.data?.length;
    const classes = classNames(ResultsClasses.Result, {
      [ ResultsClasses.ResultEmpty ]: isEmpty,
      [ ResultsClasses.ResultLoading ]: isLoading
    });
    // container direction={"column"} flexGrow={1} gap={"XXXS"} {...p}
    return <div className={classes}>
      <PersistProvider offers={offers} criteriaData={criteriaData}>
        {
          isLoading
            ? <ResultsLoader/>
            : isEmpty
              ? <ResultsEmpty/>
              : <RatesProvider
                titleFeeResult={titleFeeResult}
                appraisalFees={appraisalFees}
                stateBasedFee={stateBasedFee}
                pmi={pmi}
                rates={rates}
              />
        }
      </PersistProvider>
    </div>;
  }
);

function ResultsLoader() {
  return <CircularLoader/>;
}
function ResultsEmpty() {
  return <EmptyList
    icon={"rc_price"}
    content={"Please adjust the provided information and click on 'Get rates' to see results."}
    title={"No rate results"}
    alignSelf={"center"}
  />;
}
