//      
const keysCache = {};
const keysRegex = /[.[\]]+/;

const toPath = key => {
    if (key === null || key === undefined || !key.length) {
        return [];
    }

    if (typeof key !== 'string') {
        throw new Error('toPath() expects a string');
    }

    if (keysCache[key] == null) {
        keysCache[key] = key.split(keysRegex).filter(Boolean);
    }

    return keysCache[key];
};

//      

const getIn = (state, complexKey) => {
    // Intentionally using iteration rather than recursion
    const path = toPath(complexKey);
    let current = state;

    for (let i = 0; i < path.length; i++) {
        const key = path[i];

        if (current === undefined || current === null || typeof current !== 'object' || Array.isArray(current) && isNaN(key)) {
            return undefined;
        }

        current = current[key];
    }

    return current;
};

//      

const setInRecursor = (current, index, path, value, destroyArrays) => {
    if (index >= path.length) {
        // end of recursion
        return value;
    }

    const key = path[index]; // determine type of key

    if (isNaN(key)) {
        // object set
        if (current === undefined || current === null) {
            // recurse
            const result = setInRecursor(undefined, index + 1, path, value, destroyArrays); // delete or create an object

            return result === undefined ? undefined : {
                [key]: result
            };
        }

        if (Array.isArray(current)) {
            throw new Error('Cannot set a non-numeric property on an array');
        } // current exists, so make a copy of all its values, and add/update the new one


        const result = setInRecursor(current[key], index + 1, path, value, destroyArrays);

        if (result === undefined) {
            const numKeys = Object.keys(current).length;

            if (current[key] === undefined && numKeys === 0) {
                // object was already empty
                return undefined;
            }

            if (current[key] !== undefined && numKeys <= 1) {
                // only key we had was the one we are deleting
                if (!isNaN(path[index - 1]) && !destroyArrays) {
                    // we are in an array, so return an empty object
                    return {};
                } else {
                    return undefined;
                }
            }

            const {
                [key]: _removed,
                ...final
            } = current;
            return final;
        } // set result in key


        return { ...current,
            [key]: result
        };
    } // array set


    const numericKey = Number(key);

    if (current === undefined || current === null) {
        // recurse
        const result = setInRecursor(undefined, index + 1, path, value, destroyArrays); // if nothing returned, delete it

        if (result === undefined) {
            return undefined;
        } // create an array


        const array = [];
        array[numericKey] = result;
        return array;
    }

    if (!Array.isArray(current)) {
        throw new Error('Cannot set a numeric property on an object');
    } // recurse


    const existingValue = current[numericKey];
    const result = setInRecursor(existingValue, index + 1, path, value, destroyArrays); // current exists, so make a copy of all its values, and add/update the new one

    const array = [...current];

    if (destroyArrays && result === undefined) {
        array.splice(numericKey, 1);

        if (array.length === 0) {
            return undefined;
        }
    } else {
        array[numericKey] = result;
    }

    return array;
};

const setIn = (state, key, value, destroyArrays = false) => {
    if (state === undefined || state === null) {
        throw new Error(`Cannot call setIn() with ${String(state)} state`);
    }

    if (key === undefined || key === null) {
        throw new Error(`Cannot call setIn() with ${String(key)} key`);
    } // Recursive function needs to accept and return State, but public API should
    // only deal with Objects


    return setInRecursor(state, 0, toPath(key), value, destroyArrays);
};

const FORM_ERROR = 'FINAL_FORM/form-error';
const ARRAY_ERROR = 'FINAL_FORM/array-error';

//      
/**
 * Converts internal field state to published field state
 */

function publishFieldState(formState, field) {
    const {
        errors,
        initialValues,
        lastSubmittedValues,
        submitErrors,
        submitFailed,
        submitSucceeded,
        submitting,
        values
    } = formState;
    const {
        active,
        blur,
        change,
        data,
        focus,
        modified,
        modifiedSinceLastSubmit,
        name,
        touched,
        validating,
        visited
    } = field;
    const value = getIn(values, name);
    let error = getIn(errors, name);

    if (error && error[ARRAY_ERROR]) {
        error = error[ARRAY_ERROR];
    }

    const submitError = submitErrors && getIn(submitErrors, name);
    const initial = initialValues && getIn(initialValues, name);
    const pristine = field.isEqual(initial, value);
    const dirtySinceLastSubmit = !!(lastSubmittedValues && !field.isEqual(getIn(lastSubmittedValues, name), value));
    const valid = !error && !submitError;
    return {
        active,
        blur,
        change,
        data,
        dirty: !pristine,
        dirtySinceLastSubmit,
        error,
        focus,
        initial,
        invalid: !valid,
        length: Array.isArray(value) ? value.length : undefined,
        modified,
        modifiedSinceLastSubmit,
        name,
        pristine,
        submitError,
        submitFailed,
        submitSucceeded,
        submitting,
        touched,
        valid,
        value,
        visited,
        validating
    };
}

//      
var fieldSubscriptionItems = ['active', 'data', 'dirty', 'dirtySinceLastSubmit', 'error', 'initial', 'invalid', 'length', 'modified', 'modifiedSinceLastSubmit', 'pristine', 'submitError', 'submitFailed', 'submitSucceeded', 'submitting', 'touched', 'valid', 'value', 'visited', 'validating'];

//      
const shallowEqual = (a, b) => {
    if (a === b) {
        return true;
    }

    if (typeof a !== 'object' || !a || typeof b !== 'object' || !b) {
        return false;
    }

    var keysA = Object.keys(a);
    var keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
        return false;
    }

    var bHasOwnProperty = Object.prototype.hasOwnProperty.bind(b);

    for (var idx = 0; idx < keysA.length; idx++) {
        var key = keysA[idx];

        if (!bHasOwnProperty(key) || a[key] !== b[key]) {
            return false;
        }
    }

    return true;
};

//      
function subscriptionFilter (dest, src, previous, subscription, keys, shallowEqualKeys) {
    let different = false;
    keys.forEach(key => {
        if (subscription[key]) {
            dest[key] = src[key];

            if (!previous || (~shallowEqualKeys.indexOf(key) ? !shallowEqual(src[key], previous[key]) : src[key] !== previous[key])) {
                different = true;
            }
        }
    });
    return different;
}

//      
const shallowEqualKeys = ['data'];
/**
 * Filters items in a FieldState based on a FieldSubscription
 */

const filterFieldState = (state, previousState, subscription, force) => {
    const result = {
        blur: state.blur,
        change: state.change,
        focus: state.focus,
        name: state.name
    };
    const different = subscriptionFilter(result, state, previousState, subscription, fieldSubscriptionItems, shallowEqualKeys) || !previousState;
    return different || force ? result : undefined;
};

//      
var formSubscriptionItems = ['active', 'dirty', 'dirtyFields', 'dirtyFieldsSinceLastSubmit', 'dirtySinceLastSubmit', 'error', 'errors', 'hasSubmitErrors', 'hasValidationErrors', 'initialValues', 'invalid', 'modified', 'modifiedSinceLastSubmit', 'pristine', 'submitting', 'submitError', 'submitErrors', 'submitFailed', 'submitSucceeded', 'touched', 'valid', 'validating', 'values', 'visited'];

//      
const shallowEqualKeys$1 = ['touched', 'visited'];
/**
 * Filters items in a FormState based on a FormSubscription
 */

function filterFormState(state, previousState, subscription, force) {
    const result = {};
    const different = subscriptionFilter(result, state, previousState, subscription, formSubscriptionItems, shallowEqualKeys$1) || !previousState;
    return different || force ? result : undefined;
}

//      

const memoize = fn => {
    let lastArgs;
    let lastResult;
    return (...args) => {
        if (!lastArgs || args.length !== lastArgs.length || args.some((arg, index) => !shallowEqual(lastArgs[index], arg))) {
            lastArgs = args;
            lastResult = fn(...args);
        }

        return lastResult;
    };
};

var isPromise = (obj => !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function');

const ffVersion = "4.20.0";

//      
const configOptions = ['debug', 'initialValues', 'keepDirtyOnReinitialize', 'mutators', 'onSubmit', 'validate', 'validateOnBlur'];

const tripleEquals = (a, b) => a === b;

const hasAnyError = errors => {
    return Object.keys(errors).some(key => {
        const value = errors[key];

        if (value && typeof value === 'object' && !(value instanceof Error)) {
            return hasAnyError(value);
        }

        return typeof value !== 'undefined';
    });
};

function convertToExternalFormState({
                                        // kind of silly, but it ensures type safety ¯\_(ツ)_/¯
                                        active,
                                        dirtySinceLastSubmit,
                                        modifiedSinceLastSubmit,
                                        error,
                                        errors,
                                        initialValues,
                                        pristine,
                                        submitting,
                                        submitFailed,
                                        submitSucceeded,
                                        submitError,
                                        submitErrors,
                                        valid,
                                        validating,
                                        values
                                    }) {
    return {
        active,
        dirty: !pristine,
        dirtySinceLastSubmit,
        modifiedSinceLastSubmit,
        error,
        errors,
        hasSubmitErrors: !!(submitError || submitErrors && hasAnyError(submitErrors)),
        hasValidationErrors: !!(error || hasAnyError(errors)),
        invalid: !valid,
        initialValues,
        pristine,
        submitting,
        submitFailed,
        submitSucceeded,
        submitError,
        submitErrors,
        valid,
        validating: validating > 0,
        values
    };
}

function notifySubscriber(subscriber, subscription, state, lastState, filter, force) {
    const notification = filter(state, lastState, subscription, force);

    if (notification) {
        subscriber(notification);
        return true;
    }

    return false;
}

function notify({
                    entries
                }, state, lastState, filter, force) {
    Object.keys(entries).forEach(key => {
        const entry = entries[Number(key)]; // istanbul ignore next

        if (entry) {
            const {
                subscription,
                subscriber,
                notified
            } = entry;

            if (notifySubscriber(subscriber, subscription, state, lastState, filter, force || !notified)) {
                entry.notified = true;
            }
        }
    });
}

function createForm(config) {
    if (!config) {
        throw new Error('No config specified');
    }

    let {
        debug,
        destroyOnUnregister,
        keepDirtyOnReinitialize,
        initialValues,
        mutators,
        onSubmit,
        validate,
        validateOnBlur
    } = config;

    if (!onSubmit) {
        throw new Error('No onSubmit function specified');
    }

    const state = {
        subscribers: {
            index: 0,
            entries: {}
        },
        fieldSubscribers: {},
        fields: {},
        formState: {
            dirtySinceLastSubmit: false,
            modifiedSinceLastSubmit: false,
            errors: {},
            initialValues: initialValues && { ...initialValues
            },
            invalid: false,
            pristine: true,
            submitting: false,
            submitFailed: false,
            submitSucceeded: false,
            valid: true,
            validating: 0,
            values: initialValues ? { ...initialValues
            } : {}
        },
        lastFormState: undefined
    };
    let inBatch = 0;
    let validationPaused = false;
    let validationBlocked = false;
    let nextAsyncValidationKey = 0;
    const asyncValidationPromises = {};

    const clearAsyncValidationPromise = key => result => {
        delete asyncValidationPromises[key];
        return result;
    };

    const changeValue = (state, name, mutate) => {
        const before = getIn(state.formState.values, name);
        const after = mutate(before);
        state.formState.values = setIn(state.formState.values, name, after) || {};
    };

    const renameField = (state, from, to) => {
        if (state.fields[from]) {
            state.fields = { ...state.fields,
                [to]: { ...state.fields[from],
                    name: to,
                    // rebind event handlers
                    blur: () => api.blur(to),
                    change: value => api.change(to, value),
                    focus: () => api.focus(to),
                    lastFieldState: undefined
                }
            };
            delete state.fields[from];
            state.fieldSubscribers = { ...state.fieldSubscribers,
                [to]: state.fieldSubscribers[from]
            };
            delete state.fieldSubscribers[from];
            const value = getIn(state.formState.values, from);
            state.formState.values = setIn(state.formState.values, from, undefined) || {};
            state.formState.values = setIn(state.formState.values, to, value);
            delete state.lastFormState;
        }
    }; // bind state to mutators


    const getMutatorApi = key => (...args) => {
        // istanbul ignore next
        if (mutators) {
            // ^^ causes branch coverage warning, but needed to appease the Flow gods
            const mutatableState = {
                formState: state.formState,
                fields: state.fields,
                fieldSubscribers: state.fieldSubscribers,
                lastFormState: state.lastFormState
            };
            const returnValue = mutators[key](args, mutatableState, {
                changeValue,
                getIn,
                renameField,
                resetFieldState: api.resetFieldState,
                setIn,
                shallowEqual
            });
            state.formState = mutatableState.formState;
            state.fields = mutatableState.fields;
            state.fieldSubscribers = mutatableState.fieldSubscribers;
            state.lastFormState = mutatableState.lastFormState;
            runValidation(undefined, () => {
                notifyFieldListeners();
                notifyFormListeners();
            });
            return returnValue;
        }
    };

    const mutatorsApi = mutators ? Object.keys(mutators).reduce((result, key) => {
        result[key] = getMutatorApi(key);
        return result;
    }, {}) : {};

    const runRecordLevelValidation = setErrors => {
        const promises = [];

        if (validate) {
            const errorsOrPromise = validate({ ...state.formState.values
            }); // clone to avoid writing

            if (isPromise(errorsOrPromise)) {
                promises.push(errorsOrPromise.then(setErrors));
            } else {
                setErrors(errorsOrPromise);
            }
        }

        return promises;
    };

    const getValidators = field => Object.keys(field.validators).reduce((result, index) => {
        const validator = field.validators[Number(index)]();

        if (validator) {
            result.push(validator);
        }

        return result;
    }, []);

    const runFieldLevelValidation = (field, setError) => {
        const promises = [];
        const validators = getValidators(field);

        if (validators.length) {
            let error;
            validators.forEach(validator => {
                const errorOrPromise = validator(getIn(state.formState.values, field.name), state.formState.values, validator.length === 0 || validator.length === 3 ? publishFieldState(state.formState, state.fields[field.name]) : undefined);

                if (errorOrPromise && isPromise(errorOrPromise)) {
                    field.validating = true;
                    const promise = errorOrPromise.then(error => {
                        field.validating = false;
                        setError(error);
                    }); // errors must be resolved, not rejected

                    promises.push(promise);
                } else if (!error) {
                    // first registered validator wins
                    error = errorOrPromise;
                }
            });
            setError(error);
        }

        return promises;
    };

    const runValidation = (fieldChanged, callback) => {
        if (validationPaused) {
            validationBlocked = true;
            callback();
            return;
        }

        const {
            fields,
            formState
        } = state;
        const safeFields = { ...fields
        };
        let fieldKeys = Object.keys(safeFields);

        if (!validate && !fieldKeys.some(key => getValidators(safeFields[key]).length)) {
            callback();
            return; // no validation rules
        } // pare down field keys to actually validate


        let limitedFieldLevelValidation = false;

        if (fieldChanged) {
            const changedField = safeFields[fieldChanged];

            if (changedField) {
                const {
                    validateFields
                } = changedField;

                if (validateFields) {
                    limitedFieldLevelValidation = true;
                    fieldKeys = validateFields.length ? validateFields.concat(fieldChanged) : [fieldChanged];
                }
            }
        }

        let recordLevelErrors = {};
        const fieldLevelErrors = {};
        const promises = [...runRecordLevelValidation(errors => {
            recordLevelErrors = errors || {};
        }), ...fieldKeys.reduce((result, name) => result.concat(runFieldLevelValidation(fields[name], error => {
            fieldLevelErrors[name] = error;
        })), [])];
        const hasAsyncValidations = promises.length > 0;
        const asyncValidationPromiseKey = ++nextAsyncValidationKey;
        const promise = Promise.all(promises).then(clearAsyncValidationPromise(asyncValidationPromiseKey)); // backwards-compat: add promise to submit-blocking promises iff there are any promises to await

        if (hasAsyncValidations) {
            asyncValidationPromises[asyncValidationPromiseKey] = promise;
        }

        const processErrors = () => {
            let merged = { ...(limitedFieldLevelValidation ? formState.errors : {}),
                ...recordLevelErrors
            };

            const forEachError = fn => {
                fieldKeys.forEach(name => {
                    if (fields[name]) {
                        // make sure field is still registered
                        // field-level errors take precedent over record-level errors
                        const recordLevelError = getIn(recordLevelErrors, name);
                        const errorFromParent = getIn(merged, name);
                        const hasFieldLevelValidation = getValidators(safeFields[name]).length;
                        const fieldLevelError = fieldLevelErrors[name];
                        fn(name, hasFieldLevelValidation && fieldLevelError || validate && recordLevelError || (!recordLevelError && !limitedFieldLevelValidation ? errorFromParent : undefined));
                    }
                });
            };

            forEachError((name, error) => {
                merged = setIn(merged, name, error) || {};
            });
            forEachError((name, error) => {
                if (error && error[ARRAY_ERROR]) {
                    const existing = getIn(merged, name);
                    const copy = [...existing];
                    copy[ARRAY_ERROR] = error[ARRAY_ERROR];
                    merged = setIn(merged, name, copy);
                }
            });

            if (!shallowEqual(formState.errors, merged)) {
                formState.errors = merged;
            }

            formState.error = recordLevelErrors[FORM_ERROR];
        }; // process sync errors


        processErrors(); // sync errors have been set. notify listeners while we wait for others

        callback();

        if (hasAsyncValidations) {
            state.formState.validating++;
            callback();

            const afterPromise = () => {
                state.formState.validating--;
                callback();
            };

            promise.then(() => {
                if (nextAsyncValidationKey > asyncValidationPromiseKey) {
                    // if this async validator has been superseded by another, ignore its results
                    return;
                }

                processErrors();
            }).then(afterPromise, afterPromise);
        }
    };

    const notifyFieldListeners = name => {
        if (inBatch) {
            return;
        }

        const {
            fields,
            fieldSubscribers,
            formState
        } = state;
        const safeFields = { ...fields
        };

        const notifyField = name => {
            const field = safeFields[name];
            const fieldState = publishFieldState(formState, field);
            const {
                lastFieldState
            } = field;
            field.lastFieldState = fieldState;
            const fieldSubscriber = fieldSubscribers[name];

            if (fieldSubscriber) {
                notify(fieldSubscriber, fieldState, lastFieldState, filterFieldState, lastFieldState === undefined);
            }
        };

        if (name) {
            notifyField(name);
        } else {
            Object.keys(safeFields).forEach(notifyField);
        }
    };

    const markAllFieldsTouched = () => {
        Object.keys(state.fields).forEach(key => {
            state.fields[key].touched = true;
        });
    };

    const hasSyncErrors = () => !!(state.formState.error || hasAnyError(state.formState.errors));

    const calculateNextFormState = () => {
        const {
            fields,
            formState,
            lastFormState
        } = state;
        const safeFields = { ...fields
        };
        const safeFieldKeys = Object.keys(safeFields); // calculate dirty/pristine

        let foundDirty = false;
        const dirtyFields = safeFieldKeys.reduce((result, key) => {
            const dirty = !safeFields[key].isEqual(getIn(formState.values, key), getIn(formState.initialValues || {}, key));

            if (dirty) {
                foundDirty = true;
                result[key] = true;
            }

            return result;
        }, {});
        const dirtyFieldsSinceLastSubmit = safeFieldKeys.reduce((result, key) => {
            // istanbul ignore next
            const nonNullLastSubmittedValues = formState.lastSubmittedValues || {}; // || {} is for flow, but causes branch coverage complaint

            if (!safeFields[key].isEqual(getIn(formState.values, key), getIn(nonNullLastSubmittedValues, key))) {
                result[key] = true;
            }

            return result;
        }, {});
        formState.pristine = !foundDirty;
        formState.dirtySinceLastSubmit = !!(formState.lastSubmittedValues && Object.values(dirtyFieldsSinceLastSubmit).some(value => value));
        formState.modifiedSinceLastSubmit = !!(formState.lastSubmittedValues && // Object.values would treat values as mixed (facebook/flow#2221)
            Object.keys(safeFields).some(value => safeFields[value].modifiedSinceLastSubmit));
        formState.valid = !formState.error && !formState.submitError && !hasAnyError(formState.errors) && !(formState.submitErrors && hasAnyError(formState.submitErrors));
        const nextFormState = convertToExternalFormState(formState);
        const {
            modified,
            touched,
            visited
        } = safeFieldKeys.reduce((result, key) => {
            result.modified[key] = safeFields[key].modified;
            result.touched[key] = safeFields[key].touched;
            result.visited[key] = safeFields[key].visited;
            return result;
        }, {
            modified: {},
            touched: {},
            visited: {}
        });
        nextFormState.dirtyFields = lastFormState && shallowEqual(lastFormState.dirtyFields, dirtyFields) ? lastFormState.dirtyFields : dirtyFields;
        nextFormState.dirtyFieldsSinceLastSubmit = lastFormState && shallowEqual(lastFormState.dirtyFieldsSinceLastSubmit, dirtyFieldsSinceLastSubmit) ? lastFormState.dirtyFieldsSinceLastSubmit : dirtyFieldsSinceLastSubmit;
        nextFormState.modified = lastFormState && shallowEqual(lastFormState.modified, modified) ? lastFormState.modified : modified;
        nextFormState.touched = lastFormState && shallowEqual(lastFormState.touched, touched) ? lastFormState.touched : touched;
        nextFormState.visited = lastFormState && shallowEqual(lastFormState.visited, visited) ? lastFormState.visited : visited;
        return lastFormState && shallowEqual(lastFormState, nextFormState) ? lastFormState : nextFormState;
    };

    const callDebug = () => debug && "development" !== 'production' && debug(calculateNextFormState(), Object.keys(state.fields).reduce((result, key) => {
        result[key] = state.fields[key];
        return result;
    }, {}));

    let notifying = false;
    let scheduleNotification = false;

    const notifyFormListeners = () => {
        if (notifying) {
            scheduleNotification = true;
        } else {
            notifying = true;
            callDebug();

            if (!inBatch && !validationPaused) {
                const {
                    lastFormState
                } = state;
                const nextFormState = calculateNextFormState();

                if (nextFormState !== lastFormState) {
                    state.lastFormState = nextFormState;
                    notify(state.subscribers, nextFormState, lastFormState, filterFormState);
                }
            }

            notifying = false;

            if (scheduleNotification) {
                scheduleNotification = false;
                notifyFormListeners();
            }
        }
    };

    const beforeSubmit = () => Object.keys(state.fields).some(name => state.fields[name].beforeSubmit && state.fields[name].beforeSubmit() === false);

    const afterSubmit = () => Object.keys(state.fields).forEach(name => state.fields[name].afterSubmit && state.fields[name].afterSubmit());

    const resetModifiedAfterSubmit = () => Object.keys(state.fields).forEach(key => state.fields[key].modifiedSinceLastSubmit = false); // generate initial errors


    runValidation(undefined, () => {
        notifyFormListeners();
    });
    const api = {
        batch: fn => {
            inBatch++;
            fn();
            inBatch--;
            notifyFieldListeners();
            notifyFormListeners();
        },
        blur: name => {
            const {
                fields,
                formState
            } = state;
            const previous = fields[name];

            if (previous) {
                // can only blur registered fields
                delete formState.active;
                fields[name] = { ...previous,
                    active: false,
                    touched: true
                };

                if (validateOnBlur) {
                    runValidation(name, () => {
                        notifyFieldListeners();
                        notifyFormListeners();
                    });
                } else {
                    notifyFieldListeners();
                    notifyFormListeners();
                }
            }
        },
        change: (name, value) => {
            const {
                fields,
                formState
            } = state;

            if (getIn(formState.values, name) !== value) {
                changeValue(state, name, () => value);
                const previous = fields[name];

                if (previous) {
                    // only track modified for registered fields
                    fields[name] = { ...previous,
                        modified: true,
                        modifiedSinceLastSubmit: !!formState.lastSubmittedValues
                    };
                }

                if (validateOnBlur) {
                    notifyFieldListeners();
                    notifyFormListeners();
                } else {
                    runValidation(name, () => {
                        notifyFieldListeners();
                        notifyFormListeners();
                    });
                }
            }
        },

        get destroyOnUnregister() {
            return !!destroyOnUnregister;
        },

        set destroyOnUnregister(value) {
            destroyOnUnregister = value;
        },

        focus: name => {
            const field = state.fields[name];

            if (field && !field.active) {
                state.formState.active = name;
                field.active = true;
                field.visited = true;
                notifyFieldListeners();
                notifyFormListeners();
            }
        },
        mutators: mutatorsApi,
        getFieldState: name => {
            const field = state.fields[name];
            return field && field.lastFieldState;
        },
        getRegisteredFields: () => Object.keys(state.fields),
        getState: () => calculateNextFormState(),
        initialize: data => {
            const {
                fields,
                formState
            } = state;
            const safeFields = { ...fields
            };
            const values = typeof data === 'function' ? data(formState.values) : data;

            if (!keepDirtyOnReinitialize) {
                formState.values = values;
            }
            /**
             * Hello, inquisitive code reader! Thanks for taking the time to dig in!
             *
             * The following code is the way it is to allow for non-registered deep
             * field values to be set via initialize()
             */
                // save dirty values


            const savedDirtyValues = keepDirtyOnReinitialize ? Object.keys(safeFields).reduce((result, key) => {
                    const field = safeFields[key];
                    const pristine = field.isEqual(getIn(formState.values, key), getIn(formState.initialValues || {}, key));

                    if (!pristine) {
                        result[key] = getIn(formState.values, key);
                    }

                    return result;
                }, {}) : {}; // update initalValues and values

            formState.initialValues = values;
            formState.values = values; // restore the dirty values

            Object.keys(savedDirtyValues).forEach(key => {
                formState.values = setIn(formState.values, key, savedDirtyValues[key]);
            });
            runValidation(undefined, () => {
                notifyFieldListeners();
                notifyFormListeners();
            });
        },
        isValidationPaused: () => validationPaused,
        pauseValidation: () => {
            validationPaused = true;
        },
        registerField: (name, subscriber, subscription = {}, fieldConfig) => {
            if (!state.fieldSubscribers[name]) {
                state.fieldSubscribers[name] = {
                    index: 0,
                    entries: {}
                };
            }

            const index = state.fieldSubscribers[name].index++; // save field subscriber callback

            state.fieldSubscribers[name].entries[index] = {
                subscriber: memoize(subscriber),
                subscription,
                notified: false
            };

            if (!state.fields[name]) {
                // create initial field state
                state.fields[name] = {
                    active: false,
                    afterSubmit: fieldConfig && fieldConfig.afterSubmit,
                    beforeSubmit: fieldConfig && fieldConfig.beforeSubmit,
                    blur: () => api.blur(name),
                    change: value => api.change(name, value),
                    data: fieldConfig && fieldConfig.data || {},
                    focus: () => api.focus(name),
                    isEqual: fieldConfig && fieldConfig.isEqual || tripleEquals,
                    lastFieldState: undefined,
                    modified: false,
                    modifiedSinceLastSubmit: false,
                    name,
                    touched: false,
                    valid: true,
                    validateFields: fieldConfig && fieldConfig.validateFields,
                    validators: {},
                    validating: false,
                    visited: false
                };
            }

            let haveValidator = false;
            const silent = fieldConfig && fieldConfig.silent;

            const notify = () => {
                if (silent) {
                    notifyFieldListeners(name);
                } else {
                    notifyFormListeners();
                    notifyFieldListeners();
                }
            };

            if (fieldConfig) {
                haveValidator = !!(fieldConfig.getValidator && fieldConfig.getValidator());

                if (fieldConfig.getValidator) {
                    state.fields[name].validators[index] = fieldConfig.getValidator;
                }

                if (fieldConfig.initialValue !== undefined && getIn(state.formState.values, name) === undefined // only initialize if we don't yet have any value for this field
                ) {
                    state.formState.initialValues = setIn(state.formState.initialValues || {}, name, fieldConfig.initialValue);
                    state.formState.values = setIn(state.formState.values, name, fieldConfig.initialValue);
                    runValidation(undefined, notify);
                }

                if (fieldConfig.defaultValue !== undefined && fieldConfig.initialValue === undefined && getIn(state.formState.initialValues, name) === undefined) {
                    state.formState.values = setIn(state.formState.values, name, fieldConfig.defaultValue);
                }
            }

            if (haveValidator) {
                runValidation(undefined, notify);
            } else {
                notify();
            }

            return () => {
                let validatorRemoved = false; // istanbul ignore next

                if (state.fields[name]) {
                    // state.fields[name] may have been removed by a mutator
                    validatorRemoved = !!(state.fields[name].validators[index] && state.fields[name].validators[index]());
                    delete state.fields[name].validators[index];
                }

                delete state.fieldSubscribers[name].entries[index];
                let lastOne = !Object.keys(state.fieldSubscribers[name].entries).length;

                if (lastOne) {
                    delete state.fieldSubscribers[name];
                    delete state.fields[name];

                    if (validatorRemoved) {
                        state.formState.errors = setIn(state.formState.errors, name, undefined) || {};
                    }

                    if (destroyOnUnregister) {
                        state.formState.values = setIn(state.formState.values, name, undefined, true) || {};
                    }
                }

                if (!silent) {
                    if (validatorRemoved) {
                        runValidation(undefined, () => {
                            notifyFormListeners();
                            notifyFieldListeners();
                        });
                    } else if (lastOne) {
                        // values or errors may have changed
                        notifyFormListeners();
                    }
                }
            };
        },
        reset: (initialValues = state.formState.initialValues) => {
            if (state.formState.submitting) {
                throw Error('Cannot reset() in onSubmit(), use setTimeout(form.reset)');
            }

            state.formState.submitFailed = false;
            state.formState.submitSucceeded = false;
            delete state.formState.submitError;
            delete state.formState.submitErrors;
            delete state.formState.lastSubmittedValues;
            api.initialize(initialValues || {});
        },

        /**
         * Resets all field flags (e.g. touched, visited, etc.) to their initial state
         */
        resetFieldState: name => {
            state.fields[name] = { ...state.fields[name],
                ...{
                    active: false,
                    lastFieldState: undefined,
                    modified: false,
                    touched: false,
                    valid: true,
                    validating: false,
                    visited: false
                }
            };
            runValidation(undefined, () => {
                notifyFieldListeners();
                notifyFormListeners();
            });
        },

        /**
         * Returns the form to a clean slate; that is:
         * - Clear all values
         * - Resets all fields to their initial state
         */
        restart: (initialValues = state.formState.initialValues) => {
            api.batch(() => {
                for (const name in state.fields) {
                    api.resetFieldState(name);
                    state.fields[name] = { ...state.fields[name],
                        ...{
                            active: false,
                            lastFieldState: undefined,
                            modified: false,
                            modifiedSinceLastSubmit: false,
                            touched: false,
                            valid: true,
                            validating: false,
                            visited: false
                        }
                    };
                }

                api.reset(initialValues);
            });
        },
        resumeValidation: () => {
            validationPaused = false;

            if (validationBlocked) {
                // validation was attempted while it was paused, so run it now
                runValidation(undefined, () => {
                    notifyFieldListeners();
                    notifyFormListeners();
                });
            }

            validationBlocked = false;
        },
        setConfig: (name, value) => {
            switch (name) {
                case 'debug':
                    debug = value;
                    break;

                case 'destroyOnUnregister':
                    destroyOnUnregister = value;
                    break;

                case 'initialValues':
                    api.initialize(value);
                    break;

                case 'keepDirtyOnReinitialize':
                    keepDirtyOnReinitialize = value;
                    break;

                case 'mutators':
                    mutators = value;

                    if (value) {
                        Object.keys(mutatorsApi).forEach(key => {
                            if (!(key in value)) {
                                delete mutatorsApi[key];
                            }
                        });
                        Object.keys(value).forEach(key => {
                            mutatorsApi[key] = getMutatorApi(key);
                        });
                    } else {
                        Object.keys(mutatorsApi).forEach(key => {
                            delete mutatorsApi[key];
                        });
                    }

                    break;

                case 'onSubmit':
                    onSubmit = value;
                    break;

                case 'validate':
                    validate = value;
                    runValidation(undefined, () => {
                        notifyFieldListeners();
                        notifyFormListeners();
                    });
                    break;

                case 'validateOnBlur':
                    validateOnBlur = value;
                    break;

                default:
                    throw new Error('Unrecognised option ' + name);
            }
        },
        submit: () => {
            const {
                formState
            } = state;

            if (formState.submitting) {
                return;
            }

            delete formState.submitErrors;
            delete formState.submitError;
            formState.lastSubmittedValues = { ...formState.values
            };

            if (hasSyncErrors()) {
                markAllFieldsTouched();
                state.formState.submitFailed = true;
                notifyFormListeners();
                notifyFieldListeners();
                return; // no submit for you!!
            }

            const asyncValidationPromisesKeys = Object.keys(asyncValidationPromises);

            if (asyncValidationPromisesKeys.length) {
                // still waiting on async validation to complete...
                Promise.all(asyncValidationPromisesKeys.map(key => asyncValidationPromises[Number(key)])).then(api.submit, console.error);
                return;
            }

            const submitIsBlocked = beforeSubmit();

            if (submitIsBlocked) {
                return;
            }

            let resolvePromise;
            let completeCalled = false;

            const complete = errors => {
                formState.submitting = false;

                if (errors && hasAnyError(errors)) {
                    formState.submitFailed = true;
                    formState.submitSucceeded = false;
                    formState.submitErrors = errors;
                    formState.submitError = errors[FORM_ERROR];
                    markAllFieldsTouched();
                } else {
                    formState.submitFailed = false;
                    formState.submitSucceeded = true;
                    afterSubmit();
                }

                notifyFormListeners();
                notifyFieldListeners();
                completeCalled = true;

                if (resolvePromise) {
                    resolvePromise(errors);
                }

                return errors;
            };

            formState.submitting = true;
            formState.submitFailed = false;
            formState.submitSucceeded = false;
            formState.lastSubmittedValues = { ...formState.values
            };
            resetModifiedAfterSubmit(); // onSubmit is either sync, callback or async with a Promise

            const result = onSubmit(formState.values, api, complete);

            if (!completeCalled) {
                if (result && isPromise(result)) {
                    // onSubmit is async with a Promise
                    notifyFormListeners(); // let everyone know we are submitting

                    notifyFieldListeners(); // notify fields also

                    return result.then(complete, error => {
                        complete();
                        throw error;
                    });
                } else if (onSubmit.length >= 3) {
                    // must be async, so we should return a Promise
                    notifyFormListeners(); // let everyone know we are submitting

                    notifyFieldListeners(); // notify fields also

                    return new Promise(resolve => {
                        resolvePromise = resolve;
                    });
                } else {
                    // onSubmit is sync
                    complete(result);
                }
            }
        },
        subscribe: (subscriber, subscription) => {
            if (!subscriber) {
                throw new Error('No callback given.');
            }

            if (!subscription) {
                throw new Error('No subscription provided. What values do you want to listen to?');
            }

            const memoized = memoize(subscriber);
            const {
                subscribers
            } = state;
            const index = subscribers.index++;
            subscribers.entries[index] = {
                subscriber: memoized,
                subscription,
                notified: false
            };
            const nextFormState = calculateNextFormState();
            notifySubscriber(memoized, subscription, nextFormState, nextFormState, filterFormState, true);
            return () => {
                delete subscribers.entries[index];
            };
        }
    };
    return api;
}

export { ARRAY_ERROR, FORM_ERROR, configOptions, createForm, fieldSubscriptionItems, formSubscriptionItems, getIn, setIn, ffVersion };
