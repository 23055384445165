import { useEffect }          from "react";
import React                  from "react";
import { FC }                 from "react";
import { AdjustmentsClasses } from "./AdjustmentsClasses";
import "./adjustments.css";

export const Adjustments: FC = React.memo(function Adjustments(props) {
  return <table className={AdjustmentsClasses.Adjustments}>{/*todo make a configurable table in the future*/}
    <colgroup>
      <col span={1}/>
      <col span={1}/>
      <col span={1}/>
      <col span={1}/>
    </colgroup>
    <thead>
    <tr>
      <th align="left">
        <span>Adjustment description</span>
      </th>
      <th align="right">
        <span>Rate adjustment</span>
      </th>
      <th align="right">
        <span>Price adjustment</span>
      </th>
      <th align="right">
        <span>Margin adjustment</span>
      </th>
    </tr>
    </thead>
    <tbody>
    {props.children}
    </tbody>
  </table>;
});
