import React              from "react";
import { FC }             from "react";
import { ComponentType }  from "react";
import { useContext }     from "react";
import { useMemo }        from "react";
import { ModalProps }     from "../../..";
import { JsonFormProps }  from "../../..";
import { DeepPartial }    from "../../..";
import { deepMerge }      from "../../../utils/deepMerge";
import { BaseFieldProps } from "../../Field/BaseField";
import { defaultMapping } from "./defaultMapping";

export interface ComponentMappings {
  fields: Record<string, ComponentType<BaseFieldProps>>
  sections: Record<string, ComponentType>
  views: Record<string, ComponentType>
  headers: Record<string, ComponentType>
  widgets: Record<string, ComponentType>
  forms: Record<string, ComponentType<Partial<JsonFormProps>>>
  dialogs: Record<string, ComponentType<Partial<ModalProps>>>
}

export const ComponentsContext = React.createContext<ComponentMappings>(defaultMapping);

export const ComponentsProvider: FC<{ components?: DeepPartial<ComponentMappings> }> = React.memo(function ComponentsProvider(props) {
  const value = useMemo(() => deepMerge(defaultMapping, props.components), [props.components]);
  return (
    <ComponentsContext.Provider value={value}>
      {props.children}
    </ComponentsContext.Provider>
  );
});

export function useComponents() {
  return useContext(ComponentsContext);
}
