import { useMemo }                  from "react";
import React                        from "react";
import { FC }                       from "react";
import { EmailsFieldProps }         from "@relcu/ui";
import { BaseArrayField }           from "@relcu/ui";
import { IChoiceField }             from "../../../../../../types/ISchemas";
import { useSchemaField }           from "../../../../useSchemaField";
import { EmailsDuplicateEditFiled } from "./EmailsDuplicateEditFiled";

export type EmailsDuplicateFieldProps = Partial<EmailsFieldProps>

export const EmailsDuplicateField: FC<EmailsDuplicateFieldProps> = React.memo(function EmailsDuplicateField(props) {
  const { options } = useSchemaField<IChoiceField>("EmailAddress", "type");
  return (
    <BaseArrayField
      {...props}
      view="write"
      readView={null}
      defaultValue={useMemo(() => [{ type: options[ 0 ]?.value }], [])}
      editView={<EmailsDuplicateEditFiled/>}
    />
  );
});
