import React                 from "react";
import { Box }               from "../../..";
import { classNames }        from "../../..";
import { ListItemClasses }   from "../";
import { BoxComponentProps } from "../../..";

export interface ListItemSwitchProps extends BoxComponentProps {
  label: string,
  value: boolean
}

export function ListItemSwitch(props: ListItemSwitchProps) {
  const { className, label, value, ...p } = props;
  const classes = classNames(ListItemClasses.ListItemText, className);

  return <Box container className={classes} {...p}>
    <b>{label}</b> : {value ? "True" : "False"}
  </Box>;
}
