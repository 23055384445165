import { useLayoutEffect }                    from "react";
import React, { useEffect, useState, useRef } from "react";
import { NavBarClasses }                      from "../..";
import { classNames }                         from "../../";
import { BadgeColors }                        from "../Badge";
import { BadgeVariants }                      from "../Badge";
import { Badge }                              from "../Badge";
import { BoxItemComponentProps }              from "../Box";
import { applyBoxItemStyles }                 from "../Box";
import { Box }                                from "../Box";

export enum ScrollBoxDirection {
  Row = "row",
  Column = "column"
}

export interface ScrollBoxProps extends BoxItemComponentProps {
  direction?: ScrollBoxDirection
  gap?: "XXXL" | "XXL" | "XL" | "L" | "M" | "S" | "XS" | "XXS" | "XXXS" | "XXXXS"
  current?: number
}
const defaultScrollBoxProps: ScrollBoxProps = {
  direction: ScrollBoxDirection.Column,
  gap: "XS",
  current: 0
};
ScrollBox.defaultProps = defaultScrollBoxProps;
export function ScrollBox(props: ScrollBoxProps) {
  let properties = applyBoxItemStyles<ScrollBoxProps>(props);
  const { className, children, gap, direction, current, ...p } = properties;
  const classes = classNames(ScrollBoxClasses.ScrollBox, className);
  const container = useRef(null);
  const [widgets, setWidgets] = useState([]);
  const [outWidgets, setOutWidgets] = useState(0);

  const scrollTo = (element, index) => {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    if (!container.current.children[ container.current.childElementCount - 2 ]) {
      return;
    }
    let lastChild = container.current.children[ container.current.childElementCount - 2 ].getBoundingClientRect();
    let reducingHeight = direction == ScrollBoxDirection.Row ?
      container.current.getBoundingClientRect().width - lastChild.width :
      container.current.getBoundingClientRect().height - lastChild.height;
    container.current.lastElementChild.style.cssText = `${direction == ScrollBoxDirection.Row ? `width: ${reducingHeight}px; flex-shrink: 0;` : `margin-top:${reducingHeight}px;`} visibility: hidden`;
  }, [children, gap, direction]);

  useLayoutEffect(() => {
    let outsOfView = new Set();
    const listener = (entries) => {
      entries.forEach(
        ({ isIntersecting, boundingClientRect, target }) => {
          if (!isIntersecting) {
            if (boundingClientRect.top < 50) {
              outsOfView.add(target);
            }
          } else {
            if (outsOfView.has(target)) {
              outsOfView.delete(target);
            }
          }
        }
      );
      setOutWidgets(outsOfView.size);
    };
    const observer = new IntersectionObserver(listener, {
      root: container.current,
      rootMargin: "0px",
      threshold: .5
    });

    const activesItems = container.current.querySelectorAll(`.${ScrollBoxClasses.ScrollContainer} .${ScrollBoxClasses.ScrollItem}:not(:empty)`);
    setWidgets([...activesItems]);
    for (let i = 0; i < activesItems.length; i++) {
      observer.observe(activesItems[ i ]);
    }
    return () => {
      const activesItems = container.current.querySelectorAll(`.${ScrollBoxClasses.ScrollContainer} .${NavBarClasses.NavBarItem}:not(:empty)`);
      for (let i = 0; i < activesItems.length; i++) {
        observer.unobserve(activesItems[ i ]);
      }
      observer.disconnect();
    };
  }, [children]);

  useEffect(() => {
    container.current[ direction == ScrollBoxDirection.Row ? "scrollLeft" : "scrollTop" ] = 0;
  }, []);

  return <Box
    container
    gap={"XXS"}
    direction={direction == ScrollBoxDirection.Column ? "row" : "column"}
    className={classes}
    {...p}
  >
    <Box
      ref={container}
      tabIndex={0}
      container
      direction={direction}
      gap={gap}
      className={
        classNames(ScrollBoxClasses.ScrollContainer, {
          [ ScrollBoxClasses.Row ]: direction == ScrollBoxDirection.Row
        })
      }>
      {
        React.Children.map(children, (child) => {
          return <Box flexShrink={0} container alignItems={"center"} justify={"center"}
                      className={ScrollBoxClasses.ScrollItem}>{child}</Box>;
        })
      }
      <Box container flexShrink={0}/>
    </Box>
    <Box container direction={direction} alignSelf={"center"} justify={"center"} gap={"XXS"}>
      {
        widgets.map((widget, index) => {
          return <Badge key={index} variant={BadgeVariants.Dot}
                        className={ScrollBoxClasses.ScrollMover}
                        color={outWidgets == index ? BadgeColors.Primary : BadgeColors.White}
                        onClick={() => scrollTo(widget, index)}/>;
        })
      }
    </Box>
  </Box>;
}

export enum ScrollBoxClasses {
  ScrollBox = "scroll-box",
  ScrollContainer = "scroll-box__container",
  ScrollItem = "scroll-box__item",
  ScrollMover = "scroll-box__mover",
  Row = "scroll-box__container"
}
