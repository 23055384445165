import { CommonClasses }                                  from "@relcu/ui";
import { Link }                                           from "@relcu/ui";
import React                                              from "react";
import { TypographyColor }                                from "@relcu/ui";
import { Typography }                                     from "@relcu/ui";
import { TimelineItemSubject }                            from "@relcu/ui";
import { TimelineItemIconColors }                         from "@relcu/ui";
import { TimelineItemIcon }                               from "@relcu/ui";
import { TimelineItem }                                   from "@relcu/ui";
import { ObjectMergeActivity as ObjectMergeActivityType } from "../../../../__types__/ObjectMergeActivity";

export interface ContactMergeActivityProps extends ObjectMergeActivityType {
  createdAt: string;
}

export const ObjectMergeActivity = React.memo<ContactMergeActivityProps>(function ObjectMergeActivity(props) {
  const { sourceObject, targetObject, mergeBy: contactMergeBy, createdAt } = props;
  const initiatorName = contactMergeBy ? contactMergeBy.objectName : "Relcu";
  const subjectName = targetObject ? targetObject.objectName : "Unknown";
  return <TimelineItem
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"connect_without_contact"}/>}
    primary={<TimelineItemSubject>
      {
        contactMergeBy?.objectId && !contactMergeBy?.objectId.startsWith("int:") ?
          <Link
            to={`/user/${contactMergeBy.objectId}/details`}>
            <Typography className={CommonClasses.ClickableIcon}>{initiatorName}</Typography>
          </Link>
          :
          <Typography>{initiatorName}</Typography>
      }
      <Typography color={TypographyColor.Secondary}>merged</Typography>
      <Typography>{sourceObject.objectName}</Typography>
      <Typography color={TypographyColor.Secondary}>with</Typography>
      {
        targetObject?.objectId ?
          <Link
            to={`/contact/${targetObject.objectId}`}>
            <Typography className={CommonClasses.ClickableIcon}>{subjectName}</Typography>
          </Link>
          :
          <Typography>{subjectName}</Typography>
      }
    </TimelineItemSubject>}
    creationDate={createdAt}
  />;
});