import { useApolloClient }      from "@apollo/client";
import { getIn }                from "@relcu/form";
import { useNavigate }          from "@relcu/react-router";
import { useSearchParams }      from "@relcu/react-router";
import { ButtonColors }         from "@relcu/ui";
import { useModal }             from "@relcu/ui";
import { useLazyCondition }     from "@relcu/ui";
import { JsonViewProps }        from "@relcu/ui";
import { confirmModal }         from "@relcu/ui";
import { useState }             from "react";
import { useCallback }          from "react";
import { useMemo }              from "react";
import { layoutVar }            from "../../../../reactiveVars";
import { transformNameToLabel } from "../../../../utils/helpers";
import { getObjectPath }        from "../../../../utils/layoutUtils";
import { getHumanized }         from "../../../../utils/schemaUtils";
import { usePermissions }       from "../../../AccessControl";
import { useFilter }            from "../../../Filter";
import { SchemaDialog }         from "../../Dialog/SchemaDialog";
import { getJqlMutation }       from "../../Jql";
import { getJqlQuery }          from "../../Jql";
import { useJqlMutation }       from "../../Jql";
import { useJqlQuery }          from "../../Jql";

const dialogTitles = {
  PhoneMessageTemplate: "SMS template",
  PmiPartner: "PMI partner"

};
export function useList(props: JsonViewProps) {
  const layouts = layoutVar();
  const count = useMemo(() => Math.round((window.innerHeight - 262) / 44), [window.innerHeight]);
  const [modal, modalContext] = useModal(SchemaDialog);
  const { canUpdate, canCreate, canDelete } = usePermissions({ __typename: props.classId });
  const evaluate = useLazyCondition();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPage = +searchParams.get("page") || 1;
  const offset = count;
  const operation = props.jql.query.find.operation;
  const deleteOp = props.jql.mutation.delete;

  const filter = useFilter({
    schema: props.classId,
    fields: props.filters.fields,
    rules: props.filters.rules,
    onApply: useCallback(async (where) => {
      setWhere(prevState => {
        if (currentPage !== 1) {
          api.onPage(1);
        }
        return where;
      });
    }, [])
  });
  const [where, setWhere] = useState(filter.query);
  const client = useApolloClient();
  const variables = {
    where: where,
    first: offset,
    skip: offset * (currentPage - 1),
    order: props.order || ["createdAt_DESC", "objectId_ASC"]
  };
  if (props.draggable) {
    variables.order.unshift("priority_ASC");
  }
  const { data, loading, subscribeToMore, refetch } = useJqlQuery(props.jql.query.find, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    operationName: `${props.classId}ListViewQuery`,
    variables
  });
  const [remove] = useJqlMutation({
    operation: deleteOp.operation,
    fields: deleteOp.fields,
    variables: {
      input: {
        type: deleteOp.variables.input.type
      }
    }
  },{
    operationName: `${props.classId}ListViewRemove`
  });

  // useEffect(() => {
  //   if (props.jql.subscription) {
  //     const subscription = buildSubscription(props.jql.subscription);
  //     return subscribeToMore({
  //       document: subscription.documentNode,
  //       variables: { where, events: [SubscriptionEvent.UPDATE] }
  //     });
  //   }
  // }, [props.classId, where, props.jql.subscription]);

  const api = {
    modalContext,
    draggable: props.draggable,
    description: props.description,
    hideFilter: props.filters.hide,
    name: dialogTitles[ props.classId ] || transformNameToLabel(props.classId),
    title: props.title,
    loading: loading,
    count,
    offset,
    filter: filter as any,
    currentPage,
    blank: props.blank,
    get data() {
      const nodes = data?.[ operation ]?.[ "edges" ] ?? [];
      return nodes.map(({ node }) => {
        return node;
      });
    },
    get total() {
      return (
        data &&
        data[ operation ] &&
        data[ operation ][ "count" ]
      ) || 0;
    },
    onRowMove(dragIndex, hoverIndex) {
      const { documentNode } = getJqlQuery(props.jql.query.find, {

        operationName: `${props.classId}ListViewQuery`
      });
      let edges = data?.[ operation ]?.[ "edges" ] ?? [];
      const p = [...edges];
      p.splice(hoverIndex, 0, p.splice(dragIndex, 1)[ 0 ]);
      edges = p;
      client.writeQuery({
        query: documentNode,
        variables,
        data: {
          ...data,
          [ operation ]: {
            ...data[ operation ],
            edges
          }
        }
      });
    },
    onRowDrop() {
      const { documentNode } = getJqlMutation(props.jql.mutation.update, {
        operationName: `${props.classId}ListViewUpdate`
      });
      api.data.map((node, priority) => {
        return client.mutate({
          mutation: documentNode,
          variables: {
            input: {
              id: node.id,
              fields: {
                priority
              }
            }
          }
        });
      });
    },
    onRowClick(obj) {
      navigate(getObjectPath(obj));
    },
    onPage(page) {
      searchParams.set("page", page.toString());
      navigate(`/${location.pathname}?${searchParams.toString()}`);
    },
    getColumnValue(row, column) {
      return getHumanized(row.__typename, column.name, getIn(row, column.name));
    },
    onRowEdit(obj) {
      modal({
        action: "save",
        className: props.classId,
        record: obj,
        title: dialogTitles[ obj.__typename ]
      });
    },
    async onRowDelete(obj) {
      try {
        const entityName = dialogTitles[ props.classId ] || transformNameToLabel(props.classId);
        await confirmModal({
          title: "Delete confirmation",
          subTitle: `Are you sure you want to delete ${entityName}?`,
          content: `All information related with this ${entityName} will be lost permanently`,
          label: "DELETE"
        });
        await remove({
          variables: {
            input: {
              id: obj.id
            }
          },
          async update() {
            refetch();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    onCreate(obj) {
      modal({
        action: "create",
        className: props.classId,
        title: dialogTitles[ obj.__typename ]
      });
    },
    get headers() {
      return props.headers?.filter((field) => {
        if (field.conditions) {
          const { apply } = evaluate({ conditions: field.conditions });
          return apply;
        }

        return true;
      });
    },
    get fields() {
      return props.fields.filter((field) => {
        if (field.conditions) {
          const { apply } = evaluate({ conditions: field.conditions });
          return apply;
        }
        return true;
      });
    },
    get actions() {
      const access = typeof props.editable === "object" ? evaluate({ conditions: props.editable }).apply : props.editable;
      if (access) {
        const actions = [];
        if (canUpdate) {
          actions.push({
            icon: "create" as "create",
            tooltip: "Edit",
            onClick(e, data) {
              e.stopPropagation();
              api.onRowEdit(data);
            },
            color: ButtonColors.White
          });
        }
        if (canDelete) {
          actions.push(
            {
              icon: "clear" as any,
              tooltip: "Delete",
              onClick(e, data) {
                e.stopPropagation();
                api.onRowDelete(data);
              },
              color: ButtonColors.White
            }
          );
        }
        return actions;
      }
    },
    get createAction() {
      const canCreateAccess = "creatable" in props ? props.creatable : props.editable;
      if (canCreateAccess) {
        const apply = typeof canCreateAccess === "object" ? evaluate({ conditions: props.creatable }).apply : canCreateAccess;
        if (canCreate && apply) {
          return {
            onClick(e, data) {
              e.stopPropagation();
              api.onCreate({ __typename: props.classId });
            }
          };
        }
      }
      return;
    }
  };
  const onRowClick = layouts[ props.classId ]?.tabs.length ? api.onRowClick : null;
  return {
    ...api,
    onRowClick,
    data: useMemo(() => api.data, [data, operation]),
    total: useMemo(() => api.total, [data, operation]),
    headers: useMemo(() => api.headers, [props.headers, evaluate]),
    fields: useMemo(() => api.fields, [props.fields, evaluate])
  };
}
