import { Icon }           from "@relcu/rc";
import { IconType }       from "@relcu/rc";
import React              from "react";
import { FC }             from "react";
import { HTMLAttributes } from "react";
import { Typography }     from "@relcu/rc";
import "./dial-list.css";

export interface DialListItemProps extends HTMLAttributes<HTMLDivElement> {
  icon?: IconType;
}
export const DialListItem: FC<DialListItemProps> = React.memo(function DialListItem(props) {
  const { children, onClick, icon, ...p } = props;

  return <div className={"dial-list-item"} onClick={onClick}>
    <Typography lineHeight={"lh24"} className={"dial-list-item-text"}>
      {children}
    </Typography>
    {
      icon &&
      <Icon type={icon}/>
    }
  </div>;
});
