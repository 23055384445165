export const enum MoomLoaChecklistClasses {
  MoomLoaChecklist = "moom-loa-checklist",
  MoomLoaChecklistItem = "moom-loa-checklist-item",
  MoomLoaChecklistItemContent = "moom-loa-checklist-item-content",
  MoomLoaChecklistEmptyView = "moom-loa-checklist-empty-view",
  MoomLoaChecklistActions = "moom-loa-checklist-actions",
  Active = "active",
  MoomLoaChecklistReadMoreButton = "moom-loa-checklist-read-more-button",
  ChecklistHeader = "checklist-header",
  ChecklistLogo = "checklist-logo",
  ChecklistRow = "checklist-row",
  ChecklistNotes = "checklist-notes"
}
