import React        from "react";
import { OnBlur }   from "@relcu/form";
import { OnChange } from "@relcu/form";
import { getField } from "../../../../../../utils/schemaUtils";

export interface OfferListenerProps {
  names: string[];
  onChange: (name: string, value?, previous?) => void;
}
export const OfferListener = React.memo<OfferListenerProps>(function OfferListener(props) {
  const { names, onChange } = props;
  return (
    <>
      {names.map(name => {
        const field = getField("LoanEstimateOffer", name);
        if (field.type == "Choice" || field.type === "Boolean") {
          return <OnChange key={name} name={name} children={(value, previous) => onChange(name, value, previous)}/>;
        }
        return <OnBlur key={name} name={name} children={() => onChange(name)}/>;
      })}
    </>
  );
});
