import React                        from "react";
import { FC }                       from "react";
import { InlineEditableInput }      from "../../Input/InlineEditableInput";
import { InlineEditableInputProps } from "../../Input/InlineEditableInput";
import { useBaseField }             from "../BaseField";
import { BaseFieldProps }           from "../BaseField";

export type TextInlineEditableProps = Omit<BaseFieldProps, "mode"> & Omit<InlineEditableInputProps, "value"> & {
  style?: any
  classes?: {
    root?: string
    actions?: string
  }
};

export const TextInlineEditableField: FC<TextInlineEditableProps> = React.memo(function TextInlineEditableField(props) {
  const field = useBaseField<TextInlineEditableProps>(props);
  const { input, meta: { touched, error, submitError } } = field;
  const hasError = (touched && !!error) || !!submitError;
  return (
    <InlineEditableInput
      hideActionButtons={props.hideActionButtons}
      error={hasError}
      value={input.value}
      {...input}
    />
  );
});
