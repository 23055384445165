import { useReactiveVar }       from "@apollo/client";
import { Icon }                 from "@relcu/rc";
import { Badge }                from "@relcu/rc";
import React                    from "react";
import { FC }                   from "react";
import { audioDevicesVar }      from "../../reactiveVars";
import { BarToggleButton }      from "./BarToggleButton";
import { BarToggleButtonProps } from "./BarToggleButton";

export interface DialBarButtonProps extends BarToggleButtonProps {

}

export const DialBarButton: FC<DialBarButtonProps> = React.memo(function DialBarButton(props) {
  const devices = useReactiveVar(audioDevicesVar);
  const Button = <BarToggleButton {...props}/>;
  if ((!devices.audiooutput.length || !devices.audioinput.length) && props.state !== "dial") {
    return <Badge className={"dial-button-error-badge"} content={
      <Icon color={"error"} type={"info" as any}/>
    }>
      {Button}
    </Badge>;
  }
  return Button;
});
