import { CommonClasses }                                    from "@relcu/ui";
import { Link }                                             from "@relcu/ui";
import { TimelineItemBody }                                 from "@relcu/ui";
import { Box }                                              from "@relcu/ui";
import { TimelineItem }                                     from "@relcu/ui";
import { TypographyColor }                                  from "@relcu/ui";
import { Typography }                                       from "@relcu/ui";
import { TypographyWeight }                                 from "@relcu/ui";
import { TimelineItemSubject }                              from "@relcu/ui";
import { TimelineItemIconColors }                           from "@relcu/ui";
import { TimelineItemIcon }                                 from "@relcu/ui";
import React                                                from "react";
import { StatusChangeActivity as StatusChangeActivityType } from "../../../../__types__/StatusChangeActivity";

export interface StatusActivityProps extends StatusChangeActivityType {
  createdAt: string;
}

export const StatusChangeActivity = React.memo<StatusActivityProps>(function StatusChangeActivity(props) {
  const { statusChangedBy, statusChangedSubject, statusNewValue, statusOldValue, createdAt } = props;

  return <TimelineItem
    primary={<TimelineItemSubject>
      {
        statusChangedBy?.objectId && !statusChangedBy.objectId.startsWith("int:") ?
          <Link to={`/user/${statusChangedBy.objectId}/focus`}>
            <Typography className={CommonClasses.ClickableIcon}>{statusChangedBy?.objectName || "Unknown"}</Typography>
          </Link>
          :
          <Typography>{statusChangedBy?.objectName || "Unknown"}</Typography>
      }
      <Typography color={TypographyColor.Secondary}>updated</Typography>
      <Typography>status</Typography>
      <Typography color={TypographyColor.Secondary}>on</Typography>
      {
        statusChangedSubject?.objectId ?
          <Link to={`/lead/${statusChangedSubject.objectId}`}>
            <Typography
              className={CommonClasses.ClickableIcon}>{statusChangedSubject?.objectName || "Unknown"}</Typography>
          </Link>
          :
          <Typography>{statusChangedSubject?.objectName || "Unknown"}</Typography>
      }
    </TimelineItemSubject>}
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"flash_on"}/>}
    creationDate={createdAt}
  >
    <TimelineItemBody>
      <Box container alignItems={"center"} gap={"XXXS"}>
        <Typography color={TypographyColor.Secondary}>from</Typography>
        <Typography weight={TypographyWeight.Medium}>{statusOldValue?.statusName || "Unknown"}</Typography>
        <Typography color={TypographyColor.Secondary}>to</Typography>
        <Typography weight={TypographyWeight.Medium}>{statusNewValue?.statusName || "Unknown"}</Typography>
        <Typography color={TypographyColor.Secondary}>through</Typography>
        <Typography weight={TypographyWeight.Medium}>{statusNewValue?.actionName || "Unknown"}</Typography>
        <Typography color={TypographyColor.Secondary}>action</Typography>
      </Box>
    </TimelineItemBody>
  </TimelineItem>;
});
