import React                        from "react";
import { RsRefForwardingComponent } from "../@types/common";
import { FlexboxGridProps }         from "../FlexboxGrid";
import { FlexboxGrid }              from "../index";
import { useClassNames }            from "../utils";

export interface TableHorizontalProps extends FlexboxGridProps {
  bordered?: boolean;
}

export const TableHorizontal: RsRefForwardingComponent<"div", TableHorizontalProps> = React.forwardRef((props: TableHorizontalProps, ref) => {
  const {
    children,
    className,
    classPrefix = "table-horizontal",
    bordered,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix({ bordered })
  );

  return <FlexboxGrid className={classes} {...rest}>
    {children}
  </FlexboxGrid>;
});

export default TableHorizontal;
