import React                 from "react";
import { FC }                from "react";
import { Alignment }         from "../../constants/Alignemnt";
import { CommonClasses }     from "../../theme/classes";
import { useBoxProps }       from "../Box";
import { BoxComponentProps } from "../Box";
import { Box }               from "../Box";
import { FontIcon }          from "../Icon";
import { Tooltip }           from "../Tooltip";

interface DescribedLabelProps extends BoxComponentProps {
  description: string;
  alignment?: Alignment;
}

export const DescribedLabel: FC<DescribedLabelProps> = React.memo(function DescribedLabel(props) {
  const { children, description, alignment, ...p } = props;
  return <Box container gap={"XXXS"} alignItems={"center"} {...useBoxProps(p)}>
    {children}
    {
      description &&
      <Tooltip title={description} alignment={alignment}>
        <FontIcon type={"info"} className={CommonClasses.GrayIcon}
                  style={{ fontSize: "var(--typography-icon-size)" }}/>
      </Tooltip>
    }
  </Box>;
});

DescribedLabel.defaultProps = {
  alignment: Alignment.Top
};