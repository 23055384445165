import React                  from "react";
import { FC }                 from "react";
import { Box }                from "../../Box";
import { Button }             from "../../Button";
import { EmptyList }          from "../../EmptyList";
import { IconType }           from "../../Icon";
import { Modal }              from "../Modal";
import { ModalProps }         from "../Modal";
import { ModalFooter }        from "../Modal";
import { ModalBody }          from "../Modal";
import { EmptyDialogClasses } from "./EmptyDialogClasses";

export interface EmptyDialogProps extends Partial<ModalProps> {
  modalTitle: string,
  title: string,
  content: string
  icon: IconType
}

export const EmptyDialog: FC<EmptyDialogProps> = React.memo(function EmptyDialog(props) {
  const { modalTitle, title, content, onClose, icon, open } = props;
  return <Modal
    title={modalTitle}
    onClose={props.onClose}
    open={open}
    closeIcon={true}
    disableBackdropClick={false}
    className={EmptyDialogClasses.Modal}
  >
    <ModalBody container direction={"column"} flex={"1 1 auto"} justify={"center"} gap={"L"}
               className={EmptyDialogClasses.Container}>
      <EmptyList style={{ padding: 20 }} icon={icon} content={content}
                 title={title}
                 alignSelf={"center"}/>
    </ModalBody>
    <ModalFooter>
      <Button alignSelf={"end"} onClick={() => onClose()}>CLOSE</Button>
    </ModalFooter>
  </Modal>;
});

export default EmptyDialog;
