import React                from "react";
import { FC }               from "react";
import { useCallback }      from "react";
import { InputState }       from "../../Input/Input";
import { Select }           from "../../Input/Select";
import { useConsumeField }  from "../BaseField";
import { ChoiceFieldProps } from "./ChoiceField";

export const ChoiceEditField: FC = React.memo(function ChoiceEditField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField<ChoiceFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  const getOptionSelected = (selectedItem, value) => {
    if (!value) {
      return false;
    }
    return (selectedItem?.value ?? selectedItem) == value;
  };

  const handleClear = (event) => {
    event.stopPropagation();
    input.onChange(null)
  }

  return (
    <Select
      handleClear={input.clearable ? handleClear : null}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
      height={input?.options.length > 4 ? 45 : "fit-content"}
      {...input}
      onChange={(item) => input.onChange(item?.value ?? item)}
      getOptionSelected={useCallback(getOptionSelected, [input.value, input.options])}
      getOptionValue={useCallback((item) => item?.value ?? item, [input.value, input.options])}
      getOptionLabel={useCallback((item) => item?.label ?? item, [input.value, input.options])}
    />
  );
});
export default ChoiceEditField;
