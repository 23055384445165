import React                   from "react";
import { FC }                  from "react";
import { Box }                 from "../Box";
import { VerticalDivider }     from "../Divider";
import { TimelineItemClasses } from "./TimelineItemClasses";

export interface TimelineItemContainerProps {
  last: boolean;
}

export const TimelineItemContainer: FC<TimelineItemContainerProps> = React.forwardRef(function TimelineItemContainer(props, ref: any) {
  const { last, children } = props;

  return (
    <Box
      container
      direction={"row"}
      flexGrow={1}
      alignItems={"center"}
      gap={"XXS"}
      ref={ref}>
      <Box
        container
        direction={"column"}
        flexGrow={1}
        className={TimelineItemClasses.TimelineItemContainer}>
        {children}
      </Box>
    </Box>
  );
});