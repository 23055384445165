/**
 * Actions represent the type of change to a location value.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#action
 */
export enum Action {
   /**
    * A POP indicates a change to an arbitrary index in the history stack, such
    * as a back or forward navigation. It does not describe the direction of the
    * navigation, only that the current index changed.
    *
    * Note: This is the default action for newly created history objects.
    */
   Pop = 'POP',

   /**
    * A PUSH indicates a new entry being added to the history stack, such as when
    * a link is clicked and a new page loads. When this happens, all subsequent
    * entries in the stack are lost.
    */
   Push = 'PUSH',

   /**
    * A REPLACE indicates the entry at the current index in the history stack
    * being replaced by a new one.
    */
   Replace = 'REPLACE'
}

/**
 * A URL pathname, beginning with a /.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.pathname
 */
export type Pathname = string;

/**
 * A URL search string, beginning with a ?.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.search
 */
export type Search = string;

/**
 * A URL fragment identifier, beginning with a #.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.hash
 */
export type Hash = string;

/**
 * An object that is used to associate some arbitrary data with a location, but
 * that does not appear in the URL path.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.state
 */
export type State = object | null;

/**
 * A unique string associated with a location. May be used to safely store
 * and retrieve data in some other storage API, like `localStorage`.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.key
 */
export type Key = string;

/**
 * The pathname, search, and hash values of a URL.
 */
export interface Path {
   /**
    * A URL pathname, beginning with a /.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.pathname
    */
   pathname: Pathname;

   /**
    * A URL search string, beginning with a ?.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.search
    */
   search: Search;

   /**
    * A URL fragment identifier, beginning with a #.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.hash
    */
   hash: Hash;
}

/**
 * An entry in a history stack. A location contains information about the
 * URL path, as well as possibly some arbitrary state and a key.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location
 */
export interface Location<S extends State = State> extends Path {
   /**
    * An object of arbitrary data associated with this location.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.state
    */
   state: S;

   /**
    * A unique string associated with this location. May be used to safely store
    * and retrieve data in some other storage API, like `localStorage`.
    *
    * Note: This value is always "default" on the initial location.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.key
    */
   key: Key;
}

/**
 * A partial Path object that may be missing some properties.
 */
export interface PartialPath {
   /**
    * The URL pathname, beginning with a /.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.pathname
    */
   pathname?: Pathname;

   /**
    * The URL search string, beginning with a ?.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.search
    */
   search?: Search;

   /**
    * The URL fragment identifier, beginning with a #.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.hash
    */
   hash?: Hash;
}

/**
 * A partial Location object that may be missing some properties.
 */
export interface PartialLocation<S extends State = State> extends PartialPath {
   /**
    * An object of arbitrary data associated with this location.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.state
    */
   state?: S;

   /**
    * A unique string associated with this location. May be used to safely store
    * and retrieve data in some other storage API, like `localStorage`.
    *
    * Note: This value is always "default" on the initial location.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#location.key
    */
   key?: Key;
}

/**
 * A change to the current location.
 */
export interface Update<S extends State = State> {
   /**
    * The action that triggered the change.
    */
   action: Action;

   /**
    * The new location.
    */
   location: Location<S>;
}

/**
 * A function that receives notifications about location changes.
 */
export interface Listener<S extends State = State> {
   (update: Update<S>): void;
}

/**
 * A change to the current location that was blocked. May be retried
 * after obtaining user confirmation.
 */
export interface Transition<S extends State = State> extends Update<S> {
   /**
    * Retries the update to the current location.
    */
   retry(): void;
}

/**
 * A function that receives transitions when navigation is blocked.
 */
export interface Blocker<S extends State = State> {
   (tx: Transition<S>): void;
}

/**
 * Describes a location that is the destination of some navigation, either via
 * `history.push` or `history.replace`. May be either a URL or the pieces of a
 * URL path.
 */
export type To = string | PartialPath;

/**
 * A history is an interface to the navigation stack. The history serves as the
 * source of truth for the current location, as well as provides a set of
 * methods that may be used to change it.
 *
 * It is similar to the DOM's `window.history` object, but with a smaller, more
 * focused API.
 */
export interface History<S extends State = State> {
   /**
    * The last action that modified the current location. This will always be
    * Action.Pop when a history instance is first created. This value is mutable.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.action
    */
   readonly action: Action;

   /**
    * The current location. This value is mutable.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.location
    */
   readonly location: Location<S>;

   /**
    * Returns a valid href for the given `to` value that may be used as
    * the value of an <a href> attribute.
    *
    * @param to - The destination URL
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.createHref
    */
   createHref(to: To): string;

   /**
    * Pushes a new location onto the history stack, increasing its length by one.
    * If there were any entries in the stack after the current one, they are
    * lost.
    *
    * @param to - The new URL
    * @param state - Data to associate with the new location
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.push
    */
   push(to: To, state?: S): void;

   /**
    * Replaces the current location in the history stack with a new one.  The
    * location that was replaced will no longer be available.
    *
    * @param to - The new URL
    * @param state - Data to associate with the new location
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.replace
    */
   replace(to: To, state?: S): void;

   /**
    * Navigates `n` entries backward/forward in the history stack relative to the
    * current index. For example, a "back" navigation would use go(-1).
    *
    * @param delta - The delta in the stack index
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.go
    */
   go(delta: number): void;

   /**
    * Navigates to the previous entry in the stack. Identical to go(-1).
    *
    * Warning: if the current location is the first location in the stack, this
    * will unload the current document.
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.back
    */
   back(): void;

   /**
    * Navigates to the next entry in the stack. Identical to go(1).
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.forward
    */
   forward(): void;

   /**
    * Sets up a listener that will be called whenever the current location
    * changes.
    *
    * @param listener - A function that will be called when the location changes
    * @returns unlisten - A function that may be used to stop listening
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.listen
    */
   listen(listener: Listener<S>): () => void;

   /**
    * Prevents the current location from changing and sets up a listener that
    * will be called instead.
    *
    * @param blocker - A function that will be called when a transition is blocked
    * @returns unblock - A function that may be used to stop blocking
    *
    * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#history.block
    */
   block(blocker: Blocker<S>): () => void;
}

/**
 * A browser history stores the current location in regular URLs in a web
 * browser environment. This is the standard for most web apps and provides the
 * cleanest URLs the browser's address bar.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#browserhistory
 */
export interface BrowserHistory<S extends State = State> extends History<S> {}

/**
 * A hash history stores the current location in the fragment identifier portion
 * of the URL in a web browser environment.
 *
 * This is ideal for apps that do not control the server for some reason
 * (because the fragment identifier is never sent to the server), including some
 * shared hosting environments that do not provide fine-grained controls over
 * which pages are served at which URLs.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#hashhistory
 */
export interface HashHistory<S extends State = State> extends History<S> {}

/**
 * A memory history stores locations in memory. This is useful in stateful
 * environments where there is no web browser, such as node tests or React
 * Native.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#memoryhistory
 */
export interface MemoryHistory<S extends State = State> extends History<S> {
   index: number;
}
const __DEV__ = false;
const readOnly: <T extends unknown>(obj: T) => T = __DEV__
   ? obj => Object.freeze(obj)
   : obj => obj;

function warning(cond: boolean, message: string) {
   if (!cond) {
      // eslint-disable-next-line no-console
      if (typeof console !== 'undefined') console.warn(message);

      try {
         // Welcome to debugging history!
         //
         // This error is thrown as a convenience so you can more easily
         // find the source for a warning that appears in the console by
         // enabling "pause on exceptions" in your JavaScript debugger.
         throw new Error(message);
         // eslint-disable-next-line no-empty
      } catch (e) {}
   }
}

////////////////////////////////////////////////////////////////////////////////
// BROWSER
////////////////////////////////////////////////////////////////////////////////

type HistoryState = {
   usr: State;
   key?: string;
   idx: number;
};

const BeforeUnloadEventType = 'beforeunload';
const HashChangeEventType = 'hashchange';
const PopStateEventType = 'popstate';

export type BrowserHistoryOptions = { window?: Window };

/**
 * Browser history stores the location in regular URLs. This is the standard for
 * most web apps, but it requires some configuration on the server to ensure you
 * serve the same app at multiple URLs.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#createbrowserhistory
 */
export function createBrowserHistory(
   options: BrowserHistoryOptions = {}
): BrowserHistory {
   let { window = document.defaultView! } = options;
   let globalHistory = window.history;

   function getIndexAndLocation(): [number, Location] {
      let { pathname, search, hash } = window.location;
      let state = globalHistory.state || {};
      return [
         state.idx,
         readOnly<Location>({
            pathname,
            search,
            hash,
            state: state.usr || null,
            key: state.key || 'default'
         })
      ];
   }

   let blockedPopTx: Transition | null = null;
   function handlePop() {
      if (blockedPopTx) {
         blockers.call(blockedPopTx);
         blockedPopTx = null;
      } else {
         let nextAction = Action.Pop;
         let [nextIndex, nextLocation] = getIndexAndLocation();

         if (blockers.length) {
            if (nextIndex != null) {
               let delta = index - nextIndex;
               if (delta) {
                  // Revert the POP
                  blockedPopTx = {
                     action: nextAction,
                     location: nextLocation,
                     retry() {
                        go(delta * -1);
                     }
                  };

                  go(delta);
               }
            } else {
               // Trying to POP to a location with no index. We did not create
               // this location, so we can't effectively block the navigation.
               warning(
                  false,
                  // TODO: Write up a doc that explains our blocking strategy in
                  // detail and link to it here so people can understand better what
                  // is going on and how to avoid it.
                  `You are trying to block a POP navigation to a location that was not ` +
                  `created by the history library. The block will fail silently in ` +
                  `production, but in general you should do all navigation with the ` +
                  `history library (instead of using window.history.pushState directly) ` +
                  `to avoid this situation.`
               );
            }
         } else {
            applyTx(nextAction);
         }
      }
   }

   window.addEventListener(PopStateEventType, handlePop);

   let action = Action.Pop;
   let [index, location] = getIndexAndLocation();
   let listeners = createEvents<Listener>();
   let blockers = createEvents<Blocker>();

   if (index == null) {
      index = 0;
      globalHistory.replaceState({ ...globalHistory.state, idx: index }, '');
   }

   function createHref(to: To) {
      return typeof to === 'string' ? to : createPath(to);
   }

   function getNextLocation(to: To, state: State = null): Location {
      return readOnly<Location>({
         ...location,
         ...(typeof to === 'string' ? parsePath(to) : to),
         state,
         key: createKey()
      });
   }

   function getHistoryStateAndUrl(
      nextLocation: Location,
      index: number
   ): [HistoryState, string] {
      return [
         {
            usr: nextLocation.state,
            key: nextLocation.key,
            idx: index
         },
         createHref(nextLocation)
      ];
   }

   function allowTx(action: Action, location: Location, retry: () => void) {
      return (
         !blockers.length || (blockers.call({ action, location, retry }), false)
      );
   }

   function applyTx(nextAction: Action) {
      action = nextAction;
      [index, location] = getIndexAndLocation();
      listeners.call({ action, location });
   }

   function push(to: To, state?: State) {
      let nextAction = Action.Push;
      let nextLocation = getNextLocation(to, state);
      function retry() {
         push(to, state);
      }

      if (allowTx(nextAction, nextLocation, retry)) {
         let [historyState, url] = getHistoryStateAndUrl(nextLocation, index + 1);

         // TODO: Support forced reloading
         // try...catch because iOS limits us to 100 pushState calls :/
         try {
            globalHistory.pushState(historyState, '', url);
         } catch (error) {
            // They are going to lose state here, but there is no real
            // way to warn them about it since the page will refresh...
            window.location.assign(url);
         }

         applyTx(nextAction);
      }
   }

   function replace(to: To, state?: State) {
      let nextAction = Action.Replace;
      let nextLocation = getNextLocation(to, state);
      function retry() {
         replace(to, state);
      }

      if (allowTx(nextAction, nextLocation, retry)) {
         let [historyState, url] = getHistoryStateAndUrl(nextLocation, index);

         // TODO: Support forced reloading
         globalHistory.replaceState(historyState, '', url);

         applyTx(nextAction);
      }
   }

   function go(delta: number) {
      globalHistory.go(delta);
   }

   let history: BrowserHistory = {
      get action() {
         return action;
      },
      get location() {
         return location;
      },
      createHref,
      push,
      replace,
      go,
      back() {
         go(-1);
      },
      forward() {
         go(1);
      },
      listen(listener) {
         return listeners.push(listener);
      },
      block(blocker) {
         let unblock = blockers.push(blocker);

         if (blockers.length === 1) {
            window.addEventListener(BeforeUnloadEventType, promptBeforeUnload);
         }

         return function() {
            unblock();

            // Remove the beforeunload listener so the document may
            // still be salvageable in the pagehide event.
            // See https://html.spec.whatwg.org/#unloading-documents
            if (!blockers.length) {
               window.removeEventListener(BeforeUnloadEventType, promptBeforeUnload);
            }
         };
      }
   };

   return history;
}

////////////////////////////////////////////////////////////////////////////////
// HASH
////////////////////////////////////////////////////////////////////////////////

export type HashHistoryOptions = { window?: Window };

/**
 * Hash history stores the location in window.location.hash. This makes it ideal
 * for situations where you don't want to send the location to the server for
 * some reason, either because you do cannot configure it or the URL space is
 * reserved for something else.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#createhashhistory
 */
export function createHashHistory(
   options: HashHistoryOptions = {}
): HashHistory {
   let { window = document.defaultView! } = options;
   let globalHistory = window.history;

   function getIndexAndLocation(): [number, Location] {
      let { pathname = '/', search = '', hash = '' } = parsePath(
         window.location.hash.substr(1)
      );
      let state = globalHistory.state || {};
      return [
         state.idx,
         readOnly<Location>({
            pathname,
            search,
            hash,
            state: state.usr || null,
            key: state.key || 'default'
         })
      ];
   }

   let blockedPopTx: Transition | null = null;
   function handlePop() {
      if (blockedPopTx) {
         blockers.call(blockedPopTx);
         blockedPopTx = null;
      } else {
         let nextAction = Action.Pop;
         let [nextIndex, nextLocation] = getIndexAndLocation();

         if (blockers.length) {
            if (nextIndex != null) {
               let delta = index - nextIndex;
               if (delta) {
                  // Revert the POP
                  blockedPopTx = {
                     action: nextAction,
                     location: nextLocation,
                     retry() {
                        go(delta * -1);
                     }
                  };

                  go(delta);
               }
            } else {
               // Trying to POP to a location with no index. We did not create
               // this location, so we can't effectively block the navigation.
               warning(
                  false,
                  // TODO: Write up a doc that explains our blocking strategy in
                  // detail and link to it here so people can understand better
                  // what is going on and how to avoid it.
                  `You are trying to block a POP navigation to a location that was not ` +
                  `created by the history library. The block will fail silently in ` +
                  `production, but in general you should do all navigation with the ` +
                  `history library (instead of using window.history.pushState directly) ` +
                  `to avoid this situation.`
               );
            }
         } else {
            applyTx(nextAction);
         }
      }
   }

   window.addEventListener(PopStateEventType, handlePop);

   // popstate does not fire on hashchange in IE 11 and old (trident) Edge
   // https://developer.mozilla.org/de/docs/Web/API/Window/popstate_event
   window.addEventListener(HashChangeEventType, () => {
      let [, nextLocation] = getIndexAndLocation();

      // Ignore extraneous hashchange events.
      if (createPath(nextLocation) !== createPath(location)) {
         handlePop();
      }
   });

   let action = Action.Pop;
   let [index, location] = getIndexAndLocation();
   let listeners = createEvents<Listener>();
   let blockers = createEvents<Blocker>();

   if (index == null) {
      index = 0;
      globalHistory.replaceState({ ...globalHistory.state, idx: index }, '');
   }

   function getBaseHref() {
      let base = document.querySelector('base');
      let href = '';

      if (base && base.getAttribute('href')) {
         let url = window.location.href;
         let hashIndex = url.indexOf('#');
         href = hashIndex === -1 ? url : url.slice(0, hashIndex);
      }

      return href;
   }

   function createHref(to: To) {
      return getBaseHref() + '#' + (typeof to === 'string' ? to : createPath(to));
   }

   function getNextLocation(to: To, state: State = null): Location {
      return readOnly<Location>({
         ...location,
         ...(typeof to === 'string' ? parsePath(to) : to),
         state,
         key: createKey()
      });
   }

   function getHistoryStateAndUrl(
      nextLocation: Location,
      index: number
   ): [HistoryState, string] {
      return [
         {
            usr: nextLocation.state,
            key: nextLocation.key,
            idx: index
         },
         createHref(nextLocation)
      ];
   }

   function allowTx(action: Action, location: Location, retry: () => void) {
      return (
         !blockers.length || (blockers.call({ action, location, retry }), false)
      );
   }

   function applyTx(nextAction: Action) {
      action = nextAction;
      [index, location] = getIndexAndLocation();
      listeners.call({ action, location });
   }

   function push(to: To, state?: State) {
      let nextAction = Action.Push;
      let nextLocation = getNextLocation(to, state);
      function retry() {
         push(to, state);
      }

      warning(
         nextLocation.pathname.charAt(0) === '/',
         `Relative pathnames are not supported in hash history.push(${JSON.stringify(
            to
         )})`
      );

      if (allowTx(nextAction, nextLocation, retry)) {
         let [historyState, url] = getHistoryStateAndUrl(nextLocation, index + 1);

         // TODO: Support forced reloading
         // try...catch because iOS limits us to 100 pushState calls :/
         try {
            globalHistory.pushState(historyState, '', url);
         } catch (error) {
            // They are going to lose state here, but there is no real
            // way to warn them about it since the page will refresh...
            window.location.assign(url);
         }

         applyTx(nextAction);
      }
   }

   function replace(to: To, state?: State) {
      let nextAction = Action.Replace;
      let nextLocation = getNextLocation(to, state);
      function retry() {
         replace(to, state);
      }

      warning(
         nextLocation.pathname.charAt(0) === '/',
         `Relative pathnames are not supported in hash history.replace(${JSON.stringify(
            to
         )})`
      );

      if (allowTx(nextAction, nextLocation, retry)) {
         let [historyState, url] = getHistoryStateAndUrl(nextLocation, index);

         // TODO: Support forced reloading
         globalHistory.replaceState(historyState, '', url);

         applyTx(nextAction);
      }
   }

   function go(delta: number) {
      globalHistory.go(delta);
   }

   let history: HashHistory = {
      get action() {
         return action;
      },
      get location() {
         return location;
      },
      createHref,
      push,
      replace,
      go,
      back() {
         go(-1);
      },
      forward() {
         go(1);
      },
      listen(listener) {
         return listeners.push(listener);
      },
      block(blocker) {
         let unblock = blockers.push(blocker);

         if (blockers.length === 1) {
            window.addEventListener(BeforeUnloadEventType, promptBeforeUnload);
         }

         return function() {
            unblock();

            // Remove the beforeunload listener so the document may
            // still be salvageable in the pagehide event.
            // See https://html.spec.whatwg.org/#unloading-documents
            if (!blockers.length) {
               window.removeEventListener(BeforeUnloadEventType, promptBeforeUnload);
            }
         };
      }
   };

   return history;
}

////////////////////////////////////////////////////////////////////////////////
// MEMORY
////////////////////////////////////////////////////////////////////////////////

/**
 * A user-supplied object that describes a location. Used when providing
 * entries to `createMemoryHistory` via its `initialEntries` option.
 */
export type InitialEntry = string | PartialLocation;

export type MemoryHistoryOptions = {
   initialEntries?: InitialEntry[];
   initialIndex?: number;
};

/**
 * Memory history stores the current location in memory. It is designed for use
 * in stateful non-browser environments like tests and React Native.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#creatememoryhistory
 */
export function createMemoryHistory(
   options: MemoryHistoryOptions = {}
): MemoryHistory {
   let { initialEntries = ['/'], initialIndex } = options;
   let entries: Location[] = initialEntries.map(entry => {
      let location = readOnly<Location>({
         pathname: '/',
         search: '',
         hash: '',
         state: null,
         key: createKey(),
         ...(typeof entry === 'string' ? parsePath(entry) : entry)
      });

      warning(
         location.pathname.charAt(0) === '/',
         `Relative pathnames are not supported in createMemoryHistory({ initialEntries }) (invalid entry: ${JSON.stringify(
            entry
         )})`
      );

      return location;
   });
   let index = clamp(
      initialIndex == null ? entries.length - 1 : initialIndex,
      0,
      entries.length - 1
   );

   let action = Action.Pop;
   let location = entries[index];
   let listeners = createEvents<Listener>();
   let blockers = createEvents<Blocker>();

   function createHref(to: To) {
      return typeof to === 'string' ? to : createPath(to);
   }

   function getNextLocation(to: To, state: State = null): Location {
      return readOnly<Location>({
         ...location,
         ...(typeof to === 'string' ? parsePath(to) : to),
         state,
         key: createKey()
      });
   }

   function allowTx(action: Action, location: Location, retry: () => void) {
      return (
         !blockers.length || (blockers.call({ action, location, retry }), false)
      );
   }

   function applyTx(nextAction: Action, nextLocation: Location) {
      action = nextAction;
      location = nextLocation;
      listeners.call({ action, location });
   }

   function push(to: To, state?: State) {
      let nextAction = Action.Push;
      let nextLocation = getNextLocation(to, state);
      function retry() {
         push(to, state);
      }

      warning(
         location.pathname.charAt(0) === '/',
         `Relative pathnames are not supported in memory history.push(${JSON.stringify(
            to
         )})`
      );

      if (allowTx(nextAction, nextLocation, retry)) {
         index += 1;
         entries.splice(index, entries.length, nextLocation);
         applyTx(nextAction, nextLocation);
      }
   }

   function replace(to: To, state?: State) {
      let nextAction = Action.Replace;
      let nextLocation = getNextLocation(to, state);
      function retry() {
         replace(to, state);
      }

      warning(
         location.pathname.charAt(0) === '/',
         `Relative pathnames are not supported in memory history.replace(${JSON.stringify(
            to
         )})`
      );

      if (allowTx(nextAction, nextLocation, retry)) {
         entries[index] = nextLocation;
         applyTx(nextAction, nextLocation);
      }
   }

   function go(delta: number) {
      let nextIndex = clamp(index + delta, 0, entries.length - 1);
      let nextAction = Action.Pop;
      let nextLocation = entries[nextIndex];
      function retry() {
         go(delta);
      }

      if (allowTx(nextAction, nextLocation, retry)) {
         index = nextIndex;
         applyTx(nextAction, nextLocation);
      }
   }

   let history: MemoryHistory = {
      get index() {
         return index;
      },
      get action() {
         return action;
      },
      get location() {
         return location;
      },
      createHref,
      push,
      replace,
      go,
      back() {
         go(-1);
      },
      forward() {
         go(1);
      },
      listen(listener) {
         return listeners.push(listener);
      },
      block(blocker) {
         return blockers.push(blocker);
      }
   };

   return history;
}

////////////////////////////////////////////////////////////////////////////////
// UTILS
////////////////////////////////////////////////////////////////////////////////

function clamp(n: number, lowerBound: number, upperBound: number) {
   return Math.min(Math.max(n, lowerBound), upperBound);
}

function promptBeforeUnload(event: BeforeUnloadEvent) {
   // Cancel the event.
   event.preventDefault();
   // Chrome (and legacy IE) requires returnValue to be set.
   event.returnValue = '';
}

type Events<F> = {
   length: number;
   push: (fn: F) => () => void;
   call: (arg: any) => void;
};

function createEvents<F extends Function>(): Events<F> {
   let handlers: F[] = [];

   return {
      get length() {
         return handlers.length;
      },
      push(fn: F) {
         handlers.push(fn);
         return function() {
            handlers = handlers.filter(handler => handler !== fn);
         };
      },
      call(arg) {
         handlers.forEach(fn => fn && fn(arg));
      }
   };
}

function createKey() {
   return Math.random()
      .toString(36)
      .substr(2, 8);
}

/**
 * Creates a string URL path from the given pathname, search, and hash components.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#createpath
 */
export function createPath({
                              pathname = '/',
                              search = '',
                              hash = ''
                           }: PartialPath) {
   return pathname + search + hash;
}

/**
 * Parses a string URL path into its separate pathname, search, and hash components.
 *
 * @see https://github.com/ReactTraining/history/tree/master/docs/api-reference.md#parsepath
 */
export function parsePath(path: string) {
   let partialPath: PartialPath = {};

   if (path) {
      let hashIndex = path.indexOf('#');
      if (hashIndex >= 0) {
         partialPath.hash = path.substr(hashIndex);
         path = path.substr(0, hashIndex);
      }

      let searchIndex = path.indexOf('?');
      if (searchIndex >= 0) {
         partialPath.search = path.substr(searchIndex);
         path = path.substr(0, searchIndex);
      }

      if (path) {
         partialPath.pathname = path;
      }
   }

   return partialPath;
}