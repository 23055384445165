export enum CalendarClasses {
  Active = "active",
  Event = "event",
  Calendar = "calendar",
  CalendarBody = "calendar-body",
  CalendarDate = "calendar-date",
  CalendarEvent = "calendar-event",
  CalendarEvents = "calendar-events",
  CalendarHeader = "calendar-header",
  CalendarLg = "calendar-lg",
  CalendarArrowIcon = "calendar-arrow_icon",
  CalendarNav = "calendar-nav",
  CalendarRange = "calendar-range",
  DateItem = "date-item",
  DateToday = "date-today",
  Disabled = "disabled",
  DisabledAction = "disabled-action",
  RangeEnd = "range-end",
  RangeStart = "range-start",
  Container = "calendar-container",
  TimeIcon = "time-icon",
  CalendarTime = "calendar-time",
  CurrentMonth = "current-month",
  YearItem = "year-item",
  MonthItem = "month-item",
  Years = "years",
}
