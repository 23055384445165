import { UseFieldConfig } from "@relcu/form";
import { omit }           from "../../../utils/helpers";
import { pick }           from "../../../utils/helpers";

export function getFieldProps<T, V = any>(props: UseFieldConfig<V>): [UseFieldConfig<V>, T] {
  const properties = [
    "afterSubmit", "allowNull", "beforeSubmit", "data", "defaultValue", "format", "formatOnBlur",
    "initialValue", "isEqual", "custom", "multiple", "parse", "subscription", "type", "validate", "validateFields", "value", "override", "edit"
  ];
  const config = pick(props, properties);
  const input = omit(props, properties);
  return [config, input as T];
}
