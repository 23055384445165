import React                    from "react";
import { useMemo }              from "react";
import { Form }                 from "@relcu/form";
import { Field }                from "@relcu/form";
import { VerticalDivider }      from "@relcu/ui";
import { deepPick }             from "@relcu/ui";
import { formUtils }            from "@relcu/ui";
import { ButtonVariants }       from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { ModalFooter }          from "@relcu/ui";
import { SwitchField }          from "@relcu/ui";
import { TextField }            from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { Modal }                from "@relcu/ui";
import { defaultMutators }      from "@relcu/ui";
import { Tooltip }              from "@relcu/ui";
import { ModalBody }            from "@relcu/ui";
import { Alignment }            from "@relcu/ui";
import { ToggleSwitchField }    from "@relcu/ui";
import { FontIcon }             from "@relcu/ui";
import { PointerField }         from "../../Field/PointerField";
import { useJqlForm }           from "../../Jql/JqlForm";
import { SchemaDialogProps }    from "../SchemaDialog";
import { useSchemaDialog }      from "../SchemaDialog";
import { MailboxDialogClasses } from "./MailboxDialogClasses";
import "./mailbox-dialog.css";

export interface MailboxDialogProps extends SchemaDialogProps {

}

export const MailboxDialog = React.memo<MailboxDialogProps>(function MailboxDialog(props) {
    const {
      initialValues,
      user,
      sections,
      jql,
      formProps,
      action,
      className,
      afterSubmit,
      loading,
      title,
      ...modal
    } = useSchemaDialog(props);
    const initial = useMemo(() => {
      const fields = formUtils.getFieldNames(sections);
      return deepPick(initialValues || {}, fields);
    }, [initialValues]);
    const jqlForm = useJqlForm({
      jql, className, sections, initialValues,
      afterSubmit: () => props.onClose(), onError: () => ({ "account": "This prefix already exists for this domain" })
    });

    const onSubmit = React.useCallback((values, formApi) => {
      if (!values.common && !values.assignedTo) {
        return {
          "assignedTo": "Assigned to is required."
        };
      }

      if (values.common && (values.assignedTo != null)) {
        values.assignedTo = null;
      }

      if (!values.defaultRecipient) {
        values.defaultRecipient = null;
      }

      values.address = `${values.account}@${values.domain.objectName}`;
      return jqlForm.onSubmit(values, formApi);
    }, [jqlForm.onSubmit]);

    return (
      <Modal
        {...modal}
        variant={"middle"}
        open={props.open}
        onClose={props.onClose}
        title={`${action === "save" ? "Edit" : "Create"} section`}
        closeIcon={false}
        disableBackdropClick={false}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={initial}
          subscription={{ submitting: true, values: true }}
          mutators={defaultMutators}>
          {
            ({ form, values, handleSubmit, submitting }) => {
              return <form
                onSubmit={handleSubmit}
                noValidate
                style={{ display: "contents" }}>
                <ModalBody style={{ position: "relative" }} container direction={"column"} gap={"XS"} flexGrow={1}
                           className="base-dialog-container">

                  <Box gap={"XS"} container direction="row" style={{ padding: "24px 8px" }}>
                    <Box container direction={"column"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                      <h2>Details</h2>
                      <Box container direction={"row"} alignItems={"end"}>
                        <TextField
                          name={`account`}
                          halfSize
                          label={"Email prefix"}
                          required={true}
                          placeholder={"Email prefix"}
                          flex={1}
                        />
                        <Box>
                          <FontIcon type="alternate_email" className={MailboxDialogClasses.SecColor}/>
                        </Box>
                        <PointerField
                          placeholder={"Select domain"}
                          required={true}
                          flexBasis={"30%"}
                          thumbnail={false}
                          filters={{
                            valid: { equalTo: true }
                          }}
                          name={"domain"}
                          targetClass={"MailDomain"}/>
                      </Box>
                      <Box container gap={"XXXXS"} alignItems={"center"}>
                        <SwitchField name={"common"} placeholder={"Common email"} label={"Set as common"}/>
                        <Tooltip
                          title={"The common email will be available for all assigned users to send and receive emails."}
                          alignment={Alignment.Top}>
                          <FontIcon type={"info"} className={MailboxDialogClasses.SecColor}/>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box container direction={"row"} gap={"XS"}>
                      <VerticalDivider className={MailboxDialogClasses.VertDevider}/>
                    </Box>
                    <Box container direction={"column"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                      <Field name={"common"} subscription={{ value: true }}>
                        {({ input }) => {
                          if (input.value && values.assignedTo?.__typename == "User") {
                            values.assignedTo = "";
                          }
                          return <Box container gap={"XXXXS"} alignItems={"center"}>
                            <PointerField placeholder={input.value ? "Select campaign" : "Select user or campaign"}
                                          label={"Assigned to"}
                                          required={!input.value}
                                          flexGrow={1}
                                          name={"assignedTo"}
                                          targetClass={input.value ? ["LeadSource"] : ["User", "LeadSource"]}/>
                            <Tooltip
                              title={"If campaign is selected, unassigned emails will generate a lead."}
                              alignment={Alignment.TopRight}>
                              <FontIcon type={"info"} className={MailboxDialogClasses.SecColor}/>
                            </Tooltip>
                          </Box>;
                        }}
                      </Field>
                      <Field name={"common"} subscription={{ value: true }}>
                        {({ input }) => (
                          <Field name={"assignedTo"} subscription={{ value: true }}>
                            {({ input: inp }) => {
                              const type = inp.value?.__typename;
                              return input.value && <Box container gap={"XXXXS"} alignItems={"center"}>
                                <PointerField placeholder={"Select user"}
                                              label={"Default recipient"}
                                              name={"defaultRecipient"}
                                              flexGrow={1}
                                              disabled={type == "LeadSource"}
                                              targetClass={"User"}/>
                                <Tooltip
                                  title={"Select a default recipient to not lose the emails that are not associated with anyone."}
                                  alignment={Alignment.TopRight}>
                                  <FontIcon type={"info"} className={MailboxDialogClasses.SecColor}/>
                                </Tooltip>
                              </Box>;
                            }}
                          </Field>
                        )}
                      </Field>
                    </Box>
                  </Box>
                  {props.action == "save" &&
                    <Box gap={"XS"} container direction="column" style={{ padding: "24px 8px" }}>
                      <h2>General</h2>
                      <ToggleSwitchField name={"disabled"} label={"Disable account"}/>
                    </Box>}
                </ModalBody>
                <ModalFooter>
                  <Button disabled={submitting} type="button" variant={ButtonVariants.Outline}
                          onClick={props.onClose}>CANCEL</Button>
                  <Button disabled={submitting} type="submit">{action.toUpperCase()}</Button>
                </ModalFooter>
              </form>;
            }
          }
        </Form>
      </Modal>
    );
  }
);
