import { UseFieldConfig } from "@relcu/form";
import { useForm }        from "@relcu/form";
import { useField }       from "@relcu/form";
import { useEffect }      from "react";
import { useMemo }        from "react";
import { BaseFieldProps } from "./BaseFieldProps";
import { getFieldProps }  from "./getFieldProps";

export function useBaseField<T extends BaseFieldProps>(options: T, mergeOptions?: UseFieldConfig<any>) {
  const props = {
    ...options,
    ...mergeOptions
  };
  const [config, properties] = useMemo(() => getFieldProps<T>(props), [props]);
  const form = useForm();
  const field = useField(props.name, {
    ...config,
    validate(value, allValues, meta?) {
      if (props.required && ((!value && value !== 0) || (Array.isArray(value) && value.length == 0))) {
        return `${props.label || "Field"} is required.`;
      }
      if (config.validate) {
        return config.validate(value, allValues, meta);
      }
    }
  });

  useEffect(() => form.mutators?.setFieldData?.(field.input.name, { warning: props.warning }), [props.warning, props.name]);
  useEffect(() => form.mutators.mount?.(props.name), [form, props.name]);
  return {
    ...field,
    input: {
      ...field.input,
      ...properties,
      onBlur(e) {
        field.input.onBlur(e);
        properties.onBlur?.(e);
      },
      onFocus(e) {
        field.input.onFocus(e);
        properties.onFocus?.(e);
      },
      disabled: properties?.disabled || properties?.[ "properties" ]?.disabled || field.meta.submitting
    }
  };
}
