import React                        from "react";
import { FC }                       from "react";
import { ListView as BaseListView } from "@relcu/ui";
import { JsonViewProps }            from "@relcu/ui";
import { ListDraggableContext }     from "@relcu/ui";
import Filter                       from "../../../Filter";
import { useList }                  from "./useList";

export const ListView: FC<JsonViewProps> = React.memo(function ListView(props) {
  const { modalContext, filter, onRowDrop, onRowMove, draggable, ...api } = useList(props);
  const filterElement = !api.hideFilter && <Filter value={filter}/>;
  return (
    <ListDraggableContext.Provider value={{ onRowDrop, onRowMove, draggable }}>
      {modalContext}
      <BaseListView {...api} filter={filterElement}/>
    </ListDraggableContext.Provider>
  );
});
