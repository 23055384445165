import { useField }            from "@relcu/form";
import { CurrencyField }       from "@relcu/ui";
import { useContext }          from "react";
import React                   from "react";
import { format }              from "../../../../../../utils/helpers";
import { isPositiveInteger }   from "../../../../../../utils/helpers";
import { LoanCriteriaContext } from "../LoanCriteriaProvider";

export const DownPaymentField = React.memo(function DownPaymentField() {
  const { calculate, product: { type } ,settings} = useContext(LoanCriteriaContext);
  const { input: { value: propertyValue } } = useField("property.value", { subscription: { value: true } });
  const { input: { value: downPayment } } = useField("downPayment", { subscription: { value: true } });
  const minPercent = settings[ type ]?.purchase?.minDownPaymentPercent;
  const minDownPayment = propertyValue * minPercent / 100;
  const warning = minPercent && (downPayment < minDownPayment);
  return <CurrencyField view={"write"} validate={value => isPositiveInteger(value)} required={true} onBlur={() => calculate("downPayment")}
                        warning={warning ? `Must be at least $${format(minDownPayment, 2)}` : undefined}
                        flex={1}
                        name={"downPayment"}
                        label={"Down Payment"} placeholder={"Enter down payment"} />;
});
