import { useQuery }                              from "@apollo/client";
import { useMutation }                           from "@apollo/client";
import { gql }                                   from "@apollo/client";
import { useEffect }                             from "react";
import { SubscriptionEvent }                     from "../../../../types/graphql-global-types";
import { DeleteOfferVariables }                  from "./__types__/DeleteOffer";
import { DeleteOffer }                           from "./__types__/DeleteOffer";
import { GetPendingOffersByLeadVariables }       from "./__types__/GetPendingOffersByLead";
import { GetPendingOffersByLead }                from "./__types__/GetPendingOffersByLead";
import { SubscribePendingOffersByLeadVariables } from "./__types__/SubscribePendingOffersByLead";
import { SubscribePendingOffersByLead }          from "./__types__/SubscribePendingOffersByLead";
import { OFFER_FRAGMENT }                        from "./operations.graphql";

export function usePendingOffers(id: string) {
  const [remove] = useMutation<DeleteOffer, DeleteOfferVariables>(DELETE_OFFER);
  const {
    data: {
      offers: { edges = [], count: offersCount } = {}
    } = {},
    loading: offersLoading, subscribeToMore
  } = useQuery<GetPendingOffersByLead, GetPendingOffersByLeadVariables>(
    GET_PENDING_OFFERS_BY_LEAD, {
      variables: { id }
    });
  const subscribeToMoreOffers = () => {
    return subscribeToMore<SubscribePendingOffersByLead, SubscribePendingOffersByLeadVariables>({
      document: SUBSCRIBE_PENDING_OFFERS_BY_LEAD,
      variables: { id },
      updateQuery(previousQueryResult, { subscriptionData: { data: { offers: { event, node } } } }) {
        switch (event) {
          case SubscriptionEvent.ENTER:
          case SubscriptionEvent.CREATE:
            let index = previousQueryResult.offers.edges.findIndex(({ node: o }) => (o.id == node.id));
            if (index > -1) {
              return previousQueryResult;
            }
            return {
              ...previousQueryResult,
              offers: {
                ...previousQueryResult.offers,
                count: previousQueryResult.offers.count + 1,
                edges: [
                  {
                    node,
                    __typename: "OfferEdge"
                  },
                  ...previousQueryResult.offers.edges
                ]
              }
            };
          case SubscriptionEvent.LEAVE:
            let offers = previousQueryResult.offers.edges.filter(edge => edge.node.id !== node.id);
            return {
              ...previousQueryResult,
              offers: {
                ...previousQueryResult.offers,
                edges: offers,
                count: offers.length
              }
            };
        }
      }
    });
  };

  useEffect(subscribeToMoreOffers, [id]);

  return {
    remove,
    edges,
    offers: edges.map(({ node }) => node),
    offerIds: edges.map(({ node }) => node.objectId),
    count: offersCount,
    loading: offersLoading
  };
}

export const GET_PENDING_OFFERS_BY_LEAD = gql`
  ${OFFER_FRAGMENT}
  query GetPendingOffersByLead($id: ID!) {
    offers(where: {
      deleted: {notEqualTo: true}
      loanProposal: {exists: false}
      lead: {
        have: {
          id: {
            equalTo: $id
          }
        }
      }
    }, order: [createdAt_DESC]) {
      edges {
        node {
          ...Offer
        }
      }
      count
    }
  }
`;
export const SUBSCRIBE_PENDING_OFFERS_BY_LEAD = gql`
  subscription SubscribePendingOffersByLead($id: ID!) {
    offers(
      events: [CREATE,LEAVE,ENTER]
      where: {
        deleted: {notEqualTo: true}
        loanProposal: {exists: false}
        lead: {
          have: {
            link: $id
          }
        }
      }) {
      event
      node {
        ...Offer
      }
    }
  }
  ${OFFER_FRAGMENT}
`;

export const DELETE_OFFER = gql`
  mutation DeleteOffer($id: ID!) {
    updateOffer(input: {id: $id,fields: {deleted: true}}){
      offer{
        id
        deleted
      }
    }
  }
`;
