import { gql }                     from "@apollo/client";
import { EMAIL_TEMPLATE_FRAGMENT } from "../../../operations.graphql";

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateMailTemplate ($input:UpdateEmailTemplateInput!) {
    updateEmailTemplate (input: $input) {
      emailTemplate {
        objectId
        id
        title
      }
    }
  }
`;

//TODO CHANGE MAIL TO EMAIL
export const GET_MAIL_TEMPLATES = gql`
  query GetMailTemplates ($where:EmailTemplateWhereInput,$skip:Int,$before:String,$after:String,$from:String,$first:Int,$last:Int) {
    emailTemplates (
      where: $where,
      order: [createdAt_DESC, objectId_ASC],
      first: $first,
      last: $last,
      skip:$skip
      before:$before
      after:$after,
      from:$from,
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...EmailTemplate
        }
      }
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;
//TODO CHANGE MAIL TO EMAIL
export const SUBSCRIBE_MAIL_TEMPLATES = gql`
  subscription SubscribeMailTemplates($where: EmailTemplateSubscriptionWhereInput!) {
    emailTemplates(events: [CREATE, ENTER,LEAVE, UPDATE], where: $where){
      event
      node {
        ...EmailTemplate
      }
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const DUPLICATE_EMAIL_TEMPLATE = gql`
  mutation DuplicateEmailTemplate($input: CreateEmailTemplateInput!) {
    createEmailTemplate(input: $input) {
      emailTemplate {
        ...EmailTemplate
      }
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const REMOVE_EMAIL_TEMPLATE = gql`
  mutation RemoveEmailTemplate($id: ID!) {
    deleteEmailTemplate(input: {id: $id}) {
      emailTemplate {
        id
      }
    }
  }
`;
