import React                from "react";
import { FC }               from "react";
import { ButtonSizes }      from "@relcu/ui";
import { ButtonColors }     from "@relcu/ui";
import { ListActions }      from "@relcu/ui";
import { Box }              from "@relcu/ui";
import { EmptyList }        from "@relcu/ui";
import { ListClasses }      from "@relcu/ui";
import { ListItemDate }     from "@relcu/ui";
import { ListItemSwitch }   from "@relcu/ui";
import { ListRow }          from "@relcu/ui";
import { JsonViewProps }    from "@relcu/ui";
import { ListItemText }     from "@relcu/ui";
import { ListRowColors }    from "@relcu/ui";
import { Tooltip }          from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { ButtonGroup }      from "@relcu/ui";
import { CircularLoader }   from "@relcu/ui";
import { ButtonVariants }   from "@relcu/ui";
import { ListViewClasses }  from "@relcu/ui";
import { ListRowClasses }   from "@relcu/ui";
import { classNames }       from "@relcu/ui";
import { useAppraisalList } from "./useAppraisalList";

export const AppraisalTypeMap = {
  "conventional": "Conventional",
  "va": "VA",
  "fha": "FHA",
  "jumbo": "Jumbo",
  "nonConf": "Non Conforming"
};

export const AppraisalListView: FC<JsonViewProps> = React.memo(function AppraisalListView(props) {
    const { isEmpty, data, onPreview, onRemove, onEdit, appraisalModalContext, importFees, handleDownload, setActive, active, appraisalFeeModalContext } = useAppraisalList(props);

    return (<>
      {appraisalFeeModalContext}
      {appraisalModalContext}
      <Box container direction={"column"} gap={"XXS"} flexGrow={1} className={ListViewClasses.ListView}>
        <Box container justify={!props.title ? "end" : "space-between"} flexShrink={0} alignItems={"center"}>
          {props.title &&
          <h2 className={ListClasses.ListHeader}>
            {props.title}
          </h2>
          }
          {!isEmpty &&
          <Button alignSelf={"end"} variant={ButtonVariants.Ghost} icon={"add"} onClick={importFees}>Import fees</Button>}
        </Box>
        <Box container direction={"column"} flexGrow={1} gap={"XXS"}>
          {data.loading ?
            <CircularLoader alignSelf={"center"}/>
            :
            <>
              {isEmpty ?
                <Box container
                     flex={1}
                     justify={"center"}
                     direction={"column"}
                     gap={"XXS"}>
                  <EmptyList icon={props.icon} title={"You have not imported appraisal fees yet."}
                             content={"Click on the import button to start uploading the appraisal fees and make it accessible for all other users."}
                             alignSelf={"center"}
                             style={{ margin: "24px 0" }}/>
                  <Button alignSelf={"center"} variant={ButtonVariants.Ghost} icon={"add"} onClick={importFees}>Import
                    fees</Button>
                </Box>
                :
                <Box container
                     flex={1}
                     justify={"start"}
                     direction={"column"}
                     gap={"XXS"}>
                  <ListRow className={ListClasses.ListRowHead} gap={"XXS"} color={ListRowColors.White} container
                           justify={"space-between"}>
                    {props.headers.map((headerItem, index) => {
                      return (<ListItemText
                        key={index}
                        tiny={true}
                        text={headerItem.title}
                        flexBasis={headerItem.flexBasis}
                        {...headerItem}
                        {...headerItem.properties}
                      />);
                    })}
                  </ListRow>
                  {data.edges.map(({ node: { objectName, enabled, createdAt, uploadedFiles, objectId } }, index) => {
                      return <ListRow className={classNames({ [ ListRowClasses.ActionSticky ]: objectId == active })}
                                      key={index} gap={"XS"} justify={"space-between"}>
                        <ListItemText
                          flexBasis={145}
                          text={objectName}
                        />
                        <ListItemSwitch
                          key={index}
                          label={"Enabled"}
                          flexBasis={145}
                          value={enabled}/>
                        <ListItemDate
                          flexBasis={200}
                          date={createdAt}
                          format="withTime"/>
                        <ListActions>
                          <ButtonGroup collapse size={ButtonSizes.Small} color={ButtonColors.White} tooltip={"Preview"}
                                       buttonMenu onOpen={(e) => {
                            e.stopPropagation();
                            setActive(objectId);
                          }} onClose={() => setActive(null)}>
                            <Button
                              size={ButtonSizes.Small}
                              icon={"preview"}
                              color={ButtonColors.White}
                              variant={ButtonVariants.Fill}
                              onlyIcon/>
                            {uploadedFiles.map((file, index) => <Button key={index} onClick={(e) => {
                                e.stopPropagation();
                                setActive(null);
                                onPreview(objectId, file.type, objectName);
                              }
                              }> {AppraisalTypeMap[ file.type ]} </Button>
                            )}
                          </ButtonGroup>
                          <Tooltip title={"Edit"}>
                            <Button onlyIcon size={ButtonSizes.Small} icon={"create"} color={ButtonColors.White}
                                    onClick={() => {
                                      onEdit(objectId);
                                    }}/>
                          </Tooltip>
                          <Tooltip title={"Remove"}>
                            <Button onlyIcon size={ButtonSizes.Small} icon={"delete"} color={ButtonColors.White}
                                    onClick={() => {
                                      onRemove(objectId);
                                    }}/>
                          </Tooltip>
                        </ListActions>
                      </ListRow>;
                    }
                  )}
                </Box>
              }
              <Button alignSelf={"end"} variant={ButtonVariants.Ghost} icon={"file_download"} onClick={handleDownload}>
                Download .CSV template
              </Button>
            </>
          }
        </Box>
      </Box>
    </>);
  }
);
export default AppraisalListView;






