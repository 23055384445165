import React     from "react";
import { FC }    from "react";
import { Stack } from "@relcu/rc";
import "./details-container.css";

export const DetailsContainer: FC<any> = React.memo(function DetailsContainer(props) {
  return <Stack
    direction={"column"}
    alignItems={"flex-start"}
    spacing={32}
    className={"rc-details-container"}
    childrenRenderMode={"clone"}
    {...props}/>;
});
