import { Client }                       from "./Client";
import { soundVar }                     from "./reactiveVars";
import { audioNotificationOutputIdVar } from "./reactiveVars";
import { audioInputIdVar }              from "./reactiveVars";

export type SoundTypes = "call-increment" | "lead-increment" | "direct-call" | "sms" | "direct-call-main" | "hold" | "beep" | "alert" | "incoming-call";

// export class SoundOld {
//   #audioMap: Record<SoundTypes, AudioType> = {
//     "call-increment": new AudioType("assets/sounds/call-increment.mp3"),
//     "lead-increment": new AudioType("assets/sounds/lead-increment.mp3"),
//     "direct-call": new AudioType("assets/sounds/direct-call.mp3"),
//     "sms": new AudioType("assets/sounds/sms.mp3")
//   };
//
//   public async play(type: SoundTypes, loop = 0, onError: (e?: Error) => void = (e) => {}) {
//     try{
//       throw new Error("Play in sound")
//     }catch(e){
//       console.info(e);
//     }
//     const audioType = this.getAudio(type);
//     try {
//       await audioType?.play(!loop ? 1 : loop);
//     } catch (e) {
//       console.error("error", e);
//       onError(e);
//       console.warn(e);
//     } finally {
//       audioType?.pause();
//     }
//   }
//   public pause(type: SoundTypes) {
//     try{
//       throw new Error("Pause in sound")
//     }catch(e){
//       console.info(e);
//     }
//
//     const audioType = this.getAudio(type);
//     audioType?.pause();
//   }
//
//   private getAudio(type: SoundTypes): AudioType {
//     //return new AudioType(`assets/sounds/${type}.mp3`);
//     return this.#audioMap[ type ];
//   }
// }

export class Sound {
  #audioMap: Record<SoundTypes, { isPlaying: boolean, audio: AudioType }> = {
    "call-increment": { isPlaying: false, audio: new AudioType("assets/sounds/call-increment.mp3") },
    "lead-increment": { isPlaying: false, audio: new AudioType("assets/sounds/lead-increment.mp3") },
    "direct-call": { isPlaying: false, audio: new AudioType("assets/sounds/direct-call.mp3") },
    "hold": { isPlaying: false, audio: new AudioType("assets/sounds/hold.wav") },
    "beep": { isPlaying: false, audio: new AudioType("assets/sounds/beep.wav") },
    "alert": { isPlaying: false, audio: new AudioType("assets/sounds/alert.wav") },
    "incoming-call": { isPlaying: false, audio: new AudioType("assets/sounds/incoming-call.wav") },
    "direct-call-main": { isPlaying: false, audio: new AudioType("assets/sounds/direct-call.mp3") },
    "sms": { isPlaying: false, audio: new AudioType("assets/sounds/sms.mp3") }
  };
  #silentMode: boolean = false;
  #client: Client;
  constructor(client: Client) {
    this.#client = client;
    soundVar(this);
    client.soundPreferences.addEventListener("change", () => {
      this.notificationOutputId = audioNotificationOutputIdVar();
    });
    const audioNotificationOutputId = audioNotificationOutputIdVar();
    if (audioNotificationOutputId) {
      this.notificationOutputId = audioNotificationOutputId;
    }

  }

  set notificationOutputId(id) {
    Object.values(this.#audioMap).map((audioType) => {
      audioType.audio.setOutputId(id);
    });
  }

  public async silent(silentMode = true) {
    await Promise.allSettled(Object.keys(this.#audioMap).map((k: SoundTypes) => {
      if (this.#audioMap[ k ].isPlaying) {
        return this.pause(k);
      }
    }));
    this.#silentMode = true;
  }
  public unSilent() {
    this.#silentMode = false;
  }

  public async play(type: SoundTypes, loop = 0, reset = true, onError: (e?: Error) => void = (e) => {
  }) {
    if (this.#silentMode || !this.#audioMap[ type ]) {
      return;
    }
    const audioType = this.getAudio(type);
    const isPlaying = this.isPlaying(type);
    if (isPlaying && reset) {
      await this.pause(type);
    } else if (isPlaying && !reset) {
      return;
    }
    let paused = false;
    try {
      this.setIsPlaying(type, true);
      paused = await audioType?.play(!loop ? 1 : loop);
    } catch (e: any) {
      onError(e);
      console.warn(e);
    }
    if (!paused) {
      await this.pause(type);
    }
  }
  public async pause(type: SoundTypes) {
    if (this.isPlaying(type)) {
      const audioType = this.getAudio(type);
      await audioType?.pause();
      this.setIsPlaying(type, false);
    }
  }

  private getAudio(type: SoundTypes): AudioType {
    return this.#audioMap[ type ].audio;
  }
  private isPlaying(type: SoundTypes): boolean {
    return this.#audioMap[ type ]?.isPlaying;
  }
  private setIsPlaying(type: SoundTypes, isPlaying: boolean) {
    this.#audioMap[ type ].isPlaying = isPlaying;
  }
}
class AudioType {
  #audio: HTMLAudioElement;
  #hold: number;
  #playing: boolean = false;
  #pause: boolean = false;
  #stata: "play" | "playing" | "canplay" | "ended" | "pause";
  constructor(src: string, hold: number = 100) {
    this.#audio = new Audio(src);
    this.#hold = hold;
    this.#audio.preload = "auto";
    this.#audio.addEventListener("play", () => {
      this.#stata = "play";
    });
    this.#audio.addEventListener("pause", () => {
      this.#stata = "pause";
    });
    this.#audio.addEventListener("canplay", () => {
      this.#stata = "canplay";
    });
    this.#audio.addEventListener("playing", () => {
      this.#stata = "playing";
    });
    this.#audio.addEventListener("ended", () => {
      this.#stata = "ended";
      this.#playing = false;
    });
    this.#audio.addEventListener("error", () => {
      this.#playing = false;
    });
  }
  async waitToEvent(event) {
    return new Promise<boolean>((resolve, reject) => {
      this.#audio.addEventListener(event, () => {
        resolve(true);
      }, { once: true });
      this.#audio.addEventListener("error", () => {
        resolve(false);
      }, { once: true });
    });

  }
  async play(loop: number = 1) {
    this.#pause = false;
    while (loop && !this.#pause) {
      await this.#play();
      loop--;
    }
    return this.#pause;
  }
  setOutputId(id) {
    this.#audio[ "setSinkId" ](id).catch(console.error);
  }
  #play() {
    if (this.#playing) {
      return Promise.resolve();
    }
    return new Promise<void>(async (resolve, reject) => {
      this.#audio.addEventListener("ended", () => {
        this.#wait(this.#hold).then(() => {
          this.#playing = false;
          resolve();
        });
      }, { once: true });

      try {
        this.#audio.currentTime = 0;
        await this.#audio.play();
        this.#playing = true;
      } catch (e) {
        reject(e);
      }
    });
  }
  async pause() {
    if (this.#stata == "play") {
      await this.waitToEvent("canplay");
    }
    this.#audio.pause();
    this.#pause = true;
    this.#playing = false;
  }
  #wait(ms) {
    return new Promise((accept) => {
      setTimeout(accept, ms);
    });
  }
}
