import React                from "react";
import { FC }               from "react";
import { InputState }       from "../../Input/Input";
import { Input }            from "../../Input/Input";
import { useConsumeField }  from "../BaseField";
import { NumberFieldProps } from "./NumberField";

export const NumberEditField: FC = React.memo(function NumberEditField(props) {
  const { input, meta: { touched, error, submitError, data: { warning } }, onChange } = useConsumeField<NumberFieldProps>();
  const hasError = (touched && !!error) || !!submitError;

  return (
    <Input
      message={hasError ? (error || submitError) : warning}
      {...input}
      type="text"
      onChange={onChange}
      state={(hasError && InputState.Error) || (warning && InputState.Warning) || undefined}
    />
  );
});
NumberEditField.defaultProps = {
  precision: 2
};

export default NumberEditField;
