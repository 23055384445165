import React                from "react";
import { FC }               from "react";
import { ReactNode }        from "react";
import { Box }              from "../../Box";
import { ButtonColors }     from "../../Button";
import { ButtonVariants }   from "../../Button";
import { Button }           from "../../Button";
import { TypographyWeight } from "../../Typography";
import { TypographySize }   from "../../Typography";
import { Typography }       from "../../Typography";
import { ModalBody }        from "../Modal";
import { Modal }            from "../Modal";
import { ModalProps }       from "../Modal";

export interface ConfirmDialogProps extends Partial<ModalProps> {
  title: string,
  onConfirm: () => void
  subTitle?: string
  content?: string | ReactNode
  label?: string
  showActions?: boolean
  modalProps?: object
}

export const ConfirmDialog: FC<ConfirmDialogProps> = React.memo(function EmptyDialog(props) {
  const { title, content = "Action cannot be undone", onClose, onConfirm, label = "CONFIRM", subTitle, open, modalProps, showActions, closeIcon } = props;
  return <Modal
    title={title}
    onClose={onClose}
    open={open}
    closeIcon={closeIcon}
    disableBackdropClick={false}
    variant={"small"}
    className={ConfirmDialogClasses.Modal}
    modalProps={modalProps}
  >
    <ModalBody container direction={"column"} flex={"1 1 auto"} justify={"center"} gap={"XXXL"}
               className={ConfirmDialogClasses.Container}>
      <Box container direction={"column"} gap={"XS"}>
        {
          subTitle && typeof subTitle == "string" ?
            <Typography
              size={TypographySize.Subtitle}
              weight={TypographyWeight.Medium}
              className={ConfirmDialogClasses.SubTitle}>
              {subTitle}
            </Typography> :
            subTitle
        }
        {content && typeof content == "string" ? <Typography>{content}</Typography> : content}
      </Box>
      {
        showActions &&
        <Box container justify="end" alignItems={"end"} gap={"XXS"}>
          <Button variant={ButtonVariants.Ghost}
                  onClick={() => onClose()}>CANCEL</Button>
          <Button type="submit" color={ButtonColors.Error} onClick={() => onConfirm()}>{label}</Button>
        </Box>
      }
    </ModalBody>
  </Modal>;
});

ConfirmDialog.defaultProps = {
  showActions: true,
  closeIcon: true
};

export default ConfirmDialog;
export const enum ConfirmDialogClasses {
  Modal = "confirm-dialog-modal",
  Container = "confirm-dialog__container",
  SubTitle = "confirm-dialog__sub-title",
  Footer = "confirm-dialog__footer"
}
