import { gql } from "@apollo/client";

export const VIEWER_USER_ROLE = gql`
  query ViewerUserRole {
    viewer {
      sessionToken
      user {
        id
        objectId
        role
        team
      }
    }
  }
`;
