export const enum SliderClasses {
  Slider = "slider",
  SliderContainer = "slider__container",
  SliderItem = "slider__item",
  SliderIndicator = "slider__indicator",
  SliderIndicatorL = "slider__indicator-left",
  SliderIndicatorR = "slider__indicator-right",
  SliderItemOffScreen = "slider__item--offScreen",
  SliderBtn = "slider__btn",
  SliderBtnIcon = "slider__btn--icon",
  SliderBtnR = "slider__btn--right",
  SliderBtnL = "slider__btn--left"
}