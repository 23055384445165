import { useSource }                            from "@relcu/ui";
import { CommonClasses }                        from "@relcu/ui";
import { Link }                                 from "@relcu/ui";
import { TimelineItem }                         from "@relcu/ui";
import { TypographyColor }                      from "@relcu/ui";
import { Typography }                           from "@relcu/ui";
import { TimelineItemSubject }                  from "@relcu/ui";
import { TimelineItemIconColors }               from "@relcu/ui";
import { TimelineItemIcon }                     from "@relcu/ui";
import React                                    from "react";
import { ExportActivity as ExportActivityType } from "../../../../__types__/ExportActivity";

export interface ExportActivityProps extends ExportActivityType {
  createdAt: string;
}

export const ExportActivity = React.memo<ExportActivityProps>(function ExportActivity(props) {
  const { exportedBy, exportSubject, createdAt } = props;
  const { $object } = useSource();

  return <TimelineItem
    primary={$object.__typename != "Lead" ? <TimelineItemSubject>
        <Link to={`/user/${exportedBy.objectId}/focus`}>
          <Typography className={CommonClasses.ClickableIcon}>{exportedBy?.objectName || "Unknown"}</Typography>
        </Link>
        <Typography color={TypographyColor.Secondary}>exported</Typography>
        <Link to={`/lead/${exportSubject.objectId}/relcuLoan`}>
          <Typography
            className={CommonClasses.ClickableIcon}>{exportSubject?.objectName || "Unknown"}</Typography>
        </Link>
        <Typography color={TypographyColor.Secondary}>to</Typography>
        <Typography>loan origination system</Typography>
      </TimelineItemSubject>
      : <TimelineItemSubject>
        <Link to={`/lead/${exportSubject.objectId}/relcuLoan`}>
          <Typography
            className={CommonClasses.ClickableIcon}>{exportSubject?.objectName || "Unknown"}</Typography>
        </Link>
        <Typography color={TypographyColor.Secondary}>record was exported to</Typography>
        <Typography>loan origination system</Typography>
        <Typography color={TypographyColor.Secondary}>by</Typography>
        <Link to={`/user/${exportedBy.objectId}/focus`}>
          <Typography className={CommonClasses.ClickableIcon}>{exportedBy?.objectName || "Unknown"}</Typography>
        </Link>
      </TimelineItemSubject>}
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"ios_share"}/>}
    creationDate={createdAt}
  >
  </TimelineItem>;
});
