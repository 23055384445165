import { CircularLoader }  from "@relcu/ui";
import React               from "react";
import { FC }              from "react";
import { EmptyList }       from "@relcu/ui";
import { ButtonSizes }     from "@relcu/ui";
import { ButtonVariants }  from "@relcu/ui";
import { Button }          from "@relcu/ui";
import { Alignment }       from "@relcu/ui";
import { Tooltip }         from "@relcu/ui";
import { TypographySize }  from "@relcu/ui";
import { TypographyColor } from "@relcu/ui";
import { Typography }                         from "@relcu/ui";
import { Box }                                from "@relcu/ui";
import { classNames }                         from "@relcu/ui";
import { Card }                               from "@relcu/ui";
import { DeepPartial }                        from "@apollo/client/utilities";
import { SliderClasses }                      from "../../../../../Slider";
import { Slider }                             from "../../../../../Slider";
import { BulkTable }                          from "../../../../Section/BulkSection/BulkTable";
import { BulkItemClasses }                    from "../../../MailView/BulkItemContent/BulItemClasses";
import { PhoneMessageConversation_bulkStats } from "../../__types__/PhoneMessageConversation";

export interface BulkItemStatisticsProps {
  columns: any;
  data: any;
  actions: any;
  onCancelAll: () => Promise<void>;
  page: number;
  onPage: (page: number) => void;
  pageCount: number;
  onFilter: (status: string | null) => void;
  status: string | null;
  count: number;
  statistics: DeepPartial<PhoneMessageConversation_bulkStats>;
  cancelLoading?: boolean;
  loading?: boolean;
  onBehalf?: boolean;
}

export const BulkItemStatistics: FC<BulkItemStatisticsProps> = React.memo(function BulkItemStatistics(props) {
  const { onFilter, onBehalf, statistics, count, columns, data, actions, status, onCancelAll, loading, page, onPage, pageCount, cancelLoading } = props;
  return <>
    <Slider>
      <Card onClick={() => onFilter(null)} title={"Recipients"} selected={!status} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.total}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("sent")} title={"Sent"} selected={status === "sent"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.sent}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("delivered")} title={"Delivered"} selected={status === "delivered"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.delivered}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("undelivered")} title={"Undelivered"} selected={status === "undelivered"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Error} size={TypographySize.Subtitle}>
            {statistics.undelivered}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("failed")} title={"Failed"} selected={status === "failed"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Error} size={TypographySize.Subtitle}>
            {statistics.failed}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("canceled")} title={"Canceled"} selected={status === "canceled"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.canceled}
          </Typography>
        </Box>
      </Card>
    </Slider>
    <Box container justify={"space-between"} className={BulkItemClasses.ClearAllSection}>
      <Typography color={TypographyColor.ExtraDark} size={TypographySize.SubtitleSmall}>
        Recipients
      </Typography>
      {
        !!statistics.queued && (status === "queued" || !status) && !onBehalf &&
        <Tooltip alignment={Alignment.Top} title={"Only pending will be canceled"}>
          <Button disabled={cancelLoading} onClick={onCancelAll} variant={ButtonVariants.Ghost}
                  size={ButtonSizes.Small}
                  aria-label="clear-browsing-history">Cancel
            all</Button>
        </Tooltip>
      }
    </Box>
    {
      loading ?
        <Box container flex={1} justify={"center"} alignItems={"center"}>
          <CircularLoader/>
        </Box>
        :
        !count ?
      <Box container flex={1} justify={"center"} alignItems={"center"}>
        <EmptyList title={"No messages found under this filter"}
                   content={`Please navigate to a different filter to see messages.`}
                   alignSelf={"center"}/>
      </Box> :
      <BulkTable columns={columns} pageCount={pageCount} data={data} actions={actions} currentPage={page}
                 onPage={onPage}/>}
  </>;

});
