import { useMemo }                     from "react";
import { useCallback }                 from "react";
import { useLazyQuery }                from "@apollo/client";
import { gql }                         from "@apollo/client";
import { useAlert }                    from "@relcu/ui";
import { useModal }                    from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { useQuery }                    from "@apollo/client";
import { useNavigate }                 from "@relcu/react-router";
import { MessageDraft }                from "../../../reactiveVars";
import { messageDraftsVar }            from "../../../reactiveVars";
import { createPhoneNumbers }          from "../../../utils/helpers";
import { toFirstLower }                from "../../../utils/helpers";
import { getField }                    from "../../../utils/schemaUtils";
import { RenderPhoneMessageVariables } from "../../__types__/RenderPhoneMessage";
import { RenderPhoneMessage }          from "../../__types__/RenderPhoneMessage";
import { usePermissions }              from "../../AccessControl";
import { useMessageTemplates }         from "../../Layout/View/MessageView/useMessageTemplates";
import { RENDER_PHONE_MESSAGE_QUERY }  from "../../operations.graphql";
import { useMailApi }                  from "../../useMailApi";
import { GetMailLPTemplates }          from "./__types__/GetMailLPTemplates";
import { PdfModal }                    from "./index";

export function usePreviewLoanProposal(nodeId: string) {
  const navigate = useNavigate();
  const { $viewer, $object } = useSource();
  const { states } = getField("Lead", "leadStatus");
  const { data: { emailTemplates: { edges = [] } = {} } = {} } = useQuery<GetMailLPTemplates>(GET_MAIL_LP_TEMPLATES, {
    variables: {
      where: {
        applyTo: { equalTo: "loanProposal" },
        enabled: { equalTo: true },
        OR: [
          {
            owner: {
              exists: false
            }
          },
          {
            owner: {
              have: {
                id: {
                  equalTo: $viewer.id
                }
              }
            }
          }
        ]
      }
    }
  });
  const [open, modalContext] = useModal(PdfModal);
  const { defaultMailbox, mailBoxes, nodeEmail, createDraftMail } = useMailApi($object);
  const fromMail = defaultMailbox?.address;
  const { error } = useAlert();
  const to = createPhoneNumbers($object, "smsOptOut");
  const { templates } = useMessageTemplates({
    type: {
      equalTo: "loan_proposal"
    },
    enabled: { equalTo: true },
    availableFor: {
      in: ["manual"]
    },
    applyTo: {
      in: [toFirstLower($object.__typename)]
    },
    OR: [
      {
        owner: {
          exists: false
        }
      },
      {
        owner: {
          have: {
            id: {
              equalTo: $viewer.id
            }
          }
        }
      }
    ]
  });
  const { canUpdate } = usePermissions($object);
  const smsTemplate = useMemo(() => templates[ "toSorted" ]((t) => t.node.owner?.objectId ? -1 : 1)[ 0 ], [templates]);
  const emailTemplate = edges?.[ "toSorted" ]((t) => t.node.owner?.objectId ? -1 : 1)?.at(0)?.node?.objectId;

  const [load] = useLazyQuery<RenderPhoneMessage, RenderPhoneMessageVariables>(RENDER_PHONE_MESSAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: { errorPolicy: "ignore" }
  });

  let doNotContact = !!states[ $object.leadStatus.status ]?.not_contact || !canUpdate;
  return {
    modalContext,
    preview: useCallback((options: {
      file: any,
      proposalId?: string,
      image?: any,
      loanEstimateId?: string,
      onCancel?: () => void
    }) => {
      const { file, image, onCancel, ...proposalOrEstimateId } = options;

      if (file?.url) {
        const { destroy } = open({
          onCancel,
          url: file.url,
          actions: [
            {
              children: "SEND VIA MMS",
              disabled: doNotContact,
              onClick: async (event) => {
                const drafts = messageDraftsVar();

                const { id, ...attachment } = image || file;
                const draft: MessageDraft = {
                  attachment: [{ attachment }],
                  ...proposalOrEstimateId
                };

                if (smsTemplate) {
                  const phoneMessage = await load({
                    variables: {
                      input: {
                        template: smsTemplate?.node?.text,
                        user: $viewer.objectId,
                        to: to[ 0 ]?.value,
                        scope: $object.id
                      }
                    }
                  });

                  draft.template = {
                    label: smsTemplate.node.title,
                    value: phoneMessage?.data?.renderPhoneMessage?.html ?? "",
                    objectId: smsTemplate.node.objectId
                  };
                }
                messageDraftsVar({
                  ...drafts,
                  [ nodeId ]: draft
                });
                destroy();
                setTimeout(() => {
                  navigate(`/lead/${nodeId}/sms`);
                });

              }
            },
            {
              children: "SEND VIA EMAIL",
              disabled: (doNotContact || !nodeEmail || !fromMail),
              onClick: async () => {
                // let html = "";
                // let text = "";
                // let subject = "Loan Proposal";
                // if (loanProposalTemplate) {
                //   const result = await render({
                //     variables: {
                //       input: {
                //         to: [nodeEmail],
                //         from: $viewer.objectId,
                //         subject: "Loan Proposal",
                //         proposal: options.proposalId || options.loanEstimateId,
                //         scope: $object.objectId,
                //         templateId: loanProposalTemplate
                //       }
                //     }
                //   });
                //   subject = result.data.renderMail.subject;
                //   html = result.data.renderMail.html;
                //   text = result.data.renderMail.text;
                // }
                const { data: { createDraftEmail: { conversation: { objectId } } } } = await createDraftMail({
                  subject: "Loan Proposal",
                  from: fromMail,
                  to: [nodeEmail],
                  loanEstimateId: options.loanEstimateId,
                  loanProposalId: options.proposalId,
                  html: "",
                  text: "",
                  scope: $object.id,
                  template: emailTemplate,
                  attachments: [{
                    url: file.url,
                    objectId: file.objectId,
                    mimeType: file.mimeType,
                    name: file.name
                  }]
                });
                destroy();
                setTimeout(() => {
                  navigate(`/lead/${nodeId}/emails?from=${objectId}`);
                });
              }
            }
          ]
        });
      } else {
        error("Oops. Looks like something wrong with pdf file.");
      }

    }, [navigate, nodeId, templates, $viewer, doNotContact])
  };
}

const GET_MAIL_LP_TEMPLATES = gql`
  query GetMailLPTemplates($where: EmailTemplateWhereInput!) {
    emailTemplates(where: $where,order: [createdAt_DESC]){
      edges {
        node {
          id
          objectId
          objectIcon
          objectName
          enabled
          content
          lead
          loanProposal
          title
          subject
          owner {
            id
            objectIcon
            objectId
            objectName
          }
        }
      }
    }
  }
`;
