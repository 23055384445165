import { useMemo }           from "react";
import React                 from "react";
import { FC }                from "react";
import { useSource }         from "../../..";
import { omit }              from "../../..";
import { BadgeVariants }     from "../../Badge";
import { BadgeAlignment }    from "../../Badge";
import { Badge }             from "../../Badge";
import { BadgeColors }       from "../../Badge";
import { BadgeSize }         from "../../Badge";
import { BoxComponentProps } from "../../Box";
import { Box }               from "../../Box";
import { Stats }             from "./JsonPage";

export interface BubbleProps {
  show?: boolean
  size?: BadgeSize,
  color?: BadgeColors
}

export const Bubble: FC<BubbleProps> = React.memo(function Bubble({ children, show, size, color, ...rest }) {
  if (!show) {
    return <Box {...rest}>{children}</Box>;
  }
  return (
    <Badge outline={true} color={color} alignment={BadgeAlignment.TopRight}
           variant={BadgeVariants.Dot} {...rest}>
      {children}
    </Badge>
  );
});
Bubble.defaultProps = {
  show: true,
  color: BadgeColors.Primary
};
export interface NodeBubble extends BoxComponentProps {
  for: string
  stats: Stats,
}
export const NodeBubble: FC<NodeBubble> = React.memo(function NodeBubble(props) {
  const { stats, children } = props;
  const { $object } = useSource();
  const notificationControls = $object.__typename === "User" ? $object.notificationControls : null;
  const box = useMemo(() => omit(props, ["for", "stats", "children"]), [props]);
  if (props.for === "sms") {
    const show = (!notificationControls && (stats.unreadMessagesCount > 0 || stats.bulkSMSCount > 0)) ||
      (notificationControls &&
        (stats.unreadMessagesCount > 0 && notificationControls[ "sms" ]) ||
        (stats.bulkSMSCount > 0 && notificationControls[ "bulkSMS" ]));

    return <Bubble show={show} color={BadgeColors.Error} {...box}>
      {children}
    </Bubble>;
  }
  if (props.for === "calls") {
    return <Bubble show={stats.missedCallsCount > 0 && (!notificationControls || notificationControls[ "missedCalls" ])}
                   color={BadgeColors.Error} {...box}>
      {children}
    </Bubble>;
  }
  if (props.for === "pipeline" || props.for == "loan") {
    return <Bubble
      show={stats.unreadPipelinesCount > 0 && (!notificationControls || notificationControls[ "loanComments" ])}
      color={BadgeColors.Error} {...box}>
      {children}
    </Bubble>;
  }
  if (props.for === "emails") {
    const show = (!notificationControls && (stats.unreadEmailsCount > 0 || stats.bulkEmailsCount > 0)) ||
      (notificationControls &&
        (stats.unreadEmailsCount > 0 && notificationControls[ "emails" ]) ||
        (stats.bulkEmailsCount > 0 && notificationControls[ "bulkEmail" ]));
    return <Bubble show={show} color={BadgeColors.Error} {...box}>
      {children}
    </Bubble>;
  }
  if (props.for === "reminders") {
    return <Bubble
      show={stats.remindersCount > 0 && (!notificationControls || notificationControls[ "tasksReminders" ])}
      color={BadgeColors.Error} {...box}>{children}</Bubble>;
  }
  return (
    <Box {...box}>{props.children}</Box>
  );
});
