import React                   from "react";
import { Box }                 from "../../..";
import { classNames }          from "../../..";
import { ListItemClasses }     from "..";
import { BoxComponentProps }   from "../../..";
import { Alignment }           from "../../../constants/Alignemnt";
import { GlobalClasses }       from "../../../constants/GlobalClasses";
import { EllipsisWithTooltip } from "../../EllipsisTooltip/EllipsisWithTooltip";
import { useChoiceLabel }      from "./useChoiceLabel";

export interface ListItemChoiceProps extends BoxComponentProps {
  optionKey?: string
  optionLabel?: string
  options: any[]
  value: string | object
}
ListItemChoice.defaultProps = {
  optionKey: "value",
  optionLabel: "label"
};
export function ListItemChoice(props: ListItemChoiceProps) {
  const { className, optionKey, optionLabel, value, options, ...p } = props;
  const classes = classNames(ListItemClasses.ListItemText, className);
  let label = useChoiceLabel(value, options, optionKey, optionLabel);
  label = label || value;

  return <Box container className={classes} alignItems={"center"} {...p}>
    <EllipsisWithTooltip value={label} alignment={Alignment.Top}>
      <p className={GlobalClasses.EllipsisFlex}>
        {label}
      </p>
    </EllipsisWithTooltip>
  </Box>;
}
