import { useCallback }  from "react";
import { toFirstUpper } from "../utils/helpers";

export function useTitle() {
  const setTitle = useCallback((title) => {
    document.title = title;
  }, []);
  const setTitleByView = useCallback((layout: Partial<{ objectName: string, objectId: string }>, tabTitle: string) => {
    let title = layout.objectName;
    if (!title) {
      title = toFirstUpper(layout.objectId);
    }
    if (tabTitle) {
      title = `${tabTitle} - ${title}`;
    }
    setTitle(title);
  }, [setTitle]);
  return {
    setTitle,
    setTitleByView
  };
}
