import React             from "react";
import { WithAsProps }   from "../@types/common";
import { useClassNames } from "../utils";

export interface CounterProps extends WithAsProps, React.HTMLAttributes<HTMLElement> {
  count: number;
  color?: "blue" | "red" | "transparent-blue" | "transparent-red";
}

export const Counter = React.forwardRef((props: CounterProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, className, count, color, classPrefix = "counter", ...rest } = props;
  const { withClassPrefix, merge } = useClassNames(classPrefix);
  const classes = merge(className, withClassPrefix(color));

  return (
    <span className={classes} title={`${count}`}>{count > 99 ? "99+" : count}</span>
  );
});

Counter.displayName = "Toolbar";

export default Counter;
