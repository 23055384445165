import React, { useState }       from "react";
import { useEffect }             from "react";
import { useRef }                from "react";
import { Box }                   from "../..";
import { useRect }               from "../..";
import { ClickAwayListener }     from "../..";
import { Portal }                from "../..";
import { BoxItemComponentProps } from "../..";
import { Alignment }             from "../../constants/Alignemnt";

export interface PopperProps extends BoxItemComponentProps {
  alignment: Alignment
  threshold?: number
  open: boolean
  onClickAway?: (e) => void
  anchorBounding: any
  container?: HTMLElement
}

const noop = () => {
};

const PopperDefaultProps = {
  open: true,
  threshold: 4,
  onClickAway: noop
};

Popper.defaultProps = PopperDefaultProps;
export function Popper(props: PopperProps) {
  const { children, alignment, anchorBounding, threshold, onClickAway, container } = props;
  const [tooltipStyle, setTooltipStyle] = useState({});
  const childRef: any = useRef();
  let rect = useRect(childRef);

  useEffect(() => {
    if (!anchorBounding) {
      setTooltipStyle({
        animation: "none"
      });
      return;
    }
    let styles: any = {
      opacity: 0,
      visibility: "hidden",
      animationDuration: ".5s",
      animationFillMode: "forwards",
      animationName: "show-popper"
    };
    switch (alignment) {
      case Alignment.TopLeft:
        styles.bottom = `calc(100% - ${anchorBounding.top - threshold}px)`;
        styles.left = anchorBounding.left;
        break;
      case Alignment.BottomLeft:
        styles.top = anchorBounding.bottom + threshold;
        styles.left = anchorBounding.left;
        break;
      case Alignment.Left:
        styles.top = anchorBounding.top - threshold;
        styles.left = anchorBounding.left - threshold;
        styles.transform = "translateX(-100%)";

        break;
      case Alignment.TopRight:
        styles.bottom = `calc(100% - ${anchorBounding.top - threshold}px)`;
        styles.right = `calc(100% - ${anchorBounding.right}px)`;
        break;
      case Alignment.BottomRight:
        styles.top = anchorBounding.bottom + threshold;
        styles.right = `calc(100% - ${anchorBounding.right}px)`;
        break;
      case Alignment.Right:
        styles.top = anchorBounding.top - threshold;
        styles.right = `calc(100% - ${anchorBounding.right - threshold}px)`;
        styles.transform = "translateX(100%)";
        break;
      case Alignment.Top:
        styles.bottom = `calc(100% - ${anchorBounding.top - threshold}px)`;
        styles.left = anchorBounding.left + (anchorBounding.width / 2);
        styles.transform = "translate(-50%)";
        break;
      case Alignment.RightTop:
        styles.bottom = `calc(100% - ${anchorBounding.bottom}px)`;
        styles.right = `calc(100% - ${anchorBounding.right - threshold}px)`;
        styles.transform = "translateX(100%)";
        break;
      case Alignment.Bottom:
        styles.top = anchorBounding.bottom + threshold;
        styles.left = anchorBounding.left + (anchorBounding.width / 2);
        styles.transform = "translate(-50%)";
        break;
      default:
        //top
        styles.bottom = `calc(100% - ${anchorBounding.top - threshold}px)`;
        styles.left = anchorBounding.left + (anchorBounding.width / 2);
        styles.transform = "translate(-50%)";
    }
    styles.position = "absolute";

    if (alignment.indexOf("top") > -1 && (anchorBounding.top - rect.height - threshold) <= 0) {
      styles.top = anchorBounding.bottom + threshold;
      delete styles.bottom;
      // Top is out of viewport
    }

    if (alignment.indexOf("bottom") > -1 && (anchorBounding.bottom + rect.height + threshold) >= (rect.windowHeight || document.documentElement.clientHeight)) {
      styles.bottom = `calc(100% - ${anchorBounding.top - threshold}px)`;
      delete styles.top;
      // Bottom is out of viewport
    }

    if (alignment.indexOf("left") > -1 && (anchorBounding.left - rect.width) < 0) {
      styles.left = anchorBounding.left;
      delete styles.right;
      delete styles.transform;
      // Left side is out of viewport
    }

    if (alignment.indexOf("right") > -1 && (anchorBounding.right + rect.width) > (rect.windowWidth || document.documentElement.clientWidth)) {
      styles.right = `calc(100% - ${anchorBounding.right}px)`;
      delete styles.left;
      delete styles.transform;
      // Right side is out of viewport
    }
    setTooltipStyle(styles);
  }, [props.anchorBounding, children, rect, alignment]);

  return (!!anchorBounding && <Portal container={container}>
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        {
          React.Children.map(children, ((child: any) => React.cloneElement(child, {
            style: {
              ...child?.props?.style,
              ...tooltipStyle
            }, ref: childRef
          })))
        }
      </Box>
    </ClickAwayListener>
  </Portal>);
}

export enum PopperClasses {
  Popper = "popper",
}
