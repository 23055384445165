export const enum NotificationClasses {
  Secondary = "notification_icon--secondary",
  Tertiary = "notification_icon--tertiary",
  Primary = "notification_icon--primary",
  Success = "notification_icon--success",
  Error = "notification_icon--error",
  Warning = "notification_icon--warning",
  Info = "notification_icon--info",
  White = "notification_icon--white",

  Icon = "notification_icon",
  IconL = "notification_icon--l"
}