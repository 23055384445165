import { useCallback }                from "react";
import React                          from "react";
import { useMutation }                from "@apollo/client";
import { useConsumeField }            from "@relcu/ui";
import { InputState }                 from "@relcu/ui";
import { useAlert }                   from "@relcu/ui";
import { ImageUpload }                from "@relcu/ui";
import { fileToBase64 }               from "../../../../utils/helpers";
import { readFileAsync }              from "../../../../utils/helpers";
import { UploadAvatar }               from "../../../Message/Messanger/__types__/UploadAvatar";
import { UploadAvatarVariables }      from "../../../Message/Messanger/__types__/UploadAvatar";
import { UPLOAD_AVATAR }              from "../../../Message/Messanger/Messenger";
import { ImageUploadFieldProps }      from "./ImageUploadField";

export const ImageUploadEditField = React.memo(function ImageUploadEditField(props) {
  const [sendFileUrl] = useMutation<UploadAvatar, UploadAvatarVariables>(UPLOAD_AVATAR);
  const { input, meta: { touched, error, submitError } } = useConsumeField<ImageUploadFieldProps>();
  const { error: alertError } = useAlert();
  const hasError = (touched && !!error) || !!submitError;
  const handleUpload = useCallback(async (file: File | string) => {
    try {
      if (typeof file == "string") {
        const { data: { uploadAvatar } } = await sendFileUrl({
          variables: {
            url: file,
          }
        });
        input.onChange(uploadAvatar.url);
      } else {
        if (!(input.acceptedTypes?.includes("image/*") && file.type.match(/^image/i)) && !input.acceptedTypes?.includes(file.type)) {
          alertError("Invalid file type");
          return false;
        }
        if (file?.size > 5 * 1024 * 1024) {
          alertError("File is too large (max: 5MB)");
          return false;
        }

        const reader = await readFileAsync(file);
        let base64 = fileToBase64(reader);
        const { data: { uploadAvatar } } = await sendFileUrl({
          variables: {
            blob: {
              name: file.name,
              content: base64,
              type: file.type
            }
          }
        });
        input.onChange(uploadAvatar.url);
      }
      return true;
    } catch (e) {
      alertError(e.toString());
      return false;
    }
  }, [input.acceptedTypes, input.onChange]);

  return <ImageUpload
    {...input}
    onUpload={handleUpload}
    state={(hasError && InputState.Error) || undefined}
    message={hasError ? (error || submitError) : undefined}
  />;
});
