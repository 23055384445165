import React                   from "react";
import { FC }                  from "react";
import { useState }            from "react";
import { useContext }          from "react";
import { useCallback }         from "react";
import { editor }              from "monaco-editor";
import Editor                  from "@monaco-editor/react";
import { OnMount }             from "@monaco-editor/react";
import { Field }               from "@relcu/form";
import { SelectPicker }        from "@relcu/rc";
import { Icon }                from "@relcu/rc";
import { Button }              from "@relcu/rc";
import { Typography }          from "@relcu/rc";
import { Stack }               from "@relcu/rc";
import { MailTemplateContext } from "../Content";
import { TemplatePreview }     from "../TemplatePreview";
import { SectionHeader }       from "../SectionHeader";

export interface EditorProps {
  replacements: any[];
}
const ToggleSelect = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <Button
    size={"xs"}
    color={"red"}
    style={{ background: "transparent" }}
    appearance={"text"}
    startIcon={<Icon type={"rc_data_object"} style={{ color: "var(--rc-accent-03-primary)" }}/>}>
    INSERT VARIABLE
  </Button>;
});
export const HtmlEditor: FC<EditorProps> = React.memo(function HtmlEditor(props) {
  const { preview } = useContext(MailTemplateContext);
  const [monacoInstance, setMonacoInstance] = useState<editor.IStandaloneCodeEditor | null>(null);

  const setParam = useCallback((item) => {
    monacoInstance.trigger("keyboard", "type", { text: item });
  }, [monacoInstance]);

  const editorMount: OnMount = (editorL: editor.IStandaloneCodeEditor) => {
    setMonacoInstance(editorL);
  };

  return <Stack childrenRenderMode={"clone"} style={{ flex: 1 }}>
    {
      !preview &&
      <Stack direction={"column"} style={{ flex: "1 0 50%", alignSelf: "stretch" }} childrenRenderMode={"clone"}>
        <SectionHeader>
          <Typography variant={"base16"} lineHeight={"lh20"}>Editor</Typography>
          <SelectPicker
            style={{ alignSelf: "end" }}
            data={props.replacements}
            placement={"autoVerticalEnd"}
            value={null}
            menuAutoWidth={false}
            menuStyle={{ minWidth: 300 }}
            onChange={(item) => setParam(item)}
            groupBy={"role"}
            toggleAs={ToggleSelect}
            searchable={true}
          />
        </SectionHeader>
        <Field name={`content`}>
          {({ meta }) => {
            return meta.data.error ?
              <Typography
                style={{ color: "var(--rc-status-03-error-primary)", padding: "10px 20px 10px 20px" }}>
                {meta.data.error}
              </Typography> :
              null;
          }}
        </Field>
        <Stack.Item style={{ alignSelf: "stretch", flex: 1 }}>
          <Field name={`content`}>
            {({ input: { value, onChange } }) => {
              return <>
                <Editor
                  onMount={editorMount}
                  defaultLanguage={"handlebars"}
                  value={value}
                  onChange={(value) => {
                    console.info(value);
                    onChange(value)
                  }}
                  options={{ minimap: { enabled: false } }}
                />
              </>;
            }}
          </Field>
        </Stack.Item>
      </Stack>
    }
    <TemplatePreview/>
  </Stack>;
});
