import React                   from "react";
import { ReactElement }        from "react";
import { useState }            from "react";
import { useLayoutEffect }     from "react";
import { useRef }              from "react";
import { HTMLAttributes }      from "react";
import { useWindowDimensions } from "../..";
import { Tooltip }             from "../..";
import { Alignment }           from "../..";

export interface EllipsisWithTooltipProps extends HTMLAttributes<any> {
  value: any;
  children: ReactElement;
  alignment?: Alignment;
}

export function EllipsisWithTooltip(props: EllipsisWithTooltipProps) {
  const { value, children, alignment } = props;
  const [tooltip, setTooltip] = useState<any>(null);
  const { width } = useWindowDimensions();
  const ref = useRef<any>();
  useLayoutEffect(() => {
    if (ref?.current?.scrollWidth > ref?.current?.offsetWidth || ref?.current?.scrollHeight > ref?.current?.offsetHeight) {
      setTooltip(value);
    }
  }, [width]);
  return <Tooltip title={tooltip} alignment={alignment ?? Alignment.TopLeft}>
    {React.cloneElement(children, { ref })}
  </Tooltip>;
}
