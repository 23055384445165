import React                                     from "react";
import PropTypes                                 from "prop-types";
import { FlexboxGrid }                           from "../index";
import { useClassNames }                         from "../utils";
import { WithAsProps, RsRefForwardingComponent } from "../@types/common";
import { FlexboxGridItemProps }                  from "./FlexboxGridItem";

export interface FlexboxGridCellProps extends FlexboxGridItemProps {
  /** horizontal arrangement */
  justify?: "start" | "end" | "center";
}

const FlexboxGridCell: RsRefForwardingComponent<"div", FlexboxGridCellProps> = React.forwardRef(
  (props: FlexboxGridCellProps, ref) => {

    const {
      children,
      classPrefix = "flex-box-grid-cell",
      className,
      justify,
      ...rest
    } = props;

    const { merge, withClassPrefix } = useClassNames(classPrefix);
    const classes = merge(className, withClassPrefix(justify));

    return <FlexboxGrid.Item {...rest} className={classes}>
      {children}
    </FlexboxGrid.Item>;
  });

FlexboxGridCell.displayName = "FlexboxGridCell";
FlexboxGridCell.propTypes = {
  justify: PropTypes.oneOf(["start", "end"])
};

export default FlexboxGridCell;
