import { ButtonColors }        from "@relcu/ui";
import { JsonViewProps }       from "@relcu/ui";
import { ButtonVariants }      from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { Alert }               from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { AlertColors }         from "@relcu/ui";
import { useSource }           from "@relcu/ui";
import { useModal }            from "@relcu/ui";
import { FC }                  from "react";
import { useMemo }             from "react";
import React                   from "react";
import { ResetPasswordDialog } from "../../Dialog/ResetPasswordDialog";
import { DetailsView }         from "../DetailsView";

export const UserDetailsView :FC<JsonViewProps> = React.memo<JsonViewProps>(function UserDetailsView(props) {
  const { $object } = useSource();
  const isLocked = useMemo(() => new Date($object.accountLockoutExpiresAt) > new Date(), [$object.accountLockoutExpiresAt]);
  const [modal, contextHolder] = useModal(ResetPasswordDialog);
  function onUnblock() {
    modal({
      record: $object
    });
    // const destroy = modal("ResetPasswordDialog", {
    //   user: node,
    //   onReset() {
    //     destroy();
    //   }
    // });
  }
  return (
    <>
      {isLocked &&
      <Alert variant={AlertColors.Error} width={"100%"}>
        <Box container flexGrow={1} justify={"space-between"} alignItems={"center"}>
          <Box gap={"XXXS"} container>
            <p>{$object.firstName} {$object.lastName}</p>
            <span>has exceeded the number of password attempts and is blocked.</span>
          </Box>
          <Button onClick={onUnblock} color={ButtonColors.Transparent} variant={ButtonVariants.Ghost}>UNBLOCK</Button>
        </Box>
      </Alert>
      }
      <DetailsView {...props} />
      {contextHolder}
    </>
  );
});
