import { FontIcon }     from "@relcu/ui";
import { ReactElement } from "react";
import { useState }     from "react";
import { useMemo }      from "react";
import React            from "react";
import { ReactNode }    from "react";
import { StackProps }   from "../index";
import { Button }       from "../index";
import Stack            from "../Stack";

export interface ItemsEllipsisProps extends StackProps {
  ellipsis?: (count, extended) => ReactElement;
  renderItem: (item, index) => ReactNode;
  showLength?: number;
  items: any[];
  optionKey?: string;
  optionLabel?: string;
  holdup?: boolean;
}

export function ItemsEllipsis(props: ItemsEllipsisProps) {
  const { items, renderItem, ellipsis, showLength, holdup, ...p } = props;
  const [showAll, setShowAll] = useState(false);
  const remainingItems = useMemo(() => items.length > showLength ? items.length - showLength : 0, [showLength, items]);
  const visibleItems = useMemo(() => {
    return showLength && items.length > showLength ? items.slice(0, showLength) : items;
  }, [items, showLength]);

  const handleToggle = e => {
    e.stopPropagation();
    setShowAll(!showAll);
  };
  const component = ellipsis?.(remainingItems, showAll);
  return <Stack spacing={8} wrap {...p}>
    {
      (showAll ? items : visibleItems).map((item, index) => renderItem(item, index))
    }
    {
      !!showLength && !!remainingItems &&
      (
        ellipsis ?
          React.cloneElement(component, {
            ...component.props,
            onClick: handleToggle
          })
          :
          <Button
            size={"xs"}
            appearance={"text"}
            onClick={handleToggle}
            disabled={false}>
            {!showAll ? `${remainingItems} more` : "Show less"}
          </Button>
      )
    }
  </Stack>;
}

ItemsEllipsis.defaultProps = {
  holdup: true
};

export const enum ItemsEllipsisClasses {
  EllipsisPopoverContainer = "ellipsis-popover__container"
}
