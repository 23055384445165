import React                 from "react";
import { useState }          from "react";
import { useMemo }           from "react";
import { useCallback }       from "react";
import { Form }              from "@relcu/form";
import { JsonFieldProps }    from "@relcu/ui";
import { Step }              from "@relcu/ui";
import { StepInterface }     from "@relcu/ui";
import { Stepper }           from "@relcu/ui";
import { Box, ModalFooter }  from "@relcu/ui";
import { ModalBody }         from "@relcu/ui";
import { deepPick }          from "@relcu/ui";
import { Modal }             from "@relcu/ui";
import { ButtonVariants }    from "@relcu/ui";
import { Button }            from "@relcu/ui";
import { defaultMutators }   from "@relcu/ui";
import { formUtils }         from "@relcu/ui";
import { defaultValidator }  from "@relcu/ui";
import { useJqlForm }        from "../../Jql/JqlForm";
import { convertSort }       from "../../View/DistributionQueueView/DistributionQueueDialog/utils";
import { parseSort }         from "../../View/DistributionQueueView/DistributionQueueDialog/utils";
import { Columns }           from "../PageViewDialog/Steps/Columns";
import { Filter }            from "../PageViewDialog/Steps/Filter";
import { Name }              from "../PageViewDialog/Steps/Name";
import { SchemaDialogProps } from "../SchemaDialog";
import { useSchemaDialog }   from "../SchemaDialog";
import { LeadCount }         from "./Steps/Count/LeadCount";
import { LeadSort }          from "./Steps/Sort/LeadSort";
import { Status }            from "./Steps/Status/Status";

const fieldsInStep = {
  0: ["title"],
  1: ["leadFilter"],
  3: ["leadCount"],
  4: ["columns"]
};

export interface PriorityQueueDialog extends SchemaDialogProps {
  leadSort: {
    prioritizeBy: {
      options: {
        value: string,
        label: string
      }[]
    },
    order: {
      options: {
        value: string,
        label: string
      }[]
    }
  },
  leadFilters: {
    name: string
    label: string
    schemaName?: string
    operators: {
      value: string,
      label: string
    }[]
  }[],
  availableFields: JsonFieldProps[]
  viewId: string
}

export const PriorityQueueDialog = React.memo<PriorityQueueDialog>(function PriorityQueueDialog({ leadFilters, leadSort, availableFields, viewId, ...props }) {
  const {
    initialValues,
    user,
    sections,
    jql,
    formProps,
    action,
    className,
    afterSubmit,
    loading,
    title,
    onClose,
    ...modal
  } = useSchemaDialog(props);
  const [step, setStep] = useState(0);
  const jqlForm = useJqlForm({ jql, className, sections, initialValues, afterSubmit: () => onClose() });
  const initialFilter = {
    "id": "g-0.24071163710643773",
    "rules": [
      {
        "id": "r-0.198780306477929",
        "field": "leadStatus.status",
        "operator": "in"
      }
    ],
    "combinator": "and",
    "not": false
  };
  const initial = useMemo(() => {
    const fields = formUtils.getFieldNames(sections);
    return {
      ...deepPick(initialValues || {}, fields),
      leadSort: parseSort(initialValues?.leadSort ?? ["createdAt"]),
      leadFilter: initialValues?.leadFilter || initialFilter,
      enabled: initialValues?.enabled ?? true,
      expandable: initialValues?.expandable ?? true,
      columns: action == "create" ? availableFields.filter(f => f.visible).map(({ name, visible, ...rest }) => ({
        id: name,
        column: "selected",
        data: {
          key: name,
          ...rest
        }
      })) : parseFields()
    };
  }, [initialValues, availableFields, action]);

  function parseFields() {
    const columns = [];
    initialValues.fields.forEach(field => {
      const availableField = availableFields.find((afield, index) => afield.name == field.key);
      const { name, visible, ...rest } = availableField;
      columns.push({
        id: field.key,
        column: "selected",
        data: {
          ...field,
          ...rest
        }
      });
    });

    return columns;
  }

  const handleSubmit = useCallback((values, form) => {
    const { priority, columns, ...restValues } = values;
    const valid = validate(values);
    if (Object.keys(valid).length) {
      return valid;
    }
    jqlForm.onSubmit({
      ...restValues,
      priorityView: {
        id: viewId
      },
      leadSort: convertSort(values.leadSort),
      fields: columns.map(selected => {
        return { key: selected.data.key, fixed: selected.data.fixed ?? null };
      })
    }, form);
  }, [jqlForm.onSubmit, viewId]);

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.title) {
      errors[ "title" ] = "Section name is required.";
    }

    if (!values.leadCount) {
      errors[ "leadCount" ] = "Lead count is required.";
    }

    if (!values.columns.length) {
      errors[ "columns" ] = "There's should be at least one selected field.";
    }

    if (values.leadFilter) {
      const valid = !Object.values(defaultValidator(values.leadFilter)).filter(rv => (typeof rv !== "boolean") || rv === false).length;
      if (!valid || !values.leadFilter.rules.length) {
        errors[ "leadFilter" ] = "Lead filtering is incomplete.";
      }
    } else {
      errors[ "leadFilter" ] = "Lead filtering is incomplete.";
    }

    for (let i = 0; i < values.leadSort.length; i++) {
      if (!values.leadSort?.[ i ]?.order) {
        if (!errors[ "leadSort" ]) {
          errors[ "leadSort" ] = [];
        }
        errors[ "leadSort" ][ i ] = { order: "Prioritize by is required." };
      }

      if (!values.leadSort?.[ i ]?.prioritizeBy || (typeof values.leadSort?.[ i ]?.prioritizeBy === "object" && !Object.keys(values.leadSort[ i ].prioritizeBy).length)) {
        if (!errors[ "leadSort" ]) {
          errors[ "leadSort" ] = [];
        }
        errors[ "leadSort" ][ i ] = {
          ...errors[ "leadSort" ][ i ],
          prioritizeBy: "Prioritize by is required."
        };
      }
    }
    return errors;
  }, []);

  const steps: StepInterface[] = useMemo(() => {
    return [
      {
        title: "Name",
        name: "title",
        required: true,
        component: Name
      },
      {
        title: "Lead filtering",
        name: "leadFilter",
        required: true,
        fields: leadFilters,
        component: Filter,
        schemaName: "Lead"
      },
      {
        title: "Lead sorting",
        name: "leadSort",
        prioritizeByOptions: leadSort?.prioritizeBy?.options,
        orderOptions: leadSort.order?.options,
        component: LeadSort
      },
      {
        title: "Lead count",
        required: true,
        component: LeadCount
      },
      {
        title: "Columns",
        name: "columns",
        required: true,
        component: Columns,
        data: {
          availableFields: []
        }
      },
      {
        title: "Status",
        name: "enabled",
        component: Status
      }
    ];
  }, []);

  const renderStep = useMemo(() => {
    const activeStep = steps.find((s, index) => index == step);
    let { component: Step, ...stepProps } = activeStep;
    const data = {
      availableFields: availableFields
    };
    return <Step {...stepProps} data={data}/>;
  }, [step]);

  return (
    <Modal
      {...modal}
      title={`${action === "save" ? "Edit" : "Create"} section`}
      variant={"large"}
      style={{ minHeight: "90%" }}
      open={props.open}
      onClose={props.onClose}
      closeIcon={false}
      disableBackdropClick={false}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={initial}
        subscription={{ submitting: true, submitErrors: true }}
        mutators={defaultMutators}>
        {
          ({ handleSubmit, submitting, submitErrors }) => {
            return <form
              onSubmit={handleSubmit}
              noValidate
              style={{ display: "contents" }}>
              <ModalBody
                style={{ padding: 0, position: "relative" }}
                container
                direction={"column"}
                flexGrow={1}>
                <Box container flex={1} style={{ overflow: "hidden" }}>
                  <Stepper>
                    {
                      steps?.map((s, i) => {
                        const error = fieldsInStep[ i ]?.find(field => submitErrors?.[ field ]);
                        return <Step
                          error={error}
                          label={s.title}
                          active={i == step}
                          past={i < step}
                          edit={true}
                          required={s.required}
                          onClick={() => setStep(i)}
                          key={i}/>;
                      })
                    }
                  </Stepper>
                  {renderStep}
                </Box>
                {
                  <ModalFooter borderOnTop>
                    <Button disabled={submitting} type="button" variant={ButtonVariants.Ghost}
                            onClick={props.onClose}>CANCEL</Button>
                    <Button disabled={submitting} type="submit">{action.toUpperCase()}</Button>
                  </ModalFooter>
                }
              </ModalBody>
            </form>;
          }
        }
      </Form>
    </Modal>
  );
});
