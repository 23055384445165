import React              from "react";
import { FC }             from "react";
import { HTMLAttributes } from "react";
import { Typography }     from "@relcu/rc";
import { classNames }     from "@relcu/ui";

interface NotificationTimeSectionProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

export const NotificationTimeSection: FC<NotificationTimeSectionProps> = React.memo(function NotificationTimeSection(props) {
  const { className, title, children } = props;
  const classes = classNames("notification-time-section", className);
  return <div className={classes}>
    <div className={"notification-time-section-heading"}>
      <Typography
        color={"secondary"}
        variant={"small12"}>
        {title}
      </Typography>
    </div>
    {children}
  </div>;
});
