import { useAlert }                     from "@relcu/ui";
import { useSource }                    from "@relcu/ui";
import { KeyboardEvent }                from "react";
import { useEffect }                    from "react";
import React                            from "react";
import { FC }                           from "react";
import { useMemo }                      from "react";
import { useState }                     from "react";
import { useLazyQuery }                 from "@apollo/client";
import { gql }                          from "@apollo/client";
import { Stack }                        from "@relcu/rc";
import { Tag }                          from "@relcu/rc";
import { ItemsEllipsis }                from "@relcu/rc";
import { useThrottle }                  from "@relcu/ui";
import { useMounted }                   from "@relcu/ui";
import { Typography }                   from "@relcu/rc";
import { Avatar }                       from "@relcu/rc";
import { ToggleDropdown }               from "@relcu/rc";
import { schemaVar }                    from "../../../../../reactiveVars";
import { InfoPopover }                  from "../InfoPopover";
import { formatPhoneNumber }            from "../../../../../utils/helpers";
import { getDefaultPhoneNumberForCall } from "../../../../../utils/helpers";
import { getHumanized }                 from "../../../../../utils/schemaUtils";
import "./mail-auto-suggest.css";

export interface SuggestedItem {
  contactId: string,
  objectName: string,
  objectIcon: string,
  address: string,
  phone: string,
  type: string,
  contactType: string
}

export interface AutoSuggestProps {
  data: SuggestedItem[];
  onChange?(values: {
    contactId?: string,
    address: string,
    objectName: string
  }[]);
  value?: string,
  selected: {
    contactId?: string,
    address: string,
    objectName: string
  }[],
  onInput?(input: string);
  showLength?: number;
  name?: string
}

export const MailAutoSuggest: FC<AutoSuggestProps> = React.memo(function MailAutoSuggest(props) {
  const { data, selected, onChange, showLength = 1, name } = props;
  const { $object, $viewer } = useSource();
  const { warning } = useAlert();
  const schema = schemaVar();
  const [value, setValue] = useState("");
  let [query, setQuery] = useThrottle(value, 1000);
  const objectId = query ? query.trim() : null;
  const matchesRegex = `.*${objectId}.*`;
  const skipContacts = useMemo(()=>{
    const schemaContactSearchable = schema["Contact"].isSearchable
    if(typeof schemaContactSearchable === "boolean"){
      return !schemaContactSearchable
    } else {
      const role = $viewer.role
      return typeof schemaContactSearchable[role] === "boolean"
        ? !schemaContactSearchable[role]
        : !schemaContactSearchable["*"]
    }
  },[])
  const [load, {
    data: { contacts, mailboxes } = {} = {}
  }] = useLazyQuery(MAIL_AUTO_SUGGEST, {
    variables: {
      skipContacts: skipContacts,
      first: 3,
      matchesRegex,
      skipContactIds: selected?.map(s => s.contactId) ?? []
    },
    fetchPolicy: "no-cache"
  });

  const fetchedItems = useMemo(() => {
    const items = [];
    if (contacts?.edges?.length) {
      items.push({ header: "CONTACTS" });
    }
    contacts?.edges.forEach(({ node }) => {
      const phone = getDefaultPhoneNumberForCall(node.phones, "Contact")?.number;
      node.emails.forEach((e) => {
        items.push({
          contactId: node.objectId,
          type: e.type,
          optOut: e.optOut,
          value: e.address,
          address: e.address,
          objectName: node.objectName,
          objectIcon: node.objectIcon,
          disabled: e.optOut,
          contactType: getHumanized("LeadMember", "type", node.type),
          phone: formatPhoneNumber(phone ?? "")
        });
      });
    });
    if (mailboxes?.edges?.length) {
      items.push({ header: "USERS" });
    }
    mailboxes?.edges?.forEach(e => {
      const mailBox = e.node;
      items.push({
        address: mailBox.address,
        objectName: mailBox.assignedTo.objectName,
        disabled: mailBox.disabled
      });
    });
    return items;
  }, [contacts?.edges, mailboxes?.edges]);

  const items = useMemo(() => {
    return (value ? fetchedItems : data).filter(d => {
      return selected.findIndex((s) => s.address == d.address) < 0;
    });
  }, [selected, data, fetchedItems, value]);

  const handleChange = (contact) => {
    if (!selected.find(s => s.address == contact.address)) {
      onChange([...selected, contact]);
      setValue("");
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();

    if (event.key === "Enter" && !items.length && new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$").test(value) && !selected.find(s => s.address == value)) {
      onChange([...selected, { address: value, objectName: value }]);
      setValue("");
    }
  };

  const onRemove = (item) => {
    if ($object.__typename === "Contact" && item.contactId === $object.objectId && name === "To") {
      const filtered = selected.filter(v => v.address != item.address);
      const otherExist = filtered.find(f => data.find(d => d.address === f.address));
      if (data.length === 1 || !otherExist) {
        warning("At least one of the recipients has to be the contact.");
      } else {
        onChange(selected.filter(v => v.address != item.address));
      }
    } else {
      onChange(selected.filter(v => v.address != item.address));
    }
  };

  useEffect(() => setQuery(value), [value]);
  useMounted(load, [query]);

  return <Stack spacing={8} wrap={true}>
    <ItemsEllipsis
      holdup={false}
      showLength={showLength}
      items={selected}
      renderItem={(item, index) => {
        return <InfoPopover
          key={index}
          trigger={"hover"}
          padding={16}
          toggleRenderer={
            <Tag input key={index} onClose={() => onRemove(item)}
                 className={"mail-auto-suggest"}>
              {item.objectName}
              <Tag.SecondaryText> ({item.address})</Tag.SecondaryText>
            </Tag>
          }
          speaker={
            <Stack direction={"column"} spacing={24} alignItems={"flex-start"} className={"rc-receivers-info"}>
              <Stack spacing={8} alignItems={"flex-start"}>
                <Avatar circle={true} size={"md"} showIndicator={false} src={item.objectIcon}
                        className={"rc-list-card-avatar"}>{item.objectName}</Avatar>
                <Stack direction={"column"} spacing={8} alignItems={"flex-start"}>
                  <Typography weights={"medium"} variant={"base16"}>{item.objectName}</Typography>
                  <Typography style={{ color: "var(--rc-accent-03-primary)" }}
                              variant={"base14"}>{item.address}</Typography>
                </Stack>
              </Stack>
            </Stack>
          }
        />;
      }}
    />
    <Stack.Item grow={1}>
      <ToggleDropdown
        placement={"autoVerticalStart"}
        toggleRenderer={() => {
          return <input
            type="text"
            style={{ padding: 5, width: "100%", height: "28px" }}
            onKeyPress={onKeyDown}
            onInput={(e) => setValue?.(e.currentTarget.value)}
            value={value}
          />;
        }}>
        {
          items.map((item, index) => {
            if (item.header) {
              return <div key={index} style={{ padding: "5px 8px" }}>
                <Typography variant={"small10"}>{item.header}</Typography>
              </div>;
            }
            return <ToggleDropdown.Item key={index} onClick={() => handleChange(item)}>
              <Avatar circle showIndicator={false} size={"sm"} src={item.objectIcon}>
                {item.objectName}
              </Avatar>
              {/*<InfoPopover*/}
              {/*  speaker={<div>QAQ</div>}*/}
              {/*  toggleRenderer={() => <Avatar circle showIndicator={false} size={"sm"} src={d.objectIcon}>*/}
              {/*    {d.objectName}*/}
              {/*  </Avatar>}/>*/}
              <div style={{ display: "inline-flex", flexDirection: "column", gap: 4 }}>
                <Typography weights={"medium"}>{item.objectName}
                  {!value && <Typography as={"span"} color={"tertiary"}> ({item.address})</Typography>}
                </Typography>
                <Typography color={"tertiary"}>{value ? item.address : item.contactType}</Typography>
              </div>
            </ToggleDropdown.Item>;
          })
        }
      </ToggleDropdown>
    </Stack.Item>
  </Stack>;
});

MailAutoSuggest.displayName = "MailAutoSuggest";

const MAIL_AUTO_SUGGEST = gql`
  query MailAutoSuggestContacts($matchesRegex: String!,  $skipContacts:Boolean!, $first:Int, $skipContactIds: [ID]) {
    contacts(first: $first, where: {
      AND: [
        {
          OR: [
            {
              firstName: {matchesRegex: $matchesRegex,options: "i"}
            },
            {
              lastName: {matchesRegex: $matchesRegex,options: "i"}
            },
            {
              objectName: {matchesRegex: $matchesRegex,options: "i"}
            }
            {
              emails: {
                have: {
                  address: {matchesRegex:$matchesRegex,options: "i"}
                  optOut: {notEqualTo: true}
                }
              }
            }
          ]
        },
        {
          objectId: {
            notIn: $skipContactIds
          }
        }
      ]
    }) @skip(if: $skipContacts){
      edges {
        node {
          objectIcon
          objectId
          objectName
          tags
          emails{
            address
            type
          }
          updatedAt
        }
      }
    }
    mailboxes:mailboxes(first: $first,where:{assignedTo: {exists: true}, disabled: {notEqualTo: true},address:{matchesRegex: $matchesRegex}}){
      edges {
        node {
          id
          objectId
          objectName
          address
          disabled
          assignedTo {
            ...on Document{
              objectName
              objectId
            }
          }
        }
      }
    }
  }
`;





