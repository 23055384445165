export enum Keys {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  ArrowRight = "ArrowRight",
  ArrowLeft = "ArrowLeft",
  Space = " ",
  Enter = "Enter"
}

export enum KeyCodes {
  ArrowDown = 40,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowLeft = 37,
  Space = 32,
  Enter = 13
}