import { layoutUpdatedVar } from "../reactiveVars";
import { schemaUpdatedVar } from "../reactiveVars";
import { versionVar }       from "../reactiveVars";
import { expiresVar }       from "../reactiveVars";

export async function loadConfig() {
  let settings = await fetch("/api/v1/settings").then(r => r.json());
  window.__CONFIG__ = {
    ...settings,
    wss: settings?.wss || window.location.origin.replace(/^http/, "ws") + "/api/v1/graphql",
    wssVoice: settings?.wssVoice || window.location.origin.replace(/^http/, "ws") + "/api/v1/voice",
    api: settings?.api || (window.location.origin + "/api/v1"),
    assets: settings?.assets || "/assets"
  };
  expiresVar(window.__CONFIG__.expires);
  versionVar(window.__CONFIG__.versions.web);
  layoutUpdatedVar(window.__CONFIG__.versions.layoutUpdatedAt)
  schemaUpdatedVar(window.__CONFIG__.versions.schemaUpdatedAt)
  return settings;
}
