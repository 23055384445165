import { useCallback }        from "react";
import { useLayoutEffect }    from "react";
import { KeyboardEvent }      from "react";
import { useMemo }            from "react";
import { useState }           from "react";
import { useMounted }         from "../../../index";
import { useImperativeState } from "../../../index";
import { Keys }               from "../../../constants/Keys";
import { filterData }         from "../Select/helpers";
import { getItemField }       from "../Select/helpers";

export function useMultiSelect(props){
  const {
    value,
    optionKey,
    optionLabel,
    options,
    onChange,
    searchText,
    onType,
    getOptionValueByKey,
    selectedFilter: selectedFilterActive,
    onSelectedFilter
  } = props;

  const [filteredData, setFilteredData] = useState([]);
  const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
  const [selectedFilter, setSelectedFilter] = useImperativeState(selectedFilterActive, onSelectedFilter);
  const initialValue = Array.isArray(value) ? value : [];
  const [selectedItems, setSelectedItems] = useImperativeState(initialValue, onChange);
  const [search, setSearch] = useImperativeState(searchText, onType);
  const allSelected = useMemo(() => selectedItems.length === options.length, [selectedItems, options]);
  const items = useMemo(() => {
    const items = search ? filteredData : options;
    if (selectedFilter && value?.length) {
      return options.filter((item) => value.includes(item[optionKey ?? "value"])) || [];
    }

    return items || [];
  }, [search, filteredData, options, selectedFilter, onSelectedFilter]);
  const togglePopper = event => {
    setAnchorBounding(!anchorBounding ? event.currentTarget.getBoundingClientRect() : null);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    event.preventDefault();
    if ((event.key === Keys.Enter || event.key === Keys.Space)) {
      togglePopper(event);
    }
  };

  const handleSelectAll = useCallback(() => {
    setSelectedItems(getOptionValueByKey ? items.map(i => getItemField(i, optionKey)) : items);
  }, [items, getOptionValueByKey, optionKey]);

  const selectItem = (item) => {
    const selected = getItemField(item, optionKey);
    if (selectedItems.find(select => getItemField(select, optionKey) == selected)) {
      let filtered = selectedItems.filter((s) => (getItemField(s, optionKey) !== selected));
      setSelectedItems(filtered);
    } else {
      setSelectedItems(
        [
          ...selectedItems,
          getOptionValueByKey ? getItemField(item, optionKey) : item
        ]
      );
    }
  };

  const onFilter = () => {
    let value = search.toLowerCase();
    let filtered = options.filter(select => filterData(select, value, optionLabel));
    setFilteredData(filtered);
  };

  const checkIsSelected = (item) => {
    const selectedItem = getItemField(item, optionKey);
    return selectedItems.find(select => {
        const item = getItemField(select, optionKey);
        return selectedItem !== undefined && item !== undefined && item !== null && item === selectedItem;
      }
    );
  };

  const deleteItem = (item) => {
    const selectedItem = getItemField(item, optionKey);
    setSelectedItems(selectedItems.filter(
      select => {
        const item = getItemField(select, optionKey);
        return item !== undefined && item !== null && item !== selectedItem;
      }
    ));
  };

  useMounted(() => {
    if (search) {
      onFilter();
    }
  }, [search]);

  useLayoutEffect(() => {
    setSelectedItems(initialValue);
  }, [initialValue]);

  return {
    setAnchorBounding,
    setSelectedFilter,
    setSelectedItems,
    checkIsSelected,
    handleSelectAll,
    selectedFilter,
    handleKeyPress,
    anchorBounding,
    selectedItems,
    filteredData,
    togglePopper,
    allSelected,
    selectItem,
    deleteItem,
    setSearch,
    search,
    items
  };
}
