import { useImperativeState } from "../../hooks/useImperativeState";
import { applyDrag }          from "../../utils/helpers";
import { TableProps }         from "./Table";


export function useTable(props: TableProps) {
  const {
    columns, onSortColumn, data, onRowSelection,
    actions, getCellValue, pagination, empty, onColumnsChange, selectedRows: initialSelectedRows, type,
    ...tableProps
  } = props;
  const [sColumns, setSColumns] = useImperativeState<any>(columns, onColumnsChange);
  const [selectedRows, setSelectedRows] = useImperativeState<string[]>(initialSelectedRows ?? [], onRowSelection);

  const api = {
    handleCheckAll: (event) => {
      event.stopPropagation();
      const checked = event.target.checked;
      const keys = checked ? data.map(item => item.id) : [];
      setSelectedRows(keys);
    },
    handleCheck: (event, rowDataId: string) => {
      if (selectedRows.some(item => item === "*")){
        const keys = data.map(item => item.id).filter(item => item != rowDataId);
        setSelectedRows(keys);
      } else {
        const checked = event.target.checked;
        const keys: string[] = checked ? [...selectedRows, rowDataId] : selectedRows.filter(item => item !== rowDataId);
        setSelectedRows(keys);
      }
    },
    onSortColumn: (sortColumn, sortType) => {
      onSortColumn(sortColumn, sortType);
    },
    handleFreeze(dataKey: string, direction: "left" | "right" = "left") {
      setSColumns(prev => {
        let targetCol;
        const leftCols = [];
        const midCols = [];
        const rightCols = [];
        prev.forEach((col) => {
          if (col.key == dataKey) {
            targetCol = col;
            return;
          }
          switch (col.fixed) {
            case "right":
              rightCols.push({ ...col });
              break;
            case "left":
              leftCols.push({ ...col });
              break;
            default:
              midCols.push({ ...col });
          }
        });
        targetCol.fixed = direction;
        if (direction == "left") {
          leftCols.push(targetCol);
        } else {
          rightCols.unshift(targetCol);
        }
        return [...leftCols, ...midCols, ...rightCols];
      });
    },
    handleUnFreeze(dataKey: string) {
      setSColumns(prev => {
        let targetCol;
        const leftCols = [];
        const midCols = [];
        const rightCols = [];
        prev.forEach((col) => {
          if (col.key == dataKey) {
            targetCol = col;
            return;
          }
          switch (col.fixed) {
            case "right":
              rightCols.push({ ...col });
              break;
            case "left":
              leftCols.push({ ...col });
              break;
            default:
              midCols.push({ ...col });
          }
        });
        const direction = targetCol.fixed;
        delete targetCol.fixed;
        if (direction == "left") {
          leftCols.push(targetCol);
        } else {
          rightCols.unshift(targetCol);
        }
        return [...leftCols, ...midCols, ...rightCols];
      });

    },
    handleDragColumn: (sourceId, targetId) => {
      setSColumns((source) => {
        const removedIndex = source.findIndex(item => item.key == sourceId);
        const payload = source.find(item => item.key === sourceId);
        const addedIndex = source.findIndex(item => item.key === targetId);
        return applyDrag(source, { removedIndex, addedIndex, payload });
      });
    },
    data,
    empty,
    columns: sColumns,
    onRowSelection,
    actions,
    type,
    getCellValue,
    tableProps,
    selectedRows,
    pagination
  };
  return api;
}
