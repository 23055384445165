import { CommonClasses }                    from "@relcu/ui";
import React                                from "react";
import { BaseFieldClasses, Label, Tooltip } from "@relcu/ui";
import { Box }                              from "@relcu/ui";
import { useConsumeField }                  from "@relcu/ui";
import { ImageUploadFieldProps }            from "./ImageUploadField";

export const ImageUploadReadField = React.memo(function ImageUploadReadField() {
  const {
    input: {
      label,
      required,
      value,
      name,
      targetClassName,
      flexBasis,
      flexShrink,
      flexGrow,
      acceptedTypes,
      ...textProps
    }
  } = useConsumeField<ImageUploadFieldProps>();

  return (
    <Box container
         direction={"column"}
         gap={"XXXS"}
         className={BaseFieldClasses.ReadField}
         alignSelf={"start"}
         {...textProps}
         flexBasis={flexBasis}
         flexShrink={flexShrink}
         flexGrow={flexGrow}>
      <Label>{label}</Label>
      <Tooltip title={value}>
        <p className={CommonClasses.FlexEllipsis}>
          {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>n/a</span>}
        </p>
      </Tooltip>
    </Box>
  );
});

export default ImageUploadReadField;
