import { MutationHookOptions }  from "@apollo/client";
import { IQueryBuilderOptions } from "@relcu/gql-query-builder";
import React                    from "react";
import { useJqlMutation }       from "./useJqlMutation";

export const JqlMutation = React.memo<JqlMutationProps>(
  function JqlQuery(props) {
    const { mutation, children, ...options } = props;
    const result = useJqlMutation(mutation, options);
    if (typeof children === "function") {
      return result ? children(result) : null;
    }
    return null;
  }
);
export interface JqlMutationProps extends Omit<MutationHookOptions, "mutation"> {
  mutation: IQueryBuilderOptions | IQueryBuilderOptions[],
  children?: (result: ReturnType<typeof useJqlMutation>) => JSX.Element | null;
  operationName: string;
}
