import { useField }          from "@relcu/form";
import { useContext }        from "react";
import React                 from "react";
import {PercentField}        from "@relcu/ui";
import { isPositiveInteger } from "../../../../../../utils/helpers";

import { LoanCriteriaContext } from "../LoanCriteriaProvider";

export const LtvField = React.memo(function LtvField() {
  const { calculate, product: { type },settings } = useContext(LoanCriteriaContext);
  const { input: { value: loanPurpose } } = useField("loanPurpose", { subscription: { value: true } });
  const purpose = loanPurpose === "purchase" ? "purchase" : "refinance";
  const { input: { value: downPayment } } = useField("downPayment", { subscription: { value: true } });
  const { input: { value: propertyValue } } = useField("property.value", { subscription: { value: true } });
  const minPercent = settings[ type ]?.[ purpose ]?.minDownPaymentPercent;
  const warning = minPercent && (downPayment < propertyValue * minPercent / 100);
  return <PercentField view={"write"} validate={value => isPositiveInteger(value)} required={true} onBlur={() => calculate("ltv")}
                       warning={warning ? `Must be maximum ${100 - minPercent}%` : undefined} flex={1} name={"ltv"}
                       label={"LTV"} placeholder={"Enter LTV"} />;
});
