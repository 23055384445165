import React             from "react";
import { FC }            from "react";
import { classNames }    from "../../utils/classNames";
import { ButtonProps }   from "../Button";
import { IconDirection } from "../Button";
import { ButtonSizes }   from "../Button";
import { Button }        from "../Button";

export interface MentionProps extends ButtonProps {
  direction: "top" | "bottom";
  width?: string | number;
}

export const Mention: FC<MentionProps> = React.memo(function Mention(props) {
  const { direction, width, ...p } = props;
  const classes = classNames("button-mention", {
    [ "button-mention--top" ]: direction == "top",
    [ "button-mention--bottom" ]: direction == "bottom"
  });

  return <Button
    style={{ width }}
    className={classes}
    size={ButtonSizes.Small}
    iconDirection={IconDirection.Right}
    {...p}/>;
});
