import React                 from "react";
import { useBoxProps }       from "../..";
import { classNames }        from "../..";
import { Box }               from "../..";
import { BoxComponentProps } from "../..";
import { ButtonColors }      from "../..";
import { ButtonVariants }    from "../..";
import { ButtonSizes }       from "../..";
import { Button }            from "../..";
import { FontIcon }          from "../..";

export interface DetailsSectionProps extends Omit<BoxComponentProps, "title"> {
  title?: string | React.ReactNode;
  editable?: boolean;
  originalClassName?: string;
  view?: "write" | "read" | "disabled" | "create";
  onEdit?: (e?) => void;
  onClear?: (e?) => void;
  onDone?: (e?) => void;
  bodyProps?: { [ key: string ]: any; };
}
Section.defaultProps = {
  view: "read",
  editable: true,
  onDone: () => void 0
};
export function Section(props: DetailsSectionProps) {
  const { children, className, title, view, editable, onClear, onEdit, onDone, bodyProps, ...p } = props;
  const isEditable = editable && view !== "create";
  const classes = classNames(SectionClasses.Section, {
    [ SectionClasses.EditSection ]: view === "write" && isEditable,
    [ SectionClasses.EditableSection ]: isEditable,
    [ SectionClasses.DisabledSection ]: view === "disabled",
    [ SectionClasses.WriteOnly ]: view === "create"

  }, className);
  return <Box container className={classes} direction={"column"} gap={"XXS"} {...useBoxProps(p)}>
    {(title || isEditable) && <Box container
                                   className={SectionClasses.SectionHeader}
                                   justify={"space-between"}>
      <>{title || null}</>
      {
        isEditable &&
        (
          view == "write" ?
            <Box container gap={"XXS"} alignItems={"center"} className={SectionClasses.SectionActions}>
              <Button icon={"save"} type="submit" onClick={onDone} variant={ButtonVariants.Ghost}
                      color={ButtonColors.White} onlyIcon
                      size={ButtonSizes.Medium}/>
              <Button icon={"clear"} type="button" variant={ButtonVariants.Ghost} color={ButtonColors.White}
                      onClick={onClear || null} onlyIcon size={ButtonSizes.Medium}/>
              {/*<FontIcon type="clear" onClick={onClear}/>*/}
            </Box>
            :
            <Box container gap={"XXS"} alignItems={"center"} className={SectionClasses.SectionActions}>
              <FontIcon onClick={onEdit || null} type={"create"}/>
            </Box>
        )
      }
    </Box>}
    <Box container className={SectionClasses.SectionBody} gap={"XS"} wrap={"wrap"}
         justify={"start"} {...bodyProps}>
      {children}
    </Box>
  </Box>;
}
export enum SectionClasses {
  Section = "details-section",
  SectionHeader = "details-section__header",
  SectionBody = "details-section__body",
  EditableSection = "details-section--editable",
  EditSection = "details-section--edit",
  DisabledSection = "details-section--disabled",
  WriteOnly = "details-section--write-only",
  SectionActions = "details-section__actions",
}
