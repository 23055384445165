import * as React                from "react";
import { classNames }            from "../..";
import { applyBoxItemStyles }    from "../Box";
import { BoxItemComponentProps } from "../Box";

export type IconType =
  "add"
  | "person"
  | "contacts"
  | "expand_less"
  | "expand_more"
  | "power_settings_new"
  | "clear"
  | "person_add"
  | "check"
  | "account_box"
  | "call"
  | "info"
  | "event_note"
  | "email"
  | "toc"
  | "comment"
  | "note_add"
  | "note"
  | "send"
  | "fiber_manual_record"
  | "perm_phone_msg"
  | "adb"
  | "apps"
  | "settings"
  | "settings_applications"
  | "filter"
  | "filter_list"
  | "group_add"
  | "supervisor_account"
  | "fiber_new"
  | "keyboard_arrow_down"
  | "keyboard_arrow_up"
  | "keyboard_arrow_right"
  | "keyboard_arrow_left"
  | "call_end"
  | "call_made"
  | "call_received"
  | "play_arrow"
  | "description"
  | "face"
  | "volume_up"
  | "delete"
  | "remove"
  | "reply"
  | "emoji_events"
  | "switch_account"
  | "folder"
  | "remove_red_eye"
  | "all_inclusive"
  | "message"
  | "autorenew"
  | "menu"
  | "preview"
  | "search"
  | "update"
  | "home"
  | "notifications"
  | "perm_data_setting"
  | "supervised_user_circle"
  | "accessibility"
  | "account_circle"
  | "local_library"
  | "credit_card"
  | "bookmark"
  | "flare"
  | "linear_scale"
  | "timeline"
  | "format_quote"
  | "format_color_text"
  | "attachment"
  | "business"
  | "warning"
  | "error"
  | "refresh"
  | "done"
  | "done_all"
  | "arrow_drop_down"
  | "arrow_drop_up"
  | "arrow_left"
  | "arrow_right"
  | "cached"
  | "hourglass_empty"
  | "calendar_today"
  | "mic_off"
  | "ring_volume"
  | "android"
  | "recent_actors"
  | "account_balance"
  | "create"
  | "visibility_off"
  | "rotate_90_degrees_ccw"
  | "playlist_add_check"
  | "phone_paused"
  | "child_care"
  | "person_outline"
  | "logout"
  | "receipt"
  | "gps_fixed"
  | "arrow_upward"
  | "link"
  | "insert_comment"
  | "cloud_upload"
  | "cloud_off"
  | "check_circle"
  | "remove_circle_outline"
  | "phone_in_talk"
  | "pause_circle_filled"
  | "play_circle_filled"
  | "translate"
  | "arrow_back"
  | "forum"
  | "more_vert"
  | "keyboard_control"
  | "drag_indicator"
  | "check_box"
  | "check_box_outline_blank"
  | "indeterminate_check_box"
  | "arrow_forward"
  | "code"
  | "engineering"
  | "access_time"
  | "monetization_on"
  | "donut_small"
  | "mic"
  | "forward"
  | "graphic_eq"
  | "call_missed_outgoing"
  | "sticky_note_2"
  | "call_missed"
  | "request_quote"
  | "notes"
  | "local_offer"
  | "history"
  | "flash_on"
  | "save"
  | "pie_chart"
  | "group"
  | "streetview"
  | "beenhere"
  | "location_city"
  | "panorama"
  | "house"
  | "phone"
  | "phone_missed"
  | "attach_money"
  | "location_on"
  | "insert_invitation"
  | "https"
  | "map"
  | "maps_ugc"
  | "flag"
  | "foundation"
  | "roofing"
  | "edit_road"
  | "list_alt"
  | "access_alarm"
  | "add_task"
  | "trip_origin"
  | "circle"
  | "record_voice_over"
  | "stop"
  | "filter_alt"
  | "voicemail"
  | "north"
  | "south"
  | "contact_mail"
  | "insert_photo"
  | "dialpad"
  | "search_off"
  | "file_download"
  | "quickreply"
  | "alternate_email"
  | "undo"
  | "upload"
  | "bar_chart"
  | "call_split"
  | "unfold_less"
  | "unfold_more"
  | "content_copy"
  | "trending_up"
  | "hourglass_top"
  | "real_estate_agent"
  | "lock_open"
  | "cancel"
  | "backspace"
  | "call_merge"

  | "five9"
  | "partnership"

  | "rc_summary"
  | "rc_word_doc"
  | "rc_lead"
  | "rc_email_integration"
  | "rc_expand_right"
  | "rc_expand_left"
  | "rc_timezone_error"
  | "rc_outgoing_missed"
  | "rc_custom-cooldown"
  | "rc_custom-focus-config"
  | "rc_incoming_call"
  | "rc_outgoing_call"
  | "rc_focus"
  | "rc_note"
  | "rc_price"
  | "rc_percent"
  | "rc_pdf_doc"
  | "rc_lp"
  | "rc_contact"
  | "rc_county"
  | "rc_state"
  | "rc_country"
  | "rc_district"
  | "rc_home_interpolated"
  | "rc_street_intersection"
  | "rc_locality"
  | "rc_postal_code"
  | "rc_goal-achieved"
  | "rc_custom-empty"
  | "rc_custom-redistribution"
  | "rc_custom-phone-ringing"
  | "rc_custom-action-to-lead"
  | "rc_street"
  | "rc_subdistrict"
  | "rc_place"
  | "rc_voicemail_call"
  | "rc_pin"
  | "rc_unpin"
  | "rc_sleep"
  | "rc_no_history"
  | "rc_sms_template"
  | "rc_missed_voicemail"
  | "rc_left_voicemail"
  | "rc_property_price"
  | "rc_custom-email-signature"
  | "rc_real-estate-agent"
  | "rc_email_templates"
  | "rc_lock_pipeline"
  | "rc_freeze_left"
  | "rc_freeze_right"
  | "rc_section_entered"
  | "rc_section_left"
  | "rc_bulk_sms"
  | "rc_bulk_email"
  | "rc_workflow"
  | "rc_partner_exchange"
  | "speaker"
  | "tune"
  | "contact_page"
  | "work_off"
  | "file_upload"
  | "rc_closing_fee"
  | "inbox"
  | "access_alarms"
  | "inventory"
  | "delete_outline"
  | "star"
  | "analytics"
  | "ios_share"
  | "file_copy"
  | "label_important"
  | "history_toggle_off"
  | "connect_without_contact"
  | "account_circle_40"

export interface FontIconProperties extends BoxItemComponentProps {
  type: IconType
}

export function FontIcon(props: FontIconProperties) {
  let properties = applyBoxItemStyles<FontIconProperties>(props);
  const { type, className, ...p } = properties;
  return <i className={classNames(className, IconClasses.Icon, `icon-${type}`)} {...p} />;
}

enum IconClasses {
  Icon = "icon",
  Loading = "icon-loading",
  Inverted = "icon-inverted",
  Gray = "icon-gray",
  BorderedIcon = "bordered-icon",
  Circular = "icon-circular"
}
