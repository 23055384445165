import React                        from "react";
import { CommonClasses }            from "../../..";
import { classNames }               from "../../..";
import { Box }                      from "../../Box";
import { BoxComponentProps }        from "../../Box";
import { RecipientListClasses }     from "./RecipientListClasses";

export interface RecipientItemContainerProps extends BoxComponentProps {
  selected?: boolean;
}

export const RecipientItemContainer =  React.forwardRef<any,RecipientItemContainerProps>(function RecipientItemContainer(props, ref) {
  const { selected, className, children, ...p } = props;
  const classes = classNames(RecipientListClasses.ListItem, {
    [ RecipientListClasses.ListItemSelected ]: selected
  }, CommonClasses.FlexEllipsis, className);

  return <Box container direction={"column"} gap={"XS"} flexShrink={0} style={{minHeight: 58}} className={classes} ref={ref} {...p}>
    <Box container gap={"XXS"} alignItems={"center"} justify={"space-between"}>
      {children}
    </Box>
  </Box>;
});
