import { gql }                  from "@apollo/client";
import { useQuery }             from "@apollo/client";
import { OperationVariables }   from "@apollo/client";
import { QueryHookOptions }     from "@apollo/client";
import { IQueryBuilderOptions } from "@relcu/gql-query-builder";
import * as builder             from "@relcu/gql-query-builder";
import { useMemo }              from "react";

export function useJqlQuery<TData = any, TVariables = OperationVariables>(
  query: IQueryBuilderOptions | IQueryBuilderOptions[],
  options: QueryHookOptions<TData, TVariables> & { operationName?: string }
) {
  const { documentNode, variables } = useMemo(() => getJqlQuery(query, options), [query, options?.operationName]);

  return useQuery<TData, TVariables>(documentNode, {
    ...options,
    variables: {
      ...variables,
      ...options?.variables
    }
  });
}

export function getJqlQuery(query: IQueryBuilderOptions | IQueryBuilderOptions[], options?: QueryHookOptions & { operationName?: string }) {
  const { query: queryString, variables } = builder.query(query, null, {
    operationName: options?.operationName || ""
  });
  return {
    documentNode: gql(queryString),
    variables
  };
}
