export const enum TypographyClasses {
  Typography = "typo",
  NoWrap = "typo--no-wrap",
  ExtraDark = "typo--extra-dark",

  Secondary = "typo--secondary",
  Tertiary = "typo--tertiary",
  TertiaryDark = "typo--tertiary-dark",
  LGSecondary = "typo--lg-secondary",

  Primary = "typo--primary",
  Success = "typo--success",
  Error = "typo--error",
  Warning = "typo--warning",
  Info = "typo--info",
  White = "typo--white",
  Light = "typo--light",

  TextTiny = "typo--text-tiny",
  TextSmall = "typo--text-small",
  Text = "typo--text",
  Title = "typo--title",
  SubTitle = "typo--subtitle",
  SubTitleSmall = "typo--subtitle-small",
  YellowDark = "typo--yellow-dark",

  Bold = "typo--bold",
  Medium = "typo--medium",
  Regular = "typo--regular",

  Line17 = "typo--line-17",
  Line13 = "typo--line-13",
  LineCaption = "typo--line-caption",
  LineOverline = "typo--line-overline",
  LineBody2 = "typo--line-body2",
  LineBody1 = "typo--line-body1",
  LineTitle = "typo--line-title",
  LineH3 = "typo--line-h3",
}
