import React                from "react";
import { useBoxProps }      from "../../Box";
import { Box }              from "../../Box";
import { Label }            from "../../Label";
import { useConsumeField }  from "../BaseField";
import { BaseFieldClasses } from "../BaseField";

export const RadioReadField = React.memo(function RadioReadField(props) {
  const { input: { label, required, value, name, checked, ...radioProps } } = useConsumeField();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(radioProps)}>
      <Label>{label}</Label>
      {checked ? "On" : "Off"}
    </Box>
  );
});

export default RadioReadField;