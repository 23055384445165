import { useMemo }               from "react";
import { useEffect }             from "react";
import { getHumanized }          from "../../../utils/schemaUtils";
import { useJqlQuery }           from "../../Layout/Jql";
import { RecipientSectionProps } from "./BulkRecipientList";
import { formatNumber }          from "@relcu/ui";
import { useRelayPagination }    from "@relcu/ui";

export function useBulkRecipientList(props: RecipientSectionProps) {
  const { query: { where = {}, order = [] }, selected, onSelect, find } = props;
  const { queryVariables } = useMemo(() => createWhereInputVariables(where, order), [where, order]);
  const { data, fetchMore, refetch } = useJqlQuery(find, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: queryVariables
  });

  const api = {
    get data() {
      // @ts-ignore
      const { pageInfo, edges, count } = Object(data?.[ find.operation ]);
      return { pageInfo, edges, count };
    },
    get loaded() {
      return !!data;
    },
    reload() {
      if (api.loaded) {
        refetch().catch(console.error);
      }
    },
    async fetchMore() {
      if (api.data.pageInfo) {
        const variables = {
          ...queryVariables,
          after: api.data.pageInfo.endCursor
        };

        return fetchMore({
          variables
        });
      }
    },
    get selectedId() {
      return selected?.id;
    },
    getInfo(lead) {
      const {
        loanPurpose,
        timezone,
        loanAmount = 0,
        leadSource,
        loanProgram,
        loanProduct
      } = lead;

      return [
        {
          label: "Lead source:",
          value: leadSource?.objectName || ""
        },
        {
          label: "Amount:",
          value: `$ ${formatNumber(loanAmount, 0)}`
        },
        {
          label: "Purpose:",
          value: getHumanized("Lead", "loanPurpose", loanPurpose)
        },
        {
          label: "Program:",
          value: getHumanized("Lead", "loanProgram", loanProgram)
        },
        {
          label: "Product:",
          value: getHumanized("Lead", "loanProduct", loanProduct)
        },
        {
          label: "Timezone:",
          value: getHumanized("Lead", "timezone", timezone)
        }
      ];
    }
  };
  const pagination = useRelayPagination({
    pageInfo: api.data.pageInfo,
    onLoadMore: api.fetchMore,
    fromTop: false
  });

  useEffect(api.reload, []);

  useEffect(() => {
    if (!selected) {
      const lead = api.data?.edges?.[ 0 ]?.node;
      onSelect(lead);
    }
  }, [api.data.edges]);

  return {
    ...api,
    ...pagination
  };
}

const createWhereInputVariables = (where, order) => {
  return {
    queryVariables: { where, order },
    subscriptionVariables: { where, order }
  };
};

