import React             from "react";
import { FC }            from "react";
import { Alignment }     from "../../../constants/Alignemnt";
import { CommonClasses } from "../../../theme/classes";
import { ButtonSizes }   from "../../Button";
import { Button }        from "../../Button";
import { ButtonGroup }   from "../../ButtonGroup";
import { CallOut }       from "../../CallOut";
import { ChipsSizes }    from "../../Chips";
import { ChipsColors }   from "../../Chips";
import { Chips }         from "../../Chips";
import { Tooltip }       from "../../Tooltip";

export interface StatusCallOutProps {
  disable: boolean,
  actions: { value: string, label: string }[],
  onAction: (availableActions) => void,
  status: string,
  states: any
}

export const StatusCallOut: FC<StatusCallOutProps> = React.memo(function StatusCallOut(props) {
  const { disable, actions, onAction, states } = props;

  return (
    <CallOut
      gap={"XXS"}
      avatar={
        <ButtonGroup height={50} collapse buttonMenu alignment={Alignment.BottomRight}>
          <Button disabled={disable} size={ButtonSizes.Big} onlyIcon icon={"flash_on"}>Trigger Action</Button>
          {
            actions.map((action, index) => {
              return <Button key={index} onClick={() => onAction(action)}>{action.label}</Button>;
            })
          }
        </ButtonGroup>
      }
      header={states[ status ]?.stage && <p className={CommonClasses.TextSecondary}>{states[ status ]?.stage}</p>}
      content={
        states[ status ]?.status && states[ status ]?.stage &&
        <Tooltip title={`${states[ status ]?.stage} - ${states[ status ]?.status}`} alignment={Alignment.Bottom}>
          <Chips style={{ width: 152 }} size={ChipsSizes.Medium} color={ChipsColors.Grey} disabled
                 label={states[ status ]?.status} length={17}/>
        </Tooltip>
      }
    />
  );
});