import React                 from "react";
import { FC }                from "react";
import { classNames }        from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import { Box }               from "@relcu/ui";
import "./mail-section.css";

export enum MailSectionPosition {
  Top = "top",
  Bottom = "bottom",
}
export interface MailSectionProps extends BoxComponentProps {
  position?: MailSectionPosition;
}

export const MailSection: FC<MailSectionProps> = React.memo(function MailSection(props) {
  const { className, position, ...p } = props;
  const classes = classNames(MailSectionClasses.MailSection,
    {
      [MailSectionClasses.Top]: position == MailSectionPosition.Top,
      [MailSectionClasses.Bottom]: position == MailSectionPosition.Bottom
    },
    className);

  return (
    <Box className={classes} {...p}/>
  );
});

export const enum MailSectionClasses {
  MailSection = "mail-section",
  Top = "mail-section--top",
  Bottom = "mail-section--bottom",
}