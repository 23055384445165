import React               from "react";
import { FC }              from "react";
import { formatNumber }    from "@relcu/ui";
import { Field }           from "@relcu/form";
import { Typography }      from "@relcu/rc";
import { Icon }            from "@relcu/rc";
import { CellGroupHeader } from "@relcu/rc";

export const GroupSummaryCell: FC<{ name: string, bordered?: boolean }> = React.memo((props) => {
  return (
    <CellGroupHeader justify={"space-between"}>
      <Icon type={"attach_money"} style={{ color: "var(--rc-text-tertiary)", fontSize: 16 }}/>
      <Field name={props.name}>
        {({ input }) => <Typography weights={"medium"}>{formatNumber(input.value, 2)}</Typography>}
      </Field>
    </CellGroupHeader>
  );
});
