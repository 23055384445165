import React      from "react";
import { Box }    from "../Box";
import { Loader } from "../Loader";

export const Spinner = React.memo(function RootLoader() {
  return (
    <Box style={{ height: "100vh" }} container justify={"center"} alignItems={"center"}>
      <Loader variant="circular"/>
    </Box>
  );
});
