export function toDate(argument) {

  const argStr = Object.prototype.toString.call(argument);

  if (
    argument instanceof Date ||
    (typeof argument === "object" && argStr === "[object Date]")
  ) {
    return new Date(argument.getTime());
  } else if (typeof argument === "number" || argStr === "[object Number]") {
    return new Date(argument);
  } else {
    if (
      (typeof argument === "string" || argStr === "[object String]") &&
      typeof console !== "undefined"
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        "Starting with v2.0.0-beta.1 date-fns doesn't accept strings as arguments. Please use `parseISO` to parse strings. See: https://git.io/fjule"
      );
      // eslint-disable-next-line no-console
      console.warn(new Error().stack);
    }
    return new Date(NaN);
  }
}

export function startOfDay(dirtyDate) {
  const date = toDate(dirtyDate);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function addDays(dirtyDate, dirtyAmount) {
  const date = toDate(dirtyDate);
  const amount = parseInt(dirtyAmount);
  if (isNaN(amount)) {
    return new Date(NaN);
  }
  if (!amount) {
    // If 0 days, no-op to avoid changing times in the hour before end of DST
    return date;
  }
  date.setDate(date.getDate() + amount);
  return date;
}

export function isSameDay(dirtyDateLeft, dirtyDateRight) {

  const dateLeftStartOfDay = startOfDay(dirtyDateLeft);
  const dateRightStartOfDay = startOfDay(dirtyDateRight);

  return dateLeftStartOfDay.getTime() === dateRightStartOfDay.getTime();
}

export function eachDayOfInterval(dirtyInterval, options?) {

  const interval = dirtyInterval || {};
  const startDate = toDate(interval.start);
  const endDate = toDate(interval.end);

  const endTime = endDate.getTime();

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startDate.getTime() <= endTime)) {
    throw new RangeError("Invalid interval");
  }

  const dates = [];

  const currentDate = startDate;
  currentDate.setHours(0, 0, 0, 0);

  const step = options && "step" in options ? Number(options.step) : 1;
  if (step < 1 || isNaN(step)) {
    throw new RangeError("`options.step` must be a number greater than 1");
  }

  while (currentDate.getTime() <= endTime) {
    dates.push(toDate(currentDate));
    currentDate.setDate(currentDate.getDate() + step);
    currentDate.setHours(0, 0, 0, 0);
  }

  return dates;
}

export function isBefore(dirtyDate, dirtyDateToCompare) {
  const date = toDate(dirtyDate);
  const dateToCompare = toDate(dirtyDateToCompare);
  return date.getTime() < dateToCompare.getTime();
}

export function isAfter(dirtyDate, dirtyDateToCompare) {
  const date = toDate(dirtyDate);
  const dateToCompare = toDate(dirtyDateToCompare);
  return date.getTime() > dateToCompare.getTime();
}

export function isBeforeOrEqual(dirtyDate, dirtyDateToCompare) {
  const date = toDate(dirtyDate);
  const dateToCompare = toDate(dirtyDateToCompare);
  return date.getTime() <= dateToCompare.getTime();
}

export function getYear(dirtyDate) {
  const date = toDate(dirtyDate);
  return date.getFullYear();
}

export function getMonth(dirtyDate) {
  const date = toDate(dirtyDate);
  return date.getMonth();
}

export function startOfToday() {
  return startOfDay(Date.now());
}

export function startOfMonth(dirtyDate) {
  const date = toDate(dirtyDate);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function addMonths(dirtyDate, dirtyAmount) {

  const date = toDate(dirtyDate);
  const amount = parseInt(dirtyAmount);
  if (isNaN(amount)) {
    return new Date(NaN);
  }
  if (!amount) {
    return date;
  }
  const dayOfMonth = date.getDate();
  const endOfDesiredMonth = new Date(date.getTime());
  endOfDesiredMonth.setMonth(date.getMonth() + amount + 1, 0);
  const daysInMonth = endOfDesiredMonth.getDate();
  if (dayOfMonth >= daysInMonth) {
    return endOfDesiredMonth;
  } else {
    date.setFullYear(
      endOfDesiredMonth.getFullYear(),
      endOfDesiredMonth.getMonth(),
      dayOfMonth
    );
    return date;
  }
}

export function isWithinInterval(dirtyDate, dirtyInterval) {
  const interval = dirtyInterval || {};
  const time = toDate(dirtyDate).getTime();
  const startTime = toDate(interval.start).getTime();
  const endTime = toDate(interval.end).getTime();

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startTime <= endTime)) {
    throw new RangeError("Invalid interval");
  }

  return time >= startTime && time <= endTime;
}

export function endOfMonth(dirtyDate) {
  const date = toDate(dirtyDate);
  const month = date.getMonth();
  date.setFullYear(date.getFullYear(), month + 1, 0);
  date.setHours(23, 59, 59, 999);
  return date;
}

export function endOfWeek(dirtyDate, dirtyOptions?) {

  const options = dirtyOptions || {};

  const locale = options.locale;
  const localeWeekStartsOn =
    locale && locale.options && locale.options.weekStartsOn;
  const defaultWeekStartsOn =
    localeWeekStartsOn == null ? 0 : parseInt(localeWeekStartsOn);
  const weekStartsOn =
    options.weekStartsOn == null
      ? defaultWeekStartsOn
      : parseInt(options.weekStartsOn);

  // Test if weekStartsOn is between 0 and 6 _and_ is not NaN
  if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  }

  const date = toDate(dirtyDate);
  const day = date.getDay();
  const diff = (day < weekStartsOn ? -7 : 0) + 6 - (day - weekStartsOn);

  date.setDate(date.getDate() + diff);
  date.setHours(23, 59, 59, 999);
  return date;
}

export function getDay(dirtyDate) {
  const date = toDate(dirtyDate);
  return date.getDay();
}

export function startOfWeek(dirtyDate, dirtyOptions?) {

  const options = dirtyOptions || {};
  const locale = options.locale;
  const localeWeekStartsOn =
    locale && locale.options && locale.options.weekStartsOn;
  const defaultWeekStartsOn =
    localeWeekStartsOn == null ? 0 : parseInt(localeWeekStartsOn);
  const weekStartsOn =
    options.weekStartsOn == null
      ? defaultWeekStartsOn
      : parseInt(options.weekStartsOn);

  if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  }

  const date = toDate(dirtyDate);
  const day = date.getDay();
  const diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;

  date.setDate(date.getDate() - diff);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function isSameMonth(dirtyDateLeft, dirtyDateRight) {

  const dateLeft = toDate(dirtyDateLeft);
  const dateRight = toDate(dirtyDateRight);
  return (
    dateLeft.getFullYear() === dateRight.getFullYear() &&
    dateLeft.getMonth() === dateRight.getMonth()
  );
}

export function isToday(dirtyDate: Date) {
  return isSameDay(dirtyDate, Date.now());
}

export function setYear(dirtyDate, dirtyYear) {

  const date = toDate(dirtyDate);
  const year = parseInt(dirtyYear);

  date.setFullYear(year);
  return date;
}

export function getDaysInMonth(dirtyDate: Date) {

  const date = toDate(dirtyDate);
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const lastDayOfMonth = new Date(0);
  lastDayOfMonth.setFullYear(year, monthIndex + 1, 0);
  lastDayOfMonth.setHours(0, 0, 0, 0);
  return lastDayOfMonth.getDate();
}

export function setMonth(dirtyDate: Date, month: number) {

  const date = toDate(dirtyDate);
  const year = date.getFullYear();
  const day = date.getDate();

  const dateWithDesiredMonth = new Date(0);
  dateWithDesiredMonth.setFullYear(year, month, 15);
  dateWithDesiredMonth.setHours(0, 0, 0, 0);
  const daysInMonth = getDaysInMonth(dateWithDesiredMonth);
  // Set the last day of the new month
  // if the original date was the last day of the longer month
  date.setMonth(month, Math.min(day, daysInMonth));
  return date;
}

export function addMilliseconds(
  dirtyDate: Date | number,
  dirtyAmount: number
): Date {

  const timestamp = toDate(dirtyDate).getTime();
  return new Date(timestamp + dirtyAmount);
}

export function addMinutes(
  dirtyDate: Date | number,
  dirtyAmount: number
): Date {
  const MILLISECONDS_IN_MINUTE = 60000;
  return addMilliseconds(dirtyDate, dirtyAmount * MILLISECONDS_IN_MINUTE);
}

export function subMinutes(dirtyDate: Date | number, dirtyAmount: number) {
  return addMinutes(dirtyDate, -dirtyAmount);
}

export function isValidDate(value) {
  if (!value) {
    return false;
  }
  if (!(value instanceof Date)) {
    value = new Date(value);
  }
  return !isNaN(value);
}

export function addHours(
  dirtyDate: Date | number,
  dirtyAmount: number
): Date {
  const MILLISECONDS_IN_HOUR = 3600000;
  return addMilliseconds(dirtyDate, dirtyAmount * MILLISECONDS_IN_HOUR);
}

export function subHours(dirtyDate: Date | number, dirtyAmount: number) {
  return addHours(dirtyDate, -dirtyAmount);
}

export function subDays(dirtyDate: Date | number, dirtyAmount: number) {
  return addDays(dirtyDate, -dirtyAmount);
}
