import React                 from "react";
import { TypographySize }    from "../..";
import { TypographyColor }   from "../..";
import { Typography }        from "../..";
import { classNames }        from "../..";
import { BoxComponentProps } from "../..";
import { Box }               from "../..";

export interface LabelProps extends BoxComponentProps {
  required?: boolean
  mLeft?: boolean
}

Label.defaultProps = {
  mLeft: false
};
export function Label(props: LabelProps) {
  const { className, children, required, mLeft, ...p } = props;
  const classes = classNames(LabelClasses.Label, {
    [ LabelClasses.Required ]: required,
    [ LabelClasses.MarginLeft ]: mLeft
  }, className);

  return <Box container className={classes} {...p}>
    <Typography color={TypographyColor.Secondary} size={TypographySize.TextSmall}>
      {children}
    </Typography>
  </Box>;
}
export enum LabelClasses {
  Label = "label",
  Required = "required",
  MarginLeft = "margin-left"
}
