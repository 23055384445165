import * as React from "react";


export function useEventCallback(fn) {
  const ref = React.useRef(fn);
  useEnhancedEffect(() => {
    ref.current = fn;
  });
  // @ts-ignore
  return React.useCallback((...args) => (0, ref.current)(...args), []);
}

const useEnhancedEffect = typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;
