import { useState }    from "react";
import { usePrevious } from "@relcu/ui";
import { useMounted }  from "@relcu/ui";

export function useShowMention(notifications, containerDomElement) {
  const [showMention, setShowMention] = useState(false);
  const unReadCount = notifications.map(({ node }) => !node.unread);
  const prevUnReadCount = usePrevious(unReadCount.length);
  useMounted(() => {
    if (unReadCount.length != 0 && prevUnReadCount < unReadCount.length && containerDomElement?.scrollTop > 70) {
      setShowMention(true);
    }
  }, [unReadCount]);

  return {
    showMention,
    setShowMention
  };
}