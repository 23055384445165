import React          from "react";
import { FC }         from "react";
import { useFilters } from "./useFilters";

export type UseFilters = ReturnType<typeof useFilters>;
export const FiltersContext = React.createContext<UseFilters>(null);
export const FiltersProvider: FC<any> = React.memo(function FiltersProvider(props) {
  const {children} = props
  return <FiltersContext.Provider value={useFilters()}>
    {children}
  </FiltersContext.Provider>
});
