export *         from "@relcu/final-form";
import { getIn } from "@relcu/final-form";

export { OnBlur }           from "./OnBlur";
export type { OnBlurProps } from "./OnBlur";

export { OnChange }           from "./OnChange";
export type { OnChangeProps } from "./OnChange";

export type FieldName = string
export type FieldPattern = FieldName | RegExp | (FieldName | RegExp)[]
export type UpdatesByName = {
  [ FieldName: string ]: (value: any, allValues?: Object, prevValues?: Object) => any
}
export type UpdatesForAll = (
  value: any,
  field: string,
  allValues?: Object,
  prevValues?: Object
) => { [ FieldName: string ]: any }
export type Updates = UpdatesByName | UpdatesForAll
export type Calculation = {
  field: FieldPattern,
  updates: Updates,
  isEqual?: (a: any, b: any) => boolean,
}
const isPromise = (obj => !!obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function");
const tripleEquals = (a, b) => a === b;
export function createCalculation<FormValues = object>(
  ...calculations: Calculation[]
) {
  return form => {
    let previousValues = {};
    const unsubscribe = form.subscribe(({
      values
    }) => {
      form.batch(() => {
        const runUpdates = (field, isEqual, updates) => {
          const next = values && getIn(values, field);
          const previous = previousValues && getIn(previousValues, field);

          if (!isEqual(next, previous)) {
            if (typeof updates === "function") {
              const results = updates(next, field, values, previousValues);

              if (isPromise(results)) {
                results.then(resolved => {
                  Object.keys(resolved).forEach(destField => {
                    form.change(destField, resolved[ destField ]);
                  });
                });
              } else {
                Object.keys(results).forEach(destField => {
                  form.change(destField, results[ destField ]);
                });
              }
            } else {
              Object.keys(updates).forEach(destField => {
                const update = updates[ destField ];
                const result = update(next, values, previousValues);

                if (isPromise(result)) {
                  result.then(resolved => {
                    form.change(destField, resolved);
                  });
                } else {
                  form.change(destField, result);
                }
              });
            }
          }
        };

        const fields = form.getRegisteredFields();
        calculations.forEach(({
          field,
          isEqual,
          updates
        }) => {
          if (typeof field === "string") {
            runUpdates(field, isEqual || tripleEquals, updates);
          } else {
            // field is a either array or regex
            const matches = Array.isArray(field) ? name => ~field.indexOf(name) || field.findIndex(f => f instanceof RegExp && f.test(name)) !== -1 : name => field.test(name);
            fields.forEach(fieldName => {
              if (matches(fieldName)) {
                runUpdates(fieldName, isEqual || tripleEquals, updates);
              }
            });
          }
        });
        previousValues = values;
      });
    }, {
      values: true
    });
    return unsubscribe;
  };
}
