import React              from "react";
import { FC }       from "react";
import { getIn }    from "@relcu/form";
import { FormSpy }  from "@relcu/form";
import { Provider } from "./Provider";

export const ProviderSwitcher: FC<any> = React.memo(function ProviderSwitcher(props) {
  return <FormSpy subscription={{ values: true }}>
    {
      ({values}) => {
        const type = getIn(values, "provider.type");
        switch (type) {
          case "gmail":
          case "outlook":
            return <Provider type={type}/>
          default:
            return null
        }
      }
    }
  </FormSpy>;
});
