import { useMemo }       from "react";
import { useEffect }     from "react";
import { useState }      from "react";
import { useRef }        from "react";
import React             from "react";
import { FC }            from "react";
import { CommonClasses } from "../../../theme/classes";
import { classNames }    from "../../../utils/classNames";
import { FontIcon }      from "../../Icon";
import { Typography }    from "../../Typography";
import { QueueClasses }  from "./QueueClasses";

export interface CoolDownProps {
  coolDownTime: number
  coolDownPercentage: number
  showTime: boolean
  collapsed: boolean
}

export const CoolDown: FC<CoolDownProps> = React.memo(function CoolDown(props) {
  const { coolDownTime, coolDownPercentage, showTime, collapsed } = props;
  const coolDawnRef = useRef(null);
  const arcRef = useRef(null);
  const [seconds, setSeconds] = useState(coolDownTime);

  function toHHMMSS(coolDown) {
    let sec_num = parseInt(coolDown, 10);
    let minutes: any = sec_num > 0 ? Math.floor(sec_num / 60) : 0;
    let seconds: any = sec_num > 0 ? sec_num - (minutes * 60) : 0;

    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  }
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((s) => {
        if (s > 0) {
          s--;
        }
        return s;
      });
    }, 1000);
    return () => {
      timer && clearInterval(timer);
    };
  }, [coolDownTime]);

  const per = useMemo(() => 175 - (175 * coolDownPercentage) / 100, [coolDownPercentage]);
  return <>
    {
      showTime ?
        <Typography className={
          classNames(QueueClasses.Time, {
            [ QueueClasses.SmallTime ]: collapsed
          })
        }>{toHHMMSS(seconds)}</Typography>
        :
        <FontIcon
          type="rc_custom-cooldown"
          className={classNames(QueueClasses.CoolDownIcon, CommonClasses.GrayIcon)}
        />
    }
    <svg viewBox={"10 10 60 60 "} className={QueueClasses.Circle}
         style={{ "--animation-duration": `${coolDownTime}s`, "--animation-percentage": per } as any}>
      <path ref={coolDawnRef} fill="none" stroke="#4a5ae8" strokeWidth="4"
            className={QueueClasses.LeftTimeAnimated}
            d="M 39.51133261975606 12.004264535621044 A 28 28 0 1 0 40 12"
            strokeLinecap={(seconds ? "round" : "unset") as any}/>
      <path ref={arcRef} fill="none" stroke="#9ea6b4" strokeWidth="1.5"
            d="M 39.99999999999999 12 A 28 28 0 1 0 40.48866738024393 12.004264535621044"
            className={QueueClasses.PastTimeAnimated}
      />
    </svg>
  </>;
});
