import React from 'react';

type ExternalPropsContextValue<T> = {
  readOnly?: boolean;
  plaintext?: boolean;
  disabled?: boolean;
  layout?: "horizontal" | "vertical" | "inline" | "floating";
};

export type FormContextValue<T = Record<string, any>, errorMsgType = any> = ExternalPropsContextValue<T>;

export const FormContext = React.createContext<FormContextValue>({});
export const FormPlaintextContext = React.createContext<boolean>(false);

export default FormContext;
