import React                     from "react";
import { FC }                    from "react";
import { Alignment }             from "../../..";
import { Box }                   from "../../Box";
import { useBoxProps }           from "../../Box";
import { Label }                 from "../../Label";
import { Tooltip }               from "../../Tooltip";
import { useConsumeField }       from "../BaseField";
import { BaseFieldClasses }      from "../BaseField";
import { MultiTextFieldClasses } from "./MultiTextFieldClasses";

export const MultiTextReadField = React.memo(function MultiTextReadField(props) {
  const { input: { readPlaceholder, label, withTooltip = true, required, value, style, ...multiTextProps } } = useConsumeField();

  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps({ ...multiTextProps, ...style })} style={style}>
      <Label>{label}</Label>
      {withTooltip
        ? <Tooltip title={value} alignment={Alignment.Bottom}>
          <Box className={MultiTextFieldClasses.MultiTextReadContainer}>
            {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
          </Box>
        </Tooltip>
        : <Box className={MultiTextFieldClasses.MultiTextReadContainer}>
          {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
        </Box>
      }
    </Box>
  );
});

export default MultiTextReadField; //todo need to test
