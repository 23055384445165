import React                     from "react";
import { InputState }      from "../../Input/Input";
import { MultiSelect } from "../../Input/MultiSelect";
import { useConsumeField } from "../BaseField";
import { MultiChoiceFieldProps } from "./MultiChoiceField";

export const MultiChoiceEditField = React.memo(function MultiChoiceEditField(props) {
  let { input: { read, write, ...p }, meta: { touched, error, submitError } } = useConsumeField<MultiChoiceFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  return (
    <MultiSelect
      getOptionValueByKey
      {...p}
      options={p.options}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
      direction={"column"}
    />
  );
});
export default MultiChoiceEditField;
