import { FC }                       from "react";
import React                        from "react";
import { classNames }               from "../..";
import { SpeakingIndicatorClasses } from "./SpeakingIndicatorClasses";

export interface SpeakingIndicatorProps {
  speaking?: boolean
}

export const SpeakingIndicator: FC<SpeakingIndicatorProps> = React.memo((props) => {
  const { speaking } = props;
  return (
    <svg className={classNames(SpeakingIndicatorClasses.SpeakingIndicator, {
      [ SpeakingIndicatorClasses.Active ]: speaking
    })} xmlns="http://www.w3.org/2000/svg" width="24"
         height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-1441 -315) translate(1256 311) translate(185 4)">
              <path d="M0 0H24V24H0z"/>
              <rect x="4" y="8" width="3.5" height="4" fill="#92C353" rx="2"/>
              <rect x="10" y="8" width="3.5" height="4" fill="#92C353" rx="2"/>
              <rect x="16" y="8" width="3.5" height="4" fill="#92C353" rx="2"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
});
SpeakingIndicator.defaultProps = {
  speaking: false
};
