import React               from "react";
import { InputState }      from "../../Input/Input";
import { TextArea }        from "../../Input/TextArea";
import { useConsumeField } from "../BaseField";

export const MultiTextEditField = React.memo(function MultiTextEditField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField();
  const hasError = (touched && !!error) || !!submitError;
  return (
    <TextArea
      state={(hasError && InputState.Error) || undefined}
      {...input}
      message={hasError ? (error || submitError) : input.message}
    />
  );
});
export default MultiTextEditField;
