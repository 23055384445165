export const enum MessengerClasses {
  Messenger = "messenger",
  MessengerAttachments = "messenger__attachments",
  MessengerCharacters = "messenger__characters",
  MessengerError = "messenger__error",
  Dragging = "messenger--dragging",
  MessengerTextArea = "messenger-textarea",
  FileInput = "file-input",
  Drop = "messenger--drop",
  MessengerDisabled = "messenger--disabled"
}