import { Box }                                           from "@relcu/ui";
import React                                             from "react";
import { FC }                                            from "react";
import { useMutation }                                   from "@apollo/client";
import { useEffect }                                     from "react";
import { Typography }                                    from "@relcu/ui";
import { MarkMessagesAsReadVariables }                   from "../../../../../__types__/MarkMessagesAsRead";
import { MarkMessagesAsRead }                            from "../../../../../__types__/MarkMessagesAsRead";
import { MsgAttachment }                                 from "../../../../../Message/Msg/MsgAttachments/MsgAttachment";
import { MARK_MESSAGES_AS_READ }                         from "../../../../../operations.graphql";
import { BulkPreview }                                   from "../../../../Section/BulkSection/BulkPreview";
import { GetBulkPhoneMessages_phoneMessages_edges_node } from "./__types__/GetBulkPhoneMessages";

export interface BulkItemPreviewProps {
  selectedItem: { data: GetBulkPhoneMessages_phoneMessages_edges_node, cursor: string } | null;
  count: number;
  skip: number;
  data: GetBulkPhoneMessages_phoneMessages_edges_node[];
  slide: (path: string) => void;
  status: string | null;
}

export const BulkItemPreview: FC<BulkItemPreviewProps> = React.memo(function BulkItemPreview(props) {
  const { count, selectedItem, status, slide, data, skip } = props;
  const [markAsRead] = useMutation<MarkMessagesAsRead, MarkMessagesAsReadVariables>(MARK_MESSAGES_AS_READ);
  const message: any = selectedItem.data;
  const receiver = message?.participants.find(p => p.type == "receiver");

  useEffect(() => {
    if (selectedItem?.data?.unread) {
      markAsRead({ variables: { ids: [selectedItem.data.id] } });
    }
  }, [selectedItem.data]);

  const index = data.findIndex(phoneMessage => {
    return phoneMessage.objectId == selectedItem.data.objectId;
  });

  return <BulkPreview
    skip={skip}
    selectedIndex={index}
    count={count}
    selectedItem={selectedItem}
    slide={slide}
    status={status}
    messageStatus={message?.status}
    receiver={receiver?.party?.objectName}>
    {message &&
      <>
        <Typography style={{ textIndent: "38px" }}>
          {message.content}
        </Typography>
        <Box container gap={"XXS"} wrap={"wrap"} style={{ paddingLeft: 38 }}>
          {
            message.attachments?.map((attachment, index) => (
              <MsgAttachment
                key={index}
                incoming
                attachment={attachment}/>
            ))
          }
        </Box>
      </>
    }
  </BulkPreview>;
});
