import "./pdf-modal.css";
import { VerticalDivider } from "@relcu/ui";
import { useState }        from "react";
import React               from "react";
import { FC }              from "react";
import { CircularLoader }  from "@relcu/ui";
import { ButtonProps }     from "@relcu/ui";
import { ButtonVariants }  from "@relcu/ui";
import { Button }          from "@relcu/ui";
import { ModalProps }      from "@relcu/ui";
import { Box }             from "@relcu/ui";
import { Modal }           from "@relcu/ui";
import { PdfModalClasses } from "./PdfModalClasses";

export interface PdfDialogProps extends Partial<ModalProps> {
  url: string;//todo I think here should be object that contains link to pdf file
  actions?: ButtonProps[];
  onCancel?: () => void;
}

export const PdfModal: FC<PdfDialogProps> = React.memo(function PdfDialog(props) {
  const { onClose, url, actions, onCancel } = props;
  const [loading, setLoading] = useState(true);
  const handleClose = ()=>{
    onCancel?.()
    onClose();
  }
  return <Modal
    onClose={onClose}
    open={true}
    closeIcon={true}
    variant="giant"
    disableBackdropClick={false}
    className={PdfModalClasses.Modal}
  >
    <Box container direction={"column"}>
      {
        loading &&
        <Box container flex={1} alignItems={"center"} justify={"center"} className={PdfModalClasses.Loader}>
          <CircularLoader/>
        </Box>
      }
      <embed type="application/pdf" onLoad={(e) => setLoading(false)} src={url} width={"100%"}
             style={{ height: "calc(100vh - 64px)" }}/>
      {/*height={document.body.clientHeight - 64}*/}
      <Box container gap={"XS"} className={PdfModalClasses.Footer} justify={"end"} alignItems={"center"}>
        <Button variant={ButtonVariants.Ghost} icon={"clear"} onClick={handleClose}>
          CLOSE
        </Button>
        {
          actions?.map((a, i) => {
            return <React.Fragment key={i}>
              <VerticalDivider/>
              <Button {...a}/>
            </React.Fragment>;
          })
        }
      </Box>
    </Box>
  </Modal>;
});

export default PdfModal;
