import React                 from "react";
import { classNames }        from "../../..";
import { ListItemClasses }   from "..";
import { Tooltip }           from "../../..";
import { FontIcon }          from "../../..";
import { BoxComponentProps } from "../../..";
import { Alignment }         from "../../../constants/Alignemnt";
import { GlobalClasses }     from "../../../constants/GlobalClasses";
import { Box }               from "../../Box";

export interface ListItemEmailProps extends BoxComponentProps {
  email: string
  label?:string
}

export function ListItemEmail(props: ListItemEmailProps) {
  const { className, email,label, ...p } = props;
  const classes = classNames(ListItemClasses.ListItemText, className);

  return <Box container className={classes} gap={"XXXS"} alignItems={"center"} {...p}>
    <FontIcon type="email"/>
    <Tooltip title={label} alignment={Alignment.Top}>
      <p className={GlobalClasses.EllipsisFlex}>
        {email}
      </p>
    </Tooltip>
  </Box>;
}
