import React                        from "react";
import { RsRefForwardingComponent } from "../@types/common";
import Container                    from "../Container";
import { ContainerProps }           from "../Container";
import { useClassNames }            from "../utils";
import PageSidebar                  from "./PageSidebar";
import PageToggle                   from "./PageToggle";

export interface PageProps extends ContainerProps {

}

export interface PageComponent
  extends RsRefForwardingComponent<"section", PageProps & { ref?: React.Ref<HTMLDivElement> }> {
  Sidebar: typeof PageSidebar;
  Toggle: typeof PageToggle;
}

export const Page: PageComponent = React.forwardRef((props: PageProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    classPrefix = "page",
    className,
    children,
    ...rest
  } = props;
  const { withClassPrefix, merge } = useClassNames(classPrefix);
  const classes = merge(className, withClassPrefix());

  return (
    <Container {...rest} ref={ref} className={classes}>
      {children}
    </Container>
  );
}) as unknown as PageComponent;

Page.Sidebar = PageSidebar;
Page.Toggle = PageToggle;

Page.displayName = "Page";

export default Page;
