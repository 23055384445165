export const enum AudioPlayerClasses {
  AudioPlayer = "audio-player",
  Player = "player",
  PlayerTogglePlay = "player-toggle-play",
  PlayerToggleRecord = "player-toggle-record",
  PlayerTime = "player-time",
  PlayerCurrentTime = "player-current-time",
  PlayerTotalTime = "player-total-time",
  PlayerProgressBarWrapper = "player-progress-bar-wrapper",
  PlayerProgressBar = "player-progress-bar",
  PlayerProgressBarIndicator = "player-progress-bar-indicator",
}
