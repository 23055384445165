import { StepInterface }    from "@relcu/ui";
import React                from "react";
import { FC }               from "react";
import { StepBody }         from "@relcu/ui";
import { StepHeader }       from "@relcu/ui";
import { StepContainer }    from "@relcu/ui";
import { StepSection }      from "@relcu/ui";
import { FieldArray }       from "@relcu/form";
import { StepClasses }      from "@relcu/ui";
import { StepRow }          from "@relcu/ui";
import { StepEmptySection } from "@relcu/ui";
import { Box }              from "@relcu/ui";
import { ListItemText }     from "@relcu/ui";
import { ChoiceField }      from "@relcu/ui";
import { FontIcon }         from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { ButtonVariants }   from "@relcu/ui";
import { CommonClasses }    from "@relcu/ui";
import { FieldSpy }         from "@relcu/ui";

export interface LeadSortProps  extends StepInterface {
  name: string;
  handleClean?;
  prioritizeByOptions;
  orderOptions;
}
export const LeadSort: FC<LeadSortProps> = React.memo(function LeadSort(props) {
  const { name, handleClean, onChange, prioritizeByOptions, orderOptions, title } = props;
  return <StepContainer>
    <StepHeader
      title={title}/>
    <StepBody>
      <StepSection title={"Lead sorting"} tooltip={"Define the logic for lead prioritization."}>
        <FieldArray name={name}>
          {
            ({ fields, meta }) => {
              return <>
                {
                  !fields.length ?
                    <StepEmptySection
                      content={`If you skip this step filtered leads will be distributed to all users.`}
                      title={`Click on “Add filter” to map the filtered results.`}
                      onClick={() => fields.push({})}>
                    </StepEmptySection>
                    :
                    <Box container direction={"column"} gap={"XXXS"}>
                      <Box container gap={"XS"} alignItems={"center"} className={StepClasses.StepRowHeader}>
                        <ListItemText flexShrink={0} flex={"1 1 calc(50% - ( 30px / 2 ))"}
                                      className={StepClasses.StepItem}
                                      text={"Prioritize by"} tiny/>
                        <ListItemText flexShrink={0} flex={"1 1 calc(50% - ( 30px / 2 ))"}
                                      className={StepClasses.StepItem}
                                      text={"Order"} tiny/>
                        <FontIcon flexShrink={0} type={"" as any}/>
                      </Box>
                      {
                        fields.map((name, index) => {
                          return <StepRow gap={"XS"} key={index}>
                            <ChoiceField halfSize name={`${name}.prioritizeBy`}
                                         options={prioritizeByOptions ?? []}
                                         placeholder={"Select field"}/>
                            <ChoiceField halfSize name={`${name}.order`}
                                         options={orderOptions ?? [{ value: "desc", label: "Descending" }, { value: "asc", label: "Ascending" }]}
                                         placeholder={"Select order"}/>
                            <FontIcon type={index == 0 ? "add" : "remove"} className={CommonClasses.ClickableIcon}
                                      onClick={() => index == 0 ? fields.push({}) : fields.remove(index)}/>
                          </StepRow>;
                        })
                      }
                      {
                        handleClean &&
                        <Button
                          alignSelf={"end"}
                          variant={ButtonVariants.Ghost}
                          onClick={handleClean}>
                          Clear filter
                        </Button>
                      }
                    </Box>
                }
              </>;
            }
          }
        </FieldArray>
      </StepSection>
    </StepBody>
  </StepContainer>;
});
