import React              from "react";
import { FC }             from "react";
import { RadioProps }     from "../../Input/Radio";
import { BaseFieldProps } from "../BaseField";
import { BaseField }      from "../BaseField";
import RadioEditField     from "./RadioEditField";
import RadioReadField     from "./RadioReadField";

export type RadioFieldProps = BaseFieldProps & Partial<RadioProps>;

export const RadioField: FC<RadioFieldProps> = React.memo(function RadioField(props) {
  return (
    <BaseField {...props} readView={<RadioReadField/>} editView={<RadioEditField/>} type={"radio"}/>
  );
});