import React         from "react";
import { FC }        from "react";
import { Routes }    from "@relcu/react-router";
import { Navigate }  from "@relcu/react-router";
import { Route }     from "@relcu/react-router";
import { Page }      from "./Page";
import { useLayout } from "./useLayout";

export const Layout: FC = React.memo(function Content() {
  const layout = useLayout();
  const { role, objectId } = layout.$viewer;
  const path = (role === "loan_officer" || role === "lead_provider") ? `/user/${objectId}/focus` : "/home";

  return <Routes>
    {
      layout.routes.map(layout => (
        <Route key={layout.route} path={layout.route} element={<Page layout={layout}/>}/>
      ))
    }
    <Route path={"*"} element={<Navigate to={path} replace={true}/>}/>
  </Routes>;
});
