import { useMemo }                                   from "react";
import { useCallback }                               from "react";
import { useState }                                  from "react";
import { gql }                                       from "@apollo/client";
import { useAlert }                                  from "@relcu/ui";
import { useLazyQuery }                              from "@apollo/client";
import { useLocation }                               from "@relcu/react-router";
import { useNavigate }                               from "@relcu/react-router";
import { useSchemaDialog }                           from "../SchemaDialog";
import { GetContactByPhoneNumbersOrEmailsVariables } from "./__types__/GetContactByPhoneNumbersOrEmails";
import { GetContactByPhoneNumbersOrEmails }          from "./__types__/GetContactByPhoneNumbersOrEmails";
import { GetContactByPhoneNumbersOrEmailsOldVariables } from "./__types__/GetContactByPhoneNumbersOrEmailsOld";
import { GetContactByPhoneNumbersOrEmailsOld }       from "./__types__/GetContactByPhoneNumbersOrEmailsOld";
import { ContactDialogProps }                        from "./ContactDialog";

export function useContactDialog(props: ContactDialogProps) {
  let { action, onClose, navigate: needToNavigate = true } = props;
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { error, success } = useAlert();
  const [createContactData, setCreateContactData] = useState(null);
  const [needResolve, setNeedResolve] = useState(false);
  const [resolveDuplicationData, setResolveDuplicationData] = useState(null);
  const [merging, setMerging] = useState(false);
  const [resolveDuplicationErrors, setResolveDuplicationErrors] = useState(null);
  const [load, { data: { contacts } = Object() }] = useLazyQuery<GetContactByPhoneNumbersOrEmailsOld,
    GetContactByPhoneNumbersOrEmailsOldVariables>(GET_CONTACT_BY_PHONE_NUMBERS_OR_EMAILS_OLD, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const { initialValues, loading, sections, jql } = useSchemaDialog(props);

  const handleResolve = async () => {
    const numbers = createContactData.phones.map(p => p.number);
    const emails = createContactData.emails.map(p => p.address);
    if (numbers.length || emails.length) {
      load({
        variables: { numbers, emails, id: initialValues?.id ?? null }
      }).catch(console.error);

      navigate(pathname, { state: { ...state, form: "DuplicationForm", title: "Resolve duplication" } });

    }
  };
  const handleSubmitErrors = useCallback(submitError => {
    if (submitError) {
      error(submitError);
    }
  }, []);

  const afterSubmit = (data, form) => {
    data = data[ jql.query.get.operation ] || data;
    setTimeout(() => {
      const { submitSucceeded } = form.getState();
      if (submitSucceeded) {
        success("Changes saved successfully.");
        if (needToNavigate) {
          navigate(`/contact/${data.objectId}/details`);
        }
        props.onClose(data);
      }
    });
  };

  return {
    initialValues: useMemo(() => ({ ...initialValues, ...createContactData }), [initialValues, createContactData]),
    setCreateContactData,
    createContactData,
    needToNavigate,
    get resolveContactInitialValues() {
      const resolveContactInitialValues = {
        temporary: { ...createContactData },
        exists: { ...contacts?.edges[ 0 ]?.node }
      };
      const existsPhones = contacts?.edges[ 0 ]?.node?.phones;
      const createdPhones = createContactData.phones;
      const existsEmails = contacts?.edges[ 0 ]?.node?.emails;
      const createdEmails = createContactData.emails;
      const { exists: existingPhones, current: currentPhones } = reorderDuplicates(existsPhones, createdPhones, "number");
      const { exists: existingEmails, current: currentEmails } = reorderDuplicates(existsEmails, createdEmails, "address");
      resolveContactInitialValues.exists.phones = existingPhones;
      resolveContactInitialValues.temporary.phones = currentPhones;
      resolveContactInitialValues.exists.emails = existingEmails;
      resolveContactInitialValues.temporary.emails = currentEmails;
      return resolveContactInitialValues;
    },
    setResolveDuplicationData,
    resolveDuplicationData,
    setResolveDuplicationErrors,
    resolveDuplicationErrors,
    handleSubmitErrors,
    setNeedResolve,
    needResolve,
    handleResolve,
    afterSubmit,
    setMerging,
    onClose,
    loading,
    merging,
    action,
    jql,
    sections
  };
}

function reorderDuplicates(exists = [], current = [], key: string) {
  const currentOrdered = [];
  let existingOrdered = [];

  const currentMap = new Map(current.map((i) => [i[ key ], i]));
  const existingMap = new Map(exists.map((i) => [i[ key ], i]));

  currentMap.forEach((v, k) => {
    const item = existingMap.get(k);
    if (item) {
      currentOrdered.unshift(v);
      existingOrdered.unshift(v);
      existingMap.delete(k);
    } else {
      currentOrdered.push(v);
    }
  });
  existingOrdered = existingOrdered.concat(Array.from(existingMap.values()));

  return {
    current: currentOrdered,
    exists: existingOrdered
  };
}
export const CONTACT = gql`
  fragment Contact on Contact {
    id
    objectId
    objectName
    objectIcon
    firstName
    middleName
    lastName
    types
    ssn
    birthday
    company
    tags
    phones {
      number
      objectName
      type
      carrier
      nationalFormat
      sms
      smsCountryCode
      callOptOut
      smsOptOut
      default
    }
    emails {
      address
      type
      optOut
    }
  }
`;
export const GET_CONTACT_BY_PHONE_NUMBERS_OR_EMAILS_OLD = gql`
  query GetContactByPhoneNumbersOrEmailsOld($numbers: [Phone], $emails: [Email],$id:ID) {
    contacts(where: {
      AND: [
        {id: {notEqualTo: $id}}
        {OR: [
          {
            phones: {
              have: {
                number: {
                  in: $numbers
                }
              }
            }
          },
          {
            emails: {
              have: {
                address: {
                  in: $emails
                }
              }
            }
          }
        ]}
      ]
    }) {
      edges {
        node {
          ...Contact
        }
      }
    }
  }
  ${CONTACT}
`;
