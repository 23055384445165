import React                 from "react";
import { FC }                from "react";
import { useMemo }           from "react";
import { Alignment }         from "../../../constants/Alignemnt";
import { CommonClasses }     from "../../../theme/classes";
import { classNames }        from "../../../utils/classNames";
import { AvatarSizes }       from "../../Avatar";
import { Avatar }            from "../../Avatar";
import { BadgeColors }       from "../../Badge";
import { BadgeVariants }     from "../../Badge";
import { Badge }             from "../../Badge";
import { BoxComponentProps } from "../../Box";
import { Box }               from "../../Box";
import { Card }              from "../../Card";
import { Tooltip }           from "../../Tooltip";

export interface MilestoneProps extends BoxComponentProps {
  title: string;
  date: string;
  dateDiff: {
    years: number,
    months: number,
    weeks: number,
    days: number,
    hours: number
  };
  done?: boolean;
  current?: boolean;
  unread?: boolean;
  passed?: boolean;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  user?: {
    objectName: string,
    objectIcon: string,
  },
}

export const DATE_DIFFERENT = ["years", "months", "weeks", "days", "hours", "minutes"];

export const Milestone: FC<MilestoneProps> = React.memo(function Milestone(props) {
  const { className, title, date, passed, disabled, active, selected, unread, current, user, dateDiff, onClick, ...p } = props;

  const time = useMemo(() => {
    let difference;

    for (let i = 0; i < DATE_DIFFERENT.length; i++) {
      const diff = DATE_DIFFERENT[ i ];
      if (dateDiff?.[ diff ] == undefined) {
        continue;
      }

      if (dateDiff?.[ diff ] != 0) {
        const count = diff == "hours" ? Math.ceil(dateDiff[ diff ]) : Math.floor(dateDiff[ diff ]);
        const type = count == -1 || count == 1 ? diff.slice(0, -1) : diff;
        difference = {
          count,
          type: diff,
          visibleType: type
        };
        break;
      }
    }

    return difference;
  }, [dateDiff]);
  const isDeadLine = useMemo(() => time?.type == "hours" && time?.count > 0 && time?.count < 4 && (!disabled && !passed), [time, disabled, passed]);
  const dateText = useMemo(() => {
    if (!time && (!date || !Object.keys(date)?.length)) {
      return "";
    }

    if (date) {
      return date;
    } else if (isDeadLine) {
      return `Deadline in ${time?.count} ${time?.visibleType}`;
    } else if (time?.count < 0) {
      return `Overdue ${Math.abs(time?.count)} ${time?.visibleType}`;
    }

    return `${time?.count} ${time?.visibleType}`;
  }, [date, time, isDeadLine]);

  const dateClasses = classNames(
    CommonClasses.TextSecondary,
    CommonClasses.TextTiny,
    {
      [ MilestoneClasses.DateDeadLine ]: isDeadLine,
      [ MilestoneClasses.DateOverDue ]: time?.count < 0 && !disabled
    }
  );

  return <Card selected={selected} passed={passed} disabled={disabled} active={active} onClick={onClick} className={className}>
    <Box container direction={"column"} gap={"XXXS"}>
      <p className={MilestoneClasses.Title}>{title}</p>
      <p className={dateClasses}>{dateText}</p>
    </Box>
    <Box container justify={user ? "space-between" : "end"} alignItems={"center"}>
      {
        unread &&
        <Badge outline className={MilestoneClasses.UnreadIndicator} variant={BadgeVariants.Dot} color={BadgeColors.Primary}/>
      }
      {
        user &&
        <Tooltip title={user.objectName} alignment={Alignment.Top}>
          <Avatar size={AvatarSizes.Small} text={user.objectName} icon={user.objectIcon}/>
        </Tooltip>
      }
      {
        (passed || active) && <Badge
          variant={BadgeVariants.Regular}
          icon={"done"}
          color={passed ? BadgeColors.Success : BadgeColors.LightGrey}
        />
      }
    </Box>
  </Card>;
});

export const enum MilestoneClasses {
  Title = "milestone__title",
  DateDeadLine = "milestone__date--deadline",
  DateOverDue = "milestone__date--overdue",
  Task = "milestone__tasks",
  Item = "milestone__task-item",
  UnreadIndicator = "milestone__unread-indicator",
}