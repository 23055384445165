import { useEffect } from "react";
import { useState }  from "react";

interface useAudioProps {
  url: string,
  deviceId?: string,
  duration?: number,
  countdown?: boolean
}

interface Audio extends HTMLAudioElement {
  setSinkId?: (id: string) => Promise<void>
}

export function useAudio ({ url, duration, deviceId, countdown }: useAudioProps) {
  const [sourceAudio, setSourceAudio] = useState<Audio>(null);
  const [status, setStatus] = useState<"playing" | "pause" | "ended" | "idle">(null);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    url ? setStatus("idle") : setStatus(null);
    if (url) {
      const destinationAudio: Audio = new Audio(url);
      setSourceAudio(destinationAudio);
      destinationAudio.addEventListener("error", onError);
      destinationAudio.addEventListener("play", onPlay);
      destinationAudio.addEventListener("ended", onEnded);
      destinationAudio.addEventListener("pause", onPause);

      if (duration) {
        destinationAudio.addEventListener("timeupdate", (e) => onTimeupdate(e, destinationAudio));
      }

      return () => {
        destinationAudio.removeEventListener("error", onError);
        destinationAudio.removeEventListener("play", onPlay);
        destinationAudio.removeEventListener("ended", onEnded);
        destinationAudio.removeEventListener("pause", onPause);
        if (duration) {
          destinationAudio.removeEventListener("timeupdate", (e) => onTimeupdate(e, destinationAudio));
        }
      };
    }
  }, [url, duration]);

  function onError(e) {
    console.error("error", e);
  }

  function onPlay(e) {
    setStatus("playing");
  }
  function onEnded(e) {
    setStatus("ended");
    setCurrentTime(null);
  }

  function onPause(e) {
    setStatus("pause");
  }

  function onTimeupdate(e, destinationAudio: Audio) {
    if (countdown) {
      setCurrentTime(duration - Math.floor(destinationAudio?.currentTime * 1000));
    } else {
      setCurrentTime(Math.floor(destinationAudio?.currentTime * 1000));
    }
  }

  async function playAudio() {
    await sourceAudio?.setSinkId?.(deviceId);
    await sourceAudio.play();
  }

  function pauseAudio() {
    sourceAudio.pause();
  }

  async function resumeAudio() {
    try {
      await sourceAudio.setSinkId(deviceId);
      sourceAudio.play();
    } catch (e) {
      console.error(e);
    }
  }

  return {
    status,
    currentTime,
    playAudio,
    pauseAudio,
    resumeAudio
  };
}
