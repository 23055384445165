import { formatNumber }         from "@relcu/ui";
import React                    from "react";
import { FC }                   from "react";
import { FormSpy }              from "@relcu/final-form";
import { FieldCell }            from "@relcu/rc";
import { NumberFieldCellProps } from "@relcu/rc";

export const CreditsCell: FC<NumberFieldCellProps> = React.memo(function CreditsCell(props) {
  return <FormSpy subscription={{ values: true }}>
    {
      ({ values }) => {
        const available = Math.round((Number(values.totalLoanAmount) * Math.abs(values.points))) / 100;
        return <FieldCell.Number
          status={values[ props.name ] > available ? "warning" : null}
          helperText={`Available credits are $${formatNumber(available, 2)}`}
          icon={"attach_money"}
          {...props}
        />;
      }
    }
  </FormSpy>;
});
