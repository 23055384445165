import { IconType }       from "@relcu/rc";
import { Icon }           from "@relcu/rc";
import { Button }         from "@relcu/rc";
import { useNavigate }    from "@relcu/react-router";
import { useParams }      from "@relcu/react-router";
import { FontIcon }       from "@relcu/ui";
import { ButtonSizes }    from "@relcu/ui";
import { CircularLoader } from "@relcu/ui";
import { EmptyList }      from "@relcu/ui";
import { Box }            from "@relcu/ui";
import { Suspense }       from "react";
import React              from "react";
import { FC }             from "react";
import "./dashboards.css";
import { useDashboardView } from "./useDashboardView";

const TableauFrame = React.lazy(() => import("./TableauFrame"));
//?rf_role_filter=role_name&rf_team_filter=team_name
export const DashboardsView: FC = React.memo(function DashboardsView() {
  const { loadingDashboards, dashboards, childDashboards, selectedDashboard, role,team } = useDashboardView();
  const navigate = useNavigate();
  return <Box container direction={"column"} className={"dashboard"} justify={"center"} alignItems={"center"} style={{display:(selectedDashboard?.node?.url && !childDashboards.length) ? "block":"flex"}} >
    {
      (loadingDashboards) ?
        <Box gap={"XS"} flex={1} >
          <CircularLoader justify={"center"} />
        </Box> :
        <>
          {
          (!dashboards.length)  ?
            <Box gap={"XS"} flex={1}>
              <EmptyList icon={"analytics"} title={"Analytics"} content={"No dashboard configured"} />
            </Box> :
            <>
              {
                (selectedDashboard?.node?.url && !childDashboards.length) ?
                  <>
                    <Icon type={"arrow_back"} className={"dashboard-back"} size={40} onClick={()=>{navigate(-1)}} />
                    <Suspense fallback={<CircularLoader justify={"center"}/>}>
                      <TableauFrame view={`${selectedDashboard.node.url}`} params={{rf_role_filter:role,rf_team_filter:team}}/>
                    </Suspense>
                  </> :
                  <>
                    {
                      childDashboards.length > 0 ?
                        <>
                          <Icon type={"arrow_back"} className={"dashboard-back"} size={40} onClick={()=>{navigate(-1)}} />
                          <Box className={"dashboard-list"} container alignItems={"center"} justify={"center"} alignSelf={"center"} wrap={"wrap"} gap={"XXXL"}>
                            {
                              childDashboards.map((d,k)=>(
                                <Button key={k} title={d.node?.objectName} startIcon={<Icon type={d.node?.objectIcon as IconType} />}
                                        type={"button"} size={"xl"} style={{ marginBottom: "50px" }} onClick={()=>{navigate(d.node.objectId)}}
                                        disabled={d.node?.disabled}>{d.node?.objectName}</Button>
                              ))
                            }
                          </Box>
                        </>:
                        <Box gap={"XS"} flex={1}>
                          <EmptyList icon={"analytics"} title={"Analytics"} content={"No dashboard configured"} />
                        </Box>
                    }
                  </>
              }
            </>

        }
        </>
    }
  </Box>;
});

