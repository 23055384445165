export enum StepClasses {
  Body = "step-body",
  StepSection = "step-section",
  Empty = "step-section--empty",
  EmptyTitle = "step-section__title",
  EmptyDescription = "step-section__description",
  Header = "step-section__header",
  HeaderError = "step-section__header-error",
  StepRow = "step__row",
  StepRowInactive = "step__row--inactive",
  StepRowHeader = "step__row--header",
  StepItem = "step__item"
}