import React          from "react";
import { FC }         from "react";
import { Typography } from "@relcu/rc";
import { IconButton } from "@relcu/rc";
import { Icon }       from "@relcu/rc";
import { StackProps } from "@relcu/rc";
import { Stack }      from "@relcu/rc";

export interface ManageHeaderProps extends StackProps {
  onBack?();
}

export const ManageHeader: FC<ManageHeaderProps> = React.memo(function ManageHeader(props) {
  const { children, onBack } = props;
  return <Stack style={{ height: 28 }} alignItems="center" justifyContent={onBack ? "space-between" : "center"}>
    {
      onBack &&
      <IconButton
        circle
        appearance={"subtle"}
        size={"xs"}
        icon={<Icon type={"west"} color={"primary"}/>}
        onClick={onBack}/>
    }
    <Typography variant={"small12"} color={"secondary"}>
      {children}
    </Typography>
    <Stack.Item style={{ width: 28 }}/>
  </Stack>;
});
