import React                         from "react";
import { FC }                        from "react";
import { useContext }                from "react";
import { CommonClasses }             from "@relcu/ui";
import { classNames }                from "@relcu/ui";
import { BadgeAlignment, FontIcon }  from "@relcu/ui";
import { BadgeColors }               from "@relcu/ui";
import { BadgeVariants }             from "@relcu/ui";
import { Badge }                     from "@relcu/ui";
import { UnreadNotificationContext } from "../UnreadNotificationProvider";

export interface NotificationBadgeProps {
  toggleSnackBar: (typ: string) => void;
  snackBarState: string;
}

export const NotificationBadge: FC<NotificationBadgeProps> = React.memo(function NotificationBadge(props) {
  const { snackBarState, toggleSnackBar } = props;
  const { notifications } = useContext(UnreadNotificationContext);
  const count = notifications.edges.filter(({node}) => node.type != "distributed_lead")?.length

  return (
    <div
      className={classNames("notification-icon-wrapper",
        { [ "notification-icon-wrapper--active" ]: snackBarState == "notifications" })
      }>
      {
        count ?
          <Badge color={BadgeColors.Error} variant={BadgeVariants.Dot} alignment={BadgeAlignment.TopRight}
                 outline={true}>
            <FontIcon type={"notifications"}
                      className={classNames(
                        CommonClasses.ClickableIcon,
                        { [ CommonClasses.PrimaryIcon ]: snackBarState == "notifications" }
                      )}
                      onClick={() => toggleSnackBar("notifications")}/>
          </Badge> :
          <FontIcon type={"notifications"}
                    className={classNames(
                      CommonClasses.ClickableIcon,
                      { [ CommonClasses.PrimaryIcon ]: snackBarState == "notifications" }
                    )}
                    onClick={() => toggleSnackBar("notifications")}/>
      }
    </div>
  );
});
