import React                   from "react";
import { classNames }          from "../../utils/classNames";
import { Box }                 from "../Box";
import { BoxComponentProps }   from "../Box";
import { FontIcon }            from "../Icon";
import { IconType }            from "../Icon";
import { TimelineItemClasses } from "./TimelineItemClasses";

export enum TimelineItemIconColors {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Primary = "primary",
  Default = "default",
  TealDark = "teal-dark"
}

export interface TimelineItemIconProps extends BoxComponentProps {
  icon: IconType,
  variant: TimelineItemIconColors
}

export const TimelineItemIcon = React.memo<TimelineItemIconProps>(({ icon, className, variant }) => {

  const classes = classNames(TimelineItemClasses.TimelineItemIconContainer, {
    [ TimelineItemClasses.Error ]: variant == TimelineItemIconColors.Error,
    [ TimelineItemClasses.Success ]: variant == TimelineItemIconColors.Success,
    [ TimelineItemClasses.Warning ]: variant == TimelineItemIconColors.Warning,
    [ TimelineItemClasses.Primary ]: variant == TimelineItemIconColors.Primary,
    [ TimelineItemClasses.Default ]: variant == TimelineItemIconColors.Default,
    [ TimelineItemClasses.Default ]: variant == TimelineItemIconColors.Default,
    [ TimelineItemClasses.TealDark ]: variant == TimelineItemIconColors.TealDark
  }, className);

  return <Box container justify={"center"} alignItems={"center"} className={classes}>
    <FontIcon type={icon} className={TimelineItemClasses.TimelineItemIcon} />
  </Box>;
});
