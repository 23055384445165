import { FC }                  from "react";
import { ReactElement }        from "react";
import React                   from "react";
import { classNames }          from "../../..";
import { BoxComponentProps }   from "../../..";
import { Box }                 from "../../..";
import { ListItemClasses }     from "..";
import { GlobalClasses }       from "../../../constants/GlobalClasses";
import { isObject }            from "../../../utils/helpers";
import { EllipsisWithTooltip } from "../../EllipsisTooltip";

export interface ListItemTextProps extends BoxComponentProps {
  text: string | ReactElement;
  tiny?: boolean;
  bold?: boolean;
  defaultValue?: string;
  label?: string;
}
export const ListItemText: FC<ListItemTextProps> = React.memo(function ListItemText(props) {
  const { className, text, tiny, defaultValue, label, bold, style, ...p } = props;
  const classes = classNames(ListItemClasses.ListItemText, {
    [ ListItemClasses.ListItemTinyText ]: tiny,
    [ ListItemClasses.ListItemBoldText ]: bold,
    [ ListItemClasses.ListItemDefaultText ]: !!((typeof text == "undefined" || text == null) && defaultValue)
  }, className);
  const value = text ?? defaultValue;

  return <Box className={classes} style={style} {...p}>
    {
      isObject(text) ? text :
        <EllipsisWithTooltip value={value}>
          <p className={GlobalClasses.EllipsisFlex}> {value} </p>
        </EllipsisWithTooltip>
    }
  </Box>;
});

ListItemText.defaultProps = {
  tiny: false,
  bold: false
};
