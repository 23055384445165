export const enum StepperClasses {
  Stepper = "stepper",
  Step = "step",
  Label = "step__label",
  ErrorLabel = "step__label--error",
  ActiveLabel = "step__label--active",
  PastLabel = "step__label--past",
  Index = "step__index",
  Past = "step--past",
  Edit = "step--edit",
  Active = "step--active"
}