import { ReactElement }      from "react";
import React                 from "react";
import { FC }                from "react";
import { classNames }        from "../..";
import { BoxComponentProps } from "../..";
import { MenuClasses }       from "./Menu";

export interface SubMenuProps extends BoxComponentProps {
  menu?: ReactElement
  alignment?: "left" | "right"
  onSelect?()
}
export const SubMenu: FC<SubMenuProps> = React.memo((props) => {
  const { children, menu, alignment } = props;
  const classes = classNames(MenuClasses.SubMenu, {
    [ MenuClasses.SubMenuLeft ]: alignment === "left",
    [ MenuClasses.SubMenuRight ]: alignment === "right"
  });
  return <ul className={classes}>
    {
      React.Children.map(children, child => {
        return React.cloneElement(child as any, {
          className: MenuClasses.SubMenuItem
        });
      })
    }
    {
      menu
    }
  </ul>;
});
SubMenu.defaultProps = {
  alignment: "left"
};
