import React       from "react";
import { FC }      from "react";
import { useRate } from "./useRate";

export const RateContext = React.createContext<ReturnType<typeof useRate>>(null);
export interface RateProviderProps {
  value: {
    rate: IRate
    sticky?: boolean
    active?: boolean
  }
}

export interface IRate {
  adjustments: {
    adjustmentDetails: adjustmentDetails[]
    totalMarginAdj: number,
    totalRateAdj: number
  },
  apr: number,
  feeAmount: number,
  fees: fees[],
  feesCalculated: number,
  index: number,
  investor: string,
  lastUpdate: string,
  loanAmount: number,
  piti: number,
  points: number,
  price: number,
  monthlyPremium: number,
  productDesc: string,
  productName: string,
  productTerm: string,
  pricingStatus: string,
  loanProgram?: string,
  rate: number,
  srp: number,
}
export interface adjustmentDetails {
  applied: boolean,
  desc: string,
  marginAdj: number,
  priceAdj: number,
  rateAdj: number
}

export interface fees {
  description: string,
  feeAmount: number,
  hudLine: string,
}
