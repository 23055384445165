import { Box }             from "@relcu/ui";
import { TypographyColor } from "@relcu/ui";
import { TypographySize }  from "@relcu/ui";
import { Typography }      from "@relcu/ui";
import { FC }              from "react";
import React               from "react";
import { SidebarClasses }  from "./SidebarClasses";

export const SidebarSubHeader:FC = React.memo(function TableSidebarSubHeader(props){
  return (
    <Box container className={SidebarClasses.SubHeading}>
      <Typography size={TypographySize.TextTiny} color={TypographyColor.Secondary}>{props.children}</Typography>
    </Box>
  )
})