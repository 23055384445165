import React                  from "react";
import { FC }                 from "react";
import { InputState }         from "../../..";
import { classNames }         from "../../..";
import { Label }              from "../../..";
import { Box }                from "../../..";
import { BaseInputClasses }   from "../../..";
import { BaseInputProps }     from "../../..";
import { BoxComponentProps }  from "../../..";
import { useImperativeState } from "../../..";

export interface TextAreaProps extends BoxComponentProps, BaseInputProps {
  value?: string
  readOnly?: boolean
  spellCheck?: boolean
  lang?: string
  resizable?: boolean
  showCharLimit?: boolean
  maxLength?: number
  rows?: number
  message?: string
  placeholder?: string
  showLabel?: boolean
  inputProps?: { [ key: string ]: any; },
  InputProps?: { [ key: string ]: any; }
  onChange?(value)
  onFocus?()
  onBlur?()
  onKeyUp?(value)
  onKeyPress?(value)
  onKeyDown?(value)
}

export const TextArea: FC<TextAreaProps> = React.memo(function TextArea(props) {
  const {
    className,
    value,
    label,
    showLabel,
    name,
    disabled,
    readOnly,
    spellCheck,
    lang,
    halfSize,
    fullSize,
    state,
    resizable,
    placeholder,
    message,
    showCharLimit,
    maxLength,
    required,
    onKeyPress,
    onChange,
    onKeyUp,
    onKeyDown,
    inputProps,
    InputProps,
    ...p
  } = props;

  const classes = classNames(TextAreaClasses.TextArea, {
    [ TextAreaClasses.Disabled ]: disabled,
    [ TextAreaClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize
  }, className);
  const [stateValue, setStateValue] = useImperativeState(value, onChange);
  return <Box container className={classes} direction={"column"} gap="XXXS" {...p} {...InputProps}>
    {
      label && showLabel &&
      <Label required={required} alignItems={"center"}>
        {
          label
        }
      </Label>
    }
    <textarea
      spellCheck={spellCheck}
      lang={lang}
      value={stateValue}
      disabled={disabled}
      readOnly={readOnly}
      maxLength={maxLength}
      className={classNames(TextAreaClasses.Area, {
        [ TextAreaClasses.Resizable ]: resizable,
        [ TextAreaClasses.Error ]: state == InputState.Error,
        [ TextAreaClasses.Success ]: state == InputState.Success
      })}
      placeholder={placeholder}
      onKeyPress={(e: any) => {
        if ((maxLength != undefined && e.target.value.length <= maxLength) || maxLength == undefined) {
          onKeyPress && onKeyPress(e);
        }
      }}
      onKeyDown={(e: any) => {
        if ((maxLength != undefined && e.target.value.length <= maxLength) || maxLength == undefined) {
          onKeyDown && onKeyDown(e);
        }
      }}
      onKeyUp={(e: any) => {
        if ((maxLength != undefined && e.target.value.length <= maxLength) || maxLength == undefined) {
          onKeyUp && onKeyUp(e);
        }
      }}
      onChange={(e: any) => {
        setStateValue(e.target.value);
      }}
      {...inputProps}
    >
    </textarea>
    <Box container className={TextAreaClasses.Details} justify={message ? "space-between" : "end"}
         alignItems={"center"}>
      <Box container className={classNames(TextAreaClasses.HelperText, {
        [ BaseInputClasses.Success ]: state == InputState.Success,
        [ BaseInputClasses.Error ]: state == InputState.Error
      })}>
        {message}
      </Box>{/*todo limit massage width*/}
      {
        showCharLimit && maxLength &&
        <Box container>
          Character Count {stateValue?.length || 0}/{maxLength}
        </Box>
      }
    </Box>
  </Box>;
});

export enum TextAreaClasses {
  TextArea = "text-area",
  Label = "label",
  Area = "area",
  Resizable = "resizable",
  Details = "details",
  Disabled = "disabled",
  ReadOnly = "read-only",
  Error = "error",
  Success = "success",
  HelperText = "helper-text"
}

TextArea.defaultProps = {
  showLabel: true
};
