import React                                          from "react";
import { gql }                                        from "@apollo/client";
import { useQuery }                                   from "@apollo/client";
import { CommonClasses }                              from "@relcu/ui";
import { Link }                                       from "@relcu/ui";
import { CircularLoader }                             from "@relcu/ui";
import { useSource }                                  from "@relcu/ui";
import { TimelineItemIconColors }                     from "@relcu/ui";
import { TypographySize }                             from "@relcu/ui";
import { AudioPlayer }                                from "@relcu/ui";
import { TimelineItemBody }                           from "@relcu/ui";
import { Box }                                        from "@relcu/ui";
import { TimelineItem }                               from "@relcu/ui";
import { TypographyColor }                            from "@relcu/ui";
import { Typography }                                 from "@relcu/ui";
import { TimelineItemSubject }                        from "@relcu/ui";
import { TimelineItemIcon }                           from "@relcu/ui";
import { getObjectPath }                              from "../../../../../utils/layoutUtils";
import { PhoneCallActivity as PhoneCallActivityType } from "../../../../__types__/PhoneCallActivity";
import { GetActivityPhoneCallVariables }              from "./__types__/GetActivityPhoneCall";
import { GetActivityPhoneCall }                       from "./__types__/GetActivityPhoneCall";
import CallIntelligence                               from "../../ConferenceView/CallIntelligence";

export interface CallActivityProps extends PhoneCallActivityType {
  createdAt: string;
}

export const PhoneCallActivity = React.memo<CallActivityProps>(function PhoneCallActivity(props) {
  const { $object, $viewer } = useSource();
  const { phoneCall, phoneCallFrom, phoneCallTo, phoneCallSubject, createdAt } = props;
  const { recording, voicemail, missed, calls } = Object(phoneCall);
  const isParty = calls.find(call => call.party.id == $viewer.id);
  const withRecord = (isParty || $viewer.role == "admin") && !!(recording || voicemail);

  const navigateTo = (obj) => {
    if (obj.__typename == "User") {
      return `/user/${obj.objectId}/calls${phoneCall?.objectId ? `/${phoneCall.objectId}` : ""}`;
    } else if (phoneCallSubject?.objectId) {
      return `${getObjectPath(phoneCallSubject)}/calls${phoneCall?.objectId ? `/${phoneCall.objectId}` : ""}`;
    } else {
      return `/contact/${obj.objectId}/calls${phoneCall?.objectId ? `/${phoneCall.objectId}` : ""}`;
    }
  };

  if (missed && $object.__typename === "User") {
    return <TimelineItem
      primary={<TimelineItemSubject>
        {
          phoneCallTo?.objectId ?
            <Link
              to={navigateTo(phoneCallTo)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{phoneCallTo?.objectName || "Unknown"}</Typography>
            </Link>
            :
            <Typography>{phoneCallTo?.objectName || "Unknown"}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>has</Typography>
        <Typography>missed call</Typography>
        {!!voicemail && <>
          <Typography color={TypographyColor.Secondary}>and</Typography>
          <Typography>voicemail</Typography>
        </>}
        <Typography color={TypographyColor.Secondary}>from</Typography>
        {
          phoneCallFrom?.objectId ?
            <Link
              to={navigateTo(phoneCallFrom)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{phoneCallFrom?.objectName || "Unknown"}</Typography>
            </Link>
            :
            <Typography>{phoneCallFrom?.objectName || "Unknown"}</Typography>
        }

      </TimelineItemSubject>}
      creationDate={createdAt}
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Error} icon={withRecord ? "voicemail" : "call"}/>}
    >
      {withRecord && <PhoneCallActivityBody objectId={phoneCall?.objectId}/>}
    </TimelineItem>;
  } else if (phoneCallFrom && ($object.objectId === phoneCallFrom.objectId || ($object.__typename === "Lead" && phoneCallFrom.__typename === "Contact"))) {
    return <TimelineItem
      primary={<TimelineItemSubject>
        {
          phoneCallFrom?.objectId ?
            <Link
              to={navigateTo(phoneCallFrom)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{phoneCallFrom?.objectName || "Unknown"}</Typography>
            </Link>
            :
            <Typography>{phoneCallFrom?.objectName || "Unknown"}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>called</Typography>
        {
          phoneCallTo?.objectId ?
            <Link
              to={navigateTo(phoneCallTo)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{phoneCallTo?.objectName || "Unknown"}</Typography>
            </Link>
            :
            <Typography>{phoneCallTo?.objectName || "Unknown"}</Typography>
        }
      </TimelineItemSubject>}
      creationDate={createdAt}
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Success} icon={withRecord ? "voicemail" : "call"}/>}
    >
      {withRecord && <PhoneCallActivityBody objectId={phoneCall?.objectId}/>}
    </TimelineItem>;
  } else {
    return <TimelineItem
      primary={<TimelineItemSubject>
        {
          phoneCallTo?.objectId ?
            <Link
              to={navigateTo(phoneCallTo)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{phoneCallTo?.objectName || "Unknown"}</Typography>
            </Link>
            :
            <Typography>{phoneCallTo?.objectName || "Unknown"}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>received</Typography>
        <Typography>a call</Typography>
        <Typography color={TypographyColor.Secondary}>from</Typography>
        {
          phoneCallFrom?.objectId ?
            <Link
              to={navigateTo(phoneCallFrom)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{phoneCallFrom?.objectName || "Unknown"}</Typography>
            </Link>
            :
            <Typography>{phoneCallFrom?.objectName || "Unknown"}</Typography>
        }
      </TimelineItemSubject>}
      creationDate={createdAt}
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Success} icon={withRecord ? "voicemail" : "call"}/>}
    >
      {withRecord && <PhoneCallActivityBody objectId={phoneCall?.objectId}/>}
    </TimelineItem>;
  }
});

export interface CallActivityBodyProps {
  objectId: string;
}

const PhoneCallActivityBody = React.memo<CallActivityBodyProps>(function PhoneCallActivityBody(props) {
  const { objectId } = props;
  const { $viewer } = useSource();
  const allowedRoles = ["admin", "sales_manager", "team_manager"];
  const { data: { conference } = {}, loading } = useQuery<GetActivityPhoneCall, GetActivityPhoneCallVariables>(GET_ACTIVITY_PHONE_CALL, { variables: { id: objectId } });

  if (loading) {
    return <Box container direction={"column"} alignItems={"center"} gap={"XS"}>
      <CircularLoader/>
    </Box>;
  }

  const { to, from, recording, voicemail, missed } = Object(conference);
  const withRecord = !!(recording || voicemail);

  return <Box container direction={"column"} gap={"XS"}>
    <TimelineItemBody>
      {
        !withRecord ?
          <Box container justify={"center"}>
            <Typography
              color={TypographyColor.Secondary}>{missed ? "No voicemail available" : "No recording"}</Typography>
          </Box>
          : <>
            <Box container gap={"M"}
                 style={{ paddingLeft: "var(--layout-box-gap-x-x-s)", paddingRight: "var(--layout-box-gap-x-x-s)" }}>
              <Box container direction={"row"} gap={"XXS"} alignItems={"center"}>
                <Typography size={TypographySize.TextSmall} color={TypographyColor.Secondary}>From:</Typography>
                <Typography>{from}</Typography>
              </Box>
              <Box container direction={"row"} gap={"XXS"} alignItems={"center"}>
                <Typography size={TypographySize.TextSmall} color={TypographyColor.Secondary}>To:</Typography>
                <Typography>{to}</Typography>
              </Box>

              <AudioPlayer src={voicemail ? voicemail.url : recording.url}/>
            </Box>
          </>
      }
    </TimelineItemBody>
    {
      $viewer.role && allowedRoles.includes($viewer.role) && (
        <TimelineItemBody>
          <CallIntelligence conference={objectId} layoutType="activity"/>
        </TimelineItemBody>
      )
    }
  </Box>;
});

const GET_ACTIVITY_PHONE_CALL = gql`
  query GetActivityPhoneCall($id:ID!){
    conference(id:$id){
      missed
      to
      from
      recording {
        objectId
        url
      }
      voicemail {
        objectId
        url
      }
    }
  }
`;
