export const enum OfferClasses {
  Offer = "offer",
  OfferHeader = "offer-header",
  OfferBody = "offer-body",
  OfferTitle = "offer-title",
  OfferCheckbox = "offer-checkbox",
  OfferHeaderRow = "offer-header-row",
  OfferSectionRow = "offer-section-row",
  OfferExpanded = "offer-expanded",
  OfferInlineEditableAFiledActions = "offer-inline-editable-field-actions"
}
