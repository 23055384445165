import { useApolloClient }               from "@apollo/client";
import { useApolloNetworkStatusReducer } from "@relcu/network-status";
import { Typography }                    from "@relcu/rc";
import { Link }                          from "@relcu/react-router";
import { AlertObject }                   from "@relcu/ui";
import { useAlert }                      from "@relcu/ui";
import { FontIcon }                      from "@relcu/ui";
import { Box }                           from "@relcu/ui";
import { AlertColors }                   from "@relcu/ui";
import { Alert }                         from "@relcu/ui";
import { useMemo }                       from "react";
import { useRef }                        from "react";
import React                             from "react";
import { useAlertBar }                   from "./useAlertBar";
import "./alert-bar.css";

export const AlertBar = React.memo(function AlertBar() {
  const { latestVersion, hasNewVersion,hasNewLayoutVersion,hasNewSchemaVersion, soundWarning, viewerId, openMicrophoneAccessModal } = useAlertBar();
  const url = useMemo(()=>{
    const url = new URL(location.href);
    if(hasNewVersion){
      url.searchParams.set("version", latestVersion);
    }
    return url
  },[hasNewVersion,latestVersion])

  return <>{
    hasNewVersion &&
    <Alert direction={"column"} className={"alert-bar"} variant={AlertColors.Warning}>A new version of Relcu is
      available.
      <a href={url.toString()} style={{ textDecoration: "underline", cursor: "pointer" }}>
        Refresh to see the latest version.
      </a>
    </Alert>
  }{
    hasNewLayoutVersion &&
    <Alert direction={"column"} className={"alert-bar"} variant={AlertColors.Warning}>Layout has been updated.
      <a href={url.toString()} style={{ textDecoration: "underline", cursor: "pointer" }}>
        Refresh to see the latest version.
      </a>
    </Alert>
  }{
    hasNewSchemaVersion &&
    <Alert direction={"column"} className={"alert-bar"} variant={AlertColors.Warning}>Schema has been updated.
      <a href={url.toString()} style={{ textDecoration: "underline", cursor: "pointer" }}>
        Refresh to see the latest version.
      </a>
    </Alert>
  }
    {
      soundWarning == "MIC_ACCESS" &&
      <Alert icon={"record_voice_over"} direction={"column"} className={"alert-bar"} variant={AlertColors.Warning}>
        <Box container justify={"space-between"} alignItems={"center"} direction={"row"} gap={"XXL"}>
          <Typography>
            Your microphone and/or speaker are not enabled. Please go to your browser settings and allow Relcu to use
            your
            microphone to be able to make calls and receive notification sounds.
          </Typography>
          <Typography className={"action"} onClick={openMicrophoneAccessModal}>
            SEE MORE
            <FontIcon type={"arrow_forward"}/>
          </Typography>
        </Box>
      </Alert>

    }
    {
      soundWarning == "MIC_MISSING" &&
      <Alert icon={"record_voice_over"} direction={"column"} className={"alert-bar"} variant={AlertColors.Warning}>
        <Box container justify={"space-between"} alignItems={"center"} direction={"row"} gap={"XXL"}>
          <Typography>
            Your microphone is not detected. Please go to your Preferences and check available devices.
          </Typography>
          <Link to={`/user/${viewerId}/preference`} className={"action"}>
            OPEN PREFERENCES
            <FontIcon type={"arrow_forward"}/>
          </Link>
        </Box>
      </Alert>
    }
    {
      soundWarning == "SPK_MISSING" &&
      <Alert direction={"column"} icon={"volume_up"} className={"alert-bar"} variant={AlertColors.Warning}>
        <Box container justify={"space-between"} alignItems={"center"} direction={"row"} gap={"XXL"}>
          <Typography>
            Your speaker is not detected. Please go to your Preferences and check available devices.
          </Typography>
          <Link to={`/user/${viewerId}/preference`} className={"action"}>
            OPEN PREFERENCES
            <FontIcon type={"arrow_forward"}/>
          </Link>
        </Box>
      </Alert>
    }
    <BulkAlert/>
  </>;
});

export const BulkAlert = React.memo(() => {
  const { info, success, error, closeAlert } = useAlert();
  const bulkAssignedToAlerts = useRef<AlertObject[]>([]);
  const bulkStatusAlerts = useRef<AlertObject[]>([]);
  const client = useApolloClient();
  useApolloNetworkStatusReducer((_, action) => {
    if (action.payload?.operation?.operationName === "UpdateLeads") {
      const context = action.payload.operation.getContext();
      switch (action.type) {
        case "REQUEST": {
          if (context.action === "SetAssignedTo") {
            bulkAssignedToAlerts.current.push(
              info(`Lead reassignment is in progress. You will see an alert once it is complete.`)
            );
          } else if (context.action === "SetStatus") {
            bulkStatusAlerts.current.push(
              info("Lead status update is in progress. You will see an alert once it is complete.")
            );
          }
          break;
        }
        case "ERROR": {
          bulkAssignedToAlerts.current.shift()?.remove();
          bulkStatusAlerts.current.shift()?.remove();
          error("Bulk operation failed");
          break;
        }
        case "SUCCESS": {
          client.refetchQueries({
            include: ["LeadTableViewQuery", "GetPriorityLeads", "GetPriorityLeadsCount"]
          });
          const modifiedCount = action.payload?.[ "result" ].data?.updateLeads.modifiedCount || "";
          if (context.action === "SetAssignedTo") {
            bulkAssignedToAlerts.current.shift()?.remove();
            success(`You have successfully updated the assignment on ${modifiedCount} leads.`, {
              autoClose: false
            });
          } else if (context.action === "SetStatus") {
            bulkStatusAlerts.current.shift()?.remove();
            success(`You have successfully updated the status on ${modifiedCount} leads.`, {
              autoClose: false
            });
          }
          break;
        }
      }
    }
  }, null);
  return null;
});
