import React                 from "react";
import { classNames }        from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import "./content.css"

interface ContentProps extends BoxComponentProps {

}

export const Content = React.forwardRef(function Content(props: ContentProps, ref: React.Ref<any>) {
  const { className, children, onChange, ...p } = props;
  const classes = classNames(ContentClasses.Content, className);

  return (
    <Box container ref={ref} direction={"column"} gap={"XS"} className={classes} {...p}>
      {children}
    </Box>
  );
});

const enum ContentClasses {
  Content = "message-view__content"
}
