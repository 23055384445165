import React                    from "react";
import { FC }                   from "react";
import { useEffect }            from "react";
import { gql }                  from "@apollo/client";
import { useLazyQuery }         from "@apollo/client";
import { CircularLoader }       from "@relcu/ui";
import { RenderFlyerVariables } from "./__types__/RenderFlyer";
import { RenderFlyer }          from "./__types__/RenderFlyer";
import Email                    from "./Email";
import { BaseRendererProps }    from "./MailRenderer";

//todo remove me after unlyer preview starts work in document mode
export const FlyerRenderer: FC<BaseRendererProps> = React.memo(function FlyerRenderer({ template, onError, onSuccess, ids }) {
  const [load, result] = useLazyQuery<RenderFlyer, RenderFlyerVariables>(RENDER_FLYER_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: { errorPolicy: "ignore" }
  });

  useEffect(() => {
    const { contactId, scopeId, userId } = ids;
    if (!template) {
      return;
    }

    let input: RenderFlyerVariables["input"] = {
      user: userId,
      contact: contactId || undefined,
      scope: scopeId || undefined,
      template
    };

    load({ variables: { input } });
  }, [template, ids]);

  useEffect(() => {
    result.error && onError?.(result.error);
    result.data && onSuccess?.(result.data);
  }, [onError, result]);

  return (
    result.loading ?
      <CircularLoader
        justify={"center"}
        alignItems={"start"}
      />
      :
      <Email
        template={result.data?.renderFlyer?.html || result.error?.message}/>
  );
});

const RENDER_FLYER_QUERY = gql`
  query RenderFlyer($input:FlyerRenderInput){
    renderFlyer(input:$input)
  }
`;

