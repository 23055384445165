import React                    from "react";
import { FC }                   from "react";
import { TreeChoiceFieldProps } from "../TreeChoiceField";
import { TreeChoiceField }      from "../TreeChoiceField";
import { useStatusField }       from "./useStatusField";

export interface StatusFieldProps extends TreeChoiceFieldProps {
  states: {
    [ k: string ]: { stage, status }
  }
}

export const StatusField: FC<StatusFieldProps> = React.memo(function StatusField(props) {
  const { format, options, parse } = useStatusField(props);
  return <TreeChoiceField {...props} options={options} format={format} parse={parse}/>;
});

