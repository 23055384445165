import { gql }                  from "@apollo/client";
import { OperationVariables }   from "@apollo/client";
import { useLazyQuery }         from "@apollo/client";
import { LazyQueryHookOptions } from "@apollo/client";
import { IQueryBuilderOptions } from "@relcu/gql-query-builder";
import * as builder             from "@relcu/gql-query-builder";
import { useMemo }              from "react";

export function useJqlLazyQuery<TData = any, TVariables = OperationVariables>(
  query: IQueryBuilderOptions | IQueryBuilderOptions[],
  options?: Omit<LazyQueryHookOptions<TData, TVariables>, "variables"> & { operationName: string }
) {
  const { documentNode, variables } = useMemo(() => {
    const { query: queryString, variables } = builder.query(query, null, {
      operationName: options?.operationName || ""
    });
    return {
      documentNode: gql(queryString),
      variables
    };
  }, [query]);

  return useLazyQuery(documentNode, {
    ...options,
    variables
  });
}
