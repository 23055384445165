import React                from "react";
import { useBoxProps }      from "../../Box";
import { Box }              from "../../Box";
import { Label }            from "../../Label";
import { useConsumeField }  from "../BaseField";
import { BaseFieldClasses } from "../BaseField";

export const SwitchReadField = React.memo(function SwitchReadField(props) {
  const { input: { label, required, value, name, checked, ...switchProps } } = useConsumeField();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(switchProps)}>
      <Label>{label}</Label>
      {checked ? "True" : "False"}
    </Box>
  );
});

export default SwitchReadField;
