import { MouseEventHandler }  from "react";
import { useState }           from "react";
import { useCallback }        from "react";
import React                  from "react";
import { CommonClasses }      from "../..";
import { SubMenu }            from "../..";
import { MenuItem }           from "../..";
import { Menu }               from "../..";
import { Popper }             from "../..";
import { Avatar }             from "../..";
import { StatusBadge }        from "../..";
import { useImperativeState } from "../..";
import {
  Alignment,
  Badge,
  BadgeAlignment,
  BadgeColors,
  BadgeVariants,
  classNames as clsNames,
  classNames,
  FontIcon,
  IconType,
  Tooltip
}                             from "../..";
import { Box }                from "../Box";
import RelcuLogoUrl                from "./relcu.svg";

export enum AppBarClasses {
  AppBar = "appbar",
  AppBarItem = "appbar__item",
  AppBarLogo = "appbar__logo",
  AppBarDivider = "appbar__divider",
  AppBarSplitter = "appbar__splitter",
  AppBarUserInfo = "appbar__userinfo",
  AppBarActiveItem = "appbar__active_item",
  AppBarLinkContent = "appbar__link_content",
  AppbarSection = "appbar__item_section"
}

export interface AppBarProps {
  user: {
    objectId: string;
    objectName?: string;
    objectIcon?: string;
    role?: string
    status?: string
  };
  items: AppBarItemProps[],
  onAction(action: string, ...params: any[]);
}

export function AppBar(props: AppBarProps) {
  const { user, items, onAction } = props;

  const classes = classNames(AppBarClasses.AppBar);
  return <div className={classes}>
    {
      items.map((item, id) => {
        if (item.type == "logo") {
          return <AppBarLogo key={id} {...item} onAction={onAction}/>;
        }
        if (item.type == "divider") {
          return <AppBarDivider key={id}/>;
        }
        if (item.type == "splitter") {
          return <AppBarSplitter key={id}/>;
        }
        if (item.type == "menu") {
          return <AppBarMenuItem key={id} {...item} onAction={onAction}/>;
        }
        if (item.type == "user" && user) {
          return <UserInfo key={id} user={user} items={item.items} onAction={onAction}/>;
        }
        return <AppBarItem key={id} {...item} onAction={onAction}/>;
      })
    }
  </div>;
}
AppBar.defaultProps = {};

export interface AppBarItemProps {
  type?: string;
  label?: number,
  variant?: BadgeVariants
  outline?: boolean
  icon?: IconType
  src?: string
  title?: string;
  action?: string;
  params?: any[];
  active?: boolean;
  items?: AppBarItemProps[]
}
function AppBarItem(props: AppBarItemProps & { onAction: AppBarProps["onAction"] }) {
  const { label: l, outline, icon, title, action, active, params = [], onAction } = props;
  const classNames = clsNames(AppBarClasses.AppBarItem, { active: active });
  let label = l >= 1000 ? `${Math.round(Number(l) / 1000)}K` : l;
  const onClick = useCallback<MouseEventHandler>(() => {
    onAction(action, ...params);
  }, [props]);
  return <Tooltip title={title} alignment={Alignment.Right}>
    <div className={classNames} onClick={onClick}>
      {label
        ? <Badge color={BadgeColors.Error} alignment={BadgeAlignment.TopRight}
                 label={label} variant={BadgeVariants.Regular}
                 outline={outline}>
          <FontIcon type={icon}/>
        </Badge>
        : <FontIcon type={icon}/>
      }
    </div>
  </Tooltip>;
}

function AppBarLogo(props: AppBarItemProps & { onAction: AppBarProps["onAction"] }) {
  const { src, action='logo', params=[], onAction } = props;
  const onClick = useCallback<MouseEventHandler>(() => {
    onAction(action, ...params);
  }, [props]);
  const classNames = clsNames(AppBarClasses.AppBarItem, AppBarClasses.AppBarLogo);
  return <div className={classNames} onClick={onClick}>
    <img src={src}/>
  </div>;
}
AppBarLogo.defaultProps= {
  src:RelcuLogoUrl
}
function AppBarDivider() {
  return <div className={AppBarClasses.AppBarDivider}/>;
}
function AppBarSplitter() {
  return <div className={AppBarClasses.AppBarSplitter}/>;
}
AppBarItem.defaultProps = {
  badgeColor: BadgeColors.Error,
  alignment: BadgeAlignment.TopRight
};

export interface UserInfoProps extends AppBarItemProps {
  user: AppBarProps["user"];
}

export enum UserInfoClasses {
  UserInfo = "user-info",
  UserInfoActive = "user-info--active",
  UserInfoName = "user-info__name",
}
function UserInfo(props: UserInfoProps & { onAction: AppBarProps["onAction"] }) {
  const { onAction, user: { status, objectIcon: icon, objectName: name } } = props;
  const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
  const [active, setActive] = useState<Boolean>(false);
  const togglePopper = useCallback((event) => {
    let rect: DOMRect = event.currentTarget.getBoundingClientRect();
    setActive(!active);
    setAnchorBounding(!anchorBounding ? rect : null);
  }, [active, anchorBounding]);

  const classNames = clsNames(AppBarClasses.AppBarItem, AppBarClasses.AppBarUserInfo, {
    [ UserInfoClasses.UserInfoActive ]: active === true
  });

  return <>
    <Tooltip title={name} alignment={Alignment.Right}>
      <div className={classNames} onClick={togglePopper}>
        <StatusBadge variant={BadgeVariants.Regular} outline={true} status={status}>
          <Avatar text={name} icon={icon} style={{ cursor: "pointer" }}/>
        </StatusBadge>
      </div>
    </Tooltip>
    <Popper
      open={!!anchorBounding}
      anchorBounding={anchorBounding}
      onClickAway={() => {
        setActive(false);
        setAnchorBounding(null);
      }}
      alignment={Alignment.RightTop}
      threshold={-8}>
      <Menu
        key="status"
        type="select"
        height="fit-content"
        style={{ overflow: "unset", contain: "unset" }}
        role="listbox">
        {props.items?.map(({ action, icon, title, params = [] }, id) => {
          const onClick = useCallback<MouseEventHandler>((e) => {
            setActive(false);
            setAnchorBounding(null);
            onAction(action, ...params);
          }, [props]);
          return <MenuItem
            key={id}
            thumbnail={<FontIcon type={icon}/>}
            onClick={onClick}>
            {title}
          </MenuItem>;
        })}

        {/*todo tmp solution*/}
        <SubMenu
          alignment="right"
          menu={
            <Menu type="select" height="fit-content" role="listbox">
              <MenuItem
                thumbnail={<FontIcon type={"done"}/>}
                onClick={(e) => {
                  togglePopper(e);
                  onAction("status", "available");
                }}>
                Available
              </MenuItem>
              <MenuItem
                thumbnail={<FontIcon type={"work_off"}/>}

                onClick={(e) => {
                  togglePopper(e);
                  onAction("status", "out_of_work");
                }}>
                Out of office
              </MenuItem>
              <MenuItem
                thumbnail={<FontIcon type={"remove_circle_outline"}/>}
                onClick={(e) => {
                  togglePopper(e);
                  onAction("status", "busy");
                }}>
                Busy
              </MenuItem>
            </Menu>
          }
        >
          <MenuItem alignItems={"center"} thumbnail={<FontIcon type={"person_outline"}/>}>
            <Box container flex={1} justify={"space-between"} alignItems={"center"}>
              Status
              <FontIcon className={CommonClasses.GrayIcon} type="keyboard_arrow_right"/>
            </Box>
          </MenuItem>
        </SubMenu>
        <MenuItem
          key={"logout"}
          thumbnail={<FontIcon type={"logout"}/>}
          onClick={(e) => {
            togglePopper(e);
            onAction("logout");
          }}>
          Logout
        </MenuItem>
      </Menu>
    </Popper>
  </>;
}

function AppBarMenuItem(props: AppBarItemProps & { onAction: AppBarProps["onAction"] }) {
  let { onAction } = props;
  const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
  const [active, setActive] = useState<Boolean>(false);
  const togglePopper = useCallback((event) => {
    setActive(!active);
    setAnchorBounding(!anchorBounding ? event.currentTarget.getBoundingClientRect() : null);
  }, [active, anchorBounding]);
  const onClickAway = useCallback(() => {
    setActive(false);
    setAnchorBounding(null);
  }, [props]);
  const classes = classNames(AppBarClasses.AppBarItem, {
    [ UserInfoClasses.UserInfoActive ]: active === true
  });

  return (
    <>
      <Tooltip title={props.title} alignment={Alignment.Right}>
        <div className={classes} onClick={togglePopper}>
          <FontIcon type={props.icon}/>
        </div>
      </Tooltip>
      {props.items && <Popper
        open={true}
        anchorBounding={anchorBounding}
        onClickAway={onClickAway}
        alignment={Alignment.Right}
        threshold={-8}>
        <Menu
          type={"select"}
          height={"fit-content"}
          style={{ overflow: "unset", contain: "unset" }}
          role="listbox">
          {props.items.map(({ title, icon, action, params = [] }, id) => {
            const onClick = useCallback<MouseEventHandler>((e) => {
              setActive(false);
              setAnchorBounding(null);
              onAction(action, ...params);
            }, [props]);
            return <MenuItem
              key={id}
              thumbnail={<FontIcon type={icon}/>}
              onClick={onClick}>{title}</MenuItem>;
          })}
        </Menu>
      </Popper>
      }
    </>
  );
}
