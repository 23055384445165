import React          from "react";
import { FC }         from "react";
import { StackProps } from "@relcu/rc";
import { Stack }      from "@relcu/rc";

export const SectionHeader: FC<StackProps> = React.memo(function SectionHeader(props) {
  return <Stack
    {...props}
    className={"mail-template-section-header"}
    justifyContent={"space-between"}
  />;
});
