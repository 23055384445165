import React                    from "react";
import { FC }                   from "react";
import { ImageInput }           from "../../Input/ImageInput";
import { InputState }           from "../../Input/Input";
import { useConsumeField }      from "../BaseField";
import { ImageInputFieldProps } from "./ImageInputField";


export const ImageInputEditField: FC<ImageInputFieldProps> = React.memo(function ImageField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField<ImageInputFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  return (
    <ImageInput
      {...input}
      name={props.name}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
    />
  );
});
export default ImageInputEditField;
