import React                     from "react";
import { FC }                    from "react";
import { FormApi }               from "@relcu/final-form";
import { classNames }            from "../../../../utils/classNames";
import { useBoxProps }           from "../../../Box";
import { MultiChoiceFieldProps } from "../../../Field/MultiChoiceField";
import { MultiChoiceField }      from "../../../Field/MultiChoiceField";
import { FieldContainer }        from "../FieldContainer";
import { EmailOption }           from "./EmailOption";

export interface EmailMultiSelectFieldProps extends MultiChoiceFieldProps {
  label: string;
  onDelete?(selected, field: string, form: FormApi<any>);
  validateTo?(value);
}

export const EmailMultiSelectField: FC<EmailMultiSelectFieldProps> = React.memo(function EmailMultiSelectField(props) {
  const { label, onDelete, options, validateTo, name, className, placeholder, ...p } = props;
  const classes = classNames(EmailMultiSelectClasses.EmailMultiSelect, className);

  return <FieldContainer label={label} className={classes} {...useBoxProps(p)}>
    <MultiChoiceField
      flex={1}
      optionLabel={"value"}
      optionKey={"value"}
      placeholder={"Select email"}
      name={name}
      renderOption={(option, selected) => {
        return <EmailOption optionLabel={"value"} {...option} active={selected}/>;
      }}
      options={options}
      {...p}
      validate={validateTo}
    />
  </FieldContainer>;
});

export const enum EmailMultiSelectClasses {
  EmailMultiSelect = "email-multiselect",
  Title = "email-option__title",
  Active = "email-option__title--active",
  Addition = "email-option__addition"
}