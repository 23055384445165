import { DeepPartial }                             from "@apollo/client/utilities";
import { CircularLoader }                          from "@relcu/ui";
import React                                       from "react";
import { FC }                                      from "react";
import { EmptyList }                               from "@relcu/ui";
import { ButtonSizes }                             from "@relcu/ui";
import { ButtonVariants }                          from "@relcu/ui";
import { Button }                                  from "@relcu/rc";
import { Alignment }                               from "@relcu/ui";
import { Tooltip }                                 from "@relcu/ui";
import { TypographySize }                          from "@relcu/ui";
import { TypographyColor }                         from "@relcu/ui";
import { Typography }                              from "@relcu/ui";
import { Box }                                     from "@relcu/ui";
import { classNames }                              from "@relcu/ui";
import { Card }                                    from "@relcu/ui";
import { Slider }                                  from "../../../../Slider";
import { SliderClasses }                           from "../../../../Slider";
import { BulkTable }                               from "../../../Section/BulkSection/BulkTable";
import { MailConversation_bulkStatsPerUser_stats } from "../__types__/MailConversation";
import { BulkItemClasses }                         from "./BulItemClasses";

export interface BulkItemStatisticsProps {
  columns: any;
  data: any;
  actions: any;
  onCancelAll: () => Promise<void>;
  page: number;
  onPage: (page: number) => void;
  pageCount: number;
  onFilter: (status: string | null) => void;
  status: string | null;
  count: number;
  onBehalf?: boolean;
  cancelLoading?: boolean;
  loading?: boolean;
  statistics: DeepPartial<MailConversation_bulkStatsPerUser_stats>;
}

export const BulkItemStatistics: FC<BulkItemStatisticsProps> = React.memo(function BulkItemStatistics(props) {
  const { onFilter, onBehalf, statistics, count, columns, data, actions, status, onCancelAll, page, onPage, pageCount, cancelLoading, loading } = props;
  return <>
    <Slider>
      <Card onClick={() => onFilter(null)} title={"Recipients"} selected={!status} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.total}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("queued")} title={"Processing"} selected={status === "queued"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.queued}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("completed")} title={"Sent"} selected={status === "completed"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.sent}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("failed")} title={"Failed"} selected={status === "failed"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Error} size={TypographySize.Subtitle}>
            {statistics.failed}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("open")} title={"Opened"} selected={status === "open"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.opened}
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("click")} title={"Click rate"} selected={status === "click"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.clicked ? (100 * statistics.clicked / statistics.total).toFixed(2) : 0}%
          </Typography>
          <Typography color={TypographyColor.Info} size={TypographySize.TextSmall}>
            ({statistics.clicked})
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("unsubscribe")} title={"Unsubscribe"} selected={status === "unsubscribe"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.unsubscribed ? (100 * statistics.unsubscribed / statistics.total).toFixed(2) : 0}%
          </Typography>
          <Typography color={TypographyColor.Info} size={TypographySize.TextSmall}>
            ({statistics.unsubscribed})
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("spam")} title={"Spam complaints"} selected={status === "spam"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.spammed ? (100 * statistics.spammed / statistics.total).toFixed(2) : 0}%
          </Typography>
          <Typography color={TypographyColor.Info} size={TypographySize.TextSmall}>
            ({statistics.spammed})
          </Typography>
        </Box>
      </Card>
      <Card onClick={() => onFilter("canceled")} title={"Canceled"} selected={status === "canceled"} active
            className={classNames(BulkItemClasses.StatusCard, SliderClasses.Item)}>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <Typography color={TypographyColor.Primary} size={TypographySize.Subtitle}>
            {statistics.canceled}
          </Typography>
        </Box>
      </Card>
    </Slider>
    <Box container justify={"space-between"} className={BulkItemClasses.ClearAllSection}>
      <Typography color={TypographyColor.ExtraDark} size={TypographySize.SubtitleSmall}>
        Recipients
      </Typography>
      {
        !!statistics.queued && !onBehalf && (status === "queued" || !status) &&
        <Tooltip alignment={Alignment.Top} title={"Only pending will be canceled"}>
          <Button disabled={cancelLoading} onClick={onCancelAll} appearance={"text"} size={"xs"}
                  aria-label="clear-browsing-history">CANCEL ALL</Button>
        </Tooltip>
      }
    </Box>
    {
      loading ?
        <Box container flex={1} justify={"center"} alignItems={"center"}>
          <CircularLoader/>
        </Box>
        :
        !count ?
          <Box container flex={1} justify={"center"} alignItems={"center"}>
            <EmptyList title={"No emails found under this filter"}
                       content={`Please navigate to a different filter to see emails.`}
                       alignSelf={"center"}/>
          </Box> :
          <BulkTable columns={columns} pageCount={pageCount} data={data} actions={actions} currentPage={page}
                     onPage={onPage}/>}
  </>;

});
