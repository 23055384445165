import React                     from "react";
import { AvatarSizes }           from "../../Avatar";
import { Avatar }                from "../../Avatar";
import { Box }                   from "../../Box";
import { useBoxProps }           from "../../Box";
import { ChipsVariants }         from "../../Chips";
import { ChipsColors }           from "../../Chips";
import { Chips }                 from "../../Chips";
import { Label }                 from "../../Label";
import { useConsumeField }       from "../BaseField";
import { BaseFieldClasses }      from "../BaseField";
import { PointerListFieldProps } from "./PointerListField";

export const PointerListReadField = React.memo(function PointerListReadField(props) {
  const { input: { readPlaceholder, label, selected, thumbnail, value, ...inputProps } } = useConsumeField<PointerListFieldProps>();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(inputProps)}>
      <Label>{label}</Label>
      <Box container wrap={"wrap"} gap={"XXXS"}>
        {
          (value && value.length) ? value.map(s => (
            <Chips
              alignSelf={"start"}
              key={s.objectId}
              color={ChipsColors.Grey}
              label={s.objectName}
              variant={ChipsVariants.Fill}
              thumbnail={thumbnail ? <Avatar icon={s.objectIcon} size={AvatarSizes.Small} text={s.objectName}/> : null}
            />
          )) : <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
        }
      </Box>
    </Box>
  );
});

export default PointerListReadField; //todo need to test
