import { Form }              from "@relcu/rc";
import React                 from "react";

export const ProposalPropertyTax = React.memo(function ProposalPropertyTax() {
  return (
    <Form.InputNumber
      name="propertyAnnualTax"
      label="Annual property tax"
      prefix="$"
      size="lg"
      required
      properties={{ style: { width: "100%" } }}
    />
  );
});
