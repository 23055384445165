import { transformNameToLabel } from "@relcu/ui";
import { toFirstLower }         from "@relcu/ui";
import { useMemo }              from "react";
import React                    from "react";
import { FC }                   from "react";
import { useCallback }          from "react";
import { useState }             from "react";
import { ButtonVariants }       from "@relcu/ui";
import { ButtonColors }         from "@relcu/ui";
import { Alignment }            from "@relcu/ui";
import { classNames }           from "@relcu/ui";
import { BoxComponentProps }    from "@relcu/ui";
import { Link }                     from "@relcu/ui";
import { Popper }                   from "@relcu/ui";
import { Tooltip }                  from "@relcu/ui";
import { SearchableMenu }           from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { FontIcon }                 from "@relcu/ui";
import { Button }                   from "@relcu/ui";
import { ButtonSizes }              from "@relcu/ui";
import { confirmModal }             from "@relcu/ui";
import { getObjectPath }            from "../../utils/layoutUtils";
import { getField }                 from "../../utils/schemaUtils";
import { usePointerListField }      from "../Layout/Field/PointerListField/usePointerListField";
import { ScopeConnectionClasses }   from "./ScopeConnectionClasses";
import "./scope-connection.css";

export interface LeadConnectionProps extends BoxComponentProps {
  scope?: any,
  type?: string
  typename: string;
  onClick(item)
  onRemove()
}

export const ScopeConnection: FC<LeadConnectionProps> = React.memo((props) => {
  const { onClick, scope, className, justify, onRemove, type, typename, ...p } = props;
  const targetClass = useMemo(() => {
    const scopeField = getField(typename, "scope");
    return scopeField.targetClass;
  }, [typename]);
  const { options, q, setQ } = usePointerListField({ ...p, targetClass });
  const [searchBounding, setSearchBounding] = useState(null);
  const handleOpen = useCallback((e) => {
    setSearchBounding(searchBounding ? null : e.currentTarget.getBoundingClientRect());
  }, [setSearchBounding, searchBounding]);
  const handleClose = useCallback(() => {
    setSearchBounding(null);
    setQ("");
  }, [setSearchBounding, searchBounding]);

  const handleSelect = useCallback((item) => {
    onClick(item);
    handleClose();
  }, [handleClose]);

  const removeLink = async () => {
    const scopeType = toFirstLower(transformNameToLabel(scope?.__typename))
    try {
      await confirmModal({
        title: `Remove ${scopeType} relation`,
        subTitle: `You are removing ${scopeType} relation.`,
        content: `You can restore scope of conversation by clicking on "Connect to" button.`,
        label: "REMOVE"
      });
      await onRemove();
    } catch (e) {
      console.error(e);
    }
  };

  return <>
    {
      type === "bulk" ?
        <Box container gap={"XXXS"}>
          <Button icon={"link"} size={ButtonSizes.Large} variant={ButtonVariants.Ghost} onClick={(e) => handleOpen(e)}>
            Relate to
          </Button>
          <Button icon={"delete"} size={ButtonSizes.Large} variant={ButtonVariants.Ghost} onClick={removeLink}>
            Remove relation
          </Button>
        </Box>
        :
        <Box {...p} justify={justify} container gap={"XXXS"} alignItems={"center"}
             className={classNames(className, ScopeConnectionClasses.ScopeConnection)}>
          {
            scope ?
              <>
                <Link
                  container
                  justify={justify}
                  alignItems={"center"}
                  className={ScopeConnectionClasses.ScopeConnectionLink}
                  to={`${getObjectPath(scope)}/details`}>
                  <FontIcon type={scope.__typename == "Lead" ? "rc_lead" : "rc_partner_exchange"}/>
                  <span>{scope?.objectName}</span>
                </Link>
                <Tooltip title={"Change relation"} alignment={Alignment.Bottom}>
                  <Button onlyIcon size={ButtonSizes.Small} className={ScopeConnectionClasses.ScopeConnectionEdit}
                          icon={"link"} color={ButtonColors.White}
                          onClick={(e) => handleOpen(e)}/>
                </Tooltip>
                <Tooltip title={"Remove"} alignment={Alignment.Bottom}>
                  <Button onlyIcon size={ButtonSizes.Small} className={ScopeConnectionClasses.ScopeConnectionEdit}
                          icon={"delete"} color={ButtonColors.White}
                          onClick={removeLink}/>
                </Tooltip>
              </>
              :
              <Box container gap={"XXXS"} alignItems={"center"}
                   onClick={(e) => handleOpen(e)}>
                <FontIcon type={"link"}/>
                Connect to
              </Box>
          }
        </Box>
    }
    {
      <Popper alignment={Alignment.BottomRight} open={!!searchBounding} onClickAway={handleClose}
              anchorBounding={searchBounding}>
        <SearchableMenu
          optionLabel={"objectName"}
          optionKey={"id"}
          options={options}
          onSelect={handleSelect}
          selected={scope && { id: scope.objectId }}
          searchText={q}
          loading={false}
          onType={(t) => t ? setQ(t) : setQ("")}
          showCreateButton={false}
        />
      </Popper>
    }
  </>;
});
