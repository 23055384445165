import React, { ReactNode }  from "react";
import { classNames }        from "../..";
import { BoxComponentProps } from "../..";
import { applyBoxStyles }    from "../..";
import { Box }               from "../..";
import { IconType }          from "../..";
import { FontIcon }          from "../..";
import { MenuClasses }       from "./Menu";

export interface MenuItemProps extends BoxComponentProps {
  disabled?: boolean;
  selected?: boolean;
  icon?: IconType;
  thumbnail?: ReactNode;
  onClick?(event?);
  contentProps?: { [ key: string ]: any; }
}

const defaultMenuItemProps: MenuItemProps = {
  disabled: false,
  selected: false
};

export const MenuItem = React.memo(React.forwardRef(function MenuItem(props: MenuItemProps = defaultMenuItemProps, ref: any) {
  const properties = applyBoxStyles<MenuItemProps>({
    container: true,
    flexShrink: 0,
    justify: "center",
    alignItems: "center",
    gap: "XXS",
    ...props
  });
  const { children, className, disabled, thumbnail, selected, icon, onClick, contentProps, ...p } = properties;
  let t = thumbnail;
  if (icon && !t) {
    t = <FontIcon type={icon}/>;
  }

  const classes = classNames(MenuClasses.MenuItem, {
    [ MenuClasses.Disabled ]: disabled,
    [ MenuClasses.Selected ]: selected
  }, className);
  return <li
    tabIndex={0}
    className={classes}
    ref={ref}
    role="option"
    onClick={!disabled ? onClick : null}
    // style={{ "contentVisibility": "auto", "containIntrinsicSize": "40px" } as any}
    {...p}>
    {t}
    <Box container flex={1} alignItems={"center"} className={MenuClasses.MenuItemContent} {...contentProps}>
      {children}
    </Box>
    {
      selected &&
      <FontIcon type={"done"} className={MenuClasses.MenuItemIcon}/>
    }
  </li>;
}));
// MenuItem.defaultProps = defaultMenuItemProps;
