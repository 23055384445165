import { useForm }               from "@relcu/form";
import { useMemo }               from "react";
import { useContext }            from "react";
import React                     from "react";
import { FC }                    from "react";
import { useCallback }           from "react";
import { useState }              from "react";
import { CircularLoader }        from "@relcu/ui";
import { Ellipsis, ModalBody }   from "@relcu/ui";
import { ListItemText }          from "@relcu/ui";
import { ButtonVariants }        from "@relcu/ui";
import { Button }                from "@relcu/ui";
import { ListItemTitle }         from "@relcu/ui";
import { ListRow }               from "@relcu/ui";
import { Radio }                 from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { ModalProps }            from "@relcu/ui";
import { Modal }                 from "@relcu/ui";
import { EmptyList }             from "@relcu/ui";
import { useLazySummary }        from "@relcu/ui";
import { TitleFeeArgs }          from "../../../../../../types/graphql-global-types";
import { format }                from "../../../../../../utils/helpers";
import { TitleFee }              from "../../__types__/TitleFee";
import { RateContext }           from "../Rate/RateProvider";
import { TITLE_FEE_SUM_FIELDS }  from "../RateConditions";
import { RatesContext }          from "../RatesProvider";
import { TitleFeeDialogClasses } from "./TitleFeeDialogClasses";
import "./title-fee-dialog.css";

export interface TitleFeeDialogProps extends Partial<ModalProps> {
  queryInput?: TitleFeeArgs;
}

export const TitleFeeDialog: FC<TitleFeeDialogProps> = React.memo(function TitleFeeDialog(props) {
  const { titleFeeResult } = useContext(RatesContext);
  const { selectedTitleFee } = useContext(RateContext);
  const { selected: { title: { selectedTitleFeeIdVar, customTitleFeeVar } } } = useContext(RatesContext);
  const { queryInput, onClose } = props;
  const [selected, setSelected] = useState<TitleFee>(selectedTitleFee);
  const [reFetchingId, setReFetchingId] = useState<string>();
  const form = useForm();
  const onApply = useCallback(() => {
    selectedTitleFeeIdVar(selected.providerId);
    form.change("ownersTitle", selected.fees.ownersTitle);
    form.change("recordingCharges", selected.fees.recordingCharges);
    form.change("settlementFee", selected.fees.settlementFee);
    form.change("titleInsurance", selected.fees.titleInsurance);
    form.change("transferTax", selected.fees.transferTax);
    form.change("lendersTitle", selected.fees.lendersTitle);
    onClose?.();
  }, [form, selected, onClose]);
  const titleFees = useMemo(() => {
    const customFee = customTitleFeeVar();
    return titleFeeResult?.result.map((title) => {
      if (title.providerId == "custom") {
        return customFee
      }
      return title
    });
  }, [titleFeeResult?.result]);

  const calculateTitleFees = useLazySummary({ fields: TITLE_FEE_SUM_FIELDS });
  const loading = titleFeeResult.loading;
  const reload = useCallback((providerId) => {
    setReFetchingId(providerId);
    titleFeeResult.refetch({
      input: {
        ...queryInput,
        providerIds: [providerId]
      }
    });
  }, [titleFeeResult?.refetch, setReFetchingId]);
  return <Modal
    title={"Title fees"}
    onClose={props.onClose}
    open={props.open}
    closeIcon={true}
    disableBackdropClick={false}
    className={TitleFeeDialogClasses.Modal}
    gap={"S"}
  >
    <ModalBody container direction={"column"} gap={"S"} className={TitleFeeDialogClasses.Container} flex={1}>
      <h3 className={TitleFeeDialogClasses.ContainerTitle}> Select a title company</h3>
      {
        !!titleFees?.length ?
          <>
            <Box container direction={"column"} gap={"XXS"} flex={1}>
              {titleFees.map((fee, k) => {
                return <Radio
                  disabled={!!fee.errorMessage}
                  checked={fee?.providerId == selected?.providerId}
                  onChange={() => setSelected(fee)}
                  name="titleCompany"
                  key={`tab-panel-item-${k}`}
                  label={<ListRow className={TitleFeeDialogClasses.TitleFee} flex={1} gap={"XS"}>
                    <ListItemTitle title={fee.title} flexBasis={"20%"} flexShrink={2} flex={"1 2 20%"}/>
                    {loading && reFetchingId == fee.providerId ?
                      <Box container gap={"XXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                        <CircularLoader/>
                        <ListItemText text={"Loading..."}/>
                      </Box> :
                      fee.errorMessage ?
                        <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                          <ListItemText label={fee.errorMessage}
                                        text={<Ellipsis
                                          style={{ pointerEvents: "auto", color: "var(--color-error)" }}
                                          from={90}>{fee.errorMessage}</Ellipsis>} tiny/>
                          <Button style={{ pointerEvents: loading ? "none" : "auto" }} disabled={loading}
                                  variant={ButtonVariants.Ghost}
                                  onClick={() => {
                                    reload(fee.providerId);
                                  }}>
                            Reload
                          </Button>
                        </Box>
                        :
                        <Box container gap={"XXXS"} flex={"1 0 15%"} justify={"end"} alignItems={"center"}>
                          <ListItemText text={"Rate:"} tiny/>
                          <ListItemText text={`$${format(calculateTitleFees(fee.fees), 2)}`}/>
                        </Box>
                    }
                  </ListRow>}/>;
              })
              }
            </Box>
            <Box container justify={"end"} gap={"XXS"} className={TitleFeeDialogClasses.Footer}>
              <Button variant={ButtonVariants.Outline} onClick={() => onClose()}>CANCEL</Button>
              <Button variant={ButtonVariants.Fill} onClick={onApply}>APPLY</Button>
            </Box>
          </>
          :
          <Box flex={"1 1 auto"} justify={"center"}>
            <EmptyList icon={"pie_chart"} content={"Please update lead data and try again."}
                       title={"No matching rates"}
                       alignSelf={"center"}/>
          </Box>
      }
    </ModalBody>
  </Modal>;
});
//Contact:O2fKs9vCNT  boxk
//'Contact:LFuwLdjSfd'
