//      
function moveFieldState(state, source, destKey, oldState = state) {
  delete state.fields[source.name];
  state.fields[destKey] = { ...source,
    name: destKey,
    // prevent functions from being overwritten
    // if the state.fields[destKey] does not exist, it will be created
    // when that field gets registered, with its own change/blur/focus callbacks
    change: oldState.fields[destKey] && oldState.fields[destKey].change,
    blur: oldState.fields[destKey] && oldState.fields[destKey].blur,
    focus: oldState.fields[destKey] && oldState.fields[destKey].focus,
    lastFieldState: undefined // clearing lastFieldState forces renotification

  };

  if (!state.fields[destKey].change) {
    delete state.fields[destKey].change;
  }

  if (!state.fields[destKey].blur) {
    delete state.fields[destKey].blur;
  }

  if (!state.fields[destKey].focus) {
    delete state.fields[destKey].focus;
  }
}

//      
// From MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
const escapeRegexTokens = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

//      

const insert = ([name, index, value], state, {
  changeValue,
  resetFieldState
}) => {
  changeValue(state, name, array => {
    const copy = [...(array || [])];
    copy.splice(index, 0, value);
    return copy;
  });
  const backup = { ...state.fields
  }; // now we have increment any higher indexes

  const pattern = new RegExp(`^${escapeRegexTokens(name)}\\[(\\d+)\\](.*)`); // we need to increment high indices first so
  // lower indices won't overlap

  Object.keys(state.fields).sort().reverse().forEach(key => {
    const tokens = pattern.exec(key);

    if (tokens) {
      const fieldIndex = Number(tokens[1]);

      if (fieldIndex >= index) {
        // inc index one higher
        const incrementedKey = `${name}[${fieldIndex + 1}]${tokens[2]}`;
        moveFieldState(state, backup[key], incrementedKey);
      }
    }
  });
};

//      
const concat = ([name, value], state, {
  changeValue
}) => {
  changeValue(state, name, array => array ? [...array, ...value] : value);
};

//      

function moveFields(name, matchPrefix, destIndex, state) {
  Object.keys(state.fields).forEach(key => {
    if (key.substring(0, matchPrefix.length) === matchPrefix) {
      const suffix = key.substring(matchPrefix.length);
      const destKey = `${name}[${destIndex}]${suffix}`;
      moveFieldState(state, state.fields[key], destKey);
    }
  });
}

//      
function restoreFunctions(state, backupState) {
  Object.keys(state.fields).forEach(key => {
    state.fields[key] = { ...state.fields[key],
      change: state.fields[key].change || backupState.fields[key] && backupState.fields[key].change,
      blur: state.fields[key].blur || backupState.fields[key] && backupState.fields[key].blur,
      focus: state.fields[key].focus || backupState.fields[key] && backupState.fields[key].focus
    };

    if (!state.fields[key].change) {
      delete state.fields[key].change;
    }

    if (!state.fields[key].blur) {
      delete state.fields[key].blur;
    }

    if (!state.fields[key].focus) {
      delete state.fields[key].focus;
    }
  });
}

//      
const TMP = 'tmp';

const move = ([name, from, to], state, {
  changeValue
}) => {
  if (from === to) {
    return;
  }

  changeValue(state, name, array => {
    const copy = [...(array || [])];
    const value = copy[from];
    copy.splice(from, 1);
    copy.splice(to, 0, value);
    return copy;
  }); //make a copy of a state for further functions restore

  const backupState = { ...state,
    fields: { ...state.fields
    } // move this row to tmp index

  };
  const fromPrefix = `${name}[${from}]`;
  moveFields(name, fromPrefix, TMP, state);

  if (from < to) {
    // moving to a higher index
    // decrement all indices between from and to
    for (let i = from + 1; i <= to; i++) {
      const innerFromPrefix = `${name}[${i}]`;
      moveFields(name, innerFromPrefix, `${i - 1}`, state);
    }
  } else {
    // moving to a lower index
    // increment all indices between to and from
    for (let i = from - 1; i >= to; i--) {
      const innerFromPrefix = `${name}[${i}]`;
      moveFields(name, innerFromPrefix, `${i + 1}`, state);
    }
  } // move from tmp index to destination


  const tmpPrefix = `${name}[${TMP}]`;
  moveFields(name, tmpPrefix, to, state);
  restoreFunctions(state, backupState);
};

//      

const pop = ([name], state, {
  changeValue
}) => {
  let result;
  let removedIndex;
  changeValue(state, name, array => {
    if (array) {
      if (!array.length) {
        return [];
      }

      removedIndex = array.length - 1;
      result = array[removedIndex];
      return array.slice(0, removedIndex);
    }
  }); // now we have to remove any subfields for our index,

  if (removedIndex !== undefined) {
    const pattern = new RegExp(`^${escapeRegexTokens(name)}\\[${removedIndex}].*`);
    Object.keys(state.fields).forEach(key => {
      if (pattern.test(key)) {
        delete state.fields[key];
      }
    });
  }

  return result;
};

//      
const push = ([name, value], state, {
  changeValue
}) => {
  changeValue(state, name, array => array ? [...array, value] : [value]);
};

//      

const remove = ([name, index], state, {
  changeValue,
  renameField,
  getIn,
  setIn
}) => {
  let returnValue;
  changeValue(state, name, array => {
    const copy = [...(array || [])];
    returnValue = copy[index];
    copy.splice(index, 1);
    return copy;
  }); // now we have to remove any subfields for our index,
  // and decrement all higher indexes.

  const pattern = new RegExp(`^${escapeRegexTokens(name)}\\[(\\d+)\\](.*)`);
  const backup = { ...state,
    fields: { ...state.fields
    }
  };
  Object.keys(state.fields).forEach(key => {
    const tokens = pattern.exec(key);

    if (tokens) {
      const fieldIndex = Number(tokens[1]);

      if (fieldIndex === index) {
        // delete any subfields for this array item
        delete state.fields[key]; // delete any submitErrors for this array item
        // if the root key of the delete index

        if (key === `${name}[${index}]`) {
          const path = `formState.submitErrors.${name}`;
          const submitErrors = getIn(state, path); // if has submitErrors for array

          if (Array.isArray(submitErrors)) {
            submitErrors.splice(index, 1);
            state = setIn(state, path, submitErrors);
          }
        }

      } else if (fieldIndex > index) {
        // shift all higher ones down
        delete state.fields[key];
        const decrementedKey = `${name}[${fieldIndex - 1}]${tokens[2]}`;

        if (backup.fields[decrementedKey]) {
          moveFieldState(state, backup.fields[key], decrementedKey, backup);
        } else {
          // take care of setting the correct change, blur, focus, validators on new field
          renameField(state, key, decrementedKey);
        }
      }
    }
  });
  return returnValue;
};

//      

const countBelow = (array, value) => array.reduce((count, item) => item < value ? count + 1 : count, 0);

const removeBatch = ([name, indexes], state, {
  changeValue
}) => {
  const sortedIndexes = [...indexes];
  sortedIndexes.sort(); // remove duplicates

  for (let i = 0; i < sortedIndexes.length; i++) {
    if (i > 0 && sortedIndexes[i] === sortedIndexes[i - 1]) {
      sortedIndexes.splice(i--, 1);
    }
  }

  let returnValue = [];
  changeValue(state, name, array => {
    // use original order of indexes for return value
    returnValue = indexes.map(index => array && array[index]);

    if (!array || !sortedIndexes.length) {
      return array;
    }

    const copy = [...array];
    const removed = [];
    sortedIndexes.forEach(index => {
      copy.splice(index - removed.length, 1);
      removed.push(array && array[index]);
    });
    return copy;
  }); // now we have to remove any subfields for our indexes,
  // and decrement all higher indexes.

  const pattern = new RegExp(`^${escapeRegexTokens(name)}\\[(\\d+)\\](.*)`);
  const newState = { ...state,
    fields: {}
  };
  Object.keys(state.fields).forEach(key => {
    const tokens = pattern.exec(key);

    if (tokens) {
      const fieldIndex = Number(tokens[1]);

      if (!~sortedIndexes.indexOf(fieldIndex)) {
        // not one of the removed indexes
        // shift all higher ones down
        const decrementedKey = `${name}[${fieldIndex - countBelow(sortedIndexes, fieldIndex)}]${tokens[2]}`;
        moveFieldState(newState, state.fields[key], decrementedKey, state);
      }
    } else {
      newState.fields[key] = state.fields[key];
    }
  });
  state.fields = newState.fields;
  return returnValue;
};

//      

const shift = ([name], state, tools) => remove([name, 0], state, tools);

//      
const TMP$1 = 'tmp';

const swap = ([name, indexA, indexB], state, {
  changeValue
}) => {
  if (indexA === indexB) {
    return;
  }

  changeValue(state, name, array => {
    const copy = [...(array || [])];
    const a = copy[indexA];
    copy[indexA] = copy[indexB];
    copy[indexB] = a;
    return copy;
  }); //make a copy of a state for further functions restore

  const backupState = { ...state,
    fields: { ...state.fields
    } // swap all field state that begin with "name[indexA]" with that under "name[indexB]"

  };
  const aPrefix = `${name}[${indexA}]`;
  const bPrefix = `${name}[${indexB}]`;
  const tmpPrefix = `${name}[${TMP$1}]`;
  moveFields(name, aPrefix, TMP$1, state);
  moveFields(name, bPrefix, indexA, state);
  moveFields(name, tmpPrefix, indexB, state);
  restoreFunctions(state, backupState);
};

//      

const unshift = ([name, value], state, tools) => insert([name, 0, value], state, tools);

//      
const update = ([name, index, value], state, {
  changeValue
}) => {
  changeValue(state, name, array => {
    const copy = [...(array || [])];
    copy.splice(index, 1, value);
    return copy;
  });
};

//      
export const arrayMutators = {
  insert,
  concat,
  move,
  pop,
  push,
  remove,
  removeBatch,
  shift,
  swap,
  unshift,
  update
};

export default arrayMutators;
