import React            from "react";
import { FC }           from "react";
import { useRef }       from "react";
import { useCallback }  from "react";
import { useFormState } from "@relcu/final-form";
import { useBlocker }   from "@relcu/react-router";
import { Button }       from "@relcu/rc";
import { Typography }   from "@relcu/rc";
import { ModalFooter }  from "@relcu/ui";
import { ModalBody }    from "@relcu/ui";
import { Modal }        from "@relcu/ui";
import { modal }        from "@relcu/ui";
import { omit }         from "@relcu/ui";

export interface DirtyDialogControllerProps {
  header?: string
  title?: string,
  subTitle?: string
}

export const DirtyDialogController: FC<DirtyDialogControllerProps> = React.memo(function DirtyDialogController(props) {
  const { title, subTitle, header } = props;
  const isOpened = useRef(false);

  const { dirty, submitting, dirtyFields } = useFormState({
    subscription: {
      dirty: true,
      submitting: true,
      dirtyFields: true
    }
  });
  const fields = omit(dirtyFields, ["from", "to", "loanProposal", "scope", "previewSettings", "previewSettings.mailbox", "previewSettings.user", "previewSettings.scope"]);
  const handleBlocker = useCallback((tx) => {
    if (!submitting && !isOpened.current) {
      isOpened.current = true;
      const { destroy } = modal(DirtyDialog, {
        title,
        subTitle,
        header,
        onClose: () => {
          isOpened.current = false;
          destroy();
        },
        onConfirm: () => {
          isOpened.current = false;
          tx.retry();
          destroy();
        }
      });
    }
  }, [dirty, submitting, dirtyFields]);

  useBlocker(handleBlocker, Object.keys(fields).length > 0);

  return null;
});

export interface DirtyDialogProps extends DirtyDialogControllerProps {
  onClose(),
  onConfirm()
}

export const DirtyDialog: FC<DirtyDialogProps> = function DirtyDialog(props) {
  const { onClose, onConfirm, header, title, subTitle } = props;

  return (
    <Modal
      className={"unsaved-changes-modal"}
      title={header ?? "Unsaved changes"}
      open={true}
      onClose={onClose}
      closeIcon={true}
      variant={"small"}>
      <ModalBody gap={"XS"} container direction={"column"} flex={"1 1 auto"} justify={"center"} alignItems={"center"}>
        <Typography variant={"base14"} weights={"medium"} lineHeight={"lh24"}>
          {title ?? "You have unsaved changes that will be lost if you proceed."}
        </Typography>
        <Typography variant={"small12"} style={{ color: "var(--rc-text-secondary)" }}>
          {subTitle ?? "Are you sure you want to continue without saving?"}
        </Typography>
      </ModalBody>
      <ModalFooter>
        <Button type="button" size={"sm"} onClick={onClose} appearance="text">CANCEL</Button>
        <Button size={"sm"} onClick={onConfirm}>CONTINUE</Button>
      </ModalFooter>
    </Modal>
  );
};
