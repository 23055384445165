import React             from "react";
import { FC }            from "react";
import { FieldArray }    from "@relcu/form";
import { StepInterface } from "@relcu/ui";
import { HiddenField }   from "@relcu/ui";
import { TimeField }     from "@relcu/ui";
import { SwitchField }   from "@relcu/ui";
import { classNames }    from "@relcu/ui";
import { ListItemText }  from "@relcu/ui";
import { Box }           from "@relcu/ui";
import { StepClasses }   from "@relcu/ui";
import { StepContainer } from "@relcu/ui";
import { StepHeader }    from "@relcu/ui";
import { StepRow }       from "@relcu/ui";
import { StepSection }   from "@relcu/ui";
import { StepBody }      from "@relcu/ui";

export const Schedule: FC<StepInterface> = React.memo(function Schedule(props) {
  const { title, description, step } = props;
  return <StepContainer>
    <StepHeader
      step={step}
      title={title}
      description={description}
      edit={false}/>
    <StepBody alignItems={"center"}>
      <StepSection title={"Time and days of the week"} justify={"center"} style={{ minWidth: 710 }}>
        <Box container direction={"column"} gap={"XXXS"}>
          <Box container gap={"XS"} alignItems={"center"} className={StepClasses.StepRowHeader}>
            <ListItemText className={StepClasses.StepItem} flex={"0 0 180px"} text={"Week days"}
                          tiny/>
            <ListItemText className={StepClasses.StepItem} flex={"0 0 180px"} text={"From time"}
                          tiny/>
            <ListItemText className={StepClasses.StepItem} flex={"0 0 180px"} text={"To time"}
                          tiny/>
          </Box>
          <Box container direction={"column"} gap={"XXS"}>
            <FieldArray name={"schedule"}>
              {
                ({ fields, meta }) => {
                  return fields.map((name, index) => {
                    return <StepRow container gap={"XS"}
                                    className={classNames({ [ StepClasses.StepRowInactive ]: !fields.value[ index ].enabled })}
                                    key={index}>
                      <HiddenField name={`${name}.key`}/>
                      <SwitchField name={`${name}.enabled`} flex={"0 0 180px"} placeholder={fields.value[ index ].day}/>
                      <TimeField name={`${name}.from`} placeholder={"Select time"}
                                 disabled={!fields.value[ index ].enabled} flex={"0 0 180px"}/>
                      <TimeField name={`${name}.to`} placeholder={"Select time"}
                                 disabled={!fields.value[ index ].enabled} flex={"0 0 180px"}/>
                    </StepRow>;
                  });
                }
              }
            </FieldArray>
          </Box>
        </Box>
      </StepSection>
      <StepSection title={"Additional settings on distribution hours"} justify={"center"} style={{ minWidth: 710 }}>
        <SwitchField placeholder={"Distribute leads outside of \"Callable hours\""} name={"skipTimezoneCheck"}/>
      </StepSection>
    </StepBody>
  </StepContainer>;
});
