import React                  from "react";
import { ReactNode }          from "react";
import { useEffect }          from "react";
import { ReactElement }       from "react";
import { useState }           from "react";
import { useMemo }            from "react";
import { Alignment }          from "../../constants/Alignemnt";
import { useImperativeState } from "../../hooks/useImperativeState";
import { useBoxProps }        from "../Box";
import { BoxComponentProps }  from "../Box";
import { Box }                from "../Box";
import { Chips }              from "../Chips";
import { ChipsVariants }      from "../Chips";
import { ChipsColors }        from "../Chips";
import { FontIcon }           from "../Icon";
import { Popper }             from "../Popper";

export interface ItemsEllipsisProps extends BoxComponentProps{
  ellipsis?: (count, extended) => ReactElement;
  renderItem: (item, index) => ReactNode;
  showLength?: number;
  items: any[];
  optionKey?: string;
  optionLabel?: string;
  holdup?: boolean;
  alignment?: Alignment;
}

export function ItemsEllipsis(props: ItemsEllipsisProps) {
  const { items, renderItem, ellipsis, showLength, holdup, alignment, ...p } = props;
  const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
  const [showAll, setShowAll] = useState(false);
  const remainingItems = useMemo(() => items.length > showLength ? items.length - showLength : 0, [showLength, items]);
  let visibleItems = useMemo(() => {
    return showLength && items.length > showLength ? items.slice(0, showLength) : items;
  }, [items, showLength]);
  const holdupItems = useMemo(() => {
    return showLength && items.length > showLength ? items.slice(showLength) : [];
  }, [items, showLength]);


  useEffect(() => {
    if (!holdupItems.length) {
      setAnchorBounding(null)
    }
  }, [holdupItems])

  const togglePopper = e => {
    setAnchorBounding(!anchorBounding ? e.currentTarget.getBoundingClientRect() : null);
  };

  const handleToggle = e => {
    holdup ?
      (() => {
        e.stopPropagation();
        setShowAll(!showAll);
      })()
      :
      togglePopper(e);
  };
  const component = ellipsis?.(remainingItems, showAll);

  return <Box container gap={"XXXS"} wrap={"wrap"} alignItems={"center"} {...useBoxProps(p)}>
    {
      (showAll ? items : visibleItems).map((item, index) => renderItem(item, index))
    }
    {
      !!showLength && !!remainingItems &&
      (
        ellipsis ?
          React.cloneElement(component, {
            ...component.props,
            onClick: handleToggle
          })
          :
          <Chips
            thumbnail={showAll && <FontIcon type={"keyboard_arrow_left"}/>}
            color={ChipsColors.Grey}
            variant={ChipsVariants.Outline}
            label={!showAll && `+${remainingItems}`}
            onClick={togglePopper}
            disabled={false}/>
      )
    }
    <Popper
      open={!!anchorBounding}
      anchorBounding={anchorBounding}
      onClickAway={(e) => {
        setAnchorBounding(null);
      }}
      alignment={alignment ?? Alignment.Top}
      threshold={4}>
      <Box container gap={"XXXS"} wrap={"wrap"}
           className={ItemsEllipsisClasses.EllipsisPopoverContainer}>
        {
          holdupItems.map((item, index) => renderItem(item, index))
        }
      </Box>
    </Popper>
  </Box>;
}

ItemsEllipsis.defaultProps = {
  holdup: true
};

export const enum ItemsEllipsisClasses {
  EllipsisPopoverContainer = "ellipsis-popover__container"
}
