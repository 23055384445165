import React                        from "react";
import { Tooltip }                  from "../index";
import { Whisper }                  from "../index";
import { RsRefForwardingComponent } from "../@types/common";
import { WhisperProps }             from "../Whisper";

export interface AdviceProps extends Omit<WhisperProps, "speaker"> {
  /** Whether visible */
  visible?: boolean;

  /** Whether show the arrow indicator */
  arrow?: boolean;

  text: string;
}

const Advice: RsRefForwardingComponent<"div", AdviceProps> = React.forwardRef(
  (props: AdviceProps, ref) => {
    const {
      className,
      children,
      style,
      visible,
      arrow = true,
      text,
      ...rest
    } = props;

    return (
      <Whisper
        placement="top"
        controlId="control-id-focus"
        trigger="hover"
        speaker={<Tooltip arrow={arrow} visible={visible}>{text}</Tooltip>}
        {...rest}>
        {children}
      </Whisper>
    );
  }
);

Advice.displayName = "Advice";

export default Advice;
