import { FC }             from "react";
import React              from "react";
import Icon               from "../Icon";
import { Popover }        from "../index";
import { ToggleDropdown } from "../index";
import { Typography }     from "../index";
import { Avatar }         from "../index";
import { useClassNames }  from "../utils";

export interface RecipientProps extends React.HTMLAttributes<HTMLDivElement> {
  objectName: string
  objectIcon?: string
  hint: string,
  active: boolean,
  info: { label: string, value: string }[]
}
export const Recipient: FC<RecipientProps> = (props: RecipientProps) => {
  const { objectIcon, objectName, hint, active, info, ...rest } = props;
  const { withClassPrefix, merge, prefix } = useClassNames("recipient", "rc");
  const renderMenu = ({ onClose, left, top, className }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} arrow={false} full>
        <div className={"rc-recipient-info"}>
          {
            info.map((i, index) => {
              return <div key={index} className={"rc-recipient-info-item"}>
                <Typography className={"rc-recipient-info-item-key"} weights={"regular"} color={"secondary"}
                            variant={"base14"} lineHeight={"lh20"}>{i.label}</Typography>
                <Typography className={"rc-recipient-info-item-value"} weights={"medium"} color={"primary"}
                            variant={"base14"} lineHeight={"lh20"}>{i.value}</Typography>
              </div>;
            })
          }
        </div>
      </Popover>
    );
  };
  return <div {...rest} className={withClassPrefix({ active })}>
    <Avatar circle={true} size={"sm"} showIndicator={false} src={objectIcon}>{objectName}</Avatar>
    <div style={{ overflow: "hidden" }}>
      <div className={"rc-recipient-name-section"}>
        <Typography weights={"medium"} color={"primary"} variant={"base14"}
                    lineHeight={"lh20"}>{objectName}</Typography>
        {
          info &&
          <ToggleDropdown
            trigger={"hover"}
            speaker={renderMenu}
            toggleRenderer={() => {
              return <Icon className={"rc-recipient-info-icon"} type={"info"}/>;
            }}/>
        }
      </div>
      <Typography weights={"regular"} className={"rc-recipient-hint"} color={"secondary"}
                  variant={"small12"}>{hint}</Typography>
    </div>
    {/*<IconButton circle={true} appearance={'subtle'} className={'rc-cart-delete-icon'} icon={<Icon  type={'delete'}/>}/>*/}
  </div>;
};
