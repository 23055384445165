import { TopLevelCondition } from "@relcu/ui";
import { FormSummaryField }  from "@relcu/ui";

export const WHEN_LENDER_CREDIT_NOT_NULL: TopLevelCondition = { and: [{ path: "lenderCredits", operator: "neqEquals", value: null }] };
export const WHEN_SELLER_CONCESSION_NOT_NULL: TopLevelCondition = { and: [{ path: "closingCost.sellerConcession", operator: "neqEquals", value: null }] };
export const WHEN_EARNEST_MONEY_PAID_NOT_NULL: TopLevelCondition = { and: [{ path: "closingCost.earnestMoneyPaid", operator: "neqEquals", value: null }] };
export const WHEN_DISCOUNT_POINT_NOT_NULL: TopLevelCondition = { and: [{ path: "closingCost.discountPoints", operator: "neqEquals", value: null }] };

export const DIALOG_PREPAID_COSTS_SUM_FIELDS: FormSummaryField[] = [
  { name: "prepaid.tax" },
  { name: "prepaid.insurance" },
  { name: "prepaid.lockInFee" },
  { name: "prepaid.yearlyInsurance", conditions: { and: [{ path: "prepaid.yearlyInsurance", operator: "isNotEmpty" }] } },
  { name: "prepaid.interest", conditions: { and: [{ path: "prepaid.interest", operator: "isNotEmpty" }] } }
];

export const DIALOG_CLOSING_COST_SUM_FIELDS: FormSummaryField[] = [
  { name: "closingCost.adminFee", conditions: { and: [{ path: "closingCost.adminFee", operator: "isNotEmpty" }] } },
  { name: "closingCost.appraisalFee", conditions: { and: [{ path: "closingCost.appraisalFee", operator: "isNotEmpty" }] } },
  { name: "closingCost.attorney", conditions: { and: [{ path: "closingCost.attorney", operator: "isNotEmpty" }] } },
  { name: "closingCost.condoCertificate", conditions: { and: [{ path: "closingCost.condoCertificate", operator: "isNotEmpty" }] } },
  { name: "closingCost.discountPoints", conditions: { and: [{ path: "closingCost.discountPoints", operator: "isNotEmpty" }] } },
  { name: "closingCost.docReview", conditions: { and: [{ path: "closingCost.docReview", operator: "isNotEmpty" }] } },
  { name: "closingCost.earnestMoneyPaid", conditions: { and: [{ path: "closingCost.earnestMoneyPaid", operator: "isNotEmpty" }] } },
  { name: "closingCost.floodCertification", conditions: { and: [{ path: "closingCost.floodCertification", operator: "isNotEmpty" }] } },
  { name: "closingCost.fundingFee", conditions: { and: [{ path: "closingCost.fundingFee", operator: "isNotEmpty" }] } },
  { name: "closingCost.mip", conditions: { and: [{ path: "closingCost.mip", operator: "isNotEmpty" }] } },
  { name: "closingCost.pestInspection", conditions: { and: [{ path: "closingCost.pestInspection", operator: "isNotEmpty" }] } },
  { name: "closingCost.sellerConcession", conditions: { and: [{ path: "closingCost.sellerConcession", operator: "isNotEmpty" }] } },
  { name: "closingCost.subordination", conditions: { and: [{ path: "closingCost.subordination", operator: "isNotEmpty" }] } },
  { name: "closingCost.survey", conditions: { and: [{ path: "closingCost.survey", operator: "isNotEmpty" }] } },
  { name: "closingCost.pmi", conditions: { and: [{ path: "closingCost.pmi", operator: "isNotEmpty" }] } },
  { name: "closingCost.title.total" },
  { name: "closingCost.creditAndOther.total" }
];

export const DIALOG_TOTAL_CLOSING_COST_SUM_FIELDS: FormSummaryField[] = [
  ...DIALOG_CLOSING_COST_SUM_FIELDS,
  ...DIALOG_PREPAID_COSTS_SUM_FIELDS,
  { name: "lenderCredits", conditions: { and: [{ path: "lenderCredits", operator: "isNotEmpty" }] } }
];

export const DIALOG_MONTHLY_PAYMENT_SUM_FIELDS: FormSummaryField[] = [
  { name: "monthlyPayment.insurance" },
  { name: "monthlyPayment.mi" },
  { name: "monthlyPayment.pi" },
  { name: "monthlyPayment.tax" },
  { name: "monthlyPayment.pmi", conditions: { and: [{ path: "monthlyPayment.pmi", operator: "isNotEmpty" }] } }
];

export const DIALOG_PREPAID_INSURANCE: FormSummaryField[] = [
  { name: "prepaid.monthlyInsurance" },
  { name: "prepaid.monthsToPayInsurance" }
];

export const DIALOG_PREPAID_INTEREST: FormSummaryField[] = [
  { name: "prepaid.dailyInterest" },
  { name: "prepaid.daysToPayInterest" }
];
export const DIALOG_PREPAID_TAX: FormSummaryField[] = [
  { name: "prepaid.monthlyTax" },
  { name: "prepaid.monthsToPayTax" }
];
