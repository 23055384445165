import React                from "react";
import { Label }            from "../../..";
import { Box }              from "../../Box";
import { useConsumeField }  from "../BaseField";
import { BaseFieldClasses } from "../BaseField";

export const UsernameReadField = React.memo(function UsernameReadField(props: any) {
  const { input: { readPlaceholder, label, required, value, name, targetClassName, flexBasis, flexShrink, flexGrow, ...textProps } } = useConsumeField();
  return (
    <Box container
         direction={"column"}
         gap={"XXXS"}
         className={BaseFieldClasses.ReadField}
         alignSelf={"start"}
         {...textProps}
         flexBasis={flexBasis}
         flexShrink={flexShrink}
         flexGrow={flexGrow}
    >
      <Label>{label}</Label>
      {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
    </Box>
  );
});

export default UsernameReadField;
