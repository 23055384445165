import { useCallback }              from "react";
import React                        from "react";
import { FC }                       from "react";
import { InlineEditableInput }      from "../../Input/InlineEditableInput";
import { InlineEditableInputProps } from "../../Input/InlineEditableInput";
import { BaseFieldProps }           from "../BaseField";
import { NumberFieldProps }         from "../NumberField";
import { useNumberField }           from "../NumberField/useNumberField";

export type CurrencyInlineEditableProps = NumberFieldProps & Omit<InlineEditableInputProps, "value">;

export const CurrencyInlineEditableField: FC<CurrencyInlineEditableProps> = React.memo(function CurrencyInlineEditableField(props) {
  const field = useNumberField(props);
  const parse = useCallback((value) => {
    return field.toFixedNoRounding(Number(field.clean(value)), props.precision);
  }, [field.toFixedNoRounding, field.clean, props.precision]);
  const { input, meta: { touched, error, submitError } } = field;
  const hasError = (touched && !!error) || !!submitError;
  return (
    <InlineEditableInput
      hideActionButtons={props.hideActionButtons}
      error={hasError}
      value={input.value}
      {...input}
      {...props}
      parse={(value => parse(value))}
      editFormat={(value => field.format(value, input.name))}
      onChange={field.onChange}
      format={value => (value || value === 0) ? `$${value}` : "-"}
    />
  );
});
CurrencyInlineEditableField.defaultProps = {
  precision: 2
};
