import { Link }                                   from "@relcu/ui";
import React                                      from "react";
import { useSource }                              from "@relcu/ui";
import { CommonClasses }                          from "@relcu/ui";
import { TimelineItem }                           from "@relcu/ui";
import { TypographyColor }                        from "@relcu/ui";
import { Typography }                             from "@relcu/ui";
import { TimelineItemSubject }                    from "@relcu/ui";
import { TimelineItemIconColors }                 from "@relcu/ui";
import { TimelineItemIcon }                       from "@relcu/ui";
import { BulkSMSActivity as BulkSMSActivityType } from "../../../../__types__/BulkSMSActivity";

export interface BulkSMSActivityProps extends BulkSMSActivityType {
  createdAt: string;
}

export const BulkSMSActivity = React.memo<BulkSMSActivityProps>(function BulkMailActivity(props) {
  const { bulkSMSConversation, bulkSMSCount, bulkSMSCountPerUser, bulkSMSUserCount: usersCount, bulkSMSConversationFrom, createdAt } = props;
  const bulkSMSUserCount = usersCount - 1;
  const { $viewer, $object } = useSource();
  const isOwner = bulkSMSConversationFrom.objectId === $object.objectId;
  const behalfOf = bulkSMSUserCount && bulkSMSCount !== bulkSMSUserCount;
  const smsCount = isOwner ? bulkSMSCount : (bulkSMSCountPerUser?.find(b => b.user == $object.objectId).count ?? bulkSMSCount);
  const navigateTo = (obj) => {
    if ($viewer.objectId === obj.objectId) {
      return `/user/${obj.objectId}/sms/${bulkSMSConversation.objectId}`;
    } else {
      return `/user/${obj.objectId}`;
    }
  };
  return <TimelineItem
    primary={<TimelineItemSubject>
      <Link
        to={navigateTo(bulkSMSConversationFrom)}>
        <Typography
          className={CommonClasses.ClickableIcon}>{bulkSMSConversationFrom?.objectName || "Unknown"}</Typography>
      </Link>
      <Typography color={TypographyColor.Secondary}>sent</Typography>
      <Typography>bulk SMS</Typography>
      <Typography color={TypographyColor.Secondary}>to</Typography>
      <Typography>{smsCount} people {behalfOf ? `on behalf of` : ``}</Typography>
      {!!behalfOf && (!isOwner ?
          <Link
            to={navigateTo($object)}>
            <Typography className={CommonClasses.ClickableIcon}>{$object.objectName}</Typography>
          </Link> : <Typography>{bulkSMSUserCount} users</Typography>
      )}    </TimelineItemSubject>}
    creationDate={createdAt}
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Primary} icon={"rc_bulk_sms"}/>}>
  </TimelineItem>;

});
