import { OperationVariables }   from "@apollo/client";
import { MutationHookOptions }  from "@apollo/client";
import { gql }                  from "@apollo/client";
import { useMutation }          from "@apollo/client";
import { IQueryBuilderOptions } from "@relcu/gql-query-builder";
import * as builder             from "@relcu/gql-query-builder";
import { useMemo }              from "react";

export function useJqlMutation<TData = any, TVariables = OperationVariables>(
  mutation: IQueryBuilderOptions | IQueryBuilderOptions[],
  options: MutationHookOptions<TData, TVariables> & { operationName: string }
) {

  const { documentNode, variables } = useMemo(() => getJqlMutation(mutation, options), [mutation, options?.operationName]);

  return useMutation<TData, TVariables>(documentNode, {
    ...options,
    variables: {
      ...variables,
      ...options?.variables
    }
  });
}

export function getJqlMutation(mutation: IQueryBuilderOptions | IQueryBuilderOptions[], options) {
  const { query: mutationString, variables } = builder.mutation(mutation, null, {
    operationName: options?.operationName || ""
  });
  return {
    documentNode: gql(mutationString),
    variables
  };
}
