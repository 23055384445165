import React                 from "react";
import { useEffect }         from "react";
import { useRef }            from "react";
import { ReactNode }         from "react";
import { useFormState }      from "@relcu/form";
import { getIn }             from "@relcu/form";
import { TopLevelCondition } from "../../utils/Condition";
import { formatNumber }      from "../../utils/helpers";
import { useLazyCondition }  from "./index";

export interface FormSummaryField {
  conditions?: TopLevelCondition;
  source?: any;
  name: string;
}

export interface UseLazySummaryOptions {
  operator?: "sum" | "multiply" | "divide" | "diff";
  fields: FormSummaryField[];
  data?: any;
}

export interface FormSummaryProps extends UseLazySummaryOptions {
  prefix?: string;
  children?: FunctionOrReactNode;
}
type FunctionOrReactNode = ReactNode | ((value: number) => ReactNode)

export const FormSummary = React.memo<FormSummaryProps>(function FormSummary(props) {
  const { children, prefix = "$", ...lazyProps } = props;
  const { values, valid, invalid } = useFormState({ subscription: { values: true, valid: true, invalid: true } });
  let value = 0;
  const prevValueRef = useRef(value);
  const calculate = useLazySummary(lazyProps);

  if (valid) {
    value = calculate(values);
  }
  useEffect(() => {
    if (value) {
      prevValueRef.current = value;
    }
  }, [value]);

  if (invalid) {
    value = prevValueRef.current;
  }

  if (children instanceof Function) {
    return <>{children(value)}</>;
  }
  return <>{prefix || ""}{formatNumber(value, 2)}</>;
});

export function useLazySummary(props: UseLazySummaryOptions) {
  const { operator = "sum" } = props;
  const evaluate = useLazyCondition();
  function calculate(values) {
    const formValues = { ...values, ...props.data };
    let value = 0;
    let i = 0;
    for (let { name, source, conditions } of props.fields) {
      source = { ...formValues, ...source };
      if (conditions) {
        const { apply } = evaluate({ conditions, source });
        if (!apply) {
          continue;
        }
      }
      const current = Number(getIn(source, name)) || 0;

      switch (operator) {
        case "sum":
          value += current;
          break;
        case "diff":
          if (!value && i === 0) {
            value = current;
          } else {
            value -= current;
          }
          break;
        case "divide":
          if (!value) {
            value = current;
          } else {
            value /= current;
          }
          break;
        case "multiply":
          if (!value && i === 0) {
            value = current;
          } else {
            value *= current;
          }
          break;
      }
      i++;
    }

    return Number(value.toFixed(2));
  }

  return calculate;
}

