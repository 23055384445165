import React                from "react";
import { MutableRefObject } from "react";
import { Box }              from "@relcu/ui";
import { EmptyQueue }       from "./EmptyQueue";
import { LeadQueue }        from "./LeadQueue";
import { useQueueStats }    from "./useQueueStats";
import "./lead-queue.css";

export const LeadQueues = React.memo(React.forwardRef(function LeadQueues({ collapsed, showCall }: { collapsed: boolean, showCall?:boolean }, ref: MutableRefObject<HTMLDivElement>) {
  const stats = useQueueStats();
  return (
    !stats?.queues || !stats?.queues.length
      ?
      <EmptyQueue collapsed={collapsed} role={stats.role} loading={stats?.loading}/>
      :
      <Box container direction={"column"} gap={"XXS"} alignItems={"center"} ref={ref}>
        {
          stats.queues.map(queue =>
            <LeadQueue
              canRing={!showCall}
              collapsed={collapsed}
              queue={queue}
              key={queue.id}
              take={(id) => stats.take(id)}
              loading={stats.loadingTakeLead}/>)
        }
      </Box>
  );
}));
