import { ToggleSwitch }                            from "@relcu/ui";
import { useState }                                from "react";
import { useCallback }                             from "react";
import React                                       from "react";
import { FC }                                      from "react";
import { useMutation }                             from "@apollo/client";
import { gql }                                     from "@apollo/client";
import { Typography }                              from "@relcu/ui";
import { useSource }                               from "@relcu/ui";
import { Box }                                     from "@relcu/ui";
import { JsonSectionProps }                        from "@relcu/ui";
import { Section }                                 from "@relcu/ui";
import { USER_NOTIFICATION_CONTROLS_FRAGMENT }     from "../../../../graph/operations.graphql";
import { UpdateUserNotificationControlsVariables } from "./__types__/UpdateUserNotificationControls";
import { UpdateUserNotificationControls }          from "./__types__/UpdateUserNotificationControls";

export const NotificationControlsSection: FC<JsonSectionProps> = React.memo(function NotificationControlsSection(props) {
  const { title, editable, onEdit, onClear, ...p } = props;
  const [updateUser] = useMutation<UpdateUserNotificationControls, UpdateUserNotificationControlsVariables>(UPDATE_USER_NOTIFICATION_CONTROLS);
  const { $object: { id, notificationControls } } = useSource();
  const { __typename, ...controls } = notificationControls;
  const [notifControls, setNotifControls] = useState(controls);
  const onChange = useCallback(async (name, value) => {
    await updateUser({
      variables: {
        input: {
          id,
          fields: {
            notificationControls: {
              ...notifControls,
              [ name ]: value
            }
          }
        }
      }
    });
    setNotifControls(contr => {
      return { ...contr, [ name ]: value };
    });
  }, [updateUser, id, notifControls]);

  return <Section
    view={props.view}
    title={title}
    editable={editable}
    container
    direction={"column"}
    gap={"XXS"}
    onEdit={onEdit}
    onClear={onClear}
    {...p}>
    <Box container direction={"column"} gap={"XS"}>
      <Typography style={{ whiteSpace: "pre-wrap" }}>In case you disable one of these, you will stop seeing in-app and
        browser notifications.
        {"\n"}Counters also won't be shown on the navigation, however, you will still be able to see them in
        notification center. </Typography>
      {props.fields.map((field, index) => (
        <ToggleSwitch checked={notifControls[ field.name ]}
                      key={index}
                      onChange={(e) => onChange(field.name, e)}
                      label={field.label}
                      name={field.name}/>
      ))}

    </Box>
  </Section>;
});

export default NotificationControlsSection;

const UPDATE_USER_NOTIFICATION_CONTROLS = gql`
  mutation UpdateUserNotificationControls($input:UpdateUserInput!) {
    updateUser(input: $input){
      user{
        id
        ...UserNotificationControls
      }
    }
  }
  ${USER_NOTIFICATION_CONTROLS_FRAGMENT}
`;
