import React                   from "react";
import { FC }                  from "react";
import { useEffect }           from "react";
import { Stack }               from "@relcu/rc";
import { classNames }          from "@relcu/ui";
import { useConstant }         from "@relcu/ui";
import { usePhone }            from "../../usePhone";
import { HybridCall }          from "./HybridCall/HybridCall";
import { HybridCallCollapsed } from "./HybridCall/HybridCallCollapsed";
import "./call.css";

export const Call: FC<{ collapsed: boolean }> = React.memo(function Call(props) {
  const { active, advancedPhone, hybridCalls } = usePhone();
  const classes = classNames(
    "call-helper"
  );
  const isSystemCall = active?.to.includes("system");
  const alertUser = useConstant(() => (e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  return (
    <>
      {
        !props.collapsed ?
          <Stack
            justifyContent={"space-between"}
            direction={"column"}
            className={classes}
            spacing={32}
            alignItems={"center"}
            style={{ alignSelf: "stretch" }}
            childrenRenderMode={"clone"}>
            <HybridCall/>
          </Stack>
          :
          !isSystemCall &&
          <>
            {
              !props.collapsed &&
              <Stack.Item basis={32}/>
            }
            {
              hybridCalls?.myCall &&
              <Stack
                childrenRenderMode={"clone"}>
                <HybridCallCollapsed advancedPhone={advancedPhone}/>
              </Stack>
            }
          </>
      }
    </>
  );
});
