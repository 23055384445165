import React                    from "react";
import { useContext }           from "react";
import { useCallback }          from "react";
import { useReactiveVar }       from "@apollo/client";
import { useForm }              from "@relcu/form";
import { Checkbox }             from "@relcu/ui";
import { MenuItem }             from "@relcu/ui";
import { FontIcon }             from "@relcu/ui";
import { classNames }           from "@relcu/ui";
import { CommonClasses }        from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { Where }                from "@relcu/ui";
import { FormSummary }          from "@relcu/ui";
import { Summary }              from "@relcu/ui";
import { CurrencyField }        from "@relcu/ui";
import { NumberField }          from "@relcu/ui";
import { Accordion }            from "@relcu/ui";
import { daysOfaYear }          from "../../../../../../utils/helpers";
import { transformNameToLabel } from "../../../../../../utils/helpers";
import { MoreFees }             from "../MoreFees";
import { PersistContext }       from "../PersistProvider";
import { RateClasses }          from "../Rate/RateClasses";
import { RateContext }          from "../Rate/RateProvider";
import { RateSelectBody }       from "../Rate/RateSelect";
import { WHEN_IS_PURCHASE }     from "../RateConditions";
import { PrepaidCostsSum }      from "./PrepaidCostsSum";

export const PrepaidCosts = React.memo(function PrepaidCosts(props) {
  const { updateTaxInsurance, updateInsurance } = useContext(RateContext);
  const form = useForm();
  const { fees: { prepaymentFeesVar, togglePrepaymentFees } } = useContext(PersistContext);
  const prepaymentFees = useReactiveVar(prepaymentFeesVar);
  const validateMonth = useCallback((value) => {
    if (value < 0 || value > 12) {
      return true;
    }
  }, []);

  const validateDay = useCallback((value) => {
    if (value < 0 || value > daysOfaYear(new Date().getFullYear())) {
      return true;
    }
  }, []);

  return <Accordion
    className={RateClasses.RateSelect}
    collapse={false}
    openClass={RateClasses.RateSelectOpen}
    flex={1}
    header={(open) =>
      <Box container alignItems={"center"} gap={"XS"} flex={1} className={classNames(RateClasses.RateSelectHeader, {
        [ RateClasses.RateSelectExtended ]: open
      })}>
        <FontIcon
          className={classNames(CommonClasses.GrayIcon, {
            [ CommonClasses.PrimaryIcon ]: open
          })}
          alignSelf={"center"}
          type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
        <p className={RateClasses.RateSelectTitle} style={{ flexGrow: 1 }}>
          Prepaid costs
        </p>
        <MoreFees>
          <MenuItem alignItems={"stretch"}>
            <Checkbox
              style={{ alignSelf: "center" }}
              leftMessageSpace={false}
              onChange={() => togglePrepaymentFees("lockInFee")}
              alignSelf={"stretch"}
              checked={prepaymentFees.includes("lockInFee")}
              placeholder={"Lock in fee"}/>
          </MenuItem>
        </MoreFees>
        <p className={RateClasses.RateSelectSum}>
          <PrepaidCostsSum/>
        </p>
        {/*<Button icon={open ? "keyboard_arrow_up" : "keyboard_arrow_down"} color={ButtonColors.Gray} onlyIcon size={ButtonSizes.Small}/>*/}

      </Box>
    }>
    <RateSelectBody divider direction={"row"} wrap={"wrap"}>
      <Where conditions={WHEN_IS_PURCHASE}>
        <CurrencyField precision={2}
                       leaveStateSpace={false}
                       view={"write"}
                       flexBasis={"calc(50% - ( 16px / 2 ))"}
                       name={"yearlyInsurance"}
                       onBlur={() => updateInsurance(form.getFieldState("yearlyInsurance").value, form)}
                       label="Property insurance (yearly)"/>
      </Where>
      <Summary flexBasis={"calc(50% - ( 16px / 2 ))"} title="Property insurance (prepaid)">
        <NumberField view={"write"} validate={validateMonth} leaveStateSpace={false} flexBasis={"52px"}
                     name={"insurancePrepaymentMonths"}
                     message={"Month"}/>
        <p>*</p>
        <CurrencyField
          view={"write"}
          precision={2}
          leaveStateSpace={false}
          flexBasis={"105px"}
          name={"monthlyInsurance"}
          onBlur={() => updateTaxInsurance("monthlyInsurance", form.getFieldState("monthlyInsurance").value, form)}
          message={"Amount"}/>
        <p>=</p>
        <FormSummary
          fields={[
            { name: "monthlyInsurance" },
            { name: "insurancePrepaymentMonths" }
          ]}
          operator={"multiply"}
        >
          {(value) => (
            <Summary.Value icon="attach_money" flexBasis={"105px"} value={value}/>
          )}
        </FormSummary>
      </Summary>
      <Summary flexBasis={"calc(50% - ( 16px / 2 ))"} title="Property tax (prepaid)">
        <NumberField view={"write"} validate={validateMonth} leaveStateSpace={false} flexBasis={"52px"}
                     name={"taxPrepaymentMonths"}
                     message={"Month"}/>
        <p>*</p>
        <CurrencyField
          view={"write"}
          precision={2}
          leaveStateSpace={false}
          flexBasis={"105px"}
          name={"monthlyTax"}
          onBlur={() => updateTaxInsurance("monthlyTax", form.getFieldState("monthlyTax").value, form)}
          message={"Amount"}/>
        <p>=</p>
        <FormSummary
          fields={[
            { name: "monthlyTax" },
            { name: "taxPrepaymentMonths" }
          ]}
          operator={"multiply"}
        >
          {(value) => <Summary.Value icon="attach_money" value={value} flexBasis={"105px"}/>}
        </FormSummary>
      </Summary>
      <Summary flexBasis={"calc(50% - ( 16px / 2 ))"} title="Prepaid interest">
        <NumberField view={"write"} validate={validateDay} leaveStateSpace={false} flexBasis={"52px"}
                     name={"interestPrepaymentDays"}
                     message={"Day"}/>
        <p>*</p>
        <CurrencyField view={"write"} precision={2} leaveStateSpace={false} disabled flexBasis={"105px"}
                       name={"interestPrepayment"}
                       message={"Amount"}/>
        <p>=</p>
        <FormSummary
          fields={[
            { name: "interestPrepayment" },
            { name: "interestPrepaymentDays" }
          ]}
          operator={"multiply"}
        >
          {(value) => <Summary.Value icon="attach_money" value={value} flexBasis={"105px"}/>}
        </FormSummary>
      </Summary>
      {
        prepaymentFees.map((name) => <CurrencyField
          view={"write"}
          key={name}
          precision={2}
          flexBasis={"calc(50% - ( 16px / 2 ))"}
          name={name}
          label={transformNameToLabel(name)}
        />)
      }
    </RateSelectBody>

  </Accordion>;
});

export default PrepaidCosts;
