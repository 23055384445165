import React                    from "react";
import { FC }                   from "react";
import { useContext }           from "react";
import { useForm }              from "@relcu/final-form";
import { FormSpy }              from "@relcu/final-form";
import { Stack }                from "@relcu/rc";
import { FlyerRenderer }        from "../../../../../ContentVisualizer/FlyerRenderer";
import { Preview }              from "../../../../../Generation";
import { FlyerContext }         from "../Content";
import { FlyerPreviewSettings } from "./FlyerPreviewSettings";

export const FlyerPreview: FC = React.memo(function FlyerPreview(props) {
  const { preview } = useContext(FlyerContext);
  const form = useForm();

  return <Preview style={{ display: !preview ? "none" : "flex" }}>
    <Preview.RendererContainer>
      <Stack.Item style={{ padding: 16, flex: 1, overflowY: "scroll", width: "100%" }}>
        <FormSpy subscription={{ values: true }}>
          {
            ({ values }) => {
              return <FlyerRenderer
                onError={(error) => {
                  form.mutators.setFieldState(error.message, "content");
                }}
                onSuccess={() => {
                  form.mutators.setFieldState(null, "content");
                }}
                template={values.content ?? " "}
                ids={{
                  userId: values.previewSettings.user?.objectId,
                  scopeId: values.previewSettings.scope?.id
                }}
              />;
            }
          }
        </FormSpy>
      </Stack.Item>
    </Preview.RendererContainer>
    {
      preview &&
      <FlyerPreviewSettings/>
    }
  </Preview>;
});
