import React             from "react";
import { Stack }         from "../index";
import { StackProps }    from "../Stack";
import { useClassNames } from "../utils";

export interface ToolbarProps extends StackProps {
  size? : "sm" | "md"
}

const Toolbar = React.forwardRef((props: ToolbarProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, className, size = "md", classPrefix = "toolbar", ...rest } = props;
  const { withClassPrefix, merge } = useClassNames(classPrefix);
  const classes = merge(className, withClassPrefix(size));

  return (
    <Stack {...rest} className={classes}>
      {children}
    </Stack>
  );
});

Toolbar.displayName = "Toolbar";

export default Toolbar;
