import React                        from "react";
import { RsRefForwardingComponent } from "../@types/common";
import { TypeAttributes }           from "../@types/common";
import { WithAsProps }              from "../@types/common";
import { useClassNames }            from "../utils";

export interface TypographyProps extends WithAsProps, React.HTMLAttributes<HTMLElement> {
  /** A typo can have different sizes */
  variant?: TypeAttributes.Typography;

  /** A typo can have different colors */
  color?: TypeAttributes.TypographyColor;

  /** A typo can have different weights */
  weights?: TypeAttributes.TypographyWeight;

  lineHeight?: TypeAttributes.TypographyLineHeight;

  disabled?: boolean;
}

export const Typography: RsRefForwardingComponent<"p", TypographyProps> = React.forwardRef((props: TypographyProps, ref) => {
  const {
    as,
    className,
    children,
    classPrefix = "typography",
    variant,
    weights,
    color,
    disabled,
    lineHeight,
    ...rest
  } = props;

  const { withClassPrefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix(variant, color, weights, lineHeight, { disabled })
  );

  const Component = as || "p";
  const role = rest.role || (Component !== "p" ? "paragraph" : undefined);

  return (
    <Component
      {...rest}
      role={role}
      ref={ref}
      disabled={disabled}
      aria-disabled={disabled}
      className={classes}>
      {children}
    </Component>
  );
});
