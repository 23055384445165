import React                  from "react";
import { FC }                 from "react";
import { SetStateAction }     from "react";
import { Dispatch }           from "react";
import { Button }             from "@relcu/ui";
import { ButtonVariants }     from "@relcu/ui";
import { MailDomain }         from "../../__types__/MailDomain";
import { useMsConnectButton } from "./useMsConnectButton";
import MicrosoftSvgUrl        from "../../../assets/microsoft.svg";

export interface MsConnectButtonProps {
  domain: MailDomain;
  deviceId?: string;
  onError: Dispatch<SetStateAction<any>>;
  onSuccess?: (response) => void;
}

export const MsConnectButton: FC<MsConnectButtonProps> = React.memo(function MsConnectButton(props) {
  const { handleLogin } = useMsConnectButton(props);

  return (
    <Button
      onClick={() => {
        props.onError(null);
        handleLogin();
      }}
      disabled={props.domain.disabled}
      variant={ButtonVariants.Outline}
      icon={<img src={MicrosoftSvgUrl} alt="Authorize in Microsoft"/>}>
      CONNECT
    </Button>
  );
});
