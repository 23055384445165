import React                          from "react";
import { FC }                         from "react";
import { useContext }                 from "react";
import { useNavigate }                from "@relcu/react-router";
import { Field }                      from "@relcu/form";
import { FormSpy }                    from "@relcu/form";
import { Form }                       from "@relcu/form";
import { ButtonVariants }             from "@relcu/ui";
import { ModalBody }                  from "@relcu/ui";
import { ModalFooter }                from "@relcu/ui";
import { Box }                        from "@relcu/ui";
import { ToggleSwitch }               from "@relcu/ui";
import { Button }                     from "@relcu/ui";
import { defaultMutators }            from "@relcu/ui";
import { UndoField }                  from "@relcu/ui";
import { TextField }                  from "@relcu/ui";
import { Where }                      from "@relcu/ui";
import { DateField }                  from "@relcu/ui";
import { ContactDialogContext }       from "../../ContactDialog";
import { ContactDialogClasses }       from "../../ContactDialogClasses";
import { WHEN_SHOW_MORE_IS_TRUE }     from "../../ContactDialogConditions";
import ContactTypesField              from "../../Field/ContactTypesField";
import { EmailsDuplicateField }       from "../../Field/EmailsDuplicateFiled";
import { PhoneNumbersDuplicateField } from "../../Field/PhoneNumbersDuplicateField";
import { RowTitle }                   from "../../Row";
import { Row }                        from "../../Row";
import HeaderRow                      from "../../Row";
import { validate }                   from "./useResolveDuplicates";
import { useResolveDuplicates }       from "./useResolveDuplicates";

export const DuplicationForm: FC<any> = React.memo(function ResolveDuplication(props) {
  const navigate = useNavigate();
  const { resolveContactInitialValues, action, handleSubmitErrors } = useContext(ContactDialogContext);
  const { showMore, setShowMore, handleMerge, handleResolveSubmit, modifiedExistingRef } = useResolveDuplicates();
  return (
    <Form
      keepDirtyOnReinitialize={true}
      validate={validate}
      mutators={defaultMutators}
      onSubmit={handleResolveSubmit}
      initialValues={resolveContactInitialValues}>
      {({ handleSubmit, form }) =>
        <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
          <Field name={"exists.id"} type="hidden" component="input"/>
          <Field name={"temporary.id"} type="hidden" component="input"/>
          <Field name={"exists.objectId"} type="hidden" component="input"/>
          <Field name={"temporary.objectId"} type="hidden" component="input"/>
          <Field name={"__typename"} type="hidden" component="input"/>
          <ModalBody container direction={"column"} gap={"XS"} className={ContactDialogClasses.Body}>
            <Box>
              <ToggleSwitch label="Merge contact" checked={false} onChange={() => handleMerge(form)}/>
            </Box>
            <Box container direction={"column"} flex={1}>
              <Box container flex={1} alignSelf="stretch">
                <HeaderRow flexBasis={"20%"}/>
                <HeaderRow flexBasis={"40%"} title="Existing contact"/>
                <HeaderRow flexBasis={"40%"} title={`${action === "save" ? "Existing" : "New"} contact`}/>
              </Box>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"}>
                  <RowTitle>First name</RowTitle>
                </Row>
                <Row flexBasis={"40%"}>
                  <UndoField name={"exists.firstName"} flex={1} alignItems={"center"}>
                    <TextField
                      placeholder={"Enter first name"}
                      name={"exists.firstName"}
                      flex={1}
                    />
                  </UndoField>
                </Row>
                <Row flexBasis={"40%"}>
                  <UndoField name={"temporary.firstName"} flex={1}>
                    <TextField
                      placeholder={"Enter first name"}
                      name={"temporary.firstName"}
                      flex={1}
                    />
                  </UndoField>
                </Row>
              </Box>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"}>
                  <RowTitle>Middle name</RowTitle>
                </Row>
                <Row flexBasis={"40%"}>
                  <UndoField name={"exists.middleName"} flex={1} alignItems={"center"}>
                    <TextField
                      placeholder={"Enter Middle name"}
                      name={"exists.middleName"}
                      flex={1}
                    />
                  </UndoField>
                </Row>
                <Row flexBasis={"40%"}>
                  <UndoField name={"temporary.middleName"} flex={1} alignItems={"center"}>
                    <TextField
                      placeholder={"Enter middle name"}
                      name={"temporary.middleName"}
                      flex={1}/>
                  </UndoField>
                </Row>
              </Box>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"}>
                  <RowTitle>Last name</RowTitle>
                </Row>
                <Row flexBasis={"40%"}>
                  <UndoField name={"exists.lastName"} flex={1} alignItems={"center"}>
                    <TextField
                      placeholder={"Enter last name"}
                      name={"exists.lastName"}
                      flex={1}
                    />
                  </UndoField>
                </Row>
                <Row flexBasis={"40%"}>
                  <UndoField name={"temporary.lastName"} flex={1} alignItems={"center"}>
                    <TextField
                      placeholder={"Enter last name"}
                      name={"temporary.lastName"}
                      flex={1}/>
                  </UndoField>
                </Row>
              </Box>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"}>
                  <RowTitle>Phone(s)</RowTitle>
                </Row>
                <Row flexBasis={"40%"} style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <PhoneNumbersDuplicateField
                    placeholder={"Enter phone number"}
                    name={"exists.phones"}
                  />
                </Row>
                <Row flexBasis={"40%"} style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <PhoneNumbersDuplicateField
                    placeholder={"Enter phone number"}
                    name={"temporary.phones"}
                  />
                </Row>
              </Box>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"} alignItems={"start"}>
                  <RowTitle>Emails(s)</RowTitle>
                </Row>
                <Row flexBasis={"40%"} style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <EmailsDuplicateField
                    placeholder={"Enter email address"}
                    name={"exists.emails"}
                  />
                </Row>
                <Row flexBasis={"40%"} style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <EmailsDuplicateField
                    placeholder={"Enter email address"}
                    name={"temporary.emails"}
                  />
                </Row>
              </Box>
              <Where conditions={WHEN_SHOW_MORE_IS_TRUE} source={{ showMore }}>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>Contact type</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"} style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                       alignItems={"start"}>
                    <ContactTypesField
                      placeholder={"Select type"}
                      name={"exists.types"}
                    />
                  </Row>
                  <Row flexBasis={"40%"} style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                       alignItems={"start"}>
                    <ContactTypesField
                      placeholder={"Select type"}
                      name={"temporary.types"}
                    />
                  </Row>
                </Box>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>Birthday</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <UndoField name={"exists.birthday"} flex={1} alignItems={"center"}>
                      <DateField
                        flex={1}
                        placeholder={"Enter birthday"}
                        name={"exists.birthday"}
                      />
                    </UndoField>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <UndoField name={"temporary.birthday"} flex={1} alignItems={"center"}>
                      <DateField
                        flex={1}
                        placeholder={"Enter birthday"}
                        name={"temporary.birthday"}
                      />
                    </UndoField>
                  </Row>
                </Box>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>Company</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <UndoField name={"exists.company"} flex={1}>
                      <TextField
                        name={"exists.company"}
                        placeholder={"Enter company name"}
                        flex={1}
                      />
                    </UndoField>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <UndoField name={"temporary.company"} flex={1}>
                      <TextField
                        name={"temporary.company"}
                        placeholder={"Enter company name"}
                        flex={1}
                      />
                    </UndoField>
                  </Row>
                </Box>
              </Where>
            </Box>
          </ModalBody>
          <Button variant={ButtonVariants.Ghost} alignSelf={"center"}
                  onClick={() => setShowMore((prev) => {
                    return !prev;
                  })}>Show {showMore ? "less" : "more"}</Button>
          <ModalFooter>
            <Button type="button" variant={ButtonVariants.Outline}
                    onClick={() => {
                      return navigate(-1);
                    }}>CANCEL</Button>
            <FormSpy
              subscription={{ submitting: true, errors: true }}>
              {
                ({ submitting, errors }) => {
                  return <Button type="submit"
                                 disabled={submitting || !!Object.keys(errors).length}>{`${action === "save" ? "SAVE" : "CREATE"}`} AND
                    ADD</Button>;
                }
              }
            </FormSpy>
            <FormSpy subscription={{ submitError: true, submitErrors: true }}
                     onChange={({ submitError, submitErrors }) => handleSubmitErrors(submitError || submitErrors)}/>
            <FormSpy subscription={{ dirtyFields: true }}
                     onChange={({ dirtyFields }) => {
                       modifiedExistingRef.current = Object.entries(dirtyFields).some(([key, value]) => /^exists/.test(key) && value == true);
                     }}/>
          </ModalFooter>
        </form>
      }
    </Form>
  );
});
