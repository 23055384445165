import { MutableRefObject }   from "react";
import { useCallback }        from "react";
import React                  from "react";
import { FC }                 from "react";
import { useImperativeState } from "../../../../..";
import { NoteItemProps }      from "../../../../..";
import { classNames }         from "../../../../..";
import { FontIcon }           from "../../../../..";
import { Keys }               from "../../../../..";
import { RelayPageInfo }        from "../../../../../hooks/useRelayPagination";
import { useRelayPagination } from "../../../../../hooks/useRelayPagination";
import { Box }                from "../../../../Box";
import { EmptyList }          from "../../../../EmptyList";
import { TextArea }           from "../../../../Input/TextArea";
import { CircularLoader }     from "../../../../Loader";
import { NoteItem }           from "../../../../Note";

export interface NotesViewProps {
  scrollContainerRef: MutableRefObject<HTMLDivElement>
  editable: boolean
  count: number,
  pageInfo: RelayPageInfo
  edges: {
    node: Omit<NoteItemProps, "date"> & { createdAt: string }
    cursor: string
  }[]
  content?: string
  onContentChange?: (text) => void
  onLoadMore: () => Promise<any>
  send: (text) => Promise<any>,
  cursor?: string,
  // refetch: (scrollContainer: HTMLDivElement) => Promise<any>
}
export const NotesView: FC<NotesViewProps> = React.memo(function NotesView(props) {
  const { pageInfo, count, edges, editable, onLoadMore, content = "", onContentChange } = props;
  const { elements, loaderRef, scrollContainerRef, cursorRef } = useRelayPagination({ pageInfo, onLoadMore });
  props.scrollContainerRef.current = scrollContainerRef.current;
  if (props.cursor) {
    cursorRef.current = props.cursor;
  }
  const [text, setText] = useImperativeState(content, onContentChange);
  const send = useCallback(async () => {
    await props.send(text);
    setText("");
  }, [text, setText]);
  return (
    <Box container flex={1} direction={"column"} className={NotesViewClasses.NotesView}>
      <Box container direction={"column"} flexGrow={1} className={NotesViewClasses.NotesViewItems}
           ref={scrollContainerRef}>
        <Box flex={"1 1 auto"} justify={"center"}>{
          pageInfo?.hasPreviousPage &&
          <CircularLoader alignSelf={"center"} ref={loaderRef}/>
        }
        </Box>
        {
          count ?
            edges?.map(({ node, cursor }, index) => (
              <NoteItem
                key={index}
                text={node.text}
                date={node.createdAt}
                owner={node.owner}
                ref={e => e && elements.set(cursor, e)}
              />
            )) :
            <Box flex={"1 1 auto"} justify={"center"}>
              <EmptyList icon={"rc_note"} content={"Click to \"Add note...\" below to get started"} title={"No notes"}
                         alignSelf={"center"}/>
            </Box>
        }
      </Box>
      <TextArea
        maxLength={3000}
        showCharLimit
        name={"content"}
        value={text}
        placeholder={"Add note..."}
        onChange={setText}
        disabled={!editable}
        onKeyPress={async (e) => {
          if (e.key == Keys.Enter && !e.shiftKey && text?.trim()) {
            await send();
          }
        }}
      />
      <FontIcon type="send" className={classNames(NotesViewClasses.NoteViewSubmit, {
        [ NotesViewClasses.Disabled ]: !text?.trim() || !editable
      })} onClick={text?.trim() ? send : null}/>
    </Box>
  );
});
export const enum NotesViewClasses {
  NotesView = "notes-view",
  NotesViewItems = "notes-view-items",
  NoteViewSubmit = "note-view-submit",
  Disabled = "disabled"
}
