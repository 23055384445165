import React                        from "react";
import { RsRefForwardingComponent } from "../@types/common";
import Button                       from "../Button";
import { ButtonProps }              from "../Button";
import { useClassNames }            from "../index";

export interface MentionButtonProps extends ButtonProps {
  direction: "top" | "bottom";
  width?: string | number;
  top?: number;
  bottom?: number;
}

export const MentionButton: RsRefForwardingComponent<"button", MentionButtonProps> = React.forwardRef((props: MentionButtonProps, ref) => {
  const { direction, width, className, style, ...p } = props;
  const { merge, withClassPrefix } = useClassNames("button-mention", "rc");
  const classes = merge(className, withClassPrefix({
    "top": direction == "top",
    "bottom": direction == "bottom"
  }));

  return <Button
    style={{ ...style, width }}
    className={classes}
    size={"xs"}
    ref={ref}
    {...p}
  />;
});
