import { PropsWithChildren } from "react";
import React                 from "react";
import { Box }               from "../../..";
import { CalendarClasses }   from "../CalendarClasses";

export function MonthContent(props: PropsWithChildren<{}>) {
  return (
    <Box container direction={"column"} className={CalendarClasses.Container}>
      {props.children}
    </Box>
  );
}
