import React           from "react";
import { FC }          from "react";
import { Box }         from "../../../../Box";
import { ChipsColors } from "../../../../Chips";
import { ChipsProps }  from "../../../../Chips";
import { ChipsSizes }  from "../../../../Chips";
import { Chips }       from "../../../../Chips";
import { IconType }    from "../../../../Icon";
import { FontIcon }    from "../../../../Icon";

interface Folder extends Omit<ChipsProps, "label"> {
  name: string,
  icon: IconType
}

export interface EmailInfoFoldersProps {
  folders: Folder[];
}

export const EmailInfoFolders: FC<EmailInfoFoldersProps> = React.memo(function EmailInfoFolders(props) {
  const { folders } = props;

  return (
    folders.length > 0 ?
      <Box flex={1} container gap={"XXXS"} wrap={"wrap"}>
        {
          folders.map(({ name, icon, ...rest }, index) => {
            return (
              <Chips
                key={index}
                thumbnail={<FontIcon type={icon} style={{fontSize: "var(--typography-button-font-size)"}}/>}
                color={ChipsColors.Grey}
                label={name}
                length={15}
                size={ChipsSizes.Small}
                {...rest}
              />
            );
          })
        }
      </Box>
      : null
  );
});