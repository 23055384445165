import { useReactiveVar }           from "@apollo/client";
import { useContext }               from "react";
import { ReactNode }                from "react";
import React                        from "react";
import { FC }                       from "react";
import { FormSummary }              from "@relcu/ui";
import { FormSpy }                  from "@relcu/form";
import { format }                   from "../../../../../../utils/helpers";
import { PersistContext }           from "../PersistProvider";
import { PREPAID_COSTS_SUM_FIELDS } from "../RateConditions";

type FunctionOrReactNode = ReactNode | ((value: string | number) => ReactNode)
export const PrepaidCostsSum: FC<{ children?: FunctionOrReactNode, prefix?: string }> = React.memo(function PrepaidCostsSum(props) {
  const { children, prefix = "$" } = props;
  const { fees:{prepaymentFeesVar} } = useContext(PersistContext);
  const prepaymentFees = useReactiveVar(prepaymentFeesVar);

  return <FormSpy subscription={{ values: true }}>
    {({ values }) =>
      <FormSummary
        data={{
          propertyInsurancePrepaid: (values.insurancePrepaymentMonths * values.monthlyInsurance),
          propertyTaxPrepaid: (values.taxPrepaymentMonths * values.monthlyTax),
          prepaidInterest: (values.interestPrepaymentDays * values.interestPrepayment),
          prepaymentFees
        }}
        fields={PREPAID_COSTS_SUM_FIELDS}>
        {
          (value) => {
            return children instanceof Function
              ?
              <>{children(value)}</>
              :
              <>{prefix || ""}{format(value, 2)}</>;
          }
        }
      </FormSummary>
    }
  </FormSpy>;
});
