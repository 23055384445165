import { useMutation }           from "@apollo/client";
import { useForm }               from "@relcu/final-form";
import { Field }                 from "@relcu/final-form";
import { Button }                from "@relcu/rc";
import { Typography }            from "@relcu/rc";
import { Stack }                 from "@relcu/rc";
import { useMemo }               from "react";
import React                     from "react";
import { MailMessage }           from "../../../../../graph/__types__/MailMessage";
import { formatBytes }           from "../../../../../utils/helpers";
import { RemoveFileVariables }   from "../../../../Message/Messanger/__types__/RemoveFile";
import { RemoveFile }            from "../../../../Message/Messanger/__types__/RemoveFile";
import { REMOVE_FILE }           from "../../../../Message/Messanger/Messenger";
import { Attachment }            from "./Attachment";
import { AttachmentTypes }       from "./UploadButton";
import { useAttachmentUploader } from "./useAttachmentUploader";
import "./attachmentsUpload.css";

export const Attachments = React.memo<{
  draftReplyToMessage?: MailMessage
  removeAble?: boolean
}>(function Attachments({ removeAble=true,draftReplyToMessage }) {
    const { error, fields, keysRef } = useAttachmentUploader({ name: "attachments" });
    const form = useForm();
    const sum = !fields.length ? 0
      : fields.value.reduce((accu, { attachment }) => accu + (attachment?.size ?? 0), 0);
    const [removeFile] = useMutation<RemoveFile, RemoveFileVariables>(REMOVE_FILE);
    const replyAttachments = useMemo(() => {
      return Object(draftReplyToMessage?.attachments.reduce((obj, curr) => {
        if (curr.file?.id) {
          obj[ curr.file.id ] = curr;
        }
        return obj;
      }, {}));
    }, [draftReplyToMessage]);

    const removeAll = () => {
      fields.value.filter(v => !replyAttachments[ v.attachment.file?.id ]).map(({ attachment }) => {
        removeFile({ variables: { id: attachment.id } }).catch(console.error);
      });
      form.change("attachments", []);
    };

    const uploading = useMemo(() => {
      const loading = fields.value?.find(({ attachment }) => attachment instanceof File);
      return loading;
    }, [fields.value]);

    return <>
      {!!fields.length && <Stack className={"attachments-section"}>
        <Field name={"attachments"}>
          {({ input: { value, onChange } }) => (
            <div>
              <Stack spacing={4} className={"attachments-stack"}>
                {
                  fields.map((name, index) => {
                    return <Stack.Item key={name}>
                      <Attachment removeAble={removeAble} name={`${name}.attachment`} key={name}
                                  shouldRemove={file => !replyAttachments[ file.id ]}
                                  onDelete={() => keysRef.current.splice(index, 1) && fields.remove(index)}
                                  objectTypes={AttachmentTypes}/>
                    </Stack.Item>;
                  })
                }
              </Stack>
              {
                !!fields.length && <Stack className={"attachments-summary"}>
                  <Typography variant={"small12"} color={"tertiary"}>
                    {fields.length} file, ({formatBytes(sum)})
                  </Typography>
                  <Button size={"xs"} appearance={"text"} disabled={uploading || !removeAble} onClick={removeAll}>Remove all</Button>
                </Stack>
              }
              {
                typeof error === "string" && <p className={"attachments-error-message"}>{error}</p>
              }
            </div>
          )}
        </Field>
      </Stack>}
    </>;
  }
);
