import React               from "react";
import { FC }              from "react";
import { useReactiveVar }  from "@apollo/client";
import { Navigate }        from "@relcu/react-router";
import { Outlet }             from "@relcu/react-router";
import { isAuthenticatedVar } from "../../reactiveVars";

export const Viewer: FC = React.memo(function ViewerGate() {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  if (!isAuthenticated) {
    return <Navigate replace={true} to="/login"/>;
  }
  return <Outlet/>;
});
