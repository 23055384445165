import React                    from "react";
import { FC }                   from "react";
import { useCallback }          from "react";
import { useMemo }              from "react";
import { Link }                 from "@relcu/react-router";
import { Tooltip }              from "@relcu/rc";
import { SelectPicker }         from "@relcu/rc";
import { Whisper }              from "@relcu/rc";
import { Icon }                 from "@relcu/rc";
import { IconButton }           from "@relcu/rc";
import { Stack }                from "@relcu/rc";
import { confirmModal }         from "@relcu/ui";
import { transformNameToLabel } from "@relcu/ui";
import { toFirstLower }         from "@relcu/ui";
import { getObjectPath }        from "../../utils/layoutUtils";
import { getField }             from "../../utils/schemaUtils";
import { usePointerListField }  from "../Layout/Field/PointerListField/usePointerListField";
import "./scope-connection-rc.css";

export interface LeadConnectionRCProps {
  lead?: any,
  scope?: any,
  type?: string
  typename: string
  onClick(item)
  onRemove()
}

export const ScopeConnectionRC: FC<LeadConnectionRCProps> = React.memo((props) => {
    const { onClick, scope, onRemove, type, typename } = props;
    const targetClass = useMemo(() => {
      const scopeField = getField(typename, "scope");
      return scopeField.targetClass;
    }, [typename]);
    const { options: scopes, setQ } = usePointerListField({ ...props, targetClass });

    const data = useMemo(() => {
      const data = [];
      scopes.forEach(scope => {
        if (scope.options) {
          scope.options.forEach(option => {
            data.push({ ...option, classLabel: scope.label });
          });
        } else {
          data.push(scope);
        }
      });
      return data;
    }, [scopes]);

    const apply = useCallback((item) => {
      onClick(item);
    }, []);

    const removeLink = async () => {
      const scopeType = toFirstLower(transformNameToLabel(scope.__typename ?? "Lead"));
      try {
        await confirmModal({
          title: "Unlink scope",
          subTitle: `You are unlinking ${scopeType} relation.`,
          content: `You can restore scope of conversation by clicking on "Relink" button.`,
          label: "UNLINK"
        });
        await onRemove();
      } catch (e) {
      }
    };
    const handleSelect = (node, item) => {
      apply(item);
    };

    return (
      type !== "bulk" &&
      <Stack
        spacing={4}
        alignItems={"center"}
        className={"lead-connection-rc"}>
        {
          scope ?
            <Stack>
              <Link to={`/${getObjectPath(scope)}/${scope.objectId}/details`}
                    className={"lead-connection-rc__link"} style={{ whiteSpace: "nowrap" }}>
                <Icon type={"launch"}/>
                {scope?.objectName}
              </Link>
              <Whisper
                placement="bottom"
                controlId="control-id-focus"
                trigger="hover"
                speaker={<Tooltip>Unlink Scope</Tooltip>}>
                <IconButton circle appearance={"subtle"} onClick={removeLink}
                            icon={<Icon className={"header-icon"} type={"link_off"}/>}/>
              </Whisper>
            </Stack>
            : null
        }
        <SelectPicker
          onSearch={(searchKeyword) => setQ(searchKeyword)}
          placement={"bottomEnd"}
          menuStyle={{ width: 300 }}
          groupBy="classLabel"
          data={data}
          valueKey={"objectId"}
          labelKey={"objectName"}
          toggleAs={RenderToggle}
          onSelect={handleSelect}
        />
      </Stack>
    );
  }
);

const RenderToggle: FC = function () {
  return <IconButton circle appearance={"subtle"} style={{ background: "transparent" }}
                     icon={<Icon className={"header-icon"} type={"link"}/>}/>;
};
