import React                 from "react";
import { classNames }        from "../../..";
import { GlobalClasses }     from "../../../constants/GlobalClasses";
import { BoxComponentProps } from "../../Box";
import { FontIcon }          from "../../Icon";
import { IconType }          from "../../Icon";

export enum ListItemIconColors {
  Error = "error",
  Dark = "dark",
  Secondary = "secondary"
}

export interface ListItemIconProps extends BoxComponentProps {
  type: IconType,
  color?: ListItemIconColors
}

export function ListItemIcon(props: ListItemIconProps) {
  const { className, children, type, color, ...p } = props;
  const classes = classNames(ListItemIconClasses.ListItemIcon, {
    [ ListItemIconClasses.ListItemIconError ]: color == ListItemIconColors.Error,
    [ ListItemIconClasses.ListItemIconSecondary ]: color == ListItemIconColors.Secondary,
    [ ListItemIconClasses.ListItemIconDark ]: color == ListItemIconColors.Dark
  }, className);
  return <FontIcon type={type} className={classes} {...p}/>;
}
export enum ListItemIconClasses {
  ListItemIcon = "list-item-icon",
  ListItemIconError = "error",
  ListItemIconSecondary = "secondary",
  ListItemIconDark = "dark"
}
