import React                from "react";
import { FC }               from "react";
import { useCallback }      from "react";
import { useBoxProps }      from "../../Box";
import { Box }              from "../../Box";
import { BaseInputClasses } from "../../Input/BaseInput";
import { Checkbox }         from "../../Input/Checkbox";
import { Label }            from "../../Label";
import { DescribedLabel }   from "../../Label/DescribedLabel";
import { useConsumeField }  from "../BaseField";
import { PermissionProps }  from "./PermissionField";

export const PermissionEditField: FC = React.memo(function PermissionEditField(props) {
  const {
    input: { name, value, showWarnings = true, autocomplete = true, onChange, onBlur, onFocus, label, required, message, description, options, ...otherProps }
  } = useConsumeField<PermissionProps>();
  const onToggle = useCallback((permKey) => {
    onChange({ ...value, [ permKey ]: !value[ permKey ] });
  }, [value, onChange]);

  return <Box container gap={"XXXS"} direction={"column"} {...useBoxProps(otherProps)}>
    <Box container
         direction={"row"}
         alignItems={"center"}
         gap={"XXS"}
         className={BaseInputClasses.MainContainer}>
      <Box container direction={"column"} gap={"XXXS"} flex={1} className={BaseInputClasses.InputContainer}>
        {
          label && description &&
          <DescribedLabel description={description}>
            <Label required={required}>{label}</Label>
          </DescribedLabel>
        }
        <Box container direction={"column"} gap={"XXXS"}>
          {
            options.map(option => {
              return <Checkbox
                disabled={option.name == "share" && value[ "create" ] == false}
                key={option.name}
                leftMessageSpace={false}
                InputProps={{ style: { padding: 0 } }}
                checked={value[ option.name ]}
                onChange={() => onToggle(option.name)}
                placeholder={option.label}/>;
            })
          }
        </Box>
      </Box>
    </Box>
    <Box container className={BaseInputClasses.Message}>
      {message}
    </Box>
  </Box>;
});
