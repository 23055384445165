import React                 from "react";
import { FC }                from "react";
import { FieldSubscription } from "@relcu/form";
import { useField }          from "@relcu/form";
import { useMounted }        from "../../..";

interface FieldSpy {
  name: string
  subscription: FieldSubscription;
  onChange?: (value: any) => void;
}
export const FieldSpy: FC<FieldSpy> = React.memo(function FieldSpy(props) {
  const {name, subscription, onChange, children} = props;
  const { input: { value } } = useField(name, {
    subscription: subscription
  });


  useMounted(() => {
    onChange(value)
  }, [value]);

  return null
});
