import { FC }                 from "react";
import React                  from "react";
import { HTMLAttributes }     from "react";
import { SetStateAction }     from "react";
import { Dispatch }           from "react";
import { SyntheticEvent }     from "react";
import { useRef }             from "react";
import { useEffect }          from "react";
import { useState }           from "react";
import { Tooltip }            from "../../..";
import { InputState }         from "../../..";
import { useImperativeState } from "../../..";
import { FontIcon }           from "../../..";
import { AvatarSizes }        from "../../..";
import { AvatarColors }       from "../../..";
import { Avatar }             from "../../..";
import { classNames }         from "../../..";
import { Label }              from "../../..";
import { Box }                from "../../..";
import { BoxComponentProps }  from "../../..";
import { Alignment }          from "../../../constants/Alignemnt";
import { BaseInputClasses }   from "../BaseInput";
import { BaseInputProps }     from "../BaseInput";

export enum ImageUploadMode {
  Simple = "simple",
  WithPreview = "withPreview",
}

export interface ImageProps extends BoxComponentProps, BaseInputProps {
  value?: any;
  clearable?: boolean;
  readOnly?: boolean;
  message?: string;
  mode?: ImageUploadMode
  acceptedTypes?: string[]
  placeholder?: string;
  onChange?()
}

export const ImageInput: FC<ImageProps> = React.memo(function ImageInput(props) {
  const {
    className,
    children,
    label,
    value,
    clearable,
    disabled,
    readOnly,
    state,
    required,
    message,
    name,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onFocus,
    fullSize,
    halfSize,
    mode,
    acceptedTypes,
    ...p
  } = props;

  const [image, setImage] = useImperativeState(value, onChange);
  const imgRef = useRef(null);

  const classes = classNames(BaseInputClasses.Input, {
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error
  }, ImageClasses.Image, className);

  const handleChange = (e) => {
    if (e.currentTarget.files && e.currentTarget.files[ 0 ]) {

      if (e.currentTarget.files[ 0 ] && e.currentTarget.files[ 0 ].type.includes("image")) {
        setImage(e.currentTarget.files[ 0 ]);
      } else {
        e.currentTarget.value = "";
      }
    }
  };

  return mode == ImageUploadMode.Simple ?
    <ImageInputSimple
      name={name}
      disabled={disabled}
      className={classes}
      readOnly={readOnly}
      acceptedTypes={acceptedTypes}
      handleChange={handleChange} {...p}/> :
    <ImageInputWithPreview {...props} className={classes} image={image} handleChange={handleChange} setImage={setImage}
                           ref={imgRef}/>;
});

ImageInput.defaultProps = {
  mode: ImageUploadMode.WithPreview,
  acceptedTypes: ["*"]
};

interface ImageInputSimpleProps extends HTMLAttributes<HTMLLabelElement & HTMLInputElement> {
  name?: string,
  disabled?: boolean,
  readOnly?: boolean,
  handleChange(e: SyntheticEvent),
  acceptedTypes?: string[]
}

const ImageInputSimple = React.forwardRef(function ImageInputSimple(props: ImageInputSimpleProps, ref: React.MutableRefObject<HTMLInputElement>) {
  const { name, disabled, readOnly, handleChange, className, acceptedTypes, ...p } = props;
  const classes = classNames(ImageClasses.ImageSimple, className);
  return (
    <label className={classes} {...p}>
      <input
        type="file"
        accept={acceptedTypes.join(",")}
        ref={ref}
        name={name}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        onChange={handleChange}
      />
      CHOOSE A FILE
    </label>
  );
});

interface ImageInputWithPreview extends ImageProps {
  image: File,
  handleChange(e: SyntheticEvent),
  setImage: Dispatch<SetStateAction<any>> | ((state: any, event?: SyntheticEvent) => void)
}

const ImageInputWithPreview = React.forwardRef(function ImageInputWithPreview(props: ImageInputWithPreview, ref: React.Ref<HTMLInputElement>) {
  const { className, required, label, image, name, disabled, readOnly, handleChange, setImage, message, halfSize, acceptedTypes, ...p } = props;
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    if (image) {
      let reader = new FileReader();

      reader.onload = function (e) {
        setPreview(e.target.result);
      };

      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  return (
    <Box container className={className} {...p} gap={"XXXS"} direction={"column"}>
      <Box container gap={"XXXS"}>
        <Box container
             direction={"row"}
             alignItems={"center"}
             gap={"XXS"}
             className={BaseInputClasses.MainContainer}>
          <Box container direction={"column"} gap={"XXXS"} flex={1} className={BaseInputClasses.InputContainer}>
            {label && <Label required={required}>{label}</Label>}
            <Box container alignItems={"center"} justify={"space-between"} gap={"XXS"}
                 className={ImageClasses.ImageInfo}>
              <Box container gap={"XXS"} alignItems={"center"}>
                <Avatar icon={(preview) || "person"} alignSelf={"start"} size={AvatarSizes.Small}
                        color={!preview && AvatarColors.Primary}/>
                {image && image.name}
              </Box>
              <label>
                <input
                  type="file"
                  accept={acceptedTypes.join(",")}
                  ref={ref}
                  name={name}
                  disabled={disabled || readOnly}
                  readOnly={readOnly}
                  onChange={handleChange}
                />
                <FontIcon type={"attachment"}/>
              </label>
            </Box>
          </Box>
        </Box>
        <Box container direction={"column"} justify={"end"} className={ImageClasses.ImageActionBar}>
          {
            image &&
            <Tooltip title={"Remove"} alignment={Alignment.Bottom}>
              <FontIcon type={"remove"} onClick={() => {
                setImage(null);
                (ref as any).current.value = "";
              }}/>
            </Tooltip>
          }
        </Box>
      </Box>
      <Box container className={BaseInputClasses.Message}>
        {message}
      </Box>
    </Box>
  );
});

export enum ImageClasses {
  Image = "image",
  ImageActionBar = "image__action-bar",
  ImageInfo = "image__info",
  ImageSimple = "image__input",
}
