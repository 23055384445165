import { Container } from "@relcu/rc";
import { Modal }     from "@relcu/rc";
import React         from "react";
import { FC }        from "react";
import "./bulk-modal-body.css";

export const BulkModalBody: FC = React.memo(function BulkModalContainer(props) {
  return (
    <Modal.Body style={{ overflow: "hidden" }} className={"rc-bulk-modal-body"}>
      <Container>
        {props.children}
      </Container>
    </Modal.Body>
  );
});
