import React         from "react";
import { FC }        from "react";
import { useEffect } from "react";
import { useRef }    from "react";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "email-visualizer": any;
    }
  }
}

export interface EmailProps {
  template: string
  data?: any
}
export const Email: FC<EmailProps> = React.memo(function Email(props) {
  const { template, data } = props;
  const element = useRef<any>();
  useEffect(() => {
    element?.current.renderEmail({ template, data });
  }, [template, data]);

  return <email-visualizer ref={element}/>;
});

export default Email
