import React                 from "react";
import { FC }                from "react";
import { useMemo }           from "react";
import { JsonPermissions }   from "@relcu/ui";
import { Form }              from "@relcu/form";
import { JsonFieldProps }    from "@relcu/ui";
import { Step }              from "@relcu/ui";
import { ModalProps }        from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { ButtonVariants }    from "@relcu/ui";
import { Button }            from "@relcu/ui";
import { defaultMutators }   from "@relcu/ui";
import { ModalBody }         from "@relcu/ui";
import { Modal }             from "@relcu/ui";
import { Stepper }           from "@relcu/ui";
import { ModalFooter }       from "@relcu/ui";
import { TableViewFragment } from "../../View/TableView/__types__/TableViewFragment";
import { usePageViewDialog } from "./usePageViewDialog";

export interface PageViewDialogProps extends Partial<ModalProps> {
  edit: boolean;
  fields: any[]
  schemaName: string,
  permission?: JsonPermissions
  data?: {
    availableFields: JsonFieldProps[],
    selectedView?: TableViewFragment
  }
  initialStep?: number;
  share: boolean;
  onConfirm?: (data) => void
}

const fieldsInStep = {
  0: ["name"],
  1: ["filter"],
  2: ["columns"]
};

export const PageViewDialog: FC<PageViewDialogProps> = React.memo(function PageViewDialog(props) {
  const { onClose, open, modalProps, closeIcon, edit } = props;
  const { step, setStep, handleSubmit, initialValues, steps } = usePageViewDialog(props);
  const renderStep = useMemo(() => {
    const activeStep = steps.find((s, index) => index == step);
    let { component: Step, ...stepProps } = activeStep;
    return <Step {...stepProps} {...props}/>;
  }, [step]);

  return <Modal
    title={`${edit ? "Edit" : "Create"} page view`}
    onClose={onClose}
    open={open}
    closeIcon={closeIcon}
    disableBackdropClick={false}
    variant={"large"}
    modalProps={modalProps}
    style={{ minHeight: "60%" }}
  >
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={defaultMutators}>
      {
        ({ form, handleSubmit, submitErrors }) => {
          return <form
            onSubmit={handleSubmit}
            noValidate
            style={{ display: "contents" }}>
            <ModalBody container direction={"column"} flex={"1 1 auto"} justify={"center"} style={{ padding: 0 }}>
              <Box container flex={1} style={{ overflow: "hidden" }}>
                <Stepper>
                  {
                    steps?.map((s, i) => {
                      const error = fieldsInStep[ i ]?.find(field => submitErrors?.[ field ]);
                      return <Step
                        error={!!error}
                        label={s.title}
                        active={i == step}
                        past={i < step}
                        edit={true}
                        onClick={() => setStep(i)}
                        key={i}/>;
                    })
                  }
                </Stepper>
                {renderStep}
              </Box>
              {
                <ModalFooter borderOnTop>
                  <Button variant={ButtonVariants.Outline}
                          onClick={() => onClose()}>CANCEL</Button>
                  <Button type={"submit"}>
                    SAVE
                  </Button>
                </ModalFooter>
              }
            </ModalBody>
          </form>;
        }
      }
    </Form>
  </Modal>;
});