import { gql }                     from "@apollo/client";
import { FLYER_FRAGMENT }          from "../../../operations.graphql";

export const CREATE_FLYER = gql`
  mutation CreateFlyer ($input: CreateFlyerInput!) {
    createFlyer(input: $input) {
      flyer {
        ...Flyer
      }
    }
  }
  ${FLYER_FRAGMENT}
`;

export const UPDATE_FLYER = gql`
  mutation UpdateFlyer ($input:UpdateFlyerInput!) {
    updateFlyer (input: $input) {
      flyer {
        ...Flyer
      }
    }
  }
  ${FLYER_FRAGMENT}
`;

//TODO CHANGE MAIL TO EMAIL
export const GET_FLYERS = gql`
  query GetFlyers ($where:FlyerWhereInput,$skip:Int,$before:String,$after:String,$from:String,$first:Int,$last:Int) {
    flyers (
      where: $where,
      order: [createdAt_DESC, objectId_ASC],
      first: $first,
      last: $last,
      skip:$skip
      before:$before
      after:$after,
      from:$from,
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...Flyer
        }
      }
    }
  }
  ${FLYER_FRAGMENT}
`;
//TODO CHANGE MAIL TO EMAIL
export const SUBSCRIBE_FLYERS = gql`
  subscription SubscribeFlyers($where: FlyerSubscriptionWhereInput!) {
    flyers(events: [CREATE, ENTER,LEAVE, UPDATE], where: $where){
      event
      node {
        ...Flyer
      }
    }
  }
  ${FLYER_FRAGMENT}
`;

export const DUPLICATE_FLYER = gql`
  mutation DuplicateFlyer($input: CreateFlyerInput!) {
    createFlyer(input: $input) {
      flyer {
        ...Flyer
      }
    }
  }
  ${FLYER_FRAGMENT}
`;

export const REMOVE_FLYER = gql`
  mutation RemoveFlyer($id: ID!) {
    deleteFlyer(input: {id: $id}) {
      flyer {
        id
      }
    }
  }
`;

export const GET_MERGE_TAGS = gql`
  query GetMergeTags($scope: ID, $user: ID) {
    getMergeTags(scope: $scope, user: $user)
  }
`
