import React                 from "react";
import { FC }                from "react";
import { ToggleSwitchField } from "@relcu/ui";
import { StepInterface }     from "@relcu/ui";
import { StepSection }       from "@relcu/ui";
import { StepBody }          from "@relcu/ui";
import { StepHeader }        from "@relcu/ui";
import { StepContainer }     from "@relcu/ui";

export const Status: FC<StepInterface> = React.memo(function Status(props) {
  const { title, name } = props;
  return (
    <StepContainer>
      <StepHeader
        title={title}/>
      <StepBody>
        <StepSection title="Status">
          <ToggleSwitchField name={name} label={"Showing on Focus view"}/>
        </StepSection>
      </StepBody>
    </StepContainer>
  );
});