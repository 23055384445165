import React                     from "react";
import { FC }                    from "react";
import { classNames }            from "../../utils/classNames";
import { BoxItemComponentProps } from "../Box";
import { applyBoxItemStyles }    from "../Box";
import { TypographyClasses }     from "./TypographyClasses";

export enum TypographyVariant {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  p = "p",
  span = "span",
  time = "time",
  inherit = "p",
}

export enum TypographyColor {
  ExtraDark = "extraDark",
  Secondary = "secondary",
  LGSecondary = "lg-secondary",
  Tertiary = "tertiary",
  TertiaryDark = "tertiary-dark",
  Primary = "primary",
  Success = "success",
  Error = "error",
  Warning = "warning",
  YellowDark = "yellow-dark ",
  Info = "info",
  White = "white",
  Light = "light"
}

export enum TypographySize {
  TextTiny = "text-tiny",
  TextSmall = "text-small",
  Text = "text",
  SubtitleSmall = "subtitle-small",
  Subtitle = "subtitle",
  Title = "title",
}

export enum TypographyWeight {
  Bold = "text-bold",
  Medium = "text--medium",
  Regular = "text-regular",
}

export enum TypographyLine {
  Line17 = "line17",
  Line13 = "line13",
  LineCaption = "14px",
  LineOverline = "16px",
  LineBody2 = "18px",
  LineBody1 = "24px",
  LineTitle = "28px",
  LineH3 = "32x",
}

export interface TypographyProp extends BoxItemComponentProps<HTMLParagraphElement> {
  variant?: TypographyVariant;
  color?: TypographyColor;
  size?: TypographySize;
  weight?: TypographyWeight;
  lineHeight?: TypographyLine;
  noWrap?: boolean;
}

export const Typography: FC<TypographyProp> = React.memo(React.forwardRef(function Typography(props, ref) {
  const properties = applyBoxItemStyles(props);
  const { variant, className, noWrap, color, size, weight, lineHeight, ...p } = properties;

  const classes = classNames(TypographyClasses.Typography, {
    [ TypographyClasses.NoWrap ]: noWrap,

    [ TypographyClasses.ExtraDark ]: color == TypographyColor.ExtraDark,
    [ TypographyClasses.Secondary ]: color == TypographyColor.Secondary,
    [ TypographyClasses.Tertiary ]: color == TypographyColor.Tertiary,
    [ TypographyClasses.TertiaryDark ]: color == TypographyColor.TertiaryDark,
    [ TypographyClasses.LGSecondary ]: color == TypographyColor.LGSecondary,
    [ TypographyClasses.Primary ]: (color == TypographyColor.Primary),
    [ TypographyClasses.Success ]: (color == TypographyColor.Success),
    [ TypographyClasses.Error ]: (color == TypographyColor.Error),
    [ TypographyClasses.Warning ]: (color == TypographyColor.Warning),
    [ TypographyClasses.YellowDark ]: (color == TypographyColor.YellowDark),
    [ TypographyClasses.Info ]: (color == TypographyColor.Info),
    [ TypographyClasses.White ]: (color == TypographyColor.White),
    [ TypographyClasses.Light ]: (color == TypographyColor.Light),

    [ TypographyClasses.Title ]: size == TypographySize.Title,
    [ TypographyClasses.SubTitle ]: size == TypographySize.Subtitle,
    [ TypographyClasses.SubTitleSmall ]: size == TypographySize.SubtitleSmall,
    [ TypographyClasses.Text ]: size == TypographySize.Text,
    [ TypographyClasses.TextSmall ]: size == TypographySize.TextSmall,
    [ TypographyClasses.TextTiny ]: size == TypographySize.TextTiny,

    [ TypographyClasses.Bold ]: weight == TypographyWeight.Bold,
    [ TypographyClasses.Medium ]: weight == TypographyWeight.Medium,
    [ TypographyClasses.Regular ]: weight == TypographyWeight.Regular,

    [ TypographyClasses.Line17 ]: lineHeight == TypographyLine.Line17,
    [ TypographyClasses.Line13 ]: lineHeight == TypographyLine.Line13,
    [ TypographyClasses.LineH3 ]: lineHeight == TypographyLine.LineH3,
    [ TypographyClasses.LineTitle ]: lineHeight == TypographyLine.LineTitle,
    [ TypographyClasses.LineBody1 ]: lineHeight == TypographyLine.LineBody1,
    [ TypographyClasses.LineBody2 ]: lineHeight == TypographyLine.LineBody2,
    [ TypographyClasses.LineOverline ]: lineHeight == TypographyLine.LineOverline,
    [ TypographyClasses.LineCaption ]: lineHeight == TypographyLine.LineCaption

  }, className);
  //todo check if child is not string clone and add props, classes
  const Component: any = TypographyVariant[ variant ] || "span";

  return <Component className={classes} ref={ref} {...p}/>;
}));

Typography.defaultProps = {
  noWrap: false,
  variant: TypographyVariant.p,
  color: TypographyColor.ExtraDark,
  size: TypographySize.Text
};
