import React                from "react";
import { FC }               from "react";
import { SwitchFieldProps } from "@relcu/ui";
import { SwitchField }      from "@relcu/ui";
import { withCalc }         from "./withCalc";


export const SwitchCalcField: FC<SwitchFieldProps> = React.memo(withCalc(function SwitchCalcField(props) {
  return <SwitchField {...props}/>;
}));
