import { useRef }         from "react";
import React              from "react";
import { ModalProps }     from "@relcu/ui";
import { ModalFooter }    from "@relcu/ui";
import { Modal }          from "@relcu/ui";
import { ButtonVariants } from "@relcu/ui";
import { Button }         from "@relcu/ui";
import { Box }            from "@relcu/ui";
import { ModalBody }      from "@relcu/ui";
import { layoutVar }      from "../../../../reactiveVars";
import { ClassForm }      from "../../Form/ClassForm";

export interface ResetPasswordDialogProps extends Partial<ModalProps> {
  record;
}

export const ResetPasswordDialog = React.memo<ResetPasswordDialogProps>((props) => {
  const formRef = useRef(null);
  const layouts = layoutVar();
  const { dialog: { jql } } = layouts.User;
  const afterSubmit = (data) => {
    props.onClose();
  };

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      title={`Please reset the user’s password`}
      closeIcon={false}
      disableBackdropClick={false}
      variant={"small"}
    >
      <ClassForm
        group={false}
        afterSubmit={afterSubmit}
        subscription={{ submitting: true }}
        successMessage={"Password has been reset successfully."}
        jql={{
          ...jql,
          mutation: {
            ...jql.mutation,
            update: {
              ...jql.mutation.update,
              fields: [{
                "user": ["id", "objectId", "objectName", "objectIcon", "accountLockoutExpiresAt", "failedLoginAttempts"]
              }]
            }
          }
        }}
        sections={[
          {
            fields: [
              {
                "name": "password",
                "label": "Password",
                "type": "Password",
                "required": false,
                "placeholder": "Enter password",
                "component": "TextField",
                "properties": {
                  flexBasis: "100%"
                }
              },
              {
                "name": "confirm",
                "label": "Confirm Password",
                "type": "Password",
                "required": false,
                "placeholder": "Confirm password",
                "component": "TextField",
                "properties": {
                  flexBasis: "100%"
                }
              },
              {
                "name": "accountLockoutExpiresAt",
                "component": "HiddenField"
              },
              {
                "name": "failedLoginAttempts",
                "component": "HiddenField"
              }
            ]
          }
        ]}
        initialValues={{
          ...props.record,
          accountLockoutExpiresAt: null,
          failedLoginAttempts: 0
        }}
        formProps={{
          ref: formRef
        }}
        className="User"
      >
        {({ elements, submitting }) => (
          <ModalBody style={{ position: "relative" }} container direction={"column"} gap={"XS"} flexGrow={1}
                     className="base-dialog-container">
            <Box container direction={"column"} flex={"1 1 auto"} gap={"XS"}>
              {elements}
            </Box>
            <ModalFooter>
              <Button disabled={submitting} type="button" variant={ButtonVariants.Outline}
                      onClick={props.onClose}>CANCEL</Button>
              <Button disabled={submitting} type="submit">UPDATE</Button>
            </ModalFooter>
          </ModalBody>
        )}
      </ClassForm>
    </Modal>
  );
});
