import { ReactElement }       from "react";
import { FC }                 from "react";
import React                  from "react";
import { classNames }         from "../../";
import { Box }                from "../../";
import { useImperativeState } from "../../";
import { BoxComponentProps }  from "../../";

export interface AccordionProps extends BoxComponentProps {
  header?: (open: boolean) => ReactElement,
  openClass?: string;
  collapse?: boolean,
  disable?: boolean,
  InputProps?: Record<string, any>
  onToggle?(toggle,e)
}

export const Accordion: FC<AccordionProps> = React.memo(function Accordion(props) {
  const { children, className, openClass="open", header, collapse, onToggle, InputProps, disable, ...p } = props;
  const [isOpen, setIsOpen] = useImperativeState(collapse, onToggle);
  const classes = classNames({
    [ openClass ]: !!(isOpen && openClass)
  }, className);
  return <Box container direction={"column"} className={classes} {...InputProps} {...p}>
    {
      header && React.cloneElement(header(isOpen), {
        onClick: !disable ? (e) => {
          e.stopPropagation();
           setIsOpen(!isOpen, e);
        } : null
      })
    }
    {isOpen && children}
  </Box>;
});

Accordion.defaultProps = {
  collapse: false
};
