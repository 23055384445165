import { FC }                 from "react";
import { useCallback }        from "react";
import { useState }           from "react";
import { useEffect }          from "react";
import { useRef }             from "react";
import React                  from "react";
import { BoxComponentProps }  from "../../..";
import { useImperativeState } from "../../..";
import { classNames }         from "../../..";
import { Box }                from "../../..";
import { GlobalClasses }      from "../../../constants/GlobalClasses";
import { ReadActionsProps }   from "../../InlineEdit";
import { EditActionsProps }   from "../../InlineEdit";
import { InlineEdit }         from "../../InlineEdit";

export interface InlineEditableInputProps extends Omit<BoxComponentProps, "onChange"> {
  onConfirm?(event: React.SyntheticEvent, value)
  onCancel?(event)
  onChange?(value, event)
  onEdit?(event)
  format?(value)
  editFormat?(value)
  parse?(value)
  value: string
  error?: boolean
  isEditing?: boolean
  hideActionButtons?: boolean
  disableActionsButtons?: boolean
  classes?: {
    root?: string,
    actions?: string
    read?: string,
    edit?: string
  }
  readProps?: {
    [ key: string ]: any
  }
  editProps?: {
    [ key: string ]: any
  }
  renderEditActions?(props: EditActionsProps): React.ReactNode
  renderReadActions?(props: ReadActionsProps): React.ReactNode
}

export const InlineEditableInput: FC<InlineEditableInputProps> = React.memo<InlineEditableInputProps>(function InlineEditableInput(props) {
  const { isEditing, onConfirm, onCancel, onChange, value, error, onEdit, hideActionButtons, format, editFormat, classes, justify, style, renderReadActions, renderEditActions, readProps, editProps, parse, ...p } = props;
  const [editing, setEditing] = useImperativeState(isEditing, onEdit);
  const [val, setVal] = useState(value);
  const setValue = useCallback((value) => {
    const formated = editFormat ? editFormat(value) : value;
    setVal(formated == null ? "" : formated);
  }, [parse, setVal]);
  const handleConfirm = (e) => {
    e.stopPropagation();
    const parsed = parse ? parse(val) : val;
    onChange?.(parsed, e);
    onConfirm?.(e, parsed);
    setEditing(false);
  };
  useEffect(() => {
    setValue(value);
  }, [value]);
  const onInputChange = useCallback((event) => {
    let cursor = event.target.selectionEnd;
    event.persist();
    setTimeout(() => {
      if (props.editFormat && String(event.target.value).includes(",")) {
        const oldLength = (String(val).match(/,/g) || []).length;
        const newLength = (String(event.target.value).match(/,/g) || []).length;
        if (oldLength !== newLength) {
          cursor += 1;
        }
      }
      event.target.selectionStart = cursor;
      event.target.selectionEnd = cursor;
      event.target.focus();
    });
    setValue(event.target.value);
  }, [val]);

  return (
    <InlineEdit
      classes={{
        root: classes?.root,
        actions: classes?.actions
      }}
      hideActionButtons={hideActionButtons}
      flex={1}
      justify={justify ?? "end"}
      readView={() =>
        <InlineEditableReadView
          {...readProps}
          className={classes?.read}
          style={style}
          value={format ? format(val) : val}/>
      }
      editView={() =>
        <InlineEditableEditView
          {...editProps}
          className={classes?.edit}
          style={style}
          onKeyDown={(e) => e.key == "Enter" && handleConfirm(e)}
          error={error}
          onChange={onInputChange}
          onBlur={(e) => {
            e.stopPropagation();
            setValue(value);
            onCancel?.(e);
            setEditing(false);
          }}
          value={val}
        />
      }
      isEditing={editing}
      onEdit={(e) => setEditing(true)}
      onConfirm={handleConfirm}
      onCancel={(e) => {
        e.stopPropagation();
        setValue(value);
        onCancel?.(e);
        setEditing(false);
      }}
      renderReadActions={renderReadActions}
      renderEditActions={renderEditActions}
      {...p}
    />
  );
});
export interface InlineEditableReadViewProps extends BoxComponentProps {
  value: any
}
export const InlineEditableReadView = React.memo<InlineEditableReadViewProps>(function InlineRead(props) {
  const { value, className, ...p } = props;
  const classes = classNames(InlineEditableInputClasses.InlineRead, className);
  return <p className={classes} {...p}>
    {props.value}
  </p>;
});

export interface InlineEditableEditViewProps extends BoxComponentProps {
  error?: boolean
  onChange?(event)
  value?: any
}
export const InlineEditableEditView = React.memo<InlineEditableEditViewProps>(function InlineInput(props) {
  const { className, error, ...p } = props;
  const ref = useRef(null);
  const classes = classNames(InlineEditableInputClasses.InlineInput, {
    [ GlobalClasses.Error ]: error
  }, className);
  useEffect(() => {
    ref.current.focus();
  }, []);
  return <input ref={ref} className={classes} {...p}/>;
});

export enum InlineEditableInputClasses {
  InlineInput = "inline-input",
  InlineRead = "inline-read"
}
