import { FC }                 from "react";
import React                  from "react";
import { useContext }         from "react";
import { MultiInputField }    from "@relcu/ui";
import { DateField }          from "@relcu/ui";
import { MultiChoiceField }   from "@relcu/ui";
import { NumberField }        from "@relcu/ui";
import { SwitchField }        from "@relcu/ui";
import { TextField }          from "@relcu/ui";
import { PointerListField }   from "../Layout/Field/PointerListField";
import { TagsField }          from "../Layout/Field/TagsField";
import { FilterFieldContext } from "./Filter";
import { FilterContext }      from "./FilterProvider";

export const NumberOperator: FC<QueryOperatorProps> = React.memo(function NumberOperator({ name }) {
  return (
    <NumberField name={name} required label="Value"
                 view={"write"}
                 placeholder={"Type value"} flexBasis={"30%"}
                 precision={2}/>
  );
});
export const StringOperator: FC<QueryOperatorProps> = React.memo(function StringOperator({ name }) {
  return (
    <TextField name={name} required label="Value"
               placeholder={"Type value"} flexBasis={"30%"}/>
  );
});

export const MultiTextOperator: FC<QueryOperatorProps> = React.memo(function MultiTextOperator({ name }) {
  return (
    <MultiInputField name={name}
                     required label="Value"
                     placeholder={"Type and press Enter"} flexBasis={"30%"}/>
  );
});

export const PointerOperator: FC<QueryOperatorProps> = React.memo(function PointerOperator({ name }) {
  const context = useContext(FilterContext);
  const fieldContext = useContext(FilterFieldContext);
  const schema = context.getFieldSchema<{ targetClass: string }>(fieldContext.field);
  return (
    <PointerListField targetClass={schema.targetClass} name={name}
                      collapse required label="Value"
                      style={{flex: 1}}
                      placeholder={"Select value"}/>
  );
});
export const ChoiceOperator: FC<QueryOperatorProps> = React.memo(function ChoiceOperator({ name }) {
  const context = useContext(FilterContext);
  const fieldContext = useContext(FilterFieldContext);
  const schema = context.getFieldSchema<{ options }>(fieldContext.field);

  return <MultiChoiceField
    showSelectionActions
    options={schema.options}
    name={name}
    view={"write"}
    required
    label="Value"
    placeholder={"Select value"}
    style={{width: 400}}
    flexBasis={"50%"}/>;
});

export const StateOperator: FC<QueryOperatorProps> = React.memo(function StateOperator({ name }) {
  const context = useContext(FilterContext);
  const fieldContext = useContext(FilterFieldContext);
  const schema = context.getFieldSchema<{ states }>(fieldContext.field);
  const availableActions = Object.keys(schema.states)?.map((state) => ({ value: state, label: schema.states[ state ].status }));

  return <MultiChoiceField
    showSelectionActions
    options={availableActions}
    name={name}
    required
    view={"write"}
    label="Value"
    placeholder={"Select value"}
    flexBasis={"30%"}/>;
});
export const BooleanOperator: FC<QueryOperatorProps> = React.memo(function NumberOperator({ name }) {
  return (
    <SwitchField name={name} label="Value"
                 placeholder={"Select value"} flexBasis={"30%"} required/>
  );
});
export const DateTimeOperator: FC<QueryOperatorProps> = React.memo(function NumberOperator({ name }) {
  return (
    <DateField name={name} label="Value" type={"datetime"}
               placeholder={"Select value"} flexBasis={"30%"} required/>
  );
});

export const DateOperator: FC<QueryOperatorProps> = React.memo(function NumberOperator({ name }) {
  return (
    <DateField name={name} label="Value" type={"date"}
               placeholder={"Select value"} flexBasis={"30%"} required/>
  );
});
export const TagsOperator: FC<QueryOperatorProps> = React.memo(function TagsOperator({ name }) {
  const context = useContext(FilterContext);
  return (
    <TagsField name={name} label="Tag" options={[]} targetClass={context.target} flexBasis={"30%"} required/>
  );
});

export const FilterOperator = {
  ID: [
    { value: "equalTo", label: "Is", component: StringOperator },
    { value: "notEqualTo", label: "Is not", component: StringOperator },
    { value: "in", label: "In", component: MultiTextOperator },
    { value: "notIn", label: "Not in", component: MultiTextOperator }
  ],
  String: [
    { value: "equalTo", label: "Is", component: StringOperator },
    { value: "notEqualTo", label: "Is not", component: StringOperator },
    { value: "in", label: "In", component: MultiTextOperator },
    { value: "notIn", label: "Not in", component: MultiTextOperator },
    { value: "startWith", label: "Starts With", component: StringOperator },//"(?i)^${text}"
    { value: "endWith", label: "Ends With", component: StringOperator },//(?i)${text}$
    { value: "contains", label: "Contains", component: StringOperator },//"(?i)${text}"
    { value: "exists", label: "Has any value" },
    { value: "notExists", label: "Has no value" }
  ],
  Status: [
    { value: "in", label: "Is", component: StateOperator },
    { value: "notIn", label: "Is not", component: StateOperator },
    { value: "exists", label: "Has any value" },
    { value: "notExists", label: "Has no value" }
  ],
  Pointer: [
    { value: "in", label: "In", component: PointerOperator },
    { value: "notIn", label: "Not in", component: PointerOperator },
    { value: "exists", label: "Has any value" },
    { value: "notExists", label: "Has no value" }
  ],
  Choice: [
    { value: "in", label: "Is", component: ChoiceOperator },
    { value: "notIn", label: "Is not", component: ChoiceOperator },
    { value: "exists", label: "Has any value" },
    { value: "notExists", label: "Has no value" }
  ],
  Date: [
    { value: "on", label: "On", component: DateOperator },
    { value: "greaterThan", label: "After", component: DateTimeOperator },
    { value: "lessThan", label: "Before", component: DateTimeOperator }
  ],
  Boolean: [
    { value: "exists", label: "True" },
    { value: "notExists", label: "False" }
  ],
  Number: [
    { value: "equalTo", label: "Equal to", component: NumberOperator },
    { value: "notEqualTo", label: "Not equal to", component: NumberOperator },
    { value: "exists", label: "Has value" },
    { value: "notExists", label: "Has no value" },
    { value: "greaterThan", label: "Greater than", component: NumberOperator },
    { value: "greaterThanOrEqualTo", label: "Greater than or equal to", component: NumberOperator },
    { value: "lessThan", label: "Less than", component: NumberOperator },
    { value: "lessThanOrEqualTo", label: "Less than or equal to", component: NumberOperator }
  ],
  Tag: [
    { value: "in", label: "In", component: TagsOperator },
    { value: "notIn", label: "Not in", component: TagsOperator },
    { value: "exists", label: "Has any value" },
    { value: "notExists", label: "Has no value" }
  ]
};
export interface QueryOperatorProps {
  name: string;
}
