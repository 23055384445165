export enum BaseInputClasses {
  Input = "input",
  FullSize = "full-size",
  HalfSize = "half-size",
  MainContainer = "main-container",
  UnderlineHidden = "main-container--underlined-hidden",
  InputContainer = "input-container",
  StateContainer = "state-container",
  MultiSelectPlaceHolder= "multi-select__placeholder",
  Message = "message",
  Primary = "primary",
  Grey = "grey",
  Warning = "warning",
  Success = "success",
  Error = "error",
  Disabled = "disabled",
  Active = "active",
  ReadOnly = "read-only",
}
