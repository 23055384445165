import React                  from "react";
import { FC }                 from "react";
import { BackDropLoader }     from "@relcu/ui";
import { SearchInput }        from "@relcu/ui";
import { Box }                from "@relcu/ui";
import { ModalProps }         from "@relcu/ui";
import { ModalBody }          from "@relcu/ui";
import { Modal }              from "@relcu/ui";
import { EmptyList }          from "@relcu/ui";
import { SearchModalClasses } from "../SearchModal/SearchModalClasses";
import { RecordSearchResult } from "./RecordSearchResult";
import "../SearchModal/search-dialog.css";
import { useSearchRecord }    from "./useSearchRecord";

export interface DirectMailSearchDialogProps extends Partial<ModalProps> {
  onClose?(e?),
  objectName,
  leadId
}
export const DirectMailSearchModal: FC<DirectMailSearchDialogProps> = React.memo((props) => {
  const search = useSearchRecord(props);
  return (
    <Modal title={"Records"} onClose={props.onClose} open={true} closeIcon={true}
           top={"100px"}
           disableBackdropClick={false}
           variant={"middle"}>
      <ModalBody className={SearchModalClasses.Container} flexGrow={1} gap={"XS"} container direction={"column"}>
        <Box container direction={"column"} flex={"1 1 auto"} gap={"XS"}
             className={SearchModalClasses.Body}>
          <Box container direction={"column"} gap={"XS"}>
            <SearchInput
              clearable
              placeholder={"Search"}
              value={search.value}
              style={{ padding: 0 }}
              autoFocus={true}
              onChange={search.setValue}/>
          </Box>
          {
            search.isEmpty ?
              <EmptyList
                justify={"center"}
                flexBasis={"300px"}
                title={"No search results"}
                content={"Please type record information to search"}
                icon={"search"}/>
              : search.loading ?
              <Box flexBasis={300} style={{ position: "relative" }}>
                <BackDropLoader/>
              </Box>
              : <RecordSearchResult
                onRowClick={search.onRowClick}
                data={search.data}
                count={search.count}
                onPage={search.onPage}
              />
          }

        </Box>
      </ModalBody>
    </Modal>
  );
});

