import React                        from "react";
import { FC }                       from "react";
import { useRef }                   from "react";
import { SyntheticEvent }           from "react";
import { useState }                 from "react";
import { useEffect }                from "react";
import { useCallback }              from "react";
import { useMemo }                  from "react";
import { Advice }                   from "@relcu/rc";
import { Link }                     from "@relcu/react-router";
import { DeepPartial }              from "@apollo/client/utilities";
import { useMutation }              from "@apollo/client";
import { useClassNames }            from "@relcu/rc";
import { ItemsEllipsis }            from "@relcu/rc";
import { Chips }                    from "@relcu/ui";
import { ChipsSizes }               from "@relcu/ui";
import { ChipsColors }              from "@relcu/ui";
import { Badge }                    from "@relcu/rc";
import { FileUploadCard }           from "@relcu/rc";
import { Divider }                  from "@relcu/rc";
import { Icon }                     from "@relcu/rc";
import { Tooltip }                  from "@relcu/rc";
import { Whisper }                  from "@relcu/rc";
import { List }                     from "@relcu/rc";
import { Typography }               from "@relcu/rc";
import { Avatar }                   from "@relcu/rc";
import { Stack }                    from "@relcu/rc";
import { Accordion }                from "@relcu/rc";
import { Button }                   from "@relcu/rc";
import { useNavigate }              from "@relcu/react-router";
import { useSource }                from "@relcu/ui";
import { useAlert }                 from "@relcu/ui";
import { DateTime }                 from "luxon";
import { MailMessage_attachments }  from "../../../../../graph/__types__/MailMessage";
import { MailMessage }              from "../../../../../graph/__types__/MailMessage";
import { MailSendInput }            from "../../../../../types/graphql-global-types";
import { downloadFile }             from "../../../../../utils/helpers";
import { getObjectPath }            from "../../../../../utils/layoutUtils";
import { getField }                 from "../../../../../utils/schemaUtils";
import { CreateDraftMail }          from "../../../../__types__/CreateDraftMail";
import { CreateDraftMailVariables } from "../../../../__types__/CreateDraftMail";
import { usePermissions }    from "../../../../AccessControl";
import { Email }             from "../../../../ContentVisualizer";
import { EmptyConversation } from "../../../../EmptyConversation/EmptyConversation";
import { CREATE_DRAFT_MAIL }        from "../../../../operations.graphql";
import { useMailApi }               from "../../../../useMailApi";
import { MailConversation }         from "../__types__/MailConversation";
import { HeaderProps }              from "../Header/ThreadHeader";
import { ThreadHeader }             from "../Header/ThreadHeader";
import { InfoPopover }              from "../InfoPopover";
import { Compose }                  from "./Compose/Compose";
import { ComposeProps }             from "./Compose/Compose";
import { useThread }                from "./useThread";
import "./thread.css";

export interface ThreadProps extends HeaderProps {
  firstConversationId?: string;
}

export interface ThreadMessageHeaderProps {
  onMarkAsRead: (objectId) => void,
  expanded: boolean,
  message: MailMessage,
  mail: DeepPartial<MailConversation>,
  onReply(e: SyntheticEvent),
  onForward(e: SyntheticEvent),
  canContact: boolean,
  haveADraft: boolean,
  isBulkPart: boolean
}

export interface ThreadMessageBodyProps {
  message: MailMessage,
  draftReplyToMessage?: MailMessage
  mail: DeepPartial<MailConversation>,
  expand: boolean
  onToggle?()
  viewerObjectId: string
  isBulkPart: boolean
  canSeeBulk: boolean
}

export interface ThreadAttachmentsProps {
  attachment: MailMessage_attachments;
}

export interface ComposeRendererProps extends ComposeProps {
  message: MailMessage;
}

export const Thread: FC<ThreadProps> = React.memo(function Thread(props) {
  const { expand, onToggle, firstConversationId } = props;
  const { objects, mail, loading, handleClick, onRemove, onMarkAsRead, defaultSelectedMessage, bulkOwner, viewerObjectId } = useThread();
  const navigate = useNavigate();
  const [createDraft] = useMutation<CreateDraftMail, CreateDraftMailVariables>(CREATE_DRAFT_MAIL);
  const elements = useRef<Record<string, HTMLDivElement>>({});
  const { $object, $viewer: user } = useSource();
  const { canUpdate } = usePermissions($object);
  const [scrollToMessage, setScrollToMessage] = useState<string>(defaultSelectedMessage);
  const { states } = getField("Lead", "leadStatus");
  const { mailBoxes, defaultMailbox } = useMailApi($object);
  const from = defaultMailbox?.address;
  const canContact = ($object.__typename != "Lead" || !states[ $object.leadStatus.status ]?.not_contact) && !!from && canUpdate;
  const [expands, setExpands] = useState(() => getExpandedMails());
  const isOnlyDraft = useMemo(() => {
    return objects.length == 1 && objects[ 0 ].parties.find(p => p.type == "sender")?.metadata?.isDraft;
  }, [objects]);
  const isUnsubscribed = objects.some(o => o.unsubscribe);
  function getExpandedMails() {
    const expands = {};
    objects.filter((ob, index, array) => {
      const isExpand = ob.parties.find(p => p.type == "sender")?.metadata?.isDraft && !ob.inReplyTo ||
        array.find((arr) => {
          return arr?.inReplyTo?.objectId == ob.objectId && arr.parties.find(p => p.type == "sender")?.metadata?.isDraft;
        });
      expands[ ob.objectId ] = !!isExpand;
    });
    return defaultSelectedMessage ? { ...expands, [ defaultSelectedMessage ]: true } : { ...expands };
  }
  useEffect(() => {
    if ((Object.keys(expands).length == 0 || Object.keys(expands).every((e) => expands[ e ] == false)) && objects.length) {
      setExpands({ [ objects.at(0).objectId ]: true });
    }
  }, [objects?.length]);

  const handleReplay = async (e: SyntheticEvent, message: MailMessage) => {
    e.stopPropagation();
    const sender = message.parties.find(p => p.type == "sender");
    const cc = message.parties.filter(p => p.type == "cc").map(e => e.address);
    let toAddresses = message.parties.filter(p => p.type == "receiver" && (p.party.__typename !== "Contact" || (p.party.__typename == "Contact" && p.party.emails.find(m => m.address === p.address && !m.optOut)))).map(e => e.address).filter(address => !mailBoxes.find((mail) => mail.address === address));
    if (sender && sender.party.objectId != user.objectId && sender.party.__typename == "Contact") {
      const notOptOut = sender.party.emails.find(e => e.address === sender.address && !e.optOut);
      toAddresses = notOptOut ? [sender.address, ...toAddresses] : toAddresses;
    }
    if (sender && sender.party.objectId != user.objectId && sender.party.__typename == "User") {
      toAddresses = [sender.address, ...toAddresses];
    }
    const input: any = {
      from,
      to: [...toAddresses],
      inReplyTo: message.objectId,
      subject: `Re: ${message.subject.replace("Re: ", "")}`
    };

    if (message.attachments) {
      input.attachments = message.attachments.map((a) => ({
        url: a.url,
        name: a.file.name,
        mimeType: a.file.mimeType,
        objectId: a.file.objectId
      })) as any;
    }

    if (cc.length) {
      input.cc = cc;
    }

    const result = await createDraft({
      variables: {
        input
      }
    });

    if (result?.data?.createDraftEmail?.objectId) {
      setExpands(prev => ({
        ...prev,
        [ message.objectId ]: true
      }));
      setScrollToMessage(result?.data?.createDraftEmail?.objectId);
    }
  };
  const handleForward = async (e: SyntheticEvent, message: MailMessage) => {
    e.stopPropagation();
    const subject = message.subject.replace("Re: ", "").replace("Fwd: ", "");
    const input: MailSendInput = {
      to: [],
      from,
      inReplyTo: message.objectId,
      subject: `Fwd: ${subject}`
    };
    if (message.attachments) {
      input.attachments = message.attachments.map((a) => ({
        url: a.url,
        name: a.file.name,
        mimeType: a.file.mimeType,
        objectId: a.file.objectId
      })) as any;
    }

    const result = await createDraft({
      variables: {
        input
      }
    });

    if (result?.data?.createDraftEmail?.objectId) {
      setExpands(prev => ({
        ...prev,
        [ message.objectId ]: true
      }));
      setScrollToMessage(result?.data?.createDraftEmail?.objectId);
    }
  };

  const handleToggle = (id, defaultExpanded) => {
    setExpands(prev => {
      return defaultExpanded && !Object.keys(prev).includes(id)
        ? {
          ...prev,
          [ id ]: false
        }
        : {
          ...prev,
          [ id ]: !prev[ id ]
        };
    });
  };

  const handleDelete = (id) => {
    if (notReplyMessages && firstConversationId) {
      navigate(`${getObjectPath($object)}/emails/${firstConversationId}`);
    }
  };

  const notReplyMessages = objects.filter(ob => {
    return !ob.parties.find(p => p.type == "sender")?.metadata?.isDraft || ob.parties.find(p => p.type == "sender")?.metadata?.isDraft && !ob.inReplyTo;
  });
  if (!mail) {
    return null;
  }

  const toggleAll = (open) => {
    setExpands(prev => {
      let all = {};
      notReplyMessages.forEach((message) => all[ message.objectId ] = open);
      return { ...prev, ...all };
    });
  };
  useEffect(() => {
    if (scrollToMessage && elements?.current[ scrollToMessage ]) {
      elements?.current[ scrollToMessage ].scrollIntoView({ behavior: "smooth" });
      setScrollToMessage(null);
    }
  }, [objects]);

  const allExpanded = useMemo(() => {
    const arrExpands = Object.keys(expands);
    let expandsCount = arrExpands.filter((id) => expands[ id ]).length;
    const defaultExpands = [];
    notReplyMessages.forEach((message, index) => {
      const replyToMessage = objects.find((ob) => ob?.inReplyTo?.objectId == message.objectId && ob.parties.find(p => p.type == "sender")?.metadata?.isDraft);
      if (replyToMessage) {
        return defaultExpands.push(message.objectId);
      }
    });
    if (defaultExpands.length) {
      defaultExpands.forEach((id) => {
        !arrExpands.includes(id) && expandsCount++;
      });
    }
    return expandsCount;
  }, [expands, notReplyMessages, objects]);
  return <>
    {
      isOnlyDraft ?
        <ComposeRenderer
          onDelete={handleDelete}
          draftId={objects[ 0 ].objectId}
          message={objects[ 0 ]}
          expand={expand}
          onToggle={onToggle}
        /> :
        !!notReplyMessages.length
          ?
          <>
            <ThreadHeader
              isUnsubscribed={isUnsubscribed}
              allToggled={allExpanded === notReplyMessages.length}
              toggleAll={toggleAll}
              scope={mail.scope}
              handleClick={handleClick}
              onRemove={onRemove}
              subject={mail.subject}
              mailId={mail.id}
              expand={expand}
              onToggle={onToggle}/>
            <div className={"rc-thread-content"}>
              <List bordered>
                {notReplyMessages.map((message, index) => {
                  const replyToMessage = objects.find((ob) => ob?.inReplyTo?.objectId == message.objectId && ob.parties.find(p => p.type == "sender")?.metadata?.isDraft);
                  const isBulkPart = (!!mail.parent && index === notReplyMessages.length - 1);
                  const canSeeBulk = bulkOwner || !!message.parties.find(p => p.party.objectId == user.objectId);
                  return <List.Item key={index} ref={(e) => elements.current[ message.objectId ] = e}>
                    <Accordion
                      headerClassName={"rc-thread-header"}
                      bodyClassName={"rc-thread-body"}
                      defaultExpanded={!!replyToMessage}
                      key={index}
                      onSelect={() => handleToggle(message.objectId, !!replyToMessage)}
                      expanded={expands[ message.objectId ]}
                      header={(expanded) =>
                        <ThreadMessageHeader
                          canContact={canContact}
                          onMarkAsRead={onMarkAsRead}
                          mail={mail}
                          expanded={expanded}
                          message={message}
                          haveADraft={!!replyToMessage}
                          onReply={(e) => handleReplay(e, message)}
                          onForward={(e) => handleForward(e, message)}
                          isBulkPart={isBulkPart}

                        />
                      }>
                      <ThreadMessageBody
                        ref={e => {
                          replyToMessage ? elements.current[ replyToMessage.objectId ] = e : null;
                        }}
                        isBulkPart={isBulkPart}
                        mail={mail}
                        canSeeBulk={canSeeBulk}
                        message={message}
                        viewerObjectId={viewerObjectId}
                        expand={expand}
                        onToggle={onToggle}
                        draftReplyToMessage={replyToMessage}
                      />
                    </Accordion>
                  </List.Item>;
                })}
              </List>
            </div>
          </>
          :
          (!loading && <EmptyConversation icon={"email"} text={"Select email to see conversation."}/>)
    }
  </>;
});
const ThreadMessageHeader: FC<ThreadMessageHeaderProps> = (props) => {
  const { message, expanded, isBulkPart, mail, onMarkAsRead, onForward, onReply, canContact, haveADraft } = props;
  const { $viewer: { objectId }, $object } = useSource();
  const sender = useMemo(() => message.parties.find((p) => p.type === "sender"), [message.parties]);
  const viewerParticipant = useMemo(() => Object(message.parties.find((p) => p.party?.objectId === objectId)), [message.parties]);
  const { unread } = viewerParticipant;
  const receivers = useMemo(() => message.parties.filter((p) => p.type === "receiver" || p.type === "cc"), [message.parties]);
  const hasAttachments = useMemo(() => !!message.attachments.length, [message.attachments]);
  const [more, setMore] = useState(receivers.length <= 3);
  const labels = useMemo(() => {
    const l = [];
    if (viewerParticipant.metadata?.isDraft) {
      l.push("Draft");
    }
    const folders = viewerParticipant.metadata?.labels || l;
    if (viewerParticipant.metadata?.provider === "gmail") {
      return folders.map(label => {
        let icon = "folder";
        let color = ChipsColors.Grey;
        let name = label;
        switch (label) {
          case "Sent Mail":
            name = "Sent";
            icon = "send";
            break;
          case "Trash":
          case "Draft":
            icon = "delete_outline";
            color = ChipsColors.Error;
            break;
          case "Inbox":
          case "INBOX":
            icon = "inbox";
            break;
          case "Snoozed":
            icon = "access_alarms";
            break;
          case "Important":
            icon = "label_important";
            break;
          case "Archive":
            icon = "inventory";
            break;
          case "Starred":
            icon = "star";
            break;
        }
        return {
          name,
          color,
          icon
        };
      });
    } else if (viewerParticipant.metadata?.provider === "outlook") {
      return folders.map(label => {
        let icon = "folder";
        let color = ChipsColors.Grey;
        let name = label;
        switch (label) {
          case "Sent Mail":
            name = "Sent";
            icon = "send";
            break;
          case "Deleted Items":
          case "Draft":
            name = "Deleted";
            icon = "delete_outline";
            color = ChipsColors.Error;
            break;
          case "Inbox":
          case "INBOX":
            icon = "inbox";
            break;
          case "Snoozed":
            icon = "access_alarms";
            break;
          case "Archive":
            icon = "inventory";
            break;
          case "Starred":
            icon = "star";
            break;
        }
        return {
          name,
          color,
          icon
        };
      });
    }
    return folders.map(label => {
      let icon = "folder";
      let color = ChipsColors.Grey;
      let name = label;
      return {
        name,
        color,
        icon
      };
    });
  }, [viewerParticipant]);

  useEffect(() => {
    message.parties.forEach(p => {
      if (p.unread && p.party.objectId == objectId && expanded) {
        onMarkAsRead(message.objectId);
      }
    });
  }, [expanded, message.objectId, message.parties, objectId]);
  let cantReplyMessage = useMemo(() => {
    if ($object.__typename === "Contact") {
      if (!$object.emails.length) {
        return "The contact doesn't have any email.";
      } else if (sender && sender.party.__typename === "User") {
        return "";
      } else {
        const senderMail = $object.emails.find(e => e.address === sender?.address);
        return !(senderMail && !senderMail.optOut) && "The contact opted out from email.";
      }
    } else {
      return "";
    }
  }, [$object, sender]);

  const { prefix, withClassPrefix } = useClassNames("thread-blue-icon", "rc");
  const isToday = useMemo(() => {
    const today = DateTime.now();
    const date = DateTime.fromISO(message.date);
    return date.month === today.month && date.year === today.year && date.day === today.day;
  }, [message.date]);

  return <Stack spacing={16} alignItems={"flex-start"}>
    <Stack onClick={(e) => e.stopPropagation()}>
      <Link to={getObjectPath(sender.party)}>
        <Avatar circle={true} size={"md"} showIndicator={false} src={sender.party.objectIcon}
                className={"rc-list-card-avatar"}>{sender.party.objectName}</Avatar>
      </Link>
    </Stack>
    <Stack.Item grow={1} style={{ overflowX: "hidden" }}>
      <Stack spacing={12} direction={"column"} alignItems={"flex-start"}>
        <Stack.Item style={{ width: "100%" }}>
          <Stack justifyContent={"space-between"} spacing={16}>
            <Stack spacing={16}>
              <Typography variant={"base14"} weights={"medium"}
                          style={{ whiteSpace: "nowrap" }}>{sender.party.objectName}</Typography>
              <Stack spacing={4} wrap={true}>
                <ItemsEllipsis
                  showLength={2}
                  items={labels}
                  renderItem={({ name, icon, color, ...rest }, index) => {
                    return <Chips
                      className={"rc-thread-chips"}
                      key={index}
                      thumbnail={<Icon type={icon} style={{ fontSize: "var(--typography-button-font-size)" }}/>}
                      color={ChipsColors.Grey}
                      label={name}
                      length={15}
                      size={ChipsSizes.Small}
                      {...rest}
                    />;
                  }}
                />
              </Stack>

            </Stack>
            <Stack spacing={12}>
              {
                hasAttachments &&
                <Stack.Item style={{ lineHeight: 1 }}>
                  <Icon className={"rc-thread-icon"} type={"attachment"}/>
                </Stack.Item>
              }
              <Typography variant={"small12"} color={"secondary"}
                          style={{ whiteSpace: "nowrap" }}>
                {isToday ? `Today, ${DateTime.fromISO(message.date).toLocaleString({ hour: "2-digit", minute: "2-digit" })}` : DateTime.fromISO(message.date).toLocaleString({ month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}
              </Typography>
              {unread && <Stack.Item style={{ display: "flex" }}>
                <Badge className={"rs-online-status"} size={"sm"}/>
              </Stack.Item>}
              {expanded && canContact &&
                <Stack spacing={16}>
                  <Stack.Item style={{ lineHeight: 1 }}>
                    {
                      cantReplyMessage
                        ? <Advice text={cantReplyMessage}>
                          <div>
                            <Icon
                              className={withClassPrefix({ disabled: haveADraft || cantReplyMessage })}
                              type={"rc_reply"}
                              onClick={onReply}
                            />
                          </div>
                        </Advice>
                        : <Advice text={"Reply all"}>
                          <Icon
                            className={withClassPrefix({ disabled: haveADraft })}
                            type={"rc_reply"}
                            onClick={onReply}
                          />
                        </Advice>
                    }

                  </Stack.Item>
                  <Stack.Item style={{ lineHeight: 1 }}>
                    <Advice text={"Forward"}>
                      <Icon
                        className={withClassPrefix({ disabled: haveADraft })}
                        type={"rc_forward"}
                        onClick={onForward}
                      />
                    </Advice>
                  </Stack.Item>
                </Stack>
              }
            </Stack>
          </Stack>
        </Stack.Item>
        {!expanded ?
          <Stack.Item style={{ width: "100%" }}>
            <Typography style={{
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              {message?.snippet}
            </Typography>
          </Stack.Item>
          :
          <Stack spacing={2} onClick={(e) => e.stopPropagation()}>
            <Whisper placement="bottom" controlId="control-id-focus" trigger="hover" speaker={<Tooltip>
              Show details
            </Tooltip>}>
              <Stack alignItems={"center"}>
                <Typography variant={"small12"} weights={"regular"} color={"secondary"}>
                  To: {more ? receivers.map(receiver => receiver.party?.objectName).join(", ") : receivers.slice(0, 3).map(receiver => receiver.party?.objectName).join(", ")}
                </Typography>
                {!more ? <Button onClick={() => setMore(true)} appearance={"text"}
                                 size={"xs"}>{receivers.length - 3} more </Button> : null}
              </Stack>
            </Whisper>
            <InfoPopover
              toggleRenderer={
                <div className={"rc-thread-expend-icon-container"}>
                  <Icon className={"rc-thread-expend-icon"} type={"arrow_drop_down"}/>
                </div>
              }
              speaker={
                <Stack direction={"column"} spacing={12} alignItems={"flex-start"} className={"rc-receivers-info"}>
                  <Stack spacing={16} alignItems={"flex-start"}>
                    <Typography color={"secondary"} className={"rc-receivers-info-item_title"}>From:</Typography>
                    <Stack spacing={4} style={{ flexWrap: "wrap" }}>
                      <Typography weights={"medium"} variant={"base14"}>{sender.party.objectName}</Typography>
                      <Typography color={"secondary"} variant={"base14"}>({sender.address})</Typography>
                    </Stack>
                  </Stack>
                  {/*<Stack spacing={16} alignItems={"flex-start"}>*/}
                  {/*  <Typography color={"secondary"} className={"rc-receivers-info-item_title"}>Organizer:</Typography>*/}
                  {/*  <Stack spacing={4} style={{ flexWrap: "wrap" }}>*/}
                  {/*    <Typography weights={"medium"} variant={"base14"}>{sender.party.objectName}</Typography>*/}
                  {/*    <Typography color={"secondary"} variant={"base14"}>({sender.address})</Typography>*/}
                  {/*  </Stack>*/}
                  {/*</Stack>*/}
                  <Stack spacing={16} alignItems={"flex-start"}>
                    <Typography color={"secondary"} className={"rc-receivers-info-item_title"}>Recipient:</Typography>
                    <Stack direction={"column"} spacing={4} alignItems={"flex-start"}>
                      {receivers.map((receiver, index) => (
                        <Typography key={index} weights={"medium"}
                                    variant={"base14"}>{receiver.party.objectName}</Typography>
                      ))}
                    </Stack>
                  </Stack>
                  <Stack spacing={16}>
                    <Typography color={"secondary"} className={"rc-receivers-info-item_title"}>Date:</Typography>
                    <Typography weights={"medium"}
                                variant={"base14"}>{DateTime.fromISO(message.date).toFormat("ccc t")}</Typography>
                  </Stack>
                  <Stack spacing={16} alignItems={"flex-start"}>
                    <Typography color={"secondary"} className={"rc-receivers-info-item_title"}>Subject:</Typography>
                    <Typography weights={"medium"} variant={"base14"}>{message.subject}</Typography>
                  </Stack>
                  <Stack spacing={16}>
                    <Typography color={"secondary"} className={"rc-receivers-info-item_title"}>Type:</Typography>
                    <Typography weights={"medium"} variant={"base14"}>{isBulkPart ? "bulk" : mail.type}</Typography>
                  </Stack>
                </Stack>
              }
            />
          </Stack>
        }
      </Stack>
    </Stack.Item>
  </Stack>;
};

export const ThreadMessageBody = React.forwardRef<HTMLDivElement, ThreadMessageBodyProps>((props, ref) => {
  const { message, mail, isBulkPart, viewerObjectId, expand, onToggle, draftReplyToMessage, canSeeBulk } = props;
  const hasAttachments = useMemo(() => !!message.attachments.length, [message.attachments]);
  const navigate = useNavigate();

  return <Stack direction={"column"} alignItems={"stretch"} spacing={16}>
    {
      message.parties.find(p => p.type == "sender").metadata?.isDraft
        ?
        <ComposeRenderer
          message={message}
          mode={"reply"}
          expand={expand}
          onToggle={onToggle}
          draftReplyToMessage={draftReplyToMessage}
        />
        :
        <Stack.Item style={{ marginLeft: 60 }}>
          <Typography className={"base-14"}>
            <Email template={message.html || message.snippet}/>
          </Typography>
        </Stack.Item>
    }
    {
      hasAttachments &&
      <>
        <Divider/>
        <Stack direction={"column"} alignItems={"flex-start"} spacing={10}>
          <Typography weights={"medium"} variant={"small10"}>
            {message.attachments.length} attached file{message.attachments.length > 1 && "s"}
          </Typography>
          <Stack spacing={10} wrap={true}>
            {message.attachments.map((attachment, index) => <ThreadAttachments key={index} attachment={attachment}/>)}
          </Stack>
        </Stack>
      </>
    }
    {
      draftReplyToMessage &&
      <Stack.Item>
        <div ref={ref} style={{ height: "100%" }}><ComposeRenderer
          message={draftReplyToMessage}
          mode={"reply"}
          expand={expand}
          onToggle={onToggle}
          draftReplyToMessage={message}
        />
        </div>
      </Stack.Item>
      /*todo do not remove message and draftReplyToMessage there both needed*/
    }
    {
      isBulkPart &&
      <Button
        disabled={!canSeeBulk}
        appearance={"text"}
        size={"xs"}
        onClick={() => navigate(`/user/${viewerObjectId}/emails/${mail.parent.objectId}`)}
      >
        See full bulk action
      </Button>
    }
  </Stack>;
});
const ThreadAttachments: FC<ThreadAttachmentsProps> = (props) => {
  const { error } = useAlert();
  const { attachment } = props;
  const onDownload = useCallback(async (attachment) => {
    try {
      await downloadFile({ url: attachment.url, name: attachment.filename, type: attachment.file.mimeType });
    } catch (e) {
      error(e?.message || `Could not download ${attachment.name} file`);
    }
  }, []);
  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[ i ];
  };
  return <FileUploadCard incoming onDownload={() => onDownload(attachment)} fileName={attachment.file.name}
                         fileSize={formatBytes(attachment.file.size) as any}
                         extension={attachment.file.mimeType.includes("pdf") && "pdf"}/>;
};

const ComposeRenderer: FC<ComposeRendererProps> = (props) => {
  const { message, ...rest } = props;
  const to = message.parties.filter(p => p.type == "receiver").map(e => {
    return {
      contactId: e.party.objectId,
      value: e.address,
      address: e.address,
      objectName: e.party.objectName,
      objectIcon: e.party.objectIcon
    };
  });
  const cc = message.parties.filter(p => p.type == "cc").map(e => {
    return {
      contactId: e.party.objectId,
      value: e.address,
      address: e.address,
      objectName: e.party.objectName,
      objectIcon: e.party.objectIcon
    };
  });

  const initials: any = {
    from: message.parties.find(p => p.type == "sender").address,
    to: to,
    cc: cc,
    loanEstimateId: message.loanEstimate?.objectId,
    loanProposalId: message.loanProposal?.objectId,
    subject: message.subject,
    html: message.html,
    attachments: message.attachments,
    template: message.template?.objectId
  };

  return <Compose
    draftId={message.objectId}
    initialValues={initials}
    {...rest}
  />;
};
