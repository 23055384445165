import { gql, useLazyQuery }           from "@apollo/client";
import { FormSpy }                     from "@relcu/form";
import { useFormState }                from "@relcu/form";
import { Field }                       from "@relcu/form";
import { useField }                    from "@relcu/form";
import { Alignment }                   from "@relcu/ui";
import { Button }                      from "@relcu/ui";
import { CurrencyField }               from "@relcu/ui";
import { FormWhere }                   from "@relcu/ui";
import { PercentField }                from "@relcu/ui";
import { SwitchField }                 from "@relcu/ui";
import { BoxComponentProps }           from "@relcu/ui";
import { InputState }                  from "@relcu/ui";
import { Autocomplete }                from "@relcu/ui";
import { ChoiceField }                 from "@relcu/ui";
import { MultiChoiceField }            from "@relcu/ui";
import { SwitchFieldProps }            from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { FontIcon }                    from "@relcu/ui";
import { HiddenField }                 from "@relcu/ui";
import { TopLevelCondition }           from "@relcu/ui";
import { classNames }                  from "@relcu/ui";
import React                           from "react";
import { FC }                          from "react";
import { useState }                    from "react";
import { useRef }                      from "react";
import { useCallback }                 from "react";
import { useContext }                  from "react";
import { ReactNode }                   from "react";
import { ExoticComponent }             from "react";
import { useEffect }                   from "react";
import { useMemo }                     from "react";
import { format }                      from "../../../../../utils/helpers";
import { isPositiveInteger }           from "../../../../../utils/helpers";
import { getField }                    from "../../../../../utils/schemaUtils";
import { PricingViewClasses }          from "../PricingViewClasses";
import { usePricingView }              from "../usePricingView";
import { GetCountiesByStateVariables } from "./__types__/GetCountiesByState";
import { GetCountiesByState }          from "./__types__/GetCountiesByState";
import { SwitchCalcField }             from "./Field";
import { ScoreField }                  from "./Field";
import { LtvField }                    from "./Field";
import { TotalLoanAmountField }        from "./Field";
import { DownPaymentField }            from "./Field";
import { LoanAmountField }             from "./Field";
import { ChoiceCalcField }             from "./Field/ChoiceCalcField";
import { HiddenCalcField }             from "./Field/HiddenCalcField";
import { VeteranTypeField }            from "./Field/VeteranTypeField";
import "./loan-criteria.css";
import { LoanCriteriaClasses }         from "./LoanCriteriaClasses";
import { LoanCriteriaContext }         from "./LoanCriteriaProvider";
import { LoanCriteriaProvider }        from "./LoanCriteriaProvider";
import { useLoanCriteria }             from "./useLoanCriteria";
import { useLoanLimit }                from "./useLoanLimit";

const WHEN_CASH_OUT: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "equals", value: "cash_out_refinance" }] };
const WHEN_RATE_TERM: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "equals", value: "rate_term_refinance" }] };
const WHEN_PURCHASE: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "equals", value: "purchase" }] };
const WHEN_COSTCO: TopLevelCondition = { and: [{ path: "mortech.source", operator: "equals", value: "9" }] };
const WHEN_HAS_SECONDARY_FINANCING: TopLevelCondition = { and: [{ path: "secondaryFinancing", operator: "neqEquals", value: "none" }] };
export const LoanCriteria: FC = React.memo(function LoanCriteria() {
  const api = useLoanCriteria();
  return <LoanCriteriaProvider calculate={api.calculate} settings={api.settings} product={api.selectedProduct}>
    <Box container direction={"column"} className={PricingViewClasses.PricingViewHeader}>
      <h3>Loan criteria</h3>
    </Box>
    <Container>
      <HiddenCalcField name={"loanProgram"} onChange={() => api.calculate("loanProgram")}/>
      <HiddenField name={"totalLoanAmount"}/>
      <HiddenField name={"loanTerm"}/>
      <HiddenField name={"loanProduct"}/>
      <HiddenField name={"mortech.productName"}/>
      <HiddenField name={"property.propertyAddress.city"}/>
      <HiddenField name={"property.currentMortgageBalance"}/>
      <HiddenField name={"cashOut"}/>
      <Row>
        <MultiChoiceField
          required={true}
          alignItems={"stretch"}
          flex={1}
          name="mortech.investorIds"
          options={api.investorOptions}
          showSelectedCount
          showSelectionActions
          label="Investor"
          placeholder="Select investor"
          width={250}
        />
        <ChoiceField required={true} flex={1} name="mortech.view" options={api.viewOptions} label="View" alignment={Alignment.BottomRight}
                     placeholder="Select view" width={250}/>
      </Row>
      <Row>
        <ChoiceCalcField
          searchable
          height={82}
          required={true}
          flex={1}
          name="mortech.product"
          label="Product category"
          options={api.productOptions}
          placeholder="Search here"/>
      </Row>
      <Row>
        <ChoiceField disabled={(api.sourceOptions.length <= 1)} required={true} flex={1} name="mortech.source"
                     options={api.sourceOptions}
                     label="Source"
                     placeholder="Select source"/>
        {/*<FormWhere conditions={WHEN_COSTCO}>*/}
        {/*  <ChoiceField disabled={true} required={true} flex={1} name="costco.membershipType"*/}
        {/*               options={api.costcoMembershipOptions}*/}
        {/*               label="Membership type"*/}
        {/*               placeholder="Select membership"/>*/}
        {/*</FormWhere>*/}
      </Row>
      <Row>
        <ChoiceCalcField required={true} flex={1} name="loanPurpose" label="Loan purpose"
                         options={api.loanPurposeOptions} placeholder="Select loan purpose" width={250}/>
        <FormWhere conditions={WHEN_PURCHASE}>
          {({ apply }) => <CurrencyField view={"write"} required={true}
                                         onBlur={() => api.calculate("property.value")}
                                         flex={1}
                                         validate={value => isPositiveInteger(value)}
                                         name={"property.value"}
                                         label={apply ? "Purchase price" : "Property Value"}
                                         placeholder="Enter value"/>}
        </FormWhere>
      </Row>
      <IsVaFHARefinance>
        {
          ({ apply }) => {
            return apply ?
              <Row>
                <IsVa>
                  <SwitchCalcField flex={1} name={"mortech.streamLine"} label={"Is IRRRL"}/>
                </IsVa>
                <IsFha>
                  <SwitchCalcField flex={1} name={"mortech.streamLine"} label={"Is Streamline"}/>
                </IsFha>
                <IsStreamLine>
                  <SwitchCalcField flex={1} name={"mortech.streamLineWithCredit"} label={"With credit"}/>
                  <SwitchCalcField flex={1} name={"mortech.streamLineWithAppraisal"} label={"With appraisal"}/>
                </IsStreamLine>
              </Row> :
              <HiddenField name={"mortech.streamLine"}/>;
          }
        }
      </IsVaFHARefinance>
      <Row>
        <ChoiceField required={true} className={LoanCriteriaClasses.LcHalfSize} name="property.type"
                     options={api.propertyTypeOptions} label="Property type" placeholder="Select Enter" width={250}/>
        <ChoiceField required={true} className={LoanCriteriaClasses.LcHalfSize} name="property.occupancy"
                     options={api.propertyOccupancyOptions} label="Occupancy" placeholder="Select occupancy" width={250} alignment={Alignment.BottomRight}/>
      </Row>
      <IsHUDReo>
        <SwitchCalcField flex={1} name={"mortech.hudReo"} label={"Is HUD Reo"}/>
      </IsHUDReo>
      <AddressRow/>
      <IsVa>
        {({ apply }) => !apply && <LoanLimitNote/>}
      </IsVa>
      <Row>
        <FormWhere conditions={WHEN_CASH_OUT}>
          <CurrencyField view={"write"}
                         validate={value => isPositiveInteger(value)}
                         required={true} onBlur={() => api.calculate("property.currentMortgageBalance")} flex={1}
                         name="property.currentMortgageBalance" className={LoanCriteriaClasses.LcHalfSize}
                         label="Current balance"
                         placeholder="Enter mortgage balance"/>
          <CurrencyField view={"write"} validate={value => isPositiveInteger(value)} required={true}
                         onBlur={() => api.calculate("cashOut")} name="cashOut"
                         className={LoanCriteriaClasses.LcHalfSize}
                         label="Cash amount" placeholder="Enter cash amount"/>
        </FormWhere>
      </Row>
      <Row>
        <FormWhere conditions={WHEN_RATE_TERM}>
          <CurrencyField view={"write"} validate={value => isPositiveInteger(value)}
                         required={true} onBlur={() => api.calculate("property.currentMortgageBalance")} flex={1}
                         name="property.currentMortgageBalance" className={LoanCriteriaClasses.LcHalfSize}
                         label="Current mortgage balance"
                         placeholder="Enter mortgage balance"/>
        </FormWhere>
        <LoanAmountField/>
        <FormWhere conditions={WHEN_PURCHASE}>
          <DownPaymentField/>
        </FormWhere>
      </Row>
      <Row>
        <IsFha>
          <FFMIPFeeField flex={1} name="financeMip"
                         className={LoanCriteriaClasses.LcHalfSize}
                         label="Finance MIP"/>
          <TotalLoanAmountField/>
        </IsFha>
        <IsVa>
          <FFMIPFeeField flex={1} name="financeFf"
                         label="Finance F/F"/>
          <TotalLoanAmountField/>
        </IsVa>
      </Row>
      <Row>
        <LtvField/>
        <FormWhere conditions={WHEN_HAS_SECONDARY_FINANCING}>
          {({ apply }) => (
            <PercentField view={"write"} validate={value => isPositiveInteger(value)} required={apply} flex={1}
                          name={"cltv"}
                          label={"CLTV"} disabled={!apply}
                          placeholder={"Enter CLTV"}/>)}
        </FormWhere>
      </Row>
      {/*<IsPmiEligible>*/}
      <Row>
        <PercentField view={"write"} validate={value => isPositiveInteger(value)} required={true} flex={1}
                      name={"dti"} label={"DTI"} precision={2}
                      placeholder={"Enter DTI"}/>
      </Row>
      {/*</IsPmiEligible>*/}
      <Row>
        <ChoiceCalcField required={true} flex={1} options={api.secondaryFinancingOptions} name={"secondaryFinancing"}
                         label={"Secondary financing"} placeholder={"Select financing"}/>
      </Row>
      <ScoreRow/>
      <IsVa>
        <Row>
          <SwitchCalcField flex={1} name={"firstUseOfVaProgram"} label={"First time use"}/>
          <VeteranTypeField options={api.veteranStatusOptions}/>
        </Row>
      </IsVa>
      <Row>
        <ChoiceField required={true} flex={1} name={"mortech.lockInDays"} options={api.lockInDays}
                     label={"Lock in days"} placeholder={"Select lock days"}/>
      </Row>
      <Row>
        <SwitchField name="firstTimeHomeBuyer" className={LoanCriteriaClasses.LcHalfSize}
                     label="First time home buyer"/>
        <IsFirstTimeHomeBuyer>
          <SwitchField name="mortech.amiLlpaWaiver" className={LoanCriteriaClasses.LcHalfSize}
                       label="AMI Waiver Eligible"/>
        </IsFirstTimeHomeBuyer>
        <SwitchField name="waiveEscrow" className={LoanCriteriaClasses.LcHalfSize} label="Waive escrow"/>
      </Row>
    </Container>
    <FormSpy subscription={{ submitting: true, dirtySinceLastSubmit: true }}>
      {
        ({ submitting, dirtySinceLastSubmit }) => <Row style={{ padding: 16 }} alignItems={"end"} justify={"center"}>
          <Button type={"submit"} style={{ paddingLeft: 84, paddingRight: 84 }}
                  disabled={submitting}>{(dirtySinceLastSubmit ? "UPDATE RATES" : "GET RATES")}</Button>
        </Row>
      }
    </FormSpy>

  </LoanCriteriaProvider>;
});

const Row: FC<BoxComponentProps> = React.memo(function Row(props) {
  return (
    <Box container direction={"row"} gap={"XXS"} {...props}>
      {props.children}
    </Box>
  );
});
const Container: FC = React.memo(function Row(props) {
  return (
    <Box container gap={"XS"} direction={"column"} flex={1} className={LoanCriteriaClasses.LoanCriteriaContainer}>
      {props.children}
    </Box>
  );
});
const AddressRow = React.memo(function AddressRow() {
  const [loadCounties, { data: { countiesByState = [] } = {}, called, loading: loadingCountiesByState }] = useLazyQuery<GetCountiesByState, GetCountiesByStateVariables>(GET_COUNTIES_BY_STATE);
  const { options: stateOptions } = getField("Address", "state");
  const { input: { value: state } } = useField("property.propertyAddress.state", { subscription: { value: true } });
  const { input: { value: zipCode, onChange: onChangeZipCode } } = useField("property.propertyAddress.zipCode", { subscription: { value: true } });
  const { input: { value: county, onChange: onChangeCounty } } = useField("property.propertyAddress.county", { subscription: { value: true } });
  const { input: { value: city, onChange: onChangeCity } } = useField("property.propertyAddress.city", { subscription: { value: true } });
  const countyRef = useRef<string>();
  const zipCodeRef = useRef<string>();
  const cityRef = useRef<string>();
  countyRef.current = county;
  zipCodeRef.current = zipCode;
  cityRef.current = city;
  const counties = useMemo(() => countiesByState.map(c => c.county), [countiesByState]);
  useEffect(() => {
    // loadInfo({ variables: { state } });
    loadCounties({ variables: { state } });
  }, [state]);
  const zipCodes: string[] = useMemo(() => {
    const zipCodes = [];
    countiesByState.forEach((c) => {
      zipCodes.push(...c.regions.map(region => region.zipCode));
    });
    return Array.from(new Set<string>(zipCodes));
  }, [countiesByState]);

  const zipCodesLimited = useMemo(() => {
    let filtered = [];
    if (zipCode) {
      filtered = zipCodes.filter(item => item.startsWith(zipCode));
    } else {
      filtered = zipCodes;
    }
    return filtered.slice(0, 15);
  }, [zipCode, zipCodes]);

  const validateZipCode = useCallback((value, allValues) => {
    if (!value) {
      return "Zip code is required.";
    }
    if (zipCodes.length > 0 && !zipCodes.includes(value)) {
      return `The ZIP Code does not exist in ${allValues.property.propertyAddress.state}`;
    }
  }, [zipCodes]);

  //hook to change zip code on county change if needed
  useEffect(() => {
    const zipsInCounty = countiesByState.find(e => e.county === county)?.regions.map(region => region.zipCode);
    if (county && zipsInCounty) {
      if (!zipCodeRef.current) {
        onChangeZipCode(zipsInCounty[ 0 ]);
      } else if (!zipsInCounty.includes(zipCodeRef.current)) {
        onChangeZipCode(zipsInCounty[ 0 ]);
      }
    }
  }, [countiesByState, county]);

  //hook to make default County selection (will select zip as well)
  useEffect(() => {
    if (zipCodes.length == 0 || counties.length == 0) {
      return;
    }
    let existingZip = zipCodes.includes(zipCodeRef.current);
    let existingCounty = countyRef.current.trim() ? counties.some((s) => s.includes(countyRef.current.trim())) : false;
    let existingExactCounty = counties.includes(countyRef.current);
    if (!existingCounty || !existingZip || !existingExactCounty) {
      if (existingCounty && !existingExactCounty) {
        existingZip = false;
      }
      if (existingZip) {
        let nextCity;
        let nextCounty;
        for (let { regions, county } of countiesByState) {
          let r = regions.find(region => region.zipCode == zipCodeRef.current);
          if (r) {
            nextCity = r.primaryCity;
            nextCounty = county;
            break;
          }
        }
        if (nextCounty && nextCounty != countyRef.current) {
          onChangeCounty(nextCounty);
        }
        if (nextCity && nextCity != cityRef.current) {
          onChangeCity(nextCity);
        }
        return;
      }
      if (existingCounty) {
        let nextCity;
        let nextCounty;
        let nextZipCode;
        for (let { regions, county } of countiesByState) {
          if (!county.includes(countyRef.current)) {
            continue;
          }
          let r = regions.find(region => (region.primaryCity == cityRef.current));
          if (county && !r) {
            r = regions[ 0 ];
          }

          nextCity = r.primaryCity;
          nextZipCode = r.zipCode;
          nextCounty = county;
          break;
        }

        if (nextCounty && nextCounty != countyRef.current) {
          onChangeCounty(nextCounty);
        }
        if (nextZipCode && nextZipCode != zipCodeRef.current) {
          onChangeZipCode(nextZipCode);
        }
        if (nextCity && nextCity != cityRef.current) {
          onChangeCity(nextCity);
        }
        return;
      }
      onChangeZipCode(zipCodes[ 0 ]);
    }
  }, [zipCodes, counties]);

  //hook to change county/city on zip code change if needed
  useEffect(() => {
    let nextCity;
    let nextCounty;
    if (!zipCodes) {
      return;
    }
    let existingCounty = countiesByState.find((c) => (c.county == countyRef.current));
    let existingRegion = existingCounty?.regions.find((r) => (r.zipCode == zipCode));

    if (existingRegion) {
      nextCity = existingRegion.primaryCity;
    } else {
      for (let { regions, county } of countiesByState) {
        let r = regions.find(region => region.zipCode == zipCode);
        if (r) {
          nextCity = r.primaryCity;
          nextCounty = county;
          break;
        }
      }
    }
    if (nextCounty && nextCounty != countyRef.current) {
      onChangeCounty(nextCounty);
    }
    if (nextCity && nextCity != cityRef.current) {
      onChangeCity(nextCity);
    }
  }, [zipCode]);
  return (
    <>
      <Row>
        <ChoiceField searchable required={true} name="property.propertyAddress.state" options={stateOptions}
                     placeholder={"Search here"} height={64}
                     className={LoanCriteriaClasses.LcHalfSize} label="State" width={250}/>
        <Field name={"property.propertyAddress.zipCode"}
               validate={validateZipCode}
               subscription={{ submitting: true, value: true, error: true, submitError: true, touched: true }}>
          {({ input, meta: { submitting, error, submitError, touched } }) => {
            const hasError = (touched && !!error) || !!submitError;
            return <Autocomplete
              {...input}
              disabled={!!submitting}
              name={"property.propertyAddress.zipCode"}
              className={LoanCriteriaClasses.LcHalfSize}
              required={true}
              label="Zip code"
              state={(hasError && InputState.Error) || undefined}
              message={hasError ? (error || submitError) : undefined}
              options={zipCodesLimited}
              height={45}
              onInputChange={(q) => {
                input.onChange(q);
              }}
              loading={loadingCountiesByState}
              onSelect={(e) => {
                input.onChange(e);
              }}
              placeholder="Enter zip code"
            />;
          }}
        </Field>
      </Row>
      <Row>
        <ChoiceField
          required={true}
          flex={1}
          loading={loadingCountiesByState}
          name="property.propertyAddress.county"
          options={counties}
          className={LoanCriteriaClasses.LcHalfSize}
          label="County"
          placeholder={"Select county"}/>
      </Row>
    </>
  );
});
export const GET_COUNTIES_BY_STATE = gql`
  query GetCountiesByState($state:String!,$type:String) {
    countiesByState(state:$state,type:$type)
  }
`;
const ScoreRow = React.memo(function ScoreRow() {

  const { input: { value: members } } = useField("members", { subscription: { value: true } });
  const names = useMemo(() => {
    const names = [];
    for (let [index, member] of Object.entries<{ type: string }>(members)) {
      if (["borrower", "co_borrower"].includes(member.type)) {
        names.push(`members[${index}].creditScore`);
      }
    }
    return names;
  }, [members]);
  return (
    <Row wrap={"wrap"}>
      {
        names.map((name, index) => <ScoreField validate={value => isPositiveInteger(value)} flex={1} key={name}
                                               label={`FICO score ${index + 1}`}
                                               name={name} placeholder={"Enter score"}/>)
      }
    </Row>
  );
});
const LoanLimitNote = React.memo(function LoanLimitNote() {
  const loanLimit = useLoanLimit();
  return (
    !!loanLimit &&
    <FormSpy subscription={{ submitting: true }}>
      {
        ({ submitting }) =>
          <Row alignItems={"center"}
               className={classNames(LoanCriteriaClasses.LoanCriteriaNote, { [ LoanCriteriaClasses.LoanCriteriaDisabledNote ]: !!submitting })}>
            <FontIcon type={"info"}/> Maximum loan amount
            <span style={{ fontWeight: "bold" }}>{`$${format(loanLimit, 2)}`}</span>
          </Row>
      }
    </FormSpy>
  );
});
const IsPmiEligible: ExoticComponent<{
  children: FunctionOrReactNode
}> = React.memo(function IsPmiEligible({ children }) {
  const { product: { type }, settings } = useContext(LoanCriteriaContext);
  const isConventional = type === "conventional";
  const { input: { value: downPayment } } = useField("downPayment", { subscription: { value: true } });
  const { input: { value: propertyValue } } = useField("property.value", { subscription: { value: true } });
  const minPercent = settings.conventional.pmiDownPaymentBoundary;
  const apply = isConventional && (downPayment < propertyValue * minPercent / 100);
  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : null}</>;
});
const IsFha: ExoticComponent<{ children: FunctionOrReactNode }> = React.memo(function ISFwa({ children }) {
  const { product: { type } } = useContext(LoanCriteriaContext);
  const apply = type === "fha";
  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : null}</>;
});
const IsVa: ExoticComponent<{ children: FunctionOrReactNode }> = React.memo(function IsVa({ children }) {
  const { product: { type } } = useContext(LoanCriteriaContext);
  const apply = type === "va";
  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : null}</>;
});
const IsVaFHARefinance: ExoticComponent<{
  children: FunctionOrReactNode
}> = React.memo(function IsVaFHARefinance({ children }) {
  const { product: { type } } = useContext(LoanCriteriaContext);
  const { input: { value: loanPurpose } } = useField("loanPurpose", { subscription: { value: true } });
  const apply = (["va", "fha"].includes(type) && loanPurpose == "rate_term_refinance");
  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : null}</>;
});
const IsStreamLine: ExoticComponent<{
  children: FunctionOrReactNode
}> = React.memo(function IsStreamLine({ children }) {
  const { input: { value: streamLine } } = useField("mortech.streamLine", { subscription: { value: true } });
  const apply = streamLine;
  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : null}</>;
});
const IsHUDReo: ExoticComponent<{ children: FunctionOrReactNode }> = React.memo(function IsHUDReo({ children }) {
  const { initialValues } = usePricingView();
  const { input: { value: loanPurpose } } = useField("loanPurpose", { subscription: { value: true } });
  const { product: { type } } = useContext(LoanCriteriaContext);
  const apply = (type == "fha" && loanPurpose == "purchase" && initialValues.property.use === "primary_residence");
  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : null}</>;
});

const FFMIPFeeField: FC<SwitchFieldProps> = React.memo(function FFMIPFeeField(props) {
  let showFee: boolean;
  const { values: { loanAmount, totalLoanAmount, financeMip, financeFf } } = useFormState();
  const { meta: { active } } = useField("loanAmount", { subscription: { active: true } });
  const [fee, setFee] = useState(() => format((totalLoanAmount - loanAmount), 2));

  switch (props.name) {
    case "financeMip":
      showFee = financeMip;
      break;
    case "financeFf":
      showFee = financeFf;
      break;
  }
  useEffect(() => {
    if (!active) {
      setFee(format((totalLoanAmount - loanAmount), 2));
    }
  }, [active, totalLoanAmount, loanAmount]);
  return <SwitchCalcField placeholder={showFee && `$ ${fee}`} {...props}/>;
});
const IsFirstTimeHomeBuyer: ExoticComponent<{
  children: FunctionOrReactNode
}> = React.memo(function IsVa({ children }) {
  const { input: { value: firstTimeHomeBuyer } } = useField("firstTimeHomeBuyer", { subscription: { value: true } });
  const apply = firstTimeHomeBuyer;
  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : null}</>;
});
type FunctionOrReactNode = ReactNode | ((value: { apply: boolean }) => ReactNode)
