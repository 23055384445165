import { useMemo }             from "react";
import { Dispatch }            from "react";
import React                   from "react";
import { Box }                 from "../../..";
import { Button }              from "../../..";
import { useImperativeState }  from "../../..";
import { BoxComponentProps }   from "../../..";
import { Month }               from "../Month";
import { Time }                from "../Time";
import { DatepickerContainer } from "./DatepickerContainer";
import DatepickerContext       from "./DatepickerContext";
import { FirstDayOfWeek }      from "./useDatepicker";
import { OnDatesChangeProps }  from "./useDatepicker";
import { FocusedInput }        from "./useDatepicker";
import { START_DATE }          from "./useDatepicker";
import { useDatepicker }       from "./useDatepicker";

export interface DatepickerBaseProps extends Omit<BoxComponentProps, "onChange"> {
  className?: string
  range: true | false,
  large?: boolean,
  type?: "date" | "datetime",
  yearProps?: any
  onDatesChange?: Dispatch<OnDatesChangeProps>
  onApply?: () => void
}

export interface RangeDatepickerProps extends DatepickerBaseProps {
  range: true,
  startDate: Date | null,
  endDate: Date | null,
  isDateBlocked?: (day:Date) => boolean,
  firstDayOfWeek?: FirstDayOfWeek
}

export interface SingleDatepickerProps extends DatepickerBaseProps {
  range: false,
  date: Date | null,
  isDateBlocked?: (day:Date) => boolean,
  firstDayOfWeek?: FirstDayOfWeek
}

export type DatepickerProps = RangeDatepickerProps | SingleDatepickerProps;

export const Datepicker = React.forwardRef(function Datepicker(props: DatepickerProps, ref) {
  const { className, large, range, onDatesChange, type, firstDayOfWeek, isDateBlocked, yearProps, onApply, ...otherProps } = props;
  const [state, setState] = useImperativeState({
    startDate: isRangeDatepicker(props) ? props.startDate : props.date,
    endDate: isRangeDatepicker(props) ? props.endDate : props.date,
    focusedInput: START_DATE as FocusedInput
  }, onDatesChange);
  const now = useMemo(() => new Date(), []);
  const datepicker = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput as FocusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths: 1,
    isDateBlocked: isDateBlocked,
    firstDayOfWeek: firstDayOfWeek || 0
  });

  const { activeMonths } = datepicker;
  function handleDateChange(data) {
    if (!range) {
      setState({
        startDate: data.startDate,
        endDate: data.startDate,
        focusedInput: START_DATE
      });
      return;
    }
    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
    } else {
      setState(data);
    }
  }
  const {
    startDate,
    endDate,
    date,
    ...containerProps
  } = otherProps as any;
  return (
    <DatepickerContainer
      large={large}
      className={className}
      ref={ref}
      {...containerProps}
    >
      <DatepickerContext.Provider value={datepicker}>
        {activeMonths.map(month => (
          <Month
            yearProps={yearProps}
            range={range}
            startDate={state.startDate}
            endDate={state.endDate}
            now={now}
            focusedInput={state.focusedInput}
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={datepicker.firstDayOfWeek}
          />
        ))
        }
        {type === "datetime" &&
        <Time
          range={range}
          now={now}
          startDate={state.startDate}
          endDate={state.endDate}
          date={state.startDate}
        />
        }
        {onApply && !range && <Box container justify={"center"}>
          <Button onClick={onApply} disabled={!state.startDate}>DONE</Button>
        </Box>}
      </DatepickerContext.Provider>
    </DatepickerContainer>
  );
});

Datepicker.defaultProps = {
  large: false,
  range: true,
  type: "date"
};

function isRangeDatepicker(props: DatepickerProps): props is RangeDatepickerProps {
  return props.range;
}
