import React                        from "react";
import { TypeAttributes }           from "../../@types/common";
import { RsRefForwardingComponent } from "../../@types/common";
import { AutoCompleteProps }        from "../../AutoComplete";
import { IconType }                 from "../../index";
import { AutoComplete }             from "../../index";
import { Cell }                     from "../../index";
import { useClassNames }            from "../../utils";

export interface AutoCompleteCellProps extends AutoCompleteProps {
  active?: boolean;
  data: Record<string, any>[];
  /** A tag can have different colors */
  status?: TypeAttributes.Alert;

  span?: number;

  /**Tooltip text*/
  helperText?: string;

  /** Revers items order */
  revers?: boolean;

  icon?: IconType;

  /** space-betweem */
  space?: boolean;

  affected?: boolean;
}

export const AutoCompleteCell: RsRefForwardingComponent<"div", AutoCompleteCellProps> = React.forwardRef((props: AutoCompleteCellProps, ref) => {
  const {
    className,
    classPrefix = "cell-auto-complete",
    helperText,
    status,
    affected,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Cell status={status} helperText={helperText} space={true} className={classes} affected={affected}>
    <AutoComplete
      menuClassName={prefix("menu")}
      containerPadding={0}
      {...rest}
    />
  </Cell>;
});
