import React                from "react";
import { useCallback }      from "react";
import { FC }               from "react";
import { BaseFieldProps }   from "../BaseField";
import { BaseField }        from "../BaseField";
import MultiUrlEditField    from "./MultiUrlEditField";
import MultiUrlReadField    from "./MultiUrlReadField";
import { validURL }         from "../../../utils/helpers";
import { InputProps }       from "../../Input/Input";

export type MultiUrlFieldProps = BaseFieldProps & InputProps;

export const MultiUrlField: FC<MultiUrlFieldProps> = React.memo(function MultiUrlField(props) {

  const validate = useCallback((value) => {

    const data = value ?? []
    const invalidItems = data.filter(item => !validURL(item))
    if (invalidItems.length > 0) {
      return `${props.label} is invalid`;
    }
  }, []);

  return (
    <BaseField
      {...props}
      validate={validate}
      format={(value) => value || []}
      readView={<MultiUrlReadField/>}
      editView={<MultiUrlEditField/>}
    />
  );
});
