import { useQuery }         from "@apollo/client";
import { schemaVar }        from "../../reactiveVars";
import { ViewerUserRole }   from "./__types__/ViewerUserRole";
import { VIEWER_USER_ROLE } from "./queries.grapql";
import { useACL }           from "./useACL";
import { UseACL }           from "./useACL";

export function usePolicy() {
  const { data: { viewer } } = useQuery<ViewerUserRole>(VIEWER_USER_ROLE, {
    fetchPolicy: "cache-only"
  });
  const acl = useACL();
  const policy = {
    get viewer() {
      return viewer.user;
    },
    can(type: "create" | "get" | "find" | "update" | "delete", object: UseACL) {
      const schemas = schemaVar();
      const { classLevelPermissions } = schemas[ object.__typename ];
      if (classLevelPermissions?.[ type ] && !viewer) {
        return false;
      }
      let isAllowUser = !!classLevelPermissions[ type ]?.[ `user:${viewer.user.objectId}` ];
      let isAllowRole = !!classLevelPermissions[ type ]?.[ `role:${viewer.user.role}` ];

      if (classLevelPermissions[ type ]?.[ "*" ] && classLevelPermissions[ type ]?.[ `user:${viewer.user.objectId}` ] !== false && classLevelPermissions[ type ]?.[ `role:${viewer.user.role}` ] !== false) {
        return true;
      }
      const allow = (isAllowUser || isAllowRole);
      if (allow) {
        return true;
      }
      switch (type) {
        case "get":
        case "find":
          return acl.canRead(object);
        default:
          return acl.canWrite(object);
      }
    },
    canRead<T extends UseACL>(object: T) {
      return policy.can("get", object);
    },
    canFind<T extends UseACL>(object: T) {
      return policy.can("find", object);
    },
    canCreate<T extends UseACL>(object: T) {
      return policy.can("create", object);
    },
    canUpdate<T extends UseACL>(object: T) {
      return policy.can("update", object);
    },
    canDelete<T extends UseACL>(object: T) {
      return policy.can("delete", object);
    }
  };
  return policy;
}
