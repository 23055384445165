import React                   from "react";
import { FC }                  from "react";
import { MicrophoneAccess }    from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { Section }             from "@relcu/ui";
import { useReactiveVar }      from "@apollo/client";
import { microphoneAccessVar } from "../../../../reactiveVars";
import { Devices }             from "./Components/Devices";
import { SoundSectionClasses } from "./SoundSectionClasses";
import { TwilioInfo }          from "./Components/TwilioInfo";
import "./sound-section.css";

export const SoundSection: FC<any> = React.memo(function SoundSection(props) {
  const { title, view, editable } = props;
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  return !microphoneAccess ?
    <MicrophoneAccess/> :
    <Section view={view} title={title} editable={editable}>
      <Box container flex={1} wrap={"wrap"} className={SoundSectionClasses.Sound}>
        <Devices flexBasis={"68%"}/>
        <TwilioInfo flexBasis={"32%"}/>
      </Box>
    </Section>;
});

export default SoundSection;
