import React                from "react";
import { useBoxProps }      from "../../Box";
import { Box }              from "../../Box";
import { Label }            from "../../Label";
import { useConsumeField }  from "../BaseField";
import { BaseFieldClasses } from "../BaseField";

export const DateReadField = React.memo(function DateReadField(props) {
  const { input: { readPlaceholder, label, required, value, name, ...dateProps } } = useConsumeField();
  return <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
              alignSelf={"start"} {...useBoxProps(dateProps)}>
    <Label>{label}</Label>
    {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
  </Box>;
});

export default DateReadField;
