import React                        from "react";
import { RsRefForwardingComponent } from "../@types/common";
import { WhisperProps }             from "../index";
import { IconButton }               from "../index";
import { Whisper }                  from "../index";
import { PopoverProps }             from "../index";
import { Dropdown, Popover }        from "../index";
import PlusIcon                     from "@rsuite/icons/Plus";
import { PositionChildProps }       from "../Overlay/Position";
import { ToggleDropdownItem }       from "./ToggleDropdownItem";

export interface ToggleDropdownProps extends PopoverProps, Omit<Omit<WhisperProps, "children">, "speaker"> {
  toggleRenderer?: (() => React.ReactElement) | React.ReactElement;
  dropDownStyle?: React.CSSProperties;
  speaker?:
    | React.ReactElement
    | ((
    props: PositionChildProps &
      Pick<React.HTMLAttributes<HTMLElement>, "id" | "onMouseEnter" | "onMouseLeave"> & {
      onClose: (delay?: number) => NodeJS.Timeout | void;
    },
    ref: React.RefCallback<HTMLElement>
  ) => React.ReactElement);
}

export interface ToggleDropdownComponent extends RsRefForwardingComponent<"div", ToggleDropdownProps> {
  Item?: typeof ToggleDropdownItem;
}

export const ToggleDropdown: ToggleDropdownComponent = React.forwardRef((props: ToggleDropdownProps, ref) => {
  const {
    as,
    className,
    children,
    toggleRenderer,
    classPrefix = "btn",
    speaker,
    dropDownStyle,
    ...rest
  } = props;

  const renderMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = eventKey => {
      onClose();
      // unlockScroll();
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} arrow={false} full>
        {
          React.Children.count(children) ?
            <Dropdown.Menu onSelect={handleSelect} style={dropDownStyle}>
              {children}
            </Dropdown.Menu>
            :
            null
        }
      </Popover>
    );
  };

  return <Whisper
    placement="bottomStart"
    trigger="click"
    enterable
    speaker={speaker ?? renderMenu}
    {...rest}>
    {
      toggleRenderer ?
        typeof toggleRenderer == "function" ? toggleRenderer() : toggleRenderer
        :
        <IconButton appearance="contained" icon={<PlusIcon/>} circle style={{flexShrink: 0}}/>
    }
  </Whisper>;
});

ToggleDropdown.Item = ToggleDropdownItem;


