import React                     from "react";
import { JsonSectionProps }      from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { Section }               from "@relcu/ui";
import { LeadMemberEditSection } from "./LeadMemberEditSection";
import { LeadMemberReadSection } from "./LeadMemberReadSection";
import "./lead-member.css";

export const LeadMemberSection = React.memo<JsonSectionProps>(function LeadMemberSection(props) {
  const { title, view, editable, ...rest } = props;
  return (
    <Section view={view} title={title} editable={editable}>
        <Box flex={1} container direction={"column"} gap={"XXS"} style={{ maxWidth: "100%" }}>
          {props.view == "create" ? <LeadMemberEditSection/> : <LeadMemberReadSection {...rest}/>}
        </Box>
    </Section>
  );
});

