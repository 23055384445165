import React                    from "react";
import { useMemo }              from "react";
import { useBoxProps }          from "../../Box";
import { Box }                  from "../../Box";
import { Label }                from "../../Label";
import { useConsumeField }      from "../BaseField";
import { BaseFieldClasses }     from "../BaseField";
import { MultiUrlFieldProps }   from "./MultiUrlField";
import { LinkExternal }         from "../../LinkExternal";
import { LinkExternalColors }   from "../../LinkExternal";
import { handlebars }           from "../../../utils/helpers";
import { useSource }            from "../../Layout/JsonPage";

export const MultiUrlReadField = React.memo(function MultiInputReadField() {
  const { $object, $viewer } = useSource();
  const {input: {template, label, required, readPlaceholder, value, ...inputProps}} = useConsumeField<MultiUrlFieldProps>();

  const templated = useMemo(() => {
    if (template?.url && template?.field) {
      const source = { ...$object, $viewer };
      return (source[template?.field] ?? []).map(item => (
        {
          url: handlebars(template.url, item),
          label: handlebars(template.label || template.url, item)
        }
      ))

    }
    return null;
  }, [$object, template]);

  const isTemplated = useMemo(() => templated?.length > 0, [template])
  const hasValue = useMemo(() => value?.length > 0, [value])

  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(inputProps)}>
      <Label required={required}>{label}</Label>

      {isTemplated &&
        templated.map(({url, label}, key) => (
          <LinkExternal key={key} to={url} color={LinkExternalColors.Primary} allowCopy>
            {label}
          </LinkExternal>
        ))
      }

      {(!isTemplated && value) &&
        value.map((item, key) => (
          <LinkExternal to={item} key={key} color={LinkExternalColors.Primary} allowCopy>
            {item}
          </LinkExternal>
        ))
      }

      {(!isTemplated && !hasValue) &&
        <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
      }
    </Box>
  );
});

export default MultiUrlReadField;
