import { NumberField }   from "@relcu/ui";
import React             from "react";
import { FC }            from "react";
import { StepHeader }    from "@relcu/ui";
import { StepInterface } from "@relcu/ui";
import { StepContainer } from "@relcu/ui";
import { TextField }     from "@relcu/ui";
import { StepRow }       from "@relcu/ui";
import { StepSection }   from "@relcu/ui";
import { StepBody }      from "@relcu/ui";

export const Name: FC<StepInterface> = React.memo(function Name(props) {
  const { title, name } = props;

  return (
    <StepContainer>
      <StepHeader
        title={title}/>
      <StepBody>
        <StepSection title="Name" alignSelf={"center"} style={{ width: 522 }}>
          <StepRow>
            <TextField name={name} flex={1} placeholder={"Type section name here"}/>
          </StepRow>
        </StepSection>
      </StepBody>
    </StepContainer>
  );
});
