import { gql }                                       from "@apollo/client";
import { useQuery }                                  from "@apollo/client";
import { getIn }                                     from "@relcu/form";
import { useSource }                                 from "@relcu/ui";
import { FILE_FRAGMENT }                             from "../../../../graph/operations.graphql";
import { getHumanized }                              from "../../../../utils/schemaUtils";
import { GetLoanProposals_loanProposals_edges_node } from "./__types__/GetLoanProposals";
import { GetLoanProposalsVariables }                 from "./__types__/GetLoanProposals";
import { GetLoanProposals }                          from "./__types__/GetLoanProposals";
import { usePreviewLoanProposal }                    from "../../../Modal/ProposalPdfModal/usePreviewLoanProposal";

type LoanProposal = GetLoanProposals_loanProposals_edges_node
export function useLoanProposalView() {
  const { $object, $viewer } = useSource();
  const { preview, modalContext } = usePreviewLoanProposal($object.objectId);
  const { data: { loanProposals: { edges = [], count } = {} } = {}, loading } = useQuery<GetLoanProposals, GetLoanProposalsVariables>(GET_LOAN_PROPOSALS, {
    variables: {
      id: $object.id
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  return {
    edges,
    loading,
    count,
    modalContext,
    viewerRole: $viewer.role,
    objectId: $viewer.objectId,
    loanProposals: edges,
    getColumnValue(row, column) {
      if (column.name == "cashAtClosing") {
        const propertyVal = row.loanCriteria.loanPurpose == "purchase" ? row.loanCriteria.property.value : row.loanCriteria.property.currentMortgageBalance;
        const cashAtClosing = row.totalClosingCost + propertyVal - row.loanCriteria.loanAmount;
        column.properties = {
          ...column.properties,
          "data-support-text": cashAtClosing > 0 ? "(From you)" : "(To you)"
        };
        return getHumanized(row.__typename, column.name, cashAtClosing);
      }
      return getHumanized(row.__typename, column.name, getIn(row, column.name));
    },
    onPreview({ file, objectId, image }: Partial<LoanProposal>) {
      preview({file,proposalId: objectId, image});
    }
  };
}


export const GET_LOAN_PROPOSALS = gql`
  ${FILE_FRAGMENT}
  query GetLoanProposals($id: ID!) {
    loanProposals(where: {lead: {have: {id: { equalTo: $id}}}}, order: [createdAt_DESC]) {
      count
      edges {
        node {
          objectName
          id
          objectId
          lead {
            id
          }
          file {
            ...File
          }
          image {
            ...File
          }
          sent
          offers {
            edges {
              node {
                objectName
                createdAt
                selectedRate{
                  quoteDetails {
                    apr
                    rate
                    price
                  }
                }
                monthlyPayment {
                  total
                }
                closingCost {
                  total
                }
                totalClosingCost
                loanCriteria{
                  loanPurpose
                  loanAmount
                  property{
                    value
                    currentMortgageBalance
                  }
                }
                prepaid {
                  total
                }
              }
            }
          }
        }
      }
    }
  }
`;
