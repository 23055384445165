import { SyntheticEvent }   from "react";
import { modal }            from "../../Modal";
import ImageUploadDialog    from "../../Modal/ImageUploadDialog/ImageUploadDialog";
import { ImageUploadProps } from "./ImageUpload";

export function useImageUpload(props: ImageUploadProps) {
  const { onUpload, onChange, acceptedTypes } = props;
  const handleOpenModal = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { destroy } = modal(ImageUploadDialog, {
      acceptedTypes,
      closeIcon: true,
      onConfirm: async (file: File | string) => {
        const res = await onUpload(file);
        if (res === true) {
          destroy();
        }
      },
      onCancel: () => {
        destroy();
      }
    });
  };

  const handleFieldClear = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(null);
  };

  return {
    handleOpenModal,
    handleFieldClear
  };
}