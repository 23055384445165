import React              from "react";
import { FC }             from "react";
import { IconType }       from "../../..";
import { BadgeAlignment } from "../../..";
import { BadgeSize }      from "../../..";
import { BadgeColors }    from "../../..";
import { BadgeVariants }  from "../../..";
import { Badge }          from "../../..";

export type BadgeStatus = "available" | "away" | "busy" | "out_of_work" | string;
export interface StatusBadgeProps {
  status: BadgeStatus;
  variant?: BadgeVariants;
  outline?: boolean;
  size?: BadgeSize;
  alignment?: BadgeAlignment;
  color?: BadgeColors;
}
export const StatusBadge: FC<StatusBadgeProps> = React.memo(function StatusBadge(props) {
  const badgeIcon: { [ key: string ]: IconType } = {
    available: "done",
    busy: "remove_circle_outline",
    away: "access_time",
    offline: "remove",
    out_of_work: "work_off"
  };
  const badgeColor = {
    available: BadgeColors.Success,
    busy: BadgeColors.Error,
    away: BadgeColors.Warning,
    offline: BadgeColors.Grey,
    out_of_work: BadgeColors.Grey
  };
  if (!badgeColor[ props.status ] && !props.color) {
    return (
      <>
        {props.children}
      </>
    );
  }
  return (
    <Badge
      size={props.size}
      variant={props.variant}
      alignment={props.alignment}
      icon={props.variant !== BadgeVariants.Dot ? badgeIcon[ props.status ] : undefined}
      color={props.color || badgeColor[ props.status ]}
      outline={props.outline}
    >
      {props.children}
    </Badge>
  );
});
StatusBadge.defaultProps = {
  variant: BadgeVariants.Dot,
  outline: true,
  alignment: BadgeAlignment.BottomRight
};
