export const enum FilterClasses {
  Filters = "filters",
  FiltersOpen = "filters-open",
  FiltersHeader = "filters-header",
  FiltersBody = "filters-body",
  FilterSideBar = "filters-sidebar",
  FilterSideBarButton = "filters-sidebar-button",
  FilterSideBarButtonSelected = "filters-sidebar-button-selected",
  FilterSideBarItem = "filters-sidebar-item",
  FilterSideBarItemActions = "filters-sidebar-item-actions",
  FilterSideBarItemSelected = "filters-sidebar-item-selected",
  FilterSideBarItemEdit = "filters-sidebar-item-edit",
  FilterSideBarItemSelectedRead = "filters-sidebar-item-selected-read",
  FiltersItem = "filters-item",
  FiltersApply = "filters-apply"
}
