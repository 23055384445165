import React                        from "react";
import { WithAsProps }              from "../@types/common";
import { RsRefForwardingComponent } from "../@types/common";
import { FlexboxGridProps }         from "../index";
import { FlexboxGrid }              from "../index";
import { useClassNames }            from "../utils";

export interface CellGroupHeaderProps extends FlexboxGridProps {
  divided?: boolean;
}

export const CellGroupHeader: RsRefForwardingComponent<"div", CellGroupHeaderProps> = React.forwardRef((props: CellGroupHeaderProps, ref) => {
  const {
    as: Component = FlexboxGrid,
    className,
    children,
    classPrefix = "cell-group-header",
    divided,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix({ divided })
  );

  return <Component
    className={classes}
    align={"middle"}
    justify={"start"}
    {...rest}>
    {children}
  </Component>;
});
