import { FC, ReactElement } from "react";
import React                from "react";
import { classNames }       from "../../../utils/classNames";
import { ButtonProps }      from "../../Button";
import { ButtonSizes }      from "../../Button";
import { ButtonVariants }   from "../../Button";
import { ButtonColors }     from "../../Button";
import { Button }           from "../../Button";
import { TypographyLine }   from "../../Typography";
import { Typography }       from "../../Typography";
import { TypographyWeight } from "../../Typography";
import { TypographySize }   from "../../Typography";
import { TypographyColor }  from "../../Typography";
import { DialPadClasses }   from "./DialPadClasses";

export interface DialPadProps extends ButtonProps {
  digit: string | number,
  text?: ReactElement | string,
}

export const DialPadButton: FC<DialPadProps> = React.memo(function DialPad(props) {
  const { onClick, className, digit, text, ...p } = props;
  const classes = classNames(DialPadClasses.DialPadButton, className);
  return <Button
    onlyIcon
    onClick={onClick}
    color={ButtonColors.Gray}
    variant={ButtonVariants.Outline}
    size={ButtonSizes.Large}
    className={classes} {...p}>
    <Typography
      weight={TypographyWeight.Medium}
      lineHeight={TypographyLine.LineBody1}
      size={TypographySize.SubtitleSmall}>
      {digit}
    </Typography>
    {text && <Typography
      weight={TypographyWeight.Regular}
      lineHeight={TypographyLine.LineCaption}
      size={TypographySize.TextTiny}
      color={TypographyColor.Secondary}>
      {text}
    </Typography>}
  </Button>;
});
