import React               from "react";
import { useRef }          from "react";
import { useContext }      from "react";
import { useCallback }     from "react";
import { Select }          from "../../..";
import { FontIcon }        from "../../..";
import { Box }             from "../../..";
import { CalendarClasses } from "../CalendarClasses";
import DatepickerContext   from "../Datepicker/DatepickerContext";
import { formatAMPM }      from "../time-fns";
import { setTime }         from "../time-fns";
import { getHalf }         from "../time-fns";
import { timeFormat }      from "../time-fns";
import { formatTime }      from "../time-fns";

export interface TimeBaseProps {
  range: true | false
  now: Date
}

export interface RangeTimeProps extends TimeBaseProps {
  range: true
  startDate: Date | null;
  endDate: Date | null;
}

export interface SingleTimeProps extends TimeBaseProps {
  range: false
  date: Date | null
}

export type TimeProps = SingleTimeProps | RangeTimeProps;

export function Time(props: TimeProps) {
  const { onDateSelect } = useContext(DatepickerContext);
  const { now } = props;
  const date = isSingleTime(props) ? props.date : props.startDate;
  const change = useCallback((dirtyDate: Date, type, value?) => {
    const date = setTime(dirtyDate, type, value);
    onDateSelect(date);
  }, [onDateSelect]);
  return (
    <Box container direction={"column"} className={CalendarClasses.CalendarTime} gap={"XXS"}>
      <Box container direction={"row"} gap={"XXS"} alignItems={"center"}>
        <FontIcon className={CalendarClasses.TimeIcon} type={"access_time"}/>
        <Select
          height={43}
          disabled={!date}
          mode={"button"}
          value={formatAMPM(date || now).hours}
          options={timeFormat(12, 1)}
          onChange={(v) => change(date, "hour", v)}
        />
        <Box>
          :
        </Box>
        <Select
          height={43}
          disabled={!date}
          mode={"button"}
          value={formatTime(date ? date.getMinutes() : now.getMinutes())}
          options={timeFormat(59, 0)}
          onChange={(v) => change(date, "minute", v)}
        />
        <Select
          disabled={!date}
          mode={"button"}
          value={getHalf(date || now)}
          options={["AM", "PM"]}
          onChange={(v) => change(date, v)}
        />
      </Box>
      {!isSingleTime(props) &&
      <Box container direction={"row"} gap={"XXS"} alignItems={"center"}>
        <FontIcon className={CalendarClasses.TimeIcon} type={"arrow_forward"}/>
        <Select
          height={43}
          disabled={!props.endDate}
          mode={"button"}
          value={formatAMPM(date || new Date).hours}
          options={timeFormat(12, 1)}
          onChange={(v) => change(date, "hour", v)}
        />
        <Box>:</Box>
        <Select
          height={43}
          disabled={!props.endDate}
          mode={"button"}
          value={formatAMPM(props.endDate || now).minutes}
          options={timeFormat(60, 0)}
          onChange={(v) => change(date, "minute", v)}
        />
        <Select
          disabled={!props.endDate}
          mode={"button"}
          value={formatAMPM(props.endDate || now).ampm} options={["AM", "PM"]}
          onChange={(v) => change(props.endDate, v)}
        />
      </Box>
      }
    </Box>
  );
}

function isSingleTime(props: TimeProps): props is SingleTimeProps {
  return !props.range;
}
