import React, { useState }       from "react";
import { Property }              from "csstype";
import { ButtonSizes }           from "../..";
import { Alignment }             from "../../";
import { classNames }            from "../..";
import { applyBoxItemStyles }    from "../..";
import { BoxItemComponentProps } from "../..";
import { Box }                   from "../..";
import { ButtonVariants }        from "../..";
import { Button }                from "../..";
import { ButtonColors }          from "../..";
import { Menu }                  from "../..";
import { MenuItem }              from "../..";
import { Popper }                from "../..";
import { Tooltip }               from "../..";

export interface ButtonGroupProps extends BoxItemComponentProps {
  variant?: ButtonVariants,
  color?: ButtonColors,
  disabled?: boolean,
  buttonMenu?: boolean,
  collapse?: boolean
  alignment?: Alignment
  height?: Property.Height<any>
  size?: ButtonSizes
  tooltip?: string
  tooltipAlignment?: Alignment
  onOpen?(e)
  onClose?()
}
const defaultButtonGroupProps: ButtonGroupProps = {
  variant: ButtonVariants.Fill,
  color: ButtonColors.Primary,
  disabled: false,
  buttonMenu: false,
  collapse: false,
  alignment: Alignment.BottomRight,
  tooltipAlignment: Alignment.BottomRight
};

export const ButtonGroup = (props: ButtonGroupProps) => {
  let properties = applyBoxItemStyles<ButtonGroupProps>(props);
  const {
    className,
    variant,
    color,
    collapse,
    children,
    disabled,
    alignment,
    buttonMenu,
    height,
    onOpen,
    onClose,
    size,
    tooltip,
    tooltipAlignment,
    ...p
  } = properties;

  const [anchorBounding, setAnchorBounding] = useState(null);
  const classes = classNames(ButtonGroupClasses.ButtonGroup, {
    [ ButtonGroupClasses.Fill ]: variant == ButtonVariants.Fill
  }, className);
  let buttonChildren = [];
  let popupChildren = [];
  let newProps = {};

  if (disabled) {
    newProps[ "disabled" ] = disabled;
  }
  if (variant) {
    newProps[ "variant" ] = variant;
  }
  if (color) {
    newProps[ "color" ] = color;
  }

  const togglePopper = event => {
    setAnchorBounding(!anchorBounding ? event.currentTarget.parentElement.getBoundingClientRect() : null);
  };
  React.Children.forEach(children, (child: any) => {
    if (child?.type == Button) {
      buttonChildren.push(child);
    }
  });

  if (collapse) {
    popupChildren = buttonChildren.splice(1);
    buttonChildren = buttonChildren.map((child: any, index) => {
      newProps[ "key" ] = index;
      if (buttonMenu && popupChildren.length >= 1) {
        newProps[ "onClick" ] = (e) => {
          onOpen?.(e);
          togglePopper(e);
        };

        if (index == 0) {
          newProps[ "className" ] = classNames(child.props.className, { [ ButtonGroupClasses.Active ]: anchorBounding });
        }
      }
      return tooltip ?
        <Tooltip key={index} title={tooltip} alignment={tooltipAlignment}>
          {React.cloneElement(child, { ...newProps })}
        </Tooltip> :
        React.cloneElement(child, { ...newProps });

    });

    if (!buttonMenu) {
      buttonChildren.push(
        <Button {...newProps}
                key={Math.random()}
                icon={!!!anchorBounding ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                className={classNames(ButtonGroupClasses.Toggle, {
                  [ ButtonGroupClasses.Active ]: anchorBounding
                })}
                onlyIcon={true}
                size={size}
                flexShrink={0}
                onClick={(e) => {
                  togglePopper(e);
                  onOpen?.(e);
                }}/>);
    }
  } else {
    buttonChildren = buttonChildren.map((child: any) => {
      return React.cloneElement(child, { ...newProps, key: Math.random() });
    });
  }

  return <Box container className={classes} {...p}>
    {buttonChildren}
    <Popper open={!!anchorBounding} onClickAway={() => {
      setAnchorBounding(null);
      onClose?.();
    }} alignment={alignment} anchorBounding={anchorBounding}>
      <Menu height={height ?? "fit-content"}>
        {popupChildren.length ?
          popupChildren.map((item, index) => {
            return <MenuItem disabled={item.props.disabled} key={index} onClick={(event) => {
              togglePopper(event);
              item.props.onClick && item.props.onClick(event);
            }}>{item.props.children}</MenuItem>;
          })
          :
          <MenuItem>Empty</MenuItem>
        }
      </Menu>
    </Popper>
  </Box>;
};
ButtonGroup.defaultProps = defaultButtonGroupProps;
export enum ButtonGroupClasses {
  ButtonGroup = "button-group",
  Toggle = "toggle",
  Fill = "fill",
  Active = "active"
}
