import React                      from "react";
import { FC }                     from "react";
import { LinkClasses }            from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { classNames as clsNames } from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { SoundSectionClasses }    from "../SoundSectionClasses";
import TwilioLogoUrl              from "./twilio_logo.png";
import TwilioLogoWebpUrl          from "./twilio_logo.webp";

export const TwilioInfo: FC<BoxComponentProps> = React.memo(function TwilioInfo(props) {
  const { className, ...p } = props;
  const classNames = clsNames(SoundSectionClasses.TwilioInfo, className);
  return <Box container direction={"column"} gap={"XS"} flexShrink={0} className={classNames} {...p}>
    <picture>
      <source srcSet={TwilioLogoWebpUrl} type="image/webp"/>
      <img src={TwilioLogoUrl} alt="twilio" width={66} height={20}/>
    </picture>
    <p>Check your browser and network environment to {"\n"} ensure you can use
      Twilio's WebRTC products.</p>
    <a href="https://networktest.twilio.com/"
       className={clsNames(LinkClasses.Link, LinkClasses.Primary)}
       target="_blank">
      Start network diagnostic
    </a>
  </Box>;
});
