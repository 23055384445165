import React                     from "react";
import { InputState }            from "../../Input/Input";
import { PhoneNumber }           from "../../Input/PhoneNumber";
import { useConsumeField }       from "../BaseField";
import { PhoneNumberFieldProps } from "./PhoneNumberField";
import { PhoneNumberOptions }    from "./PhoneNumberOptions";

export const PhoneNumberEditField = React.memo(function PhoneNumberEditField(props) {
  const {
    input: { name, value, showWarnings = true, autocomplete = true, onChange, onBlur, onFocus, ...phoneProps },
    meta: { touched, error, submitError, modifiedSinceLastSubmit }
  } = useConsumeField<PhoneNumberFieldProps>();
  function onPaste(e) {
    if (String(value?.number).replace?.(/_/g, "") == "+1") {
      e.preventDefault();
      let paste = e.clipboardData.getData("text");
      paste = paste.trim().replace(/^\+1/, "");
      value.number += paste;
      onChange(value);
      return false;
    } else {
      return e;
    }
  }
  const hasError = (touched && !!error) || (!modifiedSinceLastSubmit && !!submitError);
  return (
    <PhoneNumber
      {...phoneProps}
      inputProps={{
        onPaste
      }}
      value={value}
      onChange={(data) => onChange({ ...value, ...data })}
      onFocus={onFocus}
      onBlur={onBlur}
      name={name}
      state={(hasError && InputState.Error) || (showWarnings && phoneProps.warning && InputState.Warning) || undefined}
      message={hasError ? (error || submitError) : showWarnings && phoneProps.warning}
      options={PhoneNumberOptions}
    />

  );
});
export default PhoneNumberEditField;
