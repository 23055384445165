import { gql }                                         from "@apollo/client";
import { useQuery }                                    from "@apollo/client";
import { useLazyQuery }                                from "@apollo/client";
import { useSource }                                   from "@relcu/ui";
import { FC }                                          from "react";
import { useMemo }                                     from "react";
import React                                           from "react";
import { useJqlQuery }                                 from "../../../../Jql";
import { useSchemaField }                              from "../../../../useSchemaField";
import { MortechProductListVariables }                 from "../Cells/__types__/MortechProductList";
import { MortechProductList }                          from "../Cells/__types__/MortechProductList";
import { GetTitleCompanies_titleCompanies_edges_node } from "./__types__/GetTitleCompanies";
import { GetTitleCompanies }                           from "./__types__/GetTitleCompanies";
import { MortechInvestor }                             from "./__types__/MortechInvestor";
import { OBSources }                                   from "./__types__/OBSources";

export const GET_MORTECH_PRODUCT_LITS = gql`
  query MortechProductList($investorIds:[String]!){
    mortechProducts(input: $investorIds){
      name
      id
      code
    }
  }
`;
export const GET_TITLE_COMPANIES = gql`
  query GetTitleCompanies{
    titleCompanies(where: {disabled: {notEqualTo: true}}){
      edges {
        node {
          id
          objectId
          providerId
          title
          editable
        }
      }
    }
  }
`;
export type PricingEngineContextValue = {
  obsources: { label: string, value: string }[]
  investorIds: string[]
  mortechProductList: { label: string, value: string }[]
  loanProductId: string
  titleCompanies: GetTitleCompanies_titleCompanies_edges_node[]
}

export const PricingEngineContext = React.createContext<PricingEngineContextValue>(null);
export const PricingEngineProvider: FC = React.memo(function PricingEngineProvider({ children }) {
  const { $settings: { pricing: settings, excludeProductCategory } } = useSource();

  const { options: engineOptions = [] } = useSchemaField("LoanEstimateOffer", "pricingEngine");
  const hasMortech = engineOptions.some((o) => o.value == "mortech");
  const hasOb = engineOptions.some((o) => o.value == "optimalblue");

  const { data: { mortechInvestors = [] } = {}, loading } = useJqlQuery<MortechInvestor>({
    operation: "mortechInvestors",
    fields: ["id", "name"]
  }, {
    operationName: "MortechInvestor",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !hasMortech
  });
  const { data: { obsources } = { obsources: [] }, loading: sourceLoading } = useJqlQuery<OBSources>({
    operation: "obsources",
    fields: ["label", "value"]
  }, {
    operationName:"OBSources",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !hasOb
  });
  const { data: titleCompaniesData, loading: titleCompaniesLoading } = useQuery<GetTitleCompanies>(GET_TITLE_COMPANIES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const investorIds = useMemo(() => mortechInvestors.map((value) => value.id), [mortechInvestors]);

  const { data: { mortechProducts } = { mortechProducts: [] }, loading: productLoading } = useQuery<MortechProductList, MortechProductListVariables>(GET_MORTECH_PRODUCT_LITS, {
    skip: investorIds.length == 0, //|| engine != "mortech",
    variables: { investorIds }
  });
  const mortechProductList = useMemo(() => {
    const allProducts = mortechProducts?.map(({ id, name }) => ({ value: id, label: name }));
    if (!excludeProductCategory?.length) {
      return allProducts;
    }
    const excluded = new Set(excludeProductCategory);
    const filteredProducts = allProducts.filter(m => !excluded.has(m.value));
    return filteredProducts;
  }, [mortechProducts, excludeProductCategory]);
  const titleCompanies = useMemo(() => {
    return titleCompaniesData?.titleCompanies?.edges?.map(t => t.node);
  }, [titleCompaniesData]);

  const defaultValue = mortechProductList?.find(({ value, label }) => (label.toLowerCase()
  .includes("conv 30") || label.toLowerCase().includes("conf 30")));
  const loanProductId = defaultValue?.value || mortechProductList[ 0 ]?.value;
  return <PricingEngineContext.Provider
    value={{ obsources, investorIds, loanProductId, mortechProductList, titleCompanies }}>
    {(!loading && !productLoading && !titleCompaniesLoading) && children}
  </PricingEngineContext.Provider>;
});
