export class PopupWindow {
  static open(id, url, options = {}) {
    if (!window.onmessage) {
      window.addEventListener("message", this.handleMessage.bind(this));
    }
    const popup = new PopupWindow(id, url, options);
    return popup.open();
  }

  static handleMessage(event) {
    const message = typeof event.data == "string" && JSON.parse(event.data);
    const id = message?.popupId;
    const popup = this.getPopup(id);
    if (popup) {
      const { error, result } = message;
      if (error) {
        popup.reject({ error });
      } else {
        popup.resolve(result);
      }
      popup.close();
    }
  }
  static popups = new Map<string, PopupWindow>();
  static getPopup(id: string) {
    return this.popups.get(id);
  }

  private window;
  private url;
  private id;
  private options;

  private resolve;
  private reject;

  constructor(id, url, options = {}) {
    this.id = id;
    this.url = url;
    this.options = options;
  }

  open() {
    const { url, id, options } = this;
    const popup = PopupWindow.getPopup(id);
    if (popup) {
      popup.close();
    }
    this.window = window.open(url, id, toQuery(options, ","));
    PopupWindow.popups.set(id, this);
    return new Promise(((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    }));
  }

  close() {
    PopupWindow.popups.delete(this.id);
    window.removeEventListener("message", PopupWindow.handleMessage, true);
    this.window.close();
  }
}

export function toQuery(params, delimiter = "&") {
  const keys = Object.keys(params);
  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[ key ]}`;
    if (index < (keys.length - 1)) {
      query += delimiter;
    }
    return query;
  }, "");
}
