import React                     from "react";
import { applyBoxItemStyles }    from "../..";
import { BoxItemComponentProps } from "../..";

export interface LogoProps extends BoxItemComponentProps {
  url: string
  height?: number
  width?: number
}

export function Logo(props: LogoProps) {
  let properties = applyBoxItemStyles<LogoProps>(props);
  const { className, children, url, ...p } = properties;

  return <img className={className} src={url} alt="Relcu" {...p}/>;
}
