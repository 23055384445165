import { useEffect }                                       from "react";
import React                                               from "react";
import { FC }                                              from "react";
import { useQuery }                                        from "@apollo/client";
import { gql }                                             from "@apollo/client";
import { Badge }                                           from "@relcu/rc";
import { useClassNames }                                   from "@relcu/rc";
import { NOTIFICATION_FRAGMENT }                           from "../../graph/operations.graphql";
import { SubscriptionEvent }                               from "../../types/graphql-global-types";
import { nextCursor }                                      from "../../utils/helpers";
import {
  SubscribeDistributedLeadUnreadNotification
}                                                          from "../__types__/SubscribeDistributedLeadUnreadNotification";
import { SUBSCRIBE_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS } from "../operations.graphql";
import {
  GetDistributedLeadUnreadNotificationsCount
}                                                          from "./__types__/GetDistributedLeadUnreadNotificationsCount";
import {
  SubscribeDistributedLeadUnreadNotificationCount
}                                                          from "./__types__/SubscribeDistributedLeadUnreadNotificationCount";
import { BarToggleButtonProps }                                 from "./BarToggleButton";
import { BarToggleButton }                                 from "./BarToggleButton";

export interface PushBarToggleButtonProps extends BarToggleButtonProps {
}

export const PushBarToggleButton: FC<PushBarToggleButtonProps> = React.memo(function PushBarToggleButton(props) {
  const { data: { notifications: { count } = {} } = {}, subscribeToMore } = useQuery<GetDistributedLeadUnreadNotificationsCount>(GET_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS_COUNT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const { state, actualState, icon, active, disabled, onClick, className, ...p } = props;
  const { merge, withClassPrefix } = useClassNames("push-wrapper", "rc");
  const classes = merge(withClassPrefix({ "blinking": count }));

  useEffect(() => {
    return subscribeToMore<SubscribeDistributedLeadUnreadNotificationCount>({
      document: SUBSCRIBE_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS_COUNT,
      updateQuery(root, { subscriptionData: { data: { notifications: { node, event } } } }) {
        switch (event) {
          case SubscriptionEvent.CREATE:
          case SubscriptionEvent.ENTER:
            return {
              ...root,
              notifications: {
                ...root.notifications,
                count: root.notifications.count + 1
              }
            };
          case SubscriptionEvent.LEAVE:
            return {
              ...root,
              notifications: {
                ...root.notifications,
                count: root.notifications.count - 1
              }
            };
        }
      }
    });
  }, []);

  return (
    <Badge content={count ? count : false}>
      <BarToggleButton className={classes} {...props}>
        <div className="rc-push-wrapper-circle" style={{ animationDelay: "0s" }}/>
        <div className="rc-push-wrapper-circle" style={{ animationDelay: ".5s" }}/>
        <div className="rc-push-wrapper-circle" style={{ animationDelay: "1s" }}/>
        <div className="rc-push-wrapper-circle" style={{ animationDelay: "1.5s" }}/>
      </BarToggleButton>
    </Badge>
  );
});

export const GET_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS_COUNT = gql`
  query GetDistributedLeadUnreadNotificationsCount {
    notifications(where: {unread: {equalTo: true},type: {equalTo: "distributed_lead"}, record: {exists: true}},order: [createdAt_DESC]) @connection(key: "unreadPushNotifications") {
      count
    }
  }
`;

export const SUBSCRIBE_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS_COUNT = gql`
  subscription SubscribeDistributedLeadUnreadNotificationCount {
    notifications(where: {unread: {equalTo: true},type: {equalTo: "distributed_lead"}, record: {exists: true}}) {
      event
      node {
        id
      }
    }
  }
`;
