import React                from "react";
import { Label }            from "../../Label";
import { Box }              from "../../Box";
import { BaseFieldClasses } from "../BaseField";
import { useConsumeField }  from "../BaseField";
import { TimeFieldProps }   from "./TimeField";

export const TimeReadField = React.memo(function TimeReadField() {
  const {
    input: {
      label,
      required,
      value,
      name,
      targetClassName,
      flexBasis,
      flexShrink,
      flexGrow,
      readPlaceholder,
      ...textProps
    }
  } = useConsumeField<TimeFieldProps>();

  return (
    <Box container
         direction={"column"}
         gap={"XXXS"}
         className={BaseFieldClasses.ReadField}
         alignSelf={"start"}
         {...textProps}
         flexBasis={flexBasis}
         flexShrink={flexShrink}
         flexGrow={flexGrow}
    >
      <Label>{label}</Label>
      {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
    </Box>
  );
});

export default TimeReadField;
