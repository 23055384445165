import { gql }                     from "@apollo/client";
import { PHONE_MESSAGE_TEMPLATE_FRAGMENT } from "../../../operations.graphql";

export const GET_PHONE_MESSAGE_TEMPLATES = gql`
  query GetPhoneMessageTemplates ($where:PhoneMessageTemplateWhereInput,$skip:Int,$before:String,$after:String,$from:String,$first:Int,$last:Int) {
    phoneMessageTemplates (
      where: $where,
      order: [createdAt_DESC, objectId_ASC],
      first: $first,
      last: $last,
      skip:$skip
      before:$before
      after:$after,
      from:$from,
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...PhoneMessageTemplate
        }
      }
    }
  }
  ${PHONE_MESSAGE_TEMPLATE_FRAGMENT}
`;

export const SUBSCRIBE_PHONE_MESSAGE_TEMPLATES = gql`
  subscription SubscribePhoneMessageTemplates($where: PhoneMessageTemplateSubscriptionWhereInput!) {
    phoneMessageTemplates(events: [CREATE, ENTER,LEAVE, UPDATE, DELETE], where: $where){
      event
      node {
        ...PhoneMessageTemplate
      }
    }
  }
  ${PHONE_MESSAGE_TEMPLATE_FRAGMENT}
`;

export const DUPLICATE_PHONE_MESSAGE_TEMPLATE = gql`
  mutation DuplicatePhoneMessageTemplate($input: CreatePhoneMessageTemplateInput!) {
    createPhoneMessageTemplate(input: $input) {
      phoneMessageTemplate {
        ...PhoneMessageTemplate
      }
    }
  }
  ${PHONE_MESSAGE_TEMPLATE_FRAGMENT}
`;

export const REMOVE_PHONE_MESSAGE_TEMPLATE = gql`
  mutation RemovePhoneMessageTemplate($id: ID!) {
    deletePhoneMessageTemplate(input: {id: $id}) {
      phoneMessageTemplate {
        id
      }
    }
  }
`;

export const CREATE_PHONE_MESSAGE_TEMPLATE = gql`
  mutation CreatePhoneMessageTemplate ($input: CreatePhoneMessageTemplateInput!) {
    createPhoneMessageTemplate(input: $input) {
      phoneMessageTemplate {
        ...PhoneMessageTemplate
      }
    }
  }
  ${PHONE_MESSAGE_TEMPLATE_FRAGMENT}
`;
export const UPDATE_PHONE_MESSAGE_TEMPLATE = gql`
  mutation UpdatePhoneMessageTemplate($input: UpdatePhoneMessageTemplateInput!) {
    updatePhoneMessageTemplate(input: $input) {
      phoneMessageTemplate {
        ...PhoneMessageTemplate
      }
    }
  }
  ${PHONE_MESSAGE_TEMPLATE_FRAGMENT}
`;
