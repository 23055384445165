import React              from "react";
import { FC }             from "react";
import { TimeProps }      from "../../Input/Time";
import { BaseFieldProps } from "../BaseField";
import { BaseField }      from "../BaseField";
import { TextField }      from "../TextField";
import { TimeEditField }  from "./TimeEditField";
import { TimeReadField }  from "./TimeReadField";

export const TimeField: FC<TimeFieldProps> = React.memo(function TimeField(props) {
  const { regexp, ...rest } = props;
  return (
    "showPicker" in HTMLInputElement.prototype
      ?
      <BaseField
        {...rest}
        validate={(value) => {
          if (regexp) {
            let reg = new RegExp(regexp, "iu");
            if (!value?.match(reg)) {
              return `${props.label} is invalid`;
            }
          }
        }}
        readView={<TimeReadField/>}
        editView={<TimeEditField/>}
      />
      :
      <TextField {...props} type="time"/>
  );
});

export type TimeFieldProps = BaseFieldProps & TimeProps & {
  regexp?: string
}
