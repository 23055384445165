import React                               from "react";
import { useCallback }                     from "react";
import { getIn }                           from "@relcu/form";
import { FormSpy, useForm }                from "@relcu/form";
import { FieldState }                      from "@relcu/form";
import { Box }                             from "@relcu/ui";
import { GlobalClasses }                   from "@relcu/ui";
import { FontIcon }                        from "@relcu/ui";
import { Label }                           from "@relcu/ui";
import { classNames }                      from "@relcu/ui";
import { useConsumeArrayField }            from "@relcu/ui";
import { BaseFieldClasses }                from "@relcu/ui";
import { PhoneNumberField }                from "@relcu/ui";
import { CommonClasses }                   from "@relcu/ui";
import { DuplicateFieldClasses }           from "./DuplicateFieldClasses";
import { PhoneNumbersDuplicateFieldProps } from "./PhoneNumbersDuplicateField";

export const PhoneNumbersDuplicateEditField = React.memo(function PhoneNumbersDuplicateEditField(props) {
  const {
    fields,
    input: { className, label, required, defaultValue, fullSize = true, targetClass, ...phoneNumberProps },
    meta
  } = useConsumeArrayField<PhoneNumbersDuplicateFieldProps>();
  const { touched, error } = meta;
  const form = useForm();

  const classes = classNames(BaseFieldClasses.MultiField, {
    [ BaseFieldClasses.FullSize ]: fullSize
  }, className);

  const validatePhone = useCallback((value, all, meta: FieldState<string>) => {
    if (String(value).includes("_")) {
      return `${label || "Phone number"} is invalid`;
    }
  }, [label]);

  const handleRevert = useCallback(() => {
    const name = fields.name;
    form.change(name, getIn(form.getState().initialValues, name));
  }, []);// field revert functionality need only call this

  return <Box name={fields.name} className={classes} container {...phoneNumberProps}>
    <Box container direction={"column"} flex={1} gap={"XXXS"}>
      {
        label &&
        <Label required={required} mLeft>
          {label}
        </Label>
      }
      {
        fields.map((name, index) => {
          return <FormSpy subscription={{ errors: true }} key={name}>
            {
              ({ errors }) => {
                const duplicateError = getIn(errors, `${name}.duplicate`);
                return (
                  <Box container justify={"space-between"} gap={"XS"} alignItems={"center"} key={name}
                       className={classNames(DuplicateFieldClasses.DuplicateFiled, { [ DuplicateFieldClasses.Duplication ]: duplicateError })}>
                    <PhoneNumberField
                      halfSize={true}
                      flexShrink={0}
                      name={`${name}.number`}
                      value={null}
                      options={[]}
                      flex={1}
                      message={""}
                      optionKey={"objectId"}
                      optionLabel={"objectName"}
                      validate={validatePhone}
                    />
                    <FontIcon
                      type={"clear"}
                      onClick={() => {
                        fields.remove(index);
                      }}
                      className={BaseFieldClasses.RemoveAction}
                      style={{ marginRight: "var(--layout-box-gap-x-x-s)" }}
                    />
                  </Box>
                );
              }
            }
          </FormSpy>;
        })
      }
      {
        touched && !!error && typeof error === "string" &&
        <Box className={classNames(GlobalClasses.Message, GlobalClasses.Error)}>
          {error}
        </Box>
      }
      {
        meta.dirty &&
        <FontIcon type={"undo"} className={CommonClasses.ClickableIcon}
                  style={{ marginRight: "var(--layout-box-gap-s)" }}
                  alignSelf={"end"} onClick={handleRevert}/>
      }
    </Box>
  </Box>;
});
export default PhoneNumbersDuplicateEditField;
