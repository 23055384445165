import React           from "react";
import { FC }          from "react";
import { classNames }  from "../../../../utils/classNames";
import { Box }         from "../../../Box";
import { Typography }  from "../../../Typography";
import { CellClasses } from "../CellClasses";
import { ICellProps }  from "../ICellProps";

export interface BooleanCellProps extends ICellProps {

}

export const BooleanCell: FC<BooleanCellProps> = React.memo(function BooleanCell({ getCellValue, className, defaultValue, rowData, dataKey, ...props }) {
  const value = getCellValue(rowData, dataKey);
  return <Box className={classNames(className, CellClasses.TextCell)}>
    <Typography>
      {value === true ? "Yes" : value === false ? "No" : defaultValue ?? "-"}
    </Typography>
  </Box>;
});