import { gql, useQuery }            from "@apollo/client";
import { useField }                 from "@relcu/form";
import { useForm }                  from "@relcu/form";
import { useEffect }                from "react";
import { useSource }                from "@relcu/ui";
import { useMemo }                  from "react";
import { totalLoanAmount }          from "../../../../../utils/helpers";
import { getField }                 from "../../../../../utils/schemaUtils";
import { MortechInvestors }         from "./__types__/MortechInvestors";
import { MortechProductsVariables } from "./__types__/MortechProducts";
import { MortechProducts }          from "./__types__/MortechProducts";

export function useLoanCriteria() {
  const form = useForm();
  const { $settings: { pricing: settings, excludeProductCategory } } = useSource();
  const { data: { mortechInvestors } = { mortechInvestors: [] } } = useQuery<MortechInvestors>(GET_MORTECH_INVESTORS);
  const { input: { value: selectedProductId } } = useField("mortech.product", { subscription: { value: true } });
  const { input: { value: investorIds } } = useField("mortech.investorIds", { subscription: { value: true } });
  const { data: { mortechProducts } = { mortechProducts: [] } } = useQuery<MortechProducts, MortechProductsVariables>(GET_MORTECH_PRODUCTS, {
    skip: !investorIds,
    variables: { investorIds }
  });
  useEffect(() => {
    if (mortechProducts.length == 0) {
      form.resetFieldState("mortech.product");
      return;
    }
    const { value } = form.getFieldState("mortech.product");
    if (!value || !mortechProducts.some(({ id }) => id == value)) {
      form.change("mortech.product", mortechProducts[ 0 ].id);
    }
  }, [mortechProducts]);
  useEffect(() => {
    const { value } = form.getFieldState("mortech.investorIds");
    (!value || value?.length == 0) && form.change("mortech.investorIds", mortechInvestors.map(({ id }) => id));
  }, [mortechInvestors]);
  const selectedProduct: { type: LoanType, name: string } = useMemo(() => {
    const selectedProduct = mortechProducts.find(({ id }) => id === selectedProductId);
    let selectedProductName = (selectedProduct?.name || "").toLowerCase();
    let type: LoanType = "conventional";
    switch (true) {
      // case (selectedProductName.includes("conf") || selectedProductName.includes("conv") || selectedProductName.includes("home")) && !selectedProductName.includes("non"):
      //   type = "conventional";
      //   break;
      case selectedProductName.includes("fha"):
        type = "fha";
        break;
      case selectedProductName.includes("va"):
        type = "va";
        break;
    }
    return {
      name: selectedProduct?.name,
      type
    };
  }, [selectedProductId, form, mortechProducts]);
  useEffect(() => {
    form.change("loanProgram", selectedProduct.type);
    let loanProduct = "other_products";
    let loanTerm = "360";
    if (selectedProduct.name) {
      let productMatches = selectedProduct.name.match(/.*(\d{2}).*[Yr|Year].*[ARM|LIBOR][^0-9]+(\d{1,2})[\/1| Yr.*]/);
      if (productMatches) {
        loanProduct = `${productMatches[ 2 ]}_year_ARM_30_yrs`;
        loanTerm = "360";
      } else {
        productMatches = selectedProduct.name.match(/(\d{2})( )(Yr|Year)(.*)(Fixed)/i);
        if (productMatches && ["10", "15", "20", "25", "30", "40"].includes(productMatches[ 1 ])) {
          loanProduct = `${productMatches[ 1 ]}_year_fixed`;
          loanTerm = (parseInt(productMatches[ 1 ]) * 12).toString();
        } else {
          productMatches = selectedProduct.name.match(/.*(\d{2})$/);
          if (productMatches) {
            loanProduct = `${productMatches[ 1 ]}_year_fixed`;
            loanTerm = (parseInt(productMatches[ 1 ]) * 12).toString();
          }
        }
      }
    }
    form.change("mortech.productName", selectedProduct.name);
    form.change("loanProgram", selectedProduct.type);
    form.change("loanTerm", loanTerm);
    form.change("loanProduct", loanProduct);
  }, [selectedProduct.type, selectedProduct.name]);
  useEffect(() => api.calculate(), []);
  const { options: viewOptions } = getField("Lead", "mortech.view");
  const { options: loanPurposeOptions } = getField("Lead", "loanPurpose");
  const { options: propertyTypeOptions } = getField("Lead", "property.type");
  const { options: costcoMembershipOptions } = getField("Lead", "costco.membershipType");
  const { options: propertyOccupancyOptions } = getField("Lead", "property.occupancy");
  const { options: secondaryFinancingOptions } = getField("Lead", "secondaryFinancing");
  const { options: lockInDaysOptions } = getField("Lead", "mortech.lockInDays");
  const { options: veteranStatusOptions } = getField("LeadMember", "veteranStatus");
  const { options: sourceOptions } = getField("Lead", "mortech.source");
  const api = {
    get investorOptions() {
      return mortechInvestors?.map(({ id, name }) => ({ value: id, label: name }));
    },
    get productOptions() {
      if (!excludeProductCategory?.length) {
        return mortechProducts.map(({ id, name }) => ({ value: id, label: name }));
      }

      const excluded = new Set(excludeProductCategory);
      const filteredProducts = mortechProducts.filter(m => !excluded.has(m.id));
      return filteredProducts?.map(({ id, name }) => ({ value: id, label: name }));
    },
    get lockInDays() {
      return lockInDaysOptions.map(option => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + Number(option.value));
        return {
          value: option.value,
          label: `${option.label} - ${currentDate.toLocaleString("en-US", { month: "long", day: "numeric" })}`
        };
      });
    },
    sourceOptions,
    settings,
    viewOptions,
    selectedProduct,
    loanPurposeOptions,
    propertyTypeOptions,
    secondaryFinancingOptions,
    propertyOccupancyOptions,
    costcoMembershipOptions,
    mortechProducts,
    veteranStatusOptions,
    calculate: (name?: string) => {
      let allValues: Partial<any & { totalLoanAmount: number }> = form.getState().values;
      if (!allValues.property?.value && (allValues.loanAmount && allValues.downPayment)) {
        form.change("property.value", allValues.loanAmount + allValues.downPayment);
        allValues = form.getState().values;
      }
      if (!allValues.property?.value) {
        return;
      }
      let borrowerIndex = allValues.members.findIndex((b) => b.type == "borrower");

      let calculations = {
        loanAmount: {
          get downPayment() {
            return allValues.property.value - allValues.loanAmount;
          },
          get ltv() {
            return (allValues.loanAmount / allValues.property.value) * 100;
          },
          get cltv() {
            if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
              return this.ltv;
            }
            return allValues.cltv;
          },
          get "property.currentMortgageBalance"() {
            if (["rate_term_refinance", "cash_out_refinance"].includes(allValues.loanPurpose)) {
              return allValues?.property?.currentMortgageBalance ? allValues.property.currentMortgageBalance : allValues.loanAmount;
            } else {
              return allValues.loanAmount;
            }
          },
          get cashOut() {
            return allValues.loanAmount - this[ "property.currentMortgageBalance" ] > 0 ? allValues.loanAmount - this[ "property.currentMortgageBalance" ] : 0;
          },
          get totalLoanAmount() {
            return totalLoanAmount({ ...allValues, downPayment: this.downPayment }, settings);
          }
        },
        downPayment: {
          get loanAmount() {
            return allValues.property.value - allValues.downPayment;
          },
          get ltv() {
            return (this.loanAmount / allValues.property.value) * 100;
          },
          get cltv() {
            if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
              return this.ltv;
            }
            return allValues.cltv;
          },
          get totalLoanAmount() {
            return totalLoanAmount({ ...allValues, loanAmount: this.loanAmount }, settings);
          },
          get "property.currentMortgageBalance"() {
            if (["rate_term_refinance", "cash_out_refinance"].includes(allValues.loanPurpose)) {
              return allValues?.property?.currentMortgageBalance ? allValues.property.currentMortgageBalance : this.loanAmount;
            } else {
              return this.loanAmount;
            }
          },
          get cashOut() {
            return this.loanAmount - this[ "property.currentMortgageBalance" ] > 0 ? this.loanAmount - this[ "property.currentMortgageBalance" ] : 0;
          }
        },
        ltv: {
          get loanAmount() {
            return (allValues.ltv * allValues.property.value) / 100;
          },
          get downPayment() {
            return allValues.property.value - this.loanAmount;
          },
          get cltv() {
            if (allValues.secondaryFinancing === "none" || allValues.cltv <= allValues.ltv || allValues.cltv > 100) {
              return allValues.ltv;
            }
            return allValues.cltv;
          },
          get totalLoanAmount() {
            return totalLoanAmount({ ...allValues, downPayment: this.downPayment, loanAmount: this.loanAmount }, settings);
          },
          get "property.currentMortgageBalance"() {
            if (["rate_term_refinance", "cash_out_refinance"].includes(allValues.loanPurpose)) {
              return allValues?.property?.currentMortgageBalance ? allValues.property.currentMortgageBalance : this.loanAmount;
            } else {
              return this.loanAmount;
            }
          },
          get cashOut() {
            return this.loanAmount - this[ "property.currentMortgageBalance" ] > 0 ? this.loanAmount - this[ "property.currentMortgageBalance" ] : 0;
          }
        },
        "property.value": {
          get loanAmount() {
            return allValues.property.value > allValues.loanAmount ? allValues.loanAmount : allValues.property.value;
          },
          get downPayment() {
            return allValues.property.value - this.loanAmount;
          },
          get ltv() {
            return (this.loanAmount / allValues.property.value) * 100;
          },
          get cltv() {
            if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
              return this.ltv;
            }
            return allValues.cltv;
          },
          get totalLoanAmount() {
            return totalLoanAmount({ ...allValues, downPayment: this.downPayment, loanAmount: this.loanAmount }, settings);
          },
          get "property.currentMortgageBalance"() {
            if (["rate_term_refinance", "cash_out_refinance"].includes(allValues.loanPurpose)) {
              return allValues.property?.currentMortgageBalance ? allValues.property.currentMortgageBalance : this.loanAmount;
            } else {
              return this.loanAmount;
            }
          },
          get cashOut() {
            return this.loanAmount - this[ "property.currentMortgageBalance" ] > 0 ? this.loanAmount - this[ "property.currentMortgageBalance" ] : 0;
          }
        },
        financeFf: {
          get totalLoanAmount() {
            return totalLoanAmount(allValues, settings);
          }
        },
        financeMip: {
          get totalLoanAmount() {
            return totalLoanAmount(allValues, settings);
          }
        },
        firstUseOfVaProgram: {
          get totalLoanAmount() {
            return totalLoanAmount(allValues, settings);
          }
        },
        loanPurpose: {
          get "property.currentMortgageBalance"() {
            if (["rate_term_refinance", "cash_out_refinance"].includes(allValues.loanPurpose)) {
              return allValues.property?.currentMortgageBalance ? allValues.property.currentMortgageBalance : allValues.loanAmount;
            } else {
              return allValues.loanAmount;
            }
          },
          get cashOut() {
            return allValues.loanAmount - this[ "property.currentMortgageBalance" ] > 0 ? allValues.loanAmount - this[ "property.currentMortgageBalance" ] : 0;
          },
          get totalLoanAmount() {
            return totalLoanAmount(allValues, settings);
          },
          get "mortech.streamLine"() {
            if (["va", "fha"].includes(allValues.loanProgram) && allValues.loanPurpose === "rate_term_refinance") {
              return allValues.mortech.streamLine;
            }
            return false;
          },
          get "mortech.hudReo"() {
            if (allValues.loanProgram === "fha" && allValues.loanPurpose === "purchase" && allValues.property.use === "primary_residence") {
              return allValues.mortech.hudReo;
            }
            return false;
          }
        },
        // "mortech.product": {
        //   get totalLoanAmount() {
        //     return totalLoanAmount(allValues, settings);
        //   }
        // },
        "property.currentMortgageBalance": {
          get loanAmount() {
            if (allValues.loanPurpose == "cash_out_refinance") {
              return allValues.loanAmount > allValues.cashOut + allValues.property.currentMortgageBalance ? allValues.loanAmount : allValues.cashOut + allValues.property.currentMortgageBalance;
            }
            return allValues.loanAmount;
          },
          get downPayment() {
            return allValues.property.value - this.loanAmount;
          },
          get cashOut() {
            return (this.loanAmount - allValues.property.currentMortgageBalance) > 0 ? (this.loanAmount - allValues.property.currentMortgageBalance) : 0;
          },
          get ltv() {
            return (this.loanAmount / allValues.property.value) * 100;
          },
          get cltv() {
            if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
              return this.ltv;
            }
            return allValues.cltv;
          },
          get totalLoanAmount() {
            return totalLoanAmount({ ...allValues, downPayment: this.downPayment, loanAmount: this.loanAmount }, settings);
          }
        },
        cashOut: {
          get loanAmount() {
            if (allValues.loanPurpose !== "cash_out_refinance") {
              return allValues.loanAmount;
            }
            return allValues.cashOut + allValues.property.currentMortgageBalance;
          },
          get downPayment() {
            return allValues.property.value - this.loanAmount;
          },
          get ltv() {
            return (this.loanAmount / allValues.property.value) * 100;
          },
          get cltv() {
            if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
              return this.ltv;
            }
            return allValues.cltv;
          },
          get totalLoanAmount() {
            return totalLoanAmount({ ...allValues, downPayment: this.downPayment, loanAmount: this.loanAmount }, settings);
          }
        },
        secondaryFinancing: {
          get cltv() {
            if (allValues.secondaryFinancing === "none" || allValues.cltv <= allValues.ltv || allValues.cltv > 100) {
              return allValues.ltv;
            }
            return allValues.cltv;
          }
        },
        loanProgram: {
          get financeMip() {
            return (allValues.loanProgram === "fha");
          },
          get financeFf() {
            return (allValues.loanProgram === "va");
          },
          get "mortech.streamLine"() {
            if (["va", "fha"].includes(allValues.loanProgram) && allValues.loanPurpose === "rate_term_refinance") {
              return allValues.mortech?.streamLine;
            }
            return false;
          },
          get "mortech.hudReo"() {
            if (allValues.loanProgram === "fha" && allValues.loanPurpose === "purchase" && allValues.property.use === "primary_residence") {
              return allValues.mortech.hudReo;
            }
            return false;
          },
          get totalLoanAmount() {
            return totalLoanAmount({ ...allValues, financeMip: this.financeMip, financeFf: this.financeFf }, settings);
          }
        },
        "mortech.streamLine": {
          get totalLoanAmount() {
            return totalLoanAmount(allValues, settings);
          }
        }
      };
      calculations[ `members[${borrowerIndex}].veteranStatus` ] = {
        get totalLoanAmount() {
          return totalLoanAmount(allValues, settings);
        }
      };
      if (!name) {
        Object.keys(calculations).forEach(field => {
          const changed = Object.assign({}, calculations[ field ]);
          Object.keys(changed).forEach(field => {
            form.change(field, changed[ field ]);
          });
          allValues = form.getState().values;
        });
      } else {
        const changed = Object.assign({}, calculations[ name ]);
        Object.keys(changed).forEach(field => {
          form.change(field, changed[ field ]);
        });
      }
    }
  };
  return api;
}

export type LoanType = "fha" | "va" | "conventional";
export const GET_MORTECH_INVESTORS = gql`
  query MortechInvestors{
    mortechInvestors{
      id
      name
    }
  }
`;
export const GET_MORTECH_PRODUCTS = gql`
  query MortechProducts($investorIds:[String]!){
    mortechProducts(input: $investorIds){
      name
      id
      code
    }
  }
`;
