import React                              from "react";
import { EmailsField as BaseEmailsField } from "@relcu/ui";
import { EmailsFieldProps }               from "@relcu/ui";
import { IChoiceField }                   from "../../../../types/ISchemas";
import { getSchema }                      from "../../../../utils/schemaUtils";
import { useSchemaField }                 from "../../useSchemaField";

export const EmailsField = React.memo<Omit<EmailsFieldProps, "options">>(function EmailsField(props) {
  const schema = getSchema("EmailAddress");
  return (
    <BaseEmailsField {...props} schema={schema}/>
  );
});
