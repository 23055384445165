import React                   from "react";
import { SyntheticEvent }      from "react";
import { FC }                  from "react";
import { ListItemDate }        from "@relcu/ui";
import { ButtonColors }        from "@relcu/ui";
import { ButtonSizes }         from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { TypographySize }      from "@relcu/ui";
import { TypographyColor }     from "@relcu/ui";
import { Typography }          from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { InlineEditableInput } from "@relcu/ui";
import { CommonClasses }       from "@relcu/ui";
import { classNames }          from "@relcu/ui";
import { FontIcon }            from "@relcu/ui";
import { PriorityView }        from "../../../../graph/__types__/PriorityView";
import { OfferClasses }        from "../PricingView/LoanProposalDialog/Offer/OfferClasses";

export interface ViewHeaderProps {
  open: boolean,
  node: PriorityView,
  handleUpdateView(e: SyntheticEvent, id: string, data: any),
  handleCreateQueue(e: SyntheticEvent),
  handleShareView(e: SyntheticEvent, node: PriorityView),
  handleCopyView(e: SyntheticEvent, node: PriorityView),
  handleDeleteView(e: SyntheticEvent, node: PriorityView),
}

export const ViewHeader: FC<ViewHeaderProps> = React.memo(function ViewHeader(props) {
  const { open, node, handleUpdateView, handleCreateQueue, handleShareView, handleCopyView, handleDeleteView, ...p } = props;
  return (
    <div className={"focus-view__header"} {...p}>
      <FontIcon type={"drag_indicator"}
                style={{ fontSize: "var(--typography-icon-size)", cursor: "move", flex: "0 0 10px" }}
                className={classNames("column-drag-handle", CommonClasses.ClickableIcon)}/>
      <FontIcon
        type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        key={"create"}
        className={open ? CommonClasses.PrimaryIcon : CommonClasses.GrayIcon}/>
      <InlineEditableInput
        value={node.title}
        flex={"1 0 350px"}
        renderReadActions={({ onEdit }) => {
          return <FontIcon type="create" key={"create"} className={CommonClasses.ClickableIcon}
                           onClick={(e) => {
                             e.stopPropagation();
                             onEdit(e);
                           }}/>;
        }}
        onConfirm={(event, value) => {
          handleUpdateView(event, node.id, { title: value });
        }
        }
        justify={"start"}
        onCancel={() => {}}
        classes={{ actions: OfferClasses.OfferInlineEditableAFiledActions }}/>
      <Box flex={1}/>
      {
        node.rPerm &&
        <div className={"focus-view__share-indicator"}>
          <Typography color={TypographyColor.Secondary} size={TypographySize.TextSmall}>Shared
            with:</Typography>
          <Typography size={TypographySize.TextSmall}>{node.rPerm.length}</Typography>
        </div>
      }
      <div className={"focus-view__button-container"}>
        <Button onlyIcon className={"focus-view__button"} icon={"add"} size={ButtonSizes.Small}
                onClick={handleCreateQueue}/>
        <Button onlyIcon className={"focus-view__button"} icon={"share"} size={ButtonSizes.Small}
                color={ButtonColors.White} onClick={(e) => handleShareView(e, node)}/>
        <Button onlyIcon className={"focus-view__button"} icon={"content_copy"}
                size={ButtonSizes.Small}
                color={ButtonColors.White} onClick={(e) => handleCopyView(e, node)}/>
        <Button onlyIcon className={"focus-view__button"} icon={"delete"} size={ButtonSizes.Small}
                color={ButtonColors.White} onClick={(e) => handleDeleteView(e, node)}/>
      </div>
      <div className={"focus-view__time-container"}>
        <ListItemDate date={node.createdAt} format={"withTime"}/>
      </div>
    </div>
  );
});
