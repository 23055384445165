import { useClassNames }         from "@relcu/rc";
import { Icon }                  from "@relcu/rc";
import { useEffect }             from "react";
import { FC }                    from "react";
import React                     from "react";
import { useAttachmentUploader } from "./useAttachmentUploader";
import "./attachmentsUpload.css";

interface UploadButtonProps {
  name: string;
  canUpdate: boolean;
  disabled?: boolean;
}

export const UploadButton: FC<UploadButtonProps> = React.memo(function UploadButton({ name, canUpdate, disabled }) {
  const { addFile, error, onPaste } = useAttachmentUploader({ name });

  useEffect(() => {
    if (canUpdate) {
      window.addEventListener("paste", onPaste);
    }
    return () => window.removeEventListener("paste", onPaste);
  }, []);

  const { withClassPrefix } = useClassNames("upload-button", "rc");

  return <label className={withClassPrefix({ disabled })}>
    <input style={{ display: "none" }}
           disabled={error}
           type="file"
           accept={AttachmentTypes.join(",")}
           onChange={(e) => {
             e.target.files[ 0 ] && addFile(e.target.files[ 0 ]);
             e.target.value = "";
           }}
    />
    <Icon type={"attachment"}/>
  </label>;
});

export const AttachmentTypes = [
  "audio/basic",
  "audio/L24",
  "audio/mp4",
  "audio/mpeg",
  "audio/ogg",
  "audio/vorbis",
  "audio/vnd.rn-realaudio",
  "audio/vnd.wave",
  "audio/3gpp",
  "audio/3gpp2",
  "audio/ac3",
  "audio/vnd.wave",
  "audio/webm",
  "audio/amr-nb",
  "audio/amr",
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
  "video/3gpp-tt",
  "video/H261",
  "video/H263",
  "video/H263-1998",
  "video/H263-2000",
  "video/H264",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/bmp",
  "text/vcard",
  "text/csv",
  "text/rtf",
  "text/richtext",
  "text/calendar",
  "text/directory",
  "application/pdf"
];
