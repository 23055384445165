import React                     from "react";
import { FC }                    from "react";
import { BadgeColors }           from "../Badge";
import { BadgeVariants }         from "../Badge";
import { Badge }                 from "../Badge";
import { Box }                   from "../Box";
import { Timer }                 from "../Timer";
import { TypographyWeight }      from "../Typography";
import { TypographySize }        from "../Typography";
import { RecordingTimerClasses } from "./RecordingTimerClasses";

export const RecordingTimer: FC<any> = React.memo(function RecordingTimer(props) {
  return <Box container alignItems="center" gap={"XXS"} className={RecordingTimerClasses.Timer}>
    <Badge variant={BadgeVariants.Dot} color={BadgeColors.Error}/>
    <Timer size={TypographySize.Subtitle} weight={TypographyWeight.Medium}/>
  </Box>;
});
