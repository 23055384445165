import React             from "react";
import { CheckboxProps } from "../../Checkbox";
import Checkbox          from "../../Checkbox";
import { Cell }          from "../../TableHorizontal";
import { CellProps }     from "../../TableHorizontal/Cell";
import { useClassNames } from "../../utils";

export interface CheckboxCellProps extends Omit<CellProps, "onChange"> {
  onChange?: (value, event) => void;
}

export const CheckboxCell = React.forwardRef((props: CheckboxCellProps, ref) => {
  const {
    className,
    classPrefix = "cell-checkbox",
    status,
    children,
    affected,
    space,
    revers,
    onAnimationEnd,
    span,
    inert,
    helperText,
    ...rest
  } = props;

  const { withClassPrefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );
  const onChange = (value, checked, event) => {
    rest.onChange?.(checked, event);
  };

  return <Cell
    status={status}
    className={classes}
    affected={affected}
    onAnimationEnd={onAnimationEnd}
    space={space}
    revers={revers}
    span={span}
    helperText={helperText}
    inert={inert}>
    <Checkbox {...rest as unknown as CheckboxProps} onChange={onChange}/>
    {children}
  </Cell>;
});
