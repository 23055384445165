import React                      from "react";
import { FC }                     from "react";
import { TypographySize }         from "@relcu/ui";
import { TypographyVariant }      from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { Typography }             from "@relcu/ui";
import { classNames as clsNames } from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { ListClasses }            from "./ListClasses";

export const ListHeader: FC<BoxComponentProps> = React.memo(function ListHeader(props) {
  const { className, children, ...p } = props;
  const classNames = clsNames(ListClasses.ListHeader, className);
  return <Box container justify={"space-between"} alignItems={"center"} className={classNames} {...p}>
    {children}
  </Box>;
});

export interface ListHeaderProps extends BoxComponentProps {
  value: string
}
export const ListHeaderTitle: FC<ListHeaderProps> = React.memo(function ListHeader(props) {
  const { value, className } = props;
  const classNames = clsNames(ListClasses.ListHeaderTitle, className);
  return <Box container direction={"column"} gap={"XXS"} className={classNames}>
    <Typography variant={TypographyVariant.h1} size={TypographySize.Title}>{value}</Typography>
  </Box>;
});
