import { TypographyLine } from "@relcu/ui";
import { Button }         from "@relcu/ui";
import { Typography }     from "@relcu/ui";
import React              from "react";
import { FC }             from "react";
import { SyntheticEvent } from "react";

export const EmptyFocusView: FC<{ onCreate(e: SyntheticEvent) }> = React.memo(function EmptyFocusView(props) {
  return (
    <div className={"focus-view__empty-content"}>
      <Typography lineHeight={TypographyLine.Line17}>
        No sections
      </Typography>
      <Button icon={"add"} onClick={props.onCreate}>ADD SECTION</Button>
    </div>
  );
});