import { gql }              from "@apollo/client";
import { useQuery }         from "@apollo/client";
import { useModal }         from "@relcu/ui";
import { useAlert }         from "@relcu/ui";
import { confirmModal }     from "@relcu/ui";
import { useState }         from "react";
import { useCallback }      from "react";
import { loadingVar }       from "../../../../reactiveVars";
import { downloadFile }     from "../../../../utils/helpers";
import AppraisalFeesModal   from "../../../Modal/AppraisalFeesModal/AppraisalFeesModal";
import AppraisalImportModal from "../../../Modal/AppraisalImportModal/AppraisalImportModal";

export function useAppraisalList(props) {
  const [modalfee, appraisalFeeModalContext] = useModal(AppraisalFeesModal);
  const [modal, appraisalModalContext] = useModal(AppraisalImportModal);
  const { error } = useAlert();
  const [active, setActive] = useState(null);
  const { data: { appraisals: { edges = [], count = 0 } = {} } = {}, loading, refetch } = {} = useQuery(GET_APPRAISAL_LIST, {
    fetchPolicy: "cache-and-network"
  });
  async function handleDownload(e) {
    try {
      e.preventDefault();
      e.stopPropagation();
      await downloadFile({ name: "Appraisal Template", type: "text/csv", url: "./appraisalTemplate.csv" });
    } catch (e) {
      error("Failed to Download Template");
    }
  }

  const api = {
    get isEmpty() {
      return !count;
    },
    get data() {
      return {
        edges,
        count,
        loading
      };
    },
    appraisalModalContext,
    appraisalFeeModalContext,
    handleDownload,
    active,
    setActive,
    onEdit(id) {
      const appData = edges.find(el => el.node.objectId === id);
      modal({ refetch, action: "edit", data: appData });
    },
    async onRemove(id) {
      try {
        await confirmModal({
          title: "Delete confirmation",
          subTitle: `Are you sure you want to delete Appraisal fees?`,
          content: `All information related with this Appraisal fees will be lost permanently`,
          label: "DELETE"
        });
        loadingVar(true);
        const formData = new FormData();
        formData.append("id", id);
        const response = await fetch(`/api/v1/appraisal`, {
          body: formData,
          method: "DELETE",
          cache: "no-cache",
          credentials: "include",
          headers: {
            "Accept": "application/json"
          }
        });
        loadingVar(false);
        const result = await response.json();
        if (!Object.keys(result).length) {
          await refetch();
        } else {
          error("Failed to Delete Appraisal");
        }
      } catch (e) {
        loadingVar(false);
        console.error(e);
      }
    },
    onPreview(id, type, name) {
      modalfee({
        id, type, name, headers: props.dialogHeaders, fields: props.dialogFields
      });
    },
    importFees: useCallback(() => modal({ refetch }), [])
  };
  return {
    ...api
  };
}

export const APPRAISAL_FILE = gql`
  fragment AppraisalFile on AppraisalFile{
    file{
      objectId
      objectName
    }
    fileName
    type
  }
`;
export const GET_APPRAISAL_LIST = gql`
  ${APPRAISAL_FILE}
  query GetAppraisalList {
    appraisals(order: [createdAt_DESC]) {
      count
      edges{
        node{
          id
          enabled
          uploadedFiles{
            ...AppraisalFile
          }
          defaultType
          objectId
          objectName
          createdAt
        }
      }
    }
  }
`;
