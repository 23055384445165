import React              from "react";
import { Form }           from "@relcu/rc";
import { useSchemaField } from "../../../useSchemaField";

export const ProposalPropertyType = React.memo(function PropertyType() {
  const { options } = useSchemaField("LoanEstimate", "propertyType");
  return (
    <Form.Select
      properties={{ style: { width: "100%" } }}
      name="propertyType"
      label="Property type"
      size="lg"
      required
      data={options}
    />
  );
});
