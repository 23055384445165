export function attachParent<T>(data: T, parent: T): T {
  // mark "parent" unenumable

  // Object.defineProperty(data, 'parent', {
  //   value: parent,
  //   writable: false,
  //   enumerable: false,
  //   configurable: true
  // });
  // return data;
  //todo this rise error in EmailTemplateView on new template get after changing applyTo field
  //todo https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
  return {...data, "parent": parent};
}
