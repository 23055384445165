import { FunctionComponent } from "react";
import React                 from "react";
import { FC }                from "react";
import { classNames }        from "../../../utils/classNames";
import { Box }               from "../../Box";
import { BoxComponentProps } from "../../Box";
import { Tooltip }           from "../../Tooltip";
import { QueueClasses }      from "./QueueClasses";

type QueueStatProps = {}

interface QueueStatComponent extends FunctionComponent<QueueStatProps & BoxComponentProps> {
  Item?: FC<{
    label: string
    tooltip: string
    value: number | string
    disabled?: boolean
  } & BoxComponentProps>
}

export const QueueStat: QueueStatComponent = React.memo(function QueueStat(props) {
  const { className, ...p } = props;
  return <Box container wrap={"wrap"} alignSelf={"stretch"} gap={"XXXS"} justify={"center"} {...p}/>;
});

QueueStat.Item = React.memo(function QueueStatItem(props) {
  const { label, tooltip, value, disabled, className, ...p } = props;
  const classes = classNames(QueueClasses.StatItem, {
    [ QueueClasses.StatItemDisabled ]: disabled
  }, className);

  return (
    <Tooltip title={tooltip}>
      <Box container justify={"space-between"} className={classes} flexShrink={0} flexBasis={"110px"}>
        <p className={QueueClasses.StatLabel}>{label}</p>
        <p className={QueueClasses.StatValue}>{value}</p>
      </Box>
    </Tooltip>
  );
});