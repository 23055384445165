import React               from "react";
import { FC }              from "react";
import { useState }        from "react";
import { useMemo }         from "react";
import { EmptyState }      from "@relcu/rc";
import { CircularLoader }  from "@relcu/ui";
import { gql }             from "@apollo/client";
import { FormSpy }         from "@relcu/final-form";
import { Field }           from "@relcu/final-form";
import { useClassNames }   from "@relcu/rc";
import { RadioButton }     from "@relcu/rc";
import { AutoComplete }    from "@relcu/rc";
import { Icon }            from "@relcu/rc";
import { InputGroup }      from "@relcu/rc";
import { Button }          from "@relcu/rc";
import { SectionMessage }  from "@relcu/rc";
import { Stack }           from "@relcu/rc";
import { Form }            from "@relcu/rc";
import { Container }       from "@relcu/rc";
import { Sidebar }         from "@relcu/rc";
import { Typography }      from "@relcu/rc";
import { ModalProps }      from "@relcu/rc";
import { Modal }           from "@relcu/rc";
import { BulkModalBody }  from "../../../Bulk/BulkModalBody";
import Email              from "../../../ContentVisualizer";
import { FLYER_FRAGMENT } from "../../../operations.graphql";
import { RelayQuery }      from "../../../Relay";
import { FlyerDialogList } from "./__types__/FlyerDialogList";
import "./flyer-dialog.css";

export interface FlyerDialogProps extends Partial<ModalProps> {
  filters: any;
  onInsert(flyer: string);
}

export const FlyerDialog: FC<FlyerDialogProps> = React.memo(function FlyerDialog(props) {
  const { filters, onInsert, ...modalProps } = props;
  const { withClassPrefix } = useClassNames("flyer-dialog", "rc");
  const [search, setSearch] = useState(null);
  const where = useMemo(() => {
    const query = { ...filters };
    if (search) {
      query[ "title" ] = {
        matchesRegex: `^${search}`,
        options: "i"
      };
    }

    return query;
  }, [filters, search]);

  const handleSubmit = (values) => {
    if (!values.flyer) {
      return { flyer: "Flyer is missing. Please choose flyer." };
    }
    onInsert(values.flyer);
  };

  return (
    <Modal {...modalProps} size={"xl"}>
      <Form
        onSubmit={handleSubmit}
        layout={"inline"}>
        <Modal.Header>
          <Typography weights={"medium"} variant={"base16"}>Select flyer</Typography>
        </Modal.Header>
        <RelayQuery<FlyerDialogList>
          className={"Flyer"}
          rowHeight={48}
          query={{
            document: FLYER_DIALOG_LIST,
            fetchPolicy: "network-only",
            nextFetchPolicy: "cache-first",
            variables: { where }
          }}
          render={renderProps => {
            const { data: { flyers: { edges = [] } = {} } = {}, loading } = renderProps;
            return <BulkModalBody>
              <Sidebar width={310}
                       className={withClassPrefix("sidebar")}>
                <Stack childrenRenderMode={"clone"} className={withClassPrefix("search")}>
                  <InputGroup inside>
                    <InputGroup.Addon>
                      <Icon type={"search"}/>
                    </InputGroup.Addon>
                    <Form.Control onChange={setSearch} name="name" accepter={AutoComplete} placeholder="Search..."/>
                  </InputGroup>
                </Stack>
                <Stack direction={"column"} alignItems={"flex-start"} childrenRenderMode={"clone"} spacing={16}
                       style={{ overflowY: "scroll", flex: 1 }}>
                  {
                    edges.map(({ node }) => {
                      return <Field
                        key={node.id}
                        required
                        type={"radio"}
                        value={node.objectId}
                        name="flyer">
                        {
                          ({ input, meta }) => {
                            return (
                              <RadioButton
                                {...input}>
                                {node.objectName}
                              </RadioButton>
                            );
                          }
                        }
                      </Field>;
                    })
                  }
                  {
                    loading ?
                      <CircularLoader
                        style={{ display: "flex", flex: 1, alignSelf: "center", justifyContent: "center" }}/> :
                      !loading && edges.length == 0 ?
                        <EmptyState
                          wrapperStyle={{ alignSelf: "center", marginRight: 16 }}
                          title={"No flyers"}
                          subtitle={search ? "Try again with different term." : "You haven't created any flyers yet."}/> :
                        null
                  }
                </Stack>
              </Sidebar>
              <Container className={withClassPrefix("container")}>
                <Field name={"flyer"} subscription={{ value: true }}>
                  {({ input: { value: flyerId } }) => {
                    const template = edges.find(({ node }) => node.objectId == flyerId);
                    return (
                      template ?
                        <Email template={template.node[ "content" ]}/> :
                        <EmptyState icon={"add_photo_alternate"} title={"No flyers"}
                                    subtitle={`You haven't selected any flyer yet.`}/>
                    );
                  }}
                </Field>
                <FormSpy
                  subscription={{ touched: true, submitErrors: true, values: true }}>
                  {
                    ({ touched, submitErrors, values }) => {
                      if (touched.flyer && submitErrors?.flyer && !values.flyer) {
                        return <SectionMessage
                          type="warning">{submitErrors?.flyer ?? "Flyer is missing. Please choose flyer."}
                        </SectionMessage>;
                      }
                      return null;
                    }
                  }
                </FormSpy>
              </Container>
            </BulkModalBody>;
          }}
        />
        <Modal.Footer>
          <Button type={"button"} size={"xs"} onClick={props.onClose} color={"blue"} appearance="text">CANCEL</Button>
          <Field name={"flyer"} subscription={{ value: true }}>
            {({ input: { value: flyerId } }) => <Button type={"submit"} size={"xs"} disabled={!flyerId}
                                                        appearance="primary">INSERT</Button>}
          </Field>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});
const FLYER_DIALOG_LIST = gql`
  query FlyerDialogList($where: FlyerWhereInput) {
    flyers(where: $where) {
      edges {
        node {
          ...Flyer
        }
      }
    }
  }
  ${FLYER_FRAGMENT}
`;
