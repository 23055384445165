import React                      from "react";
import { SearchableMultiSelect }  from "@relcu/ui";
import { Tag as RcTag }           from "@relcu/ui";
import { TagProps as RcTagProps } from "@relcu/ui";
import { useTag }                 from "./useTag";

export interface TagProps extends Omit<RcTagProps, | "options" | "onChange" | "searchText" | "onType"> {
  id: string;
  className: string;
  view?: "read" | "write";
  options?: string[]
  onChange?(tags)
}

export const Tag = React.memo<TagProps>(function Tag(props) {
  const { handleChange, options, searchText, handleType, handleCreateNew } = useTag(props);
  return (
    props.view == "write" ?
      <SearchableMultiSelect
        searchIcon={"local_offer"}
        onCreateNew={handleCreateNew}
        onType={handleType}
        searchText={searchText}
        value={props.tags}
        options={options}
        {...props}
      />
      :
      <RcTag
        {...props}
        searchText={searchText}
        onType={handleType}
        onChange={handleChange}
        options={options}
      />
  );
});
