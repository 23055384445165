export const MAX_SELECTED_EDGES = 3;

export const MIN_SELECTED_EDGES = 1;

export const AUDIO_LEVEL_THRESHOLD = 200;

export const AUDIO_LEVEL_STANDARD_DEVIATION_THRESHOLD = AUDIO_LEVEL_THRESHOLD * 0.05; // 5% threshold

export const INPUT_TEST_DURATION = 200000;

export const RECORD_DURATION = 200000;
