import React                  from "react";
import { FC }                 from "react";
import { BoxComponentProps }  from "../../Box";
import { Box }                from "../../Box";
import { ButtonSizes }        from "../../Button";
import { ButtonColors }       from "../../Button";
import { ButtonVariants }     from "../../Button";
import { Button }             from "../../Button";
import { ButtonGroup }        from "../../ButtonGroup";
import { Tooltip }            from "../../Tooltip";
import { TaskActionsClasses } from "./TaskActionsClasses";

interface TaskActionsProps extends BoxComponentProps {
  isCallable?: boolean,
  done?: boolean
  disabled?: boolean
  size?: ButtonSizes
  onCallBack?(e)
  onEdit?(e)
  onSendSms?(e)
  onMarkAsDone?(e)
  setActive?(active: boolean)
  onSnooze?(time: string)
  doneTitle?: string
  contactDisabled?: () => boolean
}

export const TaskActions: FC<TaskActionsProps> = React.memo(function TaskActions(props) {
  const { onCallBack, onEdit, contactDisabled = false, setActive, doneTitle, onSnooze, onMarkAsDone, isCallable, onSendSms, size, done, disabled } = props;
  // const show = task && (subMinutes(new Date(task.dueDate), Number(task.remind)) > new Date());
  return <Box container className={TaskActionsClasses.TaskActions} alignSelf={"start"}
              gap={"XXS"} flexGrow={0} flexBasis={72} alignItems={"center"}>
    {
      onCallBack && !done && !disabled && !contactDisabled &&
      <Tooltip title={"Call back"}>
        <Button
          onClick={e => {
            onCallBack(e);
          }}
          variant={ButtonVariants.Fill}
          size={size}
          icon={"call"}
          onlyIcon
          color={ButtonColors.Success}
          disabled={isCallable}/>
      </Tooltip>
    }
    {
      onSendSms && !done && !disabled && !contactDisabled &&
      <Tooltip title={"Send sms"}>
        <Button
          onClick={e => {
            onSendSms(e);
          }}
          size={size}
          variant={ButtonVariants.Fill}
          icon={"forum"} onlyIcon
          color={ButtonColors.Primary}/>
      </Tooltip>
    }
    {
      onMarkAsDone && !done &&
      <Tooltip title={doneTitle ?? "Mark as done"}>
        <Button onClick={e => onMarkAsDone(e)}
                size={size}
                variant={ButtonVariants.Fill}
                icon={"check"} onlyIcon
                color={ButtonColors.White}/>
      </Tooltip>
    }
    {
      onSnooze && setActive && !done && !disabled &&
      <ButtonGroup collapse size={size} tooltip={"Snooze"} buttonMenu onOpen={(e) => {
        e.stopPropagation();
        setActive(true);
      }} onClose={() => setActive(false)}>
        <Button
          size={size}
          variant={ButtonVariants.Fill}
          icon={"access_alarm"}
          onlyIcon
          color={ButtonColors.White}/>
        <Button onClick={(e) => {
          e.stopPropagation();
          setActive(false);
          onSnooze("10");
        }
        }> 10 minutes </Button>
        <Button onClick={(e) => {
          e.stopPropagation();
          setActive(false);
          onSnooze("20");
        }
        }> 20 minutes </Button>
        <Button onClick={(e) => {
          e.stopPropagation();
          setActive(false);
          onSnooze("30");
        }
        }> 30 minutes </Button>
      </ButtonGroup>
    }
    {
      onEdit &&
      <Tooltip title={"Edit"}>
        <Button onClick={onEdit}
                size={size}
                variant={ButtonVariants.Fill}
                icon={"create"}
                onlyIcon
                color={ButtonColors.White}/>
      </Tooltip>
    }
  </Box>;
});
TaskActions.defaultProps = {
  size: ButtonSizes.Medium,
  done: false
};
