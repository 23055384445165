import React              from "react";
import { FC }             from "react";
import { TextAreaProps }  from "../../Input/TextArea";
import { BaseField }      from "../BaseField";
import { BaseFieldProps } from "../BaseField";
import MultiTextEditField from "./MultiTextEditField";
import MultiTextReadField from "./MultiTextReadField";

export type MultiTextProps = BaseFieldProps & TextAreaProps;
export const MultiTextField: FC<MultiTextProps> = React.memo(function MultiTextField(props) {

  return (
    <BaseField
      {...props}
      readView={<MultiTextReadField/>}
      editView={<MultiTextEditField/>}
    />
  );
});
