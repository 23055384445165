import React               from "react";
import { InputState }      from "../../Input/Input";
import { Radio }           from "../../Input/Radio";
import { useConsumeField } from "../BaseField";

export const RadioEditField = React.memo(function RadioEditField(props) {
  const { input, meta: { touched, error } } = useConsumeField();
  const hasError = touched && !!error;
  delete input[ "public" ];//todo find public in backend
  return (
    <Radio
      {...input}
      state={(hasError && InputState.Error) || undefined}
    />
  );
});
export default RadioEditField;