import React                         from "react";
import { useState }                  from "react";
import { useMemo }                   from "react";
import { gql }                       from "@apollo/client";
import { useQuery }                  from "@apollo/client";
import { BaseFieldProps }            from "@relcu/ui";
import { PointerListField }          from "@relcu/ui";
import { PHONE_LINE_FRAGMENT }       from "../../../../graph/operations.graphql";
import { SearchPhoneLinesVariables } from "./__types__/SearchPhoneLines";
import { SearchPhoneLines }          from "./__types__/SearchPhoneLines";

const SEARCH_PHONE_LINES = gql`
  ${PHONE_LINE_FRAGMENT}
  query SearchPhoneLines($where:PhoneLineWhereInput!) {
    phoneLines(
      where: $where
      last: 10
    ){
      edges {
        node {
          ...PhoneLine
        }
      }
    }
  }
`;

export const PhoneLinesField = React.memo<BaseFieldProps>(function PhoneLinesField(props) {
  const [searchText, onType] = useState(String());
  const [selectedIds, setSelectedIds] = useState([]);
  const variables = useMemo(() => {
    const vars = {
      number: { matchesRegex: searchText || String(), options: "i" }
    };

    if (selectedIds.length) {
      vars[ "id" ] = {
        "in": selectedIds
      };
    } else {
      vars[ "assignedTo" ] = { exists: false };
      vars[ "common" ] = { equalTo: false };
    }

    return vars;
  }, [searchText, selectedIds]);

  const { data: { phoneLines: { edges = [] } = {} } = {} } = useQuery<SearchPhoneLines, SearchPhoneLinesVariables>(SEARCH_PHONE_LINES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: props.view === "read",
    variables: {
      where: variables
    }
  });
  const options = useMemo(() => edges.map(({ node }) => node), [edges]);

  return (
    <PointerListField
      {...props}
      onSelectedFilter={(selected) => {
        setSelectedIds((selected as any).map(s => s.id));
      }}
      searchText={searchText}
      onType={onType}
      options={options}
      renderSelect={null}
    />
  );
});
