import { HTMLAttributes }    from "react";
import React, { useContext } from "react";
import { useClassNames }     from "../utils";
import { useConstant }       from "./useConstant";
import { TabClasses }        from "./TabClasses";
import { Elements }          from "./TabContainer";
import { TabsState }         from "./TabContainer";

export const usePanelState = () => {
  const [activeIndex] = useContext(TabsState);
  const elements = useContext(Elements);

  const panelIndex = useConstant(() => {
    const currentIndex = elements.panels;
    elements.panels += 1;

    return currentIndex;
  });

  return panelIndex === activeIndex;
};

export interface TabPanelProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
}

export function TabPanel(props: TabPanelProps) {
  const { children, className, active,style, ...p } = props;
  const { merge, withClassPrefix } = useClassNames(TabClasses.TabPanel, "rc");
  const classes = merge(className, withClassPrefix());
  const isActive = usePanelState();

  if (typeof children === "function") {
    return React.cloneElement(children(isActive || active));
  }

  return isActive || active ?
    <div style={{ display: "flex", flex: 1, ...style }} className={classes} {...p}>{children}</div> :
    null;
}
