import { useMemo }        from "react";
import { getField }       from "../utils/schemaUtils";
import { usePermissions } from "./AccessControl";
import { useMailboxes }   from "./useMailboxes";

export function useMailAccess(node) {
  const { mailBoxes: mBoxes } = useMailboxes();
  const mailBoxes = useMemo(() => mBoxes.map(({ node }) => ({ ...node, disabled: node.disabled || node.domain.disabled })), [mBoxes]);
  const enabledMailBoxes = mailBoxes.filter(m => !m.disabled && !m.domain.disabled);
  const { canUpdate } = usePermissions(node);
  const { states } = getField("Lead", "leadStatus");
  return !!enabledMailBoxes.length && canUpdate && (node.__typename != "Lead" || !states[ node.leadStatus.status ]?.not_contact);
}
