import { useRef }                from "react";
import React, { HTMLAttributes } from "react";
import { useResolvedPath }       from "@relcu/react-router";
import { useLocation }           from "@relcu/react-router";
import { BoxComponentProps }     from "../..";
import { classNames }            from "../..";
import { GlobalClasses }         from "../../constants/GlobalClasses";
import { Badge }                 from "../Badge";
import { BadgeColors }           from "../Badge";
import { Box }                   from "../Box";
import { applyBoxItemStyles }    from "../Box";
import { BoxItemComponentProps } from "../Box";
import { FontIcon }              from "../Icon";
import { Link }                  from "../Link";
import { NavBarClasses }         from "./NavBarClasses";

export interface NavBarItemProps extends BoxItemComponentProps {
  draggable?: boolean,
  to: string,
  count?: number | string,
  small?: boolean,
  color?: BadgeColors,
  active?: boolean,
  onClick?()
}

const defaultItemProps = {
  draggable: false,
  onClick: null,
  small: false,
  color: BadgeColors.Primary
};

NavBarItem.defaultProps = defaultItemProps;
export function NavBarItem(props: NavBarItemProps) {
  let properties = applyBoxItemStyles<NavBarItemProps>(props);
  const { className, children, onClick, to, draggable, count, small, color, active, ...p } = properties;
  const { pathname } = useLocation();
  const classes = classNames(NavBarClasses.NavBarItem, {
    [ NavBarClasses.WithCounter ]: count > 0,
    [ GlobalClasses.Active ]: active,
    [ NavBarClasses.Draggable ]: draggable,
    [ NavBarClasses.Small ]: small,
    [ NavBarClasses.NavBarActiveItem ]: pathname.startsWith(useResolvedPath(to).pathname)//todo to not hover active link need to change
  }, className);
  const itemRef = useRef(null);

  return <Box
    ref={itemRef}
    onClick={onClick}
    className={classes} alignItems={"center"}
    tabIndex={1} draggable container {...p}
  >
    {draggable && <FontIcon className={NavBarClasses.DragIcon} type="drag_indicator"/>}
    <Link to={to} tabIndex={0} container flex={1} alignItems={"center"} draggable={false}>
      <Box flexGrow={1} container gap={small ? null : "XXS"} alignItems={"center"} direction={"row"}>
        {children}
        {count > 0 && !small &&
        <Badge alignSelf={"start"}
               color={color}
               label={count >= 1000 ? `${Math.round(Number(count) / 1000)}K` : count.toString()}/>}
      </Box>
    </Link>
  </Box>;
}

export function NavBarItemAction(props: HTMLAttributes<any>) {
  const { className, children, ...p } = props;
  const classes = classNames(NavBarClasses.NavBarItemActions, className);

  return <Box container {...p} className={classes} alignItems={"center"} gap={"XXS"}>
    {children}
  </Box>;
}

export function NavBarItemContent(props: BoxComponentProps) {
  const { className, children, ...p } = props;
  const classes = classNames(NavBarClasses.NavBarItemContent, className);

  return <Box container {...p} alignItems={"start"} className={classes} flex={1}>
    {children}
  </Box>;
}
