import { useCallback }          from "react";
import { useContext }           from "react";
import { useSource }            from "@relcu/ui";
import { LoanEstimateOffer }    from "../../../../../../graph/__types__/LoanEstimateOffer";
import { useSchemaField }       from "../../../../useSchemaField";
import { Proposal }             from "../../Proposal";
import { loanAmountDetails }    from "../../utils";
import { productName }          from "../../utils";
import { totalLoanAmount }      from "../../utils";
import { PricingEngineContext } from "./PricingEngineProvider";

export function useCalculations() {
  const { $settings } = useSource();
  const settings = $settings.pricing;
  const loanEstimate = useContext(Proposal.Context);
  const engine = useContext(PricingEngineContext);
  const { defaultValue: sourceDefaultValue } = useSchemaField("LoanEstimateOffer", "mortech.source");
  const { defaultValue: viewDefaultValue } = useSchemaField("LoanEstimateOffer", "mortech.view");
  const { defaultValue: initialArmTermValue } = useSchemaField("LoanEstimateOffer", "initialArmTerm");
  const calculate = useCallback((allValues, name?: string) => {
    const calculations = {
      exempt: {
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, allValues, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, allValues, settings);
        }
      },
      loanAmount: {
        get downPayment() {
          return Math.round((allValues.propertyValue - allValues.loanAmount) * 100) / 100;
        },
        get ltv() {
          return (allValues.loanAmount / allValues.propertyValue) * 100;
        },
        get cltv() {
          if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
            return this.ltv;
          }
          return allValues.cltv;
        },
        get currentMortgageBalance() {
          if (loanEstimate.loanPurpose === "cash_out_refinance") {
            return ((!allValues?.currentMortgageBalance && allValues?.currentMortgageBalance!=0) || allValues.loanAmount < allValues.currentMortgageBalance) ? Math.round(allValues.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else if (loanEstimate.loanPurpose === "rate_term_refinance") {
            return ((!allValues?.currentMortgageBalance && allValues?.currentMortgageBalance!=0) || allValues.loanAmount > allValues.currentMortgageBalance) ? Math.round(allValues.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else {
            return Math.round(allValues.loanAmount * 100) / 100;
          }
        },
        get cashAmount() {
          return allValues.loanAmount - this[ "currentMortgageBalance" ] > 0 ? Math.round((allValues.loanAmount - this[ "currentMortgageBalance" ]) * 100) / 100 : 0;
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (this.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        },
        get mip() {
          const { fee } = loanAmountDetails(loanEstimate, allValues, settings);
          return fee;
        },
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, allValues, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, allValues, settings);
        }
      },
      downPayment: {
        get loanAmount() {
          return Math.round((allValues.propertyValue - allValues.downPayment) * 100) / 100;
        },
        get ltv() {
          return (this.loanAmount / allValues.propertyValue) * 100;
        },
        get cltv() {
          if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
            return this.ltv;
          }
          return allValues.cltv;
        },
        get currentMortgageBalance() {
          if (loanEstimate.loanPurpose === "cash_out_refinance") {
            return (!allValues?.currentMortgageBalance || this.loanAmount < allValues.currentMortgageBalance) ? Math.round(this.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else if (loanEstimate.loanPurpose === "rate_term_refinance") {
            return (!allValues?.currentMortgageBalance || this.loanAmount > allValues.currentMortgageBalance) ? Math.round(this.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else {
            return Math.round(this.loanAmount * 100) / 100;
          }
        },
        get cashAmount() {
          return this.loanAmount - this[ "currentMortgageBalance" ] > 0 ? Math.round((this.loanAmount - this[ "currentMortgageBalance" ]) * 100) / 100 : 0;
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (this.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        },
        get mip() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
        }
      },
      ltv: {
        get loanAmount() {
          return Math.round((allValues.ltv * allValues.propertyValue)) / 100;
        },
        get downPayment() {
          return Math.round((allValues.propertyValue - this.loanAmount) * 100) / 100;
        },
        get cltv() {
          if (allValues.secondaryFinancing === "none" || allValues.cltv <= allValues.ltv || allValues.cltv > 100) {
            return allValues.ltv;
          }
          return allValues.cltv;
        },
        get mip() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
        },
        get currentMortgageBalance() {
          if (loanEstimate.loanPurpose === "cash_out_refinance") {
            return ((!allValues?.currentMortgageBalance && allValues?.currentMortgageBalance!=0) || this.loanAmount < allValues.currentMortgageBalance) ? Math.round(this.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else if (loanEstimate.loanPurpose === "rate_term_refinance") {
            return ((!allValues?.currentMortgageBalance && allValues?.currentMortgageBalance!=0) || this.loanAmount > allValues.currentMortgageBalance) ? Math.round(this.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else {
            return Math.round(this.loanAmount * 100) / 100;
          }
        },
        get cashAmount() {
          return this.loanAmount - this[ "currentMortgageBalance" ] > 0 ? Math.round((this.loanAmount - this[ "currentMortgageBalance" ]) * 100) / 100 : 0;
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (allValues.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        }
      },
      propertyValue: {
        get loanAmount() {
          return allValues.propertyValue > allValues.loanAmount ? Math.round(allValues.loanAmount) : Math.round(allValues.propertyValue);
        },
        get downPayment() {
          return Math.round((allValues.propertyValue - this.loanAmount) * 100) / 100;
        },
        get ltv() {
          return (this.loanAmount / allValues.propertyValue) * 100;
        },
        get cltv() {
          if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
            return this.ltv;
          }
          return allValues.cltv;
        },
        get mip() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
        },
        get currentMortgageBalance() {
          if (loanEstimate.loanPurpose === "cash_out_refinance") {
            return ((!allValues?.currentMortgageBalance && allValues?.currentMortgageBalance!=0) || this.loanAmount < allValues.currentMortgageBalance) ? Math.round(this.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else if (loanEstimate.loanPurpose === "rate_term_refinance") {
            return ((!allValues?.currentMortgageBalance && allValues?.currentMortgageBalance!=0) || this.loanAmount > allValues.currentMortgageBalance) ? Math.round(this.loanAmount * 100) / 100 : Math.round(allValues.currentMortgageBalance * 100) / 100;
          } else {
            return Math.round(this.loanAmount * 100) / 100;
          }
        },
        get cashAmount() {
          return this.loanAmount - this[ "currentMortgageBalance" ] > 0 ? Math.round((this.loanAmount - this[ "currentMortgageBalance" ]) * 100) / 100 : 0;
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (this.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        }
      },
      financeFf: {
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, allValues, settings);
        }
      },
      financeMip: {
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, allValues, settings);
        }
      },
      firstUseOfVaProgram: {
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, allValues, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, ff: this.ff }, settings);
        }
      },
      productType: {
        get financeMip() {
          return (allValues.productType === "fha" && allValues.financeMip === undefined ? true : allValues.financeMip);
        },
        get financeFf() {
          return (allValues.productType === "va" && allValues.financeFf === undefined ? true : allValues.financeFf);
        },
        get isStreamLine() {
          if (["va", "fha"].includes(allValues.productType) && loanEstimate.loanPurpose === "rate_term_refinance") {
            return allValues.isStreamLine;
          }
          return false;
        },
        get withAppraisal() {
          return this.isStreamLine ? false : allValues.withAppraisal;
        },
        get isHUDReo() {
          if (allValues.productType === "fha" && loanEstimate.loanPurpose === "purchase" && allValues.propertyUse === "primary_residence") {
            return allValues.isHUDReo;
          }
          return false;
        },
        get mip() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, financeMip: this.financeMip, financeFf: this.financeFf, isStreamLine: this.isStreamLine }, settings);
          return fee;
        },
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, financeMip: this.financeMip, financeFf: this.financeFf, isStreamLine: this.isStreamLine }, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, financeMip: this.financeMip, financeFf: this.financeFf, ff: this.ff, mip: this.mip, isStreamLine: this.isStreamLine }, settings);
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (allValues.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        },
        get loanProduct() {
          return allValues.pricingEngine == "mortech" ? allValues.loanProduct : productName(allValues);
        }
      },
      currentMortgageBalance: {
        get loanAmount() {
          if (loanEstimate.loanPurpose == "cash_out_refinance") {
            return allValues.loanAmount > allValues.cashAmount + allValues.currentMortgageBalance ? Math.round(allValues.loanAmount * 100) / 100 : Math.round((allValues.cashAmount + allValues.currentMortgageBalance) * 100) / 100;
          } else if (loanEstimate.loanPurpose == "rate_term_refinance" && (!allValues.loanAmount || allValues.loanAmount > allValues.currentMortgageBalance)) {
            return Math.round(allValues.currentMortgageBalance * 100) / 100;
          }
          return Math.round(allValues.loanAmount * 100) / 100;
        },
        get downPayment() {
          return Math.round((allValues.propertyValue - this.loanAmount) * 100) / 100;
        },
        get cashAmount() {
          return (this.loanAmount - allValues.currentMortgageBalance) > 0 ? Math.round((this.loanAmount - allValues.currentMortgageBalance) * 100) / 100 : 0;
        },
        get ltv() {
          return (this.loanAmount / allValues.propertyValue) * 100;
        },
        get cltv() {
          if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
            return this.ltv;
          }
          return allValues.cltv;
        },
        get mip() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (this.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        }
      },
      cashAmount: {
        get loanAmount() {
          if (loanEstimate.loanPurpose !== "cash_out_refinance") {
            return Math.round(allValues.loanAmount * 100) / 100;
          }
          return Math.round((allValues.cashAmount + allValues.currentMortgageBalance) * 100) / 100;
        },
        get downPayment() {
          return Math.round((allValues.propertyValue - this.loanAmount) * 100) / 100;
        },
        get ltv() {
          return (this.loanAmount / allValues.propertyValue) * 100;
        },
        get cltv() {
          if (allValues.secondaryFinancing === "none" || allValues.cltv <= this.ltv || allValues.cltv > 100) {
            return this.ltv;
          }
          return allValues.cltv;
        },
        get mip() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, loanAmount: this.loanAmount }, settings);
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (this.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        }
      },
      secondaryFinancing: {
        get cltv() {
          if (allValues.secondaryFinancing === "none" || allValues.cltv <= allValues.ltv || allValues.cltv > 100) {
            return allValues.ltv;
          }
          return allValues.cltv;
        }
      },
      isStreamLine: {
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, allValues, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, ff: this.ff }, settings);
        },
        get withAppraisal() {
          return !allValues.isStreamLine ? false : allValues.withAppraisal;
        }
      },
      veteranStatus: {
        get ff() {
          const { fee } = loanAmountDetails(loanEstimate, allValues, settings);
          return fee;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, { ...allValues, ff: this.ff }, settings);
        }
      },
      pricingEngine: {
        get "mortech.source"() {
          if (allValues.pricingEngine == "mortech") {
            return allValues?.mortech?.source ?? sourceDefaultValue;
          }
          return allValues?.mortech?.source;
        },
        get "optimalBlue.source"() {
          if (allValues.pricingEngine == "optimalblue") {
            return allValues?.optimalBlue?.source;
          }
        },
        get "mortech.view"() {
          if (allValues.pricingEngine == "mortech") {
            return allValues?.mortech?.view ?? viewDefaultValue;
          }
          return allValues?.mortech?.view;
        },
        get loanProduct() {
          return allValues.pricingEngine == "mortech" ? allValues.loanProduct : productName(allValues);
        }
      },
      loanTerm: {
        get loanProduct() {
          return allValues.pricingEngine == "mortech" ? allValues.loanProduct : productName(allValues);
        }
      },
      amortizationType: {
        get loanProduct() {
          return allValues.pricingEngine == "mortech" ? allValues.loanProduct : productName(allValues);
        },
        get initialArmTerm() {
          return allValues.pricingEngine == "mortech" ?
            allValues.initialArmTerm :
            allValues.initialArmTerm ?? initialArmTermValue;
        }
      },
      conforming: {
        get loanProduct() {
          return allValues.pricingEngine == "mortech" ? allValues.loanProduct : productName(allValues);
        },
        get pmiEligible() {
          return (allValues.productType === "conventional" && allValues.conforming && (allValues.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        }
      },
      initialArmTerm: {
        get loanProduct() {
          return allValues.pricingEngine == "mortech" ? allValues.loanProduct : productName(allValues);
        }
      },
      "mortech.loanProductId": {
        get loanProduct() {
          if (allValues.pricingEngine === "mortech") {
            const productId = allValues.mortech.loanProductId;
            const option = engine.mortechProductList.find(value => value.value == productId);
            return option?.label ?? allValues.loanProduct ?? "";
          }
          return productName(allValues);
        },
        get productType() {
          if (allValues.pricingEngine === "mortech") {
            let loanProductType = "conventional";
            if (this.loanProduct) {
              if (this.loanProduct.toLowerCase().includes("fha")) {
                loanProductType = "fha";
              } else if (this.loanProduct.toLowerCase().includes("va")) {
                loanProductType = "va";
              } else if (this.loanProduct.toLowerCase().includes("jum") || this.loanProduct.toLowerCase().includes("jumbo")) {
                loanProductType = "conventional";
              } else if (this.loanProduct.toLowerCase().includes("non conf")) {
                loanProductType = "conventional";
              }
            }
            return loanProductType;
          }
          return allValues.productType;
        },
        get conforming() {
          if (allValues.pricingEngine === "mortech") {
            let conforming = true;

            if (this.loanProduct.toLowerCase().includes("fha")) {
              conforming = false;
            } else if (this.loanProduct.toLowerCase().includes("va")) {
              conforming = false;
            } else if (this.loanProduct.toLowerCase().includes("jum") || this.loanProduct.toLowerCase().includes("jumbo")) {
              conforming = false;
            } else if (this.loanProduct.toLowerCase().includes("non conf")) {
              conforming = false;
            }

            return conforming;
          }

          return allValues.conforming;
        },
        get amortizationType() {
          if (allValues.pricingEngine === "mortech") {
            let amortizationType = "fixed";
            let productMatches = this.loanProduct.match(/.*(\d{2}).*[Yr|Year].*[ARM|LIBOR][^0-9]+(\d{1,2})[\/1| Yr.*]/);

            if (productMatches) {
              amortizationType = "arm";
            } else {
              productMatches = this.loanProduct.match(/(\d{2})( )(Yr|Year)(.*)(Fixed)/i);
              if (productMatches && ["10", "15", "20", "25", "30", "40"].includes(productMatches[ 1 ])) {
                amortizationType = "fixed";
              } else {
                if (this.loanProduct.match(/.*(\d{2})$/)) {
                  amortizationType = "fixed";
                }
              }
            }

            return amortizationType;
          }
          return allValues.amortizationType;
        },
        get initialArmTerm() {
          if (allValues.pricingEngine === "mortech") {
            let initialArmTerm;
            let productMatches = this.loanProduct.match(/.*(\d{2}).*[Yr|Year].*[ARM|LIBOR][^0-9]+(\d{1,2})[\/1| Yr.*]/);

            if (productMatches) {
              initialArmTerm = (parseInt(productMatches[ 2 ]) * 12).toString();
            }

            return initialArmTerm;

          }

          return allValues.initialArmTerm;
        },

        get loanTerm() {
          if (allValues.pricingEngine === "mortech") {
            let loanTerm = "360";
            let productMatches = this.loanProduct.match(/.*(\d{2}).*[Yr|Year].*[ARM|LIBOR][^0-9]+(\d{1,2})[\/1| Yr.*]/);

            if (productMatches) {
              loanTerm = (parseInt(productMatches[ 1 ]) * 12).toString();
            } else {
              productMatches = this.loanProduct.match(/(\d{2})( )(Yr|Year)(.*)(Fixed)/i);
              if (productMatches && ["10", "15", "20", "25", "30", "40"].includes(productMatches[ 1 ])) {
                loanTerm = (parseInt(productMatches[ 1 ]) * 12).toString();
              } else {
                productMatches = this.loanProduct.match(/.*(\d{2})$/);
                if (productMatches) {
                  loanTerm = (parseInt(productMatches[ 1 ]) * 12).toString();
                }
              }
            }

            return loanTerm;
          }
          return allValues.loanTerm;
        }
      }
    } as Record<keyof LoanEstimateOffer & "mortech.productId", any>;
    const changes = {};
    if (!name) {
      Object.keys(calculations).forEach(field => {
        const changed = Object.assign({}, calculations[ field ]);
        Object.keys(changed).forEach(field => {
          changes[ field ] = changed[ field ];
          // form.change(field as keyof LoanEstimateOffer, changed[ field ]);
        });
        // allValues = form.getState().values;
      });
    } else {
      const changed = Object.assign({}, calculations[ name ]);
      Object.keys(changed).forEach(field => {
        changes[ field ] = changed[ field ];
        // form.change(field as keyof LoanEstimateOffer, changed[ field ]);
      });
    }
    return changes;
  }, [loanEstimate, initialArmTermValue, viewDefaultValue, sourceDefaultValue, settings]);

  return calculate;
}
