import React                 from "react";
import { useMemo }           from "react";
import { useCallback }       from "react";
import { Form }              from "@relcu/form";
import { ModalFooter }       from "@relcu/ui";
import { ModalBody }         from "@relcu/ui";
import { Modal }             from "@relcu/ui";
import { ButtonVariants }    from "@relcu/ui";
import { Button }            from "@relcu/ui";
import { defaultMutators }   from "@relcu/ui";
import { Share }             from "../PageViewDialog/Steps/Share";
import { SchemaDialogProps } from "../SchemaDialog";

export interface PriorityQueueShareDialog extends SchemaDialogProps {
  rPerm: string[];
  onConfirm: (data: string[]) => void;
}

export const PriorityQueueShareDialog = React.memo<PriorityQueueShareDialog>(function PriorityQueueShareDialog(props) {
  const initial = useMemo(() => ({ rPerm: props.rPerm ?? [] }), []);

  const handleSubmit = useCallback((values, form) => {
    props.onConfirm(values.rPerm);
  }, []);

  return (
    <Modal
      title={"Share View"}
      variant={"large"}
      style={{ minHeight: "90%" }}
      open={props.open}
      onClose={props.onClose}
      closeIcon={false}
      disableBackdropClick={false}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={initial}
        subscription={{ submitting: true, submitErrors: true }}
        mutators={defaultMutators}>
        {
          ({ handleSubmit, submitting, submitErrors }) => {
            return <form
              onSubmit={handleSubmit}
              noValidate
              style={{ display: "contents" }}>
              <ModalBody
                style={{ padding: 0, position: "relative" }}
                container
                direction={"column"}
                flexGrow={1}>
                <Share/>
                {
                  <ModalFooter borderOnTop>
                    <Button disabled={submitting} type="button" variant={ButtonVariants.Ghost}
                            onClick={props.onClose}>CANCEL</Button>
                    <Button disabled={submitting} type="submit">SHARE</Button>
                  </ModalFooter>
                }
              </ModalBody>
            </form>;
          }
        }
      </Form>
    </Modal>
  );
});