import React                from "react";
import { FC }               from "react";
import { Section }          from "@relcu/ui";
import { SelectVariants }   from "@relcu/ui";
import { Select }           from "@relcu/ui";
import { Box }              from "@relcu/ui";
import { JqlForm }          from "../../Jql/JqlForm";
import { useRelcuLoanView } from "./useRelcuLoanView";

export const RelcuLoanView: FC<any> = React.memo(function RelcuLoanView(props) {
  const { loan, loanOptions, setLoanId, sections } = useRelcuLoanView(props);

  return <Box container direction={"column"} gap={"XXS"}>
    {
      (loanOptions.length > 0) &&
      <Section container gap={"XXS"} alignItems={"end"} alignContent={"center"} direction={"column"} editable={false}>
        <Select
          label={"Selected loan :"}
          mode={"button"}
          variant={SelectVariants.Ghost}
          placeholder="Choose loan"
          value={loan?.objectId}
          onChange={(select) => setLoanId(select.value)}
          options={loanOptions}
          flexGrow={0}
        />
      </Section>
    }
    <JqlForm sections={sections} className={"RelcuLoan"} jql={props.jql} initialValues={loan}/>
  </Box>;
});