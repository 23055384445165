import React             from "react";
import { CommonClasses } from "../../theme/classes";
import { Box }           from "../Box";
import { FontIcon }      from "../Icon";

export interface TimelineItemArrowProps {
  open: boolean
  onClick: () => void
}

export const TimelineItemArrow = React.memo<TimelineItemArrowProps>(({ open, onClick }) => {

  return <Box onClick={(e) => {
    e.preventDefault()
    e.stopPropagation()
    onClick();
  }}>
    <FontIcon type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"} className={CommonClasses.ClickableIcon}/>
  </Box>;
});