import React                      from "react";
import { FC }                     from "react";
import { useCallback }            from "react";
import { Icon }                   from "@relcu/rc";
import { Tooltip }                from "@relcu/rc";
import { Whisper }                from "@relcu/rc";
import { Badge }                  from "@relcu/rc";
import { MicrophoneAccessDialog } from "@relcu/ui";
import { modal }                  from "@relcu/ui";

export const MaybeError: FC<{ message?: string }> = React.memo(function MaybeError(props) {
  const { message, children } = props;
  const openMicrophoneAccessModal = useCallback(() => {
    const {destroy} = modal(MicrophoneAccessDialog,{
      open:true,
      closeIcon:true,
      onClose(e?) {
        destroy()
      }
    });
  }, []);
  if (message) {
    return <Badge className={"sound-controls-error-badge"} onClick={openMicrophoneAccessModal} content={
      <Whisper
        trigger="hover"
        placement={"top"}
        speaker={
          <Tooltip>{message}</Tooltip>
        }
      >
        <Icon color={"error"} type={"info" as any}/>
      </Whisper>
    }>
      {children}
    </Badge>;
  }
  return <>
    {children}
  </>;
});
