export enum CommonClasses {
  GrayIcon = "i--g",
  ClickableIcon = "i--c",
  ClickableWhiteIcon = "i--c-w",
  PrimaryIcon = "i--p",
  ErrorIcon = "i--e",
  TextTiny = "text--tiny",
  TextSecondary = "text--secondary",
  FlexEllipsis = "ell-flex",
  WidthEllipsis = "ell-width",
  SH = "scroll--hide"
}
