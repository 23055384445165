import React                from "react";
import { FC }               from "react";
import { StepClasses }      from "@relcu/ui";
import { StepRow }          from "@relcu/ui";
import { StepEmptySection } from "@relcu/ui";
import { FieldArray }       from "@relcu/form";
import {
  Alert,
  AlertColors,
  Box,
  Button,
  ButtonVariants,
  ChoiceField,
  CommonClasses,
  FieldSpy,
  FontIcon,
  ListItemText
}                           from "@relcu/ui";

export type LeadSortProps = { name, handleClean?, error, onChange, prioritizeByOptions, orderOptions }
export const LeadSort: FC<LeadSortProps> = React.memo(function LeadSort(props) {
  const { name, handleClean, error, onChange, prioritizeByOptions, orderOptions } = props;
  return <>
    <FieldSpy name={name} subscription={{ value: true }} onChange={onChange}/>
    <FieldArray name={name}>
      {
        ({ fields, meta }) => {
          return !fields.length ?
            <StepEmptySection
              content={`If you skip this step filtered leads will be distributed to all users.`}
              title={`Click on “Add filter” to map the filtered results.`}
              onClick={() => fields.push({})}>
            </StepEmptySection>
            :
            <Box container direction={"column"} gap={"XXXS"}>
              <Box container gap={"XS"} alignItems={"center"} className={StepClasses.StepRowHeader}>
                <ListItemText flexShrink={0} flex={"1 1 calc(50% - ( 30px / 2 ))"} className={StepClasses.StepItem}
                              text={"Prioritize by"} tiny/>
                <ListItemText flexShrink={0} flex={"1 1 calc(50% - ( 30px / 2 ))"} className={StepClasses.StepItem}
                              text={"Order"} tiny/>
                <FontIcon flexShrink={0} type={"" as any}/>
              </Box>
              {
                fields.map((name, index) => {
                  return <StepRow gap={"XS"} key={index}>
                    <ChoiceField halfSize name={`${name}.prioritizeBy`}
                                 options={prioritizeByOptions ?? []}
                                 placeholder={"Select field"}/>
                    <ChoiceField halfSize name={`${name}.order`}
                                 options={orderOptions ?? [{ value: "desc", label: "Descending" }, { value: "asc", label: "Ascending" }]}
                                 placeholder={"Select order"}/>
                    <FontIcon type={index == 0 ? "add" : "remove"} className={CommonClasses.ClickableIcon}
                              onClick={() => index == 0 ? fields.push({}) : fields.remove(index)}/>
                  </StepRow>;
                })
              }
              {
                handleClean &&
                <Button
                  alignSelf={"end"}
                  variant={ButtonVariants.Ghost}
                  onClick={handleClean}>
                  Clear filter
                </Button>
              }
            </Box>;
        }
      }
    </FieldArray>
    {
      error &&
      <Alert stretch variant={AlertColors.Error}>
        {error}
      </Alert>
    }
  </>;
});
