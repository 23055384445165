import { useMemo }                  from "react";
import { useContext }               from "react";
import React                        from "react";
import { FC }                       from "react";
import { useReactiveVar }           from "@apollo/client";
import { StepSection }              from "@relcu/ui";
import { DistributionQueueContext } from "../../../../DistributionQueueDialog";
import { RuleSectionInterface }     from "../Rule";
import { LeadSort }                 from "./LeadSort/LeadSort";

export const Sort: FC<RuleSectionInterface> = React.memo(function Sort(props) {
  const { name, handleClean, index } = props;
  const { sortingAndDistribution, notCompleteRulesVar, clearRulesError } = useContext(DistributionQueueContext);
  const notCompleteRules = useReactiveVar(notCompleteRulesVar);
  const errorName = useMemo(() => name.split(".")[ 1 ], [name]);
  const error = useMemo(() => notCompleteRules?.[ index ]?.[ errorName ], [notCompleteRules, index, errorName]);

  return <StepSection
    title={"Lead sorting and distribution sequence"}
    tooltip={"Define the logic for distribution sequence and lead prioritization."}
    error={!!error}
  >
    <LeadSort
      name={name}
      error={error}
      handleClean={() => handleClean(name, [])}
      prioritizeByOptions={sortingAndDistribution?.prioritizeBy?.options ?? []}
      orderOptions={sortingAndDistribution?.order?.options ?? [{ value: "desc", label: "Descending" }, { value: "asc", label: "Ascending" }]}
      onChange={() => clearRulesError(index, errorName)}
    />
  </StepSection>;
});


