import { useState }              from "react";
import { useMemo }               from "react";
import { ReactElement }          from "react";
import { useMutation }           from "@apollo/client";
import { createCalculation }     from "@relcu/form";
import { useSource }             from "@relcu/ui";
import { getIn }                 from "@relcu/form";
import { setIn }                 from "@relcu/form";
import { IBaseField }            from "../../types/ISchemas";
import { IPointerField }         from "../../types/ISchemas";
import { compileVars }           from "../../utils/helpers";
import { getField }              from "../../utils/schemaUtils";
import { isPointerField }        from "../../utils/schemaUtils";
import { isStatusField }         from "../../utils/schemaUtils";
import { isArrayField }          from "../../utils/schemaUtils";
import { isBooleanField }        from "../../utils/schemaUtils";
import { UpdateFilterVariables } from "./__types__/UpdateFilter";
import { UpdateFilter }          from "./__types__/UpdateFilter";
import { FilterOperator }        from "./FilterOperator";
import { applyStatus }           from "./parseFilter";
import { applyBoolean }          from "./parseFilter";
import { applyOn }               from "./parseFilter";
import { applyContains }         from "./parseFilter";
import { applyEndWith }          from "./parseFilter";
import { applyStartWith }        from "./parseFilter";
import { UPDATE_FILTER }         from "./useFilters";

export function useFilter(props: UseFilterProps) {
  const source = useSource();
  const target = props.schema;
  const [update] = useMutation<UpdateFilter, UpdateFilterVariables>(UPDATE_FILTER);
  function getCompiledRules() {
    return compileVars(props.rules || [], source);
  }
  function getSchemas() {
    return props.fields.reduce((schema, column) => {
      schema[ column.field ] = getField(props.schema, column.field);
      return schema;
    }, {});
  }
  function getColumns() {
    return props.fields.reduce((object, column) => {
      const schema = schemas[ column.field ];
      object[ column.field ] = {
        ...column,
        type: column.type || schema?.type,
        label: column.label || schema?.label
      };
      return object;
    }, {});
  }

  function getFields() {
    return props.fields.map(column => {
      const schema = schemas[ column.field ];
      return {
        value: column.field,
        label: column.label || schema.label || column.field
      };
    });
  }
  function getInitialRules() {
    const defaultRules = [] as FilterValues[];
    let rules = compiledRules || defaultRules;
    rules = rules.filter(rule => !rule.hidden);
    rules = rules.length === 0 ? defaultRules : rules;
    return rules;
  }
  function getHiddenRules() {
    return compiledRules.filter(r => r.hidden);
  }
  function getQuery() {
    return parse({ rules: compiledRules });
  }

  function parse({ rules }: FilterValues) {
    let where = { "AND": [] };
    rules.forEach(rule => {
      if (isStatusField(getField(props.schema, rule.field))) {
        const field = rule.field.split(".")[ 0 ];
        applyStatus(where, rule, field);
      } else if (isPointerField(getField(props.schema, rule.field))) {
        if (["in", "notIn"].includes(rule.operator)) {
          const schema = getField(props.schema, rule.field) as IPointerField;
          if (Array.isArray(schema.targetClass)) {
            where[ "AND" ].push({
              [ rule.operator === "in" ? "OR" : "AND" ]: [
                ...rule.value.map?.(({ id }) => {
                  return {
                    [ rule.field ]: {
                      [ rule.operator === "in" ? "have" : "haveNot" ]: {
                        link: id
                      }
                    }
                  };
                })
              ]
            });
          } else {
            where[ "AND" ].push({
              [ rule.field ]: {
                [ "have" ]: {
                  [ "id" ]: {
                    ...where[ rule.field ]?.[ "have" ]?.[ "id" ],
                    [ rule.operator ]: rule.value.map?.(({ id }) => id) || []
                  }
                }
              }
            });
          }
        } else {
          where[ "AND" ].push(setIn({}, rule.field, { [ rule.operator == "notExists" ? "exists" : rule.operator ]: (rule.operator == "exists" ? true : (rule.operator == "notExists" ? false : rule.value)) }));
        }
      } else if (isBooleanField(getField(props.schema, rule.field))) {
        if (rule.field.includes(".")) {
          const [parentField, childField] = rule.field.split(/[.[\]]+/).filter(Boolean);
          if (isArrayField(getField(props.schema, parentField))) {
            where.AND.push({
              [ parentField ]: {
                [ rule.operator === "notExists" ? "haveNot" : "have" ]: {
                  [ childField ]: {
                    equalTo: true
                  }
                }
              }
            });
          }
        } else {
          applyBoolean(where, rule);
        }
      } else {
        if (["exists", "notExists"].includes(rule.operator)) {
          if (rule.field === "tags") {
            where[ "AND" ].push({ [ rule.field ]: { [ rule.operator == "notExists" ? "equalTo" : "notEqualTo" ]: [] } });
          } else {
            where[ "AND" ].push(setIn({}, rule.field, { [ rule.operator == "notExists" ? "equalTo" : "notEqualTo" ]: null }));
          }
        } else if (rule.operator === "on") {
          applyOn(where, rule);
        } else if (rule.operator == "startWith") {
          applyStartWith(where, rule);
        } else if (rule.operator == "endWith") {
          applyEndWith(where, rule);
        } else if (rule.operator == "contains") {
          applyContains(where, rule);
        } else {
          where[ "AND" ].push(setIn({}, rule.field, { [ rule.operator ]: rule.value }));
        }
      }
    });
    if (!where[ "AND" ]?.length) {
      delete where[ "AND" ];
    }//todo check how to check if rules is empty
    return where;
  }

  const compiledRules = useMemo(getCompiledRules, [props, source]);
  const schemas = useMemo(getSchemas, [props]);
  const columns = useMemo(getColumns, [props]);
  const fields = useMemo(getFields, [props]);
  const rules = useMemo(getInitialRules, [props]);
  const hiddenRules = useMemo(getHiddenRules, [props]);
  const query = useMemo(getQuery, [props]);
  const [filtersCount, setFiltersCount] = useState(compiledRules.length - hiddenRules.length);
  const context = {
    viewerId: source.$viewer.id,
    fields,
    filtersCount,
    query,
    target,
    decorators: [decorator],
    initialValues: {
      rules
    },
    async onFilterUpdate(selected, form) {
      const { values: { rules = [] } } = form.getState();
      await update({
        variables: {
          input: {
            id: selected,
            fields: {
              rules
            }
          }
        }
      });
    },
    onApply({ rules }: FilterValues) {
      rules = [...hiddenRules, ...rules];
      props.onApply(parse({ rules }));
      setFiltersCount(rules.length - hiddenRules.length);
    },
    getFieldSchema<T = IBaseField>(field: string): T {
      return schemas[ field ];
    },
    getField(field: string) {
      return columns[ field ];
    },
    getType(field: string) {
      const schema = columns[ field ] || schemas[ field ];
      return schema?.type;
    },
    getOperators(field: string) {
      return FilterOperator[ context.getType(field) ];
    },
    getComponent(field: string, operator: string) {
      const column = columns[ field ];
      if (!operator) {
        return null;
      }
      if (typeof column?.render === "function") {
        return column.render(column, operator);
      }
      const operation = FilterOperator[ context.getType(field) ]?.find?.(o => o.value === operator);
      return operation?.component;
    }
  };
  return context;
}

const decorator = createCalculation({
  isEqual(a, b) {
    if (typeof b === "undefined") {
      return true;
    }
    return a === b;
  },
  field: /rules\[\d+\]\.operator/,
  updates: (value, name, allValues: FilterValues, prevValues: FilterValues) => {
    if (allValues.__reset || prevValues.rules.length > allValues.rules.length) {
      return {};
    }
    let valueFieldName = name.replace("operator", "value");
    let defaultValue = null;
    if (["in", "notIn"].includes(value)) {
      defaultValue = [];
    } else if (value === "exists") {
      defaultValue = false;
    }
    return { [ valueFieldName ]: defaultValue };
  }
}, {
  isEqual(a, b) {
    if (typeof b === "undefined") {
      return true;
    }
    return a === b;
  },
  field: /rules\[\d+\]\.field/,
  updates: (value, name, allValues: FilterValues, prevValues: FilterValues) => {
    if (allValues.__reset || prevValues.rules.length > allValues.rules.length) {
      return {};
    }
    let valueFieldName = name.replace("field", "value");
    let operatorFieldName = name.replace("field", "operator");
    const operator = getIn(allValues, operatorFieldName);
    let defaultValue;
    if (["in", "notIn"].includes(operator)) {
      defaultValue = [];
    } else if (value === "exists") {
      defaultValue = false;
    }
    return Promise.resolve().then(() => ({ [ valueFieldName ]: defaultValue }));
  }
});

export type UseFilter = ReturnType<typeof useFilter>;
export type FilterValues = { rules: FilterRule[], __reset?: boolean };
export interface UseFilterProps {
  schema: string;
  fields: FilterField[]
  rules?: FilterRule[],
  onApply: (values) => void
}
export interface FilterField {
  field: string,
  label?: string,
  type?: string,
  defaultValue?: any,
  render?: (column: FilterField, operator: string) => ReactElement
}
export interface FilterRule {
  field: string;
  operator: string;
  value: any;
  hidden?: boolean
}
