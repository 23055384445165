import React                 from "react";
import { FC }                from "react";
import InputMask             from "react-input-mask";
import { FlexboxGrid }       from "@relcu/rc";
import { Stack }             from "@relcu/rc";
import { Typography }        from "@relcu/rc";
import { Alignment }         from "@relcu/ui";
import { MenuItem }          from "@relcu/ui";
import { Menu }              from "@relcu/ui";
import { Popper }            from "@relcu/ui";
import { ContactWhereInput } from "../../../../types/graphql-global-types";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { checkContactIsUnknown } from "../../../../utils/helpers";
import { DialIcon }              from "./DialIcon";
import { useDialInput }          from "./useDialInput";
import "./dial-input.css";

export interface DialInputProps {
  userRole?: string
  queryFilterWhere?: Partial<ContactWhereInput>
  name: string
  actions?: string[]
}

export const DialInput: FC<DialInputProps> = React.memo(function DialInput(props) {
  const {
    input,
    handleChange,
    selectRef,
    handleBack,
    hasError,
    submitError,
    anchorBounding,
    setAnchorBounding,
    handleSelect,
    error,
    getPhone,
    checkPhoneNumberDisabled,
    options
  } = useDialInput(props);

  return (
    <Stack direction={"column"} spacing={8} style={{ height: 59, width: 252, alignSelf: "stretch" }} alignItems={"stretch"} childrenRenderMode={"clone"}>
      <FlexboxGrid
             className={"dail-input-container"}
             ref={selectRef}>
        <InputMask
          mask={"\\(999\\) 999-9999"}
          maskChar={null}
          placeholder={"Dial the number"}
          value={input.value}
          onChange={handleChange}
          beforeMaskedValueChange={(newState, oldState, userInput) => {
            if (userInput?.slice(0, 2) === "+1" && (oldState.selection.start === 0 || oldState.selection.start === 1)) {
              newState.value = userInput.slice(2);
            }
            return newState;
          }}
          className={"dail-input__text"}/>
        {
          input.value &&
            <DialIcon fill={"#6D7683"} onClick={handleBack}/>
        }
      </FlexboxGrid>
      {
        hasError &&
        <Typography variant={"small10"}
                    style={{ alignSelf: "center", lineHeight: "14px", color: "var(--rc-status-03-error-primary)" }}>{(error || submitError)}</Typography>
      }
      <Popper
        open={!!anchorBounding}
        anchorBounding={anchorBounding}
        onClickAway={() => setAnchorBounding(null)}
        alignment={Alignment.BottomLeft}
        threshold={0}>
        <Menu type={"select"} height={44} role="listbox"
              style={{ width: 252, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          {
            options.map((option, index) => {
              const phone = getPhone(option);
              const disabled = checkPhoneNumberDisabled(phone);
              return <MenuItem
                disabled={disabled}
                key={index}
                onClick={(e) => handleSelect?.(option)}>
                <Stack direction={"column"} alignItems={"flex-start"} childrenRenderMode={"clone"}>
                  <Typography
                    lineHeight={"lh24"}
                    color={"primary"}>
                    {checkContactIsUnknown(option.objectName) ? "Unknown contact" : option.objectName}
                  </Typography>
                  <Typography
                    lineHeight={"lh24"}
                    color={"tertiary"}>
                    {formatPhoneNumber(phone?.number)}
                  </Typography>
                </Stack>
              </MenuItem>;
            })
          }
        </Menu>
      </Popper>
    </Stack>
  );
});

DialInput.defaultProps = {
  actions: ["call", "sms"]
}

