import { TypePolicy } from "@apollo/client";
import { Root }       from "../../graph/__types__/Root";
import { ROOT }       from "../../graph/operations.graphql";

export const Loan: TypePolicy = {
  fields: {
    notifications: {
      read(exising, { readField, cache }) {
        const id = readField("objectId");
        const { notifications } = cache.readQuery<Root>({ query: ROOT });
        const loanNotifications = notifications.edges.filter(({ node }) => node.record?.objectId == id).map((n) => n.node);
        return loanNotifications.length;
      }
    }}
};
