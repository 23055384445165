import { useState }      from "react";
import { useMemo }       from "react";
import React             from "react";
import ReactDOM          from "react-dom";
import { QuotedHTML }    from "../../utils/QuotedHTML";
import ClearDotUrl       from "./cleardot.gif";
import MoreHorizPngUrl   from "./more_horiz_black_20dp.png";

export class EmailVisualizer extends HTMLElement {
  emailElement: HTMLElement;
  transformer: QuotedHTML;
  constructor() {
    super();
    this.emailElement = document.createElement("div");
    this.transformer = new QuotedHTML();
    this.attachShadow({ mode: "open" }).appendChild(this.emailElement);
  }

  renderEmail({ ref, template, collapse = false }) {
    const __html =  template;
    if (collapse) {
      ReactDOM.render(<MailCollapse ref={ref} html={__html}/>, this.emailElement);
    } else if (this.transformer.hasQuotedHTML(__html)) {
      ReactDOM.render(<EmailQuoted ref={ref} html={__html} transformer={this.transformer}/>, this.emailElement);
    } else {
      ReactDOM.render(<div ref={ref} dangerouslySetInnerHTML={{ __html }}/>, this.emailElement);
    }

  }
}

export const MailCollapse = React.forwardRef<HTMLDivElement, { html: string }>(function EmailQuoted({ html }, ref) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <TrimButton onClick={() => setExpand(q => !q)}/>
      {expand && <div ref={ref} dangerouslySetInnerHTML={{ __html: html }}/>}
    </>
  );
});

export const EmailQuoted = React.forwardRef<HTMLDivElement, EmailQuotedProps>(function EmailQuoted({ html, transformer }, ref) {
  const [quoted, setQuoted] = useState(false);
  const originalHtml = useMemo(() => transformer.removeQuotedHTML(html), [html, transformer]);
  const quotedHtml = useMemo(() => transformer.appendQuotedHTML("<div/>", html), [html, transformer]);
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: originalHtml }}/>
      {/*<FontIcon type="keyboard_control" onClick={() => setQuoted(q => !q)}/>*/}
      <TrimButton onClick={() => setQuoted(q => !q)}/>
      {quoted && <div ref={ref} dangerouslySetInnerHTML={{ __html: quotedHtml }}/>}
    </>
  );
});
export interface EmailQuotedProps {
  html: string;
  transformer: QuotedHTML;
}

export const TrimButton = React.memo<{ onClick: () => void }>(function (props) {
  const onMouseOver = (e) => {
    e.currentTarget.style.backgroundColor = "#DADCE2";
  };
  const onMouseOut = (e) => {
    e.currentTarget.style.backgroundColor = "#e8eaed";
  };
  const root = {
    cursor: "pointer",
    outline: "none",
    padding: "10px 0",
    width: "22px"
  };
  const wrap: React.CSSProperties = {
    clear: "both",
    lineHeight: "6px",
    outline: "none",
    position: "relative",
    backgroundColor: "#e8eaed",
    border: "none",
    WebkitBorderRadius: "5.5px",
    borderRadius: "5.5px",
    width: "24px",
    marginTop: "20px"
  };
  const img = {
    backgroundImage: `url(${MoreHorizPngUrl})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px",
    height: "11px",
    width: "24px"
  };
  return (
    <div style={root}>
      <div role="button" onClick={props.onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} style={wrap}
           tabIndex={0}
           title="Show trimmed content"
           aria-label="Show trimmed content"><img style={img}
                                                  src={ClearDotUrl}/>
      </div>
    </div>
  );
});
