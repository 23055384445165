import { useMemo }         from "react";
import { ISchemas }        from "../types/ISchemas";
import { isObject }        from "./helpers";
import { getField }        from "./schemaUtils";
import { isAliasField }    from "./schemaUtils";
import { isReservedField } from "./schemaUtils";
import { isRelationField } from "./schemaUtils";
import { isArrayField }    from "./schemaUtils";
import { isObjectField }   from "./schemaUtils";
import { isStatusField }   from "./schemaUtils";
import { isPointerField }  from "./schemaUtils";
import { omit }            from "@relcu/ui";

export function getSelectionSet(fieldNames: any = []) {
  const selectionSet = new Set(fieldNames.concat([
    "id",
    "objectId",
    "objectName",
    "objectIcon"
  ]));
  return Array.from(selectionSet);
}

export function transformFields(values, initialValues, className: string, schemas: ISchemas, removeAliases: boolean = false) {
  if (!isObject(values)) {
    return undefined;
  }
  const fields = {};
  const schema = schemas[ className ];
  Object.keys(values).forEach(name => {
    if (isReservedField(name)) {
      return;
    }
    const field = schema.fields[ name ];
    const value = values[ name ];
    const initial = initialValues?.[ name ];
    if (isAliasField(field)) {
      if(!removeAliases){
        fields[ name ] = value;
      }
      return;
    }
    if (isPointerField(field)) {
      fields[ name ] = transformers.pointer(value);
      return;
    }
    if (isStatusField(field)) {
      fields[ name ] = transformers.status(value);
      return;
    }
    if (isObjectField(field)) {
      fields[ name ] = transformers.object(value, initial, field.targetClass, schemas);
      return;
    }
    if (isArrayField(field)) {
      fields[ name ] = transformers.array(value, initial, field.targetClass, schemas);
      return;
    }
    if (isRelationField(field)) {
      fields[ name ] = transformers.relation(value, initial);
      return;
    }
    fields[ name ] = value;
  });
  return fields;
}


export function getScopeWhere (className, id) {
  const scopeField = getField(className, "scope");
  if (Array.isArray(scopeField.targetClass)) {
    return {
      have: {
        link: id
      }
    }
  }

  return {
    have: {
      id: {
        equalTo: id
      }
    }
  }
};

const transformers = {
  pointer: (value) => {
    if (value?.id) {
      return { link: value.id };
    }
    if (value === null) {
      return { link: null };
    }
  },
  status(value) {
    if (value) {
      return omit(value, ["__typename"]);
    }
    return value;
  },
  object(value, initial, className: string, schemas: ISchemas) {
    return transformFields(value, initial, className, schemas);
  },
  array(values, initial, className: string | string[], schemas: ISchemas) {
    if (className == "String" || className == "Number") {
      return values;
    }
    let results = [];
    if (!Array.isArray(className) && values) {
      for (let [index, value] of Object.entries(values)) {
        const initialValue = initial?.[ index ];
        let result = transformFields(value, initialValue, className, schemas);
        results.push(result);
      }
    }
    return results;
  },
  relation(value = [], initial = []) {
    const add = [];
    const remove = [];
    const existing = initial.map(({ id }) => id);
    const incoming = value.map(({ id }) => id);
    const links = new Set([...existing, ...incoming]);
    links.forEach(link => {
      if (existing.includes(link) && !incoming.includes(link)) {
        remove.push(link);
      } else if (!existing.includes(link) && incoming.includes(link)) {
        add.push(link);
      }
    });
    return {
      add,
      remove
    };
  }
};
