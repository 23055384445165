import React                 from "react";
import { useMemo }           from "react";
import { FC }                from "react";
import { Box }               from "@relcu/ui";
import { StepSection }       from "@relcu/ui";
import { TypographyVariant } from "@relcu/ui";
import { TypographySize }    from "@relcu/ui";
import { TypographyWeight }  from "@relcu/ui";
import { Typography }        from "@relcu/ui";
import { ProviderChanger }   from "./ProviderChanger";
import { ProviderSwitcher }  from "./ProviderSwitcher";

export interface EmailProviderSectionProps {
}

export const EmailProviderSection: FC<EmailProviderSectionProps> = React.memo(function EmailProviderSection(props) {
  return <StepSection>
    <Box gap={"XS"} container direction="column">
      <Box>
        <Typography variant={TypographyVariant.h3} size={TypographySize.SubtitleSmall}
                    weight={TypographyWeight.Medium}>Email provider</Typography>
      </Box>
      <Box>
        <Typography>Choose one of the options bellow to configure email.</Typography>
      </Box>
      <Box container direction={"column"} gap={"M"}>
        <ProviderChanger/>
        <ProviderSwitcher/>
      </Box>
    </Box>
  </StepSection>;
});