import React                                                    from "react";
import { PhoneNumbersField as BasePhoneNumbersField }           from "@relcu/ui";
import { PhoneNumbersFieldProps as BasePhoneNumbersFieldProps } from "@relcu/ui";
import { IChoiceField }                                         from "../../../../types/ISchemas";
import { getSchema }                                            from "../../../../utils/schemaUtils";
import { useSchemaField }                                       from "../../useSchemaField";

export type PhoneNumbersFieldProps = Omit<BasePhoneNumbersFieldProps, "options">
export const PhoneNumbersField = React.memo<PhoneNumbersFieldProps>(function PhoneNumbersField(props) {
  const schema = getSchema("PhoneNumber");
  return <BasePhoneNumbersField {...props} schema={schema}/>;
});
