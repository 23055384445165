import React                           from "react";
import { FC }                          from "react";
import { JsonViewProps}                from "@relcu/ui";
import { ObjectView as BaseObjectView} from "@relcu/ui";
import { useObjectView }               from "./useObjectView";

export const ObjectView: FC<JsonViewProps> = React.memo(function ObjectView(props) {
  const api = useObjectView(props)
  return <BaseObjectView {...api}/>
});
