import { gql }                                 from "@apollo/client";
import { useLazyQuery }                        from "@apollo/client";
import { useField }                            from "@relcu/final-form";
import { SelectPickerProps }                   from "@relcu/rc";
import { SelectPicker }                        from "@relcu/rc";
import { AutoComplete }                        from "@relcu/rc";
import { AutoCompleteProps }                   from "@relcu/rc";
import { useCallback }                         from "react";
import { useEffect }                           from "react";
import { useMemo }                             from "react";
import { useRef }                              from "react";
import React                                   from "react";
import { getField }                            from "../../../../../utils/schemaUtils";
import { Form }                                from "@relcu/rc";
import { GetProposalCountiesByState }          from "./__types__/GetProposalCountiesByState";
import { GetProposalCountiesByStateVariables } from "./__types__/GetProposalCountiesByState";

export const GET_PROPOSAL_COUNTIES_BY_STATE = gql`
  query GetProposalCountiesByState($state:String!,$type:String) {
    countiesByState(state:$state,type:$type)
  }
`;

export const ProposalAddress = React.memo(function ProposalAddress() {
  const [loadCounties, { data: { countiesByState = [] } = {}, called, loading: loadingCountiesByState }] = useLazyQuery<GetProposalCountiesByState, GetProposalCountiesByStateVariables>(GET_PROPOSAL_COUNTIES_BY_STATE);
  const { options: stateOptions } = getField("Address", "state");
  const { input: { value: state } } = useField("propertyState", { subscription: { value: true } });
  const { input: { value: zipCode, onChange: onChangeZipCode } } = useField("propertyZipCode", { subscription: { value: true } });
  const { input: { value: county, onChange: onChangeCounty } } = useField("propertyCounty", { subscription: { value: true } });
  const { input: { value: fipsCode, onChange: onChangeFipsCode } } = useField("propertyFipsCode", { subscription: { value: true } });
  const { input: { value: city, onChange: onChangeCity } } = useField("propertyCity", { subscription: { value: true } });
  const countyRef = useRef<string>();
  const zipCodeRef = useRef<string>();
  const cityRef = useRef<string>();
  const fipsCodeRef = useRef<string>();
  countyRef.current = county;
  zipCodeRef.current = zipCode;
  cityRef.current = city;
  fipsCodeRef.current = fipsCode;
  const counties = useMemo(() => countiesByState.map(c => c.county), [countiesByState]);
  useEffect(() => {
    // loadInfo({ variables: { state } });
    loadCounties({ variables: { state } });
  }, [state]);
  const zipCodes: string[] = useMemo(() => {
    const zipCodes = [];
    countiesByState.forEach((c) => {
      zipCodes.push(...c.regions.map(region => region.zipCode));
    });
    return Array.from(new Set<string>(zipCodes));
  }, [countiesByState]);

  const zipCodesLimited = useMemo(() => {
    let filtered = [];
    if (zipCode) {
      filtered = zipCodes.filter(item => item.startsWith(zipCode));
    } else {
      filtered = zipCodes;
    }
    return filtered.slice(0, 15);
  }, [zipCode, zipCodes]);

  const validateZipCode = useCallback((value, allValues) => {
    if (!value) {
      return "Zip code is required.";
    }
    if (zipCodes.length > 0 && !zipCodes.includes(value)) {
      return `The ZIP Code does not exist in ${allValues.propertyState}`;
    }
  }, [zipCodes]);

  //hook to change zip code on county change if needed
  useEffect(() => {
    const selectedCounty = countiesByState.find(e => e.county === county);
    const zipsInCounty = selectedCounty?.regions.map(region => region.zipCode);
    if (county && zipsInCounty) {
      if (!zipCodeRef.current) {
        onChangeZipCode(zipsInCounty[ 0 ]);
      } else if (!zipsInCounty.includes(zipCodeRef.current)) {
        onChangeZipCode(zipsInCounty[ 0 ]);
      }
    }
    if (selectedCounty) {
      onChangeFipsCode(selectedCounty.fipsCode);
    }
  }, [countiesByState, county]);

  //hook to make default County selection (will select zip as well)
  useEffect(() => {
    if (zipCodes.length == 0 || counties.length == 0) {
      return;
    }
    let existingZip = zipCodes.includes(zipCodeRef.current);
    let existingCounty = countyRef.current.trim() ? counties.some((s) => s.includes(countyRef.current.trim())) : false;
    let existingExactCounty = counties.includes(countyRef.current);
    if (!existingCounty || !existingZip || !existingExactCounty) {
      if (existingCounty && !existingExactCounty) {
        existingZip = false;
      }
      if (existingZip) {
        let nextCity;
        let nextCounty;
        let nextFipsCode;
        for (let { regions, county, fipsCode } of countiesByState) {
          let r = regions.find(region => region.zipCode == zipCodeRef.current);
          if (r) {
            nextCity = r.primaryCity;
            nextCounty = county;
            nextFipsCode = fipsCode;
            break;
          }
        }
        if (nextCounty && nextCounty != countyRef.current) {
          onChangeCounty(nextCounty);
        }
        if (nextFipsCode && nextFipsCode != fipsCodeRef.current) {
          onChangeFipsCode(nextFipsCode);
        }
        if (nextCity && nextCity != cityRef.current) {
          onChangeCity(nextCity);
        }
        return;
      }
      if (existingCounty) {
        let nextCity;
        let nextCounty;
        let nextZipCode;
        let nextFipsCode;
        for (let { regions, county, fipsCode } of countiesByState) {
          if (!county.includes(countyRef.current)) {
            continue;
          }
          let r = regions.find(region => (region.primaryCity == cityRef.current));
          if (county && !r) {
            r = regions[ 0 ];
          }

          nextCity = r.primaryCity;
          nextZipCode = r.zipCode;
          nextCounty = county;
          nextFipsCode = fipsCode;
          break;
        }

        if (nextCounty && nextCounty != countyRef.current) {
          onChangeCounty(nextCounty);
        }
        if (nextFipsCode && nextFipsCode != fipsCodeRef.current) {
          onChangeFipsCode(nextFipsCode);
        }
        if (nextZipCode && nextZipCode != zipCodeRef.current) {
          onChangeZipCode(nextZipCode);
        }
        if (nextCity && nextCity != cityRef.current) {
          onChangeCity(nextCity);
        }
        return;
      }
      onChangeZipCode(zipCodes[ 0 ]);
    }
  }, [zipCodes, counties]);

  //hook to change county/city on zip code change if needed
  useEffect(() => {
    let nextCity;
    let nextCounty;
    let nextFipsCode;
    if (!zipCodes) {
      return;
    }
    let existingCounty = countiesByState.find((c) => (c.county == countyRef.current));
    let existingRegion = existingCounty?.regions.find((r) => (r.zipCode == zipCode));

    if (existingRegion) {
      nextCity = existingRegion.primaryCity;
    } else {
      for (let { regions, county, fipsCode } of countiesByState) {
        let r = regions.find(region => region.zipCode == zipCode);
        if (r) {
          nextCity = r.primaryCity;
          nextCounty = county;
          nextFipsCode = fipsCode;
          break;
        }
      }
    }
    if (nextCounty && nextCounty != countyRef.current) {
      onChangeCounty(nextCounty);
    }
    if (nextFipsCode && nextFipsCode != fipsCodeRef.current) {
      onChangeFipsCode(nextFipsCode);
    }
    if (nextCity && nextCity != cityRef.current) {
      onChangeCity(nextCity);
    }
  }, [zipCode]);

  return (
    <>
      <Form.Field<SelectPickerProps>
        name="propertyState"
        label="State"
        required
        component={SelectPicker}
        properties={{ size: "lg", data: stateOptions, style: { width: "100%" } }}
      />
      <Form.Field<AutoCompleteProps>
        name="propertyZipCode"
        label="Zip code"
        required
        component={AutoComplete}
        validate={validateZipCode}
        properties={{
          size: "lg",
          placeholder: "Enter zip code",
          autoComplete: "off",
          data: zipCodesLimited
        }}
      />
      <Form.Field<AutoCompleteProps>
        name="propertyCounty"
        label="County"
        required
        component={AutoComplete}
        properties={{ size: "lg", data: counties }}
      />
    </>
  );
});
