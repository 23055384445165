import React                from "react";
import { Box }              from "../../Box";
import { Label }            from "../../Label";
import { BaseFieldClasses } from "../BaseField";
import { useConsumeField }  from "../BaseField";

export const NumberReadField = React.memo(function NumberReadField() {
  const { input: { label, readPlaceholder, required, value, options, icon, read, write, ...numberProps } } = useConsumeField();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...numberProps}>
      <Label>{label}</Label>
      {
        icon ?
          <Box container gap={"XXS"}>
            {icon}
            {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
          </Box> :
          value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
      }
    </Box>
  );
});

export default NumberReadField;
