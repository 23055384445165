import React                    from "react";
import { FC }                   from "react";
import { useState }             from "react";
import { VoiceTemplate }        from "../../../../Advanced/Voice/VocieTemplate";
import { VoiceTemplateClasses } from "../../../../Advanced/Voice/VocieTemplate/VoiceTemplateClasses";
import { VoiceAction }          from "../../../../Advanced/Voice/VoiceAction";
import { Box }                  from "../../../../Box";
import { ButtonColors }         from "../../../../Button";
import { Button }               from "../../../../Button";
import { EmptyList }            from "../../../../EmptyList";
import { ListRowColors }        from "../../../../List";
import { ListRow }              from "../../../../List";
import { JsonSectionProps }     from "../../JsonSection";

export const VoiceTemplateSection: FC<VoiceTemplateSectionProps> = React.memo(function VoiceTemplateSection(props) {
  const { options } = props;
  return <Box container direction={"column"} gap={"XS"} flexGrow={1}>
    {
      options.voiceTemplates.length == 0 && !options.active
        ?
        <Box container direction="column" gap={"S"}>
          <EmptyList icon={"rc_voicemail_call"}
                     content={`Record your voicemail template by clicking on "Start recording" below`}
                     title={`You do not have voicemail templates`}
                     flexGrow={1}/>
          <Button
            alignSelf="center"
            disabled={options.busy}
            onClick={options.toggleRecording}
            icon={!!options.active ? "stop" : "record_voice_over"}
            color={ButtonColors.Primary}
          >
            START RECORDING
          </Button>
        </Box>
        :
        <>
          <VoiceAction busy={options.busy} active={options.active} onClick={options.toggleRecording}/>
          <VoiceTemplates onSave={options.updateRecording} onDelete={options.deleteRecording}
                          voiceTemplates={options.voiceTemplates}/>
        </>
    }
  </Box>;
});

export interface VoiceTemplateSectionProps extends JsonSectionProps {
  options: {
    busy: boolean
    active: boolean
    editId: string
    defaultVoicemail: { id? }
    toggleRecording: (e) => any
    updateRecording: (e) => any
    onChangeDefault: (e) => any
    onCancel: () => void
    setEditId: (e) => any
    deleteRecording: (e) => any
    handleSave: (e) => any
    voiceTemplates: Recording[]
  };
}

interface Recording {
  id,
  name,
  url,
  uploaded,
  uploadedStatus
}

interface VoiceTemplatesProps {
  voiceTemplates: Recording[],
  onSave: (r: Recording) => void,
  onDelete: (r: Recording) => void
}

export const VoiceTemplates: FC<VoiceTemplatesProps> = React.memo(function VoiceTemplates(props) {
  const { voiceTemplates, onSave, onDelete, ...p } = props;
  const [editId, setEditId] = useState<string>(null);
  const onCancel = () => {
    setEditId(null);
  };
  const handleSave = async (recording) => {
    await onSave(recording);
    setEditId(null);
  };

  return <Box container direction="column" gap={"XS"}>
    {voiceTemplates.map((voiceTemplate, i) => {
      return voiceTemplate.uploaded ? <VoiceTemplate
          key={voiceTemplate.id}
          onEdit={() => setEditId(voiceTemplate.id)}
          editing={editId == voiceTemplate.id}
          voiceTemplate={voiceTemplate}
          onCancel={onCancel}
          onDelete={onDelete}
          onSave={handleSave}
          {...p}
        /> :
        voiceTemplate?.uploadedStatus != 'failed' &&
        <ListRow
          key={voiceTemplate.id}
          gap={"XXXL"}
          color={ListRowColors.Grey}
          outlined={false}
          className={VoiceTemplateClasses.VoiceRow}
          style={{ marginLeft: 33 }}>
          <p
            style={{ color: "var(--color-gray-extra-dark)", fontSize: "var(--typography-body-font-size)" }}>{voiceTemplate.name} recording
            is being processed. </p>
        </ListRow>;
    })}
  </Box>;
});

export default VoiceTemplateSection;
