import { FC }                    from "react";
import React                     from "react";
import { classNames }            from "../..";
import { Box }                   from "../Box";
import { BoxItemComponentProps } from "../Box";
import { DividerClasses }        from "./DividerClasses";

export const VerticalDivider: FC<BoxItemComponentProps> = React.memo((props) => {
  const { className, ...p } = props;
  return <Box className={classNames(DividerClasses.VerticalDivider, className)} {...p}/>;
});
