import { Icon }                  from "@relcu/rc";
import { IconButton }            from "@relcu/rc";
import { useSource }             from "@relcu/ui";
import { ButtonVariants }        from "@relcu/ui";
import { Button }                from "@relcu/ui";
import { TypographyColor }       from "@relcu/ui";
import { TypographySize }        from "@relcu/ui";
import { TypographyWeight }      from "@relcu/ui";
import { TypographyLine }        from "@relcu/ui";
import { Typography }            from "@relcu/ui";
import { AudioPlayer }           from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { classNames }            from "@relcu/ui";
import { useCallback }           from "react";
import { FC }                    from "react";
import { useEffect }             from "react";
import { useState }              from "react";
import React                     from "react";
import { downloadFile }          from "../../../../utils/helpers";
import { ConferenceViewClasses } from "./ConferenceViewClasses";

interface Recording {
  recording: any,
}

export const Recording: FC<Recording> = React.memo(function Recording({ recording }) {
  // const [transcription, setTranscription] = useState(null);
  // const [currentTime, setCurrentTime] = useState(0);
  const { $settings, $viewer } = useSource();
  const downloadAccess = $settings.recordingDownloadPermission && $settings.recordingDownloadPermission.includes($viewer.role);
  // useEffect(() => {
  //   (async () => {
  //     if (recording.transcriptionUrl) {
  //       const res = await fetch(recording.transcriptionUrl);
  //       const { results } = await res.json();
  //       setTranscription(results);
  //     }
  //   })();
  // }, [recording]);

  // const formatTime = useCallback((time) => {
  //   let hrs = ~~(time / 3600);
  //   let mins = ~~((time % 3600) / 60);
  //   let secs = ~~time % 60;
  //   let ret = "";
  //   ret += "" + (hrs < 10 ? "0" : "") + hrs;
  //   ret += ":" + (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "");
  //   ret += "" + secs;
  //   return ret;
  // }, []);

  return <Box
    container
    direction={"column"}
    gap={"XS"}
    style={{ overflowY: "hidden" }}
    flex={1}
    className={classNames(ConferenceViewClasses.ConferenceSection)}>
    <Box container direction={"column"}
         gap={"XXS"}
         className={ConferenceViewClasses.ConferenceSectionHeader}>
      Recording
      <Box container gap={"XS"} alignItems={"center"}>
        <AudioPlayer src={recording.url}/>
        {downloadAccess && <IconButton
          size={"xs"}
          appearance={"text"}
          icon={<Icon type={"file_download"}/>}
          onClick={() => downloadFile({ name: recording.name, type: "audio/mpeg", url: recording.url })}
        />}
      </Box>
    </Box>

    {/*{*/}
    {/*  recording.transcriptionStatus != null && <>*/}
    {/*    <Box container gap={"XS"} alignItems={"center"}>*/}
    {/*      <Typography lineHeight={TypographyLine.Line13} weight={TypographyWeight.Medium}*/}
    {/*                  size={TypographySize.TextSmall}*/}
    {/*                  color={recording.transcriptionStatus == "failed" ? TypographyColor.Warning : TypographyColor.Primary}>*/}
    {/*        Transcription {recording.transcriptionStatus == "in-progress" ? "in progress" : (recording.transcriptionStatus == "failed" && "failed")}*/}
    {/*      </Typography>*/}

    {/*      {*/}
    {/*        (recording.transcriptionStatus == "in-progress") &&*/}
    {/*        <Button*/}
    {/*          onlyIcon*/}
    {/*          icon={"refresh"}*/}
    {/*          variant={ButtonVariants.Ghost}*/}
    {/*          //onClick={() => load()}*/}
    {/*        />*/}
    {/*      }*/}

    {/*    </Box>*/}
    {/*    <Box style={{ overflowY: "scroll" }} container direction={"column"} gap={"XS"}>*/}
    {/*      {transcription?.map(({ speaker, transcript, time }, i) => {*/}
    {/*        return <Box container gap={"XXS"} key={i}>*/}
    {/*          <Box style={{ width: "8px" }}>*/}
    {/*            {currentTime >= time.from && currentTime <= time.to &&*/}
    {/*            <div className={ConferenceViewClasses.TranscriptionIndicator}/>}*/}
    {/*          </Box>*/}
    {/*          <Box container gap={"M"}>*/}
    {/*            <Box>*/}
    {/*              <Typography lineHeight={TypographyLine.Line17}>Speaker {speaker + 1}</Typography>*/}
    {/*              <Typography style={{ lineHeight: "14px" }} size={TypographySize.TextTiny}*/}
    {/*                          color={TypographyColor.Secondary}>{formatTime(time.to)}</Typography>*/}
    {/*            </Box>*/}
    {/*            <Typography lineHeight={TypographyLine.Line17}>{transcript}</Typography>*/}
    {/*          </Box>*/}
    {/*        </Box>;*/}
    {/*      })}*/}
    {/*    </Box>*/}
    {/*  </>*/}
    {/*}*/}
  </Box>;
});
