import { ListRowColors }                             from "@relcu/ui";
import { ListRow }                                   from "@relcu/ui";
import React                                         from "react";
import { FC }                                        from "react";
import { useState }                                  from "react";
import { ButtonColors }                              from "@relcu/ui";
import { JsonSectionProps }                          from "@relcu/ui";
import { Button }                                    from "@relcu/ui";
import { Box }                                       from "@relcu/ui";
import { EmptyList }                                 from "@relcu/ui";
import { VoiceTemplate }                             from "@relcu/ui";
import { VoiceAction }                               from "@relcu/ui";
import { Section }                                   from "@relcu/ui";
import { VoiceMailRecordings_recordings_edges_node } from "../__types__/VoiceMailRecordings";
import { useVoiceTemplateSection }                   from "./useVoiceTemplateSection";

type Recording = VoiceMailRecordings_recordings_edges_node

export const VoiceTemplateSection: FC<JsonSectionProps> = React.memo(function VoiceTemplateSection(props) {
  const { title, view, editable } = props;
  const api = useVoiceTemplateSection();
  return <Section view={view} title={title} editable={editable} container direction={"column"} gap={"XS"} flexGrow={1}>
    <Box container direction={"column"} gap={"XS"} flexGrow={1}>
      {
        api.voiceTemplates.length == 0 && !api.active
          ?
          <Box container direction="column" gap={"S"}>
            <EmptyList icon={"rc_voicemail_call"}
                       content={`Record your voicemail template by clicking on "Start recording" below`}
                       title={`You do not have voicemail templates`}
                       flexGrow={1}/>
            <Button
              alignSelf="center"
              disabled={api.busy}
              onClick={api.toggleRecording}
              icon={!!api.active ? "stop" : "record_voice_over"}
              color={ButtonColors.Primary}
            >
              START RECORDING
            </Button>
          </Box>
          :
          <>
            <VoiceAction busy={api.busy} active={api.active} onClick={api.toggleRecording}/>
            <VoiceTemplates onSave={api.updateRecording} onDelete={api.deleteRecording}
                            voiceTemplates={api.voiceTemplates}/>
          </>
      }
    </Box>
  </Section>;
});

interface VoiceTemplatesProps {
  voiceTemplates: Recording[],
  onSave: (r: Recording) => void,
  onDelete: (r: Recording) => void
}

export const VoiceTemplates: FC<VoiceTemplatesProps> = React.memo(function VoiceTemplates(props) {
  const { voiceTemplates, onSave, onDelete, ...p } = props;
  const [editId, setEditId] = useState<string>(null);
  const onCancel = () => {
    setEditId(null);
  };
  const handleSave = async (recording) => {
    await onSave(recording);
    setEditId(null);
  };

  return <Box container direction="column" gap={"XS"}>
    {voiceTemplates.map((voiceTemplate, i) => (
      voiceTemplate.uploaded ? <VoiceTemplate
        key={voiceTemplate.id}
        onEdit={() => setEditId(voiceTemplate.id)}
        editing={editId == voiceTemplate.id}
        voiceTemplate={voiceTemplate}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={handleSave}
        {...p}
      /> : <ListRow
        gap={"XXXL"}
        key={voiceTemplate.id}
        color={ListRowColors.Grey}
        outlined={false}>
        <p
          style={{ color: "var(--color-gray-extra-dark)", fontSize: "var(--typography-body-font-size)" }}>{voiceTemplate.name} recording
          is being processed. </p>
      </ListRow>
    ))}
  </Box>;
});

export default VoiceTemplateSection;
