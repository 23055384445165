import { ReactNode }        from "react";
import React                from "react";
import { FC }               from "react";
import { CommonClasses }    from "../../theme/classes";
import { Box }              from "../Box";
import { TypographyLine }   from "../Typography";
import { TypographyColor }  from "../Typography";
import { TypographySize }   from "../Typography";
import { TypographyWeight } from "../Typography";
import { Typography }       from "../Typography";

export interface NotificationSubjectProps {
  objectName?: string | ReactNode,
  prefix?: string,
  subject?: string | ReactNode
  inApp?: boolean
}
export const NotificationSubject: FC<NotificationSubjectProps> = React.memo(function (props) {
  const { objectName, subject, prefix, inApp } = props;
  return <Box container direction={"column"} gap={inApp ? "XXXS" : "XXS"}>
    {!!objectName && <Box container gap={inApp ? "XXS" : "XXXS"} style={{ overflowX: "inherit" }}>
      <Typography lineHeight={inApp && TypographyLine.LineBody1}
                  size={inApp ? TypographySize.Text : TypographySize.TextSmall}
                  color={inApp ? TypographyColor.Light : TypographyColor.Tertiary}
                  weight={TypographyWeight.Regular}>{prefix}</Typography>
      {
        typeof objectName === "string" ?
          <Typography size={inApp ? TypographySize.Text : TypographySize.TextSmall}
                      lineHeight={inApp && TypographyLine.LineBody1}
                      color={inApp ? TypographyColor.White : TypographyColor.ExtraDark}
                      className={CommonClasses.FlexEllipsis}>{objectName}</Typography> :
          objectName
      }
    </Box>}
    {
      subject &&
      (
        typeof subject === "string"
          ?
          <Typography size={inApp ? TypographySize.Text : TypographySize.TextSmall}
                      lineHeight={inApp && TypographyLine.LineBody1}
                      color={inApp ? TypographyColor.White : TypographyColor.ExtraDark}
                      className={CommonClasses.FlexEllipsis}
                      noWrap>{subject}</Typography>
          : subject
      )
    }
  </Box>;
});

NotificationSubject.defaultProps = {
  prefix: "From"
};