import { HTMLAttributes } from "react";
import React              from "react";
import { FC }             from "react";
import { classNames }     from "../../utils/classNames";
import { Box }            from "../Box";
import { Typography }     from "../Typography";

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  unread?: boolean;
  passed?: boolean;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

export const Card: FC<CardProps> = React.memo(function Card(props) {
  const { selected, passed, disabled, active, className, title, children, ...p } = props;
  const classes = classNames("card", {
    [ "card--selected" ]: selected,
    [ "card--success" ]: passed,
    [ "card--disabled" ]: disabled,
    [ "card--active" ]: active
  }, className);

  return (
    <Box container direction={"column"} justify={"space-between"} className={classes} {...p}>
      {
        title &&
        <Typography>{title}</Typography>
      }
      {children}
    </Box>
  );
});