import React                 from "react";
import { FC }         from "react";
import { classNames } from "../..";
import { Box }        from "../..";
import { BoxComponentProps } from "../..";
import { CircularLoader }    from "./CircularLoader";
import { LoaderClasses }     from "./LoaderClasses";

export const BackDropLoader: FC<BoxComponentProps> = React.memo(function BackDropLoader(props) {
  const { children, className, ...p } = props;
  const classes = classNames(LoaderClasses.BackDropFilter, className);
  return <Box container className={classes} justify={"center"} alignItems={"center"} {...p}>
    {
      children || <CircularLoader/>
    }
  </Box>;
});
