import React                     from "react";
import { Box }                   from "../../Box";
import { Label }                 from "../../Label";
import { BaseFieldClasses }      from "../BaseField";
import { useConsumeField }       from "../BaseField";
import { PhoneNumberFieldProps } from "./PhoneNumberField";

export const PhoneNumberReadField = React.memo(function PhoneNumberReadField(props) {
  const { input: { readPlaceholder, label, required, value, ...phoneNumberProps } } = useConsumeField<PhoneNumberFieldProps>();
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...phoneNumberProps}>
      <Label>{label}</Label>
      {value?.formatted || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
    </Box>
  );
});

export default PhoneNumberReadField;
