import React                            from "react";
import { FC }                           from "react";
import { useMemo }                      from "react";
import { Stack }                        from "@relcu/rc";
import { EmptyState }                   from "@relcu/rc";
import { gql }                          from "@apollo/client";
import { useMutation }                  from "@apollo/client";
import { useNavigate }                  from "@relcu/react-router";
import { useParams }                    from "@relcu/react-router";
import { Route }                        from "@relcu/react-router";
import { Routes }                       from "@relcu/react-router";
import { useSearchParams }              from "@relcu/react-router";
import { Navigate }                     from "@relcu/react-router";
import { SelectVariants }               from "@relcu/ui";
import { Select }                       from "@relcu/ui";
import { CircularLoader }               from "@relcu/ui";
import { classNames }                   from "@relcu/ui";
import { Box }                          from "@relcu/ui";
import { EmptyList }                    from "@relcu/ui";
import { JsonViewProps }                from "@relcu/ui";
import { useSource }                    from "@relcu/ui";
import { MEMBER_OWN_FIELDS_FRAGMENT }   from "../../../../graph/operations.graphql";
import { getScopeWhere }                from "../../../../utils/graphQlUtils";
import { getObjectPath }                from "../../../../utils/layoutUtils";
import { MarkCallsAsReadVariables }     from "../../../__types__/MarkCallsAsRead";
import { MarkCallsAsRead }              from "../../../__types__/MarkCallsAsRead";
import { usePermissions }               from "../../../AccessControl";
import { List }                         from "../../../List/List";
import { ListHeaderTitle }              from "../../../List/ListHeader";
import { ListHeader }                   from "../../../List/ListHeader";
import { ListCallItem }                 from "../../../List/ListItem/ListCallItem";
import { MARK_CALLS_AS_READ }           from "../../../operations.graphql";
import { RelayMention }                 from "../../../Relay";
import { RelayMentionProvider }         from "../../../Relay";
import { RelayQuery }                   from "../../../Relay";
import { GetConferencesByDynamicWhere } from "./__types__/GetConferencesByDynamicWhere";
import { Conference }                   from "./Conference";
import { ConferenceViewClasses }        from "./ConferenceViewClasses";
import "./conference-view.css";

const filters = [{ label: "All", value: "all" }, { label: "Voicemail", value: "voicemail" }, { label: "Recording", value: "recording" }];
export const ConferenceView: FC<JsonViewProps> = React.memo((props) => {
  const { $object, $viewer } = useSource();
  const params = useParams();
  const navigate = useNavigate();
  const objectId = params[ "*" ];
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get("filter") || "all";
  const { canUpdate } = usePermissions($object);
  const scope = useMemo(() => {
    return getScopeWhere("Conference", $object.id);
  }, [$object.__typename, $object.id]);

  const queryWhereVariables = useMemo(() => {
    const id = $object.id;
    const key = $object.__typename;
    const viewerId = $viewer.id;
    let whereMap = {
      [ $object.__typename ]: {
        scope: scope
      }
    };
    whereMap[ "User" ] = {
      calls: {
        have: {
          party: {
            have: {
              link: id
            }
          }
        }
      }
    };
    if ($viewer.role != "admin") {
      if($object.__typename !== "Lead" && $object.__typename !== "User"){
        whereMap[`${$object.__typename}`] = ({
          AND: [
            {
              scope: scope
            },
            {
              calls: {
                have: {
                  party: {
                    have: {
                      link: viewerId
                    }
                  }
                }
              }
            }
          ]
        } as any);
      }
      whereMap[ "Contact" ] = {
        AND: [
          {
            calls: {
              have: {
                party: {
                  have: {
                    link: id
                  }
                }
              }
            }
          },
          {
            calls: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                }
              }
            }
          }
        ]
      };
    } else {
      whereMap[ "Contact" ] = {
        calls: {
          have: {
            party: {
              have: {
                link: id
              }
            }
          }
        }
      };
    }
    let query = whereMap[ key ];
    if (filter == "voicemail") {
      if (query[ "AND" ]) {
        query[ "AND" ] = [...query[ "AND" ], { voicemail: { exists: true } }, { direction: { equalTo: "inbound" } }];
      } else {
        query[ "AND" ] = [{ voicemail: { exists: true } }, { direction: { equalTo: "inbound" } }];
      }
    }
    if (filter == "recording") {
      if (query[ "AND" ]) {
        query[ "AND" ] = [...query[ "AND" ], { recording: { exists: true } }];
      } else {
        query[ "AND" ] = [{ recording: { exists: true } }];
      }
    }
    return query;
  }, [$object.__typename, $object.id, filter, scope]);
  const subscriptionWhereVariables = useMemo(() => {
    const id = $object.id;
    const key = $object.__typename;
    const viewerId = $viewer.id;

    let whereMap = {
      [ $object.__typename ]: {
        scope: {
          have: {
            link: id
          }
        }
      }
    };
    whereMap[ "User" ] = {
      calls: {
        have: {
          party: {
            have: {
              link: id
            }
          }
        }
      }
    };
    if ($viewer.role != "admin") {
      if($object.__typename !== "Lead" && $object.__typename !== "User"){
        whereMap[`${$object.__typename}`] = ({
          AND: [
            {
              scope: {
                have: {
                  link: id
                }
              }
            },
            {
              calls: {
                have: {
                  party: {
                    have: {
                      link: viewerId
                    }
                  }
                }
              }
            }
          ]
        } as any);
      }
      whereMap[ "Contact" ] = {
        AND: [
          {
            calls: {
              have: {
                party: {
                  have: {
                    link: id
                  }
                }
              }
            }
          },
          {
            calls: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                }
              }
            }
          }
        ]
      };
    } else {
      whereMap[ "Contact" ] = {
        calls: {
          have: {
            party: {
              have: {
                link: id
              }
            }
          }
        }
      };
    }
    let query = whereMap[ key ];
    if (filter == "voicemail") {
      if (query[ "AND" ]) {
        query[ "AND" ] = [...query[ "AND" ], { voicemail: { exists: true } }, { direction: { equalTo: "inbound" } }];
      } else {
        query[ "AND" ] = [{ voicemail: { exists: true } }, { direction: { equalTo: "inbound" } }];
      }
    }
    if (filter == "recording") {
      if (query[ "AND" ]) {
        query[ "AND" ] = [...query[ "AND" ], { recording: { exists: true } }];
      } else {
        query[ "AND" ] = [{ recording: { exists: true } }];
      }
    }
    return query;
  }, [$object.__typename, $object.id, filter]);
  const [markAsRead] = useMutation<MarkCallsAsRead, MarkCallsAsReadVariables>(MARK_CALLS_AS_READ);
  const onSelect = node => {
    navigate(`${node.objectId}${filter !== "all" ? `?filter=${filter}` : ""}`);
  };
  const onMarkRead = (id) => {
    markAsRead({ variables: { ids: [id] } }).catch(console.error);
  };
  const mentionQuery = {
    calls: {
      have: {
        party: {
          have: {
            link: $viewer.id
          }
        }
      }
    },
    missed: { equalTo: true },
    unread: { equalTo: true }
  };
  return (
    <RelayQuery<GetConferencesByDynamicWhere>
      className={"Conference"}
      rowHeight={71}
      from={objectId}
      key={filter}
      query={{
        document: GET_CONFERENCES_BY_DYNAMIC_WHERE,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: { where: queryWhereVariables }
      }}
      subscription={{
        document: SUBSCRIBE_CONFERENCE_BY_DYNAMIC_WHERE,
        variables: { where: subscriptionWhereVariables }
      }}
      render={renderProps => {
        const {
          scrollContainerRef,
          beforeLoaderRef,
          afterLoaderRef,
          loading,
          data: { conferences: { edges = [], pageInfo } } = {},
          register
        } = renderProps;
        const selected = edges.find(e => e.node.objectId === objectId)?.node;
        return (
          <Box container
               gap={"XS"}
               flex={1}
               justify={(loading || edges.length == 0) ? "center" : null}
               className={classNames(ConferenceViewClasses.ConferenceView, {
                 [ "list-view--empty" ]: loading || edges.length == 0
               })}>
            <Box container flex={1} gap={"XXXS"} style={{ overflow: "hidden" }}>
              <List
                flex={`1 0 ${$object.__typename === "User" ? "32%" : "40%"}`}
                style={{ position: "relative" }}
                header={
                  <ListHeader alignItems={"start"} container gap={"XXS"} direction={"column"}>
                    <ListHeaderTitle value={"Calls"}/>
                    <Box container gap={"XXS"} alignItems={"center"}>
                      <p className={ConferenceViewClasses.FilterText}>Filter:</p>
                      <Select
                        mode={"button"}
                        variant={SelectVariants.Ghost}
                        placeholder="Choose type"
                        alignSelf={"start"}
                        value={filter}
                        onChange={(select) => setSearchParams({ filter: select.value })}
                        options={filters}
                        flexGrow={0}
                      />
                    </Box>
                  </ListHeader>
                }>
                <Box container
                     flex={1}
                     justify={edges.length == 0 ? "center" : "start"}
                     direction={"column"}
                     style={{ overflowY: "auto", padding: "8px 0" }}
                     ref={scrollContainerRef}
                     gap={"XXS"}>
                  {
                    pageInfo?.hasPreviousPage &&
                    <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>
                  }
                  {
                    !!edges.length ? edges.map(({ node, cursor }) =>
                      <ListCallItem
                        key={node.objectId}
                        onClick={() => onSelect(node)}
                        selected={objectId == node.objectId}
                        call={node}
                        ref={e => register(e, node.objectId)}
                        onMarkRead={onMarkRead}/>
                    ) : (loading ?
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          style={{ flex: 1 }}>
                          <CircularLoader/>
                        </Stack> : (
                          <EmptyList icon={"call"}
                                     title={"No calls available"}
                                     className={ConferenceViewClasses.ConferenceEmptyList}
                                     content={"All calls will appear here."}
                                     alignSelf={"center"}/>
                        )
                    )
                  }
                  <RelayMentionProvider<GetConferencesByDynamicWhere>
                    variables={{
                      order: ["createdAt_DESC"],
                      where: {
                        ...queryWhereVariables[ "AND" ] ? {
                          AND: [
                            ...queryWhereVariables[ "AND" ],
                            mentionQuery
                          ]
                        } : {
                          AND: [
                            mentionQuery
                          ]
                        }
                      }
                    }}
                    predicate={({ conferences: { edges = [] } }) => {
                      return edges.filter(e => e.node.missed && e.node.unread && e.node.calls.findIndex((call) => (call?.party?.objectId == $viewer.objectId)) > -1).map(e => e.node.objectId);
                    }}>
                    <RelayMention direction={"top"} style={{ top: 100, width: "200px" }}>New call</RelayMention>
                    <RelayMention direction={"bottom"}>New call</RelayMention>
                  </RelayMentionProvider>
                  {
                    pageInfo?.hasNextPage &&
                    <CircularLoader alignSelf={"center"} ref={afterLoaderRef}/>
                  }
                </Box>
              </List>
              <Routes>
                <Route path={"/"} element={
                  loading ?
                    <Box container flex={1} justify={"center"} alignItems={"center"}
                         style={{ backgroundColor: "white" }}>
                      <CircularLoader/>
                    </Box> :
                    (
                      edges.length ?
                        <Navigate to={`${edges[ 0 ].node.objectId}${filter !== "all" ? `?filter=${filter}` : ""}`}/> :
                        <EmptyState subtitle={`No calls available`}/>
                    )
                }/>
                <Route path={"/:from"} element={!!selected &&
                  <Conference
                    key={objectId}
                    disabled={!canUpdate}
                    toPath={party => getObjectPath(party)}
                    node={$object}
                    selected={selected}
                    flex={`1 0 ${$object.__typename === "User" ? "68%" : "60%"}`}
                  />
                }/>
              </Routes>
            </Box>
          </Box>
        );
      }}
    />);
});

export const CONFERENCE_FRAGMENT = gql`
  ${MEMBER_OWN_FIELDS_FRAGMENT}
  fragment ConferenceRecording on Recording {
    id
    name
    url
    type
    uploaded
    uploadStatus
    transcriptionUrl
    transcriptionStatus
  }
  fragment Conference on Conference {
    id
    objectId
    objectName
    createdAt
    updatedAt
    tags
    recordingUrl
    from
    to
    status
    missed
    unread
    direction
    startDate
    unread
    endDate
    conferenceSid
    recording{
      ...ConferenceRecording
    }
    voicemail{
      ...ConferenceRecording
    }
    prompt{
      ...ConferenceRecording
    }
    scope {
      ...on Document {
        objectName
        objectId
        objectIcon
      }
      ...on Node {
        id
      }
      ...on Lead {
        loanAmount
        loanProgram
        loanPurpose
        team
        assignedUserTeam
        leadSource {
          leadPartner {
            title
          }
        }
        leadStatus{
          action
          status
        }
        members{
          ...MemberOwnFields
          contact{
            id
            firstName
            lastName
            middleName
            types
          }
        }
      }
    }
    calls{
      status
      type
      party {
        __typename
        ... on User {
          id
          objectId
          objectName
          objectIcon
          firstName
          lastName
        }
        ... on Contact {
          id
          objectId
          objectName
          objectIcon
          firstName
          lastName
        }
      }
    }
    createdAt
  }
`;
export const SUBSCRIBE_CONFERENCE_BY_DYNAMIC_WHERE = gql`
  subscription SubscribeConferencesByDynamicWhere($where: ConferenceSubscriptionWhereInput) {
    conferences(where: $where) {
      event
      node {
        ...Conference
      }
    }
  }
  ${CONFERENCE_FRAGMENT}
`;

// export const SUBSCRIBE_CONFERENCE_RECORDING = gql`
//   subscription SubscribeConferenceRecording($where: RecordingSubscriptionWhereInput) {
//     recordings(where: $where) {
//       event
//       node {
//         id
//         name
//         url
//         type
//         uploaded
//         uploadStatus
//         transcriptionUrl
//         transcriptionStatus
//       }
//     }
//   }
// `;
export const GET_CONFERENCES_BY_DYNAMIC_WHERE = gql`
  query GetConferencesByDynamicWhere($where: ConferenceWhereInput!,$before:String,$after:String, $first: Int,$last:Int,$from:String) {
    conferences(
      first: $first,
      last: $last,
      before: $before,
      after: $after,
      where: $where,
      from: $from,
      order: createdAt_DESC
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...Conference
        }
      }
    }
  }
  ${CONFERENCE_FRAGMENT}
`;

export default ConferenceView;
