import React                 from "react";
import { FC }                from "react";
import { useContext }        from "react";
import { loanAmountDetails } from "../../../../../../utils/helpers";
import { format }            from "../../../../../../utils/helpers";
import { RateContext }       from "./RateProvider";

export const TotalLoanAmount: FC<any> = React.memo(function TotalLoanAmount(props) {
  const { children, prefix = "$" } = props;
  const {
    lead,
    settings
  } = useContext(RateContext);
  const { total,amount,type } = loanAmountDetails(lead, settings);
  return children instanceof Function
    ?
    <>{children(total,amount, type)}</>
    :
    <>{prefix || ""}{format(total, 2)}</>;
});
