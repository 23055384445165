import React                            from "react";
import { FC }                           from "react";
import { FormField }                    from "@relcu/rc";
import { useField }                     from "@relcu/form";
import { FormSpy }                      from "@relcu/form";
import { Field }                        from "@relcu/form";
import { SectionMessage }               from "@relcu/rc";
import { EmptyState }                   from "@relcu/rc";
import { Typography }                   from "@relcu/rc";
import { Form }                         from "@relcu/rc";
import { useSource }                    from "@relcu/ui";
import { BoxComponentProps }            from "@relcu/ui";
import { Box }                          from "@relcu/ui";
import { Button }                       from "@relcu/rc";
import { Modal }                        from "@relcu/rc";
import { ModalProps }                   from "@relcu/rc";
import { Sidebar }                      from "@relcu/rc";
import { Container }                    from "@relcu/rc";
import { Content }                      from "@relcu/rc";
import { Header }                       from "@relcu/rc";
import { BulkModalBody }                from "../../Bulk/BulkModalBody";
import { BulkRecipientList }            from "../../Bulk/BulkRecipientList";
import { PhoneMessageRenderer }         from "../../ContentVisualizer/PhoneMessageRenderer";
import { PointerPicker }                from "../../Layout/Field/PointerPicker";
import { PointerPickerProps }           from "../../Layout/Field/PointerPicker";
import { useBulkSendPhoneMessageModal } from "./useBulkSendPhoneMessageModal";
import "./send-bulk-message-modal.css";

export interface BulkSendPhoneMessageModalProps extends Partial<ModalProps> {
  variables: any;
  className?: string;
  find?: any;
  ids: string[];
  onSubmit(data);
  destroyHandler: () => void;
}

export const BulkSendPhoneMessageModal: FC<BulkSendPhoneMessageModalProps> = React.memo(function BulkSendPhoneMessageModal(props) {
  const { variables, ids, onSubmit, find, ...modalProps } = props;
  const {
    onBulkSubmit,
    query,
    from,
    numbers,
    handleSelect,
    selected,
    flyerFilters,
    getTemplate,
    templateFilters,
    primaryBorrower
  } = useBulkSendPhoneMessageModal({ ...props });

  const { $viewer } = useSource();

  return (
    <Modal {...modalProps} size={"xl"}>
      <Form
        onSubmit={onBulkSubmit}
        initialValues={{ from }}
        layout={"inline"}
      >
        <Modal.Header>
          <Typography weights={"medium"} variant={"base16"}>Create bulk SMS</Typography>
        </Modal.Header>
        <BulkModalBody>
          <Sidebar width={310}>
            <BulkRecipientList
              find={find}
              query={query}
              type={"sms"}
              selected={selected}
              onSelect={handleSelect}
            />
          </Sidebar>
          <Container>
            <Header className={"send-bulk-sms-modal-header"}>
              <Form.Select
                name="from"
                label="From"
                errorMessage={false}
                properties={{
                  style: { width: 251 }
                }}
                data={numbers}
              />
              <FormField<PointerPickerProps>
                component={PointerPicker}
                name="template"
                label="Template"
                required
                errorMessage={false}
                properties={{
                  cleanable: true,
                  style: { width: 200 },
                  labelKey: "objectName",
                  valueKey: "id",
                  fields: ["text", "title"],
                  filters: templateFilters,
                  targetClass: "PhoneMessageTemplate"
                }}
              />

              {
                $viewer?.flyerPermissions?.send &&
                <FormField<PointerPickerProps>
                  component={PointerPicker}
                  name="flyer"
                  label="Flyer"
                  errorMessage={false}
                  properties={{
                    cleanable: true,
                    style: { width: 200 },
                    labelKey: "objectName",
                    valueKey: "objectId",
                    fields: ["content", "title"],
                    filters: flyerFilters,
                    targetClass: "Flyer"
                  }}
                />
              }
              <div style={{ flex: "1 0 30%" }}/>
              {/*todo workaround our form nor working with horizontal alignment*/}
            </Header>
            <Field name={"template"} subscription={{ value: true }}>
              {({ input: { value: templateId } }) => {
                const from = useField("from").input.value;
                const template = !!templateId && getTemplate(templateId);
                return (
                  <>
                    <Content>
                      {
                        !!template ?
                          <PhoneMessageRenderer
                            template={template.text}
                            ids={{
                              scopeId: selected.id,
                              userId: (from == "" ? selected?.assignedTo?.objectId : $viewer.objectId),
                              contactId: primaryBorrower?.contact?.objectId
                            }}/> :
                          <EmptyState
                            icon={"description"}
                            title={"Template not selected"}
                            subtitle={"Please select the template"}
                          />
                      }
                    </Content>
                  </>
                );
              }}
            </Field>
            <FormSpy
              subscription={{ errors: true, touched: true }}>
              {
                ({ errors, touched }) => {
                  if (touched.template && errors?.template) {
                    return <div className={"send-bulk-message-modal-warning"}>
                      <SectionMessage type="warning">Content is missing. Please choose template to send the
                        message.</SectionMessage>
                    </div>;
                  }
                  return null;
                }
              }
            </FormSpy>
          </Container>
        </BulkModalBody>
        <Modal.Footer>
          <Button type={"button"} size={"xs"} onClick={props.onClose} color={"blue"} appearance="text">CANCEL</Button>
          <Button type={"submit"} size={"xs"} appearance="primary">SEND</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

interface BulkSendPhoneMessageModalAlertProps extends BoxComponentProps {
  handleClick: () => void;
}

export const BulkSendPhoneMessageModalAlert: FC<BulkSendPhoneMessageModalAlertProps> = function (props) {
  const { handleClick } = props;
  const style = {
    color: "white",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 500
  };
  return (
    <Box>
      SMSs in progress, you can follow the process on <a onClick={handleClick} style={style}>SMS</a> tab.
    </Box>
  );
};
