import React                      from "react";
import { FC }                     from "react";
import { GlobalClasses }          from "../../..";
import { Box }                    from "../../Box";
import { InlineEditableEditView } from "../../Input/InlineEditableInput";
import { NumberFieldProps }       from "../NumberField";
import { useNumberField }         from "../NumberField/useNumberField";

export type CurrencyInputFieldProps = NumberFieldProps & {
  style?: any
  label?: string
};

export const CurrencyInputField: FC<CurrencyInputFieldProps> = React.memo(function CurrencyInputField(props) {
  const field = useNumberField(props);
  const { label, validate, ...otherProps } = props;
  const { input, meta: { touched, error, submitError } } = field;
  const hasError = (touched && !!error) || !!submitError;

  return (
    <Box container direction={"column"} gap="XXXS">
      <InlineEditableEditView
        error={hasError}
        value={input.value}
        {...input}
        {...otherProps}
        onChange={(e) => field.onChange(e.target.value, e)}/>
      {
        label &&
        <Box className={GlobalClasses.Message}>
          {label}
        </Box>
      }
    </Box>
  );
});
