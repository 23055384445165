import { useMemo }              from "react";
import React                    from "react";
import { useContext }           from "react";
import { useField }             from "@relcu/form";
import { Box }                  from "@relcu/ui";
import { CurrencyField }        from "@relcu/ui";
import { Where }                from "@relcu/ui";
import { format }               from "../../../../../../utils/helpers";
import { RateClasses }          from "../Rate/RateClasses";
import { RateContext }          from "../Rate/RateProvider";
import { WHEN_DISCOUNT_POINT }  from "../RateConditions";
import { WHEN_LENDER_CREDITS }  from "../RateConditions";
import ClosingCosts             from "./ClosingCosts";
import { TotalClosingCostsSum } from "./TotalClosingCostsSum";

export const TotalClosingCosts = React.memo(function TotalClosingCosts() {
  return <Box container direction={"column"}>
    <Box container justify={"space-between"} className={RateClasses.RateSectionHeader}>
      <h2 className={RateClasses.SectionTitle}>Closing costs</h2>
      <p className={RateClasses.SectionSum}>
        <TotalClosingCostsSum/>
        {/*<ClosingCostsSum/>*/}
      </p>
    </Box>
    <ClosingCosts/>
  </Box>;
});

export const LenderCredits = React.memo(function LenderCredits() {
  const { points, mortechPoints } = useContext(RateContext);
  const { input: { value: lenderCredits } } = useField("lenderCredits", { subscription: { value: true } });
  const warning = useMemo(() => {
    return lenderCredits > Number(mortechPoints);
  }, [lenderCredits, mortechPoints]);
  return <Where source={{ points }} conditions={WHEN_LENDER_CREDITS}>
    <CurrencyField
      view={"write"}
      warning={warning ? `Available credits are $${format(mortechPoints, 2)}` : undefined}
      precision={2}
      flexBasis={"calc(50% - ( 16px / 2 ))"}
      name={"lenderCredits"}
      label={"Lender credits"}
      placeholder={"Enter lender credits"}
    />
  </Where>;
});

export const DiscountPoints = React.memo(function LenderCredits() {
  const { points, mortechPoints } = useContext(RateContext);
  const { input: { value: discountPoints } } = useField("discountPoints", { subscription: { value: true } });
  const warning = useMemo(() => {
    return discountPoints > Number(mortechPoints);
  }, [discountPoints, mortechPoints]);
  return <Where source={{ points }} conditions={WHEN_DISCOUNT_POINT}>
    <CurrencyField
      view={"write"}
      warning={warning ? `Available points are $${format(mortechPoints, 2)}` : undefined}
      precision={2}
      flexBasis={"calc(50% - ( 16px / 2 ))"}
      name={"discountPoints"}
      label={"Discount points"}/>
  </Where>;
});

export default TotalClosingCosts;
