import { toFirstLower }    from "@relcu/ui";
import { JsonViewProps }   from "@relcu/ui";
import { useSource }       from "@relcu/ui";
import { ObjectViewProps } from "@relcu/ui";
import { useJqlQuery }     from "../../Jql";

export function useObjectView(props: JsonViewProps) {
  const { $object } = useSource();
  const { data, loading } = useJqlQuery(
    {
      operation: toFirstLower(props.className),
      fields: ["id", "objectIcon", "assignedAt", `${props.fieldName}`],
      variables: {
        id: {
          name: "id",
          type: "ID!",
          value: $object.id
        }
      }
    },
    { operationName: `Get${props.fieldName}` }
  );
  const api: ObjectViewProps = {
    loading,
    className: props.fieldName,
    get data() {
      return data[ props.className.toLowerCase() ][ props.fieldName ];
    },
    emptyText: "Lead has no original source",
    emptyContent: "Seems this lead was created directly on relcu",
    emptyIcon: "input" as any
  };
  return api;
}
