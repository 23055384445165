import { AddressField }         from "../../Field/AddressField";
import { ChoiceField }          from "../../Field/ChoiceField";
import { CurrencyField }        from "../../Field/CurrencyField";
import { DateField }            from "../../Field/DateField";
import { EmailField }           from "../../Field/EmailField";
import { EmailsField }          from "../../Field/EmailsField";
import { HiddenField }          from "../../Field/HiddenField";
import { MultiChoiceField }     from "../../Field/MultiChoiceField";
import { MultiInputField }      from "../../Field/MultiInputField";
import { MultiUrlField }        from "../../Field/MultiUrlField";
import { MultiTextField }       from "../../Field/MultiTextField";
import { NumberField }          from "../../Field/NumberField";
import { PercentField }         from "../../Field/PercentField";
import { PhoneNumberField }     from "../../Field/PhoneNumberField";
import { PhoneNumbersField }    from "../../Field/PhoneNumbersField";
import { PointerField }         from "../../Field/PointerField";
import { PointerListField }     from "../../Field/PointerListField";
import { StatusField }          from "../../Field/StatusField";
import { SwitchField }          from "../../Field/SwitchField";
import { TextField }            from "../../Field/TextField";
import { TimeField }            from "../../Field/TimeField";
import { TreeChoiceField }      from "../../Field/TreeChoiceField";
import { UrlField }             from "../../Field/UrlField";
import { ToggleSwitchField }    from "../../Field/ToggleSwitchField";
import { UsernameField }        from "../../Field/UsernameField";
import { SoundSection }         from "../JsonForm/Section/SoundSection";
import { StatsSection }         from "../JsonForm/Section/StatsSection";
import { StreetSection }        from "../JsonForm/Section/StreetSection";
import { VoiceMailSection }     from "../JsonForm/Section/VoiceMailSection";
import { VoiceTemplateSection } from "../JsonForm/Section/VoiceTemplateSection";
import { ComponentMappings }    from "./ComponentProvider";

export const defaultMapping: ComponentMappings = {
  fields: {
    TextField,
    EmailField,
    EmailsField,
    NumberField,
    SwitchField,
    PhoneNumberField,
    PhoneNumbersField,
    MultiTextField,
    ChoiceField,
    TreeChoiceField,
    MultiChoiceField,
    MultiInputField,
    MultiUrlField,
    DateField,
    PointerField,
    PointerListField,
    PercentField,
    CurrencyField,
    UrlField,
    HiddenField,
    AddressField,
    StatusField,
    TimeField,
    ToggleSwitchField,
    UsernameField
  },
  sections: {
    StatsSection,
    SoundSection,
    StreetSection,
    VoiceMailSection,
    VoiceTemplateSection
  },
  headers: {},
  views: {},
  widgets: {},
  forms: {},
  dialogs: {}
};
