import React                           from "react";
import { FC }                          from "react";
import { CommonClasses }               from "@relcu/ui";
import { ButtonVariants }              from "@relcu/ui";
import { BoxItemComponentProps }       from "@relcu/ui";
import { ButtonSizes }                 from "@relcu/ui";
import { ButtonColors }                from "@relcu/ui";
import { CircularLoader }              from "@relcu/ui";
import { ListItemText }                from "@relcu/ui";
import { ButtonGroup }                 from "@relcu/ui";
import { ListItemTitle }               from "@relcu/ui";
import { ListItemIcon }                from "@relcu/ui";
import { ListRowColors }               from "@relcu/ui";
import { ListRow }                     from "@relcu/ui";
import { classNames }                  from "@relcu/ui";
import { Button }                      from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { Section }                     from "@relcu/ui";
import { ButtonClasses }               from "@relcu/ui";
import { classNames as clsNames }      from "@relcu/ui";
import { EmptyList }                   from "@relcu/ui";
import { useLoanProposalTemplateView } from "./useLoanProposalTemplateView";
import "./loan-proposal-template-view.css";

export const LoanProposalTemplateView: FC<any> = React.memo(function LoanProposalTemplateListView(props) {
  const {modalContext, handleEdit, handleDownload, handleTemplateCreate, isEmpty, edit, handleClear, loading, file, onPreview, handleSave, loanProposal } = useLoanProposalTemplateView();

  return <Section title={"Loan Proposal Template"} justify={"center"} view={"read"} editable={false}>
    {modalContext}
    {
      isEmpty ?
        <Box container direction={"column"} gap={"S"} flex={1} alignItems={"center"}>
          <EmptyList icon={"rc_lp"} content={"Upload LP template by clicking on \"Upload template\" below"}
                     title={`You do not have LP templates`}
                     alignSelf={"center"} flexGrow={1} justify={"center"}/>
          <FileUploadButton handleTemplateCreate={handleTemplateCreate}>UPLOAD TEMPLATE</FileUploadButton>
        </Box>
        :
        <ListRow
          onClick={handleEdit}
          flex={1}
          gap={"XXXS"}
          color={edit ? ListRowColors.White : ListRowColors.Grey}
          className={classNames(LPTViewClasses.LPRow, { [ LPTViewClasses.LPRowActive ]: edit })}>
          <ListItemIcon type={"rc_word_doc"}/>
          <ListItemTitle title={file?.name}/>
          <Box flex={1}/>
          {
            loading
              ?
              <Box container gap={"XXS"} flex={2} justify={"start"}>
                <ListItemText text={"File uploading..."}/>
                <CircularLoader/>
              </Box>
              :
              <Box container gap={"XS"}>
                <ButtonGroup variant={ButtonVariants.Outline} collapse>
                  <Button onClick={() => onPreview(1, true)}>PREVIEW</Button>
                  <Button onClick={() => onPreview(1, true)}>Purchase - 1 offer</Button>
                  <Button onClick={() => onPreview(2, true)}>Purchase - 2 offers</Button>
                  <Button onClick={() => onPreview(3, true)}>Purchase - 3 offers</Button>
                  <Button onClick={() => onPreview(1, false)}>Refinance - 1 offer</Button>
                  <Button onClick={() => onPreview(2, false)}>Refinance - 2 offers</Button>
                  <Button onClick={() => onPreview(3, false)}>Refinance - 3 offers</Button>
                </ButtonGroup>
                {
                  edit ?
                    <Box container gap={"XXS"} alignItems={"center"}>
                      <ListItemIcon className={CommonClasses.ClickableIcon} type={"save"}
                                    onClick={handleSave}/>
                      <ListItemIcon className={CommonClasses.ClickableIcon} type={"clear"}
                                    onClick={handleClear}/>
                    </Box> :
                    !!loanProposal?.templateId &&
                    <>
                      <FileUploadButton handleTemplateCreate={handleTemplateCreate}>REPLACE</FileUploadButton>
                      <Button onClick={handleDownload} icon={"file_download"} onlyIcon={true}
                              variant={ButtonVariants.Outline}/>
                    </>
                }
              </Box>
          }
        </ListRow>
    }
  </Section>;
});

export default LoanProposalTemplateView;

export interface FileUploadButtonProps extends BoxItemComponentProps {
  handleTemplateCreate(e)
  color?: ButtonColors,
  variant?: ButtonVariants,
  size?: ButtonSizes,
  disabled?: boolean
}

export const FileUploadButton: FC<FileUploadButtonProps> = React.memo(function FileUploadButton(props) {
  const { color, variant, disabled, size, className, children } = props;
  const classNames = clsNames(ButtonClasses.Button, {
    [ ButtonClasses.Primary ]: (color == ButtonColors.Primary),
    [ ButtonClasses.Outline ]: (variant == ButtonVariants.Outline),
    [ ButtonClasses.Disabled ]: disabled,
    [ ButtonClasses.Medium ]: (size == ButtonSizes.Medium),
  }, className);

  return <label className={classNames}>
    <input
      style={{ display: "none" }}
      type="file"
      accept={"application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
      onChange={props.handleTemplateCreate}/>
    {children}
  </label>;
});

FileUploadButton.defaultProps = {
  variant: ButtonVariants.Outline,
  color: ButtonColors.Primary,
  size: ButtonSizes.Medium
};

const enum LPTViewClasses {
  LPRow = "lp-row",
  LPRowActive = "lp-row--active"
}
