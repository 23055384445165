import { useEffect }            from "react";
import { useState }             from "react";
import { useQuery }             from "@apollo/client";
import { gql }                  from "@apollo/client";
import { useLazyQuery }         from "@apollo/client";
import { useNavigate }          from "@relcu/react-router";
import { useThrottle }          from "@relcu/ui";
import { parsePhoneNumber }     from "@relcu/ui";
import { usePrevious }          from "@relcu/ui";
import { useMounted }           from "@relcu/ui";
import { schemaVar }            from "../../../reactiveVars";
import { toFirstLower }         from "../../../utils/helpers";
import { transformNameToLabel } from "../../../utils/helpers";
import { getObjectPath }        from "../../../utils/layoutUtils";
import { pluralize }            from "../../../utils/pluralize";
import { ViewerUserRole }       from "../../AccessControl/__types__/ViewerUserRole";
import { VIEWER_USER_ROLE }     from "../../AccessControl/queries.grapql";
import { useSearchPermissions } from "../../AccessControl/useSearchPermision";
import { SearchQueryVariables } from "./__types__/SearchQuery";
import { SearchQuery }          from "./__types__/SearchQuery";

export const SEARCH_QUERY = gql`
  query SearchQuery(
    $objectId:ID,
    $matchesRegex:String,
    $first:Int,
    $offsetLeads: Int,
    $offsetUsers: Int,
    $offsetContacts: Int,
    $offsetTasks: Int,
    $offsetRelcuLoans: Int,
    $skipLeads:Boolean = false,
    $skipUsers:Boolean = false,
    $skipContacts:Boolean = false,
    $skipTasks:Boolean = false,
    $skipRelcuLoans:Boolean = false
  ) {
    leads(
      first: $first,
      skip: $offsetLeads,
      where: {
        AND: [
          {
            duplicateOf: {
              exists: false
            }
          },
          { OR: [
            {
              members: {
                have: {
                  type: {equalTo: "borrower"}
                  contact: {
                    have: {
                      OR: [
                        {
                          firstName: {matchesRegex: $matchesRegex,options: "i"}
                        },
                        {
                          lastName: {matchesRegex: $matchesRegex,options: "i"}
                        },
                        {
                          objectName: {matchesRegex: $matchesRegex,options: "i"}
                        },
                        {
                          company: {matchesRegex: $matchesRegex,options: "i"}
                        }
                        {
                          phones: {have: {number: {matchesRegex:$matchesRegex,options: "i" }}}
                        },
                        {
                          emails: {have: {address: {matchesRegex:$matchesRegex,options: "i"}}}
                        }
                      ]
                    }
                  }
                }
              }
            },
            {
              objectName: {matchesRegex: $matchesRegex,options: "i"}
            },
            {
              objectId: {equalTo: $objectId}
            }
          ]
          }
        ],
        duplicateOf: {exists: false}
      }
    ) @skip(if: $skipLeads){
      count
      edges {
        node {
          objectId
          objectName
          objectIcon
          loanProgram
          loanPurpose
          loanAmount
          leadSource{
            title
          }
          leadStatus{
            status
          }
          assignedTo{
            objectId
            objectIcon
            objectName
          }
          updatedAt
          createdAt
        }
      }
    }
    relcuLoans(
      first: $first,
      skip: $offsetRelcuLoans,
      where: {
        OR: [
          {
            objectName: {matchesRegex: $matchesRegex,options: "i"}
          },
          {
            loanNumber: {matchesRegex: $matchesRegex,options: "i"}
          },
          {
            borrowerFirstName: {matchesRegex: $matchesRegex,options: "i"}
          },
          {
            borrowerLastName: {matchesRegex: $matchesRegex,options: "i"}
          },
          {
            borrowerFullName: {matchesRegex: $matchesRegex,options: "i"}
          }
        ]
      }
    ) @skip(if: $skipRelcuLoans){
      count
      edges {
        node {
          objectId
          objectName
          objectIcon
          loanNumber
          borrowerFirstName
          borrowerLastName
          borrowerFullName
          currentMilestone
          lead{
            objectId
            objectIcon
            objectName
          }
          updatedAt
          createdAt
        }
      }
    }
    users(
      first: $first,
      skip: $offsetUsers,
      where: {
        OR: [
          {
            firstName: {matchesRegex: $matchesRegex,options: "i"}
          },
          {
            lastName: {matchesRegex: $matchesRegex,options: "i"}
          },
          {
            email: {matchesRegex: $matchesRegex,options: "i"}
          },
          {
            objectName: {matchesRegex: $matchesRegex,options: "i"}
          }
          {
            phoneLines: {
              have: {
                OR: [
                  {
                    objectName: {matchesRegex: $matchesRegex,options: "i"}
                  },
                  {
                    number: {matchesRegex: $matchesRegex,options: "i"}
                  },
                  {
                    name: {matchesRegex: $matchesRegex,options: "i"}
                  }
                ]
              }
            }
          }
        ]
      }
    ) @skip(if: $skipUsers){
      count
      edges {
        node {
          objectId
          objectIcon
          objectName
          firstName
          lastName
          team
          role
          updatedAt
          createdAt
        }
      }
    }
    contacts(first: $first,skip: $offsetContacts,where: {
      OR: [
        {
          firstName: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          lastName: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          objectName: {matchesRegex: $matchesRegex,options: "i"}
        }
        {
          company: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          phones: {have: {number: {matchesRegex:$matchesRegex,options: "i" }}}
        },
        {
          emails: {have: {address: {matchesRegex:$matchesRegex,options: "i"}}}
        }
      ]
    }) @skip(if: $skipContacts){
      count
      edges {
        node {
          objectIcon
          objectId
          objectName
          firstName
          lastName
          tags
          emails{
            address
            type
          }
          updatedAt
          createdAt
        }
      }
    }
    tasks(first: $first,skip: $offsetTasks,where: {
      OR: [
        {
          title: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          description: {matchesRegex: $matchesRegex,options: "i"}
        }
      ]
    }) @skip(if: $skipTasks){
      count
      edges {
        node {
          objectIcon
          objectId
          objectName
          title
          done
          description
          assignedTo {
            objectId
            objectIcon
            objectName
          }
          subject {
            ...on Document {
              objectId
              objectIcon
              objectName
            }
          }
          dueDate
          remind
          createdAt
        }
      }
    }
  }
`;

export function useSearch(onClose) {
  const [value, setValue] = useState("");
  let [query, setQuery] = useThrottle(value, 1000);
  const navigate = useNavigate();
  const { data: { viewer } } = useQuery<ViewerUserRole>(VIEWER_USER_ROLE);
  const schema = schemaVar();
  const { canSearch } = useSearchPermissions();
  const [visibleSchemas] = useState(() => {
    let schemas = Object.values(schema).filter(s => canSearch(s.className));
    return schemas.map((schema) => {
      return {
        label: transformNameToLabel(schema.className),
        value: schema.className
      };
    }).sort((a, b) => {
      if (a.value == "Lead") {
        return -1;
      } else {
        return 1;
      }
    }).filter(s => s.value !== "Loan");
  });
  function skipQuery(__typename: string) {
    return !classNames.includes(__typename);
  }
  const limit = 15;
  const [classNames, setClassNames] = useState<string[]>(["Lead"]);
  if (query && !/[A-Z]/ig.test(query)) {
    query = parsePhoneNumber(query);
  }
  if (query && query.includes("+")) {
    query = String(query).replace(/[+]/g, "");
  }
  const objectId = query ? query.trim() : null;
  const matchesRegex = `.*${objectId}.*`;
  const [load, { loading, data, called, refetch, variables }] = useLazyQuery<SearchQuery, SearchQueryVariables>(SEARCH_QUERY, {
    variables: {
      first: limit,
      offsetLeads: 0,
      offsetUsers: 0,
      offsetContacts: 0,
      offsetTasks: 0,
      offsetRelcuLoans: 0,
      skipLeads: skipQuery("Lead"),
      skipUsers: skipQuery("User"),
      skipContacts: skipQuery("Contact"),
      skipTasks: skipQuery("Task"),
      skipRelcuLoans: skipQuery("RelcuLoan"),
      matchesRegex,
      objectId: objectId ?? ""
    },
    fetchPolicy: "no-cache"
  });
  const previousData = usePrevious(data);
  useEffect(() => setQuery(value), [value]);
  useMounted(load, [query]);
  const search = {
    loading,
    value,
    load,
    setValue,
    classNames,
    setClassNames,
    visibleSchemas,
    onRowClick(item) {
      if (item.__typename.toLowerCase() == "task" && item?.subject?.objectId) {
        navigate(`/lead/${item.subject?.objectId}/tasks`);
      } else if (toFirstLower(item.__typename) == "relcuLoan") {
        if (item.lead?.objectId) {
          navigate(`/lead/${item.lead?.objectId}/relcuLoan`);
        }
      } else {
        navigate(getObjectPath({ __typename: item.__typename, objectId: item.objectId }))
      }
    },
    getObjects(__typename: string) {
      const key = pluralize(toFirstLower(__typename));
      const edges = search.data?.[ key ]?.edges || [];
      return edges.map(({ node }) => node);
    },
    getCount(__typename: string) {
      const key = pluralize(toFirstLower(__typename));
      return search.data?.[ key ]?.count || search.data?.[ key ]?.data?.length || 0;
    },
    onPage(__typename: string, page: number) {
      return refetch({
        skipLeads: skipQuery("Lead"),
        skipUsers: skipQuery("User"),
        skipContacts: skipQuery("Contact"),
        skipTasks: skipQuery("Task"),
        skipRelcuLoans: skipQuery("RelcuLoan"),
        offsetLeads: __typename == "Lead" ? (page - 1) * limit : variables.offsetLeads,
        offsetContacts: __typename == "Contact" ? (page - 1) * limit : variables.offsetContacts,
        offsetTasks: __typename == "Task" ? (page - 1) * limit : variables.offsetTasks,
        offsetRelcuLoans: __typename == "RelcuLoan" ? (page - 1) * limit : variables.offsetRelcuLoans,
        offsetUsers: __typename == "User" ? (page - 1) * limit : variables.offsetUsers
        // skip: (page - 1) * limit
      });
    },
    get data() {
      return loading ? previousData : data;
    },
    get isEmpty() {
      return !called || (!loading && (
        Object.keys(Object(data)).length === 0 || (
          !Object.keys(data).some(k => data[ k ]?.count > 0)
        )
      )) || !classNames.length;
    }
  };

  return search;
}
