import { useMemo }        from "react";
import { HTMLAttributes } from "react";
import React              from "react";
import { classNames }     from "../..";
import { omit }           from "../../utils/helpers";
import { pick }           from "../../utils/helpers";

export type Globals = "-moz-initial" | "inherit" | "initial" | "revert" | "unset";
export type GlobalsNumber = Globals | number;
export type FlexDirectionProperty = Globals | "column" | "column-reverse" | "row" | "row-reverse";
export type FlexProperty<TLength> = Globals | TLength | "auto" | "content" | "max-content" | "min-content" | "none" | string | number;
export type FlexBasisProperty<TLength> = Globals | TLength | "-moz-max-content" | "-moz-min-content" | "-webkit-auto" | "auto" | "content" | "max-content" | "min-content" | string;
export type FlexWrapProperty = Globals | "nowrap" | "wrap" | "wrap-reverse";

export interface BoxContainerProps {
  container?: boolean;
  direction?: FlexDirectionProperty;
  wrap?: FlexWrapProperty;
  justify?: "space-around" | "space-between" | "space-evenly" | "stretch" | "center" | "end" | "start";
  alignItems?: "center" | "end" | "start" | "self-end" | "self-start" | "baseline" | "stretch";
  alignContent?: "space-around" | "space-between" | "space-evenly" | "stretch" | "baseline" | "center" | "end" | "start";
  gap?: "XXXL" | "XXL" | "XL" | "L" | "M" | "S" | "XS" | "XXS" | "XXXS" | "XXXXS"
}
export interface BoxItemProps {
  order?: GlobalsNumber;
  flexGrow?: GlobalsNumber;
  flexShrink?: GlobalsNumber;
  flexBasis?: FlexBasisProperty<number>;
  flex?: FlexProperty<number>;
  alignSelf?: "center" | "end" | "start" | "self-end" | "self-start" | "baseline" | "stretch" | "normal";
}
export type BoxProps = BoxContainerProps & BoxItemProps;
export type BoxComponentProps<T = any> = BoxProps & HTMLAttributes<T>;
export type BoxContainerComponentProps<T = any> = BoxContainerProps & HTMLAttributes<T>;
export type BoxItemComponentProps<T = any> = BoxItemProps & HTMLAttributes<T>;

export function applyBoxStyles<T extends BoxComponentProps>(props: T): Omit<Omit<T, keyof BoxContainerProps>, keyof BoxItemProps> {
  let propsAfterApplyContainer = applyBoxContainerStyles<T>(props);
  let propsAfterApplyingItem = applyBoxItemStyles<typeof propsAfterApplyContainer>(propsAfterApplyContainer);
  return propsAfterApplyingItem;
}

export function applyBoxContainerStyles<T extends BoxContainerComponentProps>(props: T): Omit<T, keyof BoxContainerProps> {
  return useMemo(() => {
    let { container, direction = "row", wrap, justify, alignItems, alignContent, gap, ...properties } = props;
    properties[ "className" ] = classNames({
      [ "box" ]: container,

      [ "box-column" ]: (direction == "column"),
      [ "box-column-reverse" ]: (direction == "column-reverse"),
      [ "box-row" ]: (direction == "row"),
      [ "box-row-reverse" ]: (direction == "row-reverse"),

      [ "box-nowrap" ]: (wrap == "nowrap"),
      [ "box-wrap" ]: (wrap == "wrap"),
      [ "box-wrap-reverse" ]: (wrap == "wrap-reverse"),

      [ "box-justify-space-around" ]: (justify == "space-around"),
      [ "box-justify-space-between" ]: (justify == "space-between"),
      [ "box-justify-space-evenly" ]: (justify == "space-evenly"),
      [ "box-justify-stretch" ]: (justify == "stretch"),
      [ "box-justify-center" ]: (justify == "center"),
      [ "box-justify-end" ]: (justify == "end"),
      [ "box-justify-start" ]: (justify == "start"),

      [ "box-align-content-space-around" ]: (alignContent == "space-around"),
      [ "box-align-content-space-between" ]: (alignContent == "space-between"),
      [ "box-align-content-space-evenly" ]: (alignContent == "space-evenly"),
      [ "box-align-content-stretch" ]: (alignContent == "stretch"),
      [ "box-align-content-center" ]: (alignContent == "center"),
      [ "box-align-content-end" ]: (alignContent == "end"),
      [ "box-align-content-start" ]: (alignContent == "start"),
      [ "box-align-content-baseline" ]: (alignContent == "baseline"),

      [ "box-align-items-self-start" ]: (alignItems == "self-start"),
      [ "box-align-items-self-end" ]: (alignItems == "self-end"),
      [ "box-align-items-baseline" ]: (alignItems == "baseline"),
      [ "box-align-items-stretch" ]: (alignItems == "stretch"),
      [ "box-align-items-center" ]: (alignItems == "center"),
      [ "box-align-items-end" ]: (alignItems == "end"),
      [ "box-align-items-start" ]: (alignItems == "start"),

      [ "box-gap-xxxxs" ]: (gap == "XXXXS"),
      [ "box-gap-xxxs" ]: (gap == "XXXS"),
      [ "box-gap-xxs" ]: (gap == "XXS"),
      [ "box-gap-xs" ]: (gap == "XS"),
      [ "box-gap-s" ]: (gap == "S"),
      [ "box-gap-m" ]: (gap == "M"),
      [ "box-gap-l" ]: (gap == "L"),
      [ "box-gap-xl" ]: (gap == "XL"),
      [ "box-gap-xxl" ]: (gap == "XXL"),
      [ "box-gap-xxxl" ]: (gap == "XXXL")

    }, properties.className);

    return properties;
  }, [props]);
}
export function applyBoxItemStyles<T extends Omit<BoxItemComponentProps, "title">>(props: T): Omit<T, keyof BoxItemProps> {
  return useMemo(() => {
    let { order, flexGrow, flex, flexBasis, flexShrink, alignSelf, ...properties } = props;
    properties[ "className" ] = classNames({
      [ "box-align-items-self-start" ]: (alignSelf == "self-start"),
      [ "box-align-items-self-end" ]: (alignSelf == "self-end"),
      [ "box-align-self-baseline" ]: (alignSelf == "baseline"),
      [ "box-align-self-stretch" ]: (alignSelf == "stretch"),
      [ "box-align-self-center" ]: (alignSelf == "center"),
      [ "box-align-self-end" ]: (alignSelf == "end"),
      [ "box-align-self-start" ]: (alignSelf == "start"),
      [ "box-align-self-normal" ]: (alignSelf == "normal")
    }, properties.className || "");

    properties.style = { ...properties.style } || {};

    if (order != null) {
      properties.style.order = order;
    }
    if (flexGrow != null) {
      properties.style.flexGrow = flexGrow;
    }
    if (flex != null) {
      properties.style.flex = flex;
    }
    if (flexBasis != null) {
      properties.style.flexBasis = flexBasis;
    }
    if (flexShrink != null) {
      properties.style.flexShrink = flexShrink;
    }

    return properties;
  }, [props]);
}
export const Box = React.memo(React.forwardRef(function Box(props: BoxComponentProps, ref: React.Ref<HTMLDivElement>) {
  let p = applyBoxStyles<BoxComponentProps>(props);
  return <div {...p} ref={ref}/>;
}));

export function getBoxProps(props) {
  const properties = ["container", "direction", "wrap", "justify", "alignItems", "alignContent", "gap", "order",
    "flexGrow", "flexShrink", "flexBasis", "flex", "alignSelf", "style", "flex",
  ];
  return pick(props, properties);
}
export function useBoxProps(props) {
  return useMemo(() => getBoxProps(props), [props]);
}

export enum BoxClasses {
  Box = "box",
  Column = "box-column",
  Row = "box-row",
  RowReverse = "box-row-reverse",
  ColumnReverse = "box-column-reverse",

  Wrap = "box-wrap",
  WrapReverse = "box-wrap-reverse",
  NoWrap = "box-nowrap",

  JustifySpaceAround = "box-justify-space-around",
  JustifySpaceBetween = "box-justify-space-between",
  JustifySpaceEvenly = "box-justify-space-evenly",
  JustifyStretch = "box-justify-stretch",
  JustifyCenter = "box-justify-center",
  JustifyEnd = "box-justify-end",
  JustifyStart = "box-justify-start",

  AlignContentSpaceAround = "box-align-content-space-around",
  AlignContentSpaceBetween = "box-align-content-space-between",
  AlignContentSpaceEvenly = "box-align-content-space-evenly",
  AlignContentStretch = "box-align-content-stretch",
  AlignContentCenter = "box-align-content-center",
  AlignContentEnd = "box-align-content-end",
  AlignContentStart = "box-align-content-start",
  AlignContentBaseLine = "box-align-content-baseline",

  AlignItemsSelfStart = "box-align-items-self-start",
  AlignItemsSelfEnd = "box-align-items-self-end",
  AlignItemsBaseLine = "box-align-items-baseline",
  AlignItemsStretch = "box-align-items-stretch",
  AlignItemsCenter = "box-align-items-center",
  AlignItemsEnd = "box-align-items-end",
  AlignItemsStart = "box-align-items-start",

  AlignSelfSelfStart = "box-align-items-self-start",
  AlignSelfSelfEnd = "box-align-items-self-end",
  AlignSelfBaseLine = "box-align-self-baseline",
  AlignSelfStretch = "box-align-self-stretch",
  AlignSelfCenter = "box-align-self-center",
  AlignSelfEnd = "box-align-self-end",
  AlignSelfStart = "box-align-self-start",
  AlignSelfNormal = "box-align-self-normal",

  gapXXXL = "box-gap-xxxl",
  gapXXL = "box-gap-xxl",
  gapXL = "box-gap-xl",
  gapL = "box-gap-l",
  gapM = "box-gap-m",
  gapS = "box-gap-s",
  gapXS = "box-gap-xs",
  gapXXS = "box-gap-xxs",
  gapXXXS = "box-gap-xxxs",
  gapXXXXS = "box-gap-xxxxs",
}
