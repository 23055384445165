import { useCallback } from "react";
import { useState }    from "react";
import {
  AudioInputTest,
  AudioOutputTest,
  DiagnosticError,
  testAudioInputDevice,
  testAudioOutputDevice,
  WarningName
}                      from "@relcu/rtcd";

import {
  INPUT_TEST_DURATION,
  RECORD_DURATION
}                                                        from "../helpers/constants";

let audioInputTest: AudioInputTest;

const getErrorMessage = (error: DiagnosticError) => {
  let message = "An unknown error has occurred";
  if (error) {
    message = error.domError ? error.domError.toString() : error.message;
  }
  return message;
};

export default function useAudioInput() {
  const [isRecording, setIsRecording] = useState(false);
  const [isAudioInputTestRunning, setIsAudioInputTestRunning] = useState(false);
  const [dataArray, setDataArray] = useState(null);
  const [playbackURI, setPlaybackURI] = useState("");
  const [recordDuration, setRecordDuration] = useState(null);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [testEnded, setTestEnded] = useState(false);

  const stopReadAudioInput = useCallback(() => {
    if (audioInputTest) {
      audioInputTest.stop();
    }
  }, []);

  const readAudioInput = useCallback(
    (options: AudioInputTest.Options) => {
      if (audioInputTest) {
        audioInputTest.stop();
      }

      const duration = options.enableRecording ? RECORD_DURATION : INPUT_TEST_DURATION;
      options = { duration, ...options };
      audioInputTest = testAudioInputDevice(options);
      setIsAudioInputTestRunning(true);
      if (options.enableRecording) {
        setTestEnded(false);
        setIsRecording(true);
        setWarning("");
      }

      audioInputTest.on(AudioInputTest.Events.Volume, (value: number/*average value*/, dataArray) => {
        setDataArray({ ...dataArray });
      });

      audioInputTest.on(AudioInputTest.Events.End, (report: AudioInputTest.Report) => {
        if (playbackURI && report.recordingUrl) {
          URL.revokeObjectURL(playbackURI);
        }

        if (report.recordingUrl) {
          setPlaybackURI(report.recordingUrl);
          setRecordDuration(report?.testTiming?.duration || null);
        }

        setIsRecording(false);
        setIsAudioInputTestRunning(false);
      });

      audioInputTest.on(AudioInputTest.Events.Error, (diagnosticError: DiagnosticError) => {
        console.error("error", diagnosticError);
        setError(getErrorMessage(diagnosticError));
      });
      audioInputTest.on(AudioInputTest.Events.Warning, (name: WarningName) => {
        console.warn("warning", name);
      });
      audioInputTest.on(AudioInputTest.Events.WarningCleared, (name: WarningName) => {
        console.warn("warning-cleared", name);
      });
    },
    [playbackURI]
  );

  return {
    error,
    warning,
    dataArray,
    isRecording,
    isAudioInputTestRunning,
    setPlaybackURI,
    playbackURI,
    recordDuration,
    readAudioInput,
    stopReadAudioInput,
    testEnded
  };
}


