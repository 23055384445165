import React                from "react";
import { FC }               from "react";
import { FormSpy, useForm } from "@relcu/form";
import { Button }           from "@relcu/rc";
import { CellGroupHeader }  from "@relcu/rc";

export const LoanCriteriaGroupCell: FC = React.memo((props) => {
  const form = useForm();

  const handleClick = (e) => {
    e.stopPropagation();
    form.setConfig("keepDirtyOnReinitialize", false);
    form.reset();
    form.setConfig("keepDirtyOnReinitialize", true);
  };
  return (
    <CellGroupHeader justify={"center"}>
      <FormSpy subscription={{ dirty: true }}>
        {
          ({ dirty }) => (
            dirty &&
            <Button appearance={"text"} size={"xs"} onClick={handleClick}>DISCARD CHANGES</Button>
          )
        }
      </FormSpy>
    </CellGroupHeader>
  );
});
