import React                    from "react";
import { useContext }           from "react";
import { useReactiveVar }       from "@apollo/client";
import { ApolloProvider }       from "@apollo/client";
import { useAsync }             from "@relcu/ui";
import { Spinner }              from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { ComponentsProvider }   from "@relcu/ui";
import { ClientContext }        from "../Client";
import { audioDevicesVar }      from "../reactiveVars";
import { microphoneAccessVar }  from "../reactiveVars";
import { versionVar }           from "../reactiveVars";
import { fullScreenVar }        from "../reactiveVars";
import { AlertBar }             from "./AlertBar";
import { components }           from "./Layout";
import { Layout }               from "./Layout";
import { ReauthorizeModal }     from "./Modal/ReauthorizeModal";
import { NavBar }               from "./NavBar";
import { Root }                 from "./Root";
import { SettingsSubscription } from "./SettingsSubscription";
import { SideBar }              from "./SideBar";
import { SnackBar }             from "./SnackBar";
import { Sound }                from "./Sound";
import { useReactiveStorage }   from "./useReactiveStorage";

const version = import.meta.env.VITE_VERSION;
export const App = React.memo(function App() {
  const context = useContext(ClientContext);
  const [navbarActive] = useReactiveStorage("@navbar", true);
  const isFullScreen = useReactiveVar(fullScreenVar);
  const { loading } = useAsync(() => context.setup(), []);
  const currentVersion = version || window.__CONFIG__.versions.web;
  const latestVersion = useReactiveVar(versionVar);
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const audioDevices = useReactiveVar(audioDevicesVar);
  const isNewVersionAvailable = currentVersion !== latestVersion;
  const hasAlert = isNewVersionAvailable || !microphoneAccess || !audioDevices?.audiooutput?.length || !audioDevices?.audiooutput?.length;
  if (loading || !context.client) {
    return <Spinner/>;
  }

  return (
    <ApolloProvider client={context.client}>
      <Root>
        <ComponentsProvider components={components}>
          <AlertBar/>
          <Box container className="main" style={hasAlert ? { height: "calc(100vh - 40px)" } : undefined}>
            <SettingsSubscription/>
            {
              !isFullScreen &&
              <>
                <SideBar/>
                {navbarActive && <NavBar/>}
              </>
            }

            <Box container className="content" justify={"end"}>
              <Layout/>
              {
                !isFullScreen &&
                <SnackBar/>
              }
              {/*<MicrophoneAccessDialog open={!microphoneAccess}/>*/}
              <Sound/>
              <ReauthorizeModal/>
            </Box>
          </Box>
        </ComponentsProvider>
      </Root>
    </ApolloProvider>
  );
});

