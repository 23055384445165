export const enum NavBarClasses {
  NavMenu = "nav-menu",
  NavMenuContainer = "nav-menu__container",
  NavMenuContent = "nav-menu__content",
  NavMenuContentTitle = "nav-menu__title",
  NavMenuContentType = "nav-menu__type",
  NavMenuContentDeactivated = "nav-menu__deactivated",
  Empty = "nav-menu--empty",
  PlaceHolder = "nav-menu__placeholder",
  Small = "nav-menu--small",
  Top = "nav-menu__mention--top",
  Bottom = "nav-menu__mention--bottom"
}
