import { gql }                from "@apollo/client";
import { USER_NODE_FRAGMENT } from "../../../graph/operations.graphql";
import { DOCUMENT }           from "../../../graph/operations.graphql";
import { NODE_FRAGMENT }      from "../../../graph/operations.graphql";

export const USER_VOICE_MAIL = gql`
  ${NODE_FRAGMENT}
  query UserVoiceMail {
    viewer {
      user{
        id
        voiceMail{
          ...Node
          objectId
          objectName
          objectIcon
          name
          uploaded
          uploadStatus
          url
          type
        }
        voicemailOutOfWork {
          ...Node
          objectId
          objectName
          objectIcon
          name
          uploaded
          uploadStatus
          url
          type
        }
      }
    }
  }
`;

export const VOICE_TEMPLATE_USER = gql`
  query VoiceTemplateUser {
    viewer {
      user {
        id
      }
    }
  }
`;

export const VOICEMAIL_RECORDINGS = gql`
  query VoiceMailRecordings($id: ID!,$type:String = "voice_mail_template") {
    recordings(where: {
      type: {equalTo: $type}
      deleted: {notEqualTo: true}
      owner: {have: {id: {equalTo: $id}}}}){
      count
      edges{
        node{
          id
          name
          uploaded
          uploadStatus
          url
        }
      }
    }
  }
`;

export const SUBSCRIBE_RECORDINGS = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  subscription SubscribeRecodings($id: ID! $type:String = "voice_mail_template", $events: [SubscriptionEvent] = [CREATE,ENTER,UPDATE,LEAVE]) {
    recordings(where: {
      type: {equalTo: $type}
      deleted: {notEqualTo: true}
      owner: {
        have: {link: $id}
      }
    },events: $events){
      event
      node {
        ...Node
        ...Document
        name
        uploaded
        uploadStatus
        url
        type
      }
    }
  }
`;

export const SET_DEFAULT_VOICEMAIL = gql`
  ${NODE_FRAGMENT}
  ${USER_NODE_FRAGMENT}
  mutation SetDefaultVoiceMail($id: ID!,$rId:ID) {
    updateUser(input: {id: $id,fields: {
      voiceMail: {link:$rId}
    }}){
      user{
        ...UserNode
      }
    }
  }
`;

export const SET_DEFAULT_OUT_OF_WORK_VOICEMAIL = gql`
  ${NODE_FRAGMENT}
  ${USER_NODE_FRAGMENT}
  mutation SetDefaultOutOfWorkVoicemail($id: ID!,$rId:ID) {
    updateUser(input: {id: $id,fields: {
      voicemailOutOfWork: {link:$rId}
    }}){
      user{
        ...UserNode
      }
    }
  }
`;

export const UPDATE_RECORDING_NAME = gql`
  mutation UpdateRecordingName($id: ID!,$name: String!) {
    updateRecording(input: {id: $id,fields: {name: $name}}){
      recording{
        id
        name
        url
      }
    }
  }
`;
export const DELETE_RECORDING = gql`
  mutation DeleteRecording($id: ID!) {
    updateRecording(input: {id: $id,fields: {deleted: true}}){
      recording{
        id
      }
    }
  }
`;
