import { HTMLAttributes } from "react";
import React              from "react";
import { classNames }     from "../..";
import { Box }            from "../..";
import { NavBarClasses }  from "./NavBarClasses";

export interface NavBarSubtitleProps extends HTMLAttributes<any> {
  title: string,
}

export function NavBarSubtitle(props: NavBarSubtitleProps) {
  const { className, children, title, ...p } = props;
  const classes = classNames(NavBarClasses.NavBarSubtitle, className);

  return <Box container justify={"space-between"} alignItems={"center"} className={classes} {...p}>
    {title}
    {children}
  </Box>;
}
