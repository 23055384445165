import { useReactiveVar }              from "@apollo/client";
import { ReactNode }                   from "react";
import { useContext }                  from "react";
import React                           from "react";
import { FC }                          from "react";
import { classNames, ListItemClasses } from "@relcu/ui";
import { CommonClasses }               from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { Where }                       from "@relcu/ui";
import { FormSummary }                 from "@relcu/ui";
import { FormSummaryField }            from "@relcu/ui";
import { format }                      from "../../../../../../utils/helpers";
import { TotalClosingCostsSum }        from "../ClosingCosts/TotalClosingCostsSum";
import { PersistContext }              from "../PersistProvider";
import { REFINANCE_SUM_FIELDS }        from "../RateConditions";
import { PURCHASE_SUM_FIELDS }         from "../RateConditions";
import { WHEN_IS_REFINANCE }           from "../RateConditions";
import { WHEN_IS_PURCHASE }            from "../RateConditions";
import { TotalLoanAmount }             from "./TotalLoanAmount";

// export const PurchaseClosing: FC<any> = React.memo(function PurchaseClosing(props) {
//   const { lead } = useContext(RateContext);
//   return <Where conditions={WHEN_IS_PURCHASE}>
//     <TotalClosingCostsSum>
//       {
//         (value) => <>
//           <RatePartnerRow title={"Purchase value"}
//                           sum={`$${format(lead?.property?.value, 2)}`}/>
//           <TotalLoanAmount>
//             {(totalLoanAmountValue, loanAmount, type) => <>
//               <RatePartnerRow title={"Loan amount"}
//                               sum={`- $${format(totalLoanAmountValue, 2)}`}/>
//               <RatePartnerRow title={"Total closing costs"}
//                               sum={`$${format(value, 2)}`}/>
//               {["ff", "mip"].includes(type) && <RatePartnerRow title={type == "ff" ? "Funding fee" : "MIP"}
//                                                                sum={`$${format(totalLoanAmountValue - loanAmount, 2)}`}/>}
//               <ClosingSum data={{
//                 purchaseValue: lead?.property?.value,
//                 totalLoanAmount: -totalLoanAmountValue,
//                 loanAmount: totalLoanAmountValue - loanAmount,
//                 totalClosingCosts: value
//               }} fields={PURCHASE_SUM_FIELDS}>
//                 {
//                   (purchaseValue) =>
//                     <ListItemText text={`$${format(purchaseValue, 2)} ${purchaseValue > 0 ? "(From you)" : "(To you)"}`}/>
//                     // <RatePartnerSummaryRow
//                     // title={`Cash at closing ${purchaseValue > 0 ? "(From you)" : "(To you)"}`}
//                     // sum={`$${format(purchaseValue, 2)}`}/>
//                 }
//               </ClosingSum>
//             </>
//             }
//           </TotalLoanAmount>
//         </>
//       }
//     </TotalClosingCostsSum>
//   </Where>;
// });
//
// export const RefinanceClosing: FC<any> = React.memo(function RefinanceClosing(props) {
//   const { lead } = useContext(RateContext);
//   return <Where conditions={WHEN_IS_REFINANCE}>
//     <TotalClosingCostsSum>
//       {
//         (value) =>
//           <>
//             <RatePartnerRow title={"Current mortgage balance"}
//                             sum={`$${format(lead?.property?.currentMortgageBalance, 2)}`}/>
//             <TotalLoanAmount>
//               {(totalLoanAmountValue, loanAmount, type) => <>
//                 <RatePartnerRow title={"New loan amount"} sum={`- $${format(totalLoanAmountValue, 2)}`}/>
//                 <RatePartnerRow title={"Total closing costs"}
//                                 sum={`$${format(value, 2)}`}/>
//                 {["ff", "mip"].includes(type) && <RatePartnerRow title={type == "ff" ? "Funding fee" : "MIP"}
//                                                                  sum={`$${format(totalLoanAmountValue - loanAmount, 2)}`}/>}
//                 <ClosingSum data={{
//                   currentMortgageBalance: lead?.property?.currentMortgageBalance,
//                   totalLoanAmount: -totalLoanAmountValue,
//                   loanAmount: totalLoanAmountValue - loanAmount,
//                   totalClosingCosts: value
//                 }} fields={REFINANCE_SUM_FIELDS}>
//                   {
//                     (refinanceValue) =>
//                       <ListItemText text={`$${format(Math.abs(refinanceValue as number), 2)} ${refinanceValue > 0 ? "(From you)" : "(To you)"}`}/>
//                       // <RatePartnerSummaryRow
//                       // title={`Cash at closing ${refinanceValue > 0 ? "(From you)" : "(To you)"}`}
//                       // sum={`$${format(Math.abs(refinanceValue as number), 2)}`}/>
//                   }
//                 </ClosingSum>
//               </>
//               }
//             </TotalLoanAmount>
//           </>
//       }
//     </TotalClosingCostsSum>
//   </Where>;
// });

export const PurchaseClosing: FC<any> = React.memo(function PurchaseClosing(props) {
  const { leadDataVar } = useContext(PersistContext);
  const lead = useReactiveVar(leadDataVar);
  let children = props.children || ((purchaseValue) => {
    return <Box container gap={"XXXS"} alignItems={"center"} flexBasis={"31%"}>
      <p className={ListItemClasses.ListItemText}>${format(purchaseValue, 2)}</p>
      <p
        className={classNames(CommonClasses.TextTiny, CommonClasses.TextSecondary)}>{purchaseValue > 0 ? "(From you)" : "(To you)"}</p>
    </Box>;
  });

  return <Where conditions={WHEN_IS_PURCHASE} source={{ $object: lead }}>
    <TotalClosingCostsSum>
      {
        (value) =>
          <TotalLoanAmount>
            {(totalLoanAmountValue, loanAmount) =>
              <ClosingSum data={{
                purchaseValue: lead?.property?.value,
                totalLoanAmount: -totalLoanAmountValue,
                loanAmount: totalLoanAmountValue - loanAmount,
                totalClosingCosts: value
              }} fields={PURCHASE_SUM_FIELDS}>
                {children}
              </ClosingSum>
            }
          </TotalLoanAmount>
      }
    </TotalClosingCostsSum>
  </Where>;
});

export const RefinanceClosing: FC<any> = React.memo(function RefinanceClosing(props) {
  const { leadDataVar } = useContext(PersistContext);
  const lead = useReactiveVar(leadDataVar);
  let children = props.children || ((refinanceValue) => {
    return <Box container gap={"XXXS"} alignItems={"center"} flexBasis={"31%"}>
      <p className={ListItemClasses.ListItemText}>${format(Math.abs(refinanceValue as number), 2)}</p>
      <p
        className={classNames(CommonClasses.TextTiny, CommonClasses.TextSecondary)}>{refinanceValue > 0 ? "(From you)" : "(To you)"}</p>
    </Box>;
  });

  return <Where conditions={WHEN_IS_REFINANCE} source={{ $object: lead }}>
    <TotalClosingCostsSum>
      {
        (value) =>
          <TotalLoanAmount>
            {(totalLoanAmountValue, loanAmount) =>
              <ClosingSum data={{
                currentMortgageBalance: lead?.property?.currentMortgageBalance,
                totalLoanAmount: -totalLoanAmountValue,
                loanAmount: totalLoanAmountValue - loanAmount,
                totalClosingCosts: value
              }} fields={REFINANCE_SUM_FIELDS}>
                {children}
              </ClosingSum>
            }
          </TotalLoanAmount>
      }
    </TotalClosingCostsSum>
  </Where>;
});

type FunctionOrReactNode = ReactNode | ((value: string | number) => ReactNode)
interface SumProps {
  children?: FunctionOrReactNode,
  prefix?: string,
  data: any
  fields: FormSummaryField[]
}
export const ClosingSum: FC<SumProps> = React.memo(function PurchaseSum(props) {
  const { children, prefix = "$", data, fields } = props;
  return <FormSummary data={data} fields={fields}>
    {
      (value) => {
        return children instanceof Function
          ?
          <>{children(value)}</>
          :
          <>{prefix || ""}{format(value, 2)}</>;
      }
    }
  </FormSummary>;
});
