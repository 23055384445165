import React, { useMemo, useCallback, useImperativeHandle, useRef } from "react";
import { FormInputNumber }                                          from "../FormField";
import FormField                                                    from "../FormField";
import { FormInput }                                                from "../FormField";
import { FormSelect }                                               from "../FormField";
import FormContext                                                  from "./FormContext";
import FormControl                                                  from "../FormControl";
import FormControlLabel                                             from "../FormControlLabel";
import FormGroup                                                    from "../FormGroup";
import FormHelpText                                                 from "../FormHelpText";
import { WithAsProps, TypeAttributes, RsRefForwardingComponent }    from "../@types/common";
import { useFormClassNames }                                        from "./useFormClassNames";
import { Form as FinalForm }                                        from "@relcu/form";
import { FormProps as FinalFormProps }                              from "@relcu/form";

export interface FormProps<T = Record<string, any>,
  errorMsgType = any,
  E = { [P in keyof T]?: errorMsgType }> extends WithAsProps,
  Omit<FinalFormProps, "children"> {
  /** Set the left and right columns of the layout of the elements within the form */
  layout?: "horizontal" | "vertical" | "inline" | "floating";

  /** The fluid property allows the Input 100% of the form to fill the container, valid only in vertical layouts. */
  fluid?: boolean;

  /** Make the form readonly */
  readOnly?: boolean;

  /** Render the form as plain text */
  plaintext?: boolean;

  /** Disable the form. */
  disabled?: boolean;

  formProps?: {
    [ key: string ]: any
  };
}

export interface FormInstance<T = Record<string, any>,
  errorMsg = string,
  E = { [P in keyof T]?: errorMsg }> {
  root: HTMLFormElement | null;
}

export interface FormComponent
  extends RsRefForwardingComponent<"form", FormProps & { ref?: React.Ref<FormInstance> }> {
  Control: typeof FormControl;
  Field: typeof FormField;
  Select: typeof FormSelect;
  Input: typeof FormInput;
  InputNumber: typeof FormInputNumber;
  ControlLabel: typeof FormControlLabel;
  Group: typeof FormGroup;
  HelpText: typeof FormHelpText;
}

export const Form: FormComponent = React.forwardRef((props: FormProps, ref: React.Ref<FormInstance>) => {
  const {
    classPrefix = "form",
    as: Component = "form",
    fluid,
    layout = "floating",
    readOnly,
    plaintext,
    className,
    children,
    disabled,
    onSubmit,
    formProps,
    ...rest
  } = props;

  const classes = useFormClassNames({
    classPrefix,
    className,
    fluid,
    layout,
    readOnly,
    plaintext,
    disabled
  });

  useImperativeHandle(ref, () => ({
    root: rootRef.current
  }));

  const handleSubmit = useCallback((values, form) => {
      if (disabled || readOnly || plaintext) {
        return;
      }

      return onSubmit?.(values, form);
    },
    [disabled, readOnly, plaintext, onSubmit]
  );

  const rootRef = useRef<HTMLFormElement>(null);
  const formContextValue = useMemo(
    () => ({
      readOnly,
      plaintext,
      disabled,
      layout
    }),
    [
      readOnly,
      plaintext,
      disabled,
      layout
    ]
  );

  return (
    <FinalForm
      {...rest}
      subscription={{ submitting: true }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, submitting }) => (
        <Component ref={rootRef} onSubmit={handleSubmit} className={classes} {...formProps}>
          <FormContext.Provider value={submitting ? { ...formContextValue, disabled: submitting } : formContextValue}>
            {children}
          </FormContext.Provider>
        </Component>
      )}
    </FinalForm>

  );
}) as unknown as FormComponent;

Form.Control = FormControl;
Form.ControlLabel = FormControlLabel;
Form.Group = FormGroup;
Form.HelpText = FormHelpText;
Form.Control = FormControl;
Form.Field = FormField;
Form.Select = FormSelect;
Form.Input = FormInput;
Form.InputNumber = FormInputNumber;

Form.displayName = "Form";
