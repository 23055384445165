export function getItemField(item, key?) {
  return typeof item == "object" ? item?.[ key ] : item;
}

export function getItemLabel(item, label?) {
  return typeof item == "object" ? item?.[ label ] : item;
}

export function filterData(item, value, label) {
  return getItemLabel(item, label).toString().toLowerCase().indexOf(value) != -1;
}