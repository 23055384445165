import React                       from "react";
import { FC }                      from "react";
import { Box }                     from "../Box";
import { BoxComponentProps }       from "../Box";
import { MicrophoneAccessClasses } from "./MicrophoneAccessClasses";
import MicAccess1Url               from "./mic_access1.svg";

export const MicrophoneAccess: FC<BoxComponentProps> = React.memo(function MicrophoneAccess(props) {
  return <Box className={MicrophoneAccessClasses.Container}
              flexGrow={1}
              alignItems={"center"}
              justify={"center"}
              gap={"M"}
              container
              direction={"column"} {...props}>
    <img src={MicAccess1Url} className={"image1"} alt="Access microphone 1"/>
    <Box container alignItems={"center"} justify={"center"} direction={"column"} gap={"XXS"}
         className={MicrophoneAccessClasses.Empty}>
      <p>Your microphone is blocked</p>
      <p>Please go to your browser settings and allow Relcu to use your microphone</p>
    </Box>
  </Box>;
});
