import React                        from "react";
import { IconButtonProps }          from "../IconButton";
import IconButton                   from "../IconButton";
import { Icon }                     from "../index";

export interface PageToggleProps extends IconButtonProps {
  expand: boolean;
  visible?: boolean;
  onToggle?: () => void;
}

export const PageToggle = React.forwardRef((props: PageToggleProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    expand,
    onToggle,
    ...rest
  } = props;

  return (
    <IconButton
      onClick={onToggle}
      appearance={"text"}
      icon={expand ? <Icon type={"rc_expand_left"}/> : <Icon type={"rc_expand_right"}/>}
      {...rest}
    />
  );
});

PageToggle.displayName = "PageBody";

export default PageToggle;
