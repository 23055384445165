import { FC }             from "react";
import { useCallback }    from "react";
import React              from "react";
import { CommonClasses }  from "../../..";
import { Box }            from "../../Box";
import { FontIcon }       from "../../Icon";
import { useBaseField }   from "../BaseField";
import { BaseFieldProps } from "../BaseField";

export const UndoField: FC<BaseFieldProps> = React.memo(function UndoField(props) {
  const { input, meta } = useBaseField(props);
  const { children, ...p } = props;
  const handleReset = useCallback(() => {
    input.onChange(meta.initial);
  }, []);
  return <Box container gap={"XS"} {...p}>
    {children}
    {
      meta.dirty && <FontIcon type={"undo"} className={CommonClasses.ClickableIcon} onClick={handleReset}/>
    }
  </Box>;
});
