import React           from "react";
import { FC }          from "react";
import { Box }         from "../../../Box";
import { ChipsColors } from "../../../Chips";
import { Chips }       from "../../../Chips";
import { ICellProps }  from "../ICellProps";

export const UserStatusCell: FC<ICellProps> = React.memo(function UserStatusCell({
    getCellValue, rowData, dataKey, className, ...props
  }) {
    let text = getCellValue(rowData, dataKey);
    if (rowData.deactivated) {
      text = "deactivated";
    } else {
      text = text.replaceAll("_", " ");
    }
    const color = text === "deactivated" ? ChipsColors.Error
      : text === "busy" ? ChipsColors.Warning
        : text === "available" ? ChipsColors.Success
          : ChipsColors.Grey;
    text = text[ 0 ].toUpperCase() + text.substring(1);
    if (text == "Out of work") {
      text = "Out of office";
    }
    return <Box className={className} style={{ width: "fit-content" }}>
      <Chips color={color} label={text}/>
    </Box>;
  }
);
