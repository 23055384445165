import * as React    from "react";
import { useLatest } from "./useLatest";

export function useThrottleCallback<CallbackArguments extends any[]>(
  callback: (...args: CallbackArguments) => void,
  wait = 0,
  leading = false
): (...args: CallbackArguments) => void {
  const storedCallback = useLatest(callback);
  const prev = React.useRef(0);
  const trailingTimeout = React.useRef<ReturnType<typeof setTimeout>>();
  const clearTrailing = () =>
    trailingTimeout.current && clearTimeout(trailingTimeout.current);
  const deps = [wait, leading, storedCallback];

  // Reset any time the deps change
  React.useEffect(
    () => () => {
      prev.current = 0;
      clearTrailing();
    },
    deps
  );

  return React.useCallback(function () {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const rightNow = Date.now();
    const call = () => {
      prev.current = rightNow;
      clearTrailing();
      storedCallback.current.apply(null, args as any);
    };
    const current = prev.current;
    // leading
    if (leading && current === 0) {
      return call();
    }
    // body
    if (rightNow - current > wait) {
      if (current > 0) {
        return call();
      }
      prev.current = rightNow;
    }
    // trailing
    clearTrailing();
    trailingTimeout.current = setTimeout(() => {
      call();
      prev.current = 0;
    }, wait);
  }, deps);
}

export function useThrottle<State>(
  initialState: State | (() => State),
  wait?: number,
  leading?: boolean
): [State, React.Dispatch<React.SetStateAction<State>>] {
  const state = React.useState<State>(initialState);
  return [state[ 0 ], useThrottleCallback(state[ 1 ], wait, leading)];
}

export const throttle = <T extends any[]>(
  callback: (..._: T) => void,
  wait: number,
  immediate = false
): (..._: T) => void => {
  const next = () => {
    timeout = clearTimeout(timeout) as undefined;
    callback(...lastArgs);
  };
  let timeout: NodeJS.Timeout | undefined;
  let lastArgs: T;
  let initialCall = true;

  return (...args: T) => {
    lastArgs = args;
    if (immediate && initialCall) {
      initialCall = false;
      next();
    }
    if (timeout === void 0) {
      timeout = setTimeout(next, wait);
    }
  };
};
