import { useMemo }                    from "react";
import React                          from "react";
import { FC }                         from "react";
import { PhoneNumberFieldProps }      from "@relcu/ui";
import { BaseArrayField }             from "@relcu/ui";
import { IChoiceField }               from "../../../../../../types/ISchemas";
import { useSchemaField }             from "../../../../useSchemaField";
import PhoneNumbersDuplicateEditField from "./PhoneNumbersDuplicateEditField";
import "../duplicate-field-style.css";

export type PhoneNumbersDuplicateFieldProps = Partial<PhoneNumberFieldProps>

export const PhoneNumbersDuplicateField: FC<PhoneNumbersDuplicateFieldProps> = React.memo(function PhoneNumbersDuplicateField(props) {
  const { options } = useSchemaField<IChoiceField>("PhoneNumber", "type");
  return (
    <BaseArrayField
      {...props}
      view="write"
      readView={null}
      defaultValue={useMemo(() => [{ type: options[ 0 ]?.value }], [])}
      editView={<PhoneNumbersDuplicateEditField/>}
    />
  );
});
