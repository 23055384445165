import { useMemo }                                     from "react";
import { useRef }                                      from "react";
import { useState }                                    from "react";
import { useReactiveVar }                              from "@apollo/client";
import { useMutation }                                 from "@apollo/client";
import { useNavigate }                                 from "@relcu/react-router";
import { useParams }                                   from "@relcu/react-router";
import { omit }                                        from "@relcu/ui";
import { useAlert }                                    from "@relcu/ui";
import { useSource }                                   from "@relcu/ui";
import { EditorRef }                                   from "react-email-editor/dist/types";
import { fullScreenVar }                               from "../../../../../reactiveVars";
import { htmlToText }                                  from "../../../../../utils/helpers";
import { toFirstLower }                                from "../../../../../utils/helpers";
import { getObjectPath }                               from "../../../../../utils/layoutUtils";
import { CreateFlyerVariables }                        from "../__types__/CreateFlyer";
import { CreateFlyer }                                 from "../__types__/CreateFlyer";
import { GetFlyers_flyers_edges_node_previewSettings } from "../__types__/GetFlyers";
import { UpdateFlyerVariables }                        from "../__types__/UpdateFlyer";
import { UpdateFlyer }                                 from "../__types__/UpdateFlyer";
import { UPDATE_FLYER }                                from "../FlyerGenerationViewQueries";
import { CREATE_FLYER }                                from "../FlyerGenerationViewQueries";
import { ContentProps }                                from "./Content";

const requiredFields = {
  "title": "Flyer name",
  "content": "Content"
};
export function useContent(props: ContentProps) {
  const {
    flyer,
    replaceableFieldsSources
  } = props;
  const { $viewer, $object } = useSource();
  const [create] = useMutation<CreateFlyer, CreateFlyerVariables>(CREATE_FLYER);
  const [update] = useMutation<UpdateFlyer, UpdateFlyerVariables>(UPDATE_FLYER, {
    refetchQueries: ["GetFlyers"]
  });
  const [preview, setPreview] = useState(false);
  const [loadingEditor, setLoadingEditor] = useState(false);
  const emailEditorRef = useRef<EditorRef>(null);
  const isFullScreen = useReactiveVar(fullScreenVar);
  const isGenerating = useMemo(() => ($object.className == "Settings" || $object.__typename == "User"), [$object]);
  const isLO = useMemo(() => $viewer.role == "loan_officer", [$viewer]);
  const { success } = useAlert();
  const { flyerId, objectId: userObjectId } = useParams();
  const canUpdate = $object.className == "Settings" || $object.id === $viewer.id;
  const navigate = useNavigate();
  const initialValues = useMemo(() => {
    if (flyerId == "create") {
      const initial: any = {
        title: "New flyer",
        availableForType: "email",
        availableFor: [],
        applyTo: "lead",
        previewSettings: {
          user: $viewer
        }
      };

      if (replaceableFieldsSources?.length == 1) {
        initial.applyTo = toFirstLower(replaceableFieldsSources[ 0 ]);
      }
      return initial;
    }

    const existsData= { ...flyer, previewSettings: (flyer.previewSettings ? { ...flyer.previewSettings } : {}) as GetFlyers_flyers_edges_node_previewSettings };

    if (!existsData?.applyTo) {
      existsData.applyTo = "lead";
    }

    //todo remove this after SMS templating change
    if (existsData[ "availableForType" ]?.length) {
      existsData[ "availableForType" ] = existsData[ "availableForType" ][ 0 ] as any;
    }

    if (!isGenerating) {
      existsData.previewSettings.scope = $object;
    }

    if (isLO) {
      existsData.previewSettings.user = $viewer;
    }

    if (!Array.isArray(existsData?.availableFor)) {
      existsData.availableFor = [];
    }

    return existsData;
  }, [flyer, flyerId, replaceableFieldsSources, isGenerating, isLO]);
  //todo no need for memo

  const handleSubmit = async (values, form) => {
    const formValues: any = omit({ ...values }, ["id", "objectIcon", "objectId", "__typename", "ACL"]);
    const errors = Object.keys(requiredFields).reduce((previousValue, currentValue) => {
      if (!values[ currentValue ]) {
        previousValue[ currentValue ] = `${requiredFields[ currentValue ]} is required.`;
      }

      return previousValue;
    }, {});

    if (Object.keys(errors).length) {
      return errors;
    }

    const data: any = { ...omit(formValues, ["__typename"]) };

    if (data[ "availableForType" ]) {
      data[ "availableForType" ] = [data[ "availableForType" ]];
    }

    if (data.previewSettings) {
      const settings = {};
      Object.entries(omit(data.previewSettings, ["__typename"])).map(previewSetting => {
        if (previewSetting[ 1 ]) {
          settings[ previewSetting[ 0 ] ] = {
            link: previewSetting[ 1 ].id
          };
        }
      });
      data.previewSettings = settings;
    }

    if (values.id) {
      if (formValues.owner) {
        data[ "owner" ] = {
          link: formValues.owner.id
        };
      } else {
        delete data.owner;
      }

      await update({
        variables: {
          input: {
            id: values.id,
            fields: data
          }
        }
      });
      setTimeout(() => {
        form.reset(values);
      }, 0);
      success("Flyer successfully updated.");
    } else {
      if ($object.className !== "Settings") {
        data[ "owner" ] = {
          link: $viewer.id
        };
      }

      const { data: { createFlyer: { flyer: { objectId } } = {} } = {} } = await create({
        variables: {
          input: {
            fields: data
          }
        }
      });
      // todo redirect to result.data.createEmailTemplate.emailTemplate.objectId
      success("Flyer successfully created.");
      setTimeout(() => {
        form.reset(values);
      }, 0);
      setTimeout(() => {
        if ($object.className == "Settings") {
          navigate(`/settings/flyer-generation/${objectId}`);
        } else {
          navigate(`${getObjectPath({ __typename: "User", objectId: userObjectId })}/flyer-generation/${objectId}`);
        }
      }, 500);
    }
  };
  const handleFullScreen = () => {
    const isFullScreen = fullScreenVar();
    fullScreenVar(!isFullScreen);
  };
  const checkCanAction = (values) => {
    return !!htmlToText(values.content)?.trim() && !!(values?.title?.trim()) && values.applyTo?.length && values.availableForType?.length && values.availableFor?.length;
  };

  return {
    handleFullScreen,
    setLoadingEditor,
    checkCanAction,
    emailEditorRef,
    loadingEditor,
    initialValues,
    handleSubmit,
    isGenerating,
    isFullScreen,
    setPreview,
    canUpdate,
    flyerId,
    preview,
    isLO
  };
}
