import React                  from "react";
import { FC }                 from "react";
import { classNames }         from "../../../../utils/classNames";
import { Box }                from "../../../Box";
import { FontIcon }           from "../../../Icon";
import { IconType }           from "../../../Icon";
import { ICellProps }         from "../ICellProps";
import { Typography }         from "../../../Typography";
import { TypographyVariant }  from "../../../Typography";

export enum IconCellColors {
  Error = "error",
  Dark = "dark",
  Secondary = "secondary"
}
export interface IconCellProps extends ICellProps {
  color?: IconCellColors
}

export const IconCell: FC<IconCellProps> = React.memo(function IconCell({ getCellValue, className, color, defaultValue, rowData, dataKey, ...props }) {
  const type = getCellValue(rowData, dataKey) as IconType;
  const classes = classNames(IconCellClasses.IconCell, {
    [ IconCellClasses.IconCellError ]: color == IconCellColors.Error,
    [ IconCellClasses.IconCellSecondary ]: color == IconCellColors.Secondary,
    [ IconCellClasses.IconCellDark ]: color == IconCellColors.Dark
  });

  return <Box className={className}>
    {type &&
        <FontIcon className={classes} type={type}/>}

    {(!type && defaultValue) &&
        <Typography variant={TypographyVariant.span}>{defaultValue}</Typography>}
  </Box>;
});

export enum IconCellClasses {
  IconCell = "list-item-icon",
  IconCellError = "error",
  IconCellSecondary = "secondary",
  IconCellDark = "dark"
}
