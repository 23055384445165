import React                 from "react";
import { FC }                from "react";
import { FlexBasisProperty } from "../../../Box";
import { BoxComponentProps } from "../../../Box";
import { Box }               from "../../../Box";
import { Typography }        from "../../../Typography";
import { TypographySize }    from "../../../Typography";
import { TypographyColor }   from "../../../Typography";

export interface FieldContainerProps extends BoxComponentProps {
  label: string;
  space?: FlexBasisProperty<any>;
}

export const FieldContainer: FC<FieldContainerProps> = React.memo(function FieldContainer(props) {
  const { children, label, space, ...p } = props;
  return <Box container alignItems={"center"} className={FieldContainerClasses.FieldContainer} {...p}>
    <Typography
      color={TypographyColor.Secondary}
      size={TypographySize.TextSmall}
      style={{ flexBasis: space ?? "10%" }}>
      {label}
    </Typography>
    <Box container direction={"column"} gap={"XS"} style={{overflowX: "hidden"}} flex={1}>
      {children}
    </Box>
  </Box>;
});
export const enum FieldContainerClasses {
  FieldContainer = "field-container",
  Label = "field-container__label",
  Error = "field-container__label--error"
}
