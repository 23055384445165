import React                        from "react";
import { FC }                       from "react";
import { FieldArray }               from "@relcu/form";
import { HorizontalDivider }        from "@relcu/ui";
import { ButtonVariants }           from "@relcu/ui";
import { Button }                   from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { LeadMemberForm }           from "./LeadMemberForm";
import { LeadMemberSectionClasses } from "./LeadMemberSectionClasses";

export const LeadMemberEditSection: FC = React.memo(function LeadMemberEditSection(props) {
  const onClick = (fields) => {
    fields.push({});
  };

  return (
    <FieldArray name="members" initialValue={[{}]}>
      {({ fields }) => (
        <Box container direction={"column"}>
          <Button
            alignSelf={"end"}
            type="button" icon={"add"}
            onClick={() => onClick(fields)}
            variant={ButtonVariants.Ghost}>
            Add contact
          </Button>
          {fields.map((name, index) =>
            <Box direction={"column"} container key={index} className={LeadMemberSectionClasses.FormSection}>
              <LeadMemberForm name={name}/>
              {fields.length > 1 && (
                <Box container gap={"XXS"} direction={"column"}><Button
                  alignSelf={"end"}
                  type="button" icon={"remove"}
                  onClick={() => {
                    fields.pop();
                  }}
                  variant={ButtonVariants.Ghost}>
                  Remove contact
                </Button>
                  <HorizontalDivider bold={true}/>
                </Box>
              )}
            </Box>)}
        </Box>
      )}
    </FieldArray>
  );
});
