import { FC }             from "react";
import React              from "react";
import { SelectProps }    from "../../Input/Select";
import { BaseFieldProps } from "../BaseField";
import { BaseField }      from "../BaseField";
import ChoiceEditField    from "./ChoiceEditField";
import ChoiceReadField    from "./ChoiceReadField";

export type ChoiceFieldProps = BaseFieldProps & SelectProps;

export const ChoiceField: FC<ChoiceFieldProps> = React.memo(function ChoiceField(props) {
  return (
    <BaseField {...props} readView={<ChoiceReadField/>} editView={<ChoiceEditField/>}/>
  );
});
ChoiceField.defaultProps = {
  options: []
};
