import { FORM_ERROR }   from "@relcu/form";
import { useSource }    from "@relcu/ui";
import { useCallback }  from "react";
import React            from "react";
import { JqlFormProps } from "../../Jql/JqlForm";
import { JqlForm }      from "../../Jql/JqlForm";

export const UserForm = React.memo<JqlFormProps>(function UserForm(props) {
  const { $settings } = useSource();
  const passValidation = useCallback((values) => {
    const { containNumbers, containSpecialCharacters, includeUpAndLoCase, length } = $settings.passwordPolicy;
    let strongRegex;
    const errorMessageArr = [];
    if (includeUpAndLoCase) {
      strongRegex = strongRegex ? strongRegex + `(?=.*?[A-Z])(?=.*?[a-z])` : `(?=.*?[A-Z])(?=.*?[a-z])`;
      errorMessageArr.unshift("upper and lowercase");
    }
    if (containNumbers) {
      strongRegex = strongRegex ? strongRegex + `(?=.*?[0-9])` : `(?=.*?[0-9])`;
      errorMessageArr.unshift("a number");
    }
    if (containSpecialCharacters) {
      strongRegex = strongRegex ? strongRegex + `(?=.*?[!"#$%&*+,\\-)(.:;<=>?@^_|~])` : `(?=.*?[!"#$%&*+,\\-)(.:;<=>?@^_|~])`;
      errorMessageArr.unshift("a special character");
    }
    if (length) {
      strongRegex = strongRegex ? strongRegex + `.{${length},}$` : `.{${length},}$`;
      errorMessageArr.unshift(`minimum ${length} characters`);
    }
    strongRegex = strongRegex ? new RegExp(`^${strongRegex}`) : undefined;

    if (values.password && strongRegex && !strongRegex.test(values.password)) {
      return {
        password: `Password must contain ${errorMessageArr.join(", ")}.`
      };
    }
    if (values.password && values.password !== values.confirm) {
      return {
        confirm: "Passwords don't match."
      };
    }
    return {};
  }, []);
  const beforeSubmit = useCallback((values) => {
    delete values.confirm;
    if (!values.password) {
      delete values.password;
    }
  }, []);
  return (
    <JqlForm
      {...props}
      beforeSubmit={beforeSubmit}
      getErrorMessage={(error, errors) => errors[ FORM_ERROR ]}
      initialValues={{
        ...props.initialValues,
        phoneLines: props.initialValues?.phoneLines?.edges?.map(({ node }) => node)
      }}
      validate={(values) => {
        if (values.password || values.confirm) {
          return passValidation(values);
        }
      }}
    />
  );
});
