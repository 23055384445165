import React, { FC }      from "react";
import { useReactiveVar } from "@apollo/client";
import { Field }          from "@relcu/final-form";
import { IconButton }     from "@relcu/rc";
import { Stack }          from "@relcu/rc";
import { Icon }           from "@relcu/rc";
import { Typography }     from "@relcu/rc";
import { Page }           from "@relcu/rc";
import { Toolbar }        from "@relcu/rc";
import { Header }         from "@relcu/rc";
import { fullScreenVar }  from "../../../../../reactiveVars";
import { HeaderProps }    from "./ThreadHeader";
import "./header.css";

export const ComposeHeader: FC<HeaderProps> = React.memo(function ComposeHeader(props) {
  const { expand, onToggle } = props;
  const isFullScreen = useReactiveVar(fullScreenVar);

  return <Header>
    <Toolbar childrenRenderMode={"clone"} spacing={8} size={expand ? "md" : "sm"}
             style={{ padding: `0  16px 0 ${expand ? "16px" : "8px"}`, height: "56px", verticalAlign: "center" }}>
      <Stack.Item style={{
        width: 80,
        flexWrap: "nowrap",
        display: "inline-flex",
        alignItems: "center"
      }}>
        {
          !expand &&
          <Page.Toggle size={"md"} expand={expand} onToggle={onToggle}/>
        }
        <Typography variant={"base14"} color={"tertiary"}>Subject:</Typography>
      </Stack.Item>
      <Field name="template" subscription={{ value: true }}>
        {({ input: templateInput }) => (
          <Field name={"subject"}>
            {({ input }) => {
              return <input
                autoComplete="subject"
                disabled={!!templateInput.value}
                value={input.value}
                name={"subject"}
                onChange={input.onChange}
                style={{ fontSize: "16px", fontWeight: "500", flex: 1 }}/>;
            }}
          </Field>
        )}
      </Field>
      <IconButton
        circle
        appearance={"subtle"}
        icon={<Icon className={"header-icon"} type={isFullScreen ? "close_fullscreen" : "open_in_full"}/>}
        onClick={() => fullScreenVar(!isFullScreen)}/>
    </Toolbar>
  </Header>;
});
