import { IconButton }        from "@relcu/rc";
import { Icon }              from "@relcu/rc";
import React                 from "react";
import { FC }                from "react";
import { Form }              from "@relcu/form";
import { Tooltip }           from "@relcu/ui";
import { Ellipsis }          from "@relcu/ui";
import { SelectColors }      from "@relcu/ui";
import { SelectVariants }    from "@relcu/ui";
import { Alignment }         from "@relcu/ui";
import { SelectSizes }       from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import { CallOut }           from "@relcu/ui";
import { ChoiceField }       from "@relcu/ui";
import { snackbarVar }       from "../../../../reactiveVars";
import { isObject }          from "../../../../utils/helpers";
import { HeaderCallClasses } from "../HeaderCall/HeaderCallClasses";
import { useHeaderCall }     from "../HeaderCall/useHeaderCall";

export const Five9Call: FC<BoxComponentProps> = React.memo(function HeaderCall(props) {
  const call = useHeaderCall();
  const onCall = (values) => {
    const { current } = snackbarVar();
    if (values.to?.value) {
      const interactionApi = window.Five9.CrmSdk.interactionApi();
      interactionApi.click2dial({
        click2DialData: {
          clickToDialNumber: values.to.value.split("+1")[ 1 ]
        }
      });
    }
    snackbarVar({
      previous: current,
      current: "f9"
    });
  };

  return (
    <Box container flexBasis={200} flexShrink={0} flexGrow={0} className={HeaderCallClasses.HeaderCall} {...props}>
      <Form
        onSubmit={onCall}
        subscription={{
          initialValues: true,
          invalid: true,
          submitting: true,
          values: true
        }}
        initialValues={{
          to: call.to
        }}
      >
        {({ handleSubmit, invalid, submitting, values }) => {
          const disable = isObject(values.to) ? values.to.callOptOut : false;
          return (
            <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
              <CallOut
                gap={"XXXS"}
                verticalGap={"XXXXS"}
                avatar={
                  <Tooltip title={"Make a five9 call"} alignment={Alignment.Bottom}>
                    <IconButton
                      type={"submit"}
                      size={"md"}
                      icon={<Icon type={"five9"}/>}
                      disabled={invalid || submitting || disable}
                      color={"blue"}
                    />
                  </Tooltip>
                }
                header={null}
                content={
                  <ChoiceField
                    renderSelect={select => <Ellipsis from={20}>{call.getSelectTo(select)}</Ellipsis>}
                    label={"To"}
                    showLabel={false}
                    required={true}
                    options={call.toNumbers}
                    disabled={call.isDisabled}
                    name={"to"}
                    mode={"button"}
                    size={SelectSizes.Medium}
                    variant={SelectVariants.Ghost}
                    color={SelectColors.Grey}
                  />
                }
              />
            </form>
          );
        }}
      </Form>
    </Box>
  );
});
