export const MessageStatuses = [
  {
    "value": "queued",
    "label": "Queued"
  },
  {
    "value": "canceled",
    "label": "Canceled"
  },
  {
    "value": "in_progress",
    "label": "In progress"
  },
  {
    "value": "sent",
    "label": "Sent"
  },
  {
    "value": "processed",
    "label": "Processed"
  },
  {
    "value": "delivered",
    "label": "Delivered"
  },
  {
    "value": "undelivered",
    "label": "Undelivered"
  },
  {
    "value": "failed",
    "label": "Failed"
  },
  {
    "value": "queued",
    "label": "Queued"
  },
  {
    "value": "canceled",
    "label": "Canceled"
  },
  {
    "value": "in_progress",
    "label": "In progress"
  },
  {
    "value": "deferred",
    "label": "Deferred"
  },
  {
    "value": "bounce",
    "label": "Bounce"
  },
  {
    "value": "dropped",
    "label": "Dropped"
  }
];
