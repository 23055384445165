import { FC }             from "react";
import { useMemo }        from "react";
import React              from "react";
import { JsonViewProps }  from "@relcu/ui";
import { useSource }      from "@relcu/ui";
import { usePermissions } from "../../../AccessControl";
import { ClassForm }      from "../../Form/ClassForm";
import { useJqlQuery }    from "../../Jql";

export const DetailsView: FC<JsonViewProps> = React.memo<JsonViewProps>(function DetailsView(props) {
  const { $viewer, $object } = useSource();
  const object = $object.__typename ? $object : { __typename: props.className };
  const { canUpdate } = usePermissions(object);
  const sections = useMemo(() => {
    if (!canUpdate) {
      return props.sections.map(({ editable, ...s }) => ({ ...s, editable: false }));
    } else {
      return props.sections;
    }
  }, [props.sections, canUpdate]);
  const { data } = useJqlQuery(props.jql.query.get, {
    operationName: `${props.className}Query`,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
    returnPartialData: true,
    variables: {
      id: $object.id
    }
  });
  const initialValues = data?.[ props.jql.query.get.operation ];
  if (!initialValues) {
    return null;
  }
  const { id, objectId, objectName, role, __typename } = $viewer;
  const user = useMemo(() => ({
    id, objectId, objectName, role, __typename
  }), [id, objectId, objectName, role, __typename]);

  return (
    <ClassForm
      group={true}
      className={props.className}
      jql={props.jql}
      sections={sections}
      initialValues={initialValues}
      user={user}
    />
  );
});
