import React               from "react";
import { Box }             from "../../..";
import { CalendarClasses } from "../CalendarClasses";

export function WeeksDays({ labels }) {
  return (
    <Box container wrap={"nowrap"} justify={"space-between"} className={CalendarClasses.CalendarHeader}>
      {labels.map((day, index) => (
        <Box container justify={"center"} alignItems={"center"} key={index}
             className={CalendarClasses.CalendarDate}>{day.toUpperCase()}</Box>))}
    </Box>
  );
}
