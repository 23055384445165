export const enum RateClasses {
  Rate = "rate",
  Sticky = "rate--sticky",
  Active = "rate--active",
  Best = "rate--best",
  Open = "rate--open",
  RateSection = "rate-section",
  ScrollContainer = "rate__scroll-container",
  ScrollContainerTop = "rate__scroll-container--top",
  ScrollContainerBottom = "rate__scroll-container--bottom",
  Container = "rate__container",
  RateSectionHeader = "rate-section__header",
  SectionTitle = "rate-section__title",
  SectionSum = "rate-section__sum",
  RateActions = "rate__actions",
  OfferPreview = "rate__offer-preview",
  RateSummary = "rate-summary",
  RateSummaryItem = "rate-summary-item",
  RateSelect = "rate-select",
  PartnerSelect = "rate__partner-select",
  RateSelectHeader = "rate-select-header",
  TitleFeeHeader = "title-fee-header",
  RateSelectOpen = "rate-select-open",
  RateSelectBody = "rate-select-body",
  DividerVertical = "rate-divider--vertical",
  DividerHorizontal = "rate-divider--horizontal",
  RateSelectTitle = "rate-select-title",
  RateSelectSum = "rate-select-sum",
  RateSelectCustom = "rate-select-custom",
  RateSelectExtended = "rate-select-extended",
  EmptySelect = "empty-select",
  EmptySelectTitle = "empty-select-title",
  RateBody = "rate__body",
  RateHeader = "rate__header",
  RateHeaderCell = "rate__header_cell",
  RateItemReverse = "reverse",
  RateTitle = "rate-title",
  RateValue = "rate-value",
  RateValueContainer = "rate-value-container",
}
