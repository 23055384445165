import React                     from "react";
import { FC }                    from "react";
import { useBoxProps }           from "../../../Box";
import { ChoiceFieldProps }      from "../../../Field/ChoiceField";
import { ChoiceField }           from "../../../Field/ChoiceField";
import { FieldContainer }        from "../FieldContainer";

export interface EmailSelectFieldProps extends ChoiceFieldProps {
  label: string;
  validateTo?(value);
}

export const EmailSelectField: FC<EmailSelectFieldProps> = React.memo(function EmailSelectField(props) {
  const { label, options, validateTo, name, className, ...p } = props;

  return <FieldContainer label={label}  {...useBoxProps(p)}>
    <ChoiceField
      flex={1}
      optionLabel={"value"}
      optionKey={"value"}
      placeholder={"Select email"}
      name={name}
      options={options}
      {...p}
      validate={validateTo}
    />
  </FieldContainer>;
});

export const enum EmailSelectClasses {
  EmailMultiSelect = "email-multiselect",
  Title = "email-option__title",
  Active = "email-option__title--active",
  Addition = "email-option__addition"
}