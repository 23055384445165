import React              from "react";
import { FC }             from "react";
import { DateTime }       from "luxon";
import { Button }         from "../../Button";
import { ButtonVariants } from "../../Button";
import { EmptyList }      from "../../EmptyList";
import { IconType }       from "../../Icon";
import { FontIcon }       from "../../Icon";
import { ModalFooter }    from "../Modal";
import { ModalBody }      from "../Modal";
import { Modal }          from "../Modal";
import { ModalProps }     from "../Modal";

import { TimeWarningDialogClasses } from "./TimeWarningDialogClasses";

export interface TimeWarningDialogProps extends Partial<ModalProps> {
  date?: DateTime,
  name?: string,
  icon?: IconType,
  onClose?(e?),
  onCancel?(e?),
  onConfirm?(e?),
  title?,
  content?
}
export const TimeWarningDialog: FC<TimeWarningDialogProps> = React.memo(function ConfirmDialog(props) {
  return <Modal className={TimeWarningDialogClasses.Modal}
                title={<><FontIcon type={"warning"}/>Time warning</>}
                onClose={props.onClose} open={props.open} closeIcon={!!props.closeIcon}
                disableBackdropClick={true}>
    <ModalBody className={TimeWarningDialogClasses.Container} flexGrow={1} gap={"XS"} container direction={"column"}>
      <EmptyList className={TimeWarningDialogClasses.Body}
                 title={props.title || <>It
                   is <span>{props.date.toLocaleString(DateTime.TIME_SIMPLE)}</span> for {props.name}. Are you
                   sure you want
                   to
                   continue?</>}
                 content={props.content || "This may not be the best time to contact this person. If this urgent click on “Contact anyway” button below."}
                 icon={props.icon || "rc_sleep"}/>
    </ModalBody>
    <ModalFooter>
      <Button variant={ButtonVariants.Outline}
              onClick={props.onConfirm}>Contact anyway</Button>
      <Button onClick={props.onCancel}>Cancel</Button>
    </ModalFooter>
  </Modal>;
});
export default TimeWarningDialog;
