import React             from "react";
import { FC, useEffect } from "react";
import ReactDOM          from "react-dom";

export interface PortalProps {
  container?: HTMLElement
}

export const Portal: FC<PortalProps> = React.memo(function Portal(props) {
  const [container] = React.useState(() => {
    return props.container || document.createElement("div");
  });
  useEffect(() => {
    if (!props.container) {
      document.body.appendChild(container);
    }
    return () => {
      if (!props.container) {
        document.body.removeChild(container);
      }
    };
  }, [props.container]);
  return ReactDOM.createPortal(props.children, container);
});

