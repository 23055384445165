import React                      from "react";
import { FC }                     from "react";
import { ReactNode }              from "react";
import { classNames }             from "../../../utils/classNames";
import { Box }                    from "../../Box";
import { BoxComponentProps }      from "../../Box";
import { EmptyList }              from "../../EmptyList";
import { FontIcon }               from "../../Icon";
import { IconType }               from "../../Icon";
import { LinkColors }             from "../../Link";
import { Link }                   from "../../Link";
import { AccessibilityClasses }   from "./AccessibilityClasses";

export interface AccessibilityProps extends BoxComponentProps {
  title: string;
  icon: IconType | ReactNode
  redirectUrl: string;
  redirectLabel: string
  emptyContent: string
  emptyTitle: string
  containerProps?: {
    [key: string]: any
  }
}

export const Accessibilities: FC<AccessibilityProps> = React.memo(function Accessibilities(props) {
  const { title, className, redirectUrl, redirectLabel, children, icon, emptyContent, emptyTitle, containerProps, ...p } = props;
  const classes = classNames(AccessibilityClasses.Accessibilities, className);
  return <Box container className={classes} direction={"column"} gap={"XXXS"} flexShrink={0} {...p}>
    <Box container gap={"XXS"} flexBasis={30} alignItems={"center"} className={AccessibilityClasses.AccessibilitiesTitle}>
      {
        React.isValidElement(icon) ?
          icon :
          <FontIcon className={AccessibilityClasses.AccessibilitiesIcon} type={icon as IconType}/>
      }
      {title}
    </Box>
    {
      React.Children.count(children) == 0 ?
        <Box container flex={"1 1 auto"} justify={"center"} className={AccessibilityClasses.AccessibilitiesEmpty}>
          <EmptyList content={emptyContent}
                     title={emptyTitle}
                     alignSelf={"center"} small/>
        </Box> :
        <Box container direction={"column"} gap={"XXS"} {...containerProps}>
          {children}
        </Box>
    }
    <Link to={redirectUrl} color={LinkColors.Primary} alignSelf={"end"}>{redirectLabel}</Link>
  </Box>;
});

