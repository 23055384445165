import { useForm }                  from "@relcu/form";
import { OnChange }                 from "@relcu/form";
import { RadioGroupField }          from "@relcu/ui";
import React                        from "react";
import { FC }                       from "react";
import { useState }                 from "react";
import { useCallback }              from "react";
import { useMemo }                  from "react";
import { useContext }               from "react";
import { useField }                 from "@relcu/form";
import { ListItemText }             from "@relcu/ui";
import { ListRowColors }            from "@relcu/ui";
import { ListRow }                  from "@relcu/ui";
import { TypographyVariant }        from "@relcu/ui";
import { StepInterface }            from "@relcu/ui";
import { useReactiveVar }           from "@apollo/client";
import { FormSpy }                  from "@relcu/form";
import { FieldSpy, ListItemImage }  from "@relcu/ui";
import { ButtonColors, CheckGroup } from "@relcu/ui";
import { AlertColors }              from "@relcu/ui";
import { Typography }               from "@relcu/ui";
import { QueueStat }                from "@relcu/ui";
import { SwitchField }              from "@relcu/ui";
import { Queue }                    from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { ChoiceField }              from "@relcu/ui";
import { TextField }                from "@relcu/ui";
import { Alert }                    from "@relcu/ui";
import { soundVar }                 from "../../../../../../../../reactiveVars";
import { DistributionQueueContext } from "../../../DistributionQueueDialog";
import { StepContainer }            from "@relcu/ui";
import { StepHeader }               from "@relcu/ui";
import { StepRow }                  from "@relcu/ui";
import { StepSection }              from "@relcu/ui";
import { StepBody }                 from "@relcu/ui";
import "./appearance.css";

export const Appearance: FC<StepInterface> = React.memo(function Appearance(props) {
  const { title, description, step } = props;
  const { view, notCompleteAppearanceVar } = useContext(DistributionQueueContext);
  const notCompleteAppearance = useReactiveVar(notCompleteAppearanceVar);
  const errorName = useMemo(() => notCompleteAppearance[ "name" ], [notCompleteAppearance]);
  const form = useForm();
  function cleanAppearanceError(name) {
    const notCompleterAppearance = notCompleteAppearanceVar();
    const errors = { ...notCompleterAppearance };
    delete errors?.[ name ];
    notCompleteAppearanceVar(errors ?? {});
  }
  return <StepContainer>
    <StepHeader
      step={step}
      title={title}
      description={description}
      edit={false}/>
    <StepBody gap={"XS"}>
      <StepSection title={"Queue name"}>
        <FieldSpy name={"view.name"} subscription={{ value: true }} onChange={() => cleanAppearanceError("name")}/>
        <StepRow>
          <TextField name={"view.name"} flexBasis={"30%"} placeholder={"Type name here"}/>
        </StepRow>
      </StepSection>
      {
        errorName &&
        <Alert stretch variant={AlertColors.Error}>
          {errorName}
        </Alert>
      }
      <FormSpy subscription={{ values: true }}>
        {({ values: { strategy } }) => {
          if (strategy == "round_robin") {
            return null;
          }
          return <StepSection title={"Appearance"}>
            <Box container gap={"XXXL"} className={AppearanceClasses.AppearanceSection}>
              <Box container direction={"column"} justify={"center"} gap={"XS"} flexBasis={`40%`}>
                <ChoiceField
                  label={"Icon"}
                  name={"view.icon"}
                  options={view?.icon?.options ?? []}
                />
                <ChoiceField
                  label={"Button color"}
                  name={"view.color"}
                  options={view?.color?.options ?? ["success", "primary", "error", "info", "warning"]}
                />
              </Box>
              <Box container justify={"center"} flexBasis={`50%`}>
                <FormSpy subscription={{ values: true }}>
                  {({ values: { view: { icon, color } } }) => {
                    return <Queue
                      collapsed={false}
                      countVisible={true}
                      showCoolDownTime={false}
                      showStatIcon={false}
                      isLimitExceeded={false}
                      lastTakenAt={""}
                      coolDownPeriod={0.6}
                      availableCount={20}
                      state={"available"}
                      animate={0}
                      icon={icon ?? "rc_street_intersection"}
                      color={color ?? ButtonColors.Primary}
                    />;
                  }}
                </FormSpy>
              </Box>
            </Box>
          </StepSection>;
        }}
      </FormSpy>
      <StepSection title={"Distribution type"}>
        <Box container gap={"XXXL"} className={AppearanceClasses.AppearanceSection}>
          <RadioGroupField name={"strategy"} options={[
            {
              value: "pull",
              label: "Pull"
            },
            {
              value: "round_robin",
              label: "Round robin"
            }
          ]}/>
        </Box>
      </StepSection>
      <OnChange name={"strategy"} children={(value, previous) => {
        if (value == "round_robin") {
          form.change("notify", true);
          form.change("notifyMobile", false);
        }
      }}/>
      <FormSpy subscription={{ values: true }}>
        {({ values: { strategy } }) => {
          if (strategy == "round_robin") {
            return null;
          }
          return <>
            <StepSection title={"Lead count"}>
              <Box container gap={"XXXL"} className={AppearanceClasses.AppearanceSection}>
                <Box container direction={"column"} justify={"center"} gap={"XS"} flexBasis={`40%`}>
                  <CheckGroup>
                    <SwitchField placeholder={"Daily cap"} name={"view.showDailyCap"}/>
                    <SwitchField placeholder={"Weekly cap"} name={"view.showWeeklyCap"}/>
                    <SwitchField placeholder={"Monthly cap"} name={"view.showMonthlyCap"}/>
                    <SwitchField placeholder={"In Queue"} name={"view.showLeadCount"}/>
                    <SwitchField placeholder={"Show cool down time"} name={"view.showCoolDownTime"}/>
                  </CheckGroup>{/*todo make SwitchGroupField not for this case jus for have*/}
                </Box>
                <Box container justify={"center"} flexBasis={`50%`}>
                  <FormSpy subscription={{ values: true }}>
                    {({ values: { view: { showDailyCap, showWeeklyCap, showMonthlyCap, showLeadCount, strategy, icon, color } } }) => {
                      return <Queue
                        collapsed={false}
                        countVisible={true}
                        showCoolDownTime={false}
                        showStatIcon={false}
                        isLimitExceeded={false}
                        lastTakenAt={""}
                        coolDownPeriod={0.6}
                        availableCount={20}
                        state={"available"}
                        animate={0}
                        statView={true}
                        icon={"rc_street_intersection"}
                        color={ButtonColors.Primary}
                        stats={<QueueStat>
                          {
                            showDailyCap &&
                            <QueueStat.Item label={"Daily"} tooltip={`Available for today: 5`}
                                            value={`3/5`} disabled={false}/>
                          }
                          {
                            showWeeklyCap &&
                            <QueueStat.Item label={"Weekly"} tooltip={`Available for this week: 65`}
                                            value={`37/65`} disabled={false}/>
                          }
                          {
                            showMonthlyCap &&
                            <QueueStat.Item label={"Monthly"} tooltip={`Available for this month: 390`}
                                            value={`0/390`} disabled={false}/>
                          }
                          {
                            showLeadCount &&
                            <QueueStat.Item label={"In queue"} tooltip={`In queue: 150`} value={"150"}/>
                          }
                        </QueueStat>}
                      />;
                    }}
                  </FormSpy>
                </Box>
              </Box>
            </StepSection>
            <SoundSection/>
          </>;
        }}
      </FormSpy>
    </StepBody>
  </StepContainer>;
});
const SoundRow: FC<{ name: "phoneLeadSound" | "webLeadSound" }> = React.memo(({ name }) => {
  const { getFieldOptions } = useContext(DistributionQueueContext);
  const sound = soundVar();
  const [playing, setPlaying] = useState(false);
  const soundOptions = getFieldOptions("DistributionQueue", `view.${name}.sound`);
  const repeatOptions = [{ label: "No repeat", value: 0 }, { label: "2 times", value: 2 }, { label: "5 times", value: 5 }];
  const soundFiled = useField(`view.${name}.sound`);
  const repeatField = useField(`view.${name}.repeat`);

  const onPreview = useCallback(async () => {
    if (playing) {
      await sound.pause(soundFiled.input.value);
      setPlaying(false);
    } else {
      setPlaying(true);
      await sound.play(soundFiled.input.value, repeatField.input.value);
      setPlaying(false);
    }
  }, [soundFiled, repeatField, sound,playing]);
  return <ListRow
    style={{ background: "white" }}
    gap={"XXS"}
    color={ListRowColors.White} container
    justify={"space-between"}>
    <ListItemText
      tiny={true}
      text={
        <Typography variant={TypographyVariant.span}>
          {name === "phoneLeadSound" ? "Phone" : "Web"} Lead
        </Typography>
      }
      flexGrow={1}
      flexShrink={0}
      flexBasis={"100px"}
    />
    <ListItemText
      tiny={true}
      text={
        <ChoiceField
          // label={"Sound"}
          name={`view.${name}.sound`}
          options={soundOptions}
        />
      }
      flexGrow={1}
      flexShrink={0}
      flexBasis={"100px"}
    />
    <ListItemText
      tiny={true}
      text={
        <ChoiceField
          // label={"Sound"}
          name={`view.${name}.repeat`}
          options={repeatOptions}
        />
      }
      flexGrow={1}
      flexShrink={1}
      flexBasis={"100px"}
    />
    <ListItemImage
      flexGrow={0}
      onClick={onPreview}
      flexShrink={0}
      flexBasis={"50px"}
      color={"primary"}
      icon={playing ? "pause_circle_filled" : "play_circle_filled"}/>

  </ListRow>;
});
const SoundSection: FC = React.memo(() => {
  return <StepSection title={"Sound"}>
    <Box gap={"XXS"} direction={"column"} container flexGrow={1}>
      <ListRow
        gap={"XXS"}
        color={ListRowColors.White} container
        justify={"space-between"}>
        <ListItemText
          tiny={true}
          text={"Type"}
          flexGrow={1}
          flexShrink={0}
          flexBasis={"108px"}
        />
        <ListItemText
          tiny={true}
          text={"Sound"}
          flexGrow={1}
          flexShrink={0}
          flexBasis={"108px"}
        />
        <ListItemText
          tiny={true}
          text={"Repeat the sound"}
          flexGrow={1}
          flexShrink={1}
          flexBasis={"100px"}
        />
        <ListItemText

          alignItems={"end"}
          tiny={true}
          text={"Preview"}
          flexGrow={0}
          flexShrink={0}
          flexBasis={"50px"}

        />
      </ListRow>
      <SoundRow name={"webLeadSound"}/>
      <SoundRow name={"phoneLeadSound"}/>
    </Box>
  </StepSection>;
});
const enum AppearanceClasses {
  AppearanceSection = "section--appearance"
}
