import React                from "react";
import { FC }               from "react";
import { HTMLAttributes }  from "react";
import { TypographySize }  from "@relcu/ui";
import { TypographyColor } from "@relcu/ui";
import { Box }             from "@relcu/ui";
import { Typography }      from "@relcu/ui";
import { CommonClasses }   from "@relcu/ui";
import { classNames }      from "@relcu/ui";
import { FontIcon }        from "@relcu/ui";

export interface FocusSectionProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  enabled: boolean;
  dragHandleClassName?: string;
}

export const FocusSection: FC<FocusSectionProps> = React.memo(function FocusSection(props) {
  const { title, enabled, dragHandleClassName, children, className, ...p } = props;
  const classes = classNames("focus-view__section",className);

  return <div className={classes} {...p}>
    <FontIcon
      type={"drag_indicator"}
      style={{ fontSize: "var(--typography-icon-size)", cursor: "move", flex: "0 0 10px" }}
      className={classNames(dragHandleClassName, CommonClasses.ClickableIcon)}/>
    <Typography flexBasis={400} flexShrink={1}>{title}</Typography>
    <Box container flex={1} flexShrink={2} justify={"center"}>
      {
        !enabled &&
        <Typography color={TypographyColor.Secondary} size={TypographySize.TextSmall}>
          Section hidden
        </Typography>
      }
    </Box>
    {children}
  </div>;
});

FocusSection.defaultProps = {
  dragHandleClassName: "column-drag-handle"
};
