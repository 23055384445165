import { HTMLAttributes }      from "react";
import React                   from "react";
import { FC }                  from "react";
import { CommonClasses }       from "../../theme/classes";
import { classNames }          from "../../utils/classNames";
import { Box }                 from "../Box";
import { IconType }            from "../Icon";
import { FontIcon }            from "../Icon";
import { TypographyLine }      from "../Typography";
import { TypographyWeight }    from "../Typography";
import { TypographyColor }     from "../Typography";
import { TypographySize }      from "../Typography";
import { Typography }          from "../Typography";
import { NotificationClasses } from "./NotificationClasses";

export enum NotificationIconColor {
  Secondary = "secondary",
  Tertiary = "tertiary",
  Primary = "primary",
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
  White = "white"
}

export interface NotificationHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  icon: IconType,
  color?: NotificationIconColor;
  inApp?: boolean
}
export const NotificationHeader: FC<NotificationHeaderProps> = React.memo(function ({ icon, title, color, children, inApp = false }) {
  const classes = classNames({
    [ NotificationClasses.Secondary ]: color == NotificationIconColor.Secondary,
    [ NotificationClasses.Primary ]: (color == NotificationIconColor.Primary),
    [ NotificationClasses.Success ]: (color == NotificationIconColor.Success),
    [ NotificationClasses.Error ]: (color == NotificationIconColor.Error),
    [ NotificationClasses.Warning ]: (color == NotificationIconColor.Warning),
    [ NotificationClasses.Info ]: (color == NotificationIconColor.Info),
    [ NotificationClasses.White ]: (color == NotificationIconColor.White),
    [ NotificationClasses.Icon ]: !inApp,
    [ NotificationClasses.IconL ]: inApp
  });

  return <Box container gap={inApp ? "XXS" : "XXXS"} alignItems={"center"}>
    <FontIcon className={classes} type={icon}/>
    <Typography lineHeight={inApp && TypographyLine.LineBody1}
                color={inApp && TypographyColor.White}
                size={inApp ? TypographySize.Subtitle : TypographySize.TextSmall}
                weight={inApp ? TypographyWeight.Medium : TypographyWeight.Regular}
                className={CommonClasses.FlexEllipsis}>{title}</Typography>
    <Box flex={1}/>
    {children}
  </Box>;
});