import React                     from "react";
import { FC }                    from "react";
import { useState }              from "react";
import { useMemo }               from "react";
import { useContext }            from "react";
import { FormSpy }               from "@relcu/final-form";
import { useForm }               from "@relcu/final-form";
import { gql }                   from "@apollo/client";
import { useMutation }           from "@apollo/client";
import { useAlert }              from "@relcu/ui";
import { useSource }             from "@relcu/ui";
import { FormField }             from "@relcu/rc";
import { Icon }                  from "@relcu/rc";
import { Button }                from "@relcu/rc";
import { Form }                  from "@relcu/rc";
import { transformNameToLabel }  from "../../../../../../utils/helpers";
import { toFirstLower }          from "../../../../../../utils/helpers";
import { PreviewSettings }       from "../../../../../Generation";
import { PointerListPicker }     from "../../../../Field/PointerListPicker";
import { PointerPicker }         from "../../../../Field/PointerPicker";
import { PointerPickerProps }    from "../../../../Field/PointerPicker";
import { MailTemplateContext }   from "../Content";
import { SendTestMailVariables } from "./__types__/SendTestMail";
import { SendTestMail }          from "./__types__/SendTestMail";

export interface TemplatePreviewSettingsProps {
  screenType: "mobile" | "desktop";
  onScreenTypeChange(type: "mobile" | "desktop");
}

export const TemplatePreviewSettings: FC<TemplatePreviewSettingsProps> = React.memo(function TemplatePreviewSettings(props) {
  const [send] = useMutation<SendTestMail, SendTestMailVariables>(SEND_TEST_MAIL);
  const { replaceableFieldsSources } = useContext(MailTemplateContext);
  let optionalFields = useMemo(() => replaceableFieldsSources.filter(r => r != "Contact"), [replaceableFieldsSources]);
  const [email, setEmail] = useState();
  const { $viewer } = useSource();
  const form = useForm();
  const { success, error } = useAlert();
  const onSendTestMail = async () => {
    const { subject, content, scope, from, loanProposal } = form.getState().values;
    try {
      await send({
        variables: {
          input: {
            scope,
            subject: subject,
            to: email,
            from: from,
            loanProposalId: loanProposal,
            text: content
          }
        }
      });
      success("Email sent.");
    } catch (e) {
      error(e.message);
    }
  };
  const userFilters = useMemo(() => {
    if ($viewer.role == "loan_officer" || ($viewer.role == "team_manager" && !$viewer.team)) {
      return {
        objectId: { equalTo: $viewer.objectId }
      };
    } else if ($viewer.role == "team_manager") {
      return {
        team: { equalTo: $viewer.team }
      };
    }
    return {};
  }, [$viewer.role]);
  return <PreviewSettings divided>
    <PreviewSettings.Section>
      <PreviewSettings.Title>
        Preview settings
      </PreviewSettings.Title>
      <FormField<PointerPickerProps>
        component={PointerListPicker}
        name="to"
        label="To"
        errorMessage={false}
        initialValue={[]}
        properties={{
          style: { width: "100%" },
          labelKey: "objectName",
          valueKey: "objectId",
          fields: ["primaryEmail"],
          targetClass: "Contact"
        }}
      />
      <FormField<PointerPickerProps>
        component={PointerPicker}
        name="from"
        label="From"
        errorMessage={false}
        properties={{
          cleanable: true,
          style: { width: "100%" },
          labelKey: "objectName",
          valueKey: "objectId",
          filters: userFilters,
          targetClass: "User"
        }}
      />
      {
        <FormSpy subscription={{ values: true, initialValues: true }}>
          {
            ({ values, initialValues }) => {
              return optionalFields.filter(r => values.applyTo.includes(toFirstLower(r))).map((field, index) => {
                const name = toFirstLower(field);
                return <FormField<PointerPickerProps>
                  key={index}
                  component={PointerPicker}
                  name={name == "loanProposal" ? name : "scope"}
                  label={transformNameToLabel(field)}
                  errorMessage={false}
                  properties={{
                    cleanable: true,
                    style: { width: "100%" },
                    labelKey: "objectName",
                    valueKey: name == "loanProposal" ? "objectId" : "id",
                    targetClass: field
                  }}
                />;
              });
            }
          }
        </FormSpy>
      }
    </PreviewSettings.Section>
    <PreviewSettings.Section>
      <PreviewSettings.Title>
        Send test email
      </PreviewSettings.Title>
      <Form.Group>
        <Form.ControlLabel htmlFor={"recipient"}>Recipient</Form.ControlLabel>
        <Form.Control onChange={setEmail} name="recipient" id={"recipient"}/>
      </Form.Group>
      <Button disabled={!email} onClick={onSendTestMail} appearance={"text"} startIcon={<Icon type="email"/>}>
        SEND TEST EMAIL
      </Button>
    </PreviewSettings.Section>
  </PreviewSettings>;
});

export const SEND_TEST_MAIL = gql`
  mutation SendTestMail($input:MailSendInput){
    sendTestEmail(input:$input){
      id
    }
  }
`;
