import React                    from "react";
import { InputState }           from "../../Input/Input";
import { MultiInput }           from "../../Input/MultiInput";
import { useConsumeField }      from "../BaseField";
import { MultiInputFieldProps } from "./MultiInputField";

export const MultiInputEditField = React.memo(function MultiInputEditField(props) {
  let { input: { targetClassName, ...input }, meta: { touched, error, submitError } } = useConsumeField<MultiInputFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  return (
    <MultiInput
      {...input}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
    />
  );
});
export default MultiInputEditField;
