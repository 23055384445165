export const enum MsgClasses {
  Msg = "msg",
  MsgAvatar = "msg__avatar",
  MsgContent = "msg__content",
  MsgContentError = "msg__content--error",
  MsgDate = "msg__date",
  MsgCheckBox = "msg__checkbox",
  MsgCheckBoxShow = "msg__checkbox_show",
  MsgText = "msg__text",
  MsgReverse = "msg__text--reverse",
  MsgInfo = "msg__info",
  MsgStatus = "msg__status",
  MsgHeader = "msg__header",
  MsgError = "msg__error",
  MsgResend = "msg__resend",
  MsgToggle = "msg__toggle",
  MsgOpen = "msg--open",
  Error = "msg--error",
}
