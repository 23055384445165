import React                from "react";
import { FC }               from "react";
import { FontIcon }         from "../../Icon";
import { BaseFieldClasses } from "../BaseField";
import { NumberField }      from "../NumberField";
import { NumberFieldProps } from "../NumberField";

export const CurrencyField: FC<NumberFieldProps> = React.memo(function CurrencyField(props) {
  return (
    <NumberField
      {...props}
      precision={props.precision ?? 0}
      icon={<FontIcon className={BaseFieldClasses.ReadFieldIcon} type={"attach_money"}/>}
    />
  );
});
