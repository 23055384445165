import { QueryResult }          from "@apollo/client";
import { QueryHookOptions }     from "@apollo/client";
import { IQueryBuilderOptions } from "@relcu/gql-query-builder";
import React           from "react";
import { useJqlQuery } from "./useJqlQuery";

export const JqlQuery = React.memo<JqlQueryProps>(function JqlQuery(props) {
  const { query, children, ...options } = props;
  const result = useJqlQuery(query, options);
  if(typeof children === "function"){
    return result ? children(result) : null;
  }
  return null;
});

export interface JqlQueryProps extends Omit<QueryHookOptions, "query"> {
  query: IQueryBuilderOptions | IQueryBuilderOptions[],
  operationName: string
  children?: (result: QueryResult) => JSX.Element | null;
}
