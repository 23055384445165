import { Link }                                             from "@relcu/react-router";
import { CommonClasses }                                    from "@relcu/ui";
import React                                                from "react";
import { TimelineItem }                                     from "@relcu/ui";
import { TypographyColor }                                  from "@relcu/ui";
import { Typography }                                       from "@relcu/ui";
import { TimelineItemSubject }                              from "@relcu/ui";
import { TimelineItemIconColors }                           from "@relcu/ui";
import { TimelineItemIcon }                                 from "@relcu/ui";
import { DistributionActivity as DistributionActivityType } from "../../../../__types__/DistributionActivity";

export interface TakeLeadActivityProps extends DistributionActivityType {
  createdAt: string;
}

export const DistributionActivity = React.memo<TakeLeadActivityProps>(function DistributionActivity(props) {
  const { distributedTo, distributionSubject, distributionQueue, createdAt } = props;
  const to = distributedTo ? distributedTo.objectName : "Unknown";
  const isPush = distributionQueue.strategy == "round_robin";
  const queue = distributionQueue ? distributionQueue.objectName : "Unknown";
  return isPush ?
    <TimelineItem
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"rc_lead"}/>}
      primary={<TimelineItemSubject>
        {
          distributionQueue?.objectId ?
            <Typography>{queue}</Typography>
            :
            <Typography>{to}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>distributed</Typography>
        {distributionSubject?.objectId ?
          <Link to={`/lead/${distributionSubject.objectId}`}>
            <Typography
              className={CommonClasses.ClickableIcon}>{distributionSubject.objectName || "Unknown"}</Typography>
          </Link>
          :
          <Typography>{distributionSubject?.objectName || "Unknown"}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>to</Typography>
        {distributedTo?.objectId ?
          <Link to={`/user/${distributedTo.objectId}/lead`}>
            <Typography className={CommonClasses.ClickableIcon}>{to}</Typography>
          </Link>
          :
          <Typography>{to}</Typography>}
      </TimelineItemSubject>}
      creationDate={createdAt}
    /> :
    <TimelineItem
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"rc_lead"}/>}
      primary={<TimelineItemSubject>
        {
          distributedTo?.objectId ?
            <Link to={`/user/${distributedTo.objectId}/lead`}>
              <Typography className={CommonClasses.ClickableIcon}>{to}</Typography>
            </Link>
            :
            <Typography>{to}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>took</Typography>
        {distributionSubject?.objectId ?
          <Link to={`/lead/${distributionSubject.objectId}`}>
            <Typography
              className={CommonClasses.ClickableIcon}>{distributionSubject.objectName || "Unknown"}</Typography>
          </Link>
          :
          <Typography>{distributionSubject?.objectName || "Unknown"}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>from</Typography>
        <Typography>{queue}</Typography>
      </TimelineItemSubject>}
      creationDate={createdAt}
    />;
});
