import { Container }           from "@relcu/rc";
import { FC }                  from "react";
import React                   from "react";
import { BulkItemPreview }     from "../BulkItemContent/BulkItemPreview";
import { BulkItemStatistics }  from "../BulkItemContent/BulkItemStatistics";
import { useBulkContent }      from "../BulkItemContent/useBulkContent";
import { BulkHeader }          from "../Header/BulkHeader";
import { MailSection }         from "../MailSection/MailSection";
import { MailMessagesClasses } from "../Message/MailMessages";

export const Bulk: FC<any> = React.memo(function Bulk(props) {
  const { className, expand, onToggle, ...p } = props;
  const {
    bulkConversation, onBehalf, stats, conversations, columns, actions,
    selectedItem, slide, count, onCancelAll, page, onPage, pageCount, onFilter, status, loading,
    cancelLoading,
    skip
  } = useBulkContent();
  return <Container>
    <BulkHeader expand={expand} onToggle={onToggle}
                organizerName={bulkConversation.lastSender.party.objectName} subject={bulkConversation.subject}/>
    <MailSection container direction={"column"} flex={1} className={MailMessagesClasses.Body}>
      {
        !selectedItem
          ? <BulkItemStatistics onBehalf={onBehalf} statistics={stats} status={status} cancelLoading={cancelLoading}
                                onCancelAll={onCancelAll}
                                onFilter={onFilter} actions={actions} data={conversations} columns={columns}
                                page={page} onPage={onPage} pageCount={pageCount} count={count} loading={loading}/>
          :
          <BulkItemPreview slide={slide} count={count} status={status} selectedItem={selectedItem} data={conversations}
                           skip={skip}/>
      }
    </MailSection>
  </Container>;
});
