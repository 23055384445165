import React                              from "react";
import { FC }                             from "react";
import { useContext }                     from "react";
import { useMemo }                        from "react";
import { useEffect }                      from "react";
import { useQuery }                       from "@apollo/client";
import { Spinner }                        from "@relcu/ui";
import { Root as IRoot }                  from "../../graph/__types__/Root";
import { SubscribeUnreadNotifications }   from "../../graph/__types__/SubscribeUnreadNotifications";
import { ClientContext }                  from "../../Client";
import { SUBSCRIBE_UNREAD_NOTIFICATIONS } from "../../graph/operations.graphql";
import { ROOT }                           from "../../graph/operations.graphql";
import { SubscriptionEvent }              from "../../types/graphql-global-types";
import { useSubscribeMailboxes }          from "../useMailboxes";

export const Root: FC = React.memo(function Root(props) {
  const { data: { viewer } = {}, loading, error, subscribeToMore: rootSubscribeToMore } = useQuery<IRoot>(ROOT, {
    returnPartialData: true,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only"
  });

  const userId = useMemo(() => viewer?.user.id, [viewer?.user.id]);
  useEffect(() => {
    const DD = (window as any).DD_RUM;
    const DD_LOGS = (window as any).DD_LOGS;
    if (DD || DD_LOGS) {
      if (viewer?.user) {
        const { objectId, objectName, email, role } = viewer?.user;
        const user = {
          id: objectId,
          name: objectName,
          email: email,
          role: role
        };
        DD && DD.setUser(user);
        DD_LOGS && DD_LOGS.setUser(user);
      }
    }
    return () => {
      if (DD || DD_LOGS) {
        DD.clearUser();
        DD_LOGS.clearUser();
      }
    };
  }, [userId]);

  useEffect(() => rootSubscribeToMore<SubscribeUnreadNotifications>({
    document: SUBSCRIBE_UNREAD_NOTIFICATIONS,
    updateQuery(root, { subscriptionData: { data: { notifications: { node, event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
        case SubscriptionEvent.ENTER:
          return {
            ...root,
            notifications: {
              ...root.notifications,
              edges: [
                {
                  __typename: "NotificationEdge",
                  node
                },
                ...root.notifications.edges
              ]
            }
          };
        case SubscriptionEvent.LEAVE:
          return {
            ...root,
            notifications: {
              ...root.notifications,
              edges: root.notifications.edges.filter(e => e.node.id !== node.id)
            }
          };
      }
    }
  }), []);

  return (loading || !!error) ? <Spinner/> : (<ViewerRoot viewer={viewer}>{props.children}</ViewerRoot>);
});

export const ViewerRoot: FC<{ viewer }> = React.memo(function TwRoot(props) {
  const context = useContext(ClientContext);
  useEffect(() => context.tw.setup(), []);
  useSubscribeMailboxes(props.viewer);
  return <>{props.children}</>;
});
