import { Interval } from "luxon";
import { DateTime } from "luxon";
export const usTimeZones = [
  "America/Adak",
  "America/Anchorage",
  "America/Boise",
  "America/Chicago",
  "America/Denver",
  "America/Detroit",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Los_Angeles",
  "America/Menominee",
  "America/Metlakatla",
  "America/New_York",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Phoenix",
  "America/Sitka",
  "America/Yakutat",
  "America/Shiprock",
  "Pacific/Honolulu"
];
export const getValidTimezones = (startTime:string,endTime:string)=>{
  const now =  DateTime.local()
  const [startHour, startMinute] = String(startTime).split(":");
  const [endHour, endMinute] = String(endTime).split(":");
  return usTimeZones.filter((timezone) => {
    const time = now.setZone(timezone);
    const start = DateTime.fromObject({
      day: time.day,
      month: time.month,
      year: time.year,
      hour: parseInt(startHour) ?? 8,
      minute: parseInt(startMinute) ?? 0,
      second: 0
    }, { zone: timezone });
    const end = DateTime.fromObject({
      day: time.day,
      month: time.month,
      year: time.year,
      hour: parseInt(endHour) ?? 21,
      minute: parseInt(endMinute) ?? 0,
      second: 0
    }, { zone: timezone });

    return Interval.fromDateTimes(start, end).contains(time);
  });
}