import { useMemo }   from "react";
import { UseACL }    from "./useACL";
import { usePolicy } from "./usePolicy";

export function usePermissions(object: UseACL) {
  const policy = usePolicy();
  return useMemo(() => ({
    canRead: policy.canRead(object),
    canFind: policy.canRead(object),
    canCreate: policy.canCreate(object),
    canUpdate: policy.canUpdate(object),
    canDelete: policy.canDelete(object)
  }), [policy, object]);
}
