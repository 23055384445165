import React       from "react";
import { useStat } from "./useStat";

export interface StatProps {
  id?: string;
  children: (props: ReturnType<typeof useStat>) => React.ReactNode
}

export const Stat = React.memo<StatProps>(function Stat(props) {
  const stat = useStat(props.id);
  return (
    <>
      {props.children(stat)}
    </>
  );
});
