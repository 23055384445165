import React             from "react";
import { Icon }          from "../../index";
import { Tooltip }       from "../../index";
import { Whisper }       from "../../index";
import { Cell }          from "../../TableHorizontal";
import { CellProps }     from "../../TableHorizontal/Cell";
import { useClassNames } from "../../utils";

export interface ImageCellProps extends CellProps {
  src: string;
  imageHelperText?: string;
}

export const ImageCell = React.forwardRef((props: ImageCellProps, ref) => {
  const {
    className,
    classPrefix = "image-cell",
    status,
    children,
    src,
    affected,
    imageHelperText,
    ...rest
  } = props;

  const { withClassPrefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Cell status={status} className={classes} {...rest} affected={affected}>
    <Whisper placement="autoVertical" speaker={<Tooltip>{imageHelperText}</Tooltip>}>
      <img src={src} height={20}/>
    </Whisper>
    {children}
  </Cell>;
});
