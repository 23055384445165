import React                  from "react";
import { Label }              from "../../..";
import { GlobalClasses }      from "../../..";
import { classNames }         from "../../..";
import { Box }                from "../../Box";
import { useConsumeField }    from "../BaseField";
import { BaseFieldClasses }   from "../BaseField";
import { LinkExternal }       from "../../LinkExternal";
import { LinkExternalColors } from "../../LinkExternal";

export const EmailReadField = React.memo(function EmailReadField(props: any) {
  const {
    input: {
      readPlaceholder, label, required, value, name, targetClassName, flexBasis, flexShrink, flexGrow, ...textProps
    }
  } = useConsumeField();
  return (
    <Box container
         direction={"column"}
         gap={"XXXS"}
         className={BaseFieldClasses.ReadField}
         alignSelf={"start"}
         {...textProps}
         flexBasis={flexBasis}
         flexShrink={flexShrink}
         flexGrow={flexGrow}
    >
      <Label>{label}</Label>
      {
        value
          ?
            <LinkExternal
              color={LinkExternalColors.Primary}
              to={`mailto:${value}`}
              className={GlobalClasses.EllipsisFlex}
              allowCopy
              copyText={value}
            >
              {value}
            </LinkExternal>
          :
          <span className={classNames(BaseFieldClasses.ReadFieldNotAvailable, GlobalClasses.EllipsisFlex)}>
            {readPlaceholder ?? "-"}
          </span>
      }
    </Box>
  );
});

export default EmailReadField;
