import React          from "react";
import { classNames } from "../..";
import { Box }        from "../..";
import { applyBoxItemStyles }    from "../..";
import { BoxItemComponentProps } from "../..";
import { LoaderClasses }         from "./LoaderClasses";

export interface LinearLoaderProps extends BoxItemComponentProps {
  freeze?: boolean
}

export function LinearLoader(props: LinearLoaderProps) {
  let properties = applyBoxItemStyles<LinearLoaderProps>(props);
  const { className, freeze = false, ...p } = properties;
  return (
    <Box container className={classNames(LoaderClasses.Linear, className)} {...p}>
      {!freeze && <div className={LoaderClasses.Bar}/>}
    </Box>
  );
}
