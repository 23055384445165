import React                                from "react";
import { FC }                               from "react";
import { useMemo }                          from "react";
import { useEffect }                        from "react";
import { useState }                         from "react";
import { parsePhoneNumber }                 from "@relcu/ui";
import { EmptyState }                       from "@relcu/rc";
import { useLazyQuery }                     from "@apollo/client";
import { useMounted }                       from "@relcu/ui";
import { gql }                              from "@apollo/client";
import { Input }                            from "@relcu/rc";
import { Stack }                            from "@relcu/rc";
import { useThrottle }                      from "@relcu/ui";
import { Device }                           from "../../../../Tw";
import { HybridCall }                       from "../../../../types/hybridCall";
import { formatPhoneNumber }                from "../../../../utils/helpers";
import { AvatarListItem }                   from "../AvatarListItem/AvatarListItem";
import { DialContact }                      from "./__types__/DialContact";
import { SearchDialContactsQueryVariables } from "./__types__/SearchDialContactsQuery";
import { SearchDialContactsQuery }          from "./__types__/SearchDialContactsQuery";

interface ContactListProps {
  onSelect(item: DialContact);
  calls: HybridCall[];
}

export const ContactList: FC<ContactListProps> = React.memo(function ContactList(props) {
  const [search, setSearch] = useState<string>("");
  const { calls } = props;
  let [query, setQuery] = useThrottle(search, 1000);
  if (query && !/[A-Z]/ig.test(query)) {
    query = parsePhoneNumber(query);
  }
  if (query && query.includes("+")) {
    query = String(query).replace(/[+]/g, "");
  }
  const objectId = query ? query.trim() : null;
  const matchesRegex = `.*${objectId}.*`;

  const excludeId = useMemo(() => {
    const ids = [];
    calls?.map(call => {
      if (call.__typename == "Contact") {
        ids.push(call.id);
      }
    });

    return ids;
  }, [calls]);

  const [load, { data: { contacts: { edges = [] } = {} } = {} }] = useLazyQuery<SearchDialContactsQuery, SearchDialContactsQueryVariables>(SEARCH_DIAL_CONTACTS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      notIncludedIds: excludeId,
      matchesRegex,
      first: 5
    }
  });

  useEffect(() => {
    if ((search.includes("+") && search.length >= 6) || (!search.includes("+") && search.length >= 5)) {
      setQuery(search);
    }
  }, [search]);
  useMounted(() => {
    load();
  }, [query]);
  const phones: any = useMemo(() => {
    const numbers = [];
    edges.forEach(({ node }) => {
      node.phones?.forEach(p => {
        numbers.push({ ...node, phones: [{ number: p.number, __typename: p.__typename, optOut: p.optOut, callOptOut: p.callOptOut }] });
      });
    });
    return numbers;
  }, [edges]);

  return <Stack direction={"column"} childrenRenderMode={"clone"} alignItems={"stretch"} style={{ flex: 1 }}
                spacing={8}>
    <Stack.Item grow={1}>
      <Input placeholder={"Search contact"} size={"md"} value={search} onChange={(value: string) => setSearch(value)}/>
    </Stack.Item>
    <Stack direction={"column"} spacing={8} alignItems={"stretch"} style={{ height: 300, overflowY: "auto" }}>
      {
        !!phones?.length ?
          phones?.map(p => {
            const number = p.phones?.[ 0 ];
            return <AvatarListItem
              key={p.objectId}
              disabled={number?.optOut || number?.callOptOut}
              onClick={() => props.onSelect(p)}
              src={p.objectIcon}
              title={p.objectName}
              text={
                formatPhoneNumber(number?.number)
              }/>;
          })
          :
          <EmptyState
            compact
            title={search ? "No result" : "Start typing to find contact"}
            subtitle={search ? "Try again with different term." : null}
            icon={search ? "search_off" : "search"}
            style={{ padding: 16 }}
          />
      }
    </Stack>
  </Stack>;
});

export const SEARCH_DIAL_CONTACTS_QUERY = gql`
  fragment DialContact on Contact {
    id
    objectId
    objectIcon
    objectName
    firstName
    lastName
    updatedAt
    createdAt
    phones {
      optOut
      callOptOut
      number
    }
  }
  query SearchDialContactsQuery(
    $matchesRegex:String,
    $notIncludedIds: [ID]!
    $first:Int,
  ) {
    contacts(first: $first,where: {
      id: {
        notIn: $notIncludedIds
      }
      OR: [
        {
          firstName: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          lastName: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          objectName: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          company: {matchesRegex: $matchesRegex,options: "i"}
        },
        {
          phones: {have: {number: {matchesRegex: $matchesRegex, options: "i"}}}
        }
        {
          emails: {have: {address: {matchesRegex:$matchesRegex,options: "i"}}}
        }
      ]
    }) {
      count
      edges {
        node {
          ...DialContact
        }
      }
    }
  }
`;
