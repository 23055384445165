import React            from "react";
import { FC }           from "react";
import { ReactElement } from "react";
import { Box }          from "../Box";

interface StepHeader {
  edit?: boolean;
  step?: string;
  title: string;
  description?: string;
  action?: ReactElement;
}

export const StepHeader: FC<StepHeader> = React.memo(function StepHeader(props) {
  const { step, title, description, edit, action } = props;
  return <Box container direction={"column"} gap={"XXXS"} className={StepHeaderClasses.Header}>
    {
      !edit &&
      <p className={StepHeaderClasses.Number}>{step}</p>
    }
    <h2 className={StepHeaderClasses.Title}>{title}</h2>
    <Box container justify={"space-between"} alignItems={"center"}>
      <p className={StepHeaderClasses.Description}>{description}</p>
      {action}
    </Box>
  </Box>;
});

StepHeader.defaultProps = {};

export const enum StepHeaderClasses {
  Header = "step-header",
  Number = "step-header__number",
  Title = "step-header__title",
  Description = "step-header__description",
}


