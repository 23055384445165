import { useEffect } from "react";
import { useState }  from "react";
import { useRef }    from "react";
import { useLatest } from "./useLatest";

export function useAsync<R = any, Args extends any[] = any[]>(
  asyncFunction: () => Promise<R>,
  params: Args,
  initialState?: Partial<UseAsyncState<R>>
) {
  const promises = useRef([]);
  const call = useLatest(asyncFunction);
  const [state, setState] = useState<UseAsyncState<R>>(() => ({
    data: undefined,
    error: undefined,
    loading: true,
    called: true,
    lazy: false,
    async call() {
      let nextState: Partial<UseAsyncState<R>> = {};
      if (!current.loading) {
        nextState.loading = true;
      }
      if (!current.called) {
        nextState.called = true;
      }
      if (!!Object.keys(nextState).length) {
        dispatch(nextState);
      }
      const promise = call.current().then(data => {
        const nextState = {
          data,
          error: undefined,
          loading: false
        };
        dispatch(nextState, promise);
        return data;
      }).catch(error => {
        const nextState = {
          data: undefined,
          error,
          loading: false
        };
        dispatch(nextState, promise);
      });
      promises.current.push(promise);
      return promise as Promise<R>;
    },
    ...initialState
  }));
  const { current } = useLatest(state);

  function dispatch(nextState: Partial<UseAsyncState<R>>, currentPromise?) {
    if (currentPromise) {
      const index = promises.current.indexOf(currentPromise);
      if (index === -1) {
        return;
      }
      promises.current.splice(0, index + 1);
    }
    setState(prevState => ({
      ...prevState,
      ...nextState
    }));
  }

  function load() {
    if (!current.lazy) {
      current.call().catch(console.error);
    }
    return () => {
      promises.current = [];
    };
  }

  useEffect(load, params);

  return state;
}

export function useLazyAsync<R = any, Args extends any[] = any[]>(
  asyncFunction: () => Promise<R>,
  params: Args,
  initialState?: Partial<UseAsyncState<R>>
): UseLazyAsyncTuple<R> {
  const { call, ...state } = useAsync<R>(asyncFunction, params, {
    ...initialState,
    loading: false,
    called: false,
    lazy: true
  });
  return [call, state];
}

export interface UseAsyncState<R> {
  data: R
  error: any,
  loading: boolean
  called: boolean
  lazy: boolean
  call(): Promise<R>
}
export type UseLazyAsyncTuple<R> = [() => Promise<R>, Omit<UseAsyncState<R>, "call">]
