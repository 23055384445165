import { gql } from "@apollo/client";

//==============================================================
// START Enums and Input Objects
//==============================================================

//==============================================================
// END Enums and Input Objects
//==============================================================
export const typeDefs = gql`
  directive @client on FIELD
  directive @connection(key:String!,filter:[String]) on FIELD

  type Config {
    base: String
    url: String
    cdn: String
    main: String
    domain: String
  }
  extend type Query {
    config:Config
  }
  extend type Lead {
    pricing:Object
    readyToPullPricing:Boolean
  }
  extend type Query {
    schemas:Object
  }
  
  extend type LoanEstimateOffer {
    isValid: Boolean
    isDirty: Boolean
    isTitleFeeEditable: Boolean
  }
`;
