import React                 from "react";
import { classNames }        from "../../utils/classNames";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";
import { StepClasses }       from "./StepClasses";

export const StepBody = React.forwardRef(function StepBody(props: BoxComponentProps, ref: React.MutableRefObject<HTMLDivElement>) {
  const { className, ...p } = props;
  const classes = classNames(StepClasses.Body, className);
  return <Box container direction={"column"} gap={"XXXS"} className={classes} flex={1} ref={ref} {...p}/>;
});


