import React              from "react";
import { FC }             from "react";
import { CheckboxProps }  from "../../Input/Checkbox";
import { BaseFieldProps } from "../BaseField";
import { BaseField }      from "../BaseField";
import SwitchEditField    from "./SwitchEditField";
import SwitchReadField    from "./SwitchReadField";

export type SwitchFieldProps = BaseFieldProps & Partial<CheckboxProps>;

export const SwitchField: FC<SwitchFieldProps> = React.memo(function SwitchField(props) {
  return (
    <BaseField {...props} readView={<SwitchReadField/>} editView={<SwitchEditField/>} type={"checkbox"}/>
  );
});
