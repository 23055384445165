import { useMemo }                                             from "react";
import { useRef }                                              from "react";
import { useMounted }                                          from "@relcu/ui";
import { useLazyQuery }                                        from "@apollo/client";
import { useField }                                            from "@relcu/final-form";
import { useImperativeState }                                  from "@relcu/ui";
import { phoneNumberMatchers }                                 from "../../../../utils/helpers";
import { useSearchPermissions }                                from "../../../AccessControl/useSearchPermision";
import { FindContactByPhoneNumber }                            from "../../__types__/FindContactByPhoneNumber";
import { FindContactByPhoneNumberVariables }                   from "../../__types__/FindContactByPhoneNumber";
import { FIND_CONTACT_BY_PHONE_NUMBER }                        from "../../useDIalPadSubmit";
import { DialInputProps }                                      from "./DialInput";

export function useDialInput(props: DialInputProps) {
  const selectRef: any = useRef();
  const { canSearch } = useSearchPermissions();
  const skipContacts = !canSearch("Contact");
  const [find, { loading, data }] = useLazyQuery<FindContactByPhoneNumber, FindContactByPhoneNumberVariables>(FIND_CONTACT_BY_PHONE_NUMBER, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
  const { input, meta: { touched, error, submitError } } = useField(props.name, {
    parse: (value) => {
      return "+1" + phoneNumberMatchers(value);
    },
    format: (value) => {
      return value?.slice(2).replace?.(/_/g, "");
    }
  });
  const { input: contactInput } = useField("contact", {
    parse: (value) => {
      //in case of contact have more than one phone
      if (typeof value == "object" && value != null) {
        return {
          id: value.id,
          objectId: value.objectId,
          objectName: value?.objectName,
          company: value?.company,
          number: getNumber(value),
          phone: getPhone(value),
          __typename: value.__typename
        };
      }
      return value;
    }
  });
  const hasError = (touched && !!error) || !!submitError;
  const options = useMemo(() => {
    return (
      Object(data?.[ "contacts" ])[ "edges" ] || []
    ).map(({ node }) => node);
  }, [data]);
  const handleSelect = (data) => {
    const phone = getNumber(data);
    if (phone) {
      input.onChange(phone?.slice(2));
    }
    contactInput.onChange(data);
    setTimeout(() => setAnchorBounding(null));
  };
  const handleChange = (e) => {
    if (contactInput.value) {
      contactInput.onChange(null);
    }

    input.onChange(e.currentTarget.value);
  };
  const handleBack = () => {
    input.onChange(input.value.slice(0, -1));
    if (contactInput.value) {
      contactInput.onChange(null);
    }
  };
  function getPhone(contact) {
    return contact?.phones?.find((p) => p.number.includes(input.value));
  }
  function getNumber(contact) {
    return contact?.phones?.find((p) => p.number.includes(input.value))?.number;
  }

  function checkPhoneNumberDisabled(phone) {
    if (phone?.optOut) {
      return true;
    }

    let disabled = false;

    if (props.actions.indexOf("call") > -1) {
      if (phone?.callOptOut) {
        disabled = true;
      } else {
        disabled = false;
      }
    }

    if (props.actions.indexOf("sms") > -1) {
      if (phone?.smsOptOut) {
        disabled = true;
      } else {
        disabled = false;
      }
    }

    return disabled;
  }

  useMounted(() => {
    if (input.value?.length >= 5 && !skipContacts) {
      const fetchData = async () => {
        let variables: FindContactByPhoneNumberVariables = {
          first: 2,
          where: {
            phones: {
              have: {
                number: {
                  matchesRegex: `^\\+1${phoneNumberMatchers(input.value).replace?.(/_/g, "")}`
                }
              }
            }
          }
        };
        if (props.queryFilterWhere) {
          variables = {
            ...variables,
            where: {
              ...variables.where,
              ...props.queryFilterWhere
            }
          };
        }

        const { data, loading } = await find({ variables });
        setAnchorBounding((data?.[ "contacts" ][ "edges" ]?.length && !loading && !contactInput.value) ? selectRef.current?.getBoundingClientRect() : (!loading && null));
      };

      fetchData();
    } else {
      setAnchorBounding(null);
    }
  }, [input.value, contactInput.value, props.queryFilterWhere]);

  return {
    input,
    handleChange,
    handleBack,
    selectRef,
    hasError,
    submitError,
    anchorBounding,
    setAnchorBounding,
    checkPhoneNumberDisabled,
    handleSelect,
    error,
    getNumber,
    getPhone,
    options
  };
}
