import { useCallback }      from "react";
import React                from "react";
import { ComponentType }    from "react";
import { Select }           from "@relcu/ui";
import { Box }              from "@relcu/ui";
import { ValueEditorProps } from "react-querybuilder";

export const LeadToUserValueEditor: ComponentType<ValueEditorProps> = React.memo(function LeadToUserValueEditor(props) {
  const { value, handleOnChange, fieldData, operator } = props;
  if (operator == "notNull" || operator == "is null") {
    return <Box flex={"0 1 30%"}/>;/*todo remove width*/
  }
  const onChange = useCallback((selection) => handleOnChange(selection.value), [handleOnChange]);
  return <Select
    value={value}
    options={fieldData.userOperators}
    direction={"column"}
    style={{ padding: "0 8px" }}
    flex={"0 1 32%"}
    placeholder={"Select value"}
    onChange={onChange}
  />;
});
