import { MultiInputField }               from "@relcu/ui";
import { useMemo }                       from "react";
import React                             from "react";
import { FC }                            from "react";
import { Box }                           from "@relcu/ui";
import { Typography, TypographyVariant } from "@relcu/ui";
import { Tooltip }                       from "@relcu/ui";
import { FontIcon }                      from "@relcu/ui";
import { ButtonVariants }                from "@relcu/ui";
import { Button }                        from "@relcu/ui";
import { HorizontalDivider }             from "@relcu/ui";
import { TextField }                     from "@relcu/ui";
import { ToggleSwitchField }             from "@relcu/ui";
import { StepSection }                   from "@relcu/ui";
import { TypographyColor }               from "@relcu/ui";
import { TypographyWeight }              from "@relcu/ui";
import { TypographySize }                from "@relcu/ui";
import { Alignment }                     from "@relcu/ui";
import { CommonClasses }                 from "@relcu/ui";
import { MailDomainDialogClasses }       from "../../MailDomainDialog";
import { MailDomainTooltipProps }        from "../../MailDomainDialog";
import { DnsSection }                    from "./Dns/DnsSection";
import { EmailForward }                  from "./EmailForward/EmailForward";
import { EmailProviderSectionProps }     from "./EmailProvider/EmailProviderSection";
import { EmailProviderSection }          from "./EmailProvider/EmailProviderSection";

export interface DomainDetailsFormProps extends EmailProviderSectionProps {
  onEnableRelcuEmail();
  onDnsValidate();
  mailDomain: any; //todo remove form state and add types
  action: "create" | "save";
}

export const DomainDetailsForm: FC<DomainDetailsFormProps> = React.memo(function DomainDetailsForm(props) {
  const { action } = props;
  const isCreate = useMemo(() => action == "create", [action]);


  return <Box gap={"XS"} container direction="column" flex={1} className={MailDomainDialogClasses.MailDomainDialogBody}>
    <Box>
      <Typography variant={TypographyVariant.h2} size={TypographySize.Subtitle} weight={TypographyWeight.Medium}>
        Domain details
      </Typography>
    </Box>
    <Box container direction="row">
      <TextField
        disabled={true}
        required
        label={"Domain name"}
        name={"domain"}
        flexBasis={"100%"}
        placeholder={"Enter domain name"}/>
    </Box>
    <EmailProviderSection/>
    <Box container justify={"center"}>
      <Typography color={TypographyColor.Secondary}>or/and</Typography>
    </Box>
    <StepSection>
      <Box gap={"XS"} container direction="column">
        <Box container gap={"XXXS"}>
          <Typography
            variant={TypographyVariant.h2}
            size={TypographySize.Subtitle}
            weight={TypographyWeight.Medium}>
            Relcu email
          </Typography>
          <MailDomainTooltip text={
            "Once you activate this option users will be able to \n" +
            "use shared inboxes, send and receive verified \n" +
            "emails through Relcu inbox."
          }/>
        </Box>
        {
          props.mailDomain.dns ?
            <>
              <DnsSection dns={props.mailDomain.dns} onDnsValidate={props.onDnsValidate}/>
              <HorizontalDivider bold/>
              <EmailForward dns={props.mailDomain.dns}/>
            </> :
            <Box gap={"XS"} direction={"column"} container alignItems={"center"} justify={"center"}>
              <Typography>
                Get “Relcu email” to enable shared inboxes, send (e.g., marketing campaigns) and receive verified
                emails.
              </Typography>
              <Button variant={ButtonVariants.Outline} onClick={props.onEnableRelcuEmail}>
                ENABLE
              </Button>
            </Box>
        }
      </Box>
    </StepSection>
    <Box container direction="row">
      <MultiInputField
        label={"Aliases"}
        name={"aliases"}
        flexBasis={"100%"}
        placeholder={"Enter domain name"}/>
    </Box>
    {!isCreate &&
      <Box container direction={"column"} gap={"XS"}>
        <Typography variant={TypographyVariant.h2} size={TypographySize.Subtitle}
                    weight={TypographyWeight.Medium}>General</Typography>
        <ToggleSwitchField name={"disabled"} label={"Disable domain"}/>
        <ToggleSwitchField name={"useForOut"} label={"Use domain for authentication"}/>
      </Box>}
  </Box>;
});

export const MailDomainTooltip = React.memo<MailDomainTooltipProps>(function MailDomainTooltip(props) {
  const { text } = props;
  return (
    <Tooltip
      title={text}
      alignment={Alignment.Top}
      style={{ textAlign: "center" }}
    >
      <FontIcon
        type={"info"}
        className={CommonClasses.GrayIcon}
        style={{
          fontSize: 20,
          cursor: "pointer",
          verticalAlign: "middle"
        }}
      />
    </Tooltip>
  );
});
