import React                      from "react";
import { FC }                     from "react";
import { classNames as clsNames } from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { RateClasses }            from "./RateClasses";

export const RateSelect: FC<BoxComponentProps> = React.memo(function RateSelect(props) {
  const { className, ...p } = props;
  const classNames = clsNames(RateClasses.RateSelect, className);
  return <Box container
              justify={"space-between"}
              alignItems={"center"}
              gap={"XXXS"}
              className={classNames}
              {...p}/>;
});

export const RateSelectBody: FC<BoxComponentProps & { divider?: boolean, horizontalDivider?: boolean }> = React.memo(function RateSelectBody(props) {
  const { className, divider, horizontalDivider, ...p } = props;
  const classNames = clsNames(RateClasses.RateSelectBody, {
    [ RateClasses.DividerVertical ]: divider,
    [ RateClasses.DividerHorizontal ]: horizontalDivider
  }, className);
  return <Box container
              gap={"XS"}
              direction={"column"}
              className={classNames}
              {...p}/>;
});

RateSelectBody.defaultProps = {
  divider: true,
  horizontalDivider: true
};
