import { classNames }            from "../..";
import { FC }                    from "react";
import React                     from "react";
import { useEffect }             from "react";
import { Box }                   from "../Box";
import { AnimatedCircleClasses } from "./AnimatedCircleClasses";

export interface AnimatedCircleProps {
  count?: number
  duration?: number
  color?: AnimatedCircleColors
  className?: string
  size?: number
  onClose?()
}

export enum AnimatedCircleColors {
  Primary = "primary",
  Success = "success",
}

export const AnimatedCircle: FC<AnimatedCircleProps> = React.memo(function AnimatedCircle(props) {
  const { onClose, count, children, className, duration=2500,color, size } = props;
  const classes = classNames(AnimatedCircleClasses.AnimatedCircle, {
    [ AnimatedCircleClasses.Success ]: color == AnimatedCircleColors.Success
  }, className);
  useEffect(() => {
    const timer = setTimeout(() => onClose?.(), duration);
    return () => clearTimeout(timer);
  }, [count]);

  return <>
    <Box className={classes} style={{ animationDelay: "0s",  "--size": `${size}px`} as any}/>
    <Box style={{zIndex: 1}}>
      {children || null}
    </Box>
    <Box className={classes} style={{ animationDelay: "-1s", "--size": `${size}px`} as any}/>
  </>;
});

AnimatedCircle.defaultProps = {
  color: AnimatedCircleColors.Primary,
  size: 32
};
