import { ReactNode }             from "react";
import { BaseInputProps }        from "../../..";
import { useImperativeState }    from "../../..";
import { applyBoxItemStyles }    from "../../..";
import { BoxItemComponentProps } from "../../..";
import { checkType }             from "../../../utils/helpers";

export interface UseAutocomplete extends BoxItemComponentProps, BaseInputProps {
  value?: number | string | object
  loading?: boolean
  message?: string
  variant?: "default" | "outline"
  placeholder?: string
  defaultOption?: string
  options?: any[]
  optionKey?: string
  optionLabel?: string
  getOptionValue?: (option) => string
  getOptionLabel?: (option) => string | ReactNode
  getOptionSelected?: (option, value) => boolean
  onChange?(option)
  onInputChange?(value)
  onBlur?(event)
  onFocus?(event)
}

export function useAutocomplete(props: UseAutocomplete) {
  let properties = applyBoxItemStyles<UseAutocomplete>(props);
  const {
    variant,
    options = [],
    onChange,
    onInputChange,
    value,
    optionLabel = "label",
    optionKey = "value",
    defaultOption,
    onFocus,
    onBlur,
    halfSize,
    fullSize,
    className,
    loading = false,
    color = null,
    getOptionValue,
    getOptionSelected,
    getOptionLabel,
    ...p
  } = properties;

  const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
  const [selectedItem, setSelectedItem] = useImperativeState(getOptionValue ? getOptionValue(value) : value, onChange);
  const [stateValue, setStateValue] = useImperativeState(value, onInputChange);

  const autocomplete = {
    anchorBounding,
    setAnchorBounding,
    options,
    loading,
    defaultOption,
    get value() {
      return stateValue;
    },
    setBounding(event) {
      setAnchorBounding(!anchorBounding ? event.target.getBoundingClientRect() : null);
      if (stateValue) {
        onInputChange(stateValue);
      }
      onFocus?.(event);
    },
    onChange(e, event) {
      if (!anchorBounding) {
        setAnchorBounding(event.target.getBoundingClientRect());
      }
      setStateValue(e || null);
    },
    isSelected(option) {
      return selectedItem && typeof selectedItem == "object" && typeof option == "object" ?
        selectedItem[ optionKey ] !== undefined && option[ optionKey ] !== undefined && selectedItem[ optionKey ] === option[ optionKey ] :
        selectedItem !== undefined && option !== undefined && selectedItem === option;
    },
    getInputProps() {
      return {
        ...p,
        onBlur,
        onFocus: autocomplete.setBounding,
        onChange: autocomplete.onChange,
        value: stateValue
      };
    },
    getOptionProps(option, index: number) {
      return {
        selected: autocomplete.getOptionSelected(option),
        key: index,
        children: autocomplete.getOptionLabel(option),
        onClick: (e) => {
          setSelectedItem(autocomplete.getOptionValue(option));
          setAnchorBounding(null);
        }
      };
    },
    getDefaultOptionProps() {
      if (!loading && defaultOption) {
        return {
          children: defaultOption,
          onClick(e) {
            setSelectedItem(null);
            setAnchorBounding(null);
          }
        };
      }
    },
    getOptionValue(option) {
      return getOptionValue ? getOptionValue(option) : option;
    },
    getOptionLabel(option) {
      return getOptionLabel ? getOptionLabel(option) : checkType(option, optionLabel);
    },
    getOptionSelected(option) {
      return (getOptionSelected && getOptionSelected(option, selectedItem)) || autocomplete.isSelected(option);
    }
  };

  return autocomplete;

}
