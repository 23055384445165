export const enum QueueClasses {
  Queue = "rd-queue",
  ShowStat = "rd-queue--show-stat",
  StatItem = "rd-queue__stat_item",
  StatItemDisabled = "rd-queue__stat_item--disabled",
  StatLabel = "rd-queue__stat_label",
  StatValue = "rd-queue__stat_value",
  State = "rd-queue__state",
  StateSmall = "rd-queue__state_small",
  StateSmallCoolDown = "rd-queue__state--coolDown",
  StateBig = "rd-queue__state_big",
  Animate = "rd-queue__state--animate",
  AnimateWarning = "rd-queue__state--animate--warning",
  AnimatePrimary = "rd-queue__state--animate--primary",
  AnimateError = "rd-queue__state--animate--error",
  AnimateSuccess = "rd-queue__state--animate--success",
  AnimateInfo = "rd-queue__state--animate--info",
  TwoRepeat = "rd-queue__state--animate--two-repeat",
  FiveRepeat = "rd-queue__state--animate--five-repeat",
  Circle = "rd-queue__circle",
  LeftTimeAnimated = "left-time--animated",
  PastTimeAnimated = "past-time--animated",
  Time = "rd-queue__time",
  SmallTime = "rd-queue__time--small",
  ColorWhite = "rd-queue__color-white",
  Icon = "rd-queue__icon",
  IconCollapsed = "rd-queue__icon-opened",
  IconWarning = "rd-queue__icon--warning",
  IconPrimary = "rd-queue__icon--primary",
  IconError = "rd-queue__icon--error",
  IconSuccess = "rd-queue__icon--success",
  IconInfo = "rd-queue__icon--info",
  CoolDownIcon = "rd-queue__coolDown-icon",
  Description = "rd-queue__description",
  Name = "rd-queue__name",
  ButtonSwitcher = "rd-queue__button-switcher",
  Switcher = "rd-queue__switcher",
  Header = "rd-queue__header",
  Main = "rd-queue__main",
  Footer = "rd-queue__footer",
  FontSmall = "rd-queue__font_small"
}