import { useLayoutEffect }    from "react";
import React                  from "react";
import { FC }                 from "react";
import { useRef }             from "react";
import { useCallback }        from "react";
import { SyntheticEvent }     from "react";
import { IconType }           from "../../..";
import { FontIcon }           from "../../..";
import { Box }                from "../../..";
import { classNames }         from "../../..";
import { useImperativeState } from "../../..";
import { BoxComponentProps }  from "../../..";
import { CommonClasses }      from "../../../theme/classes";

export interface SearchProps extends BoxComponentProps {
  value?: number | string;
  autoFocus?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  message?: string;
  icon?: IconType,
  type?: "text" | "password";
  placeholder?: string;
  inputProps?: { [ key: string ]: any; };
  InputProps?: { [ key: string ]: any; };
  onChange?(value, event?: SyntheticEvent);
  onFocus?(value);
  onBlur?(value);
  onKeyDown?(value);
  onKeyPress?(value);
  onKeyUp?(value);
}

export const SearchInput: FC<SearchProps> = React.memo((props) => {
  const {
    className,
    children,
    value,
    clearable,
    disabled,
    readOnly,
    message,
    type,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onFocus,
    icon,
    autoFocus,
    inputProps,
    InputProps,
    ...p
  } = props;
  const inputRef = useRef(null);
  const [stateValue, setStateValue] = useImperativeState(value, onChange);
  const classes = classNames(SearchClasses.Search, className);

  const handleChange = useCallback((e) => {
    e.stopPropagation();
    setStateValue(e.target.value || null, e);
  }, [onChange]);

  useLayoutEffect(() => {
    if (autoFocus) {
      inputRef?.current.focus();
    }
  }, [inputRef?.current]);

  const handleKeyUp = (e) => {
    onKeyUp?.(e);
    if (e.key == "Escape") {
      setStateValue("");
    }
  };
  return <Box container direction={"column"} className={classes} {...InputProps} {...p}>
    {
      React.Children.count(children) > 0 &&
      <Box container justify={"space-between"} alignItems={"center"} className={SearchClasses.ActionContainer}>
        {children}
      </Box>
    }
    <Box container gap={"XXXS"} alignItems={"center"} className={SearchClasses.SearchContainer}>
      <FontIcon className={CommonClasses.GrayIcon} type={icon}/>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        value={stateValue || ""}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        placeholder={placeholder || "Select value"}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={handleKeyUp}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        {...inputProps}
      />
      {
        (clearable && stateValue) &&
        <FontIcon className={CommonClasses.GrayIcon} type={"clear"} onClick={() => setStateValue("")}/>
      }
    </Box>
  </Box>;

});

SearchInput.defaultProps = {
  clearable: true,
  icon: "search"
};

export enum SearchClasses {
  Search = "search",
  SearchContainer = "search-container",
  ActionContainer = "action-container"
}
