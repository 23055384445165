import React                           from "react";
import { useContext }                  from "react";
import { HTMLAttributes }              from "react";
import { useState }                    from "react";
import { useRef }                      from "react";
import { useMemo }                     from "react";
import { TabItem }                     from "@relcu/rc";
import { TabPanel }                    from "@relcu/rc";
import { TabPanelContainer }           from "@relcu/rc";
import { TabContainer, TabsSwitcher }  from "@relcu/rc";
import { CircularLoader }              from "@relcu/ui";
import { BackDropLoader }              from "@relcu/ui";
import { TypographyColor }             from "@relcu/ui";
import { TypographySize }              from "@relcu/ui";
import { FontIcon }                    from "@relcu/ui";
import { TypographyWeight }            from "@relcu/ui";
import { Typography }                  from "@relcu/ui";
import { MultiSelectVariants }         from "@relcu/ui";
import { MultiSelect }                 from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { Alignment }                   from "@relcu/ui";
import { CommonClasses }               from "@relcu/ui";
import { useConstant }                 from "@relcu/ui";
import { classNames }                  from "@relcu/ui";
import { EmptyList }                   from "@relcu/ui";
import { dividedDataByFixedDate }      from "../../../utils/helpers";
import { GetAllNotifications }         from "../../__types__/GetAllNotifications";
import { SUBSCRIBE_ALL_NOTIFICATIONS } from "../../operations.graphql";
import { GET_ALL_NOTIFICATIONS }       from "../../operations.graphql";
import { RelayQuery }                  from "../../Relay";
import { useNotification }             from "../InAppNotification/useNotification";
import { UnreadNotificationContext }   from "../UnreadNotificationProvider";
import { BulkMailNotification }        from "./Items/BulkMailNotification";
import { BulkSMSNotification }         from "./Items/BulkSMSNotification";
import { CallNotification }            from "./Items/CallNotification";
import { EmailNotification }           from "./Items/EmailNotification";
import { LoanNotification }            from "./Items/LoanNotification";
import { ReminderNotification }        from "./Items/ReminderNotification";
import { SmsNotification }             from "./Items/SmsNotification";
import { NotificationMention }         from "./NotificationMention";
import { NotificationTimeSection }     from "./NotificationTimeSection";

import "./notification-bar.css";

export const TimeStepLabels = {
  "new": "New",
  "today": "Today",
  "week": "This week",
  "month": "This month",
  "year": "This year",
  "old": "Older"
};
export const NotificationIcons = {
  "sms": "forum",
  "email": "email",
  "missed_call": "phone_missed",
  "reminder": "access_alarm",
  "milestone_comment": "rc_pipeline_note",
  "distributed_lead": "rc_lead",
  "bulk_email": "rc_bulk_email",
  "bulk_sms": "rc_bulk_sms"
};
export const NotificationTitles = {
  "sms": "New SMS",
  "email": "New Email",
  "missed_call": "Missed call",
  "missed_call_with_voice": "Missed call with Voicemail",
  "reminder": "Reminder",
  "milestone_comment": "New note on loan(Pipeline)",
  "distributed_lead": "New Lead",
  "bulk_email": "Bulk email",
  "bulk_sms": "Bulk sms"
};
export const NotificationComponents = {
  "sms": SmsNotification,
  "email": EmailNotification,
  "missed_call": CallNotification,
  "reminder": ReminderNotification,
  "milestone_comment": LoanNotification,
  "bulk_email": BulkMailNotification,
  "bulk_sms": BulkSMSNotification
};

export interface NotificationProps extends HTMLAttributes<HTMLDivElement> {
  node: any;
  isNew?: boolean;
  indicateStatus?: boolean;
  onNavigate();
  inApp?: boolean;
}

export const NotificationFilters = [
  {
    label: "Calls",
    value: "missed_call"
  },
  {
    label: "SMS",
    value: "sms"
  },
  {
    label: "Email",
    value: "email"
  },
  {
    label: "Reminders",
    value: "reminder"
  },
  {
    label: "Loan comments",
    value: "milestone_comment"
  },
  {
    label: "Bulk emails",
    value: "bulk_email"
  },
  {
    label: "Bulk sms",
    value: "bulk_sms"
  }
];

export const NotificationBar = React.memo(function NotificationBar() {
  return (
    <div className="notification-bar">
      <NotificationBarHeader/>
      <NotificationBarContent/>
    </div>
  );
});
function NotificationBarHeader() {
  const { filters, setFilters } = useContext(UnreadNotificationContext);
  return <div className={"notification-bar-header"}>
    <Typography size={TypographySize.SubtitleSmall} weight={TypographyWeight.Medium}>
      Notifications
    </Typography>
    <MultiSelect
      filterable={false}
      alignment={Alignment.BottomRight}
      variant={MultiSelectVariants.Ghost}
      options={NotificationFilters}
      placeholder={"Select filter"}
      searchable={false}
      value={filters}
      optionKey={"value"}
      onChange={setFilters}
      renderHeader={(options) => {
        return <Box container gap={"XXXS"} alignItems={"center"}>
          {
            !!options.length &&
            <Typography
              color={TypographyColor.Secondary}
              size={TypographySize.TextSmall}>
              {`${options.length} filter${options.length > 1 ? "s" : ""}`}
            </Typography>
          }
          <FontIcon type={"filter_alt"} className={CommonClasses.ClickableIcon}/>
        </Box>;
      }}
      width={200}
    />
  </div>;
}
function NotificationBarContent() {
  return <TabContainer gap={24} outerState={useState(0)} className={"notification-bar-body"}>
    <TabPanelContainer className={"notification-bar-tab-container"}>
      <TabPanel className={"notification-bar-unread-tab-panel"}>
        <UnreadNotifications/>
      </TabPanel>
      <TabPanel className={"notification-bar-all-tab-panel"}>
        <AllNotifications/>
      </TabPanel>
    </TabPanelContainer>
    <TabsSwitcher className={"notification-bar-tabs"}>
      <TabItem active={true} className={"notification-bar-tab-item"}>Unread</TabItem>
      <TabItem active={false} className={"notification-bar-tab-item"}>All</TabItem>
    </TabsSwitcher>
  </TabContainer>;
}

const UnreadNotifications = React.memo(function UnreadNotifications() {
  const { notifications, lastNotificationSeenAt, filters } = useContext(UnreadNotificationContext);
  const scrollContainerRef = useRef(null);
  const { getRecord, onNavigate } = useNotification();
  const lastSeenAt = useConstant(() => lastNotificationSeenAt);
  let data = useMemo(() => filters.length ?
    notifications.edges.filter(({ node }) => filters.findIndex((f) => f.value == node.type) > -1) :
    notifications.edges, [notifications.edges, filters]);
  data = data.filter(({ node }) => node.type !== "distributed_lead");
  const dividedData = useMemo(() => dividedDataByFixedDate(data, null, lastSeenAt), [lastSeenAt, data]);
  const dateSteps = Object.entries(dividedData).filter(dateStep => dateStep[ 1 ].length);
  const isFilterExists = useMemo(() => filters?.length != 0, [filters]);

  return (
    !data.length ?
      <EmptyList
        flex={1}
        justify={"center"}
        alignSelf={"stretch"}
        title={isFilterExists ? "No results" : "No unread notifications"}
        content={isFilterExists ? "Please try again with another set of filters" : "You have read all your notifications."}/>
      :
      <>
        <NotificationMention scrollContainerRef={scrollContainerRef}/>
        <div className={"notification-bar-unread-notes"} ref={scrollContainerRef}>
          {
            dateSteps.map((dateStep, index) => {
              return <NotificationTimeSection key={index} title={TimeStepLabels[ dateStep[ 0 ] ]}>
                {
                  dateStep[ 1 ].map(({ node }, index) => {
                      if (!NotificationComponents[ node.type ]) {
                        return null;
                      }
                      const Component = NotificationComponents[ node.type ];

                      const record = getRecord(node);
                      const classes = classNames("notification-bar-note", {
                        [ "notification-bar-note-new" ]: dateStep[ 0 ] == "new",
                        [ "notification-bar-note-unread" ]: record?.unread,
                        [ "notification-bar-note-disabled" ]: !record?.record
                      });
                      return <Component
                        key={index}
                        node={record}
                        onNavigate={() => onNavigate(record)}
                        className={classes}
                        indicateStatus
                      />;
                    }
                  )
                }
              </NotificationTimeSection>;
            })
          }
        </div>
      </>
  );
});
const AllNotifications = React.memo(function AllNotifications() {
  // const { scrollContainerRef, loaderRef, dateSteps, loading, pageInfo } = useAllNotifications();
  const { filters, notifications, lastNotificationSeenAt } = useContext(UnreadNotificationContext);
  const lastSeenAt = useConstant(() => lastNotificationSeenAt);
  const { getRecord, onNavigate } = useNotification();
  const isFilterExists = useMemo(() => filters?.length != 0, [filters]);
  const webNotificationTypes = ["sms", "email", "bulk_email", "bulk_sms", "reminder", "missed_call", "milestone_comment"];

  const variables = filters?.length ? {
    type: {
      in: filters.map(f => f.value)
    }
  } : { type: { in: webNotificationTypes } };
  return (
    <RelayQuery<GetAllNotifications>
      query={{
        document: GET_ALL_NOTIFICATIONS,
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables
      }}
      rowHeight={74}
      subscription={{
        document: SUBSCRIBE_ALL_NOTIFICATIONS,
        variables
      }}
      className={"Notification"}
      render={({ scrollContainerRef, afterLoaderRef, loading, data: { notifications: { edges = [], pageInfo } } }) => {
        const dividedData = useMemo(() => dividedDataByFixedDate(edges, null, lastSeenAt), [lastSeenAt, edges]);
        const dateSteps = Object.entries(dividedData).filter(dateStep => dateStep[ 1 ].length);
        return <>
          <NotificationMention scrollContainerRef={scrollContainerRef}/>
          <div
            className={"notification-bar-all-notes"}
            ref={scrollContainerRef}>
            {
              loading && <BackDropLoader style={{ zIndex: 1 }}/>
            }
            {
              !!dateSteps.length ?
                <>
                  {
                    dateSteps.map((dateStep, index) => {
                      return <NotificationTimeSection key={index} title={TimeStepLabels[ dateStep[ 0 ] ]}>
                        {
                          dateStep[ 1 ].map(({ node }, index) => {

                              if (!NotificationComponents[ node.type ]) {
                                return null;
                              }
                              const Component = NotificationComponents[ node.type ];
                              const record = getRecord(node);
                              const classes = classNames("notification-bar-note", {
                                [ "notification-bar-note--new" ]: dateStep[ 0 ] == "new",
                                [ "notification-bar-note--unread" ]: record?.unread,
                                [ "notification-bar-note--disabled" ]: !record?.record
                              });
                              return <Component
                                key={index}
                                node={record}
                                onNavigate={() => onNavigate(record)}
                                className={classes}
                                indicateStatus
                              />;
                            }
                          )
                        }
                      </NotificationTimeSection>;
                    })
                  }
                  {
                    pageInfo?.hasNextPage &&
                    <CircularLoader style={{ minHeight: 34 }} alignSelf={"center"} ref={afterLoaderRef}/>
                  }
                </>
                :
                <EmptyList
                  flex={1} justify={"center"}
                  alignSelf={"stretch"}
                  title={isFilterExists ? "No results" : "No notifications"}
                  content={isFilterExists ? "Please try again with another set of filters" : "All notifications will appear here."}
                />
            }
          </div>
        </>;
      }}
    />
  );
});
