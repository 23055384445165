import { FORM_ERROR }           from "@relcu/form";
import { Field, Form, FormSpy } from "@relcu/form";
import { useCallback }          from "react";
import React                    from "react";
import { FC }                   from "react";
import { classNames }           from "../../../utils/classNames";
import { AlertColors }          from "../../Alert";
import { Alert }                from "../../Alert";
import { Box }                  from "../../Box";
import { Button }               from "../../Button";
import { ButtonVariants }       from "../../Button";
import { ImageInputField }      from "../../Field/ImageInputField";
import { RadioGroupField }      from "../../Field/RadioGroupField";
import { TextField }            from "../../Field/TextField";
import { ImageUploadMode }      from "../../Input/ImageInput";
import { FormWhere }            from "../../Where";
import { ModalFooter }          from "../Modal";
import { ModalBody }            from "../Modal";
import { Modal }                from "../Modal";
import { ModalProps }           from "../Modal";
import { ImagePreview }         from "./ImagePreview";
import MissingImageUrl          from "./missing_image.png";

export interface ImageUploadDialogProps extends Partial<ModalProps> {
  acceptedTypes?: string[]
  onClose?(e?),
  onCancel?(e?),
  onConfirm?(e?),
  onUpload?(e?),
  title?,
  content?
}

export const ImageUploadDialog: FC<ImageUploadDialogProps> = React.memo(function ImageUploadDialog(props) {
  const { onConfirm, acceptedTypes, ...p } = props;
  const handleSubmit = useCallback(async (data) => {
    try {
      if (data.type == "web") {
        onConfirm(data.imageUrl);
      } else {
        onConfirm(data.imageUpload);
      }
    } catch (e) {
      console.error(e);
      return {
        [ FORM_ERROR ]: e.message
      };
    }
  }, [acceptedTypes, onConfirm]);

  return <Modal
    className={ImageUploadDialogClasses.Modal}
    title={"Upload image"}
    onClose={props.onClose}
    open={props.open}
    closeIcon={!!props.closeIcon}
    disableBackdropClick={false}>
    <Form onSubmit={handleSubmit} initialValues={{ type: "web" }}>
      {
        ({ form, handleSubmit }) => {
          return <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
            <ModalBody
              container
              direction={"column"}
              flexGrow={1}
              gap={"M"}
              className={ImageUploadDialogClasses.Container}>
              <Box container
                   flexGrow={1}
                   gap={"S"}
                   justify={"space-around"}>
                <Box container direction={"column"} gap={"S"} flexBasis={"50%"}>
                  <RadioGroupField label={"Upload from"} name={"type"} options={[
                    {
                      value: "web",
                      label: "From web"
                    },
                    {
                      value: "local",
                      label: "From local"
                    }
                  ]}/>
                  <FormSpy subscription={{ values: true }}>
                    {
                      ({ values }) => {
                        return values.type == "local" ?
                          <ImageInputField
                            style={{ marginTop: 12 }}
                            mode={ImageUploadMode.Simple}
                            name={"imageUpload"}
                            className={ImageUploadDialogClasses.Upload}
                            acceptedTypes={acceptedTypes}/>
                          :
                          <TextField
                            name={"imageUrl"}
                            label={"Profile picture url"}
                            placeholder={"Enter image url"}
                          />;
                      }
                    }
                  </FormSpy>
                </Box>
                <FormWhere
                  conditions={{ and: [{ path: "type", operator: "equals", value: "local" }, { path: "imageUpload", operator: "neqEquals", value: null }] }}>
                  <Field name={"imageUpload"}>
                    {
                      ({ input }) => {
                        return <ImagePreview className={ImageUploadDialogClasses.Preview} img={input.value}/>;
                      }
                    }
                  </Field>
                </FormWhere>
                <FormWhere
                  conditions={{ and: [{ path: `type`, operator: "equals", value: "web" }, { path: "imageUrl", operator: "notWeakEquals", value: null }] }}>
                  <Field name={"imageUrl"}>
                    {
                      ({ input }) => {
                        return <img className={ImageUploadDialogClasses.Preview} src={input.value}
                                    alt="Profile picture"/>;
                      }
                    }
                  </Field>
                </FormWhere>
                <FormWhere
                  conditions={{ and: [{ path: `type`, operator: "equals", value: "web" }, { path: "imageUrl", operator: "weakEquals", value: null }] }}>
                  <img className={classNames(ImageUploadDialogClasses.Preview, ImageUploadDialogClasses.Empty)}
                       src={MissingImageUrl}
                       alt="Profile picture"/>
                </FormWhere>
                <FormWhere
                  conditions={{ and: [{ path: `type`, operator: "equals", value: "local" }, { path: "imageUpload", operator: "weakEquals", value: null }] }}>
                  <img className={classNames(ImageUploadDialogClasses.Preview, ImageUploadDialogClasses.Empty)}
                       src={MissingImageUrl}
                       alt="Profile picture"/>
                </FormWhere>
              </Box>
              <FormSpy
                subscription={{ submitError: true, dirtyFieldsSinceLastSubmit: true, dirtySinceLastSubmit: true, modifiedSinceLastSubmit: true }}>
                {
                  ({ submitError, modifiedSinceLastSubmit }) => {
                    return (submitError && !modifiedSinceLastSubmit) ?
                      <Alert variant={AlertColors.Error} flex={1} stretch>{submitError}</Alert> : null;
                  }
                }</FormSpy>
            </ModalBody>
            <ModalFooter>
              <Button onClick={props.onCancel} variant={ButtonVariants.Outline}>CANCEL</Button>
              <FormSpy
                subscription={{ invalid: true, values: true, submitError: true }}>
                {
                  ({ values }) => {
                    const value = values.type == "local" ? values.imageUpload : values.imageUrl;
                    return <Button type={"submit"} disabled={!value}>SAVE</Button>;
                  }
                }
              </FormSpy>
            </ModalFooter>
          </form>;
        }
      }
    </Form>
  </Modal>;
});

export const enum ImageUploadDialogClasses {
  Modal = "image-upload-dialog-modal",
  Container = "image-upload-dialog__container",
  Upload = "image-upload-dialog__upload",
  Preview = "image-upload-dialog__preview",
  Empty = "image-upload-dialog__preview--empty",
}

export default ImageUploadDialog;