import React                     from "react";
import { classNames }            from "../..";
import { applyBoxItemStyles }    from "../..";
import { BoxItemComponentProps } from "../..";
import { NavBarClasses }         from "./NavBarClasses";

export interface NavBarProps extends BoxItemComponentProps {
}

export const NavBar = React.forwardRef(function NavBar(props: NavBarProps, ref: React.Ref<HTMLDivElement>) {
  const properties = applyBoxItemStyles<NavBarProps>(props);
  const { className, children, ...p } = properties;
  const classes = classNames(NavBarClasses.NavBar, className);
  return <aside ref={ref} {...p} className={classes}>
    {children}
  </aside>;
});
