import { Link }                                                 from "@relcu/ui";
import { CommonClasses }                                        from "@relcu/ui";
import React                                                    from "react";
import { TimelineItem }                                         from "@relcu/ui";
import { TypographyColor }                                      from "@relcu/ui";
import { Typography }                                           from "@relcu/ui";
import { TimelineItemSubject }                                  from "@relcu/ui";
import { TimelineItemIconColors }                               from "@relcu/ui";
import { TimelineItemIcon }                                     from "@relcu/ui";
import { PrioritizationActivity as PrioritizationActivityType } from "../../../../__types__/PrioritizationActivity";

export interface FocusActivityProps extends PrioritizationActivityType {
  createdAt: string;
}

export const PrioritizationActivity = React.memo<FocusActivityProps>(function PrioritizationActivity(props) {
  const { prioritizationQueue, prioritizationSubject, prioritizationUser, prioritizationEvent, createdAt } = props;
  const isEntered = prioritizationEvent === "enter";
  return <TimelineItem
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default}
                              icon={isEntered ? "rc_section_left" : "rc_section_entered"}/>}
    primary={<TimelineItemSubject>
      {prioritizationSubject?.objectId ?
        <Link to={`/lead/${prioritizationSubject.objectId}`}>
          <Typography
            className={CommonClasses.ClickableIcon}>{prioritizationSubject?.objectName || "Unknown"}</Typography>
        </Link>
        :
        <Typography>{prioritizationSubject?.objectName || "Unknown"}</Typography>
      }
      <Typography color={TypographyColor.Secondary}>{isEntered ? "entered" : "left"}</Typography>
      {
        prioritizationUser?.objectId ?
          <Link to={`/user/${prioritizationUser.objectId}/focus`}>
            <Typography className={CommonClasses.ClickableIcon}>{prioritizationQueue?.objectName || "Unknown"}</Typography>
          </Link>
          :
          <Typography>{prioritizationQueue?.objectName || "Unknown"}</Typography>
      }
      <Typography color={TypographyColor.Secondary}>section</Typography>
    </TimelineItemSubject>}
    creationDate={createdAt}
  />;
});
