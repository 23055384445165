import { SyntheticEvent }     from "react";
import { ReactNode }          from "react";
import React                  from "react";
import { FC }                 from "react";
import { TypographySize }     from "../..";
import { TypographyWeight }   from "../..";
import { TypographyLine }     from "../..";
import { TypographyColor }    from "../..";
import { Typography }         from "../..";
import { Button }             from "../..";
import { classNames }         from "../..";
import { Box }                from "../Box";
import { applyBoxItemStyles } from "../Box";
import { BoxComponentProps }  from "../Box";
import { FontIcon }           from "../Icon";
import { IconType }           from "../Icon";
import { EmptyListClasses }   from "./EmptyListClasses";

export interface EmptyListProps extends Omit<BoxComponentProps, "title"> {
  icon?: IconType;
  title?: ReactNode;
  content?: string | ReactNode;
  small?: boolean;
  onClick?(e: SyntheticEvent);
}

export const EmptyList: FC<EmptyListProps> = React.memo((props) => {
  let properties = applyBoxItemStyles<EmptyListProps>(props);
  const { className, content, title, icon, small, onClick, ...p } = properties;
  const classes = classNames(EmptyListClasses.EmptyList, { [ EmptyListClasses.EmptyListSmall ]: small }, className);

  return <Box container direction={"column"} alignItems={"center"} className={classes} gap={"XXS"} {...p}>
    {icon &&
      <Box className={EmptyListClasses.EmptyListIconContainer} container justify={"center"} alignItems={"center"}>
        <FontIcon type={icon}/>
      </Box>}
    {
      title &&
      <Typography lineHeight={TypographyLine.Line17} weight={TypographyWeight.Medium}>{title}</Typography>
    }
    {
      content &&
      <Typography lineHeight={TypographyLine.Line13} size={small ? TypographySize.TextTiny : TypographySize.TextSmall}
                  color={TypographyColor.Secondary}>{content}</Typography>
    }
    {
      onClick &&
      <Button alignSelf={"center"} icon={"add"} onClick={onClick}>
        Create
      </Button>
    }
  </Box>;
});
