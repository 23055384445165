import React                    from "react";
import { FC, ReactNode }        from "react";
import { CommonClasses }        from "../../../../theme/classes";
import { classNames }           from "../../../../utils/classNames";
import { AvatarSizes }          from "../../../Avatar";
import { Avatar }               from "../../../Avatar";
import { Box }                  from "../../../Box";
import { IconType }             from "../../../Icon";
import { FontIcon }             from "../../../Icon";
import { AccessibilityClasses } from "../AccessibilityClasses";

export enum AccessibilityVariant {
  Standard = "standard",
  Tiny = "tiny",
}

export enum AccessibilityOrientation {
  Top = "top",
  Middle = "middle",
  Space = "space"
}

export interface AccessibilityInterface {
  active?: boolean
  operative?: boolean
  initiator?: string
  initiatorName?: string | ReactNode
  initiatorIcon?: string
  date?: string
  state?: string
  icon?: IconType | ReactNode
  content: string | ReactNode
  variant?: AccessibilityVariant
  orientation?: AccessibilityOrientation
  actions?: ReactNode
  onNavigate?()
}

export const Accessibility: FC<AccessibilityInterface> = React.memo(function Accessibility(props) {
  const { active, initiator, date, icon, content, onNavigate, actions, state, orientation, variant, initiatorIcon, initiatorName, operative, ...p } = props;
  return (
    <Box container direction={"row"}
         className={classNames(AccessibilityClasses.Accessibility, {[ AccessibilityClasses.AccessibilityActive ]: active})} gap={"XXS"} onClick={onNavigate} tabIndex={0} {...p}>
      <Box container direction={"column"} gap={"XXXS"} alignSelf={"stretch"}
           justify={orientation == AccessibilityOrientation.Top ? "start" : "center"}>
        <Avatar text={initiator as string}
                icon={initiatorIcon}
                size={orientation == AccessibilityOrientation.Middle ? AvatarSizes.Medium : AvatarSizes.Small}
                flexGrow={0}
                flexShrink={0}/>
        {
          (orientation == AccessibilityOrientation.Space || !orientation) &&
          (
            React.isValidElement(icon) ?
              icon :
              <FontIcon type={icon as IconType} className={classNames(AccessibilityClasses.AccessibilityIcon)}/>
          )
        }
      </Box>
      <Box container direction={"column"} gap={orientation != AccessibilityOrientation.Space ? "XXXS" : null}
           alignSelf={"stretch"} flex={1}
           justify={"space-between"}
           className={AccessibilityClasses.AccessibilityContent}>
        <Box container gap={"XXS"} alignItems={"center"}>
          {
            initiatorName ?
              React.isValidElement(initiatorName) ? initiatorName :
                <p
                  className={classNames(AccessibilityClasses.AccessibilityInitiator, CommonClasses.FlexEllipsis)}>{initiatorName}</p>
              :
              <p
                className={classNames(AccessibilityClasses.AccessibilityInitiator, CommonClasses.FlexEllipsis)}>{initiator}</p>
          }
          <Box flexGrow={1}/>
          <Box flexShrink={0} className={AccessibilityClasses.AccessibilityItemDate} flexGrow={0}>
            {
              date ?? state
            }
          </Box>
        </Box>
        <Box container gap={"XXS"} alignItems={"center"}>
          {
            React.isValidElement(content) ?
              content :
              <p className={classNames(AccessibilityClasses.AccessibilityDescription, {
                [ AccessibilityClasses.AccessibilityDescriptionActive ]: operative && variant != AccessibilityVariant.Tiny,
                [ AccessibilityClasses.AccessibilityDescriptionTiny ]: variant == AccessibilityVariant.Tiny
              }, CommonClasses.FlexEllipsis)}>{content}</p>
          }
        </Box>
      </Box>
      {actions}
    </Box>
  );
});

Accessibility.defaultProps = {
  orientation: AccessibilityOrientation.Space,
  variant: AccessibilityVariant.Standard
};
