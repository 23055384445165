import React                   from "react";
import { FC }                  from "react";
import { useBoxProps }         from "../../../../Box";
import { Box }                 from "../../../../Box";
import { MicrophoneAccess }    from "../../../../Microphone";
import { Section }             from "../../../../Section";
import { DevicesProps }        from "./Components/Devices";
import { Devices }             from "./Components/Devices";
import { SoundSectionClasses } from "./SoundSectionClasses";
import { TwilioInfo }          from "./Components/TwilioInfo";

export const SoundSection: FC<SoundSectionProps> = React.memo(function SoundSection(props) {
  const { microphoneAccess, className, outputId, inputId, devices, onInput, onOutput, ...rest } = props;
  const devicesProps = { className, outputId, inputId, devices, onInput, onOutput };
  return (
    <Section editable={false}>
      {
        !microphoneAccess ?
          <MicrophoneAccess/> :
          <Box container flex={1} wrap={"wrap"} className={SoundSectionClasses.Sound}>
            <Devices flexBasis={"68%"} {...devicesProps} {...useBoxProps(rest)} />
            <TwilioInfo flexBasis={"32%"}/>
          </Box>
      }
    </Section>
  );
});
export interface SoundSectionProps extends DevicesProps {
  microphoneAccess?: boolean
}
