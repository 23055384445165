import { gql }         from "@apollo/client";
import { useQuery }    from "@apollo/client";
import { Box }         from "@relcu/ui";
import React           from "react";
import { FC }          from "react";
import { GetWfaToken } from "./__types__/GetWfaToken";

import "./automation.css";

export const AutomationView: FC = React.memo(function ReportingView() {
  const { data: { viewer: { wfaToken } = {} } = {}, loading } = useQuery<GetWfaToken>(GET_WFA_TOKEN, { fetchPolicy: "network-only" });
  if (!wfaToken && loading) {
    return null;
  }
  //{ path: , token: this.#getJwt() }
  let path = encodeURIComponent("/recipes")
  let url = `https://app.workato.com/direct_link?workato_dl_path=${path}&workato_dl_token=${wfaToken}`;
  return <Box container direction={"row"} className={"analytics"}>
    <iframe id={"workato"} src={url}/>
  </Box>;
});
const GET_WFA_TOKEN = gql`
  query GetWfaToken{
    viewer {
      wfaToken
    }
  }`;
