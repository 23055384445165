import React                from "react";
import { FC }               from "react";
import SunEditor            from "suneditor-react";
import {}            from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "./editor.css";
import { SunEditorOptions } from "suneditor/src/options";

export interface EditorProps {
  value: string;
  onChange(content: string);
  getSunEditorInstance?;
  plugins?;
  buttonList?: any [];
  options?: SunEditorOptions;
}

export const Editor: FC<EditorProps> = React.memo(function Editor(props) {
  const { value, onChange, getSunEditorInstance, plugins, buttonList = [], options = {} } = props;

  return <SunEditor
    placeholder={"Type a message..."}
    setContents={value}
    onChange={onChange}
    setAllPlugins={true}
    getSunEditorInstance={getSunEditorInstance}
    height={"100%"}
    setOptions={{
      showPathLabel: false,
      toolbarContainer: "#custom_toolbar",
      plugins: plugins,
      buttonList: [
        ["bold", "underline", "italic", "fontSize", "fontColor", "hiliteColor", "outdent", "indent", "align", "list", "link",
          ...buttonList]
      ],
      ...options
    }}>
    <div></div>
  </SunEditor>;
});
