import React                   from "react";
import { FC }                  from "react";
import { useCallback }         from "react";
import get                     from "lodash/get";
import Collapse                from "../Animation/Collapse";
import { AnimationEventProps } from "../@types/common";
import { WithAsProps }         from "../@types/common";
import { useClassNames }       from "../utils";
import { useControlled }       from "../utils";

export interface AccordionProps<T = string | number> extends WithAsProps, AnimationEventProps {
  /** Whether it is a collapsible panel */
  collapsible?: boolean;

  /** Show border */
  headerBordered?: boolean;

  /** With shadow */
  shaded?: boolean;

  /** Content area filled with containers */
  bodyFill?: boolean;

  /** The head displays information. */
  header?: ((expanded: boolean) => React.ReactNode) | React.ReactNode;

  /** ID */
  id?: string | number;

  /** Expand then panel by default */
  defaultExpanded?: boolean;

  /** Expand then panel */
  expanded?: boolean;

  /** The event key corresponding to the panel. */
  eventKey?: T;

  /** Role of header */
  headerRole?: string;

  /** Role of Panel */
  panelRole?: string;

  /** callback function for the panel clicked */
  onSelect?: (eventKey: T | undefined, event: React.SyntheticEvent) => void;

  headerProps?: { [ key: string ]: any };
  bodyClassName?: string
  headerClassName?:string
}

export const Accordion: FC<AccordionProps> = React.forwardRef((props, ref) => {
  const {
    id,
    as: Component = "div",
    collapsible = true,
    eventKey,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    children,
    bodyFill,
    expanded: expandedProp = undefined,
    defaultExpanded,
    classPrefix = "accordion",
    onSelect,
    className,
    headerClassName,
    bodyClassName,
    header,
    headerProps,
    ...rest
  } = props;
  const { merge, prefix, withClassPrefix } = useClassNames(classPrefix);
  const [expandedState, setExpanded] = useControlled(
    expandedProp,
    defaultExpanded
  );
  let expanded = expandedState;
  const handleSelect = useCallback(
    (event: React.MouseEvent) => {
      onSelect?.(eventKey, event);
      setExpanded(!expanded);
    },
    [eventKey, expanded, onSelect, setExpanded]
  );
  const renderHeading = () => {
    if (!header) {
      return null;
    }

    let panelTitleElement: React.ReactNode;
    const className = merge(prefix("title"), get(header, "props.className"));

    if (!!React.isValidElement(header)) {
      panelTitleElement = React.cloneElement<any>(header, { className });
    } else if (typeof header == "function") {
      panelTitleElement = React.cloneElement<any>(header(expanded), { className });
    } else {
      panelTitleElement = (
        <span className={prefix("title")} role="presentation">
            <span className={expanded ? undefined : "collapsed"}>{header}</span>
        </span>
      );
    }

    return (
      <div
        role={"button"}
        aria-controls={collapsible && id ? `${id}` : undefined}
        aria-expanded={expanded}
        className={merge(headerClassName, prefix("header"), withClassPrefix({ bordered: headerProps?.headerBordered}))}
        onClick={collapsible ? handleSelect : undefined}
        tabIndex={-1}
      >
        {/*{collapsible && <AngleDownIcon rotate={expanded ? 180 : 0} data-testid="caret icon"/>}*/}
        {panelTitleElement}
      </div>
    );
  };

  const renderCollapsibleBody = () => (
    <Collapse
      in={expanded}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      {(transitionProps, ref) => {
        const { className, ...rest } = transitionProps;
        return (
          <div
            {...rest}
            id={id ? `${id}` : null}
            aria-expanded={expanded}
            className={merge(className, prefix("collapse"))}
            ref={ref}
          >
            {renderBody()}
          </div>
        );
      }}
    </Collapse>
  );

  const renderBody = useCallback(() => {
    const classes = prefix("body", {
      "body-fill": bodyFill
    });

    return (
      <div role={"region"} className={merge(classes, bodyClassName)}>
        {children}
      </div>
    );
  }, [bodyFill, children, prefix]);

  const classes = merge(
    className,
    withClassPrefix({ in: expanded, collapsible })
  );

  return <Component {...rest} ref={ref} className={classes} id={collapsible ? null : id}>
    {renderHeading()}
    {renderCollapsibleBody()}
  </Component>;
});

Accordion.defaultProps = {};

export default Accordion;
