import React                          from "react";
import { FC }                         from "react";
import { useState }                   from "react";
import { Button }                     from "../../Button";
import { ButtonVariants }             from "../../Button";
import { Box }                        from "../../Box";
import { EmptyList }                  from "../../EmptyList";
import { IconType }                   from "../../Icon";
import { Modal }                      from "../Modal";
import { ModalBody }                  from "../Modal";
import { ModalFooter }                from "../Modal";
import { ModalProps }                 from "../Modal";
import { StateWarningDialogClasses }  from "./StateWarningDialogClasses";
import { Typography }                 from "../../Typography";
import { TypographyColor }            from "../../Typography";
import { TypographySize }             from "../../Typography";
import { TypographyVariant }          from "../../Typography";

export interface StateWarningDialogProps extends Partial<ModalProps> {
  leads?: string[],
  name?: string,
  icon?: IconType,
  onClose?(e?),
  onSkipMismatches?(e?),
  onAssignAllAnyway?(e?),
  title?,
  content?
}
export const StateWarningDialog: FC<StateWarningDialogProps> = React.memo(function ConfirmDialog(props) {
  const [showMore, setShowMore] = useState(false);

  return <Modal className={StateWarningDialogClasses.Modal}
                title={"Licensed states contradiction"}
                onClose={props.onClose} open={props.open} closeIcon={!!props.closeIcon}
                disableBackdropClick={true}>
    <ModalBody className={StateWarningDialogClasses.Container} flexGrow={1} gap={"XS"} container direction={"column"}>
      <EmptyList className={StateWarningDialogClasses.Body}
                 title={props.title || "This user is not licensed to work with some of the selected leads"}
                 content={props.content || "The user’s licensed states do not match with the leads’ property states. Do you want to skip leads with mismatching states?"}
                 icon={props.icon || "warning"}/>
      {props.leads.length &&
        <Box container flex={1} justify={"center"} alignItems={"center"}>
          <Box>

            <Typography variant={TypographyVariant.span} color={TypographyColor.Secondary} size={TypographySize.TextSmall}>
              {props.leads.length > 1 ? "Leads" : "Lead"}:{' '}
            </Typography>
            {showMore && (
              <Typography variant={TypographyVariant.span} size={TypographySize.TextSmall}>
                {props.leads.toString().replaceAll(',', ', ')}
              </Typography>
            ) || (
              <Typography variant={TypographyVariant.span} size={TypographySize.TextSmall}>
                {props.leads.slice(0,5).toString().replaceAll(',', ', ')}{"..."}
              </Typography>
            )}

            {props.leads.length > 5 &&
                <Button variant={ButtonVariants.Ghost} onClick={() => { setShowMore(!showMore) } }>
                    Show {showMore ? 'less' : 'more' }
                </Button>
            }
          </Box>
          <Box>

          </Box>
        </Box>
      }
    </ModalBody>
    <ModalFooter>
      <Button variant={ButtonVariants.Outline}
              onClick={props.onAssignAllAnyway}>ASSIGN ALL ANYWAY</Button>
      <Button onClick={props.onSkipMismatches}>SKIP MISMATCHES</Button>
    </ModalFooter>
  </Modal>;
});
export default StateWarningDialog;
