import { gql }                                       from "@apollo/client";
import { useQuery }                                  from "@apollo/client";
import { getIn }                                     from "@relcu/form";
import { useSource }                                 from "@relcu/ui";
import { LoanEstimateOffer }                         from "../../../../graph/__types__/LoanEstimateOffer";
import { FILE_FRAGMENT }                             from "../../../../graph/operations.graphql";
import { getHumanized }                              from "../../../../utils/schemaUtils";
import { usePreviewLoanProposal }                    from "../../../Modal/ProposalPdfModal/usePreviewLoanProposal";
import { GetLoanEstimates_loanEstimates_edges_node } from "./__types__/GetLoanEstimates";
import { GetLoanEstimatesVariables }                 from "./__types__/GetLoanEstimates";
import { GetLoanEstimates }                          from "./__types__/GetLoanEstimates";

type LoanEstimate = GetLoanEstimates_loanEstimates_edges_node
export function useLoanEstimateView() {
  const { $object, $viewer } = useSource();
  const { preview, modalContext } = usePreviewLoanProposal($object.objectId);
  const { data: { loanEstimates: { edges = [], count } = {} } = {}, loading } = useQuery<GetLoanEstimates, GetLoanEstimatesVariables>(GET_LOAN_ESTIMATES, {
    variables: {
      where: {
        AND: [
          {
            lead: {
              have: {
                id: {
                  equalTo: $object.id
                }
              }
            }
          },
          {
            draft: {
              equalTo: false
            }
          }
        ]
      }
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  return {
    edges,
    loading,
    count,
    modalContext,
    viewerRole: $viewer.role,
    objectId: $viewer.objectId,
    loanEstimates: edges,
    getColumnValue(row, column,loanEstimate) {
      if (column.name == "cashAtClosing") {
        const cashAtClosing = row["cashToClose"]
        column.properties = {
          ...column.properties,
          "data-support-text": cashAtClosing > 0 ? "(From you)" : "(To you)"
        };
        return getHumanized(row.__typename, column.name, Math.abs(cashAtClosing));
      }
      if (column.name == "monthlyPayment.total") {
        return getHumanized(row.__typename, column.name,  row["monthlyPaymentTotal"]);
      }
      return getHumanized(row.__typename, column.name, getIn(row, column.name));
    },
    onPreview({ file, objectId, image }: Partial<LoanEstimate>) {
      preview({file,loanEstimateId:objectId,image});
    }
  };
}

export const GET_LOAN_ESTIMATES = gql`
  query GetLoanEstimates($where: LoanEstimateWhereInput){
    loanEstimates(where: $where,order: [createdAt_DESC]){
      count
      edges {
        node {
          objectName
          id
          loanPurpose
          objectId
          lead {
            id
          }
          file {
            ...File
          }
          image {
            ...File
          }
          offers {
            edges {
              node {
                cashToClose
                monthlyPaymentTotal
                objectName
                createdAt
                apr
                rate
                price
              }
            }
          }
        }

      }
    }
  }
  ${FILE_FRAGMENT}
`;
