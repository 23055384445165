import { gql }                                         from "@apollo/client";
import { useMemo }                                     from "react";
import { useEffect }                                   from "react";
import { useCallback }                                 from "react";
import { useState }                                    from "react";
import { useContext }                                  from "react";
import { useMutation }                                 from "@apollo/client";
import { useQuery }                                    from "@apollo/client";
import { ApolloCache }                                 from "@apollo/client";
import { useForm }                                     from "@relcu/form";
import { useAlert }                                    from "@relcu/ui";
import { InlineEditableInputProps }                    from "@relcu/ui";
import { useSearchParams }                             from "@relcu/react-router";
import { base64urlDecode }                             from "@relcu/ui";
import { CreateFilterVariables }                       from "./__types__/CreateFilter";
import { CreateFilter }                                from "./__types__/CreateFilter";
import { DeleteFilterVariables }                       from "./__types__/DeleteFilter";
import { DeleteFilter }                                from "./__types__/DeleteFilter";
import { GetFilters_filters_edges_node_rules_Element } from "./__types__/GetFilters";
import { GetFilters_filters_edges_node }               from "./__types__/GetFilters";
import { GetFiltersVariables }                         from "./__types__/GetFilters";
import { GetFilters }                                  from "./__types__/GetFilters";
import { UpdateFilterVariables }                       from "./__types__/UpdateFilter";
import { UpdateFilter }                                from "./__types__/UpdateFilter";
import { FilterContext }                               from "./FilterProvider";

type FilterElement = GetFilters_filters_edges_node_rules_Element
type Filter = GetFilters_filters_edges_node;
export function useFilters() {
  const { target, onApply, viewerId } = useContext(FilterContext);
  const [searchParams] = useSearchParams();
  const form = useForm();
  const urlSelected = searchParams.get("filter");
  const { filterBy = null, filterData = null } = useMemo(() => {
    if (urlSelected) {
      const [by, f] = urlSelected.split(":");
      if (by && f) {
        return { filterBy: by, filterData: f };
      }
    }
    return {};
  }, [urlSelected]);
  const appliedFilter = useMemo(() => {
    if (filterBy == "ID") {
      return filterData;
    }
    return null;
  }, [filterBy, filterData]);
  const [selected, setSelected] = useState("new");
  const [current, setCurrent] = useState<InlineEditableInputProps>(null);
  const { error } = useAlert();
  const { data: { filters: { edges = [], count } = {} } = {} } = useQuery<GetFilters, GetFiltersVariables>(GET_FILTERS, {
    //fetchPolicy: "cache-and-network",
    variables: { target }
  });

  const selectedFilter = useMemo(() => {
    if (!appliedFilter) {
      return null;
    }
    const edge = edges.find(({ node }) => node.objectId === appliedFilter);
    if (!edge) {
      return null;
    }
    return edge.node.rules.map((element: FilterElement) => element.value);
  }, [appliedFilter, edges]);
  const [create] = useMutation<CreateFilter, CreateFilterVariables>(CREATE_FILTER, {
    update(cache, { data: { createFilter: { filter } } }) {
      let data: any = readFilter(cache);
      if (!data) {
        data = { filters: { edges: [], count: 0 } };
      }
      let edges = [
        ...data.filters.edges,
        {
          __typename: "FilterEdge",
          node: filter
        }
      ];
      cache.writeQuery<GetFilters, GetFiltersVariables>({
        query: GET_FILTERS,
        variables: { target },
        data: {
          ...data,
          filters: {
            ...data.filters,
            edges: edges,
            count: edges.length
          }
        }
      });
    }
  });
  const [update] = useMutation<UpdateFilter, UpdateFilterVariables>(UPDATE_FILTER);
  const [remove] = useMutation<DeleteFilter, DeleteFilterVariables>(DELETE_FILTER, {
    update(cache, { data: { deleteFilter: { filter: { id, objectId } } } }) {
      let data: any = readFilter(cache);
      if (!data) {
        data = { filters: { edges: [], count: 0 } };
      }
      let edges = data.filters.edges.filter(({ node }) => node.id !== id);
      cache.writeQuery<GetFilters, GetFiltersVariables>({
        query: GET_FILTERS,
        variables: { target },
        data: {
          ...data,
          filters: {
            ...data.filters,
            edges: edges,
            count: edges.length
          }
        }
      });
      cache.evict({ id: cache.identify({ __typename: "Filter", id }) });
      if (selected == objectId) {
        onReset();
      }
    }
  });
  const onAdd = useCallback(() => setCurrent({
    value: "",
    isEditing: true,
    onChange(value) {
      setCurrent(prevState => ({ ...prevState, value }));
    },
    onCancel() {
      setCurrent(null);
    },
    async onConfirm(event, name) {
      try {
        const { values: { rules = [] } } = form.getState();
        const { data: { createFilter: { filter: { objectId } = {} } = {} } = {} } = await create({
          variables: {
            name,
            target,
            userId: viewerId,
            rules
          }
        });
        setSelected(objectId || null);
        setCurrent(null);
        form.change("__reset", true);
        form.change("filterId", objectId);
        form.change("rules", rules);
        form.change("__reset", false);
      } catch (e) {
        error(e.message);
        setCurrent(null);
      }
    }
  }), [target, viewerId, form]);
  function readFilter(cache: ApolloCache<CreateFilter | DeleteFilter>) {
    return cache.readQuery<GetFilters, GetFiltersVariables>({
      query: GET_FILTERS,
      variables: { target }
    });
  }
  function select(by, f) {
    try {
      if (!by || !f) {
        return;
      }
      if (by == "ID") {
        const edge = edges.find(({ node }) => node.objectId === f);
        if (!edge) {
          return;
        }
        const filter = edge.node;
        const rules = filter.rules.map((element: FilterElement) => element.value);
        onApply({ rules });

        onFill(filter);
      } else if (by == "R") {
        let rules = JSON.parse(base64urlDecode(f));
        onApply({ rules });
        form.change("__reset", true);
        form.change("rules", rules);
        form.change("__reset", false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  function onFill(filter: Filter) {
    const rules = filter.rules.map((element: FilterElement) => element.value);
    form.change("__reset", true);
    form.change("rules", rules);
    form.change("filterId", filter.objectId);
    form.change("__reset", false);
    setSelected(filter.objectId);
  }
  function onUpdate(filter: Filter) {
    try {
      return update({
        variables: {
          input: {
            id: filter.id,
            fields: {
              name: filter.name
            }
          }
        }
      });
    } catch (e) {
      console.error(e);
      error(e.message);
      setCurrent(null);
    }

  }
  async function onRemove(id: string, objectId: string) {
    await remove({
      variables: { id }
    });
    if (objectId == selected) {
      setSelected("new");
    }
  }

  function onReset() {
    form.setConfig("keepDirtyOnReinitialize", false);
    form.reset();
    form.setConfig("keepDirtyOnReinitialize", true);
  }
  function onNew() {
    onReset();
    setSelected("new");
  }

  useEffect(() => {
    select(filterBy, filterData);
  }, [filterBy, filterData, selectedFilter]);

  return {
    count,
    edges,
    onUpdate,
    onRemove,
    onAdd,
    onFill,
    onReset,
    onNew,
    current,
    selected,
    urlSelected,
    appliedFilter
  };
}

export const FILTER_FRAGMENT = gql`
  fragment Filter on Filter {
    id
    objectId
    name
    target
    rules {
      ... on Element {
        value
      }
    }
  }
`;
export const GET_FILTERS = gql`
  query GetFilters($target: String!) {
    filters(where: { target: { equalTo: $target } }) {
      count
      edges {
        node {
          ...Filter
        }
      }
    }
  }
  ${FILTER_FRAGMENT}
`;
export const CREATE_FILTER = gql`
  mutation CreateFilter(
    $userId: ID!
    $name: String!
    $target: String!
    $rules: [Any]
  ) {
    createFilter(
      input: {
        fields: {
          ACL: {
            public: { read: false, write: false }
            users: [{ userId: $userId, read: true, write: true }]
          }
          name: $name
          rules: $rules
          target: $target
        }
      }
    ) {
      filter {
        ...Filter
      }
    }
  }
  ${FILTER_FRAGMENT}
`;
export const UPDATE_FILTER = gql`
  mutation UpdateFilter($input: UpdateFilterInput!){
    updateFilter(input:$input){
      filter {
        ...Filter
      }
    }
  }
  ${FILTER_FRAGMENT}
`;
export const DELETE_FILTER = gql`
  mutation DeleteFilter($id: ID!) {
    deleteFilter(input: { id: $id }) {
      filter {
        id
        objectId
      }
    }
  }
`;
