import React                 from "react";
import { ItemsEllipsis }     from "../../..";
import { ListItemClasses }   from "..";
import { classNames }        from "../../..";
import { ChipsSizes }        from "../../..";
import { ChipsColors }       from "../../..";
import { Chips }             from "../../..";
import { Box }               from "../../Box";
import { BoxComponentProps } from "../../Box";

export interface ListItemArrayProps extends BoxComponentProps {
  items?: object[]
  showLength: number,
  label?: string
}

ListItemArray.defaultProps = {
  showLength: 1
};
export function ListItemArray(props: ListItemArrayProps) {
  const { className, items, showLength, label, ...p } = props;
  const classes = classNames(ListItemClasses.ListItemTags, className);
  return <Box container alignItems={"center"} gap={"XXS"} flexBasis={145} flexGrow={1} className={classes} {...p}>
    <ItemsEllipsis
      showLength={showLength}
      items={items}
      renderItem={(item, index) => <Chips
        length={25}
        key={index}
        color={ChipsColors.Grey}
        label={item}
        size={ChipsSizes.Small}
      />}
      ellipsis={(count) => <React.Fragment>{`+${count}`}</React.Fragment>}/>
  </Box>;
}
