import React                          from "react";
import { FC }                         from "react";
import { useCallback }                from "react";
import { useRef }                     from "react";
import { CSSTransition }              from "react-transition-group";
import { HorizontalDivider }          from "@relcu/ui";
import { classNames }                 from "@relcu/ui";
import { BoxComponentProps }          from "@relcu/ui";
import { Box }                        from "@relcu/ui";
import { StatusBar }                  from "@relcu/ui";
import { soundVar }                   from "../../reactiveVars";
import { Call }                       from "./Call";
import { BarToggleButton }            from "./BarToggleButton";
import { DialBarButton }              from "./DialBarButton";
import { Five9 }                      from "./Five9";
import { LeadQueues }                 from "./LeadQueues";
import { NotificationBadge }          from "./Notifications/NotificationBadge";
import { NotificationBar }            from "./Notifications/NotificationBar";
import { Push }                       from "./Push";
import { PushBarToggleButton }        from "./PushBarToggleButton";
import { UnreadNotificationProvider } from "./UnreadNotificationProvider";
import { useSnackBar }                from "./useSnackBar";
import { InAppNotification }          from "./InAppNotification/InAppNotification";
import { DialBar }                    from "./Dial/DialBar";
import "./snack-bar.css";

const STATUS_INFO = {
  away: {
    title: `You are “Away”`,
    description: "To start receiving calls and managing leads change your status to “Available.”"
  },
  busy: {
    title: `You are “Busy”`,
    description: "You will receive only direct calls and SMS."
  },
  out_of_work: {
    title: `You are “Out of office”`,
    description: "If you receive incoming calls or SMS your predefined voicemail or SMS templates will be automatically sent out."
  }
};

export const SnackBar: FC<BoxComponentProps> = React.memo((props) => {
  const { className } = props;
  const {
    showLeadQueue,
    status,
    showCall,
    snackBarState,
    toggleSnackBar,
    lastNotificationSeenAt,
    id,
    userObjectId,
    userRole,
    updateStatus,
    showStatusBar,
    notificationControls,
    isSystemCall
  } = useSnackBar();
  const sound = soundVar();

  // useEffect(() => {
  //   if (showCall) {
  //     sound.pause("call-increment");
  //     sound.pause("lead-increment");
  //     sound.pause("sms");
  //   }
  // }, [showCall]);//todo pause all non call ring on call

  const classes = classNames("snack-bar", className);
  const mainContainerRef = useRef(null);
  const supportContainerRef = useRef(null);
  const renderBar = useCallback(() => {
    switch (snackBarState) {
      case "notifications" :
        return <NotificationBar/>;
      case "queues":
        return <div className={"distribution-bar"}>
          {
            showStatusBar &&
            <StatusBar
              {...STATUS_INFO[ status ]}
              collapsed={false}
              onAvailable={(e, status) => updateStatus(status)}
              status={status}/>
          }
          {showLeadQueue && <LeadQueues collapsed={false} showCall={showCall}/>}
        </div>;
      case "push":
        return <Push/>;
      case "dial":
        return <DialBar userRole={userRole}/>;
      default:
        return <div style={{ width: 300, height: "100%", backgroundColor: "var(--color-white)" }}/>;
    }
  }, [snackBarState, showStatusBar, status, showLeadQueue, showCall]);
  return (
    <>
      <UnreadNotificationProvider lastNotificationSeenAt={lastNotificationSeenAt} id={id} userObjectId={userObjectId}>
        <div className={classes}>
          <div className={"snack-bar__support-container"}>
            <PushBarToggleButton
              state={snackBarState}
              actualState={"push"}
              onClick={() => toggleSnackBar("push")}
              icon={"call_merge"}
              disabled={showCall}
              active={snackBarState == "push" && !isSystemCall}
            />
            <Box flexBasis={24}/>
            <HorizontalDivider style={{ width: "100%" }}/>
            <Box flexBasis={24}/>
            <BarToggleButton
              state={snackBarState}
              actualState={"queues"}
              onClick={() => toggleSnackBar("queues")}
              icon={"call_split"}
              disabled={showCall}
              active={snackBarState == "queues" && !isSystemCall}
            />
            <Box flexBasis={16}/>
            {
              snackBarState != "dial" && showCall &&
              <>
                <Box flexBasis={8}/>
                <Call collapsed={true}/>
              </>
            }
            <CSSTransition
              in={showLeadQueue && snackBarState != "queues"}
              nodeRef={supportContainerRef}
              timeout={500}
              classNames="snack-bar__support-distribution"
              unmountOnExit
              mountOnEnter
              addEndListener={(done) => {
                supportContainerRef.current.addEventListener("transitionend", (e) => {
                  done(e);
                }, false);
              }}>
              <LeadQueues collapsed={true} ref={supportContainerRef} showCall={showCall}/>
            </CSSTransition>
            <Box flexBasis={16}/>
            <Box flex={1}/>
            <HorizontalDivider style={{ width: "100%" }}/>
            <Box flexBasis={8}/>
            {window.__CONFIG__.f9 &&
              <BarToggleButton
                state={snackBarState}
                actualState={"f9"}
                onClick={() => toggleSnackBar("f9")}
                icon={"five9"}
                disabled={isSystemCall}
                active={snackBarState == "f9"}
              />
            }
            <DialBarButton
              state={snackBarState}
              actualState={"dial"}
              onClick={() => toggleSnackBar("dial")}
              icon={"perm_phone_msg"}
              disabled={isSystemCall}
              active={snackBarState == "dial"}
            />
            <NotificationBadge toggleSnackBar={toggleSnackBar} snackBarState={snackBarState}/>
          </div>
          <CSSTransition
            in={!!snackBarState}
            nodeRef={mainContainerRef}
            timeout={500}
            classNames="snack-bar__main-container"
            unmountOnExit
            mountOnEnter
            addEndListener={(done) => {
              mainContainerRef.current.addEventListener("transitionend", (e) => {
                done(e);
              }, false);
            }}
          >
            {
              (status) => (
                <div className={"snack-bar__main-container"} ref={mainContainerRef}
                     style={{ display: status == "exited" ? "none" : "flex" }}>
                  {
                    window.__CONFIG__.f9 &&
                    <div id={"f9-frame"}
                         style={{ display: snackBarState === "f9" ? "flex" : "none", width: "100%", position: "relative" }}>
                      <Five9 key={"five9"}/>
                    </div>
                  }
                  {snackBarState !== "f9" && renderBar()}
                </div>
              )
            }
          </CSSTransition>
        </div>
      </UnreadNotificationProvider>
      <InAppNotification snackBarState={snackBarState} notificationControls={notificationControls}/>
    </>
  );
});
