import { useMemo }     from "react";
import React           from "react";
import { FC }          from "react";
import { Cell }        from "rsuite-table";
import { Alignment }   from "../../../../constants/Alignemnt";
import { Box }         from "../../../Box";
import { ButtonSizes } from "../../../Button";
import { Button }      from "../../../Button";
import { Tooltip }     from "../../../Tooltip";
import { Actions }     from "../../Table";
import { ICellProps }  from "../ICellProps";

interface ActionsCellProps extends Omit<ICellProps, "getCellValue"> {
  actions: Actions;
}
export const ActionsCell: FC<ActionsCellProps> = React.memo(function TextCell({ rowData, dataKey, actions, ...props }) {
  const actionSet = useMemo(() => {
    if (typeof actions == "function") {
      return actions(rowData);
    } else {
      return actions;
    }
  }, [actions]);

  return <Cell {...props} >
    <Box container alignSelf={"start"} gap={"XXS"} flexGrow={0} flexBasis={72}
         alignItems={"center"} justify={"space-around"}>
      {actionSet.map((action, index) => {
        const { component: Component, ...other } = action;
        return <Tooltip title={action.tooltip} key={index} alignment={Alignment.Bottom}>
          {
            Component ?
              <Component key={index} rowData={rowData} {...other}/> :
              <Button onlyIcon size={ButtonSizes.Small} {...action} onClick={(e) => action.onClick(e, rowData)}/>
          }
        </Tooltip>;
      })}
    </Box>
  </Cell>;
});
