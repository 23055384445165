import React                           from "react";
import { FC }                          from "react";
import { useCallback }                 from "react";
import { gql }                         from "@apollo/client";
import { useMutation }                 from "@apollo/client";
import { useSource }                   from "@relcu/ui";
import { ButtonColors }                from "@relcu/ui";
import { ButtonVariants }              from "@relcu/ui";
import { Button }                      from "@relcu/ui";
import { GetViewerMailBoxesVariables } from "../__types__/GetViewerMailBoxes";
import { GetViewerMailBoxes }          from "../__types__/GetViewerMailBoxes";
import { GET_VIEWER_MAILBOXES }        from "../useMailAccountsSection";
import { DisconnectMailVariables }     from "./__types__/DisconnectMail";
import { DisconnectMail }              from "./__types__/DisconnectMail";
import GoogleSvgUrl                    from "../../../../../assets/google.svg";
import MicrosoftSvgUrl                 from "../../../../../assets/microsoft.svg";

export interface GmailDisconnectButton {
  objectId: string;
  type: "gmail" | "outlook";
}

export const DisconnectButton: FC<GmailDisconnectButton> = React.memo(function DisconnectButton(props) {
  const { $viewer } = useSource();
  const [disconnectMail] = useMutation<DisconnectMail, DisconnectMailVariables>(DISCONNECT_MAIL, {
    update(cache, { data: { disconnectMail } }) {
      const variables = {
        where: {
          assignedTo: {
            have: {
              link: $viewer.id
            }
          }
        }
      };
      let data = cache.readQuery<GetViewerMailBoxes, GetViewerMailBoxesVariables>({
        query: GET_VIEWER_MAILBOXES,
        variables
      });

      if (!data) {
        data = { mailboxes: { edges: [], __typename: "MailboxConnection" } };
      }
      let edges = data.mailboxes.edges.map((edge) => {
        return {
          ...edge,
          node: {
            ...edge.node,
            auth: null,
            disabled: !edge.node.domain.valid
          }
        };
      });
      cache.writeQuery<GetViewerMailBoxes, GetViewerMailBoxesVariables>({
        query: GET_VIEWER_MAILBOXES,
        variables,
        data: {
          ...data,
          mailboxes: {
            ...data.mailboxes,
            edges: edges
          }
        }
      });
    }
  });
  const handleDisconnect = useCallback(() => {
    disconnectMail({
      variables: {
        objectId: props.objectId
      }
    });
  }, [props.objectId]);
  return <Button
    onClick={handleDisconnect}
    variant={ButtonVariants.Ghost}
    color={ButtonColors.Error}
    icon={
      <img src={
        props.type == "gmail" ? GoogleSvgUrl : MicrosoftSvgUrl
      } alt={`Sign out from ${props.type == "gmail" ? "Google" : "Microsoft"}`}/>
    }>
    DISCONNECT
  </Button>;
});

export const DISCONNECT_MAIL = gql`
  mutation DisconnectMail($objectId: String!) {
    disconnectMail(objectId: $objectId)
  }
`;
