import React                 from "react";
import { FC }                from "react";
import { classNames }        from "../../utils/classNames";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";

interface StepContainerProps extends BoxComponentProps {
  background?: "white" | "transparent";
}

export const StepContainer: FC<StepContainerProps> = React.memo(function StepContainer(props) {
  const { className, background, ...p } = props;
  const classes = classNames("step__container", {
    "step__container--white": background == "white"
  }, className);

  return <Box container direction={"column"} flex={1} className={classes} {...p}/>;
});
