import React                 from "react";
import { FC }                from "react";
import { Box }               from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import { classNames }        from "@relcu/ui";
import "./mail-subsection.css";

export const MailSubSection: FC<BoxComponentProps> = React.memo(function MailSubSection(props) {
  const { className, ...p } = props;
  const classes = classNames(MailSubSectionClasses.SubSection, className);
  return <Box container justify={"space-between"} className={classes} {...p}/>;
});

export const enum MailSubSectionClasses {
  SubSection = "mail-sub-section"
}