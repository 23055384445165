import React                       from "react";
import { FC }                      from "react";
import { useState }                from "react";
import { classNames }              from "@relcu/ui";
import { TabItem }                 from "@relcu/rc";
import { TabsSwitcher }            from "@relcu/rc";
import { TabPanel }                from "@relcu/rc";
import { TabPanelContainer }       from "@relcu/rc";
import { TabContainer }            from "@relcu/rc";
import { Typography }              from "@relcu/rc";
import { AllPushNotifications }    from "./AllPushNotifications";
import { UnreadPushNotifications } from "./UnreadPushNotifications";
import "../Notifications/notification-bar.css";

export const Push: FC<any> = React.memo(function Push(props) {
  const { className } = props;
  const classes = classNames("push-bar", className);

  return (
    <div className={classes}>
      <div className={"push-notification-bar-header"}>
        <Typography variant={"base16"} weights={"medium"} lineHeight={"lh24"}>
          Push distribution queue
        </Typography>
      </div>
      <TabContainer gap={16} outerState={useState(0)} className={"notification-bar-body"}>
        <TabPanelContainer className={"notification-bar-tab-container"}>
          <TabPanel className={"notification-bar-unread-tab-panel"}>
            <UnreadPushNotifications/>
          </TabPanel>
          <TabPanel className={"notification-bar-all-tab-panel"}>
            <AllPushNotifications/>
          </TabPanel>
        </TabPanelContainer>
        <TabsSwitcher className={"notification-bar-tabs"}>
          <TabItem active={true} className={"notification-bar-tab-item"}>
            Unread
          </TabItem>
          <TabItem active={false} className={"notification-bar-tab-item"}>
            All
          </TabItem>
        </TabsSwitcher>
      </TabContainer>
    </div>
  );
});
