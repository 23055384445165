import React                 from "react";
import { BoxComponentProps } from "../..";
import { classNames }        from "../..";
import { Box }               from "../..";
import { LoaderClasses }     from "./LoaderClasses";

export interface CircularLoaderProps extends BoxComponentProps {

}

export const CircularLoader = React.forwardRef(function CircularLoader(props: CircularLoaderProps, ref: React.MutableRefObject<HTMLDivElement>) {
  const { className, style, ...p } = props;

  return (
    <Box container ref={ref} alignItems={"center"} className={className} style={{ minHeight: 33, ...style }} {...p}>
      <svg className={classNames(LoaderClasses.Circular)} viewBox="0 0 100 100">
        <circle className={LoaderClasses.Path} cx="50" cy="50" r="45"/>
      </svg>
    </Box>
  );
});
