import { ReactNode }         from "react";
import React                 from "react";
import { FC }                from "react";
import { CommonClasses }     from "../../theme/classes";
import { classNames }        from "../../utils/classNames";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";

export const enum CallOutClasses {
  Header = "call-out__header",
  Content = "call-call-out__content"
}
export interface CallOutProps extends BoxComponentProps {
  header: string | ReactNode
  content: string | ReactNode
  avatar: ReactNode
  verticalGap?: "XXXL" | "XXL" | "XL" | "L" | "M" | "S" | "XS" | "XXS" | "XXXS" | "XXXXS"
}
export const CallOut: FC<CallOutProps> = React.memo(function CallOut(props) {
  const { header, content, avatar, verticalGap, ...p } = props;
  return <Box container gap={"XXS"} {...p}>
    <Box container alignItems={"center"} alignSelf={"stretch"}>{avatar}</Box>
    <Box alignSelf={"stretch"} container direction={"column"} flex={1} justify={(!header || !content) ? "center" : "space-between"} gap={verticalGap ?? "XXXS"} style={{overflow: "hidden"}}>
      {
        header &&
        (React.isValidElement(header) ? header :
          <p className={CallOutClasses.Header}>{header}</p>)
      }
      {
        content &&
        (React.isValidElement(content) ? content :
          <p
            className={classNames(CommonClasses.TextTiny, CommonClasses.TextSecondary, CallOutClasses.Content)}>{content}</p>)
      }
    </Box>
  </Box>;
});
