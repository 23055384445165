export enum GlobalClasses {
  MaterialIcons = "material-icons",
  VisuallyHidden = "visually-hidden",
  Active = "active",
  Disabled = "disabled",
  Message = "message",
  Error = "error",
  Edit = "edit",
  EllipsisFlex = "ell-flex",
  EllipsisWidth = "ell-width",
}
