import React            from "react";
import { DialList }     from "../../../DialList/DialList";
import { DialListItem } from "../../../DialList/DialListItem";
import { useSmsReply }  from "./useSmsReply";

export const SmsReply = React.memo<{ onClick?() }>(function SmsReply(props) {
  const reply = useSmsReply(props);
  return (
    reply.templates.length > 0 &&
    <DialList header={"Send a SMS message"}>
      {
        reply.templates.map(t => {
          return <DialListItem key={t.node.id} onClick={() => reply.send(t.node)}>
            {t.node.title}
          </DialListItem>;
        })
      }
    </DialList>

  );
});

