import React                                               from "react";
import { FC }                                              from "react";
import { useMemo }                                         from "react";
import { useRef }                                          from "react";
import { useContext }                                      from "react";
import { useEffect }                                       from "react";
import { useQuery }                                        from "@apollo/client";
import { BackDropLoader }                                  from "@relcu/ui";
import { EmptyState }                                      from "@relcu/rc";
import { PushDistributionItem }                            from "@relcu/rc";
import { ButtonColors }                                    from "@relcu/ui";
import { Mention }                                         from "@relcu/ui";
import { useConstant }                                     from "@relcu/ui";
import { SubscriptionEvent }                               from "../../../../types/graphql-global-types";
import { nextCursor }                                      from "../../../../utils/helpers";
import { toFirstUpper }                                    from "../../../../utils/helpers";
import { dividedDataByFixedDate }                          from "../../../../utils/helpers";
import {
  GetDistributedLeadUnreadNotifications
}                                                          from "../../../__types__/GetDistributedLeadUnreadNotifications";
import {
  SubscribeDistributedLeadUnreadNotification
}                                                          from "../../../__types__/SubscribeDistributedLeadUnreadNotification";
import { SUBSCRIBE_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS } from "../../../operations.graphql";
import { GET_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS }       from "../../../operations.graphql";
import { useNotification }                                 from "../../InAppNotification/useNotification";
import { TimeStepLabels }                                  from "../../Notifications/NotificationBar";
import { NotificationTimeSection }   from "../../Notifications/NotificationTimeSection";
import { useShowMention }            from "../../Notifications/NotificationMention/useShowMention";
import { UnreadNotificationContext } from "../../UnreadNotificationProvider";

export const UnreadPushNotifications: FC<any> = React.memo(function UnreadPushNotifications(props) {
  const { data: { notifications: { edges = [] } = {} } = {}, loading, subscribeToMore } = useQuery<GetDistributedLeadUnreadNotifications>(GET_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const { lastNotificationSeenAt } = useContext(UnreadNotificationContext);
  const scrollContainerRef = useRef(null);
  const { getRecord, onNavigate } = useNotification();
  const lastSeenAt = useConstant(() => lastNotificationSeenAt);
  const dividedData = useMemo(() => dividedDataByFixedDate(edges, null, lastSeenAt), [lastSeenAt, edges]);
  const dateSteps = Object.entries(dividedData).filter(dateStep => dateStep[ 1 ].length);
  const { setShowMention, showMention } = useShowMention(edges, scrollContainerRef.current);

  useEffect(() => {
    return subscribeToMore<SubscribeDistributedLeadUnreadNotification>({
      document: SUBSCRIBE_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS,
      updateQuery(root, { subscriptionData: { data: { notifications: { node, event } } } }) {
        switch (event) {
          case SubscriptionEvent.CREATE:
          case SubscriptionEvent.ENTER:
            return {
              ...root,
              notifications: {
                ...root.notifications,
                edges: [
                  {
                    __typename: "NotificationEdge",
                    cursor: nextCursor(root.notifications.count),
                    node
                  },
                  ...root.notifications.edges
                ]
              }
            };
          case SubscriptionEvent.LEAVE:
            return {
              ...root,
              notifications: {
                ...root.notifications,
                edges: root.notifications.edges.filter(e => e.node.id !== node.id)
              }
            };
        }
      }
    });
  }, []);

  function handleScrollTo() {
    scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    setShowMention(false);
  }

  return (
    <>
      {
        showMention &&
        <Mention
          width={150}
          style={{ top: 20 }}
          direction={"top"}
          color={ButtonColors.Error}
          icon={"north"}
          onClick={handleScrollTo}>
          New notification
        </Mention>
      }
      <div className={"notification-bar-unread-notes"} ref={scrollContainerRef}>
        {
          loading && <BackDropLoader style={{ zIndex: 1 }}/>
        }
        {
          !edges.length ?
            <EmptyState
              icon={"rc_lead"}
              compact
              title={"No new leads"}
              subtitle={"You have seen all your lead assignments."}/>
            :
            dateSteps.map((dateStep, index) => {
              return <NotificationTimeSection key={index} title={TimeStepLabels[ dateStep[ 0 ] ]}>
                {
                  dateStep[ 1 ].map(({ node }, index) => {
                      const record = getRecord(node);
                      return <PushDistributionItem
                        key={index}
                        date={node.createdAt}
                        type={node?.record?.objectName ? toFirstUpper(node?.record?.objectName.toLowerCase()): ""}
                        name={node.scope.objectName}
                        unread={node.unread}
                        onClick={() => onNavigate(record)}
                      />;
                    }
                  )
                }
              </NotificationTimeSection>;
            })
        }
      </div>
    </>
  );
});
