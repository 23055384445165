import React                    from "react";
import { FC }                   from "react";
import { MultiSelectProps } from "../../Input/MultiSelect";
import { BaseField }            from "../BaseField";
import { BaseFieldProps }       from "../BaseField";
import MultiChoiceEditField     from "./MultiChoiceEditField";
import MultiChoiceReadField     from "./MultiChoiceReadField";

export type MultiChoiceFieldProps = BaseFieldProps & MultiSelectProps;

export const MultiChoiceField: FC<MultiChoiceFieldProps> = React.memo(function MultiChoiceField(props) {
  return (
    <BaseField
      {...props}
      readView={<MultiChoiceReadField/>}
      editView={<MultiChoiceEditField/>}
    />
  );
});
MultiChoiceField.defaultProps = {
  options: []
};
