import React                 from "react";
import { FC }                from "react";
import { useMemo }           from "react";
import { Box }               from "../../..";
import { Label }             from "../../..";
import { classNames }        from "../../..";
import { BoxComponentProps } from "../../..";
import { BaseInputClasses }  from "../BaseInput";
import { BaseInputProps }    from "../BaseInput";
import { InputState }        from "../Input";

export interface CheckboxProps extends BoxComponentProps, BaseInputProps {
  checked: boolean;
  leftMessageSpace?: boolean;
  indeterminate?: boolean;
  placeholder?: string;
  state?: InputState;
  halfSize?: boolean;
  alignment?: "left" | "right" | string;
  message?: string;
  InputProps?: { [ key: string ]: any };
  onChange?(e?);
  onBlur?(e?);
  onFocus?(e?);
}
const defaultCheckBoxProps: Partial<CheckboxProps> = {
  leftMessageSpace: true,
  checked: false,
  disabled: false,
  alignment: "right",
  onChange: null,
  halfSize: false,
  container: true,
  direction: "column",
  alignItems: "baseline"
};

export const Checkbox: FC<CheckboxProps> = React.memo(function Checkbox(props) {
  const { className, checked, label, placeholder, disabled, onChange, alignment, name, indeterminate, state, onBlur, onFocus, halfSize, fullSize, message, InputProps, leftMessageSpace, ...p } = props;
  const classes = classNames(CheckboxClasses.CheckBox, {
    [ CheckboxClasses.Reverse ]: (alignment == "left"),
    [ CheckboxClasses.Indeterminate ]: indeterminate,
    [ CheckboxClasses.Disabled ]: disabled,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Error ]: state == InputState.Error,
    [ BaseInputClasses.Success ]: state == InputState.Success
  }, className, BaseInputClasses.Input);

  let inputProps = useMemo(() => {
    const opts = {
      onChange,
      onFocus,
      onBlur
    };

    if (onChange == null) {
      opts[ "defaultChecked" ] = checked;
    } else {
      opts[ "checked" ] = checked;
    }

    return opts;
  }, [onChange, checked]);

  return (
    <Box container className={classes} direction={"column"} gap={"XXXS"} {...p}>
      <Box container gap={"XXXS"} direction={"column"} className={CheckboxClasses.Single} {...InputProps}>
        {
          label && <Label>
            {label}
          </Label>
        }
        <label className={CheckboxClasses.Label}>
          <input type="checkbox" name={name} disabled={disabled} {...inputProps}/>
          <span className={CheckboxClasses.Placeholder}/>
          {
            placeholder
          }
        </label>
      </Box>
      {
        leftMessageSpace &&
        <Box container className={BaseInputClasses.Message}>
          {message}
        </Box>
      }
    </Box>
  );
});
Checkbox.defaultProps = defaultCheckBoxProps;

export default Checkbox;

export enum CheckboxClasses {
  CheckBox = "checkbox",
  Placeholder = "checkbox__placeholder",
  Disabled = "checkbox--disabled",
  Indeterminate = "checkbox--indeterminate",
  Reverse = "checkbox--reverse",
  Single = "checkbox--single",
  Label = "checkbox__label"
}
