import React                from "react";
import { FC }               from "react";
import { Tooltip }          from "@relcu/ui";
import { ButtonSizes }      from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { Alignment }        from "@relcu/ui";
import { IActionCellProps } from "@relcu/ui";

export const TaskCreateAction: FC<IActionCellProps> = React.memo(function TaskCreateAction(props) {
  const { rowData, disabled, tooltip, path, bulkPermissions, onClick, ...p } = props;

  return <Tooltip title={tooltip} alignment={Alignment.Bottom}>
    <Button
      onlyIcon
      size={ButtonSizes.Small}
      onClick={() => onClick(rowData)}
      disabled={rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length || !rowData?.assignedTo}
      {...p}/>
  </Tooltip>;
});