import { useNavigate }                       from "@relcu/react-router";
import { usePhone }                          from "../../usePhone";
import { useDIalPadSubmit }                  from "../useDIalPadSubmit";

export enum ActionType {
  Call = "call",
  SMS = "sms"
}

export function useDialBar() {
  const phone = usePhone();
  const navigate = useNavigate();
  const { checkIsInternalNumber, getDialedContact, createContact } = useDIalPadSubmit();

  const handleSubmit = async ({ from, to, contact, action }: {
    from?: string,
    to?: string,
    contact?,
    action: ActionType
  }) => {
    try {
      if (!from) {
        return {
          from: "From is required."
        };
      }

      if (typeof to == "string" && !contact) {
        const isInternal = await checkIsInternalNumber(to);
        if (isInternal) {
          return {
            to: {
              [ ActionType.SMS ]: "You cannot send internal SMS",
              [ ActionType.Call ]: "You cannot make internal call"
            }[ action ]
          };
        }
        contact = await getDialedContact(to);

        if (contact) {
          const phone = contact?.phones?.find((p) => p.number.includes(to));
          if (
            (phone.callOptOut && action === ActionType.Call) ||
            (phone.smsOptOut && action === ActionType.SMS)
          ) {
            return {
              to: {
                [ ActionType.SMS ]: "This number is opt out for SMS",
                [ ActionType.Call ]: "This number is opt out for call"
              }[ action ]
            };
          }
        }

        if (!contact) {
          contact = await createContact(to);
        }
      } else if (contact) {
        const phone = contact.phone;
        if (
          (phone.callOptOut && action === ActionType.Call) ||
          (phone.smsOptOut && action === ActionType.SMS)
        ) {
          return {
            to: {
              [ ActionType.SMS ]: "This number is opt out for SMS",
              [ ActionType.Call ]: "This number is opt out for call"
            }[ action ]
          };
        }
        to = contact.number;
      }

      if (contact) {
        const params = {
          from: from,
          to: to,
          contactId: contact.objectId,
          contactName: contact.objectName
        };

        if (contact.company) {
          params[ "contactCompany" ] = contact.company;
        }

        if (action === ActionType.Call) {
          phone.call(params);
        }

        if (action === ActionType.SMS) {
          navigate(`/contact/${contact.objectId}/sms`, {
            state: {
              to: { value: to },
              from: { value: from }
            }
          });
        } else {
          navigate(`/contact/${contact.objectId}/details`);
        }
      }
    } catch (e) {
      return {
        FORM_ERROR: "Something went wrong."
      };
    }
  };

  return {
    handleSubmit
  };
}




