import React                                from "react";
import { FC }                               from "react";
import { gql }                              from "@apollo/client";
import { useQuery }                         from "@apollo/client";
import { MAIL_MESSAGE }                     from "../../../../../graph/operations.graphql";
import { BulkPreview }                      from "../../../Section/BulkSection/BulkPreview";
import { GetBulkConversations_mailConversations_edges_node } from "./__types__/GetBulkConversations";
import { GetConversationMessagesVariables } from "./__types__/GetConversationMessages";
import { GetConversationMessages }          from "./__types__/GetConversationMessages";
import { MessageBody }                      from "./MessageBody";

export interface BulkItemPreviewProps {
  selectedItem: { conversationId: string | null, cursor: string } | null;
  count: number;
  skip: number;
  slide: (path: string) => void;
  status: string | null;
  data: GetBulkConversations_mailConversations_edges_node[]
}

export const BulkStatuses = {
  "click": "Click rate",
  "unsubscribe": "Unsubscribe",
  "open": "Opened",
  "spam": "Spam complaints"
};
export const BulkItemPreview: FC<BulkItemPreviewProps> = React.memo(function BulkItemPreview(props) {
  const { count, selectedItem, status, slide, data, skip } = props;
  const {
    data: { conversationMessages: { edges = [] } = {} } = {},
    loading
  } = useQuery<GetConversationMessages, GetConversationMessagesVariables>(GET_CONVERSATION_MESSAGES, {
    variables: { conversationId: selectedItem.conversationId }
  });

  if (loading) {
    return null;
  }

  const message = edges.at(0)?.node;
  const receiver = message?.parties.find(p => p.type == "receiver");

  const index = data.findIndex(phoneMessage => {
    return phoneMessage.objectId == selectedItem.conversationId;
  });

  return <BulkPreview
    skip={skip}
    selectedIndex={index}
    count={count}
    selectedItem={selectedItem}
    slide={slide}
    status={status}
    statusMsg={BulkStatuses[ status ]}
    messageStatus={message?.status}
    receiver={receiver?.party?.objectName}
  >
    {message && <MessageBody message={message}/>}
  </BulkPreview>;
});

export const GET_CONVERSATION_MESSAGES = gql`
  query GetConversationMessages($conversationId:String!) {
    conversationMessages(where: {},conversationId: $conversationId) {
      edges {
        node {
          ...MailMessage
        }
      }
    }
  }
  ${MAIL_MESSAGE}
`;
