import { useCallback }      from "react";
import React                from "react";
import { JsonSectionProps } from "@relcu/ui";
import { useModal }         from "@relcu/ui";
import { JsonSection }      from "@relcu/ui";
import { useSource }        from "@relcu/ui";
import { ContactDialog }    from "../../Dialog/ContactDialogOld";

export const ContactSection = React.memo<JsonSectionProps>(function PasswordSection({ component, ...props }) {
  const [modal, contextHolder] = useModal(ContactDialog);
  const { $object } = useSource();
  const onEdit = useCallback(() => {
    modal({
      className: $object.__typename,
      record: $object,
      action: "save"
    });
  }, [$object]);
  return (
    <JsonSection {...props} onEdit={onEdit}>
      {contextHolder}
    </JsonSection>
  );
});
