import React                 from "react";
import { classNames }        from "../../..";
import { AvatarSizes }       from "../../Avatar";
import { Avatar }            from "../../Avatar";
import { BoxComponentProps } from "../../Box";
import { Box }               from "../../Box";
import { CallOut }           from "../../CallOut";
import { ChipsColors }       from "../../Chips";
import { ChipsSizes }        from "../../Chips";
import { Chips }             from "../../Chips";
import { StatusBadge }       from "../StatusBadge";

export interface DirectionCallOutProps extends BoxComponentProps {
  name: string,
  header: string,
  type: string,
  status?: "available" | "away" | "busy" | "out_of_work"|string,
  deactivated?: boolean
  icon?: string,
}

export const DirectionCallOut = React.memo<DirectionCallOutProps>(function DirectionCallOut(props) {
  const { name, header, type, className, status, icon,deactivated, ...p } = props;
  const classes = classNames(DirectionCallOutClasses.Direction, className);
  return (
    <CallOut
      className={classes}
      gap={"XS"}
      avatar={
        status ?
          <StatusBadge status={status}>
            <Avatar icon={icon} text={name} size={AvatarSizes.Big} flexShrink={0}/>
          </StatusBadge> :
          <Avatar icon={icon} text={name} className={DirectionCallOutClasses.Avatar}
                  size={AvatarSizes.Big} flexShrink={0}/>
      }
      header={header && <h1 className={DirectionCallOutClasses.Header}>{header}</h1>}
      content={
        <Box gap={"XXS"} container justify={"start"} alignItems={"center"}>
          {type &&
          <Chips color={ChipsColors.Grey} size={ChipsSizes.Small} alignContent={"center"}
                 disabled={true} label={type} className={DirectionCallOutClasses.Chips}/>}
          <p className={DirectionCallOutClasses.Name}>
            {name ?? "Untitled"}
          </p>
          {!!deactivated &&
          <p className={DirectionCallOutClasses.Deactivated}>
            Deactivated Account
          </p>
          }
        </Box>
      }
      {...p}
    />
  );
});
export enum DirectionCallOutClasses {
  Direction = "direction",
  Avatar = "direction__avatar",
  Header = "direction__header",
  Chips = "direction__chips",
  Name = "direction__name",
  Deactivated = "direction__deactivated",
}
