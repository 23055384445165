import React, { useContext } from "react";
import { InputProps }        from "../index";
import { SelectPickerProps } from "../index";
import SelectPicker          from "../SelectPicker";
import { FormField }         from "./FormField";
import { FormFieldProps }    from "./FormField";

export interface FormInputProps<ValueType = any> extends Omit<FormFieldProps<InputProps, ValueType>, "component"> {
  size?: SelectPickerProps["size"];
}

export interface FormSelectComponent extends React.FC<FormInputProps> {
  <P = any>(
    props: FormInputProps<P>
  ): React.ReactElement | null;
}

export const FormInput: FormSelectComponent = React.memo((props: FormInputProps) => {
  const { size, properties, ...rest } = props;
  return (
    <FormField<FormInputProps> {...rest} properties={{ size, ...properties }}/>
  );
});

FormInput.displayName = "FormInput";
