import { useQuery }                     from "@apollo/client";
import { useSource }                    from "@relcu/ui";
import { useEffect }                    from "react";
import { useMemo }                      from "react";
import { GetMailBoxes_mailboxes_edges } from "../graph/__types__/GetMailBoxes";
import { GetMailBoxesVariables }        from "../graph/__types__/GetMailBoxes";
import { GetMailBoxes }                 from "../graph/__types__/GetMailBoxes";
import { SubscribeMailBoxesVariables }  from "../graph/__types__/SubscribeMailBoxes";
import { SubscribeMailBoxes }           from "../graph/__types__/SubscribeMailBoxes";
import { SUBSCRIBE_MAILBOXES_DELETE }   from "../graph/operations.graphql";
import { SUBSCRIBE_MAILBOXES }          from "../graph/operations.graphql";
import { GET_MAILBOXES }                from "../graph/operations.graphql";
import { SubscriptionEvent }            from "../types/graphql-global-types";

export function useMailboxes(viewer?) {
  const { $viewer = viewer?.user } = useSource();
  const { id } = $viewer;
  const variables = useMemo(() => ({
    where: {
      OR: [
        { common: { equalTo: true } },
        { assignedTo: { have: { link: id } } }
      ]
    }
  }), [id]);
  const { data: { mailboxes: { edges = [] } = {} } = {}, subscribeToMore } = useQuery<GetMailBoxes, GetMailBoxesVariables>(GET_MAILBOXES, {
    variables,
    skip: !id
  });
  return {
    subscribeToMore,
    variables,
    mailBoxes: edges as GetMailBoxes_mailboxes_edges[]
  };
}

export function useSubscribeMailboxes(viewer) {
  const { subscribeToMore, variables } = useMailboxes(viewer);
  useEffect(() => {
    return subscribeToMore<SubscribeMailBoxes, SubscribeMailBoxesVariables>({
      document: SUBSCRIBE_MAILBOXES,
      variables,
      updateQuery(previousQueryResult, { subscriptionData: { data: { mailboxes: { event, node } } } }) {
        switch (event) {
          case SubscriptionEvent.ENTER:
          case SubscriptionEvent.CREATE:
            return {
              ...previousQueryResult,
              mailboxes: {
                ...previousQueryResult.mailboxes,
                edges: [
                  {
                    node,
                    __typename: "MailboxEdge"
                  },
                  ...previousQueryResult.mailboxes.edges
                ]
              }
            };
          case SubscriptionEvent.UPDATE:
            return {
              ...previousQueryResult,
              mailboxes: {
                ...previousQueryResult.mailboxes,
                edges: previousQueryResult.mailboxes?.edges.map(edge => {
                  if (edge.node.id !== node.id) {
                    return edge;
                  } else {
                    return { __typename: "MailboxEdge", node };
                  }
                })
              }
            };
          case SubscriptionEvent.LEAVE:
            return {
              ...previousQueryResult,
             // count: Math.abs(previousQueryResult.mailboxes.count - 1),
              mailboxes: {
                ...previousQueryResult.mailboxes,
                edges: previousQueryResult.mailboxes?.edges.filter(edge => edge.node.id !== node.id)
              }
            };
        }
      }
    });
  }, [variables]);
  //todo temporary subscription for delete only since we have
  //issue on delete event when selection set contains pointers
  useEffect(() => {
    return subscribeToMore<SubscribeMailBoxes, SubscribeMailBoxesVariables>({
      document: SUBSCRIBE_MAILBOXES_DELETE,
      variables,
      updateQuery(previousQueryResult, { subscriptionData: { data: { mailboxes: { event, node } } } }) {
        return {
          ...previousQueryResult,
          mailboxes: {
            ...previousQueryResult.mailboxes,
            edges: previousQueryResult.mailboxes?.edges.filter(edge => edge.node.id !== node.id)
          }
        };
      }
    });
  }, [variables]);
}
