import React                 from "react";
import { BoxComponentProps } from "../../Box";
import { Box }             from "../../Box";
import { classNames }      from "../../../index";
import { CalendarClasses } from "../CalendarClasses";

export interface CalendarContainerProps extends BoxComponentProps {
  large?: boolean
}

export const DatepickerContainer = React.forwardRef(function DatepickerContainer(props: CalendarContainerProps, ref: any) {
  const { className, large, children, ...rest } = props;
  return (
    <Box
      container
      ref={ref}
      direction={"column"}
      gap={"XS"}
      className={classNames(
        CalendarClasses.Calendar,
        className, {
          [ CalendarClasses.CalendarLg ]: large
        })}
      {...rest}
    >
      {children}
    </Box>
  );
});
