import React                        from "react";
import { RsRefForwardingComponent } from "../../@types/common";
import { CheckPickerProps }         from "../../index";
import { CheckPicker }              from "../../index";
import { Cell }                     from "../../TableHorizontal";
import { CellProps }                from "../../TableHorizontal/Cell";
import { useClassNames }            from "../../utils";

export interface CheckPickerCellProps
  extends Omit<CellProps, "onChange" | "onSelect" | "placeholder" | "defaultValue" | "size" | "value">, CheckPickerProps<any> {
  active?: boolean;
  data: Record<string, any>[]
}


export const CheckPickerCell: RsRefForwardingComponent<"div", CheckPickerCellProps> = React.forwardRef((props: CheckPickerCellProps, ref) => {
  const {
    className,
    classPrefix = "cell-check-picker",
    status,
    helperText,
    children,
    affected,
    ...rest
  } = props as any

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Cell status={status} helperText={helperText} className={classes} affected={affected}>
    <CheckPicker
      placement={"autoVertical"}
      containerPadding={0}
      menuClassName={prefix("menu")}
      {...rest}/>
  </Cell>;
});
