import React              from "react";
import { FC }             from "react";
import { useCallback }    from "react";
import { useState }       from "react";
import { HTMLAttributes } from "react";
import { useClassNames }  from "@relcu/rc";
import { Typography }     from "@relcu/rc";
import { StackProps }     from "@relcu/rc";
import { Stack }          from "@relcu/rc";
import "./fields-adjuster.css";

export interface FieldsAdjusterRowProps extends HTMLAttributes<HTMLDivElement> {
  label: string,
  visible?: boolean
  static?: boolean
}

export interface FieldsAdjusterProps extends StackProps {
  onExpand?();
}

export interface FieldsAdjusterComponent extends FC<FieldsAdjusterProps> {
  Row: typeof FieldsAdjusterRow;
}

export const FieldsAdjuster: FieldsAdjusterComponent = React.memo(function FieldsAdjuster(props: FieldsAdjusterProps) {
  const { children, onExpand } = props;
  const { withClassPrefix, prefix } = useClassNames("field-toggle", "rc");
  const [labels, setLabels] = useState(() => {
    const labels = {};
    React.Children.toArray(children).forEach((c: React.ReactElement) => {
      if (!c.props.static) {
        labels[ c.props.label ] = c.props.visible ?? false;
      }
    });
    return labels;
  });

  const handleToggleField = useCallback((key) => {
    setLabels((prev) => {
      if (!prev[ key ]) {
        return {
          ...prev,
          [ key ]: !prev[ key ]
        };
      }

      return prev;
    });
  }, []);

  return <div style={{ position: "relative" }}>
    <div className={withClassPrefix()}>{/*todo do not use stack alignment working wrong*/}
      {
        Object.entries(labels).map(([key, value]) => {
          if (!value) {
            return <Typography
              key={key}
              color={"tertiary"}
              variant={"base14"}
              className={prefix`action`}
              onClick={() => handleToggleField(key)}>
              {key}
            </Typography>;
          }
        })
      }
    </div>
    <Stack
      direction={"column"}
      alignItems={"stretch"}
      className={"rc-fields-adjuster"}>
      {
        React.Children.map(children, (child: React.ReactElement, index) => {
          return (!child?.props?.static && !labels[ child?.props?.label ]) ? null : child;
        })
      }
    </Stack>
  </div>;
}) as unknown as FieldsAdjusterComponent;

export const FieldsAdjusterRow = React.memo(function FieldsAdjusterRow(props: FieldsAdjusterRowProps) {
  const { label, static: staticState, children, ...rest } = props;
  return <Stack.Item className={"rc-fields-adjuster-row"}
                     style={{ "--rc-adjust-row-padding": staticState ? "160px" : 0 } as any} grow={1} {...rest}>
    <Stack spacing={8}>
      <Stack.Item style={{ width: 80 }} shrink={0}>
        <Typography variant={"base14"} color={"tertiary"}>{label}:</Typography>
      </Stack.Item>
      <Stack.Item grow={1}>{children}</Stack.Item>
    </Stack>
  </Stack.Item>;
});

FieldsAdjuster.Row = FieldsAdjusterRow;

