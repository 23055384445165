import React                           from "react";
import { FC }                          from "react";
import { useMemo }                     from "react";
import { useContext }                  from "react";
import { useParams }                   from "@relcu/react-router";
import { OnChange }                    from "@relcu/form";
import { useForm }                     from "@relcu/form";
import { Field }                       from "@relcu/form";
import { FieldArray }                  from "@relcu/form";
import { FormSpy }                     from "@relcu/form";
import { Radio }                       from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { CheckboxGroup }               from "@relcu/rc";
import { Checkbox }                    from "@relcu/rc";
import { FormField }                   from "@relcu/rc";
import { Avatar, Stack, Typography }   from "@relcu/rc";
import { transformNameToLabel }        from "../../../../../../utils/helpers";
import { toFirstLower }                from "../../../../../../utils/helpers";
import { DetailsContainer }            from "../../../../../Generation";
import { DetailsField }                from "../../../../../Generation";
import { useSchemaField }              from "../../../../useSchemaField";
import { PhoneMessageTemplateContext } from "../Content";

interface TemplateDetailsProps {
  owner?: string;
  name?: string;
}

export const PhoneMessageTemplateDetails: FC<TemplateDetailsProps> = React.memo(function PhoneMessageTemplateDetails(props) {
  const { replaceableFieldsSources } = useContext(PhoneMessageTemplateContext);
  const { phoneMessageTemplateId } = useParams();
  let optionalField = useMemo(() => replaceableFieldsSources.find(r => r != "Contact" && r != "Lead"), [replaceableFieldsSources]);
  const { options } = useSchemaField("User", "role");
  const { options: typeOptions } = useSchemaField("PhoneMessageTemplate", "type");
  const { $object } = useSource();
  const form = useForm();
  function compareWithInitial(initial, current) {
    return initial?.every(i => {
      return current.includes(i);
    });
  }
  function handleApplyToChange(value, formValue) {
    let changedValue = [...value];

    if (optionalField && formValue) {
      optionalField = toFirstLower(optionalField);
      if (!formValue.includes("lead") && changedValue.includes("lead") && changedValue.includes(optionalField)) {
        changedValue = changedValue.filter(cv => cv != optionalField);
      } else if (!formValue.includes(optionalField) && changedValue.includes(optionalField) && (changedValue.includes("lead") || changedValue.includes("contact"))) {
        changedValue = changedValue.filter(cv => cv != "lead" && cv != "contact");
      } else if (!formValue.includes("contact") && changedValue.includes("contact") && changedValue.includes(optionalField)) {
        changedValue = changedValue.filter(cv => cv != optionalField);
      }
    }
    const type = form.getState().values?.type;
    if ((type == "call" || type == "out_of_work") && replaceableFieldsSources.length != changedValue.length) {
      form.change("type", "sms");
    } else if (type == "loan_proposal" && !changedValue.includes("lead")) {
      form.change("type", "sms");
    }
    return changedValue;
  }

  function handleTypeChange(value) {
    if (value == "call" || value == "out_of_work") {
      form.change("applyTo", replaceableFieldsSources.map(field => toFirstLower(field)));
    } else if (value == "loan_proposal") {
      form.change("applyTo", ["lead"]);
    }

    if (value != "sms") {
      form.change("availableFor", form.getState().values?.availableFor?.filter(a => a == "manual"));
    }
  }

  return <DetailsContainer>
    <DetailsField style={{ width: "50%" }}>
      <DetailsField.Title>General</DetailsField.Title>
      <FormField
        name="title"
        label="Template name"
        required
        validate={(value) => {
          if (value?.trim() === "") {
            return `Template name is required.`;
          }
        }}
        groupProperties={{ style: { alignSelf: "stretch" } }}
      />
    </DetailsField>
    <DetailsField>
      <DetailsField.Title withGap={false}>Apply to</DetailsField.Title>
      <DetailsField.SubTitle>Select when this template should be available for use.</DetailsField.SubTitle>
      <FieldArray
        name="applyTo"
        validate={(value, allValues, meta) => {
          if (meta.modified && value?.length == 0) {
            return "Please select at least one option.";
          }
        }}>
        {
          ({ fields, meta }) => {
            return (
              <>
                <CheckboxGroup
                  value={fields.value}
                  onChange={(value) => {
                    form.change("applyTo", handleApplyToChange(value, fields.value));
                  }}>
                  {
                    replaceableFieldsSources.map((source, index) => {
                      return <Checkbox value={toFirstLower(source)} key={index}>
                        {transformNameToLabel(source)}
                      </Checkbox>;
                    })
                  }
                </CheckboxGroup>
                {
                  meta.error &&
                  <DetailsField.State variant={"error"}>
                    {meta.error}
                  </DetailsField.State>
                }
              </>
            );
          }
        }
      </FieldArray>
      <FormSpy subscription={{ values: true, initialValues: true }}>
        {
          ({ values, initialValues }) => {
            return compareWithInitial(initialValues.applyTo, values.applyTo) || phoneMessageTemplateId == "create" || (!initialValues.applyTo && !values.applyTo) ?//todo remove last condition after migration
              null :
              <DetailsField.State variant={"warning"}>
                This change may result in errors with replacement parameters when you use the sms template.
              </DetailsField.State>;
          }
        }
      </FormSpy>
    </DetailsField>
    <DetailsField>
      <DetailsField.Title>Use for</DetailsField.Title>
      {
        typeOptions.map((type, index) => {
          return <Field
            key={index}
            type={"radio"}
            value={type.value}
            name="type">
            {
              ({ input, meta }) => {
                {/*todo change this to rc radioButton*/}
                return (
                  <Radio
                    name={"type"}
                    checked={input.checked}
                    value={input.value}
                    key={index}
                    label={type.label}
                    onChange={(value) => {
                      input.onChange(value);
                    }}/>
                );
              }
            }
          </Field>;
        })
      }
      <OnChange
        name={"type"}
        children={handleTypeChange}
      />
    </DetailsField>
    <DetailsField>
      <DetailsField.Title>Available for</DetailsField.Title>
      <FieldArray
        name="availableFor"
        validate={(value, allValues, meta) => {
          if (meta.modified && value?.length == 0 || allValues[ "type" ] != "sms" && !value.includes("manual")) {
            return "Please select at least one option.";
          }
        }}>
        {
          ({ fields, meta }) => {
            return (
              <>
                <CheckboxGroup value={fields.value} onChange={(value) => form.change("availableFor", value)}>
                  <Checkbox value={"manual"}>Manual</Checkbox>
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      if (values.type == "sms") {
                        return <>
                          {
                            $object.className == "Settings" &&
                            <Checkbox value={"automated"}>Automated</Checkbox>
                          }
                          <Checkbox value={"bulk"}>Bulk</Checkbox>
                        </>;
                      }
                      return null;
                    }}
                  </FormSpy>
                </CheckboxGroup>
                {
                  (meta.error || meta.submitError) &&
                  <DetailsField.State variant={"error"}>
                    {meta.error || meta.submitError}
                  </DetailsField.State>
                }
              </>
            );
          }
        }
      </FieldArray>
    </DetailsField>
    <DetailsField>
      <DetailsField.Title>Status</DetailsField.Title>
      <Field
        name="enabled"
        type="checkbox">
        {({ input }) => {
          return <Checkbox
            checked={input.checked}
            onChange={(value, checked) => input.onChange(checked)}>
            Enable template
          </Checkbox>;
        }}
      </Field>
    </DetailsField>
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const owner = values?.owner;
        const role = options.find(option => option.value == owner?.role);
        return owner ?
          <>
            <DetailsField>
              <DetailsField.Title>Template owner</DetailsField.Title>
              <Stack direction={"row"} alignItems={"center"} spacing={16}>
                <Avatar size={"xl"} src={owner.objectIcon} showIndicator={false}>{owner.objectName}</Avatar>
                <Stack spacing={4} direction={"column"} alignItems={"flex-start"} childrenRenderMode={"clone"}>
                  <Typography variant={"base16"} lineHeight={"lh20"}>{owner.objectName}</Typography>
                  <Typography variant={"base14"} lineHeight={"lh16"} color={"tertiary"}>{role?.label}</Typography>
                </Stack>
              </Stack>
            </DetailsField>
          </>
          : null;
      }}
    </FormSpy>
  </DetailsContainer>;
});



