import React                  from "react";
import { FC }                 from "react";
import { useState }           from "react";
import { useMounted }         from "../../..";
import { BoxComponentProps }  from "../../..";
import { useImperativeState } from "../../..";
import { Input }              from "../../..";
import { Select }             from "../../..";
import { BaseInputProps }     from "../../..";
import { Box }                from "../../Box";

export interface CalendarDateProps extends Omit<BoxComponentProps, "onChange">, BaseInputProps {
  value?: OptionsValues;
}

export const CalendarDate: FC<CalendarDateProps> = React.memo(function CalendarDate(props) {
  const { value, onChange, ...p } = props;
  const [date, setDate] = useImperativeState(value, onChange);

  return (
    <Box  {...p}>
      <Select placeholder={"Select value"} options={options as any} value={date}
              onChange={({ value }) => {
                setDate(value)
              }}/>
    </Box>
  );
});
type OptionsValues = typeof options[number]["value"]
const options = [
  {
    label: "Today",
    value: "today"
  },
  {
    label: "Yesterday",
    value: "yesterday"
  },
  {
    label: "Tomorrow",
    value: "tomorrow"
  },
  {
    label: "This calendar week",
    value: "week"
  },
  {
    label: "This calendar month",
    value: "month"
  },
  {
    label: "This calendar year",
    value: "year"
  },
  {
    label: "Next calendar week",
    value: "next_week"
  },
  {
    label: "Next calendar month",
    value: "next_month"
  },
  {
    label: "Next calendar year",
    value: "next_year"
  },
  {
    label: "Last calendar week",
    value: "last_week"
  },
  {
    label: "Last calendar month",
    value: "last_month"
  },
  {
    label: "Last calendar year",
    value: "last_year"
  }
] as const;
