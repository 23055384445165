export const enum AccessibilityClasses {
  Accessibilities = "accessibilities",
  AccessibilitiesIcon = "accessibilities__icon",
  AccessibilitiesEmpty = "accessibilities__empty",
  AccessibilitiesTitle = "accessibilities__title",
  Accessibility = "accessibility",
  AccessibilityActive = "accessibility--active",
  AccessibilityItemDate = "accessibility__date",
  AccessibilityIcon = "accessibility__icon",
  AccessibilityContent = "accessibility__content",
  AccessibilityDescription = "accessibility__description",
  AccessibilityDescriptionActive = "accessibility__description--active",
  AccessibilityDescriptionTiny = "accessibility__description--tiny",
  AccessibilityInitiator = "accessibility__initiator",
}
