import { useMemo }                  from "react";
import { useNavigate }              from "@relcu/react-router";
import { useWindowDimensions }      from "@relcu/ui";
import { useSource }                from "@relcu/ui";
import { useModal }                 from "@relcu/ui";
import { getObjectPath }            from "../../../../utils/layoutUtils";
import { usePermissions }           from "../../../AccessControl";
import { SchemaDialog }             from "../../Dialog/SchemaDialog";
import { useMailApi }               from "../../../useMailApi";

export function useReferralHeader() {
  const [modal, modalHolder] = useModal(SchemaDialog);
  const { $object: node, $viewer } = useSource();
  const { defaultMailbox, nodeEmail, createDraftMail, createDraftMailData } = useMailApi(node);
  const navigate = useNavigate();
  const { canUpdate } = usePermissions(node);
  const windowDimensions = useWindowDimensions();
  const small = useMemo(() => {
    return windowDimensions.width < (window.__CONFIG__.f9 ? 1800 : 1600);
  }, [windowDimensions]);
  const api = {
    modalHolder,
    node,
    canUpdate,
    get showMore() {
      return small;
    },
    get canSendEmail() {
      return $viewer.identities?.find((c) => c?.provider == "microsoft")?.scope?.includes("Mail.Send");
    },
    get canContact() {
      return defaultMailbox && !createDraftMailData?.loading;
    },
    navigateToSms: () => {
      navigate(`${getObjectPath(node)}/sms`);
    },
    navigateToEmail: async () => {
      const to = nodeEmail ? [nodeEmail] : [];
      if (canUpdate) {
        const { data: { createDraftEmail: { conversation: { objectId } } } } = await createDraftMail({
          from: defaultMailbox.address,
          to: to
        });
        navigate(`${getObjectPath(node)}/emails/${objectId}`);
      } else {
        navigate(`${getObjectPath(node)}/emails`);
      }
    },
    onAddTask: () => {
      modal({
        className: "Task",
        record: {
          subject: node
        }
      });
    }

  };
  return api;
}
