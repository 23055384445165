import { FormSummaryField }  from "@relcu/ui";
import { TopLevelCondition } from "@relcu/ui";

//Closing costs conditions
export const WHEN_PROPERTY_TYPE_CONDO: TopLevelCondition = { and: [{ path: "$object.property.type", operator: "includes", value: ["condo"] }] };
export const WHEN_MIP: TopLevelCondition = { and: [{ path: "$object.loanProgram", operator: "equals", value: "fha" }, { path: "$object.financeMip", operator: "isEmpty" }] };
export const WHEN_FF: TopLevelCondition = { and: [{ path: "$object.loanProgram", operator: "equals", value: "va" }, { path: "$object.financeFf", operator: "isEmpty" }] };
export const WHEN_IN_PESTINSPECTION_FEE_STATES: TopLevelCondition = { and: [{ path: "$object.property.propertyAddress.state", operator: "includes", value: "$closingFees.pestInspectionFeeStates" }] };
export const WHEN_PESTINSPECTION_FEE: TopLevelCondition = { and: [...WHEN_IN_PESTINSPECTION_FEE_STATES.and,{ path: "$object.mortech.streamLine", operator: "neqEquals", value: true }, { path: "$object.loanProgram", operator: "equals", value: "va" }] };
export const WHEN_IN_DOC_REVIEW_FEE_STATES: TopLevelCondition = { and: [{ path: "$object.property.propertyAddress.state", operator: "includes", value: "$closingFees.docReviewFeeStates" }] };
export const WHEN_IN_ATTORNEY_FEE_STATES: TopLevelCondition = { and: [{ path: "$object.loanPurpose", operator: "equals", value: "purchase" }, { path: "$object.property.propertyAddress.state", operator: "includes", value: "$closingFees.attorneyFeeStates" }] };
export const WHEN_IN_SURVEY_FEE_STATES: TopLevelCondition = { and: [{ path: "$object.property.propertyAddress.state", operator: "includes", value: "$closingFees.surveyFeeStates" }] };
export const WHEN_SECONDARY_FINANCING_NOT_NONE: TopLevelCondition = { and: [{ path: "$object.secondaryFinancing", operator: "neqEquals", value: "none" }] };
export const WHEN_DISCOUNT_POINT: TopLevelCondition = { and: [{ path: "points", operator: "greater", value: 0 }] };
export const WHEN_LENDER_CREDITS: TopLevelCondition = { and: [{ path: "points", operator: "lowerEqual", value: 0 }] };
export const WHEN_CONDO_CERTIFICATE_FEE_INCLUDED: TopLevelCondition = { and: [{ path: "closingFees", operator: "contains", value: "condoCertificateFee" }, { path: "$object.property.type", operator: "includes", value: ["condo"] }] };
export const WHEN_EARNEST_MONEY_PAID_INCLUDED: TopLevelCondition = { and: [{ path: "closingFees", operator: "contains", value: "earnestMoneyPaid" }] };
export const WHEN_SELLER_CONCESSION_INCLUDED: TopLevelCondition = { and: [{ path: "closingFees", operator: "contains", value: "sellerConcession" }] };
export const WHEN_PMI_IS_NOT_MONTHLY: TopLevelCondition = { and: [{ path: "pmi", operator: "isNotEmpty" }] };


//Title Fee conditions
export const WHEN_TITLE_INSURANCE_IS_NOT_EMPTY: TopLevelCondition = { and: [{ path: "titleInsurance", operator: "neqEquals", value: null }] };
export const WHEN_RECORDING_CHARGES_IS_NOT_EMPTY: TopLevelCondition = { and: [{ path: "recordingCharges", operator: "neqEquals", value: null}] };
export const WHEN_OWNERS_TITLE_IS_NOT_EMPTY: TopLevelCondition = { and: [{ path: "ownersTitle", operator: "neqEquals", value: null}] };
export const WHEN_TRANSFER_TAX_IS_NOT_EMPTY: TopLevelCondition = { and: [{ path: "transferTax", operator: "neqEquals", value: null}] };
export const WHEN_SETTLEMENT_FEE_IS_NOT_EMPTY: TopLevelCondition = { and: [{ path: "settlementFee", operator: "neqEquals", value: null}] };
export const WHEN_LENDERS_TITLE_IS_NOT_EMPTY: TopLevelCondition = { and: [{ path: "lendersTitle", operator: "neqEquals", value: null}] };

//Prepaid costs conditions
export const WHEN_LOCK_IN_FEE_INCLUDED: TopLevelCondition = { and: [{ path: "prepaymentFees", operator: "contains", value: "lockInFee" }] };
export const WHEN_STREAMLINE_WO_APPRAISAL: TopLevelCondition = {or:[
    { path: "$object.mortech.streamLine", operator: "neqEquals", value: true  },
    { and: [{ path: "$object.mortech.streamLine", operator: "equals", value: true },{ path: "$object.mortech.streamLineWithAppraisal", operator: "equals", value: true  }] }
  ]};
export const WHEN_STREAMLINE_WO_CREDIT: TopLevelCondition = {or:[
    { path: "$object.mortech.streamLine", operator: "neqEquals", value: true  },
    { and: [{ path: "$object.mortech.streamLine", operator: "equals", value: true },{ path: "$object.mortech.streamLineWithCredit", operator: "equals", value: true }] }
  ]};
export const WHEN_IS_PURCHASE: TopLevelCondition = { and: [{ path: "$object.loanPurpose", operator: "equals", value: "purchase" }] };
export const WHEN_IS_REFINANCE: TopLevelCondition = { and: [{ path: "$object.loanPurpose", operator: "contains", value: "refinance" }] };

//Monthly payment conditions
export const WHEN_MONTHLY_PREMIUM_GREATER_THEN_0: TopLevelCondition = { and: [{ path: "monthlyPremium", operator: "greater", value: 0 }] };

//Summary conditions
export const PREPAID_COSTS_SUM_FIELDS: FormSummaryField[] = [
  { name: "yearlyInsurance", conditions: WHEN_IS_PURCHASE },
  { name: "propertyInsurancePrepaid" },
  { name: "prepaidInterest" },
  { name: "propertyTaxPrepaid" },
  { name: "lockInFee", conditions: WHEN_LOCK_IN_FEE_INCLUDED }
];

export const TITLE_FEE_SUM_FIELDS: FormSummaryField[] = [
  { name: "titleInsurance", conditions: WHEN_TITLE_INSURANCE_IS_NOT_EMPTY },
  { name: "recordingCharges", conditions: WHEN_RECORDING_CHARGES_IS_NOT_EMPTY },
  { name: "ownersTitle", conditions: WHEN_OWNERS_TITLE_IS_NOT_EMPTY },
  { name: "transferTax", conditions: WHEN_TRANSFER_TAX_IS_NOT_EMPTY },
  { name: "settlementFee", conditions: WHEN_SETTLEMENT_FEE_IS_NOT_EMPTY },
  { name: "lendersTitle", conditions: WHEN_LENDERS_TITLE_IS_NOT_EMPTY }
];

export const CLOSING_COSTS_SUM_FIELDS: FormSummaryField[] = [
  { name: "appraisalFee", conditions:WHEN_STREAMLINE_WO_APPRAISAL },
  { name: "discountPoints", conditions: WHEN_DISCOUNT_POINT },
  { name: "floodCertificationFee" },
  { name: "adminFee" },
  { name: "condoCertificateFee", conditions: WHEN_CONDO_CERTIFICATE_FEE_INCLUDED },
  { name: "fundingFee", conditions: WHEN_MIP },
  { name: "fundingFee", conditions: WHEN_FF },
  { name: "surveyFee", conditions: WHEN_IN_SURVEY_FEE_STATES },
  { name: "pestInspectionFee", conditions: WHEN_PESTINSPECTION_FEE },
  { name: "docReviewFee", conditions: WHEN_IN_DOC_REVIEW_FEE_STATES },
  { name: "attorneyFee", conditions: WHEN_IN_ATTORNEY_FEE_STATES },
  { name: "earnestMoneyPaid", conditions: WHEN_EARNEST_MONEY_PAID_INCLUDED },
  { name: "sellerConcession", conditions: WHEN_SELLER_CONCESSION_INCLUDED },

  { name: "titleInsurance", conditions: WHEN_TITLE_INSURANCE_IS_NOT_EMPTY },
  { name: "recordingCharges", conditions: WHEN_RECORDING_CHARGES_IS_NOT_EMPTY },
  { name: "ownersTitle", conditions: WHEN_OWNERS_TITLE_IS_NOT_EMPTY },
  { name: "transferTax", conditions: WHEN_TRANSFER_TAX_IS_NOT_EMPTY },
  { name: "settlementFee", conditions: WHEN_SETTLEMENT_FEE_IS_NOT_EMPTY },
  { name: "lendersTitle", conditions: WHEN_LENDERS_TITLE_IS_NOT_EMPTY },

  { name: "creditReportFee", conditions:WHEN_STREAMLINE_WO_CREDIT },
  { name: "loanSafeFee" },
  { name: "mersFee" },
  { name: "verificationFee" },
  { name: "electronicDocumentDelivery" },
  { name: "transcriptFee" },
  { name: "subordinationFee", conditions: WHEN_SECONDARY_FINANCING_NOT_NONE },
  { name: "pmi", conditions: WHEN_PMI_IS_NOT_MONTHLY }
];

export const OTHER_COSTS_SUM_FIELDS: FormSummaryField[] = [
  { name: "lenderCredits", conditions:WHEN_LENDER_CREDITS  },
  { name: "appraisalFee", conditions:WHEN_STREAMLINE_WO_APPRAISAL },
  { name: "discountPoints", conditions: WHEN_DISCOUNT_POINT },
  { name: "floodCertificationFee" },
  { name: "adminFee" },
  { name: "condoCertificateFee", conditions: WHEN_CONDO_CERTIFICATE_FEE_INCLUDED },
  { name: "fundingFee", conditions: WHEN_MIP },
  { name: "fundingFee", conditions: WHEN_FF },
  { name: "surveyFee", conditions: WHEN_IN_SURVEY_FEE_STATES },
  { name: "pestInspectionFee", conditions: WHEN_PESTINSPECTION_FEE },
  { name: "docReviewFee", conditions: WHEN_IN_DOC_REVIEW_FEE_STATES },
  { name: "attorneyFee", conditions: WHEN_IN_ATTORNEY_FEE_STATES },
  { name: "earnestMoneyPaid", conditions: WHEN_EARNEST_MONEY_PAID_INCLUDED },
  { name: "sellerConcession", conditions: WHEN_SELLER_CONCESSION_INCLUDED },
  { name: "subordinationFee", conditions: WHEN_SECONDARY_FINANCING_NOT_NONE },
];
export const TOTAL_CLOSING_COSTS_SUM_FIELDS: FormSummaryField[] = [
  ...CLOSING_COSTS_SUM_FIELDS,
  ...PREPAID_COSTS_SUM_FIELDS,
  { name: "lenderCredits", conditions:WHEN_LENDER_CREDITS  }
];

export const CREDIT_REPORT_SUM_FIELDS: FormSummaryField[] = [
  { name: "creditReportFee", conditions:WHEN_STREAMLINE_WO_CREDIT  },
  { name: "loanSafeFee" },
  { name: "mersFee" },
  { name: "verificationFee" },
  { name: "electronicDocumentDelivery" },
  { name: "transcriptFee" },
];

export const MONTHLY_INSURANCE_TAXES_SUM_FIELDS: FormSummaryField[] = [
  { name: "monthlyTax" },
  { name: "monthlyInsurance" },
];

export const MORTGAGE_INSURANCE_SUM_FIELDS: FormSummaryField[] = [
  { name: "pmi" },
  { name: "monthlyPremium" },
];
export const MONTHLY_PAYMENT_SUM_FIELDS: FormSummaryField[] = [
  ...MONTHLY_INSURANCE_TAXES_SUM_FIELDS,
  ...MORTGAGE_INSURANCE_SUM_FIELDS,
  { name: "principal" },
];

export const REFINANCE_SUM_FIELDS: FormSummaryField[] = [
  { name: "currentMortgageBalance" },
  { name: "loanAmount" },
  { name: "totalLoanAmount" },
  { name: "totalClosingCosts" },
]

export const PURCHASE_SUM_FIELDS: FormSummaryField[] = [
  { name: "purchaseValue" },
  { name: "loanAmount" },
  { name: "totalLoanAmount" },
  { name: "totalClosingCosts" },
]