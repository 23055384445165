import React from "react";

export const MicrosoftLogo = React.memo(function MicrosoftLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path fill="#FFF" d="M0 0H24V24H0z"
                  transform="translate(-890.000000, -749.000000) translate(890.000000, 749.000000)"/>
            <g>
              <path fill="#F65314" d="M0 0L8.563 0 8.562 8.563 0 8.563z"
                    transform="translate(-890.000000, -749.000000) translate(890.000000, 749.000000) translate(3.000000, 3.000000)"/>
              <path fill="#7FBB41" d="M9.437 0H18v8.563H9.437V0z"
                    transform="translate(-890.000000, -749.000000) translate(890.000000, 749.000000) translate(3.000000, 3.000000)"/>
              <path fill="#00A1F1" d="M0 9.437h8.563V18H0V9.437z"
                    transform="translate(-890.000000, -749.000000) translate(890.000000, 749.000000) translate(3.000000, 3.000000)"/>
              <path fill="#FB0" d="M9.438 9.438H18V18H9.437V9.438z"
                    transform="translate(-890.000000, -749.000000) translate(890.000000, 749.000000) translate(3.000000, 3.000000)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
});
