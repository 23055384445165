import React                   from "react";
import { Box }                 from "../Box";
import { BoxComponentProps }   from "../Box";
import { TimelineItemClasses } from "./TimelineItemClasses";


export const TimelineItemSubject = React.memo<BoxComponentProps>(({ children }) => {
  return <Box container alignItems={"center"} gap={"XXXS"} className={TimelineItemClasses.TimelineItemSubject}>
    {children}
  </Box>;
});