import { useMemo }           from "react";
import React                 from "react";
import { classNames }        from "../..";
import { BoxComponentProps } from "../..";
import { Box }               from "../..";
import { concatText }        from "../../utils/helpers";
import { stringToHslColor }  from "../../utils/helpers";
import { validURL }          from "../../utils/helpers";
import { IconType }          from "../Icon";
import { FontIcon }          from "../Icon";

export enum AvatarSizes {
  Mini = "mini",
  Tiny = "tiny",
  Small = "small",
  Medium = "medium",
  Big = "big",
  Large = "large",
  Huge = "huge"
}

export enum AvatarColors {
  Primary = "primary",
  Success = "success",
  Warning = "warning",
  White = "white",
}

export interface AvatarProps extends BoxComponentProps {
  icon?: IconType | string,
  text?: string,
  size?: AvatarSizes,
  borderColor?: AvatarColors
  color?: AvatarColors
}

const defaultAvatarProps: AvatarProps = {
  icon: null,
  text: null,
  size: AvatarSizes.Medium,
  borderColor: AvatarColors.Primary
};

Avatar.defaultProps = defaultAvatarProps;
export function Avatar(props: AvatarProps) {
  const { className, size, children, text, icon, borderColor, style = {}, color, ...p } = props;
  const isValidImage = validURL(icon);
  const classes = classNames(AvatarClasses.Avatar, {
    [ AvatarClasses.Mini ]: (size == AvatarSizes.Mini),
    [ AvatarClasses.Tiny ]: (size == AvatarSizes.Tiny),
    [ AvatarClasses.Small ]: (size == AvatarSizes.Small),
    [ AvatarClasses.Medium ]: (size == AvatarSizes.Medium),
    [ AvatarClasses.Big ]: (size == AvatarSizes.Big),
    [ AvatarClasses.Large ]: (size == AvatarSizes.Large),
    [ AvatarClasses.Huge ]: (size == AvatarSizes.Huge),

    [ AvatarClasses.Primary ]: color == AvatarColors.Primary,
    [ AvatarClasses.Success ]: color == AvatarColors.Success,
    [ AvatarClasses.Warning ]: color == AvatarColors.Warning,

    [ AvatarClasses.BorderWhite ]: (borderColor == AvatarColors.White),

    [ AvatarClasses.Border ]: isValidImage

  }, className);

  let midName = useMemo(() => text ? concatText(text.replaceAll("  ", " "), size == AvatarSizes.Tiny ? 1 : 2).toUpperCase() : "", [text, size]);
  if (text && !isValidImage && !color) {
    style[ "--avatar-bg-color" ] = stringToHslColor(text, 70, 60);
  }

  if (isValidImage || /^data:image.+;base64,/.test(icon)) {
    return <Box className={classes} alignSelf={"center"} flexShrink={0} style={style} {...p}>
      <img alt={midName} src={icon}/>
    </Box>;
  } else if (!isValidImage && icon) {
    return !color
      ?
      <FontIcon type={icon as IconType}
                className={classNames(className, AvatarClasses.AvatarIcon)}  {...p}/>
      :
      <Box className={classes} alignSelf={"center"} flexShrink={0} style={style} {...p}>
        <FontIcon type={icon as IconType}
                  className={classNames(className, AvatarClasses.AvatarIcon)}/>
      </Box>;
  } else if (midName) {
    return <Box className={classes} alignSelf={"center"} flexShrink={0} style={style} {...p}>
      {midName}
    </Box>;
  }

  return <Box className={classes} alignSelf={"center"} flexShrink={0} style={style} {...p}>
    <FontIcon type={"person"}/>
  </Box>;
}

export enum AvatarClasses {
  Avatar = "avatar",
  AvatarIcon = "avatar-icon",
  Mini = "avatar_mini",
  Tiny = "avatar_tiny",
  Small = "avatar_small",
  Medium = "avatar_medium",
  Large = "avatar_large",
  Big = "avatar_big",
  Huge = "avatar_huge",
  Border = "border",
  Primary = "avatar_primary",
  Success = "avatar_success",
  Warning = "avatar_warning",
  BorderWhite = "border-white",
}
