export interface DraggableProps {
  effectAllowed: "copy" | "move" | "link" | "none" | "copyMove" | "copyLink" | "linkMove" | "all",
  id: number | string
  droppableId: string,
  item,
  draggable?: boolean,
  dragImage?: any;
  onDragEndCb?(e),
  onDragStartCb?(e),
  onDragCb?(e)
}

export const useDraggable = (args: DraggableProps) => {
  const {
    effectAllowed = "all",
    draggable = true,
    droppableId,
    onDragEndCb,
    onDragStartCb,
    onDragCb,
    id,
    item
  } = args;

  const onDragStart = (e) => {
    e.stopPropagation();
    e.target.style.opacity = 0.5;
    e.dataTransfer.clearData();
    e.dataTransfer.setData(`type/${droppableId}-${item.objectType}`, JSON.stringify({ data: item }));
    e.dataTransfer.effectAllowed = effectAllowed;
    onDragStartCb && onDragStartCb(e);
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    e.target.style.opacity = "unset";
    onDragEndCb && onDragEndCb(e);
  };

  const onDrag = (e) => {
    onDragCb && onDragCb(e);
  };

  return { id, draggable, onDragStart, onDragEnd, onDrag };
};
