import { useCallback }    from "react";
import React              from "react";
import { isEmail }        from "../../../utils/helpers";
import { BaseField }      from "../BaseField";
import { TextFieldProps } from "../TextField";
import EmailEditField     from "./EmailEditField";
import EmailReadField     from "./EmailReadField";

export const EmailField = React.memo<TextFieldProps>(function EmailField(props) {
  const toLowercase = useCallback((value) => {
    if (value) {
      return value.toLowerCase();
    }
    return value;
  }, []);
  const validate = useCallback((value) => {
      if (value && !isEmail(value)) {
          return `${props.label || "Email"} is invalid`;
      }
  }, []);
  return (
    <BaseField
      {...props}
      format={toLowercase}
      parse={toLowercase}
      validate={validate}
      readView={<EmailReadField/>}
      editView={<EmailEditField/>}
    />
  );
});
