import { useForm }             from "@relcu/form";
import { OnChange }            from "@relcu/form";
import React                   from "react";
import { Field }               from "@relcu/form";
import { useBoxProps }         from "../../..";
import { FontIcon }            from "../../..";
import { MenuItem }            from "../../..";
import { InputState }          from "../../..";
import { Autocomplete }        from "../../..";
import { Label }               from "../../..";
import { Box }                 from "../../..";
import { classNames }          from "../../..";
import { useConsumeField }     from "../BaseField";
import { BaseFieldClasses }    from "../BaseField";
import { ChoiceField }         from "../ChoiceField";
import { HiddenField }         from "../HiddenField";
import { TextField }           from "../TextField";
import { AddressFieldProps }   from "./AddressField";
import { AddressFieldClasses } from "./AddressFieldClasses";
import { useAddressField }     from "./useAddressField";

export const AddressEditField = React.memo(function AddressEditField() {
  const {
    input: {
      className, label, name, required, value, defaultValue, fullSize = true, onChange, options, ...otherProps
    }
  } = useConsumeField<AddressFieldProps & ReturnType<typeof useAddressField>>();
  const form = useForm();
  const classes = classNames(AddressFieldClasses.AddressFieldContainer, {
    [ BaseFieldClasses.FullSize ]: fullSize
  }, className);
  return <Box className={classes} container {...useBoxProps(otherProps)}>
    <Box container direction={"column"} flex={1} gap={"XXXS"}>
      {
        label &&
        <Label required={required} mLeft>
          {label}
        </Label>
      }
      <Box container gap={"XS"} justify={"space-between"}
           className={AddressFieldClasses.AddressFieldAutocompleteContainer}>
        <Field name={`${name}.state`}
               subscription={{ error: true, submitFailed: true }}>
          {({ meta: { error, submitFailed } }) => (
            <Autocomplete
              message={(submitFailed && error) || otherProps.message}
              state={submitFailed && error && InputState.Error}
              onInputChange={otherProps.onSearch}
              onBlur={otherProps.onBlur}
              options={otherProps.data as any[]}
              loading={otherProps.loading}
              value={otherProps.formatted}
              optionKey={"title"}
              optionLabel={"title"}
              onChange={otherProps.onSelect}
              renderOption={option => (
                <MenuItem
                  thumbnail={<FontIcon type={otherProps.getOptionIcon(option)}/>}
                >
                  {option.address.label}
                </MenuItem>
              )}
              placeholder={"Search address here"}
            />
          )}
        </Field>
        <FontIcon
          type={otherProps.extendedView ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          onClick={() => otherProps.setExtendedView((p) => (!p))}
        />
      </Box>
      <Box container gap={"XS"} justify={"space-between"} direction={"column"}
           className={classNames(AddressFieldClasses.AddressFieldFormContainer, { [ AddressFieldClasses.AddressFieldFormHidden ]: !otherProps.extendedView })}>
        <Box container gap={"XS"} justify={"space-between"}>
          <TextField label={"Street address"} name={`${name}.street`} value={value?.street}
                     placeholder={"e.g. 123 Berkley Blvd."} flexGrow={1}/>
          <TextField label={"Apartment/Suite #"} name={`${name}.street2`} value={value?.street2}
                     placeholder={"e.g. Apt #7"} flexGrow={1}/>
        </Box>
        <Box container gap={"XS"} justify={"space-between"}>
          <TextField label={"City"} name={`${name}.city`} value={value?.city} placeholder={"e.g. Bellingham"}
                     flexGrow={1}/>
          <TextField label={"County"} name={`${name}.county`} value={value?.county} placeholder={"e.g. Los Angeles"}
                     flexGrow={1}/>
        </Box>
        <Box container gap={"XS"} justify={"space-between"}>
          <ChoiceField options={options} required={required} label={"State"}
                       name={`${name}.state`} value={value?.state} placeholder={"e.g. California"} flexGrow={1}
                       flexBasis={"calc(50% - 16px)"}/>
          <TextField label={"Postal Code"} name={`${name}.zipCode`} value={value?.zipCode} placeholder={"e.g. 98226"}
                     flexGrow={1}
                     flexBasis={"calc(50% - 16px)"}/>
        </Box>
        <HiddenField name={`${name}.formatted`}/>
        <HiddenField name={`${name}.country`} defaultValue={value?.country}/>
        <HiddenField name={`${name}.lat`}/>
        <HiddenField name={`${name}.lng`}/>
        <OnChange name={name} children={(value, previous) => {
          if (!value) {
            return value;
          }
          let formatted = [
            value.street ? `${value.street}, ` : "",
            value.city ? `${value.city}, ` : "",
            value.state ? `${value.state} ` : "",
            value.zipCode ? `${value.zipCode}` : ""].join("");
          if (value.formatted !== formatted) {
            form.change(`${name}.formatted`, formatted);
          }
        }}/>
      </Box>
    </Box>
  </Box>;
});

export default AddressEditField;
