import React               from "react";
import { useMemo }         from "react";
import { useEffect }       from "react";
import { useState }        from "react";
import { FC }              from "react";
import { Typography }      from "../index";
import { TypographyProps } from "../index";

export function pad(num: number) {
  return num.toString().length > 1 ? num : "0" + num;
}

export interface TimerProps extends TypographyProps {
  live?: boolean,

  startFrom?: { h: number, m: number, s: number }
}
export const Timer: FC<TimerProps> = React.memo((props) => {
  const { live = true, startFrom = { h: 0, m: 0, s: 0 }, ...p } = props;
  const [duration, setDuration] = useState<any>(startFrom);
  const h = useMemo(() => pad(duration.h), [duration.h]);
  const m = useMemo(() => pad(duration.m), [duration.m]);
  const s = useMemo(() => pad(duration.s), [duration.s]);
  useEffect(() => {
    let timer;
    if (live) {
      timer = setInterval(() => {
        setDuration(({ h, m, s }) => {
          s++;
          if (s >= 59) {
            s = 0;
            m++;
          }
          if (m == 59) {
            h++;
          }
          return { h, m, s };
        });
      }, 1000);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [live]);
  return <Typography lineHeight={"lh24"} {...p}>{h}:{m}:{s}</Typography>;
});
