import { BaseFieldProps, MultiChoiceFieldProps } from "@relcu/ui";
import { BaseField }                             from "@relcu/ui";
import React                                     from "react";
import { FC }                                    from "react";
import { TagsEditField }                         from "./TagsEditField";

export interface TagsFieldProps extends MultiChoiceFieldProps {
  targetClass: string
}

export const TagsField: FC<TagsFieldProps> = React.memo(function UsersAndTagsField(props) {
  return <BaseField {...props} readView={null} editView={<TagsEditField/>}/>;
});
