import React               from "react";
import { FC }              from "react";
import { useMemo }         from "react";
import { DateTime }        from "luxon";
import { isValidDate }     from "../../..";
import { DatePickerProps } from "../../Input/DatePicker";
import { BaseFieldProps }  from "../BaseField";
import { BaseField }       from "../BaseField";
import DateEditField       from "./DateEditField";
import DateReadField       from "./DateReadField";

export type DateFieldProps = BaseFieldProps & DatePickerProps & { isTime?: boolean };
export const DateField: FC<DateFieldProps> = React.memo(function DateField(props) {
  const { isTime, ...inputProps } = props;
  const type = (isTime === true) ? "datetime" : props.type || "date";
  const format = useMemo(() => type === "datetime" ? "MM/dd/yyyy hh:mm a" : "MM/dd/yyyy", [type]);
  inputProps.type = type;

  return (
    <BaseField
      {...inputProps}
      format={value => {
        if (isValidDate(value)) {
          return DateTime.fromJSDate(new Date(value)).toFormat(format);
        }
        return value || null;
      }}
      validate={(value) => {
        if (value && value != format && !isValidDate(value)) {
          return `${props.label} is invalid.`;
        }
      }}
      readView={<DateReadField/>}
      editView={<DateEditField/>}
    />
  );
});
