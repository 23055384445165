import React                 from "react";
import { BoxComponentProps } from "../../..";
import { Label }             from "../../..";
import { Box }               from "../../..";

interface CheckGroupProps extends BoxComponentProps {
  label?: string;
}

export function CheckGroup(props: CheckGroupProps) {
  const { label, children, ...p } = props;
  return <Box container gap={"XXXS"} direction={"column"} {...p}>
    {
      label && <Label mLeft>{label}</Label>
    }
    {children}
  </Box>;
}
