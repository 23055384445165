import React               from "react";
import { FC }              from "react";
import { ToggleDropdown }  from "@relcu/rc";
import { Icon }            from "@relcu/rc";
import { Alignment }       from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { VerticalDivider }   from "@relcu/ui";
import { IconButton }        from "@relcu/rc";
import { Tooltip }           from "@relcu/ui";
import { HeaderCall }        from "../HeaderCall";
import "../LeadHeader/lead-header.css";
import { useReferralHeader } from "./useReferralHeader";

export const ReferralHeader: FC = React.memo(function ReferralHeader(props) {
  const api = useReferralHeader();

  return <Box container alignItems={"center"} justify={"end"} gap={"XXS"} flexBasis={"50%"}
              className={"lead-header"}>
    {api.modalHolder}
      <HeaderCall/>
    {props.children}
    <VerticalDivider/>
    {
      api.showMore ?
        <ToggleDropdown
          placement={"autoVerticalEnd"}
          toggleRenderer={
            <IconButton
              icon={<Icon type={"keyboard_control"}/>}
              size={"md"}
              style={{flexShrink: 0}}
            />}
        >
          <ToggleDropdown.Item onSelect={api.navigateToSms}>
            Send SMS
          </ToggleDropdown.Item>
          <ToggleDropdown.Item disabled={!api.canContact} onSelect={api.navigateToEmail}>
            Write email
          </ToggleDropdown.Item>
          <ToggleDropdown.Item disabled={!api.canUpdate} onSelect={api.onAddTask}>
            Create task
          </ToggleDropdown.Item>
        </ToggleDropdown>
        :
        <>
            <Tooltip title={"Send SMS"} alignment={Alignment.Bottom}>
              <IconButton
                style={{flexShrink: 0}}
                icon={<Icon type={"forum"}/>}
                size={"md"}
                onClick={api.navigateToSms}
              />
            </Tooltip>
            <VerticalDivider/>
            <Tooltip title={"Send email"} alignment={Alignment.Bottom}>
              <IconButton
                style={{flexShrink: 0}}
                icon={<Icon type={"email"}/>}
                size={"md"}
                onClick={api.navigateToEmail}
                disabled={!api.canContact}
              />
            </Tooltip>
            <VerticalDivider/>
          <Tooltip title={"Create a task"} alignment={Alignment.Bottom}>
            <IconButton
              style={{flexShrink: 0}}
              icon={<Icon type={"add_task"}/>}
              size={"md"}
              onClick={api.onAddTask}
              disabled={!api.canUpdate}
            />
          </Tooltip>
        </>
    }
  </Box>;
});
