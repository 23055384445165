import React, { useEffect, useState } from "react";
import { Box }                        from "../..";
import { FontIcon }                   from "../..";
import { BoxComponentProps }          from "../..";
import { classNames }                 from "../..";
import { PaginationClasses }          from "./PaginationClasses";
import { PaginationItem }             from "./PaginationItem";

export interface PaginationProps extends BoxComponentProps {
  count: number
  defaultPage?: number
  siblingCount?: number
  boundaryCount?: number
  onChange?(currentPage?),
}

export const defaultPaginationProps = {
  defaultPage: 1,
  siblingCount: 1,
  boundaryCount: 1
};

Pagination.defaultProps = defaultPaginationProps;
export function Pagination(props: PaginationProps) {
  const { className, count, children, onChange, defaultPage, siblingCount, boundaryCount, ...p } = props;
  const [current, setCurrent] = useState(props.defaultPage);
  const classes = classNames(PaginationClasses.Pagination, className);
  const siblingsCount = parseInt(siblingCount as any) || 1;//demo send string instead of number
  const boundCount = boundaryCount - 1;

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({
      length: length
    }, function (_, i) {
      return start + i;
    });
  };

  let startPages = range(1, Math.min(boundCount + 1, count));
  let endPages = range(Math.max(count - boundCount, boundCount + 2), count);
  let siblingsStart = Math.max(Math.min(
    current - siblingsCount, // Lower boundary when page is high
    count - boundCount - siblingsCount * 2 - 2), // Greater than startPages
    boundCount + 3);
  let siblingsEnd = Math.min(Math.max(
    current + siblingsCount, // Upper boundary when page is low
    boundCount + siblingsCount * 2 + 3), // Less than endPages
    endPages[ 0 ] - 2);
  let itemList = [].concat(["previous"], startPages, siblingsStart > boundCount + 3 ? ["start-ellipsis"] : 2 + boundCount < count - boundCount - 1 ? [2 + boundCount] : [], range(siblingsStart, siblingsEnd), siblingsEnd < count - boundCount - 2 ? ["end-ellipsis"] : count - boundCount - 1 > boundCount + 1 ? [count - boundCount - 1] : [], endPages, ["next"]);

  useEffect(() => {
    setCurrent(defaultPage);
  }, [defaultPage]);

  const setPage = (item) => {
    if ((current == endPages[ 0 ] && item == "next") || (current == startPages[ 0 ] && item == "previous") || endPages[ 0 ] == undefined) {
      return;
    }

    if (item == "next") {
      item = current + 1;
    } else if (item == "previous") {
      item = current - 1;
    }

    if (onChange) {
      onChange(item);
    }

    setCurrent(item);
  };

  const renderItem = (item) => {
    switch (item) {
      case "previous":
        return <FontIcon type={"keyboard_arrow_left"} className={classNames({
          [ PaginationClasses.Hidden ]: current === 1
        })}/>;
      case "next":
        return <FontIcon type={"keyboard_arrow_right"} className={classNames({
          [ PaginationClasses.Hidden ]: current === count
        })}/>;
      case "start-ellipsis":
      case "end-ellipsis":
        return "...";
      default:
        return item;
    }
  };

  return <Box container {...p} className={classes} gap={"XXS"}>
    {itemList.map((item, index) => {
      return <PaginationItem selected={item === current} key={index}
                             onClick={item == "end-ellipsis" || item == "start-ellipsis" ? null : () => setPage(item)}>
        {renderItem(item)}
      </PaginationItem>;
    })}
  </Box>;
}

