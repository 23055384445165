import React                     from "react";
import { FC }                    from "react";
import { useNavigate }           from "@relcu/react-router";
import { FieldArrayRenderProps } from "@relcu/final-form";
import { useSource }             from "@relcu/ui";
import { useAlert }              from "@relcu/ui";
import { getObjectPath }         from "../../../../../utils/layoutUtils";
import { useJqlMutation }        from "../../../Jql";
import { LeadMemberRowProps }    from "./LeadMemberRow";
import { LeadMemberRow }         from "./LeadMemberRow";

export const LeadReferralMemberRow: FC<Omit<FieldArrayRenderProps<any, any>, "meta"> & LeadMemberRowProps> = React.memo(function LeadReferralMemberRow(props) {
  const navigate = useNavigate();
  const { info } = useAlert();
  const { $object } = useSource();
  const [doConvertToReferralPartner] = useJqlMutation({
    operation: "convertToReferralPartner",
    variables: {
      contactId: {
        type: "ID!"
      },
      userId: {
        type: "ID!"
      }
    },
    fields: [
      "id",
      "objectId"
    ]
  }, {
    operationName: "ConvertToReferralPartner",
    context: {
      errorPolicy: "ignore"
    }
  });

  const handleClick = (id) => {
    navigate(`/${getObjectPath({ __typename: "ReferralPartner", objectId: id })}/details`);
  };

  const onConvert = async (contact) => {
    const { data: { convertToReferralPartner } = {}, errors: [error] = [] } = await doConvertToReferralPartner({
      errorPolicy: "all",
      variables: {
        contactId: contact.objectId,
        userId: $object.assignedTo.objectId
      }
    });
    if (error) {
      info(
        <p>{error.message}
          <a
            onClick={() => handleClick(error.extensions?.objectId)} style={{
            color: "white",
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: 500
          }}>
            See here
          </a>
        </p>
      );
    }
    if (convertToReferralPartner) {
      info(
        <p>The system just created a referral partner with this contact information.
          <a
            onClick={() => handleClick(convertToReferralPartner.objectId)} style={{
            color: "white",
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: 500
          }}>
            See here
          </a>
        </p>
      );
    }

  };

  return <LeadMemberRow
    onConvert={$object.assignedTo ? (contact) => onConvert(contact) : null}
    {...props}
  />;
});
