import { FC }                   from "react";
import { useCallback }          from "react";
import React                    from "react";
import { useFormState }         from "@relcu/form";
import { ButtonColors }         from "@relcu/ui";
import { ButtonVariants }       from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { classNames }           from "@relcu/ui";
import { LinkClasses }          from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { JsonSection }          from "@relcu/ui";
import { JsonFieldProps }       from "@relcu/ui";
import { EmptyList }            from "@relcu/ui";
import { useStreetViewSection } from "./useStreetViewSection";

export const StreetViewSection: FC<JsonFieldProps> = React.memo(function StreetViewSection(props) {
  const { ref, ...rest } = props;
  const { zillowLink } = useStreetViewSection(props.selectedFields);
  const { pristine, submitting } = useFormState({ subscription: { pristine: true, submitting: true } });
  // TODO : once confirmed the test, remove styles and implement class based
  return (
    <React.Fragment>
      <JsonSection fields={props.selectedFields} {...rest} editable={true} view={"write"}/>
      <Box container gap={"XXS"} direction={"row"}
           style={{ position: "absolute", top: "16px", right: "16px", color: "#" }} alignContent={"center"}
           alignItems={"center"}>
        {!pristine && <Button icon={"save"} onlyIcon={true} variant={ButtonVariants.Ghost} color={ButtonColors.White}
                              disabled={submitting} type={"submit"}/>}
        <a href={zillowLink}
           className={classNames(LinkClasses.Link, LinkClasses.Primary)} target={"_blank"}
           style={{ lineHeight: "34px" }}>Go to
          Zillow page</a>
      </Box>
      <Box container gap={"XXS"} direction={"column"} id={"street-view"} style={{ height: "600px" }} flexBasis={"100%"}
           justify={"center"}>
        <EmptyList icon={"location_on"} content={"The street view for the given address not found."}
                   title={"Street view not found"}/>
      </Box>
    </React.Fragment>
  );
});

export default StreetViewSection;
