import { gql }                         from "@apollo/client";
import { useQuery }                    from "@apollo/client";
import React                           from "react";
import { EmptyState }                  from "@relcu/rc";
import { humanize }                    from "@relcu/ui";
import { CircularLoader }              from "@relcu/ui";
import { ModalProps }                  from "@relcu/ui";
import { Modal }                       from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { ModalBody }                   from "@relcu/ui";
import { Column }                      from "rsuite-table";
import { Cell }                        from "rsuite-table";
import { HeaderCell }                  from "rsuite-table";
import { Table }                       from "rsuite-table";
import { GetIneligibleRatesVariables } from "./__types__/GetIneligibleRates";
import { GetIneligibleRates }          from "./__types__/GetIneligibleRates";
import "./ineligibleRatesModal.css";

export interface IneligibleRateDialogProps extends Partial<ModalProps> {
  searchId: string;
}

export const IneligibleRateDialog = React.memo<IneligibleRateDialogProps>((props) => {
  const { data: { optimalBlueIneligibleRates } = {}, loading } = useQuery<GetIneligibleRates, GetIneligibleRatesVariables>(GET_INELIGIBLE_RATES, {
    variables: {
      searchId: props.searchId
    }
  });

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      title={`Optimal Blue ineligible rates`}
      closeIcon={true}
      disableBackdropClick={false}
      variant={"large"}
    >
      <ModalBody container direction={"column"} gap={"XS"} flexGrow={1}
                 className="base-dialog-container ineligible-modal_body">
        <Box container direction={"column"} flex={"1 1 auto"} gap={"XS"}>
          {loading
            ? <Box container flex={1} alignItems={"center"} justify={"center"}>
              <CircularLoader/>
            </Box>
            : !!optimalBlueIneligibleRates?.products?.length
              ? <Table affixHeader={1} data={optimalBlueIneligibleRates.products} bordered cellBordered
                       wordWrap="break-word"
                       style={{ width: "100%", border: "1px, solid #eee" }} height={400}>
                {
                  Object.keys(optimalBlueIneligibleRates.products[ 0 ])?.map((k) => {
                    if (["__typename", "productCode", "productId"].includes(k)) {
                      return;
                    }
                    return <Column key={k} flexGrow={1}>
                      <HeaderCell>{humanize(k)}</HeaderCell>
                      <Cell>
                        {
                          rowData => {
                            if (k === "ineligibleReason") {
                              return <ol type={"1"}>{rowData[ k ]?.map((r, i) => (
                                <li key={i}><b>{i + 1}.</b> {r}</li>))}</ol>;
                            }
                            return rowData[ k ];
                          }
                        }
                      </Cell>
                    </Column>;
                  })
                }
              </Table>
              : <Box className={"ineligible-empty_state_container"}>
                <EmptyState
                  background
                  icon={"cached"}
                  title={"No ineligible rates"}/>
              </Box>}
        </Box>
      </ModalBody>
    </Modal>
  );
});

export const GET_INELIGIBLE_RATES = gql`
  query GetIneligibleRates ($searchId: String!) {
    optimalBlueIneligibleRates (searchId: $searchId) {
      searchId
      products {
        productName
        investor
        ineligibleReason
        ineligibleStatus
        productCode
        productId
      }
    }
  }
`;