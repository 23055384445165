import React                        from "react";
import { RsRefForwardingComponent } from "../../@types/common";
import { Cell }                     from "../../TableHorizontal";
import { CellProps }                from "../../TableHorizontal/Cell";
import { useClassNames }            from "../../utils";

export interface InputCellProps extends CellProps {
}

export const InputCell: RsRefForwardingComponent<"input", InputCellProps> = React.forwardRef((props: InputCellProps, ref) => {
  const {
    as: Component = "input",
    className,
    classPrefix = "cell-input",
    helperText,
    status,
    affected,
    space,
    revers,
    icon,
    onAnimationEnd,
    style,
    inert,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Cell icon={icon} status={status} helperText={helperText} className={classes} affected={affected} space={space} onAnimationEnd={onAnimationEnd} revers={revers} style={style} inert={inert}>
    <Component
      {...rest}>
    </Component>
  </Cell>;
});
