import React                   from "react";
import { FC }                  from "react";
import { useEffect }           from "react";
import { useMemo }             from "react";
import { useMutation }         from "@apollo/client";
import { useField }            from "@relcu/form";
import { useFormState }        from "@relcu/form";
import { useForm }             from "@relcu/form";
import { Mime }                from "@relcu/mime";
import { FileUploadCard }      from "@relcu/rc";
import { useLatest }           from "@relcu/ui";
import { formatBytes }         from "../../../../../utils/helpers";
import { fileToBase64 }        from "../../../../../utils/helpers";
import { RemoveFileVariables } from "../../../../Message/Messanger/__types__/RemoveFile";
import { RemoveFile }          from "../../../../Message/Messanger/__types__/RemoveFile";
import { UploadFileVariables } from "../../../../Message/Messanger/__types__/UploadFile";
import { UploadFile }          from "../../../../Message/Messanger/__types__/UploadFile";
import { UPLOAD_FILE }         from "../../../../Message/Messanger/Messenger";
import { REMOVE_FILE }         from "../../../../Message/Messanger/Messenger";

export interface AttachmentProps {
  name: string,
  shouldRemove: (file) => boolean,
  onDelete: () => void,
  removeAble:boolean
  objectTypes: string[]
}

export const Attachment: FC<AttachmentProps> = React.memo(function Attachment(props) {
  const removeAble = props.removeAble ?? true
  const form = useForm();
  const { submitting } = useFormState({ subscription: { submitting: true } });
  //TODO make mutations common
  const [sendFile, { loading }] = useMutation<UploadFile, UploadFileVariables>(UPLOAD_FILE);
  const [removeFile] = useMutation<RemoveFile, RemoveFileVariables>(REMOVE_FILE);
  const { input, meta } = useField(props.name, {
    validate(value, allValue, meta) {
      if (!value) {
        return;
      }
      const type = value.mimeType || value.type || Mime.getType(value.name);
      if (!props.objectTypes.includes(type)) {
        return "Invalid file type";
      }
      if (value?.size > 5 * 1024 * 1024) {
        return "File is too large (max: 5MB)";
      }
      if (meta.data?.error) {
        return meta.data.error;
      }
    }
  });

  const submittingRef = useLatest(submitting);
  const inputRef = useLatest(input);
  const loadingRef = useLatest(loading);
  useEffect(() => {
    if (inputRef.current.value instanceof File && meta.valid && !loadingRef.current) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        let encoded = fileToBase64(reader);
        try {
          const { data: { uploadFile } } = await sendFile({
            variables: {
              name: inputRef.current.value.name,
              content: encoded,
              type: inputRef.current.value.type || Mime.getType(inputRef.current.value.name)
            }
          });
          inputRef.current.onChange({ ...uploadFile, size: inputRef.current.value.size });
        } catch (e) {
          console.error(e);
          form.mutators.setFieldData(props.name, { error: e.message || "File not uploaded" });
        }
      };
      reader.readAsDataURL(inputRef.current.value);
    }
    // return () => {
    //   if (inputRef.current.value.id && !submittingRef.current) {
    //     removeFile({ variables: { id: inputRef.current.value.id } }).catch(console.error);
    //   }
    // };
  }, []);

  const handleDelete = () => {
    props.onDelete();
    if (inputRef.current.value.id && !submittingRef.current && props.shouldRemove(inputRef.current.value)) {
      removeFile({ variables: { id: inputRef.current.value.id } }).catch(console.error);
    }
  };
  const extension = useMemo(() => {
    const split = input.value.name.split(".");
    return split[ split.length - 1 ];
  }, [input.value.name]);

  return (
    <FileUploadCard
      fileName={input.value.name}
      fileSize={formatBytes(input.value.size)}
      extension={extension}
      isUploading={loading}
      errorMsg={meta.error}
      onDelete={removeAble && handleDelete}/>
  );
});
