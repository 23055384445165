import React                 from "react";
import { FC }                from "react";
import { CheckboxProps }     from "../../Input/Checkbox";
import { BaseFieldProps }    from "../BaseField";
import { BaseField }         from "../BaseField";
import ToggleSwitchEditField from "./ToggleSwitchEditField";
import ToggleSwitchReadField from "./ToggleSwitchReadField";

export type ToggleSwitchFieldProps = BaseFieldProps & Partial<CheckboxProps> & {
  True?: React.ReactNode,
  False?: React.ReactNode,
};

export const ToggleSwitchField: FC<ToggleSwitchFieldProps> = React.memo(function SwitchField(props) {
  return (
    <BaseField {...props} readView={<ToggleSwitchReadField/>} editView={<ToggleSwitchEditField/>} type={"checkbox"}/>
  );
});
