import React                   from "react";
import { useRef }              from "react";
import { useMemo }             from "react";
import { useImperativeHandle } from "react";
import { useCallback }         from "react";
import { ReactNode }           from "react";
import { MultiSelectClasses }  from "../../..";
import { BoxComponentProps }   from "../../..";
import { useBoxProps }         from "../../..";
import { IconType }            from "../../..";
import { classNames }          from "../../..";
import { SearchableMenu }      from "../../..";
import { InputState }          from "../../..";
import { BaseInputClasses }    from "../../..";
import { Box }                 from "../../..";
import { applyBoxItemStyles }  from "../../..";
import { BaseInputProps }      from "../../..";
import { useImperativeState }  from "../../..";
import { Alignment }           from "../../../constants/Alignemnt";
import { Popper }              from "../../Popper";
import { MultiSelectToggle }   from "../MultiSelect/MultiSelectToggle/MultiSelectToggle";

export interface SearchableMultiSelectProps extends BaseInputProps, BoxComponentProps {
  value: any[],
  message?: string,
  placeholder?: string,
  readOnly?: boolean,
  searchText?: string
  loading?: boolean,
  keepErrorSpace?: boolean,
  options: any[],
  className?: string,
  optionKey?: string,
  optionLabel?: string,
  emptyMessage?: string,
  alignment?: Alignment,
  searchIcon?: IconType,
  onChange?: (options) => void,
  renderSelectedItem?: (option) => ReactNode,
  renderSelect?: (option) => ReactNode,
  renderOption?: (option) => ReactNode,
  renderHeader?: (option) => ReactNode | string;
  getSelected?: (option) => string;//not used
  getOptionLabel?: (option) => string;//not used
  getOptionValue?: (option) => string | any;//not used
  getOptionSelected?: (option, value) => boolean;//not used
  getOptionDisabled?: (option) => boolean;//not used
  InputProps?: { [ key: string ]: any }
  showSelectedCount?: boolean
  showSelectionActions?: boolean
  searchPlaceholder?: string
  showCreateButton?: boolean
  onType?(value: string, e)
  selectedFilter?: boolean
  onSelectedFilter?(filtered: boolean)
  onLoadMoreHandler?: React.ReactElement
  onCreateNew?(),
}

const defaultProps = {
  optionKey: "label",
  optionLabel: "value",
  searchText: "",
  alignment: Alignment.BottomLeft,
  disabled: false,
  readOnly: false,
  loading: false,
  searchPlaceholder: "Search value",
  showSelectionActions: true,
  showSelectedCount: true,
  selected: [],
  options: []
};

export const SearchableMultiSelect = React.forwardRef(function SearchableMultiSelect(props: SearchableMultiSelectProps, ref) {
  let properties = applyBoxItemStyles<SearchableMultiSelectProps>(props);
  const {
    label,
    className,
    required,
    disabled,
    message,
    readOnly,
    state,
    placeholder,
    options,
    value,
    optionKey,
    optionLabel,
    onCreateNew,
    loading,
    onType,
    halfSize,
    fullSize,
    searchText,
    renderOption,
    renderSelectedItem,
    showSelectedCount,
    showSelectionActions,
    showCreateButton,
    renderSelect,
    renderHeader,
    searchPlaceholder,
    alignment,
    onChange,
    searchIcon,
    InputProps,
    keepErrorSpace,
    emptyMessage,
    selectedFilter,
    onLoadMoreHandler,
    onSelectedFilter,
    ...p
  } = properties;
  const [search, setSearch] = useImperativeState(searchText, onType);
  const [anchorBounding, setAnchorBounding] = useImperativeState<DOMRect>(null);
  const toggleRef = useRef(null);
  const [selectedItems, setSelectedItems] = useImperativeState(value || [], onChange);
  const disabledMod = useMemo(() => disabled || readOnly, [disabled, readOnly]);
  const classes = classNames(className, {
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error,
    [ MultiSelectClasses.Active ]: !!anchorBounding
  }, BaseInputClasses.Input, MultiSelectClasses.MultiSelect);
  function isSelected(item) {
    if (typeof item === "object") {
      return selectedItems.find((select) => {
        if (typeof select == "string") {
          return select === item[ optionKey ];
        }
        return select[ optionKey ] === item[ optionKey ];
      });
    }
    return selectedItems.includes(item);
  }

  function filter(item) {
    if (typeof item === "object") {
      return selectedItems.filter((s) => {
        return typeof s == "object" ? s[ optionKey ] !== item[ optionKey ] :
          typeof item == "object" ? s !== item[ optionKey ] : s !== item;
      });
    }
    return selectedItems.filter(s => s !== item);
  }

  function remove(item) {
    if (typeof item === "object") {
      return selectedItems.filter(select => select[ optionKey ] !== item[ optionKey ]);
    }
    return selectedItems.filter(s => {
      return typeof s == "object" ? s[ optionKey ] !== item : s !== item;
    });
  }

  const selectItem = (item, event?) => {
    if (isSelected(item)) {
      let filtered = filter(item);
      setSelectedItems(filtered);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleClose = useCallback(() => {
    setAnchorBounding(null);
  }, [anchorBounding]);

  useImperativeHandle(ref, () => ({
    handleClose
  }));

  const deleteItem = (item) => {
    setSelectedItems(remove(item));
  };

  const togglePopper = event => {
    setAnchorBounding(!anchorBounding ? event.currentTarget.getBoundingClientRect() : null);
  };

  return (
    <Box container
         className={classes}
         gap={"XXXS"}
         direction={"column"}
         {...useBoxProps(p)}>
      <MultiSelectToggle
        ref={toggleRef}
        renderHeader={renderHeader}
        renderSelect={renderSelect}
        options={options}
        items={selectedItems}
        message={message}
        togglePopper={togglePopper}
        placeholder={placeholder}
        handleDeleteItem={deleteItem}
        opened={!!!anchorBounding}
        showSelectedCount={showSelectedCount}
        optionLabel={optionLabel}
        optionKey={optionKey}
        disabled={disabledMod}
        label={label}
        required={required}
        readOnly={readOnly}
        keepErrorSpace={keepErrorSpace}
      />
      <Popper alignment={alignment} open={!!anchorBounding} onClickAway={handleClose}
              anchorBounding={anchorBounding}>
        <SearchableMenu
          width={(toggleRef?.current?.clientWidth) as number}
          selectedFilter={selectedFilter}
          onSelectedFilter={onSelectedFilter}
          onSelectAll={showSelectionActions ? () => setSelectedItems(options) : null}//todo multiclass case doesn't covered
          onUnSelectAll={showSelectionActions ? () => setSelectedItems([]) : null}
          placeholder={searchPlaceholder}
          searchIcon={searchIcon}
          renderOption={renderOption}
          emptyMessage={emptyMessage}
          options={options}
          selected={selectedItems}
          showCreateButton={showCreateButton}
          onSelect={selectItem}
          searchText={search}
          optionKey={optionKey}
          optionLabel={optionLabel}
          onCreateNew={onCreateNew}
          loading={loading}
          onLoadMoreHandler={onLoadMoreHandler}
          onType={setSearch}
        />
      </Popper>
    </Box>
  );
});

SearchableMultiSelect.defaultProps = defaultProps;

