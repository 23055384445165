import React                        from "react";
import { FC }                       from "react";
import { AlertColors }              from "@relcu/ui";
import { FieldSpy }                 from "@relcu/ui";
import { Alert, BoxComponentProps } from "@relcu/ui";
import { Typography }               from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { TextField }                from "@relcu/ui";
import { MailDomainDialogClasses }  from "../../MailDomainDialog";

export interface DomainNameFormProps extends BoxComponentProps {
  domainDuplicate?: string;
  setDomainDuplicate?: any;
}

export const DomainNameForm: FC<DomainNameFormProps> = React.memo(function DomainNameForm(props) {
  const { domainDuplicate, setDomainDuplicate, ...p } = props;
  return (
    <Box
      container
      gap={"XS"}
      direction="column"
      flex={1}
      className={MailDomainDialogClasses.MailDomainDialogBody} {...p}>
      <Typography>
        Enter the domain you want to associate with your company email(s).
      </Typography>
      <Box container>
        <TextField
          required
          label={"Domain name"}
          name={"domain"}
          flexBasis={"50%"}
          placeholder={"Enter domain name"}
        />
      </Box>
      {
        domainDuplicate &&
        <>
          <FieldSpy name={"domain"} subscription={{value: true}} onChange={() => setDomainDuplicate(null)}/>
          <Alert variant={AlertColors.Error} stretch>
            {domainDuplicate}
            {/*This domain already exists. Edit the existing domain or add a different one.*/}
          </Alert>
        </>
      }
    </Box>
  );
});