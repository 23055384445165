import { gql }                from "@apollo/client";
import { FORM_ERROR }         from "@relcu/form";
import { FormSpy }            from "@relcu/form";
import { Form }               from "@relcu/form";
import { Box }                from "@relcu/ui";
import { Button }             from "@relcu/ui";
import { ModalProps }         from "@relcu/ui";
import { Modal }              from "@relcu/ui";
import { TextField }          from "@relcu/ui";
import { SwitchField }        from "@relcu/ui";
import { Alert }              from "@relcu/ui";
import { ItemsEllipsis }      from "@relcu/ui";
import { Chips }              from "@relcu/ui";
import { Label }              from "@relcu/ui";
import { FontIcon }           from "@relcu/ui";
import { ChipsVariants }      from "@relcu/ui";
import { ChipsColors }        from "@relcu/ui";
import { ModalFooter }        from "@relcu/ui";
import { ModalBody }          from "@relcu/ui";
import { BoxComponentProps }  from "@relcu/ui";
import { RadioField }         from "@relcu/ui";
import { CommonClasses }      from "@relcu/ui";
import React                  from "react";
import { FC }                 from "react";
import { AppraisalTypeMap }   from "../../Layout/View/AppraisalListView";
import { FileUploadButton }   from "../../Layout/View/LeadImportView";
import { useAppraisalImport } from "./useAppraisalImport";

export interface AppraisalImportProps extends Partial<ModalProps> {
  refetch: () => void,
  action?: string
  data?: any
}

export const AppraisalImportModal: FC<AppraisalImportProps> = React.memo(function AppraisalImportModal(props) {
  const { onClose, data, action } = props;
  const { initial, onSubmit, appraisalFeeTypes } = useAppraisalImport(props);
  return <Modal
    onClose={onClose}
    open={true}
    closeIcon={true}
    variant="middle"
    disableBackdropClick={false}
    title={"Import appraisal fees"}
  >
    <Box container direction={"column"} style={{ overflow: "hidden" }}>
      <Box container direction={"column"} style={{ overflow: "hidden" }}>
        <Form onSubmit={onSubmit} subscription={{ submitting: true }} initialValues={initial}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} style={{ display: "contents" }}>
              <ModalBody container direction={"column"} gap={"XS"} flex={1}
                         style={{ padding: "16px", overflow: "hidden" }}>
                <FormSpy subscription={{ submitErrors: true }}>
                  {
                    ({ submitErrors }) => {
                      return !!submitErrors?.[ FORM_ERROR ] ?
                        <Alert variant={submitErrors[ FORM_ERROR ].color} width={"100%"}>
                          <span>{submitErrors[ FORM_ERROR ].message}</span>
                        </Alert> : null;
                    }
                  }
                </FormSpy>
                <Box container direction={"column"} flex={1} gap={"M"} style={{ overflow: "auto" }}>
                  <Box container flex={1} gap={"XXXL"}>
                    <TextField required={true} flexBasis="30%" label="Name" name="name"
                               defaultValue={data?.node.objectName} flex={1}/>
                    <SwitchField label={"Enable for users to see"} name={`enabled`} defaultChecked={data?.node.enabled}
                                 flex={1}/>
                  </Box>
                  {
                    action !== "edit"
                      ? <>
                        {appraisalFeeTypes.map((t, i) => {
                          return <UploadField showUpload={true} key={i}
                                              label={`Import CSV for ${AppraisalTypeMap[ t ]} loans`} name={t}/>;
                        })}
                      </>
                      : <>
                        {data.node.uploadedFiles.map((o, i) => {
                          return <UploadField showUpload={false} key={i}
                                              label={`Imported CSV for ${AppraisalTypeMap[ o.type ]} loans`}
                                              fileName={o.fileName}
                                              name={o.type}/>;
                        })}
                        {appraisalFeeTypes.map((t, i) => {
                          return <UploadField showUpload={true} key={i}
                                              label={`Import CSV for ${AppraisalTypeMap[ t ]} loans`} name={t}/>;
                        })}
                      </>
                  }
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" disabled={submitting}
                        alignSelf={"end"}>{action === "edit" ? "SAVE" : "UPLOAD"}</Button>
              </ModalFooter>
            </form>
          )}
        </Form>
      </Box>
    </Box>
  </Modal>;
});

interface UploadFieldProps extends BoxComponentProps {
  label: string,
  showUpload: boolean,
  name: string
  fileName?: string
}

export const UploadField: FC<UploadFieldProps> = React.memo(function UploadField(props) {
  const { showUpload, name, label, fileName } = props;
  return <Box container flex={1} alignItems={"center"} gap={"XXXL"}>
    {showUpload
      ?
      <FormSpy subscription={{ values: true }}>
        {
          ({ values: { defaultType } }) => {
            return <FileUploadButton
              validate={(value) => {
                return value?.size > 1048576 && "File is too big!";
              }}
              flex={"0 0 36%"}
              label={label}
              name={name}
              required={defaultType === name}
            />;
          }
        }
      </FormSpy>
      : <Box container direction={"column"} gap={"XXXS"} style={{ maxWidth: "36%" }} flex={"0 0 36%"}>
        <Label>
          {label}
        </Label>
        <p className={CommonClasses.FlexEllipsis}>{fileName}</p>
      </Box>
    }
    <RadioField name={"defaultType"} label="Default" value={name}/>
    <Box container flex={"1 1 auto"} gap={"XXXL"}>
      <Box container flex={1} gap={"XXXXS"} wrap={"wrap"}>
        <FormSpy subscription={{ submitErrors: true }}>
          {({ submitErrors }) => submitErrors?.[ name ] ? <ItemsEllipsis
            showLength={2}
            items={submitErrors[ name ]}
            renderItem={(item, index) =>
              <Chips key={index}
                     color={ChipsColors.Error}
                     label={item}
                     disabled={true}/>}
          /> : null}
        </FormSpy>
      </Box>
    </Box>
  </Box>;
});
export default AppraisalImportModal;

export const UPDATE_APPRAISAL = gql`
  mutation UpdateAppraisal($id:ID!,$fields: UpdateAppraisalFieldsInput!){
    updateAppraisal(input:{id:$id,fields:$fields}){
      appraisal{
        id
        enabled
      }
    }
  }
`;
