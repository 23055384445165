import { FC }               from "react";
import React                from "react";
import { Stack }            from "@relcu/rc";
import { Icon }             from "@relcu/rc";
import { IconButton }       from "@relcu/rc";
import { VerticalDivider }  from "@relcu/ui";
import { HeaderCall }       from "../HeaderCall";
import { useContactHeader } from "./useContactHeader";
import "./contact-header.css";

export const ContactHeader: FC = React.memo(function LeadHeader() {
  const api = useContactHeader();
  return <Stack
    childrenRenderMode={"clone"}
    alignItems={"center"}
    justifyContent={"flex-end"}
    spacing={8}
    className={"contact-header"}>
    <HeaderCall/>
    <VerticalDivider/>
    <IconButton
      onClick={api.navigateToSms}
      size={"md"}
      icon={<Icon type={"forum"}/>}
    />
  </Stack>;
});
