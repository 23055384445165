import { useFormState }     from "@relcu/form";
import React                from "react";
import { EmptyList }        from "../../../../..";
import { LinkClasses }      from "../../../../..";
import { classNames }       from "../../../../..";
import { Box }              from "../../../../Box";
import { ButtonColors }     from "../../../../Button";
import { ButtonVariants }   from "../../../../Button";
import { Button }           from "../../../../Button";
import { JsonSection }      from "../../JsonSection";
import { JsonSectionProps } from "../../JsonSection";
import { useStreetSection } from "./useStreetSection";

export const StreetSection = React.memo<StreetSectionProps>((props) => {
  const { ref, ...rest } = props;
  const { zillowLink } = useStreetSection(props);
  const { pristine, submitting } = useFormState({ subscription: { pristine: true, submitting: true } });
  return (
    <JsonSection {...rest} editable={false} view={props.editable ? "write" : "read"} component={null}>
      <Box container gap={"XXS"} direction={"row"}
           style={{ position: "absolute", top: "16px", right: "16px", color: "#" }} alignContent={"center"}
           alignItems={"center"}>
        {!pristine && <Button icon={"save"} onlyIcon={true} variant={ButtonVariants.Ghost} color={ButtonColors.White}
                              disabled={submitting} type={"submit"}/>}
        <a href={zillowLink}
           className={classNames(LinkClasses.Link, LinkClasses.Primary)} target={"_blank"}
           style={{ lineHeight: "34px" }}>Go to
          Zillow page</a>
      </Box>
      <Box container gap={"XXS"} direction={"column"} id={"street-view"} style={{ height: "600px" }} flexBasis={"100%"}
           justify={"center"}>
        <EmptyList icon={"location_on"} content={"The street view for the given address not found."}
                   title={"Street view not found"}/>
      </Box>
    </JsonSection>
  );
});

export interface StreetSectionProps extends JsonSectionProps {
  ref
}
