import { setIn }           from "@relcu/final-form";
import { schemaVar }       from "../reactiveVars";
import { IField }          from "../types/ISchemas";
import { ISchema }         from "../types/ISchemas";
import { toFirstLower }    from "./helpers";
import { flatten }         from "./helpers";
import { pluralize }       from "./pluralize";
import { getSchema }       from "./schemaUtils";
import { getField }        from "./schemaUtils";
import { isRelationField } from "./schemaUtils";
import { isStatusField }   from "./schemaUtils";
import { isPointerField }  from "./schemaUtils";
import { isArrayField }    from "./schemaUtils";
import { isObjectField }   from "./schemaUtils";

export interface JqlOptions {
  className: string;
  fields: string[];
  getField(className: string, fieldName: string): IField;
  getClass(className: string): ISchema;
}

export class Jql {
  #valid: boolean;
  #get: JqlGet;
  #find: JqlFind;
  #create: JqlCreate;
  #update: JqlUpdate;
  #delete: JqlDelete;
  #subscription: JqlSubscription;
  constructor(options: JqlOptions) {
    this.#valid = !!options.getClass(options.className);
    this.#get = new JqlGet(options);
    this.#find = new JqlFind(options);
    this.#create = new JqlCreate(options);
    this.#update = new JqlUpdate(options);
    this.#delete = new JqlDelete(options);
    this.#subscription = new JqlSubscription(options);
  }
  build(type: JqlType) {
    if (!this.#valid) {
      return null;
    }
    switch (type) {
      case "get":
        return this.#get.build();
      case "find":
        return this.#find.build();
      case "create":
        return this.#create.build();
      case "update":
        return this.#update.build();
      case "delete":
        return this.#delete.build();
      case "subscription":
        return this.#subscription.build();
    }
  }
}
export type JqlType = "get" | "find" | "create" | "update" | "delete" | "subscription"
abstract class JqlBase {
  #options: JqlOptions;
  constructor(options: JqlOptions) {
    this.#options = options;
  }
  static treeToSet(state): string[] {
    return Object.keys(flatten(state));
  }
  static setToTree(fields: string[]) {
    fields.sort((nameA, nameB) => {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    let state = {};
    fields.forEach(field => {
      state = setIn(state, field, true);
    });
    return state;
  }
  get className() {
    return this.#options.className;
  }
  getClass(className: string) {
    return this.#options.getClass(className);
  }
  getSelectionSet(className: string = this.#options.className, fields: string[] = this.#options.fields) {
    return this.applyDefaultSelectionSet(this.#getNestedSelectionSet(className, fields));
  }
  #getNestedSelectionSet(className: string = this.#options.className, fields: string[] = this.#options.fields) {
    const state = JqlBase.setToTree(fields);
    return this.#getSelectionSet(className, state);
  }
  #getSelectionSet(className, obj, root?) {
    const getObjectSelectionSet = (key, schemaField, extra = {}) => {
      if ((isObjectField(schemaField) || isArrayField(schemaField)) && !Array.isArray(schemaField.targetClass)) {
        const schema = this.getClass(schemaField.targetClass);
        const extraFields = JqlBase.treeToSet(extra);
        if (schema) {
          const fields = Object.keys(schema.fields);
          return {
            [ key ]: this.#getNestedSelectionSet(schemaField.targetClass, fields.concat(...extraFields))
          };
        }
      }
    };
    return Object.keys(obj).map(key => {
      if (obj[ key ] === true) {
        const fieldName = root ? [root, key].join(".") : key;
        const schemaField = this.#options.getField(className, fieldName);
        const objectSelectionSet = getObjectSelectionSet(key, schemaField);
        if (objectSelectionSet) {
          return objectSelectionSet;
        }
        if (isObjectField(schemaField)) {
          return key;
        }
        if (isPointerField(schemaField)) {
          if (Array.isArray(schemaField.targetClass)) {
            return {
              [ key ]: schemaField.targetClass.map(className => ({
                [ `... on ${className}` ]: this.applyDefaultSelectionSet()
              }))
            };
          }
          return {
            [ key ]: this.applyDefaultSelectionSet()
          };
        }
        if (isStatusField(schemaField)) {
          return {
            [ key ]: ["status", "action","actionCount", "updatedAt", "currentStatusActionCount", "currentStageActionCount"]
          };
        }
        if (isRelationField(schemaField)) {
          const schema = this.getClass(schemaField.targetClass);
          if (schema) {
            let fields = Object.keys(schema.fields);
            if (schemaField.targetClass === className) {
              fields = fields.filter(fieldName => fieldName !== key);
            }
            fields = fields.filter(fieldName => fieldName !== schemaField.targetField).concat("id");
            return {
              [ key ]: [{
                edges: [{
                  node: this.#getNestedSelectionSet(schemaField.targetClass, fields)
                }]
              }]
            };
          }
        }
        if (key === "ACL") {
          return {
            [ key ]: [
              {
                users: ["read", "write", "userId"]
              },
              {
                roles: ["read", "write", "roleName"]
              },
              {
                teams: ["read", "write", "teamName"]
              },
              {
                public: ["read", "write"]
              }
            ]
          };
        }
        return key;
      } else {
        const fieldName = root ? [root, key].join(".") : key;
        const schemaField = this.#options.getField(className, fieldName);
        if (isPointerField(schemaField)) {
          return {
            [ key ]: this.applyDefaultSelectionSet(this.#getSelectionSet(className, obj[ key ], root))
          };
        }
        const objectSelectionSet = getObjectSelectionSet(key, schemaField, obj[ key ]);
        if (objectSelectionSet) {
          return objectSelectionSet;
        }
        if (isObjectField(schemaField)) {
          return key;
        }
        if (isStatusField(schemaField)) {
          return {
            [ key ]: ["status", "action", "actionCount", "updatedAt", "currentStatusActionCount", "currentStageActionCount"]
          };
        }
        return {
          [ key ]: this.#getSelectionSet(className, obj[ key ], root)
        };
      }
    });
  }
  protected applyDefaultSelectionSet(fieldNames = []) {
    const selectionSet = new Set(fieldNames.concat([
      "id",
      "objectId",
      "objectName",
      "objectIcon",
      {
        "ACL": [
          {
            users: ["read", "write", "userId"]
          },
          {
            roles: ["read", "write", "roleName"]
          },
          {
            teams: ["read", "write", "teamName"]
          },
          {
            public: ["read", "write"]
          }
        ]
      }
    ]));
    return Array.from(selectionSet);
  }
  abstract build()
}
class JqlGet extends JqlBase {
  build() {
    return {
      operation: toFirstLower(this.className),
      variables: {
        id: {
          name: "id",
          type: "ID!"
        }
      },
      fields: this.getSelectionSet()
    };
  }
}
class JqlFind extends JqlBase {
  build() {
    const node = this.getSelectionSet();
    return {
      operation: pluralize(toFirstLower(this.className)),
      variables: {
        where: {
          name: "where",
          type: `${this.className}WhereInput`
        },
        order: {
          name: "order",
          type: `[${this.className}Order!]`
        },
        skip: {
          name: "skip",
          type: "Int"
        },
        search: {
          name: "search",
          type: "String"
        },
        after: {
          name: "after",
          type: "String"
        },
        first: {
          name: "first",
          type: "Int"
        },
        last: {
          name: "last",
          type: "Int"
        },
        before: {
          name: "before",
          type: "String"
        }
      },
      fields: [
        //"count",
        {
          pageInfo: ["hasNextPage", "hasPreviousPage", "startCursor", "endCursor"]
        },
        {
          edges: [
            "cursor",
            {
              node
            }
          ]
        }
      ]
    };
  }
}
class JqlCreate extends JqlBase {
  build() {
    return {
      operation: `create${this.className}`,
      variables: {
        input: {
          name: "input",
          type: `Create${this.className}Input!`
        }
      },
      fields: [
        {
          [ toFirstLower(this.className) ]: this.getSelectionSet()
        }
      ]
    };
  }
}
class JqlUpdate extends JqlBase {
  build() {
    return {
      operation: `update${this.className}`,
      variables: {
        input: {
          name: "input",
          type: `Update${this.className}Input!`
        }
      },
      fields: [
        {
          [ toFirstLower(this.className) ]: this.getSelectionSet()
        }
      ]
    };
  }
}
class JqlDelete extends JqlBase {
  build() {
    return {
      operation: `delete${this.className}`,
      variables: {
        input: {
          name: "input",
          type: `Delete${this.className}Input!`
        }
      },
      fields: [
        {
          [ toFirstLower(this.className) ]: ["id"]
        }
      ]
    };
  }
}
class JqlSubscription extends JqlBase {
  build() {
    return {
      operation: pluralize(toFirstLower(this.className)),
      variables: {
        where: {
          name: "where",
          type: `${this.className}SubscriptionWhereInput`
        },
        events: {
          name: "events",
          type: "[SubscriptionEvent]"
        }
      },
      fields: [
        "event",
        {
          node: this.getSelectionSet()
        }
      ]
    };
  }

}

export function getJql(fields, actions, rootFields, className) {
  const jqlFields = rootFields.map(f => {
    return fields.find(field => field.name == f.key);
  }).filter(f => !!f);

  if (actions) {
    const actionsWithFieldRequests = actions.filter(action => !!action.field);
    jqlFields.push(...actionsWithFieldRequests);
  }

  return getListJql(className, jqlFields);
}

export function getListJql(className, fields) {
  const jqlFields = getJqlFields(className, fields);
  return new Jql({
    className: className,
    fields: Array.from(jqlFields),
    getClass: getSchema,
    getField: getField
  });
}

function getJqlFields(className, fields) {
  const jqlFields = new Set<string>();
  fields.filter(f => f?.type !== "Password").forEach(f => {
    if (Array.isArray(f.fields)) {
      for (let fn of f.fields) {
        jqlFields.add(fn);
      }
    } else if (Array.isArray(f.field)) {
      for (let fn of f.field) {
        jqlFields.add(fn);
      }
    } else if (typeof f.fields == "string") {
      jqlFields.add(f.fields);
    } else if (typeof f.field == "string") {
      jqlFields.add(f.field);
    } else {
      jqlFields.add(f.name);
    }
  });

  return jqlFields;
}
