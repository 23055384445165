import React                 from "react";
import { FC }                from "react";
import { useState }          from "react";
import { useCallback }       from "react";
import { useLayoutEffect }   from "react";
import { useRef }            from "react";
import { BoxComponentProps } from "@relcu/ui";
import { CommonClasses }     from "@relcu/ui";
import { classNames }        from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { FontIcon }          from "@relcu/ui";
import "./slider.css";

export const Slider: FC<BoxComponentProps> = React.memo(function Slider(props) {
  const containerRef = useRef<any>(null);
  const [outFormRight, setOutFormRight] = useState([]);
  const [outFormLeft, setOutFormLeft] = useState([]);

  useLayoutEffect(() => {
    const listener = (entries) => {
      entries.forEach(
        ({ isIntersecting, boundingClientRect, target, rootBounds }) => {
          if (!isIntersecting) {
            if ((boundingClientRect.right > rootBounds.right) && !outFormRight.find(out => out == target)) {
              setOutFormRight([...outFormRight, target]);
            }
            if ((boundingClientRect.left < rootBounds.left) && !outFormLeft.find(out => out == target)) {
              setOutFormLeft([...outFormLeft, target]);
            }
          } else {
            if (outFormRight.find(out => out == target)) {
              setOutFormRight(outFormRight.filter(out => out != target));
            }

            if (outFormLeft.find(out => out == target)) {
              setOutFormLeft(outFormLeft.filter(out => out != target));
            }
          }
        }
      );
    };
    const observer = new IntersectionObserver(listener, {
      root: containerRef.current,
      rootMargin: "0px",
      threshold: 1
    });
    const cards = document.querySelectorAll(".rc-slider__item");
    for (let i = 0; i < cards.length; i++) {
      observer.observe(cards[ i ]);
    }
    return () => {
      const cards = document.querySelectorAll(".rc-slider__item");
      for (let i = 0; i < cards.length; i++) {
        observer.unobserve(cards[ i ]);
      }
      observer.disconnect();
    };
  }, [outFormRight, outFormLeft]);

  const handleMoveTo = useCallback((side: "left" | "right") => {
    if (side == "left") {
      containerRef.current.scrollTo({ left: containerRef.current.scrollLeft - 60, behavior: "smooth" });
    } else {
      containerRef.current.scrollTo({ left: containerRef.current.scrollLeft + 60, behavior: "smooth" });
    }
  }, []);

  return <Box className={SliderClasses.Slider} flexShrink={0}>
    {
      !!outFormLeft?.length &&
      <FontIcon
        onClick={() => handleMoveTo("left")}
        type={"keyboard_arrow_left"}
        className={classNames(SliderClasses.Arrow, SliderClasses.ArrowLeft, CommonClasses.ClickableIcon)}
      />
    }
    <Box container direction={"row"} gap={"XXS"} ref={containerRef} className={SliderClasses.List} alignItems={"end"} {...props}>
      {props.children}
    </Box>
    {
      !!outFormRight?.length &&
      <FontIcon
        onClick={() => handleMoveTo("right")}
        type={"keyboard_arrow_right"}
        className={classNames(SliderClasses.Arrow, SliderClasses.ArrowRight, CommonClasses.ClickableIcon)}
      />
    }
  </Box>;
});

export const enum SliderClasses {
  Slider = "rc-slider",
  List = "rc-slider__list",
  Item = "rc-slider__item",
  Arrow = "rc-slider__arrow",
  ArrowRight = "rc-slider__arrow--right",
  ArrowLeft = "rc-slider__arrow--left"
}
