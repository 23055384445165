import { useMemo }                  from "react";
import React                        from "react";
import { FC }                       from "react";
import { useCallback }              from "react";
import { useState }                 from "react";
import { SyntheticEvent }           from "react";
import { useContext }               from "react";
import { useNavigate }              from "@relcu/react-router";
import { Field, useForm }           from "@relcu/form";
import { FieldArray }               from "@relcu/form";
import { arrayMutators }            from "@relcu/form";
import { Form }                     from "@relcu/form";
import { FormSpy }                  from "@relcu/form";
import { Tooltip }                  from "@relcu/ui";
import { Ellipsis }                 from "@relcu/ui";
import { InlineEditableEditView }   from "@relcu/ui";
import { useImperativeState }       from "@relcu/ui";
import { SelectVariants }           from "@relcu/ui";
import { Select }                   from "@relcu/ui";
import { ButtonGroup }              from "@relcu/ui";
import { Label }                    from "@relcu/ui";
import { ButtonVariants }           from "@relcu/ui";
import { InlineEditableInputProps } from "@relcu/ui";
import { Popper }                   from "@relcu/ui";
import { MenuItem }                 from "@relcu/ui";
import { Menu }                     from "@relcu/ui";
import { Alignment }                from "@relcu/ui";
import { Accordion }                from "@relcu/ui";
import { FontIcon }                 from "@relcu/ui";
import { Button }                   from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { classNames as clsNames }   from "@relcu/ui";
import { CommonClasses }            from "@relcu/ui";
import { guidGenerator }            from "@relcu/ui";
import { base64urlEncode }          from "@relcu/ui";
import { transformNameToLabel }     from "@relcu/ui";
import { ChoiceField }              from "@relcu/ui";
import { HiddenField }              from "@relcu/ui";
import { Filter_rules_Element }     from "./__types__/Filter";
import { FilterClasses }            from "./FilterClasses";
import { FilterContext }            from "./FilterProvider";
import { FilterProvider }           from "./FilterProvider";
import { FiltersContext }           from "./FiltersProvider";
import { FiltersProvider }          from "./FiltersProvider";
import { FilterField }              from "./useFilter";
import { UseFilter }                from "./useFilter";
import "./filter.css";

export const Filter: FC<FilterProps> = React.memo(function Filter(props) {
  const context = props.value;
  return (
    <FilterProvider {...props}>
      <Form
        initialValues={context.initialValues}
        keepDirtyOnReinitialize={true}
        onSubmit={async (values, form, callback) => ({ values, form, callback })}
        decorators={context.decorators}
        mutators={{
          ...arrayMutators
        }}
      >
        {({ handleSubmit, form }) => {
          return <form
            onSubmit={handleSubmit}
            noValidate
            style={{ display: "contents" }}>
            <FiltersProvider>
              <Accordion className={FilterClasses.Filters} header={(open) => <FilterHeader open={open}/>}>
                <FilterBody/>
                {props.children}
              </Accordion>
            </FiltersProvider>
          </form>;
        }}
      </Form>
    </FilterProvider>
  );
});

export const FilterBody: FC = React.memo(function FilterBody() {
  return (
    <Box container direction={"row"} gap={"XS"} className={FilterClasses.FiltersBody}>
      <FilterSidebar/>
      <FilterArray/>
    </Box>
  );
});
export const FilterHeader: FC<FilterHeaderProps> = React.memo(function FilterHeader({ open, onClick }) {
  const { edges, appliedFilter, count } = useContext(FiltersContext);
  const context = useContext(FilterContext);
  const navigate = useNavigate();
  return (
    <Box container gap={"XXS"} justify={"space-between"} alignItems={"center"}
         className={clsNames(FilterClasses.FiltersHeader, { [ FilterClasses.FiltersOpen ]: open })}
         onClick={onClick}>
      <Box container gap={"XXS"} alignItems={"center"}>
        <FontIcon
          className={clsNames(CommonClasses.GrayIcon, { [ CommonClasses.PrimaryIcon ]: context.filtersCount > 0 })}
          type="filter_alt"
          alignSelf={"start"}/>
        <p>Filter ({context.filtersCount}):</p>
        <Select
          height={43}
          disabled={!count}
          placeholder={"Select filter"}
          optionKey={"objectId"}
          optionLabel={"name"}
          variant={SelectVariants.Ghost}
          mode={"button"}
          value={appliedFilter}
          options={edges.map(({ node }) => node)}
          onChange={(node) => {
            navigate(`?filter=ID:${node.objectId}`);
          }}
          onClick={(e) => e.stopPropagation()}
          style={{ maxWidth: 200 }}
        />
      </Box>
      {!open && <HiddenField name={"rules"}/>}
      {!open && <HiddenField name={"filterId"}/>}
      <FontIcon className={CommonClasses.ClickableIcon}
                type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                alignSelf={"end"}/>
    </Box>
  );
});
export const FilterSidebar = React.memo(function FilterSidebar() {
  const { current, edges, onUpdate, onRemove, selected, onFill, onNew } = useContext(FiltersContext);
  return (
    <Box container direction={"column"} flexBasis={250} gap={"XS"} className={FilterClasses.FilterSideBar}>
      <Label style={{ marginLeft: 16 }}>
        Saved filters
      </Label>
      <Box container gap={"XXS"} direction={"column"}>
        <Box
          container
          alignItems={"center"}
          onClick={onNew}
          className={clsNames(FilterClasses.FilterSideBarButton, {
            [ FilterClasses.FilterSideBarButtonSelected ]: selected == "new"
          })} justify={"space-between"}>
          <span>New filter</span>
          <FontIcon type={"add"}/>
        </Box>
        {edges.map(({ node }) => (
          <InlineFilterInput
            key={node.id}
            value={node.name}
            selected={node.objectId === selected}
            onSelect={() => onFill(node)}
            onRemove={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(node.id, node.objectId);
            }}
            onConfirm={(e, name) => onUpdate({ ...node, name })}
          />
        ))}
        {
          !!current &&
          <InlineFilterInput flex={"0 0 0%"} {...current}/>
        }
      </Box>
    </Box>
  );
});
export const FilterArray = React.memo(function FilterArray() {
  const context = useContext(FilterContext);
  return <>
    <FieldArray name={"rules"}>
      {({ fields }) => {
        return (<Box container direction={"column"} flexGrow={1} gap={"XXS"}>
          {
            fields.map((name, index) => (
              <Box key={fields.value[ index ]?.name || name} container direction={"row"} gap={"XS"}
                   className={FilterClasses.FiltersItem}
                   justify={"space-between"} alignItems={"center"}>
                <Box container direction={"row"} gap={"XS"} justify={"start"} flexGrow={1}>
                  <ChoiceField name={`${name}.field`} options={context.fields} required label="Field"
                               placeholder={"Select Field"} flexBasis={"30%"}/>
                  <Field name={`${name}.field`} subscription={{ value: true }}>
                    {({ input: { value: field } }) => (
                      <>
                        {!!field &&
                        <>
                          <ChoiceField name={`${name}.operator`}
                                       options={context.getOperators(field)}
                                       required
                                       label="Operation" placeholder={"Select operation"} flexBasis={"30%"}
                                       optionKey={"value"}
                                       optionLabel={"label"}/>
                          <Field name={`${name}.operator`} subscription={{ value: true }}>
                            {({ input: { value: operator } }) => {
                              const QueryOperator = context.getComponent(field, operator);
                              return (
                                <FilterFieldContext.Provider value={context.getField(field)}>
                                  {!!QueryOperator && <QueryOperator name={`${name}.value`}/>}
                                </FilterFieldContext.Provider>
                              );
                            }}
                          </Field>
                        </>
                        }
                      </>
                    )}
                  </Field>
                </Box>
                <FontIcon
                  type={"remove"}
                  onClick={() => {
                    fields.remove(index);
                  }}
                />
              </Box>
            ))
          }
          <FilterFooter onAdd={(field) => {
            fields.push({
              field: field.value,
              name: guidGenerator()
            });
          }}/>
        </Box>);
      }}
    </FieldArray>
    <HiddenField name={"filterId"}/>
  </>;
});
export const FilterFooter: FC<FilterFooterProps> = React.memo(function FilterFooter({ onAdd }) {
  const context = useContext(FilterContext);
  const { selected, edges, onAdd: onFilterAdd } = useContext(FiltersContext);
  const [menuBounding, setMenuBounding] = useState(null);
  const navigate = useNavigate();
  const form = useForm();
  const relativeHeight = useMemo(() => context.fields.length > 4 ? `${40 * 4}px` : null, [context.fields]);
  function onMenuToggle(e: SyntheticEvent) {
    menuBounding == null ? setMenuBounding(e.currentTarget.getBoundingClientRect()) : setMenuBounding(null);
  }
  async function handleSubmit(type) {
    let submitted = await form.submit();
    if (submitted) {
      const { rules, filterId } = submitted.values;
      let nR = base64urlEncode(JSON.stringify(rules));
      if (type == "apply") {
        if (filterId) {
          const filter = edges.find(({ node }) => node.objectId == filterId);
          const existingRules = (filter?.node?.rules || []).map((element: Filter_rules_Element) => element.value);
          let r = base64urlEncode(JSON.stringify(existingRules));
          if (r == nR) {
            navigate(`?filter=ID:${filterId}`);
            return;
          }
        }
        navigate(`?filter=R:${nR}`);
      } else if (type == "save") {
        const filter = edges.find(({ node }) => node.objectId == selected);
        await context.onFilterUpdate(filter.node.id, form);
      } else if (type == "save_as") {
        onFilterAdd();
      }
    }
  }
  return (
    <Box container direction={"column"} gap={"XS"} flex={1} className={FilterClasses.FiltersApply}
         justify={"end"} alignItems={"center"}>
      <Button
        onClick={onMenuToggle}
        icon={"add"} variant={ButtonVariants.Ghost} alignSelf={"baseline"}> Add filter</Button>
      <Popper
        open={!!menuBounding}
        anchorBounding={menuBounding}
        onClickAway={onMenuToggle}
        alignment={Alignment.BottomLeft}
        threshold={6}>
        <Menu
          type={"select"}
          height={44}
          role="listbox">
          {
            context.fields.map((field) => {
              return <MenuItem key={field.value} onClick={(e) => {
                onMenuToggle(e);
                onAdd(field);
              }}>
                {transformNameToLabel(field.label)}
              </MenuItem>;
            })
          }
        </Menu>
      </Popper>
      <Box flex={1}/>
      <Box container gap={"XXS"} justify={"end"} style={{ width: "100%" }}>
        <FormSpy subscription={{ pristine: true, submitting: true }}>
          {({ submitting, pristine }) => {
            return <>
              {
                selected == "new"
                  ?
                  <Button onClick={() => {
                    handleSubmit("save_as");
                  }}>SAVE AS</Button>
                  :
                  <ButtonGroup collapse>
                    <Button onClick={() => {
                      handleSubmit("save");
                    }} disabled={submitting}>SAVE</Button>
                    <Button onClick={() => {
                      handleSubmit("save_as");
                    }} disabled={submitting}>SAVE AS</Button>
                  </ButtonGroup>
              }
              <Button alignSelf={"end"} disabled={submitting} onClick={async () => {
                await handleSubmit("apply");
              }}>APPLY FILTERS</Button>
            </>;
          }}
        </FormSpy>
      </Box>
    </Box>
  );
});
export const InlineFilterInput = React.memo<InlineFilterInputProps>(function InlineFilterInput(props) {
  const { onSelect, selected, onRemove, onConfirm, isEditing, onEdit, onCancel, ...p } = props;
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(false);
  const [editing, setEditing] = useImperativeState(isEditing, onEdit);
  const handleChange = useCallback((value) => setValue(value), []);
  const cancel = useCallback(() => setValue(props.value), [props.value]);
  const classNames = clsNames(FilterClasses.FilterSideBarItem, {
    [ FilterClasses.FilterSideBarItemSelected ]: selected,
    [ FilterClasses.FilterSideBarItemEdit ]: editing
  });

  function handleCancel() {
    onCancel?.() ?? cancel();
    setEditing(false);
  }

  function handleConfirm(e) {
    if (value && value.trim()) {
      onConfirm(e, value);
      setEditing(false);
      setError(false);
    } else {
      setError(true);
      setValue("");
    }
  }

  const handleKeyDown = function (e) {
    if (e.key === "Enter") {
      handleConfirm(e);
    }
    if (e.key === "Escape") {
      handleCancel();
    }
  };

  return (
    <Box container className={classNames} alignItems={"center"} onMouseDown={onSelect}>
      <Box flex={1}>
        {
          editing ?
            <InlineEditableEditView onKeyDown={handleKeyDown}
                                    onChange={(e) => p.onChange?.(e.target.value, e) ?? handleChange(e.target.value)}
                                    value={value}
                                    error={error}
                                    placeholder={error ? "Name is not valid" : ""}/>
            :
            <Tooltip title={value}>
              <Ellipsis from={18}>{value}</Ellipsis>
            </Tooltip>
        }
      </Box>
      <Box container gap={"XXS"} className={FilterClasses.FilterSideBarItemActions}>
        {
          editing ?
            <>
              <FontIcon type="save" key={"save"} onMouseDown={handleConfirm} className={CommonClasses.ClickableIcon}/>
              <FontIcon type="clear" onMouseDown={onCancel ?? handleCancel} className={CommonClasses.ClickableIcon}/>
            </>
            :
            <>
              <FontIcon
                type="create"
                key={"create"}
                onMouseDown={() => {
                  setEditing(true);
                }}
                className={CommonClasses.ClickableIcon}
              />
              <FontIcon type={"delete"} onMouseDown={onRemove} className={CommonClasses.ClickableIcon}/>
            </>
        }
      </Box>
    </Box>
  );
});
export const FilterFieldContext = React.createContext<FilterField>(null);
export interface FilterHeaderProps {
  open: boolean;
  onClick?: (event: SyntheticEvent) => void
}
export interface FilterFooterProps {
  onAdd: (field: { label: string, value: any }) => void
}
export interface FilterProps {
  value: UseFilter
}
export interface InlineFilterInputProps extends Omit<InlineEditableInputProps, "onCancel"> {
  onSelect?(e: React.SyntheticEvent)
  onRemove?(e: React.SyntheticEvent)
  onConfirm?(event: React.SyntheticEvent, value)
  onCancel?(event?)
  onChange?(value, event)
  onEdit?(event)
  selected?: boolean
}
export default Filter;
