import { useReactiveVar }      from "@apollo/client";
import React                   from "react";
import { FC }                  from "react";
import { useCallback }         from "react";
import { Advice }              from "@relcu/rc";
import { Icon, IconButton }    from "@relcu/rc";
import { useNavigate }         from "@relcu/react-router";
import { Box }                 from "@relcu/ui";
import { audioDevicesVar }     from "../../../../reactiveVars";
import { microphoneAccessVar } from "../../../../reactiveVars";
import { getObjectPath }       from "../../../../utils/layoutUtils";
import { usePhone }            from "../../../usePhone";

export const TwilioCallActionsCollapsed: FC = React.memo(function TwilioCallActionsCollapsed(props) {
  const { active } = usePhone();
  const navigate = useNavigate();

  const accept = useCallback(() => {
    active.accept();
    if (active.params.scopeId) {
      navigate(`${getObjectPath({ __typename: active.params.scopeClassName, objectId: active.params.scopeId })}/details`);
    } else {
      navigate(`/contact/${active.params.contactId}/calls`);
    }
  }, [active]);
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const devices = useReactiveVar(audioDevicesVar);
  return <Box container direction={"column"} alignItems={"center"} justify={"center"} style={{ width: "100%" }}>
    <Box container
         justify={"center"} gap={"XS"}
         alignItems={"center"}>
      {active.status === "open" &&
        <IconButton icon={<Icon type={"call_end"}/>} size={"sm"} color={"red"}
                    onClick={() => active.drop()}/>
      }
      {active.status !== "open" && active.direction === "incoming" &&
        <Box container direction={"column"} gap={"XXS"}>
          <Advice text={active.params.contactName} placement={"auto"}>
            <IconButton icon={<Icon type={"call"}/>} size={"sm"} color={"green"}
                        disabled={!microphoneAccess || !devices.audiooutput.length || !devices.audioinput.length}
                        onClick={accept}/>
          </Advice>
          <IconButton icon={<Icon type={"call_end"}/>} size={"sm"} color={"red"}
                      onClick={() => active.reject()}/>
        </Box>
      }
      {
        active.status !== "open" && active.direction === "outgoing" &&
        <IconButton icon={<Icon type={"call_end"}/>} size={"sm"} color={"red"}
                    onClick={() => active.drop()}/>
      }
    </Box>
  </Box>;
});
