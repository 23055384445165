import { useApolloClient }        from "@apollo/client";
import { DocumentNode }           from "@apollo/client";
import { useQuery }               from "@apollo/client";
import { OperationVariables }     from "@apollo/client/core";
import { SubscribeToMoreOptions } from "@apollo/client/core/watchQueryOptions";
import { QueryHookOptions }       from "@apollo/client/react/types/types";
import { TypedDocumentNode }      from "@graphql-typed-document-node/core";
import { Icon }                   from "@relcu/rc";
import { MentionButtonProps }     from "@relcu/rc";
import { MentionButton }          from "@relcu/rc";
import { useMatch }               from "@relcu/react-router";
import { useNavigate }            from "@relcu/react-router";
import { useIsMounted }           from "@relcu/ui";
import { useImperativeState }     from "@relcu/ui";
import { useMounted }             from "@relcu/ui";
import { useConstant }            from "@relcu/ui";
import { useLatest }              from "@relcu/ui";
import { SetStateAction }         from "react";
import { Dispatch }               from "react";
import { PropsWithChildren }      from "react";
import { FC }                     from "react";
import { useContext }             from "react";
import { useEffect }              from "react";
import { useState }               from "react";
import { useLayoutEffect }        from "react";
import { useMemo }                from "react";
import { MutableRefObject }       from "react";
import React                      from "react";
import { SubscriptionEvent }      from "../../types/graphql-global-types";
import { toFirstUpper }           from "../../utils/helpers";
import { toFirstLower }           from "../../utils/helpers";
import { pluralize }              from "../../utils/pluralize";
import { useJqlSubscription }     from "../Layout/Jql";
import { useJqlLazyQuery }        from "../Layout/Jql";

export interface RelayQueryProps<TData, TVariables> {
  className: string;
  query: QueryHookOptions<TData, TVariables> & { document: DocumentNode | TypedDocumentNode };
  subscription?: SubscribeToMoreOptions;
  render: (renderProps: RelayQueryRenderProps<TData>) => React.ReactElement;
  rowHeight?: number;
  limit?: number;
  from?: string | boolean | undefined;
  reverse?: boolean;
  scrollId?: string;
  setScrollIntoId?: Dispatch<SetStateAction<string>>;
}
export type RelayQueryRenderProps<TData> = {
  beforeLoaderRef: ReturnType<typeof React.useRef<HTMLDivElement>>
  afterLoaderRef: ReturnType<typeof React.useRef<HTMLDivElement>>
  scrollContainerRef: ReturnType<typeof React.useRef<HTMLDivElement>>
  register: (HTMLDivElement, string) => void
  loading: boolean;
  data: TData
}
export type RelayContextProps<TData> = {
  operation: string
  elements: Map<string, HTMLDivElement>;
  scrollContainerRef: ReturnType<typeof React.useRef<HTMLDivElement>>
  loading: boolean;
  className: string;
  query: QueryHookOptions<TData> & { document: DocumentNode | TypedDocumentNode };
  subscription?: SubscribeToMoreOptions;
  data: TData
}
export function RelayQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(props: RelayQueryProps<TData, TVariables>) {
  const { query, subscription, className, render, rowHeight, limit = 6, from, reverse = false, scrollId: sid = null, setScrollIntoId } = props;
  const scrollContainerRef = React.useRef<HTMLDivElement>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [scrollId, setScrollId] = useImperativeState<string>(sid, setScrollIntoId);
  const elements = useConstant(() => new Map<string, HTMLDivElement>());
  const client = useApolloClient();
  const [variables, setVariables] = useState<{ first?: number, last?: number, from?: string | boolean | undefined }>();
  const skip = !variables?.[ reverse ? "last" : "first" ];
  const isMounted = useIsMounted();
  const previousData = useMemo(() => {
    return Object(client.readQuery<TData>({
      query: query.document,
      variables: {
        ...variables,
        ...query.variables
      }
    }));
  }, [query.variables, variables, query.document]);
  const { data = {} as TData, subscribeToMore, loading, fetchMore } = useQuery<TData>(query.document, {
    ...query,
    skip,
    variables: {
      ...variables,
      ...query.variables
    }
  });
  const isLoading = loading || isLoadingMore;
  const operation = useMemo(() => pluralize(toFirstLower(className)), [className]);
  const { pageInfo, edges = [] } = Object(data[ operation ]);
  const edgesRef = useLatest(edges);
  const pageInfoRef = useLatest(pageInfo);
  const loadMore: typeof fetchMore = async (options) => {
    try {
      setIsLoadingMore(true);
      return await fetchMore(options);
    } finally {
      setIsLoadingMore(false);
    }
  };
  const onLoadBefore = () => {
    const { from, ...variables } = query.variables;
    return loadMore({
      query: query.document,
      variables: {
        ...variables,
        last: limit,
        before: pageInfo.startCursor
      }
    }).then(() => {
      const edge = edges.find(e => e.cursor == reverse ? pageInfo.endCursor : pageInfo.startCursor);
      setTimeout(() => setScrollId(edge.node.objectId));
    });
  };
  const onLoadAfter = () => {
    const { from, ...variables } = query.variables;
    return loadMore({
      query: query.document,
      variables: {
        ...variables,
        first: limit,
        after: pageInfo.endCursor
      }
    });
  };
  const register = (el, objectId) => {
    elements.set(objectId, el);
  };
  const beforeLoaderRef = useInfiniteScroll({
    hasMore: pageInfo?.hasPreviousPage,
    scrollContainerRef,
    loading: isLoading || skip,
    onLoadMore: onLoadBefore
  });
  const afterLoaderRef = useInfiniteScroll({
    hasMore: pageInfo?.hasNextPage,
    scrollContainerRef,
    loading: isLoading || skip,
    onLoadMore: onLoadAfter
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      let size = (rowHeight && scrollContainerRef.current) ? Math.floor(scrollContainerRef.current.offsetHeight / rowHeight) : limit;
      size = (size || limit) * 2;
      if (from) {
        setVariables({ [ reverse ? "last" : "first" ]: size, from: from || undefined });
      } else {
        setVariables({ [ reverse ? "last" : "first" ]: size });
      }
    });
  }, [from]);

  useLayoutEffect(() => {
    if (from && !loading) {
      setScrollId(from as string);
    }
  }, [loading]);

  useLayoutEffect(() => {
    if (!from && reverse && !loading && pageInfo?.endCursor) {
      const edge = edges.find(e => e.cursor == pageInfo.endCursor);
      if (edge) {
        setScrollId(edge.node.objectId);
      }
    }
  }, [loading, pageInfo?.endCursor]);

  useEffect(() => {
    const exists = from && edgesRef.current?.find(e => e.node.objectId === from);
    if (!exists && from) {
      setScrollId(from as string);
      setVariables(variables => ({ ...variables, from }));
    }
  }, [from]);

  useMounted(() => {
    const scrollId = from as string;
    const element = elements.get(String(from));
    const listener = entries => {
      entries.forEach(
        ({ isIntersecting, boundingClientRect, target }) => {
          if (!isIntersecting) {
            setScrollId(scrollId);
          }
          observer.unobserve(element);
        }
      );
    };

    const observer = new IntersectionObserver(listener, {
      root: scrollContainerRef.current,
      rootMargin: "0px"
    });
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
      observer.disconnect();
    };

  }, [from]);

  useLayoutEffect(() => {
    if (scrollId && !loading) {
      elements.get(scrollId)?.scrollIntoView({
        behavior: "auto",
        block: "center"
      });
    }
  }, [scrollId, loading]);

  useEffect(() => {
    if (subscription) {
      return subscribeToMore({
        document: subscription.document,
        variables: subscription.variables,
        updateQuery(root, { subscriptionData: { data } }) {
          const { node, event } = data[ operation ];
          switch (event) {
            case SubscriptionEvent.CREATE:
            case SubscriptionEvent.ENTER:
              const shouldAdd = !reverse ? !pageInfoRef.current?.hasPreviousPage : !pageInfoRef.current?.hasNextPage;
              if (shouldAdd) {
                setTimeout(() => setScrollId(node.objectId));
                return {
                  ...root,
                  [ operation ]: {
                    ...root[ operation ],
                    edges: !reverse ? [
                      {
                        __typename: `${className}Edge`,
                        cursor: null,
                        node
                      },
                      ...root[ operation ].edges
                    ] : [
                      ...root[ operation ].edges,
                      {
                        __typename: `${className}Edge`,
                        cursor: null,
                        node
                      }
                    ]
                  }
                };
              }
              return root;
            case SubscriptionEvent.LEAVE:
            case SubscriptionEvent.DELETE:
              return {
                ...root,
                [ operation ]: {
                  ...root[ operation ],
                  edges: root[ operation ].edges.filter(e => e.node.id !== node.id)
                }
              };
          }
        }
      });
    }
  }, [subscribeToMore]);
  if (!data[ operation ]) {
    data[ operation ] = {};
  }
  if (!previousData[ operation ]) {
    previousData[ operation ] = {};
  }

  const renderProps = {
    data: (isMounted || edges.length) ? data : previousData,
    beforeLoaderRef,
    afterLoaderRef,
    scrollContainerRef,
    loading: isLoading || skip,
    register
  };
  const value: RelayContextProps<TData> = {
    elements,
    data,
    loading,
    className,
    scrollContainerRef,
    operation,
    query,
    subscription
  };

  return <RelayContext.Provider value={value}>{render(renderProps)}</RelayContext.Provider>;
}

export function useInfiniteScroll({
  hasMore,
  onLoadMore,
  loading,
  scrollContainerRef,
  distance = 250
}): MutableRefObject<HTMLDivElement> {
  const loaderRef = React.useRef<HTMLDivElement>();
  const loadMore = useLatest(onLoadMore);
  React.useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    const scrollContainerNode = scrollContainerRef.current;
    if (!scrollContainerNode || !loaderNode || !hasMore) {
      return;
    }

    const options = {
      root: scrollContainerNode,
      // threshold:1,
      rootMargin: `0px 0px ${distance}px 0px`
    };

    const listener = entries => {
      entries.forEach(
        ({ isIntersecting }) => {
          if (isIntersecting && !loading) {
            loadMore.current();
          }
        }
      );
    };

    const observer = new IntersectionObserver(listener, options);
    observer.observe(loaderNode);
    return () => observer.disconnect();
  }, [hasMore, loading]);

  return loaderRef;
}

export const RelayContext = React.createContext<RelayContextProps<any>>(null);
export type RelayMentionProviderProps<TDate, TVariables> = PropsWithChildren<{
  variables: TVariables
  subscriptionVariables?: OperationVariables
  predicate: (data: TDate) => string[]
  onIntersecting?: (id: string, element) => void
}>
export const RelayMentionContext = React.createContext<{
  topElements: Set<HTMLDivElement>,
  bottomElements: Set<HTMLDivElement>,
  variables: OperationVariables
  subscriptionVariables: OperationVariables
}>(null);
export function RelayMentionProvider<TData, TVariables extends OperationVariables = OperationVariables>(props: RelayMentionProviderProps<TData, TVariables>) {
  const { scrollContainerRef, elements, data } = useContext(RelayContext);
  const predicate = useLatest(props.predicate);
  const result = useMemo(() => predicate.current(data) || [], [data]);
  const [top, setTop] = useState(() => new Set<HTMLDivElement>());
  const [bottom, setBottom] = useState(() => new Set<HTMLDivElement>());
  useLayoutEffect(() => {
    const nodes = elements.size ? result.map(r => elements.get(r)) : [];
    let topMentions = new Set<HTMLDivElement>();
    let bottomMentions = new Set<HTMLDivElement>();
    const listener = entries => {
      entries.forEach(
        ({ isIntersecting, boundingClientRect, target }) => {
          const currentRef = scrollContainerRef.current.getBoundingClientRect();
          if (!isIntersecting) {
            if ((boundingClientRect.top - currentRef.top) > 0) {
              topMentions.delete(target);
              bottomMentions.add(target);
            } else {
              topMentions.add(target);
              bottomMentions.delete(target);
            }
          } else {
            if (bottomMentions.has(target)) {
              bottomMentions.delete(target);
            } else {
              topMentions.delete(target);
            }
            if (props.onIntersecting) {
              const id = result.find(r => elements.get(r) === target);
              props.onIntersecting(id, target);
            }
          }
          setTop(new Set(topMentions));
          setBottom(new Set(bottomMentions));
        }
      );
    };

    const observer = new IntersectionObserver(listener, {
      root: scrollContainerRef.current,
      rootMargin: "0px"
    });

    if (!nodes.length) {
      if (top.size) {
        setTop(new Set());
      }
      if (bottom.size) {
        setBottom(new Set());
      }
    }
    for (let i = 0; i < nodes.length; i++) {
      observer.observe(nodes[ i ]);
    }
    return () => {
      for (let i = 0; i < nodes.length; i++) {
        observer.unobserve(nodes[ i ]);
      }
      observer.disconnect();
    };
  }, [elements, result]);
  const value = {
    topElements: top,
    bottomElements: bottom,
    variables: props.variables,
    subscriptionVariables: props.subscriptionVariables
  };
  return <RelayMentionContext.Provider value={value}>{props.children}</RelayMentionContext.Provider>;
}
export const RelayMention: FC<MentionButtonProps> = React.memo(function RelayTopMention(props) {
  const { direction, top, bottom, style, ...rest } = props;
  const navigate = useNavigate();
  const match = useMatch("/:typename/:objectId/:tab/*");
  const { topElements, bottomElements, variables, subscriptionVariables } = useContext(RelayMentionContext);
  const { data, operation, className, query, subscription } = useContext(RelayContext);
  const { pageInfo, edges = [] } = Object(data[ operation ]);
  const all = useMemo(() => new Set(edges.map(e => e.node.objectId)), [edges]);
  const [load, { data: mentionData, refetch, called }] = useJqlLazyQuery({
    operation,
    variables: {
      where: {
        name: "where",
        type: `${className}WhereInput`
      },
      order: {
        name: "order",
        type: `[${className}Order!]`
      },
      skip: {
        name: "skip",
        type: "Int"
      },
      search: {
        name: "search",
        type: "String"
      },
      after: {
        name: "after",
        type: "String"
      },
      first: {
        name: "first",
        type: "Int"
      },
      last: {
        name: "last",
        type: "Int"
      },
      before: {
        name: "before",
        type: "String"
      }
    },
    fields: [
      {
        pageInfo: ["hasNextPage", "hasPreviousPage", "startCursor", "endCursor"]
      },
      {
        edges: [
          "cursor",
          {
            node: [
              "objectId"
            ]
          }
        ]
      }
    ]
  }, { operationName: `Get${className}${toFirstUpper(direction)}Mentions`, fetchPolicy: "no-cache" });
  const { edges: mentionEdges = [] } = Object(mentionData?.[ operation ]);
  const dataRef = useLatest(mentionData?.[ operation ]);
  const variablesRef = useLatest(direction == "top" ? {
    ...variables,
    where: {
      ...query.variables.where,
      ...variables.where
    },
    last: 10,
    before: pageInfo?.startCursor
  } : {
    ...variables,
    where: {
      ...query.variables.where,
      ...variables.where
    },
    first: 10,
    after: pageInfo?.endCursor
  });
  useEffect(() => {
    if (direction == "top") {
      if (pageInfo?.startCursor && pageInfo?.hasPreviousPage) {
        if (!dataRef.current || (dataRef.current.pageInfo.hasPreviousPage && dataRef.current.edges.every(e => all.has(e.node.objectId)))) {
          load({
            variables: variablesRef.current,
            fetchPolicy: "no-cache"
          });
        }
      }
    } else {
      if (pageInfo?.endCursor && pageInfo?.hasNextPage) {
        if (!dataRef.current || (dataRef.current.pageInfo.hasNextPage && dataRef.current.edges.every(e => all.has(e.node.objectId)))) {
          load({
            variables: variablesRef.current,
            fetchPolicy: "no-cache"
          });
        }
      }
    }

  }, [pageInfo?.startCursor, pageInfo?.endCursor, all, direction]);
  useJqlSubscription({
    operation,
    variables: {
      where: {
        name: "where",
        type: `${className}SubscriptionWhereInput`
      },
      events: {
        name: "events",
        type: `[SubscriptionEvent]`
      }
    },
    fields: ["event"]
  }, {
    operationName: `${className}${toFirstUpper(direction)}MentionsSubscription`,
    skip: !called,
    variables: {
      where: {
        ...subscription.variables.where,
        ...variables.where
      },
      ...subscriptionVariables,
      events: [SubscriptionEvent.CREATE, SubscriptionEvent.LEAVE, SubscriptionEvent.ENTER]
    },
    onData() {
      refetch();
    }
  });

  const handleMention = () => {
    if (direction === "top") {
      if (topElements.size) {
        const el = Array.from(topElements).pop();
        el.scrollIntoView({
          behavior: "auto",
          block: "center"
        });
      } else if (mentionEdges.length) {
        navigate(`${match.pathname}/${mentionEdges[ mentionEdges.length - 1 ].node.objectId}`);
      }
    } else {
      if (bottomElements.size) {
        Array.from(bottomElements)[ 0 ].scrollIntoView({
          behavior: "auto",
          block: "center"
        });
      } else if (mentionEdges.length) {
        navigate(`/${match.pathname}/${mentionEdges[ 0 ].node.objectId}`);
      }
    }
  };

  const hasMention = useMemo(() => {
    const items = direction == "top" ? topElements : bottomElements;
    return (items.size || mentionEdges.some(e => !all.has(e.node.objectId)));
  }, [topElements, mentionEdges, bottomElements, all, direction]);

  return (hasMention &&
    <MentionButton
      style={{
        ...style,
        "--top-mention-margin": top && `${top}px`,
        "--bottom-mention-margin": bottom && `${bottom}px`
      } as any}
      width={200}
      {...rest}
      endIcon={<Icon type={direction == "top" ? "north" : "south"} style={{ fontSize: 12 }}/>}
      onClick={handleMention}
      direction={direction}
    />
  );
});


