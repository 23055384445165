import React                 from "react";
import { Avatar }            from "../../Avatar";
import { useBoxProps }       from "../../Box";
import { Box }               from "../../Box";
import { Label }             from "../../Label";
import { LinkColors }        from "../../Link";
import { Link }              from "../../Link";
import { useConsumeField }   from "../BaseField";
import { BaseFieldClasses }  from "../BaseField";
import { PointerFieldValue } from "./PointerField";
import { PointerFieldProps } from "./PointerField";
import { useNavigate }       from "@relcu/react-router";

export const PointerReadField = React.memo(function PointerReadField(props) {
  const { input: { readPlaceholder, label, value, thumbnail, ...inputProps } } = useConsumeField<PointerFieldProps, PointerFieldValue>();
  const navigate = useNavigate();
  const link = `/${value.__typename?.toLowerCase()}/${value.objectId}`;
  return (
    <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField}
         alignSelf={"start"} {...useBoxProps(inputProps)}>
      <Label>{label}</Label>
      {
        value ? <Box container gap={"XXS"} alignItems={"center"}>
          {thumbnail &&
          <Avatar
            icon={value.objectIcon}
            text={value.objectName}
            onClick={() => navigate(link)}
          />
          }
          <Link
            color={LinkColors.Primary}
            to={link}>
            {value.objectName}
          </Link>
        </Box> : <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
      }
    </Box>
  );
});

export default PointerReadField;
