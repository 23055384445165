import React               from "react";
import { useMemo }         from "react";
import { FC }              from "react";
import { DateTime }        from "luxon";
import { Alignment }       from "../../../../constants/Alignemnt";
import { classNames }      from "../../../../utils/classNames";
import { timeTo }          from "../../../../utils/helpers";
import { isOverdue }       from "../../../../utils/helpers";
import { Box }             from "../../../Box";
import { FontIcon }        from "../../../Icon";
import { IconType }        from "../../../Icon";
import { Tooltip }         from "../../../Tooltip";
import { TypographyLine }  from "../../../Typography";
import { TypographyColor } from "../../../Typography";
import { TypographySize }  from "../../../Typography";
import { Typography }      from "../../../Typography";
import { CellClasses }     from "../CellClasses";
import { ICellProps }      from "../ICellProps";

interface DateCellProps extends ICellProps {
  format?: "onlyTime" | "withTime" | "onlyDate" | "relative" | string,
  withRelativeDate?: boolean,
  isExpirationDate?: boolean,
  expirationColor?: "error" | "warning" | string,
  icon: IconType
}
export const DateCell: FC<DateCellProps> = React.memo(function TextCell({ rowData, dataKey, className, getCellValue, format, icon, defaultValue, withRelativeDate, isExpirationDate, expirationColor, ...props }) {
  const value = getCellValue(rowData, dataKey);
  const date = value ?? new Date().toISOString();
  const overdueColor = {
    "error"   : TypographyColor.Error,
    "warning" : TypographyColor.YellowDark
  }[expirationColor]
  const title = useMemo(() => format == "relative" ? DateTime.fromISO(date).toFormat(dateFormats.withTime) : "",
    [format, date]);
  return <Box container className={classNames(CellClasses.DateCell,className)} flexShrink={0} gap={"XXS"}>
      {
        icon &&
        <FontIcon type={icon} alignSelf={"center"}/>
      }
      {
        value ?
          <Box container direction={"column"}>
            <Tooltip title={title} alignment={Alignment.Top}>
              <Typography color={(isOverdue(date) && isExpirationDate ) && overdueColor}
                          lineHeight={withRelativeDate ? TypographyLine.Line17 : null}>
                <time
                  dateTime={date}>
                  {format == "relative" ? timeTo(DateTime.fromISO(date)) : DateTime.fromISO(date).toFormat(getFormat(format))}
                </time>
              </Typography>
            </Tooltip>
            {
              withRelativeDate &&
              <Typography color={(isOverdue(date) && isExpirationDate ) ? overdueColor : TypographyColor.Secondary}
                          size={TypographySize.TextSmall}>
                <time
                  dateTime={date}>
                  {timeTo(DateTime.fromISO(date))}
                </time>
              </Typography>
            }
          </Box>
          :
          <small>
            {defaultValue ?? ''}
          </small>
      }
    </Box>
});
const dateFormats = {
  onlyTime: "h:mm a",
  withTime: "MMM dd, h:mm a",
  onlyDate: "MMM dd, yyyy"
};

const getFormat = (format) => {
  if (!format) {
    return dateFormats.onlyDate;
  } else {
    return dateFormats[ format ] || format;
  }
};
