import { getIn }                         from "@relcu/form";
import { Alignment }                     from "@relcu/ui";
import { EmptyList }                     from "@relcu/ui";
import { ListViewClasses }               from "@relcu/ui";
import { CircularLoader }                from "@relcu/ui";
import { Box }                           from "@relcu/ui";
import { Checkbox }                      from "@relcu/ui";
import { SearchInput }                   from "@relcu/ui";
import { Button }                        from "@relcu/ui";
import { ButtonVariants }                from "@relcu/ui";
import { Tooltip }                       from "@relcu/ui";
import { List }                          from "@relcu/ui";
import { useMemo }                       from "react";
import { FC }                            from "react";
import React                             from "react";
import { getHumanized }                  from "../../../utils/schemaUtils";
import { DistributionStatsModalClasses } from "./DistributionStatsModalClasses";
import { useDistributionStatsLeads }     from "./useDistributionStatsLeads";

interface DistributionStatsLeadsProps {
  queue: string,
  strategy: string
  onClose
}
export const DistributionStatsLeads: FC<DistributionStatsLeadsProps> = React.memo(function DistributionStatsModal(props) {
  const { queue, onClose, strategy } = props;
  const api = useDistributionStatsLeads(queue);
  const isRoundRobin = useMemo(() => {
    return strategy === "round_robin";
  }, [strategy]);

  const getTableData = useMemo(() => {
    if (!isRoundRobin) {
      return {
        fields: [
          {
            name: "objectIcon",
            component: "ListItemImage",
            label: "objectIcon",
            alt: "objectName"
          },
          {
            name: "objectName",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 0,
            flexShrink: 1
          },
          {
            name: "assignedUserIcon",
            component: "ListItemImage",
            label: "objectIcon",
            alt: "assignedUserName"
          },
          {
            name: "assignedUserName",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 0,
            flexShrink: 1
          },
          {
            name: "loanAmount",
            component: "ListItemCurrency",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "leadStatus",
            component: "ListItemStatus",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "localTime",
            component: "ListItemLocalTime",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "propertyState",
            component: "ListItemChoice",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "matchedUsers",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "updatedAt",
            component: "ListItemDate",
            flexBasis: "100px",
            flexGrow: 1,
            flexShrink: 1,
            properties:{
              format:"withTime"
            }
          },
          {
            name: "createdAt",
            component: "ListItemDate",
            flexBasis: "100px",
            flexGrow: 1,
            flexShrink: 1,
            properties:{
              format:"withTime"
            }
          }
        ],
        headers: [
          {
            "title": "Full name",
            "flexBasis": "232px",
            "flexGrow": 0,
            "flexShrink": 1
          },
          {
            "title": "Assigned user",
            "flexBasis": "232px",
            "flexGrow": 0,
            "flexShrink": 1
          },
          {
            "title": "Loan amount",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Lead status",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Local time",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Property state",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Matched users",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Updated at",
            "flexBasis": "100px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Created at",
            "flexBasis": "100px",
            "flexGrow": 1,
            "flexShrink": 1
          }
        ]
      }
    } else {
      return {
        fields: [
          {
            name: "objectIcon",
            component: "ListItemImage",
            label: "objectIcon",
            alt: "objectName"
          },
          {
            name: "objectName",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 0,
            flexShrink: 1
          },
          {
            name: "assignedUserIcon",
            component: "ListItemImage",
            label: "objectIcon",
            alt: "assignedUserName"
          },
          {
            name: "assignedUserName",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 0,
            flexShrink: 1
          },
          {
            name: "loanAmount",
            component: "ListItemCurrency",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "leadStatus",
            component: "ListItemStatus",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "localTime",
            component: "ListItemLocalTime",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "propertyState",
            component: "ListItemChoice",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "updatedAt",
            component: "ListItemDate",
            flexBasis: "100px",
            flexGrow: 1,
            flexShrink: 1,
            properties:{
              format:"withTime"
            }
          },
          {
            name: "createdAt",
            component: "ListItemDate",
            flexBasis: "100px",
            flexGrow: 1,
            flexShrink: 1,
            properties:{
              format:"withTime"
            }
          }

        ],
        headers: [
          {
            "title": "Full name",
            "flexBasis": "232px",
            "flexGrow": 0,
            "flexShrink": 1
          },
          {
            "title": "Assigned user",
            "flexBasis": "232px",
            "flexGrow": 0,
            "flexShrink": 1
          },
          {
            "title": "Loan amount",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Lead status",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Local time",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Property state",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Updated at",
            "flexBasis": "100px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Created at",
            "flexBasis": "100px",
            "flexGrow": 1,
            "flexShrink": 1
          }
        ]
      }
    }
  }, [isRoundRobin]);

  const emptyState = useMemo(()=>{
    if(!isRoundRobin){
      return {
        title:  "No stats found",
        content: "Distribution queue is empty, please check configuration."
      }
    }else {
      return {
        title: "No stuck leads found",
        content: "All leads are matched with users and distributed"
      }
    }
  },[isRoundRobin])

  return <Box container direction={"column"} gap={"S"} flexGrow={1}>
    <Box container direction={"column"} gap={"XS"} flex={"1 1 0%"} style={{ overflow: "hidden" }}>
      <Box container direction={"row"} gap={"XXS"} alignItems={"center"}>
        <SearchInput
          clearable
          placeholder={"Search"}
          style={{ padding: 0 }}
          autoFocus={true}
          value={api.value}
          onChange={api.setValue}
        />
        {!isRoundRobin && <Checkbox
          alignment={"right"}
          direction={"row"}
          justify={"center"}
          alignItems={"center"}
          checked={api.hasNoUsers}
          onChange={() => api.setHasNoUsers(!api.hasNoUsers)}
          placeholder={"Has no Users"}/>}
        <Box flex={1}/>
        <Tooltip title={"Refresh to update data"} alignment={Alignment.TopLeft}>
          <Button
            icon={"refresh"}
            variant={ButtonVariants.Ghost}
            alignSelf={"center"}
            onClick={() => api.onFetch()}/>
        </Tooltip>
      </Box>
      {
        api.data?.length == 0 ?
          <Box container flex={"1 1 auto"} justify={"center"} alignItems={"center"}
               className={ListViewClasses.ListEmptyView}>
            <EmptyList icon={"trending_up"} content={emptyState.content}
                       title={emptyState.title}
                       alignSelf={"center"}/>
          </Box>
          :
          <Box gap={"XXS"} direction={"column"} container style={{ overflow: "hidden" }}>
            {
              api.loading ?
                <Box container flex={1} alignItems={"center"} justify={"center"}
                     className={DistributionStatsModalClasses.Loader}>
                  <CircularLoader/>
                </Box>
                :

                api.data?.length == 0 ?
                  <Box container flex={"1 1 auto"} justify={"center"}
                       className={ListViewClasses.ListEmptyView}>
                    <EmptyList icon={"trending_up"}
                               content={"Distribution queue is empty, please check configuration."}
                               title={"No stats found"}
                               alignSelf={"center"}/>
                  </Box>
                  :

                  <List
                    onRowClick={(item) => {
                      onClose();
                      api.onRowClick(item);
                    }}
                    getColumnValue={(row, field) =>
                      getHumanized("Lead", field.name, getIn(row, field.name))
                    }
                    fields={getTableData.fields}
                    headers={getTableData.headers}
                    onRowRender={(object) => {
                      if (object.matchedUsers == 0 && !isRoundRobin) {
                        return {
                          style: {
                            backgroundColor: "#FFEAEE"
                          }
                        };
                      }
                      return {};
                    }}
                    objects={api.data}
                    total={api.count}
                    onPage={page => api.onPageChange(page)}
                    flex={1}/>
            }
          </Box>
      }
    </Box>
  </Box>;
});

