import React, { HTMLAttributes } from "react";
import { Box }                   from "../..";
import { classNames }            from "../..";
import { TabClasses }            from "./TabClasses";

export interface TabPanelContainerProps extends HTMLAttributes<HTMLDivElement> {
}

export function TabPanelContainer(props: TabPanelContainerProps) {
  const { children, className, ...p } = props;
  const classes = classNames(TabClasses.TabPanelContainer, className);

  return <Box container flex={1} className={classes} {...p}>
    {children}
  </Box>;
}
