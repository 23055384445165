import { useMemo }                                          from "react";
import { useState }                                         from "react";
import React                                                from "react";
import { FC }                                               from "react";
import { gql }                                              from "@apollo/client";
import { useQuery }                                         from "@apollo/client";
import { SearchableMultiSelect }                            from "@relcu/ui";
import { Chips, ChipsColors, CommonClasses, ItemsEllipsis } from "@relcu/ui";
import { FontIcon, MenuItem }                               from "@relcu/ui";
import { Avatar, InputState }                               from "@relcu/ui";
import { MultiChoiceFieldProps }                            from "@relcu/ui";
import { useConsumeField }                                  from "@relcu/ui";
import { AvatarSizes }                                      from "@relcu/ui";
import { DOCUMENT }                                         from "../../../../graph/operations.graphql";
import { NODE_FRAGMENT }                                    from "../../../../graph/operations.graphql";
import { GetDistributionUserVariables }                     from "./__types__/GetDistributionUser";
import { GetDistributionUser }                              from "./__types__/GetDistributionUser";
import { GetDistributionUserTagsVariables }                 from "./__types__/GetDistributionUserTags";
import { GetDistributionUserTags }                          from "./__types__/GetDistributionUserTags";

export const UsersAndTagsEditField: FC<any> = React.memo(function UsersAndTagsEditField(props) {
    let { input, meta: { touched, error, submitError, initial } } = useConsumeField<MultiChoiceFieldProps>();
    const hasError = (touched && !!error) || !!submitError;
    const [q, setQ] = useState("");

    const userVariables = useMemo(() => {
      const variables: any = {
        others: 2,
        searchWhere: {
          AND: [
            {
              objectName: {
                matchesRegex: q ? `${q?.trim()}` : "",
                options: "i"
              }
            }
          ]
        }
      };
      const selectedUsers = (initial ?? []).filter(({ type }) => type == "user");
      if (selectedUsers.length) {
        variables[ "first" ] = selectedUsers.length;
        const usersIds = selectedUsers.map(s => s.objectId);
        variables[ "searchWhere" ][ "AND" ].push({
          "id": {
            "notIn": usersIds
          }
        });

        variables[ "selectedWhere" ] = {
          id: {
            in: usersIds
          }
        };
      } else {
        variables[ "skipSelectedUsers" ] = true;
      }

      return variables;
    }, [q, initial]);

    const {
      data: { selectedUsers: { edges: selectedUsersList = [] } = {}, searchUsers: { edges: searchUsersList = [] } = {} } = {}
    } = useQuery<GetDistributionUser, GetDistributionUserVariables>(GET_DISTRIBUTION_USER, {
      fetchPolicy: "network-only",
      variables: userVariables as any
    });
    const {
      data: { tags: searchTags = [] } = {}
    } = useQuery<GetDistributionUserTags, GetDistributionUserTagsVariables>(GET_DISTRIBUTION_USER_TAGS, {
      fetchPolicy: "network-only",
      variables: {
        limit: 2,
        className: "User",
        search: q ? `(?i)${q?.trim()}` : ""
      }
    });
    const options = useMemo(() => {
      const selectedTags = (initial ?? []).filter(({ type }) => type == "tag");
      return [
        {
          label: "TAG",
          type: "tag",
          options: [...selectedTags, ...searchTags.filter(t => !selectedTags.find(tag => tag.objectId === t)).map(tag => ({ objectId: tag, objectName: tag, type: "tag" }))]
        },
        {
          label: "USER",
          type: "user",
          options: [...selectedUsersList.map(({ node }) => ({ ...node, type: "user" })), ...searchUsersList.map(({ node }) => ({ ...node, type: "user" }))]
        }
      ];
    }, [searchTags, selectedUsersList, searchUsersList, initial]);

    function findRenderedOption(item) {
      let match;
      options.map(({ options }) => {
        let find;
        if (find = options.find(opt => opt.objectId == item)) {
          match = find;
        }
      });

      return match;
    }
    return <SearchableMultiSelect
      onType={setQ}
      searchText={q}
      options={options}
      value={input.value}
      optionKey={"objectId"}
      optionLabel={"objectName"}
      showSelectionActions={false}
      renderOption={(option) => {
        return <MenuItem
          container
          thumbnail={
            option?.__typename == "User" ?
              <Avatar icon={option.objectIcon} size={AvatarSizes.Small} text={option.objectName}/> :
              <FontIcon type={"local_offer"} className={CommonClasses.GrayIcon}/>
          }>
          {option.objectName}
        </MenuItem>;
      }}
      renderSelect={(selectedItems) => {
        return <ItemsEllipsis
          optionKey={"objectId"}
          showLength={3}
          items={selectedItems}
          renderItem={(item, index) => {
            const label = findRenderedOption(item?.objectId);
            return <Chips
              length={25}
              key={index}
              color={ChipsColors.Grey}
              label={label?.objectName ?? item?.objectName}

            />;
          }}
        />;
      }}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
      {...input}
    />;
  }
);

const GET_DISTRIBUTION_USER_TAGS = gql`
  query GetDistributionUserTags($className: String!, $limit: Int $search: String!){
    tags(className: $className, search: $search, limit:$limit)
  }
`;

const GET_DISTRIBUTION_USER = gql`
  query GetDistributionUser(
    $first: Int,
    $others: Int,
    $selectedWhere: UserWhereInput,
    $searchWhere: UserWhereInput,
    $skipSelectedUsers: Boolean = false,
  ){
    selectedUsers:users(where: $selectedWhere first: $first)@skip(if: $skipSelectedUsers) {
      edges {
        node {
          ...Node
          ...Document
        }
      }
    }
    searchUsers:users(where: $searchWhere first: $others) {
      edges {
        node {
          ...Node
          ...Document
        }
      }
    }
  }
  ${NODE_FRAGMENT}
  ${DOCUMENT}
`;
