import { ReactNode }       from "react";
import React               from "react";
import { FC }              from "react";
import { useMenuProvider } from "./useMenuProvider";

export const SearchableMenuContext = React.createContext<ReturnType<typeof useMenuProvider>>(null);

interface MenuProviderProps {
  options: any[],
  selected?: object | object[],
  optionKey?: string,
  optionLabel?: string,
  renderOption?: (option) => ReactNode,
  onSelect(item: any)
  onCreateNew?(),
}

export const MenuProvider: FC<MenuProviderProps> = React.memo(function MenuProvider(props) {
  const { children } = props;
  return <SearchableMenuContext.Provider
    value={useMenuProvider(props)}>
    {children}
  </SearchableMenuContext.Provider>;
});