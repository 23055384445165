import React                                              from "react";
import { FC }                                             from "react";
import { useMemo }                                        from "react";
import { Navigate }                                       from "@relcu/react-router";
import { Route }                                          from "@relcu/react-router";
import { Routes }                                         from "@relcu/react-router";
import { useNavigate }                                    from "@relcu/react-router";
import { Container }                                      from "@relcu/rc";
import { Typography }                                     from "@relcu/rc";
import { Page }                                           from "@relcu/rc";
import { Toolbar }                                        from "@relcu/rc";
import { Sidenav }                                        from "@relcu/rc";
import { EmptyState }                                     from "@relcu/rc";
import { ListCard }                                       from "@relcu/rc";
import { Stack }                                          from "@relcu/rc";
import { useClassNames }                                  from "@relcu/rc";
import { CircularLoader }                                 from "@relcu/ui";
import { transformNameToLabel }                           from "@relcu/ui";
import { Box }                                            from "@relcu/ui";
import { useSource }                                      from "@relcu/ui";
import { gql }                                            from "@apollo/client";
import { useParams }                                      from "@relcu/react-router";
import { DOCUMENT }                                       from "../../../../graph/operations.graphql";
import { NODE_FRAGMENT }                                  from "../../../../graph/operations.graphql";
import { PhoneMessageConversationSubscriptionWhereInput } from "../../../../types/graphql-global-types";
import { PhoneMessageConversationWhereInput }             from "../../../../types/graphql-global-types";
import { pluralize }                                      from "../../../../utils/pluralize";
import { RelayMention }                                   from "../../../Relay";
import { RelayMentionProvider }                           from "../../../Relay";
import { RelayQuery }                                     from "../../../Relay";
import { ConversationContent }                            from "./ConversartionContent/ConversationContent";
import { PhoneMessageConversations }                      from "./__types__/PhoneMessageConversations";
import "./conversation-view.css";

export const ConversationView: FC<any> = React.memo(function ConversationView({ classId, navigation, className, ...props }) {
  const params = useParams();
  const navigate = useNavigate();
  const [objectId] = params[ "*" ].split("/");
  const { $object: { id: nodeId, __typename: type, objectIcon, objectName, objectId: nodeObjectId }, $viewer: { id: viewerId, objectId: viewerObjectId, role } } = useSource();
  const whereQuery: PhoneMessageConversationWhereInput = useMemo(() => {
    return {
      bulkOperation: {
        notEqualTo: true
      },
      OR: [{
        participants: {
          have: {
            party: {
              have: {
                link: nodeId
              }
            }
          }
        }
      }, {
        bulkStatsPerUser: {
          have: {
            user: {
              have: {
                id: { equalTo: nodeId }
              }
            }
          }
        }
      }]
    };
  }, [nodeId]);
  const whereSubscription: PhoneMessageConversationSubscriptionWhereInput = useMemo(() => {
    return {
      bulkOperation: {
        notEqualTo: true
      },
      OR: [{
        participants: {
          have: {
            party: {
              have: {
                link: nodeId
              }
            }
          }
        }
      }, {
        bulkStatsPerUser: {
          have: {
            user: {
              have: {
                link: nodeId
              }
            }
          }
        }
      }]
    };
  }, [nodeId]);
  const { prefix } = useClassNames("conversation", "rc");

  const onSelect = node => {
    navigate(node.objectId);
  };

  return (
    <RelayQuery<PhoneMessageConversations>
      className={"PhoneMessageConversation"}
      rowHeight={71}
      from={objectId}
      query={{
        document: PHONE_MESSAGE_CONVERSATIONS,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: { where: whereQuery }
      }}
      subscription={{
        document: SUBSCRIBE_PHONE_MESSAGE_CONVERSATIONS,
        variables: { where: whereSubscription }
      }}
      render={renderProps => {
        const {
          scrollContainerRef,
          beforeLoaderRef,
          afterLoaderRef,
          loading,
          data: { phoneMessageConversations: { edges = [], pageInfo } = {} } = {},
          register
        } = renderProps;
        const selected = edges.find(e => e.node.objectId === objectId)?.node;
        return (
          <Page>
            <Page.Sidebar width={400} style={{ borderWidth: "1px", opacity: 1 }}>
              <Sidenav.Header>
                <Toolbar spacing={6} size={"sm"}>
                  {/*<Page.Toggle size={"sm"} expand={true} onToggle={() => undefined}/>*/}
                  <Stack
                    style={{ width: 16 }}/>{/*todo tmp uncomment toggle when right side will switch to new design*/}
                  <Typography weights={"medium"} variant={"base16"}>Conversations</Typography>
                  <Stack.Item grow={1}/>
                </Toolbar>
              </Sidenav.Header>
              <Sidenav style={{ height: "calc(100% - 56px)" }} expanded={true} appearance="subtle">
                <Sidenav.Body style={{ height: "100%" }}>
                  <div className={prefix("list-container")} ref={scrollContainerRef}>
                    {
                      pageInfo?.hasPreviousPage &&
                      <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>
                    }
                    {
                      (edges.length > 0) ?
                        edges.map((edge) => {
                          const phoneMessage = edge.node;
                          const isSelected = (selected?.id == phoneMessage.id);
                          const lastContactSender = phoneMessage.participants.find(p => p.party.__typename == "Contact");
                          const lastUserSender = phoneMessage.participants.find(p => p.party.__typename == "User");
                          const isBulk = phoneMessage.type == "bulk";
                          const lastSender = isBulk ? lastUserSender : lastContactSender;
                          const behalfOf = !phoneMessage.participants.find(p => p.party.objectId == nodeObjectId);
                          const stats = (phoneMessage.type == "bulk") &&
                            (behalfOf ? phoneMessage.bulkStatsPerUser.find(p => p.user.objectId == nodeObjectId)?.stats : phoneMessage.bulkStats);
                          const icon = (isBulk && !behalfOf) ? "rc_bulk_sms" : ((phoneMessage.direction == "incoming") ? "call_received" : "call_made");
                          const progressPercentage = isBulk && 100 * (
                            stats.failed +
                            stats.sent +
                            stats.canceled +
                            stats.delivered +
                            stats.undelivered
                          ) / stats.total;
                          const recipientsCount = phoneMessage.bulkStatsPerUser?.filter((u) => u.user.objectId !== phoneMessage.participants[ 0 ].party.objectId).length;
                          return <ListCard
                            key={phoneMessage.id}
                            icon={icon}
                            selected={isSelected}
                            unread={!behalfOf && phoneMessage.unread}
                            behalfOf={behalfOf}
                            recipientsCounter={phoneMessage.type == "bulk" && !behalfOf && !!phoneMessage.bulkStatsPerUser?.length && recipientsCount}
                            ref={e => e && register(e, phoneMessage.objectId)}
                            objectIcon={behalfOf ? (objectIcon && objectIcon) : (lastSender.party.objectIcon && lastSender.party.objectIcon)}
                            objectName={behalfOf ? objectName : lastSender.party.objectName}
                            onClick={() => onSelect(phoneMessage)}
                            subject={phoneMessage.error || phoneMessage.content}
                            date={phoneMessage.lastUpdatedAt}>
                            {
                              phoneMessage.type == "bulk" &&
                              <ListCard.ProgressIndicator count={stats?.total} icon={"rc_bulk_sms"}
                                                          title={`Sending to ${stats?.total} ${pluralize(transformNameToLabel(phoneMessage[ "scopeType" ] ?? "Lead"), stats.total)}...`}
                                                          type={transformNameToLabel(phoneMessage[ "scopeType" ] ?? "Lead")}
                                                          progressPercentage={progressPercentage}/>
                            }
                          </ListCard>;
                        })
                        : (
                          loading ?
                            <Box container flex={1} justify={"center"} alignItems={"center"}>
                              <CircularLoader/>
                            </Box> : <EmptyState
                              icon={"forum"}
                              title={"All the conversations will appear here"}
                            />
                        )
                    }
                    <RelayMentionProvider<PhoneMessageConversations>
                      variables={{
                        order: ["lastUpdatedAt_DESC"],
                        where: {
                          unread: { equalTo: true }
                        }
                      }}
                      predicate={({ phoneMessageConversations: { edges = [] } }) => {
                        return edges.filter(e => {
                          return e.node.unread && e.node.participants.find(p => p.party.objectId == nodeObjectId);
                        }).map(e => e.node.objectId);
                      }}>
                      <RelayMention direction={"top"} top={70}>New message</RelayMention>
                      <RelayMention direction={"bottom"} bottom={30}>New message</RelayMention>
                    </RelayMentionProvider>
                    {
                      pageInfo?.hasNextPage &&
                      <CircularLoader justify={"center"} alignSelf={"center"} ref={afterLoaderRef}/>
                    }
                  </div>
                </Sidenav.Body>
              </Sidenav>
            </Page.Sidebar>
            <Container style={{ overflowX: "hidden" }}>
              <Routes>
                <Route path={"/"} element={(!loading && edges.length) ?
                  <Navigate to={`${edges[ 0 ].node.objectId}`}/> :
                  <EmptyState icon={"forum"} subtitle={`No messages available`}/>}/>
                <Route path={"/:conversationId"} element={<ConversationContent/>}/>
                <Route path={"/:conversationId/:nearBy"} element={<ConversationContent/>}/>
              </Routes>
            </Container>
          </Page>
        );
      }}
    />
  );
});

export const PHONE_MESSAGE_CONVERSATION_FRAGMENT = gql`
  fragment PhoneMessageConversation on PhoneMessageConversation {
    ...Node
    ...Document
    content
    sid
    unread
    lastUpdatedAt
    direction
    error
    type
    scopeType
    bulkStats {
      queued
      canceled
      delivered
      undelivered
      total
      failed
      sent
    }
    participants {
      type
      party {
        ...on User {
          __typename
          id
          objectId
          objectName
          objectIcon
          firstName
          lastName
        }
        ...on Contact {
          __typename
          id
          objectId
          objectName
          objectIcon
          firstName
          lastName
          middleName
          phones {
            default
            number
            type
            isPrimary
            callOptOut
            smsOptOut
          }
        }
      }
    }
    bulkStatsPerUser {
      user {
        objectId
        objectName
      }
      stats {
        queued
        canceled
        delivered
        undelivered
        total
        failed
        sent
      }
    }
    scopeType
    scope {
      ... on Node {
        id
      }
      ...Document
    }
  }
  ${NODE_FRAGMENT}
  ${DOCUMENT}
`;

export const PHONE_MESSAGE_CONVERSATIONS = gql`
  query PhoneMessageConversations($where: PhoneMessageConversationWhereInput,$skip:Int,$before:String,$after:String,$from:String,$first:Int,$last:Int) {
    phoneMessageConversations(
      where:$where
      order: lastUpdatedAt_DESC
      first: $first,
      last: $last,
      skip:$skip
      before:$before
      after:$after,
      from:$from,
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...PhoneMessageConversation
        }
      }
    }
  }
  ${PHONE_MESSAGE_CONVERSATION_FRAGMENT}
`;

export const SUBSCRIBE_PHONE_MESSAGE_CONVERSATIONS = gql`
  subscription SubscribePhoneMessageConversations($where: PhoneMessageConversationSubscriptionWhereInput!) {
    phoneMessageConversations(where: $where){
      event
      node {
        ...PhoneMessageConversation
      }
    }
  }
  ${PHONE_MESSAGE_CONVERSATION_FRAGMENT}
`;
