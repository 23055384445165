import { FC }                from "react";
import React                 from "react";
import { useBoxProps }       from "../..";
import { ButtonSizes }       from "../..";
import { ButtonColors }      from "../..";
import { Button }            from "../..";
import { FontIcon }          from "../..";
import { BoxComponentProps } from "../..";
import { classNames }        from "../..";
import { Box }               from "../..";

export interface InlineEditProps extends BoxComponentProps, EditActionsProps, ReadActionsProps {
  readView: () => React.ReactNode;
  editView: () => React.ReactNode;
  isEditing?: boolean;
  hideActionButtons?: boolean;
  disableActionsButtons?: boolean;
  InputProps?: { [ key: string ]: any };
  classes?: {
    root?: string,
    actions?: string
  };
  renderEditActions?(props: EditActionsProps): React.ReactNode;
  renderReadActions?(props: ReadActionsProps): React.ReactNode;
}
export interface EditActionsProps {
  onConfirm(event);
  onCancel?(event);
}
export interface ReadActionsProps {
  onEdit?(event);
}

export const InlineEdit: FC<InlineEditProps> = React.memo(function InlineEdit(props) {
  const { hideActionButtons, isEditing, onConfirm, onCancel, onEdit, readView, editView, classes, disableActionsButtons, renderEditActions, renderReadActions, ...p } = props;
  const className = classNames(InlineEditClasses.InlineEdit, {
    [ InlineEditClasses.InlineEditClose ]: !isEditing
  }, classes?.root);
  return (
    <Box container gap={"XXS"} alignItems={"center"} className={className} {...useBoxProps(p)}>
      {
        isEditing ? editView() : readView()
      }
      <Box container gap={"XXS"} style={{ width: 56 }} alignSelf={"stretch"} alignItems={"center"}
           className={classNames(InlineEditClasses.InlineEditActions, { [ InlineEditClasses.InlineDisableActions ]: disableActionsButtons }, classes?.actions)}>
        {
          !hideActionButtons &&
          (
            isEditing
              ?
              renderEditActions({ onCancel, onConfirm })
              :
              renderReadActions({ onEdit })
          )
        }
      </Box>
    </Box>
  );
});
InlineEdit.defaultProps = {
  renderEditActions: ({ onConfirm, onCancel }) => (
    <>
      <Button onMouseDown={onConfirm} onlyIcon size={ButtonSizes.Small} color={ButtonColors.White}
              className={InlineEditClasses.InlineEditAction} icon={"save"}/>
      <Button onMouseDown={onCancel} onlyIcon size={ButtonSizes.Small} color={ButtonColors.White}
              className={InlineEditClasses.InlineEditAction} icon={"clear"}/>
    </>
  ),
  renderReadActions: ({ onEdit }) => (
    <FontIcon type="create" key={"create"} className={InlineEditClasses.InlineEditAction} onClick={(e) => {
      e.stopPropagation();
      onEdit(e);
    }}/>
  )
};
export enum InlineEditClasses {
  InlineEdit = "inline-edit",
  InlineEditClose = "inline-edit--close",
  InlineEditActions = "inline-edit__actions",
  InlineEditAction = "inline-edit__action",
  InlineDisableActions = "inline-edit__actions--disabled"
}
