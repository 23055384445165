import { getIn }                         from "@relcu/form";
import { EmptyList }                     from "@relcu/ui";
import { ListViewClasses }               from "@relcu/ui";
import { CircularLoader }                from "@relcu/ui";
import { Box }                           from "@relcu/ui";
import { SearchInput }                   from "@relcu/ui";
import { List }                          from "@relcu/ui";
import { Checkbox }                      from "@relcu/ui";
import { Button }                        from "@relcu/ui";
import { Tooltip }                       from "@relcu/ui";
import { ButtonVariants }                from "@relcu/ui";
import { Alignment }                     from "@relcu/ui";
import { useMemo }                       from "react";
import { FC }                            from "react";
import React                             from "react";
import { DistributionStatsModalClasses } from "./DistributionStatsModalClasses";
import { useDistributionStatsUsers }     from "./useDistributionStatsUsers";

interface DistributionStatsUsersProps {
  queue: string,
  strategy: string
  distributeOfflineUsers: boolean
  onClose
}
export const DistributionStatsUsers: FC<DistributionStatsUsersProps> = React.memo(function DistributionStatsModal(props) {
  const { queue, onClose, strategy, distributeOfflineUsers } = props;
  const api = useDistributionStatsUsers(queue);
  const isRoundRobin = useMemo(() => {
    return strategy === "round_robin";
  }, [strategy]);
  const getTableData = useMemo(() => {
    if (!isRoundRobin) {
      return {
        fields: [
          {
            name: "objectIcon",
            component: "ListItemImage",
            label: "objectIcon",
            alt: "objectName"
          },
          {
            name: "objectName",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 0,
            flexShrink: 1
          },
          {
            name: "team",
            component: "ListItemChoice",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "role",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "coolDownPeriod",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "leadCaps",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "leadsAvailable",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          }
        ],
        headers: [
          {
            "title": "Full name",
            "flexBasis": "232px",
            "flexGrow": 0,
            "flexShrink": 1
          },
          {
            "title": "Team",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Role",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Cool down period",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Lead caps",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Matched leads",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          }

        ]
      };
    } else {
      return {
        fields: [
          {
            name: "objectIcon",
            component: "ListItemImage",
            label: "objectIcon",
            alt: "objectName"
          },
          {
            name: "objectName",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 0,
            flexShrink: 1
          },
          {
            name: "team",
            component: "ListItemChoice",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "role",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "coolDownPeriod",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          },
          {
            name: "leadCaps",
            component: "ListItemTitle",
            flexBasis: "200px",
            flexGrow: 1,
            flexShrink: 1
          }
        ],
        headers: [
          {
            "title": "Full name",
            "flexBasis": "232px",
            "flexGrow": 0,
            "flexShrink": 1
          },
          {
            "title": "Team",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Role",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Cool down period",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          },
          {
            "title": "Lead caps",
            "flexBasis": "200px",
            "flexGrow": 1,
            "flexShrink": 1
          }
        ]
      };
    }
  }, [isRoundRobin]);

  return <Box container direction={"column"} gap={"S"} flexGrow={1}>
    <Box container direction={"column"} gap={"XS"} flex={"1 1 0%"} style={{ overflow: "hidden" }}>
      <Box container direction={"row"} gap={"XXS"} alignItems={"center"}>
        <SearchInput
          clearable
          placeholder={"Search"}
          style={{ padding: 0 }}
          autoFocus={true}
          value={api.value}
          onChange={api.setValue}
        />
        {!isRoundRobin && <Checkbox
          alignment={"right"}
          direction={"row"}
          justify={"center"}
          alignItems={"center"}
          checked={api.hasNoLeads}
          onChange={() => api.setHasNoLeads(!api.hasNoLeads)}
          placeholder={"Has no leads"}/>}
        <Box flex={1}/>
        <Tooltip title={"Refresh to update data"} alignment={Alignment.TopLeft}>
          <Button
            icon={"refresh"}
            variant={ButtonVariants.Ghost}
            alignSelf={"center"}
            onClick={() => api.onFetch()}/>
        </Tooltip>
      </Box>
      {
        api.data?.length == 0 ?
          <Box container flex={"1 1 auto"} justify={"center"} alignItems={"center"}
               className={ListViewClasses.ListEmptyView}>
            <EmptyList icon={"trending_up"} content={"Distribution queue is empty, please check configuration."}
                       title={"No stats found"}
                       alignSelf={"center"}/>
          </Box>
          :
          <Box gap={"XXS"} direction={"column"} container style={{ overflow: "hidden" }}>
            {
              api.loading ?
                <Box container flex={1} alignItems={"center"} justify={"center"}
                     className={DistributionStatsModalClasses.Loader}>
                  <CircularLoader/>
                </Box>
                :

                api.data?.length == 0 ?
                  <Box container flex={"1 1 auto"} justify={"center"}
                       className={ListViewClasses.ListEmptyView}>
                    <EmptyList icon={"trending_up"}
                               content={"Distribution queue is empty, please check configuration."}
                               title={"No stats found"}
                               alignSelf={"center"}/>
                  </Box>
                  :
                  <List
                    onRowClick={(item) => {
                      onClose();
                      api.onRowClick(item);
                    }}
                    getColumnValue={(row, field) => {
                      return getIn(row, field.name);
                    }
                    }
                    fields={getTableData.fields}
                    headers={getTableData.headers}
                    onColumnRender={(name, object, value) => {
                      if (name == "leadCaps") {
                        return object.leadCaps;
                      }
                    }}
                    onRowRender={(object) => {
                      if (isRoundRobin && (object.online && object.status === "available" || (!object.online && distributeOfflineUsers && object.status === "offline"))) {
                        return {
                          style: { backgroundColor: "var(--rc-status-01-success-lighter)" }
                        };
                      }
                      if (!isRoundRobin && object.leadsAvailable == 0) {
                        return {
                          style: {
                            backgroundColor: "#FFEAEE"
                          }
                        };
                      }
                      return {};
                    }}
                    objects={api.data}
                    total={api.count}
                    onPage={page => api.onPageChange(page)}
                    flex={1}/>
            }
          </Box>
      }
    </Box>
  </Box>;
});
