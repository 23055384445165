import { useLayoutEffect } from "react";
import { useCallback }     from "react";
import { useState }        from "react";

const getRect = (element) => {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };
  }

  return element.getBoundingClientRect();
};

export const useRect = (ref) => {
  const [rect, setRect] = useState(getRect(ref ? ref.current : null));

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    setRect(getRect(ref.current));
  }, [ref]);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref.current]);

  return rect;
};

