import React, { useMemo } from "react";
import { CSSProperties }  from "react";
import { FC }             from "react";
import { AlertPosition }  from "./AlertPosition";

export const getStyles = position => {
  const initialStyles: CSSProperties = {
    position: "fixed",
    zIndex: 100
  };

  switch (position) {
    case AlertPosition.TOP_LEFT:
      return {
        ...initialStyles,
        top: "3%",
        left: "1%"
        // alignItems: 'flex-start'
      };
    case AlertPosition.TOP_CENTER:
      return {
        ...initialStyles,
        top: "3%",
        left: "50%",
        transform: "translate(-50%)"
      };
    case AlertPosition.TOP_RIGHT:
      return {
        ...initialStyles,
        top: "3%",
        right: "1%"
      };
    case AlertPosition.BOTTOM_LEFT:
      return {
        ...initialStyles,
        bottom: "3%",
        left: "1%"
      };
    case AlertPosition.BOTTOM_CENTER:
      return {
        ...initialStyles,
        bottom: "3%",
        left: "50%",
        transform: "translate(-50%)"
      };
    case AlertPosition.BOTTOM_RIGHT:
      return {
        ...initialStyles,
        bottom: "3%",
        right: "1%"
      };

    default: {
      return initialStyles;
    }
  }
};

export interface AlertContainerProps {
  position: AlertPosition,
  className?: string,
  style?: CSSProperties
}

export const AlertContainer: FC<AlertContainerProps> = React.memo(({ children, position, className, style }) => {
  const styles = useMemo(() => getStyles(position), [position]);

  return (
    <div style={{ ...styles, ...style }} className={className}>
      {children}
    </div>
  );
});

export default AlertContainer;
