import React                 from "react";
import { FC }                from "react";
import { Box }               from "../../..";
import { BoxComponentProps } from "../../..";
import { classNames }        from "../../..";

export const ListActions: FC<BoxComponentProps> = React.memo(function ActionsContainer(props) {
  const { className, ...p } = props;
  const classes = classNames(ListActionsClasses.ListActions, className);

  return <Box container className={classes} alignSelf={"start"} gap={"XXS"} flexGrow={0} flexBasis={72}
              alignItems={"center"} {...p}/>;
});
export enum ListActionsClasses {
  ListActions = "list-actions"
}
