import React                   from "react";
import { FC }                  from "react";
import { Popover }             from "@relcu/rc";
import { ToggleDropdownProps } from "@relcu/rc";
import { ToggleDropdown }      from "@relcu/rc";

export interface InfoPopoverProps extends ToggleDropdownProps {
  padding?: number;
}

export const InfoPopover: FC<InfoPopoverProps> = React.memo(function InfoPopover(props) {
  const { speaker, padding = 12, toggleRenderer, ...rest } = props;

  const renderMenu = ({ onClose, left, top, className, ...rest }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} arrow={false} full>
        <div style={{
          color: "#000",
          background: "#fff",
          padding: padding,
          borderRadius: 8,
          border: "1px solid #EAECF0",
          boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.08)"
        }}>
          {speaker}
        </div>
      </Popover>
    );
  };

  return <ToggleDropdown trigger={"click"} toggleRenderer={toggleRenderer} preventOverflow
                         speaker={renderMenu} {...rest}/>;
});
