import { useLazyQuery }          from "@apollo/client";
import { gql }                   from "@apollo/client";
import { getIn }                 from "@relcu/form";
import { useNavigate }           from "@relcu/react-router";
import { useThrottle }           from "@relcu/ui";
import { useCallback }           from "react";
import { useState }              from "react";
import { useEffect }             from "react";
import { IChoiceField }          from "../../../types/ISchemas";
import { getHumanized }          from "../../../utils/schemaUtils";
import { useSchemaField }        from "../../Layout/useSchemaField";
import { LeadsByQueueVariables } from "./__types__/LeadsByQueue";
import { LeadsByQueue }          from "./__types__/LeadsByQueue";

export function useDistributionStatsLeads(queueName) {
  const navigate = useNavigate();
  const [hasNoUsers,setHasNoUsers] = useState(false)
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  let [query, setQuery] = useThrottle(value, 1000);
  const limit = 15;
  const loanPurposeSchema = useSchemaField<IChoiceField>("Lead", "loanPurpose");
  const [loadLeadsStats, { data, loading,refetch }] = useLazyQuery<LeadsByQueue,LeadsByQueueVariables>(LEADS_BY_QUEUE, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const getColumnValue = useCallback((row, field) => {
    return getHumanized("Lead", field.name, getIn(row, field.name))
  }, []);
  useEffect(() => {
    loadLeadsStats({
      variables:{
        queue:queueName,
        hasNoUsers:hasNoUsers,
        match: query,
        first:limit,
        skip:0
      }
    })
  }, [queueName,hasNoUsers]);

  const onPageChange = (page)=>{
    setPage(page)
    refetch({
      queue:queueName,
      hasNoUsers:hasNoUsers,
      first: limit,
      match: query,
      skip: (page - 1) * limit,
    })
  }
  const onFetch = ()=>{
    refetch({
      queue:queueName,
      hasNoUsers:hasNoUsers,
      first: limit,
      match: query,
      skip: (page - 1) * limit,
    })
  }

  useEffect(() => setQuery(value), [value]);
  useEffect(() => {
    setPage(1)
    refetch({
      queue: queueName,
      hasNoUsers:hasNoUsers,
      first: limit,
      match: query,
      skip:0
    });
  }, [query,hasNoUsers]);
  return {
    get data() {
      return  (data?.leadsByQueue?.leadsByQueue || []).map((item)=>{
        return {
          objectName:item.lead.objectName,
          assignedUserName: item.lead.assignedTo?.objectName || "N/A",
          assignedUserIcon: item.lead.assignedTo?.objectIcon,
          loanPurpose:item.lead.loanPurpose,
          loanAmount:item.lead.loanAmount || 0,
          leadStatus:item.lead.leadStatus,
          localTime:item.lead.timezone,
          propertyState:item.lead.property?.propertyAddress?.state || '-',
          matchedUsers:item.distributionRules.length,
          createdAt: item.lead.createdAt,
          updatedAt: item.lead.updatedAt,
          objectId:item.lead.objectId,
        }
      });
    },
    onRowClick(item) {
      setTimeout(()=>{navigate(`/lead/${item.objectId}`)})
    },
    getColumnValue,
    hasNoUsers,
    setHasNoUsers,
    onPageChange,
    onFetch,
    setValue,
    value,
    count:data?.leadsByQueue?.count,
    loading:loading,
  };

}
export const LEADS_BY_QUEUE = gql`
  query LeadsByQueue($queue:String!, $first:Int = 50, $skip:Int = 0, $hasNoUsers:Boolean = false,$match:String) {
    leadsByQueue(queue:$queue,first: $first,skip: $skip, hasNoUsers: $hasNoUsers,match: $match) {
      count
      leadsByQueue {
        lead {
          objectId
          objectName
          property{
            propertyAddress{
              state
            }
          }
          assignedTo{
            objectId
            objectName
            objectIcon
          }
          loanAmount
          loanPurpose
          borrowerFirstName
          borrowerLastName
          leadStatus{
            status
            updatedAt
          }
          timezone
          createdAt
          updatedAt
        }
        distributionRules
      }
    }
  }
`;

