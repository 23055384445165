import { Form }           from "@relcu/rc";
import React              from "react";
import { useSchemaField } from "../../../useSchemaField";

export const ProposalOccupancy = React.memo(function ProposalOccupancy() {
  const { options } = useSchemaField("LoanEstimate", "propertyOccupancy");
  return (
    <Form.Select
      name="propertyOccupancy"
      label="Occupancy"
      required
      size={"lg"}
      data={options}
      properties={{ searchable: false, style: { width: "100%" } }}
    />
  );
});
