import { useApolloClient }         from "@apollo/client";
import { gql }                     from "@apollo/client";
import { useMutation }             from "@apollo/client";
import { FORM_ERROR, FormApi }     from "@relcu/form";
import { setIn }                   from "@relcu/form";
import { getIn }                   from "@relcu/form";
import { useContext }              from "react";
import { useState }                from "react";
import { useMemo }                 from "react";
import { schemaVar }               from "../../../../../../reactiveVars";
import { IChoiceField }            from "../../../../../../types/ISchemas";
import { transformFields }         from "../../../../../../utils/graphQlUtils";
import { toNodeId }                from "../../../../../../utils/helpers";
import { useBookmarks }            from "../../../../../NavBar/useBookmarks";
import { useJqlMutation }          from "../../../../Jql";
import { useSchemaField }          from "../../../../useSchemaField";
import { ContactDialogContext }    from "../../ContactDialog";
import { CONTACT }                 from "../../useContactDialog";
import { ReplaceContactVariables } from "./__types__/ReplaceContact";
import { ReplaceContact }          from "./__types__/ReplaceContact";
import { ReplaceContactOldVariables } from "./__types__/ReplaceContactOld";
import { ReplaceContactOld }       from "./__types__/ReplaceContactOld";

function validateMergedContactInfoExists(value) {
  if (value.emails?.length == 0 && value.phones?.length == 0) {
    return "Please provide at least one contact information.";
  }
  return undefined;
}

const mergeContactsSingleFields = ["firstName", "lastName", "middleName", "birthday", "company"];

export function useMergeContacts() {
  const { resolveContactInitialValues, afterSubmit, jql } = useContext(ContactDialogContext);
  const {onRemove} = useBookmarks()
  const client = useApolloClient();
  const [update] = useJqlMutation(jql.mutation.update, { operationName: "MergeContact" });
  const [replace] = useMutation<ReplaceContactOld, ReplaceContactOldVariables>(REPLACE_CONTACT_OLD);
  const { options } = useSchemaField<IChoiceField>("Contact", "types");
  const defaultCheckedTypes = useMemo(() => {
    return new Array(resolveContactInitialValues.exists?.types?.length).fill(true);
  }, [resolveContactInitialValues]);
  const defaultUnCheckedTypes = useMemo(() => {
    return new Array(resolveContactInitialValues.temporary?.types?.length).fill(true);
  }, [resolveContactInitialValues]);
  const defaultCheckedPhones = useMemo(() => {
    return new Array(resolveContactInitialValues.exists?.phones?.length).fill({ number: true });
  }, [resolveContactInitialValues]);
  const defaultUnCheckedPhones = useMemo(() => {
    return new Array(resolveContactInitialValues.temporary?.phones?.length).fill({ number: true });
  }, [resolveContactInitialValues]);
  const defaultCheckedEmails = useMemo(() => {
    return new Array(resolveContactInitialValues.exists?.emails?.length).fill({ address: true });
  }, [resolveContactInitialValues]);
  const defaultUnCheckedEmails = useMemo(() => {
    return new Array(resolveContactInitialValues.temporary?.emails?.length).fill({ address: true });
  }, [resolveContactInitialValues]);
  const [marks, setMarks] = useState(() => {
    const initial = {
      exists: {
        phones: defaultCheckedPhones,
        emails: defaultCheckedEmails,
        types: defaultCheckedTypes
      },
      temporary: {
        phones: defaultUnCheckedPhones,
        emails: defaultUnCheckedEmails,
        types: defaultUnCheckedTypes
      }
    };
    mergeContactsSingleFields.map(k => {
      if (resolveContactInitialValues.temporary[ k ]) {
        initial.temporary[ k ] = true;
      }

      if (resolveContactInitialValues.exists[ k ]) {
        initial.temporary[ k ] = false;
        initial.exists[ k ] = true;
      }
    });

    return initial;
  });

  function mergeContactTypes(exists = [], temporary = []) {
    const exTypes = exists.filter((e, i) => marks.exists.types[ i ]);
    const temTypes = temporary.filter((e, i) => marks.temporary.types[ i ]);
    return Array.from(new Set([...exTypes, ...temTypes]));
  }
  function mergeTags(exists = [], temporary = []) {
    return Array.from(new Set([...exists, ...temporary]));
  }
  function mergePhones(exists, temporary) {
    const merged = [];
    exists.forEach((e, i) => {
      if (marks.exists.phones[ i ].number) {
        merged.push(e);
      }
    });

    temporary.filter((e, i) => {
      if (marks.temporary.phones[ i ].number && !merged.find(m => m.number == e.number)) {
        merged.push(e);
      }
    });

    return merged;
  }
  function mergeEmails(exists, temporary) {
    const merged = [];
    exists.forEach((e, i) => {
      if (marks.exists.emails[ i ].address) {
        merged.push(e);
      }
    });

    temporary.filter((e, i) => {
      if (marks.temporary.emails[ i ].address && !merged.find(m => m.address == e.address)) {
        merged.push(e);
      }
    });

    return merged;
  }
  function formatType(value) {
    return options.find(t => t.value == value)?.label;
  }

  const handleSubmit = async ({ exists, temporary }, form: FormApi) => {
    const contact = { ...exists };
    const tempId = toNodeId({ className: "Contact", objectId: temporary.objectId });
    const tempContact = client.readFragment({
      fragment: CONTACT,
      fragmentName: "Contact",
      id: client.cache.identify({ __typename: "Contact", id: tempId })
    });

    if (tempContact) {
      temporary.tags = tempContact.tags;
    }
    const { initialValues: initialExists } = form.getState();
    const schemas = schemaVar();
    mergeContactsSingleFields.map(k => {
      if (marks.temporary[ k ]) {
        contact[ k ] = temporary[ k ];
      }
    });
    contact.types = mergeContactTypes(exists.types, temporary.types);
    contact.tags = mergeTags(exists.tags, temporary.tags);
    contact.phones = mergePhones(exists.phones, temporary.phones);
    contact.emails = mergeEmails(exists.emails, temporary.emails);
    const { id, objectId, objectName, objectIcon, ...fields } = contact;
    const formErrors = validateMergedContactInfoExists(contact);
    if (formErrors) {
      return { [ FORM_ERROR ]: formErrors };
    }
    //todo if temporary does not have id just update existing one
    try {
      if (temporary.objectId) {
        const { data } = await replace({
          variables: {
            fromContact: {
              objectId: temporary.objectId
            },
            toContact: {
              objectId: contact.objectId,
              fields: transformFields(fields, initialExists, "Contact", schemas)
            }
          }
        });
        onRemove(temporary).catch((e)=> console.error("MergeContact remove bookmark",e) )
        client.cache.evict({ id: tempId });
        afterSubmit(data.replaceContact, form);
      } else {
        const { data: { updateContact: { contact: updatedContact } }, errors } = await update({
          variables: {
            input: { id, fields: transformFields(fields, initialExists, "Contact", schemas) }
          }
        });
        afterSubmit?.(updatedContact, form);
      }
    } catch (e) {
      console.error(e);
      return e.message || "Ops something went wrong.";
    }
  };

  const onMark = (name: string, allValues?: any) => {
    setMarks(prevState => {
      let prev: any = { ...prevState };
      const targetMark = getIn(prev, name);
      const oppositeName = name.indexOf("exists") > -1 ? name.replace("exists", "temporary") : name.replace("temporary", "exists");
      const oppositeMark = getIn(prev, oppositeName);
      if (allValues) {
        const targetValue = getIn(allValues, name);
        const oppositeValue = getIn(allValues, oppositeName);
        if (targetValue == oppositeValue) {
          prev = setIn(prev, name, !targetMark);
          prev = setIn(prev, oppositeName, !targetMark);
        } else {
          if (targetValue) {
            prev = setIn(prev, name, !targetMark);
          }
        }

      } else {
        prev = setIn(prev, name, !targetMark);
        prev = setIn(prev, oppositeName, !oppositeMark);
      }

      return prev;
    });
  };

  return {
    handleSubmit,
    formatType,
    onMark,
    setMarks,
    marks
  };
}

export const REPLACE_CONTACT_OLD = gql`
  mutation ReplaceContactOld($fromContact: ReplaceContactInputType!,$toContact:ReplaceContactInputType!) {
    replaceContact(fromContact: $fromContact,toContact: $toContact) {
      ...Contact
    }
  }
  ${CONTACT}
`;
