import { CommonClasses }                          from "@relcu/ui";
import { Link }                                   from "@relcu/ui";
import React                                      from "react";
import { TypographyColor }                        from "@relcu/ui";
import { Typography }                             from "@relcu/ui";
import { TimelineItemSubject }                    from "@relcu/ui";
import { TimelineItemIconColors }                 from "@relcu/ui";
import { TimelineItemIcon }                       from "@relcu/ui";
import { TimelineItem }                           from "@relcu/ui";
import { getObjectPath }                          from "../../../../../utils/layoutUtils";
import { GenericActivity as GenericActivityType } from "../../../../__types__/GenericActivity";

export interface GenericActivityProps extends GenericActivityType {
  createdAt: string;
}

export const GenericActivity = React.memo<GenericActivityProps>(function GenericActivity(props) {
  const { message, generatedBy, genericSubject, createdAt } = props;
  const initiatorName = generatedBy ? generatedBy.objectName : "Relcu";
  const subject = genericSubject ? genericSubject.objectName : "Unknown";
  return <TimelineItem
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"flare"}/>}
    primary={<TimelineItemSubject>
      {generatedBy?.objectId && !generatedBy.objectId.startsWith("int:") ?
        <Link
          to={generatedBy.__typename === "User" ? `/user/${generatedBy.objectId}` : `/contact/${generatedBy.objectId}`}>
          <Typography className={CommonClasses.ClickableIcon}>{initiatorName}</Typography>
        </Link>
        :
        <Typography>{initiatorName}</Typography>
      }
      <Typography color={TypographyColor.Secondary}>{message}</Typography>
      {
        genericSubject &&
        <>
          <Typography color={TypographyColor.Secondary}>on</Typography>
          {genericSubject.objectId ?
            <Link to={getObjectPath(genericSubject)}>
              <Typography className={CommonClasses.ClickableIcon}>{subject}</Typography>
            </Link>
            :
            <Typography>{subject}</Typography>
          }
        </>
      }
    </TimelineItemSubject>}
    creationDate={createdAt}
  />;
});

