import React                               from "react";
import Dropdown, { DropdownMenuItemProps } from "../Dropdown";
import { useClassNames }                   from "../utils";

export interface ToggleDropdownItemProps extends DropdownMenuItemProps {

}

export const ToggleDropdownItem = React.forwardRef((props: ToggleDropdownItemProps, ref) => {
  const {
    className,
    classPrefix = "toggle-dropdown-item",
    ...rest
  } = props;
  const { withClassPrefix, merge } = useClassNames(classPrefix, "rc");

  const classes = merge(
    className,
    withClassPrefix()
  );
  return <Dropdown.Item className={classes} {...rest}/>;
});
