import React, { FC, ReactNode }  from "react";
import { BoxItemComponentProps } from "../Box";
import { Box }                   from "../Box";

export interface NotificationInterface extends BoxItemComponentProps {
  children: ReactNode
  onNavigate?(),
}

export const Notification: FC<NotificationInterface> = React.memo(function (props) {
  const { children, onNavigate, className, style } = props

  return <Box container flexGrow={1} direction={"column"} gap={"XXS"} onClick={onNavigate} className={className} style={{cursor:"pointer", overflow: "hidden", ...style }}>
    {children}
  </Box>;
});


