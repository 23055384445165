import { LoanEstimate }      from "../../../../graph/__types__/LoanEstimate";
import { LoanEstimateOffer } from "../../../../graph/__types__/LoanEstimateOffer";
import { schemaVar }         from "../../../../reactiveVars";
import { uuid }              from "../../../../utils/helpers";
import { getField }          from "../../../../utils/schemaUtils";
import { getNullValues }     from "../../../../utils/schemaUtils";

export function totalLoanAmount(loanEstimate: Partial<LoanEstimate>, loanEstimateOffer: Partial<LoanEstimateOffer>, settings) {
  const { total } = loanAmountDetails(loanEstimate, loanEstimateOffer, settings);
  return total;
}

export function productName(loanEstimateOffer: Partial<LoanEstimateOffer>) {
  let productPiece = "";
  let termPiece = "";
  const { options: loanTermOptions } = getField("LoanEstimateOffer", "loanTerm");
  const { options: amortizationTypeOptions } = getField("LoanEstimateOffer", "amortizationType");
  const { options: productTypeOptions } = getField("LoanEstimateOffer", "productType");
  const { options: initialArmTermOptions } = getField("LoanEstimateOffer", "initialArmTerm");

  if(loanEstimateOffer.productType == "conventional"){
    productPiece = loanEstimateOffer.conforming ? "Conforming" : "Non Conforming"
  }else{
    productPiece = productTypeOptions.find((o)=>(o.value==loanEstimateOffer.productType))?.label;
  }
  let loanTerm = loanTermOptions.find((o)=>(o.value==loanEstimateOffer.loanTerm))?.label?.replace("Year","Yr")?.replace("Month","M");
  if(loanEstimateOffer.amortizationType=="fixed"){
    termPiece = `${loanTerm} Fixed`
  }else if(loanEstimateOffer.amortizationType=="balloon"){
    termPiece = `${loanTerm} Balloon`
  }else{
    let initialArmTerm = initialArmTermOptions.find((o)=>(o.value==loanEstimateOffer.initialArmTerm))?.label?.replace("Year","Yr")?.replace("Month","M");
    termPiece = `${loanTerm} ${amortizationTypeOptions.find((o)=>(o.value==loanEstimateOffer.amortizationType))?.label} ${initialArmTerm}`
  }
  return `${productPiece} ${termPiece}`;
}

export function loanAmountDetails(loanEstimate: Partial<LoanEstimate>, loanEstimateOffer: Partial<LoanEstimateOffer>, settings) {
  let percentage = 0;
  let type = "other";
  const downPaymentPercentage = ((loanEstimateOffer.downPayment / loanEstimateOffer.propertyValue) * 100);
  if (loanEstimateOffer.productType == "va") {
    type = "ff";
    if (loanEstimateOffer.exempt) {
      percentage = 0;
    } else if (loanEstimate.loanPurpose == "purchase") {
      if (downPaymentPercentage < 5) {
        percentage = loanEstimateOffer.firstUseOfVaProgram ? settings.va.purchase.firstUseFiveDownFF : settings.va.purchase.secondUseFiveDownFF;
      } else if (downPaymentPercentage < 10) {
        percentage = loanEstimateOffer.firstUseOfVaProgram ? settings.va.purchase.firstUseFiveOrMoreDownFF : settings.va.purchase.secondUseFiveOrMoreDownFF;
      } else {
        percentage = loanEstimateOffer.firstUseOfVaProgram ? settings.va.purchase.firstUseTenOrMoreDownFF : settings.va.purchase.secondUseTenOrMoreDownFF;
      }
    } else if (loanEstimateOffer.isStreamLine) {
      percentage = settings.va.refinance.streamlineRefinanceFF;
    } else {
      percentage = loanEstimateOffer.firstUseOfVaProgram ? settings.va.refinance.firstUseCashOutRefinanceFF : settings.va.refinance.secondUseCashOutRefinanceFF;
    }
    const fee = (loanEstimateOffer.loanAmount * percentage / 100);
    if (loanEstimateOffer.financeFf) {
      return {
        type,
        amount: loanEstimateOffer.loanAmount,
        fee,
        total: !!(loanEstimateOffer.loanAmount + fee) && Number((loanEstimateOffer.loanAmount + fee).toFixed(2))
      };
    } else {
      return {
        type,
        amount: loanEstimateOffer.loanAmount,
        fee,
        total: loanEstimateOffer.loanAmount && Number(loanEstimateOffer.loanAmount.toFixed(2))
      };
    }
  }
  if (loanEstimateOffer.productType == "fha") {
    type = "mip";
    percentage = settings.fha.mip;
    const fee = (loanEstimateOffer.loanAmount * percentage / 100);
    if (loanEstimateOffer.financeMip) {
      return {
        type,
        amount: loanEstimateOffer.loanAmount,
        fee,
        total: !!(loanEstimateOffer.loanAmount + fee) && Number((loanEstimateOffer.loanAmount + fee).toFixed(2))
      };
    } else {
      return {
        type,
        amount: loanEstimateOffer.loanAmount,
        fee,
        total: loanEstimateOffer.loanAmount && Number(loanEstimateOffer.loanAmount.toFixed(2))
      };
    }
  }
  return { total: loanEstimateOffer.loanAmount && Number(loanEstimateOffer.loanAmount.toFixed(2)), fee: 0, amount: loanEstimateOffer.loanAmount, type };
}

export const createDefaultOfferEdge = (data?) => {
  const schemas = schemaVar();
  const defaultLoanEstimateOffer = getNullValues(schemas, "LoanEstimateOffer");
  return {
    node: {
      ...defaultLoanEstimateOffer,
      id: uuid(),
      objectIcon: null,
      objectName: "Offer 1",
      isValid: null,
      isDirty: null,
      isTitleFeeEditable: false,
      createdAt: new Date().toISOString(),
      ...data,
      __typename: "LoanEstimateOffer"
    } as LoanEstimateOffer,
    __typename: "LoanEstimateOfferEdge" as "LoanEstimateOfferEdge"
  };
};
