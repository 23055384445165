import React                                       from "react";
import { FC }                                      from "react";
import { useSource }                               from "@relcu/ui";
import { ButtonSizes }                             from "@relcu/ui";
import { ChipsColors }                             from "@relcu/ui";
import { ListItemChips }                           from "@relcu/ui";
import { classNames }                              from "@relcu/ui";
import { ListItemImage }                           from "@relcu/ui";
import { ListItemIcon }                            from "@relcu/ui";
import { ListItemText }                            from "@relcu/ui";
import { ListItemDate }                            from "@relcu/ui";
import { CircularLoader }                          from "@relcu/ui";
import { ListRow }                                 from "@relcu/ui";
import { Chips }                                   from "@relcu/ui";
import { Box }                                     from "@relcu/ui";
import { EmptyList }                               from "@relcu/ui";
import { TaskActions }                             from "@relcu/ui";
import { JsonViewProps }                           from "@relcu/ui";
import { ListRowClasses }                          from "@relcu/ui";
import { ReminderListViewClasses }                 from "./ReminderListViewClasses";
import { useReminderList }                         from "./useReminderList";
import "./reminder-list-view.css";
export const ReminderListView: FC<JsonViewProps> = React.memo(function ReminderListView(props) {
  const { $object } = useSource();
  const api = useReminderList(props);
  const { pageInfo, dividedData } = api.data;
  const {
    isEmpty,
    active,
    isExpired,
    status,
    setIsActive,
    onEdit,
    onSendSms,
    onCallBack,
    onMarkAsDone,
    onSnooze,
    isCallable,
    duplicateOf,
    onRowClick,
    contextSchemaHolder
  } = api;
  return (
    <>
      {contextSchemaHolder}
      <Box container direction={"column"} gap={"XXS"} flexGrow={1} className={ReminderListViewClasses.ReminderListView}
           ref={api.scrollContainerRef}>
        <Box container direction={"column"} flexGrow={1} gap={"XXS"}>
          {
            !isEmpty ?
              <>
                {dividedData.map((edge, index) => {
                  return (
                    Date.parse(edge) ?
                      <Chips disabled color={ChipsColors.Grey} key={index}
                             label={edge} alignSelf={"center"}/>
                      :
                      (() => {
                        const { node: taskNode } = edge;
                        return <ListRow key={index} gap={"XS"} justify={"space-between"}
                                        onClick={() => onRowClick(taskNode.subject)}
                                        className={classNames(
                                          ReminderListViewClasses.ReminderListItem,
                                          {
                                            [ ReminderListViewClasses.ReminderListItemActive ]: active == taskNode.objectId,
                                            [ ListRowClasses.Clickable ]: !!onRowClick
                                          })
                                        }>
                          <ListItemText
                            flexShrink={1}
                            flexBasis={150}
                            text={taskNode.title}
                            className={classNames({
                              [ ReminderListViewClasses.ReminderListItemDone ]: taskNode.done
                            })}
                          />
                          {
                            $object.__typename != taskNode.subject?.__typename &&
                            <Box container
                                 gap={"XXXS"}
                                 alignItems={"center"}
                                 className={ReminderListViewClasses.ReminderListItemSection} flexShrink={0}
                                 flexBasis={"180px"}>
                              <ListItemIcon type={taskNode.subject.__typename == "Lead" ? "rc_lead" : "rc_partner_exchange"}/>
                              <ListItemText
                                flex={"1 3 120px"}
                                text={taskNode.subject.objectName}
                              />
                            </Box>
                          }
                          <Box
                            container
                            gap={"XXXS"}
                            alignItems={"center"}
                            className={ReminderListViewClasses.ReminderListItemSection}
                            flexShrink={0} flexBasis={"150px"}

                          >
                            <ListItemImage icon={taskNode.assignedTo.objectIcon} text={taskNode.assignedTo.objectName}/>
                            <ListItemText
                              flex={"1 2 120px"}
                              text={taskNode.assignedTo.objectName}
                            />
                          </Box>
                          <ListItemDate
                            format="onlyTime"
                            className={classNames({
                              [ ReminderListViewClasses.ReminderListExpiredDate ]: isExpired(taskNode)
                            })}
                            flexBasis={60}
                            flexShrink={0}
                            date={taskNode.dueDate}
                          />
                          <ListItemChips flexShrink={0} flexBasis={80} value={status(taskNode)}
                                         color={isExpired(taskNode) ? ChipsColors.Error : ChipsColors.Grey}/>
                          <ListItemDate
                            flexBasis={80}
                            date={taskNode.createdAt}
                            format="withTime"
                          />
                          <TaskActions
                            disabled={duplicateOf}
                            done={taskNode.done}
                            contactDisabled={api.isNotContact}
                            size={ButtonSizes.Small}
                            setActive={active => setIsActive(active, taskNode)}
                            onEdit={e => onEdit(e, taskNode)}
                            onSnooze={time => onSnooze(taskNode, time)}
                            onSendSms={e => onSendSms(e, taskNode)}
                            onCallBack={e => onCallBack(e, taskNode)}
                            onMarkAsDone={e => onMarkAsDone(e, taskNode)}
                            isCallable={!isCallable(taskNode)}/>
                        </ListRow>;
                      })()
                  );
                })
                }
                {
                  pageInfo?.hasNextPage &&
                  <CircularLoader alignSelf={"center"} ref={api.loaderRef}/>
                }
              </>
              :
              <>
                <Box flex={"1 1 auto"} justify={"center"}/>
                <Box flex={"1 1 auto"} justify={"center"}>
                  <EmptyList title={`No tasks assigned to \n ${$object?.objectName}`}
                             content={`All tasks assigned to  \n ${$object?.objectName} will appear here`}
                             icon={props.icon} style={{ margin: "24px 0" }}/>
                </Box>
              </>
          }
        </Box>
      </Box>
    </>
  );
});

export default ReminderListView;
