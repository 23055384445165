import React                        from "react";
import { createContext }            from "react";
import { TypeAttributes }           from "../@types/common";
import { RsRefForwardingComponent } from "../@types/common";
import { AccordionProps }           from "../Accordion";
import { CellGroupHeader }          from "../index";
import { Accordion }                from "../index";
import { useClassNames }            from "../utils";

export interface CellGroupProps extends AccordionProps {
  /** A tag can have different colors */
  color?: TypeAttributes.Alert;

  span?: number;

  disabled?: boolean;

  /** The content of the component */
  children?: React.ReactNode;

  level?: 1 | 2 | 3;
}

export const CellGroupContext = createContext(null);

export const CellGroup: RsRefForwardingComponent<"div", CellGroupProps> = React.forwardRef((props: CellGroupProps, ref) => {
  const {
    as: Component = "div",
    className,
    children,
    classPrefix = "cell-group",
    header = <CellGroupHeader/>,
    level = 1,
    headerBordered,
    disabled,
    ...rest
  } = props;

  const { withClassPrefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix({ disabled })
  );

  return <Accordion header={header} className={classes} headerProps={{ headerBordered }}
                    style={{ "--rc-padding-level": level } as any} {...rest}>
    <CellGroupContext.Provider value={{ disabled }}>
      {children}
    </CellGroupContext.Provider>
  </Accordion>;
});
