import React           from "react";
import { FC }          from "react";
import { classNames }  from "../../../utils/classNames";
import { Box }         from "../../Box";
import { useSwitch }   from "./useSwitch";
import { SwitchProps } from "./useSwitch";

export interface ToggleCombinatorProps extends SwitchProps {
  options?: { name?: string, label: string }[];
}

export const ToggleCombinator: FC<ToggleCombinatorProps> = React.memo(function ToggleCombinator(props) {
  const { isChecked, name, disabled, opts } = useSwitch(props);
  const classes = classNames(ToggleCombinatorClasses.ToggleCombinator, {
    [ ToggleCombinatorClasses.Disabled ]: disabled,
    [ ToggleCombinatorClasses.Revers ]: isChecked
  });

  return <label style={{ display: "inline-flex" }}>
    <input type="checkbox" name={name} checked={isChecked}
           disabled={disabled} {...opts}/>
    <Box className={classes}>
      <Box className={ToggleCombinatorClasses.ToggleCircle}/>
      {/*<p className={ToggleCombinatorClasses.ToggleText}>{texts[Number(isChecked)]}</p>*/}{/*todo second animation*/}
      <p className={
        classNames(ToggleCombinatorClasses.ToggleTextLeft, {
          [ ToggleCombinatorClasses.ToggleTextHidden ]: isChecked
        })}>{props.options[ 0 ].label}</p>
      <p className={
        classNames(ToggleCombinatorClasses.ToggleTextRight, {
          [ ToggleCombinatorClasses.ToggleTextHidden ]: !isChecked
        })}>{props.options[ 1 ].label}</p>
    </Box>
  </label>;
});

ToggleCombinator.defaultProps = {
  options: [
    {
      "name": "and",
      "label": "AND"
    },
    {
      "name": "or",
      "label": "OR"
    }
  ]
};

export enum ToggleCombinatorClasses {
  ToggleCombinator = "toggle-combinator",
  Revers = "toggle-combinator--revers",
  ToggleCircle = "toggle-combinator__circle",
  Disabled = "toggle-combinator--disabled",
  ToggleTextLeft = "toggle-combinator__text--left",
  ToggleTextHidden = "toggle-combinator__text--hidden",
  ToggleTextRight = "toggle-combinator__text--right",
}