import React                                         from "react";
import { FC }                                        from "react";
import { DialList }                                  from "../../../DialList/DialList";
import { DialListItem }                              from "../../../DialList/DialListItem";
import { CallReplayVoiceTemplates_recordings_edges_node } from "./__types__/CallReplayVoiceTemplates";

export const CallReply: FC<{
  templates: CallReplayVoiceTemplates_recordings_edges_node[],
  send: (template: CallReplayVoiceTemplates_recordings_edges_node) => void
}> = React.memo(function CallReply({ templates, send }) {

  return (
    templates.length > 0 &&
    <DialList header={"Send a voice message"}>
      {
        templates.map(template => {
          return <DialListItem key={template.id} onClick={() => send(template)}>
            {template.name}
          </DialListItem>;
        })
      }
    </DialList>
  );
});
