import React                   from "react";
import { FC }                  from "react";
import { useMemo }             from "react";
import { useState }            from "react";
import { BoxComponentProps }   from "../../../../../..";
import { classNames }          from "../../../../../..";
import { Box }                 from "../../../../../Box";
import { Select }              from "../../../../../Input/Select";
import { SoundSectionClasses } from "../SoundSectionClasses";
import { AudioController }     from "./AudioController";

export const Devices: FC<DevicesProps> = React.memo(function Devices(props) {
  const { className, inputId, devices, outputId, onInput, onOutput, ...p } = props;
  const classes = classNames(SoundSectionClasses.Devices, className);
  const [canChangeMic, setCanChangeMic] = useState(true);
  const [canChangeSpeak, setCanChangeSpeak] = useState(true);
  const selectedInput = useMemo(() => devices.audioinput.find(d => d.deviceId == inputId), [inputId, devices]);
  const selectedOutput = useMemo(() => devices.audiooutput.find(d => d.deviceId == outputId), [outputId, devices]);
  function setOutput(device) {
    if (device) {
      onOutput?.(device);
    }
  }
  function setInput(device) {
    if (device) {
      onInput?.(device);
    }
  }

  return (
    <Box container flexShrink={1} justify={"space-between"} className={classes} gap={"M"} {...p}>
      <Box container direction={"column"} gap={"XS"}>
        <Select
          disabled={!canChangeMic || !devices.audioinput.length}
          placeholder={"Select microphone"}
          label="Microphone"
          icon="mic"
          value={selectedInput}
          onChange={setInput}
          options={devices.audioinput}
          optionLabel={"label"}
          optionKey={"deviceId"}/>
        <Select
          disabled={!canChangeSpeak || !devices.audiooutput.length}
          placeholder={"Select speaker"}
          label="Speaker"
          icon="speaker"
          value={selectedOutput}
          onChange={setOutput}
          options={devices.audiooutput}
          optionLabel={"label"}
          optionKey={"deviceId"}/>
      </Box>
      <AudioController
        disabled={!devices.audiooutput.length}
        onListen={(play) => setCanChangeSpeak(play)}
        onRecord={(record) => setCanChangeMic(record)}
        inputDeviceId={inputId}
        outputDeviceId={outputId}
      />
    </Box>
  );
});
export interface DevicesProps extends BoxComponentProps{
  className?: string
  outputId: string
  inputId: string
  devices: {
    audioinput: any[]
    audiooutput: any[]
  }
  onOutput(d)
  onInput(d)
}
