import React             from "react";
import { ButtonProps }   from "../Button";
import { useClassNames } from "../utils";

export type ToggleSelectProps = ButtonProps & {};

export const ToggleSelect = React.forwardRef<HTMLDivElement, ToggleSelectProps>((props, ref) => {
  const { className, size, active,...rest } = props;
  const { withClassPrefix, merge } = useClassNames("toggle-select");
  const classes = merge(className, withClassPrefix(size, active));
  return <div {...rest} className={classes} ref={ref}/>;
});

ToggleSelect.displayName = "ToggleSelect";

export default ToggleSelect;

