import { CircularLoader, Box, EmptyList } from "@relcu/ui";
import React                              from "react";
import { FC }                             from "react";
import "./lead-queue.css";

export const EmptyQueue: FC<{ role: string, loading?: boolean, collapsed: boolean }> = React.memo(function EmptyQueue({ role, collapsed, loading }) {
  return <>
    {loading ? <Box container direction={"column"} gap={"XXXS"} alignItems={"center"}> <CircularLoader/> </Box>
      : !collapsed && <div className={LeadQueueClasses.Empty}>
      <EmptyList
        justify={"center"}
        flexBasis={"300px"}
        title={"No available queues"}
        content={role === "admin"
          ? <p>
            To define queues go to:<br/>Settings {">"} Distribution queues
          </p>
          : "Contact to admin to set queues"}
        icon={"clear"}/>
    </div>
    }
  </>;

});

export const enum LeadQueueClasses {
  Empty = "lead-queue--empty",
  EmptyIcon = "lead-queue__icon--empty",
  EmptyTitle = "lead-queue__title--empty",
  EmptyDescription = "lead-queue__description--empty",
  CollapsedState = "lead-queues__collapsed"
}
