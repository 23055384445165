import { useEffect }       from "react";
import React               from "react";
import { FC }              from "react";
import { useState }        from "react";
import { Stack }           from "@relcu/rc";
import { usePhone }        from "../../../usePhone";
import { CallTransfer }    from "../CallTransfer/CallTransfer";
import { CallControllers } from "./CallControllers";
import { ManageHeader }    from "./ManageHeader";


export interface ManageCallProps {
  onCall
}
export const ManageCall: FC<ManageCallProps> = React.memo(function ManageCall(props) {
  const [callState, setCallState] = useState<"conference" | "transfer">(null);
  const { hybridCalls } = usePhone();

  return <Stack
    spacing={24}
    style={{ flex: 1, alignSelf: "stretch" }}
    direction={"column"}
    alignItems={"stretch"}
    justifyContent={"flex-start"}>
    <ManageHeader onBack={callState ? () => setCallState(null) : null}>
      {callState ? "Warm transfer" : "Add call"}
    </ManageHeader>
    {
      callState ?
        <CallTransfer calls={hybridCalls?.activeCalls} onCall={props.onCall}/> :
        <CallControllers onSelect={(state) => setCallState(state)}/>
    }
  </Stack>;
});
