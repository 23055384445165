import React                        from "react";
import { FC }                       from "react";
import { MutableRefObject }         from "react";
import { classNames }               from "../../../utils/classNames";
import { Box }                      from "../../Box";
import { BoxComponentProps }        from "../../Box";
import { CircularLoader }           from "../../Loader";
import { RecipientListContainer }   from "./RecipientListContainer";
import { RecipientListClasses }     from "./RecipientListClasses";
import { Typography }               from "../../Typography";
import { TypographyVariant }        from "../../Typography";
import { TypographyWeight }         from "../../Typography";
import "./recipient-list.css"

export interface RecipientListProps extends BoxComponentProps {
  header?: React.ReactNode;
  hasNextPage?: boolean;
  loaderRef?: MutableRefObject<any>;
  containerRef?: MutableRefObject<any>;
}

export const RecipientList: FC<RecipientListProps> = React.memo(function RecipientList(props) {
  const {className, children, header, hasNextPage, containerRef, loaderRef, ...p} = props;
  const classes = classNames(RecipientListClasses.List, className);

  return <Box container direction={"column"} className={classes} gap={"XXS"} {...p}>
    <Typography
      variant={TypographyVariant.h3}
      weight={TypographyWeight.Medium}
      className={RecipientListClasses.ListTitle}
    >
      {header}
    </Typography>
    {
      <RecipientListContainer ref={containerRef}>
        { children }

        {loaderRef && containerRef &&
          (hasNextPage && <CircularLoader alignSelf={"center"} ref={loaderRef}/>)
        }
      </RecipientListContainer>
    }
  </Box>;
});
