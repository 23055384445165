import React             from "react";
import { FC }            from "react";
import { useEffect }     from "react";
import { useRef }        from "react";
import { useVisualizer } from "./useVisualizer";

export interface VisualizerProps {
  stream?: any
  dataArray: Uint8Array
}
export const Visualizer: FC<VisualizerProps> = React.memo(function Visualizer(props) {
  const { canvasRef } = useVisualizer(props.dataArray);
  return <canvas ref={canvasRef} width={110} height={15} style={{ background: "white" }}/>;
});

export const DisabledVisualizer: FC = React.memo(function Visualizer(props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D>(null);

  useEffect(() => {
    const bufferLength = 16;
    const canvas = canvasRef.current;
    const radius = 2;
    // const dpr = window.devicePixelRatio || 1;
    // canvas.width = canvas.width * dpr;
    // canvas.height = (canvas.height * 2) * dpr;//todo do not remove this(thoughts for the future)
    canvas.height = (canvas.height * 2);
    const centerY = canvas?.height / 2;
    const gap = (canvas?.width - (bufferLength * radius)) / (bufferLength + 1);
    let x = gap;
    contextRef.current = canvas.getContext("2d");
    contextRef.current?.clearRect(0, 0, canvas?.width, canvas?.height);
    contextRef.current.fillStyle = "#92c353";
    for (let i = 0; i < bufferLength; i++) {
      contextRef.current.beginPath();
      contextRef.current.arc(x, centerY, radius, 0, 2 * Math.PI, false);
      contextRef.current.fill();
      x += radius + gap;
    }
  }, [contextRef, canvasRef]);

  return <canvas ref={canvasRef} width={110} height={15} style={{ background: "white" }}/>;
});


