import { FC }                        from "react";
import { ReactNode }                 from "react";
import React, { useEffect }          from "react";
import { BoxProps }                  from "../..";
import { BoxComponentProps, Portal } from "../..";
import { FontIcon }                  from "../..";
import { classNames }                from "../..";
import { Backdrop }                  from "../Backdrop";
import { Box }                       from "../Box";

export interface ModalProps extends Omit<BoxProps, "title"> {
  title?: ReactNode | string,
  open: boolean,
  disableBackdropClick?: boolean,
  disableEscapeKeyDown?: boolean,
  onClose: () => void,
  afterClose?: Function
  variant?: "small" | "middle" | "big" | "large" | "giant"
  top?: string
  style?: any
  className?: any
  closeIcon?: boolean
  modalProps?: object
}

export const Modal: FC<ModalProps> = React.memo(function Modal(props) {
  const {
    disableBackdropClick,
    disableEscapeKeyDown,
    className,
    open,
    title,
    variant,
    top = "auto",
    children,
    onClose,
    closeIcon,
    style,
    modalProps,
    ...p
  } = props;

  const handleOnBackgroundClick = (e) => {
    if (!disableBackdropClick) {
      onClose();
    }
  };

  const escPressed = (event) => {
    if (event.keyCode === 27) {
      onClose?.();
    }
  };

  useEffect(() => {
    if (!disableEscapeKeyDown) {
      document.addEventListener("keydown", escPressed, false);
      return () => {
        document.removeEventListener("keydown", escPressed, false);
      };
    }
  }, [disableEscapeKeyDown]);

  useEffect(() => {
    const app = document.querySelector("#app");
    if (app && open) {
      app.setAttribute("inert", "true");
    }

    return () => {
      if (app) {
        app.removeAttribute("inert");
      }
    };
  }, [open]);

  return (open && <Portal>
    <dialog className={classNames(ModalClasses.Modal)} {...modalProps}>
      <Backdrop onClick={handleOnBackgroundClick} open={open}/>
      <Box container direction={"column"}
           style={{ ...style, top }}
           className={classNames(ModalClasses.ModalContainer, {
             [ ModalClasses.Small ]: variant === "small",
             [ ModalClasses.Large ]: variant === "large",
             [ ModalClasses.Meddle ]: variant === "middle",
             [ ModalClasses.Big ]: variant === "big",
             [ ModalClasses.Giant ]: variant === "giant",
             [ ModalClasses.Sticky ]: top !== "auto"
           }, className)} {...p}>
        {
          title &&
          <Box container justify={"space-between"} className={ModalClasses.ModalHeader}>
            <Box container flexGrow={1} gap={"XXS"}>
              <h2>
                {title}
              </h2>
            </Box>
            {
              closeIcon && <FontIcon type={"clear"} onClick={() => props.onClose()}/>
            }
          </Box>
        }
        {children}
      </Box>
    </dialog>
  </Portal>);
});

export interface ModalFooterProps extends BoxComponentProps {
  borderOnTop?: boolean;
}

export const ModalFooter: FC<ModalFooterProps> = React.memo(function ModalFooter(props) {
  const { className, children, borderOnTop, ...p } = props;
  const classes = classNames(ModalClasses.ModalFooter, {
    [ ModalClasses.FooterBordered ]: borderOnTop
  }, className);
  return <Box className={classes} container justify="end" alignItems="end" gap="XXS" {...p}>
    {children}
  </Box>;
});

export const ModalBody: FC<BoxComponentProps> = React.memo(function ModalBody(props) {
  const { className, ...p } = props;
  const classes = classNames(ModalClasses.ModalBody, className);
  return <Box className={classes} {...p}/>;
});

Modal.defaultProps = {
  open: false,
  closeIcon: true,
  variant: "middle",
  disableBackdropClick: false,
  disableEscapeKeyDown: false
};

export enum ModalClasses {
  Modal = "modal",
  Small = "modal--small",
  Large = "modal--large",
  Big = "modal--big",
  Giant = "modal--giant",
  Meddle = "modal--middle",
  Sticky = "modal--sticky",
  ModalContainer = "modal-container",
  ModalHeader = "modal-header",
  ModalFooter = "modal-footer",
  FooterBordered = "modal-footer--bordered",
  ModalBody = "modal-body",
}
