import React                from "react";
import { FC }               from "react";
import { ButtonVariants }   from "@relcu/ui";
import { ButtonSizes }      from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { IActionCellProps } from "@relcu/ui";

export const SeePhoneMessageAction: FC<IActionCellProps> = React.memo(function SeePhoneMessageAction(props) {
  const { rowData, disabled, tooltip, path, bulkPermissions, onClick, ...p } = props;
  return (
    ["delivered", "sent", "processed"].includes(rowData.status) &&
    <Button
      size={ButtonSizes.Small}
      variant={ButtonVariants.Ghost}
      onClick={(e) => onClick(e, rowData)}
      disabled={rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length}
      {...p}>
      See SMS
    </Button>
  );
});
