import { ListRow }         from "@relcu/ui";
import { classNames }      from "@relcu/ui";
import { DraggableItem }   from "@relcu/ui";
import React               from "react";
import { FC }              from "react";
import { useMemo }         from "react";
import { useState }        from "react";
import { TypographyColor } from "@relcu/ui";
import { TypographySize }  from "@relcu/ui";
import { Typography }      from "@relcu/ui";
import { ListItemText }    from "@relcu/ui";
import { Alignment }       from "@relcu/ui";
import { SearchInput }     from "@relcu/ui";
import { Tooltip }         from "@relcu/ui";
import { IconType }        from "@relcu/ui";
import { ListItemIcon }    from "@relcu/ui";
import { CommonClasses }   from "@relcu/ui";
import { Box }             from "@relcu/ui";
import { ButtonVariants }  from "@relcu/ui";
import { ButtonSizes }     from "@relcu/ui";
import { Button }          from "@relcu/ui";
import { DropResult }      from "react-smooth-dnd";
import { Draggable }       from "react-smooth-dnd";
import { Container }       from "react-smooth-dnd";
import { Column }          from "../../../../../DroppableColumn/Column";
import { ColumnContainer } from "../../../../../DroppableColumn/ColumnContainer";
import { DroppableRows }   from "../../../../../DroppableColumn/DroppableRows";
import { ColumnsClasses }  from "../Columns";

export interface ShareColumn {
  items: any,
  title: string
  emptyTitle?: string
  emptyDescription?: string
  columnName: string
  onChange: (drop: DropResult) => void,
  button: string,
  buttonClick: () => void,
}

const icons = {
  "available": "add",
  "selected": "remove"
};
export const ShareColumn: FC<ShareColumn> = React.memo(function ShareColumn(props) {
  const { items, title, columnName, onChange, button, buttonClick, emptyTitle, emptyDescription } = props;
  const [searchedValue, setSearchedValue] = useState("");

  const showedItems = useMemo(() => {
    const val = searchedValue?.trim()?.toLowerCase();
    if (!val) {
      return items;
    }

    const filtered = items.filter((item) => {
      const name = item.objectName?.toLowerCase();
      const team = item.team?.toLowerCase();
      const includeTag = item.tags?.some(t => {
        const tag = t.toLowerCase();
        return tag.includes(val);
      });
      return name?.includes(val) || team?.includes(val) || includeTag;
    });
    return filtered?.map(i => {
      const { tags } = i;
      if (tags.length) {
        const includes = tags.filter(t => t?.toLowerCase().includes(searchedValue?.trim()?.toLowerCase()));
        const notIncludes = tags.filter(t => !t?.toLowerCase().includes(searchedValue?.trim()?.toLowerCase()));
        return {
          ...i, tags: [...includes, ...notIncludes]
        };
      }
      return i;
    });
  }, [searchedValue, items]);

  return <ColumnContainer flex={1} title={title}>
    <SearchInput className={ColumnsClasses.ColumnSearch} value={searchedValue} placeholder={"Search here"}
                 onChange={setSearchedValue}/>
    <Box container justify={"end"}>
      <Button disabled={!items.length} size={ButtonSizes.Small} variant={ButtonVariants.Ghost}
              onClick={buttonClick}>{button}</Button>
    </Box>
    {
      <div style={{ overflowY: "auto" }}>
        <Container
          autoScrollEnabled
          shouldAcceptDrop={(sourceContainerOptions, payload) => payload.from != props.columnName}
          orientation={"vertical"}
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
          groupName="push-col"
          dragHandleSelector=".column-drag-handle"
          onDrop={onChange}
          getChildPayload={index => ({ ...showedItems[ index ], from: props.columnName })}
          dragClass="card-ghost"
          dropClass="card-ghost-drop"
          dropPlaceholder={{
            showOnTop: true,
            className: "drop-preview"
          }}>
          {
            showedItems.length ?
              showedItems?.map((item, index) => <Draggable
                  key={item.objectId}
                  className={ColumnsClasses.ColumnRowDraggable}>
                  <ListRow>
                    <ListItemIcon type={"drag_indicator"}
                                  className={classNames(ColumnsClasses.ColumnDraggableIcon, "prevent-reset", "column-drag-handle")}/>
                    <ListItemText text={item.objectName} flex={1}/>
                    <Tooltip title={"Team"} alignment={Alignment.Top}>
                      <ListItemText text={item.team} flex={1} style={{ textAlign: "center" }}/>
                    </Tooltip>
                    {/*<Tooltip title={"Tag"} alignment={Alignment.Top}>*/}
                    {/*  <ListItemTags tags={item.tags} flex={1} style={{ justifyContent: "center" }}/>*/}
                    {/*</Tooltip>*/}
                    {/*<ItemsEllipsis*/}
                    {/*  items={item.data.tags}*/}
                    {/*  showLength={1}*/}
                    {/*  renderItem={(item, index) => <Chips*/}
                    {/*    key={index}*/}
                    {/*    color={ChipsColors.Grey}*/}
                    {/*    size={ChipsSizes.Small}*/}
                    {/*    thumbnail={<Badge variant={BadgeVariants.Dot} color={BadgeColors.Primary}/>}*/}
                    {/*  />}*/}
                    {/*  ellipsis={(count) => <React.Fragment>{`+${count}`}</React.Fragment>}/>*/}
                    <ListItemIcon type={icons[ columnName ] as IconType} className={CommonClasses.ClickableIcon}
                                  onClick={() => onChange(item)}/>
                  </ListRow>
                </Draggable>
              )
              :
              <Box container direction={"column"} alignItems={"center"} justify={"center"} flex={1} gap={"XXS"}>
                {
                  emptyTitle &&
                  <Typography>{emptyTitle}</Typography>
                }
                {
                  emptyDescription &&
                  <Typography size={TypographySize.TextSmall}
                              color={TypographyColor.Secondary}>{emptyDescription}</Typography>
                }
              </Box>
          }
        </Container>
      </div>
    }
    {/*<Column container style={{ overflow: "scroll" }} direction={"column"} gap={"XXS"} name={columnName}>*/}
    {/*  {*/}
    {/*    showedItems.length ?*/}
    {/*      showedItems?.map((item, index) => <DroppableRows*/}
    {/*          key={item.objectId}*/}
    {/*          name={item.objectId}*/}
    {/*          column={columnName}*/}
    {/*          index={index}*/}
    {/*          data={item.data}*/}
    {/*          className={ColumnsClasses.ColumnRowDraggable}*/}
    {/*          gap={"XXS"}*/}
    {/*          changeItemColumn={(currentItem, name) => {*/}
    {/*            if (name !== columnName) {*/}
    {/*              onChange(item);*/}
    {/*            }*/}
    {/*          }*/}
    {/*          }>*/}
    {/*          <ListItemIcon type={"drag_indicator"} className={ColumnsClasses.ColumnDraggableIcon}/>*/}
    {/*          <ListItemText text={item.objectName} flex={1}/>*/}
    {/*          <Tooltip title={"Team"} alignment={Alignment.Top}>*/}
    {/*            <ListItemText text={item.team} flex={1} style={{ textAlign: "center" }}/>*/}
    {/*          </Tooltip>*/}
    {/*          /!*<Tooltip title={"Tag"} alignment={Alignment.Top}>*!/*/}
    {/*          /!*  <ListItemTags tags={item.tags} flex={1} style={{ justifyContent: "center" }}/>*!/*/}
    {/*          /!*</Tooltip>*!/*/}
    {/*          /!*<ItemsEllipsis*!/*/}
    {/*          /!*  items={item.data.tags}*!/*/}
    {/*          /!*  showLength={1}*!/*/}
    {/*          /!*  renderItem={(item, index) => <Chips*!/*/}
    {/*          /!*    key={index}*!/*/}
    {/*          /!*    color={ChipsColors.Grey}*!/*/}
    {/*          /!*    size={ChipsSizes.Small}*!/*/}
    {/*          /!*    thumbnail={<Badge variant={BadgeVariants.Dot} color={BadgeColors.Primary}/>}*!/*/}
    {/*          /!*  />}*!/*/}
    {/*          /!*  ellipsis={(count) => <React.Fragment>{`+${count}`}</React.Fragment>}/>*!/*/}
    {/*          <ListItemIcon type={icons[ columnName ] as IconType} className={CommonClasses.ClickableIcon}*/}
    {/*                        onClick={() => onChange(item)}/>*/}
    {/*        </DroppableRows>*/}
    {/*      ) :*/}
    {/*      <Box container direction={"column"} alignItems={"center"} justify={"center"} flex={1} gap={"XXS"}>*/}
    {/*        {*/}
    {/*          emptyTitle &&*/}
    {/*          <Typography>{emptyTitle}</Typography>*/}
    {/*        }*/}
    {/*        {*/}
    {/*          emptyDescription &&*/}
    {/*          <Typography size={TypographySize.TextSmall}*/}
    {/*                      color={TypographyColor.Secondary}>{emptyDescription}</Typography>*/}
    {/*        }*/}
    {/*      </Box>*/}
    {/*  }*/}
    {/*</Column>*/}
  </ColumnContainer>;
});
