import React                           from "react";
import { FC }                          from "react";
import { Recipient }                   from "@relcu/rc";
import { Typography }                  from "@relcu/rc";
import { CircularLoader }              from "@relcu/ui";
import { getDefaultPhoneNumberForSMS } from "../../../utils/helpers";
import { getBorrowerName }             from "../../../utils/helpers";
import { getDefaultEmailAddress }      from "../../../utils/helpers";
import { getPrimaryBorrower }          from "../../../utils/helpers";
import { useBulkRecipientList }        from "./useBulkRecipientList";
import "./bulk-recipient-list.css";

export interface RecipientSectionProps {
  selected: any;
  type?: "mail" | "sms";
  onSelect(lead: object);
  find?: any;
  query: {
    where: any
    order: string[]
  };
}

export const BulkRecipientList: FC<RecipientSectionProps> = React.memo(function RecipientSection(props) {
  const { query, selected, onSelect, type, find, ...p } = props;
  const { data: { pageInfo, edges }, scrollContainerRef, loaderRef, selectedId, getInfo } = useBulkRecipientList({ query, selected, onSelect, type, find });

  return (
    <>
      <Typography className={"bulk-recipient-list-header"} variant={"base16"}>Recipients</Typography>
      {
        <div ref={scrollContainerRef}
             className={"bulk-recipient-list"}>
          {
            edges?.map(({ node }, index) => {
              const primaryBorrower = node.__typename == "Lead" ? getPrimaryBorrower(node) : node;
              const borrowerName = getBorrowerName(primaryBorrower);
              const phone = getDefaultPhoneNumberForSMS(primaryBorrower.contact?.phones || [], "Contact");
              const emailAddress = getDefaultEmailAddress(primaryBorrower.contact?.emails || []);
              const isSelected = (selectedId == node.id);

              return <Recipient
                key={index}
                info={node.__typename == "Lead" && getInfo(node)}
                objectName={borrowerName}
                hint={type == "mail" ? emailAddress?.address : phone?.number}
                active={isSelected}
                onClick={() => onSelect(node)}
              />;
            })
          }

          {loaderRef && scrollContainerRef &&
            (pageInfo?.hasNextPage && <CircularLoader alignSelf={"center"} ref={loaderRef}/>)
          }
        </div>
      }
    </>
  );
});

BulkRecipientList.defaultProps = {
  type: "mail"
};

