import { Property }          from "csstype";
import React                 from "react";
import { useMemo }           from "react";
import { MutableRefObject }  from "react";
import { useLayoutEffect }   from "react";
import { useState }          from "react";
import { KeyboardEvent }     from "react";
import { classNames }        from "../..";
import { Keys }              from "../..";
import { BoxComponentProps } from "../..";
import { MenuItem }          from "../..";

export interface MenuProps extends BoxComponentProps {
  height?: Property.Height<any>
  width?: Property.Width<any>
  autoFocus?: boolean
  type?: "button" | "select"
  withThumbnail?: boolean
  searchable?: boolean
  open?: boolean
}

const defaultMenuProps: MenuProps = {
  height: 40,
  type: "button",
  withThumbnail: false,
  autoFocus: true
};

export const Menu = React.forwardRef(function Menu(props: MenuProps, ref: MutableRefObject<any>) {
  const { className, children, height, width, style, type, withThumbnail, open, autoFocus, searchable, container = true, direction = "column", ...p } = props;
  const classes = classNames(MenuClasses.Menu, {
    [ MenuClasses.ButtonMenu ]: (type == "button"),
    [ MenuClasses.SearchableMenu ]: searchable,
    [ MenuClasses.SelectMenu ]: (type == "select"),
    [ MenuClasses.WithThumbnail ]: withThumbnail
  }, className);
  const [index, setIndex] = useState(-1);
  let nodes = useMemo(() => [], [children]);

  const handleKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    switch (key) {
      case Keys.ArrowDown:
        // Prevent scroll of the page
        event.preventDefault();
        if ((index + 1) < nodes.length) {
          nodes[ index + 1 ].focus();
          setIndex(index + 1);
        }
        break;
      case Keys.ArrowUp:
        event.preventDefault();
        if (index > 0) {
          nodes[ index - 1 ].focus();
          setIndex(index - 1);
        }
        break;
      case Keys.Enter:
        if (event.keyCode === 229) {
          // ignore the keydown event from an Input Method Editor(IME)
          // ref. https://www.w3.org/TR/uievents/#determine-keydown-keyup-keyCode
          break;
        }
        // if (!refs[ activeItemIndex ].current.classList.contains("disabled")) {}
        if (index >= 0) {
          setIndex(index);
          nodes[ index ].click();
        }
    }
  };

  const recursiveMap = (children, props): any => {
    return React.Children.map(children, (child: any, index) => {
      if (child?.type === MenuItem || child?.type?.render?.name === MenuItem) {
        return React.cloneElement(child, {
          ref: (node) => {
            if (node && !nodes.includes(node)) {
              nodes.push(node);
            }
            return node;
          }
        });
      } else {
        if (child?.props?.children) {
          child = React.cloneElement(child, {
            children: recursiveMap(child.props.children, props)
          });
        }
      }
      return child;
    });
  };

  useLayoutEffect(() => {
    if (autoFocus) {
      ref?.current.focus();
    }
  }, [ref?.current]);
  return <ul
    className={classes}
    ref={ref}
    style={{
      maxHeight: (typeof height == "number" ? height * 4 : height),
      maxWidth: (typeof width == "number" ? width * 4 : width),
      overflowY: ("overlay" as any),
      ...style
    }}
    onKeyDown={handleKeyDown}
    tabIndex={0}
    {...p}
  >
    {
      recursiveMap(children, {})
    }
  </ul>;
});

export enum MenuClasses {
  Menu = "menu",
  ButtonMenu = "button-menu",
  SelectMenu = "select-menu",
  SearchableMenu = "searchable-menu",
  MenuItem = "menu__item",
  MenuItemContent = "menu-item-content",
  MenuItemIcon = "menu-item-icon",
  Disabled = "disabled",
  Selected = "selected",
  WithThumbnail = "with-thumbnail",
  SubMenu = "sub-menu",
  SubMenuItem = "sub-menu-item",
  SubMenuLeft = "sub-menu-left",
  SubMenuRight = "sub-menu-right",
}

Menu.defaultProps = defaultMenuProps;

