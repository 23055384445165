import { useContext }   from "react";
import { RateContext }  from "../Rate/RateProvider";
import { RatesContext } from "../RatesProvider";

export function usePmi() {
  const { selectedPmi: pmi } = useContext(RateContext);
  const {
    pmiResult: {
      loading,
      result,
      isPmiEligible,
      refetch,
      error
    }
  } = useContext(RatesContext);
  return {
    get pmiResult() {
      return result;
    },
    error,
    loading,
    refetch,
    isPmiEligible,
    option: pmi?.option,
    get pmi() {
      return pmi;
    },
    get monthlyPmi() {
      return pmi?.option == "monthly" ? pmi : null;
    }
  };
}
