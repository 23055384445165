import { Box }            from "@relcu/ui";
import { CommonClasses }  from "@relcu/ui";
import { FontIcon }       from "@relcu/ui";
import { TypographySize } from "@relcu/ui";
import { Typography }     from "@relcu/ui";
import React              from "react";
import { SidebarClasses } from "./SidebarClasses";

export interface TableSidebarHeaderProps {
  title: string
  onClose: () => void
}

export const SidebarHeader = React.memo<TableSidebarHeaderProps>(function TableSidebarHeader({ title, onClose }) {
  return (
    <Box
      container
      justify={"space-between"}
      alignItems={"center"}
      className={SidebarClasses.Heading}>
      <Typography size={TypographySize.SubtitleSmall}>{title} views</Typography>
      <FontIcon className={CommonClasses.ClickableIcon} onClick={onClose} type={"clear"}/>
    </Box>
  );
});
