import { UseFieldConfig }    from "@relcu/final-form";
import { formatNumber }      from "@relcu/ui";
import { useNumberUtils }    from "@relcu/ui";
import { useCallback }       from "react";
import { FC }                from "react";
import React, { useContext } from "react";
import { InputProps }        from "../Input";
import Input                 from "../Input";
import { FormField }         from "./FormField";
import { FormInputProps }    from "./FormInput";

export interface FormInputNumberProps<ValueType = any> extends FormInputProps {
  precision?: number;
}

export const FormInputNumber: FC<FormInputNumberProps> = React.memo((props) => {
  const { size, properties, precision = 0, ...rest } = props;
  const { parse, format } = useInputNumber(props);

  return (
    <FormField<FormInputProps>
      {...rest}
      component={InputNumber}
      format={format}
      parse={parse}
      properties={{ size, ...properties }}
    />
  );
});

export interface UseInputNumberProps extends UseFieldConfig<any> {
  precision?: number;
}

export const useInputNumber = (props: UseInputNumberProps) => {
  const { precision = 2, ...config } = props;
  const { clean, parse: parseUtil, toFixedNoRounding } = useNumberUtils(config);
  const parse = (value, name) => {
    const str = String(value);
    if (value && precision && str.indexOf(".") < 0) {
      const end = str.substring(str.length - precision, str.length);
      const start = str.substring(0, str.length - precision);
      if (start && end) {
        value = [start, end].join(".");
      }
    }
    value = clean(value);
    if (!value) {
      return parseUtil(null, name);
    }
    if (precision || precision === 0) {
      value = toFixedNoRounding(value, precision);
    }

    const parsedNumber = parseUtil(Number(value), name);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
  };
  const format = (value, name) => {
    value = clean(value);
    if (!value) {
      return null;
    }
    return formatNumber(value, precision);
  };
  return { parse, format };
};

const InputNumber = React.forwardRef<React.Ref<HTMLInputElement>, InputProps>((props, ref) => {
  const onChange = useCallback((value, event) => {
    let cursor = event.target.selectionEnd;
    props.onChange(value, event);
    event.persist();
    setTimeout(() => {
      if (String(event.target.value).includes(",")) {
        const oldLength = (String(props.value).match(/,/g) || []).length;
        const newLength = (String(event.target.value).match(/,/g) || []).length;
        if (oldLength !== newLength) {
          cursor += 1;
        }
      }
      event.target.selectionStart = cursor;
      event.target.selectionEnd = cursor;
      event.target.focus();
    });
  }, [props.onChange, props.value]);
  return <Input {...props} onChange={onChange} ref={ref}/>;
});

FormInputNumber.displayName = "FormNumber";
