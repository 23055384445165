import React                    from "react";
import { FC }                   from "react";
import { useEffect }            from "react";
import { useState }             from "react";
import { useRef }               from "react";
import { useMemo }              from "react";
import { pad }                  from "../../../utils/helpers";
import { Box }                  from "../../Box";
import { Button }               from "../../Button";
import { EmptyList }            from "../../EmptyList";
import { ModalProps }           from "../Modal";
import { ModalBody }            from "../Modal";
import { Modal }                from "../Modal";
import { OfflineDialogClasses } from "./OfflineDialogClasses";

export interface OfflineDialogProps extends Partial<ModalProps> {
  data?: {
    attempts: number,
    duration: number,
    limit: number,
  }
  timestamp?: number,
  type?: "disconnected" | "connected" | "connecting" | "reconnected" | "reconnecting"
  onClick: () => void
  onClose?(e?),
}
export const OfflineDialog: FC<OfflineDialogProps> = React.memo((props) => {
  const { onClick, data, timestamp } = props;
  const [timeToExpire, setTimeToExpire] = useState<any>(null);
  const timoutRef = useRef(null);
  const s = useMemo(() => pad(timeToExpire ?? 0), [timeToExpire]);
  useEffect(() => {
    if (timestamp && data) {
      timoutRef.current = setInterval(() => {
        const timeToExpire = ((timestamp + data.duration) - Date.now()) / 1000;
        setTimeToExpire(Math.round(timeToExpire));
      }, 1000);
    }
    return () => {
      timoutRef.current && clearInterval(timoutRef.current);
    };
  }, [timestamp, data]);
  return (
    <Modal title={"Connection error"} onClose={props.onClose} disableEscapeKeyDown={true} open={props.open} closeIcon={!!props.closeIcon}
           disableBackdropClick={true}>
      <ModalBody className={OfflineDialogClasses.Container} flexGrow={1} gap={"XS"} container direction={"column"}>
        <EmptyList className={OfflineDialogClasses.Empty} title={"Connection interrupted"}
                   content={"Please check your internet connection or hold on a moment while we try to reconnect."}
                   icon={"cloud_off"}/>
        {
          data && data.attempts == data.limit && timeToExpire == 0
            ?
            clearInterval(timoutRef.current)
            :
            <Box container justify="center" className={OfflineDialogClasses.Timer}>
              {`${timeToExpire > -1 ? s : "00"} seconds to reconnect`}
            </Box>
        }
        {
          data?.attempts >= 3 &&
          <Button onClick={onClick}>Connect</Button>
        }
      </ModalBody>
    </Modal>
  );
});

export default OfflineDialog;

