export enum ListItemClasses {
  ListItem = "list-item",
  ListItemText = "list-item-text",
  ListItemTextAlignEnd = "list-item-text-align-end",
  ListItemTags = "list-item-tags",
  ListItemTinyText = "tiny-text",
  ListItemBoldText = "bold-text",
  ListItemDefaultText = "default-text",
  ListItemBadge = "list-item-badge",
  Open = "open"
}