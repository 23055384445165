import React               from "react";
import { FC }              from "react";
import { DateTime }        from "luxon";
import { Alignment }       from "../../../../constants/Alignemnt";
import { Box }             from "../../../Box";
import { Tooltip }         from "../../../Tooltip";
import { TypographyColor } from "../../../Typography";
import { TypographySize }  from "../../../Typography";
import { Typography }      from "../../../Typography";
import { ICellProps }      from "../ICellProps";

export const LeadStatusCell: FC<ICellProps> = React.memo(function LeadStatusCell({ rowData,className, dataKey, getCellValue, ...props }) {
  const value = getCellValue(rowData, dataKey);
  const { action, updatedAt, row } = value;
  const statusObj = row.field.states[ row.status ];
  let tooltip = "Last action triggered : ";
  if (updatedAt) {
    let updatedAt = DateTime.fromISO(value.updatedAt).toFormat("MMM dd, h:mm a");
    tooltip += `${updatedAt}`;
  } else {
    tooltip += "-";
  }
  if (action) {
    tooltip += `\nLast action : ${action}`;
  }
  return <Box className={className}>
    <Tooltip title={tooltip} alignment={Alignment.Top}>
    <Box container direction={"column"} gap={"XXXS"}>
      <Typography>{statusObj?.stage}</Typography>
      <Typography size={TypographySize.TextSmall}
                  color={TypographyColor.Secondary}>{statusObj?.status}</Typography>
    </Box>
    </Tooltip>
  </Box>;
});
