import React                     from "react";
import { BoxItemComponentProps } from "../..";
import { CircularLoader }        from "./CircularLoader";
import { LinearLoader }          from "./LinearLoader";

export interface LoaderProps extends BoxItemComponentProps {
  variant: "linear" | "circular"
}

export const Loader = React.memo(function Loader(props: LoaderProps) {
  const { className, variant, ...p } = props;

  return variant == "linear" ?
    <LinearLoader {...p} /> :
    <CircularLoader {...p}/>;
});
