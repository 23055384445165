import { Stack }             from "@relcu/rc";
import { IconType }          from "@relcu/rc";
import { EmptyState }        from "@relcu/rc";
import React                 from "react";
import { FC }                from "react";
import "./empty-conversation.css";

export interface EmptyConversationProps {
  text: string;
  icon: IconType;
}

export const EmptyConversation: FC<EmptyConversationProps> = React.memo(function EmptyConversation(props) {
  const { text, icon, ...p } = props;
  return <Stack justifyContent={"center"} style={{}} alignItems={"center"} className={"empty-conversation"}>
    <EmptyState icon={icon} title={text ?? "Select conversation to see messages."}/>
  </Stack>;

});
