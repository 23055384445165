import { useMemo }              from "react";
import { useMutation }          from "@apollo/client";
import { gql }                  from "@apollo/client";
import { useLazyQuery }         from "@apollo/client";
import { CALL_FRAGMENT }        from "../graph/operations.graphql";
import { getScopeWhere }        from "../utils/graphQlUtils";
import { GetUnreadConferences } from "./__types__/GetUnreadConferences";
import { MARK_CALLS_AS_READ }   from "./operations.graphql";

interface markConferenceReadProps {
  id: string,
  __typename: string,
  userObjectId: string
}

export function useMarkConferenceRead(props: markConferenceReadProps) {
  const { id, __typename, userObjectId } = props;
  const [markReadMutation] = useMutation(MARK_CALLS_AS_READ);
  const scope = useMemo(() => {
    return getScopeWhere("Conference", id);
  }, [id]);

  const queryWhere = useMemo(() => {
    const key = __typename;
    let whereMap = {
      "Contact": {
        calls: {
          have: {
            party: {
              have: {
                link: id
              }
            }
          }
        }
      },
      "User": {
        calls: {
          have: {
            party: {
              have: {
                link: id
              }
            }
          }
        }
      },
      "Lead": {
        scope: scope
      }
    };
    return { unread: { equalTo: true }, ...whereMap[ key ] };
  }, [__typename, id, scope]);

  const [getConferences] = useLazyQuery<GetUnreadConferences>(GET_UNREAD_CONFERENCES, {
    fetchPolicy: "network-only",
    variables: {
      where: queryWhere
    }
  });

  const markAsRead = async (callingNumber) => {
    try {
      const { data: { conferences: { edges: data = [] } = {} } = {} } = await getConferences();
      let unreadIds: string[] = [];
      data.map(({ node: { id, calls, from } }) => {
        const viewerIsParty = (calls.findIndex((call) => (call.party.objectId == userObjectId)) > -1);
        if (viewerIsParty && (from == callingNumber)) {
          unreadIds.push(id);
        }
      });
      if (unreadIds.length > 0) {
        markReadMutation({ variables: { ids: unreadIds } }).catch(console.error);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    markAsRead
  };
}

const GET_UNREAD_CONFERENCES = gql`
  query GetUnreadConferences($where: ConferenceWhereInput!,$before:String,$after:String) {
    conferences(
      first: 10,
      before: $before,
      after: $after,
      where: $where,
      order: createdAt_DESC
    ) {
      count
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          unread
          objectId
          from
          calls {
            ...Call
          }
        }
      }
    }
  }
  ${CALL_FRAGMENT}
`;
