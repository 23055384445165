import React               from "react";
import { InputState }      from "../../Input/Input";
import { Input }           from "../../Input/Input";
import { useConsumeField } from "../BaseField";
import { UrlFieldProps }   from "./UrlField";

export const UrlEditField = React.memo(function UrlEditField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField<UrlFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  return (
    <Input
      {...input}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
    />
  );
});
export default UrlEditField;
