import React                    from "react";
import { FC }                   from "react";
import { classNames }           from "@relcu/ui";
import { BoxComponentProps }    from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { ContactDialogClasses } from "./ContactDialogClasses";

export interface RowHeaderProps extends BoxComponentProps {
  title?: string;
}

export const HeaderRow: FC<RowHeaderProps> = React.memo(function HeaderRow(props) {
  const { title, className, ...p } = props;
  const classes = classNames(ContactDialogClasses.HeaderRow, className);
  return <Box container alignItems="center" className={classes} {...p}>
    {title && <p>{title}</p>}
  </Box>;
});

export interface RowProps extends BoxComponentProps {
}
export const Row: FC<RowProps> = React.memo(function Row(props) {
  const { className, ...p } = props;
  const classes = classNames(ContactDialogClasses.Row, className);
  return <Box container alignItems="center" className={classes} {...p}/>;
});

export const RowTitle: FC<RowProps> = React.memo(function RowTitle(props) {
  const { className, ...p } = props;
  const classes = classNames(ContactDialogClasses.RowTitle, className);
  return <p className={classes} {...p}/>;
});

export default HeaderRow;
