import { useLazyQuery }         from "@apollo/client";
import { gql }                  from "@apollo/client";
import { useField }             from "@relcu/final-form";
import { useForm }              from "@relcu/final-form";
import { FieldCell }            from "@relcu/rc";
import { useSource }            from "@relcu/ui";
import { useMemo }              from "react";
import { useContext }           from "react";
import { useState }             from "react";
import { useCallback }          from "react";
import React                    from "react";
import { FC }                   from "react";
import { Proposal }             from "../../../Proposal";
import { GetPmi_getPmiRatesV2 } from "./__types__/GetPmi";
import { GetPmiVariables }      from "./__types__/GetPmi";
import { GetPmi }               from "./__types__/GetPmi";
import { PmiDialog }            from "./PmiDialog";

export const PmiSelectCell: FC = React.memo(function PmiSelectCell() {
  const { getState } = useForm();
  const loanEstimate = useContext(Proposal.Context);
  const { $object: lead } = useSource();
  const form = useForm();
  const [open, setOpen] = useState(false);
  const [getPmi, { data, loading, fetchMore }] = useLazyQuery<GetPmi, GetPmiVariables>(GET_PMI, {
    notifyOnNetworkStatusChange: true
  });
  const cleanup = (data) => {
    let cleanedData = {};
    Object.keys(data).forEach(k => {
      if (k !== "__typename") {
        if (typeof data[ k ] == "object" && data[ k ] !== null) {
          cleanedData[ k ] = cleanup(data[ k ]);
        } else {
          cleanedData[ k ] = data[ k ];
        }
      }
    });
    return cleanedData;
  };

  const pmiRates = cleanup(data?.getPmiRatesV2 || {}) as GetPmi_getPmiRatesV2;
  const pmiInput = useCallback((): GetPmiVariables["input"] => {
    const { values } = getState();
    const borrowers = lead.members.filter((members) => ["borrower", "co_borrower"].includes(members.type)).map(({
      type,
      creditScore,
      employmentStatus
    }) => ({
      type,
      creditScore: (type == "borrower" ? values.fico : values.fico1),
      isSelfEmployed: employmentStatus === "self_employed"
    }));
    //todo Review this part do pmi need to be depended on pricing engine.
    const normLoanProgram = (values.loanProduct || "").toLowerCase();
    let pmiLoanProgram = "None";
    if (normLoanProgram.includes("home") && normLoanProgram.includes("ready")) {
      pmiLoanProgram = "Fannie Mae HomeReady";
    } else if (normLoanProgram.includes("home") && (normLoanProgram.includes("poss") || normLoanProgram.includes("possible"))) {
      pmiLoanProgram = "Freddie Mac HomePossible";
    }
    return {
      leadId: lead.objectId,
      loanAmount: Math.round(values.totalLoanAmount),
      dti: Math.round(values.dti * 100) / 100,
      loanPurpose: loanEstimate.loanPurpose,
      loanProgram: pmiLoanProgram,
      loanTerm: parseInt(values.loanTerm),
      firstTimeHomeBuyer: values.firstTimeHomeBuyer,
      interestRate: values.rate,
      amortizationType: values.amortizationType,
      initialArmTerm: values.initialArmTerm ? Number(values.initialArmTerm) : null,
      borrower: borrowers.find(({ type }) => type === "borrower"),
      coBorrower: borrowers.find(({ type }) => type === "co_borrower"),
      property: {
        type: loanEstimate.propertyType,
        occupancy: loanEstimate.propertyOccupancy,
        value: values.propertyValue,
        propertyAddress: {
          state: loanEstimate.propertyState,
          zipCode: loanEstimate.propertyZipCode,
          city: loanEstimate.propertyCity,
          county: loanEstimate.propertyCounty
        }
      }
    };

  }, [getState, loanEstimate, lead.members]);
  const getPmiData = (pmiRates) => {
    return {
      ...pmiRates,
      payload: Object.fromEntries(Object.entries<any>(pmiRates.payload || {}).map(([key, r]) => {
        const rate = Object.keys(r).map((key) => ({ ...r[ key ], providerId: key.toLowerCase(), providerName: key }));
        return [key, [...rate].sort((a, b) => a.premium1 - b.premium1)];
      }))
    };
  };
  const sortedPmiData = useMemo(() => getPmiData(pmiRates), [pmiRates]);
  const { input: { value: selectedPmiId } } = useField("pmiCompany", { subscription: { value: true } });
  const { input: { value: selectedPmiType } } = useField("pmiType", { subscription: { value: true } });
  const [selectedId, setSelectedId] = useState<string>(selectedPmiId);
  const [selectedType, setSelectedType] = useState<string>(selectedPmiType);
  const selectedPmi = useMemo(() => {
    if (!(pmiRates?.payload && pmiRates?.payload[ selectedType ])) {
      return null;
    }
    const rates = pmiRates?.payload[ selectedType ] || {};
    const rateArray = Object.keys(rates).map((key) => ({ ...rates[ key ], providerId: key.toLowerCase(), providerName: key }));
    return rateArray?.find(fee => fee.providerId == selectedId);
  }, [selectedId, selectedType, pmiRates]);

  const onApply = useCallback(() => {
    form.change("pmiCompany", selectedId);
    form.change("pmiType", selectedType);
    form.change("pmi", selectedPmi?.premium1);
    setOpen(false);
  }, [selectedId, selectedType, selectedPmi, setOpen]);

  const onOpen = useCallback(async () => {
    const { data } = await getPmi({ variables: { input: pmiInput() } });
    const pmiRates = cleanup(data?.getPmiRatesV2 || {}) as GetPmi_getPmiRatesV2;
    const sortedPmiData = getPmiData(pmiRates);

    if (!selectedId && Object.keys(sortedPmiData?.payload || {}).length) {
      const monthly = !!sortedPmiData.payload.monthly;
      const keys = Object.keys(sortedPmiData?.payload);
      const selected = monthly
        ? sortedPmiData.payload.monthly.find(r => r.status == "Success")
        : sortedPmiData.payload[ keys[ 0 ] ].find(r => r.status == "Success");
      if (selected) {
        let selectedType;
        let selectedId;
        selectedId = selected.providerId;
        if (monthly) {
          selectedType = "monthly";
        } else {
          selectedType = keys[ 0 ];
        }
        setSelectedType(selectedType);
        setSelectedId(selectedId);
        const selectedPmi = sortedPmiData?.payload[ selectedType ]?.find(fee => fee.providerId == selectedId);
        form.change("pmiCompany", selectedId);
        form.change("pmiType", selectedType);
        form.change("pmi", selectedPmi.premium1);
      }
    } else {
      setOpen(true);
    }
  }, [pmiInput, selectedId]);

  const reFetch = useCallback(async () => {
    await fetchMore({
      variables: {
        input: {
          ...pmiInput()
        }
      }
    });
  }, [fetchMore]);
  const warning = useMemo(() => {
    return pmiRates?.payload && Object.keys(pmiRates.payload).some(key => {
      const rates = pmiRates.payload[ key ];
      const rateArray = Object.keys(rates).map((key) => ({ ...rates[ key ], providerId: key.toLowerCase(), providerName: key }));
      const index = rateArray?.findIndex((provider) => provider.status !== "Success");
      return index > -1;
    });
  }, [sortedPmiData]);
  const error = useMemo(() => {
    return pmiRates?.status == "Error";
  }, [pmiRates]);

  return <>
    <PmiDialog open={open} onClose={() => setOpen(false)} reFetch={reFetch} loading={loading}
               pmiRates={sortedPmiData} setSelectedType={setSelectedType} setSelectedId={setSelectedId}
               onApply={onApply} selectedId={selectedId} selectedPmi={selectedPmi} selectedType={selectedType}/>
    <FieldCell.PmiCell
      helperText={error ? "Failed to get PMI rates." : (warning ? "There are options that failed to load." : "")}
      status={error ? "error" : (warning ? "warning" : "info")} required
      loading={loading} onOpen={onOpen} name={"pmiCompany"}/>
  </>;
});

export const PMI_V2_RESULT = gql`
  fragment PmiRateV2Result on PmiRateV2ResultItem{
    status
    premium1
    rate1
    pdfUrl
    quoteNumber
  }
`;
export const PMI_V2_RESULT_COMPANY_FRAGMENT = gql`
  fragment PmiRateV2ResultCompany on PmiRateV2ResultCompany {
    Arch {
      ...PmiRateV2Result
    }
    Enact {
      ...PmiRateV2Result
    }
    Essent {
      ...PmiRateV2Result
    }
    Mgic {
      ...PmiRateV2Result
    }
    National {
      ...PmiRateV2Result
    }
    Radian {
      ...PmiRateV2Result
    }
  }
  ${PMI_V2_RESULT}
`;

export const GET_PMI = gql`
  query GetPmi($input: PmiRatesArgs!)  {
    getPmiRatesV2(input:$input) @connection(key: "pmiRatesBeta",filter: ["input"]){
      payload{
        monthly {
          ...PmiRateV2ResultCompany
        }
        single_lp {
          ...PmiRateV2ResultCompany
        }
        single_nr {
          ...PmiRateV2ResultCompany
        }
        single_r {
          ...PmiRateV2ResultCompany
        }
        split_bd {
          ...PmiRateV2ResultCompany
        }
      }
      errorMessage
      requestId
      status
    }
  }
  ${PMI_V2_RESULT_COMPANY_FRAGMENT}
`;
