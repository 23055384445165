import { ReactElement }      from "react";
import React                 from "react";
import { FC }                from "react";
import { classNames }        from "../../../utils/classNames";
import { BoxComponentProps } from "../../Box";
import { Box }               from "../../Box";
import { Label }             from "../../Label";
import { RadioFieldProps }   from "../RadioField";
import { RadioField }        from "../RadioField";

export interface RadioGroupFieldProps extends BoxComponentProps {
  label?: string | ReactElement,
  name?: string,
  options: RadioFieldProps[]
}

export const RadioGroupField: FC<RadioGroupFieldProps> = React.memo(function RadioGroupField(props) {
  const { label, options, name, children, className, ...p } = props;
  return <Box container direction={"column"} gap={"XS"} flexBasis={"calc(50% - (30px / 2))"}
              className={classNames(RadioGroupFieldClasses.RadioGroupField, className)} {...p}>
    {label && <Label>{label}</Label>}
    {
      children ?? options.map((option, index) => <RadioField key={index} name={name} {...option}/>)
    }
  </Box>;
});

const enum RadioGroupFieldClasses {
  RadioGroupField = "radio-group-field"
}
