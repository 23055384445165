import React                 from "react";
import { FC }                from "react";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { Box }               from "../../../Box";
import { Typography }        from "../../../Typography";
import { ICellProps }        from "../ICellProps";

export const PhoneFormattedCell: FC<ICellProps> = React.memo(function PhoneFormattedCell({ getCellValue, className, defaultValue, rowData, dataKey, ...props }) {
  let value = getCellValue(rowData, dataKey);
  if (!value) {
    return <Box className={className} container gap={"XXXS"}>
      <Typography>{defaultValue || ''}</Typography>
    </Box>;
  }
  return <Box className={className} container gap={"XXXS"}>
    <Typography>{formatPhoneNumber(value)}</Typography>
  </Box>
});