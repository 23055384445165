import React                 from "react";
import { FC }                from "react";
import { BoxComponentProps } from "../../../Box";
import { Input }             from "../../../Input/Input";

export interface NumberFilterFieldProps extends Omit<BoxComponentProps, "onChange"> {
  value: number;
  precision?: number;
  onChange?(value: number);
}
export const NumberFilterField: FC<NumberFilterFieldProps> = React.memo(function NumberFilterField(props) {
  return <Input
    flex={"0 1 32%"}
    value={props.value}
    placeholder={"Type value"}
    onChange={(value) => props.onChange(Number(value))}/>;
});
