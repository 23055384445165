import React                 from "react";
import { Box }               from "../../..";
import { classNames }        from "../../..";
import { ItemsEllipsis }     from "../../..";
import { ListItemClasses }   from "..";
import { BoxComponentProps } from "../../..";
import { BadgeColors }       from "../../..";
import { BadgeVariants }     from "../../..";
import { Badge }             from "../../..";
import { ChipsSizes }        from "../../..";
import { ChipsColors }       from "../../..";
import { Chips }             from "../../..";

export interface ListItemTagsProps extends BoxComponentProps {
  tags?: string[]
  showLength: number,
  label?: string
}

ListItemTags.defaultProps = {
  showLength: 2
};
export function ListItemTags(props: ListItemTagsProps) {
  const { className, tags, showLength, label, ...p } = props;
  const classes = classNames(ListItemClasses.ListItemTags, className);
  return <Box container alignItems={"center"} gap={"XXS"} flexBasis={145} flexGrow={1} className={classes} {...p}>
      <ItemsEllipsis
        showLength={showLength}
        items={tags}
        renderItem={(item, index) => <Chips
          length={10}
          key={index}
          color={ChipsColors.Grey}
          label={item}
          size={ChipsSizes.Small}
          thumbnail={<Badge variant={BadgeVariants.Dot} color={BadgeColors.Primary}/>}
        />}
        ellipsis={(count) => <React.Fragment>{`+${count}`}</React.Fragment>}/>
    </Box>;
}
