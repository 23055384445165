export enum SpecialClass {
  USER = "User",
  ROLE = "Role",
  CONTACT = "Contact",
  TASK = "Task",
  CALL = "Call",
  EMAIL = "EmailMessage",
  SMS = "TextMessage",
  ACTIVITY = "Activity",
  COMMENT = "Comment"
}
