import { SetStateAction }        from "react";
import { Dispatch }              from "react";
import React                     from "react";
import { FC }                    from "react";
import { ButtonVariants }        from "@relcu/ui";
import { Button }                from "@relcu/ui";
import { MailDomain }            from "../../__types__/MailDomain";
import { useGmailConnectButton } from "./useGmailConnectButton";
import GoogleSvgUrl              from "../../../assets/google.svg";

export interface GmailConnectButtonProps {
  domain: MailDomain;
  onError: Dispatch<SetStateAction<any>>;
}

export const GmailConnectButton: FC<GmailConnectButtonProps> = React.memo(function GmailConnectButton(props) {
  const { handleLogin } = useGmailConnectButton(props);
  return <Button
    onClick={() => {
      props.onError(null);
      handleLogin();
    }}
    disabled={props.domain.disabled}
    variant={ButtonVariants.Outline}
    icon={<img src={GoogleSvgUrl} alt="Authorize in Google"/>}>
    CONNECT
  </Button>;
});
