import { Stack }       from "@relcu/rc";
import { Typography }  from "@relcu/rc";
import { Page }        from "@relcu/rc";
import { Toolbar }     from "@relcu/rc";
import { Header }      from "@relcu/rc";
import { FC }          from "react";
import React           from "react";
import { HeaderProps } from "./ThreadHeader";

export interface BulkHeaderProps extends HeaderProps {
  organizerName: string;
}
export const BulkHeader: FC<BulkHeaderProps> = React.memo(function BulkHeader(props) {
  const { expand, onToggle, organizerName, subject } = props;
  return <Header style={{ borderRight: "none", borderLeft: "none" }}>
    <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
             style={{ paddingLeft: `${expand ? "16px" : "8px"}`, verticalAlign: "center" }}>
      {!expand && <Page.Toggle size={"md"} expand={expand} onToggle={onToggle}/>}
      <Stack style={{ width: "100%" }} justifyContent={"space-between"}>
        <Stack.Item>
          <Typography weights={"medium"} variant={"base16"}>{subject}</Typography>
        </Stack.Item>
      </Stack>
    </Toolbar>
    <Stack style={{ padding: "0 16px" }}>
      <Stack.Item style={{ borderTop: "1px solid var(--color-light-gray-primary)", padding: "16px 0", width: "100%" }}>
        <Typography weights={"medium"} variant={"base16"}>
          Organizer
        </Typography>
        <Typography>
          {organizerName}
        </Typography>
      </Stack.Item>
    </Stack>
  </Header>;
});
