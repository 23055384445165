import { TypePolicy } from "@apollo/client";

export const MailDomain: TypePolicy = {
  fields: {
    provider: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      }
    }
  }
};