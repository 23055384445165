import React              from "react";
import Handlebars         from "handlebars/dist/handlebars";
import { FORM_ERROR }     from "@relcu/form";
import { JqlFormProps }   from "../../Jql/JqlForm";
import { JqlForm }        from "../../Jql/JqlForm";

export const PhoneMessageTemplateForm = React.memo<JqlFormProps>(function PhoneMessageTemplateForm(props) {
  return (
    <JqlForm
      {...props}
      validators={[
        {
          field: "text",
          validate: (value) => {
            try {
              if (value.match(/{{[{]?(.*?)[}]?}}/g)) {
                Handlebars.parse(value);
              }
            } catch (e) {
              return "Something wrong with template text.";
            }
          }
        }
      ]}
      getErrorMessage={(error, errors) => errors[ FORM_ERROR ]}
    />
  );
});
