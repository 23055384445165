
export const enum TimelineItemClasses {
  TimelineItemContainer = "timeline-item-container",
  TimelineItem = "timeline-item",
  TimelineItemIconContainer = "timeline-item-icon-container",
  TimelineItemIcon = "timeline-item-icon",
  TimelineItemSubject = "timeline-item-subject",
  TimelineItemHeader = "timeline-item-header",
  TimelineItemBodyContainer = "timeline-item-body-container",
  TimelineItemBody = "timeline-item-body",
  TimelineItemOverflow = "timeline-item-overflow",

  Error = "timeline-item-icon--error",
  Success = "timeline-item-icon--success",
  Warning = "timeline-item-icon--warning",
  Primary = "timeline-item-icon--primary",
  Stretch = "timeline-item-icon--stretch",
  Default = "timeline-item-icon--default",
  TealDark = "timeline-item-icon--teal-dark",
}
