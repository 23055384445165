import { useNavigate }   from "@relcu/react-router";
import { useSource }     from "@relcu/ui";
import { getObjectPath } from "../../../../utils/layoutUtils";

export function useContactHeader() {
  const navigate = useNavigate();
  const { $object: node } = useSource();
  const api = {
    navigateToSms: () => {
      navigate(`${getObjectPath(node)}/sms`);
    }
  };
  return api;
}
