import { useMutation }                from "@apollo/client";
import { useLazyQuery }               from "@apollo/client";
import { gql }                        from "@apollo/client";
import { confirmModal }               from "@relcu/ui";
import { useAlert }                   from "@relcu/ui";
import { useMounted }                 from "@relcu/ui";
import { parsePhoneNumber }           from "@relcu/ui";
import { isEmail }                    from "@relcu/ui";
import { useThrottle }                from "@relcu/ui";
import { useEffect }                  from "react";
import { useState }                   from "react";
import { MergeRecordToLeadVariables } from "./__types__/MergeRecordToLead";
import { MergeRecordToLead }          from "./__types__/MergeRecordToLead";

export const SEARCH_DIRECT_MAIL_QUERY_ATLAS = gql`
  query SearchDirectMailQueryAtlas(
    $query:String!,
    $offsetDirectMails: Int
  ) {
    directMails:search(className: "DirectMail",query:$query,skip:$offsetDirectMails,limit: 15  ){
      count
      data
    }
  }
`;

export function useSearchRecord(props) {
  const [value, setValue] = useState("");
  let [query, setQuery] = useThrottle(value, 1000);
  const { success, error } = useAlert();
  const [mergeRecordToLead] = useMutation<MergeRecordToLead, MergeRecordToLeadVariables>(MERGE_RECORD);

  const limit = 15;
  if (!isEmail(query)) {
    if (query && !/[A-Z]/ig.test(query)) {
      query = parsePhoneNumber(query);
    }
    if (query && query.includes("+")) {
      query = String(query).replace(/[+]/g, "");
    }
  }
  query = query ? query.trim() : null;

  const [send, { loading, data, called, refetch, variables }] = useLazyQuery(SEARCH_DIRECT_MAIL_QUERY_ATLAS, {
    fetchPolicy: "no-cache"
  });
  const load = () => {
    if (query) {
      send({
        variables: { offsetDirectMails: 0, query }
      });
    }
  };
  useEffect(() => setQuery(value), [value]);
  useMounted(load, [query]);
  const search = {
    loading,
    value,
    setValue,
    data: data?.directMails?.data,
    count: data?.directMails?.count,
    async onRowClick(item) {
      await confirmModal({
        title: "Merge record with lead",
        content: `Are you sure you want to merge a direct mail record with ${props.objectName}?`,
        label: "Merge"
      });
      try {
        const { data: { mergeRecordToLead: isSuccess } = {} = {} } = await mergeRecordToLead({ variables: { leadId: props.leadId, resNum: item.resNum } });
        if (isSuccess) {
          success("Record was successfully merged.");
        } else {
          throw Error("Something went wrong");
        }
        props.onClose();
      } catch (e) {
        error(e.message);
        console.error(e);
      }
    },
    onPage(page: number) {
      return refetch({
        offsetDirectMails: (page - 1) * limit
      });
    },
    get isEmpty() {
      return !called || (!loading && (!Object.keys(data?.directMails).length || !data?.directMails?.count));
    }
  };

  return search;
}

export const MERGE_RECORD = gql`
  mutation MergeRecordToLead($leadId: ID!,$resNum: String!) {
    mergeRecordToLead(leadId: $leadId,resNum: $resNum)
  }
`;