import React                   from "react";
import { FC }                  from "react";
import { BoxComponentProps }   from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { ButtonSizes }         from "@relcu/ui";
import { ButtonVariants }      from "@relcu/ui";
import { classNames }          from "@relcu/ui";
import { Mention }             from "@relcu/ui";
import { NavBar as RcNavBar }  from "@relcu/ui";
import { NavBarEmptyCurrent }  from "@relcu/ui";
import { NavBarEmptyWatching } from "@relcu/ui";
import { NavBarSubtitle }      from "@relcu/ui";
import { useNavBar }           from "./useNavBar";
import { NavBarClasses }       from "./NavBarClasses";
import { NavBarContainer }     from "./NavBarContainer";
import EmptyPinnedSmallUrl     from "./empty-pinned-small.png";
import EmptyPinnedBigUrl       from "./empty-pinned-big.png";

export enum SectionType {
  PINNED = "pined",
  HISTORY = "history"
}

export interface NavBarSectionProps extends BoxComponentProps {
  type: "pined" | "history",
}

export const NavBarSection: FC<NavBarSectionProps> = React.memo(function NavBarSection(props) {
  const { onPin, onUnpin, onRemove, current, watch, setWatch, setCurrent, onClear, navBarRef, top, bottom, handleScrollTo, small } = useNavBar();
  const { className, type, ...p } = props;
  const classes = classNames(NavBarClasses.NavMenu, className);
  return <RcNavBar className={classes} ref={navBarRef} {...p}>
    {
      !!top.size &&
      <Mention icon={"north"} onClick={() => handleScrollTo("top")} direction={"top"}>
        {small ? "New" : "New Update"}
      </Mention>
    }
    {
      type === SectionType.PINNED &&
      <NavBarContainer
        header={<NavBarSubtitle title={small ? "\"P\"" : "PINNED"}/>}
        small={small}
        data={watch}
        onVisibility={onUnpin}
        onChange={setWatch}
        droppableId="watch"
        objectTypes={["*"]}
        acceptFrom={["current", "watch"]}
        effectAllowed={"move"}
        dropEffect={"move"}
        empty={(
          <NavBarEmptyWatching
            small={small}
            title={"You do not have \n pinned pages"}
            text={"Click on the pin icon rom \n Browsing history list below to pin here."}
            img={small ? EmptyPinnedSmallUrl : EmptyPinnedBigUrl}
          />
        )}
      />
    }

    {
      type === SectionType.HISTORY &&
      <NavBarContainer
        small={small}
        flex={"1 1 auto"}
        data={current}
        onRemove={onRemove}
        onVisibility={onPin}
        onChange={setCurrent}
        droppableId="current"
        objectTypes={["*"]}
        acceptFrom={["current", "watch"]}
        effectAllowed={"move"}
        dropEffect={"move"}
        header={
          <NavBarSubtitle title={small ? "\"BH\"" : "BROWSING HISTORY"}>
            {current.length > 0 &&
              <Button onClick={onClear} icon={small ? "clear" : null} onlyIcon={small} variant={ButtonVariants.Ghost}
                      size={ButtonSizes.Small} aria-label="clear-browsing-history">Clear all</Button>}
          </NavBarSubtitle>
        }
        empty={
          <NavBarEmptyCurrent
            small={small}
            title={"You do not have \n recently viewed pages"}
            text={" You recently viewed pages will \n appear here for quick access."}
            icon={`rc_no_history`}
          />
        }
      />
    }
    {
      !!bottom.size &&
      <Mention icon={"south"} onClick={() => handleScrollTo("bottom")} direction={"bottom"}>
        {small ? "New" : "New Update"}
      </Mention>
    }
  </RcNavBar>;
});

