import React                 from "react";
import { useLocation }       from "@relcu/react-router";
import { Modal }             from "@relcu/ui";
import { SchemaDialogProps } from "../SchemaDialog";
import { MergeForm }         from "./Form/MergeForm/MergeForm";
import { DuplicationForm }   from "./Form/DuplicateForm/DuplicationForm";
import { useContactDialog }  from "./useContactDialog";
import "./Field/duplicate-field-style.css";
import "./contact-dialog.css";

const ContactForms = {
  MergeForm,
  DuplicationForm
};

export const ContactDialogContext = React.createContext<ReturnType<typeof useContactDialog>>(null);

export interface ContactDialogProps extends SchemaDialogProps {
  member: any
  contactsData: {
    emails: string[]
    phones: string[]
    ids?: string[]
  }
  navigate?: boolean;
  onClose?: (e?) => void;
  type?: string;
}

export const ContactDialog = React.memo<ContactDialogProps>(function ContactDialog(props) {
  const { state, pathname } = useLocation();
  const FormComponent = ContactForms[ state?.[ "form" ] ];
  const { action } = props;
  const context = useContactDialog(props);

  return (
    <Modal
      title={state?.[ "title" ] || `${action === "save" ? "Edit" : "Create"} contact`}
      onClose={props.onClose}
      open={true}
      closeIcon={false}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      variant={"big"}
      gap={"XXXS"}
      //className={LoanProposalDialogClasses.Modal}
    >
      <ContactDialogContext.Provider value={context}>
        <FormComponent {...props} />
      </ContactDialogContext.Provider>
    </Modal>
  );
});
