import React                 from "react";
import { FC }                from "react";
import { Alignment }         from "../../constants/Alignemnt";
import { CommonClasses }     from "../../theme/classes";
import { classNames }        from "../../utils/classNames";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";
import { ButtonVariants }    from "../Button";
import { Button }            from "../Button";
import { FontIcon }          from "../Icon";
import { Tooltip }           from "../Tooltip";
import { TypographyWeight }  from "../Typography";
import { TypographySize }    from "../Typography";
import { Typography }        from "../Typography";

import { StepClasses }       from "./StepClasses";

export interface StepSectionProps extends Omit<BoxComponentProps, "title"> {
  title?: string | React.ReactElement;
  tooltip?: string;
  error?: boolean;
}

export const StepSection: FC<StepSectionProps> = React.memo(function StepSection(props) {
  const { title, className, children, tooltip, error, ...p } = props;
  const classes = classNames(StepClasses.StepSection, className);

  return <Box container direction={"column"} gap={"XS"} className={classes} {...p}>
    {
      title &&
      <Box container gap={"XXXS"} alignItems={"center"}>
        {
          typeof title == "string" ?
            <Typography
              size={TypographySize.Text}
              weight={TypographyWeight.Medium}
              className={classNames(StepClasses.Header,
                {
                  [ StepClasses.HeaderError ]: error
                }
              )}>
              {title}
            </Typography>
            :
            title
        }
        {
          tooltip &&
          <Tooltip title={tooltip} alignment={Alignment.Top}>
            <FontIcon type={"info"} className={CommonClasses.GrayIcon}
                      style={{ fontSize: "var(--typography-icon-size)" }}/>
          </Tooltip>
        }
      </Box>
    }
    {children}
  </Box>;
});

interface StepEmptySection extends BoxComponentProps {
  title: string;
  content: string;
  actionText?: string;
  onClick?();
}

export const StepEmptySection: FC<StepEmptySection> = React.memo(function StepEmptySection(props) {
  const { title, content, children, className, actionText, onClick, ...p } = props;
  const classes = classNames(StepClasses.Empty, className);

  return <Box container direction={"column"} gap={"XXXS"} alignSelf={"stretch"} flex={1} justify={"center"}
              className={classes} {...p}>
    <p className={StepClasses.EmptyTitle}>{title}</p>
    <p className={StepClasses.EmptyDescription}>{content}</p>
    {
      onClick &&
      <Button variant={ButtonVariants.Ghost} alignSelf={"center"} onClick={onClick}>{actionText}</Button>
    }
  </Box>;
});

StepEmptySection.defaultProps = {
  actionText: "ADD FILTER"
};

