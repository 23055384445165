import React                from "react";
import { FC }               from "react";
import { StepSection }      from "@relcu/ui";
import { TypographyLine }   from "@relcu/ui";
import { TypographySize }   from "@relcu/ui";
import { TypographyWeight } from "@relcu/ui";
import { Typography }       from "@relcu/ui";

export const EmptyAccounts: FC<any> = React.memo(function EmptyAccounts(props) {
  return (
    <StepSection flex={1} justify={"center"} alignItems={"center"} gap={"XXXS"}>
      <Typography weight={TypographyWeight.Medium} lineHeight={TypographyLine.Line17}>
        You do not have an account.
      </Typography>
      <Typography size={TypographySize.TextSmall}>Ask your admin to create one.</Typography>
    </StepSection>
  );
});