import { useReactiveVar }      from "@apollo/client";
import { useMemo }             from "react";
import { gql }                 from "@apollo/client";
import { useQuery }            from "@apollo/client";
import { PHONE_LINE_FRAGMENT } from "../graph/operations.graphql";
import { audioDevicesVar }     from "../reactiveVars";
import { microphoneAccessVar } from "../reactiveVars";
import { GetCommonPhoneLines } from "./__types__/GetCommonPhoneLines";
import { PhoneLinesUser }      from "./__types__/PhoneLinesUser";

const PHONE_LINES_USER = gql`
  query PhoneLinesUser {
    viewer {
      user {
        id
        phoneLines {
          edges {
            node {
              ...PhoneLine
            }
          }
        }
      }
    }
  }
  ${PHONE_LINE_FRAGMENT}
`;

export function useViewerPhoneLines() {
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const devices = useReactiveVar(audioDevicesVar);
  const { data: { viewer: { user: { id, phoneLines: { edges: viewerPhoneLineEdges = [] } = {} } = {} } = {} } = {} } = useQuery<PhoneLinesUser>(PHONE_LINES_USER, {
    fetchPolicy: "cache-only"
  });

  const { data: { phoneLines: { edges: phoneLinesEdges = [] } = {} = {} } = {}, loading } = useQuery<GetCommonPhoneLines>(GET_COMMON_PHONE_LINES, {
    fetchPolicy: "cache-only"
  });
  const fromNumbers = useMemo(() => {
    return [...viewerPhoneLineEdges, ...phoneLinesEdges].map(
      ({ node: { number, objectName } }) => ({ label: objectName || number, value: number })
    );
  }, [phoneLinesEdges, id]);

  const bulkFromNumbers = useMemo(() => {
    return viewerPhoneLineEdges.map(
      ({ node: { number, objectName } }) => ({ label: objectName || number, value: number })
    );
  }, [id]);
  return {
    hasMicrophoneIssue: !microphoneAccess || !devices.audioinput.length || !devices.audiooutput.length,
    fromNumbers: fromNumbers,
    bulkFromNumbers,
    loading
  };
}

const GET_COMMON_PHONE_LINES = gql`
  query GetCommonPhoneLines {
    phoneLines(where:{common: {equalTo: true}} ){
      edges{
        node{
          ...PhoneLine
        }
      }
    }
  }
  ${PHONE_LINE_FRAGMENT}
`;
