import { SetStateAction }            from "react";
import { Dispatch }                  from "react";
import { useMemo }                   from "react";
import { useSource }                 from "@relcu/ui";
import { useModal }                  from "@relcu/ui";
import { confirmModal }              from "@relcu/ui";
import { ButtonColors }              from "@relcu/ui";
import { useLazyCondition }          from "@relcu/ui";
import { useImperativeState }        from "@relcu/ui";
import { RowDataType }               from "rsuite-table/src/@types/common";
import { transformNameToLabel }      from "../../utils/helpers";
import { usePermissions }            from "../AccessControl";
import { SchemaDialog }              from "../Layout/Dialog/SchemaDialog";
import { MailAction }                from "../Layout/View/TableView/actions";
import { CallAction }                from "../Layout/View/TableView/actions";
import { TaskCreateAction }          from "../Layout/View/TableView/actions";
import { LeadStatusAction }          from "../Layout/View/TableView/actions";
import { SeeEmailAction }            from "../Layout/View/TableView/actions/SeeEmailAction";
import { SeePhoneMessageAction }     from "../Layout/View/TableView/actions/SeePhoneMessageAction";
import { SmsAction }                 from "../Layout/View/TableView/actions/SmsAction";
import { BulkSendMailModal }         from "../Modal/BulkSendMailModal/BulkSendMailModal";
import { BulkSendPhoneMessageModal } from "../Modal/BulkSendPhoneMessageModal/BulkSendPhoneMessageModal";
import { BulkUserAssignModal }       from "../Modal/BulkUserAssignModal/BulkUserAssignModal";
import { QuickCreateTaskModal }      from "../Modal/QuickCreateTaskModal/QuickCreateTaskModal";
import { QuickSendMailModal }        from "../Modal/QuickSendMailModal/QuickSendMailModal";
import { QuickSendSmsModal }         from "../Modal/QuickSendSmsModal/QuickSendSmsModal";
import { BulkStatusModal }           from "../Modal/StatusModal/BulkStatusModal";
import { StatusModal }               from "../Modal/StatusModal/StatusModal";
import { useMailApi }                from "../useMailApi";
import { useViewerPhoneLines }       from "../useViewerPhoneLines";

export interface UseTableOptions {
  selectedRows?: string[];
  loading?: boolean;
  onRowSelect?: Dispatch<SetStateAction<string[]>> | ((state: string[]) => void);
  onRowDelete?: (obj) => void;
  onRowStatus?: (obj) => void;
  data: readonly RowDataType[];
  total?: number;
  classId: string;
  editable?: any;
  variables?: any;
  actions: any[];
  queryFind?: any
}

export function useTable(options: UseTableOptions) {
  const { selectedRows: selected = [], variables, queryFind, total, onRowSelect, data, loading, classId, onRowDelete, onRowStatus, editable = false } = options;
  const [selectedRows, setSelectedRows] = useImperativeState(selected, onRowSelect);
  const tableHeight = useMemo(() => (data.length * 46) + 46 + 10 + (selectedRows.length ? 58 : 0), [data, selectedRows]);
  const evaluate = useLazyCondition();
  const { canUpdate, canDelete } = usePermissions({ __typename: classId });
  const [modal, modalContext] = useModal(SchemaDialog);
  const [bulkStatusModal, bulkStatusModalContext] = useModal(BulkStatusModal);
  const [bulkUserAssignModal, bulkUserAssignModalContext] = useModal(BulkUserAssignModal);
  const [bulkSendMailModal, bulkSendMailModalContext] = useModal(BulkSendMailModal);
  const [bulkSendPhoneMessageModal, bulkSendPhoneMessageModalContext] = useModal(BulkSendPhoneMessageModal);
  const [statusModal, statusModalContext] = useModal(StatusModal);
  const [quickSendMailModal, quickSendMailModalContext] = useModal(QuickSendMailModal);
  const [quickSendSmsModal, quickSendSmsModalContext] = useModal(QuickSendSmsModal);
  const [quickCreateTaskModal, quickCreateTaskModalContext] = useModal(QuickCreateTaskModal);
  const { $viewer: { role } } = useSource();
  const { defaultBulkMailbox: defaultMailbox } = useMailApi();
  const { bulkFromNumbers: fromNumbers } = useViewerPhoneLines();
  const bulkActions = useMemo(() => {
    const result = [];
    options.actions?.forEach(action => {
      const { type, color, icon, ...otherProps } = action;

      if (action.bulkPermissions && !evaluate({ conditions: action.bulkPermissions }).apply) {
        return;
      }

      switch (type) {
        case "Mail":
          result.push({
            disabled: !defaultMailbox && role === "loan_officer",
            disabledMessage: "The bulk email send cannot work without Relcu email setup. Please contact your admin to configure it.",
            type,
            label: "Send Email",
            ...otherProps,
            icon: "rc_email_templates"
          });
          break;
        case "SMS":
          result.push({
            disabled: !fromNumbers.length && role === "loan_officer",
            disabledMessage: "You do not have any phone numbers assigned to you",
            type,
            label: "Send SMS",
            ...otherProps,
            icon: "rc_sms_templates"
          });
          break;
        case "AssignUser":
          result.push({
            type,
            icon,
            label: "Assign user",
            ...otherProps
          });
          break;
        case "LeadStatus":
          result.push({
            type,
            icon,
            label: "Set status",
            ...otherProps
          });
          break;

      }
    });

    if (result.length) {
      return result;
    }
  }, [options.actions, evaluate]);
  const actions = useMemo(() => {
    const access = typeof editable === "object" ? evaluate({ conditions: editable }).apply : editable;
    const actions = [];
    if (access) {
      if (canUpdate) {
        actions.push({
          icon: "create" as "create",
          tooltip: "Edit",
          onClick(e, data) {
            e.stopPropagation();
            onRowEdit(data);
          },
          color: ButtonColors.White
        });
      }
      if (canDelete) {
        actions.push(
          {
            icon: "clear" as any,
            tooltip: "Delete",
            onClick(e, data) {
              e.stopPropagation();
              handleRowDelete(data);
            },
            color: ButtonColors.White
          }
        );
      }
      if (actions.length) {
        return actions;
      }
      return;
    }

    options.actions?.forEach(action => {
      const { type, ...otherProps } = action;
      if (action.singlePermissions && !evaluate({ conditions: action.singlePermissions })?.apply) {
        return;
      }
      switch (type) {
        // case "AssignUser":
        //   actions.push({
        //     component: AssignUserAction,
        //     refetch: refetch,
        //     ...otherProps
        //   });
        //   break;
        case "SeePhoneMessage": {
          actions.push({
            component: SeePhoneMessageAction,
            width: 100,
            label: "See SMS",
            onClick: action.onClick,
            color: ButtonColors.Primary
          });
          break;
        }
        case "SeeEmail": {
          actions.push({
            component: SeeEmailAction,
            width: 100,
            label: "See Email",
            onClick: action.onClick,
            color: ButtonColors.Primary
          });
          break;
        }
        case "LeadStatus":
          actions.push({
            component: LeadStatusAction,
            onClick: (rowData) => {
              const { destroy } = statusModal({
                data: rowData,
                onSubmit() {
                  destroy();
                  onRowStatus?.(rowData);
                }
              });
            },
            ...otherProps
          });
          break;
        case "Task":
          actions.push({
            component: TaskCreateAction,
            onClick: (rowData) => {
              quickCreateTaskModal({
                data: rowData
              });
            },
            ...otherProps
          });
          break;
        case "Call":
          actions.push({
            component: CallAction,
            ...otherProps
          });
          break;
        case "Mail":
          actions.push({
            component: MailAction,
            onClick: (rowData) => {
              quickSendMailModal({
                data: rowData
              });
            },
            ...otherProps
          });
          break;
        case "SMS":
          actions.push({
            component: SmsAction,
            onClick: (rowData) => {
              quickSendSmsModal({
                data: rowData
              });
            },
            ...otherProps
          });
          break;
        case "Snooze":
          break;
      }
    });

    if (actions.length) {
      return actions;
    }
  }, [canUpdate, canDelete, options.actions]);

  const handleRowDelete = async (obj) => {
    try {
      const entityName = dialogTitles[ classId ] || transformNameToLabel(classId);
      await confirmModal({
        title: "Delete confirmation",
        subTitle: `Are you sure you want to delete ${entityName}?`,
        content: `All information related with this ${entityName} will be lost permanently`,
        label: "DELETE"
      });
      onRowDelete?.(obj);
    } catch (e) {
      console.error(e);
    }
  };
  const onRowEdit = (obj) => {
    modal({
      action: "save",
      className: classId,
      record: obj,
      title: dialogTitles[ obj.__typename ]
    });
  };

  return {
    data,
    total,
    queryFind,
    classId,
    loading,
    variables,
    selectedRows,
    tableHeight,
    setSelectedRows,
    bulkStatusModal,
    bulkUserAssignModal,
    bulkSendMailModal,
    bulkSendPhoneMessageModal,
    bulkActions,
    actions,
    modalContext,
    bulkStatusModalContext,
    bulkUserAssignModalContext,
    bulkSendMailModalContext,
    bulkSendPhoneMessageModalContext,
    statusModalContext,
    quickSendMailModalContext,
    quickSendSmsModalContext,
    quickCreateTaskModalContext
  };
}

const dialogTitles = {
  PhoneMessageTemplate: "SMS template",
  PmiPartner: "PMI partner"
};
