import React           from "react";
import { FC }          from "react";
import { FilterProps } from "./Filter";
import { useFilter }   from "./useFilter";

export type UseFilter = ReturnType<typeof useFilter>;
export const FilterContext = React.createContext<UseFilter>(null);
export const FilterProvider: FC<FilterProps> = React.memo(function FilterProvider(props) {
  const { children, value } = props;
  return <FilterContext.Provider value={value}>
    {children}
  </FilterContext.Provider>;
});
