import { useContext } from "react";
import React          from "react";
import { FC }         from "react";

export interface LoanCriteriaProps {
  product: { type: LoanType, name: string },
  calculate: (s: string) => void
  settings: LoanCriteriaConfig
}
export const LoanCriteriaContext = React.createContext<LoanCriteriaProps>(null);
export const LoanCriteriaProvider: FC<LoanCriteriaProps> = React.memo(function LoanCriteriaProvider({ children, ...other }) {
  return <LoanCriteriaContext.Provider value={other}>
    {children}
  </LoanCriteriaContext.Provider>;
});
export interface LoanCriteriaConfig {
  averageLoanLimit: number;
  fha: {
    mip: number;
    mipDownPaymentBoundary: number;
    purchase: {
      minCreditScore: number;
      minDownPaymentPercent: number;
    };
    refinance: {
      minCreditScore: number;
      minDownPaymentPercent: number;
    }
  };
  conventional: {
    pmiDownPaymentBoundary: number;
    purchase: {
      minCreditScore: number;
      minDownPaymentPercent: number;
    };
    refinance: {
      minCreditScore: number;
      minDownPaymentPercent: number;
    };
  };
  va: {
    purchase: {
      minCreditScore: number;
      minDownPaymentPercent: number;
      firstUseFiveDownFF: number;
      firstUseFiveOrMoreDownFF: number;
      firstUseTenOrMoreDownFF: number;
      secondUseFiveDownFF: number;
      secondUseFiveOrMoreDownFF: number;
      secondUseTenOrMoreDownFF: number;
    };
    refinance: {
      minCreditScore: number;
      minDownPaymentPercent: number;
      streamlineRefinanceFF: number;
      firstUseCashOutRefinanceFF: number;
      secondUseCashOutRefinanceFF: number;
    };
  };

}
export type LoanType = "fha" | "va" | "conventional" ;
