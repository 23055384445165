import { FontIcon }            from "@relcu/ui";
import { ListItemUrl }         from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { ListItemTitle }       from "@relcu/ui";
import { ListItemImage }       from "@relcu/ui";
import { ListItemText }        from "@relcu/ui";
import { useSource }           from "@relcu/ui";
import { useMemo }             from "react";
import React                   from "react";
import { FC }                  from "react";
import { toFirstLower }        from "../../../../utils/helpers";
import { getContactName }      from "../../../../utils/helpers";
import { getObjectPath }       from "../../../../utils/layoutUtils";
import { ActivityViewClasses } from "./ActivityViewClasses";
// action:'call_make' | 'call_join' | 'call_leave' | 'sms_sent' | 'action_trigger' | contact_replace
const ACTION_TYPES = {
  call_make: "called",
  called: "called",
  sms_sent: "sent sms to",
  sent_sms: "sent sms",
  sms_failed: "failed to send sms to",
  call_leave: "left from call with",
  call_join: "answered",
  action_trigger: "triggered",
  assignment: "assigned",
  unassignment: "unassigned",
  distributing: "added",
  contact_replace: "merged",
  take_lead: "took",
  focus_entrance: "entered",
  focus_leaving: "left",
  mail_sent: "sent an email to"
};
interface ActivityViewProps {
  action,
  node,
}
export const ActivityItem: FC<ActivityViewProps> = React.memo((props) => {
  const { action, receiver, meta, subject } = props.node;
  const { $object } = useSource();
  const activityContactName = useMemo(() => {
    return receiver ? getContactName(receiver, $object) : "System";
  }, [receiver, $object]);
  const activityIcon = useMemo(() => {
    return receiver?.objectIcon;
  }, [receiver]);
  return (
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXS"}>
      {
        action == "assignment" &&
        <React.Fragment>
          <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
          {subject &&
          <ListItemUrl
            url={getObjectPath(subject)}
            label={subject.objectName}
            inside
          />
          }
          <ListItemText tiny text={"to"} className={ActivityViewClasses.ActivityViewItemAction}/>
          <ListItemImage text={activityContactName} icon={activityIcon}/>
          <ListItemTitle className={ActivityViewClasses.ActivityViewItemName} title={activityContactName}/>
        </React.Fragment>
      }
      {action == "unassignment" &&
      <React.Fragment>
        <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
        {subject &&
        <ListItemUrl url={getObjectPath(subject)} label={subject.objectName}
                     inside/>}
        <ListItemText tiny text={"from"} className={ActivityViewClasses.ActivityViewItemAction}/>
        <ListItemImage text={activityContactName} icon={activityIcon}/>
        <ListItemTitle className={ActivityViewClasses.ActivityViewItemName} title={activityContactName}/>
      </React.Fragment>
      }
      {(action == "focus_entrance" || action == "focus_leaving") &&
      <React.Fragment>
        {subject &&
        <ListItemUrl url={getObjectPath(subject)} label={subject.objectName}
                     inside/>
        }
        <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
        {meta.message &&
        <ListItemTitle title={meta.message}/>
        }
        <ListItemText tiny text={"section"} className={ActivityViewClasses.ActivityViewItemAction}/>
      </React.Fragment>
      }
      {action == "mail_sent" &&
      <React.Fragment>
        <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
        {meta.message &&
        <ListItemTitle className={`${ActivityViewClasses.ActivityViewItemAction}`} title={meta.message}/>
        }
      </React.Fragment>
      }
      {action == "distributing" &&
      <React.Fragment>
        <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
        {subject &&
        <ListItemUrl url={getObjectPath(subject)} label={subject.objectName}
                     inside/>}
        <ListItemText tiny text={"to the queue"} className={ActivityViewClasses.ActivityViewItemAction}/>
      </React.Fragment>
      }
      {
        action == "sms_sent" &&
        <React.Fragment>
          <ListItemText tiny text={receiver ? ACTION_TYPES[ action ] : ACTION_TYPES[ "sent_sms" ]}
                        className={ActivityViewClasses.ActivityViewItemAction}/>
          {receiver &&
          <React.Fragment>
            <ListItemImage text={activityContactName} icon={activityIcon}/>
            <ListItemTitle
              className={ActivityViewClasses.ActivityViewItemName}
              title={activityContactName}
            />
          </React.Fragment>
          }
        </React.Fragment>
      }
      {
        action == "sms_failed" &&
        <React.Fragment>
          <ListItemText tiny text={receiver ? ACTION_TYPES[ action ] : ACTION_TYPES[ "sms_failed" ]}
                        className={ActivityViewClasses.ActivityViewItemAction}/>
          {receiver &&
          <React.Fragment>
            <ListItemImage text={activityContactName} icon={activityIcon}/>
            <ListItemTitle
              className={ActivityViewClasses.ActivityViewItemName}
              title={activityContactName}
            />
          </React.Fragment>
          }
        </React.Fragment>
      }
      {
        action == "call_make" &&
        <React.Fragment>
          <ListItemText tiny text={receiver ? ACTION_TYPES[ action ] : ACTION_TYPES[ "called" ]}
                        className={ActivityViewClasses.ActivityViewItemAction}/>
          {receiver &&
          <React.Fragment>
            <ListItemImage text={activityContactName} icon={activityIcon}/>
            <ListItemTitle
              className={ActivityViewClasses.ActivityViewItemName}
              title={activityContactName}
            />
          </React.Fragment>
          }
        </React.Fragment>
      }
      {
        action == "call_leave" &&
        <React.Fragment>
          <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
          <ListItemImage text={activityContactName} icon={activityIcon}/>
          <ListItemTitle
            className={ActivityViewClasses.ActivityViewItemName}
            title={activityContactName}
          />

        </React.Fragment>
      }
      {
        action == "call_join" &&
        <React.Fragment>
          <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
          <ListItemImage text={activityContactName} icon={activityIcon}/>
          <ListItemTitle
            className={ActivityViewClasses.ActivityViewItemName}
            title={`${activityContactName}’s`}
          />
          <ListItemText tiny text={"call"} className={ActivityViewClasses.ActivityViewItemAction}/>
        </React.Fragment>
      }
      {
        action == "take_lead" &&
        <React.Fragment>
          <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
          {subject && <>
            <ListItemText tiny text={""} className={ActivityViewClasses.ActivityViewItemAction}/>
            <ListItemUrl url={getObjectPath(subject)} label={subject.objectName}
                         inside/>
          </>}
          <ListItemText
            tiny
            className={`${ActivityViewClasses.ActivityViewItemAction}`}
            text={`from `}
          />
          <ListItemTitle
            className={ActivityViewClasses.ActivityViewItemName}
            title={`"${meta?.queue?.name}"`}
          />
          <ListItemText
            tiny
            className={`${ActivityViewClasses.ActivityViewItemAction}`}
            text={` queue`}
          />
        </React.Fragment>
      }
      {
        action == "action_trigger" &&
        <React.Fragment>
          <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
          <ListItemText
            tiny
            className={`${ActivityViewClasses.ActivityViewItemAction}`}
            text={`"${meta.message}"`}
          />
          {subject && <>
            <ListItemText tiny text={"on"} className={ActivityViewClasses.ActivityViewItemAction}/>
            <ListItemUrl url={getObjectPath(subject)} label={subject.objectName}
                         inside/>
          </>}
        </React.Fragment>
      }
      {
        action == "contact_replace" &&
        <React.Fragment>
          <ListItemText tiny text={ACTION_TYPES[ action ]} className={ActivityViewClasses.ActivityViewItemAction}/>
          <ListItemTitle
            className={`${ActivityViewClasses.ActivityViewItemAction}`}
            title={meta.objectName}
          />
          <ListItemText
            tiny
            className={`${ActivityViewClasses.ActivityViewItemAction}`}
            text={"with"}
          />
          <ListItemImage text={receiver.objectName} icon={receiver.objectIcon}/>
          <ListItemTitle
            className={`${ActivityViewClasses.ActivityViewItemAction}`}
            title={receiver.objectName}
          />
        </React.Fragment>
      }
      {
        action == "custom" &&
        <React.Fragment>
          <ListItemText
            tiny
            className={`${ActivityViewClasses.ActivityViewItemAction}`}
            text={`${meta.message}`}
          />
        </React.Fragment>
      }
      {
        action && action.startsWith("nb_journey") &&
        <ListItemText
          tiny
          className={`${ActivityViewClasses.ActivityViewItemAction}`}
          text={meta.message}
        />
      }
      {
        action && action.startsWith("te_") &&
        <TeActivity action={action} meta={meta}/>
      }
      {
        action && action.startsWith("vl_") &&
        <VlActivity action={action} meta={meta}/>
      }
    </Box>
  );

});

function VlActivity({ action, meta }) {
  switch (action) {
    case "vl_creation": {
      return <ListItemText text={<>Lead Created</>}/>;
    }
    case "vl_distribution": {
      return <ListItemText text={<>Distributed to <b>{meta.to.name}</b> by <b>{meta.by?.name}</b> program</>}/>;
    }
    case "vl_status": {
      return <ListItemText text={<>Status changed to <b>{meta.status.name}</b> by <b>{meta.by?.name}</b> </>}/>;
    }
    case "vl_assignment": {
      return <ListItemText text={<>Assigned to <b>{meta.to?.name}</b> by <b>{meta.by?.name}</b> </>}/>;
    }
    case "vl_action": {
      return <ListItemText
        text={<>Action <b>{meta.action.name}</b> taken <b>{meta.by?.name}</b> - <b>{meta.note}</b></>}/>;
    }
    case "vl_email": {
      return <ListItemText text={<>Email <b>{meta.email.name}</b> send by <b>{meta.by?.name}</b></>}/>;
    }
  }
  return <pre>{action}:{JSON.stringify(meta)}</pre>;
}
function TeActivity({ action, meta }) {
  if (meta.type == "LEAD_ASSIGNED") {
    return <ListItemText text={<>Assigned to <b>{meta.info?.to}</b></>}/>;
  } else if (meta.type == "JOURNEY_TRIGGERED") {
    return <ListItemText text={<>Journey <b>{meta.info?.name}</b> triggered with <b>{meta.info?.event}</b></>}/>;
  } else if (meta.type == "JOURNEY_STATUS_CHANGED") {
    return <ListItemText text={<>Status changed to <b>{meta.info?.status}</b></>}/>;
  } else if (meta.type == "EMAIL_SENT") {
    return <ListItemText text={<>Email <a target={"_blank"} href={meta.info?.link}>{meta.info?.subject}</a> sent</>}/>;
  } else if (meta.type == "EMAILED_LINK_CLICKED") {
    return <ListItemText text={<>Email <a target={"_blank"} href={meta.info.link}>link</a> clicked</>}/>;
  } else if (meta.type == "EMAIL_UNSUBSCRIBED") {
    return <ListItemText text={<><FontIcon type={"warning"}/>{meta.info.reason}</>}/>;
  } else if (meta.type == "EMAIL_DELIVERED") {
    return <ListItemText text={<>Email <b>{meta.info?.subject}</b> delivered</>}/>;
  } else if (meta.type == "EMAIL_DELIVERY_FAILURE") {
    return <ListItemText text={<><FontIcon type={"warning"}/>Email delivery failed</>}/>;
  } else if (meta.type == "SPAM_COMPLAINT") {
    return <ListItemText text={<><FontIcon type={"warning"}/>Email marked as spam</>}/>;
  } else if (meta.type == "EMAIL_BOUNCED") {
    return <ListItemText text={<><FontIcon type={"warning"}/>Email bounced: {meta.info.reason}</>}/>;
  } else if (meta.type == "JOURNEY_ENDED") {
    return <ListItemText text={<>Journey <b>{meta.info?.name}</b> ends.</>}/>;
  }
  return <pre>{JSON.stringify(meta)}</pre>;
}
