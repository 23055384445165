import { FC }                from "react";
import React                 from "react";
import { Link }              from "@relcu/react-router";
import { classNames }        from "../../..";
import { Box }               from "../../..";
import { BoxComponentProps } from "../../..";
import { Tooltip }           from "../../..";
import { Alignment }         from "../../../constants/Alignemnt";
import { GlobalClasses }     from "../../../constants/GlobalClasses";

export interface ListItemUrlProps extends BoxComponentProps {
  url: string
  label?: string
  inside?: boolean
}

export const ListItemUrl: FC<ListItemUrlProps> = React.memo(function ListItemUrl(props) {
  const { className, url, label, inside, ...p } = props;
  const classes = classNames(ListItemUrlClasses.ListItemUrl, className);
  return <Box container className={classes} {...p}>
    {
      inside ?
        <Link to={url}>
          {label}
        </Link>
        :
        <Tooltip title={url} alignment={Alignment.Top}>
          <a href={url} className={GlobalClasses.EllipsisFlex}>
            {label || url}
          </a>
        </Tooltip>
    }
  </Box>;
});
ListItemUrl.defaultProps = {
  inside: false
};
export enum ListItemUrlClasses {
  ListItemUrl = "list-item-url"
}
