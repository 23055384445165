import { FC }                    from "react";
import React                     from "react";
import { Radio }                 from "../../..";
import { Label }                 from "../../..";
import { Box }                   from "../../..";
import { useImperativeState } from "../../..";
import { classNames }         from "../../..";
import { applyBoxItemStyles } from "../../Box";
import { BoxItemComponentProps } from "../../Box";
import { BaseInputClasses }      from "../BaseInput";
import { InputState }            from "../Input";

export interface RadioGroupProps extends BoxItemComponentProps {
  label?: string;
  value?: number | string | object
  disabled?: boolean
  name: string;
  state?: InputState;
  required?: boolean;
  alignment?: "left" | "right"
  message?: string;//todo (Artur)
  options?: (string | object | number)[];
  optionKey?: string;
  optionLabel?: string;
  halfSize?: boolean;
  fullSize?: boolean;
  inputProps?: { [ key: string ]: any; };
  InputProps?: { [ key: string ]: any; };
  onChange?(selected);
  onBlur?();
  onFocus?();
}

export const RadioGroup: FC<RadioGroupProps> = React.memo(function RadioGroup(props) {
  let properties = applyBoxItemStyles<RadioGroupProps>(props);
  const {
    className,
    children,
    label,
    value,
    disabled,
    state,
    required,
    message,
    name,
    options,
    optionLabel,
    optionKey,
    onChange,
    alignment,
    halfSize,
    fullSize,
    inputProps,
    InputProps,
    ...p
  } = properties;

  const classes = classNames(BaseInputClasses.Input, {
    [ BaseInputClasses.Error ]: state == InputState.Error,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize
  }, className);
  const [check, setCheck] = useImperativeState(value, onChange);
  return <Box container className={classes} direction={"column"} gap={"XXXS"} {...p}>
    {label && <Label required={required}>{label}</Label>}
    <Box container direction={"column"} gap={"XXS"}>
      {
        options ?
          options.map((option, index) => {
            let label = typeof option == "object" ? (option[ optionLabel ] || "") : option;
            let checked =
              typeof option == "object" ?
                option[ optionKey ] != undefined &&
                check[ optionKey ] != undefined &&
                option[ optionKey ] === check[ optionKey ] :
                option != undefined &&
                check != undefined &&
                option == check;
            return <Radio
              inputProps={inputProps}
              InputProps={InputProps}
              alignment={alignment}
              disabled={disabled}
              key={index}
              label={label}
              checked={checked}
              state={state}
              onChange={() => setCheck(option == "object" ? option[ optionKey ] : option)}/>;
          })
          :
          React.Children.map(children, child => {
            return React.cloneElement(child as any, {
              onChange: setCheck,
              name: name
            });
          })
      }
    </Box>
    <Box className={BaseInputClasses.Message}>{message}</Box>{/*todo limit massage width*/}
  </Box>;
});

RadioGroup.defaultProps = {
  value: []
};
