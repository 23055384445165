import React                        from "react";
import { RsRefForwardingComponent } from "../../@types/common";
import { Typography }               from "../../index";
import { Cell }                     from "../../TableHorizontal";
import { CellProps }                from "../../TableHorizontal/Cell";
import { useClassNames }            from "../../utils";

export interface ReadCellProps extends CellProps {
  children?: React.ReactNode;
  value?: any;
}

export const ReadCell: RsRefForwardingComponent<"div", ReadCellProps> = React.forwardRef((props: ReadCellProps, ref) => {
  const {
    as: Component = Typography,
    className,
    classPrefix = "cell-read",
    helperText,
    status,
    icon,
    span,
    affected,
    onAnimationEnd,
    children,
    value,
    disabled,
    justify,
    space = true,
    style,
    inert,
    ...rest
  } = props;

  const { withClassPrefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix({ disabled })
  );

  return <Cell status={status} helperText={helperText} space={space} span={span} className={classes} icon={icon}
               onAnimationEnd={onAnimationEnd} affected={affected} justify={justify} style={style} inert={inert}>
    <Component
      {...rest}>
      {children || value || "—"}
    </Component>
  </Cell>;
});
