export enum NavBarClasses {
  NavBar = "nav-bar",
  NavBarItem = "nav-bar__item",
  NavBarActiveItem = "nav-bar__item--active",//todo to not hover active link need to change
  NavBarItemActions = "nav-bar__item-actions",
  NavBarItemSelect = "nav-bar__item-select",
  NavBarItemContent = "nav-bar__item-content",
  NavBarSubtitle = "nav-bar__subtitle",
  NavBarMentions = "nav-bar__mentions",
  NavBarEmptyWatching = "nav-bar--empty-watching",
  NavBarEmptyCurrent = "nav-bar--empty-current",
  NavBarDivider = "nav-bar__divider",
  Draggable = "draggable",
  WithCounter = "with-counter",
  DragIcon = "drag-icon",
  Small = "small",
}