import React                   from "react";
import { FC }                  from "react";
import { useMemo }             from "react";
import { SelectPicker }        from "@relcu/rc";
import { usePointerListField } from "../../../../Field/PointerListField/usePointerListField";
import { PointerPickerProps }  from "../../../../Field/PointerPicker";

export interface ContactPreviewProps extends PointerPickerProps {
  scope?: string;
}

export const ContactPreview: FC<ContactPreviewProps> = React.memo(function ContactPreview(props) {
  const [className, objectId] = atob(props.scope).split(":");
  const {
    options: scopes,
    setQ
  } = usePointerListField({
    targetClass: props.scope ? className : "Contact",
    filters: props.scope ? {
      objectId: {
        equalTo: objectId
      }
    } : null,
    fields: props.scope ? [{ "members": [{ "contact": ["id", "objectId", "objectName"] }] }] : []
  });

  const data = useMemo(() => {
    if (props.scope) {
      const data = [];
      scopes[ 0 ]?.members?.map(member => {
        data.push(member.contact);
      });
      return data;
    } else {
      return scopes;
    }
  }, [scopes]);

  return <SelectPicker
    style={{ width: "100%" }}
    cleanable={true}
    labelKey={"objectName"}
    valueKey={"objectId"}
    value={props.value}
    data={data}
    onSearch={(searchKeyword) => setQ(searchKeyword)}
    onChange={props.onChange}
  />;
});
