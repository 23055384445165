import React               from "react";
import { FontIcon }        from "../../Icon";
import { InputState }      from "../../Input/Input";
import { Input }           from "../../Input/Input";
import { useConsumeField } from "../BaseField";
import { TextFieldProps }  from "../TextField";

export const EmailEditField = React.memo(function EmailEditField(props) {
  const {
    input,
    meta: { touched, error, submitError, modifiedSinceLastSubmit }
  } = useConsumeField<TextFieldProps>();
  const hasError = (touched && !!error) || (!modifiedSinceLastSubmit && !!submitError);
  return (
    <Input
      {...input}
      icon={<FontIcon type={"email"}/>}
      state={(hasError && InputState.Error) || (input.warning && InputState.Warning)}
      message={hasError ? error : input.warning}
    />
  );
});

export default EmailEditField;
