import { useRef }        from "react";
import React             from "react";
import { FC }            from "react";
import { useMemo }       from "react";
import { useMutation }   from "@apollo/client";
import { useFieldArray } from "@relcu/final-form";
import { useClassNames } from "@relcu/rc";
import { Typography }    from "@relcu/rc";
import { useSource }     from "@relcu/ui";
import { useAlert }      from "@relcu/ui";
import { useModal }      from "@relcu/ui";
import { toFirstLower }           from "@relcu/ui";
import { GenerationType }         from "../../../types/graphql-global-types";
import { GenerateFlyerVariables } from "../../__types__/GenerateFlyer";
import { GenerateFlyer }          from "../../__types__/GenerateFlyer";
import { FlyerDialog }            from "../../Layout/Dialog/FlyerDialog/FlyerDialog";
import { GENERATE_FLYER }         from "../../operations.graphql";

export const FlyerAttachment: FC<{ disableFlyer: boolean, onAddFlyer(flyer) }> = React.memo(function FlyerAttachment(props) {
  const { disableFlyer } = props;
  const { info } = useAlert();
  const { prefix } = useClassNames("field-toggle", "rc");
  const { $object: node, $viewer: user } = useSource();
  const [generateFlyer] = useMutation<GenerateFlyer, GenerateFlyerVariables>(GENERATE_FLYER);
  const attachment = useFieldArray("attachments");
  const keysRef = useRef(attachment.fields.length ? [0] : []);

  const flyerFilters = useMemo(() => {
    const filters: Record<string, any> = {
      enabled: { equalTo: true },
      availableForType: { equalTo: "sms" },
      availableFor: { equalTo: "manual" },
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: user.id
              }
            }
          }
        }
      ]
    };
    const typename = toFirstLower(node.__typename);
    if (node.__typename != "User") {
      if (filters.AND) {
        filters[ "AND" ].push({
          applyTo: { equalTo: typename }
        });
      } else {
        filters.applyTo = { equalTo: typename };
      }
    }
    return filters;
  }, [node.__typename, user.id]);
  const [modal, modalContext] = useModal(FlyerDialog);

  const handleFlyerAttachment = () => {
    const { destroy } = modal({
      filters: flyerFilters,
      onInsert: async (flyer) => {
        destroy();
        if (!flyer) {
          return;
        }
        info("PDF or image generation can take up to 30 seconds.");
        const file = await generateFlyer({
          variables: {
            generationType: GenerationType.image,
            flyer,
            scope: node.id
          }
        });
        props.onAddFlyer(file.data.generateFlyer)
      }
    });
  };

  return (<>
    {modalContext}
    <Typography
      style={{ alignSelf: "center", opacity: disableFlyer ? 0.4 : 1 }}
      color={"tertiary"}
      variant={"base14"}
      className={prefix`action`}
      onClick={() => disableFlyer ? null : handleFlyerAttachment()}>
      Flyer attachment
    </Typography>
  </>);
});
