import React                from "react";
import { FC }               from "react";
import { Box }              from "../../Box";
import { FontIcon }         from "../../Icon";
import { MicrophoneAccess } from "../../Microphone";
import { ModalProps }       from "../Modal";
import { ModalBody }        from "../Modal";
import { Modal }            from "../Modal";

import { MicrophoneAccessDialogClasses } from "./MicrophoneAccessDialogClasses";

export interface MicrophoneAccessDialogProps extends Partial<ModalProps> {
  onClose?(e?),
}
export const MicrophoneAccessDialog: FC<MicrophoneAccessDialogProps> = React.memo((props) => {
  return (
    <Modal title={"Enable microphone"} onClose={props.onClose} open={props.open} closeIcon={props.closeIcon}
           disableBackdropClick={true}>
      <MicrophoneAccess/>
    </Modal>
  );
});
MicrophoneAccessDialog.defaultProps = {
  closeIcon: false
};
export default MicrophoneAccessDialog;

