import React                         from "react";
import { FC }                        from "react";
import { classNames }                from "@relcu/ui";
import { BoxComponentProps }         from "@relcu/ui";
import { Box }                       from "@relcu/ui";
import { ListClasses }               from "./ListClasses";
import { ListEmailConversationItem } from "./ListItem/ListEmailConversationItem";
import "./list.css";

export enum ListItemTypes {
  Email = "email",
  Conversation = "conversation",
}
export interface ListProps extends BoxComponentProps {
  objects?: any[]
  type?: ListItemTypes
  header?: React.ReactNode
}

export const List: FC<ListProps> = React.memo(function List(props) {
  const { className, children, objects, type, header, ...p } = props;
  const classes = classNames(ListClasses.List, className);
  return <Box container direction={"column"} className={classes} {...p}>
    {header}
    {
      children ??
      objects?.map((obj) => {
        switch (type) {
          case "email":
            return <ListEmailConversationItem key={obj.id} conversation={obj}/>;
        }
      })
    }
  </Box>;
});
