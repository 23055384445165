import { useMemo }             from "react";
import React                   from "react";
import { FC }                  from "react";
import Editor                  from "@monaco-editor/react";
import { FieldArray }          from "@relcu/form";
import { Field }               from "@relcu/form";
import { Form }                from "@relcu/form";
import { SwitchField }         from "@relcu/ui";
import { ButtonVariants }      from "@relcu/ui";
import { ButtonColors }        from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { EmptyList }           from "@relcu/ui";
import { useSource }           from "@relcu/ui";
import { defaultMutators }     from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { Section }             from "@relcu/ui";
import { TextField }           from "@relcu/ui";
import { MailRenderer }        from "../../../ContentVisualizer";
import { SignatureUserSelect } from "./SignatureUserSelect";
import { useEmailSignature }   from "./useEmailSignature";
import "./useEmailSignature";

export const EmailSignatureView: FC<any> = React.memo(function EmailSignatureView(props) {
  const { title } = props;
  const { $settings } = useSource();
  const { addSignature, handleSubmit, handleDelete, user } = useEmailSignature();
  const initial = useMemo(() => {
    return {
      emailSignature: $settings?.emailSignature,
      user
    };
  }, [$settings?.emailSignature?.name, $settings?.emailSignature?.enabledRelcu, $settings?.emailSignature?.enabledMicrosoftGmail, $settings?.emailSignature?.signature, $settings?.emailSignature?.length, user]);
  return <Form
    mutators={defaultMutators}
    onSubmit={handleSubmit}
    initialValues={initial}>
    {({ handleSubmit, form }) =>
      <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
        <FieldArray name={"emailSignature"}>
          {({ fields }) => {
            return (!fields?.value || !fields?.value?.length)
              ?
              <Section title={title} view={"read"} editable={false}>
                <Box container gap={"XXS"} direction={"column"} id={"street-view"} flexBasis={"100%"}
                     justify={"center"}>
                  <EmptyList icon={"alternate_email"}
                             content={`Create new email signature that will be automatically added to the emails of all users.`}
                             title={`No email signature`}
                             flexGrow={1}/>
                  <Button
                    alignSelf="center"
                    onClick={() => addSignature(fields)}
                    color={ButtonColors.Primary}>
                    CREATE EMAIL SIGNATURE
                  </Button>
                </Box>
              </Section>
              :
              <Box container gap={"XS"} flex={1} style={{ overflowY: "hidden" }}>
                {
                  fields.map((name, index) => {

                    return <React.Fragment key={name}>
                      <Section view={"read"} editable={false} title={title} gap={"XS"}
                               flex={"1 0 calc(50% - 16px)"} bodyProps={{ flex: 1 }}>
                        <Box container direction={"column"} gap={"XS"} alignSelf={"stretch"} flex={1}>
                          <Box container gap={"XS"}>
                            <TextField
                              name={`${name}.name`}
                              label={"Signature name"}
                              placeholder={"Enter signature name"}
                              flex={1}
                            />
                            <SwitchField label={"Relcu enabled"} name={`${name}.enabledRelcu`}/>
                            <SwitchField label={"Microsoft/Gmail enabled"} name={`${name}.enabledMicrosoftGmail`}/>
                          </Box>
                          {/*<MultiTextField*/}
                          {/*  name={`${name}.signature`}*/}
                          {/*  flex={1}*/}
                          {/*  inputProps={{ style: { height: "100%", border: "none" } }}*/}
                          {/*/>*/}
                          <Field name={`${name}.signature`}>
                            {({ input: { value, onChange } }) => {
                              return <Editor
                                defaultLanguage={"handlebars"}
                                value={value}
                                onChange={onChange}
                                options={{ minimap: { enabled: false } }}
                              />;
                            }}
                          </Field>
                          <Box container gap={"XXS"} justify={"end"}>
                            <Button color={ButtonColors.Error} variant={ButtonVariants.Ghost} onClick={handleDelete}>
                              DELETE SIGNATURE
                            </Button>
                            <Button type={"submit"}>SAVE</Button>
                          </Box>
                        </Box>
                      </Section>
                      <Section view={"read"}
                               editable={false}
                               title={"Preview"}
                               flex={"1 0 calc(50% - 16px)"}
                               style={{ overflowY: "scroll" }}
                               bodyProps={{ direction: "column" }}
                      >
                        <SignatureUserSelect name={"user"}/>
                        <Field name={"user"}>
                          {({ input: { value: $user } }) => {
                            return <Field name={`${name}.signature`}>
                              {({ input: { value } }) => {
                                return <Box style={{ overflow: "auto", maxWidth: "100%" }}>
                                  <MailRenderer template={value} ids={{ userId: $user.objectId }}/>
                                </Box>;
                              }}
                            </Field>;
                          }}
                        </Field>
                      </Section>
                    </React.Fragment>;
                  })
                }
              </Box>;
          }}
        </FieldArray>
      </form>
    }
  </Form>;
});
