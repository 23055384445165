import React                   from "react";
import { GlobalClasses }       from "../../constants/GlobalClasses";
import { CommonClasses }       from "../../theme/classes";
import { BoxComponentProps }   from "../Box";
import { Box }                 from "../Box";
import { FontIcon }            from "../Icon";
import { TypographyColor }     from "../Typography";
import { Typography }          from "../Typography";
import { TimelineItemClasses } from "./TimelineItemClasses";

export interface TimelineItemHeaderContentProps extends BoxComponentProps {
  withAttachment: boolean
}

export const TimelineItemHeaderContent = React.memo<TimelineItemHeaderContentProps>(({ withAttachment, children }) => {
  return <Box container alignItems={"center"} justify={"space-between"} flex={1} gap={"XS"} className={TimelineItemClasses.TimelineItemOverflow}>
    <Typography className={GlobalClasses.EllipsisFlex} color={TypographyColor.Secondary}>{children}</Typography>
    {withAttachment && <FontIcon type={"attachment"} style={{fontSize: 20}} className={CommonClasses.GrayIcon}/>}
  </Box>;
});