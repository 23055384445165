import React                  from "react";
import { FC }                 from "react";
import { classNames }         from "../../../../utils/classNames";
import { Box }                from "../../../Box";
import { EmailHeaderClasses } from "./EmailHeader";

export const EmailHeaderContent: FC<any> = React.memo(function EmailHeaderContent(props) {
  const {className, ...p} = props;
  const classes = classNames(EmailHeaderClasses.HeaderBody, className)

  return <Box container direction={"column"} gap={"XXS"} className={classes} {...p}/>;
});

