import React             from "react";
import { FC }            from "react";
import { StepContainer } from "@relcu/ui";
import { StepInterface } from "@relcu/ui";
import { StepHeader }    from "@relcu/ui";
import { StepBody }      from "@relcu/ui";
import { Caps }          from "../../Caps/Caps";

export const Limits: FC<StepInterface> = React.memo(function Limits(props) {
  const { title, description, step } = props;
  return <StepContainer>
    <StepHeader
      step={step}
      title={title}
      description={description}
      edit={false}/>
    <StepBody>
      <Caps name={`limits`} title={"Caps and cooldown"} coolDownIncluded/>
    </StepBody>
  </StepContainer>;
});
