import React                                 from "react";
import { FC }                                from "react";
import { FontIcon }                          from "@relcu/ui";
import { Ellipsis }                          from "@relcu/ui";
import { GlobalClasses as QuiGlobalClasses } from "@relcu/ui";
import { BadgeVariants }                     from "@relcu/ui";
import { Badge }                             from "@relcu/ui";
import { ListItemDate }                      from "@relcu/ui";
import { AvatarSizes }                       from "@relcu/ui";
import { Avatar }                            from "@relcu/ui";
import { classNames }                        from "@relcu/ui";
import { BoxComponentProps }                 from "@relcu/ui";
import { Box }                               from "@relcu/ui";
import { useWindowDimensions }               from "@relcu/ui";
import { CommonClasses }                     from "@relcu/ui";
import { ListItemClasses }                   from "./ListItemClasses";
import "./list-item.css"

export interface ListEmailConversationItemProps extends BoxComponentProps {
  selected?: boolean
  conversation: any
}
export const ListEmailConversationItem = React.forwardRef<any,ListEmailConversationItemProps>(
  function ListEmailConversationItem(props,ref) {
    const { className, children, conversation, selected, ...p } = props;
    const { width } = useWindowDimensions();
    const classes = classNames(ListItemClasses.ListItem, {
      [ ListItemClasses.ListItemActive ]: !!conversation.unreadCount,
      [ ListItemClasses.ListItemSelected ]: selected
    }, className);

    return <Box ref={ref} container direction={"column"} gap={"XXXS"} className={classes} {...p}>
      <Box container gap={"XXS"} alignItems={"center"}>
        <Avatar icon={conversation?.icon} text={conversation?.name} size={AvatarSizes.Small}/>
        {(width >= 1500) &&
        <Ellipsis from={20} className={ListItemClasses.ListItemText}>
          {conversation?.name}
        </Ellipsis>}
        <Box flex={1}/>
        {
          !!conversation?.attachmentsCount &&
          <FontIcon type={"attachment"} className={CommonClasses.GrayIcon}/>
        }
        <ListItemDate date={conversation.dateCreate} format={"withTime"}/>
        {
          !!conversation.unreadCount &&
          <Badge variant={BadgeVariants.Dot}/>
        }
      </Box>
      <Box container gap={"XXS"}>
        <p className={classNames(ListItemClasses.ListItemText, QuiGlobalClasses.EllipsisFlex)}>
          {conversation.title}
        </p>
        <Box flex={1}/>
      </Box>
      <p
        className={classNames(ListItemClasses.ListItemContent, QuiGlobalClasses.EllipsisFlex)}
      >
        {conversation.content || "No content"}
      </p>
    </Box>;
  }
);
