import React                            from "react";
import { ApolloLink }                   from "@apollo/client";
import { OperationDefinitionNode }      from "graphql";
import { ErrorBoundary }                from "@relcu/ui";
import { AlertOptions }                 from "@relcu/ui";
import { AlertMessage }                 from "@relcu/ui";
import { getMainDefinition }            from "@apollo/client/utilities";
import { createClient }                 from "graphql-ws";
import { Client as Connection }         from "graphql-ws";
import { modal }                        from "@relcu/ui";
import { OfflineDialog }                from "@relcu/ui";
import { ServerError }                  from "@apollo/client";
import { InMemoryCache }                from "@apollo/client";
import { NormalizedCacheObject }        from "@apollo/client";
import { ApolloClient }                 from "@apollo/client/core/ApolloClient";
import { ErrorResponse }                from "@apollo/client/link/error";
import { onError }                      from "@apollo/client/link/error";
import { GraphQLWsLink }                from "@apollo/client/link/subscriptions";
import { NetworkStatusLink }            from "@relcu/network-status";
import { de }                           from "suneditor/src/lang";
import { ErrorCode }                    from "./graph/ErrorCode";
import { typeDefs }                     from "./graph/typeDefs";
import { typePolicies }                 from "./graph/typePolicies";
import { microphoneAccessVar }          from "./reactiveVars";
import { AudioDevicesVar }              from "./reactiveVars";
import { AudioDevice }                  from "./reactiveVars";
import { audioNotificationOutputIdVar } from "./reactiveVars";
import { audioDevicesVar }              from "./reactiveVars";
import { audioInputIdVar }              from "./reactiveVars";
import { audioOutputIdVar }             from "./reactiveVars";
import { versionVar }                   from "./reactiveVars";
import { isAuthenticatedVar }           from "./reactiveVars";
import { leadPricingVar }               from "./reactiveVars";
import { deviceVar }                    from "./reactiveVars";
import { soundVar }                     from "./reactiveVars";
import { loadingVar }                   from "./reactiveVars";
import { schemaVar }                    from "./reactiveVars";
import { layoutVar }                    from "./reactiveVars";
import { Sound }                        from "./Sound";
import { Tw }                           from "./Tw";
import { ISchemas }                     from "./types/ISchemas";
import { fetchJson }                    from "./utils/helpers";
import { uuid }                         from "./utils/helpers";
import { loadConfig }                   from "./utils/loadConfig";

interface ClientWithOnReconnected extends Connection {
  onReconnected(cb: () => void): () => void;
}

export class SoundPreferences extends EventTarget {
  #client: Client;
  set #inputDevice(device: AudioDevice) {
    localStorage.setItem("audioInput", JSON.stringify(device));
    audioInputIdVar(device.deviceId);
    this.dispatchEvent(new CustomEvent("change"));
  }
  get #inputDevice() {
    const device = localStorage.getItem("audioInput");
    if (device) {
      return JSON.parse(device);
    }

  }
  set #outputDevice(device: AudioDevice) {
    localStorage.setItem("audioOutput", JSON.stringify(device));
    audioOutputIdVar(device.deviceId);
    this.dispatchEvent(new CustomEvent("change"));
  }
  get #outputDevice() {
    const device = localStorage.getItem("audioOutput");
    if (device) {
      return JSON.parse(device);
    }
  }
  set #notificationOutputDevice(device: AudioDevice) {
    localStorage.setItem("audioNotificationOutput", JSON.stringify(device));
    audioNotificationOutputIdVar(device.deviceId);
    this.dispatchEvent(new CustomEvent("change"));
  }
  get #notificationOutputDevice() {
    const device = localStorage.getItem("audioNotificationOutput");
    if (device) {
      return JSON.parse(device);
    }
  }

  constructor(client: Client) {
    super();
    this.#client = client;
    navigator.mediaDevices.addEventListener("devicechange", () => this.#selectDevices());
  }
  async init() {
    await this.#selectDevices(true);
    this.#checkAccessToStopMedia().catch(console.error);
  }
  changeInputDevice(device: AudioDevice, silent: boolean = false) {
    this.#inputDevice = device;
    if (!silent) {
      this.#client.info(`Your microphone updated to "${device.label}"`, {
        width: 440,
        icon: "record_voice_over"
      });
    }
  }
  changeOutputDevice(device: AudioDevice, silent: boolean = false) {
    this.#outputDevice = device;
    if (!silent) {
      this.#client.info(`Your speaker updated to "${device.label}"`, {
        width: 440,
        icon: "volume_up"
      });
    }
  }
  changeNotificationOutputDevice(device: AudioDevice, silent: boolean = false) {
    this.#notificationOutputDevice = device;
    if (!silent) {
      this.#client.info(`Your notification speaker updated to "${device.label}"`, {
        width: 440,
        icon: "volume_up"
      });
    }
  }
  async #checkAccessToStopMedia() {
    const microphonePermission = await navigator.permissions.query({ name: "microphone" as any });
    if (microphonePermission.state != "granted") {
      await navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        stream.getTracks().forEach(track => track.stop());
      }).catch(e => console.error(e));
    }
    if (microphonePermission.state == "denied") {
      microphoneAccessVar(false);
    }
    microphonePermission.onchange = () => {
      if (microphonePermission.state == "denied") {
        this.#clean();
        microphoneAccessVar(false);
      }
      if (microphonePermission.state == "granted") {
        microphoneAccessVar(true);
      }
      this.#selectDevices(true);
    };
    await this.#selectDevices(true);
  }
  #clean() {
    localStorage.removeItem("audioOutput");
    localStorage.removeItem("audioInput");
    localStorage.removeItem("audioNotificationOutput");

  }
  async #enumerateDevices() {
    const sortDevices = (devices: AudioDevice[]): AudioDevicesVar => {
      const audioInput = new Map();
      const audioOutput = new Map();
      devices.forEach(device => {
        device.kind == "audiooutput" ?
          !audioOutput.has(device.label) && audioOutput.set(device.label, device)
          :
          !audioInput.has(device.label) && audioInput.set(device.label, device);
      });//not used groupId because in that case default devices lost

      return {
        audioinput: Array.from(audioInput.values()),
        audiooutput: Array.from(audioOutput.values())
      };
    };
    const media = await navigator.mediaDevices.enumerateDevices();
    const mediaDevices = media.filter((device) => (device.kind === "audioinput" || device.kind === "audiooutput") && (device?.deviceId && device.label));
    const sortedDevices = sortDevices(mediaDevices);
    audioDevicesVar(sortedDevices);
    return sortedDevices;
  }
  async #selectDevices(silent = false) {
    const devices = await this.#enumerateDevices();
    this.#chooseInput(devices, silent);
    this.#chooseOutput(devices, silent);
    this.#chooseNotificationOutput(devices, silent);
  }
  #chooseInput(devices, silent) {
    if (devices.audioinput.length) {
      const inputDevice = this.#inputDevice;
      if (!inputDevice || !devices.audioinput.find(device => inputDevice.deviceId == device?.deviceId)) {
        let hasActiveCall = (this.#client.tw?.device?.isBusy || this.#client.tw?.active);
        this.changeInputDevice(devices.audioinput[ 0 ], !hasActiveCall);
      } else {
        audioInputIdVar(inputDevice.deviceId);
      }
    } else if (!silent && this.#inputDevice) {
      this.#client.warning(`Your microphone "${this.#inputDevice.label}" is disconnected`, {
        width: 440,
        icon: "record_voice_over"
      });
    }
  }
  #chooseOutput(devices, silent) {
    if (devices.audiooutput.length) {
      const outputDevice = this.#outputDevice;
      if (!outputDevice || !devices.audiooutput.find(device => outputDevice.deviceId == device?.deviceId)) {
        this.changeOutputDevice(devices.audiooutput[ 0 ], silent);
      } else {
        audioOutputIdVar(outputDevice.deviceId);
      }
    } else if (!silent && this.#outputDevice) {
      this.#client.warning(`Your speaker "${this.#outputDevice.label}" is disconnected`, {
        width: 440,
        icon: "volume_up"
      });
    }
  }
  #chooseNotificationOutput(devices, silent) {
    if (devices.audiooutput.length) {
      const notificationOutputDevice = this.#notificationOutputDevice;
      if (!notificationOutputDevice || !devices.audiooutput.find(device => notificationOutputDevice.deviceId == device?.deviceId)) {
        this.changeNotificationOutputDevice(devices.audiooutput[ 0 ], silent);
      } else {
        audioNotificationOutputIdVar(notificationOutputDevice.deviceId);
      }
    } else if (!silent && this.#notificationOutputDevice) {
      this.#client.warning(`Your speaker "${this.#notificationOutputDevice.label}" is disconnected`, {
        width: 440,
        icon: "volume_up"
      });
    }
  }

}

export class Client {
  static get #wsServers() {
    return {
      gql: window.__CONFIG__.wss,
      voice: window.__CONFIG__.wssVoice
    };
  }
  #connection: ClientWithOnReconnected;
  #tw: Tw;
  #soundPreferences: SoundPreferences;
  #sound: Sound;
  #retryTime: number = 1000 + Math.floor(Math.random() * 1000);
  #retryAttempts: number = 0;
  #networkStatusLink: NetworkStatusLink;
  #errorBoundaryRef: ErrorBoundary;
  #client: ApolloClient<NormalizedCacheObject>;
  #offline: { destroy: (e?) => void, update };
  #inputDevice: AudioDevice;
  #outputDevice: AudioDevice;
  #notificationOutputDevice: AudioDevice;
  #accept: Function;
  #timer: NodeJS.Timeout;

  constructor() {
    this.logOut = this.logOut.bind(this);
    this.login = this.login.bind(this);
  }

  get deviceId() {
    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
      localStorage.setItem("deviceId", deviceId = uuid());
    }
    return deviceId;
  }
  get sessionId() {
    let sessionId = sessionStorage.getItem("sessionId");
    if (!sessionId) {
      sessionStorage.setItem("sessionId", sessionId = `${Math.random()}`.substring(2));
    }
    return sessionId;
  }
  get client() {
    return this.#client;
  }
  get soundPreferences() {
    return this.#soundPreferences;
  }
  get tw() {
    return this.#tw;
  }
  get version() {
    return versionVar() || window.__CONFIG__.versions.web;
  }
  async #setupLayout() {
    try {
      const layouts = await fetchJson("layout.json");
      layoutVar(layouts);
      return layouts;
    } catch (e) {
      console.error(e);
    }
  }
  async #setupSchema() {
    try {
      const schema = await fetchJson("schema.json");
      window["__SCHEMA__"] =schema
      schemaVar(schema);
      return schema;
    } catch (e) {
      console.error(e);
    }
  }
  // #setupSound() {
  //   const sound = soundVar();
  //   if (!sound) {
  //     this.#sound = new Sound();
  //     soundVar(this.#sound);
  //   }
  // }

  async setup() {
    if (this.#client) {
      return;
    }
    if (!this.#connection) {
      this.#connection = await this.#createWsClient();
    }
    await this.#createClient();
    this.#soundPreferences = new SoundPreferences(this);
    await this.#soundPreferences.init();
    this.#tw = new Tw(this);
    this.#sound = new Sound(this);
  };
  #createWsClient() {
    let abruptlyClosed = false;
    let offlineClosed = false;
    const reconnectedCbs: (() => void)[] = [];
    const connection = createClient({
      url: Client.#wsServers[ "gql" ],
      connectionParams: { version: this.version },
      keepAlive: 10000,
      retryAttempts: Infinity,
      lazy: false,
      retryWait: (retries) => {
        this.#retryAttempts = retries;
        let retryDelay = 1000; // start with 1s delay
        for (let i = 0; i < this.#retryAttempts; i++) {
          retryDelay *= 2;
        }
        this.#retryTime = this.#retryAttempts > 6 ? 60000 : retryDelay + Math.floor(Math.random() * 1000);
        return new Promise((resolve) => {
          this.#accept = resolve;
          this.#timer = setTimeout(
            resolve,
            this.#retryTime
          );
        });
      },
      shouldRetry: (errOrCloseEvent: CloseEvent) => {
        return errOrCloseEvent.code != 1000;
      },
      on: {
        // ping:()=>{
        //   console.log("On Ping")
        // },
        // pong:()=>{
        //   console.log("On Pong")
        // },
        message: (message) => {
          if (message.type == "error" && message.id == "re-auth") {
            const token = message.payload.at(0)[ "token" ];
            this.attempt(`Bearer ${token}`).catch(console.error);
          }
        },
        connecting: () => {
          loadingVar(true);
        },
        connected: (socket: WebSocket, payload: Record<string, string>) => {
          console.log("Connected payload", payload);
          if (abruptlyClosed) {
            abruptlyClosed = false;
            reconnectedCbs.forEach((cb) => cb());
          }
          offlineClosed = false;
          loadingVar(false);
          isAuthenticatedVar(true);
          this.#checkVersion(payload);
          // this.#phoneConnection = this.#createVoiceClient()
        },
        error: (error: ErrorEvent) => {
          console.log("On Error", error);
          if (error.message) {
            this.#errorBoundaryRef.error(error.message);
          }
          loadingVar(false);
        },
        closed: (event: CloseEvent) => {
          console.log("On GQL closed", event.code);
          if (event.code == 4500 || event.code == 4401) {
            this.logOut().catch(console.error);
          }

          if (event.code !== 1000) {
            abruptlyClosed = true;
          }

          if (!offlineClosed && event.reason && (event.code !== 1000 && event.code !== 4401)) {
            this.#errorBoundaryRef.error(event.reason);
          }
          loadingVar(false);
        }
      }
    });
    window.addEventListener("offline", () => {
      offlineClosed = true;
      connection.terminate();
    });
    return {
      ...connection,
      onReconnected: (cb) => {
        reconnectedCbs.push(cb);
        return () => {
          reconnectedCbs.splice(reconnectedCbs.indexOf(cb), 1);
        };
      }
    };
  }
  #addEventHandles() {
    this.#connection.on("connected", (socket, payload) => {
      this.#networkStatusLink?.reset();
      this.#retryTime = 0;
      this.#retryAttempts = 0;
      this.hideOfflineModal();
    });//work only after disconnect
    this.#connection.on("closed", (event: CloseEvent) => {
      const opt = {
        timestamp: new Date().getTime(),
        data: {
          limit: 5,
          duration: this.#retryTime,
          attempts: this.#retryAttempts
        }
      };

      this.#networkStatusLink?.reset();

      if (event.code == 1000 || event.code == 4401) {
        return;
      }
      if (this.#retryAttempts > 1) {
        this.showOfflineModal(opt);
      }
    });
    this.#connection.onReconnected(() => {
      this.#client.reFetchObservableQueries().catch(e => console.error(e));
      this.#tw.reconnect().catch(e => console.error(e));
    });
  }
  async #createClient() {
    await this.#setupLayout();
    const schemas: ISchemas = await this.#setupSchema();
    const collections = Object.values(schemas).filter(schema => schema.kind === "collection");
    const possibleTypes = collections.map(schema => schema.className);
    this.#addEventHandles();

    this.#networkStatusLink = new NetworkStatusLink();
    const loggerLink = new ApolloLink((operation, forward) => {
      const DD_LOGS = (window as any).DD_LOGS;
      const initiatedAt = new Date().getTime();
      operation.setContext({ initiatedAt });
      operation.extensions = ({ initiatedAt });
      const definition = getMainDefinition(operation.query) as OperationDefinitionNode;
      const meta = {
        event: {
          kind: "event",
          category: "network",
          type: ["protocol"],
          action: operation.operationName,
          actionType: definition.operation
        },
        details: {
          initiatedAt
        }
      };
      if (definition.operation == "query" && DD_LOGS) {
        DD_LOGS.logger.debug(`Start > (${definition.operation}:${operation.operationName})`, { meta });
      }
      return forward(operation).map(data => {
        if (definition.operation == "query" && DD_LOGS) {
          const initiatedAt = operation.getContext().initiatedAt;
          const clientName = operation.getContext().clientName || "gql";
          const completedAt = new Date().getTime();
          const completion = completedAt - initiatedAt;
          const details = {
            initiatedAt,
            ...meta.details,
            ...(data.extensions?.details ?? {}),
            completedAt,
            completion,
            clientName,
            latency: data.extensions?.details?.processing && (completion - data.extensions.details.processing)
          };
          DD_LOGS.logger.debug(`Complete < (${definition.operation}:${operation.operationName})`, {
            meta: {
              ...meta,
              details
            }
          });
        }
        return data;
      });
    });
    const wsLink = new GraphQLWsLink(this.#connection);
    const errorLink = onError(error => this.#onError(error));
    const link = this.#networkStatusLink.concat(loggerLink.concat(errorLink.concat(wsLink)));
    this.#client = new ApolloClient({
      link: link,
      connectToDevTools: true,
      cache: new InMemoryCache({
        possibleTypes: {
          Document: possibleTypes,
          Node: possibleTypes
        },
        typePolicies
      }),
      defaultOptions: {
        query: {
          fetchPolicy: "cache-first"
        }
      },
      typeDefs
    });
  }
  #onError({ graphQLErrors, networkError, operation }: ErrorResponse) {
    console.log({ graphQLErrors, networkError });
    const context = operation.getContext();
    if(context?.errorPolicy === "ignore"){
      return;
    }
    const DD_LOGS = (window as any).DD_LOGS;
    const definition = getMainDefinition(operation.query) as OperationDefinitionNode;
    const meta = {
      event: {
        kind: "event",
        category: "network",
        type: ["protocol"],
        action: operation.operationName,
        actionType: definition.operation
      }
    };
    function isServerError(networkError): networkError is ServerError {
      return !!networkError.result;
    }
    const sessionErrors = [ErrorCode.INVALID_SESSION_TOKEN, ErrorCode.ACCOUNT_DEACTIVATED];
    if (graphQLErrors && graphQLErrors.length) {
      let invalidSession = graphQLErrors.find(e => sessionErrors.includes(e.extensions?.code));
      if (invalidSession) {
        this.logOut().catch(console.error);
      } else {
        if (DD_LOGS) {
          DD_LOGS.logger.error(`Failed to complete ${operation.operationName} ${definition.operation}`, { meta: { ...meta, event: { ...meta.event, category: "graphQL" }, details: { message: graphQLErrors[ 0 ].message } } });
        }
        this.error(graphQLErrors[ 0 ].message);
      }
    }
    if (networkError) {
      if (isServerError(networkError)) {
        switch (Object(networkError.result).code) {
          case ErrorCode.INVALID_SESSION_TOKEN:
          case ErrorCode.ACCOUNT_DEACTIVATED:
            this.logOut().catch(console.error);
            return;
        }
      } else {
        if (DD_LOGS) {
          DD_LOGS.logger.error(`Failed to complete ${operation.operationName} ${definition.operation}`, { meta: { ...meta, details: { message: networkError.message } } });
        }
      }
    }
  }
  showOfflineModal(opt) {
    if (this.#offline == null) {
      this.#offline = modal(OfflineDialog, {
        onClick: () => this.#reconnect(),
        ...opt
      });
    } else {
      this.#offline.update(opt);
    }
  }
  hideOfflineModal() {
    if (this.#offline) {
      this.#offline.destroy();
      this.#offline = null;
    }
  }
  async #reconnect() {
    loadingVar(true);
    try {
      this.#accept?.();
      clearTimeout(this.#timer);
    } catch (e) {
      console.error(e);
      loadingVar(false);
    } finally {
      loadingVar(false);
    }
  }
  async attempt(authorization: string) {
    return fetch("/api/v1/login", {
      method: "POST",
      headers: {
        "Authorization": authorization,
        "X-RC-Device": `web:${this.deviceId}:${this.version}`,
        "X-RC-VERSION": this.version,
        "X-RC-Session": this.sessionId
      }
    });
  }
  async login(authorization: string) {
    try {
      const response = await this.attempt(authorization);
      if (!response.ok) {
        const result = await response.json();
        this.error(result.error);
      } else {
        this.#connection = await this.#createWsClient();
      }
    } catch (e) {
      console.error(e);
      this.error("Ops something went wrong.");
    }
  }
  async logOut() {
    loadingVar(true);
    try {
      await fetch("/api/v1/logout", {
        method: "POST"
      });
      await loadConfig();
      isAuthenticatedVar(false);
      this.#tw.destroy();
      deviceVar(null);
      leadPricingVar(null);
      this.#connection.dispose();
      this.#client.stop();
      this.#client.clearStore();
      this.#client = null;
      this.#connection = null;
    } finally {
      loadingVar(false);
    }
  }
  setErrorBoundary(e) {
    this.#errorBoundaryRef = e;
  }
  error(message: AlertMessage, options?: AlertOptions) {
    console.info("ERROR", this.#errorBoundaryRef);
    this.#errorBoundaryRef.error(message, options);
  }
  info(message: AlertMessage, options?: AlertOptions) {
    this.#errorBoundaryRef.info(message, options);
  }
  warning(message: AlertMessage, options?: AlertOptions) {
    this.#errorBoundaryRef.warning(message, options);
  }
  #checkVersion(connectedPayload) {
    const latestVersion = connectedPayload.web.version;
    if (this.version != latestVersion) {
      this.#serviceWorkerUnregister();
      versionVar(latestVersion);
    }
  }
  #serviceWorkerUnregister() {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      if (registrations.length) {
        for (let registration of registrations) {
          registration.unregister();
        }
      }
    });
  }
}

export const ClientContext = React.createContext(new Client());
