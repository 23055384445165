import React                 from "react";
import { FC }                from "react";
import { useRef }            from "react";
import { SyntheticEvent }    from "react";
import { ReactNode }         from "react";
import { SetStateAction }    from "react";
import { Dispatch }          from "react";
import { Property }          from "csstype";
import { useBoxProps }       from "../../..";
import { Alignment }         from "../../..";
import { SearchableMenu }    from "../../..";
import { IconType }          from "../../..";
import { BoxComponentProps } from "../../..";
import { classNames }        from "../../..";
import { Box }               from "../../..";
import { Popper }            from "../../..";
import { BaseInputClasses }  from "../BaseInput";
import { BaseInputProps }    from "../BaseInput";
import { SelectToggle }      from "./SelectToggle/SelectToggle";
import { useSelect }         from "./useSelect";

export enum SelectVariants {
  Outline = "outline",
  Ghost = "ghost"
}

export enum SelectColors {
  Grey = "grey",
  Primary = "primary",
}
export enum SelectSizes {
  Medium = "medium",
  Big = "big"
}

export interface SelectProps extends BaseInputProps, BoxComponentProps {
  showLabel?: boolean;
  searchable?: boolean;
  value?: number | string | object;
  showUnderLine?:boolean;
  readOnly?: boolean;
  keepErrorSpace?: boolean;
  loading?: boolean;
  message?: string;
  color?: SelectColors;
  mode?: "default" | "button";
  variant?: SelectVariants;
  size?: SelectSizes;
  height?: Property.Height<any>;
  width?: number | string;
  alignment?: Alignment;
  handleClear?(e: SyntheticEvent)
  defaultOption?: string;
  options?: any[];
  optionKey?: string;
  optionLabel?: string;
  icon?: IconType;
  renderOption?: (option) => ReactNode;
  renderSelect?: (option) => ReactNode | string;
  onLoadingChange?: Dispatch<SetStateAction<boolean>>;
  getSelected?: (option) => string;
  getOptionLabel?: (option) => string;
  getOptionValue?: (option) => string;
  getOptionSelected?: (option, value) => boolean;
  getOptionDisabled?: (option) => boolean;
  InputProps?: { [ key: string ]: any };
  onChange?(value);
}

export const Select: FC<SelectProps> = React.memo(function Select(props) {
  const {
    mode,
    variant,
    size,
    options,
    onClick,
    value,
    placeholder,
    optionLabel,
    optionKey,
    readOnly,
    required,
    defaultOption,
    onFocus,
    onBlur,
    disabled,
    message,
    state,
    height,
    width,
    label,
    showLabel,
    halfSize,
    fullSize,
    className,
    loading: l,
    color,
    icon,
    renderOption,
    renderSelect,
    getOptionLabel,
    getSelected,
    getOptionValue,
    getOptionSelected,
    getOptionDisabled,
    onLoadingChange,
    keepErrorSpace,
    direction,
    InputProps,
    searchable,
    alignment,
    showUnderLine,
    handleClear,
    ...p
  } = props;

  const {
    anchorBounding,
    setAnchorBounding,
    checkValue,
    valueExist,
    handleKeyPress,
    disabledMod,
    togglePopper,
    showSelectedField,
    selected,
    loading,
    setSearch,
    search,
    handleSelect,
    items
  } = useSelect(props);
  const toggleRef = useRef(null)
  return (
    <Box container
         className={classNames(SelectClasses.Select, {
           [ BaseInputClasses.HalfSize ]: halfSize && mode === "default",
           [ BaseInputClasses.FullSize ]: fullSize && mode === "default"
         }, className)}
         onFocus={(e) => onFocus && onFocus(e)}
         onBlur={(e) => onBlur && onBlur(e)}
         {...useBoxProps(p)}
         {...InputProps}>
      <SelectToggle
        ref={toggleRef}
        mode={mode}
        direction={direction}
        label={label}
        showLabel={showLabel}
        required={required}
        variant={variant}
        color={color}
        size={size}
        handleClear={handleClear}
        anchorBounding={anchorBounding}
        disabled={disabled}
        readOnly={readOnly}
        state={state}
        disabledMod={disabledMod}
        checkValue={checkValue}
        valueExist={valueExist}
        handleKeyPress={handleKeyPress}
        count={options.length}
        togglePopper={togglePopper}
        message={message}
        showSelectedField={showSelectedField}
        icon={icon}
        keepErrorSpace={keepErrorSpace}
        className={className}
        showUnderLine={showUnderLine}
      />
      <Popper open={!!anchorBounding} anchorBounding={anchorBounding} alignment={alignment}
              onClickAway={() => {
                setAnchorBounding(null);
              }}
              threshold={6}>
        <SearchableMenu
          searchable={searchable && !!options.length}
          placeholder={placeholder}
          renderOption={renderOption}
          options={items}
          selected={selected}
          onSelect={handleSelect}
          searchText={search}
          optionKey={optionKey}
          optionLabel={optionLabel}
          loading={loading}
          onType={setSearch}
          width={(width ?? toggleRef?.current?.clientWidth) as number}
        />
      </Popper>
    </Box>
  );
});

Select.defaultProps = {
  options: [],
  alignment: Alignment.BottomLeft,
  optionKey: "value",
  optionLabel: "label",
  mode: "default",
  searchable: false,
  disabled: false,
  readOnly: false,
  loading: false,
  color: null,
  variant: SelectVariants.Outline,
  size: SelectSizes.Big,
  showLabel: true
};

export enum SelectClasses {
  Select = "select",
  SelectButton = "select__button",
  SelectButtonOutline = "select__button--outline",
  SelectButtonGhost = "select__button--ghost",
  Big = "big",
  Medium = "medium",
  OutlineGray = "outline-gray",
  Default = "default",
  Active = "active",
  Disabled = "disabled",
  SelectText = "select-text",
  SelectPlaceHolder = "select-placeholder",
  UnderlineHidden = "main-container--underlined-hidden",
}

