import { Viewer } from "../components/Layout/JsonPage";

function checkAccess({ objectId, role }: Partial<Viewer>, permission) {
  if (!permission) {
    return false;
  }
  if (permission[ `user:${objectId}` ] === true) {
    return true;
  }
  if (permission[ `role:${role}` ] === true) {
    return true;
  }
  return !!permission[ `*` ];
}
function hasAccess({ objectId, role }: Partial<Viewer>, permissions: JsonPermissions, can: "read" | "write") {
  if (!permissions) {
    return true;
  }
  if (!permissions.read && !permissions.write && !permissions.filters) {
    return true;
  }
  return (
    checkAccess({ objectId, role }, permissions?.[ can ])
  );
}

export const permissionUtils = {
  hasAccess,
  checkAccess
};

export interface JsonPermissions {
  read?: {
    "*"?: boolean,
    [ key: string ]: boolean
  },
  write?: {
    "*"?: boolean,
    [ key: string ]: boolean
  }
  filters?: {
    "*"?: boolean,
    [ key: string ]: boolean
  }
}
