import { TypographyWeight }    from "@relcu/ui";
import { TypographySize }      from "@relcu/ui";
import { Typography }          from "@relcu/ui";
import React                   from "react";
import { FC }             from "react";
import { MailSection }    from "../../View/MailView/MailSection/MailSection";
import { MailSubSection } from "../../View/MailView/MailSubSection";

export interface BulkItemHeaderProps{
  name: string;
  subject: string;
}

export const BulkHeader: FC<BulkItemHeaderProps> = React.memo(function BulkHeader({name, subject}) {
  return <MailSection className={"mail-message--header"}>
    <MailSubSection>
      <Typography size={TypographySize.Title} weight={TypographyWeight.Medium}>{subject}</Typography>
    </MailSubSection>
    <MailSubSection direction={"column"} gap={"XXXS"}>
      <Typography size={TypographySize.Subtitle} weight={TypographyWeight.Medium}>
        Organizer
      </Typography>
      <Typography>
        {name}
      </Typography>
    </MailSubSection>
  </MailSection>
})
