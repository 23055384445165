import { getIn } from "@relcu/form";

export type Operator = keyof typeof DEFAULT_OPERATORS | string;
export type Operators = Record<Operator, (a, b) => boolean>
export interface ConditionProperties {
  operator: Operator;
  value?: { fact: string } | any;
  path: string;
}
export type NestedCondition = ConditionProperties | TopLevelCondition;
export type AndConditions = { and: NestedCondition[] };
export type OrConditions = { or: NestedCondition[] };

export type TopLevelCondition = AndConditions | OrConditions;

const DEFAULT_OPERATORS = {
  equals: (a, b) => a === b,
  neqEquals: (a, b) => a !== b,
  weakEquals: (a, b) => a == b,
  notWeakEquals: (a, b) => a != b,
  greater: (a, b) => a > b,
  greaterEqual: (a, b) => a >= b,
  lower: (a, b) => a < b,
  lowerEqual: (a, b) => a <= b,
  in: (a, b) => b.includes(a),
  notIn: (a, b) => !b.includes(a),
  startsWith: (a, b) => b.some(c => typeof a === "string" && a.startsWith(c)),
  notStartsWith: (a, b) => b.every(c => typeof a === "string" && !a.startsWith(c)),
  endsWith: (a, b) => b.some(c => typeof a === "string" && a.endsWith(c)),
  notEndsWith: (a, b) => b.every(c => typeof a === "string" && !a.endsWith(c)),
  includes: (a, b) => b.some(c => typeof a === "string" && a.indexOf(c) !== -1),
  notIncludes: (a, b) => b.every(c => typeof a === "string" && a.indexOf(c) === -1),
  some: (a, b) =>  a.some(c => b.indexOf(c) !== -1),
  every: (a, b) => a.every(c => b.indexOf(c) !== -1),
  none: (a, b) => a.every(c => b.indexOf(c) === -1),
  regexp: (a, b) => b.some(c => (c instanceof RegExp ? c.test(a) : new RegExp(c).test(a))),
  isEmpty: (a) => isEmpty(a),
  isNotEmpty: (a) => !isEmpty(a),
  contains: (a, b) => {
    return a.includes(b);
  }
};

export class Condition {
  #source;
  constructor(source?) {
    this.#source = source;
  }
  static isAnd(c: TopLevelCondition | NestedCondition): c is AndConditions {
    return Object.prototype.hasOwnProperty.call(c, "and");
  }
  static isOr(c: TopLevelCondition | NestedCondition): c is OrConditions {
    return Object.prototype.hasOwnProperty.call(c, "or");
  }
  static getPath(path: string, root: string) {
    if (path.startsWith("$.")) {
      return path.replace("$.", "");
    }
    if (path.startsWith("$")) {
      return path;
    }
    return root ? [root, path].join(".") : path;
  }
  #operators: Operators = DEFAULT_OPERATORS;
  invoke(c: ConditionProperties, data: Record<string, any>, root?: string) {
    const path = Condition.getPath(c.path, root);
    const property = getIn(data, path);
    let value = c.value;
    if (typeof value === "string" && value.startsWith("$")) {
      value = getIn(data, value);
    }
    return this.#operators[ c.operator ](property, value);
  }
  addOperators(operators: Operators) {
    Object.assign(this.#operators, operators);
  }
  section(c: TopLevelCondition | NestedCondition, data: Record<string, any>, results, root?: string) {
    if (Condition.isAnd(c)) {
      return c.and.every(c => this.section(c, data, results, root));
    }

    if (Condition.isOr(c)) {
      return c.or.some(c => this.section(c, data, results, root));
    }

    if (this.invoke(c, data, root) === true) {
      return results.push(c) > 0;
    }
    return false;
  }
  evaluate(conditions: TopLevelCondition, data: Record<string, any>, root?: string) {
    const result = [];
    if (Boolean(this.section(conditions, { ...this.#source, ...data }, result, root)) === true) {
      return {
        apply: true,
        conditions
      };
    }
    return {
      apply: false,
      result
    };
  }
}

const isEmpty = (value) => {
  const isEmptyObject = (a) => {
    if (typeof a.length === "undefined") { // it's an Object, not an Array
      let hasNonempty = Object.keys(a).some(function nonEmpty(element) {
        return !isEmpty(a[ element ]);
      });
      return hasNonempty ? false : isEmptyObject(Object.keys(a));
    }

    return !a.some(function nonEmpty(element) { // check if array is really not empty as JS thinks
      return !isEmpty(element); // at least one element should be non-empty
    });
  };
  return (
    value == false
    || typeof value === "undefined"
    || value == null
    || (typeof value === "object" && isEmptyObject(value))
  );
};




