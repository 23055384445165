import { SyntheticEvent }   from "react";
import React                from "react";
import { FC }               from "react";
import { ButtonVariants }   from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { TypographyWeight } from "@relcu/ui";
import { TypographySize }   from "@relcu/ui";
import { Typography }       from "@relcu/ui";
import "./view-header.css";

export interface ViewHeaderProps {
  title: string;
  onClick?(e: SyntheticEvent);
}

export const ViewHeader: FC<ViewHeaderProps> = React.memo(function ViewHeader(props) {
  const { title, onClick } = props;
  return <div className={"view-header"} style={{ justifyContent: !props.title ? "end" : "space-between" }}>
    {props.title &&
      <Typography size={TypographySize.Subtitle} weight={TypographyWeight.Medium}>
        {title}
      </Typography>
    }
    <Button alignSelf={"end"} variant={ButtonVariants.Ghost} icon={"add"} onClick={onClick  }>Create</Button>
  </div>;
});