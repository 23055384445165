import React                      from "react";
import { useMemo }                from "react";
import { FC }                     from "react";
import { useMutation }            from "@apollo/client";
import { Button }                 from "@relcu/rc";
import { useAlert }               from "@relcu/ui";
import { useSource }              from "@relcu/ui";
import { toFirstLower }           from "@relcu/ui";
import { useModal }               from "@relcu/ui";
import { GenerateFlyerVariables } from "../../../../../__types__/GenerateFlyer";
import { GenerateFlyer }          from "../../../../../__types__/GenerateFlyer";
import { GENERATE_FLYER }         from "../../../../../operations.graphql";
import { FlyerDialog }            from "../../../../Dialog/FlyerDialog/FlyerDialog";
import { useAttachmentUploader }  from "../../MailAttachmentUpload/useAttachmentUploader";

export interface MailFlyerAttachmentProps {
  sendingMail: boolean;
}
export const MailFlyerAttachment: FC<MailFlyerAttachmentProps> = React.memo(function MailFlyerAttachment(props) {
  const [modal, modalContext] = useModal(FlyerDialog);
  const [generateFlyer] = useMutation<GenerateFlyer, GenerateFlyerVariables>(GENERATE_FLYER);
  const { addFile } = useAttachmentUploader({ name: "attachments" });
  const { info } = useAlert();
  const { $object: node, $viewer: user } = useSource();
  const flyerFilters = useMemo(() => {
    const filters: Record<string, any> = {
      enabled: { equalTo: true },
      availableForType: { equalTo: "email" },
      availableFor: { equalTo: "manual" },
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: user.id
              }
            }
          }
        }
      ]
    };
    const typename = toFirstLower(node.__typename);
    if (node.__typename != "User") {
      if (filters.AND) {
        filters[ "AND" ].push({
          applyTo: { equalTo: typename }
        });
      } else {
        filters.applyTo = { equalTo: typename };
      }
    }
    return filters;
  }, [node.__typename, user.id]);
  const handleApplyFlyer = async () => {
    const { destroy } = modal({
      filters: flyerFilters,
      onInsert: async (flyer) => {
        destroy();
        if (!flyer) {
          return;
        }
        info("PDF or image generation can take up to 30 seconds.");
        const file = await generateFlyer({
          variables: {
            flyer,
            scope: node.id
          }
        });

        if (file?.data?.generateFlyer) {
          addFile(file.data.generateFlyer);
        }
      }
    });
  };

  const { sendingMail } = props;
  return <>
    {modalContext}
    <Button
      appearance={"subtle"}
      disabled={sendingMail}
      style={{ display: "flex", justifyContent: "start", width: "100%", borderRadius: 8 }}
      onClick={handleApplyFlyer}>
      Flyer attachment
    </Button>
  </>;
});
