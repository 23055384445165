import React                   from "react";
import { FC }                  from "react";
import { GlobalClasses }       from "../../../../constants/GlobalClasses";
import { classNames }          from "../../../../utils/classNames";
import { Box }                 from "../../../Box";
import { EllipsisWithTooltip } from "../../../EllipsisTooltip";
import { TypographyColor }     from "../../../Typography";
import { TypographySize }      from "../../../Typography";
import { TypographyWeight }    from "../../../Typography";
import { Typography }          from "../../../Typography";
import { CellClasses }         from "../CellClasses";
import { ICellProps }          from "../ICellProps";

export interface TextCellProps extends ICellProps {
  tiny?: boolean;
  bold?: boolean;
  isMultiText?: boolean;
  defaultValue?: string;
}

export const TextCell: FC<TextCellProps> = React.memo(function TextCell({ getCellValue, className, defaultValue, rowData, bold, tiny, dataKey, isMultiText, ...props }) {
  const text = getCellValue(rowData, dataKey);
  const value = text ?? defaultValue;

  return <Box className={classNames(className, CellClasses.TextCell)}
              style={{ "--line-clamp": isMultiText ? 2 : 1 } as any}>
    <EllipsisWithTooltip value={value}>
      <Typography className={GlobalClasses.EllipsisFlex}
                  size={tiny && TypographySize.TextSmall}
                  color={tiny && TypographyColor.Secondary}
                  weight={bold && TypographyWeight.Bold}>
        {value}
      </Typography>
    </EllipsisWithTooltip>
  </Box>;
});
