import React                        from "react";
import { WithAsProps }              from "../@types/common";
import { TypeAttributes }           from "../@types/common";
import { RsRefForwardingComponent } from "../@types/common";
import FlexboxGrid                  from "../FlexboxGrid";
import { useClassNames }            from "../utils";

export interface HeaderCellProps extends WithAsProps, React.HTMLAttributes<HTMLElement> {
  /** A tag can have different colors */
  color?: TypeAttributes.Alert;

  span?: number;

  /** The content of the component */
  children?: React.ReactNode;
}

export const HeaderCell: RsRefForwardingComponent<"div", HeaderCellProps> = React.forwardRef((props: HeaderCellProps, ref) => {
  const {
    as: Component = "div",
    className,
    children,
    classPrefix = "header-cell",
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Component
    className={classes}
    {...rest}>
    {children}
  </Component>;
});
