import React                        from "react";
import { RsRefForwardingComponent } from "../../@types/common";
import { ButtonProps }              from "../../index";
import { SelectPicker }             from "../../index";
import { SelectProps }              from "../../SelectPicker";
import { Cell }                     from "../../TableHorizontal";
import { CellProps }                from "../../TableHorizontal/Cell";
import { useClassNames }            from "../../utils";

export interface SelectCellProps extends Omit<CellProps, "onSelect">, SelectProps<any> {
  active?: boolean;
  data: Record<string, any>[]
}

export const SelectCell: RsRefForwardingComponent<"div", SelectCellProps> = React.forwardRef((props: SelectCellProps, ref) => {
  const {
    className,
    classPrefix = "cell-select",
    helperText,
    color,
    status,
    affected,
    style,
    inert,
    ...rest
  } = props as any;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );
  return <Cell status={status} helperText={helperText} space={true} className={classes} affected={affected} style={style} inert={inert}>
    <SelectPicker
      placement={"autoVertical"}
      menuClassName={prefix("menu")}
      toggleAs={SelectCellToggle}
      containerPadding={0}
      searchable={false}
      {...rest}
    />
  </Cell>;
});

export interface SelectCellToggleProps extends ButtonProps {
  active?: boolean;
}

export const SelectCellToggle: RsRefForwardingComponent<"div", SelectCellToggleProps> = React.forwardRef<HTMLDivElement, SelectCellToggleProps>((props, ref: any) => {
  const {
    className,
    classPrefix = "cell-select-toggle",
    active,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  let classes = merge(
    className,
    withClassPrefix({ active })
  );

  return <div className={classes} {...rest} ref={ref}/>;
});


