import { omit } from "@relcu/ui";

const weekDays = {
  SUN: "Sunday",
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday"
};

const sorter = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6
}

interface ScheduleInterface {
  key: string,
  enabled: boolean,
  day: string,
  from?: string,
  to?: string
}

interface SortingInterface {
  prioritizeBy: string,
  order: string
}

export function parseSchedule(schedule: { [ key: string ]: string }, defaultTime?: string) {
  const transformed = [];
  let defaultStartEnd = (defaultTime ?? "00:00-12:00").split("-");
  if (schedule == null) {
    Object.entries(weekDays).map(([short, weekDay]) => {
      transformed.push({
        key: short,
        enabled: false,
        day: weekDay,
        from: defaultStartEnd[ 0 ],
        to: defaultStartEnd[ 1 ]
      });
    });
    return transformed;
  }

  Object.keys(schedule)?.forEach(key => {
    if (schedule[ key ]) {
      const startEnd = schedule[ key ].split("-");
      transformed.push({
        key,
        enabled: true,
        day: weekDays[ key ],
        from: startEnd[ 0 ],
        to: startEnd[ 1 ]
      });
    } else {
      transformed.push({
        key,
        enabled: false,
        from: defaultStartEnd[ 0 ],
        to: defaultStartEnd[ 1 ],
        day: weekDays[ key ]
      });
    }
  });

  return transformed;
}

export function sortSchedule (schedule: {key: string, [key: string]: any}[]) {
  return schedule.sort(function sortByDay(a, b) {
    let day1 = a.key;
    let day2 = b.key;
    return sorter[day1] - sorter[day2];
  });
}


export function convertSchedule(schedule: ScheduleInterface[]) {
  let result = schedule.reduce((prev, current) => {
    if (current.from && current.to && current.enabled) {
      prev[ current.key ] = `${current.from}-${current.to}`;
    } else if (!current.enabled) {
      prev[ current.key ] = null;
    }

    return prev;
  }, {});

  if (!Object.values(result).length) {
    result = null;
  }

  return result;
}

export function parseSort(sorting: (string | null)[] | null) {
  const parsedSorting = [];

  sorting.forEach(sort => {
    let match;
    if (match = sort.match?.(/^[-]/)) {
      parsedSorting.push({
        prioritizeBy: match.input.substring(match.index + 1),
        order: "desc"
      });
    } else {
      parsedSorting.push({
        prioritizeBy: sort,
        order: "asc"
      });
    }
  });
  return parsedSorting;
}

export function convertSort(sorting: SortingInterface[] | null) {
  return sorting.reduce((prev, current) => {
    if (current.order == "desc") {
      return [...prev, `-${current.prioritizeBy}`];
    } else {
      return [...prev, current.prioritizeBy];
    }
  }, []);
}

export function parseFilter(target) {
  let match;
  if (match = target.match(":")) {
    const splited = target.split(":");
    if (splited[ 0 ] == "user") {
      return { objectId: splited[ 1 ], type: "user" };
    } else {
      return { objectId: splited[ 1 ], objectName: splited[ 1 ], type: "tag" };
    }
  }
}

export function convertToFilter(filters: any[]) {
  if (filters.includes("*")) {
    return filters;
  } else {
    return filters.map(f => `${f.type}:${f.objectId}`);
  }
}

export function convertLimits(limits) {
  return limits.map(limit => {
    return {
      ...limit,
      filter: convertToFilter(limit.filter)
    };
  });
}
export function parseLimits(limits) {
  return limits.map(limit => {
    return {
      ...omit(limit, ["__typename"]),
      filter: limit.filter.map(f => {
        if (f.startsWith("*")) {
          return f;
        } else {
          return parseFilter(f);
        }
      })
    };
  });
}
