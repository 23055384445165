import React                    from "react";
import { FC }                   from "react";
import { useFieldArray }        from "@relcu/form";
import { useFormState }         from "@relcu/form";
import { getIn }                from "@relcu/form";
import { Field }                from "@relcu/form";
import { Checkbox }             from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { classNames }           from "@relcu/ui";
import { ContactDialogClasses } from "../ContactDialogClasses";
import { ReadField }            from "./ReadField";

export interface MultiFieldProps {
  name: string
  fieldName: string
  marks: any
  errors: any
  onMark(name: string, allValues?: any)
  format?(value): any
}
export const MultiField: FC<MultiFieldProps> = React.memo(function MultiField(props) {
  const { marks, errors, onMark, name, fieldName, format } = props;
  const { fields } = useFieldArray(name);
  const { values } = useFormState();
  return <>
    {
      fields.map((name, index) => {
        return <Field name={`${name}.${fieldName}`} key={name}>
          {({ input, meta }) => {
            const selected = getIn(marks, input.name);
            const duplicate = getIn(errors, `${name}.duplicate`);
            return <Box flex={1} alignSelf={"stretch"}
                        className={classNames(ContactDialogClasses.MultiRow, {
                          [ ContactDialogClasses.MultiRowDuplicated ]: duplicate
                        })}>
              <ReadField alignSelf={"stretch"} selected={selected}
                         style={{ marginLeft: 16, marginRight: 16, height: 32 }}>
                <Checkbox checked={selected} alignSelf={"center"}
                          placeholder={format?.(input.value) || input.value}
                          onChange={() => onMark(input.name, values)}/>
              </ReadField>
            </Box>;
          }}
        </Field>;
      })
    }
  </>;
});
