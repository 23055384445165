import { Typography }             from "@relcu/rc";
import { TypeKind }               from "graphql";
import { useContext }             from "react";
import React                      from "react";
import { FC }                     from "react";
import { useMemo }                from "react";
import { useState }               from "react";
import { useReactiveVar }         from "@apollo/client";
import { Select }                 from "@relcu/ui";
import { classNames as clsNames } from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { ClientContext }          from "../../../../../Client";
import { audioNotificationOutputIdVar } from "../../../../../reactiveVars";
import { audioDevicesVar }        from "../../../../../reactiveVars";
import { audioInputIdVar }        from "../../../../../reactiveVars";
import { audioOutputIdVar }       from "../../../../../reactiveVars";
import { SoundSectionClasses }    from "../SoundSectionClasses";
import { AudioController }        from "./AudioController";

export const Devices: FC<any> = React.memo(function Devices(props) {
  const { className, ...p } = props;
  const client = useContext(ClientContext);
  const outputId = useReactiveVar(audioOutputIdVar);
  const inputId = useReactiveVar(audioInputIdVar);
  const audioNotificationOutputId = useReactiveVar(audioNotificationOutputIdVar);
  const classNames = clsNames(SoundSectionClasses.Devices, className);
  const [canChangeMic, setCanChangeMic] = useState(true);
  const [canChangeSpeak, setCanChangeSpeak] = useState(true);
  const devices = useReactiveVar(audioDevicesVar);
  const selectedInput = useMemo(() => devices.audioinput?.find(d => d.deviceId == inputId), [inputId, devices]);
  const selectedOutput = useMemo(() => devices.audiooutput?.find(d => d.deviceId == outputId), [outputId, devices]);
  const selectedNotificationOutput = useMemo(() => devices.audiooutput?.find(d => d.deviceId == audioNotificationOutputId), [audioNotificationOutputId, devices]);
  function setOutput(device) {
    if (device) {
      client.soundPreferences.changeOutputDevice(device)
    }
  }
  function setNotificationOutput(device) {
    if (device) {
      client.soundPreferences.changeNotificationOutputDevice(device);
    }
  }
  function setInput(device) {
    if (device) {
      client.soundPreferences.changeInputDevice(device)
    }
  }

  return <Box container flexShrink={1} justify={"space-between"} className={classNames} gap={"M"} {...p}>
    <Box container direction={"column"} gap={"XS"}>
      <Box container direction={"row"} gap={"XS"}>
        <Box container direction={"column"} gap={"XS"}>
          <Typography>Call audio settings</Typography>
          <Select
            disabled={!canChangeSpeak || !devices.audiooutput.length}
            placeholder={"No speaker detected"}
            label="Speaker"
            icon="volume_up"
            value={selectedOutput}
            onChange={setOutput}
            options={devices.audiooutput}
            optionLabel={"label"}
            optionKey={"deviceId"}/>
          <Select
            disabled={!canChangeMic || !devices.audioinput.length}
            placeholder={"No microphone detected"}
            label="Microphone"
            icon={"record_voice_over"}
            value={selectedInput}
            onChange={setInput}
            options={devices.audioinput}
            optionLabel={"label"}
            optionKey={"deviceId"}/>
        </Box>
        <Box container direction={"column"}  gap={"XS"}>
          <Typography>Notification audio settings</Typography>
          <Select
            disabled={!canChangeSpeak || !devices.audiooutput.length}
            placeholder={"Select speaker"}
            label="Speaker"
            icon="volume_up"
            value={selectedNotificationOutput}
            onChange={setNotificationOutput}
            options={devices.audiooutput}
            optionLabel={"label"}
            optionKey={"deviceId"}/>
        </Box>
      </Box>


    </Box>
    <AudioController
      disabled={!devices.audiooutput.length}
      onListen={(play) => setCanChangeSpeak(play)}
      onRecord={(record) => setCanChangeMic(record)}
      inputDeviceId={inputId}
      outputDeviceId={outputId}
    />
  </Box>;
});
