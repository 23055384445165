import React                from "react";
import { FC }               from "react";
import { useNavigate }      from "@relcu/react-router";
import { Tooltip }          from "@relcu/ui";
import { ButtonSizes }      from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { Alignment }        from "@relcu/ui";
import { IActionCellProps } from "@relcu/ui";
import { getObjectPath }    from "../../../../../utils/layoutUtils";

export const RedirectAction: FC<IActionCellProps> = React.memo(function SmsAction(props) {
  const { rowData, disabled, tooltip, path, bulkPermissions, ...p } = props;
  const navigate = useNavigate();
  function onClick(e) {
    e.stopPropagation();
    navigate(`${getObjectPath(rowData)}/${path ?? null}`);
  }

  return <Tooltip title={tooltip} alignment={Alignment.Bottom}>
    <Button
      onlyIcon
      size={ButtonSizes.Small}
      onClick={onClick}
      disabled={rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length}
      {...p}/>
  </Tooltip>;
});
