import { toFirstLower }               from "@relcu/ui";
import { transformNameToLabel }       from "@relcu/ui";
import React                          from "react";
import { useMemo }                    from "react";
import { gql }                        from "@apollo/client";
import { useReactiveVar }             from "@apollo/client";
import { Navigate }                   from "@relcu/react-router";
import { Route }                      from "@relcu/react-router";
import { Routes }                     from "@relcu/react-router";
import { useNavigate }                from "@relcu/react-router";
import { useParams }                  from "@relcu/react-router";
import { Box }                        from "@relcu/ui";
import { useSource }                  from "@relcu/ui";
import { CircularLoader }             from "@relcu/ui";
import { ListCard }                   from "@relcu/rc";
import { EmptyState }                 from "@relcu/rc";
import { Button, Stack }              from "@relcu/rc";
import { Container }                  from "@relcu/rc";
import { Typography }                 from "@relcu/rc";
import { Toolbar }                    from "@relcu/rc";
import { Sidenav }                    from "@relcu/rc";
import { Page }                       from "@relcu/rc";
import { NODE_FRAGMENT }              from "../../../../graph/operations.graphql";
import { mailInputVar }               from "../../../../reactiveVars";
import { getScopeWhere }              from "../../../../utils/graphQlUtils";
import { usePermissions }             from "../../../AccessControl";
import { RelayMention }               from "../../../Relay";
import { RelayMentionProvider }       from "../../../Relay";
import { RelayQuery }                 from "../../../Relay";
import { GetMailConversations }       from "./__types__/GetMailConversations";
import { Content }                    from "./Content/Content";
import { useMailApi }                 from "../../../useMailApi";
import { MailConversationWhereInput } from "../../../../types/graphql-global-types";
import { getField }                   from "../../../../utils/schemaUtils";
import "./mail-view.css";

export const MailView = React.memo(function MailView() {
  const [expand, setExpand] = React.useState(true);
  const navigate = useNavigate();
  const { $object: node, $viewer: { id: viewerId, objectId: viewerObjectId, role }, $object: { id: nodeId, __typename: type } } = useSource();
  const isCompose = !!useReactiveVar(mailInputVar);
  const params = useParams();
  const { canUpdate } = usePermissions(node);
  const { states } = getField("Lead", "leadStatus");
  const { defaultMailbox, nodeEmail, createDraftMail, createDraftMailData } = useMailApi(node);
  const from = defaultMailbox?.address;
  const canContact = (
    canUpdate && ((node.__typename === "Lead" && !states[ node.leadStatus.status ]?.not_contact) ||
      ((node.__typename != "Lead" && node.__typename !== "User") && (node.emails.length && node.emails.filter(e => !e.optOut).length)))
  ) && !!from;
  const [objectId] = params[ "*" ].split("/");
  const orMyDraftOrNonDraft = {
    "OR": [
      {
        "emailId": {
          "exists": true
        }
      },
      {
        "emailId": {
          "exists": false
        },
        "OR": [
          {
            "parties": {
              "have": {
                "AND": [
                  {
                    "party": {
                      "have": {
                        "link": viewerId
                      }
                    },
                    "metadata": {
                      "isDraft": {
                        "equalTo": true
                      }
                    }
                  }
                ]
              }
            }
          },
          {
            "parties": {
              "haveNot": {
                "metadata": {
                  "isDraft": {
                    "equalTo": true
                  }
                }
              }
            }
          }
        ]
      }
    ]
  };
  const scope = useMemo(() => {
    return getScopeWhere("MailConversation", nodeId);
  }, [type, nodeId]);

  const whereQuery = useMemo(() => {
    switch (type) {
      case "User":
        return {
          AND: [
            { deleted: { notEqualTo: true } },
            {
              OR: [{
                type: {
                  equalTo: "bulk"
                }
              }, {
                type: {
                  notEqualTo: "bulk"
                },
                OR: [{
                  parent: { exists: false }
                }, {
                  parent: { exists: true },
                  replyCount: { greaterThan: 0 }
                }
                ]
              }
              ]
            },
            {
              OR: [
                {
                  parties: {
                    have: {
                      party: {
                        have: {
                          link: nodeId
                        }
                      },
                      deleted: {
                        notEqualTo: true
                      }
                    }
                  }
                },
                {
                  bulkStatsPerUser: {
                    have: {
                      user: {
                        have: {
                          id: { equalTo: nodeId }
                        }
                      }
                    }
                  }
                }
              ]
            },
            orMyDraftOrNonDraft
          ]
        };
      case "Contact":
        const CONTACTAND: MailConversationWhereInput[] = [
          //@ts-ignore
          { deleted: { notEqualTo: true } },
          {
            parties: {
              have: {
                party: {
                  have: {
                    link: nodeId
                  }
                }
              }
            }
          },
          {
            OR: [
              {
                AND: [
                  {
                    parent: {
                      exists: true
                    }
                  },
                  {
                    status: {
                      in: ["sent", "processed", "delivered"]
                    }
                  }
                ]
              },
              {
                parent: {
                  exists: false
                }
              }
            ]
          },
          orMyDraftOrNonDraft
        ];
        if (role !== "admin") {
          CONTACTAND.push({
            parties: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                },
                deleted: { notEqualTo: true }
              }
            }
          });
        }
        return {
          AND: CONTACTAND
        };
      case "Lead":
        const AND: MailConversationWhereInput[] = [
          //@ts-ignore
          { deleted: { notEqualTo: true } },
          {
            scope: scope
          },
          {
            parties: {
              have: {
                OR: [
                  {
                    party: {
                      haveNot: {
                        link: viewerId
                      }
                    }
                  },
                  {
                    party: {
                      have: {
                        link: viewerId
                      }
                    },
                    deleted: {
                      notEqualTo: true
                    }
                  }
                ]
              }
            }
          },
          {
            OR: [
              {
                AND: [
                  {
                    parent: {
                      exists: true
                    }
                  },
                  {
                    status: {
                      in: ["sent", "processed", "delivered"]
                    }
                  }
                ]
              },
              {
                parent: {
                  exists: false
                }
              }
            ]
          },
          orMyDraftOrNonDraft
        ];
        return {
          AND
        };
      default:
        const defaultAND = [
          //@ts-ignore
          { deleted: { notEqualTo: true } },
          {
            scope: scope
          },
          {
            OR: [
              {
                AND: [
                  {
                    parent: {
                      exists: true
                    }
                  },
                  {
                    status: {
                      in: ["sent", "processed", "delivered"]
                    }
                  }
                ]
              },
              {
                parent: {
                  exists: false
                }
              }
            ]
          },
          orMyDraftOrNonDraft
        ] as MailConversationWhereInput[];
        if (role !== "admin") {
          defaultAND.push({
            parties: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                },
                deleted: { notEqualTo: true }
              }
            }
          });
        }
        return {
          AND: defaultAND
        };
    }
  }, [type, nodeId, viewerId, node.__typename, scope]);
  const whereSubscription = useMemo(() => {
    switch (type) {
      case "User":
        return {
          deleted: { notEqualTo: true },
          AND: [
            {
              OR: [
                {
                  parties: {
                    have: {
                      party: {
                        have: {
                          link: nodeId
                        }
                      },
                      deleted: {
                        notEqualTo: true
                      }
                    }
                  }
                },
                {
                  bulkStatsPerUser: {
                    have: {
                      user: {
                        have: {
                          link: nodeId
                        }
                      }
                    }
                  }
                }
              ]
            },
            orMyDraftOrNonDraft
          ]
        };
      case "Contact":
        const AND = [
          { deleted: { notEqualTo: true } },
          orMyDraftOrNonDraft,
          {
            parties: {
              have: {
                party: {
                  have: {
                    link: nodeId
                  }
                }
              }
            }
          }
        ];
        if (role !== "admin") {
          AND.push({
            parties: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                }
              }
            }
          });
        }
        return {
          AND
        };
      case "Lead":
        return {
          deleted: { notEqualTo: true },
          scope: {
            have: {
              link: nodeId
            }
          },
          ...orMyDraftOrNonDraft
        };
      default:
        const defaultAND = [
          { deleted: { notEqualTo: true } },
          {
            scope: {
              have: {
                link: nodeId
              }
            }
          },
          orMyDraftOrNonDraft
        ] as MailConversationWhereInput[];
        if (role !== "admin") {
          defaultAND.push({
            parties: {
              have: {
                party: {
                  have: {
                    link: viewerId
                  }
                }
              }
            }
          });
        }
        return {
          AND:defaultAND
        };
    }
  }, [type, nodeId, viewerId]);

  const handleCompose = async () => {
    const to = nodeEmail ? [nodeEmail] : [];
    const result = await createDraftMail({
      from: from,
      to: to,
      html: "",
      text: "",
      template: ""
    });
    navigate(result.data.createDraftEmail.conversation.objectId);
  };

  const onSelect = node => {
    navigate(node.objectId);
  };
  return (
    <RelayQuery<GetMailConversations>
      className={"MailConversation"}
      rowHeight={71}
      from={objectId}
      query={{
        document: GET_MAIL_CONVERSATIONS,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: { where: whereQuery }
      }}
      subscription={{
        document: SUBSCRIBE_MAIL_CONVERSATIONS,
        variables: { where: whereSubscription }
      }}
      render={renderProps => {
        const {
          scrollContainerRef,
          beforeLoaderRef,
          afterLoaderRef,
          loading,
          data: { mailConversations: { edges = [], pageInfo } } = {},
          register
        } = renderProps;
        const selected = edges.find(e => e.node.objectId === objectId)?.node;
        const activeIndex = edges.findIndex(e => e.node.objectId == objectId);
        const nextOrPrevConversation = activeIndex + 1 <= edges.length ?
          activeIndex + 1 :
          activeIndex - 1 >= 0 ? (activeIndex - 1) : null;

        return (
          <>
            {(!loading && !edges.length) ?
              <Container className={"empty-mail-view"}>
                <EmptyState
                  buttonDisabled={!canContact}
                  buttonLabel={"COMPOSE"}
                  onClick={node.__typename !== "User" && !node?.duplicateOf && handleCompose}
                  background
                  icon={"email"}
                  title={"You do not have email conversations"}
                  subtitle={node.__typename !== "User" && "To make new one click ‘Compose’ button"}/>
              </Container>
              :
              <Page>
                <Page.Sidebar width={expand ? 400 : 0}
                              style={{ borderWidth: expand ? "1px" : "0", opacity: expand ? 1 : 0 }}>
                  <Sidenav.Header>
                    <Toolbar spacing={6} size={"sm"}>
                      <Page.Toggle size={"sm"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>
                      <Typography weights={"medium"} variant={"base16"}>Emails</Typography>
                      <Stack.Item grow={1}/>
                      {
                        node.__typename !== "User" && !node?.duplicateOf &&
                        <Button
                          size={"xs"}
                          style={{ alignSelf: "end" }}
                          onClick={handleCompose}
                          disabled={!canContact}>
                          COMPOSE
                        </Button>
                      }
                    </Toolbar>
                  </Sidenav.Header>
                  <Sidenav style={{ height: "calc(100% - 56px)" }} expanded={expand} appearance="subtle">
                    <Sidenav.Body style={{ height: "100%" }}>
                      <div
                        className={"mail-list-container"}
                        ref={scrollContainerRef}>
                        {pageInfo?.hasPreviousPage && <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>}
                        {edges.map((item, index) => {
                          const mail = item.node;
                          const unread = mail.parties.some(p => p.party?.objectId === viewerObjectId && p.unread);
                          const viewerMetadata = mail.parties.find(p => p.party?.objectId === viewerObjectId)?.metadata;
                          const isDraft = viewerMetadata?.isDraft;
                          const draftCounts = viewerMetadata?.draftIds?.length ?? 0;
                          const threadCount = (mail.replyCount + (mail.emailId ? 1 : 0) + draftCounts);
                          const isSelected = (selected?.id == mail.id) && !isCompose;
                          const behalfOf = mail.type == "bulk" && (mail.lastSender?.party?.objectId !== node.objectId);
                          const stats = (mail.type == "bulk") && (behalfOf ? mail.bulkStatsPerUser.find(p => p.user.id == node.id)?.stats : mail.bulkStats);
                          const recipientsCount = mail.bulkStatsPerUser?.filter((u) => u.user.id !== mail.lastSender.party.id).length;
                          return <ListCard
                            key={mail.id}
                            count={mail.type !== "bulk" && threadCount > 1 && threadCount}
                            icon={mail.parent ? "rc_bulk_email" : undefined}
                            selected={isSelected}
                            unread={!behalfOf && unread}
                            behalfOf={behalfOf}
                            recipientsCounter={mail.type == "bulk" && !behalfOf && !!mail.bulkStatsPerUser?.length && recipientsCount}
                            ref={e => e && register(e, mail.objectId)}
                            objectIcon={behalfOf ? (node.objectIcon && node.objectIcon) : mail?.lastSender?.party?.objectIcon}
                            objectName={behalfOf ? node.objectName : mail.lastSender.party.objectName}
                            onClick={() => onSelect(mail)}
                            subject={
                              <Box container alignItems={"center"}>
                                <Typography
                                  weights={unread ? "medium" : "regular"}
                                  color={unread ? "primary" : "secondary"}
                                  variant={"base14"}
                                  className={"rc-text-content"}>
                                  {mail.subject}
                                </Typography>
                                {isDraft &&
                                <Typography>
                                  <mark style={{ color: "var(--rc-status-04-warning-dark)" }}>(draft)</mark>
                                </Typography>
                                }
                              </Box>
                            }
                            text={mail.lastContent}
                            date={mail.lastUpdatedAt}
                          >
                            {
                              mail.type == "bulk" &&
                              <ListCard.ProgressIndicator count={stats.total} icon={"rc_bulk_email"}
                                                          title={`Sending to ${stats?.total} ${toFirstLower(transformNameToLabel(mail[ "scopeType" ] ?? "Lead"))}${stats?.total > 1 ? "s" : ""}...`}
                                                          type={transformNameToLabel(mail[ "scopeType" ] ?? "Lead")}
                                                          progressPercentage={100 * (stats.failed + stats.sent + stats.canceled) / stats.total}/>
                            }
                          </ListCard>;
                        })}
                        {loading &&
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          style={{ flex: 1 }}>
                          <CircularLoader/>
                        </Stack>
                        }
                        <RelayMentionProvider<GetMailConversations>
                          variables={{
                            order: ["lastUpdatedAt_DESC"],
                            where: {
                              ...whereQuery,
                              AND: [
                                ...whereQuery.AND,
                                {
                                  parties: {
                                    have: {
                                      unread: { equalTo: true },
                                      party: {
                                        have: {
                                          link: viewerId
                                        }
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          }}
                          subscriptionVariables={{
                            where: {
                              ...whereSubscription,
                              parties: {
                                have: {
                                  unread: { equalTo: true },
                                  party: {
                                    have: {
                                      link: viewerId
                                    }
                                  }
                                }
                              }
                            }
                          }}
                          predicate={
                            ({ mailConversations: { edges = [] } }) => {
                              return edges.filter(e => {
                                const behalfOf = e.node.type == "bulk" && (e.node.lastSender?.party?.objectId !== node.objectId);
                                return !behalfOf && e.node.parties.some(p => {
                                  return p.party?.objectId === viewerObjectId && p.unread;
                                });
                              }).map(e => e.node.objectId);
                            }
                          }
                        >
                          <RelayMention direction={"top"} top={70}>
                            New email
                          </RelayMention>
                          <RelayMention direction={"bottom"} bottom={30}>
                            New email
                          </RelayMention>
                        </RelayMentionProvider>
                        {
                          afterLoaderRef && pageInfo?.hasNextPage &&
                          <CircularLoader alignSelf={"center"} ref={afterLoaderRef}/>
                        }
                      </div>
                    </Sidenav.Body>
                  </Sidenav>
                </Page.Sidebar>
                <Container style={{ overflowX: "hidden" }}>
                  <Routes>
                    <Route path={"/"} element={
                      loading ?
                        <Box container flex={1} justify={"center"} alignItems={"center"}
                             style={{ backgroundColor: "white" }}>
                          <CircularLoader/>
                        </Box> :
                        (
                          edges.length ?
                            <Navigate to={`${edges[ 0 ].node.objectId}`}/> :
                            <EmptyState icon={"email"} subtitle={`No emails available`}/>
                        )
                    }/>
                    <Route
                      path={"/:mailConversationId"}
                      element={
                        <Content
                          firstConversationId={edges[ nextOrPrevConversation ]?.node?.objectId}
                          expand={expand}
                          setExpand={setExpand}
                          draftId={createDraftMailData?.data?.createDraftEmail?.objectId}/>
                      }
                    />
                    <Route
                      path={"/:mailConversationId/:nearBy"}
                      element={
                        <Content
                          firstConversationId={edges[ nextOrPrevConversation ]?.node?.objectId}
                          expand={expand}
                          setExpand={setExpand}
                          draftId={createDraftMailData?.data?.createDraftEmail?.objectId}/>
                      }
                    />
                  </Routes>
                </Container>
              </Page>
            }
          </>
        );
      }}
    />
  );
});

export const MAIL_CONVERSATION = gql`
  fragment MailConversation on MailConversation {
    id
    type
    bulkStats {
      total
      queued
      sent
      spammed
      unsubscribed
      clicked
      failed
      canceled
      opened
    }
    replyCount
    parent {
      objectId
      id
      objectName
      lastSender {
        address
        type
        unread
        notified
        party {
          ...on Contact {
            ...Node
            objectId
            objectName
            objectIcon
          }
          ...on User {
            ...Node
            objectId
            objectName
            objectIcon
          }
        }
      }
    }
    objectId
    emailId
    subject
    status
    lastContent
    lastUpdatedAt
    createdAt
    updatedAt
    attachmentsCount
    scopeType
    parties {
      type
      address
      unread
      metadata {
        isDraft
        draftIds
      }
      party {
        ...on Contact {
          ...Node
          objectId
          objectName
          objectIcon
        }
        ...on User {
          ...Node
          objectId
          objectName
          objectIcon
        }
      }
    }
    scope {
      ...on Document {
        objectName
        objectId
        objectIcon
      }
      ...on Node {
        id
      }
      ...on Lead{
        team
        assignedUserTeam
      }
    }
    lastSender {
      address
      type
      unread
      notified
      party {
        ...on Contact {
          ...Node
          objectId
          objectName
          objectIcon
        }
        ...on User {
          ...Node
          objectId
          objectName
          objectIcon
        }
      }
    }
    bulkStatsPerUser {
      user {
        id
      }
      stats {
        total
        queued
        sent
        spammed
        unsubscribed
        clicked
        failed
        canceled
        opened
      }
    }
  }
  ${NODE_FRAGMENT}
`;

export const GET_MAIL_CONVERSATIONS = gql`
  query GetMailConversations($where: MailConversationWhereInput,$skip:Int,$before:String,$after:String,$from:String,$first:Int,$last:Int) {
    mailConversations(
      where:$where
      order: lastUpdatedAt_DESC
      first: $first,
      last: $last,
      skip:$skip
      before:$before
      after:$after,
      from:$from,
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...MailConversation
        }
      }
    }
  }
  ${MAIL_CONVERSATION}
`;
export const SUBSCRIBE_MAIL_CONVERSATIONS = gql`
  subscription SubscribeMailConversations($where: MailConversationSubscriptionWhereInput!) {
    mailConversations(where: $where){
      event
      node {
        ...MailConversation
      }
    }
  }
  ${MAIL_CONVERSATION}
`;

