export enum BaseFieldClasses {
  MultiField = "multi-field",
  FieldActions = "field-actions",
  RemoveAction = "remove-action",
  RemoveActionDisabled = "remove-action-disabled",
  FullSize = "full-size",
  HalfSize = "half-size",
  Indicator = "indicator",
  Disable = "disable",
  Divider = "divider",
  IndicatorEnable = "indicator-enable",
  IndicatorDisable = "indicator-disable",
  ReadField = "read-field",
  ReadFieldIcon = "read-field-icon",
  ReadFieldNotAvailable = "read-field-na",
  Default = "default"
}
