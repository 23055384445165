export function loadScript(url,attr:Record<string, string> = {}) {
  return new Promise<void>((accept, reject) => {
    const script = document.createElement("script");
    script.setAttribute("defer", "");
    script.setAttribute("src", url);
    Object.entries(attr).map(([key,value])=>{
      script.setAttribute(key, value);
    })
    const onLoadListener = () => {
      script.removeEventListener("load", onLoadListener);
      script.removeEventListener("error", onErrorListener);
      accept();
    };
    const onErrorListener = (e) => {
      script.removeEventListener("load", onLoadListener);
      script.removeEventListener("error", onErrorListener);
      reject(e.error);
    };
    script.addEventListener("load", onLoadListener, { once: true });
    script.addEventListener("error", onErrorListener, { once: true });
    document.head.append(script);
  });
}
