import { useMemo }      from "react";
import { useEffect }    from "react";
import { useState }     from "react";
import { useFormState } from "@relcu/form";
import { deepPick }     from "@relcu/ui";

export function useStreetViewSection(selectedFields) {
  const state = useFormState();
  const [panorama, setPanorama] = useState(null);

  let addressField = Object(selectedFields.find(({ type }) => (type == "Address")));
  const pickFields = (fields: string[], obj) => {
    let result: any = {};
    if (typeof obj !== "object") {
      return result;
    }
    let keys = Object.keys(obj);
    for (let k of keys) {
      if (fields.includes(k)) {
        result[ k ] = obj[ k ];
      } else if (typeof obj[ k ] === "object") {
        result = { ...result, ...pickFields(fields, obj[ k ]) };
      }
    }
    return result;
  };

  const address = useMemo(() => {
    let r = deepPick(state.values, [`${addressField.name}.lat`, `${addressField.name}.lng`, `${addressField.name}.formatted`]);
    return pickFields(["lat", "lng", "formatted"], r);
  }, [state.values, addressField]);

  const zillowLink = useMemo(() => {
    if (address?.formatted) {
      return `https://www.zillow.com/homes/${address.formatted.replaceAll(",", "").replaceAll(" ", "-")}_rb/`;
    }
    return `https://www.zillow.com/homes/`;
  }, [address?.formatted]);

  let placesService;
  let sessionToken;
  useEffect(() => {
    placesService = new window[ "google" ].maps.places.AutocompleteService();
    sessionToken = new window[ "google" ].maps.places.AutocompleteSessionToken();
  }, []);

  useEffect(() => {
    if (address.lat && address.lng) {
      if (panorama) {
        panorama.setPosition(address);
        panorama.setVisible(true);
      } else {
        let pano = new window[ "google" ].maps.StreetViewPanorama(
          document.getElementById("street-view") as HTMLElement,
          {
            position: address,
            pov: { heading: 165, pitch: 0 },
            zoom: 1
          }
        );
        pano.addListener("status_changed", () => {
          if (pano.getStatus() == "ZERO_RESULTS") {
            pano.setVisible(false);
            document.getElementById("street-view").style.backgroundColor = "transparent";
          }
        });
        setPanorama(pano);

      }

    } else if (panorama) {
      panorama.setVisible(false);
      document.getElementById("street-view").style.backgroundColor = "transparent";
    }
  }, [address?.lat, address?.lng]);

  return {
    panorama,
    zillowLink
  };
}
