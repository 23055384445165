import { useEffect }           from "react";
import { useState }            from "react";
import { useMemo }             from "react";
import { useLazyQuery }        from "@apollo/client";
import { getIn }               from "@relcu/form";
import { useSearchParams }     from "@relcu/react-router";
import { useThrottleCallback } from "@relcu/ui";
import { getHumanized }        from "../../../utils/schemaUtils";
import { GET_APPRAISAL_FEES }  from "./AppraisalFeesModal";

export function useAppraisalFees(props) {
  const { onClose, id, type } = props;
  const count = 10;
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const close = () => {
    searchParams.delete("page");
    onClose();
  };
  const getColumnValue = (row, column) => {
    if (column.name == "state") {
      return getIn(row.node, column.name);
    }
    return getHumanized(row.node.__typename, column.name, getIn(row.node, column.name));
  };
  const [getAppraisalFees, {
    data: { appraisalFees: { count: dataCount = 0, edges = [] } = {} } = {}, loading, refetch
  }] = useLazyQuery(GET_APPRAISAL_FEES, {
    fetchPolicy: "cache-and-network"
  });
  const where = useMemo(() => {
    let value = "";
    if (searchValue) {
      value = searchValue?.toLowerCase();
      return {
        AND: [{
          appraisal: {
            have: {
              id: {
                equalTo: id
              }
            }
          }
        },
          {
            loanType: {
              equalTo: type
            }
          },
          {
            OR: [
              {
                state: { matchesRegex: `.*${value}.*`, options: "i" }
              },
              {
                county: { matchesRegex: `.*${value}.*`, options: "i" }
              }
            ]
          }
        ]
      };
    } else {
      return {
        AND: [
          {
            appraisal: {
              have: {
                id: {
                  equalTo: id
                }
              }
            }
          },
          {
            loanType: {
              equalTo: type
            }
          }
        ]
      };
    }
  }, [type, id, searchValue]);
  useEffect(() => {
    getAppraisalFees({
      variables: {
        where,
        first: count,
        skip: count * (currentPage - 1)
      }
    });
  }, [count, currentPage]);

  const onChange = (val) => {
    setSearchValue(val);
    setCurrentPage(1);
    search()
  };

  const search = useThrottleCallback(() => {
      refetch({
        where,
        first: count,
        skip: count * (currentPage - 1)
      });
    }, 500, false
  );

  const api = {
    onChange,
    getColumnValue,
    close,
    loading,
    searchValue,
    edges,
    dataCount,
    count,
    currentPage,
    setCurrentPage
  };
  return {
    ...api
  };
}
