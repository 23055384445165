import { FC }                    from "react";
import React, { ReactNode }      from "react";
import { ItemsEllipsis }         from "../../..";
import { Box }                   from "../../..";
import { FontIcon }              from "../../..";
import { Label }                 from "../../..";
import { Chips }                 from "../../..";
import { ChipsColors }           from "../../..";
import { applyBoxItemStyles }    from "../../..";
import { BoxItemComponentProps } from "../../..";
import { InputState }            from "../../..";
import { useImperativeState }    from "../../..";
import { classNames }            from "../../..";
import { Keys }                  from "../../../constants/Keys";
import { BaseInputClasses }      from "../BaseInput";
import { BaseInputProps }        from "../BaseInput";

export interface MultiInputValue {
  value: string | number;
  key: string;
  state?: InputState
}

export interface MultiInputProps extends BoxItemComponentProps, BaseInputProps {
  value?: MultiInputValue[] | (number | string)[];
  clearable?: boolean;
  readOnly?: boolean;
  icon?: ReactNode;
  message?: string
  placeholder?: string;
  inputProps?: { [ key: string ]: any; };
  InputProps?: { [ key: string ]: any; };
  onChange?(value)
}

const defaultMultiInputProps = {
  disabled: false,
  readOnly: false,
  value: []
};

export const MultiInput: FC<MultiInputProps> = React.memo(function MultiInput(props) {
  let properties = applyBoxItemStyles<MultiInputProps>(props);
  const {
    className,
    children,
    label,
    value,
    clearable,
    disabled,
    readOnly,
    halfSize,
    fullSize,
    state,
    required,
    message,
    icon,
    name,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onFocus,
    InputProps,
    inputProps,
    ...p
  } = properties;

  const [stateValue, setStateValue] = useImperativeState(value, onChange);
  const classes = classNames(BaseInputClasses.Input, {
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error
  }, className);

  const setValue = (event: any) => {
    const key = event.key;
    const value = event.target.value;
    onKeyPress && onKeyPress(value);
    if (key === Keys.Enter) {
      event.preventDefault();
    }
    if (key == Keys.Enter && event.keyCode !== 229 && value) {
      setStateValue([...stateValue, value]);
      event.target.value = "";
    }
  };

  const deleteValue = (value) => {
    setStateValue([...stateValue.filter((item) => typeof item == "object" ? item.value !== value.value : item != value)]);
  };
  return <Box container direction={"column"} gap={"XXXS"} className={classes}  {...p} {...InputProps}>
    <Box container
         direction={"row"}
         alignItems={"center"}
         className={BaseInputClasses.MainContainer}
         gap={"XXS"}>
      {icon}
      <Box container direction={"column"} gap={"XXXS"} flex={1} className={BaseInputClasses.InputContainer}>
        {label && <Label required={required}>{label}</Label>}
        <Box container wrap={"wrap"} gap={"XXXS"}>
          <ItemsEllipsis
            showLength={2}
            items={stateValue}
            renderItem={(item, index) => {
              const color = typeof item == "object"
                ? item.state == InputState.Error
                  ? ChipsColors.Error : item.state == InputState.Success
                    ? ChipsColors.Success : ChipsColors.Grey
                : ChipsColors.Grey;
              return <Chips
                key={index}
                color={color}
                label={typeof item == "object" ? item.value : item}
                disabled={readOnly || disabled}
                onDelete={(e) => {
                  e.stopPropagation();
                  deleteValue(item);
                }}/>
            }}
          />
          <input
            name={name}
            disabled={disabled || readOnly}
            readOnly={readOnly}
            placeholder={placeholder || "Insert value"}
            onKeyPress={(e) => setValue(e)}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            style={{ width: "unset" }}
            {...inputProps}
          />
        </Box>
      </Box>
      <Box className={BaseInputClasses.StateContainer} alignSelf={"center"} alignItems={"center"}>
        {
          clearable && stateValue.length > 0 &&
          <FontIcon type={"clear"} style={{ cursor: "pointer" }}
                    onClick={disabled || readOnly ? null : () => setStateValue([])}/>
        }
      </Box>
    </Box>
    <Box container className={BaseInputClasses.Message}>{message}</Box>
  </Box>;
});
MultiInput.defaultProps = defaultMultiInputProps;

