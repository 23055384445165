import { useEffect }       from "react";
import { useContext }      from "react";
import { useMemo }         from "react";
import React, { useState } from "react";
import { Scrollbar }  from "../../..";
import { classNames } from "../../..";
import { Box }        from "../../..";
import { CalendarClasses } from "../CalendarClasses";
import { setMonth }        from "../date-fns";
import { setYear }         from "../date-fns";
import DatepickerContext   from "../Datepicker/DatepickerContext";

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
interface MonthYearProps {
  date: Date
  year: number;
  month: number;
  yearProps: any
}

export function MonthYear(props: MonthYearProps) {
  const { goToDate, onDateSelect } = useContext(DatepickerContext);
  const { year, month, date, yearProps } = props;
  const years = useMemo(() => {
    const minYear = yearProps && yearProps.startYear ? yearProps.startYear : 1990;
    const maxYear = yearProps && yearProps.endYear ? yearProps.endYear : 2030;
    const years = [];
    for (let i = minYear; i <= maxYear; i++) {
      years.push(i);
    }
    return years;
  }, []);
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [showMonth, setShowMonth] = useState(null);

  const onChangeYear = year => {
    setSelectedYear(year);
    setShowMonth(true);
  };

  const onChangeMonth = month => {
    setSelectedMonth(month);
    let dirtyDate = setYear(date, selectedYear);
    dirtyDate = setMonth(dirtyDate, month);
    goToDate(dirtyDate);
    onDateSelect(dirtyDate);
  };

  useEffect(()=>{
    const activeItem = document.querySelector(".year-item.active")
    activeItem?.scrollIntoView({behavior:"smooth"})
  },[])

  return (
      <Box container className={CalendarClasses.Years} wrap={"wrap"} alignItems={"start"} justify={"start"}>
        {!showMonth ? years.map((y, i) => {
            return <Box container justify={"center"} key={i} onClick={() => {
              onChangeYear(y);
            }} className={classNames(CalendarClasses.YearItem, {
              [ CalendarClasses.Active ]: (selectedYear == y)
            })}>{y}</Box>;
          })
          :
          monthNames.map((m, i) => {
            return <Box container justify={"center"} key={i} alignItems={"start"} onClick={() => {
              onChangeMonth(i);
            }} className={classNames(CalendarClasses.MonthItem, {
              [ CalendarClasses.Active ]: (monthNames[ selectedMonth ] == m)
            })}>{m}</Box>;
          })}
      </Box>
  );
}
