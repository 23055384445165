import { useReactiveVar }                      from "@apollo/client";
import { usePrevious }                         from "@relcu/ui";
import { useState }                            from "react";
import React                                   from "react";
import { FC }                                  from "react";
import { useContext }                          from "react";
import { useField }                            from "@relcu/form";
import { CircularLoader }                      from "@relcu/ui";
import { ButtonVariants }                      from "@relcu/ui";
import { Button }                              from "@relcu/ui";
import { Accordion }                           from "@relcu/ui";
import { FontIcon }                            from "@relcu/ui";
import { Box }                                 from "@relcu/ui";
import { classNames as clsNames }              from "@relcu/ui";
import { BoxComponentProps }                   from "@relcu/ui";
import { CommonClasses }                       from "@relcu/ui";
import { CurrencyField }                       from "@relcu/ui";
import { Where }                               from "@relcu/ui";
import { useMounted }                          from "@relcu/ui";
import { FormSummary }                         from "@relcu/ui";
import { useSource }                           from "@relcu/ui";
import { RateClasses }                         from "../Rate/RateClasses";
import { RateContext }                         from "../Rate/RateProvider";
import { RateSelectBody }                      from "../Rate/RateSelect";
import { WHEN_LENDERS_TITLE_IS_NOT_EMPTY }     from "../RateConditions";
import { WHEN_SETTLEMENT_FEE_IS_NOT_EMPTY }    from "../RateConditions";
import { WHEN_TRANSFER_TAX_IS_NOT_EMPTY }      from "../RateConditions";
import { WHEN_OWNERS_TITLE_IS_NOT_EMPTY }      from "../RateConditions";
import { WHEN_RECORDING_CHARGES_IS_NOT_EMPTY } from "../RateConditions";
import { WHEN_TITLE_INSURANCE_IS_NOT_EMPTY }   from "../RateConditions";
import { TITLE_FEE_SUM_FIELDS }                from "../RateConditions";
import { TitleProviderPreview }                from "../RatePartner";
import { RatePartnerClasses }                  from "../RatePartner/RatePartnerClasses";
import { RatesContext }                        from "../RatesProvider";
import { TitleFeeDialog }                      from "../TitleFeeDialog/TitleFeeDialog";

export const TitleFee: FC<BoxComponentProps> = React.memo(function TitleFee(props) {
  const { className } = props;
  const classNames = clsNames(RateClasses.RateSelect, className);
  const { $object: node } = useSource();
  const [modalOpen, setModalOpen] = useState(false);
  const { titleFeeResult } = useContext(RatesContext);
  const { selectedTitleFee } = useContext(RateContext);
  const titleFeesRefetch = titleFeeResult.refetch;
  const titleFeesError = titleFeeResult.error;
  const titleFeesLoading = titleFeeResult.loading;
  const handleTitleSelect = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const handleRefetch = (e) => {
    e.stopPropagation();
    titleFeesRefetch();
  };

  return <>
    {modalOpen && <TitleFeeDialog open={true} onClose={() => setModalOpen(false)}/>}
    <Accordion
      disable={titleFeesLoading}
      className={classNames}
      openClass={RateClasses.RateSelectOpen}
      flex={1}
      header={(open) => (
        <Box container alignItems={"center"} gap={"XS"} flex={1}
             className={clsNames(RateClasses.RateSelectHeader, {
               [ RateClasses.RateSelectExtended ]: open,
               [ RatePartnerClasses.RatePartnerError ]: !!titleFeesError,
               [ RatePartnerClasses.RatePartnerLoading ]: !!titleFeesLoading
             }, RateClasses.TitleFeeHeader)}>
          <FontIcon
            className={clsNames(CommonClasses.GrayIcon, {
              [ CommonClasses.PrimaryIcon ]: open
            })}
            alignSelf={"center"}
            type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}/>
          <p className={RateClasses.RateSelectTitle}>
            Title fees
          </p>
          {selectedTitleFee && <TitleProviderPreview/>}
          {
            titleFeesLoading
              ?
              <>
                <CircularLoader/>
                <p>Loading...</p>
              </>
              :
              titleFeesError ?
                <>
                  <p>
                    Failed to get Title fees.
                  </p>
                  {
                    titleFeesRefetch && !titleFeesLoading &&
                    <Button variant={ButtonVariants.Ghost} onClick={handleRefetch}>
                      RETRY
                    </Button>
                  }
                </>
                :null
          }
          <p className={RateClasses.RateSelectSum}>
            <FormSummary fields={TITLE_FEE_SUM_FIELDS}/>
          </p>
        </Box>)}>
      <RateSelectBody divider direction={"row"} wrap={"wrap"}>
        <Where source={{ ...selectedTitleFee?.fees }} conditions={WHEN_TITLE_INSURANCE_IS_NOT_EMPTY}>
          <CurrencyField view={"write"} disabled={(!selectedTitleFee?.editable)} precision={2}
                         flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"titleInsurance"}
                         label={"Title Service/Ins"}/>
        </Where>
        <Where source={{ ...selectedTitleFee?.fees }} conditions={WHEN_RECORDING_CHARGES_IS_NOT_EMPTY}>
          <CurrencyField view={"write"} disabled={(!selectedTitleFee?.editable)} precision={2}
                         flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"recordingCharges"}
                         label={"Recording charges"}/>
        </Where>
        <Where source={{ ...selectedTitleFee?.fees }} conditions={WHEN_OWNERS_TITLE_IS_NOT_EMPTY}>
          <CurrencyField view={"write"} disabled={(!selectedTitleFee?.editable)} precision={2}
                         flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"ownersTitle"}
                         label={"Owner's title"}/>
        </Where>

        <Where source={{ ...selectedTitleFee?.fees }} conditions={WHEN_TRANSFER_TAX_IS_NOT_EMPTY}>
          <CurrencyField view={"write"} disabled={(!selectedTitleFee?.editable)} precision={2}
                         flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"transferTax"}
                         label={"Transfer tax"}/>
        </Where>

        <Where source={{ ...selectedTitleFee?.fees }} conditions={WHEN_SETTLEMENT_FEE_IS_NOT_EMPTY}>
          <CurrencyField view={"write"} disabled={(!selectedTitleFee?.editable)} precision={2}
                         flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"settlementFee"}
                         label={"Settlement fee"}/>
        </Where>

        <Where source={{ ...selectedTitleFee?.fees }} conditions={WHEN_LENDERS_TITLE_IS_NOT_EMPTY}>
          <CurrencyField view={"write"} disabled={(!selectedTitleFee?.editable)} precision={2}
                         flexBasis={"calc(50% - ( 16px / 2 ))"}
                         name={"lendersTitle"}
                         label={"Lenders Title"}/>
        </Where>
        <TitleFeesSpy/>
      </RateSelectBody>
    </Accordion>
  </>;
});
export default TitleFee;
const TitleFeesSpy = React.memo(function TitleFeesSpy() {
  const { selected: { title: { customTitleFeeVar, selectedTitleFeeIdVar } }, titleFeeResult } = useContext(RatesContext);
  const { input: { value: titleInsuranceValue } } = useField("titleInsurance", { subscription: { value: true } });
  const { input: { value: recordingChargesValue } } = useField("recordingCharges", { subscription: { value: true } });
  const { input: { value: ownersTitleValue } } = useField("ownersTitle", { subscription: { value: true } });
  const { input: { value: transferTaxValue } } = useField("transferTax", { subscription: { value: true } });
  const { input: { value: settlementFeeValue } } = useField("settlementFee", { subscription: { value: true } });
  const { input: { value: lendersTitleValue } } = useField("lendersTitle", { subscription: { value: true } });
  const selectedFeeId = useReactiveVar(selectedTitleFeeIdVar);
  const prevFeeId = usePrevious(selectedFeeId);

  useMounted(() => {
    const selectedFee = titleFeeResult?.result.find(fee => fee.providerId == selectedFeeId);
    if(selectedFee) {
      if (
        ((selectedFee.fees.titleInsurance != titleInsuranceValue) && (selectedFee.fees.titleInsurance !== null)) ||
        ((selectedFee.fees.recordingCharges != recordingChargesValue) && (selectedFee.fees.recordingCharges !== null)) ||
        ((selectedFee.fees.ownersTitle != ownersTitleValue) && (selectedFee.fees.ownersTitle !== null)) ||
        ((selectedFee.fees.transferTax != transferTaxValue) && (selectedFee.fees.transferTax !== null)) ||
        ((selectedFee.fees.settlementFee != settlementFeeValue) && (selectedFee.fees.settlementFee !== null)) ||
        ((selectedFee.fees.lendersTitle != lendersTitleValue) && (selectedFee.fees.lendersTitle !== null))
      ) {
        let updatedFees = {
          ...selectedFee.fees,
          titleInsurance: selectedFee.fees.titleInsurance !== null ? Number(titleInsuranceValue) : null,
          recordingCharges: selectedFee.fees.recordingCharges !== null ? Number(recordingChargesValue) : null,
          ownersTitle: selectedFee.fees.ownersTitle !== null ? Number(ownersTitleValue) : null,
          transferTax: selectedFee.fees.transferTax !== null ? Number(transferTaxValue) : null,
          settlementFee: selectedFee.fees.settlementFee !== null ? Number(settlementFeeValue) : null,
          lendersTitle: selectedFee.fees.lendersTitle !== null ? Number(lendersTitleValue) : null,
        };
        customTitleFeeVar({
          ...selectedFee,
          title: "Custom",
          providerId: "custom",
          fees: updatedFees
        });
        selectedTitleFeeIdVar("custom");
      }
    }

  }, [titleInsuranceValue, recordingChargesValue, ownersTitleValue, transferTaxValue, settlementFeeValue, lendersTitleValue, selectedFeeId, prevFeeId, titleFeeResult]);
  return null;
});
