import { ButtonColors }              from "@relcu/ui";
import { Mention }                   from "@relcu/ui";
import { useContext }                from "react";
import React                         from "react";
import { UnreadNotificationContext } from "../../UnreadNotificationProvider";
import { useShowMention }            from "./useShowMention";

export const NotificationMention = React.memo<{ scrollContainerRef }>(({ scrollContainerRef }) => {
  const { notifications } = useContext(UnreadNotificationContext);
  const { setShowMention, showMention } = useShowMention(notifications.edges, scrollContainerRef.current);
  return <>
    {
      showMention &&
      <Mention
        width={150}
        direction={"top"}
        color={ButtonColors.Error}
        icon={"north"}
        onClick={() => {
          scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
          setShowMention(false);
        }}>
        New notification
      </Mention>
    }
  </>;
});
