import React               from "react";
import { Input }           from "../../Input/Input";
import { InputState }      from "../../Input/Input";
import { useConsumeField } from "../BaseField";
import { TextFieldProps }  from "./TextField";

export const TextEditField = React.memo(function TextField(props) {
  const { input, meta: { touched, error, submitError, dirtySinceLastSubmit, ...p} } = useConsumeField<TextFieldProps>();
  const hasError = (touched && !!error) || (!dirtySinceLastSubmit && !!submitError);
  return (
    <Input
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
      {...input}
    />
  );
});
export default TextEditField;
