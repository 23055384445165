import React                   from "react";
import { FC }                  from "react";

export type Source = Record<string, any>
export const SourceContext = React.createContext<Source>({});
export const SourceProvider: FC<{ source: Source }> = React.memo(function SourceProvider(props) {
  return (
    <SourceContext.Provider value={props.source}>
      {props.children}
    </SourceContext.Provider>
  );
});
