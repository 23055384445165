import { ReactElement } from "react";
import { FC }           from "react";
import React            from "react";
import { IconType }     from "../../index";
import { ModalProps }   from "../../index";
import { Button }       from "../../index";
import { EmptyState }   from "../../index";
import { Typography }   from "../../index";
import { Modal }        from "../../index";

export interface EmptyModalProps extends ModalProps {
  onCancel?: () => void
  onSubmit?: () => void
  icon?: IconType
  header: string
  title?: string
  subtitle?: string
  content?: ReactElement
  label?: string
  cancelLabel?: string
}

export const EmptyModal: FC<EmptyModalProps> = React.forwardRef((props: EmptyModalProps, ref) => {
  const { onCancel, onClose, onSubmit, cancelLabel, label, open, icon, header, content, title, subtitle } = props;
  return <Modal open={open} onClose={onClose}>
    <Modal.Header>
      {header && <Typography weights={"medium"} variant={"base16"}>
        {header}
      </Typography>}
    </Modal.Header>
    <Modal.Body>
      {content ? content : <EmptyState icon={icon}
                                       title={title}
                                       subtitle={subtitle}
      />}
    </Modal.Body>
    <Modal.Footer>
      {onCancel && <Button onClick={onCancel} color={"blue"} appearance="text">
        {cancelLabel || "CANCEL"}
      </Button>}
      {onSubmit && <Button onClick={onSubmit} appearance="primary">
        {label || "SUBMIT"}
      </Button>}
    </Modal.Footer>
  </Modal>;

});
