import { useQuery }         from "@apollo/client";
import { schemaVar }        from "../../reactiveVars";
import { ViewerUserRole }   from "./__types__/ViewerUserRole";
import { VIEWER_USER_ROLE } from "./queries.grapql";

export function useSearchPermissions() {
  const { data: { viewer } } = useQuery<ViewerUserRole>(VIEWER_USER_ROLE);
  const schema = schemaVar();

  function canSearch(className: string) {
    const searchable = schema?.[className]?.isSearchable;
    if (searchable === undefined) {
      return false;
    } else if (typeof searchable === "boolean") {
      return searchable;
    } else {
      return typeof searchable[ viewer.user.role ] === "boolean" ? searchable[ viewer.user.role ] : searchable[ "*" ];
    }
  }

  return {
    canSearch
  }
}
