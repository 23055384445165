import { useMutation }                       from "@apollo/client";
import { gql }                               from "@apollo/client";
import { useLazyQuery }                      from "@apollo/client";
import { CreateContactVariables }            from "./__types__/CreateContact";
import { CreateContact }                     from "./__types__/CreateContact";
import { FindContactByPhoneNumberVariables } from "./__types__/FindContactByPhoneNumber";
import { FindContactByPhoneNumber }          from "./__types__/FindContactByPhoneNumber";
import { FindPhoneLinesCountVariables }      from "./__types__/FindPhoneLinesCount";
import { FindPhoneLinesCount }               from "./__types__/FindPhoneLinesCount";

export const CONTACT = gql`
  fragment Contact on Contact {
    id
    objectId
    objectName
    objectIcon
    firstName
    lastName
    types
    ssn
    birthday
    company
    tags
    phones {
      number
      objectName
      type
      carrier
      nationalFormat
      sms
      smsCountryCode
      callOptOut
      smsOptOut
      default
    }
    emails {
      address
      type
      optOut
    }
  }
`;

const CREATE_CONTACT = gql`
  mutation CreateContact($input:CreateContactInput!) {
    createContact(input: $input){
      contact {
        ...Contact
      }
    }
  }
  ${CONTACT}
`;

const FIND_PHONE_LINE_COUNT = gql`
  query FindPhoneLinesCount($number:StringWhereInput) {
    phoneLines(where: {number: $number}){
      count
    }
  }
`;

export const FIND_CONTACT_BY_PHONE_NUMBER = gql`
  query FindContactByPhoneNumber($where: ContactWhereInput, $first: Int) {
    contacts(where: $where, first: $first){
      edges {
        cursor
        node {
          ...Contact
          __typename
        }
      }
    }
  }
  ${CONTACT}
`;

export function useDIalPadSubmit() {
  const [findPhoneLines] = useLazyQuery<FindPhoneLinesCount, FindPhoneLinesCountVariables>(FIND_PHONE_LINE_COUNT);
  const [getContactByPhoneNumber] = useLazyQuery<FindContactByPhoneNumber, FindContactByPhoneNumberVariables>(FIND_CONTACT_BY_PHONE_NUMBER);
  const [create] = useMutation<CreateContact, CreateContactVariables>(CREATE_CONTACT);
  const [findContacts] = useLazyQuery<FindContactByPhoneNumber, FindContactByPhoneNumberVariables>(FIND_CONTACT_BY_PHONE_NUMBER, {
    fetchPolicy: "cache-and-network"
  });

  const createContact = async (to) => {
    const { data: { createContact: { contact } = {} } = {} } = await create({
      variables: {
        input: {
          fields: {
            phones: [
              {
                type: "home",
                number: to,
                isPrimary: true
              }
            ],
            emails: [],
            types: [
              "borrower"
            ]
          }
        }
      }
    });

    return contact;
  };

  const checkIsInternalNumber = async (to) => {
    const { data: { phoneLines: { count } = {} } = {} } = await findPhoneLines({ variables: { number: { equalTo: to } } });
    return !!count;
  };

  const getDialedContact = async (to) => {
    let contact;
    const { contacts: { edges = [] } = {} } = await getContactByPhoneNumber({
      variables: {
        first: 10,
        where: {
          phones: {
            have: {
              number: {
                matchesRegex: `^\\+1${to.slice(2)}`
              }
            }
          }
        }
      }
    }) || Object();

    if (edges.length) {
      contact = edges[ 0 ].node;
    } else {
      const { data: { contacts: { edges = [] } = {} } = {} } = await findContacts({
        variables: {
          where: {
            phones: {
              have: {
                number: {
                  equalTo: to
                }
              }
            }
          }
        }
      });
      contact = edges[ 0 ]?.node;
    }

    return contact;
  };

  return {
    checkIsInternalNumber,
    getDialedContact,
    createContact
  };
}
