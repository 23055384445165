import { useCallback }   from "react";
import { FC }            from "react";
import React             from "react";
import { Icon }          from "../Icon";
import { IconButton }    from "../index";
import { Typography }    from "../index";
import { useClassNames } from "../utils";

export interface FileUploadCardProps {
  fileName: string;
  fileSize: string;
  isUploading?: boolean;
  extension: string;
  onCancel?: Function;
  onDelete?: Function;
  onDownload?: () => void;
  incoming?: boolean;
  errorMsg?: string;
}
export const FileUploadCard: FC<FileUploadCardProps> = (props: FileUploadCardProps) => {
  const { fileName, fileSize, isUploading, extension, onCancel, errorMsg, onDelete, incoming, onDownload } = props;
  const onClick = useCallback(() => {
    if (isUploading) {
      onCancel && onCancel();
    } else {
      onDelete && onDelete();
    }
  }, [isUploading, onDelete, onCancel]);

  const { withClassPrefix, prefix, merge } = useClassNames("upload-file-card-container", "rc");
  const classes = merge(
    withClassPrefix({ error: !!errorMsg })
  );

  return <div className={classes}>
    <div className={"rc-upload-file-card"} style={incoming && { cursor: "pointer" }} onClick={incoming && onDownload}>
      {extension == "pdf" ? <PdfIcon/> : <FileIcon/>}
      <Typography
        className={"rc-file-name"}
        color={"primary"}
        variant={"small12"}>
        {fileName}
      </Typography>
      {
        !isNaN(fileSize as any) &&
        <Typography
          color={"secondary"}
          variant={"small12"}>
          {fileSize} {isUploading && " - Uploading..."}
        </Typography>
      }
      {
        (!isUploading && onDelete) &&
        <IconButton
          onClick={onClick}
          circle={true}
          appearance={"subtle"}
          size={"xs"}
          icon={<Icon className={"rc-upload-delete-icon"} type={isUploading ? "clear" : "delete"}/>}
        />
      }
    </div>
    {
      errorMsg &&
      <p className={"rc-upload-file-card-error-msg"}>
        {errorMsg}
      </p>
    }

  </div>;
};

const PdfIcon = () => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4997 5.03098L16.4989 5.01282C16.4978 4.99088 16.4959 4.96044 16.4924 4.92698C16.4888 4.89288 16.4841 4.86029 16.4785 4.83288C16.4736 4.80854 16.4699 4.79805 16.47 4.79802C16.4515 4.75619 16.4158 4.69995 16.3735 4.64405C16.354 4.61817 16.3363 4.59671 16.3241 4.58226L16.3129 4.56937L13.51 1.74267C13.5009 1.73464 13.4776 1.7148 13.4435 1.68977C13.3901 1.65046 13.3192 1.60516 13.242 1.5711L13.4438 1.11364L13.242 1.5711C13.1822 1.54472 13.09 1.52387 12.9922 1.51159C12.9472 1.50596 12.9083 1.50298 12.8813 1.50145C12.8679 1.50069 12.8578 1.5003 12.8518 1.50012L12.8468 1.49997L12.8471 1.49997L12.8472 1.49997L12.8471 1.49993C12.8469 1.49985 12.8465 1.49967 12.8459 1.49939V1.49996V1.49997M16.4997 5.03098L16.9994 5.04812V17.6584C16.9994 18.4061 16.4243 18.9979 15.697 18.9979H4.30239C3.57508 18.9979 3 18.4062 3 17.6584V2.33952C3 1.59179 3.57508 1 4.30239 1H12.3523C12.4854 1.32342 12.7921 1.47475 12.8407 1.49705L12.8406 1.49997H12.8459M16.4997 5.03098C16.4996 5.03289 16.4996 5.0348 16.4995 5.0367L16.4994 5.0367V5.04812V17.6584C16.4994 18.1434 16.1349 18.4979 15.697 18.4979H4.30239C3.86444 18.4979 3.5 18.1434 3.5 17.6584V2.33952C3.5 1.85451 3.86445 1.5 4.30239 1.5H12.8459V1.49997M16.4997 5.03098L12.8523 1L12.8575 1.49997L12.8465 1.49997H12.8459"
      fill="white" stroke="#D5DAE2"/>
    <path
      d="M6.17502 13.804C5.73648 13.3532 6.21094 12.7337 7.49858 12.0759L8.30877 11.662L8.62441 10.952C8.79801 10.5615 9.05711 9.92441 9.20018 9.53617L9.46032 8.83029L9.28105 8.3079C9.06063 7.66557 8.98203 6.70032 9.12188 6.35323C9.31131 5.88309 9.93179 5.93133 10.1774 6.43529C10.3693 6.82888 10.3497 7.54167 10.1223 8.44059L9.93582 9.17755L10.1 9.46415C10.1904 9.62178 10.4542 9.99606 10.6863 10.2959L11.1226 10.8538L11.6655 10.781C13.3903 10.5496 13.981 10.9428 13.981 11.5066C13.981 12.2182 12.6267 12.2769 11.4895 11.4558C11.2336 11.271 11.0579 11.0875 11.0579 11.0875C11.0579 11.0875 10.3454 11.2366 9.99465 11.3338C9.63258 11.4341 9.45194 11.497 8.92155 11.681C8.92155 11.681 8.73546 11.9588 8.6142 12.1608C8.16305 12.9122 7.63629 13.5349 7.2601 13.7615C6.8389 14.0153 6.39734 14.0326 6.17502 13.804ZM6.86323 13.5513C7.10974 13.3947 7.60868 12.788 7.95406 12.225L8.09389 11.997L7.45718 12.3261C6.47379 12.8344 6.0239 13.3135 6.25784 13.6033C6.38926 13.766 6.54647 13.7525 6.86323 13.5513ZM13.2509 11.7081C13.492 11.5344 13.457 11.1846 13.1845 11.0435C12.9723 10.9338 12.8013 10.9112 12.2503 10.9196C11.9116 10.9433 11.367 11.0135 11.2747 11.0348C11.2747 11.0348 11.5739 11.2474 11.7067 11.3255C11.8835 11.4293 12.3132 11.622 12.6269 11.7207C12.9363 11.8181 13.1153 11.8078 13.2509 11.7081ZM10.6825 10.6106C10.5366 10.453 10.2885 10.1238 10.1311 9.87927C9.92537 9.60184 9.82218 9.40611 9.82218 9.40611C9.82218 9.40611 9.67177 9.90343 9.54838 10.2027L9.16341 11.1809L9.05179 11.4028C9.05179 11.4028 9.64518 11.2028 9.94708 11.1217C10.2668 11.0359 10.9158 10.9047 10.9158 10.9047L10.6825 10.6106ZM9.85482 7.19834C9.89204 6.87725 9.90784 6.55663 9.80748 6.39495C9.52913 6.08213 9.19317 6.343 9.25008 7.08671C9.26922 7.33689 9.32968 7.76458 9.41051 8.02819L9.55748 8.50747L9.66091 8.14649C9.7178 7.94795 9.80506 7.52128 9.85482 7.19834Z"
      fill="#E21022"/>
  </svg>;
};

const FileIcon = () => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4997 5.03145L16.4989 5.01328C16.4978 4.99133 16.4959 4.96088 16.4924 4.92742C16.4888 4.89331 16.4841 4.86071 16.4785 4.83329C16.4736 4.80895 16.4699 4.79845 16.47 4.79842C16.47 4.79841 16.4702 4.79904 16.4708 4.80026C16.4525 4.75823 16.4164 4.70114 16.3735 4.64441C16.354 4.61852 16.3363 4.59707 16.324 4.58261L16.3129 4.56972L13.51 1.74269C13.5009 1.73467 13.4776 1.71482 13.4435 1.68978C13.39 1.65047 13.3192 1.60517 13.242 1.57111L13.4438 1.11365L13.242 1.57111C13.1822 1.54473 13.09 1.52387 12.9921 1.51159C12.9472 1.50596 12.9083 1.50298 12.8812 1.50145C12.8679 1.50069 12.8578 1.5003 12.8518 1.50012L12.8468 1.49997L12.8471 1.49997L12.8472 1.49997L12.8471 1.49993C12.8469 1.49985 12.8465 1.49967 12.8459 1.49939V1.49996V1.49997M16.4997 5.03145L16.9994 5.04858V17.6604C16.9994 18.4081 16.4243 19 15.697 19H4.30239C3.57508 19 3 18.4082 3 17.6604V2.33967C3 1.59186 3.57508 1 4.30239 1H12.3523C12.4854 1.32342 12.7921 1.47475 12.8407 1.49705L12.8406 1.49997H12.8459M16.4997 5.03145C16.4996 5.03336 16.4996 5.03526 16.4995 5.03717L16.4994 5.03717V5.04858V17.6604C16.4994 18.1455 16.1349 18.5 15.697 18.5H4.30239C3.8645 18.5 3.5 18.1455 3.5 17.6604V2.33967C3.5 1.85453 3.86451 1.5 4.30239 1.5H12.8459V1.49997M16.4997 5.03145L12.8523 1L12.8575 1.49997L12.8465 1.49997H12.8459M16.3091 4.56544L16.3094 4.56571L16.3093 4.56562L16.3091 4.56544Z"
      fill="white" stroke="#D5DAE2"/>
    <rect x="6" y="7" width="8" height="1" rx="0.5" fill="#D5DAE2"/>
    <rect x="6" y="10" width="8" height="1" rx="0.5" fill="#D5DAE2"/>
    <rect x="6" y="13" width="8" height="1" rx="0.5" fill="#D5DAE2"/>
  </svg>;
};
