import { useClassNames }  from "@relcu/rc";
import React              from "react";
import { FC }             from "react";
import { HTMLAttributes } from "react";
import { Stack }          from "@relcu/rc";
import { Typography }     from "@relcu/rc";
import { Avatar }         from "@relcu/rc";
import "./avatar-list-item.css";

export interface AvatarListItemProps extends HTMLAttributes<HTMLDivElement>{
  title: string;
  active?: boolean;
  src?: string;
  disabled?: boolean;
  text: string;
}

export const AvatarListItem: FC<AvatarListItemProps> = React.memo(function AvatarListItem(props) {
  const { title, text, src, active, disabled, ...p  } = props;
  const {merge, withClassPrefix} = useClassNames("avatar-list-item","rc")
  const classes = merge( withClassPrefix({disabled}));

  return <Stack spacing={8} className={classes} {...p}>
    <Avatar showIndicator={active != null} color={active ? "green" : "red"} src={src} size={"sm"}>
      {title}
    </Avatar>
    <Stack direction={"column"} spacing={8} alignItems={"flex-start"}>
      <Typography weights={"medium"}>
        {title}
      </Typography>
      <Typography color={"secondary"}>
        {text}
      </Typography>
    </Stack>
  </Stack>;
});
