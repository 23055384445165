import React                   from "react";
import { FC }                  from "react";
import { TypographyColor }     from "@relcu/ui";
import { Typography }          from "@relcu/ui";
import { TypographySize }      from "@relcu/ui";
import { EmptyList }           from "@relcu/ui";
import { Sidebar }             from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { JsonViewProps }       from "@relcu/ui";
import { PriorityQueues }      from "./PriorityQueues";
import { PriorityViewClasses } from "./PriorityViewClasses";
import { useFocusView }        from "./useFocusView";
import "./priority-view.css";

export const PriorityView: FC<JsonViewProps> = React.memo(function PriorityView(props: JsonViewProps) {
    const {
      views,
      visible,
      setVisible,
      view,
      loading
    } = useFocusView();
    if (loading) {
      return null;
    }

    return (
      <Box container direction={"row"} className={PriorityViewClasses.PriorityView}>
        {
          !views.length ? <Box container direction={"column"} flex={1} className={PriorityViewClasses.EmptyPage}>
            <Typography size={TypographySize.SubtitleSmall} color={TypographyColor.ExtraDark}>Focus views</Typography>
            <Box container direction={"column"} justify={"center"} flexGrow={1}>
              <EmptyList
                title={"No focus views found"}
                content={"Please contact your admin to create \"Focus view\""}
                icon={"rc_focus"}/>
            </Box>
          </Box> : <>
            {visible &&
              <Sidebar
                items={views}
                title="Focus"
                onClose={() => setVisible(false)}
              />
            }
            {!!view &&
              <PriorityQueues
                view={view}
                expanded={visible}
                onExpand={setVisible}
                jql={props.jql.query.find}
                fields={props.fields}
                actions={props.actions}
              />
            }
          </>
        }
      </Box>
    );
  }
);

export default PriorityView;
