import React, { HTMLAttributes } from "react";
import { useClassNames }         from "../utils";
import { TabClasses }            from "./TabClasses";

export interface TabPanelContainerProps extends HTMLAttributes<HTMLDivElement> {
}

export function TabPanelContainer(props: TabPanelContainerProps) {
  const { children, className, ...p } = props;
  const { merge, withClassPrefix } = useClassNames(TabClasses.TabPanelContainer, "rc");
  const classes = merge(className, withClassPrefix());

  return <div className={classes} {...p}>
    {children}
  </div>;
}
