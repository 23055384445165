import React                     from "react";
import { classNames }            from "../..";
import { applyBoxItemStyles }    from "../..";
import { BoxItemComponentProps } from "../..";
import { NavBarClasses }         from "./NavBarClasses";

export function NavBarDivider(props: BoxItemComponentProps) {
  const properties = applyBoxItemStyles(props);
  const { className, ...p } = properties;
  const classes = classNames(NavBarClasses.NavBarDivider, className);

  return <div {...p} className={classes}/>;
}
