import React                 from "react";
import { ToggleSwitch }      from "../../Input/ToggleSwitch";
import { ToggleSwitchProps } from "../../Input/ToggleSwitch";
import { useConsumeField }   from "../BaseField";

export const ToggleSwitchEditField = React.memo(function SwitchEditField(props) {
  const { input } = useConsumeField<ToggleSwitchProps>();
  return (
    <ToggleSwitch {...input}/>
  );
});
export default ToggleSwitchEditField;
