import { FC }               from "react";
import React                from "react";
import { CounterProps }     from "../../../../Counter";
import { Counter }          from "../../../../Counter";
import { Section }          from "../../../../Section";
import { JsonSectionProps } from "../../JsonSection";

export const StatsSection: FC<StatsSectionProps> = React.memo(function LeadStatsSection(props) {
  const { counters, ...section } = props;
  return (
    <Section {...section}>
      <Counter counters={counters}/>
    </Section>
  );
});
StatsSection.defaultProps = {
  counters: []
};
export interface StatsSectionProps extends JsonSectionProps, CounterProps {

}
