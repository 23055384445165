import React                    from "react";
import { useMemo }              from "react";
import { FC }                   from "react";
import { Box }                  from "../Box";
import { BoxComponentProps }    from "../Box";
import { classNames }           from "../../utils/classNames";

export type LinkableTextProps = BoxComponentProps & {
    text: string
    target?: string
};

export enum LinkableTextClasses {
    LinkableText = "linkable-text",
    Primary = "linkable-text--primary"
}

export const LinkableText: FC<LinkableTextProps>  = React.memo(function LinkableText(props) {
  const { text = "", target = "_blank", className, ...p } = props;
  const classes = classNames(LinkableTextClasses.LinkableText, LinkableTextClasses.Primary, className);

  const content = useMemo(() => {
      const reg = /((http|https):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
      const truncate = (input, count) => input.length > count ? input.substring(0, count) + '...' : input;

      return (text||"").replace(reg, (url) => (`<a href=${url} target='${target}'>${truncate(url, 30)}</a>`));
  }, [text, target])

  return (
    <Box {...p}>
        <p className={classes} dangerouslySetInnerHTML={{__html: content}} />
    </Box>
  );
});
