import { FC }            from "react";
import React             from "react";
import { Icon }          from "../Icon";
import { Button }        from "../index";
import { Typography }    from "../index";
import { useClassNames } from "../utils";

export interface SectionMessageProps {
  type: "info" | "warning"
  buttonText?: string,
  onBtnClick?: () => void,
}
export const SectionMessage: FC<SectionMessageProps> = (props) => {
  const { type, children, buttonText, onBtnClick } = props;
  const { withClassPrefix, merge, prefix } = useClassNames("section-message", "rc");
  const className = merge(withClassPrefix(type));
  return <div className={className}>
    <Icon type={type}/>
    <div className={"rc-section-message-section"}>
      {typeof children === "string" ? <Typography color={"primary"} variant={"base14"}>{children}</Typography> : children}
    </div>
    {
      buttonText && <Button appearance={"text"} onClick={onBtnClick}>
        {buttonText}
      </Button>
    }
  </div>;
};
