import { FC }                from "react";
import React                 from "react";
import { BoxComponentProps } from "../..";
import { Box }               from "../..";
import { FontIcon }          from "../..";
import { IconType }          from "../..";
import { NavBarClasses }     from "./NavBarClasses";

interface NavBarEmptyCurrentProps extends BoxComponentProps {
  title: string
  text: string
  icon: IconType
  small?: boolean
}

export const NavBarEmptyCurrent: FC<NavBarEmptyCurrentProps> = React.memo(function NavBarEmptyCurrent(props) {
  const { className, small, icon, title, text, ...p } = props;
  return <Box
    container
    className={NavBarClasses.NavBarEmptyCurrent}
    flex={1}
    direction={"column"}
    alignItems={"center"}
    justify={"center"}
    gap={"XXS"}
    {...p}>
    <FontIcon type={icon}/>
    {
      !small &&
      <>
        <Box>
          {title}
        </Box>
        <p>
          {text}
        </p>
      </>
    }
  </Box>;
});
