import { FC }                from "react";
import React                 from "react";
import { ButtonProps }       from "../..";
import { useBoxProps }       from "../..";
import { BoxComponentProps } from "../..";
import { Box }               from "../..";
import { classNames }        from "../..";
import { WidgetClasses }     from "./WidgetClasses";

export const Widget: FC<WidgetProps> = React.memo(function Widget(props) {
  const { className, title, button, ...p } = props;
  return (
    <Box container direction={"column"} gap={"XXS"} flex={1}
         className={classNames(WidgetClasses.Widget, className)} {...useBoxProps(p)}>
      {!!title && (<Box container justify={"space-between"} alignItems={"center"}>
          <Box container alignSelf={"center"} className={WidgetClasses.WidgetHeader}>
            {title}
          </Box>
        {button}
      </Box>)}
      {props.children}
    </Box>
  );
});

export interface WidgetProps extends BoxComponentProps {
  title?: string,
  button?: React.ReactElement<ButtonProps>
}
