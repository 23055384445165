import { useApolloClient }                 from "@apollo/client";
import { AlertColors }                     from "@relcu/ui";
import { CircularLoader }                  from "@relcu/ui";
import { Ellipsis, FontIcon, ModalFooter } from "@relcu/ui";
import { ModalBody }                       from "@relcu/ui";
import { ListItemText }                    from "@relcu/ui";
import { BackDropLoader }                  from "@relcu/ui";
import { ButtonVariants }                  from "@relcu/ui";
import { ModalProps }                      from "@relcu/ui";
import { Button }                          from "@relcu/ui";
import { ListItemTitle }                   from "@relcu/ui";
import { ListRow }                         from "@relcu/ui";
import { Radio }                           from "@relcu/ui";
import { Alert }                           from "@relcu/ui";
import { TabItem }                         from "@relcu/ui";
import { Tabs }                            from "@relcu/ui";
import { TabPanel }                        from "@relcu/ui";
import { TabPanelContainer }               from "@relcu/ui";
import { TabContainer }                    from "@relcu/ui";
import { EmptyList }                       from "@relcu/ui";
import { Modal }                           from "@relcu/ui";
import { CommonClasses }                   from "@relcu/ui";
import { useSource }                       from "@relcu/ui";
import { Box }                             from "@relcu/ui";
import { useContext }                      from "react";
import { useCallback }                     from "react";
import React                               from "react";
import { FC }                              from "react";
import { useMemo }                         from "react";
import { useState }                        from "react";
import { format }                          from "../../../../../../utils/helpers";
import { RateContext }                     from "../Rate/RateProvider";
import { RatesContext }                    from "../RatesProvider";
import "./pmi-dialog.css";
import { PmiDialogClasses }                from "./PmiDialogClasses";

export const productTypesMap = {
  monthly: "MONTHLY",
  single_lp: "LENDER SINGLE",
  single_nr: "SINGLE NON REFUNDABLE",
  single_r: "SINGLE",
  split_bd: "SPLIT PREMIUM"
};

export interface PmiPartnerDialogProps extends Partial<ModalProps> {
  selectedPmi?: Record<any, any>;
  loading?: boolean;
  queryInput?: any;
  onApply?(value);
}
export const PmiDialog: FC<PmiPartnerDialogProps> = React.memo(function PmiDialog(props) {
    const { loading, onClose } = props;
    const { selectedPmi } = useContext(RateContext);
    const { pmiResult: { error: pmiError, result: sortedRates, loading: pmiRateLoading, refetch }, selected: { pmi: { selectedPmiVar } } } = useContext(RatesContext);
    const [selected, setSelected] = useState<number | any>(selectedPmi);
    const options = useMemo(() => Object.keys(sortedRates || {}), [sortedRates])
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(selectedPmi ? options.indexOf(selectedPmi.option) : 0);
    const onApply = useCallback(() => {
      selectedPmiVar(selected);
      onClose?.();
    }, [selected, onClose]);
    return <Modal
      title={"PMI"}
      onClose={onClose}
      open={true}
      closeIcon={true}
      disableBackdropClick={false}
      className={PmiDialogClasses.Modal}
    >
      <ModalBody direction={"column"} gap={"S"} className={PmiDialogClasses.Container}>
        {pmiError &&
          <Box container gap={"XXXS"} direction={"column"} flex={"1 0 auto"} alignItems={"center"} justify={"end"}>
            <Alert variant={AlertColors.Error}
                   children={`${pmiError}`}
                   width={"100%"}/>
            <Button style={{ pointerEvents: pmiRateLoading ? "none" : "auto" }}
                    disabled={pmiRateLoading} variant={ButtonVariants.Ghost}
                    onClick={refetch}>
              Reload
            </Button>
          </Box> }
        {
          options.length > 0 ?
            <>
              <TabContainer gap={"S"} outerState={[selectedOptionIndex, setSelectedOptionIndex]}>
                <TabPanelContainer>
                  {
                    options.map((option, key) => {
                      return <TabPanel style={{ overflowY: "hidden" }} key={`tab-panel-${key}`}
                                       active={selectedOptionIndex == key}>
                        <Box container direction={"column"} gap={"XXS"} flex={1}>
                          {sortedRates[ option ].map((rate, k) => {
                            return <Radio
                              disabled={rate.status !== "Success"}
                              checked={selected?.option == option && selected?.providerId == rate.providerId}
                              onChange={() => setSelected({ ...rate, option })}
                              name="pmiPartner"
                              key={`tab-panel-item-${k}`}
                              label={<ListRow className={PmiDialogClasses.PmiPartner} flex={1} gap={"XS"}>
                                <ListItemTitle title={rate.providerName} flexBasis={"20%"}/>
                                {
                                  // pmiRateLoading && ?
                                  //   <Box container gap={"XXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                                  //     <CircularLoader/>
                                  //     <ListItemText text={"Loading..."}/>
                                  //   </Box>
                                  //   :
                                    rate.status !== "Success" ?
                                      <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"}
                                           justify={"end"}>
                                        <ListItemText text={<Ellipsis
                                          style={{ pointerEvents: "auto", color: "var(--color-error)" }}
                                          from={90}>{rate.status}</Ellipsis>} tiny/>
                                        <Button style={{ pointerEvents: pmiRateLoading ? "none" : "auto" }}
                                                disabled={pmiRateLoading} variant={ButtonVariants.Ghost}
                                                onClick={refetch}>
                                          Reload
                                        </Button>
                                      </Box>
                                      :
                                      <>
                                        <Box container gap={"XXXS"} flexBasis={"30%"} flexShrink={0}
                                             alignItems={"center"}>
                                          <ListItemText text={"Rate:"} tiny/>
                                          <ListItemText
                                            text={`${format(rate.rate1, 3)}% / $${format(rate.premium1, 2)}`}/>
                                        </Box>
                                        <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"}>
                                          <ListItemText text={"Ref:"} tiny/>
                                          <ListItemText text={rate.quoteNumber}/>
                                        </Box>
                                        <Box container gap={"XXXS"} alignItems={"center"}>
                                          <ListItemText text={"Full quote:"} alignItems={"center"} tiny/>
                                          <a href={rate.pdfUrl} style={{ textDecoration: "none" }} target="_blank">
                                            <FontIcon className={CommonClasses.ClickableIcon} type={"rc_pdf_doc"}/>
                                          </a>
                                        </Box>
                                      </>
                                }
                              </ListRow>
                              }/>;
                          })
                          }
                        </Box>
                      </TabPanel>;
                    })
                  }
                </TabPanelContainer>
                <Tabs>
                  {
                    options.map((option, key) => (
                      <TabItem active={selectedOptionIndex == key} onClick={() => setSelectedOptionIndex(key)}
                               key={key}>{productTypesMap[ option ]}</TabItem>))
                  }
                </Tabs>
              </TabContainer>
              <ModalFooter>
                <Button variant={ButtonVariants.Outline} onClick={onClose}>CANCEL</Button>
                <Button variant={ButtonVariants.Fill} onClick={() => onApply()}>APPLY</Button>
              </ModalFooter>
            </>
            :
            !pmiError && <Box flex={"1 1 auto"} justify={"center"}>
              <EmptyList style={{ padding: 20 }} icon={"pie_chart"} content={"Please update lead data and try again."}
                         title={"No matching rates"}
                         alignSelf={"center"}/>
            </Box>
        }
      </ModalBody>
      {
        pmiRateLoading && <BackDropLoader/>
      }
    </Modal>;
  }
);

export default PmiDialog;
