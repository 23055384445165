export const PhoneNumberFlags = {
  US: `us.png`,
  AM: `armenia.png`
};
export const PhoneNumberOptions = [{
  country: "United States",
  code: "+1",
  mask: "+1 \\(999\\) 999-9999",
  flag: PhoneNumberFlags.US,

}, {
  country: "Armenia",
  code: "+374",
  mask: "+374 \\(99\\) 99-99-99",
  flag: PhoneNumberFlags.AM
}];
