import React                    from "react";
import { Label }                from "../../Label";
import { Box }                  from "../../Box";
import { BaseFieldClasses }     from "../BaseField";
import { useConsumeField }      from "../BaseField";
import { ImageInputFieldProps } from "./ImageInputField";

export const ImageInputReadField = React.memo(function ImageUploadReadField() {
  const {
    input: {
      label,
      required,
      value,
      name,
      targetClassName,
      flexBasis,
      flexShrink,
      flexGrow,
      readPlaceholder,
      ...textProps
    }
  } = useConsumeField<ImageInputFieldProps>();

  return (
    <Box container
         direction={"column"}
         gap={"XXXS"}
         className={BaseFieldClasses.ReadField}
         alignSelf={"start"}
         {...textProps}
         flexBasis={flexBasis}
         flexShrink={flexShrink}
         flexGrow={flexGrow}
    >
      <Label>{label}</Label>
      {value || <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>}
    </Box>
  );
});

export default ImageInputReadField;
