import { CellGroupHeader } from "@relcu/rc";
import AngleDownIcon       from "@rsuite/icons/legacy/AngleDown";
import React               from "react";
import { FC }              from "react";

export interface HeadingCellProps {
  expanded: boolean
}

export const HeadingCell: FC<HeadingCellProps> = React.memo((props) => {
  const {expanded, children} = props;
  return (
    <CellGroupHeader>
    <AngleDownIcon rotate={expanded ? 0 : -90}/>
      {children}
  </CellGroupHeader>
  )
})
