import { useCallback }            from "react";
import React                      from "react";
import { FC }                     from "react";
import { CircularLoader }         from "@relcu/ui";
import { useAlert }               from "@relcu/ui";
import { Ellipsis }               from "@relcu/ui";
import { classNames as clsNames } from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { FontIcon }               from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import "./msg-attachment.css";
import { loadingVar }             from "../../../../reactiveVars";
import { downloadFile }           from "../../../../utils/helpers";

export interface Attachment {
  objectId?: string,
  mimeType: string,
  size?: string | number,
  url?: string,
  name: string
}

interface MessageAttachmentProps extends BoxComponentProps {
  incoming?: boolean,
  uploaded?: boolean,
  error?: string | boolean,
  attachment: Attachment
  onDelete?()
}

export const MsgAttachment: FC<MessageAttachmentProps> = React.memo(function MsgAttachment(props) {
  const { className, incoming, uploaded, error: errorMessage, attachment, onDelete, ...p } = props;
  const { error } = useAlert();
  const classNames = clsNames(MsgAttachmentClasses.MsgAttachment, {
    [ MsgAttachmentClasses.MsgAttachmentError ]: !!errorMessage
  });

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) {
      return "0 Bytes";
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[ i ];
  }; //TODO separate this to util file

  const onDownload = useCallback(async (attachment) => {
    try {
      loadingVar(true);
      await downloadFile({ url: attachment.url, name: attachment.name, type: attachment.mimeType });
    } catch (e) {
      loadingVar(false);
      error(e?.message || `Could not download ${attachment.name} file`);
    } finally {
      loadingVar(false);
    }

  }, []);

  return (
    <Box container gap={"XXXS"} direction={"column"} className={classNames} alignSelf={"start"} {...p}>
      <Box container justify={"space-between"} alignItems={"center"} gap={"XXS"} flexGrow={1}>
        <Box container gap={"XXS"} alignItems={"center"}>
          <Box container gap={"XXXS"} alignItems={"center"} flexShrink={0}>
            <FontIcon type={"insert_photo"} className={MsgAttachmentClasses.MsgAttachmentIcon}/>
            {
              incoming
                ?
                <button onClick={() => onDownload(attachment)}
                        className={MsgAttachmentClasses.MsgAttachmentLink}>
                  <Ellipsis from={10} extension={true}>{attachment.name}</Ellipsis>
                </button>
                :
                <Ellipsis from={10} extension={true}
                          className={MsgAttachmentClasses.MsgAttachmentText}>{attachment.name}</Ellipsis>
            }
          </Box>
          {
            !incoming && attachment.size &&
            <p className={MsgAttachmentClasses.MsgAttachmentSize}>
              {formatBytes(attachment.size)}
            </p>
          }
        </Box>
        {
          uploaded == false && !errorMessage && <CircularLoader/>
        }
        {
          (uploaded || errorMessage) &&
          <FontIcon type={"clear"} className={MsgAttachmentClasses.MsgAttachmentRemove} onClick={onDelete}/>
        }
      </Box>
      {
        errorMessage &&
        <p className={MsgAttachmentClasses.MsgAttachmentErrorText}>
          {errorMessage}
        </p>
      }
    </Box>
  );
});

const enum MsgAttachmentClasses {
  MsgAttachment = "mgs-attachment",
  MsgAttachmentRemove = "mgs-attachment__remove",
  MsgAttachmentIcon = "mgs-attachment__icon",
  MsgAttachmentText = "mgs-attachment__text",
  MsgAttachmentLink = "mgs-attachment__link",
  MsgAttachmentSize = "mgs-attachment__size",
  MsgAttachmentError = "mgs-attachment--error",
  MsgAttachmentErrorText = "mgs-attachment__error-text",
}
