import React                   from "react";
import { FC }                  from "react";
import { Field }               from "@relcu/final-form";
import { Form }                from "@relcu/final-form";
import { TabItem }             from "@relcu/rc";
import { TabsSwitcher }        from "@relcu/rc";
import { TabPanel }            from "@relcu/rc";
import { TabPanelContainer }   from "@relcu/rc";
import { TabContainer }        from "@relcu/rc";
import { Stack }               from "@relcu/rc";
import { DialPad }             from "@relcu/ui";
import { Device }              from "../../../../Tw";
import { HybridCall }          from "../../../../types/hybridCall";
import { phoneNumberMatchers } from "../../../../utils/helpers";
import { DialInput }           from "../../Dial/DialInput";
import { useDIalPadSubmit }    from "../../useDIalPadSubmit";
import { DialContact }         from "../ContactList/__types__/DialContact";
import { ContactList }         from "../ContactList/ContactList";
import { DialUser }            from "../UserList/__types__/DialUser";
import { UserList }            from "../UserList/UserList";

interface CallTransferProps {
  onCall(value: DialUser | DialContact | string);
  calls: HybridCall[];
}

export const CallTransfer: FC<CallTransferProps> = React.memo(function CallTransfer(props) {
  const { checkIsInternalNumber, getDialedContact, createContact } = useDIalPadSubmit();
  const calls = props?.calls?.filter(c => c.__typename == "Contact" && (c.status == "ringing" || c.status == "in-progress" || c.status == "open" || c.status == "pending"));
  const isContactExists = props?.calls?.filter(c => c.__typename == "Contact" && c.status == "in-progress");

  const handleSubmit = async ({ from, to, contact }: { from?: string, to?: string, contact? }) => {
    try {
      if (typeof to == "string" && !contact) {
        const isInternal = await checkIsInternalNumber(to);
        if (isInternal) {
          return {
            to: "You cannot make internal call"
          };
        }

        contact = await getDialedContact(to);

        if (contact) {
          const phone = contact?.phones?.find((p) => p.number.includes(to));
          if (phone.callOptOut) {
            return {
              to: "This number is opt out for call"
            };
          }
        }

        if (!contact) {
          contact = await createContact(to);
        }
      } else if (contact) {
        const phone = contact.phone;
        if (phone.callOptOut) {
          return {
            to: "This number is opt out for call"
          };
        }
      }

      props.onCall({
        ...contact,
        phones: contact.phone ? [contact.phone] : [{ number: to }]
      });
    } catch (e) {
      return {
        FORM_ERROR: "Something went wrong."
      };
    }
  };

  return <Stack style={{ flex: 1, alignSelf: "stretch" }} childrenRenderMode={"clone"}>
    <TabContainer gap={24}
                  style={{ flex: 1, display: "flex", flexDirection: "column-reverse", gap: 24, alignSelf: "stretch" }}>
      <TabPanelContainer>
        {
          !!isContactExists?.length &&
          <TabPanel>
            <UserList calls={props.calls} onSelect={props.onCall}/>
          </TabPanel>
        }
        <TabPanel>
          <ContactList calls={props.calls} onSelect={props.onCall}/>
        </TabPanel>
        <TabPanel style={{ justifyContent: "center" }}>
          <Form
            initialValues={{ from: "", to: "" }}
            keepDirtyOnReinitialize={true}
            onSubmit={handleSubmit}>
            {({ handleSubmit, form }) => {
              return <form
                onSubmit={handleSubmit}
                noValidate
                style={{ display: "contents" }}>
                <Stack direction={"column"}>
                  <DialInput name={"to"} actions={["call"]}
                             queryFilterWhere={{ id: { notIn: calls.map(call => call.id) } }}/>
                  <Field
                    name={"to"}
                    parse={(value) => {
                      if (value.endsWith("*") || value.endsWith("#")) {
                        value = value.slice(0, -1);
                      }
                      return "+1" + phoneNumberMatchers(value);
                    }}
                    format={(value) => {
                      return value?.slice(2).replace?.(/_/g, "") ?? "";
                    }}>
                    {
                      ({ input: { value, onChange }, meta }) => {
                        return <DialPad
                          onDigit={digit => {
                            if (digit == "Backspace") {
                              // return onChange(value.slice(0, -1));
                              onChange(value);
                            } else if (value.indexOf("_") != -1) {
                              onChange(value.replace("_", digit));
                            } else if (value.indexOf("_") == -1 && value.length < 10) {
                              //todo this case happen because of back button remove digits not replace them with _ symbol
                              onChange(`${value}${digit}`);
                            }
                          }}
                          onCall={async () => {
                            await form.submit();
                          }}
                          disableCall={value?.length < 10}
                          disableDigits={value?.length >= 10}
                        />;
                      }
                    }
                  </Field>
                </Stack>
              </form>;
            }}
          </Form>
        </TabPanel>
      </TabPanelContainer>
      <TabsSwitcher itemsWidth={!!isContactExists?.length ? 86.6 : 130} size={"sm"}>
        {
          !!isContactExists?.length &&
          <TabItem active={true}>
            Users
          </TabItem>
        }
        <TabItem active={false}>
          Contact
        </TabItem>
        <TabItem active={false}>
          Number
        </TabItem>
      </TabsSwitcher>
    </TabContainer>
  </Stack>;
});
