import { useFormState }      from "@relcu/form";
import { ReactNode }         from "react";
import { useMemo }           from "react";
import React                 from "react";
import { ExoticComponent }   from "react";
import { useConstant }       from "../../hooks/useConstant";
import { Condition }         from "../../utils/Condition";
import { TopLevelCondition } from "../../utils/Condition";
import { UseLazyCondition }  from "./useLazyCondition";

type FunctionOrReactNode = ReactNode | ((value: { apply: boolean }) => ReactNode)
export interface FormWhereProps {
  conditions: TopLevelCondition,
  children: FunctionOrReactNode,
  source?: any;
  nullValue?: React.ReactNode
}
export const FormWhere: ExoticComponent<FormWhereProps> = React.memo(function FormCondition({ nullValue = null, children, conditions, source = {} }) {
  const where = useConstant(() => new Condition());
  const { values } = useFormState({ subscription: { values: true } });
  const { apply } = useMemo((options: Partial<UseLazyCondition> = {}) => {
    return where.evaluate(conditions, { ...values, ...source });
  }, [conditions, values, source]);

  if (children instanceof Function) {
    return <>{children({ apply })}</>;
  }
  return <>{apply ? children : nullValue}</>;
});
