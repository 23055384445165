import { useCallback }    from "react";
import React              from "react";
import { BaseField }      from "../BaseField";
import { TextFieldProps } from "../TextField";
import UsernameEditField  from "./UsernameEditField";
import UsernameReadField  from "./UsernameReadField";

export const UsernameField = React.memo<TextFieldProps>(function UsernameField(props) {

  const toLowercase = useCallback(value => value ? value.toLowerCase() : value, []);
  const validate = useCallback((value) => {
      if (value && !/^[a-z0-9\\.]+$/.test(value))
          return "Only alphanumeric characters (a-z, 0-9) and period (.) are allowed.";

      if (value && value.length > 16)
          return "Sorry, usernames must have a maximum of 16 characters.";
  }, [])

  return (
    <BaseField
      {...props}
      format={toLowercase}
      parse={toLowercase}
      validate={validate}
      readView={<UsernameReadField/>}
      editView={<UsernameEditField/>}
    />
  );
});
