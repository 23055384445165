import { useReactiveVar }           from "@apollo/client";
import React                        from "react";
import { FC }                       from "react";
import { classNames }               from "@relcu/ui";
import { Advice }                   from "@relcu/rc";
import { Stack }                    from "@relcu/rc";
import { Icon }                     from "@relcu/rc";
import { IconButton }               from "@relcu/rc";
import { audioDevicesVar }          from "../../../../reactiveVars";
import { microphoneAccessVar }      from "../../../../reactiveVars";
import { usePhone }                 from "../../../usePhone";
import { HybridCallActionsClasses } from "./RelcuCallActions";
import { useHybridCall }            from "./useHybridCall";
import { useRelcuCallActions }      from "./useRelcuCallActions";

export const RelcuCallActionsCollapsed: FC = React.memo(function RelcuCallActionsCollapsed() {
  const { activeCalls, myCall, isModerator, initiatingConference } = useHybridCall();
  const { active, direction } = usePhone();
  const { handleAccept, handleDropOrRemove } = useRelcuCallActions();
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const devices = useReactiveVar(audioDevicesVar);
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      childrenRenderMode={"clone"}
      style={{ width: "100%" }}>
      <Stack className={classNames({ [ HybridCallActionsClasses.HybridCallActionsDisabled ]: initiatingConference })}
             justifyContent={"center"}
             spacing={16}
             alignItems={"center"}
             childrenRenderMode={"clone"}>
        {
          activeCalls.length == 1 ? //one to one call
            <>
              {
                activeCalls?.[ 0 ]?.status == "in-progress" ?
                  <IconButton
                    size={"sm"}
                    icon={<Icon type={"call_end"}/>}
                    color={"red"}
                    onClick={() => active.drop()}/>
                  :
                  <>
                    {
                      direction === "incoming" &&
                      <Stack direction={"column"} spacing={8} childrenRenderMode={"clone"}>
                        <Advice text={activeCalls?.[ 0 ]?.objectName} placement={"auto"}>
                          <IconButton
                            size={"sm"}
                            disabled={!microphoneAccess || !devices.audiooutput.length || !devices.audioinput.length }
                            icon={<Icon type={"call"}/>}
                            color={"green"}
                            onClick={handleAccept}/>
                        </Advice>
                        <IconButton
                          size={"sm"}
                          icon={<Icon type={"call_end"}/>}
                          color={"red"}
                          onClick={() => active.reject()}/>
                      </Stack>
                    }
                    {
                      direction === "outgoing" &&
                      <IconButton
                        size={"sm"}
                        icon={<Icon type={"call_end"}/>}
                        color={"red"}
                        onClick={() => active.drop()}/>
                    }
                  </>
              }
            </>
            :
            //conf call
            //conf call not moderator
            !isModerator
              ?
              <>
                {
                  myCall.status == "in-progress" ?
                    <IconButton
                      size={"sm"}
                      icon={<Icon type={"call_end"}/>}
                      color={"red"}
                      onClick={() => active.drop()}/>
                    :
                    <Stack direction={"column"} spacing={8} childrenRenderMode={"clone"}>
                      <Advice text={myCall?.objectName} placement={"auto"}>
                        <IconButton
                          size={"sm"}
                          disabled={!microphoneAccess || !devices.audiooutput.length || !devices.audioinput.length }
                          icon={<Icon type={"call"}/>}
                          color={"green"}
                          onClick={handleAccept}/>
                      </Advice>
                      <IconButton
                        size={"sm"}
                        icon={<Icon type={"call_end"}/>}
                        color={"red"}
                        onClick={() => active.reject()}/>
                    </Stack>
                }
              </>
              :
              <IconButton
                size={"sm"}
                icon={<Icon type={"call_end"}/>}
                color={"red"}
                onClick={handleDropOrRemove}/>
        }
      </Stack>
    </Stack>
  );
});
