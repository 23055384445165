import { useField }            from "@relcu/form";
import { useContext }          from "react";
import React                   from "react";
import { FC }                  from "react";
import { NumberField }         from "@relcu/ui";
import { NumberFieldProps }    from "@relcu/ui";
import { LoanCriteriaContext } from "../LoanCriteriaProvider";

export const ScoreField: FC<NumberFieldProps> = React.memo(function ScoreField(props) {
  const { product: { type }, settings } = useContext(LoanCriteriaContext);
  const { input: { value: loanPurpose } } = useField("loanPurpose", { subscription: { value: true } });
  const { input: { value: score } } = useField(props.name, { subscription: { value: true } });
  const purpose = loanPurpose === "purchase" ? "purchase" : "refinance";
  const minScore = settings[ type ]?.[ purpose ].minCreditScore;
  const warning = minScore && (score >= 0) && (score < minScore);
  return <NumberField view={"write"} required={true} warning={warning ? `Minimum score is ${minScore}.` : undefined} {...props}/>;
});
