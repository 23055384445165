import { ReactNode }             from "react";
import { ReactElement }          from "react";
import { useMemo }               from "react";
import React                     from "react";
import { FontIcon }              from "../..";
import { classNames }            from "../..";
import { applyBoxItemStyles }    from "../Box";
import { BoxItemComponentProps } from "../Box";
import { IconType }              from "../Icon";

export enum ButtonColors {
  Primary = "primary",
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
  White = "white",
  Gray = "gray",
  Transparent = "transparent"
}
export enum ButtonVariants {
  Fill = "fill",
  Outline = "outline",
  Ghost = "ghost"
}
export enum ButtonSizes {
  Small = "small",
  Medium = "medium",
  Big = "big",
  Large = "large",
  Huge = "huge"
}
export enum IconDirection {
  Left = "left",
  Right = "right",
}
export interface ButtonProps extends BoxItemComponentProps<any> {
  variant?: ButtonVariants,
  color?: ButtonColors,
  icon?: IconType | ReactElement | ReactNode,
  size?: ButtonSizes,
  disabled?: boolean,
  onlyIcon?: boolean,
  isLoading?: boolean,
  type?: "submit" | "reset" | "button";
  iconDirection?: IconDirection
  onClick?(event)
}
const defaultButtonProps: ButtonProps = {
  variant: ButtonVariants.Fill,
  color: ButtonColors.Primary,
  icon: null,
  size: ButtonSizes.Medium,
  disabled: false,
  onlyIcon: false,
  isLoading: false,
  onClick: null,
  iconDirection: IconDirection.Left,
  type: "button"
};

export const Button = React.forwardRef(function Button(props: ButtonProps, ref: React.Ref<HTMLButtonElement>) {
  let properties = applyBoxItemStyles<ButtonProps>(props);
  const { className, variant, color, icon, size, disabled, children, iconDirection, onlyIcon, isLoading, onClick, ...p } = properties;
  const classes = classNames(ButtonClasses.Button, {
    [ ButtonClasses.Primary ]: (color == ButtonColors.Primary),
    [ ButtonClasses.Success ]: (color == ButtonColors.Success),
    [ ButtonClasses.Error ]: (color == ButtonColors.Error),
    [ ButtonClasses.Warning ]: (color == ButtonColors.Warning),
    [ ButtonClasses.Info ]: (color == ButtonColors.Info),
    [ ButtonClasses.White ]: (color == ButtonColors.White),
    [ ButtonClasses.Transparent ]: (color == ButtonColors.Transparent),//tmp
    [ ButtonClasses.Gray ]: (color == ButtonColors.Gray),

    [ ButtonClasses.Fill ]: (variant == ButtonVariants.Fill),
    [ ButtonClasses.Outline ]: (variant == ButtonVariants.Outline),
    [ ButtonClasses.Ghost ]: (variant == ButtonVariants.Ghost),
    [ ButtonClasses.Disabled ]: disabled,
    [ ButtonClasses.Small ]: (size == ButtonSizes.Small),
    [ ButtonClasses.Medium ]: (size == ButtonSizes.Medium),
    [ ButtonClasses.Big ]: (size == ButtonSizes.Big),
    [ ButtonClasses.Large ]: (size == ButtonSizes.Large),
    [ ButtonClasses.IconOnly ]: onlyIcon,
    [ ButtonClasses.WithIcon ]: !!icon && !onlyIcon,
    [ ButtonClasses.RightIcon ]: iconDirection == IconDirection.Right,
    [ ButtonClasses.LeftIcon ]: iconDirection == IconDirection.Left
  }, className);

  const iconComponent = useMemo(() => {
    if (typeof icon === "string") {
      return <FontIcon type={icon as IconType}/>;
    }
    return icon;
  }, [icon]);

  return <button tabIndex={disabled ? -1 : 0} {...p} className={classes} onClick={onClick} ref={ref}>
    {
      onlyIcon
        ?
        icon ? iconComponent : children
        :
        <>
          {
            !!icon && iconComponent
          }
          {children}
        </>
    }
  </button>;
});

Button.defaultProps = defaultButtonProps;

export enum ButtonClasses {
  Button = "btn",
  Primary = "btn--primary",
  Success = "btn--success",
  Error = "btn--error",
  Warning = "btn--warning",
  Info = "btn--info",
  White = "btn--white",
  Transparent = "btn--transparent",
  Gray = "btn--gray",
  Disabled = "btn--disabled",
  Fill = "btn--fill",
  Outline = "btn--outline",
  Ghost = "btn--ghost",
  Small = "btn--small",
  Medium = "btn--medium",
  Big = "btn--big",
  Large = "btn--large",
  IconOnly = "btn--only-icon",
  WithIcon = "btn--with-icon",
  RightIcon = "btn--right-icon",
  LeftIcon = "btn--left-icon",
}
