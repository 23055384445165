import React                      from "react";
import { useCallback }            from "react";
import { FC }                     from "react";
import { useForm }                from "@relcu/form";
import { getIn }                  from "@relcu/form";
import { Box }                    from "@relcu/ui";
import { GlobalClasses }          from "@relcu/ui";
import { FontIcon }               from "@relcu/ui";
import { Label }                  from "@relcu/ui";
import { classNames }             from "@relcu/ui";
import { useConsumeArrayField }   from "@relcu/ui";
import { BaseFieldClasses }       from "@relcu/ui";
import { ChoiceField }            from "@relcu/ui";
import { CommonClasses }          from "@relcu/ui";
import { IChoiceField }           from "../../../../../../types/ISchemas";
import { useSchemaField }         from "../../../../useSchemaField";
import { DuplicateFieldClasses }  from "../PhoneNumbersDuplicateField";
import { ContactTypesFieldProps } from "./ContactTypesField";

export const ContactTypesEditFiled: FC<any> = React.memo(function ContactTypesEditFiled(props) {
  const {
    fields,
    input: { className, label, required, defaultValue, fullSize = true, targetClass, ...phoneNumberProps },
    meta
  } = useConsumeArrayField<ContactTypesFieldProps>();
  const { touched, error } = meta;
  const { options } = useSchemaField<IChoiceField>("Contact", "types");
  const form = useForm();
  const classes = classNames(BaseFieldClasses.MultiField, {
    [ BaseFieldClasses.FullSize ]: fullSize
  }, className);

  const handleRevert = useCallback(() => {
    const name = fields.name;
    form.change(name, getIn(form.getState().initialValues, name));
  }, []);//field revert functionality need only call this

  return <Box name={fields.name} className={classes} container {...phoneNumberProps}>
    <Box container direction={"column"} flex={1} gap={"XXXS"}>
      {
        label &&
        <Label required={required} mLeft>
          {label}
        </Label>
      }
      {
        fields.map((name, index) => {
          return (
            <Box container justify={"space-between"} gap={"XS"} alignItems={"center"} key={name}
                 className={DuplicateFieldClasses.DuplicateFiled}>
              <ChoiceField
                halfSize={true}
                flexShrink={0}
                name={name}
                flex={1}
                message={""}
                options={options}
              />
              <FontIcon
                type={"clear"}
                onClick={() => {
                  fields.remove(index);
                }}
                className={BaseFieldClasses.RemoveAction}
                style={{ marginRight: "var(--layout-box-gap-x-x-s)" }}
              />
            </Box>
          );
        })
      }
      {touched && !!error && typeof error === "string" &&
      <Box className={classNames(GlobalClasses.Message, GlobalClasses.Error)}>
        {error}
      </Box>
      }
      {
        meta.dirty &&
        <FontIcon type={"undo"} className={CommonClasses.ClickableIcon}
                  style={{ marginRight: "var(--layout-box-gap-s)" }}
                  alignSelf={"end"} onClick={handleRevert}/>
      }
    </Box>
  </Box>;
});
export default ContactTypesEditFiled;
