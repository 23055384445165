import { classNames }                from "../..";
import React                         from "react";
import { FC }                        from "react";
import { Avatar }                    from "../Avatar";
import { AvatarSizes }               from "../Avatar";
import { Box }                       from "../Box";
import { BoxComponentProps }         from "../Box";
import { AutocompleteOptionClasses } from "./AutocompleteOptionClasses";

export interface AutoCompleteOptionProps extends BoxComponentProps {
  name: string;
  icon: string;
  value: string;
}

export const AutoCompleteOption: FC<AutoCompleteOptionProps> = React.memo(function AutoCompleteOption(props) {
  const { name, icon, value, className, ...p } = props;
  const classes = classNames(AutocompleteOptionClasses.AutocompleteOption, className);

  return <Box container gap={"XXS"} className={classes} {...p}>
    <Avatar size={AvatarSizes.Small} text={name} icon={icon}
            alignSelf={"baseline"}/>
    <Box container direction={"column"} className={AutocompleteOptionClasses.AutocompleteOptionInfo}>
      <Box>{name}</Box>
      {/*<Box>{option.email}</Box>*/}
      <Box>{value}</Box>
    </Box>
  </Box>;
});
