import { useMemo }                 from "react";
import { toFirstLower }            from "@relcu/ui";
import { useAlert }                from "@relcu/ui";
import { useSource }               from "@relcu/ui";
import { getPrimaryBorrower }      from "../../../utils/helpers";
import { useMailApi }              from "../../useMailApi";
import { QuickSendMailModalProps } from "./QuickSendMailModal";

export function useQuickSendModal(props: QuickSendMailModalProps) {
  const { data, onClose } = props;
  const { success, error } = useAlert();
  const { mailBoxes, handleSubmit,contentRendererRef,signatureRendererRef } = useMailApi(data);
  const primaryBorrower = data.__typename == "Lead" ? getPrimaryBorrower(data) : data;
  const emails = useMemo(() => primaryBorrower?.contact?.emails?.map((email) => ({ ...email, disabled: email.optOut })), [primaryBorrower]);
  const defaultEmail = useMemo(() => emails.find(email => !email.disabled), [emails]);
  const { $settings, $viewer } = useSource();
  const emailSignature = useMemo(() => {
    if ($settings?.emailSignature && $settings?.emailSignature[ 0 ]?.signature) {
      return $settings?.emailSignature[ 0 ]?.signature;
    }
  }, [$settings]);

  const templateFilters = useMemo(() => {
    const filters: Record<string, object> = {
      enabled: { equalTo: true },
      availableFor: { equalTo: "manual" },
      AND: [
        {applyTo: { equalTo: toFirstLower(props.data.__typename) }},
        { applyTo: { notEqualTo: "loanProposal" } }
      ],
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: $viewer.id
              }
            }
          }
        }
      ]
    };

    return filters;
  }, [props.data.__typename, $viewer.id]);

  async function onSubmit(data) {
    try {
      const result: any = await handleSubmit(data);
      if (result?.data) {
        success("Email sent.");
        onClose();
      } else {
        return result;
      }
    } catch (e) {
      console.error(e);
      error(e?.message ?? "Something went wrong");
    }
  }

  return {
    onSubmit,
    contentRendererRef,
    signatureRendererRef,
    templateFilters,
    primaryBorrower,
    emailSignature,
    signatureSettings: $settings?.emailSignature?.[ 0 ],
    mailBoxes,
    defaultEmail,
    emails
  };
}
