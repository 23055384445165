import { DocumentNode }             from "@apollo/client";
import { useQuery }                 from "@apollo/client";
import { gql }                      from "@apollo/client";
import { useSource }                from "@relcu/ui";
import { LOAN_ESTIMATE_OFFER_OB }   from "../../../../graph/operations.graphql";
import { schemaVar }                from "../../../../reactiveVars";
import { getFields }                from "../../../../utils/schemaUtils";
import { GetDefaultOfferVariables } from "./__types__/GetDefaultOffer";
import { GetDefaultOffer }          from "./__types__/GetDefaultOffer";

export const DEFAULT_LOAN_ESTIMATE_OFFER = gql`
  fragment DefaultLoanEstimateOffer on LoanEstimateOffer {
    adminFee
    appraisalFee
    cltv
    creditReportFee
    dti
    electronicDocumentDelivery
    fico
    fico1
    pmiEligible
    financeMip
    mip
    financeFf
    ff
    firstTimeHomeBuyer
    firstTimeUse
    firstUseOfVaProgram
    floodCertificationFee
    insurance
    mortech {
      investor
      loanProductId
      loanProductName
      source
      view
    }
    optimalBlue {
      ...LoanEstimateOfferOB
    }
    isHUDReo
    loanAmount
    loanProduct
    loanSafeFee
    loanTerm
    lockInDays
    lockInFee
    amiWaiverEligibility
    ltv
    mersFee
    mip
    pestInspectionFee
    docReviewFee
    attorneyFee
    surveyFee
    subordinationFee
    condoCertificateFee
    earnestMoneyPaid
    sellerConcession
    prepaidInterestAmount
    prepaidInterestDays
    prepaidInterestTotal
    propertyInsurancePrepaidAmount
    propertyInsurancePrepaidMonth
    propertyInsurancePrepaidTotal
    propertyTaxMonth
    propertyTaxAmount
    propertyTaxTotal
    propertyInsuranceYearly
    productType
    propertyValue
    propertyType
    amortizationType
    initialArmTerm
    downPayment
    currentMortgageBalance
    cashAmount
    purchasePrice
    isStreamLine
    withCredit
    withAppraisal
    loanProgram
    propertyUse
    veteranStatus
    pi
    monthlyPremium
    secondaryFinancing
    taxes
    totalLoanAmount
    transcriptFee
    verificationFee
    exempt
    waive
    waiveEscrow
    conforming
    documentType
    rate
    price
    apr
    monthlyIncome
    lienAmount
  }
  ${LOAN_ESTIMATE_OFFER_OB}
`;

export const GET_DEFAULT_OFFER = gql`
  query GetDefaultOffer($leadId:ID!){
    loanEstimateOffers(where: {lead:{have:{id:{equalTo:$leadId}}},deleted: {equalTo: false}},order: [createdAt_DESC],first: 1){
      edges {
        node {
          ...DefaultLoanEstimateOffer
        }
      }
    }
  }
  ${DEFAULT_LOAN_ESTIMATE_OFFER}
`;

export function useDefaultOffer() {
  const { $object: lead } = useSource();
  const { data: { loanEstimateOffers } = {}, loading } = useQuery<GetDefaultOffer, GetDefaultOfferVariables>(useAppendObCustomFields(GET_DEFAULT_OFFER), {
    fetchPolicy:"cache-and-network",
    nextFetchPolicy:"cache-first",
    variables: {
      leadId: lead.id
    }
  });
  const data = loanEstimateOffers?.edges?.at(0)?.node;
  return { data, loading };
}

export function useAppendObCustomFields(document:DocumentNode){
  const schemas = schemaVar();
  const obSchemaFields = getFields(schemas,"LoanEstimateOptimalBlue");
  const obCustomFields = obSchemaFields.filter((f)=>f.integration?.name).map((f)=>f.name);
  if(obCustomFields.length > 0){
    const OB_CUSTOM_FIELDS = gql`
      fragment LoanEstimateOfferOB on LoanEstimateOptimalBlue{
        ${obCustomFields.join('\n')}
      }
    `;

    let obFragmentIndex = document.definitions.findIndex((d)=>(d.kind==="FragmentDefinition" && d.name.value === "LoanEstimateOfferOB"));

    if(obFragmentIndex > -1){
      let currentSelection = (document.definitions[obFragmentIndex] as any).selectionSet.selections;
      let missingFields = obCustomFields.filter((o)=>((currentSelection.findIndex((s)=>(s.name.value===o))) === -1));
      if(missingFields.length){
        (document.definitions[obFragmentIndex] as any).selectionSet.selections.push(...(OB_CUSTOM_FIELDS.definitions[0] as any).selectionSet.selections.filter((s)=>missingFields.includes(s.name.value)));
      }
    }else{
      (document.definitions as any).push(OB_CUSTOM_FIELDS.definitions[0]);
    }
  }
  return document;
}
